<div style="margin-top: 15px;">
  <div class=" ">
    <div class="row" [formGroup]="basicDetails" (keyup)="checkForm(basicDetails)">
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputEmail1">First Name <span style="color:red">*</span></label>
          <input type="text" class="form-control mb-0" maxlength="50" style="text-transform: capitalize;"
            placeholder="Enter First Name" [readonly]="!isEdit" formControlName="firstName"
            [ngClass]="{ 'is-invalid': submitted && f.firstName.errors}">
          <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
            <div *ngIf="f.firstName.errors.required">First Name is required</div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputEmail1">Middle Name <span style="color:red"></span></label>
          <input type="text" [readonly]="!isEdit" maxlength="50" style="text-transform: capitalize;"
            class="form-control mb-0" formControlName="middleName" placeholder="Enter Middle Name"
            [ngClass]="{ 'is-invalid': submitted && f.middleName.errors}">
          <div *ngIf="submitted && f.middleName.errors" class="invalid-feedback">
            <div *ngIf="f.middleName.errors.required">Middle Name is required</div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputEmail1">Last Name <span style="color:red">*</span></label>
          <input type="text" maxlength="50" style="text-transform: capitalize;" class="form-control"
            placeholder="Enter Last Name" formControlName="lastName" [readonly]="!isEdit"
            [ngClass]="{ 'is-invalid': submitted && f.lastName.errors}">
          <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
            <div *ngIf="f.lastName.errors.required">Last Name is required</div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputEmail1"> Email Address <span style="color:red">*</span></label>
          <input type="text" class="form-control mb-0" formControlName="emailId" [readonly]="true"
            placeholder="Enter Email Address" [ngClass]="{ 'is-invalid': submitted && f.emailId.errors}">
          <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
            <div *ngIf="f.emailId.errors.required">Email is required</div>
            <div *ngIf="f.emailId.errors.email">Email must be a valid email address
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputEmail1"> Mobile <span style="color:red">*</span></label>
          <input type="text" [readonly]="true" class="form-control w-100 mb-0" formControlName="cellNumber"
            (keypress)="numberOnly($event)" placeholder="Enter Mobile Number"
            [ngClass]="{ 'is-invalid': (submitted || f.cellNumber.touched) && f.cellNumber.errors}">
          <div *ngIf="(submitted || f.cellNumber.touched) && f.cellNumber.errors" class="invalid-feedback">
            <div *ngIf="f.cellNumber.errors.required">Mobile Number is required
            </div>
            <div *ngIf="f.cellNumber.errors.pattern">Enter valid Mobile Number</div>
          </div>
          <small class="text-danger" *ngIf="basicDetails.get('cellNumber').hasError('maxLength')">
            Enter valid Mobile Number.
          </small>
        </div>
      </div>
      <!-- <div class="col-md-4 mt-40">
        <div class="form-group">
          <label for="exampleInputEmail1">Total Doctor Experience <span style="color:red">*</span></label>
          <input type="text" class="form-control" [readonly]="!isEdit" (keypress)="numberOnlyExp($event)" [maxlength]="expLength"
          (keyup)="expEntered()"
            formControlName="totalExperience"
            [ngClass]="{ 'is-invalid': (submitted || f.totalExperience.touched) && f.totalExperience.errors}">
          <div *ngIf="(submitted || f.totalExperience.touched)  && f.totalExperience.errors" class="invalid-feedback">
            <div *ngIf="f.totalExperience.errors.required">Total experience is required</div>
            <div *ngIf="f.totalExperience.errors.max">Enter valid data</div>
            <div *ngIf="f.totalExperience.errors.min">Enter valid experience</div>
          </div>
        </div>
      </div> -->


      <div class="col-md-4" *ngIf="profileDetails?.specialization != 'NUTRITIONIST'">
        <div class="form-group ">
          <label for="exampleInputEmail1"> Specialization <span style="color:red">*</span></label>
          <input type="text" [readonly]="true" class="form-control float-left mb-0" formControlName="specialization"
            placeholder="Enter Specialization"
            [ngClass]="{ 'is-invalid': (submitted || f.cellNumber.touched) && f.cellNumber.errors}">
          <div *ngIf="submitted && f.specialization.errors" class="invalid-feedback">
            <div *ngIf="f.specialization.errors.required">Specializationis required</div>
          </div>
          <!-- <label for="exampleInputEmail1">Specialization <span style="color:red">*</span></label>
          <select class="form-control mb-0" [attr.disabled]="!isEdit" formControlName="specialization"
            [ngClass]="{ 'is-invalid': (submitted || f.specialization.touched) && f.specialization.errors}">
            <option value="" selected hidden disabled>Select specialization</option>
            <option>Surgeon</option>
            <option>Psychiatrist</option>
            <option>Cardiologist</option>
            <option>Dermatologist</option>
            <option>Endocrinologist</option>
            <option>Gastroenterologist</option>
            <option>Infectious Disease Physician</option>
            <option>Nephrologist</option>
            <option>Ophthalmologist</option>
            <option>Otolaryngologist</option>
          </select>
          <div *ngIf="(submitted || f.specialization.touched) && f.specialization.errors" class="invalid-feedback">
            <div *ngIf="f.specialization.errors.required">Specialization is required</div>
          </div> -->
        </div>
      </div>


      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputEmail1"> Medical license Number <span style="color:red">*</span></label>
          <input type="text" class="form-control" maxlength="25" formControlName="medicalLicenceNumber"
            placeholder="Enter Medical License Number" [readonly]="true"
            [ngClass]="{ 'is-invalid': submitted && f.medicalLicenceNumber.errors}">
          <div *ngIf="(submitted && f.medicalLicenceNumber.touched) && f.medicalLicenceNumber.errors"
            class="invalid-feedback">
            <div *ngIf="f.medicalLicenceNumber.errors.required">Medical license
              Number is required</div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="row">

          <div class="col-md-12 col-xs-12">
            <div class="form-group">
              <label for="exampleInputEmail1">License Expiry Date <span
                  class="font-size-10 text-muted">(MM/DD/YYYY)</span><span style="color:red">*</span></label>
              <input id="matDate" #date (click)="dateOfExpiry.open()" (keypress)="dateOfExpiry.open()"
                (keyup)="dateOfExpiry.open()" (dateChange)="formateChange(date.value)" [readonly]="!isEdit"
                [disabled]="isDisabled" class="form-control" [min]="minDate" placeholder="mm/dd/yyyy" matInput
                [matDatepicker]="dateOfExpiry" formControlName="dateOfExpiry" readonly
                [ngClass]="{ 'is-invalid': (submitted || f.dateOfExpiry.touched) && f.dateOfExpiry.errors}">
              <div *ngIf="(submitted || f.dateOfExpiry.touched) && f.dateOfExpiry.errors" class="invalid-feedback">
                <div *ngIf="f.dateOfExpiry.errors.required">license expiry date is required</div>
              </div>

            </div>
          </div>

          <div class="col-md-1 col-xs-2" style="margin-top: 24px; margin-left: -44px;">
            <mat-datepicker #dateOfExpiry></mat-datepicker>
            <mat-datepicker-toggle [disabled]="!isEdit" matSuffix [for]="dateOfExpiry"></mat-datepicker-toggle>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputEmail1">Gender</label>
          <div>
            <fieldset (change)="genderChanged()" [disabled]="!isEdit">
              <div class="custom-control custom-radio custom-control-inline pl-1">
                <input type="radio" class="custom-control-input" value="Male" id="male" [readonly]="!isEdit"
                  formControlName="gender">
                <label class="custom-control-label" for="male">Male</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline pl-0">
                <input type="radio" class="custom-control-input" value="Female" id="female" [readonly]="!isEdit"
                  formControlName="gender">
                <label class="custom-control-label" for="female">Female</label>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="float-right">
          <button type="button" *ngIf="!isEdit" class="btn btn-primary btn-sm mr-1" (click)="Edit()"><i
              class="fa fa-edit"></i> Edit</button>

          <button type="button" *ngIf="isEdit" class="btn btn-success btn-sm mr-2" (click)="SaveDoctorDetails()"
            [disabled]="isSave"><i class="fas fa-save"></i> Save</button>
          <button type="button" *ngIf="isEdit" class="btn btn-danger btn-sm mr-1" (click)="resetForm()"
            [disabled]="isSave"><i class="fa fa-sync"></i> Reset</button>
        </div>
      </div>

      <!-- <div class="col-md-12">
<h5 style="margin-top: 10px;background: linear-gradient(45deg,#17A3AE,#A550D4);padding:8px;color:white;font-weight: 500;font-size: 15px;">Fee details</h5>
</div> -->
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 mt-2">
      <div style="background:#f9f9f9;padding: 10px 5px;">
        <div class="d-flex justify-content-between align-items-baseline">
          <h3 class="head mb-0" aria-controls="feedetails">Fee Details </h3>
          <p class="fs-16 mb-0 text-danger"><span>{{feeDtsMessage}}</span></p>
          <button class="btn btn-success add-btn" href="javascript:void(0)" data-toggle="modal" data-target="#add-fee" *ngIf="2>feeList?.length">
            <span><i class="fa fa-plus" aria-hidden="true"></i></span> Add
          </button>
        </div>
      </div>

      <div id="feedetails" data-parent="#feedetails" class="row">
        <div *ngFor="let item of feeList;let index=index"
          class="card d-flex flex-row mb-3 p-3 col-lg-3 col-md-6 col-xs-6 col-sm-6">
          <div class="d-flex flex-grow-1 min-width-zero">
            <div
              class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
              <select  class="form-control feetr_view_{{index}} mb-1   w-30 w-xs-100"
                [disabled]="true" [value]="item.type">
                <option value="" selected hidden disabled>Select Fee Type</option>
                <option value="Video">Video</option>
                <!-- <option value="Telephone">Telephone</option>
                <option value="Chat">Chat</option> -->
                <option value="Inperson">In person</option>
              </select>

              <select style="display:none" class="form-control feetr_edit_{{index}} mb-1   w-30 w-xs-100"
                [value]="item.type" [(ngModel)]="item.type" disabled>
                <option value="" selected hidden disabled>Select Fee Type</option>
                <option value="Video">Video</option>
                <!-- <option value="Telephone">Telephone</option>
                <option value="Chat">Chat</option> -->
                <option value="Inperson">In person</option>
              </select>


              <input type="text"
                class="form-control list-item-heading inr mb-1 truncate w-20 w-xs-100 feetr_view_{{index}}"
                [disabled]="true" [(ngModel)]="item.fee">
              <input style="display: none" placeholder="fee" (keypress)="numberOnly($event)" [maxlength]="4" type="text"
                class="form-control inr list-item-heading mb-1 truncate w-20 w-xs-100 feetr_edit_{{index}}"
                (keypress)="numberOnly($event)" [(ngModel)]="item.fee">

              <div class="w-xs-100 text-right">
                <div class=" float-right">

                  <div class=" feetr_view_{{index}}">
                    <button class="btn btn-primary btn-sm mr-2" *ngIf="item.active == 'Y'" (click)="btnEdit(index)"
                      data-placement="top" data-toggle="tooltip" data-original-title="Edit "> <i
                        class="fa fa-edit"></i></button>
                    <button class="btn btn-danger btn-sm" *ngIf="item.active == 'N'" (click)="deleteFee(item)"> <i
                        class="fa fa-plus" data-placement="top" data-toggle="tooltip"
                        data-original-title="Active"></i></button>
                    <button class="btn btn-success btn-sm" (click)="deleteFee(item)" *ngIf="item.active == 'Y'"> <i
                        class="fa fa-minus" data-placement="top" data-toggle="tooltip"
                        data-original-title="Inactive"></i></button>
                  </div>
                  <div class=" feetr_edit_{{index}}" style="display:none">
                    <button class="btn btn-success  btn-sm  mr-2" type="button" (click)="updateFee(item,index)"
                      data-placement="top" data-toggle="tooltip" data-original-title="Save "><i
                        class="fa fa-save"></i></button>
                    <button class="btn btn-danger  btn-sm" type="button" (click)="cancelFee(index)" data-placement="top"
                      data-toggle="tooltip" data-original-title="Delete "><i class="fa fa-close"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- ============================= -->

      </div>

      <div *ngFor="let item of entityFees;let index=index" class="  mb-3 mt-3">


        <div style="background:#f9f9f9;padding: 10px 5px;">
          <div class="d-flex justify-content-between align-items-baseline">
            <h3 class="head mb-0" aria-controls="feedetails">{{item.entityName}}</h3>


          </div>
        </div>




        <div class="card-body  p-2 ">
          <div class="row">
            <div class="col-md-3 card" *ngIf="item.vidoeFee!=null">
              <div class=" row p-3">
                <label class="col-md-6 text-left">Video Fee </label>
                <div class="col-md-6 text-right"> <i class="fa fa-inr"></i> {{item.vidoeFee}}</div>
              </div>
            </div>
            <!-- <div class="col-md-3 card" *ngIf="item.telephoneFee!=null">
              <div class=" row p-3">
                <label class="col-md-6 text-left">Telephone Fee</label>
                <div class="col-md-6 text-right"> <i class="fa fa-inr"></i> {{item.telephoneFee}}</div>

              </div>
            </div>
            <div class="col-md-3 card" *ngIf="item.chatFee!=null">
              <div class=" row p-3">
                <label class="col-md-6 text-left">Chat Fee</label>
                <div class="col-md-6 text-right"> <i class="fa fa-inr"></i> {{item.chatFee}}</div>


              </div>
            </div> -->
            <div class="col-md-3 card" *ngIf="item.inPersonFee!=null">
              <div class=" row p-3">
                <label class="col-md-6 text-left">Inperson Fee</label>
                <div class="col-md-6 text-right"> <i class="fa fa-inr"></i> {{item.inPersonFee}}</div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>

  <!-- Modal -->
  <div class="modal modal-right fade" id="add-fee">
    <div class="modal-dialog" style="width:550px">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title"> <i class="fa fa-calendar-plus"></i> Add Fee Details</h5>
          <button type="button" class="close text-white" data-dismiss="modal" (click)="clearFeeDetails()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-2">
          <div [formGroup]="feeDetails" class="row mb-3 p-3">
            <!-- <p class="list-item-heading mb-1 truncate w-30 w-xs-100">Andhra University </p> -->
            <div class="col-md-6">
              <select id="feeType" class="form-control" formControlName="type"
                [ngClass]="{ 'is-invalid': (feesubmitted || fe.type.touched) && fe.type.errors}">
                <option value="" selected hidden disabled>Select Fee Type</option>
                <option value="Video">Video</option>
                <!-- <option value="Telephone">Telephone</option>
                <option value="Chat">Chat</option> -->
                <option value="Inperson">In person</option>
              </select>
              <div *ngIf="(feesubmitted || fe.type.touched) && fe.type.errors" class="invalid-feedback">
                <div *ngIf="fe.type.errors.required">
                  Fee type is required</div>
              </div>
            </div>

            <div class=" col-md-6">
              <!-- <input currencyMask formControlName="fee" /> -->
              <input type="tel" (keypress)="numberOnly($event)" min="250" [maxlength]="4" class="form-control inr"
                placeholder="₹ 00:00" style="text-align: left;" formControlName="fee"
                [ngClass]="{ 'is-invalid': (feesubmitted || fe.fee.touched) && fe.fee.errors}">
              <div *ngIf="(feesubmitted || fe.fee.touched) && fe.fee.errors" class="invalid-feedback">
                <div *ngIf="fe.fee.errors.required">
                  Fee amount is required</div>
              </div>
            </div>
            <!-- <p class="mb-1  ">MBBS - Bachelor of Medicin...</p> -->
            <!-- <p class="mb-1 text-muted  w-15 w-xs-100">18.02.2018</p> -->
            <div class="col-md-12">
              <div class="btn-group float-right mt-3">
                <button type="button" class="btn btn-primary float-right btn-sm" (click)="saveFee()">
                  <i class="fa fa-save" aria-hidden="true"></i>&nbsp;Add Fee</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /.modal -->
