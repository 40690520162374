import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConsentFormComponent } from '../consent-form/consent-form.component';
import { FormGroup } from '@angular/forms';
import { GlobalStorageService } from 'src/app/global-storage.service';
import { ToastrService } from 'ngx-toastr';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { Router } from '@angular/router';
import { PdfServiceService } from 'src/app/pdf-service.service';
import { PdfMakerService } from 'src/app/pdf-maker.service';
declare var $: any;

@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.css']
})
export class PrescriptionListComponent implements OnInit {
  @Input() prescriptionInfo:any
  @Input() medicationPrescriptions:any;
  @Input() paidAmount:any;
  @Input() pharmaInvoiceData:any
  customStripeForm: FormGroup;
  formProcess: boolean = false
  checkAgree: boolean = false;associatedUserData;userID
  consent;disableCB;associatedData;obj;submitted:boolean=false;patientAppointmentId;pId;
  constructor(private modal:NgbModal,private globalStorage:GlobalStorageService,private toaster:ToastrService,
    private patientScheduleService:PatientScheduleControlService,private router:Router,private pdfservice: PdfServiceService, private pdfmake: PdfMakerService) { }

  ngOnInit(): void {
    this.associatedData = this.patientScheduleService.sharingData
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    if (this.associatedUserData?.relationUserId != null || this.associatedUserData?.relationUserId != undefined) {
      this.userID = this.associatedUserData?.relationUserId
    }
    else {
      this.userID = obj['userId']
    }
    this.loadImage()
  }

  consentForm(){
    let wClass = 'modal-right hw-40'
    const consentFormRef = this.modal.open(ConsentFormComponent, {
      // centered: true,
      windowClass: wClass,
      backdrop: 'static'
    })
    consentFormRef.componentInstance.consent = this.checkAgree
    consentFormRef.result.then((reason) => {
      this.checkAgree = reason;
    },
      (result) => {
        this.checkAgree = result
      })
  }
  pay(){
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    if ($("#consent").prop('checked') == false) {
      this.toaster.warning('Please agree the consent form.')
      return
    }
    if (this.associatedData?.relationUserId) {
      this.obj = this.associatedData
      this.obj['userId'] = this.associatedData?.relationUserId
      var data = this.globalStorage.getItem('userData')
      var userData = JSON.parse(data)
      this.obj['emailId'] = userData['emailId']
    }
    else {
      var data = this.globalStorage.getItem('userData')
      this.obj = JSON.parse(data)
    }
    this.getOerderId()
    this.submitted = true;
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    this.formProcess = true;
    this.patientAppointmentId = this.prescriptionInfo?.entityInvoiceId
    this.pId = this.userID
  }
  getOerderId(){
    var req = {
      "aliment": "",
      "amount": this.paidAmount,
      "amountPaid":this.paidAmount,
      "orderId": "",
      "patientId": this.userID,
      "patientName":this.obj['firstName'] + ' ' + this.obj['lastName']
    };
    this.patientScheduleService.getChargeCustomer(req, req?.patientId).subscribe(res => {
      //this.payWithRazor(res['data']['orderId'])
      console.log('res',res)
      if(res?.status==202){
        this.payWithRazor(res['data']?.['razorpayOrderId'])
        this.formProcess = false
        $('#order-view').modal('hide')
      }
    }, error => {
      this.toaster.error(error['error']['message'])
      this.formProcess = false
    })
  }
  payWithRazor(val) {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    var options = {
      "key": "rzp_test_1DXDi790J1BYdo", // Enter the Key ID generated from the Dashboard
      "applicationFee": this.paidAmount * 100,
      "amount": this.paidAmount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "secretKey": "s48pVrrMeUX7BNGAZXD3NRjQ",
      "name": "CureMetric",
      "description": 'Test',
      "image": "https://example.com/your_logo",
      "order_id": val, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "razorpayOrderId": val,
      "customerName":  this.obj['firstName'] + ' ' + this.obj['lastName'],
      "customerEmail":this.obj['emailId'],
      "customerContact": this.obj['cellNumber'],
      "handler": function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature)
        if (response.razorpay_payment_id) {
          var body = {
            "appointmentFor": this.appointemntFor,
            "appointmentId": this.patientAppointmentId,
            "forUser": {
              "forUserId": this.pId,
              "userId": obj['userId']
            },
            "paymentId": response.razorpay_payment_id,
          }
          this.generateInvoice(body)
        }
      }.bind(this),
      "prefill": {
        "name": this.obj['firstName'] + ' ' + this.obj['lastName'],
        "email": this.obj['emailId'],
        "contact": this.obj['cellNumber']
      },
      "notes": {
        "address": "Razorpay Corporate Office"
      },
      "theme": {
        "color": "#3399cc"
      }
    }
    // options.handler = ((response, error) => {
    //   options.response = response;
    // call your backend api to verify payment signature & capture transaction
    // });
    // options.modal.ondismiss = (() => {
    //   // handle the case when user closes the form while transaction is in progress
    // });
    const rzp = new this.patientScheduleService.nativeWindow.Razorpay(options);
    rzp.open();
  }
  generateInvoice(body) {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    var request = {
      // "carddetails": {
      //   "userId": this.userID,
      //   "username": this.invoiceData['patientname'],
      //   "saveCardDetails": true,
      //   "cardbankname": "HDFC",
      //   "cardnumber": this.invoicePayForm.cardNumber,
      //   "cardtype": "Debit",
      //   "cvv": this.invoicePayForm.cvv,
      //   "expmonth": parseInt(this.invoicePayForm.expMonth),
      //   "expyear": parseInt(this.invoicePayForm.expYear)
      // },
      "patientId": this.userID,
      "patientInvoiceId": this.patientAppointmentId,
      "chargeFor": "PHARMACY",
      "totalAmount": parseFloat(this.paidAmount),
      "amountPaid": parseFloat(this.paidAmount),
      "currencyType": "INR",
      "description": 'ONLINE',
      "discountAmount": 10,
      "discountType": " ",
      "paymentThrough": "DEBITCARD",
      "active": "Y",
      "stripeCustomerEmail": obj['emailId'],
      "stripeCustomerName": this.obj['firstName'] + ' ' + this.obj['lastName'],
      "transactionType": "DEBITCARD",
      "invoicestatus": "PAYMENT_DONE",
      "paymentId": body.paymentId,
      "mihpayId":body.paymentId
    }
    var bd={
      "mihpayId":body?.paymentId,
      "paymentId": body?.paymentId,
       "mode": '',
       "amountPaid": parseFloat(this.paidAmount),
       "chargeFor": "PHARMACY",
       "patientEmail": obj?.['emailId'],
       "patientId":  this.userID,
       "patientInvoiceId":this.patientAppointmentId,
       "patientName":this.obj['firstName'] + ' ' + this.obj['lastName'],
       "paymentDetail": {
        "aliment": "Prescription", 
        "amount": parseFloat(this.paidAmount),
         "email": obj?.['emailId'], 
         "name": this.obj['firstName'] + ' ' + this.obj['lastName'],
        "phone": obj?.['cellNumber'],
        "paymentThrough": "ONLINE", 
        "transactionType":"ONLINE",
        }
    }
    var req = {
      "aliment": "",
      "amount": parseFloat(this.paidAmount),
      "orderId": "",
      "patientId": this.userID,
      "patientName": this.obj['firstName'] + ' ' + this.obj['lastName']
    };
    this.patientScheduleService.globalPost('payment/charge', bd, this.userID).subscribe(res => {
      // this.payWithRazor(res['data']['orderId'])
      this.toaster.success('Payment Done')
      this.formProcess = false
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
      $('.paid').addClass('active')
      this.prescriptionInfo['status']='Paid'
    }, error => {
      this.toaster.error(error['error']['message'])
      this.formProcess = false
    })
  }
  pdfData;invoiceID;pharmaAddress;priceNmedicine;pharmacyAddress1;pharmacyAddress2;pharmacyCity;
  pharmacyState;pharmacyCountry;pharmacyPincode;tType;issuedDate;patientName;doctorName;
  pstreet1;pstreet2;pstate;pcity;pcountry;pzip;
  patAddress;base64Image
  printData: { content: ({ text: string; margin: number[]; columns?: undefined; table?: undefined; layout?: undefined; style?: undefined; alignment?: undefined; bold?: undefined; } | { columns: any; margin: number[]; text?: undefined; table?: undefined; layout?: undefined; style?: undefined; alignment?: undefined; bold?: undefined; } | { table: { widths: string[]; body: { text: string; style: string; }[][]; }; layout: string; margin: number[]; text?: undefined; columns?: undefined; style?: undefined; alignment?: undefined; bold?: undefined; } | { text: string; style: string; alignment: string; bold: boolean; margin?: undefined; columns?: undefined; table?: undefined; layout?: undefined; })[]; styles: { filledHeader: { fillColor: string; fontSize: number; bold: boolean; margin: number[]; }; tableHeader: { fillColor: string; fontSize: number; bold: boolean; margin: number[]; }; tableData: { fontSize: number; margin: number[]; }; }; };
  downloadAsPDF(){
    this.patientScheduleService.getInvoiceByFillRequestId(this.pharmaInvoiceData?.prescriptionFillRequestId, this.pharmaInvoiceData?.patientId).subscribe(res => {
      this.pdfData = res?.data;
      this.patAddress = res?.data?.address;
      this.pharmaAddress = res?.data?.prescriptionPricesAndAddress?.pharmacyaddress;
      this.priceNmedicine = res?.data?.prescriptionPricesAndAddress?.prescriptionprices;
      this.pharmacyAddress1 = this.pharmaAddress?.['address1']
      this.pharmacyAddress2 = this.pharmaAddress?.['address2']
      this.pharmacyCity = this.pharmaAddress?.['city']
      this.pharmacyState = this.pharmaAddress?.['state']
      this.pharmacyCountry = this.pharmaAddress?.['country']
      this.pharmacyPincode = this.pharmaAddress?.['zip']
      this.paidAmount = this.pdfData?.amount
      this.tType = this.pdfData?.transactiontype
      this.issuedDate = this.pdfData?.paidOn

      this.doctorName = res['data']?.['invoiceentityname']
      this.patientName = res['data']['patientname']
      this.pstreet1 = res['data']['address']?.['street1']
      this.pstreet2 = res['data']['address']?.['street2']
      this.pstate = res['data']['address']?.['state']
      this.pcity = res['data']['address']?.['city']
      this.pcountry = res['data']['address']?.['country']
      this.pzip = res['data']['address']?.['zip']
      this.genPdfPharma()
  })
}
genPdfPharma() {
  const now = new Date();
const formattedDate = now.toLocaleDateString();
const formattedTime = now.toLocaleTimeString();

const logo = this.pharmaAddress?.logo ? 'data:image/jpeg;base64,' + this.pharmaAddress?.logo : this.base64Image;



  const users = Object.values(this.priceNmedicine) || [];

  const tableBody = [
    [

      { text: 'Medicine', style: 'tableHeader' },
      { text: 'Quantity', style: 'tableHeader' },
      { text: 'Unit Price', style: 'tableHeader' },
      { text: 'Tax', style: 'tableHeader' },
      // { text: 'Price', style: 'tableHeader' },
      { text: 'Discount(%)', style: 'tableHeader' },
      { text: 'Total', style: 'tableHeader' },
    ]
  ];

  users.forEach((user: any) => {
    tableBody.push([

      { text: user.medicine, style: 'tableData' },
      { text: user.quantity, style: 'tableData' },
      { text: user.unitPrice, style: 'tableData' },
      { text: '0', style: 'tableData' },
      // { text: user.totalPrice, style: 'tableData' },
      { text: user.discount, style: 'tableData' },
      { text: user.totalPrice, style: 'tableData' },
    ]);
  });

  this.printData = {


    header: function (currentPage, pageCount) {
      return {
        columns: [
          {
            image: logo,
            width: 100,
            height: 0,
            alignment: 'left'
          }

        ],
        margin: [50, 20, 0, 5],
      };
    },

    content: [

      {
        columns: [
          { text: 'Invoice ID:' + this.pdfData?.invoiceId, alignment: 'right' },


        ],
        margin: [0, 5, 0, 5],
      },
      {
        columns: [
          { text: 'Date:' + this.pdfData?.paidOn, alignment: 'right' },


        ],
        margin: [0, 10, 0, 10],
      },
      {
        text: '_'.repeat(95),

        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          { text: 'Invoice From:', alignment: 'left', bold: true },
          { text: 'Invoice To:', alignment: 'left', bold: true },
        ],
        margin: [0, 10, 0, 10],
      },
      {
        columns: [
          [
            { text: this.doctorName, alignment: 'left' },
            { text: this.pharmaAddress?.address1, alignment: 'left' },
            { text: this.pharmaAddress?.city, alignment: 'left' },
            { text: this.pharmaAddress?.postalLocation, alignment: 'left' },
            { text: this.pharmaAddress?.state, alignment: 'left' },
            { text: this.pharmaAddress?.zip, alignment: 'left' },
          ],
          [
            { text: this.pdfData?.patientname, alignment: 'left' },
            { text: this.patAddress?.street1, alignment: 'left' },
            { text: this.patAddress?.city, alignment: 'left' },
            { text: this.patAddress?.postalLocation, alignment: 'left' },
            { text: this.patAddress?.state, alignment: 'left' },
            { text: this.patAddress?.zip, alignment: 'left' },

          ]
        ],
        margin: [0, 5, 0, 5],
      },
      {
        table: {
          widths: Array(7).fill('17%'),
          body: tableBody,
        },
        layout: 'noBorders',
        margin: [0, 10, 0, 0],
      },


      {
        text: '_'.repeat(95),

        margin: [0, 0, 0, 0],
      },
      { text: 'Subtotal: ' + this.pdfData?.amount, style: 'tableData', alignment: 'right', bold: true },

      {
        text: '_'.repeat(95),

        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          { text: 'Discount: ' + 0, style: 'tableData', alignment: 'right', bold: true },

        ],
        margin: [0, 10, 0, 0],

      },
      { text: 'Tax: ' + 0, style: 'tableData', alignment: 'right', bold: true },

      {
        text: '_'.repeat(95),

        margin: [0, 0, 0, 0],
      },
      { text: 'Total: ' + this.pdfData?.amount, style: 'tableData', alignment: 'right',margin:[0,0,0,30], bold: true },

      {
        columns: [
          { text: 'Payment Method:'+this.pdfData?.transactiontype || 'None', alignment: 'left',bold:true },
        ],
        margin: [0, 10, 0, 263],
      },
      {
        columns: [
          { text: formattedDate, alignment: 'left' },
          { text: formattedTime, alignment: 'right' }

        ],
        margin: [0, 10, 0, 10],
      },

    ],


    styles: {
      filledHeader: {
        fillColor: '#cccccc',
        fontSize: 14,
        bold: true,
        margin: [0, 5, 0, 5]
      },
      tableHeader: {
        fillColor: '#eeeeee',
        fontSize: 12,
        bold: true,
        margin: [0, 5, 0, 5]
      },
      tableData: {
        fontSize: 10,
        margin: [0, 5, 0, 5]
      }
    },
    footer: function() {
      return [
        {
          canvas: [
            { type: 'line', x1: 0, y1: 0, x2: 595 - 0, y2: 0, lineWidth: 1 } // Bottom border line
          ]
        },
        {
          columns: [
            {
              text: '© 2024 , Powered By CureMetric Health Tech.',
              alignment: 'center',
              margin: [20, 10, 20, 0]
            }
          ]
        }
      ];
    },

  } as any;
  console.log('print data',this.printData)
  this.pdfmake.downloadPdf(this.printData)
}
errorMessage
loadImage() {
  const imageUrl = './assets/images/invoiceLogo.png'; 
  this.getImageBase64(imageUrl)
    .then(base64 => {
      this.base64Image = base64;
    })
    .catch(error => {
      this.errorMessage = error;
    });
}
getImageBase64(url: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function() {
      const reader = new FileReader();
      reader.onloadend = function() {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = function() {
      reject('Could not load image');
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}
}
