import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
export function NoMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value == matchingControl.value) {
            matchingControl.setErrors({ noMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
export function NoMatchEmergency(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value == matchingControl.value) {
            matchingControl.setErrors({ noMatchEmer: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
export function NoMatchEmergencySecondary(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value == matchingControl.value) {
            matchingControl.setErrors({ noMatchEmerSEC: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
export function NoSameFirstName(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName]
        var val1=control['value'].toLowerCase()
        var val2=matchingControl['value'].toLowerCase()
   
        // set error on matchingControl if validation fails
        if (val2.includes(val1)) {
            matchingControl.setErrors({ NoSameFirstNamePassword: true });
        } else {
            // matchingControl.setErrors(null);
        }
    }
}
export function NoSamelastName(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName]
        var val1=control['value'].toLowerCase()
        var val2=matchingControl['value'].toLowerCase()
        // set error on matchingControl if validation fails
        if (val2.includes(val1)) {
            matchingControl.setErrors({ NoSameLastNamePassword: true });
        } else {
            // matchingControl.setErrors(null);
        }
    }
}