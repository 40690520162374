import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../inventory.service';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {
  @Input() addressId: any;
  @Input() fromWhere: any;
  // @Input() service;
  @Input() isEdit: boolean = false;
  @Input() catergory: any;
  categoryForm: FormGroup;
  submitted: boolean = false;

  constructor(private globalStorage: GlobalStorageService, private inventoryService:InventoryService,private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private toastrService: ToastrService) {

  }

  ngOnInit(): void {
    this.categoryForm = this.formBuilder.group({
      "catagory": ['', Validators.required],
      "catagoryId": [''],
      "name": [''],
      "Description": ['',]
    })
    if (this.isEdit) {
      this.categoryForm.patchValue({
        catagory: this.catergory.catagory,
        catagoryId: this.catergory.catagoryId,
        name: this.catergory.name,
        Description: this.catergory.description
      })

      // Check the checkbox if isPharmacy is 'Y'
      if (this.catergory?.isPharmacy == 'Y') {
        document.getElementById('customCheck1').setAttribute('checked', 'true');
      }
    }
  }

  get categoryFormControls() {
    return this.categoryForm.controls;
  }

  isPharmacy: boolean = false;
  onCheckboxChange(event: any) {
    this.isPharmacy = event.target.checked;
  }

  checkPharmacy(){
    return this.globalStorage.getItem('loggedInAs')=='PHARMACY'
  }

  saveCategory() {
    this.submitted = true;
    if (this.categoryForm.invalid) {
      return false;
    }
    else {
      var body = {
        "catagory": this.categoryForm?.value?.catagory,
        "addressId": this.addressId,
        "description": this.categoryForm?.value?.Description,
        "isPharmacy": this.isPharmacy ? 'Y' : 'N'
      }
      if (this.isEdit) {
        body["catagoryId"] = this.catergory?.catagoryId
      }
      this.inventoryService.saveCategory(body).subscribe(res => {
        this.isEdit ? this.toastrService.success('Category Updated Successfully.') : this.toastrService.success('Category Added Successfully.');
        this.categoryForm.reset();
        this.activeModal.close('');
        this.submitted = false;
      }, error => {
        this.toastrService.error(error?.['error']?.['message'])
      })

    }
  }
}
