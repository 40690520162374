import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject, observable, Observable } from 'rxjs'
import { HttpClient, HttpHandler, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ApicallsService } from '../apicalls.service';

@Injectable({
  providedIn: 'root'
})
export class DiagnosticService {
  baseURL = environment.doctorBaseUrl;
  patientBaseURL = environment.patientBaseUrl;
  diagnosticBaseURL = environment.diagnosticBaseUrl;
  constructor(private globalStorage: GlobalStorageService, private http: HttpClient, private ToastrService: ToastrService, private apiServices: ApicallsService) { 
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
    this.baseURL = environment.doctorBaseUrl1;
    this.patientBaseURL = environment.patientBaseUrl1;
    this.diagnosticBaseURL = environment.diagnosticBaseUrl1;
    }
  }

 

  headders = { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) }

  registerDiagnostic(diognostics) {
    return this.apiServices.post(this.diagnosticBaseURL + "register", diognostics, this.headders)
  }

  LoginDiagnostic(diognostics) {
    return this.apiServices.post(this.diagnosticBaseURL + "api/login", diognostics, this.headders)
  }

  rescheduleDcAppointment(appointment) {
    var reschedule = {
      "diagnosticcenterAppointmentId": appointment.diagnosticcenterAppointmentId,
      "appointmentStatus": "ENTITY_RESCHEDULED",
      "diagnosticcenterAddresId": parseInt(this.globalStorage.getItem('forUserId')),
      "reason": 'test',
      "scheduleHoursId": appointment.schedulehoursId
    }
    return this.apiServices.post(this.diagnosticBaseURL + 'appointment/updateAppointmentRequestsUponDiagnosticcenterChoice', reschedule, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), "accept": "*/*" }) })
  } 

  cancelDcAppointment(appointment) {
    var cancel = {
      "diagnosticcenterAppointmentId": appointment.diagnosticcenterAppointmentId,
      "appointmentStatus": "ENTITY_CANCELLED",
      "diagnosticcenterAddresId": parseInt(this.globalStorage.getItem('forUserId')),
      "reason": 'test',
      "scheduleHoursId": appointment.schedulehoursId
    }
    return this.apiServices.post(this.diagnosticBaseURL + 'appointment/updateAppointmentRequestsUponDiagnosticcenterChoice', cancel, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), "accept": "*/*" }) })
  }

  deleteDiagnosticAppointment(appointmentId) {
    return this.apiServices.delete(this.diagnosticBaseURL + 'appointment/delete/' + appointmentId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }


  LogOut() {

  }
  existingUsername(username): Observable<any> {
    return this.apiServices.get(this.diagnosticBaseURL + 'isuserNameExistOrNot?userName=' + username, {})
  }
  existingEmail(email): Observable<any> {
    return this.apiServices.get(this.diagnosticBaseURL + 'validate/email?email=' + email, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }
  existingPhone(phnumber) {
    return this.http.get(this.diagnosticBaseURL + 'validate/phonenumber?phonenumber=' + phnumber, {})
  }


  //  ==============================================================================
  private doctorDetailsSubject = new Subject<any>();
  Observable = this.doctorDetailsSubject.asObservable();

  private patientDetailsSubject = new Subject<any>();
  patientObservable = this.patientDetailsSubject.asObservable();

  private appointmentSubject = new Subject<any>();
  appointmentObservable = this.appointmentSubject.asObservable();

  private loginUserSubject = new Subject<any>();
  loginUsertObservable = this.loginUserSubject.asObservable();

  private myAssociansSubject = new Subject<any>();
  myAssociansObservable = this.myAssociansSubject.asObservable();

  private rescheduleAppointmentSubject = new Subject<any>();
  rescheduleAppointmentObservable = this.rescheduleAppointmentSubject.asObservable();

  private refreshSubject = new Subject<any>();
  refreshObservable = this.refreshSubject.asObservable();

  OpenSideNave(value: any) {
    this.doctorDetailsSubject.next(value);
  }
  OpenPatientSideNave(value: any) {
    this.patientDetailsSubject.next(value);
  }

  OpenAppontment(value: any) {
    this.appointmentSubject.next(value);
  }

  getLoginUser(value: any) {
    this.loginUserSubject.next(value)
  }

  reloadCurrentAppointment() {
    this.rescheduleAppointmentSubject.next()
  }

  updateMyassocians() {
    this.myAssociansSubject.next()
  }

  refreshSideMenu() {
    this.refreshSubject.next()
  }

  //-----------Doctor Profile------------

  getPatientAddress(request: any, userId: any): Observable<any> {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiServices.post(this.patientBaseURL + 'address/get',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId }), observe: 'response' })
  }



  getInvoiceDetails(invoice) {
    return this.apiServices.post(this.baseURL + 'invoice/criteria', invoice, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }


  saveAssociation(association) {
    return this.apiServices.post(this.baseURL + 'association', association, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })   //
  }

  getAssociationDetails(doctorId) {
    return this.apiServices.get(this.baseURL + 'all/associations/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  // getPatientByMobile(cellNumber) {
  //   return this.apiServices.get(this.baseURL + 'searchUserByCellNumber?cellNumber=' + cellNumber, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  // }


  getUsersList(id) {
    if (id != 'undefined' || id != 'null') {
      return this.apiServices.get(this.diagnosticBaseURL + 'diagnosticCenterPatientController/getPatient/' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
    }
  }
  saveUser(body) {
    return this.apiServices.post(this.diagnosticBaseURL + 'diagnosticCenterPatientController/save', body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  // getPatientByMobile(body) {
  //   return this.apiServices.post(this.diagnosticBaseURL + 'diagnosticCenterPatientController/save', body,
  //     { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  // }

  saveWalkinUser(body){
    return this.apiServices.post(this.diagnosticBaseURL+'diagnosticCenterPatientController/save',body,
    { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }

  getPatientByMobile(body){
    return this.apiServices.get(this.diagnosticBaseURL + 'diagnosticCenterPatientController/searchPatientInformationByPatientPhoneNumberUsingGET?',body)
  }

  deleteDoctorAssociation(associationId) {
    return this.apiServices.delete(this.baseURL + 'association/' + associationId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  navigationDetails() {
    this.getPersonalDetails(this.globalStorage.getItem('doctorId')).subscribe(res => {
      var userdata = {
        'personalData': res['data'],
        'lastLogin': JSON.parse(this.globalStorage.getItem('lastLogin'))
      }
      this.getLoginUser(userdata)
    }, error => {
      this.ToastrService.error("Error in getting Personal details");
    })
  }


  getDoctorDetailsById(id) {
    return this.apiServices.get(this.baseURL + 'id/' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  // getPatientByMobile(id) {
  //   return this.apiServices.get(this.baseURL + 'id/' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  // }
  uploadProfilePic(formData, doctorId): Observable<any> {  //multipart/form-data
    // let params = new HttpParams().set('doctorId', doctor.doctorId);
    return this.apiServices.post(this.baseURL + 'profilepic/' + doctorId, formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }

  updateDoctorProfile(request) {
    return this.apiServices.put(this.baseURL + '/update', request, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }





  deleteDoctorAddress(addressId) {
    return this.apiServices.delete(this.baseURL + 'address/' + addressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }


  saveFee(feeDetails) {
    return this.apiServices.post(this.baseURL + 'feeDetails', feeDetails, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getDoctorFeeDetails(doctorId) {
    // let params = new HttpParams().set('doctorId', doctorId);
    return this.apiServices.get(this.baseURL + 'feeDetails/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  deleteDoctorFee(feeId) {
    return this.apiServices.delete(this.baseURL + 'feeDetails/' + feeId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  deleteDoctorEducation(certificationId) {
    return this.apiServices.delete(this.baseURL + 'certification/' + certificationId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }


  uploadDoctorProfile(doctor): Observable<any> {
    return this.apiServices.post(this.baseURL + 'register', doctor, { headers: new HttpHeaders({ observe: 'response', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  savePersonalDetails(doctorPersonaldetails): Observable<any> {
    return this.apiServices.post(this.baseURL + 'personaldetail', doctorPersonaldetails, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })   //
  }

  getPersonalDetails(doctorId) {
    if (doctorId != -1 || doctorId != undefined || doctorId != null) {
      return this.apiServices.get(this.baseURL + 'personaldetail/doctorid/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
    }
  }


  getAddressDetails(doctorId) {
    return this.apiServices.get(this.baseURL + 'address/doctorid/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getCertificationDetails(doctorId) {
    return this.apiServices.get(this.baseURL + 'certification/doctorid/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  saveCertificationDetails(certification): Observable<any> {
    return this.apiServices.post(this.baseURL + 'certification', certification, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })   //
  }
  saveAddressDetails(address): Observable<any> {
    return this.apiServices.post(this.baseURL + 'address/save', address, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })   //
  }

  //-----------Admin Doctors------------
  getAllDoctors(): Observable<any> {
    var newRegistraion = { "doctorFlags": ["NEWLY_REGISTERED"] }
    return this.apiServices.post(this.baseURL + 'all/flags', newRegistraion, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAllVerifiedDoctors(): Observable<any> {
    var verified = { "doctorFlags": ["VERIFIED"] }
    return this.apiServices.post(this.baseURL + 'all/flags', verified, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAllApprovedDoctors(): Observable<any> {
    var verified = { "doctorFlags": ["APPROVED"] }
    return this.apiServices.post(this.baseURL + 'all/flags', verified, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  //-----------Admin Patients------------
  getAllPatients(): Observable<any> {
    var newRegistraion = { "patientFlags": ["NEWLY_REGISTERED"] }
    return this.apiServices.post(this.patientBaseURL + 'all/flags', newRegistraion, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAllVerifiedPatients(): Observable<any> {
    var verified = { "patientFlags": ["VERIFIED"] }
    return this.apiServices.post(this.patientBaseURL + 'all/flags', verified, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAllApprovedPatients(): Observable<any> {
    var verified = { "patientFlags": ["APPROVED"] }
    return this.apiServices.post(this.patientBaseURL + 'all/flags', verified, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  //-----------BaseShedule------------

  validateBaseschedule(sheduleDate) {
    var obj = {
      "doctorId": this.globalStorage.getItem('doctorId'),
      "fromDate": "",
      "monthNumber": 0,
      "scheduleCalenderEnum": "CURRENT_MONTH",
      "scheduleDate": sheduleDate,
      "toDate": "",
      "weekOfYear": 0,
      "year": 0
    }
    return this.apiServices.post(this.baseURL + 'schedule/all/bycriteria', obj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }


  getAppointmentByschedulehours(schedulehours) {
    return this.apiServices.get(this.baseURL + 'appointment/all/schedulehours/' + schedulehours, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  saveScheduleHours(reqObj) {
    return this.apiServices.post(this.baseURL + 'schedule/save', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  deleteScheduleHours(schedulehoursId) {
    return this.apiServices.delete(this.baseURL + '/schedulehours/id/' + schedulehoursId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  createBaseSchedule(scheduler): Observable<any> {
    return this.apiServices.post(this.baseURL + 'baseschedule/save', scheduler, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getDoctorBaseschedule(docid, month) {  //CURRENT_WEEK, CURRENT_DAY, CURRENT_MONTH
    var obj = {
      "doctorId": this.globalStorage.getItem('doctorId'),
      // "fromDate": "",
      "monthNumber": month,
      // "scheduleCalenderEnum": "CURRENT_MONTH",
      // "toDate": "",
      // "weekOfYear": 0,
      // "year": 0
    }
    return this.apiServices.post(this.baseURL + 'schedule/all/bycriteria', obj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getDoctorBaseSheduleView(docid) {
    return this.apiServices.get(this.baseURL + 'baseschedule/all/' + docid, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  //-----------APPINMENTS------------


  rescheduleAppointment(appointment) {
    var reschedule = {
      "appointmentId": appointment.appointmentId,
      "appointmentStatus": "ENTITY_RESCHEDULED",
      "doctorId": parseInt(this.globalStorage.getItem('doctorId')),
      "reason": null,
      "scheduleHoursId": appointment.schedulehoursId
    }
    return this.apiServices.post(this.baseURL + 'appointment/request/update/appointmentstatus', reschedule, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  acceptAppointment(appointment) {
    return this.apiServices.post(this.baseURL + 'appointment/request/accept/' + appointment.appointmentId, appointment, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  cancelAppointment(appointment) {
    var cancel = {
      "appointmentId": appointment.appointmentId,
      "appointmentStatus": "ENTITY_CANCELLED",
      "doctorId": parseInt(this.globalStorage.getItem('doctorId')),
      "reason": null,
      "scheduleHoursId": appointment.schedulehoursId
    }
    return this.apiServices.post(this.baseURL + 'appointment/request/update/appointmentstatus', cancel, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  appointmentsAdvanceSearch(appointment: any) {
    return this.apiServices.post(this.baseURL + 'appointment/search', appointment, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }



  // getPatientAllPrecriptions(patientId){
  //          let params = new HttpParams();
  //          params=params.set('patientId',patientId)
  //          return this.apiServices.get(this.baseURL+'prescription/view/all/',{params:params, headers: new HttpHeaders({'Content-Type': 'application/json', 'user-session':JSON.parse(this.globalStorage.getItem('userSession')),"accept": "*/*"})})
  // }


  getPatientAllAppointmentNotes(appointment) {
    let params = new HttpParams();
    params = params.set('doctorId', appointment.doctorId)
    params = params.set('patientId', appointment.patientId)
    return this.apiServices.get(this.baseURL + 'appointment/previousAppointmentNotes/', { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getPatientAppointmnetNotesByAppointmentId(appointment) {
    let params = new HttpParams();
    params = params.set('appointmentId', appointment.appointmentId)
    // params=params.set('patientId',appointment.patientId)
    return this.apiServices.get(this.baseURL + 'appointment/appointmentnotes/', { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }




  // acceptPatientRequist(patient){
  //   return this.apiServices.post(this.baseURL+'patient/request/accept/' +patient,{ headers: new HttpHeaders({'Content-Type': 'application/json', 'user-session':JSON.parse(this.globalStorage.getItem('userSession')),"accept": "*/*"})})
  // }
  // rejectPatientRequist(patient){
  //   return this.apiServices.post(this.baseURL+'patient/request/reject/'+patient,{ headers: new HttpHeaders({'Content-Type': 'application/json', 'user-session':JSON.parse(this.globalStorage.getItem('userSession')),"accept": "*/*"})})
  // }

  // getPatientRequests(doctorId:number){
  //   return this.apiServices.get(this.baseURL+'patient/all/newrequest/'+doctorId,{ headers: new HttpHeaders({'Content-Type': 'application/json', 'user-session':JSON.parse(this.globalStorage.getItem('userSession')),"accept": "*/*"})})
  // }


  //------------Prescription---------//

  savePresription(precription) {
    return this.apiServices.post(this.patientBaseURL + 'prescription/fromdoctor/save', precription, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }




  //-----------Dashboard------------

  myAppoinments(docid) {  // "PREVIOUS", "CURRENT", "UPCOMING", "CANCELLED"
    var reqObj = {
      "doctorId": docid,
      "patientId": null,
      "viewAppointmentEnum": ["CURRENT", "UPCOMING",]
    }
    return this.apiServices.post(this.baseURL + 'appointment/view/doctorself', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  myALLAppoinments(docid) {  // "PREVIOUS", "CURRENT", "UPCOMING", "CANCELLED"
    var reqObj = {
      "doctorId": docid,
      "patientId": null,
      "viewAppointmentEnum": ["CURRENT", "UPCOMING", "PREVIOUS", "CANCELLED"]
    }
    return this.apiServices.post(this.baseURL + 'appointment/view/doctorself', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getMyReports(doctorId) {
    //let params = new HttpParams();
    // params=params.set('doctorId',doctorId)
    return this.apiServices.get(this.baseURL + 'myreport/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }


  getCurrentAppoinments(docid, p) {  // "PREVIOUS", "CURRENT", "UPCOMING", "CANCELLED"
    var reqObj = {
      "doctorId": docid,
      "patientId": null,
      "maxResult": 10,
      "pageNum": p,
      "viewAppointmentEnum": ["CURRENT"]
    }
    return this.apiServices.post(this.baseURL + 'appointment/view/doctorself', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getUpcomingAppoinments(docid, p) {
    var reqObj = {
      "doctorId": docid,
      "patientId": null,
      "maxResult": 12,
      "pageNum": p,
      "viewAppointmentEnum": ["UPCOMING"]
    }
    return this.apiServices.post(this.baseURL + 'appointment/view/doctorself', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getPreviousAppoinments(docid, p) {
    var reqObj = {
      "doctorId": docid,
      "maxResult": 12,
      "pageNum": p,
      "patientId": null,
      "viewAppointmentEnum": ["PREVIOUS"]
    }
    return this.apiServices.post(this.baseURL + 'appointment/view/doctorself', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getCanceledAppoinments(docid, p) {
    var reqObj = {
      "doctorId": docid,
      "patientId": null,
      "maxResult": 12,
      "pageNum": p,
      "viewAppointmentEnum": ["CANCELLED"]
    }
    return this.apiServices.post(this.baseURL + 'appointment/view/doctorself', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }


  getDoctorRating(doctorId) {
    return this.apiServices.get(this.baseURL + 'rating/get/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAppointmentById(appointmentId) {
    return this.apiServices.get(this.baseURL + 'appointment/id/' + appointmentId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAllBranches(id) {
    return this.apiServices.post(this.diagnosticBaseURL + 'getAssoctedDiagnosticCenter?diagnosticCenteradminId=' + id, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  updateBranch(body) {
    return this.apiServices.put(this.diagnosticBaseURL + 'updateDiagnosticcenteruserbranch', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  searchUserName(addressId, name) {
    return this.apiServices.get(this.diagnosticBaseURL + "diagnosticCenterPatientController/searchPatientInformation?diagnosticCenterAddressId=" + addressId + "&patientName=" + name, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getReport(appointmentId,dcAddressId){
    return this.apiServices.get(this.diagnosticBaseURL+'testReportByAppointmentId?appointmentId='+appointmentId+'&diagnosticCenterAddressId='+dcAddressId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  } 
  getHospitalDcReport(hospitalAddressId,prescriptionId,userId,){
    return this.apiServices.get(this.diagnosticBaseURL+'findReportByHospitalAddressIdAndUserId?hospitalAddressId='+hospitalAddressId+'&hospitalprescriptionId='+prescriptionId+'&userId='+userId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getRoles(){
    return this.apiServices.get(this.diagnosticBaseURL+'roles',{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
}
