import { GlobalStorageService } from 'src/app/global-storage.service';
import { formatDate } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NoMatch } from 'src/app/_helpers/must_match.validators';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { PatientService } from '../../services/patient.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare global {
  interface Window {
    intlTelInputGlobals: any; // Use 'any' or a more specific type if known
  }
}

@Component({
  selector: 'app-patient-primary-details-screen',
  templateUrl: './patient-primary-details-screen.component.html',
  styleUrls: ['./patient-primary-details-screen.component.css']
})
export class PatientPrimaryDetailsScreenComponent implements OnInit {
  @Input() patientId: any;
  @Input() spouseId: any;
  @Input() isPrimaryRegistered: boolean = false;
  @Input() details: any;
  @Output() registerId = new EventEmitter<any>();
  @Output() patientDetails = new EventEmitter<any>();
  @Output() closeModal=new EventEmitter<any>();
  parentuserId: string;
  selectedValue;
  registerForm: FormGroup;
  referralTypeArray = ['Doctor', 'Social Media', 'Other'];
  genderArray = ['Male', 'Female'];
  countryArray = ['IN', 'US'];
  postalLocationArray = [];
  maxDate = new Date();
  cellNumber: string;
  emergency: string;
  referral: string;

  @ViewChild('cellInput') cellInput: ElementRef;
  @ViewChild('emInput') emInput: ElementRef;
  @ViewChild('refInput') refInput: ElementRef;

  referralType: string | null = null; // Initialize as null


  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder, private toastrService: ToastrService, private ivfService: IvfService,private clinicServices:ClinicService,
    private patientService: PatientService,private activeModal:NgbActiveModal) { }

  ngOnInit() {
    this.initializeForm();
    this.closeModal.emit()
    if (this.isPrimaryRegistered) {
      if(this.spouseId){
        this.getUserDetails(this.spouseId);
      }else{
        this.patchSpouseFromParent();
      }
      
    } else {
      if (this.patientId) {
        this.getUserDetails(this.patientId);
      }
    }

    this.filteredOptionsD = this.registerForm.get('referralTypeValue').valueChanges.pipe(
      startWith(''),
      map(value => this._filterD(value || '')),
    );
  }

  patchSpouseFromParent() {
    if(this.details){
      const keysToCopy = ["country", "postalLocation", "state", "street1", "street2", "zip", "city"];

      // Create a new object and copy the specified keys
      const newObject = {};
      keysToCopy.forEach(key => {
        if (key in this.details?.address) {
          newObject[key] = this.details?.address[key];
        }
      });
      this.registerForm.patchValue({
        emergenyContactName: this.details?.firstName + ' ' + this.details?.lastName,
        emergenyContactNumber: this.details?.cellNumber,
        referalId: this.details?.referalId,
        referralType: this.details?.referralType,
        referralTypeValue: this.details?.referralTypeValue,
        referralInfo: this.details?.referralInfo,
        address: newObject,

      });

      this.getPostalCode();

    }
  }


  getSelectedValue(selectedOption: any) {
    if (selectedOption) {
      // console.log(selectedOption);
      this.registerForm.patchValue({
        referalId: selectedOption?.id,
        referralCellNumber: selectedOption?.cellNumber
      })

    } else {
      console.log("Obj not found.");
    }
  }

  ngAfterViewInit() {
  }

  initializeForm() {
    this.registerForm = this.formBuilder.group({
      active: "Y",
      address: this.formBuilder.group({
        active: "Y",
        addressId: null,
        city: [''],
        contactName: [''],
        country: [''],
        postalLocation: [''],
        state: [''],
        street1: [''],
        street2: [''],
        userId: null,
        zip: ['']
      }),
      cellNumber: ['', Validators.required],
      // doctorName: [''],
      emailId: [''],
      DOB:[''],
      gender:[''],
      emergenyContactName: [''],
      emergenyContactNumber: [''],
      firstName: ['', Validators.required],
      homeNumber: [''],
      ivfaddressId: null,
      ivfspouseId: null,
      ivfuserId: null,
      lastName: ['', Validators.required],
      middleName: [''],
      officeNumber: [''],
      // parentuserId: null,
      // primary: [''],
      preferredlanguages: "HOME_NUMBER",
      primaryContact: "HOME_NUMBER",
      primaryUser: "",
      reason: [''],
      referalId: null,
      referralCellNumber: [''],
      referralInfo: [''],
      referralType: [''],
      referralTypeValue: [''],
      single: "N",
      spouseName: null,
      userId: null,
      userName: [''],
      userReferenceId: null,
      ivfPatientId:null
    },
      {
        validators: [NoMatch('firstName', 'lastName')]
      });
  }

  filteredOptionsD: Observable<string[]>;
  optionsD = [];

  checkEmail() {
    if(!this.isPrimaryRegistered){
    const emailId = this.registerForm.value.emailId;
    if (emailId.length>0 && this.isValidEmail(emailId)) {
      this.ivfService.checkEmailExists(emailId).subscribe(res => {
        if (res.data) {
          this.toastrService.error('Email ID Already Exists.');
          this.registerForm.patchValue(
            {
              emailId: ''
            }
          )
        }
      })
    }else if(emailId.length>0){
      this.toastrService.error('Enter a Valid Email');
    }}
  }
  isValidEmail(email){
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  searchExisting(value) {
    if (this.registerForm.value.referralTypeValue) {
      this.ivfService.typeSearchWithElasticSearch(this.registerForm.value.referralType, this.registerForm.value.referralTypeValue).subscribe(res => {
        // console.log('response:', res)
        this.optionsD = [];
        for (var sp of res?.['data']) {
          let obj = {
            id: sp.id,
            value: sp.value,
            cellNumber: sp.cellNumber
          }
          // var fullName = obj;
          this.optionsD.push(obj);
        }
        this.filteredOptionsD = this.registerForm.get('referralTypeValue').valueChanges.pipe(
          startWith(''),
          map(value => this._filterD(value || '')),
        );
      }, error => {
        this.toastrService.error(error?.['error']?.['message'])
      })
    }
  }

  private _filterD(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsD.filter(option => option?.value?.toLowerCase().includes(filterValue));
  }
  countryCodeSelected; postalLocations; zip;
  zipEntered() {
    this.selectedValue = null;
    this.countryCodeSelected = null;
  }
  latitude; longitude
  drpdownSelectionForAddress(e) {
    this.latitude = e?.latitude
    this.longitude = e?.longitude
  }
  percChanged(zip) {
      this.zip = zip;
      this.countryCodeSelected = null;
      this.registerForm.get('address').patchValue({
        country: '',
        city: '',
        state: '',
        postalLocation: ''
      });
    if(this.zip?.length < 6){
      this.postalLocations = [];
    }
  }
  countryCodeEntered(cc) {
    console.log('ccc',cc)
    this.postalLocations = [];
    this.patientService.getPincodeDetails(this.zip, cc).subscribe(res => {
      if (res['data'].length == 0) {
        this.registerForm.get('address').patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.toastrService.error("Incorrect pincode")
      }
      this.postalLocations = res['data'];
      // this.latitude = res['result'][0]['latitude'];
      // this.longitude = res['result'][0]['longitude'];
      this.registerForm.get('address').patchValue({
        country: res['data'][0]['country'],
        city: res['data'][0]['province'],
        state: res['data'][0]['state'],

      })
    })
  }
  onSubmit() {
    // this.registerForm.get('cellNumber').enable();
    // this.registerForm.get('single').enable();
    // if (this.registerForm.get('firstName').valid && this.registerForm.get('lastName').valid) {
    //   const body = this.registerForm.value;
    //   if(!this.patientId && !this.isPrimaryRegistered && this.registerForm.get('cellNumber').value.length !=10){
    //     this.toastrService.error('Mobile Number must be greater than 10 Digits');
    //     return
    //   }
    //   body.cellNumber = this.getMobileNumbers(this.cellInput);
    //   body.emergenyContactNumber = this.getMobileNumbers(this.emInput);
    //   body.referralCellNumber = this.getMobileNumbers(this.refInput);
    //   body.ivfaddressId = this.globalStorage.getItem('ivfAddressId');
    //   body.ivfuserId = this.globalStorage.getItem('ivfUserId');

    //   if (body.userName.length == 0) {
    //     if (this.registerForm.value.emailId === '' || this.registerForm.value.emailId === null) {
    //       body.userName = body.cellNumber;
    //       body.emailId = body.cellNumber;
    //     } else {
    //       body.userName = this.registerForm.value.emailId;
    //     }
    //   }

    //   body.homeNumber = body.cellNumber;
    //   body.address.contactName = body.firstName + ' ' + body.lastName;
    //   body.officeNumber = 'test';
    //   if (!this.referralTypeArray.includes(body.referralType)) {
    //     body.referralType = null;
    // }
    //   if (!this.isPrimaryRegistered) {
    //     body.primaryUser = 'Y';
    //     this.registerUser('registerUser', body);
    //   } else {
    //     body['parentuserId'] = this.patientId;
    //     body.primaryUser = 'N';
    //     this.registerUser('registerSpouse', body);
    //   }
    // } else {
    //   this.toastrService.error('Please fill all the required fields');
    // }
    const date = formatDate(this.registerForm.value.DOB, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    var reqBody = {
      "active": "Y",
      "address": {
        "active": "Y",
        "city": this.registerForm.get('address').value.city,
        "contactName": this.registerForm.value.firstName.charAt(0).toUpperCase()+this.registerForm.value.firstName.slice(1) + " " + this.registerForm.value.lastName.charAt(0).toUpperCase()+this.registerForm.value.lastName.slice(1),
        "country": this.registerForm.get('address').value.country,
        "latitude": this.latitude,
        "longitude": this.longitude,
        "postalLocation": this.registerForm.get('address').value.postalLocation,
        "state": this.registerForm.get('address').value.state,
        "street1":this.registerForm.get('address').value.street1,
        "street2": this.registerForm.get('address').value.street2,
        "zip":  this.registerForm.get('address').value.zip,
      },
      "cellNumber": this.patientDts?.cellNumber,
      "emergenyContactNumber":'',
      "emailId": this.registerForm.value.emailId,
      "firstName": this.registerForm.value.firstName.charAt(0).toUpperCase()+this.registerForm.value.firstName.slice(1),
      "patientReferenceId":this.patientId,
      "hospitaladdressId": this.globalStorage.getItem('hospitalAddressId'),
      "emergencyContact": '',
      'ipd':'N',
      "gender": this.registerForm.value.gender,
      "patientId":this.patientDts?.patientId,
      "lastName": this.registerForm.value.lastName.charAt(0).toUpperCase()+this.registerForm.value.lastName.slice(1),
      "patientName": this.registerForm.value.firstName.charAt(0).toUpperCase()+this.registerForm.value.firstName.slice(1) + " " + this.registerForm.value.lastName.charAt(0).toUpperCase()+this.registerForm.value.lastName.slice(1),
      "personaldetail": {
        "bloodgroup": '',
        "dob": date.concat('Z'),
        "ethinicity": " ",
        "eyeColor": " ",
        "gender": this.registerForm.value.gender,
        "height": " ",
        "highestDegree": " ",
        "martialstatus": " ",
        "nationality": " ",
        "preferredlanguages": " "
      },
      "userName": this.registerForm.value.userName
    }
    this.clinicServices.saveUser(reqBody).subscribe(res => {
      this.closeModal.emit()
      this.activeModal.close()
      this.toastrService.success('Details Updated')
    },error=>{
      this.toastrService.error(error?.error?.message)
    })
  }

  registerUser(url, body) {
    this.ivfService.postWithSession(url, body).subscribe(res => {
      this.toastrService.success('User Registered Successfully');
      this.registerId.emit(res['body']?.['data']?.['userId']);
      this.patientDetails.emit(body);
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    });
  }



  isEdit:boolean=false;patientDts
  getUserDetails(id) {
    
    this.clinicServices.findUserByHospitalAddressIdAndUserId(this.globalStorage.getItem('hospitalAddressId'), id).subscribe(res => {
      const patientDetails = res['data'];
      this.patientDts=res['data']
      this.patchForm(patientDetails);
      this.getPostalCode();
      this.onReferralTypeChange();
      this.patchForm(patientDetails);
      this.isEdit=true;
      this.registerForm.get('cellNumber').disable();
      this.registerForm.get('single').disable();
      
      // if (this.isPrimaryRegistered) {
      //   this.parentuserId = patientDetails['parentuserId'];
      // }
    })
    
  }

  patchForm(details) {
    const date2 = formatDate(details?.dob, "yyyy-MM-dd", 'en-us');
    this.registerForm.patchValue(details);
    this.registerForm.patchValue({
      emergenyContactName:details?.emergencyContact,
      emergenyContactNumber:details?.emergencyContactNumber,
      DOB:date2
    })
  }

  getPostalCode() {
    const countryValue = this.registerForm.get('address').get('country').value;
    const postalCode = this.registerForm.get('address').get('zip').value;
    if(postalCode.toString().length>0){
      if (postalCode.toString()) {
        this.clinicServices.getPincodeDetails(postalCode, countryValue).subscribe(res => {
          if (!res?.data) {
            // Example values for city and state
  
            // Patching the values
            this.postalLocations = []
            this.registerForm.get('address').patchValue({
              city: '',
              state: '',
              postalLocation: ''
            });
  
            this.toastrService.error("Incorrect pincode")
          }
          this.postalLocations = res['data'];
          // this.latitude = res['result'][0]['latitude'];
          // this.longitude = res['result'][0]['longitude'];
          // Example values for city and state
          const newCity = res['data'][0]['district'];
          const newState = res['data'][0]['state'];
  
          // Patching the values
          this.registerForm.get('address').patchValue({
            city: newCity,
            state: newState
          });
  
          this.registerForm.get('address').patchValue({
            postalLocation: this.postalLocations?.[0]?.['postalLocation']
          })

          if (this.isPrimaryRegistered) {
            if(!this.spouseId){
              this.registerForm.patchValue({
                postalLocation: this.details?.address?.postalLocation
              })
            }
            console.log(this.details?.address?.postalLocation)
            
          }
  
        })
      } else {
        this.toastrService.error("Pincode must be 6 Digits")
  
      }
    }
  }

  checkPhNumber() {
    // console.log(num)
    if(!this.isPrimaryRegistered){
    if (this.registerForm.value.cellNumber.length == 10) {
      this.ivfService.checkMobileNumberExists(encodeURIComponent(this.getMobileNumbers(this.cellInput))).subscribe(res => {
        if (res.message == "Phone number already exists ") {
          this.toastrService.error('Mobile Number Already Exists');
          this.registerForm.patchValue(
            {
              cellNumber: ''
            }
          )
        }
      })
    } else {
      this.toastrService.error('Mobile Number must be 10 digits');
      // this.registerForm.patchValue(
      //   {
      //     cellNumber: ''
      //   }
      // )
    }}
  }

  validateNumber(event: KeyboardEvent): boolean {
    const charCode = event.which ? event.which : event.keyCode;

    // Allow only numeric input (0-9), backspace, delete, arrow keys
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  getMobileNumbers(inputElement) {
    if (inputElement && inputElement.nativeElement) {
      // Make sure intlTelInput is initialized on the element
      const intlTelInputInstance = window.intlTelInputGlobals.getInstance(inputElement.nativeElement);
      if (intlTelInputInstance) {
        const number = intlTelInputInstance.getNumber();
        if (intlTelInputInstance) {
          // const countryData = intlTelInputInstance.getSelectedCountryData();
          return number;

        } else {
          console.error('Invalid phone number : ', number);
          return '';
        }
      } else {
        console.error('intlTelInput not initialized');
        return ''
      }
    } else {
      console.error('Input element is not provided or does not exist');
      return '';
    }
  }

  onReferralTypeChange(): void {
    this.referralType = this.registerForm.get('referralType').value;
    this.registerForm.get('referralTypeValue').setValue('');
    this.registerForm.get('referralCellNumber').setValue('');
    this.registerForm.get('referralInfo').setValue('');
  }
}
