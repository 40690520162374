<div class="modal-header">
    <h5 class="modal-title">
        <i aria-hidden="true" class="fa fa-user-plus"></i> Create Message
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-bodycure pt-0">
    <div class="container user-curetab">
        <form [formGroup]="messageForm">
            <div class="row mt-2 formgropnew">
      
                <div class="col-xl-4 col-lg-4 col-md-4 col-xs-4 form-group mb-3 mt-2">
                    <div class="form-group">
                        <label>To Department <span class="text-danger">*</span></label>
                        <mat-select placeholder="Select..." multiple [(ngModel)]="selectedDepartment" class="form-control" formControlName="department" (selectionChange)="drpdownSelectionDepartment(selectedDepartment)"
                            [ngClass]="{ 'is-invalid': (submitted || messageForm.get('department').touched) && messageForm.get('department').errors}">
                            <mat-option *ngFor="let department of departments" [value]="department.departmentName"
                                (click)="getSelectedDept(department)">
                                {{ department.departmentName }}
                            </mat-option>
                        </mat-select>
                        <div *ngIf="(submitted || messageForm.get('department').touched) && messageForm.get('department').errors"
                            class="invalid-feedback">
                            <div *ngIf="messageForm.get('department').errors.required">Please Select Department.</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-4 col-xs-4 form-group mb-3 mt-2">
                    <label for="" class="">To Role</label>
                    <mat-select class="form-control" name="item" formControlName="role" 
                      (selectionChange)="drpdownForSelectionRole(selectedRole,$event)" id="role"  placeholder="Select"
                      [ngClass]="{
                                'is-invalid': (submitted || messageForm.get('role').touched) && messageForm.get('role').errors
                                }">
                      <mat-option *ngFor="let item of allRoles" [value]="item.roleName" (click)="selectedRoles(item)">
                        {{item.roleName}}
                      </mat-option>
                    </mat-select>
                  </div>
                <div class="col-xl-4 col-lg-4 col-md-6 col-xs-4 form-group mb-3 mt-2">
                    <div class="form-group">
                        <label>To Persons <span class="text-danger">*</span></label>
                        <mat-select class="form-control" name="item" formControlName="name" [(ngModel)]="selectedPerson"
              (selectionChange)="drpdownForSelection(selectedPerson)" id="person" multiple placeholder="Select"
              [ngClass]="{
                        'is-invalid': (submitted || messageForm.get('name').touched) &&  messageForm.get('name').errors
                        }">
              <mat-option *ngFor="let item of usersList" [value]="item" (click)="selectedPersonData(item)">
                {{item.firstName}} {{item.lastName}}
              </mat-option>
            </mat-select>
                        <div *ngIf="(submitted || messageForm.get('name').touched) && messageForm.get('name').errors"
                            class="invalid-feedback">
                            <div *ngIf="messageForm.get('name').errors.required">Please Select Name.</div>
                        </div>
                    </div>
                </div>        
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3">
                    <label for="" class="col-form-label">Message<b>*</b></label>
                    <textarea  class="form-control"  rows="5" maxlength="250" cols="80" name="messages" formControlName="message"
                    placeholder="Enter Message"></textarea>
                    <div *ngIf="(submitted || messageFormControls.message.touched) && messageFormControls.message.errors"
                        class="invalid-feedback">
                        <div *ngIf="messageFormControls.message.errors.required">Message is Required</div>
                    </div>
                </div>        
            </div>
            <button type="button" class="btn btn-danger float-right btn-sm ml-2" (click)="activeModal.close('')"> Cancel </button>
            <button type="button" class="btn btn-success float-right btn-sm " (click)="createMessage()" [disabled]="!messageForm.valid"> Send </button>
        </form>
    </div>
</div>
