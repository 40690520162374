import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import{DoctorService} from '../../AppServices/doctor.service'
import{ToastrService} from 'ngx-toastr'
import { MatPaginator, MatSidenav, MatSort, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-doctor-list',
  templateUrl: './doctor-list.component.html',
  styleUrls: ['./doctor-list.component.css']
})
export class DoctorListComponent implements OnInit {

  public displayedColumns: string[] = ['select','firstName','middleName','lastName', 'emailId','Action'];
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  selection = new SelectionModel<any>(true, []);

  newRegDoctors
  p1=1;

  @ViewChild('doctorDetails',{static:false}) public  doctorDetails: MatSidenav;


  constructor(private doctorService:DoctorService,private ToastrService:ToastrService) { }

  ngOnInit(): void {
    this.LoadNewlyRegistered();
  }
  
  NewlyRegDoctors:any
  BindingNewlyRegDoctors
  LoadNewlyRegistered(){
    this.doctorService.getAllDoctors().subscribe(res=>{ 
      if(res["data"]!=null){
      this.NewlyRegDoctors=new MatTableDataSource(res["data"])
      this.BindingNewlyRegDoctors= this.NewlyRegDoctors.filteredData.NEWLY_REGISTERED;
      this.BindingNewlyRegDoctors.paginator = this.paginator;
      this.BindingNewlyRegDoctors.sort = this.sort;
      }
    },errors=>{
      this.ToastrService.error("Error on getting newly registerd doctors");
    })
  }

  verifiedDoctors:any
  BindingverifiedDoctors
  Verified(){
    this.doctorService.getAllVerifiedDoctors().subscribe(res=>{ 
      this.verifiedDoctors=new MatTableDataSource(res["data"])
      this.BindingverifiedDoctors= this.verifiedDoctors.filteredData.VERIFIED
      this.BindingverifiedDoctors.paginator = this.paginator;
      this.BindingverifiedDoctors.sort = this.sort;
    },errors=>{
      this.ToastrService.error("Error on getting verified doctors");
    })
  }

  ApprovedDoctors:any
  BindingapprovedDoctors
  Approved(){
    this.doctorService.getAllApprovedDoctors().subscribe(res=>{ 
      this.ApprovedDoctors=new MatTableDataSource(res["data"])
      this.BindingapprovedDoctors= this.ApprovedDoctors.filteredData.VERIFIED
      this.BindingapprovedDoctors.paginator = this.paginator;
      this.BindingapprovedDoctors.sort = this.sort;
    },errors=>{
      this.ToastrService.error("Error on getting approved doctors");
    })
  }
  doctor
  viewDoctorDerails(doctor){
    this.doctor=doctor;
    this.doctorDetails.position="end"
     this.doctorDetails.open();
    ///this.doctorService.OpenSideNave(doctor);
  }

  onEvent(event) {
    event.stopPropagation();
 }

 isAllSelected() {
  const numSelected = this.selection.selected.length;
   const numRows = this.NewlyRegDoctors.data.length;
  return numSelected === numRows;
}
masterToggle() {
  this.isAllSelected() ? this.selection.clear() : 
  this.BindingNewlyRegDoctors.data.forEach(row => this.selection.select(row));   
}

search(search){
  this.BindingNewlyRegDoctors = this.filter(search); 
}

 filter(searchString:string):any[]{
  this.NewlyRegDoctors.filter = searchString.trim().toLowerCase(); 
   return this.NewlyRegDoctors.filteredData;
}

public VisibleTab1: boolean;
  public VisibleTab2: boolean;
  public VisibleTab3: boolean;
  TabChanges(tab) {
    if (tab._indexToSelect == 0) {
      this.VisibleTab1 = true;
      this.VisibleTab2 = false;
      this.VisibleTab3 = false;
      this.LoadNewlyRegistered();
    }
    else if (tab._indexToSelect == 1) {
      this.VisibleTab1 = false;
      this.VisibleTab2 = true;
      this.VisibleTab3 = false;
      this.Verified()
    } else if (tab._indexToSelect == 2) {
      this.VisibleTab1 = false;
      this.VisibleTab2 = false;
      this.VisibleTab3 = true;
      this.Approved();
    }
  }


  // OpenDoctorDetails(doctor){
  //   this.doctor=doctor;
  //   this.doctorDetails.position="end"
  //    this.doctorDetails.open();
  //  }


}
