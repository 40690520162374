<div class="modal-header">
    <h5 class="modal-title"> <span class="spaneeditem2"><i class="fas fa-notes-medical"></i>&nbsp;Messages</span>
    </h5>
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"
     > <span aria-hidden="true">×</span>
    </button>
  </div>
<div class="modal-body">
  <div class="row">
    <div class="col-lg-12 mt-1 text_right ">
      <ul class="pagination" *ngIf="messagesList?.length>6">
        <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
      </ul>
    </div>
  </div>
    <div *ngIf="messagesList?.length == 0" class="no-notifications"></div>
    <div class="row d-flex ml-2">
        <div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left blt-2 mb-2 mt-1"
          *ngFor="let message of messagesList | paginate: { id: 'p2', itemsPerPage: 6, currentPage: p2 }"
          [ngClass]="{' blt-3': message?.view == 'Y','bg-light': (message.view == 'Y' && isIvf)}" (click)="viewMessage(message)">

          <a href="javascript:void(0)" class="p-2 icon-appointments  ">
            <i class="fa-solid fa-message fa-lg pt-2  icon-col-3"></i>
          </a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 " >
            <a href="javascript:void(0)"> 
              <p class="font-weight-medium mb-1 mt-1 text-truncate text-nowrap  overflow-hidden eclips" style="text-overflow: ellipsis">
                {{ message?.message }}
              </p>
              <p class="text-muted mb-1 text-small">{{message?.msgOwnerName|titlecase}} {{message?.publishedOn | date:'dd-MMM-yyyy hh:mm a'}}</p>
            </a>
          </div>

          <div class="btn-group float-right">
            <button class="btn btn-xs btn0col1" data-toggle="modal" data-backdrop="static" data-target="#viewpdf"><i
                class="fa fa-eye" aria-hidden="true" ></i></button>
          </div>
        </div>
      </div>
</div>
