import { GlobalStorageService } from 'src/app/global-storage.service';
import { formatDate, JsonPipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject } from 'rxjs';
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { PatientService } from 'src/app/shared/services/patient.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { debounceTime, distinctUntilChanged, switchMap, catchError, tap, map, startWith } from 'rxjs/operators';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import * as moment from 'moment';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { TemplateFormComponent } from '../template-form/template-form.component';
declare var $: any;
@Component({
  selector: 'app-common-prescription',
  templateUrl: './common-prescription.component.html',
  styleUrls: ['./common-prescription.component.css']
})
export class CommonPrescriptionComponent implements OnInit {
  isIvf=false
  @Input() patientPersonalDetails: any;
  @Input() image: any;searchSubject = new Subject<string>();
  public documentGrp: FormGroup;
  medications = []
  prescriptions = []
  isFormOpen = false
  isFirst = true
  isTestForm = false
  prescriptionForm: FormGroup;
  medicaltestForm: FormGroup;
  autoCompleteControl = new FormControl();
  public file
  filteredOptions: Observable<string[]>;
  filteredTemplateNames:Observable<string[]>;
  filteredOptionsT: Observable<string[]>;
  options = [];
  optionsT = [];
  optionsTemplates = [];
   isSave = false
  ngSelect = 'selected'
  instructions: any[]
  ngSelectedType = 'selected'
  isNotesOpen = false
  public form: FormGroup;
  showFillUpForm: boolean = false
  minDate = new Date(1955, 0, 1);
  maxDate = new Date(2024, 0, 1);
  patientMedication
  CurrentTabIndex; ngSelectTestType;
  submitted = false;
  isDuration = false; medicineTypes; ngSelectLabType;
  labTestLists = [];
  myControl = new FormControl('');
  followup:FormGroup;
  minDateFollow = new Date();
  maxDateFollow=new Date(3222,0,1)
  constructor(private globalStorage: GlobalStorageService, private renderer: Renderer2,public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder, public dialog: MatDialog, private cd: ChangeDetectorRef,
    private drug: DoctorService,private clinicServices:ClinicService,
    public router: Router, private sanitizer: DomSanitizer,
    private toaster: ToastrService,
    private route: ActivatedRoute,
    private controller: PatientScheduleControlService,
    private doctorPatientService: DoctorPatientService,
    private modalService: NgbModal,
  ) {
    this.form = this.formBuilder.group({
      files: ['', Validators.required]
    });

    this.prescriptionForm = this.formBuilder.group({
      type: [],
      medicineName: [],
      dosage: [],
      morning: [],
      afternoon: [],
      evening: [],
      numberOfDays: [],
      instructions: [],
      refill: [0],
      numberofrefills: [],
      notes: []
    })

    this.medicaltestForm = this.formBuilder.group({
      "notes": [''],
      "testName": ['', Validators.required],
      "testType": [''],
      "instructions": ['', Validators.required]
    });

  }
  ngOnInit(): void {
    this.followup=this.formBuilder.group({
      "followUpDate":['']
    })
    this.filteredTemplateNames = this.myControl.valueChanges.pipe(
      debounceTime(300), // Wait for 300 ms pause in events
      distinctUntilChanged(), // Ignore if next search term is same as previous
      startWith(''),
      map(value => this._filterNames(value || '')),
    );
    throw new Error('Method not implemented.');
  }
  private _filterNames(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsTemplates?.filter(option => option?.templateName?.toLowerCase().includes(filterValue));
  }
  loginUser;ivfDetails
  ngAfterViewInit(): void {
    console.log(this.patientPersonalDetails,"PatientPersonalDetails");

    if(this.router.url=='/ivf-patients' || this.router.url=='/ivf-dashboard' || this.router.url=='/appointments'){
      this.isIvf=true
    }
    else{
      this.isIvf=false
    }
    this.getInstructions();
    $('.carousel').carousel({
      interval: false,
    });
    var data = JSON.parse(this.globalStorage.getItem('LoginDetails'));
    this.loginUser = data?.data;
    this.checkPrescriptionExistOrNot()
   // this.getDocotrAddress(this.loginUser?.doctorId)
    this.medicineType();
    this.createPrescriptionForm();
    this.createMedicalTest();
    this.getLabTests();
    this.loadPatientActiveMedication(this.patientMedication)
    this.ivfDetails = JSON.parse(this.globalStorage.getItem('ivfDetails'))
    this.documentGrp = this.formBuilder.group({
      documentFile: new FormControl(File)
    });
    var req
    if(this.patientPersonalDetails?.type=="Hospital"){
      req = {
        "forUserId": this.patientPersonalDetails?.userId,
        "userId": this.patientPersonalDetails?.userId
      }
    }
    else{
      if(this.patientPersonalDetails?.isIPD=="Y"){
        req = {
          "forUserId": this.patientPersonalDetails?.userId,
          "userId": this.patientPersonalDetails?.userId
        }
      }
      if(this.router.url=='/clinic-appointments' || this.router.url=='/caretaker-dashboard' || this.router.url=='/in-patient-details' || this.router.url=='/ivf-patients' || this.router.url=='/ivf-dashboard' ||
      this.router.url=='/appointments' || this.router.url=='/in-patient-list' || this.router.url=='/users-list' || this.router.url=='/clinic-dashboard' || this.router.url=='/clinic-admin' || this.router.url=='/clinic-users' || this.router.url=='/diagnostic_users'){
        req = {
          "forUserId": this.patientPersonalDetails?.userId,
          "userId": this.patientPersonalDetails?.userId
        }
      }
      else{
        //this.getDocotrAddress(this.loginUser?.doctorId)
        req = {
          "forUserId": this.patientPersonalDetails?.userId,
          "userId": JSON.parse(this.globalStorage.getItem('doctorId'))
        }
      }
    }
    //this.getPatientAddress(req, this.patientPersonalDetails?.userId)
    //  this.getSavedPrescription(this.patientPersonalDetails)
    if(this.router.url=='/ivf-patients' || this.router.url=='/ivf-dashboard' || this.router.url=='/appointments' ){

    }
    else{
      this.drug.getDrugNames().subscribe(res => {
        this.drugNamesPush(res['data'])

      })
    }
    // this.filteredOptionsT=this.medicaltestForm.get('testName').valueChanges.pipe(
    //   startWith(''),
    //   map(value => this._filterT(value))
    // );
    this.filteredOptions = this.prescriptionForm.get('medicineName').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
      this.filteredTemplateNames = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterNames(value || '')),
      );
    $('#toggle').on('click', function (e) {
      e.preventDefault();
      $('#tweetdetails').toggleClass('open');
    });
    $('#toggle1').on('click', function (e) {
      e.preventDefault();
      $('#tweetdetails').toggleClass('open');
    });

    $(document).ready(function () {
      $("div.bhoechie-tab-menu>div.list-group>a").click(function (e) {
        e.preventDefault();
        $(this).siblings('a.active').removeClass("active");
        $(this).addClass("active");
        var index = $(this).index();
        $("div.bhoechie-tab>div.bhoechie-tab-content").removeClass("active");
        $("div.bhoechie-tab>div.bhoechie-tab-content").eq(index).addClass("active");
      });
    });
    // $('#prescription').modal('show');

  }

  getInstructions() {
    this.drug.getIns().subscribe(e => {
      if (e['body']) {
        this.instructions = e["body"]['data']
      } else {
        this.instructions = e['data']
      }
    })
  }

  doctorName; dstreet1; dstreet2; dstate; dcity; dcountry; dzip
  getDocotrAddress(id) {
    if(this.router.url!='/in-patient-details'){
      this.drug.getAddressDetails(id).subscribe(res => {
        this.doctorName = res['data'][0]['contactName']
        this.dstreet1 = res['data'][0]['street1']
        this.dstreet2 = res['data'][0]['street2']
        this.dstate = res['data'][0]['state']
        this.dcity = res['data'][0]['city']
        this.dcountry = res['data'][0]['country']
        this.dzip = res['data'][0]['zip']
      })
    }
  }
  patientName; pstreet1; pstreet2; pstate; pcity; pcountry; pzip
  getPatientAddress(request, id) {
    this.drug.getPatientAddress(request, id).subscribe(res => {
      this.globalStorage.setItem('forUserId', JSON.parse(this.globalStorage.getItem('doctorId')))
      this.patientName = res['body']['data'][0]['contactName']
      this.pstreet1 = res['body']['data'][0]['street1']
      this.pstreet2 = res['body']['data'][0]['street2']
      this.pstate = res['body']['data'][0]['state']
      this.pcity = res['body']['data'][0]['city']
      this.pcountry = res['body']['data'][0]['country']
      this.pzip = res['body']['data'][0]['zip']
    }, error => {
      this.toaster.error(error.error.message)
      this.globalStorage.setItem('forUserId', JSON.parse(this.globalStorage.getItem('doctorId')))
    })
  }
  prescriptionGiven=false;checkFillPrescription
  checkPrescriptionExistOrNot() {
    if(this.patientPersonalDetails?.['AppointmentDetails']?.['appointmentId']){
      this.drug.checkPrescriptionExistOrNot(this.patientPersonalDetails?.['AppointmentDetails']?.['appointmentId'], this.patientPersonalDetails?.userId).subscribe(res => {
        if (res['body']?.['data']?.['medications'].length > 0 || res['body']?.['data']?.['medicaltests'].length > 0) {
          this.isFormOpen = false
          this.prescriptionGiven=true
          this.checkFillPrescription=res['body']?.['data']?.['prescriptionFill']
          this.prescriptions = res['body']?.['data']?.['medications']
          this.medications = res['body']?.['data']?.['medicaltests']
        }
        else {
          this.prescriptions = []
        }

      })
    }
  }
  addMedication() {
    this.isFormOpen = true
    $('#upload-prescription-tab').removeClass('active')
    $('#pills-profile-tab').removeClass('active')
    $('#pills-home-tab').addClass('active')
    $('#pills-home').addClass('show active')
    $('#pills-profile').removeClass('show active')
    $('#upload-prescription').removeClass('show active')
  }
  addMedicalTest() {
    this.isTestForm = true
    $('#upload-prescription-tab').removeClass('active')
    $('#pills-profile-tab').addClass('active')
    $('#pills-home-tab').removeClass('active')
    $('#pills-home').removeClass('show active')
    $('#pills-profile').addClass('show active')
    $('#upload-prescription').removeClass('show active')
  }
  uploadPrescription() {
    $('#upload-prescription-tab').addClass('active')
    $('#pills-profile-tab').removeClass('active')
    $('#pills-home-tab').removeClass('active')
    $('#pills-home').removeClass('show active')
    $('#pills-profile').removeClass('show active')
    $('#upload-prescription').addClass('show active')
  }

  patientPrecription
  prescriptionList: any[] = []
  getSavedPrescription(appointment) {

    var appointmentObj = {
      "appointmentId": appointment.AppointmentDetails.appointmentId,
      "patientId": appointment.AppointmentDetails.userId
    }

    // this.doctorPatient.getPatientPrecriptionByAppointmentId(appointmentObj).subscribe(res=>{

    //   this.prescriptionList=res['data'];
    // },error=>{

    // })
  }
  // prescription formGroup Initialization
  createPrescriptionForm() {
    $('#pills-home-tab').addClass('active')
    this.prescriptionForm = this.formBuilder.group({
      "enddate": '',
      "medicineName": ['', Validators.required],
      "dosage": ['',],
      "medicineUsage": '',
      "notes": [''],
      "numberOfDays": ['', Validators.required],
      "quantity": [''],
      "numberofrefills": 0,
      "power": [''],
      "type": ['', Validators.required],
      "morning": [],
      "patientId": this.patientPersonalDetails?.userId,
      "evening": [],
      "afternoon": [],
      "inventoryId":[],
      "instructions": [Validators.required],
      "refill": ['', Validators.required],
      "inpatientId":this.patientPersonalDetails?.['AppointmentDetails']?.['inpatientId'],
      "hospitaladdressId":this.patientPersonalDetails?.['AppointmentDetails']?.['hospitalAddresId'],
      "ivfaddressId":this.patientPersonalDetails?.['AppointmentDetails']?.['ivfaddressId'],
      "amount":300
    });

    // this.drug.getDrugNames().subscribe(res => {
    //   this.drugNamesPush(res['data'])

    // })
    this.filteredOptionsT = this.medicaltestForm.get('testName').valueChanges
    .pipe(
      debounceTime(300), // Wait for 300 ms pause in events
      distinctUntilChanged(), // Ignore if next search term is same as previous
      startWith(''),
      map(value => this._filterT(value))
    );
    this.filteredOptions = this.prescriptionForm.get('medicineName').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  get f() { return this.prescriptionForm.controls; }
  get m() { return this.medicaltestForm.controls; }
  // Medical Test Form Group Initialization
  createMedicalTest() {
    this.medicaltestForm = this.formBuilder.group({
      "notes": [''],
      "testName": ['', Validators.required],
      "testType": [''],
      "instructions": ['', Validators.required],
      "patientId": this.patientPersonalDetails?.userId,
      "inpatientId": this.patientPersonalDetails?.['AppointmentDetails']?.['inpatientId'],
      "hospitaladdressId": this.patientPersonalDetails?.['AppointmentDetails']?.['hospitalAddressId'],
      "ivfaddressId":this.patientPersonalDetails?.['AppointmentDetails']?.['ivfaddressId'],
      "amount":300
    });

  }
  getLabTests() {
    this.controller.labTestList('DIAGNOSTICCENTER', 'TEST').subscribe(res => {
      for (var item of res['data']) {
        this.labTestLists.push(item.lookupValue);
      }
    })

  }
  openForm() {
    this.isFormOpen = true
    this.submitted = false
    Object.keys(this.prescriptionForm.controls).forEach(key => {
      this.prescriptionForm.controls[key].setErrors(null)
    });
  }
  openLabTestForm() {
    this.isTestForm = true
    this.submitted = false
    this.getLabTests()
    Object.keys(this.medicaltestForm.controls).forEach(key => {
      this.medicaltestForm.controls[key].setErrors(null)
    });
  }
  // adding row for Medical Test
  onAddMedicalTest() {

    this.medications.push(this.medicaltestForm.value)
    this.medicaltestForm.reset()
    this.isTestForm = false
    this.isSave = true
  }
  // adding row For Medications
  isPreviewOpen = false
  onAddMedications() {
    this.submitted = true
    if (this.prescriptionForm.value.morning == 0 && this.prescriptionForm.value.evening == 0 && this.prescriptionForm.value.afternoon == 0) {
      this.toaster.error('Please enter one of the section(Morning,Afternoon,Evening) greater than 0')
      return
    }
    if (this.prescriptionForm.invalid) {
      return
    }
    for (var i = 0; i < this.prescriptions?.length; i++) {
      if (this.prescriptions?.[i]?.['medicineName'] === this.prescriptionForm.value.medicineName) {
        this.toaster.warning('Medicine with the same name is in the last ,moifying with the current medication properties')
        this.prescriptions.splice(i, 1)
      }
    }

    var DurationDate = new Date()
    DurationDate.setDate(DurationDate.getDate() + JSON.parse(this.prescriptionForm.value.numberOfDays));
    const DOB = formatDate(DurationDate, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');

    this.prescriptionForm.value.enddate = DOB.concat('Z')
    this.prescriptionForm.value.inventoryId=this.medicineInventoryId
    this.prescriptionForm.value.amount=this.medicineData?.price
    this.prescriptionForm.value.quantity = JSON.parse(this.prescriptionForm.value.morning) + JSON.parse(this.prescriptionForm.value.evening) + JSON.parse(this.prescriptionForm.value.afternoon),
      this.prescriptions.push(this.prescriptionForm.value)
    this.prescriptionForm.reset()
    this.isFormOpen = false
    this.isFirst = false
    this.isSave = true
    // this.isPreviewOpen=true
    //this.addMedication()
    //$('.carousel-control-prev').click();
  }
  // loading a file
  onFileInput(files: File) {
    const f = files

    if ((f[0].type) == "image/png" || (f[0].type) == "application/pdf") {
      if (f[0].size > 1048769) {
        this.toaster.warning('file should be 1 Mb')
      } else {
        this.file = files
        this.isSave = true
      }
      this.OnSubmit()

    } else {
      this.toaster.warning('file should be in jpeg or PNG')
    }

  }
  medicineType() {
    this.controller.lookupstatic('PHARMACY', 'MEDICINETYPE').subscribe(res => {
      this.medicineTypes = res['data']
    })
  }
  // toggle the prescription View
  showFill() {
    this.showFillUpForm = !this.showFillUpForm
  }
  // saving Prescripiton
  appointemntFor
  public OnSubmit() {
    if(this.followup.value?.followUpDate){
      var followUpAppointmentDate=formatDate(this.followup.value?.followUpDate, "yyyy-MM-dd", 'en-us');
    }
    if (this.loginUser?.type == 'NUTRITIONIST') {
      this.appointemntFor = 'NUTRITIONIST'
    }
    else {
      this.appointemntFor = 'DOCTOR'
    }
    var formData = new FormData();
    if (this.file != undefined) {
      for (let i = 0; i < this.file.length; i++) {
        formData.append('prescriptionPic', this.file[i], this.file[i].name);
      }
    }
    var medicalPrescritionFor
    if (this.file) {
      medicalPrescritionFor = 'IMAGE'
    }
    else {
      medicalPrescritionFor = 'test'
    }
    var containsMedicalPrescription
    var containsTestPrescription
    if (this.prescriptions?.length > 0) {
      containsMedicalPrescription = 'Y'
    }
    else {
      containsMedicalPrescription = 'N'
    }
    if (this.medications?.length > 0) {
      containsTestPrescription = 'Y'
    }
    else {
      containsTestPrescription = 'N'
    }
    var request
    console.log('patient data',this.patientPersonalDetails)
    var req = {
      "appointmentDate": this.patientPersonalDetails['AppointmentDetails']['appointmentDate'],
      "appointmentFor": this.appointemntFor,
      "medicalPrescritionFor": medicalPrescritionFor,
      "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['appointmentId'],
      "doctorId": this.loginUser?.doctorId,
      "doctorName": this.loginUser?.firstName + '' + this.loginUser?.lastName,
      "containsMedicalPrescription": containsMedicalPrescription,
      "containsTestPrescription": containsTestPrescription,
      "documentLocation": null,
      "documentName": '',
      "medications": this.prescriptions,
      "medicalTests": this.medications,
      "inpatientId": this.patientPersonalDetails['AppointmentDetails']['inpatientId'],
      "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']['hospitalAddresId'],
      "medicalprescription": {
        "patientId": this.patientPersonalDetails?.userId,
        "patientName": this.patientPersonalDetails?.patientName
      },
      "medicaltestprescription": {
        "patientId": this.patientPersonalDetails?.userId,
      },
      "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
      "patientId": this.patientPersonalDetails?.userId,
      "patientName": this.patientPersonalDetails?.patientName,
      "followUpAppointmentDate":followUpAppointmentDate
    }
    var finalReq
    if (containsMedicalPrescription == 'Y') {
      finalReq = {
        "appointmentDate": this.patientPersonalDetails['AppointmentDetails']['appointmentDate'],
        "appointmentFor": this.appointemntFor,
        "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['appointmentId'],
        "inpatientId": this.patientPersonalDetails['AppointmentDetails']['inpatientId'],
        "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']['hospitalAddresId'],
        "templateName": this.templateData?.templateName,
        "createAsTemplate": this.createAsTemplate,
        "addAsstemplate":this.createAsTemplate,
        "containsMedicalPrescription": containsMedicalPrescription,
        "containsTestPrescription": containsTestPrescription,
        "medicalPrescritionFor": medicalPrescritionFor,
        "doctorId": this.loginUser?.doctorId,
        "doctorName": this.loginUser?.firstName + ' ' + this.loginUser?.lastName,
        "documentLocation": null,
        "documentName": '',
        "medicalprescription": {
          "medications": this.prescriptions,
          "patientId": this.patientPersonalDetails?.userId
        },
        // "medicaltestprescription": {
        //   "medicalTests": this.medications,
        //   "patientId": this.patientPersonalDetails?.userId
        // },
        "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
        "patientId": this.patientPersonalDetails?.userId,
        "patientName": this.patientPersonalDetails?.patientName,
        "followUpAppointmentDate":followUpAppointmentDate
      }
    }
    if (containsTestPrescription == 'Y') {
      finalReq = {
        "appointmentDate": this.patientPersonalDetails['AppointmentDetails']['appointmentDate'],
        "appointmentFor": this.appointemntFor,
        "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['appointmentId'],
        "containsMedicalPrescription": containsMedicalPrescription,
        "containsTestPrescription": containsTestPrescription,
        "medicalPrescritionFor": medicalPrescritionFor,
        "templateName": this.templateData?.templateName,
        "createAsTemplate": this.createAsTemplate,
        "addAsstemplate":this.createAsTemplate,
        "doctorId": this.loginUser?.doctorId,
        "doctorName": this.loginUser?.firstName + ' ' + this.loginUser?.lastName,
        "documentLocation": null,
        "documentName": '',
        "inpatientId": this.patientPersonalDetails['AppointmentDetails']['inpatientId'],
        "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']['hospitalAddresId'],
        // "medicalprescription": {
        //   "medications": this.prescriptions,
        //   "patientId": this.patientPersonalDetails?.userId
        // },
        "medicaltestprescription": {
          "medicalTests": this.medications,
          "patientId": this.patientPersonalDetails?.userId
        },
        "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
        "patientId": this.patientPersonalDetails?.userId,
        "patientName": this.patientPersonalDetails?.patientName,
        "followUpAppointmentDate":followUpAppointmentDate
      }
    }
    if (containsMedicalPrescription == 'Y' && containsTestPrescription == 'Y') {
      finalReq = {
        "appointmentDate": this.patientPersonalDetails['AppointmentDetails']['appointmentDate'],
        "appointmentFor": this.appointemntFor,
        "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['appointmentId'],
        "containsMedicalPrescription": containsMedicalPrescription,
        "containsTestPrescription": containsTestPrescription,
        "templateName": this.templateData?.templateName,
        "createAsTemplate": this.createAsTemplate,
        "addAsstemplate":this.createAsTemplate,
        "medicalPrescritionFor": medicalPrescritionFor,
        "doctorId": this.loginUser?.doctorId,
        "doctorName": this.loginUser?.firstName + ' ' + this.loginUser?.lastName,
        "documentLocation": null,
        "documentName": '',
        "inpatientId": this.patientPersonalDetails['AppointmentDetails']['inpatientId'],
        "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']['hospitalAddresId'],
        "medicalprescription": {
          "medications": this.prescriptions,
          "patientId": this.patientPersonalDetails?.userId
        },
        "medicaltestprescription": {
          "medicalTests": this.medications,
          "patientId": this.patientPersonalDetails?.userId
        },
        "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
        "patientId": this.patientPersonalDetails?.userId,
        "patientName": this.patientPersonalDetails?.patientName,
        "followUpAppointmentDate":followUpAppointmentDate
      }
    }

    if (this.file) {
      request = {
        "doctorSpecialization": this.loginUser.specialization,
        "appointmentFor": this.appointemntFor,
        "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['appointmentId'],
        "refill": true,
        "medicalPrescritionFor": "IMAGEUPLOAD",
        "followUpAppointmentDate":followUpAppointmentDate
      }
    }
    else {
      if (this.medications?.length == 0) {
        request = {
          "doctorSpecialization": this.loginUser?.specialization,
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['appointmentId'],
          "refill": true,
          "medications": this.prescriptions,
          "medicalPrescritionFor": "",
          "followUpAppointmentDate":followUpAppointmentDate
        }
      }
      else {
        request = {
          "doctorSpecialization": this.loginUser?.specialization,
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['appointmentId'],
          "refill": true,
          "medications": this.prescriptions,
          "medicalTests": this.medications,
          "medicalPrescritionFor": "",
          "followUpAppointmentDate":followUpAppointmentDate
        }
      }
    }
    if (this.router.url=='/ivf-dashboard') {
      if (containsMedicalPrescription == 'Y') {
        const formatedExpiryDate = moment.utc(this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        finalReq = {
          "appointmentDate": formatedExpiryDate,
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['appointmentId'],
          "inpatientId": this.patientPersonalDetails['AppointmentDetails']?.['inpatientId'],
          "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']?.['hospitalAddresId'],
          "containsMedicalPrescription": containsMedicalPrescription,
          "containsTestPrescription": containsTestPrescription,
          "templateName": this.templateData?.templateName,
          "createAsTemplate": this.createAsTemplate,
          "addAsstemplate":this.createAsTemplate,
          "medicalPrescritionFor": medicalPrescritionFor,
          "doctorId": this.patientPersonalDetails?.['AppointmentDetails']?.doctorId,
          "ivfaddressId":this.ivfDetails?.ivfaddressId,
          "doctorName": this.patientPersonalDetails?.['AppointmentDetails']?.doctorName,
          "documentLocation": null,
          "documentName": '',
          "medications": this.prescriptions,
          // "medicaltestprescription": {
          //   "medicalTests": this.medications,
          //   "patientId": this.patientPersonalDetails?.userId
          // },
          "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
          "patientId": this.patientPersonalDetails?.userId,
          "patientName": this.patientPersonalDetails?.patientName,
          "prescriptionFill": "Y",
          "followUpAppointmentDate":followUpAppointmentDate
        }
      }
      if (containsTestPrescription == 'Y') {
        const formatedExpiryDate = moment.utc(this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        finalReq = {
          "appointmentDate": formatedExpiryDate,
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['appointmentId'],
          "containsMedicalPrescription": containsMedicalPrescription,
          "containsTestPrescription": containsTestPrescription,
          "templateName": this.templateData?.templateName,
          "createAsTemplate": this.createAsTemplate,
          "addAsstemplate":this.createAsTemplate,
          "medicalPrescritionFor": medicalPrescritionFor,
          "doctorId":this.patientPersonalDetails?.['AppointmentDetails']?.doctorId,
          "doctorName": this.patientPersonalDetails?.['AppointmentDetails']?.doctorName,
          "ivfaddressId":this.ivfDetails?.ivfaddressId,
          "documentLocation": null,
          "documentName": '',
          "inpatientId": this.patientPersonalDetails['AppointmentDetails']?.['inpatientId'],
          "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']?.['hospitalAddresId'],
          // "medicalprescription": {
          //   "medications": this.prescriptions,
          //   "patientId": this.patientPersonalDetails?.userId
          // },

          "medicaltests": this.medications,
          "patientId": this.patientPersonalDetails?.userId,
          "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
          "patientName": this.patientPersonalDetails?.patientName,
          "prescriptionFill": "Y",
          "followUpAppointmentDate":followUpAppointmentDate
        }
      }
      if(containsTestPrescription == 'Y' && containsMedicalPrescription == 'Y'){
        const formatedExpiryDate = moment.utc(this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        finalReq = {
          "appointmentDate": formatedExpiryDate,
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['appointmentId'],
          "containsMedicalPrescription": containsMedicalPrescription,
          "containsTestPrescription": containsTestPrescription,
          "medicalPrescritionFor": medicalPrescritionFor,
          "doctorId":this.patientPersonalDetails?.['AppointmentDetails']?.doctorId,
          "doctorName": this.patientPersonalDetails?.['AppointmentDetails']?.doctorName,
          "templateName": this.templateData?.templateName,
          "createAsTemplate": this.createAsTemplate,
          "addAsstemplate":this.createAsTemplate,
          "ivfaddressId":this.ivfDetails?.ivfaddressId,
          "documentLocation": null,
          "documentName": '',
          "inpatientId": this.patientPersonalDetails['AppointmentDetails']?.['inpatientId'],
          "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']?.['hospitalAddresId'],
          "medications": this.prescriptions,
          "medicaltests": this.medications,
          "patientId": this.patientPersonalDetails?.userId,
          "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
          "patientName": this.patientPersonalDetails?.patientName,
          "prescriptionFill": "Y",
          "followUpAppointmentDate":followUpAppointmentDate
        }
      }
    }
    if (this.patientPersonalDetails['Appointment'] == "Current" && this.globalStorage.getItem('loggedInAs')=='HOSPITAL') {
      if (containsMedicalPrescription == 'Y') {
        finalReq = {
          "appointmentDate": this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['referenceappointmentId'],
          "inpatientId": this.patientPersonalDetails['AppointmentDetails']?.['inpatientId'],
          "hospitaladdressId":this.globalStorage.getItem('hospitalAddressId'),
          "containsMedicalPrescription": containsMedicalPrescription,
          "templateName": this.templateData?.templateName,
          "createAsTemplate": this.createAsTemplate,
          "addAsstemplate":this.createAsTemplate,
          "containsTestPrescription": containsTestPrescription,
          "medicalPrescritionFor": medicalPrescritionFor,
          "doctorId": this.loginUser?.doctorId,
          "doctorName": this.loginUser?.firstName + ' ' + this.loginUser?.lastName,
          "documentLocation": null,
          "documentName": '',
          "medications": this.prescriptions,
          "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
          "patientId": this.patientPersonalDetails?.userId,
          "patientName": this.patientPersonalDetails?.patientName,
          "prescriptionFill": "Y",
          "followUpAppointmentDate":followUpAppointmentDate
        }
      }
      if (containsTestPrescription == 'Y') {
        finalReq = {
          "appointmentDate": this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['referenceappointmentId'],
          "containsMedicalPrescription": containsMedicalPrescription,
          "containsTestPrescription": containsTestPrescription,
          "medicalPrescritionFor": medicalPrescritionFor,
          "doctorId": this.loginUser?.doctorId,
          "templateName": this.templateData?.templateName,
          "createAsTemplate": this.createAsTemplate,
          "addAsstemplate":this.createAsTemplate,
          "doctorName": this.loginUser?.firstName + ' ' + this.loginUser?.lastName,
          "documentLocation": null,
          "documentName": '',
          "inpatientId": this.patientPersonalDetails['AppointmentDetails']?.['inpatientId'],
          "hospitaladdressId":this.globalStorage.getItem('hospitalAddressId'),
          "medicaltests": this.medications,
          "patientId": this.patientPersonalDetails?.userId,
          "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
          "patientName": this.patientPersonalDetails?.patientName,
          "prescriptionFill": "Y",
          "followUpAppointmentDate":followUpAppointmentDate
        }
      }
      var hosObj
      if(this.patientPersonalDetails['AppointmentDetails']['hospitalAddressId']){
        hosObj=this.patientPersonalDetails['AppointmentDetails']
        if (containsMedicalPrescription == 'Y') {
          finalReq = {
            "appointmentDate": this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],
            "appointmentFor": this.appointemntFor,
            "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['referenceappointmentId'],
            "inpatientId": -1,
            "hospitaladdressId":this.globalStorage.getItem('hospitalAddressId'),
            "containsMedicalPrescription": containsMedicalPrescription,
            "containsTestPrescription": containsTestPrescription,
            "templateName": this.templateData?.templateName,
            "createAsTemplate": this.createAsTemplate,
            "addAsstemplate":this.createAsTemplate,
            "medicalPrescritionFor": medicalPrescritionFor,
            "doctorId": hosObj?.doctorId,
            "doctorName": hosObj?.doctorName,
            "documentLocation": null,
            "documentName": '',
            "medications": this.prescriptions,
            "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
            "patientId": this.patientPersonalDetails?.userId,
            "patientName": this.patientPersonalDetails?.patientName,
            "prescriptionFill": "Y",
            "followUpAppointmentDate":followUpAppointmentDate
          }
        }
        if (containsTestPrescription == 'Y') {
          finalReq = {
            "appointmentDate": this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],
            "appointmentFor": this.appointemntFor,
            "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['referenceappointmentId'],
            "containsMedicalPrescription": containsMedicalPrescription,
            "containsTestPrescription": containsTestPrescription,
            "medicalPrescritionFor": medicalPrescritionFor,
            "templateName": this.templateData?.templateName,
            "createAsTemplate": this.createAsTemplate,
            "addAsstemplate":this.createAsTemplate,
            "doctorId": hosObj?.doctorId,
            "doctorName": hosObj?.doctorName,
            "documentLocation": null,
            "documentName": '',
            "inpatientId": -1,
            "hospitaladdressId":this.globalStorage.getItem('hospitalAddressId'),
            "medicaltests": this.medications,
            "patientId": this.patientPersonalDetails?.userId,
            "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
            "patientName": this.patientPersonalDetails?.patientName,
            "prescriptionFill": "Y",
            "followUpAppointmentDate":followUpAppointmentDate
          }
        }
        if(containsTestPrescription == 'Y' && containsMedicalPrescription == 'Y'){
          finalReq = {
            "appointmentDate": this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],
            "appointmentFor": this.appointemntFor,
            "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['referenceappointmentId'],
            "containsMedicalPrescription": containsMedicalPrescription,
            "containsTestPrescription": containsTestPrescription,
            "medicalPrescritionFor": medicalPrescritionFor,
            "templateName": this.templateData?.templateName,
            "createAsTemplate": this.createAsTemplate,
            "addAsstemplate":this.createAsTemplate,
            "doctorId": hosObj?.doctorId,
            "doctorName": hosObj?.doctorName,
            "documentLocation": null,
            "documentName": '',
            "inpatientId": -1,
            "hospitaladdressId": this.globalStorage.getItem('hospitalAddressId'),
            "medications": this.prescriptions,
            "medicaltests": this.medications,
            "patientId": this.patientPersonalDetails?.userId,
            "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
            "patientName": this.patientPersonalDetails?.patientName,
            "prescriptionFill": "Y",
            "followUpAppointmentDate":followUpAppointmentDate
          }
        }
      }

      finalReq['payByPatient']="Y"
        this.controller.saveHospitalPrescription(finalReq, this.patientPersonalDetails?.['userId']).subscribe(res => {
          this.toaster.success('Prescription added.')
          this.activeModal.close('true')
          this.isSave = false
          this.documentGrp.reset()
          this.closeModal()
        }, error => {
          this.toaster.error(error['error']['message'])
        })


    }
    else {
      if(this.router.url=='/ivf-patients' || this.router.url=='/ivf-dashboard'){
        const formatedExpiryDate = moment.utc(this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        // finalReq['ivfaddressId']=this.ivfDetails?.ivfaddressId
        // finalReq['appointmentDate']=formatedExpiryDate
        // finalReq['doctorName']=this.patientPersonalDetails?.['AppointmentDetails']?.doctorName
        // finalReq['doctorId']=this.patientPersonalDetails?.['AppointmentDetails']?.doctorId
        this.controller.saveIvfPatientPrescription(finalReq, this.patientPersonalDetails?.['userId']).subscribe(res => {
          this.toaster.success('Prescription added.')
          this.isSave = false
          this.templatePresent=false
          this.documentGrp.reset()
        }, error => {
          this.toaster.error(error['error']['message'])
        })
      }else if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
        const formatedExpiryDate = moment.utc(this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

        this.controller.saveHospitalPrescription(finalReq, this.patientPersonalDetails?.['userId']).subscribe(res => {
          this.toaster.success('Prescription added.')
          this.isSave = false
          this.templatePresent=false
          this.closeModal()
          this.documentGrp.reset()
        }, error => {
          this.toaster.error(error['error']['message'])
        })
      }
      else{
        this.controller.savePrescription(finalReq, this.patientPersonalDetails?.['userId']).subscribe(res => {
          this.toaster.success('Prescription added.')
          this.isSave = false
          this.templatePresent=false
          this.closeModal()
          this.documentGrp.reset()
        }, error => {
          this.toaster.error(error['error']['message'])
        })
      }
    }

    // formData.append('userprescriptionJson', JSON.stringify(request));
    // formData.append('forUserId',JSON.stringify(this.patientPersonalDetails['AppointmentDetails']['userId']));
    // this.controller.sendPrescription(formData,this.patientPersonalDetails['AppointmentDetails']['userId']).subscribe(res => {
    //   this.toaster.success('Prescription added.')
    //   this.documentGrp.reset()
    // },error=>{
    //   this.toaster.error(error['error']['message'])
    // })
  }
  onRemoveRow(rowIndex: number) {
    this.prescriptions.splice(rowIndex, 1);
  }
  onPreviousRemoveRowTest(rowIndex: number) {
    this.activeMedicationList.splice(rowIndex, 1);
  }
  onRemoveRowTest(rowIndex: number) {
    this.medications.splice(rowIndex, 1);
  }
  editMedications() {
    this.prescriptionForm.patchValue({

    })
  }
  optionsTest = []
  private _filterT(value: string): string[] {
    if (value == undefined) {
      this.filteredOptionsT = this.medicaltestForm.get('testName').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterT(value))
        );
    } else {
      const filterValue = value?.toLowerCase();

      return this.optionsTest?.filter(option => option?.toLowerCase()?.includes(filterValue));
    }
  }
  private _filter(value: string): string[] {
    if (value == undefined) {
      this.filteredOptions = this.prescriptionForm.get('medicineName').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    } else {
      const filterValue = value.toLowerCase();

      return this.options.filter(option => option?.drugname?.toLowerCase()?.includes(filterValue));
    }
  }
  prescriptionEdit(item, i) {
    this.isFormOpen = true
    this.addMedication()
    this.ngSelectedType = item['type']
    this.ngSelect = item['instructions']
    if(item?.inventoryId){
      this.medicineData={}
      this.medicineInventoryId=item?.inventoryId
      this.medicineData.price=item?.amount
    }
    this.prescriptionForm.patchValue({
      "enddate": item['enddate'],
      "medicineName": item['medicineName'],
      "medicineUsage": item['medicineUsage'],
      "notes": item['notes'],
      "numberOfDays": item['numberOfDays'],
      "quantity": item['quantity'],
      "numberofrefills": item['numberofrefills'],
      "power": item['power'],
      "type": item['type'],
      "morning": item['morning'],
      "patientId": item['patientId'],
      "evening": item['evening'],
      "afternoon": item['afternoon'],
      "instructions": item['instructions'],
      "refill": item['refill']
    })
    this.onRemoveRow(i)
  }
  presviousPrescEdit(item, i) {
    this.prescriptionForm.patchValue({
      "enddate": item['enddate'],
      "medicineName": item['medicineName'],
      "medicineUsage": item['medicineUsage'],
      "notes": item['notes'],
      "numberOfDays": item['numberOfDays'],
      "quantity": item['quantity'],
      "numberofrefills": item['numberofrefills'],
      "power": item['power'],
      "type": item['type'],
      "morning": item['morning'],
      "patientId": item['patientId'],
      "evening": item['evening'],
      "afternoon": item['afternoon'],
      "instructions": item['instructions']
    })
  }
  finalPreview() {
    $('#preview').modal('show')
    //$('#tweetdetails').addClass('open');
  }
  medicalTestEdit(item, i) {
    this.openLabTestForm()
    this.ngSelect = item['instructions']
   // this.ngSelectTestType=item['testType']
   setTimeout(() => {
    this.medicaltestForm.patchValue({
      "notes": item['notes'],
      "testName": item['testName'],
      "testType": item['testType'],
      "instructions": item['instructions'],
      "patientId": this.patientPersonalDetails['AppointmentDetails']['userId'],
    })
  }, 1000);
   
    this.onRemoveRowTest(i)
  }
  public ngOnChanges(changes: SimpleChanges) {

    if ('patientPersonalDetails' in changes) {
      // if(this.patientPersonalDetails!=undefined){
      //     if(this.patientPersonalDetails['profilePic']!=null){
      //       this.ImageSrc ="data:image/jpeg;base64,"+this.patientPersonalDetails['profilePic']
      //       this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.ImageSrc);
      //     }else{
      //     this.imgURL="/assets/img/avatar.png";
      //     }
      //     }
      if ('patientMedication' in changes) {
        if (this.patientMedication != undefined) {
          this.loadPatientActiveMedication(this.patientMedication);
        }
      }

      $('a').removeClass('active')
      $('#tab_start').addClass('active')
      $('#startTab').addClass('active')

      // this.calculateAge(this.patientPersonalDetails.dob)
      if (this.patientPersonalDetails?.['Appointment'] == 'Current') {
        this.CurrentTabIndex = 0
        this.getPatientChange();
      }
      if (this.patientPersonalDetails?.['Appointment'] == 'Upcoming') {
        // this.UpcomingTabIndex=0
        this.getPatientChange();
        this.getPatientMedicalReports(this.patientPersonalDetails);
      }
      if (this.patientPersonalDetails?.['Appointment'] == 'Previous') {
        // this.PreviousTabIndex=0
        this.getPatientChange();
        this.getPatientMedicalReports(this.patientPersonalDetails);
      }
    }
  }

  getPatientChange() {
    this.patientMedication = this.patientPersonalDetails
  }
  patient
  getPatientMedicalReports(patient) {
    var patientDetails = {
      "forUserId": this.patientPersonalDetails.userId,
      "userId": this.patientPersonalDetails.userId
    }
    this.patient = patientDetails
  }
  selectedMedicine(data) {
    console.log('medicine data',data)
    if (data) {
      console.log('medicine data',data)
      this.medicineInventoryId=data?.inventoryId
      this.inventoryIdPresent = true
     // this.selectedMedicineObject=data
    }
    else {
      this.inventoryIdPresent = false
    }
  }
  selectedTestObject:any;medicineInventoryId
  search(testName) {
    if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
      this.clinicServices.searchByTest(testName, parseInt(this.globalStorage.getItem('hospitalAddressId'))).subscribe(res => {
        var medList = res['data'][0];
        // medList = JSON.parse(medList);
        if (res['data']?.length == 0) {
          this.inventoryIdPresent = false
        }
        this.medicineInventoryId=medList?.inventoryId
        this.selectedTestObject = medList;
        this.optionsTest=[];
        this.optionsTest.push(this.selectedTestObject?.serviceName);
        this.filteredOptionsT = this.medicaltestForm.get('testName').valueChanges
  
        .pipe(
          startWith(''),
          map(value => this._filterT(value))
        );
      })
    }
    else{
      this.medicaltestForm.get('testName').setValue(testName);
    }
}
  searchTest(test){
    this.options=[]
    if(this.router.url=='/ivf-dashboard' || this.router.url=='/appointments'|| this.router.url=='/ivf-patients'){
      console.log('ivf details',this.patientPersonalDetails)
      this.drug.getIvfMedicines('searchByMedicaltest/'+test+'/{hospitalAddresId}?hospitalAddresId='+this.patientPersonalDetails?.AppointmentDetails?.ivfaddressId).subscribe(res=>{
        console.log('medicines',res)
        var medList = res['data'][0];
       // medList = JSON.parse(medList);
        this.options.push(medList.serviceName);
        this.options = this.removeDuplicateItems(this.options);
      })
    }
    if(this.router.url=='/clinic-appointments'){
      this.clinicServices.searchByTest(test, parseInt(this.globalStorage.getItem('hospitalAddressId'))).subscribe(res => {
        var medList = res['data'][0];
        // medList = JSON.parse(medList);
        if (res['data']?.length == 0) {
          this.inventoryIdPresent = false
        }
        this.selectedTestObject = medList;
       // this.optionsTest=[];
       
        console.log('medicines',this.selectedTestObject?.serviceName)
        this.filteredOptionsT = this.medicaltestForm.get('testName').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterT(value))
        );
        this.optionsT.push(this.selectedTestObject?.serviceName);
      })
    }
  }
  medicineData
  searchMedicine(medicine) {
    this.medicineData={}
    this.options = [];
    if(medicine?.length>2){
      this.medicineData={}
    this.options = [];
      if(this.router.url=='/ivf-dashboard' || this.router.url=='/appointments'|| this.router.url=='/ivf-patients'){
        console.log('ivf details',this.patientPersonalDetails)
        if(medicine){
          this.drug.getIvfMedicines('find/allmedicine/byname/'+medicine+'/{pharmacyaddresId}?hospitalAddresId='+this.patientPersonalDetails?.AppointmentDetails?.ivfaddressId).subscribe(res=>{
            console.log('medicines',res)
            var medList = res['data'][0];
           // medList = JSON.parse(medList);
            this.options.push(medList.drugname);
            this.options = this.removeDuplicateItems(this.options);
          })
        }
      }
      else{
        this.drug.getAllMedicines(medicine).subscribe(res => {
          this.options=[]
          var medList = res['data'];
          medList = JSON.parse(medList);
          console.log('medicine list--->',medList)
          var obj={
            drugname:medList?.drugname,
            price:medList?.price,
            inventoryId:medList?.inventoryId
          }
          this.options.push(obj);
          this.medicineData=obj
           // this.optionsDrug=this.options
            this.filteredOptions = this.prescriptionForm.get('medicineName').valueChanges.pipe(
              startWith(''),
              map((value) => this._filter(value || '')),
            );
          this.options.push(medList.drugname);
  
          this.options = this.removeDuplicateItems(this.options);
        })
      }
    }
  }

  removeDuplicateItems(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }

  public submit(files: FileList) {
  }
  drugNamesPush(res) {
    for (var i = 0; i < res.length; i++) {
      this.options.push(res[i].drugname)
    }
  }
  onKey(x) {
    if (x > 90 || x == 0) {
      this.isDuration = true;
      this.prescriptionForm.value.numberOfDays = '1'
    } else {
      this.isDuration = false;
    }
  }
  isMgml = false
  onMgMl(x) {
    if (x > 500 || x == 0) {
      this.isMgml = true;
      this.prescriptionForm.value.power = ''
    } else {
      this.isMgml = false;
    }
  }
  limit(x) {
    if (x > 5) {
      this.isUsage = true
    }
    else {
      this.isUsage = false
    }
  }
  isUsage = false
  refillscheck(q, r, s, t) {
    // if(p!=((q*t)+(r*t)+(s*t))){
    //   this.isUsage=true
    // }
    // else{
    //   this.isUsage= false
    // }
    var result = (q * t) + (r * t) + (s * t)
    this.prescriptionForm.patchValue({
      'numberofrefills': result
    })
    this.prescriptionForm.value.numberofrefills = result
  }
  activeMedicationList: any[] = []
  loadPatientActiveMedication(patient) {
    var medication = {
      // "forUserId":parseInt(patient.userId),
      // "isOnlyActive":true,
      // "pageNum": 1
      "forUser": {
        "forUserId": parseInt(patient?.userId),
        "userId": parseInt(patient?.userId)
      },
      "isOnlyActive": true,
      "maxResults": 100,
      "pageNum": 1
    }

    this.doctorPatientService.getPatientActiveMedication(medication).subscribe(res => {
      this.activeMedicationList = res['data']
    }, error => {
      this.toaster.error("Error retrieving medications.")
    })

  }
  rightArrow() {
    $('.first').addClass('right-arrow-clicked')
    this.isNotesOpen = true
  }
  leftArrow() {
    this.isNotesOpen = false
  }


  closeModal(){
    this.activeModal.close();
  }

  onOptionSelected(event: any): void {
    // console.log('Selected option: ' + event.option.viewValue);
    this.medicaltestForm.get('testName').setValue(event.option.value);
  }
  inventoryIdPresent
  selectedTest(data) {
    if (data && data?.length > 0) {
      this.inventoryIdPresent = true
    }
    else {
      this.inventoryIdPresent = false
    }
  }
  private _filterMe(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsTest.filter(option => option.toLowerCase().includes(filterValue));
  }
  rememberme: boolean = false;templateData;createAsTemplate='N'
  checkboxChecked(){
    if (this.rememberme) {
      this.rememberme = false;
      this.createAsTemplate='N'
    } else {
      this.rememberme = true; 
      this.createAsTemplate='Y'
      // open template form
      const modalRef = this.modalService.open(TemplateFormComponent, {
        centered: false,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'modal-left hw-40 zClass'
      });
      modalRef.result.then(
        (result) => {
          if (result) {
            this.templateData=result
            console.log('print data',result,this.createAsTemplate)
            this.OnSubmit()
          }
        },
        (reason) => {
          // Handle dismissal of modal
        }
      );
    }
  }
  template;templateSelected;templatePresent=false
  searchByTemplateName(data){
    if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
      this.clinicServices.getTemplateById(data).subscribe(res=>{
        this.template=res['body']?.['data']
        this.prescriptions=this.template?.['templatemedication']
        this.medications=this.template?.['templatemedicaltest']
        if(this.template?.['templatemedicaltest']?.length>0 || this.template?.['templatemedicaltest']?.length>0){
          this.templatePresent=true
        }
        if(this.template?.['templatemedication']?.length>0 || this.template?.['templatemedication']?.length>0){
          this.templatePresent=true
        }
        console.log('templete data',this.template)
      },error=>{
        this.toaster.error(error?.error?.message)
      })
    }
    else{
      this.drug.getTemplateDataById(data,this.loginUser?.doctorId).subscribe(res=>{
        this.template=res['data']
        this.prescriptions=this.template?.['medications']
        this.medications=this.template?.['medicaltests']
        if(this.template?.['medications']?.length>0 || this.template?.['medicaltests']?.length>0){
          this.templatePresent=true
        }
        console.log('templete data',this.template)
      },error=>{
        this.toaster.error(error?.error?.message)
      })
    }
  }
  typeAheadSearchByName(value:any){
    this.optionsTemplates = [];
    if(value?.length>1){
      if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
        this.clinicServices.getTemplatesList(value,this.globalStorage.getItem('hospitalAddressId')).subscribe(res => {
          //debounceTime(300), // Wait for 300 ms pause in events
          //distinctUntilChanged(), // Ignore if next search term is same as previous
          console.log('tmeplate search',res)
          this.optionsTemplates = [];
          for (var sp of res['body']?.['data']) {
            // var fullName = sp.firstName + " " + sp.lastName;
            // var fullName = sp.patientname + " " + sp.cellNumber + "" + sp.userId
            var fullName = {
              'prescriptionTemplateId':sp.prescriptionTemplateId,
              'templateName' : sp.templateName,
            };
            // console.log(fullName,"Full name from typeAhead");
    
            this.optionsTemplates.push(fullName);
          }
          // this.optionsD = this.removeDuplicateItems(this.optionsD);
          this.filteredTemplateNames = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filterNames(value || '')),
          ),error=>{
    
          };
      })
      }
      else{
        this.drug.templateSearch(value,this.loginUser?.doctorId).subscribe(res => {
          debounceTime(300), // Wait for 300 ms pause in events
          distinctUntilChanged(), // Ignore if next search term is same as previous
          this.optionsTemplates = [];
          for (var sp of res['data']) {
            // var fullName = sp.firstName + " " + sp.lastName;
            // var fullName = sp.patientname + " " + sp.cellNumber + "" + sp.userId
            var fullName = {
              'templateName' : sp.templateName,
              'prescriptionId':sp.prescriptionId
            };
            // console.log(fullName,"Full name from typeAhead");
    
            this.optionsTemplates.push(fullName);
          }
          // this.optionsD = this.removeDuplicateItems(this.optionsD);
          this.filteredTemplateNames = this.myControl.valueChanges.pipe(
            startWith(''),
            map(value => this._filterNames(value || '')),
          ),error=>{
    
          };
      })
      }
    }
  }
  selectedTemplate(data){
    console.log('selected template',data)
    if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
      this.searchByTemplateName(data?.prescriptionTemplateId)
      this.getLabTests()
      Object.keys(this.medicaltestForm.controls).forEach(key => {
        this.medicaltestForm.controls[key].setErrors(null)
      });
    }
    else{
      this.searchByTemplateName(data?.prescriptionId)
      this.getLabTests()
      Object.keys(this.medicaltestForm.controls).forEach(key => {
        this.medicaltestForm.controls[key].setErrors(null)
      });
    }
  }
}
