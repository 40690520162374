import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cart-details',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.css']
})
export class CartDetailsComponent implements OnInit {
  cartDetails;
  @Input() userId:any;
  constructor(public activeModal: NgbActiveModal,private patientScheduleService:PatientScheduleControlService,private toaster:ToastrService,
    private router:Router
   ) { }

  ngOnInit(): void {
    this.getCartData()
  }
  getCartData(){
    this.patientScheduleService.getCartDetails(this.userId).subscribe(res=>{
      console.log('cart data',res['data'])
      this.cartDetails=res['data']
    },error=>{
      this.toaster.error(error?.error?.['message'])
    })
  }
  viewCart(cartInfo){
    this.activeModal.close(cartInfo)
  }
  deleteCartItem(item){
    this.patientScheduleService.deleteCartDetails(item?.pharmacyAddressId,this.userId).subscribe(res=>{
      this.toaster.success('Cart Item removed')
      this.getCartData()
    },error=>{
      this.toaster.error(error?.error?.['message'])
    })
  }
}
