import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from '../../AppServices/login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'
import { MustMatch } from 'src/app/_helpers/must_match.validators';
import { interval, Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
import { CaretakerService } from 'src/app/shared/services/caretaker.service';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';

@Component({
  selector: 'app-caretaker-forgot-password',
  templateUrl: './caretaker-forgot-password.component.html',
  styleUrls: ['./caretaker-forgot-password.component.css']
})
export class CaretakerForgotPasswordComponent implements OnInit {
  forgotPassword: FormGroup
  submitted = false
  isResend = false
  isSend = true
  message
  isGetOtp = false
  invalidCredentials = false
  successCredentials = false
  isResendOtp = false
  timerOn = true;
  subscription: Subscription;
  subscribeTimer
  timeLeft: number = 120;
  itsCaretaker;
  itsClinic;
  verify;
  uValid: boolean = true;
  eValid: boolean = true;
  showTimer: boolean = false;

  nValid: boolean = true;
  cValid: boolean = true;

  constructor(private loginService: LoginService, private caretakerService: CaretakerService, private clinicService: ClinicService, private toastrService: ToastrService, private router: Router, private fb: FormBuilder) {
    this.forgotPassword = this.fb.group({
      userName: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email]],
      otp: [, Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{8,15})')]],
      confirmPassword: ['', [Validators.required]]
    },
      {
        validator: MustMatch('newPassword', 'confirmPassword')
      })
  }

  // forgotPassword:FormGroup;
  //submitted:boolean=false;
  ngOnInit(): void {

    if (this.router.url == '/caretakerVerify') {
      this.verify = true;
      this.itsCaretaker = true;
      this.itsClinic = false;

    }
    else if (this.router.url == '/clinicVerify') {
      this.verify = true;
      this.itsCaretaker = false;
      this.itsClinic = true;

    }

    else if (this.router.url == '/clinicForgot') {
      this.itsCaretaker = false;
      this.itsClinic = true;

    }

    else if (this.router.url == '/caretakerForgot') {
      this.itsCaretaker = true;
      this.itsClinic = false;
    }

  }
  get f() { return this.forgotPassword.controls; }
  email
  emailId
  newPassword
  OTP
  ChangePassword() {
    this.submitted = true;
    if (this.forgotPassword.invalid) {
      return;
    }

    if (this.itsCaretaker) {
      var request =
      {
        "emailId": this.forgotPassword.value.emailId,
        "newPassword": this.forgotPassword.value.newPassword,
        "otp": parseInt(this.forgotPassword.value.otp)
      }
      this.caretakerService.resetPassword(request).subscribe(res => {
        if (res) {
          this.successCredentials = true
          this.message = res['data']
          // this.toastrService.success("Password changed successfully !")
          this.router.navigate(['/caretakerLogin'])
        }
      }, res => {
        // this.toastrService.error(res['error'].message)
        this.invalidCredentials = true
        this.message = res['error']['message']
      })
    }
    else if (this.itsClinic) {
      var request =
      {
        "emailId": this.forgotPassword.value.emailId,
        "newPassword": this.forgotPassword.value.newPassword,
        "otp": parseInt(this.forgotPassword.value.otp)
      }

      // this.clinicService.createPassword(request).subscribe(res=>{
      //     this.invalidCredentials=false;
      //     this.router.navigate(['/clinicLogin'])
      //   },error=>{
      //     this.invalidCredentials=true
      //   });
    }

  }

  isOtpSent; isResentOtp: boolean = false
  sendOTP() {
    this.invalidCredentials = false
    this.successCredentials = false;
    if (this.forgotPassword.value.emailId != "" && this.forgotPassword.value.userName != "") {
      if (this.itsCaretaker) {
        this.caretakerService.resendEmailOTPForCT(this.forgotPassword.value.emailId, this.forgotPassword.value.userName).subscribe(res => {
          this.successCredentials = true;
          this.message = "Check your email for OTP.";
          this.isOtpSent = true
          this.isResentOtp = true;
          this.showTimer = true;
          this.observableTimer()
        }
          , error => {
            this.invalidCredentials = true;
            this.message = error['error']['message'];
          });
      }
      else if (this.itsClinic) {
        this.clinicService.resneEmailOtpforH(this.forgotPassword.value.emailId, this.forgotPassword.value.userName).subscribe(res => {
          this.successCredentials = true;
          this.message = "Check your email for OTP.";
          this.isOtpSent = true
          this.isResentOtp = true
          this.showTimer = true;
          this.observableTimer();
        }, error => {
          this.invalidCredentials = true;
          this.message = error['error'];
        })
      }
    } else {
      this.invalidCredentials = true
      this.message = "Please enter user and emailId"
    }

  }
  isOtpValid = true
  otpCheck(otp) {

    if (this.itsCaretaker) {
      if (otp.length == 6) {
        this.caretakerService.otpVerify(this.forgotPassword.value.emailId, otp).subscribe(res => {
          this.isOtpValid = true;
          this.showTimer = false;
          this.uValid = true;
          this.eValid = true;

        }, error => {
          this.isOtpValid = false
          this.successCredentials = false
          this.invalidCredentials = true
          this.message = error['error']['message']
        })
      }
      else {
        this.invalidCredentials = false
      }
    }
    else if (this.itsClinic) {
      if (otp.length == 6) {

        this.clinicService.emailVerifyforH(this.forgotPassword.value.emailId, otp).subscribe(res => {
          this.isOtpValid = true;
          this.showTimer = false;
          this.uValid = true;
          this.eValid = true;

        }, error => {
          this.isOtpValid = false
          this.successCredentials = false
          this.invalidCredentials = true
          this.message = error['error']['message']
        })
      }
      else {
        this.invalidCredentials = false
      }

    }
  }
  observableTimer() {
    if (this.forgotPassword.value.emailId == "") {
      return
    }
    this.timeLeft = 120
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isSend = false
        this.isResend = true
        this.isResentOtp = false
        this.isGetOtp = false
        this.isResendOtp = false
        this.toastrService.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  showHidePwd() {
    if ($('#show_hide_password input').attr("type") == "text") {
      $('#show_hide_password input').attr('type', 'password');
      $('#show_hide_password i').addClass("fa-eye-slash");
      $('#show_hide_password i').removeClass("fa-eye");
    } else if ($('#show_hide_password input').attr("type") == "password") {
      $('#show_hide_password input').attr('type', 'text');
      $('#show_hide_password i').removeClass("fa-eye-slash");
      $('#show_hide_password i').addClass("fa-eye");
    }
  }
  showHidePwd1() {
    if ($('#show_hide_password1  input').attr("type") == "text") {
      $('#show_hide_password1 input').attr('type', 'password');
      $('#show_hide_password1 i').addClass("fa-eye-slash");
      $('#show_hide_password1 i').removeClass("fa-eye");
    } else if ($('#show_hide_password1 input').attr("type") == "password") {
      $('#show_hide_password1 input').attr('type', 'text');
      $('#show_hide_password1 i').removeClass("fa-eye-slash");
      $('#show_hide_password1 i').addClass("fa-eye");
    }
  }
  checkUserName() {
    if (this.itsCaretaker) {
      this.invalidCredentials = false
      this.caretakerService.userNameExistOrNot(this.forgotPassword.controls['userName'].value).subscribe(res => {
        if (res['data'] == false) {
          this.invalidCredentials = true
          this.message = res['message']
          this.submitted = false;
          this.uValid = true;
          return
        }
        else {
          this.uValid = false;
        }
      }, error => {

        this.toastrService.error("User id doesnt exist");
      });
    }
    else if (this.itsClinic) {
      this.invalidCredentials = false;
      this.clinicService.checkHospitalUsername(this.forgotPassword.value.userName).subscribe(res => {
        if (res['data'] == false) {
          this.invalidCredentials = true
          this.message = res['message']
          this.submitted = false;
          this.uValid = true;
          return
        }
        else {
          this.uValid = false;
        }

      }, error => {
        this.invalidCredentials = true;
        this.message = error['error'];
      })

    }
  }
  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z\_]+$/);
    return pattern.test(value);
  }

  emailEntered() {
    if (this.forgotPassword.controls['emailId'].status === "VALID")
      this.eValid = false;

    else
      this.eValid = true;
  }

  npEntered() {
    if (this.forgotPassword.controls['newPassword'].status === "VALID") {
      this.nValid = false;
    }
    else {
      this.nValid = true;
    }
  }


  cpEntered() {
    if (this.forgotPassword.controls['confirmPassword'].status === "VALID") {
      this.cValid = false;
    }
    else {
      this.cValid = true;
    }
  }
  verifyNow() {
    if (this.itsCaretaker) {
      this.caretakerService.resendEmailOTPForCT(this.forgotPassword.controls['emailId'].value, this.forgotPassword.controls['userName'].value).subscribe(res => {
        const navigationExtras: NavigationExtras = {
          state: {
            emailId: this.forgotPassword.controls['emailId'].value,
            mobileNumber: res['data'],
            userName: this.forgotPassword.controls['userName'].value
          }
        };
        this.router.navigate(['/otp-verifcation-caretaker'], navigationExtras)
      });
    }
    else if (this.itsClinic) {
      this.clinicService.resneEmailOtpforH(this.forgotPassword.controls['emailId'].value, this.forgotPassword.controls['userName'].value).subscribe(res => {
        const navigationExtras: NavigationExtras = {
          state: {
            emailId: this.forgotPassword.controls['emailId'].value,
            mobileNumber: res['data'],
            userName: this.forgotPassword.controls['userName'].value
          }
        };
        this.router.navigate(['/otp-verification-clinic'], navigationExtras)
      })
    }
  }

}
