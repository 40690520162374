<div style="padding: 10px;">
  
    <mat-expansion-panel #expPnlAppointment style="margin-top:15px;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Appintment Details
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="container" style="padding:10px">
      <div class="row">
      <div class="col-md-4">
        <label>Ailment</label>
        <input type="text" class="form-control" [(ngModel)]="appointmentDetails && appointmentDetails.ailment">
      </div>
      <!-- <div class="col-md-4">
        <label>Name</label>
        <input type="text" class="form-control" [(ngModel)]="appointmentDetails && appointmentDetails.patientName">
      </div> 
      <div class="col-md-4">
        <label>Doctor Name</label>
        <input type="text" class="form-control"
          [(ngModel)]="appointmentDetails && appointmentDetails.referenceEntityName">
      </div>-->
      <!-- <div class="col-md-4">
        <label>Appointment Date</label>
        <input type="text" class="form-control" [(ngModel)]="appointmentDetails && appointmentDetails.appointmentDate">
      </div> -->
      <div class="col-md-4">
        <label>Appointment For</label>
        <input type="text" class="form-control" [(ngModel)]="appointmentDetails && appointmentDetails.appointmentFor">
      </div>
      <div class="col-md-4">
        <label>From Time</label>
        <input type="text" class="form-control" [(ngModel)]="appointmentDetails && appointmentDetails.fromTime">
      </div>
      <div class="col-md-4">
        <label>To Time</label>
        <input type="text" class="form-control" [(ngModel)]="appointmentDetails && appointmentDetails.toTime">
      </div>
  
      <div class="col-md-4">
        <label>Description</label>
        <input type="text" class="form-control" [(ngModel)]="appointmentDetails && appointmentDetails.description">
      </div>
      <div class="col-md-4">
        <label>Amount Paid</label>
        <input type="text" class="form-control" [(ngModel)]="appointmentDetails && appointmentDetails.amountPaid">
      </div>
    </div>
  </div>
    </mat-expansion-panel>

    <mat-expansion-panel #prescription style="margin-top:15px;" (click)="viewPrecription(appointmentDetails)">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Prescription Details
        </mat-panel-title>
      </mat-expansion-panel-header>
    <app-prescription-list [patientPrescription]="patientPrescription"></app-prescription-list>
    </mat-expansion-panel>
   
    <mat-expansion-panel #vedeo style="margin-top:15px;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Video Details
        </mat-panel-title>
      </mat-expansion-panel-header>
       <div style="margin-left:40px;padding:10px;">
      <div class="player-wrapper" style="width:500px;height: 300px;">
      
        <!-- <h3>Video Player with Controls</h3> -->
    
        <vg-player>
          <vg-overlay-play></vg-overlay-play>
          <vg-buffering></vg-buffering>
    
          <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
          </vg-scrub-bar>
    
          <vg-controls>
            <vg-play-pause></vg-play-pause>
            <vg-playback-button></vg-playback-button>
    
            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>
    
            <vg-scrub-bar style="pointer-events: none;"></vg-scrub-bar>
    
            <vg-time-display vgProperty="left" vgFormat="mm:ss"></vg-time-display>
            <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>
    
            <vg-track-selector></vg-track-selector>
            <vg-mute></vg-mute>
            <vg-volume></vg-volume>
    
            <vg-fullscreen></vg-fullscreen>
          </vg-controls>
    
          <video #media [vgMedia]="media" id="singleVideo" preload="auto" style="width:500px;height: 300px;">
           
            <!-- <source src="http://static.videogular.com/assets/videos/videogular.ogg" type="video/ogg">
            <source src="http://static.videogular.com/assets/videos/videogular.webm" type="video/webm"> -->
    
            <track kind="subtitles" label="English" src="assets/subs/pale-blue-dot.vtt" srclang="en" default>
            <track kind="subtitles" label="Español" src="assets/subs/pale-blue-dot-es.vtt" srclang="es">
    
          </video>
        </vg-player>
      </div>
      
      
      <table style="margin-left:5px;margin-top:10px">
        <tr>
          <th>Recording Date</th>
          <th>Recording Link</th>
          <th></th>
        </tr>
        <tbody>
          <tr>
            <td>21-12-2020</td>
            <td>Link</td>
            <td></td>
          </tr>
        </tbody>
      </table> 
    </div>
    </mat-expansion-panel>
  
  </div>
