import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-consent-form',
  templateUrl: './consent-form.component.html',
  styleUrls: ['./consent-form.component.css']
})
export class ConsentFormComponent implements OnInit {
  consent: boolean = false;

 
  @Input() data;
  @Input() isChecked: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.isChecked) {
  //     console.log(changes.isChecked.currentValue)
  //     this.consent = changes.isChecked.currentValue
  //   }
  // }

  agreedForm() {
    
    this.activeModal.close(this.consent);

  }

}
