import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DiagnosticService } from 'src/app/AppServices/diagnostic.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { gpsService } from 'src/app/shared/services/gps.service';
import { PatientService } from 'src/app/shared/services/patient.service';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import { MustMatch, NoMatch } from 'src/app/_helpers/must_match.validators';
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
import { ClinicService } from '../clinic.service';

@Component({
  selector: 'app-clinic-registration',
  templateUrl: './clinic-registration.component.html',
  styleUrls: ['./clinic-registration.component.css']
})
export class ClinicRegistrationComponent implements OnInit {
  registerForm: FormGroup;
  isExist = false
  Url
  registerForms: FormGroup;
  loading = false;
  submitted = false;
  cond_vall = false
  isEmailExists = false
  isEmailExistsMessage
  isUserNameExists = false
  isUserNameExistsMessage
  isPhoneNumberExists = false
  isPhoneNumberExistsMessage
  message; hospitalId
  invalidCredentials: boolean = false
  successCredentials: boolean = false; postalLocations; selectedValue; searchTxt;
  telOptions = { initialCountry: 'us' };
  disableByZip = false;
  constructor(private globalStorage: GlobalStorageService, 
    private formBuilder: FormBuilder, private script: ScriptsService, private patientService: PatientService,
    private router: Router, private toastr: ToastrService, private gps: gpsService,
    private pharmacyservice: pharmaService, private diagnosticService: DiagnosticService,
    private service: AuthService, public clinicServices: ClinicService) { }
  // (^(?=.{10,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$)
  // ((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      middleName: [''],
      hospitalName: ['', [Validators.required, Validators.minLength(3)]],
      branchCode: ['', [Validators.required,Validators.minLength(4), Validators.maxLength(15)]],
      name: ['hello', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,4}$")]],
      contactNum: ['', [Validators.required]],
      licenceNumber: ['', Validators.required],
      gstin: ['', Validators.required],
      pharmacyGstin: ['', [Validators.required]],
      diagnosticcenterGstin: ['', [Validators.required]],
      pharmacyLicencenumber: ['', [Validators.required, Validators.pattern("[a-zA-Z0-9][A-Za-z0-9-/]*")]],
      diagnosticcenterLicencenumber: ['', [Validators.required, Validators.pattern("[a-zA-Z0-9][A-Za-z0-9-/]*")]],
      userName: ['', [Validators.required, Validators.minLength(3)]],
      pharmacy: [''],
      dc: [],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', [Validators.required, Validators.minLength(6)]],
      cc:['', Validators.required],
      longitude: [],
      latitude: [],
      postalLocation: ['', Validators.required],
      password: ['', [Validators.required,
      Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)*\\S{8,15})')]],
      confirmPassword: ['', [Validators.required]]
    },
      {
        validator: [MustMatch('password', 'confirmPassword'), NoMatch('firstName', 'lastName'), NoMatch('gstin', 'pharmacyGstin'),
        NoMatch('gstin', 'diagnosticcenterGstin'), NoMatch('pharmacyGstin', 'diagnosticcenterGstin'),
        NoMatch('pharmacyLicencenumber', 'diagnosticcenterLicencenumber'), NoMatch('licenceNumber', 'diagnosticcenterGstin'),
        NoMatch('licenceNumber', 'pharmacyLicencenumber')]
      }
    );
    if ($("#dc").prop('checked') == true) {
      this.dcNeed = 'Y'
    }
    else {
      this.dcNeed = 'N'
      this.registerForm.get('gstin').clearValidators();
      this.registerForm.get('gstin').updateValueAndValidity();
      this.registerForm.get('licenceNumber').clearValidators();
      this.registerForm.get('licenceNumber').updateValueAndValidity();
      this.registerForm.get('diagnosticcenterLicencenumber').clearValidators();
      this.registerForm.get('diagnosticcenterLicencenumber').updateValueAndValidity();
      this.registerForm.get('diagnosticcenterGstin').clearValidators();
      this.registerForm.get('diagnosticcenterGstin').updateValueAndValidity();
      this.registerForm.patchValue({
        diagnosticcenterGstin: 'dcGstn',
        diagnosticcenterLicencenumber: 'dcLicence'
      })
    }
    if ($("#pharmacy").prop('checked') == true) {
      this.pharmacyNeed = 'Y'
    }
    else {
      this.pharmacyNeed = 'N'
      this.registerForm.get('pharmacyLicencenumber').clearValidators();
      this.registerForm.get('pharmacyLicencenumber').updateValueAndValidity();
      this.registerForm.get('pharmacyGstin').clearValidators();
      this.registerForm.get('pharmacyGstin').updateValueAndValidity();
      this.registerForm.patchValue({
        pharmacyLicencenumber: 'pharmaLic',
        pharmacyGstin: 'pharmaGst',
      })
    }
    this.globalStorage.setItem('registeredEmail', JSON.stringify(this.registerForm.value.email));
    var input = document.querySelector("#telephone");
    jQuery(document).ready(function ($) {
      (<any>window).intlTelInputGlobals.loadUtils("./../../../assets/build/js/utils.js");

      if (jQuery('#telephone').length) {
        var input = document.querySelector("#telephone");
        (<any>window).intlTelInput(input, {
          preferredCountries: ['in'],
          separateDialCode: true, placeholderNumberType: "MOBILE",
          autoPlaceholder: "polite", autoHideDialCode: true,
          hiddenInput: "",

          customPlaceholder: null,
          formatOnDisplay: true,

        });
      }

    });
    input.addEventListener("countrychange", function () {
      (<any>input).intlTelInput();
      (<any>input).val('')
    });
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  dcNeed; pharmacyNeed;
  onSubmit(item: any) {
    this.checkEmail()
    this.checkPhoneNumber(this.registerForm.value['contactNum'])
    this.checkUserName()
    this.checkLicenseNumber()
    this.submitted = true;
    //   this.service.userExist(this.registerForm.controls['email'].value).subscribe(res=>{
    //     if(res.data==true){
    //       this.invalidCredentials=true
    //       this.message="This email address has already exist."
    //       return
    //     }
    // },(error)=>{
    //   this.toastr.error((error['error']['message']))
    // });
    // this.service.checkPhoneNumber(this.registerForm.controls['contactNum'].value).subscribe(res=>{
    //   if(res.data==true){
    //     this.invalidCredentials=true
    //     this.message="This Phone number has already exist"
    //     return
    //   }})
    if ($("#dc").prop('checked') == true) {
      this.dcNeed = 'Y'
    }
    else {
      this.dcNeed = 'N'
      this.registerForm.get('gstin').clearValidators();
      this.registerForm.get('gstin').updateValueAndValidity();
      this.registerForm.get('licenceNumber').clearValidators();
      this.registerForm.get('licenceNumber').updateValueAndValidity();
      this.registerForm.get('diagnosticcenterLicencenumber').clearValidators();
      this.registerForm.get('diagnosticcenterLicencenumber').updateValueAndValidity();
      this.registerForm.get('diagnosticcenterGstin').clearValidators();
      this.registerForm.get('diagnosticcenterGstin').updateValueAndValidity();
      this.registerForm.patchValue({
        diagnosticcenterGstin: 'dcGstn',
        diagnosticcenterLicencenumber: 'dcLicence'
      })
    }
    if ($("#pharmacy").prop('checked') == true) {
      this.pharmacyNeed = 'Y'
    }
    else {
      this.pharmacyNeed = 'N'
      this.registerForm.get('pharmacyLicencenumber').clearValidators();
      this.registerForm.get('pharmacyLicencenumber').updateValueAndValidity();
      this.registerForm.get('pharmacyGstin').clearValidators();
      this.registerForm.get('pharmacyGstin').updateValueAndValidity();
      this.registerForm.patchValue({
        pharmacyLicencenumber: 'pharmaLic',
        pharmacyGstin: 'pharmaGst',
      })
    }
    if (this.registerForm.invalid) {
      return;
    }
    if (this.isExist) {
      return;
    }

    var hospitalReq = {
      "cellNumber": $('.iti__selected-dial-code').text() + this.registerForm.value['contactNum'],
      "details": "test",
      "branchCode": this.registerForm.value.branchCode,
      "pharmacyGstin": this.registerForm.value.pharmacyGstin,
      "diagnosticcenterGstin": this.registerForm.value.diagnosticcenterGstin,
      "pharmacyLicencenumber": this.registerForm.value.pharmacyLicencenumber,
      "diagnosticcenterLicencenumber": this.registerForm.value.diagnosticcenterLicencenumber,
      "gstInr": this.registerForm.value.gstin,
      "hospitaladdress": {
        "address1": this.registerForm.value.address1,
        "address2": this.registerForm.value.address2,
        "city": this.registerForm.value.city,
        "country": this.registerForm.value.country,
        "gstin": this.registerForm.value.gstin,
        "latitude": this.latitude,
        "longitude": this.longitude,
        "postalLocation": this.registerForm.value.postalLocation?.postalLocation,
        "state": this.registerForm.value.state,
        "zip": this.registerForm.value.zip
      },
      "hospitaluser": {
        "cellNumber": $('.iti__selected-dial-code').text() + this.registerForm.value['contactNum'],
        "emailId": this.registerForm.value.email,
        "userName": this.registerForm.value.userName,
        "firstName": this.registerForm.value.firstName.charAt(0).toUpperCase()+this.registerForm.value.firstName.slice(1),
        "gstInr": this.registerForm.value.gstin,
        // "homeNumber": " ",
        "branchCode": this.registerForm.value.branchCode,
        "hospitalName": this.registerForm.value.hospitalName,
        "lastName": this.registerForm.value.lastName.charAt(0).toUpperCase()+this.registerForm.value.lastName.slice(1),
        "licencenumber": this.registerForm.value.licenceNumber,
        "middleName": this.registerForm.value.middleName,
        "password": this.registerForm.value.password,
        "primaryContact": "CELL_NUMBER"
      },
      "name": this.registerForm.value.hospitalName,
      "primaryUserEmailId": this.registerForm.value.email,
      "isPharmacyRegister": this.pharmacyNeed,
      "isdiagnosticcenterRegister": this.dcNeed
    }
    var request =
    {
      "cellNumber": $('.iti__selected-dial-code').text() + this.registerForm.value['contactNum'],
      "details": "test",

      "hospitaladdress": {
        "address1": this.registerForm.value.address1,
        "address2": this.registerForm.value.address2,
        "city": this.registerForm.value.city,
        "country": this.registerForm.value.country,
        "latitude": this.latitude,
        "longitude": this.longitude,
        "postalLocation": this.registerForm.value.postalLocation?.postalLocation,
        "state": this.registerForm.value.state,
        "zip": this.registerForm.value.zip
      },

      "hospitaluser": {
        "cellNumber": $('.iti__selected-dial-code').text() + this.registerForm.value['contactNum'],
        "emailId": this.registerForm.value.email,
        "firstName": this.registerForm.value.firstName,
        "gstInr": this.registerForm.value.gstin,
        //"homeNumber": " ",
        "branchCode": this.registerForm.value.branchCode,
        "hospitalName": this.registerForm.value.hospitalName,
        "lastName": this.registerForm.value.lastName,
        "licencenumber": this.registerForm.value.licenceNumber,
        "middleName": this.registerForm.value.middleName,
        "password": this.registerForm.value.password,
        'primaryContact': 'CELL_NUMBER'
      },
      "name": this.registerForm.value.hospitalName,
      "primaryUserEmailId": this.registerForm.value.email
    }

    if (this.isEmailExists == false && this.isPhoneNumberExists == false && this.isUserNameExists == false) {
      this.clinicServices.register(hospitalReq).subscribe(res => {
        this.hospitalId = res?.['body']?.['data']?.['hospitaladdressId']
        // if ($("#pharmacy").prop('checked') == true) {
        //   var request = {
        //     "active": 'Y',
        //     "emailId": this.registerForm.value.email,
        //     "licenseNumber": this.registerForm.value.licenceNumber,
        //     "name": this.registerForm.value.hospitalName,
        //     "phoneNumber": this.registerForm.value['contactNum'],
        //     "hospitaladdressId": this.hospitalId,
        //     "secondaryPhoneNumber": "",
        //     "primaryPharmacyAddress": {
        //       "address1": this.registerForm.value.address1,
        //       "address2": this.registerForm.value.address2,
        //       "city": this.registerForm.value.city,
        //       "country": this.registerForm.value.country,
        //       "isPrimary": "Y",
        //       "state": this.registerForm.value.state,
        //       "zip": this.registerForm.value.zip,
        //       "postalLocation": this.registerForm.value.postalLocation,
        //       "latitude": this.gps.latitude,
        //       "longitude": this.gps.longitude,
        //     },
        //     "primaryPharmacyUser": {
        //       "emailId": this.registerForm.value.email,
        //       "firstName": this.registerForm.value.firstName,
        //       "lastName": this.registerForm.value.lastName,
        //       "middleName": this.registerForm.value.middleName,
        //       "password": this.registerForm.value.password,
        //       "cellNumber": this.registerForm.value['contactNum'],
        //       'primaryContact': 'CELL_NUMBER'

        //     },
        //     "uiHostURL": window.location.origin,
        //     "cellNumber": this.registerForm.value['contactNum']
        //   }
        //   this.pharmacyservice.globalPostPharma('register', (request)).subscribe(res => {
        //   }, error => {
        //   })
        // }
        // if ($("#dc").prop('checked') == true) {
        //   var newRegistration = {
        //     "details": "test",
        //     "name": this.registerForm.value.hospitalName,
        //     "hospitaladdressId": this.hospitalId,
        //     "newlyRegistered": "test",
        //     // "diagnosticCenterId": 0,
        //     "primaryDataCenterAddress": {
        //       "address1": this.registerForm.value.address1,
        //       "address2": this.registerForm.value.address2,
        //       "city": this.registerForm.value.city,
        //       "country": this.registerForm.value.country,
        //       "isPrimary": "Y",
        //       "state": this.registerForm.value.state,
        //       "zip": this.registerForm.value.zip,
        //       "postalLocation": this.registerForm.value.postalLocation,
        //       "latitude": this.gps.latitude,
        //       "longitude": this.gps.longitude,
        //     },
        //     "primaryDiagnosticcenteruser": {
        //       "cellNumber": this.registerForm.value.contactNum,
        //       //"diagnosticCenterAddressId": 0,
        //       "diagnosticName": this.registerForm.value.hospitalName,
        //       "emailId": this.registerForm.value.email,
        //       "firstName": this.registerForm.value.firstName,
        //       "gstInr": this.registerForm.value.gstInr,
        //       "homeNumber": "test",
        //       "lastName": this.registerForm.value.lastName,
        //       "licencenumber": this.registerForm.value.licenceNumber,
        //       "middleName": this.registerForm.value.middleName,
        //       // "officeNumber":this.registerForm.value.contactNum,
        //       "password": this.registerForm.value.password,
        //       "primaryContact": "HOME_NUMBER",
        //     },
        //     "uiHostURL": window.location.origin,
        //     "cellNumber": this.registerForm.value.contactNum
        //   }
        //   this.diagnosticService.registerDiagnostic(newRegistration).subscribe(res => {
        //   }, error => {
        //   })
        // }
        //this.router.navigate(['/succesfullRegistration'])
        const navigationExtras: NavigationExtras = {
          state: {
            emailId: this.registerForm.value.email,
            mobileNumber: $('.iti__selected-dial-code').text() + this.registerForm.value['contactNum'],
            userName: this.registerForm.value.userName
          }
        };
        this.router.navigate(['/otp-verification-clinic'], navigationExtras)
        this.successCredentials = true
        this.message = res['body']['message']
        //this.toastr.success(res['body']['message'])

      }, error => {
        this.invalidCredentials = true
        this.message = error['error']['message']
        //this.toastr.error(error['error']['message'])
        //this.router.navigate(['/FailureRegistration'])
      })
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  alphaOnly(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/[a-zåäö ]/i);
    return pattern.test(value);
  };
  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z]+$/);
    return pattern.test(value);
  }

  omit_special_char_and_numbers(e) {
    var k;
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);
  }

  checkEmail() {
    this.invalidCredentials = false
    if (this.f.email.errors == null) {
      this.isExist = false
      this.clinicServices.userExist(this.registerForm.controls['email'].value).subscribe(res => {
        if (res?.['data'] == true) {
          this.invalidCredentials = true
          var ema = document.getElementById('directLink').innerHTML
          $('#directLink').addClass("email");
          //  this.message = res?.['message']
          this.isEmailExistsMessage = res?.['message']
          // this.toastr.error("A user with this email address has already been registered in the system and the associated already exists.");
          this.isExist = true
          this.submitted = false;
          this.isEmailExists = true
          return
        }
        else {
          this.isEmailExists = false
        }
      }, (error) => {
        // this.toastr.error((error['error']['message']))

      });
    }
  }

  checkUserName() {
    this.invalidCredentials = false
    if (this.f.userName.errors == null) {
      this.isExist = false
      this.service.userNameExistForHospital(this.registerForm.controls['userName'].value).subscribe(res => {
        if (res?.['data'] == true) {
          this.invalidCredentials = true
          //  this.message = res?.['message']
          this.isUserNameExists = true
          this.isUserNameExistsMessage = res?.['message']
          //   this.toastr.error("A user with this email address has already been registered in the system and the associated already exists.");
          this.isExist = true
          this.submitted = false;
          return
        }
        else {
          this.isUserNameExists = false
        }
      }, (error) => {
        // this.toastr.error((error['error']['message']))

      });
    }
  }

  checkPhoneNumber(num) {
    var number = $('.iti__selected-dial-code').text() + num
    this.invalidCredentials = false
    this.clinicServices.checkPhoneNumber(number.replace(/\+/gi, '%2B')).subscribe(res => {
      if (res?.['data'] == true) {
        this.invalidCredentials = true
        // this.message = res?.['message']
        this.isPhoneNumberExists = true
        this.isPhoneNumberExistsMessage = res?.['message']
        return
      }
      else {
        this.isPhoneNumberExists = false
      }
    }, error => {
      this.message = error?.['error']?.['message']
    });
  }
  showError
  percChanged(Zip) {
    if (Zip.includes(this.registerForm.value.firstName)) {
      this.showError = true
    }
  }

  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });

    $("#show_hide_password1 a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password1 input').attr("type") == "text") {
        $('#show_hide_password1 input').attr('type', 'password');
        $('#show_hide_password1 i').addClass("fa-eye-slash");
        $('#show_hide_password1 i').removeClass("fa-eye");
      } else if ($('#show_hide_password1 input').attr("type") == "password") {
        $('#show_hide_password1 input').attr('type', 'text');
        $('#show_hide_password1 i').removeClass("fa-eye-slash");
        $('#show_hide_password1 i').addClass("fa-eye");
      }
    });
  }

  createPharmacy() {
    if ($("#pharmacy").prop('checked') == true) {
      this.pharmacyNeed = 'Y'
      this.registerForm.patchValue({
        pharmacyLicencenumber: '',
        pharmacyGstin: '',
      })
    }
    else {
      this.pharmacyNeed = 'N'
      this.registerForm.get('pharmacyLicencenumber').clearValidators();
      this.registerForm.get('pharmacyLicencenumber').updateValueAndValidity();
      this.registerForm.get('pharmacyGstin').clearValidators();
      this.registerForm.get('pharmacyGstin').updateValueAndValidity();
      this.registerForm.patchValue({
        pharmacyLicencenumber: 'pharmaLic',
        pharmacyGstin: 'pharmaGst',
      })
    }
  }
  createDc() {
    if ($("#dc").prop('checked') == true) {
      this.dcNeed = 'Y'
      this.registerForm.patchValue({
        diagnosticcenterGstin: '',
        diagnosticcenterLicencenumber: ''
      })
    }
    else {
      this.dcNeed = 'N'
      this.registerForm.get('gstin').clearValidators();
      this.registerForm.get('gstin').updateValueAndValidity();
      this.registerForm.get('licenceNumber').clearValidators();
      this.registerForm.get('licenceNumber').updateValueAndValidity();
      this.registerForm.get('diagnosticcenterLicencenumber').clearValidators();
      this.registerForm.get('diagnosticcenterLicencenumber').updateValueAndValidity();
      this.registerForm.get('diagnosticcenterGstin').clearValidators();
      this.registerForm.get('diagnosticcenterGstin').updateValueAndValidity();
      this.registerForm.patchValue({
        diagnosticcenterGstin: 'dcGstn',
        diagnosticcenterLicencenumber: 'dcLicence'
      })
    }
  }
  zip1; countryCodeSelected
  perChanged(zip) {
    if (zip.length == 6) {
      this.zip1 = zip;
      this.countryCodeSelected = null;
      this.registerForm.patchValue({
        country: '',
        city: '',
        state: ''
      });
    }
  }
  latitude; longitude;
  countryCodeEntered(cc) {
    this.selectedValue = null;
    this.patientService.getPincodeDetails(this.zip1, cc).subscribe(res => {
      if (res['data'].length == 0) {
        this.registerForm.patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.toastr.error("Incorrect pincode")
      }
      this.postalLocations = res['data'];
      this.latitude = res['data'][0]['latitude'];
      this.longitude = res['data'][0]['longitude'];
      this.registerForm.patchValue({
        country: res['data'][0]['country'],
        city: res['data'][0]['province'],
        state: res['data'][0]['state'],
        latitude: res['data'][0]['latitude'],
        longitude: res['data'][0]['longitude']

      })
    })
  }
  drpdownSelection(e) {
    this.latitude = e?.latitude
    this.longitude = e?.longitude
  }
  getZip(Zip) {
    this.patientService.getAddressAll(Zip).subscribe(res => {
      if (res['result'].length == 0) {
        this.registerForm.patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.toastr.error("Incorrect pincode")
      }
      this.postalLocations = res['result']
      this.registerForm.patchValue({
        country: res['result'][0]['country'],
        city: res['result'][0]['province'],
        state: res['result'][0]['state']
      });
    });

    this.disableByZip = true;

  }
  licensemessage
  checkLicenseNumber(){
    if (this.f.licenceNumber.errors == null) {
      this.isExist = false
    this.clinicServices.checkLicenseNumber(this.registerForm?.value?.licenceNumber).subscribe(res=>{
      if (res?.['data'] == true) {
      this.invalidCredentials = true
        this.licensemessage = res?.['message']
      this.isUserNameExists = true
      this.submitted = false;
      return
    }
    else{
      this.invalidCredentials = false
      this.licensemessage = ''
    }
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
}
}
