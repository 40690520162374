import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appInputRestrict]'
})
export class InputRestrictDirective {
  @Input() appInputRestrict: string;

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    let regex: RegExp;

    switch (this.appInputRestrict) {
      case 'numberOnly':
        regex = /[^0-9]/g;
        break;
      case 'decimalOnly':
        regex = /[^0-9.]/g;
        break;
      case 'alphaOnlyWithSpaces':
        regex = /[^a-zA-Z ]/g;
        break;
      case 'alphaOnly':
        regex = /[^a-zA-Z]/g;
        break;
      case 'alphaNumeric':
        regex = /[^a-zA-Z0-9]/g;
        break;
      case 'mobileNumber':
        regex = /[^0-9]/g; // Allow only numbers
        this.validateMobileNumber();
        break;

        case 'licenseNumber':
          regex = /[^a-zA-Z0-9\/\\\-_]/g;
          break;
      case 'indianCurrency':
        regex = /[^0-9]/g;
        this.formatAsIndianCurrency();
        break;
      default:
        regex = /./g; // Default case to allow everything
    }

    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue.replace(regex, '');
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
  validateMobileNumber() {
    const value = this.el.nativeElement.value;
    if (value && value.length > 0 && !(parseInt(value[0]) > 5)) {
      this.el.nativeElement.value = '';
    }
  }

  formatAsIndianCurrency() {
    let value = this.el.nativeElement.value.replace(/[^0-9]/g, '');
    if (!value) return;

    let formattedValue = parseInt(value).toLocaleString('en-IN');
    this.el.nativeElement.value = `₹ ${formattedValue}`;
  }
}
