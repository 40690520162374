<div class="modal-content" style="width:300px">

  <div class="modal-body cancel-appoi">

    <div class="d-flex flex-row mb-0">


      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
        <p class=" mb-1 mt-1">

          <i class="fa fa-calendar-times fa-2x "> </i>
        </p>


        <p class="font-weight-medium mb-1 mt-1 heading">{{appintmentData?.patientName}}
        </p>
        <p class="  mb-1 mt-1"> {{appintmentData?.appointmentDate |date:'dd-MMM-yyyy'}} {{appintmentData?.fromTime |
          convertFrom24To12Format}} - {{appintmentData?.toTime | convertFrom24To12Format}}</p>
      </div>
    </div>
  </div>

  <div [formGroup]="cancelReasonForm" class="px-3 pt-2">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label for="">Reason <span class="text-danger">*</span></label>
          <textarea autoResizeRows rows="3" class="form-control" #cancelreason formControlName="reason"
            placeholder="Enter a reason for cancellation"
            [ngClass]="{'is-invalid' : (this.cancelReasonForm.controls.reason.touched) && this.cancelReasonForm.controls.reason.errors}"></textarea>
          <div *ngIf="(this.cancelReasonForm.controls.reason.touched) && this.cancelReasonForm.controls.reason.errors"
            class="invalid-feedback">
            <div *ngIf="this.cancelReasonForm.controls.reason.errors.required"> Please
              enter a reason. </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer text-center">
    <button type="button" (click)="cancelAppontment(cancelreason.value)" class="btn w-100 border"
      [disabled]="cancelReasonForm.invalid">Cancel Appointment</button>
  </div>
</div>