<div class="l-sidebar" id="open-menu">
	<div class="logo">
		<div class="logo__txt"><img alt="logo-img" class="img-center" id="logo-img" src="./assets/img/logo-white.png"></div>
	</div>
	<div class="l-sidebar__content">
		<nav class="c-menu js-menu">
			<ul class="u-list">
				<li class="c-menu__item has-submenu" data-toggle="tooltip" title="" data-original-title="Home">
					<a href="javascript:void(0)" routerLink="/caretaker-dashboard">
						<div class="c-menu__item__inner bg-warning-light">
							<i class="fa fa-home"></i>
							<div class="c-menu-item__title"><span>Home</span></div>
						</div>
					</a>
				</li>
				<li class="c-menu__item has-submenu is-active" data-toggle="tooltip" title="" data-original-title="Appointments">
					<a href="javascript:void(0)" routerLink="/caretaker-appointments">
						<div class="c-menu__item__inner bg-success-light">
							<i class="fa fa-calendar-check"></i>
							<div class="c-menu-item__title"><span>Appoinments</span></div>
						</div>
					</a>
				</li>
				<li class="c-menu__item has-submenu " data-toggle="tooltip" title="" data-original-title="Patients"
				*ngIf="isAssociatedHosp()">
					<a href="javascript:void(0)" routerLink="/caretaker-assignedUsers">
						<div class="c-menu__item__inner bg-primary-light">
							<i class="fa fa-user-injured " aria-hidden="true"></i>
							<div class="c-menu-item__title"><span>Patients</span></div>
						</div>
					</a>
				</li>
				<li class="c-menu__item has-submenu " data-toggle="tooltip" title="" data-original-title="Calendar" *ngIf="isrpmcaretaker">
					<a href="javascript:void(0)" routerLink="/caretakerCalendar">
					  <div class="c-menu__item__inner bg-primary-light">
						<i class="fa fa-calendar-day"></i>
						<div class="c-menu-item__title"><span>Calendar</span></div>
					  </div>
					</a>
				  </li>
				<li class="c-menu__item has-submenu" data-toggle="tooltip" title="" data-original-title="Base Scheduler" *ngIf="isrpmcaretaker">
					<a href="javascript:void(0)" routerLink="/caretaker-baseSchedule">
						<div class="c-menu__item__inner bg-success-light">
							<i class="fa fa-calendar-alt"></i>
							<div class="c-menu-item__title"><span>Base Scheduler</span></div>
						</div>
					</a>
				</li>
				<li class="c-menu__item has-submenu" data-toggle="tooltip" title="" data-original-title="Reports" *ngIf="isrpmcaretaker">
					<a href="javascript:void(0)" routerLink="/caretaker-patient-reports">
						<div class="c-menu__item__inner bg-success-light">
							<i class="fa fa-file-prescription" aria-hidden="true"></i>
							<div class="c-menu-item__title"><span>Reports</span></div>
						</div>
					</a>
				</li>
				<li class="c-menu__item has-submenu " data-toggle="tooltip" title="" data-original-title="Users" *ngIf="isrpmcaretaker">
					<a href="javascript:void(0)" routerLink="/caretaker-users">
						<div class="c-menu__item__inner bg-primary-light">
							<i class="fa fa-user-injured " aria-hidden="true"></i>
							<div class="c-menu-item__title"><span>Patients</span></div>
						</div>
					</a>
				</li>
				<li class="c-menu__item has-submenu" data-toggle="tooltip" title="" data-original-title="Profile">
					<a href="javascript:void(0)" routerLink="/caretaker-profile">
						<div class="c-menu__item__inner bg-danger-light">
							<i class="fa fa-user-cog"></i>
							<div class="c-menu-item__title"><span>Profile</span></div>
						</div>
					</a>
				</li>
			</ul>
		</nav>
	</div>
</div>
<header class="l-header">
	<div class="l-header__inner clearfix">
		<!-- <div class="c-header-icon js-hamburger no-mobile">
			<div class="hamburger-toggle"><span class="bar-top"></span><span class="bar-mid"></span><span class="bar-bot"></span></div>
		</div> -->
		<div class="c-search p-0 no-desktop">
			<img src="./assets/img/logo-mobile.png" />
		</div>
		<div class="c-search no-mobile">
			<div style=" float: left; ">Logged in as <b class="mr-1">{{loginUser.fullName | titlecase}}</b></div>
			<span class="float-right d-none d-lg-block mr-2 nmobile"> Last login : {{loginUser.lastLogin| date:'dd-MMM-yyyy hh:mm a'}}
			</span>
			<!-- <span class="float-left pt-0 d-none d-lg-block mr-2" *ngIf="firstTimeLogin">
                Welcome to CureMetric. This is your first login.
                </span> -->
		</div>
		<div class="header-icons-group">
			<div class="c-header-icon has-dropdown bg-danger-light" data-toggle="tooltip" data-placement="left" title="Help">
				<i class="fas fa-question-circle" feedback (send)="onSend($event)"></i>
			</div>
			<div class="c-header-icon has-dropdown bg-primary-light" *ngIf="notificationBell" aria-hidden="true" data-toggle="modal" data-target="#viewremindersDoctors">
				<span class="c-badge c-badge--header-icon ">{{notificationLength}}</span><i class="fa fa-bell"></i>
			</div>
			<div class="c-header-icon has-dropdown bg-success-light " data-placement="left" data-toggle="tooltip" data-original-title="Profile">
				<i class="fa fa-user" routerLink="/caretaker-profile"></i>
			</div>
			<div class="c-header-icon logout bg-warning-light" data-placement="left" data-toggle="tooltip" data-original-title="Logout"><i class="fa fa-sign-out-alt" (click)="logOut()"></i></div>
		</div>
	</div>
</header>
<div class="modal fade modal-right" id="viewremindersDoctors" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Notifications</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<!-- <app-view-doctor-remainders></app-view-doctor-remainders> -->
			<div class="row">
				<div class="col-lg-12 mt-3 text_right ">
					<ul class="pagination">
						<pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
					</ul>
				</div>
			</div>
			<div class="row d-flex  ml-2">
				<div *ngIf="notifications?.length==0" class="no-notifications"></div>
				<div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left  blt-2 mb-2 mt-1 " *ngFor="let reports of notifications |paginate: { id: 'p2', itemsPerPage: 6, currentPage: p2 }" [ngClass]="{' blt-3': reports.viewed == 'Y'}" (click)="updateNotify(reports)">
					<a href="javascript:void(0)" class="p-2 icon-appointments  ">
						<i *ngIf="reports.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3" aria-hidden="true"></i>
						<i *ngIf="reports.viewed != 'Y'" class="fa fa-bell fa-lg pt-2  icon-col-3" aria-hidden="true"></i>
					</a>
					<div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
						<a href="javascript:void(0)">
							<p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
							</p>
							<p class="text-muted mb-1 text-small">{{reports.reason}}</p>
						</a>
					</div>
					<div class="btn-group float-right">
						<button class="btn btn-xs btn0col1" data-toggle="modal" data-backdrop="static" data-target="#viewpdf"
						(click)="updateNotify(reports)"><i class="fa fa-eye" aria-hidden="true"></i></button>
					</div>
				</div>
			</div>
			<div class="modal fade" id="notificationToView" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered" role="document" style="min-width:300px !important;height: 50% !important;">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title">Notification Viewer</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="block inv-section">
								<div class="">
									<div>
										<img alt="" src="./assets/img/logo.png" style="border-radius: none !important;">
									</div>
								</div>
								<div class="text-center">
									<div class="font-weight-medium mb-0 mt-1 text-center col-md-12">{{notifyToView?.objecttype |titlecase}} </div>
									<div class="text-muted mb-1 text-center text-small">{{notifyToView?.reason}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="on-mobile">
	<footer class="footer-mobile ">
		<div class="container-fulid">
			<ul class="nav nav-pills nav-justified">
				<li class="nav-item">
					<a class="nav-link" href="javascript:void(0)" routerLink="/caretaker-appointments" data-toggle="tooltip" title="" data-original-title="Appointments">
						<span>
							<i class="nav-icon fa fa-calendar-alt m-col1"></i>
							<span class="nav-text">Appointments</span>
						</span>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="javascript:void(0)" routerLink="/caretaker-patients" data-toggle="tooltip" title="" data-original-title="Patients">
						<span>
							<i class="nav-icon fa fa-user-injured  m-col2"></i>
							<span class="nav-text">Patients</span>
						</span>
					</a>
				</li>
				<li class="nav-item center-item">
					<a class="nav-link" href="javascript:void(0)" routerLink="/caretaker-dashboard" data-toggle="tooltip" title="" data-original-title="Home">
						<span>
							<i class="nav-icon fa fa-home"></i>
							<span class="nav-text">Home</span>
						</span>
					</a>
				</li>
				<!-- <li class="nav-item">
                      <a class="nav-link" href="javascript:void(0)" routerLink="/caretaker-patient-reports"  data-toggle="tooltip" title="" data-original-title="Reports">
                          <span>
                              <i class="nav-icon fa fa-file-prescription m-col1"></i>
                              <span class="nav-text">Reports</span>
                          </span>
                      </a>
                  </li> -->
				<li class="nav-item">
					<a class="nav-link" href="javascript:void(0)" routerLink="/caretaker-baseSchedule" data-toggle="tooltip" title="" data-original-title="Base Scheduler">
						<span>
							<i class="nav-icon fa fa-calendar-alt m-col3"></i>
							<span class="nav-text">Base Scheduler</span>
						</span>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link" href="javascript:void(0)" routerLink="/caretaker-profile" data-toggle="tooltip" title="" data-original-title="Profile">
						<span>
							<i class="nav-icon fa fa-user-cog m-col3"></i>
							<span class="nav-text">Profile</span>
						</span>
					</a>
				</li>
			</ul>
		</div>
	</footer>
</div>

<div class="modal fade" id="notificationViewer" role="dialog" data-backdrop="static" data-keyboard="false"
aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered notificationViewerModal_Style" role="document">
	<div class="modal-content">
		<div class="modal-header">
			<h5 class="modal-title">Notification Viewer</h5>
			<button type="button" class="close" data-dismiss="modal" aria-label="Close"
			(click)="closeNotificationViewerModal()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="block inv-section">
				<div class="mb-2 text-center">
					<img alt="Logo" src="./assets/img/logo.png" style="border-radius: none !important;">
				</div>
				<div class="text-center">
					<p class="font-weight-medium mb-1 mt-1">{{notifyToView?.objecttype |titlecase}}
					</p>
					<p class="text-muted mb-1 text-small">{{notifyToView?.reason}}</p>
				</div>
			</div>
		</div>
	</div>
</div>
</div>
