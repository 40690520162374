import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InventoryService } from '../inventory.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-place-order',
  templateUrl: './place-order.component.html',
  styleUrls: ['./place-order.component.css']
})
export class PlaceOrderComponent implements OnInit {
  Supplierselected;createorders: FormGroup;suplier;submitted=false;supplierId;addressId;p1=1

  constructor(private formBuilder: FormBuilder, private inventoryService: InventoryService, private toastrService: ToastrService, public activeModal: NgbActiveModal, private invService: InventoryService) { }

  ngOnInit(): void {
    this.createorders=this.formBuilder.group({
      Supplier:['',Validators.required]
    })
    this.getsuplier()
  }
  get createordersControls() {
    return this.createorders.controls;
  }
  selectedSuppiler(Supplierselected) {``
  this.Supplierselected = Supplierselected
}
supplierInfo;supplierSelected=false
  getsupplierIdbyClick(sup){
    this.supplierId = sup?.supplierId;
    this.supplierInfo=sup
    this.supplierSelected=true
  }
  getsuplier() {
    var body = {
      "flag": "ALL",
      "addressId": this.addressId
    }
    this.inventoryService.suplier(body).subscribe(res => {
      this.suplier = res?.data?.reverse();
      this.p1 = 1;
    })
  }
}
