<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="keywords" content="HTML5 Template" />
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
  <title>Curemetric</title>
  <link rel="dns-prefetch" href="https://fonts.gstatic.com/">
  <!-- favicon icon -->
  <!-- favicon icon -->
  <link rel="shortcut icon" href="./../../../assets/landing/images/favicon.png" />

  <!-- owl-carousel -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/owl.carousel.css">

  <!-- shortcodes -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/base.css" />

  <!-- main -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/core.css" />
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/helper.css" />

  <!-- responsive -->



  <style>
    body,
    p,
    span,
    input,
    button,
    a {
      font-family: 'Poppins', sans-serif !important;
      font-size: 13px !important
    }

    h1,
    h2,
    h3,
    h4 {
      font-family: 'Poppins', sans-serif !important;
    }

    .sign-info {
      border-top: 1px solid #cdd1f3;
      margin-top: 10px;
      padding-top: 20px;
      margin-bottom: 30px
    }

    .iq-social-media {
      margin: 0;
      padding: 0;
      float: right;
    }

    .iq-social-media li {
      list-style: none;
      float: left;
      margin-right: 10px;
    }

    .iq-social-media li:last-child {
      margin-right: 0;
    }

    .iq-social-media li a {
      height: 30px;
      width: 30px;
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      display: inline-block;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
      background: #eff7f8;
      color: #40648c !important;
    }

    .iq-social-media li a:hover {
      text-decoration: none;
    }


    .form-control {
      border-top-width: 0px !important;
      border-left-width: 0px !important;
      border-right-width: 0px !important;
      border-color: #424242 !important;
      border-bottom-width: 1px !important;
      height: calc(2rem + 1px) !important;
      background: transparent !important;
      padding: 9px 0px !important;
    }


    .bg-login {
      background-repeat: no-repeat;
      background-position: top left;
      background-image: url(./assets/landing/images/vector_page/bg-login.jpg);
      background-size: cover;

      width: 100%;
      height: 100%;
      opacity: 1;
      visibility: inherit;
      z-index: 20;
    }


    .signin {
      padding: 0px 0px 0px 0px !important;
      margin-top: 120px;
      max-width: 450px;
      font-family: 'Poppins', sans-serif;
      margin-left: 18px !important;
    }

    .signin h1 {
      font-size: 26px
    }

    .rightimg img {
      height: 450px;
      margin-top: 100px
    }
  </style>

</head>

<body class="ttm-one-page-site">

  <!--page start-->
  <div class="page" id="ttm-home">

    <!-- preloader start -->

    <!-- preloader end -->

    <!--header start-->
    <header id="masthead" class="header ttm-header-style-overlay ttm-header-style-classic-overlay">
      <!-- ttm-header-wrap -->
      <div class="ttm-header-wrap">
        <!-- ttm-stickable-header-w -->
        <div id="ttm-stickable-header-w" class="ttm-stickable-header-w ttm-bgcolor-white clearfix">
          <div id="site-header-menu" class="site-header-menu">
            <div class="site-header-menu-inner ttm-stickable-header">
              <div class="container-fuild">
                <!-- site-branding -->
                <div class="site-branding">
                  <a href="javascript:void(0)" (click)="goHome('https://curemetric.com/index.html')" rel="home">
                    <img id="logo-img" class="img-center" src="./assets/landing/images/logo-img.png" alt="logo-img">
                  </a>
                </div><!-- site-branding end -->
                <!--site-navigation -->
                <div id="site-navigation" class="site-navigation" data-sticky-height="70">
                  <div class="ttm-menu-toggle">
                    <input type="checkbox" id="menu-toggle-form" />
                    <label for="menu-toggle-form" class="ttm-menu-toggle-block">
                      <span class="toggle-block toggle-blocks-1"></span>
                      <span class="toggle-block toggle-blocks-2"></span>
                      <span class="toggle-block toggle-blocks-3"></span>
                    </label>
                  </div>
                  <nav id="menu" class="menu">
                    <ul class="dropdown">
                      <li><a aria-current="page" href="javascript:void(0)"
                          (click)="goHome('https://curemetric.com/index.html')">Home</a></li>
                      <li><a aria-current="page" href="javascript:void(0)"
                          (click)="goHome('http://curemetric.com/about.html')">About</a></li>
                      <li><a aria-current="page" href="javascript:void(0)"
                          (click)="goHome('http://curemetric.com/services.html')">Services</a>
                      </li>
                      <li><a aria-current="page" href="javascript:void(0)"
                          (click)="goHome('https://curemetric.com/index.html#testmo')">Testimonial</a>
                      </li>
                      <li><a aria-current="page" href="javascript:void(0)"
                          (click)="goHome('https://curemetric.com/contact-us.html')">Contact
                          Us</a></li>
                      <li><a href="javascript:void(0)" style="padding-left: 30px; padding-right: 50px;"><i
                            class="fa fa-user"></i> Login</a>
                        <ul class="sub-menu">
                          <li><a routerLink="/patientLogin"><i class="fa fa-user-injured"></i>
                              User</a></li>
                          <li><a routerLink="/Login"><i class="fa fa-user-md"></i> Doctor</a>
                          </li>
                          <li><a href="javascript:void(0)"><i class="fa fa-x-ray"></i>
                              Diagnostic </a></li>
                          <li><a routerLink="/pharmaLogin"><i class="fa fa-file-prescription"></i> Pharmacy</a></li>
                          <li><a routerLink="/caretakerLogin"><i class="fa fa-user-nurse"></i>CareTaker</a></li>
                          <li><a routerLink="/nutritionistLogin"><i
                                class="fa-brands fa-nutritionix"></i>Nutritionist</a>
                          </li>
                          <!-- <li><a routerLink="/psychiatristLogin"><i class="fa-brands fa-nutritionix"></i>Psychiatrist</a></li> -->
                          <!-- <li><a routerLink="/physiotherapistLogin"><i class="fa-brands fa-nutritionix"></i>Physiotherapist</a></li> -->
                          <li><a routerLink="/clinicLogin"><i class="fa fa-hospital"></i>
                              Hospital </a></li>
                          <!-- <li><a routerLink="#nogo"><i class="fa fa-user-tie"></i>Employer </a></li> -->

                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div><!-- site-navigation end-->
              </div>
            </div>
          </div>
        </div><!-- ttm-stickable-header-w end-->
      </div><!--ttm-header-wrap end -->
    </header><!--header end-->


    <div class="container-fulid">
      <div class="bg-login">
        <div class=" container">
          <div class="row">

            <div class="signin col-md-4 ml-4" id="success" style="display: none">
              <h1 class="mb-0">You have been registered successfully.</h1>
              <p>
                Please check your email and click the Verify Now button in the email we have sent to you
                to verify your email address.

                <a style="color:green;text-decoration:underline" class="float-right" routerLink="/Login">Go to Login</a>
              </p>
            </div>
            <div class="signin col-md-4 ml-4" id="fail" style="display: none">
              <h1 class="mb-0">Oops!!</h1>
              <p>
                Registration has been Not done succesfully ,
                Please Contact Our Support Team
              </p>
            </div>


            <div class=" rightimg col-lg-7  col-md-5 col-sm-5">
              <img src="./assets/landing/images/p-login.png" />
            </div>
          </div>
        </div>
      </div>
    </div><!-- page end -->

    <!-- Footer -->
    <app-main-footer></app-main-footer>
    <!-- Footer Ends -->