import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { DisplayPrescriptionModalComponent } from 'src/app/commonComponents/display-prescription-modal/display-prescription-modal.component';
import { ObservableService } from 'src/app/shared/services/observable.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
declare var $: any;
@Component({
  selector: 'app-view-prescription',
  templateUrl: './view-prescription.component.html',
  styleUrls: ['./view-prescription.component.css']
})

export class ViewPrescriptionComponent implements OnInit , OnChanges {
  @Input() prescriptionListView: any
  @Output() childComponentValue = new EventEmitter<any>();
  setItemsPerPage = 6;
  totalItem = 6;
  p3 = 1
  p2 = 1
  medicalReportsData = []
  patientId
  doctorName
  totalItem2 = 6;
  totalItem1 = 6;
  p4 = 1
  testReportsData = []

  constructor(private globalStorage: GlobalStorageService, private getPrescrtion: DoctorPatientService, private controller: PatientScheduleControlService,private clinicService:ClinicService,
    private toaster: ToastrService, private sanitizer: DomSanitizer,private router:Router,private obs:ObservableService,private modalService:NgbModal) {
      this.obs.ivf_prescription_Reload?.subscribe(res=>{
        if(res=='someValue'){
          console.log('method called')
          this.reload()
        }
      })
     }
    isIvf=false
  ngOnInit(): void {
    if(this.router.url=='/appointments' || this.router.url=='ivf-dashboard' || this.router.url=='ivf-patients' || this.globalStorage.getItem('loggedInAs')=='IVF'){
      this.isIvf=true
    }
    else{
      this.isIvf=false
    }
    this.patientId = this.prescriptionListView?.['userId']
    this.getSavedPrescriptions()
    this.getSavedMedicalTestPrescriptions()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.patientId = this.prescriptionListView?.['userId']
    this.getSavedPrescriptions()
    this.getSavedMedicalTestPrescriptions()
  }
  reload(){
    this.medicalReportsData = []
  }
  previousPageMedical(){
    this.p3 = this.p3 - 1;
    this.getSavedPrescriptions()
  }
  getSavedPrescriptions() {
    // this.medicalReportsData = null
    console.log('prescriptions list',this.prescriptionListView)
    var req = {
      "forUserId": this.patientId,
      "userId": this.patientId
    }
    if(this.router.url=='/appointments' || this.router.url=='/ivf-dashboard' || this.router.url=='/ivf-patients' || this.globalStorage.getItem('loggedInAs')=='IVF'){
      console.log('prescriptions list',this.prescriptionListView)
      if(this.prescriptionListView?.isFromPrevious=='Y'){
        this.controller.getAppointmentPrescription(this.prescriptionListView?.ivfaddressId,this.prescriptionListView['userId'],this.prescriptionListView?.appointmentId).subscribe(res=>{
          // console.log('prescriptions list',res)
          if(res?.data){
            this.medicalReportsData.push(res['data'])
          }
        })
        return
      }
      else{
        if(this.prescriptionListView?.ivfaddressId){
          this.controller.getIvfPrescription(this.prescriptionListView?.ivfaddressId,this.prescriptionListView['userId']).subscribe(res => {
            this.medicalReportsData = res['data']
            // this.doctorName=res['data']['doctorName']
            // if(res['data']['containsMedicalPrescription']=='Y'){
            //   this.medicalReportsData = res['data']['medications']
            // }
            // if(res['data']['containsTestPrescription']=='Y'){
            //   this.testReportsData = res['data']['medicaltests']
            // }
          })
          return
        }
        else if(this.prescriptionListView?.userId){
          this.controller.getIvfPrescription(this.globalStorage.getItem('ivfAddressId'),this.prescriptionListView?.userId).subscribe(res => {
            this.medicalReportsData = res['data']
            // this.doctorName=res['data']['doctorName']
            // if(res['data']['containsMedicalPrescription']=='Y'){
            //   this.medicalReportsData = res['data']['medications']
            // }
            // if(res['data']['containsTestPrescription']=='Y'){
            //   this.testReportsData = res['data']['medicaltests']
            // }
          })
          return
        }
      }
    }
    if(this.prescriptionListView?.patientType=='OPD'){
      var body={
        "appointmentId": this.prescriptionListView?.appointmentId,
        "hospitalAddresId": this.prescriptionListView?.hospitalAddressId,
        "patientId": this.prescriptionListView?.userId
      }
      this.clinicService.getOpdPrescription(body).subscribe(res=>{
        console.log('hi opd patient',res['data'])
        this.medicalReportsData=res['data']?.['medications']
      },error=>{
        this.toaster.error(error?.error?.message)
      })
    }
    else{
      this.getPrescrtion.getPrescriptionPostSession('prescription/getprecriptionbyuserId/' + this.p3 + '/1000/' + this.patientId, req, this.patientId).subscribe(res => {
        var result = res['data']?.filter(obj => obj.medicalPrescriptionId != null)
        if (result.length !=0 || result != null) {

        this.medicalReportsData = null
        this.medicalReportsData = result
        // this.testReportsData=res['data'].filter(obj=>obj.containsTestPrescription=='Y')
        this.totalItem = result?.length
        }else{
this.p3 = this.p3 - 1;
        }}
        , error => {

        }
      )
    }
  }
  getSavedMedicalTestPrescriptions() {
    this.testReportsData = null
    var req = {
      "forUserId": this.patientId,
      "userId": this.patientId
    }
    if(this.globalStorage.getItem('loggedInAs')=='IVF'){

    }
    else{
      this.getPrescrtion.getPrescriptionPostSession('prescription/getprecriptionbyuserId/' + this.p2 + '/1000/' + this.patientId, req, this.patientId).subscribe(res => {
        var result = res['data']?.filter(obj => obj.medicaltestprescriptionId != null)
        this.testReportsData = result
        this.totalItem1 = result?.length
      }
      )
    }
  }
  doctorAddress; dStreet1; dStreet2; dState; dCountry; dCity; dZip
  doctorAddressDetails(id) {
    this.getPrescrtion.getDoctorAddressDetails(id).subscribe(res => {
      this.doctorAddress = res['data'][0]
      this.dStreet1 = res['data'][0]['street1']
      this.dStreet2 = res['data'][0]['street2']
      this.dState = res['data'][0]['state']
      this.dCountry = res['data'][0]['country']
      this.dCity = res['data'][0]['city']
      this.dZip = res['data'][0]['zip']
    })
  }
  pStreet1; pStreet2; pState; pZip; pCountry; pCity; pharmData; result
  pharmaAddress(id) {
    this.getPrescrtion.getPharmaAddress(id).subscribe(res => {
      this.pStreet1 = res['data'][0]['address1']
      this.pStreet2 = res['data'][0]['address2']
      this.pCity = res['data'][0]['city']
      this.pCountry = res['data'][0]['country']
      this.pState = res['data'][0]['state']
      this.pZip = res['data'][0]['zip']
    })
  }
  closeModal() {
    this.fromAllPrescription = undefined
    this.isData = false
    $("#medicalPrescriptionDoctor").val(null).trigger("change");
  }

  openPrescriptionModal(reports) {

    let wClass='right hw-50';

    if(this.globalStorage.getItem("loggedInAs")=='IVF'){
      wClass='left w-40';
    }

    const modalRef = this.modalService.open(DisplayPrescriptionModalComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.componentInstance.prescription=reports;

    modalRef.result.then(
      (result) => {
        if (result) {
          // this.editPatient(data)
        }
      },
      (reason) => {
        // Handle dismissal of modal
      }
    );
  }
  fromAllPrescription; isData = false
  precName; precId; MedicationPrescriptions; src; newsrc; showImage: boolean = false; showPdf: boolean; appointmentDate
  viewPrescription(reports) {
  if(this.globalStorage.getItem("loggedInAs")=='IVF'){
    this.openPrescriptionModal(reports)
  }else{
    console.log('reorts in')
    if(this.router.url=='/appointments'){
      console.log('reorts',reports)
      this.precId=reports['hospitalprescriptionId']
      this.obs.prescriptionToView(reports)
      this.childComponentValue.emit(reports);
    }
    else{
      $("#medicalPrescriptionDoctor").val(null).trigger("change");
      $('#medicalPrescriptionDoctor').modal('show')
      this.fromAllPrescription = reports
      if (this.fromAllPrescription != undefined) {
        this.isData = true
      }
      // this.pharmaAddress(1)
      this.doctorName = reports['doctorName']
      this.precName = reports?.['documentName']

        this.precId = reports['medicalPrescriptionId']

      this.appointmentDate = reports['appointmentDate']
    }
    //this.doctorAddressDetails(reports['doctorId'])


    //       this.getPrescrtion.globalpostBody('prescription/getPrescriptionDetailsById?presciptionId=',reports['medicalPrescriptionId'],this.patientId).subscribe(res=>{


    // this.MedicationPrescriptions=res['data']['medications']
    // this.newsrc=null
    // this.showPdf=false
    // this.showImage=false

    // // if(res['data']['medicalPrescriptionFile']!=null && (res['data']?.['documentName'].includes(".png")|| res['data']?.['documentName'].includes(".jpeg"))){
    // //   this.src="data:image/jpeg;base64,"+res['data']['medicalPrescriptionFile']

    // //   this.newsrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
    // //   this.showImage=true

    // // }else if(res['data']['medicalPrescriptionFile']!=null && (res['data']?.['documentName'].includes(".pdf"))){
    // //   this.newsrc=null
    // //   this.showPdf=true

    // //   this.src="data:application/pdf;base64,"+res['data']['medicalPrescriptionFile']
    // //   this.newsrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
    // // }
    // $('#medicalPrescriptionDoctor').modal('show')

    //       })

  }}
  MedicalTestPrescriptions; showFill
  //     viewTestPrescription(reports){

  //       this.precName=reports['documentName']
  //       this.precId=reports['medicalPrescriptionId']
  //       this.getPrescrtion.globalgetBody('prescription/id/',reports['medicalPrescriptionId'],this.patientId).subscribe(res=>{


  // this.MedicalTestPrescriptions=res['data']['medicalTests']
  // this.newsrc=null
  // this.showPdf=false
  // this.showImage=false
  // this.showFill=true
  // if(res['data']['medicalPrescriptionFile']!=null && (res['data']?.['documentName'].includes(".png")|| res['data']?.['documentName'].includes(".jpeg"))){
  //   this.src="data:image/jpeg;base64,"+res['data']['medicalPrescriptionFile']

  //   this.newsrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
  //   this.showImage=true

  // }else if(res['data']['medicalPrescriptionFile']!=null && (res['data']?.['documentName'].includes(".pdf"))){
  //   this.newsrc=null
  //   this.showPdf=true

  //   this.src="data:application/pdf;base64,"+res['data']['medicalPrescriptionFile']
  //   this.newsrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
  // }
  // $('#medicalTestPrescriptionDoctor').modal('show')
  //       })

  //     }
  pstreet1; pstreet2; pstate; pcity; pcountry; pzip; patientName
  getPatientAddress(id) {
    var request = {

      "forUserId": id,
      "userId": id

    }


    this.controller.getPatientAddress(request, id).subscribe(res => {
      this.patientName = res['body']['data'][0]['contactName']
      this.pstreet1 = res['body']['data'][0]['street1']
      this.pstreet2 = res['body']['data'][0]['street2']
      this.pstate = res['body']['data'][0]['state']
      this.pcity = res['body']['data'][0]['city']
      this.pcountry = res['body']['data'][0]['country']
      this.pzip = res['body']['data'][0]['zip']
    })
  }
  medicalTestPrescription; testPrescDate
  viewTestPrescription(reports) {
    this.doctorAddressDetails(reports['doctorId'])
    this.getPatientAddress(this.patientId)
    //this.pharmaAddress(3)
    this.precId = reports['medicaltestprescriptionId']
    this.doctorName = reports['doctorName']
    this.testPrescDate = reports['appointmentDate']
    this.controller.postGlobal('prescription/getmedicaltestprescriptiondetails?medicalTestPresciptionId=' + reports['medicaltestprescriptionId'], this.patientId).subscribe(res => {
      this.medicalTestPrescription = res['data']?.['medicaltest']?.reverse()

      this.newsrc = null
      this.showPdf = false
      this.showImage = false
      if (res['data']?.['prescriptions'] != null || (res['data']?.['prescriptions']?.[0]?.['documentName'].includes(".png") || res['data']?.['prescriptions']?.[0]?.['documentName'].includes(".jpeg"))) {
        this.src = "data:image/jpeg;base64," + res['data']?.['prescriptions']?.[0]?.['documentLocation']
        this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
        this.showImage = true
      } else if (res['data']?.['prescriptions'] != null && (res['data']?.['prescriptions']?.[0]?.['documentName'].includes(".pdf"))) {
        this.newsrc = null
        this.showPdf = true
        this.src = "data:application/pdf;base64," + res['data']?.['prescriptions']?.[0]?.['documentLocation']
        this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
      }
      $('#viewTestPrescription').modal('show')
    }, error => {
      this.toaster.error(error?.['error']?.['message'])
    })

  }
}
