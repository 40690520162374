import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';

@Component({
  selector: 'app-active-medication',
  templateUrl: './active-medication.component.html',
  styleUrls: ['./active-medication.component.css']
})
export class ActiveMedicationComponent implements OnInit {

  constructor(private doctorPatientService: DoctorPatientService, private toastr: ToastrService) { }



  @Input() patientMedication: any

  ngOnInit(): void {
    if(this.patientMedication?.userId){
      this.loadPatientActiveMedication(this.patientMedication)
    }
  }


  activeMedicationList: any[] = []
  loadPatientActiveMedication(patient) {
    var medication = {
      // "forUserId":parseInt(patient.userId),
      // "isOnlyActive":true,
      // "pageNum": 1 
      "forUser": {
        "forUserId": parseInt(patient?.userId),
        "userId": parseInt(patient?.userId)
      },
      "isOnlyActive": true,
      "maxResults": 100,
      "pageNum": 1
    }

    this.doctorPatientService.getMedicationDaily(medication).subscribe(res => {
      this.activeMedicationList = res['body']['data']
    }, error => {
      this.toastr.error("Error in active medication")
    })

  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('patientMedication' in changes) {
      if (this.patientMedication != undefined) {
        this.loadPatientActiveMedication(this.patientMedication);
      }
    }
  }

}
