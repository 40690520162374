import { I } from '@angular/cdk/keycodes';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], searchTxt: string): any[] {
    // console.log('items, search',items,searchTxt)
    if(!items || !items.length) return items;
    if(!searchTxt || !searchTxt.length) return items;
    return items?.filter(item => {
      if(item?.value){
        return item?.value?.toString()?.toLowerCase()?.indexOf(searchTxt?.toLowerCase()) > -1
      }
      if(item?.lookupValue){
        return item?.lookupValue?.toString()?.toLowerCase()?.indexOf(searchTxt?.toLowerCase()) > -1
      }
      if(item?.patientName){
        return item?.patientName?.toString()?.toLowerCase()?.indexOf(searchTxt?.toLowerCase()) > -1
      }
      if(item?.firstName){
        return item?.firstName?.toString()?.toLowerCase()?.indexOf(searchTxt?.toLowerCase()) > -1
      }
      if(item?.specialization){
        return item?.specialization?.toString()?.toLowerCase()?.indexOf(searchTxt?.toLowerCase()) > -1
      }
    });
  }

}