<div class="modal-header">
    <h5 class="modal-title"> <span class="spaneeditem2"><i class="fas fa-notes-medical"></i>&nbsp;View Message</span>
    </h5>
    <button type="button" class="close" (click)="close()" aria-label="Close"
     > <span aria-hidden="true">×</span>
    </button>
  </div>
<div class="modal-body">
  <div class="block inv-section">
    <div class="mb-2 text-center">
      <img alt="Logo" src="./assets/img/logo.png">
    </div>
    <div class="text-center">
      <p class="font-weight-medium mb-1 mt-1">{{meessageInfo?.message}}</p>
    </div>
  </div>
</div>
