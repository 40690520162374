<div class="card  doctor-right-bg  pb-4 no-mobile">
	<div class="card m-0 border_bgcolor2 pt-0 no-mobile">
		<div class="position-absolute card-top-buttons">
			<button class="btn btn-header-white text-white icon-button p-3 mr-3 mt-2">
				<i class="fa fa-bell fa-lg faa-shake animated" aria-hidden="true" data-toggle="modal"
					data-target="#viewremindersDoctor"></i>
			</button>
			<span class="count"> {{notificationLength}}</span>
		</div>
		<div class="card-body">
			<div class="d-flex flex-row ml-2 mr-2 mb-o mt-0 ">
				<a class="d-flex pt-2 profile-image" href="javascript:void(0)" (click)="fileInput.click()">
					<input #fileInput type="file" (change)="onFileInput($event.target.files)" style="display:none;" />

					<img alt="Profile" [src]="imgURL"
						class="img-thumbnail border-0  p-0 list-thumbnail align-self-center xs-small null"
						style="border-radius: 50%;background: none;">
					<div class="profile-overlay">
						<a href="javascript:void(0)" class="profile-icon" title="User Profile">
							<i class="fa fa-image"></i>
						</a>
					</div>
				</a>
				<h5 class="card-title border-0 mb-0 pb-2 mt-0 text-white">{{loginUser?.fullName |titlecase}}<br>
					<span class="text-white text-small pt-2">{{caretakerPersonalDetails?.DOB | age |titlecase}} -
						{{caretakerPersonalDetails?.gender |titlecase}}</span>
				</h5>
			</div>
		</div>
	</div>
	<div class="card mt-2 mb-1">
		<div class="card-body  " style="min-height:171px">
			<div class="chartjs-size-monitor">
				<div class="chartjs-size-monitor-expand">
					<div class=""></div>
				</div>
				<div class="chartjs-size-monitor-shrink">
					<div class=""></div>
				</div>
			</div>
			<h5 class="card-title"><i class="fa fa-chart-line themecol4 mr-2" aria-hidden="true"></i> Patients Vitals
			</h5>
			<div class="d-flex flex-row mb-0 p-2 border-bottom blt-3">
				<div class="pl-1 pr-0 d-flex  flex-column flex-grow-1 mr-0 ">
					<a href="javascript:void(0)" *ngFor="let data of usersList?.slice(0,2)"
						(click)="editVitals(data?.patientId)">
						<p class="font-weight-medium mb-1 mt-1">{{data?.patientName | titlecase}}</p>
						<p class="text-muted mb-1 text-small">{{data?.lastUpdatedTs | date:'dd-MMM-yyyy'}}</p>
					</a>
				</div>
				<div class="pl-0 pr-1 d-flex  flex-column flex-grow-1 mr-1 text-center">
					<a href="javascript:void(0)" *ngFor="let temp of tempVitals?.slice(0,2)"
						(click)="editVitals(temp?.userId)">
						<p class="font-weight-medium mb-1 mt-1 text-center  themecol3 font-size-14">{{temp.vitalValue}}
							<sup style="font-size:8px">F</sup>
						</p>
						<p class=" mb-1 text-small"> Temperature</p>
					</a>
				</div>
				<div class="pl-0 pr-1 d-flex  flex-column flex-grow-1 mr-1 text-center">
					<a href="javascript:void(0)" *ngFor="let oxygen of oxygenVitals?.slice(0,2)"
						(click)="editVitals(oxygen?.userId)">
						<p class="font-weight-medium mb-1 mt-1 text-center  themecol1 font-size-14">
							{{oxygen.vitalValue}}<sup style="font-size:8px">%</sup> </p>
						<p class=" mb-1 text-small"> Oxygen Level</p>
					</a>
				</div>
				<div class="pl-0 pr-1 d-flex  flex-column flex-grow-1 mr-1 text-center ">
					<a href="javascript:void(0)" *ngFor="let pressure of pressureVitals?.slice(0,2)"
						(click)="editVitals(pressure?.userId)">
						<p class="font-weight-medium mb-1 mt-1 text-center  themecol3 font-size-14">
							{{pressure.vitalValue}}<sup style="font-size:8px">mm/hg</sup> </p>
						<p class=" mb-1 text-small"> Pressure</p>
					</a>
				</div>
			</div>
		</div>
	</div>
	<div class="card p-min-height mt-2 mb-2" *ngIf="isrpmcaretaker">
		<div class="card-body  ">
			<h5 class="card-title"><i class="fa fa-file-prescription themecol2 mr-2" aria-hidden="true"></i> Patient
				prescriptions</h5>
			<div class="no-prescriptions"></div>
			<!-- <div class="d-flex flex-row mb-1 p-1" >
        <a>
          <img src="assets/img/asociat17.png" /> </a>
        <div class="pl-2 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
          <a>
            <a href="javascript:void(0)" class="font-weight-medium mb-0 mt-0">Associations
            </a>
            <div>
              <a href="javascript:void(0)"  class="text-muted mb-1 text-small">Associated with</a>
            </div>

          </a>
        </div>
      </div>

      <div class="d-flex flex-row mb-1 p-1" >
        <a>
          <img src="assets/img/asociat5.png" /> </a>
        <div class="pl-2 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
          <a>
            <a href="javascript:void(0)" class="font-weight-medium mb-0 mt-0">Patient Feedback
            </a>
            <div>
              <a href="javascript:void(0)"  class="text-muted mb-1 text-small">Reviews on
                Appointments </a>
            </div>

          </a>
        </div>
      </div>
      <div class="d-flex flex-row mb-1 p-1" >
        <a>
          <img src="assets/img/asociat16.png" /> </a>
        <div class="pl-2 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
          <a>
            <a href="javascript:void(0)" class="font-weight-medium mb-0 mt-0">Patient Feedback
            </a>
            <div>
              <a href="javascript:void(0)"  class="text-muted mb-1 text-small">Reviews on
                Appointments </a>
            </div>

          </a>
        </div>
      </div>


      <div class="d-flex flex-row mb-1 p-1">
        <a>
          <img src="assets/img/asociat4.png" /> </a>
        <div class="pl-2 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
          <a>
            <a href="javascript:void(0)"  class="font-weight-medium mb-0 mt-0">Payments
            </a>
            <div>
              <a href="javascript:void(0)"   class="text-muted mb-1 text-small">list of
                Payments </a>
            </div>

          </a>
        </div>
      </div> -->
		</div>
	</div>
	<div class="pt-0 pl-0 pr-0 no-mobile mt-2">
		<div class="card p-min-height">
			<div class="position-absolute card-top-buttons">
				<button class="btn btn-header-light icon-button"><i aria-hidden="true" class="fa fa-ellipsis-h"
						aria-hidden="true" data-toggle="modal" data-target="#viewremindersDoctor"></i></button>
			</div>
			<div class="card-body big ">
				<h5 class="card-title"><i class="fa fa-bell  themecol2 " aria-hidden="true"></i> Notifications</h5>
				<div *ngIf="notViewedNotifications.length==0" class="no-notifications"></div>
				<div *ngIf="notViewedNotifications.length > 0">
					<div class="d-flex flex-row mb-1 p-2 border-bottom"
						*ngFor="let notif of notViewedNotifications?.slice(0,4)"
						(click)="updateNotify(notif)">
						<a href="javascript:void(0)" class="btn btn-sm btn-light-secondery  mr-2 mt-1 p-2 nav-btns">
							<i *ngIf="notif.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3"
								aria-hidden="true"></i>
							<i *ngIf="notif.viewed != 'Y'" class="fa fa-calendar-day icon-head" aria-hidden="true"></i>
						</a>
						<div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
							<a href="javascript:void(0)" id="notifyId">
								<p class="font-weight-medium mb-0 mt-1">{{notif.objecttype |titlecase}}
								</p>
								<p class="text-muted mb-1 text-small eclips">{{notif.reason}}</p>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal fade modal-right" id="viewremindersDoctor"  role="dialog" data-backdrop="static" data-keyboard="false"
		aria-labelledby="exampleModalRight">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Notifications</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<!-- <app-view-doctor-remainders></app-view-doctor-remainders> -->
				<div class="row">
					<div class="col-lg-12 mt-3 text_right">
						<ul class="pagination">
							<pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
						</ul>
					</div>
				</div>
				<div *ngIf="notifications?.length==0" class="no-notifications"></div>
				<div class="row d-flex  ml-2">
					<div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left  blt-2 mb-2 mt-1 "
						*ngFor="let reports of notifications | paginate: { id: 'p2', itemsPerPage: 6, currentPage: p2 }"
						[ngClass]="{' blt-3': reports.viewed == 'Y'}" (click)="updateNotify(reports)">
						<a href="javascript:void(0)" class="p-2 icon-appointments  ">
							<i *ngIf="reports.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3"
								aria-hidden="true"></i>
							<i *ngIf="reports.viewed != 'Y'" class="fa fa-bell fa-lg pt-2  icon-col-3"
								aria-hidden="true"></i>
						</a>
						<div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
							<a href="javascript:void(0)">
								<p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
								</p>
								<p class="text-muted mb-1 text-small">{{reports.reason}}</p>
							</a>
						</div>
						<div class="btn-group float-right">
							<button class="btn btn-xs btn0col1" data-toggle="modal" data-backdrop="static"
								data-target="#viewpdf" (click)="updateNotify(reports)"><i class="fa fa-eye" aria-hidden="true"></i></button>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>

	<div class="modal fade modal-left" id="show-all-Vitals" role="dialog" data-backdrop="static" data-keyboard="false"
		aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		<div class="modal-dialog" role="document" style="min-width:35% !important ">
			<div class="modal-content">
				<app-add-vitals *ngIf="isAllVitals"></app-add-vitals>
			</div>
		</div>
	</div>

	<div class="modal fade" id="caretaker-sidebar-noficationview" role="dialog" data-backdrop="static" data-keyboard="false"
		aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered notificationViewerModal_Style" role="document">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Notification Viewer</h5>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					(click)="closeNotificationViewerModal()">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="block inv-section">
						<div class="mb-2 text-center">
							<img alt="Logo" src="./assets/img/logo.png">
						</div>
						<div class="text-center">
							<p class="font-weight-medium mb-1 mt-1">{{notifyToView?.objecttype |titlecase}}
							</p>
							<p class="text-muted mb-1 text-small">{{notifyToView?.reason}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
