import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewChild, NgZone, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular';
import { formatDate } from '@angular/common';
import { DoctorService } from '../../AppServices/doctor.service'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGrigPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { MatDialog } from '@angular/material/dialog';
import { BookAppointmentComponent } from '../../DoctorPages/book-appointment/book-appointment.component';
import { ToastrService } from 'ngx-toastr';
import { DeleteConfirmationComponent } from '../delete-confirmation/delete-confirmation.component';
import * as moment from 'moment';
//import { TimeFormat } from 'src/app/_helpers/utility.';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { CaretakerService } from 'src/app/shared/services/caretaker.service';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';
import { ObservableService } from 'src/app/shared/services/observable.service';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppointmentDetailsComponent } from 'src/app/shared/components/appointment-details/appointment-details.component';
import { IvfBookingConfirmationComponent } from 'projects/ivf/src/app/ivf-booking-confirmation/ivf-booking-confirmation.component';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
declare var $: any;
@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.css'],
  providers: []
})
export class SchedulerComponent implements OnInit {
  @Input() testData:any
  @Input() bookedSlots:any;
  @Input() selectedUserData:any;
  @Output() selectedSlot=new EventEmitter<any>();
  stopDate = new Date();
  selectedItem = formatDate(this.stopDate, "yyyy-MM-dd", 'en-us')
  h: string[] = ["00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00",
    "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30","00:00"]
  click: number = 0
  eventsArray: any[] = [{ title: 'event 1', date: '2020-11-02', start: "10:00", end: "10:30" }, { title: 'event 1', date: '2020-11-03' },
  { title: 'event 1', date: '2020-11-04' }, { title: 'event 1', date: '2020-11-05' }, { title: 'event 1', date: '2020-11-06' }]
  // { title: 'event 1', date: '2020-11-24',start: '2020-11-24T13:00:00',end: '2020-11-24T14:00:00'},
  // { title: 'event 2', date: '2020-11-24',start: '2020-11-24T10:00:00',end: '2020-11-24T11:30:00',textColor: '',backgroundColor:'green'

  @ViewChild('calender', { static: false }) public calendarComponent: FullCalendarComponent;
  subscription: Subscription;
  calendarPlugins = [dayGridPlugin, timeGrigPlugin, interactionPlugin];
  calendarWeekends = false;
  doctorReferenceIdd;
  calendarOptions: CalendarOptions = {
    initialView: 'timeGridDay',     ///'dayGridMonth',
    events: [],
    weekends: true,
    editable: false,
    expandRows: true,
    dayMaxEvents: true,
    slotMinTime: '00:00:00',
    slotMaxTime: '23:60:00',
    // slotLabelContent: (arg) => {
    //   const now = new Date();
    //   if (arg.date < now) {
    //     // Create a new element and style it to indicate the slot is in the past
    //     const el = document.createElement('div');
    //     el.innerHTML = arg.text;
    //     el.style.color = 'gray'; // You can choose any color or style
    //     return { html: el.outerHTML };
    //   }
    //   // For times in the future, return the default content
    //   return { text: arg.text };
    // },

    views: {
      dayGridMonth: { // name of view
        eventTimeFormat: {
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'short'
        }, selectable: false
      }
    },
    allDaySlot: false,
    eventContent: this.renderEventContent,
    eventMinHeight: 20,
    nowIndicator: true,
    headerToolbar: {
      left: 'prev,next',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,myCustomButton', //'dayGridMonth,timeGridWeek,timeGridDay,myCustomButton',
    },
    // slotLabelContent: (arg) => {
    //   const now = new Date();
    //   if (arg.date < now) {
    //     // Create a new element and style it to indicate the slot is in the past
    //     const el = document.createElement('div');
    //     el.innerHTML = arg.text;
    //     el.style.color = 'gray'; // You can choose any color or style
    //     return { html: el.outerHTML };
    //   }
    //   // For times in the future, return the default content
    //   return { text: arg.text };
    // },

    // dateClick:this.dateClick.bind(this),
    select: this.dateTimeSelect.bind(this),

    // eventRemove:this.deleteScheduleTime.bind(this),
    // eventClick:this.dateClick.bind(this),
    // eventMouseEnter:this.eventMouseEnter.bind(this),
    // expandRows: true,
    selectable: true,
    selectAllow: (selectInfo) => {
      const now = new Date();
      // Prevent selection if the start of the range is in the past
      return selectInfo.start >= now;
    },
    validRange: this.getValidRange

    // eventMouseover: (event, jsEvent, view) => this.eventMouseOver(event, jsEvent, view)
    // eventTimeFormat:this.eventClick.bind(this),
  };
  constructor(private globalStorage: GlobalStorageService, private patientService: DoctorPatientService, private router: Router, public dialog: MatDialog,private clinicServices:ClinicService,
    private ngZone: NgZone, private doctorService: DoctorService, private modal:NgbModal,private tosterService: ToastrService,private obs:ObservableService,
    private doctorPatient: DoctorPatientService, private dcService: PatientScheduleControlService,
    private caretakerService: CaretakerService,private ivfService:IvfService,private datePipe: DatePipe) {
    var event = []

    this.doctorService.appointmentObservable.subscribe(res => {
      this.eventsArray.push(res)
      this.calendarOptions.events = this.eventsArray
    })
  }

  getValidRange() {
    const now = new Date();
    return {
      start: now 
    };
  }

  getSlotMinTime() {
    const now = new Date();
    return now.getHours() + ':' + now.getMinutes() + ':00';
  }


  renderEventContent(eventInfo, createElement) {
    console.log('events info',eventInfo)
    // $('.fc-timegrid-col-events').css('margin', 0+'px')
    //  $('.fc-timegrid-col-events').append('<i class="fa fa-plus" aria-hidden="true"></i>')
    $('.fc .fc-more-popover .fc-popover-body').css('overflow', 'auto')
    $('.fc .fc-more-popover .fc-popover-body .fc-daygrid-event.fc-event-end').css('max-height', '255px !important')
    $('.fc .fc-scroller-liquid-absolute').css('overflow', 'hidden')
    $("div.schedule-calender").scrollTop(310);
    $('.fc-direction-ltr .fc-daygrid-event.fc-event-end').css('background', 'green')
    $('.fc-event-future').css('background', 'green')
    //  $('td.fc-day-sat').children( '.fc-timegrid-col-events' ).css('margin', 0+'px')
    var innerHtml;
    var openDiv
    var slotAddInnerHtml
    openDiv = "<div style='background-color:blue;height:15px;display:flex' class='event_Avalable'>"
    if (eventInfo.event._def.extendedProps.status == 'AVAILABLE') {
      openDiv = "<div style='background-color:green;height:15px;display:flex' class='event_Avalable'>"
    } else if (eventInfo.event._def.extendedProps.status == 'BLOCKED') {
      openDiv = "<div style='background-color:red;height:15px; color:#fff;display:flex'>"
    }


    var label = "<label class='event-width' style='min-width: 63.98px'>" + eventInfo.timeText + "</label>"
    var avalilableButton = "<a type='button' style='margin-left:8px;color:#fff' data-toggle='tooltip' title='Delete' onclick='deleteSchedule(" + eventInfo.event._def.extendedProps.scheduleHoursId + ")'><i class='fa fa-close'></i></a>"  //<a type='button'  style='margin-left:8px' data-toggle='tooltip' title='Reschedule' (click)='reschedule()'><i class='fa fa-calendar'></i></a>
    var bookedButton = "<a type='button' style='margin-left:8px' data-toggle='tooltip' title='View Appointment' onclick='viewAppointment(" + eventInfo.event._def.extendedProps.scheduleHoursId + ")'><i class='fa fa-eye'></i></a>"
    var addSlotButton = "<a type='button' style='margin-left:8px;color:blue' data-toggle='tooltip' title='View' onclick='deleteSchedule(" + eventInfo.event._def.extendedProps.scheduleHoursId + ")'><i class='fa fa-close'></i></a>"
    var closeDiv = "</div>"

    innerHtml = openDiv + label + addSlotButton + closeDiv + closeDiv
    if (eventInfo.event._def.extendedProps.status == 'AVAILABLE') {
      innerHtml = openDiv + label + avalilableButton + closeDiv + closeDiv;
    } else if (eventInfo.event._def.extendedProps.status == 'BLOCKED') {
      innerHtml = openDiv + label + bookedButton + closeDiv + closeDiv;
    }

    if ($(".fc-timegrid-col-events").find(".fc-timegrid-event-harness").length > 0) {

    }
    else {
    }

    return createElement = { html: '<div class=" ">' + innerHtml + '</div>' }

  }

  isHospital=false;ivfData
  routelink='/dashboard'
  ngOnInit(): void {
    const scheduleScroll = document.getElementById('schedule-calender');
    if (this.router.url == '/caretakerCalendar') {
      this.getCaretakerBaseschedule();
     // $("div.schedule-calender").css('overflow', 'visible');
     $(".schedule-calender").addClass('vAuto')
      this.routelink='/caretaker-dashboard'
    }
    else if (this.router.url == '/caretaker-dashboard' || this.rpmUserData?.appointemntFor=='RPMPATIENT' || this.router.url =='/caretaker-appointments' || this.router.url=='/caretaker-assignedUsers') {
      this.getRpmCaretakerBaseschedule();
     // $("div.schedule-calender").css('overflow', 'visible');
     $(".schedule-calender").addClass('oVisible')
     $(".fc-view-harness-active").addClass('mheight')
      this.isHospital=true
    }
    else if((this.router.url=='/ivf-dashboard' || this.router.url=='/ivf-lab-prescriptions' ) && this.globalStorage.getItem('loggedInAs')=='IVF'){
      // $('#schedule-calender').addClass('hospitalCal')
      this.ivfService.dataFromIvf$.subscribe(res=>{
        this.hospitalData=res
        this.ivfData=res
        console.log('ivfData for dc',this.ivfData)
      })

    }
    else if(this.router.url=='/ivf-admin' || this.globalStorage.getItem('loggedInAs')=='IVF'){
      // $('#schedule-calender').addClass('hospitalCal')
      this.hospitalData=this.doctorService.dataFromHospital
      this.ivfData=this.doctorService.dataFromIvf
      console.log('this.ivfData',this.ivfData)
      if(this.ivfData?.type=="CARETAKER"){
        this.getCaretakerBaseschedule();
      }
      else if(this.ivfData?.type=="DOCTOR"){
        this.getHospitalDoctorBaseschedule()
      }
    }
    else if(this.router.url=='/clinic-admin'){
      this.isHospital=true
      console.log('this.ivfData',this.hospitalData)
      $('#schedule-calender').addClass('hospitalCal')
      this.hospitalData=this.doctorService.dataFromHospital
      if(this.hospitalData?.type!='CARETAKER'){
        this.getHospitalDoctorBaseschedule()
      }
      else{
        this.getHospitalCaretakerBaseschedule()
      }
    }
    else if(this.testData){
     // this.getDcTestBaseschedule()
    }
    else {
      this.getDoctorBaseschedule();
    }
    window['SchedulerComponent'] = { component: this, zone: this.ngZone, deleteSchedule: (arg) => this.deleteSchedule(arg) };
    window['SchedulerComponent1'] = { component: this, zone: this.ngZone, viewAppointment: (arg1) => this.viewAppointment(arg1) };
  }
  hospitalData
  saveData(data){
    this.hospitalData=data
  }
  selectedDate1
  selectedDate2
  timeOfDay
  dateTimeSelect(arg) {
    console.log('dateTime selected')
    $('.fc-highlight').css('background-color', 'blue')
    if (arg.startStr)

      if (this.click < 2) {

        if (this.click == 0) {
          this.click = this.click + 1
          this.selectedDate1 = formatDate(arg.startStr, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');//moment(arg.startStr).format('YYYY-MM-DD')
          return
        }
        if (this.click == 1) {
          this.selectedDate2 = formatDate(arg.startStr, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
          if (this.selectedDate1 == this.selectedDate2) {
            this.click = this.click + 1;
          } else {
            this.click = 0;
          }
        }
        if (this.click == 2) {
          var startString
          var endString
          var date
          startString = new Date(arg.startStr);
          endString = new Date(arg.endStr);
          date = startString;

          const scheduleDate = formatDate(date, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
          var toDay = new Date()
          var today = moment(toDay).format('YYYY-MM-DD');
          var sDate = moment(scheduleDate).format('YYYY-MM-DD');
          var date: any = new Date()
          var hours = date.getHours();
          var minutes = date.getMinutes();
          var seconds = date.getSeconds()
          var ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12;
          hours = hours ? hours : 12; // the hour '0' should be '12'
          minutes = minutes < 10 ? '0' + minutes : minutes;
          seconds = seconds < 10 ? '0' + seconds : seconds;
          var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
          var fromTime = startString.toLocaleTimeString();
          var ToTime = endString.toLocaleTimeString();
          var t = new Date('2000-01-01 ' + strTime);
          strTime = new DatePipe('en-US').transform(t, 'HH:mm:ss');
          if (sDate == today || sDate > today) {
            if (fromTime == strTime || (fromTime <= strTime && strTime <= ToTime)) {
             this.tosterService.warning("Slots are not created past time & slot running time 1")
             this.click=0;
             $('.fc-highlight').css('background-color', 'red')
             return
            }else{
              const dialogRef = this.dialog.open(BookAppointmentComponent, {
                height: '200px',
                width: '350px', data: arg,
                disableClose: true
              });

              dialogRef.afterClosed().subscribe(result => {
                if (result['data'] == 'YES') {
                  this.addSchedule(arg)
                  if(this.globalStorage.getItem('loggedInAs')=='DOCTOR'){
                    this.getDoctorBaseschedule();
                  }
                } else {
                  this.click = 0;
                }
              });
            }

            // if (fromTime <= strTime <= ToTime) {
            //   this.tosterService.warning("Slots are not created slot running time")
            //   this.click = 0;
            //   return

            // }
          }

          for (var i = 0; i <= this.eventsArray.length; i++) {
            var d = new Date(this.eventsArray[i]?.['start'])
            var h = d.getHours()
            var m: any = d.getMinutes()
            var s: any = d.getSeconds()
            var ampm = h >= 12 ? 'PM' : 'AM';
            h = h % 12;
            h = h ? h : 12; // the hour '0' should be '12'
            m = m < 10 ? '0' + m : m;
            s = s < 10 ? '0' + s : s;
            var reqTime = h + ':' + m + ':' + s + ' ' + ampm;
            if (moment(d).format('YYYY-MM-DD') == moment(this.selectedDate1).format('YYYY-MM-DD') && reqTime == new Date(arg.startStr).toLocaleTimeString()) {
              this.tosterService.warning("Slot already exists create new Slot")
              this.click = 0;
              return
            }
          }
        }


        if (this.click == 2) {
          // const dialogRef = this.dialog.open(BookAppointmentComponent, {
          //   height: '200px',
          //   width: '350px', data: arg,
          //   disableClose: true
          // });

          // dialogRef.afterClosed().subscribe(result => {
          //   if (result['data'] == 'YES') {
          //     this.addSchedule(arg)
          //   } else {
          //     this.click = 0;
          //   }
          // });
        }
      }
  }

rpmUserData
  addSchedule(arg) {
    console.log('adding  schedule')

    if(this.caretakerService?.rpmUserData){
      this.rpmUserData=this.caretakerService?.rpmUserData
    }
    var startString;
    var endString;
    startString = new Date(arg.startStr);
    endString = new Date(arg.endStr);
    var schdate = startString;

    const scheduleDate = formatDate(schdate, "yyyy-MM-dd", 'en-us');
    var toDay = new Date();
    var today = moment(toDay).format('YYYY-MM-DD');
    var sDate = moment(scheduleDate).format('YYYY-MM-DD');
    var date: any = new Date();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds()
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;

    if (sDate < today) {
      this.tosterService.warning("Slots cannot be created for past dates")
      return
    }
    var fromTime = startString.toLocaleTimeString();
    var ToTime = endString.toLocaleTimeString();
    for (var i = 0; i < this.eventsArray.length; i++) {
      var d = new Date(this.eventsArray[i]?.['start'])
      var h = d.getHours()
      var m: any = d.getMinutes()
      var s: any = d.getSeconds()
      var ampm = h >= 12 ? 'PM' : 'AM';
      h = h % 12;
      h = h ? h : 12; // the hour '0' should be '12'
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      var reqTime = h + ':' + m + ':' + s + ' ' + ampm;
      if (moment(d).format('YYYY-MM-DD') == sDate && reqTime == fromTime) {
        this.tosterService.warning("Slot already exists create new Slot")
        return
      }
    }
    if (sDate == today) {
      if (fromTime == strTime || (fromTime <= strTime && strTime <= ToTime)) {
        this.tosterService.warning("Slots are not created past time & run time please book next slot")
        return
      }
    }
    if (sDate == today) {
      // if((fromTime < strTime)){
      //  this.tosterService.warning("Slots are not created past timee")
      //  return
      // }
    }
    if (fromTime >= "00:00:00" && fromTime <= "11:30:00") {

      this.timeOfDay = 1

    } else if (fromTime >= "12:00:00" && fromTime <= "15:30:00") {
      this.timeOfDay = 2
    } else {
      this.timeOfDay = 3
    }
    var houres = {

      'fromTime': this.getTwentyFourHourTimeWithoutSec(fromTime),
      'toTime': this.getTwentyFourHourTimeWithoutSec(ToTime),
      'timeOfDay': this.timeOfDay
    }
    var schedule
    if (this.router.url == '/caretakerCalendar') {
      schedule = {
        "active": "Y",
        "associateEntityId": 1,
        "associateEntityType": 'Online',
        "associateEntityName": 'Online',
        "caretakerId": parseInt(this.globalStorage.getItem('forUserId')),
        //"basseScheduleId":this.basescheduleId,
        "scheduleDate": scheduleDate,
        "scheduleHours": [houres]
      }
      this.caretakerService.saveScheduleHours(schedule).subscribe(res => {
        // this.getDoctorBaseschedule();
        var check = moment(sDate, 'YYYY/MM/DD');
        var month = check.format('M');
        this.click = 0;
        // this.Baseschedule(month)
        this.tosterService.success("Schedule added sccessfully")
        this.getCaretakerBaseschedule();
      }, error => {
        this.tosterService.error("error in new schedule")
      })
    }
    else if(this.globalStorage.getItem('loggedInAs')=='IVF' && this.ivfData?.type=='CARETAKER'){
      schedule = {
        "active": "Y",
        "associateEntityId": this.ivfData?.ivfaddressId,
        "associateEntityType": 'IVF',
        "associateEntityName": this.ivfData?.hospitalName,
        "caretakerId": this.ivfData?.referenceentityId,
        //"basseScheduleId":this.basescheduleId,
        "scheduleDate": scheduleDate,
        "scheduleHours": [houres]
      }
      this.caretakerService.saveScheduleHours(schedule).subscribe(res => {
        // this.getDoctorBaseschedule();
        var check = moment(sDate, 'YYYY/MM/DD');
        var month = check.format('M');
        this.click = 0;
        // this.Baseschedule(month)
        this.tosterService.success("Schedule added sccessfully")
        this.getCaretakerBaseschedule();
      }, error => {
        this.tosterService.error("error in new schedule")
      })
    }
    else if (this.router.url == '/clinic-admin'){
      console.log('hospital data',this.hospitalData)
      this.doctorReferenceIdd = this.hospitalData?.['referenceentityId'];
      schedule = {
        "active": "Y",
        "associateEntityId": this.hospitalData?.hospitalData?.hospitalAddressId,
        "appointmentType":arg?.type,
        "associateEntityType": 'HOSPITAL',
        "associateEntityName": this.hospitalData?.hospitalData?.hospitalName,
        // "basseScheduleId":this.basescheduleId,
        "doctorId": this.doctorReferenceIdd,
        "scheduleDate": scheduleDate,
        "scheduleHours": [houres]
      }
      this.doctorService.saveScheduleHours(schedule).subscribe(res => {
        // this.getDoctorBaseschedule();
        var check = moment(sDate, 'YYYY/MM/DD');
        var month = check.format('M');
        this.click = 0;
        // this.Baseschedule(month)
        this.tosterService.success("Schedule added sccessfully")
        this.getHospitalDoctorBaseschedule();
      }, error => {
        this.tosterService.error("error in new schedule")
      })
    }
    else if(this.globalStorage.getItem('loggedInAs')=='IVF' && this.ivfData?.type=='DOCTOR'){
      schedule = {
        "active": "Y",
        "associateEntityId": this.ivfData?.ivfaddressId,
        "appointmentType":arg?.type,
        "associateEntityType": 'IVF',
        "associateEntityName": this.ivfData?.hospitalName,
        // "basseScheduleId":this.basescheduleId,
        "doctorId": this.ivfData?.referenceentityId,
        "scheduleDate": scheduleDate,
        "scheduleHours": [houres]
      }
      console.log('save from ivf',this.ivfData)
      this.doctorService.saveScheduleHours(schedule).subscribe(res => {
        // this.getDoctorBaseschedule();
        var check = moment(sDate, 'YYYY/MM/DD');
        var month = check.format('M');
        this.click = 0;
        // this.Baseschedule(month)
        this.tosterService.success("Schedule added sccessfully")
        this.getHospitalDoctorBaseschedule();
      }, error => {
        this.tosterService.error("error in new schedule")
      })
    }
    else if(this.router.url=='/caretaker-dashboard' || this.router.url=='/caretaker-appointments' || this.router.url=='/caretaker-assignedUsers'){
      console.log('CraetakerData',this.rpmUserData)
      if(this.rpmUserData?.appointmentType=='Reschedule'){
        var bd={
          "appointmentDate": scheduleDate,
          "appointmentStatus": "ENTITY_RESCHEDULED",
          "caretakerrpmappointmentId": this.rpmUserData?.caretakerrpmappointmentId,
          "description": "shjfdssd",
          "fromTime": this.getTwentyFourHourTimeWithoutSec(fromTime),
          "toTime": this.getTwentyFourHourTimeWithoutSec(ToTime),
          "userId":this.rpmUserData?.userId
        }
        this.caretakerService.updateCaretakerAppointment(bd).subscribe(res => {
          this.tosterService.success("Appointment rescheduled.");
          $('#calendar').modal('hide')
          this.getRpmCaretakerBaseschedule();
          this.obs.getAppointments();
          let currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
        }, error => {
          this.tosterService.error(error['error']['message']);
        });
      }
      else{
        var body={
          "active":'Y',
          "appointmentDate": scheduleDate,
          "appointmentFor": "RPMPATIENT",
          "caretakerId": this.rpmUserData?.caretakerId,
          "caretakerName": this.rpmUserData?.caretakerName,
          "fromTime": this.getTwentyFourHourTimeWithoutSec(fromTime),
          "patientName": this.rpmUserData?.patientName,
          "toTime": this.getTwentyFourHourTimeWithoutSec(ToTime),
          "userId": this.rpmUserData?.patientId,
          "schedulehoursId":this.rpmUserData?.clinicAddressId
        }
        this.caretakerService.bookSlot(body).subscribe(res=>{
          this.tosterService.success('Slot Alloted.')
          $('#calendar').modal('hide')
          this.getRpmCaretakerBaseschedule();
          this.obs.getAppointments();
          let currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
        },error=>{
          this.tosterService.error(error?.['error']?.['message'])
        })
      }
    }
    else if(this.globalStorage.getItem('loggedInAs')=='IVF'){

       this.selectedSlot.emit({
        date:scheduleDate,
        fromTime:houres.fromTime,
        toTime:houres.toTime,
        hours:houres,
        type:arg?.type
       });

      }
      else if(this.testData){
        console.log('test Data',this.testData,this.selectedUserData)
       var objDc= {
          "diagnosticcenterAppointment": {
            "active": "y",
            "appointmentStatus": this.selectedUserData?.patientType,
            "description": "TEST",
            "diagnosticcenterAddresId": this.testData?.diagnosticcenterAddressId,
            "hospitalprescriptionId":this.testData?.hospitalprescriptionId,
            "diagnosticcenterSchedule": [
              {
                "appointmentDate": scheduleDate,
                "diagnosticcentertests": [
                  {
                    "entityId": this.testData?.subMenuId,
                    "entityType": this.testData?.type,
                    "price":  this.testData?.amount,
                    "testName": this.testData?.testName,
                    "testType": this.testData?.testType,
                    "medicalTestId":this.testData?.medicalTestId
                  }
                ],
                "fromTime": this.getTwentyFourHourTimeWithoutSec(fromTime),
                "scheduleHourId": -1,
                "toTime": this.getTwentyFourHourTimeWithoutSec(ToTime),
              }
            ],
            "doctorId": this.selectedUserData?.otherInformation?.doctorId ? this.selectedUserData?.otherInformation?.doctorId:this.testData?.doctorId,
            "doctorName": this.testData?.doctorName? this.testData?.doctorName:"SELF",
            "patientName": this.selectedUserData?.otherInformation?.firstName+' '+this.selectedUserData?.otherInformation?.lastName,
            "userAppointmentId": -1,
            "hospitalAddressId": parseInt(this.globalStorage.getItem('hospitalAddressId')),
            "inpatientId": this.testData?.inpatientId,
            "userId": this.testData?.patientId
          }
        }
        console.log('dc booking obj ',objDc)
        this.clinicServices.bookdcAppointment(objDc).subscribe(res=>{
          this.tosterService.success('Appointment booking successfully')
          this.selectedSlot.emit()
          this.modal.dismissAll()
        },error=>{
          this.tosterService.error(error?.error?.message)
        })
      }
    else {
      schedule = {
        "active": "Y",
        "associateEntityId": 1,
        "appointmentType":arg?.type,
        "associateEntityType": 'Online',
        "associateEntityName": 'Online',
        // "basseScheduleId":this.basescheduleId,

        "doctorId": parseInt(this.globalStorage.getItem('doctorId')),
        "scheduleDate": scheduleDate,
        "scheduleHours": [houres]
      }
      this.doctorService.saveScheduleHours(schedule).subscribe(res => {
        // this.getDoctorBaseschedule();
        var check = moment(sDate, 'YYYY/MM/DD');
        var month = check.format('M');
        this.click = 0;
        // this.Baseschedule(month)
        this.tosterService.success("Schedule added sccessfully")
        this.getDoctorBaseschedule();
        // getHospitalDoctorBaseschedule
      }, error => {
        this.tosterService.error("error in new schedule")
      })
    }

    // let currentUrl = this.router.url;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.navigate([currentUrl]);
  }



  deleteSchedule(scheduleId) {

    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      height: '200px',
      width: '300px',
      disableClose: true,
      data: {
        status: null
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result['data'] == 'YES') {
        this.doctorService.deleteScheduleHours(scheduleId).subscribe(res => {
          if(this.globalStorage.getItem('loggedInAs')=='IVF' || this.router.url == '/clinic-admin'){
            this.getHospitalDoctorBaseschedule()
          }else if(this.globalStorage.getItem('loggedInAs')=='CARETAKER'){
            this.getCaretakerBaseschedule();
          }
          else{
            this.getDoctorBaseschedule();
          }
          this.tosterService.success("Schedule hours deleted successfully")
        }, res => {
          this.tosterService.warning(res['error']['message'])
        })
      }
    });

  }
req
  viewAppointment(schedulehoursId) {
    if(this.globalStorage.getItem('loggedInAs')=='IVF'){
      this.req = {
        "associateEntityId": this.ivfData?.ivfaddressId,
        "doctorId": this.ivfData?.referenceentityId,
        "maxResults": 10,
        "pageNum": 1,
        "schedulehoursId": schedulehoursId
      }
    }else if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
      this.req = {
        "associateEntityId": this.globalStorage.getItem('hospitalAddressId'),
        "doctorId": this.hospitalData?.referenceentityId,
        "maxResults": 10,
        "pageNum": 1,
        "schedulehoursId": schedulehoursId
      }
    }
    else{
      this.req = {
        "doctorId": parseInt(this.globalStorage.getItem('doctorId')),
        "maxResults": 10,
        "pageNum": 1,
        "schedulehoursId": schedulehoursId
      }
    }
    this.getAppointmentDetails(this.req)
  }

  getAppointmentDetails(shId) {
    // this.doctorService.getAppointmentByschedulehours(shId).subscribe(res=>{getAppointmentDts
    this.doctorService.getAppointmentDts(shId).subscribe(res => {

      var appointment = res['data'][0];
      if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL' || this.globalStorage.getItem('loggedInAs')=='DOCTOR'){
        const modalRef = this.modal.open(AppointmentDetailsComponent, {
          centered: false,
          windowClass: 'modal-right hw-40',
          backdrop: 'static',
          keyboard: false,
        });
        modalRef.componentInstance.appointmentData = appointment
    
        modalRef.result.then(
          (userConfirmed: boolean) => {
            if (userConfirmed) {
            }
          },
          () => { }  // Handle dismiss callback
        );
      }
      else{
        this.getPatientDetails(appointment)
      }
      if(this.globalStorage.getItem('loggedInAs')=='IVF'){
        this.openBookingConfirmationModal(appointment);
      }
      //this.getPatientDetails(appointment)
    }, error => {

    });

  }
reqq

openBookingConfirmationModal(data) {
  const modalRef = this.modal.open(IvfBookingConfirmationComponent, {
    centered: this.globalStorage.getItem('loggedInAs') === "IVF",
    windowClass: this.globalStorage.getItem('loggedInAs') === "IVF" ? 'modal-centered hw-30' : ''
  });

  modalRef.componentInstance.bookingDetails = {
    doctorName: data.doctorName,
    specializ: data.specializ,
    aRating: data.rating,
    ttlExperience: data.exp,
    reason: data.ailment,
    selectedItem: data.appointmentDate,
    fromTime: data.fromTime,
    advanceAmount: data.advancePay,
    dueAmount: data.duePay,
    appointmentId: data.appointmentId
  };
}
  getPatientDetails(appointmentDetails) {
    if(this.globalStorage.getItem('loggedInAs')=='IVF'){
      this.reqq = {
        "forUserId": appointmentDetails.userId,
        "userId": appointmentDetails.userId,
      }
    }else if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
      this.reqq = {
        "forUserId": appointmentDetails.userId,
        "userId": appointmentDetails.doctorId,
      }
    }
    else{
      this.reqq = {
        "forUserId": appointmentDetails.userId,
        "userId": this.globalStorage.getItem('doctorId')
      }

    }
      this.patientService.getPatientPersonalDetails(appointmentDetails.userId, this.reqq).subscribe(res => {
        var patientDetails = res['body'].data;
        patientDetails.AppointmentDetails = appointmentDetails;
        patientDetails.Appointment = 'Current';
        //   //patientDetails.Appointment='Upcoming';
  
        this.doctorService.OpenPatientSideNave(patientDetails);
        //this.DoctorVideo.getUserName('41')//patientDetails.AppointmentDetails.appointmentId
      }, error => {
        this.tosterService.error("Error in getting patient details");
      })
  }

  changeIntialView() {
    this.calendarOptions.weekends = false;
    this.calendarOptions.defaultRangeSeparator = 'true';
    var obj = { title: 'event 1', date: '2020-11-02', start: "", End: "" }
    this.eventsArray.push(obj)
    this.calendarOptions.events = this.eventsArray;
  }


  doctorScheduler
  doctor; basescheduleId
  getRpmCaretakerBaseschedule(){
    var toDay = new Date()
    this.calendarOptions.events = null;
    this.eventsArray.length = 0

    this.doctor = JSON.parse(this.globalStorage.getItem('LoginDetails'))
    var check = moment(toDay, 'YYYY/MM/DD');
    var month = check.format('M');

    this.caretakerService.getAppointments(this.globalStorage.getItem('forUserId')).subscribe(res => {
      console.log('baseschedule',res)
      this.doctorScheduler = res['data'];
      this.basescheduleId = res['data']?.[0]?.['basseScheduleId']
      for (var i = 0; i < this.doctorScheduler?.length; i++) {
        var scheduleDate = new Date(this.doctorScheduler?.[i]?.appointmentDate);
        var time1 = moment(toDay).format('YYYY-MM-DD');
        var slotDate = moment(scheduleDate).format('YYYY-MM-DD');
        if (slotDate > time1 || slotDate == time1) {

            if (this.doctorScheduler) {
              var obj = {
                title: '',
                scheduleId: this.doctorScheduler[i]?.scheduleId,
                date: slotDate,
                start: slotDate + 'T' + this.doctorScheduler[i]?.fromTime,
                end: slotDate + 'T' + this.doctorScheduler[i]?.toTime,
                status: "AVAILABLE",
                scheduleHoursId: this.doctorScheduler[i]?.scheduleHoursId,
                backgroundColor: 'green'
              }
            }
            else {
              var obj = {
                title: '',
                scheduleId: this.doctorScheduler[i]?.scheduleId,
                date: slotDate,
                start: slotDate + 'T' + this.doctorScheduler[i]?.fromTime,
                end: slotDate + 'T' + this.doctorScheduler[i]?.toTime,
                status: "AVAILABLE",
                scheduleHoursId: this.doctorScheduler[i]?.scheduleHoursId,
                backgroundColor: 'green'
              }

            }
            //var removeEvent={ title:'',scheduleId:this.doctorScheduler[i].scheduleId,date:this.doctorScheduler[i].scheduleDate,start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,backgroundColor:'orange'}
            this.eventsArray.push(obj)
            //this.eventsArray.push(removeEvent)
        }
      }

      const source = interval(1000);
      this.subscription = source.subscribe(val => this.updater());
    }, error => {
      if (error.error.message == "Session Id not exists") {
        this.tosterService.error("Session timed out")
        this.router.navigate(['/caretakerLogin'])
      }
      this.tosterService.error("Error in getting baseshedule")
    })

  }
  getCaretakerBaseschedule() {
    var toDay = new Date()
    this.calendarOptions.events = null;
    this.eventsArray.length = 0

    this.doctor = JSON.parse(this.globalStorage.getItem('LoginDetails'))
    var check = moment(toDay, 'YYYY/MM/DD');
    var month = check.format('M');
    if(this.globalStorage.getItem('loggedInAs')=='IVF'){
      this.caretakerService.getCaretakerSlots(this.ivfData?.ivfaddressId,this.ivfData?.referenceentityId).subscribe(res => {
        this.doctorScheduler = res['data'];
        this.basescheduleId = res['data']?.[0]?.['basseScheduleId']
        for (var i = 0; i < this.doctorScheduler.length; i++) {
          var scheduleDate = new Date(this.doctorScheduler[i].scheduleDate);
          var time1 = moment(toDay).format('YYYY-MM-DD');
          var slotDate = moment(scheduleDate).format('YYYY-MM-DD');
          if (slotDate > time1 || slotDate == time1) {
            for (var j = 0; j < this.doctorScheduler[i].scheduleHours.length; j++) {
              if (this.doctorScheduler[i].scheduleHours[j].scheduleStatus == 'AVAILABLE') {
                var obj = {
                  title: '',
                  scheduleId: this.doctorScheduler[i].scheduleId,
                  date: slotDate,
                  start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                  end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                  status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                  scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                  backgroundColor: 'green'
                }
              }
              else {
                var obj = {
                  title: '',
                  scheduleId: this.doctorScheduler[i].scheduleId,
                  date: slotDate,
                  start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                  end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                  status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                  scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                  backgroundColor: 'green'
                }

              }
              this.eventsArray.push(obj)
            }
          }
        }

        const source = interval(1000);
        this.subscription = source.subscribe(val => this.updater());
      }, error => {
        if (error.error.message == "Session Id not exists") {
          this.tosterService.error("Session timed out")
          this.router.navigate(['/Login'])
        }
        this.tosterService.error("Error in getting baseshedule")
      })
    }
    else{
      this.caretakerService.getCaretakerBaseschedule(this.globalStorage.getItem('forUserId'), month).subscribe(res => {
        this.doctorScheduler = res['data'];
        this.basescheduleId = res['data']?.[0]?.['basseScheduleId']
        for (var i = 0; i < this.doctorScheduler.length; i++) {
          var scheduleDate = new Date(this.doctorScheduler[i].scheduleDate);
          var time1 = moment(toDay).format('YYYY-MM-DD');
          var slotDate = moment(scheduleDate).format('YYYY-MM-DD');
          if (slotDate > time1 || slotDate == time1) {
            for (var j = 0; j < this.doctorScheduler[i].scheduleHours.length; j++) {
              if (this.doctorScheduler[i].scheduleHours[j].scheduleStatus == 'AVAILABLE') {
                var obj = {
                  title: '',
                  scheduleId: this.doctorScheduler[i].scheduleId,
                  date: slotDate,
                  start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                  end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                  status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                  scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                  backgroundColor: 'green'
                }

                // var obj={ title:'',
                // scheduleId:this.doctorScheduler[i].scheduleId,
                //  date:this.doctorScheduler[i].scheduleDate,
                //  start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,
                //  end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,
                //  status:this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                //  scheduleHoursId:this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                //  backgroundColor:'green'}

              }
              else {
                // var obj={ title:'',
                // scheduleId:this.doctorScheduler[i].scheduleId,
                // date:this.doctorScheduler[i].scheduleDate,
                // start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,
                // end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,
                // status:this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                // scheduleHoursId:this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                // backgroundColor:'red'}
                // }
                var obj = {
                  title: '',
                  scheduleId: this.doctorScheduler[i].scheduleId,
                  date: slotDate,
                  start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                  end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                  status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                  scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                  backgroundColor: 'green'
                }

              }

              //var removeEvent={ title:'',scheduleId:this.doctorScheduler[i].scheduleId,date:this.doctorScheduler[i].scheduleDate,start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,backgroundColor:'orange'}

              this.eventsArray.push(obj)
              //this.eventsArray.push(removeEvent)
            }
          }
        }

        const source = interval(1000);
        this.subscription = source.subscribe(val => this.updater());
      }, error => {
        if (error.error.message == "Session Id not exists") {
          this.tosterService.error("Session timed out")
          this.router.navigate(['/Login'])
        }
        this.tosterService.error("Error in getting baseshedule")
      })
    }

  }
  getHospitalCaretakerBaseschedule() {
    var toDay = new Date()
    this.calendarOptions.events = null;
    this.eventsArray.length = 0

    var id=this.hospitalData?.referenceentityId
    var check = moment(toDay, 'YYYY/MM/DD');
    var month = check.format('M');

    this.caretakerService.getClincicCaretakerBaseschedule(id, month).subscribe(res => {
      this.doctorScheduler = res['data'];
      this.basescheduleId = res['data']?.[0]?.['basseScheduleId']
      for (var i = 0; i < this.doctorScheduler.length; i++) {
        var scheduleDate = new Date(this.doctorScheduler[i].scheduleDate);
        var time1 = moment(toDay).format('YYYY-MM-DD');
        var slotDate = moment(scheduleDate).format('YYYY-MM-DD');
        if (slotDate > time1 || slotDate == time1) {
          for (var j = 0; j < this.doctorScheduler[i].scheduleHours.length; j++) {
            if (this.doctorScheduler[i].scheduleHours[j].scheduleStatus == 'AVAILABLE') {
              var obj = {
                title: '',
                scheduleId: this.doctorScheduler[i].scheduleId,
                date: slotDate,
                start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                backgroundColor: 'green'
              }

              // var obj={ title:'',
              // scheduleId:this.doctorScheduler[i].scheduleId,
              //  date:this.doctorScheduler[i].scheduleDate,
              //  start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,
              //  end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,
              //  status:this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
              //  scheduleHoursId:this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
              //  backgroundColor:'green'}

            }
            else {
              // var obj={ title:'',
              // scheduleId:this.doctorScheduler[i].scheduleId,
              // date:this.doctorScheduler[i].scheduleDate,
              // start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,
              // end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,
              // status:this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
              // scheduleHoursId:this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
              // backgroundColor:'red'}
              // }
              var obj = {
                title: '',
                scheduleId: this.doctorScheduler[i].scheduleId,
                date: slotDate,
                start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                backgroundColor: 'green'
              }

            }

            //var removeEvent={ title:'',scheduleId:this.doctorScheduler[i].scheduleId,date:this.doctorScheduler[i].scheduleDate,start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,backgroundColor:'orange'}

            this.eventsArray.push(obj)
            //this.eventsArray.push(removeEvent)
          }
        }
      }

      const source = interval(1000);
      this.subscription = source.subscribe(val => this.updater());
    }, error => {
      if (error.error.message == "Session Id not exists") {
        this.tosterService.error("Session timed out")
        this.router.navigate(['/Login'])
      }
      this.tosterService.error("Error in getting baseshedule")
    })
  }
  removeDuplicatesSlots(array, key) {
    let lookup = new Set();
    return array?.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
  }
 getIvfBaseSchedule(){

 }
  getHospitalDoctorBaseschedule() {

    var toDay = new Date()
    this.calendarOptions.events = null;
    this.eventsArray.length = 0
    var check = moment(toDay, 'YYYY/MM/DD');
    var month = check.format('M');
    if(this.globalStorage.getItem('loggedInAs')=='IVF'){
      var id=this.ivfData?.referenceentityId
      this.doctorService.getDoctorSlots(this.ivfData?.ivfaddressId,this.ivfData?.referenceentityId).subscribe(res => {
        console.log('baseschdele',res)
        this.doctorScheduler = res['data'];
        this.basescheduleId = res['data']?.[0]?.['basseScheduleId']
        for (var i = 0; i < this.doctorScheduler.length; i++) {
          var scheduleDate = new Date(this.doctorScheduler[i].scheduleDate);
          var time1 = moment(toDay).format('YYYY-MM-DD');
          var slotDate = moment(scheduleDate).format('YYYY-MM-DD');
          if (slotDate > time1 || slotDate == time1) {
            for (var j = 0; j < this.doctorScheduler[i].scheduleHours.length; j++) {
              if (this.doctorScheduler[i].scheduleHours[j].scheduleStatus == 'AVAILABLE') {
                var obj = {
                  title: '',
                  scheduleId: this.doctorScheduler[i].scheduleId,
                  appointmentType:this.doctorScheduler[i]?.appointmentType,
                  associateEntityName: this.doctorScheduler[i].associateEntityName,
                  date: slotDate,
                  start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                  end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                  status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                  scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                  backgroundColor: 'green'
                }
              }
              else {
                var obj = {
                  title: '',
                  scheduleId: this.doctorScheduler[i].scheduleId,
                  appointmentType:this.doctorScheduler[i]?.appointmentType,
                  associateEntityName: this.doctorScheduler[i].associateEntityName,
                  date: slotDate,
                  start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                  end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                  status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                  scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                  backgroundColor: 'green'
                }

              }
              this.eventsArray.push(obj)
            }
          }
        }
        const source = interval(1000);
        this.subscription = source.subscribe(val => this.updater());
      }, error => {
        if (error.error.message == "Session Id not exists") {
          this.tosterService.error("Session timed out")
          this.router.navigate(['/Login'])
        }
        this.tosterService.error("Error in getting baseshedule")
      })
    }
    else{
      var id = this.hospitalData?.referenceentityId
      this.doctorService.getDoctorBaseschedule(id, month).subscribe(res => {
        console.log('baseschdele',res)
        this.doctorScheduler = res['data'];
        this.basescheduleId = res['data']?.[0]?.['basseScheduleId']
        for (var i = 0; i < this.doctorScheduler.length; i++) {
          var scheduleDate = new Date(this.doctorScheduler[i].scheduleDate);
          var time1 = moment(toDay).format('YYYY-MM-DD');
          var slotDate = moment(scheduleDate).format('YYYY-MM-DD');
          if (slotDate > time1 || slotDate == time1) {
            for (var j = 0; j < this.doctorScheduler[i].scheduleHours.length; j++) {
              if (this.doctorScheduler[i].scheduleHours[j].scheduleStatus == 'AVAILABLE') {
                var obj = {
                  title: '',
                  scheduleId: this.doctorScheduler[i].scheduleId,
                  appointmentType:this.doctorScheduler[i]?.appointmentType,
                  associateEntityName: this.doctorScheduler[i].associateEntityName,
                  date: slotDate,
                  start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                  end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                  status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                  scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                  backgroundColor: 'green'
                }

                // var obj={ title:'',
                // scheduleId:this.doctorScheduler[i].scheduleId,
                //  date:this.doctorScheduler[i].scheduleDate,
                //  start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,
                //  end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,
                //  status:this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                //  scheduleHoursId:this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                //  backgroundColor:'green'}

              }
              else {
                // var obj={ title:'',
                // scheduleId:this.doctorScheduler[i].scheduleId,
                // date:this.doctorScheduler[i].scheduleDate,
                // start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,
                // end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,
                // status:this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                // scheduleHoursId:this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                // backgroundColor:'red'}
                // }
                var obj = {
                  title: '',
                  scheduleId: this.doctorScheduler[i].scheduleId,
                  appointmentType:this.doctorScheduler[i]?.appointmentType,
                  associateEntityName: this.doctorScheduler[i].associateEntityName,
                  date: slotDate,
                  start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                  end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                  status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                  scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                  backgroundColor: 'green'
                }

              }

              //var removeEvent={ title:'',scheduleId:this.doctorScheduler[i].scheduleId,date:this.doctorScheduler[i].scheduleDate,start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,backgroundColor:'orange'}
              this.eventsArray.push(obj)
              // this.eventsArray=this.removeDuplicatesscheduleId(this.eventsArray,'scheduleId')
              //this.eventsArray.push(removeEvent)
            }
          }
        }
        // this.eventsArray=this.removeDuplicatesSlots(this.eventsArray,'timeText')

        const source = interval(1000);
        this.subscription = source.subscribe(val => this.updater());
      }, error => {
        if (error.error.message == "Session Id not exists") {
          this.tosterService.error("Session timed out")
          this.router.navigate(['/Login'])
        }
        this.tosterService.error("Error in getting baseshedule")
      })
    }



  }
  getDoctorBaseschedule() {

    var toDay = new Date()
    this.calendarOptions.events = null;
    this.eventsArray.length = 0
    console.log('this.doctorService.dataFromHospital',this.doctorService.dataFromHospital)
    var docid
    if(this.router.url=='/ivf-admin'){
      docid=this.doctorService?.dataFromHospital?.referenceentityId
    }
    else{
      this.doctor = JSON.parse(this.globalStorage.getItem('LoginDetails'))
      docid=this.doctor?.['data'].doctorId
    }

    var check = moment(toDay, 'YYYY/MM/DD');
    var month = check.format('M');

    this.doctorService.getDoctorBaseschedule(docid, month).subscribe(res => {
      this.doctorScheduler = res['data'];
      this.basescheduleId = res['data']?.[0]?.['basseScheduleId']
      for (var i = 0; i < this.doctorScheduler.length; i++) {
        var scheduleDate = new Date(this.doctorScheduler[i].scheduleDate);
        var time1 = moment(toDay).format('YYYY-MM-DD');
        var slotDate = moment(scheduleDate).format('YYYY-MM-DD');
        if (slotDate > time1 || slotDate == time1) {
          for (var j = 0; j < this.doctorScheduler[i].scheduleHours.length; j++) {
            if (this.doctorScheduler[i].scheduleHours[j].scheduleStatus == 'AVAILABLE') {
              var obj = {
                title: '',
                scheduleId: this.doctorScheduler[i].scheduleId,
                associateEntityName: this.doctorScheduler[i].associateEntityName,
                date: slotDate,
                start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                backgroundColor: 'green'
              }

              // var obj={ title:'',
              // scheduleId:this.doctorScheduler[i].scheduleId,
              //  date:this.doctorScheduler[i].scheduleDate,
              //  start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,
              //  end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,
              //  status:this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
              //  scheduleHoursId:this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
              //  backgroundColor:'green'}

            }
            else {
              // var obj={ title:'',
              // scheduleId:this.doctorScheduler[i].scheduleId,
              // date:this.doctorScheduler[i].scheduleDate,
              // start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,
              // end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,
              // status:this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
              // scheduleHoursId:this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
              // backgroundColor:'red'}
              // }
              var obj = {
                title: '',
                scheduleId: this.doctorScheduler[i].scheduleId,
                associateEntityName: this.doctorScheduler[i].associateEntityName,
                date: slotDate,
                start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                backgroundColor: 'green'
              }

            }

            //var removeEvent={ title:'',scheduleId:this.doctorScheduler[i].scheduleId,date:this.doctorScheduler[i].scheduleDate,start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,backgroundColor:'orange'}
            this.eventsArray.push(obj)
            // this.eventsArray=this.removeDuplicatesscheduleId(this.eventsArray,'scheduleId')
            //this.eventsArray.push(removeEvent)
          }
        }
      }
      // this.eventsArray=this.removeDuplicatesSlots(this.eventsArray,'timeText')

      const source = interval(1000);
      this.subscription = source.subscribe(val => this.updater());
    }, error => {
      if (error.error.message == "Session Id not exists") {
        this.tosterService.error("Session timed out")
        this.router.navigate(['/Login'])
      }
      this.tosterService.error("Error in getting baseshedule")
    })
  }
  updater() {
    var tDay = new Date()
    var tday = moment(tDay).format('YYYY-MM-DD');
    var sDate1 = formatDate(tDay, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    var date: any = new Date()
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds()
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    var newArray = this.eventsArray.filter(function (el) {
      return el.date == tday &&
        el.start >= sDate1 && el.end >= sDate1 || el.start >= sDate1
    }
    );
    var newArray1 = this.eventsArray.filter(function (el) {
      return el.date == tday &&
        el.start >= sDate1 && el.end >= sDate1
    }
    );
    //newArray=newArray1.concat(newArray);
    this.calendarOptions.events = newArray
  }

  Baseschedule(month) {

    var toDay = new Date()
    this.calendarOptions.events = null;
    this.eventsArray.length = 0

    // this.doctor=JSON.parse(this.globalStorage.getItem('LoginDetails'))
    // var check = moment(toDay, 'YYYY/MM/DD');
    // var month = check.format('M');

    this.doctorService.getDoctorBaseschedule(this.doctor['data'].doctorId, month).subscribe(res => {
      this.doctorScheduler = res['data'];
      for (var i = 0; i < this.doctorScheduler.length; i++) {
        var scheduleDate = new Date(this.doctorScheduler[i].scheduleDate);
        var time1 = moment(toDay).format('YYYY-MM-DD');
        var slotDate = moment(scheduleDate).format('YYYY-MM-DD');
        if (slotDate > time1 || slotDate == time1) {
          for (var j = 0; j < this.doctorScheduler[i].scheduleHours.length; j++) {
            if (this.doctorScheduler[i].scheduleHours[j].scheduleStatus == 'AVAILABLE') {
              var obj = {
                title: '',
                scheduleId: this.doctorScheduler[i].scheduleId,
                date: slotDate,
                start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                backgroundColor: 'green'
              }

              // var obj={ title:'',
              // scheduleId:this.doctorScheduler[i].scheduleId,
              //  date:this.doctorScheduler[i].scheduleDate,
              //  start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,
              //  end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,
              //  status:this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
              //  scheduleHoursId:this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
              //  backgroundColor:'green'}

            }
            else {
              //   var obj={ title:'',
              //   scheduleId:this.doctorScheduler[i].scheduleId,
              //   date:this.doctorScheduler[i].scheduleDate,
              //   start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,
              //   end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,
              //   status:this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
              //   scheduleHoursId:this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
              //   backgroundColor:'red'}
              // }
              var obj = {
                title: '',
                scheduleId: this.doctorScheduler[i].scheduleId,
                date: slotDate,
                start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                backgroundColor: 'green'
              }

            }

            //var removeEvent={ title:'',scheduleId:this.doctorScheduler[i].scheduleId,date:this.doctorScheduler[i].scheduleDate,start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,backgroundColor:'orange'}

            this.eventsArray.push(obj)
            //this.eventsArray.push(removeEvent)
          }
        }
      }

      this.calendarOptions.events = this.eventsArray
    }, error => {
      this.tosterService.error("Error in getting baseshedule")
    })
  }

  leadingZero(value) {
    if (value < 10) {
      return "0" + value.toString();
    }
    return value.toString();
  }


  appointmentDate
  fromTime: string = ""
  toTime: string = ""
  newDate
  // addScheduleTime(){
  //   if(this.newDate==""||this.fromTime==""||this.toTime==""){
  //     this.tosterService.error("Please enter mandatory fields");
  //     return
  //   }
  //   const scheduleDate=formatDate(this.newDate,"yyyy-MM-dd'T'HH:mm:ss.SSS",'en-us');
  // //  this.baseScheduleValidation(scheduleDate)

  // }


  getTwentyFourHourTime(str) {
    // var d = new Date("1/1/2013 " + amPmString);
    //return d.getHours() + ':' + d.getMinutes()+ ':'+ '00';
    str = String(str).toLowerCase().replace(/\s/g, '');
    var has_am = str.indexOf('am') >= 0;
    var has_pm = str.indexOf('pm') >= 0;
    // first strip off the am/pm, leave it either hour or hour:minute
    str = str.replace('am', '').replace('pm', '');
    // if hour, convert to hour:00
    if (str.indexOf(':') < 0) str = str + ':00';
    // now it's hour:minute
    // we add am/pm back if striped out before
    if (has_am) str += ' am';
    if (has_pm) str += ' pm';
    // now its either hour:minute, or hour:minute am/pm
    // put it in a date object, it will convert to 24 hours format for us
    var d = new Date("1/1/2011 " + str);
    // make hours and minutes double digits
    var doubleDigits = function (n) {
      return (parseInt(n) < 10) ? "0" + n : String(n);
    };
    return doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes()) + ':' + '00';
  }
  getTwentyFourHourTimeWithoutSec(str) {
    // var d = new Date("1/1/2013 " + amPmString);
    //return d.getHours() + ':' + d.getMinutes()+ ':'+ '00';
    str = String(str).toLowerCase().replace(/\s/g, '');
    var has_am = str.indexOf('am') >= 0;
    var has_pm = str.indexOf('pm') >= 0;
    // first strip off the am/pm, leave it either hour or hour:minute
    str = str.replace('am', '').replace('pm', '');
    // if hour, convert to hour:00
    if (str.indexOf(':') < 0) str = str + ':00';
    // now it's hour:minute
    // we add am/pm back if striped out before
    if (has_am) str += ' am';
    if (has_pm) str += ' pm';
    // now its either hour:minute, or hour:minute am/pm
    // put it in a date object, it will convert to 24 hours format for us
    var d = new Date("1/1/2011 " + str);
    // make hours and minutes double digits
    var doubleDigits = function (n) {
      return (parseInt(n) < 10) ? "0" + n : String(n);
    };
    return doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes());
  }

  fromTimeChange() {
    //this.fillToTime();

    this.toTime = moment(this.fromTime, 'h:mm:ss A')
      .add(0, 'seconds')
      .add(30, 'minutes')
      .format('LT');
    this.toTimeList.push(this.toTime);
  }

  toTimeList: any[] = []
  fillToTime() {

    // this.toTimeList.length = 0;
    // var fromTime = this.timeConvertion.transform(this.fromTime) //moment(this.fromTime, 'h:mma');
    // var beginningTime = moment(this.fromTime, 'h:mm a');
    // for (var i = 0; i <= this.h.length; i++) {
    //   var totime = this.timeConvertion.transform(this.h[i]);
    //   var endTime = moment(totime, 'h:mm a');
    //   if (beginningTime.isBefore(endTime)) {
    //     this.toTimeList.push(this.h[i]);
    //   }
    //   //var toTime = moment(this.h[i]|, 'h:mma');
    // }

  }
  getDcTestBaseschedule(){
    var toDay = new Date()
    this.calendarOptions.events = null;
    this.eventsArray.length = 0

    var id=this.hospitalData?.referenceentityId
    var check = moment(toDay, 'YYYY/MM/DD');
    var month = check.format('M');
    var dateS = new Date(this.selectedItem)
    var request = {
      "diagnosticCenterAddressId": this.testData?.diagnosticcenterAddresId,
      "fromDate": formatDate(this.selectedItem, "yyyy-MM-dd", 'en-us'),
      "submenuId": this.testData?.subMenuId,
      "toDate": formatDate((dateS.setMonth(dateS.getMonth() + 1)), "yyyy-MM-dd", 'en-us')
    }
    this.dcService.globalDignosticPost("schedule/all/bycriteria", request).subscribe(res => {
      console.log('baseschdele',res)
      this.doctorScheduler = res['data'];
      this.basescheduleId = res['data']?.[0]?.['basseScheduleId']
      for (var i = 0; i < this.doctorScheduler.length; i++) {
        var scheduleDate = new Date(this.doctorScheduler[i].scheduleDate);
        var time1 = moment(toDay).format('YYYY-MM-DD');
        var slotDate = moment(scheduleDate).format('YYYY-MM-DD');
        if (slotDate > time1 || slotDate == time1) {
          for (var j = 0; j < this.doctorScheduler[i].scheduleHours.length; j++) {
            if (this.doctorScheduler[i].scheduleHours[j].scheduleStatus == 'AVAILABLE') {
              var obj = {
                title: '',
                scheduleId: this.doctorScheduler[i].scheduleId,
                appointmentType:this.doctorScheduler[i]?.appointmentType,
                associateEntityName: this.doctorScheduler[i].associateEntityName,
                date: slotDate,
                start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                backgroundColor: 'green'
              }
            }
            else {
              var obj = {
                title: '',
                scheduleId: this.doctorScheduler[i].scheduleId,
                appointmentType:this.doctorScheduler[i]?.appointmentType,
                associateEntityName: this.doctorScheduler[i].associateEntityName,
                date: slotDate,
                start: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime,
                end: slotDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime,
                status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus,
                scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId,
                backgroundColor: 'green'
              }

            }
            this.eventsArray.push(obj)
          }
        }
      }
      const source = interval(1000);
      this.subscription = source.subscribe(val => this.updater());
    }, error => {
      if (error.error.message == "Session Id not exists") {
        this.tosterService.error("Session timed out")
        this.router.navigate(['/Login'])
      }
      this.tosterService.error("Error in getting baseshedule")
    })
  }
}
