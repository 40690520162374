<ol class="breadcrumb">
  <li><a href="javascript:void(0)" routerLink="/dashboard"><i class="fa fa-home" aria-hidden="true">
          </i></a></li>
  <li> <a href="javascript:void(0)">All Reports</a></li>
</ol>

<div class="card cm-height">
  <div class="position-absolute card-top-buttons">
      <button class="btn pl-2 pt-1 pb-1 pr-2 mt-1 bg-primary" data-toggle="modal" data-target="#upload-report"><i class="fa fa-plus" aria-hidden="true"></i> Generate Report</button>
  </div>
  <div class="card-body">
      <h5 class="card-title"><i class="fa fa-chart-bar themecol2 icon-head" aria-hidden="true"></i> Generate Reports </h5>
      <div class="p-3">
          <div class="row">
              <div class="col-md-3">
                  <div class="form-group">
                      <label>Select Date Range <span style="color:red">*</span></label>
                      <div class="input-group">
                          <div class="input-group-prepend">
                              <span class="input-group-text">
                                  <i class="far fa-calendar-alt"></i>
                              </span>
                          </div>
                          <input type="text" name="date" class="form-control float-right datepickerrange" value="2022/03/07 - 2022/03/07" id="date" required="">
                      </div>
                  </div>
              </div>
              <div class="col-md-3">
                  <div class="form-group">
                      <label>Type of Report <span style="color:red">*</span></label>
                      <select name="reports" class="form-control">
                          <option value="Payments">Payments</option>
                          <option value="Appointments">Appointments</option>
                          <option value="Commissions">Commissions</option>
                          <option value="Patients">Patients</option>
                      </select>
                  </div>
              </div>
              <div class="col-md-3">
                  <div class="form-group">
                      <label>Type of Report <span style="color:red">*</span></label>
                      <select name="reports" class="form-control">
                          <option value="Completed">Completed</option>
                          <option value="Canceled">Cancelled</option>
                      </select>
                  </div>
              </div>
              <div class="col-md-3">
              </div>
          </div>
          <div class="row">
              <div class="col-lg-2 col-sm-12 col-xs-12 mt-4 mb-4 ">
                  <div class="custom-info-box">
                      <div class="row">
                          <div class="col-2 col-sm-4 col-xs-4">
                              <span class="icon">
                                  <span class="text-center">
                                      <i class="fa fa-user-injured"></i>
                                  </span>
                              </span>
                          </div>
                          <div class="col-7 col-sm-8 col-xs-8">
                              <h4 class="m-0"> 5 </h4>
                              <span> Patients </span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-2 col-sm-12 col-xs-12 mt-4 mb-4 ">
                  <div class="custom-secondary-box">
                      <div class="row">
                          <div class="col-2 col-sm-4 col-xs-4">
                              <span class="icon">
                                  <span class="text-center">
                                      <i class="fa  fa-calendar-check"></i>
                                  </span>
                              </span>
                          </div>
                          <div class="col-7 col-sm-8 col-xs-8">
                              <h4 class="m-0"> 15 </h4>
                              <span> Associates </span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-2 col-sm-12 col-xs-12 mt-4 mb-4 ">
                  <div class="custom-primary-box">
                      <div class="row">
                          <div class="col-3 col-sm-4 col-xs-4">
                              <span class="icon">
                                  <span class="text-center">
                                      <i class="fa  fa-wallet"></i>
                                  </span>
                              </span>
                          </div>
                          <div class="col-7 col-sm-8 col-xs-8">
                              <h4 class="m-0">
                                  <i class="fa fa-inr"></i> 120
                              </h4>
                              <span> Total Paid </span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-2 col-sm-12 col-xs-12 mt-4 mb-4 ">
                  <div class="custom-danger-box">
                      <div class="row">
                          <div class="col-3 col-sm-4 col-xs-4">
                              <span class="icon">
                                  <span class="text-center">
                                      <i class="fa  fa-calendar-check"></i>
                                  </span>
                              </span>
                          </div>
                          <div class="col-7 col-sm-8 col-xs-8">
                              <h4 class="m-0"> 24 </h4>
                              <span> Appointments </span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-2 col-sm-12 col-xs-12 mt-4 mb-4 ">
                  <div class="custom-warning-box">
                      <div class="row">
                          <div class="col-3 col-sm-4 col-xs-4">
                              <span class="icon">
                                  <span class="text-center">
                                      <i class="fa fa-money-bill"></i>
                                  </span>
                              </span>
                          </div>
                          <div class="col-7 col-sm-8 col-xs-8">
                              <h4 class="m-0">
                                  <i class="fa fa-inr"></i> 350
                              </h4>
                              <span> Commissions </span>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-2 col-sm-12 col-xs-12 mt-4 mb-4 ">
                  <div class="custom-success-box">
                      <div class="row">
                          <div class="col-3 col-sm-4 col-xs-4">
                              <span class="icon">
                                  <span class="text-center">
                                      <i class="fa fa-money-check"></i>
                                  </span>
                              </span>
                          </div>
                          <div class="col-7 col-sm-8 col-xs-8">
                              <h4 class="m-0">
                                  <i class="fa fa-inr"></i> 650
                              </h4>
                              <span> Revenue </span>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row  ">
              <div class="col-sm-3 mb-2">
                  <input type="text" name="date" class="form-control float-left " placeholder="Search">
              </div>
              <div class="col-sm-9 mb-2">
                  <div class="dt-buttons btn-group flex-wrap float-right ">
                      <button class="btn btn-xs btn-primary buttons-copy buttons-html5" type="button" title="Copy"><span><i class="fas fa-copy"></i> Copy</span></button>
                      <button class="btn btn-xs btn-warning buttons-excel buttons-html5" type="button" title="Excel"><span><i class="fas fa-file-excel"></i> Excel</span></button>
                      <button class="btn btn-xs btn-success buttons-csv buttons-html5" title="CSV"><span><i class="fas fa-file-csv"></i> CSV</span></button>
                      <button class="btn btn-xs btn-danger buttons-pdf buttons-html5" type="button" title="PDF"><span><i class="fas fa-file-pdf"></i> PDF</span></button>
                  </div>
              </div>
              <table class="table table-bordered table-hover " role="grid" aria-describedby="example2_info">
                  <thead>
                      <tr role="row">
                          <th>#ID</th>
                          <th> Patient Info </th>
                          <th>Appointment Type </th>
                          <th>Amount Paid </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr class="odd" data-id="9">
                          <td>823</td>
                          <td>Patient1, Male, 32 </td>
                          <td>Video</td>
                          <td>650</td>
                      </tr>
                      <tr class="odd" data-id="9">
                          <td>823</td>
                          <td>Patient1, Male, 32 </td>
                          <td>Video</td>
                          <td>650</td>
                      </tr>
                      <tr class="odd" data-id="9">
                          <td>823</td>
                          <td>Patient1, Male, 32 </td>
                          <td>Video</td>
                          <td>650</td>
                      </tr>
                      <tr class="odd" data-id="9">
                          <td>823</td>
                          <td>Patient1, Male, 32 </td>
                          <td>Video</td>
                          <td>650</td>
                      </tr>
                      <tr class="odd" data-id="9">
                          <td>823</td>
                          <td>Patient1, Male, 32 </td>
                          <td>Video</td>
                          <td>650</td>
                      </tr>
                  </tbody>
              </table>
          </div>
      </div>
  </div>
</div>
