<!-- <div class="card   mt-3">
  <div class="position-absolute card-top-buttons">
  <button class="btn   icon-button">
  <i class="fa fa-calendar-alt themecol2" aria-hidden="true"></i>
  </button>
  </div>
  <div class="card-body">


   <div class="row p-2 mb-2 mt-1">

   <div class=" col-md-2" *ngFor="let item of previousAppointmentList|paginate: { id: 'p1', itemsPerPage: 6, currentPage: p1 }">
  <div class="d-flex flex-row card p-2  bta-1  mt-0">
  <div class=" d-flex flex-column flex-grow-1">
  <p class="font-weight-medium p_btm line_height">{{item.patientName}}   <span class="text-muted text-small"> {{item.ailment}} </span>  </p>
  <p class="font-weight-medium p_btm line_height pb-2">  <span class="mt-2 text-muted text-small"> {{item.fromTime|convertFrom24To12Format}}  - </span> <span class=" text-small"> {{item.toTime|convertFrom24To12Format}}  </span></p>
  <div class="border-top pt-1">
  <div class="btn-group btn-group-justified ">
  <a href="javascript:void(0)" (click)="prescriptionList(item)"><i class="fa fa-file-prescription themecol1 mr-3" aria-hidden="true"></i></a>

  <a href="javascript:void(0)" (click)="getPatientMedicalReports(item)"><i class="fa fa-notes-medical themecol3 mr-3" aria-hidden="true"></i></a>
  <a href="javascript:void(0)" (click)="viewAppointment(item)"><i class="fa fa-eye themecol4" aria-hidden="true"></i></a>

  </div>
  </div>
  </div>
  </div>
  </div>
   </div>

  </div>
  </div>

  <pagination-controls  autoHide="true"style="float: right;" autoHide="true" responsive="true" id="p1" (pageChange)="p1=$event" ></pagination-controls>	  -->
<div class="tab_content_bcivf2">
  <table class=" table table-striped  table-styled table-hover">
  <thead>
    <tr>
      <th>Patient Name </th>
      <th>Doctor Name </th>
      <th>Date / Time</th>
      <th>Reason</th>
      <th>Type</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="previousAppointmentList?.length > 0">
      <tr *ngFor="let appointment of previousAppointmentList">

        <td>
          {{appointment.patientName |
          titlecase}}
        </td>
        <td>
          {{appointment?.doctorName ?appointment?.doctorName:appointment?.referenceEntityName |
          titlecase}}
        </td>
        <td>{{appointment.fromTime|convertFrom24To12Format}} {{appointment.appointmentDate | date: 'dd-MMM-yyyy'}}
        </td>
        <td>{{appointment.ailment}}</td>
        <td> <i class="fa fa-phone-alt color_yellow"
            *ngIf="appointment?.chatType=='Telephone' || appointment?.chatType=='AUDIO_CHAT'" aria-hidden="true"></i>

          <i class="fa fa-comments  color_red"
            *ngIf="appointment?.chatType=='Chat' || appointment?.chatType=='TEXT_CHAT'"></i>

          <i class="fa fa-video color_green"
            *ngIf="appointment?.chatType=='Video' || appointment?.chatType=='VIDEO_CHAT'" aria-hidden="true"></i>
          <i *ngIf="appointment?.chatType=='In person' || appointment?.chatType=='Inperson'" class="fa-solid fa-hospital-user color_green" aria-hidden="true"></i>
        </td>
        <td>
            <button class="btn btn-sm mr-1 mt-2 p-1" 
              (click)="prescriptionList(appointment)" *ngIf="appointment?.prescriptionDone=='Y'">
              <i class="fa fa-file-prescription themecol4 patientc4"
                aria-hidden="true" data-placement="left" data-toggle="tooltip"
                data-original-title="Prescription"></i>
              </button>
            <!-- <button class="btn btn-sm mr-1 mt-2 p-1" (click)="invoiceData(appointment)" data-placement="left"
              data-toggle="tooltip" data-original-title="Invoice "><i class="fa fa-file-invoice icon-head patientc2"
                aria-hidden="true"></i></button> -->
                <button class="btn btn-sm mr-1 mt-2 p-1" 
                (click)="viewNotes(appointment)"><i
                  class="fa-book fa-solid patientc2" data-placement="left" data-toggle="tooltip"
                  data-original-title="Notes" aria-hidden="true"></i></button>
          </td>
      </tr>
    </ng-container>
    <tr *ngIf="previousAppointmentList?.length==0" style="background:#fff">
      <td colspan="6">
        <div class="no-appointments"></div>
      </td>
    </tr>
  </tbody>
</table>
</div>
<ul class="pagination">
  <pagination-controls autoHide="true" autoHide="true" responsive="true" (pageChange)="p1 = $event">
  </pagination-controls>
</ul>


<div class="modal fade modal-left" id="notes-modal" role="dialog" data-backdrop="static" data-keyboard="false" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:35%;">
      <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">Notes</h5>
              <button type="button" class="close" aria-label="Close" (click)="closenotes()">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body p-3">
              <div class="container">
                  <div class="row">
                      <div class="no-reports"></div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>