<div class="modal-header">
    <h4 class="modal-title">Create New Order</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <h4 class="head border-bottom p-2 mt-2 bg-primary-light">Patient Details</h4>

    <form [formGroup]="registerForm" class="mt-2">
        <div class="user row">

            <div class="col-md-3">
                <label class="form-labe">Mobile Number <span class="text-danger">*</span></label>
                <input #cellInput type="text" ng2TelInput [appUnifiedValidation]="['required','minlength']"
                    [minlength]="10" maxlength="10"
                    [ng2TelInputOptions]="{autoHideDialCode: false, initialCountry: 'in', separateDialCode:true,formatOnDisplay:false}"
                    class="form-control" (focusout)="checkPhNumber()" appInputRestrict="mobileNumber"
                    formControlName="cellNumber" placeholder="Enter mobile number" />
            </div>

            <div class="col-md-3">
                <label class="form-labe">First Name <span class="text-danger">*</span></label>
                <input type="text" appAppTitleCase placeholder="Enter First Name" appInputRestrict="alphaOnlyWithSpaces"
                    [appUnifiedValidation]="['required','minlength']" formControlName="firstName" [readonly]="userFound"
                    class="form-control" />
            </div>

            <div class="col-md-3">
                <label class="form-labe">Last Name <span class="text-danger">*</span></label>
                <input type="text" appAppTitleCase placeholder="Enter Last Name" appInputRestrict="alphaOnlyWithSpaces"
                    [appUnifiedValidation]="['required','minlength']" minlength="1" formControlName="lastName" [readonly]="userFound"
                    class="form-control" />
            </div>

            <div class="col-md-3">
                <label class="form-labe">Email ID</label>
                <input type="email" placeholder="Enter Email" [appUnifiedValidation]="['email']" [readonly]="userFound"
                    formControlName="emailId" class="form-control no-caps" />
            </div>

            <div class="col-md-12  float-right mt-2">
                <button type="button" class="btn btn-success float-right" *ngIf="!userFound"
                    (click)="savePatient()">Register</button>
            </div>
        </div>
    </form>


    <div class="orders mt-2">
        <h4 class="head border-bottom p-2 mt-2 bg-primary-light">Order Details</h4>

        <table class="inv-table table table-striped">
            <thead>
                <tr role="row">
                    <th>Name</th>
                    <th>Type</th>
                    <th>Quantity Available</th>
                    <th>Rack</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <!-- <th>Number of Days</th> -->
                    <th>C.G.S.T(%)</th>
                    <th>S.G.S.T(%)</th>
                    <th>Tax Amount</th>
                    <th>Price (After Tax)</th>
                    <!-- <th>Discount(%)</th>
                    <th>Total Price</th> -->
                    <th width="100px">Action
                        <button class="btn btn-primary btn-xs mr-1" (click)="addItem()"><i
                            class="fa fa-add"></i></button>
                    </th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of orderArray; let i = index;">
                    <td>
                        <ng-container *ngIf="item.isEditable; else editName">
                            <input class="form-control" matInput [(ngModel)]="item.medicineName"
                                [formControl]="medicineControl" [matAutocomplete]="auto1">
                            <mat-autocomplete #auto1="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async"
                                    (click)="onDrugSelect(i,option)" [value]="option.drugname">
                                    {{ option.drugname }}
                                </mat-option>
                            </mat-autocomplete>
                        </ng-container>
                        <!-- <ng-container *ngIf="item.isEditable; else editName">
                        <input type="text" [(ngModel)]="item.medicineName" class="form-control">
                    </ng-container> -->
                        <ng-template #editName>{{item.medicineName}}</ng-template>
                    </td>

                    <td>
                        <ng-container *ngIf="item.isEditable; else editType">
                            <input type="text" [(ngModel)]="item.type" class="form-control" disabled>
                        </ng-container>
                        <ng-template #editType>{{item.type}}</ng-template>
                    </td>

                    <td>
                        <ng-container *ngIf="item.isEditable; else editQuantityAvailable">
                            <input type="number" [(ngModel)]="item.quantityAvailable" class="form-control" disabled>
                        </ng-container>
                        <ng-template #editQuantityAvailable>{{item.quantityAvailable}}</ng-template>
                    </td>

                    <td>
                        <ng-container *ngIf="item.isEditable; else editRack">
                            <input type="text" [(ngModel)]="item.rack" class="form-control" disabled>
                        </ng-container>
                        <ng-template #editRack>{{item.rack}}</ng-template>
                    </td>

                    <td>
                        <ng-container *ngIf="item.isEditable; else editQuantity">
                            <input type="number" min="0" [(ngModel)]="item.quantity" (focusout)="calPrice(i)"
                                class="form-control">
                        </ng-container>
                        <ng-template #editQuantity>{{item.quantity}}</ng-template>
                    </td>

                    <td>
                        <ng-container *ngIf="item.isEditable; else editUnitPrice">
                            <input type="number" [(ngModel)]="item.unitPrice" class="form-control" disabled>
                        </ng-container>
                        <ng-template #editUnitPrice>{{item.unitPrice}}</ng-template>
                    </td>

                    <!-- <td>
                        <ng-container *ngIf="item.isEditable; else editNumberOfDays">
                            <input type="number" [(ngModel)]="item.numberOfDays" class="form-control">
                        </ng-container>
                        <ng-template #editNumberOfDays>{{item.numberOfDays}}</ng-template>
                    </td> -->



                    <td>
                        <ng-container *ngIf="item.isEditable; else editCgst">
                            <input type="number" [(ngModel)]="item.cgst" min="0" (focusout)="calPrice(i)" class="form-control">
                        </ng-container>
                        <ng-template #editCgst>{{item.cgst}}</ng-template>
                    </td>

                    <td>
                        <ng-container *ngIf="item.isEditable; else editSgst">
                            <input type="number" [(ngModel)]="item.sgst" min="0" (focusout)="calPrice(i)" class="form-control">
                        </ng-container>
                        <ng-template #editSgst>{{item.sgst}}</ng-template>
                    </td>

                    <td>
                        <ng-container *ngIf="item.isEditable; else tax">
                            <input type="number" [(ngModel)]="item.taxedAmount" disabled class="form-control">
                        </ng-container>
                        <ng-template #tax>{{item.taxedAmount | number:'1.2-2'}}</ng-template>
                    </td>

                    <td>
                        <ng-container *ngIf="item.isEditable; else editPrice">
                            <input type="number" [(ngModel)]="item.price" class="form-control" disabled>
                        </ng-container>
                        <ng-template #editPrice>{{item.price | number:'1.2-2'}}</ng-template>
                    </td>

                    <!-- <td>
                        <ng-container *ngIf="item.isEditable; else editDiscount">
                            <input type="text" [(ngModel)]="item.discount" class="form-control">
                        </ng-container>
                        <ng-template #editDiscount>{{item.discount}}</ng-template>
                    </td>

                    <td>
                        <ng-container *ngIf="item.isEditable; else editTotalPrice">
                            <input type="text" [(ngModel)]="item.totalPrice" class="form-control" disabled>
                        </ng-container>
                        <ng-template #editTotalPrice>{{item.totalPrice}}</ng-template>
                    </td> -->

                    <td>
                        <button *ngIf="item.isEditable" class="btn btn-success btn-xs mr-1" (click)="saveItem(i)"><i
                                class="fa fa-save"></i></button>
                        <button *ngIf="!item.isEditable" class="btn btn-primary btn-xs mr-1" (click)="editItem(i)"><i
                                class="fa fa-edit"></i></button>
                        <button class="btn btn-danger btn-xs" (click)="removeItem(i)"><i
                                class="fa fa-trash"></i></button>
                    </td>
                </tr>
            </tbody>

            <tfoot>
                <tr>
                    <td colspan="10"><b>Sub Total:</b></td>
                    <td colspan="1"><i class="fa fa-inr" aria-hidden="true"></i> {{subtotal | number:'1.2-2'}} </td>
                </tr>
                <tr>
                    <td colspan="10"><b>Tax Amount:</b></td>
                    <td colspan="1"><i class="fa fa-inr" aria-hidden="true"></i> {{taxAmount | number:'1.2-2'}}</td>
                </tr>
                <tr>
                    <td colspan="10"><b>Discount(%):</b></td>
                    <td colspan="1">
                        <input type="number" [(ngModel)]="totalDiscount" (focusout)="calTotals()" class="text-right"
                            class="form-control">
                    </td>
                </tr>
                <tr>
                    <td colspan="10"><b>Total Amount:</b></td>
                    <td colspan="1"><i class="fa fa-inr" aria-hidden="true"></i> {{totalAmount | number:'1.2-2'}}</td>
                </tr>
            </tfoot>

        </table>

        <div class="col-lg-2 float-right form-group mb-1">
            <label for="" class="col-form-label">Payment Mode</label>
            <mat-select class="form-control" name="item" [(ngModel)]="paymentMethod"
             placeholder="Payment Mode">
                <mat-option *ngFor="let item of cashModes" [value]="item.value">
                    {{item.value}}
                </mat-option>
            </mat-select>
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 float-right mt-2">
            <button type="button" [disabled]="!userFound" class="btn btn-success float-right"
                (click)="saveOrder()">Create Order</button>
        </div>

    </div>


</div>
