import { Inject, ViewEncapsulation } from '@angular/core';
import { Component, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-delete-confirmation',
  templateUrl: './delete-confirmation.component.html',
  styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent implements OnInit {
  status
  constructor(public dialogRef: MatDialogRef<DeleteConfirmationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.status = data?.status
  }

  ngOnInit(): void {
  }


  Yes() {
    this.dialogRef.close({ event: 'close', data: 'YES' });
  }
  No() {
    this.dialogRef.close({ event: 'close', data: 'NO' });
  }

}
