import { GlobalStorageService } from 'src/app/global-storage.service';
import { formatDate } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MedicalHistoryHolderComponent } from 'projects/ivf/src/app/medical-history-holder/medical-history-holder.component';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { PatientMedicalHistoryHolderComponent } from 'src/app/shared/components/patient-medical-history-holder/patient-medical-history-holder.component';
import { RolesControllerService } from 'projects/ivf/src/app/roles-controller.service';

@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['./medical-history.component.css']
})
export class MedicalHistoryComponent implements OnInit {

  constructor(private roleService:RolesControllerService ,private globalStorage: GlobalStorageService, private doctorPatientservice: DoctorPatientService, private ToastrService: ToastrService,private router:Router,private modalService:NgbModal) { }

  roles:any
  @Input() patientPersonalDetails: any

  ngOnInit(): void {

    this.roleService.permissions$.subscribe(res => {
      this.roles = res
     }) 

  }

  isIvf(){
    return this.globalStorage.getItem('loggedInAs')=='IVF'
  }

  isClinic(){
    return this.globalStorage.getItem('loggedInAs')=='HOSPITAL'
  }

  openMedicalHistory(){
    if(this.isIvf()){
      const modalRef = this.modalService.open(MedicalHistoryHolderComponent, {
        centered: false,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'right w-50'
      })
      modalRef.componentInstance.userId = this.patientPersonalDetails?.userId;
      modalRef.result.then(
        (userConfirmed: boolean) => {
          this.getMedicalHistory()
        },
        () => { }  // Handle dismiss callback
      );
    }

    if(this.isClinic()){
      const modalRef = this.modalService.open(PatientMedicalHistoryHolderComponent, {
        centered: false,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'modal-left hw-60'
      })
      modalRef.componentInstance.userId = this.patientPersonalDetails?.userId;
      modalRef.result.then(
        (userConfirmed: boolean) => {
          this.getMedicalHistory()
        },
        () => { }  // Handle dismiss callback
      );
    }
  }

  questionairre
  personalMedicalHistory
  medicalHistory
  surgicalHistory
  familyHistory
  allergies
  getMedicalHistory() {
    console.log('medication data',this.patientPersonalDetails)
    if(this.patientPersonalDetails?.userId){
      var reqObj = {
        forUserId: this.patientPersonalDetails?.userId,
        userId: this.patientPersonalDetails?.userId,
      }
    }
   else{
    var reqObj = {
      forUserId: this.patientPersonalDetails?.AppointmentDetails?.userId,
      userId: this.patientPersonalDetails?.AppointmentDetails?.userId,
    }
   }
    if(this.router.url=='/ivf-patients' || this.router.url=='/ivf-dashboard' || this.router.url=='/appointments' ){
    this.doctorPatientservice.getIvfPatientMedicalHistory(reqObj).subscribe(res=>{
      console.log('medical response',res)
      this.medicalHistory = res['data'];
        this.personalMedicalHistory = this.medicalHistory?.personalsymptoms
        this.personalMedicalHistory = this.personalMedicalHistory?.filter(obj => obj?.symptomselectted == true)
        var allergies = this.medicalHistory?.socialhistory[0]?.allergies;
        this.allergies = allergies?.split(',')
        this.questionairre = this.medicalHistory?.questionairre?.reverse()[0]
    }, error => {
      this.ToastrService.error("Error in getting patient medical history")
    })
    }
    else{
      this.doctorPatientservice.getPatientMedicalHistory(reqObj).subscribe(res => {
        this.medicalHistory = res['data'];
        this.personalMedicalHistory = this.medicalHistory?.personalsymptoms
        this.personalMedicalHistory = this.personalMedicalHistory?.filter(obj => obj?.symptomselectted == true)
        var allergies = this.medicalHistory?.socialhistory[0]?.allergies;
        this.allergies = allergies?.split(',')
        this.questionairre = this.medicalHistory?.questionairre?.reverse()[0]
        // this.surgicalHistory
        // this.familyHistory
      }, error => {
        this.ToastrService.error("Error in getting patient medical history")
      })
    }
  }


  activeMedicationList: any[] = []
  loadPatientActiveMedication(patient) {
    var medication = {
      "forUser": {
        "forUserId": parseInt(patient.userId),
        "userId": parseInt(patient.userId)
      },
      "isOnlyActive": true,
      "maxResults": 100,
      "pageNum": 1
    }
    if(this.router.url=='/ivf-patients'|| this.router.url=='/ivf-dashboard' || this.router.url=='/appointments'){
      var date: any = new Date()
      date = formatDate(date, "yyyy-MM-dd", 'en-us')
      console.log('AppointmentDetails',patient)
      if(patient?.userId){
        var body = {
          "medicinedate": date,
          "patientId": patient?.userId,
          "maxResult": 100,
          "pageNumber": 1,
        }
      }
     else{
      var body = {
        "medicinedate": date,
        "patientId": patient?.AppointmentDetails?.userId,
        "maxResult": 100,
        "pageNumber": 1,
      }
     }
      // this.doctorPatientservice.getIVFPatientActiveMedication(body).subscribe(res=>{
      //   console.log('medications',res)
      //   this.activeMedicationList = res['data']
      // })
    }
    else{
      this.doctorPatientservice.getPatientActiveMedication(medication).subscribe(res => {
        this.activeMedicationList = res['data']
      }, error => {
        this.ToastrService.error("Error in active medication")
      })
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('patientPersonalDetails' in changes) {
      this.getMedicalHistory();
      this.loadPatientActiveMedication(this.patientPersonalDetails)
    }


  }


}
