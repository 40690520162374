<div class="modal-header">
    <h5 class="modal-title">Order Status</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body ">
    <div class="text-left">
        <article class="card p-4" *ngIf="prescriptionInfo?.status=='Shipped' || prescriptionInfo?.status=='DELIVERED'">
            <div class="card-body row">
                <div class="col"> <strong>Pharmacy Name</strong> <br>{{pharmaInvoiceData?.pharmacyname}}</div><div class="col"> <strong>Estimated Delivery time:</strong> <br>{{pharmaInvoiceData?.expectedDate | date:'dd-MMM-yyyy'}} </div>
                <div class="col"> <strong>Shipping BY:</strong> <br> {{pharmaInvoiceData?.shippedBy}}, | <i class="fa fa-phone" aria-hidden="true"></i> {{pharmaInvoiceData?.mobileNumber?pharmaInvoiceData?.mobileNumber:'-'}} </div>
                <div class="col"> <strong>Order ID #:</strong> <br> {{pharmaInvoiceData?.invoiceId}} </div>
            </div>
        </article>
        <article class="card p-4" *ngIf="prescriptionInfo?.status=='Confiremed' || prescriptionInfo?.status=='Paid'">
            <div class="card-body row">
                <div class="col"> <strong>Pharmacy Name</strong> <br>{{pharmaInvoiceData?.pharmacyname}}</div>
                <div class="col"> <strong>Ordered Date</strong> <br>{{pharmaInvoiceData?.lastUpdatedTs | date:'dd-MMM-yyyy'}} </div>
                <div class="col"> <strong>Status:</strong> <br>{{prescriptionInfo?.status}}</div>
                <div class="col"> <strong>Order ID #:</strong> <br> {{prescriptionInfo?.entityInvoiceId}} </div>
            </div>
        </article><article class="card p-4" *ngIf="prescriptionInfo?.status=='REQUEST_PAYMENT' || prescriptionInfo?.status=='REQUEST_TO_PAY' || prescriptionInfo?.status=='Confirmed'">
            <div class="card-body row">
                <div class="col"> <strong>Pharmacy Name</strong> <br>{{pharmaInvoiceData?.pharmacyname}}</div>
                <div class="col"> <strong>Ordered Date</strong> <br>{{pharmaInvoiceData?.lastUpdatedTs | date:'dd-MMM-yyyy'}}  </div>
                <div class="col"> <strong>Status:</strong> <br>Confirmed
                <!-- <button class="btn btn-xs btn-danger">Pay Now</button> -->
            </div>
                <div class="col"> <strong>Order ID #:</strong> <br> {{prescriptionInfo?.entityInvoiceId}} </div>
            </div>
        </article>
        <div class="track">
            <div class="step  new" ><span class="icon"> <i class="fa fa-check" aria-hidden="true"></i></span> <span class="text">New</span> </div>
            <div class="step  pending"> <span class="icon"> <i class="fas fa-hourglass-half"></i>  </span> <span class="text">Pending</span></div>
            <div class="step confirmed"> <span class="icon"> <i class="fas fa-check-circle"></i></span> <span class="text">Confirmed</span> </div>
            <div class="step paid"> <span class="icon"> <i class="fas fa-money-check"></i></span> <span class="text">Paid</span> </div>
            <div class="step shipped"> <span class="icon"> <i class="fa fa-truck" aria-hidden="true"></i> </span> <span class="text">Shipped</span> </div>
            <div class="step delivered"> <span class="icon"> <i class="fa fa-box" aria-hidden="true"></i></span> <span class="text">Delivered</span> </div>
        </div>
    </div>
    <div class="" *ngIf="prescriptionInfo?.status=='New' || prescriptionInfo?.status=='Pending'" >
        <h5 class="mb-2"><b>Prescription Details</b></h5>
        <app-new-requests [prescriptionInfo]="prescriptionInfo"></app-new-requests>
    </div>
    <div class="" *ngIf="prescriptionInfo?.status=='Confirmed' || prescriptionInfo?.status=='Shipped' || prescriptionInfo?.status=='DELIVERED' || prescriptionInfo?.status=='Paid'">
        <h5 class="mb-2"><b>Order Details</b></h5>
        <app-prescription-list [prescriptionInfo]="prescriptionInfo" [medicationPrescriptions]="medicationPrescriptions" [paidAmount]="paidAmount" [pharmaInvoiceData]="pharmaInvoiceData"
        ></app-prescription-list>
    </div>
    <div class="" *ngIf="prescriptionInfo?.status=='New' && !pharmacySelected">
        <h5 class="mb-2"><b>Nearby Pharmacies</b></h5>
        <app-pharma-steps [userId]="prescriptionInfo?.patientId"  (pharmaSelect)="pharmaSelect($event)"></app-pharma-steps>
    </div>
    <div *ngIf="prescriptionInfo?.status=='New'">
        <app-patient-address-details *ngIf="pharmacySelected" (sendPharmacy)="sendToPharmacy($event)" [userId]="prescriptionInfo?.patientId"></app-patient-address-details>
    </div>
</div>
