import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ApicallsService } from 'src/app/apicalls.service';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CaretakerService {
  patientUrl = environment.patientBaseUrl
  doctorUrl = environment.doctorBaseUrl
  diagnosticUrl = environment.diagnosticBaseUrl
  pharmaUrl = environment.pharmaUrl
  caretakerUrl = environment.caretakerUrl
  hospitalUrl = environment.hospitalUrl
  utilitiesUrl = environment.utilitiesUrl
  rpmAdminUrl = environment.rpmAdminUrl;
  pdfUrl = environment.pdfUrl;

  private myAssociansSubject = new Subject<any>();
  myAssociansObservable = this.myAssociansSubject.asObservable();
  rpmUserData
  constructor(private globalStorage: GlobalStorageService, private http: HttpClient, private apiServices: ApicallsService) {
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
    this.patientUrl = environment.patientBaseUrl1
    this.doctorUrl = environment.doctorBaseUrl1
    this.diagnosticUrl = environment.diagnosticBaseUrl1
    this.pharmaUrl = environment.pharmaUrl1
    this.caretakerUrl = environment.caretakerUrl1
    this.hospitalUrl = environment.hospitalUrl1
    this.utilitiesUrl = environment.utilitiesUrl1
    this.rpmAdminUrl = environment.rpmAdminUrl1;
    this.pdfUrl = environment.pdfUrl1;
    }
   }
  private caretakerDetailsSubject = new Subject<any>();
  Observable = this.caretakerDetailsSubject.asObservable();
  sendRpmUserData(data) {
    this.rpmUserData = data
    this.myAssociansSubject.next(this.rpmUserData)
  }



  
  private sortOrder: string = 'asc';
  private sortField: string = '';

  sort(array: any[], field: string): any[] {
    if (this.sortField === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortOrder = 'asc';
    }

    this.sortField = field;
    const comparison = this.sortOrder === 'asc' ? 1 : -1;

    return array.sort((a, b) => {
      if (typeof a[field] === 'string' && typeof b[field] === 'string') {
        return a[field].localeCompare(b[field]) * comparison;
      } else if (typeof a[field] === 'number' && typeof b[field] === 'number') {
        return (a[field] - b[field]) * comparison;
      } else {
        return 0;
      }
    });
  }
  saveRegistration(body): Observable<any> {
    return this.apiServices.post(this.caretakerUrl + 'register', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }
  userNameExistOrNot(userName) {
    return this.apiServices.get(this.caretakerUrl + 'iscareTakerNameExit?userName=' + userName, {})
  }
  phoneNumberExistOrNot(phno) {
    return this.apiServices.get(this.caretakerUrl + 'iscaretaker/phonenumberexit?phoneNumber=' + phno, {})
  }
  getServicesList() {
    return this.apiServices.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=CARETAKER&type=SERVICE', {})
  }
  signin(request: any): Observable<any> {
    return this.apiServices.post(this.caretakerUrl + 'login',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }), observe: 'response' })
  }
  getAllCertificates() {
    return this.apiServices.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=CARETAKER&type=CERTIFICATION', {})
  }
  logout() {
    return this.apiServices.post(this.caretakerUrl + "logout", {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getPatientVitals(caretakerId) {
    return this.apiServices.post(this.patientUrl + 'getLatestVitalsForCaretaker?caretakerId=' + caretakerId, {}, {})
  }
  checkPhoneNumber(number): Observable<any> {
    return this.apiServices.get(this.caretakerUrl + 'phone/verification?cellNumber=' + number, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }
  getEmailVerification(email, otp) {
    let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.caretakerUrl + 'email/verification?emailid=' + email + "&emailotp=" + otp, { params: params })
  }
  getPhoneVerification(otp, phoneNumber) {
    let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.caretakerUrl + 'phone/verification?cellNumber=' + phoneNumber + "&otp=" + otp, {})
  }

  resetPassword(body) {
    let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.post(this.caretakerUrl + 'reset/password', body, {})
  }


  resetCaretakerPassword(data) {
    return this.apiServices.post(this.caretakerUrl + 'reset/password', data, {})
  }

  getAddressAll(postal) {
    return this.apiServices.get('https://api.worldpostallocations.com/pincode?postalcode=' + postal + '&countrycode=IN', {})
  }

  getAddressCT(postal, cc) {
    return this.apiServices.get('https://api.worldpostallocations.com/pincode?postalcode=' + postal + '&countrycode=' + cc, {})
  }
  getPincodeDetails(postal, cc) {
    return this.apiServices.get(this.utilitiesUrl + 'countrycodecontroller/getCountrycode?countrycode=' + cc + '&postalcode=' + postal, {})
  }
  resendEmailOTPForChangePassword(emailid) {
    return this.apiServices.get(this.caretakerUrl + 'resendOTP/foremail?emailId=' + emailid, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }
  resendEmailOTPForCT(emailid, userName) {
    return this.apiServices.get(this.caretakerUrl + 'resendOTP/foremail?emailId=' + emailid + '&userName=' + userName, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }
  otpVerify(email, otp) {
    return this.apiServices.get(this.caretakerUrl + 'emailOtpVerification?emailid=' + email + '&emailotp=' + otp, {})
  }
  saveAddressDetails(address): Observable<any> {
    return this.apiServices.post(this.caretakerUrl + 'save/address', address, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  savePersonalDetails(caretakerPersonaldetails): Observable<any> {
    return this.apiServices.post(this.caretakerUrl + 'save/personaldetail', caretakerPersonaldetails, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  checkEmailExistOrNot(email): Observable<any> {
    return this.apiServices.get(this.caretakerUrl + 'verify/emailId/exists?emailid=' + email, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getAddressDetails(caretakerId) {
    return this.apiServices.get(this.caretakerUrl + 'address?caretakerId=' + caretakerId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getPersonalDetails(caretakerId) {
    return this.apiServices.get(this.caretakerUrl + 'get/personaldetail?caretakerId=' + caretakerId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  cancelAppointment(appointment) {
    var cancel = {
      "appointmentId": appointment.appointmentId,
      "appointmentStatus": "ENTITY_CANCELLED",
      "caretakerId": parseInt(this.globalStorage.getItem('forUserId')),
      "reason": 'test',
      "scheduleHoursId": appointment.schedulehoursId
    }
    return this.apiServices.post(this.caretakerUrl + 'appointment/request/update/appointmentstatus', cancel, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': appointment.userId.toString(), "accept": "*/*" }) })
  }
  rescheduleAppointment(appointment) {
    var reschedule = {
      "appointmentId": appointment.appointmentId,
      "appointmentStatus": "ENTITY_RESCHEDULED",
      "caretakerId": parseInt(this.globalStorage.getItem('forUserId')),
      "reason": null,
      "scheduleHoursId": appointment.schedulehoursId
    }
    return this.apiServices.post(this.caretakerUrl + 'appointment/request/update/appointmentstatus', reschedule, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': appointment.userId.toString(), "accept": "*/*" }) })
  }
  getDoctorBaseSheduleView(caretakerId) {
    //return this.apiServices.get(this.baseURL+'baseschedule/all/'+docid,{ headers: new HttpHeaders({'Content-Type': 'application/json', 'user-session':JSON.parse(this.globalStorage.getItem('userSession')),"accept": "*/*"})})
    return this.apiServices.post(this.caretakerUrl + 'baseschedule/get/' + caretakerId + '/1/100', {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  createBaseSchedule(scheduler): Observable<any> {
    return this.apiServices.post(this.caretakerUrl + 'baseschedule/save', scheduler, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  updateBaseSchedule(scheduler): Observable<any> {
    return this.apiServices.post(this.caretakerUrl + 'baseschedule/update/' + scheduler.basseScheduleId, scheduler, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getCaretakerScheduleById(request) {
    return this.apiServices.post(this.caretakerUrl + 'schedule/all/bycriteria', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  bookAppointment(request, userId) {

    return this.apiServices.post(this.patientUrl + 'appointment/booking/newrequest', request,
      {
        headers: new HttpHeaders({
          "Content-Type": 'application/json',
          'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': userId.toString()
        })
      })
  }
  dashboardAppointments(body) {
    return this.apiServices.post(this.caretakerUrl + 'appointment/view/caretaker', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  myAppoinments(caretakerId) {  // "PREVIOUS", "CURRENT", "UPCOMING", "CANCELLED"
    var reqObj = {
      "caretakerId": caretakerId,
      "patientId": null,
      "viewAppointmentEnum": ["CURRENT", "UPCOMING", "PREVIOUS", "CANCELLED"]
    }
    return this.apiServices.post(this.caretakerUrl + 'appointment/view/caretaker', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  updateMyassocians() {
    this.myAssociansSubject.next()
  }
  getCaretakerReports(caretakerId) {
    return this.apiServices.get(this.caretakerUrl + 'getReportsForCaretaker/' + caretakerId,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getRpmCaretakerReports(caretakerId) {
    return this.apiServices.get(this.caretakerUrl + 'getReportsForRpmCaretaker/' + caretakerId,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  saveAssociation(association) {
    return this.apiServices.post(this.caretakerUrl + 'association', association, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  deleteAssociation(associationID) {
    return this.apiServices.delete(this.caretakerUrl + "association/" + associationID, {})
  }
  getAssociationDetails(caretakerId) {
    return this.apiServices.get(this.caretakerUrl + 'all/associations/' + caretakerId,
      { headers: new HttpHeaders({ 'forUserId': caretakerId, 'moduleName': 'CARETAKER', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getNotificationsAlertsCaretaker(id) {
    return this.apiServices.get(this.caretakerUrl + 'notificationalerts/get/all?caretakerId=' + id + "&maxResult=1000&pageNumber=1",
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getAllPatientData(caretakerId) {
    return this.apiServices.get(this.caretakerUrl + 'appointment/appointmentDetailsForCareTaker?caretakerId=' + caretakerId,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  updateNotifications(endpoint, notifId, request) {
    return this.apiServices.post(this.caretakerUrl + 'notificationalerts/updateNotificationalerts/' + endpoint + '?forUserId=' + notifId, request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  sendFeedback(formData) {
    return this.apiServices.post(this.utilitiesUrl + 'feedback/create', formData, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getInvoiceDetails(invoice) {
    return this.apiServices.post(this.caretakerUrl + 'invoice/criteria', invoice, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  caretakerProfileCompletedOrNot(caretakerId) {
    return this.apiServices.post(this.caretakerUrl + 'isProfileCompleted?caretakerId=' + caretakerId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getPatientList(caretakerId) {
    return this.apiServices.get(this.caretakerUrl + 'appointment/appointmentDetailsForCareTaker?caretakerId=' + caretakerId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getPatientPrescriptions(endpoint, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiServices.get(this.patientUrl + endpoint,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': userId.toString() }) })
  }
  getPrescriptionPostSession(endpoint, body, userId) {
    return this.apiServices.post(this.patientUrl + endpoint, body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }) })
  }
  getCaretakerFeeDetails(caretakerId) {
    return this.apiServices.get(this.caretakerUrl + 'getCareTakerFeeDetails?caretakerId=' + caretakerId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  deleteFee(feeId, active) {
    return this.apiServices.delete(this.caretakerUrl + 'deleteCaretakerFeeDetails?active=' + active + '&feedetailsId=' + feeId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  saveFeeDetails(body) {
    return this.apiServices.post(this.caretakerUrl + 'saveFeeDetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  saveCertificationDetails(certification): Observable<any> {
    return this.apiServices.post(this.caretakerUrl + 'saveCertification', certification, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })   //
  }
  getCertificationDetails(caretakerId) {
    return this.apiServices.get(this.caretakerUrl + 'getCaretakerCertifications?caretakerId=' + caretakerId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getCaretakerDetails(caretakerId) {
    if (caretakerId) {
      return this.apiServices.get(this.caretakerUrl + 'get/caretaker?caretakerId=' + caretakerId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
    }
  }
  updateCaretaker(caretakerId, body) {
    return this.apiServices.post(this.caretakerUrl + 'updateCaretaker?caretakerId=' + caretakerId, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  resendEmailOtp(email) {
    return this.apiServices.get(this.caretakerUrl + 'resendOTP/foremail?emailId=' + email, {})
  }

  resendEmailOtpCT(email, userName) {
    return this.apiServices.get(this.caretakerUrl + 'resendOTP/foremail?emailId=' + email + '&userName=' + userName, {})
  }
  resendMobileOtp(mobileNumber) {
    return this.apiServices.get(this.caretakerUrl + 'resendOTP/forphone?cellNumber=' + mobileNumber, {})
  }

  resendMobileOtpCT(mobileNumber, userName) {
    return this.apiServices.get(this.caretakerUrl + 'resendOTP/forphone?cellNumber=' + mobileNumber + '&userName=' + userName, {})
  }

  getUserPersonalDetails(endpoint, userId, req) {
    return this.apiServices.post(this.patientUrl + endpoint, req,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': userId.toString(), "accept": "*/*" }), observe: 'response' })
  }
  getAllVitalsByDayByDay(request, userId) {
    return this.apiServices.post(this.patientUrl + 'vitals/get',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': userId.toString() }), observe: 'response' })
  }
  deleteDoctorEducation(certificationId) {
    return this.apiServices.delete(this.caretakerUrl + 'certification/' + certificationId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getUsersList(caratakerId, hospitalId) {
    if (hospitalId != null) {
      return this.apiServices.post(this.hospitalUrl + 'getCaretakerPatientInformation?caretakerId=' + caratakerId + '&hospitalAddresId=' + hospitalId, {},
        { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': this.globalStorage.getItem('forUserId'), "accept": "*/*" }), observe: 'response' })
    }
  }
  getRpmUserList(caratakerId) {
    return this.apiServices.post(this.rpmAdminUrl + 'getCaretakerPatientInformation?caretakerId=' + caratakerId, {},
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'RPMADMIN', 'forUserId': this.globalStorage.getItem('forUserId'), "accept": "*/*" }), observe: 'response' })
  }
  saveNotes(body) {
    return this.apiServices.post(this.caretakerUrl + 'appointment/save/appointmentnotes', body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'CARETAKER', 'forUserId': body?.caretakerId.toString(), "accept": "*/*" }), observe: 'response' })
  }

  getRPMCaretkareNotes(appointmentId,caretakerId,rpmAdminaddId) {
    return this.apiServices.get(this.caretakerUrl + 'appointment/appointmentsForRpm?appointmentId=' + appointmentId + '&caretakerId=' + caretakerId + '&rpmAdminaddressId=' + rpmAdminaddId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'CARETAKER', 'forUserId': caretakerId.toString(), "accept": "*/*" }), observe: 'response' })
  }

  getNotes(caretakerId) {
    return this.apiServices.get(this.caretakerUrl + 'appointment/appointmentnotesbycaretakerId/' + caretakerId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'CARETAKER', 'forUserId': caretakerId.toString(), "accept": "*/*" }), observe: 'response' })
  }
  currentDate = new Date();
  selectedItem = formatDate(this.currentDate, "yyyy-MM-dd", 'en-us')
  dateS = new Date(this.selectedItem)
  getCaretakerBaseschedule(docid, month) {  //CURRENT_WEEK, CURRENT_DAY, CURRENT_MONTH
    var obj = {
      "careTakerId": parseInt(this.globalStorage.getItem('forUserId')),
      "fromDate": this.selectedItem,
      // "maxResutls": 10,
      // "monthNumber": month,
      // "pageNum": 1,
      // "scheduleCalenderEnum": "CURRENT_MONTH",
      "toDate": formatDate((this.dateS.setMonth(this.dateS.getMonth() + 1)), "yyyy-MM-dd", 'en-us'),
      // "weekOfYear": 0,
      // "year": 0
    }
    return this.apiServices.post(this.caretakerUrl + 'schedule/all/bycriteria', obj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getCaretakerSlots(ivfAddressId, id) {
    var obj = {
      "associateEntityId": ivfAddressId,
      "careTakerId": id,
      "maxResutls": 100,
      "pageNum": 1,
    }
    return this.apiServices.post(this.caretakerUrl + 'schedule/all/bycriteria', obj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getClincicCaretakerBaseschedule(id, month) {  //CURRENT_WEEK, CURRENT_DAY, CURRENT_MONTH
    var obj = {
      "careTakerId": id,
      "fromDate": this.selectedItem,
      // "maxResutls": 10,
      // "monthNumber": month,
      // "pageNum": 1,
      // "scheduleCalenderEnum": "CURRENT_MONTH",
      "toDate": formatDate((this.dateS.setMonth(this.dateS.getDay() + 15)), "yyyy-MM-dd", 'en-us'),
      // "weekOfYear": 0,
      // "year": 0
    }
    return this.apiServices.post(this.caretakerUrl + 'schedule/all/bycriteria', obj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  saveScheduleHours(reqObj) {
    return this.apiServices.post(this.caretakerUrl + 'schedule/save', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  postProfilePic(id, data) {
    return this.apiServices.post(this.caretakerUrl + 'profilepic/' + id, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  bookSlot(body) {
    return this.apiServices.post(this.caretakerUrl + 'saveCaretakerrpmappointment', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getAppointments(caretakerId) {
    return this.apiServices.get(this.caretakerUrl + 'getCaretakerrpmappointmenById?caretakerId=' + caretakerId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getRpmAppointments(body) {
    return this.apiServices.post(this.caretakerUrl + 'view/caretaker/myAppointments', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  saveCallDetails(body) {
    return this.apiServices.post(this.caretakerUrl + 'saveCalldetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getPatientCallDetails(patientId, caretakerId) {
    return this.apiServices.get(this.caretakerUrl + 'getCalldetailForPatient?caretakerId=' + caretakerId + '&patient=' + patientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getCaseDeatilsByAppointmentId(appointmentId, caretakerId, patientId) {
    return this.apiServices.get(this.caretakerUrl + 'getCalldetailByAppointmentId?appointmentId=' + appointmentId + '&caretakerId=' + caretakerId + '&patient=' + patientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  updateCaretakerAppointment(body) {
    return this.apiServices.post(this.caretakerUrl + 'updateCaretakerrpmappointment', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  cancelRpmAppointment(body) {
    return this.apiServices.post(this.caretakerUrl + 'updateCaretakerrpmappointment', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  startCall(body) {
    return this.apiServices.post(this.caretakerUrl + 'saveCalldetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  endCall(body) {
    return this.apiServices.post(this.caretakerUrl + 'updateCalldetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  saveCallHistory(body) {
    return this.apiServices.post(this.caretakerUrl + 'saveCallhistory', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  userPreVitalsHistory(body){
    return this.apiServices.post(this.caretakerUrl + 'userVitals', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getVitalsOfCaretakerPatient(caretakerId, rpmadminAddressId) {
    return this.apiServices.get(this.rpmAdminUrl + 'getLatestVitals?caretakerId=' + caretakerId + '&rpmadminAddressId=' + rpmadminAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'RPMADMIN', 'forUserId': this.globalStorage.getItem('forUserId'), "accept": "*/*" }), observe: 'response' })
  }

  getCancelledPatientInvoice(caretakerappointmentid, caretakerId){
    return this.apiServices.get(this.caretakerUrl + 'invoice/findInvoiceBydoctorappointmentid?caretakerappointmentid=' + caretakerappointmentid + '&caretekerId=' + caretakerId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'CARETAKER', 'forUserId': this.globalStorage.getItem('forUserId'), "accept": "*/*" }), observe: 'response' });
  }

  downloadCaretakerPdf(body): Observable<any>{
    return this.apiServices.post(this.pdfUrl + 'caretaker', body,{responseType: 'blob' as 'json'});
  }

  checkOldPassword(email,password){
    return this.apiServices.get(this.caretakerUrl+'oldPaswwordVerification?emailId='+email+'&paswword='+password,{})
  }
}
