import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';
import { SaveOrderDispatchComponent } from '../save-order-dispatch/save-order-dispatch.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-dispatch',
  templateUrl: './order-dispatch.component.html',
  styleUrls: ['./order-dispatch.component.css']
})
export class OrderDispatchComponent implements OnInit {

  @Input() stockObj;
  @Input() addressId;

  constructor(private globalStorage: GlobalStorageService, 
    private activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private inventoryService: InventoryService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fetchDispatchList();
  }

  closeModal() {
    this.activeModal.close(true);
  }

  orderDispatch; p3 = 1;
  fetchDispatchList() {
    this.inventoryService.orderDispatchList(this.stockObj?.inventoryId).subscribe(res => {
      this.orderDispatch = res?.['data'] || [];
      this.p3 = 1;
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  saveDispatch(){
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-left hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-40';
    }
    const modalRef = this.modalService.open(SaveOrderDispatchComponent,{
      centered: false,
      backdrop: 'static',
      windowClass: wClass
    });
    modalRef.componentInstance.stockObj = this.stockObj;
    modalRef.componentInstance.addressId = this.addressId;

    modalRef.result.then(
      (result) => {
        this.fetchDispatchList();
      },
      (reason) => {
      }
    );
  }

}
