<div class="modal-header">
  <h4 class="modal-title">Add Inventory</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<div class="modal-body">

  <form [formGroup]="inventoryForm">
    <div class="row">
      <div class="cryolock p-1 mb-2 ">
        <h4 class="head pl-2 pt-1 pb-1 m-0 semenwhite_bc_opacity border_6_reds text-white font-size-12">Item Details
        </h4>
      </div>
      <div class="row mt-1 mb-1">
        <div class="col-lg-4">
          <label class="form-label">Item Name <span class="text-danger">*</span></label>
          <input appAppTitleCase type="text" formControlName="name" class="form-control" />
        </div>

        <div class="col-lg-4 col-md-4">
          <div class="form-group">
            <label>Category <span class="text-danger">*</span> </label>
            <mat-select class="form-control" formControlName="catagoryId">
              <mat-option *ngFor="let c of categorylist" fo [value]="c.catagoryId"
                (click)="selectedCategory(c)">{{c.catagory}}</mat-option>
            </mat-select>
          </div>
        </div>

        <div class="col-lg-4 col-md-4">
          <div class="form-group">
            <label for="" class=""> Supplier<span class="text-danger">*</span></label>
            <mat-select class="form-control" id="Supplier" formControlName="supplierId">
              <mat-option *ngFor="let sup of suplier" [value]="sup.supplierId"
                (click)="selectedSuppiler(sup)">{{sup.name}}</mat-option>
            </mat-select>
          </div>
        </div>

        <div class="col-lg-4">
          <label class="form-label">Disposable</label>
          <mat-select placeholder="Select..." formControlName="disposable" class="form-control">
            <mat-option *ngFor="let val of selectOptions" [value]="val.value">{{val.label}}</mat-option>
          </mat-select>
        </div>


      </div>
      <div class="cryolock p-1 mb-2">

        <h4 class="head pl-2 pt-1 pb-1 m-0 semenwhite_bc_opacity border_6_reds text-white font-size-12">Price Details
        </h4>
      </div>
      <div class="row mt-1 mb-1">
        <div class="col-lg-4">
          <label class="form-label">Quantity <span class="text-danger">*</span></label>
          <input type="number" formControlName="quantity" class="form-control" (focusout)="calPrice()" />
        </div>

        <div class="col-lg-4">
          <label class="form-label">Buy Price(unit) <span class="text-danger">*</span> </label>
          <input type="number" (focusout)="calPrice()" formControlName="unitPrice" class="form-control" />
        </div>

        <div class="col-lg-4">
          <label class="form-label">Tax <span class="text-danger">*</span></label>
          <select formControlName="tax" class="form-control">
            <option *ngFor="let percentage of percentages" [value]="percentage">
              {{ percentage }}%
            </option>
          </select>
        </div>


        <div class="col-lg-4">
          <label class="form-label">M.R.P <span class="text-danger">*</span></label>
          <input type="number" formControlName="saleUnitPricel" class="form-control" />
        </div>
      </div>

      <div class="cryolock p-1 mb-2">

        <h4 class="head pl-2 pt-1 pb-1 m-0 semenwhite_bc_opacity border_6_reds text-white font-size-12">Manufacturer
          Details</h4>
      </div>
      <div class="row mt-1 mb-1">

        <div class="col-lg-4 col-md-4">
          <label>Manufactured By</label>
          <mat-select placeholder="Select..." class="form-control" formControlName="manufacturer">
            <mat-option *ngFor="let data of manufacturersData" [value]="data.manufacturer"
              (click)="selectManufacturer(data)">
              {{ data.manufacturer }}
            </mat-option>
          </mat-select>
        </div>

        <div class="col-lg-4">
          <label class="form-label">Manufactured Date</label>
          <input appProcessDate type="datetime-local" formControlName="manufacturerDate" class="form-control" />
        </div>


        <div class="col-lg-4">
          <label class="form-label">Warranty</label>
          <input appProcessDate type="datetime-local" formControlName="warranty" class="form-control" />
        </div>
      </div>


      <div class="col-lg-4">
        <label class="form-label">Batch</label>
        <input appAppTitleCase type="text" formControlName="currentBatch" class="form-control" />
      </div>


      <div class="col-lg-4">
        <label class="form-label">Location</label>
        <input appAppTitleCase type="text" formControlName="rack" class="form-control" />
      </div>

      
      <div class="col-lg-12">
        <label class="form-label">Description</label>
        <!-- <input type="text" formControlName="description" class="form-control" /> -->
        <textarea autoResizeRows class="form-control" formControlName="description"></textarea>
      </div>


      <!-- <div class="col-lg-4">
        <label class="form-label">Exp Date</label>
        <input appProcessDate type="datetime-local" formControlName="expDate" class="form-control" />
      </div> -->








      <div class="col-lg-12 text-right">
        <div class="mt-0">
          <button style="margin-top: 10px;" type="button" class="btn btn-success" (click)="saveData()">Save</button>
        </div>
      </div>

    </div>
  </form>


</div>