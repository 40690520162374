import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-medical-history-holder',
  templateUrl: './medical-history-holder.component.html',
  styleUrls: ['./medical-history-holder.component.css']
})
export class MedicalHistoryHolderComponent implements OnInit {


  @Input() userId:any;
  constructor(public activeModal:NgbActiveModal) { }

  ngOnInit(): void {
  }

  patHistoryCompleted(){
    this.activeModal.close();
  }

}
