import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from 'src/app/patient-pages/pat-login/login.component';
import { RegisterComponent } from 'src/app/patient-pages/pat-register/register.component';
import { RouterModule } from '@angular/router';
import { AuthLayoutRoutes } from './auth-layout.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthLayoutComponent } from './auth-layout.component';
import { DoctorloginComponent } from '../../pages/doctorlogin/doctorlogin.component';
//import { DashboardComponent } from '../../DoctorPages/dashboard/dashboard.component';
import { DoctorRegistrationComponent } from '../../DoctorPages/doctor-registration/doctor-registration.component'
import { ForgotPasswordComponent } from '../../DoctorPages/forgot-password/forgot-password.component';
import { DoctorEmailverificationComponent } from '../../pages/doctor-emailverification/doctor-emailverification.component';
import { OTPverificationComponent } from '../../pages/otpverification/otpverification.component';
import { DiagnosticOtpVerificationComponent } from 'src/app/diagnostic-pages/diagnostic-otp-verification/diagnostic-otp-verification.component';
import { DiagnosticLoginComponent } from 'src/app/diagnostic-pages/diagnostic-login/diagnostic-login.component';
import { DiagnosticRegistrationComponent } from 'src/app/diagnostic-pages/diagnostic-registration/diagnostic-registration.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { Ng2TelInputModule } from 'ng2-tel-input';

@NgModule({
  declarations: [
    AuthLayoutComponent,
    LoginComponent,
    RegisterComponent,
    DoctorloginComponent, DoctorRegistrationComponent, ForgotPasswordComponent, DoctorEmailverificationComponent, OTPverificationComponent,
    DiagnosticLoginComponent, DiagnosticRegistrationComponent, DiagnosticOtpVerificationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    Ng2TelInputModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(AuthLayoutRoutes),
  ],
  providers: [

  ]
})
export class AuthLayoutModule { }
