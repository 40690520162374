import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit } from '@angular/core';
import {ViewEncapsulation} from '@angular/core';
import { NotificationService } from 'src/app/shared/notification.service';
import { gpsService } from 'src/app/shared/services/gps.service';
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
import { interval, Subscription } from 'rxjs';
import { PatientService } from 'src/app/shared/services/patient.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { ObservableService } from 'src/app/shared/services/observable.service';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-patient-layout',
  templateUrl: './patient-layout.component.html',
  styleUrls: ['./patient-layout.component.css']
})
export class PatientLayoutComponent implements OnInit {
  associatedUserData
  sideBarOpen = true;
  subscription: Subscription;
  isHome=false;isSideBar
  isAssociated;errorMessage
  constructor(private globalStorage: GlobalStorageService, private gps:gpsService,private patientService:PatientService,public patSchedule: PatientScheduleControlService,
    private notification:NotificationService,private service:ScriptsService,private observable: ObservableService) {
      this.observable.invokeEvent_ChangeData.subscribe(value => {
        if (value === 'someValue') {
          this.getPersonalDts()
        }
      });
   }

  ngOnInit() {
    this.patientService.myMethod$.subscribe((data) => {
      this.associatedUserData = data; // And he have data here too!
  }
);
  //this.service.loadScripts();
  var data= this.globalStorage.getItem('userData')
  var obj=JSON.parse(data)
  //this.loadNotifications()
  this.getPersonalDts()
  //this.getAssociatedUsers()
  this.notification.requestPermission(obj['userId']);
  // this.notification.receiveMessage();
  this.notification.currentMessage
  this.checkHomeOrNot();
  const source = interval(1000);
  this.subscription = source.subscribe(val => this.checkHomeOrNot());
  this.loadNotificationBasedOnRoute()
   }
   loadNotificationBasedOnRoute(){
    if(!this.isHome){
      this.loadNotifications()
    }
   }
   reminders;p2=1
loadNotifications(){
  this.associatedUserData = this.patSchedule.sharingData
  var id
  if (this.associatedUserData) {
    id = this.associatedUserData.relationUserId
  }
  else {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    id = obj['userId']
  }
  this.patSchedule.getGlobal('notificationalerts/get/all?maxResult=100&pageNumber=' + this.p2 + '&userId=' + id, id).subscribe(res => {
    this.reminders = res['data']
  })
}
associationUsersList
// getAssociatedUsers() {
//   var obj = this.globalStorage.getItem('userData')
//   obj = JSON.parse(obj)
//   this.userId = obj['userId']
//   var body = {
//     "maxresult": 100,
//     "pageNumber": 1,
//     "userId": this.userId
//   }
//   this.patSchedule.getPatientAsoociatedUsers(this.userId, obj['userId'], body).subscribe(res => {
//     this.associationUsersList = res['body']['data']
//     //this.personalData['associationUsersList']=this.associationUsersList
//   })
// }
personalData;userId
getPersonalDts(){
  var data = this.globalStorage.getItem('userData')
  var obj = JSON.parse(data)
  this.associatedUserData = this.patSchedule.sharingData
  if (this.associatedUserData) {
    this.userId = this.associatedUserData.relationUserId
  }
  else {
    this.userId = obj["userId"];
  }
  var req = {
    "forUserId": this.userId,
    "userId": obj['userId']
  }
  // this.patSchedule.getUserPersonalDetails('personaldetails/get/', this.userId, req).subscribe(res => {
  //   this.patSchedule.savePersonalData(res['data'])
  //   this.personalData = res['data']
  // }
  // )
}
  sideBarToggler() {
    this.sideBarOpen = !this.sideBarOpen;
  }
  isErrorMessage=false
  checkHomeOrNot(){
    // if(){
    //   this.isHome = true;
    //   this.isAssociated =false;
    //   $('#top_nav').addClass('col-lg-8')
    // }
    // else{
    //   this.isHome = false;
    //   // this.isAssociated =true;
    //   $('#top_nav').addClass('col-lg-12')
    // }
    this.errorMessage = this.patSchedule?.errorMessage
    if(this.errorMessage != undefined || this.errorMessage !=null){
      this.isErrorMessage=true
    }
    else{
      this.isErrorMessage=false
    }
    if(window.location.pathname == '/p-dashboard' || window.location.pathname == '/p-associated-dashboard' || window.location.pathname == '/cureMetric/p-dashboard'  || window.location.pathname == '/cureMetric/p-associated-dashboard'){
      this.isHome = true;
      $('.home').addClass('col-lg-8 col-md-8 col-sm-12')
      $('.home').removeClass('col-lg-12 col-md-12 col-sm-12')
      this.isSideBar =true
      this.isAssociated =false;
     // $('#top_nav').addClass('col-lg-8')
     }
    else{
      $('.home').removeClass('col-lg-8 col-md-8 col-sm-12')
      $('.home').addClass('col-lg-12 col-md-12 col-sm-12')
      this.isHome = false;
      this.isSideBar = false
    //  $('#top_nav').addClass('col-lg-12')
    }
  }
}
