<div class="modal-header">
    <h4 class="centerHeader mb-0">Order Batch Information</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">

    <table class="table table-bordered table-hover table-styled table-striped">
        <thead>
            <tr role="row">
                <th>Invoice ID</th>
                <th>Batch ID</th>
                <th>Batch Number</th>
                <th>Total Stock Ordered</th>
                <th>No of Items Deliver</th>
                <th>Total Price</th>
                <th>Expected Delivery Date</th>
                <th>Delivered Date</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of ordersBatchInfo | paginate: { id: 'p1', itemsPerPage: 10, currentPage: p1 }">
                <td>{{data?.invoiceId}}</td>
                <td>{{data?.orderBatchId}}</td>
                <td>
                    <a href="javascript:void(0)" (click)="getbatchDetails(data)">{{data?.batchNumber}}</a>
                </td>
                <td>{{data?.totalStockOrdered}}</td>
                <td>{{data?.noOfitemDeliver}}</td>
                <td>{{data?.price | number:'1.2-2'}}</td>
                <td>{{data?.expectedDeliveryDate | date: 'dd-MMM-yyyy'}}</td>
                <td>{{data?.deliveredDate | date: 'dd-MMM-yyyy'}}</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="ordersBatchInfo?.length == 0">
        <div class="no-data-found"></div>
    </div>
    <div *ngIf="ordersBatchInfo?.length > 10">
        <div class="text-right">
            <ul class="pagination">
                <pagination-controls autoHide="true" id="p1" (pageChange)="p1=$event"></pagination-controls>
            </ul>
        </div>
    </div>
</div>