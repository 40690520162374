<div class="modal-header">
    <h5 class="modal-title">Prescriptions</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class=" bg-success p-2"  aria-expanded="true">
        <span class="title"><i class="fas fa-prescription"></i> Medical Prescription </span>
        <span class="accicon float-right"></span>
      </div>
    <!-- <h4 *ngIf="MedicationPrescriptions?.length>0" class="mt-2 mb-3 border-bottom pt-2 pb-2 head">Medical Prescriptions</h4> -->
    <div *ngIf="MedicationPrescriptions?.length == 0 || MedicationPrescriptions==null || MedicationPrescriptions=='null'">
        <div class="no-prescriptions"></div>
      </div>
    <ol class="prescription-ol pl-0" >
    
      <li *ngFor="let med of MedicationPrescriptions ;let i=index"><i>{{med?.type}}</i> <b> {{med?.medicineName}}
          {{med?.power}} </b><span *ngIf="med.instructions"> ({{med?.instructions}})</span>
    
        <span class="float-right" style="border-left: 1px solid #000;  padding-left: 10px;margin-right: 70px;
    ">{{med?.numberOfDays}}days - {{med?.refill}} Refill(s)</span>
    
        <ul style="padding-left: 10px;"
        *ngIf="med.morning !== null && med.morning !== 0 || med.afternoon !== null && med.afternoon !== 0 || med.evening !== null && med.evening !== 0 || med.instructions !== null">
          <li style="list-style: none" 
          *ngIf="med.morning !== null && med.morning !== 0 || med.afternoon !== null && med.afternoon !== 0 || med.evening !== null && med.evening !== 0 || med.instructions !== null">
            ({{med?.morning?med?.morning:0}}+{{med?.afternoon?med?.afternoon:0}}+{{med?.evening?med?.evening:0}}) &emsp; </li>
          <li style="list-style: none"></li>
        </ul>
      </li>
    
    </ol>
    <div class=" bg-primary p-2"  aria-expanded="true">
        <span class="title"><i class="fa fa-file-prescription"></i> Lab Test Prescriptions </span>
        <span class="accicon float-right"></span>
      </div>
    <!-- <h4 *ngIf="medicalTestPrescription?.length>0" class="mt-2 mb-3 border-bottom pt-2 pb-2 head">Lab Test Prescriptions</h4> -->
    <div *ngIf="medicalTestPrescription?.length == 0 || medicalTestPrescription == null || MedicationPrescriptions=='null'">
        <div class="no-prescriptions"></div>
      </div>
    <ol class="prescription-ol pl-0" *ngIf="medicalTestPrescription?.length>0">
      <li *ngFor="let row of medicalTestPrescription;let i =index"><i>{{row.testType}}</i> <b> {{row.testName}} </b>
        <span> ({{row.instructions}})</span>
        <!-- <ul style="padding-left: 10px">
          <li style="list-style: none">
            {{row.notes}}</li>
          <li style="list-style: none"></li>
        </ul> -->
      </li>
    </ol>
  </div>

