
      <div class="modal-header">
        <h5 class="modal-title ">Add Prescription</h5>
        <div class="position-absolute card-top-buttons">
          <div class="w-100 btn-group form-group mt-2">


            <!-- <button type="submit" class=" btn btn-success btn-xs" (click)="OnSubmit(documentGrp.value)"><i
        class="fa fa-file-export"></i>
      Save Prescription
    </button> -->

          </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="p-2 ">
          <div class="p-2" style="display: none">
            <!-- <app-active-medication [patientMedication]="patientMedication"></app-active-medication> -->
            <div class="row">
              <div class="col-md-12" *ngIf="activeMedicationList.length!=0">
                <h6 class=" font_Poppins  p_14">Current Medication </h6>
              </div>
              <div class="row">
                <div class=" " *ngFor="let row of activeMedicationList;let i =index">
                  <div class=" d-flex flex-column flex-grow-1 w-100  blt-2 mb-2 card">
                    <a href="javascript:void(0)" class="float-left">
                      <button class="btn btn-sm float-left p-1"><i class="fa fa-pills   themecol2  icon-head"
                          aria-hidden="true"></i></button>
                      <p class="font-weight-medium p_btm line_height float-left  pt-1 d-inline-block text-truncate text-nowrap  overflow-hidden "
                        style="text-overflow: ellipsis;width: 100px;">
                        <span class="mt-2 text-muted p-1 "> {{row.medicineName | titlecase }} </span>
                        <span class="text-muted text-small">{{row.power}}</span>
                      </p>
                      <div class=" float-right">


                        <button class="btn btn-sm   p-1" (click)="presviousPrescEdit(row,i)"><i
                            class="fa fa-pencil-alt themecol2    icon-head" aria-hidden="true"></i></button>
                        <button class="btn btn-sm  p-1" (click)="onPreviousRemoveRowTest(i)"><i
                            class="fa fa-times   text-danger    icon-head" aria-hidden="true"></i></button>
                      </div>
                    </a>


                  </div>

                </div>
              </div>

            </div>

          </div>
          <!-- <div class="pt-2 pb-2 text-center  w-100">

        <button   class="btn btn-danger btn-sm " data-toggle="tooltip" data-placement="top" title="PNG/PDF" (click)="fileInput.click()" ><i class="fa fa-file-prescription "></i> Upload Prescription
          <input #fileInput (change)="onFileInput($event.target.files)"  type="file" style="display:none;" />
        </button>
      </div>

      <h3 class="text-center w-100 pt-2 pb-2">   Or </h3> -->
      <div class="pr-2 d-flex flex-column flex-grow-1 mr-2 w-100">
        <div class="pr-2 d-flex flex-column flex-grow-1 mr-2 w-50" *ngIf="!prescriptionGiven"> <input class="form-control" placeholder="Search Template"
          #templateName (keyup)="typeAheadSearchByName(templateName.value)" [(ngModel)]="templateSelected" matInput
          [formControl]="myControl" [matAutocomplete]="auto1" />
        <mat-autocomplete #auto1="matAutocomplete"  [panelWidth]="'auto'">
          <mat-option *ngFor="let option of filteredTemplateNames | async" [value]="option?.templateName" (click)="selectedTemplate(option)">
            {{option?.templateName}} 
          </mat-option>
        </mat-autocomplete>
  
        <span style="position: absolute;margin-left: 278px;margin-top: 0;"><i class="fa fa-search fs-16 p-2"
            (click)="selectedTemplate(templateSelected)"></i></span>
      </div>
         <!-- <input class="form-control" placeholder="Search Template"
        #templateName  [(ngModel)]="templateSelected"  *ngIf="!prescriptionGiven"/>
      <span style="position: absolute;margin-left: 278px;margin-top: 0;"><i class="fa fa-search fs-16 p-2"
          (click)="searchByTemplateName(templateSelected)" *ngIf="!prescriptionGiven"></i></span> -->
    </div>
          <div class="row">



            <div class="col-md-12">
              <div class="block inv-section p-2">
                <!-- <div class="top-inv-col" *ngIf="!isIvf">
                  <div class="inv-logo"><img alt="" src="./assets/img/logo.png"></div>
                  <div class="float-right text-left order-details">
                     <p>Date: <span>{{patientPersonalDetails?.AppointmentDetails?.appointmentDate |
                        date:'dd-MMM-yyyy'}}</span></p>
                  </div>
                </div> -->
                <!-- <div class="row" *ngIf="!isIvf">
                  <div class="col-md-6 col-xs-6">
                    <h5>Prescribed by</h5>
                    <ul class="inv-receiver">
                      <li class="">Dr {{loginUser?.firstName}} {{loginUser?.lastName}} <br>{{dstreet1}} {{dstreet2}}
                        {{dcity}} <br>{{dstate}} {{dcountry}}<br> {{dzip}} </li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-xs-6 text-right">
                    <h5>Prescribed to</h5>
                    <ul class="inv-receiver">
                      <li class="">{{patientName}} <br>{{pstreet1}} {{pstreet2}} {{pcity}} <br>{{pstate}}
                        {{pcountry}}<br>{{pzip}} </li>
                    </ul>
                  </div>

                </div> -->

                <div class="card no-bg">
                  <div class="position-absolute card-top-buttons prescription-ol pt-1">

                    <button class="btn btn-primary btn-sm mr-2" data-placement="left" data-toggle="tooltip" title="Add"
                      data-original-title="Add" (click)="openForm()"><i class="fa fa-plus "></i></button>

                  </div>
                  <h3 class="mt-0 p-2 border-bottom font-size-12 bg-primary-light"><i class="fas fa-prescription"></i>
                    Medical Prescripiton</h3>
                  <form [formGroup]="prescriptionForm" *ngIf="isFormOpen">
                    <div class="row">

                     <div class="col-md-3 form-group">
                        <label>Type</label><span class="text-danger">*</span>
                        <select name="cars" class="form-control mb-0" id="cars" [(ngModel)]='ngSelectedType' formControlName="type">
                          <option  value="{{option?.lookupValue}}" *ngFor="let option of medicineTypes">{{option?.lookupValue}}</option>
                        </select>
                      </div>


                      <div class="col-md-7 form-group" *ngIf="ngSelectedType=='Syrup'">
                        <label>Medicine Name</label><span class="text-danger">*</span>

                        <input type="text" #medicine matInput class="form-control mb-0" [matAutocomplete]="auto"
                          formControlName="medicineName" placeholder="Medicine Name"[ngClass]="{
                            'is-invalid':
                              (submitted || f.medicineName.touched) && f.medicineName.errors
                          }"
                          (keyup)="searchMedicine(medicine.value)"/>

                                            <div *ngIf="(submitted || f.medicineName.touched) && f.medicineName.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.medicineName.errors.required">
                                                    Medicine name is required
                                                </div>

                                            </div>
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option *ngFor="let option of filteredOptions | async" [value]="option?.drugname">
                            {{option?.drugname}}
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                      <div class="col-md-9 form-group" *ngIf="ngSelectedType!='Syrup'">
                        <label>Medicine Name</label><span class="text-danger">*</span>

                        <input type="text" #medicine matInput class="form-control mb-0" [matAutocomplete]="auto"
                          formControlName="medicineName" placeholder="Medicine Name"[ngClass]="{
                            'is-invalid':
                              (submitted || f.medicineName.touched) && f.medicineName.errors
                          }"
                          (keyup)="searchMedicine(medicine.value)"/>

                                            <div *ngIf="(submitted || f.medicineName.touched) && f.medicineName.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.medicineName.errors.required">
                                                    Medicine name is required
                                                </div>

                                            </div>
                        <mat-autocomplete #auto="matAutocomplete">
                          <mat-option *ngFor="let option of filteredOptions | async" [value]="option?.drugname" (focusout)="selectedMedicine(option)">
                            {{option?.drugname}}
                          </mat-option>
                        </mat-autocomplete>
                      </div>
                      <div class="col-md-2 form-group" *ngIf="ngSelectedType=='Syrup'">
                        <label>Dosage</label><span class="text-danger">*</span>

                        <input type="text" class="form-control mb-0" formControlName="dosage" placeholder="Dosage">

                      </div>



                      <div class="col-md-5 form-group">

                        <div class="row pl-2">
                          <div class="form-check col-md-4">

                            <label for="inlineCheckbox1" class="text-center"><img
                                src="./assets/img/morning.png" /></label>

                            <input type="text" #morning min="1" max="5" maxlength="1" class="form-control mb-0"
                              placeholder="0" formControlName="morning" (keyup)="limit(morning.value)">
                          </div>
                          <div class="form-check col-md-4">

                            <label for="inlineCheckbox1" class="text-center"><img
                                src="./assets/img/afternoon.png" /></label>
                            <input type="text" #afternoon min="1" max="5" maxlength="1" class="form-control mb-0"
                              placeholder="0" formControlName="afternoon" (keyup)="limit(afternoon.value)">
                          </div>

                          <div class="form-check col-md-4">

                            <label for="inlineCheckbox1" class="text-center"><img
                                src="./assets/img/evening.png" /></label>


                            <input type="text" #evening min="1" max="5" maxlength="1" class="form-control mb-0"
                              placeholder="0" formControlName="evening" (keyup)="limit(evening.value)">
                          </div>
                        </div>
                        <div *ngIf="isUsage" class="text-danger small">Value should be between 1-5</div>

                      </div>
                      <div class="col-md-2 form-group">
                        <label>Duration</label><span class="text-danger">*</span>
                        <input type="text" #duration class="form-control mb-0" formControlName="numberOfDays"
                          placeholder="No. of Days" (keyup)="onKey(duration.value)"
                          (change)="refillscheck(morning.value,afternoon.value,evening.value,duration.value)" [ngClass]="{
                            'is-invalid':
                              (submitted || f.numberOfDays.touched) && f.numberOfDays.errors
                          }" />

                                            <div *ngIf="(submitted || f.numberOfDays.touched) && f.numberOfDays.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="f.numberOfDays.errors.required">
                                                    Number of days is required
                                                </div>

                                            </div>
                        <div *ngIf="isDuration" class="text-danger small">Value should be between 1-90</div>
                        <!-- <div *ngIf=".numberOfDays.errors" class="small">
            Value should be between 1-180
        </div> -->
                      </div>

                      <div class="col-md-2 form-group">
                        <label>Instructions</label><span class="text-danger">*</span>

                        <mat-select name="cars" class="form-control mb-0" id="cars" [(ngModel)]='ngSelect'
                          formControlName="instructions">
                          <mat-option *ngFor="let item of instructions" [value]="item.lookupValue">{{item.lookupValue}}
                          </mat-option>

                        </mat-select>
                      </div>
                      <div class="col-md-1 form-group">
                        <label>Refill</label><span class="text-danger">*</span>

                        <input type="text" class="form-control mb-0" formControlName="refill" placeholder="Refill">

                      </div>
                      <div class="col-md-2 form-group">
                        <label>Units</label><span class="text-danger">*</span>

                        <input type="text" #refills class="form-control mb-0" formControlName="numberofrefills"
                          placeholder="Number of refills" disabled>

                      </div>
                      <div class="col-md-12 form-group">
                        <label>Notes</label>

                        <textarea autoResizeRows class="form-control " name="message" placeholder="message"
                          formControlName="notes"></textarea>
                      </div>
                      <div class="col-md-12">
                        <button class="btn btn-success btn-xs mb-2 mr-2 float-right" (click)="onAddMedications()"><i
                            class="fa fa-plus "></i> Add</button>
                      </div>
                    </div>
                  </form>


                  <ol class="prescription-ol" *ngIf="prescriptions?.length>0">

                    <li *ngFor="let med of prescriptions ;let i=index"><i>{{med.type}}</i> <b> {{med.medicineName}}
                        {{med.power}} </b>
                      <span> ({{med.instructions}})</span>
                      <button class="btn btn-danger btn-sm mr-2 float-right" (click)="onRemoveRow(i)"
                        data-placement="left" data-toggle="tooltip" title="Delete" data-original-title="Delete"><i
                          class="fa fa-minus "></i></button>
                      <button class="btn btn-primary btn-sm mr-2 float-right" (click)="prescriptionEdit(med,i)"
                        data-placement="left" data-toggle="tooltip" title="Edit" data-original-title="Edit"><i
                          class="fa fa-pencil-alt "></i></button>

                      <span class="float-right" style="border-left: 1px solid #000;  padding-left: 10px;margin-right: 70px;
    ">{{med.numberOfDays}}days - {{med.refill}} Refill(s)</span>

                      <ul style="padding-left: 10px">
                        <li style="list-style: none">
                          {{med.morning}}+{{med.afternoon}}+{{med.evening}} &emsp; {{med.instructions}}</li>
                        <li style="list-style: none"></li>
                      </ul>
                    </li>

                  </ol>
                </div>
                <div class="card no-bg">
                  <div class="position-absolute card-top-buttons prescription-ol pt-1">

                    <button class="btn btn-primary btn-sm mr-2" (click)="openLabTestForm()"><i
                        class="fa fa-plus "></i></button>
                    <!-- <button  class="btn btn-danger btn-sm mr-2"><i  class="fa fa-minus "></i></button> -->
                  </div>
                  <h3 class="mt-0 p-2 border-bottom font-size-12 bg-success-light"><i class="fas fa-microscope"></i> Lab Tests</h3>
                  <form [formGroup]="medicaltestForm" *ngIf="isTestForm">
                    <div class="p-2 row">
                      <div class="col-md-2 form-group">
                        <label>Type</label><span class="text-danger">*</span>
                        <select name="scanType" class="form-control mb-0" id="scanType" [(ngModel)]='ngSelectTestType' formControlName="testType">
                          <option *ngFor="let test of labTestLists" [ngValue]="test">
                            {{test}}
                          </option>
                        </select>
                      </div>


                      <div class="col-md-6" *ngIf="!isIvf">
                        <div class="form-group">
                          <label>Test Name <span class="text-danger">*</span></label>
                          <input class="form-control mb-0" type="text" placeholder="Enter Test Name"
                            formControlName="testName" #testName (keyup)="search(testName?.value)" matInput
                            [formControl]="autoCompleteControl" [matAutocomplete]="autoMe" [(ngModel)]="selectedTestName"
                            [ngClass]="{ 'is-invalid': (submitted || m.testName.touched) && m.testName.errors}">
                          <div
                            *ngIf="(submitted || m.testName.touched) && m.testName.errors"
                            class="invalid-feedback">
                            <div *ngIf="m.testName.errors.required">Test Name is
                              required</div>
                          </div>
                          <mat-autocomplete #autoMe="matAutocomplete" (optionSelected)="onOptionSelected($event)">
                            <mat-option *ngFor="let option of filteredOptionsT | async" [value]="option"
                              (focusout)="selectedTest(option)">
                              {{ option }}
                            </mat-option>
                          </mat-autocomplete>
                        </div>
                      </div>
                      <div class="col-md-6 form-group" *ngIf="isIvf">
                        <label>Test Name</label><span class="text-danger">*</span>

                        <input type="text" #test matInput class="form-control mb-0" [matAutocomplete]="auto"
                          formControlName="testName" placeholder="Test Name"[ngClass]="{
                            'is-invalid':
                              (submitted || m.testName.touched) && m.testName.errors
                          }"
                          (keyup)="searchTest(test.value)"/>

                                            <div *ngIf="(submitted || m.testName.touched) && m.testName.errors"
                                                class="invalid-feedback">
                                                <div *ngIf="m.testName.errors.required">
                                                    Test name is required
                                                </div>

                                            </div>
                        <mat-autocomplete #auto="matAutocomplete" >
                          <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            {{option}}
                          </mat-option>
                        </mat-autocomplete>
                      </div>

                      <div class="col-md-4 form-group">
                        <label>Instructions</label><span class="text-danger">*</span>
                        <mat-select name="cars" class="form-control mb-0" id="cars" [(ngModel)]='ngSelect'
                          formControlName="instructions">
                          <mat-option *ngFor="let item of instructions" [value]="item.lookupValue">{{item.lookupValue}}
                          </mat-option>

                        </mat-select>


                      </div>
                      <div class="col-md-12 form-group">
                        <label>Notes</label>

                        <textarea autoResizeRows class="form-control " name="Notes" placeholder="Notes"
                          formControlName="notes"></textarea>
                      </div>
                      <div class="col-md-12">
                        <button class="btn btn-success btn-xs mb-2 mr-2 float-right" (click)="onAddMedicalTest()"><i
                            class="fa fa-plus "></i> Add</button>
                      </div>

                    </div>
                  </form>


                  <ol class="prescription-ol">
                    <li *ngFor="let row of medications;let i =index"><i>{{row.testType}}</i> <b> {{row.testName}} </b>
                      <span> ({{row.instructions}})</span>
                      <button class="btn btn-danger btn-sm mr-2 float-right" (click)="onRemoveRowTest(i)"><i
                          class="fa fa-minus "></i></button>
                      <button class="btn btn-primary btn-sm mr-2 float-right" (click)="medicalTestEdit(row,i)"><i
                          class="fa fa-pencil-alt "></i></button>

                      <ul style="padding-left: 10px">
                        <li style="list-style: none">
                          {{row.notes}}</li>
                        <li style="list-style: none"></li>
                      </ul>
                    </li>
                  </ol>
                </div>
                <div class="row" *ngIf="medications?.length>0 || prescriptions?.length>0">
                  <form [formGroup]="followup">
                    <div class="col-md-12 form-group">
                      <div class="row">
                        <label class="width-100 ml-1">Followup Date  <span class="font-size-10 text-muted">(MM/DD/YYYY)</span></label>
                        <div class="col-md-12 col-xs-12">
                          <input  matInput [min]="minDateFollow" [max]="maxDateFollow" [matDatepicker]="picker"
                            class="form-control mb-0" formControlName="followUpDate" datePickerFormat="MMMM DD YYYY" (click)="picker.open()" (keyup)="picker.open()"
                            (keypress)="picker.open()" >
                        </div>
                        <div class="col-md-1 col-xs-2" style="margin-top: -5px;margin-left: -55px;">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <div class=" text-right" *ngIf="!isPrescriptionShown">
                  <div class=" text-right">
                    <input type="checkbox"   [checked]="rememberme"
                    (change)="checkboxChecked()" *ngIf="!prescriptionGiven && !templatePresent">
                  <label style="margin-left:5px;" class="mr-2" for="customCheck1" *ngIf="!prescriptionGiven && !templatePresent"><b>Save as template</b></label>
                    <button class="btn btn-success mr-2" (click)="OnSubmit()" *ngIf="prescriptions?.length>0 || medications?.length > 0"><i
                        class="fas fa-save"></i> Save</button>
                  </div>
                  <!-- <button class="btn btn-success btn-xs mr-2" (click)="OnSubmit()" prescriptions?.length>0 || medications?.length > 0><i
                      class="fas fa-save"></i> Save</button> -->
                  <!-- <button class="btn btn-primary btn-xs mr-2" (click)="finalPreview()"><i class="fas fa-eye"></i>
                    Preview</button> -->
                  <!-- <button class="btn btn-danger btn-xs" (click)="closeModal()"><i class="fas fa-times"></i>
                    Cancel</button> -->
                </div>




              </div>
            </div>





            <div class="col-md-7" style="display: none;">
              <div class="tabs">
                <ul class="nav nav-tabs nav-justified">
                  <li class="nav-item">
                    <a class="active" id="pills-home-tab" (click)="addMedication()" aria-current="page"
                      href="javascript:void(0)"><i class="fas fa-prescription themecol1"></i> Add Medication </a>
                  </li>
                  <li class="nav-item">
                    <a class="" id="pills-profile-tab" (click)="addMedicalTest()" href="javascript:void(0)"><i
                        class="fas fa-microscope themecol2"></i> Add Medical Tests</a>
                  </li>
                  <li class="nav-item">
                    <a class="" id="upload-prescription-tab" (click)="uploadPrescription()" href="javascript:void(0)"><i
                        class="fa-solid fa-upload themecol3"></i> Upload Prescription</a>
                  </li>
                </ul>
              </div>
              <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active " id="pills-home" role="tabpanel"
                  aria-labelledby="pills-home-tab">
                  <div class="col-md-12 ">

                    <div class="card mt-0 no-bg">
                      <div class="card-body  ">

                        <a class="themecol1 float-right col-md-12  font-size-12 text-center"
                          style=" display: block; display: inline-block; " href="javascript:void(0)"
                          (click)="openForm()" *ngIf="!isFormOpen && isFirst">

                          <img src="./../../../assets/img/add-prescriptions.jpg" class="mt-2">
                        </a>
                        <form [formGroup]="prescriptionForm" *ngIf="isFormOpen">

                          <div class="row pt-1">

                            <div class="col-md-12 m-0 p-0">
                              <div class="position-absolute card-top-buttons prescription-ol pt-1">

                                <button class="btn btn-success btn-sm mr-2" [disabled]="!prescriptionForm.valid"
                                  (click)="onAddMedications()"><i class="fa fa-plus "></i></button>
                                <!-- <button  class="btn btn-danger btn-sm mr-2"><i  class="fa fa-minus "></i></button> -->
                              </div>
                              <h3 class="mt-0 mb-1 border-bottom p-2 head bgf9"><i
                                  class="fa  fa-prescription themecol1 icon-head" aria-hidden="true"></i> Add Medcine
                              </h3>





                            </div>






                            <!-- <div class="col-md-4 form-group">

                 <div class="row pl-2">
                <div  class="col-md-12">  <label>Usage</label><span class="text-danger">*</span></div>
                  <div class="form-check col-md-4">

                      <label class="form-check-label" for="inlineCheckbox1">Morning</label>

                      <input type="text" #morning  class="form-control mb-0" placeholder="" formControlName ="morning">
                  </div>
                  <div class="form-check col-md-4">

                    <label class="form-check-label" for="inlineCheckbox1">Afternoon</label>
                    <input type="text" #afternoon  class="form-control mb-0" placeholder="" formControlName="afternoon">
                </div>

                <div class="form-check col-md-4">

                  <label class="form-check-label" for="inlineCheckbox1">Night</label>


                  <input type="text"  #evening class="form-control mb-0" placeholder="" formControlName ="evening" (change)="refillscheck(refills.value,morning.value,afternoon.value,evening.value,duration.value)">
              </div>
              </div>


              </div>
     -->
                            <!-- <span class='fa-stack fa-lg greaterThan' *ngIf="!isNotesOpen">
      <i class='fas fa-circle fa-stack-2x' (click)="rightArrow()"></i>
      <i class='fas fa-greater-than fa-stack-1x fa-inverse' (click)="rightArrow()"></i>
  </span>
  <span class='fa-stack fa-lg greaterThan' *ngIf="isNotesOpen">
    <i class='fas fa-circle fa-stack-2x' (click)="rightArrow()"></i>
    <i class='fas fa-less-than fa-stack-1x fa-inverse' (click)="leftArrow()"></i>
</span> -->



                          </div>
                        </form>

                        <button style="display: none" class="btn btn-success bt-xs toggle float-right" id="toggle"
                          (click)="onAddMedications()">
                          Add to prescription</button>








                      </div>

                    </div>







                  </div>
                  <!-- <div class="col-md-12 mt-0"   >


      <div class="d-flex row flex-row pl-2  mb-1" >





          <div class=" d-flex flex-column flex-grow-1 col-md-3  blt-2 " *ngFor="let med of prescriptions ;let i=index">
            <a href="javascript:void(0)" class="float-left">
            <p class="font-weight-medium p_btm line_height float-left  pt-1 d-inline-block text-truncate text-nowrap  overflow-hidden "  style="text-overflow: ellipsis;width: 100px;">
             <span class="mt-2 text-muted p-1"> {{med.medicineName}} {{med.power}} </span>
              </p>
             <div class=" float-right">
              <button class="btn btn-sm  p-1"><i class="fa fa-eye themecol1    icon-head" aria-hidden="true"></i></button>

              <button class="btn btn-sm   p-1" (click)="prescriptionEdit(med,i)"><i class="fa fa-pen-square themecol2    icon-head" aria-hidden="true"></i></button>
              <button class="btn btn-sm  p-1" (click)="onRemoveRow(i)"><i class="fa fa-window-close themecol3      icon-head" aria-hidden="true" ></i></button>
              </div>
            </a>


            </div>





        </div>


  </div> -->
                </div>
                <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                  <div class="col-md-12 pt-2" *ngIf="isTestForm">
                    <div class="position-absolute card-top-buttons">
                      <div class="form-group mt-3 d-flex flex-column flex-md-row prescription-ol">
                        <button class="btn btn-success btn-sm mr-2"
                          (click)="onAddMedicalTest()"><i class="fa fa-plus "></i></button>
                        <!-- <button  class="btn btn-danger btn-sm mr-2"><i  class="fa fa-minus "></i></button> -->
                      </div>

                      <button style="display: none" class="btn  icon-button mt-3" (click)="onAddMedicalTest()">
                        <i aria-hidden="true" class="fa fa-plus-circle themecol1"></i></button>
                    </div>
                    <h3 class="mt-0 mb-1 border-bottom p-2 head bgf9"><i class="fa fa-vial themecol1 icon-head"
                        aria-hidden="true"></i> Add Medical Test </h3>
                    <form [formGroup]="medicaltestForm">
                      <div class="p-0 row">
                        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                          <div class="carousel-inner">
                            <div class="carousel-item active">
                              <div class="row">
                                <div class="col-md-2 form-group">
                                  <label>Type</label><span class="text-danger">*</span>

                                  <select name="cars" class="form-control mb-0" id="cars" [(ngModel)]='ngSelectLabType'
                                    formControlName="testType">
                                    <option selected hidden disabled value="">Select</option>
                                    <option value="X-ray">X-ray</option>
                                    <option value="CT Scan">CT Scan</option>
                                    <option value="MRI Scan">MRI Scan</option>

                                  </select>
                                </div>
                                <div class="col-md-6 form-group">
                                  <label>Test Name</label><span class="text-danger">*</span>

                                  <input type="text" class="form-control mb-0" formControlName="testName"
                                    placeholder="TestName">

                                </div>

                                <div class="col-md-4 form-group">
                                  <label>Instructions</label><span class="text-danger">*</span>
                                  <select name="cars" class="form-control mb-0" id="cars"
                                    formControlName="instructions">
                                    <option value="beforeMeal">Before Meal</option>
                                    <option value="AfterMeal">After Meal</option>

                                  </select>


                                </div>
                              </div>

                            </div>
                            <div class="carousel-item">

                            </div>
                          </div>
                          <a class="carousel-control-prev" href="#carouselExampleControls" role="button"
                            data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                          </a>
                          <a class="carousel-control-next" href="#carouselExampleControls" role="button"
                            data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                          </a>
                        </div>






                      </div>
                    </form>
                    <!-- <div class="row p-2">
    <div class="col-md-10">
      <div class="row">
      <div class=" d-flex flex-column flex-grow-1 col-md-4  blt-2 " *ngFor="let row of medications;let i =index">
        <a href="javascript:void(0)" class="float-left">
        <p class="font-weight-medium p_btm line_height float-left  pt-1">
         <span class="mt-2 text-muted p-1"> {{row.testName}} </span>
          </p>
         <div class=" float-right">
          <button class="btn btn-sm  p-1"><i class="fa fa-eye themecol1    icon-head" aria-hidden="true"></i></button>

          <button class="btn btn-sm   p-1" (click)="medicalTestEdit(row,i)"><i class="fa fa-pen-square themecol2    icon-head" aria-hidden="true"></i></button>
          <button class="btn btn-sm  p-1" (click)="onRemoveRowTest(i)"><i class="fa fa-window-close themecol3      icon-head" aria-hidden="true"></i></button>
          </div>
        </a>


        </div>


          </div>
    </div>
    <div class="pt-2 pb-2 w-100 text-right pull-right" style="display: none">
      <button class="btn btn-danger btn-sm mr-3"  data-dismiss="modal" type="button" >Cancel</button>
        <button class="btn btn-success btn-sm"  type="button" (click)="OnSubmit() "><i class="fas fa-save"></i> Save</button>
      </div>

      </div> -->
                  </div>
                </div>
                <div class="tab-pane fade" id="upload-prescription" role="tabpanel"
                  aria-labelledby="upload-prescription-tab">


                  <a class="text-center col-md-12" style=" display: block; display: inline-block; "
                    href="javascript:void(0)">
                    <button style="display: none" class="  btn btn-danger btn-xs   text-center mt-5 "
                      data-toggle="tooltip" data-placement="top" title="PNG/PDF" (click)="fileInput.click()"><i
                        class="fa fa-file-prescription"></i>
                      Upload Prescription
                      <input #fileInput type="file" (change)="onFileInput($event.target.files)" style="display: none" />
                    </button>
                    <img src="./../../../assets/img/add-prescriptions.jpg" class="mt-2">
                  </a>



                </div>
              </div>
            </div>








          </div>








        </div>
      </div>


<div class="modal modal-right fade" id="preview">
  <div class="modal-dialog" style="min-width:53%;">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title"> <i class="fa fa-calendar-plus"></i> Prescription</h5>
        <button type="button" class="close text-white" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">






        <div id="printPage">
          <div class="block inv-section">
            <div class="top-inv-col">
              <div class="inv-logo"><img alt="" src="./assets/img/logo.png"></div>
              <div class="float-right text-left order-details">
                <!-- <p>Prescripiton ID: <span>73</span></p> -->
                <p>Date: <span>{{patientPersonalDetails?.AppointmentDetails?.appointmentDate | date:'dd-MMM-yyyy'}}</span>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-xs-6">
                <h5>Prescribed by</h5>
                <ul class="inv-receiver">
                  <li class="">Dr {{loginUser?.firstName}} {{loginUser?.lastName}} <br>{{dstreet1}} {{dstreet2}}
                    {{dcity}} <br>{{dstate}} {{dcountry}}<br> {{dzip}} </li>
                </ul>
              </div>
              <div class="col-md-6 col-xs-6 text-right">
                <h5>Prescribed to</h5>
                <ul class="inv-receiver">
                  <li class="">{{patientName}} <br>{{pstreet1}} {{pstreet2}} {{pcity}} <br>{{pstate}}
                    {{pcountry}}<br>{{pzip}} </li>
                </ul>
              </div>

            </div>

          </div>


          <div id="print_prescription">
            <div class="row">

              <div class="col-md-12">

                <h3 class="mt-4 p-2 border-bottom font-size-12 bg-primary-light"><i class="fas fa-prescription"></i>
                  Medical Prescripiton</h3>
                <ol>

                  <li *ngFor="let med of prescriptions ;let i=index"><i>{{med.type}}</i> <b> {{med.medicineName}}
                      {{med.power}} </b>
                    <span> ({{med.instructions}})</span>

                    <span class="float-right" style="border-left: 1px solid #000;  padding-left: 10px;margin-right: 70px;
">{{med.numberOfDays}}days - {{med.refill}} Refill(s)</span>


                    <ul style="padding-left: 10px">
                      <li style="list-style: none">
                        {{med.morning}}+{{med.afternoon}}+{{med.evening}} &emsp; {{med.instructions}}</li>
                      <li style="list-style: none"></li>
                    </ul>
                  </li>
                </ol>

                <h3 class="mt-4 p-2 border-bottom font-size-12 bg-success-light"><i class="fas fa-microscope"></i>
                  Medical Test Prescripiton</h3>
                <ol>
                  <li *ngFor="let row of medications;let i =index"><i>{{row.testType}}</i> <b> {{row.testName}} </b>
                    <span> ({{row.instructions}})</span>
                  </li>
                </ol>


              </div>
              <div class="col-md-12">
                <button class="btn btn-success btn-xs mr-2 float-right" (click)="OnSubmit()"><i class="fas fa-save"></i>
                  Save</button>
              </div>

            </div>

            <p class="prescription-p-title"
              style=" border-top: 1px solid black;width: 150px;float: right;position: absolute;bottom: 0;right: 0;">Seal
              and
              Signature</p>


          </div>
        </div>






      </div>





    </div>
  </div>
</div>
