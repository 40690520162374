<div class="modal-header">
    <h4 class="modal-title">Assign Patient</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body pb-2">
    <div class="bg-primary-light p-0 card">
      <label class="w-100 mb-1 font-size-16 border-bottom p-1 bg-primary">Patient Details</label>
        <div class="row p-2" *ngIf="patientInfo">

            <div class="col-lg-4">
              <label class="form-label">Patient Name </label>
              <p class="form-label"> <b> {{patientInfo?.firstName}} {{patientInfo?.lastName}} </b></p>
            </div>
            <div class="col-lg-4">
              <label class="form-label">Mobile :</label>
              <p class="form-label"><b> {{patientInfo?.cellNumber}} </b></p>
            </div>
            <div class="col-lg-4">
              <label class="form-label">Email  </label>
              <p class="form-label"><b> {{patientInfo?.emailId}} </b></p>
            </div>

        </div>
    </div>




    <div class="btns d-flex justify-content-center mb-3 mt-3 btn-group btn-group-justified">
        <button type="button" class="btn btn-success mr-2" (click)="book()">Book Appointment</button>
        <button type="button" class="btn btn-warning mr-2" (click)="assignIpd()">Assign to IPD</button>
        <button type="button" class="btn btn-danger mr-2" (click)="assignEmergency()">Assign to Emergency</button>
    </div>
</div>
