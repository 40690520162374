<div class="modal-header">
    <h4 class="modal-title">Consent Form</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <p>	I hereby authorize CureMetric to use the telehealth practice platform for telecommunication for evaluating, testing and diagnosing my medical condition.  </p>
        <p>I understand that technical difficulties may occur before or during the telehealth sessions and my appointment cannot be started or ended as intended.</p>
        <p>	I accept that the professionals can contact interactive sessions with video call; however, I am informed that the sessions can be conducted via regular voice communication if the technical requirements such as internet speed cannot be met.</p>
        <p>	I understand that my current insurance may not cover the additional fees of the telehealth practices and I may be responsible for any fee that my insurance company does not cover.</p>
        <p>	I agree that my medical records on telehealth can be kept for further evaluation, analysis and documentation, and in all of these, my information will be kept private.</p>
        <p>	I agree to CureMetric’s terms of my health information being shared with health care professionals/specialists/doctors as deem fit</p>
    
      <h4 class="mb-2">Consultation Procedure</h4>
       <p>During the Telehealth consultation, your medical history, examinations and results can be discussed with other health professionals.</p>
       <p>A physical examination on you may take place.</p>
       <p>During the procedure, video, audio or photo record of you can be taken.</p>
       <p>	In case of any technical problem, a non-medical technician may be in the room during consultation. </p>
        <h4 class="mb-2">Medical History and Records</h4>
        <p>The Telehealth consultations have been protected by the law regarding your medical history and records. The telecommunications between you and your health care professional can be recorded. If any record is taken, it will not be shared without your consent. 
          Confidentiality </p>
        <p>In case of any confidentiality risk, all the reasonable and appropriate efforts have been made under the federal law. 
         Rights </p>
        <p>You can always take your grant back and withdraw your consent back at any time. </p>
          
        <p> All the procedures, potential risks, consequences and benefits have been told to you by your health care professional. And you have given an opportunity to ask your concerns and questions which have been answered.</p>
    
        <!-- <div class="container">
          <div class="row">
              <div class="col-md-4 form-group">
                <label for="exampleInputPassword1">First Name</label><span class="text-danger"></span>
                <input type="text" placeholder="Last Name" class="form-control mb-0" />
            </div>
            <div class="col-md-4 form-group">
              <label for="exampleInputPassword1">Last name</label><span class="text-danger"></span>
            <input type="text" placeholder="First Name" class="form-control mb-0" />
          </div>
          <div class="col-md-4 form-group">
            <label for="exampleInputPassword1">Phone Number</label><span class="text-danger"></span>
          <input type="text" placeholder="Phone Number" class="form-control mb-0" />
        </div>
        <div class="col-md-4 form-group">
          <label for="exampleInputPassword1">Email</label><span class="text-danger"></span>
        <input type="text" placeholder="Email Id" class="form-control mb-0" />
      </div>
      <div class="col-md-4 form-group">
        <label for="exampleInputPassword1">Birth Date</label><span class="text-danger"></span>
      <input type="text" placeholder="Email Id" class="form-control mb-0" />
    </div>
    <div class="col-md-4 form-group">
      <label for="exampleInputPassword1">Social Security Number</label><span class="text-danger"></span>
    <input type="text" placeholder="SSN" class="form-control mb-0" />
    </div>
    <hr>
    <h6>I sign this consent form on the behalf of</h6>
    <mat-radio-group aria-label="Select an option"
    class="d-flex flex-row radio-class ml-2">
    <mat-radio-button value="True" class="radio-button-style">Myself </mat-radio-button>&nbsp;
    <mat-radio-button value="False" class="radio-button-style">
      My family</mat-radio-button>&nbsp;
    <mat-radio-button value="Not Sure" class="radio-button-style">
      Someone as a legal guardian
    
    </mat-radio-button>
    </mat-radio-group>
    <div class="d-flex flex-row justify-content-center">
      <p class="col-md-6">	What is your relationship with this person?</p>
      <input type="text" placeholder="relationship" class="form-control mb-0" />
    </div>
    <div class="row">
      <div class="col-md-4 form-group">
        <label for="exampleInputPassword1">First Name</label><span class="text-danger"></span>
        <input type="text" placeholder="Last Name" class="form-control mb-0" />
    </div>
    <div class="col-md-4 form-group">
      <label for="exampleInputPassword1">Last name</label><span class="text-danger"></span>
    <input type="text" placeholder="First Name" class="form-control mb-0" />
    </div>
    <div class="col-md-4 form-group">
    <label for="exampleInputPassword1">Phone Number</label><span class="text-danger"></span>
    <input type="text" placeholder="Phone Number" class="form-control mb-0" />
    </div>
    
    <div class="col-md-4 form-group">
    <label for="exampleInputPassword1">Email</label><span class="text-danger"></span>
    <input type="text" placeholder="Email Id" class="form-control mb-0" />
    </div>
    <div class="col-md-4 form-group">
    <label for="exampleInputPassword1">Date of Sign</label><span class="text-danger"></span>
    <input type="text" placeholder="dos" class="form-control mb-0" />
    </div>
    <div class="col-md-4 form-group">
      <label for="exampleInputPassword1">Signature</label><span class="text-danger mr-2">(INITALS)</span>
      <input type="text" placeholder="sign" class="form-control mb-0" />
      </div>
    </div>
    
    <div class="row">
      <div class="col-md-12"><h3>Health Care Professional Name</h3></div>
      <div class="col-md-6 form-group">
        <label for="exampleInputPassword1">First Name</label><span class="text-danger"></span>
        <input type="text" placeholder="Last Name" class="form-control mb-0" />
    </div>
    <div class="col-md-6 form-group">
      <label for="exampleInputPassword1">Last name</label><span class="text-danger"></span>
    <input type="text" placeholder="First Name" class="form-control mb-0" />
    </div>
    </div>
          </div>
    
        </div> -->
     
        <p class="font-weight-medium mb-0 mt-0 iagree float-right" >
          <input type="checkbox" #agree  [(ngModel)]="consent" name="" value="" (change)="agreedForm()">
          <label class="m-0 p-2 font-14"> I Agree</label>
        </p>
      </div>
   
    
    
    