import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-after-register',
  templateUrl: './after-register.component.html',
  styleUrls: ['./after-register.component.css']
})
export class AfterRegisterComponent implements OnInit {

  constructor(public router: Router) { }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  ngOnInit(): void {
    if (this.router.url == "/doctor-succesfull-Registration") {
      document.getElementById("success").style.display = "block"
      document.getElementById("fail").style.display = "none"
    } else if (this.router.url == "/doctor-Failure-Registration") {
      document.getElementById("fail").style.display = "block"
      document.getElementById("success").style.display = "none"
    }
  }
}
