import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbAccordion, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicService } from '../clinic-pages/clinic.service';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { PatAssignmentComponent } from '../shared/models/pat-assignment/pat-assignment.component';
import { event } from 'jquery';
import { GlobalStorageService } from '../global-storage.service';

@Component({
  selector: 'app-short-reg-hospital',
  templateUrl: './short-reg-hospital.component.html',
  styleUrls: ['./short-reg-hospital.component.css']
})
export class ShortRegHospitalComponent {
  shortReg:FormGroup

  minDate = new Date(1925, 0, 1);
  maxDate = new Date();
  patientSearched: boolean=false;
   patientList:any=[];
  
  @ViewChild('cellInput') cellInput: ElementRef;

  countryArray = ['IN', 'US'];
  postalLocation = [];
  genderArray = ['Male', 'Female'];
  userData: any;
  constructor( private fb:FormBuilder,private activeModal:NgbActiveModal,
    private toastrService: ToastrService, private clinicServices: ClinicService,private modal : NgbModal,
    private globalStorage:GlobalStorageService) { }

  ngOnInit(): void {
    this.patientList=[];
    this.patientSearched = false;
    var d = new Date();
    this.maxDate = new Date(d);
    this.maxDate = new Date(d.getFullYear() - 8, d.getMonth(), d.getDay());
    this.shortReg = this.fb.group({
      firstName: ['',Validators.required],
      lastName: ['',Validators.required],
      emailId: [''],
      cellNumber: [''],
      dob: ['',Validators.required],
      primaryAge: [''],
      gender: ['',Validators.required],
      country: [''],
      zip: [''],
      postalLocation: [''],
      state: [''],
      city: [''],
      street: [''],

    })
    this.addListeners();
   
  }


  
  saveData() {
    const formValues = this.shortReg.value;
  
    const formattedDOB = formatDate(formValues.dob, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us').concat('Z');
    const hospitalAddressId = this.globalStorage.getItem('hospitalAddressId');
  
    const body = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      emailId: formValues.emailId || null,
      cellNumber: this.getMobileNumbers(this.cellInput),
      active: 'Y',
      gender: formValues.gender,
      // patientId: this.userData?.userId,
      hospitaladdressId: hospitalAddressId,
      address: {
        active: 'Y',
        city: formValues.city,
        country: formValues.country,
        postalLocation: formValues.postalLocation,
        state: formValues.state,
        street1: formValues.street,
        zip: formValues.zip,
      },
      personaldetail: {
        active: 'Y',
        dob: formattedDOB,
        gender: formValues.gender,
      }
    };
  
    this.clinicServices.saveUser(body).subscribe(res => {
      this.bookAppointment(res?.data);
      this.activeModal.close(res?.data);
      this.toastrService.success('Registered Successfully');

    }, error => {
      this.toastrService.error('Failed to register a Patient');
    });
  }
  


  addListeners(){
    this.shortReg.get('cellNumber').valueChanges.pipe(
      debounceTime(300), 
      distinctUntilChanged(), 
      filter(num => num.length === 10) 
    ).subscribe(num => {
      this.checkPhoneNumber(num);
    });
  }
cardsShow: boolean =false; 
checkPhoneNumber(num: string) {
  const id = this.globalStorage.getItem('hospitalAddressId');
  const number = encodeURIComponent('+91' + this.shortReg.value.cellNumber);

  this.clinicServices.searchHospitalUser(id, number).subscribe((res: { [x: string]: { primaryUserDTO: any[]; }; }) => {
    this.patientList =  res?.data?.primaryUserDTO || [];
   console.log(this.patientList);

    if (this.patientList.length === 0) {
      this.patientSearched = true;
      this.patchPatientData(null);
      return;
    }


    if (this.patientList.length > 1) {
      this.cardsShow = true;
      this.patientSearched = false;
    } else if (this.patientList.length > 0) {
      this.patientSearched = true;
      const patient = this.patientList[0];
      if (patient?.isUserExit === 'Y') {
        this.bookAppointment(patient);
        this.activeModal.close();
      } else {
        this.patientSearched = true;
        this.patchPatientData(patient);
      }
    }

  }, error => {
    this.toastrService.error('Error searching for patient');
  });
}

  backToPatient(){
    this.cardsShow = true;
    this.patientSearched=false;
  }
  
  patchPatientData(patient: any) {
    this.cardsShow = false;
    this.patientSearched=true;
    this.shortReg.patchValue({
      firstName: patient?.firstName,
      lastName: patient?.lastName,
      dob: patient?.dob,
      gender: patient?.gender,
      emailId: this.isValidEmail2(patient?.emailId),
      userId: patient?.userId,
      primaryAge: patient?.age
    });
    
  }
  


  updateDOB(value: string,primary): void {
    const age = Number(value);
    if (!isNaN(age)) {
      const currentYear = new Date().getFullYear();
      const birthYear = currentYear - age;
      const dob = new Date(birthYear, 0, 1, 12); // Noon to avoid any DST changes
  
      if(primary){
        this.shortReg.patchValue({
          dob: dob.toISOString().substring(0, 10) // Format YYYY-MM-DD
        });
      }else{
        this.shortReg.patchValue({
          spouseDob: dob.toISOString().substring(0, 10) // Format YYYY-MM-DD
        });
      }
    }
  }

  primaryAge=0;
  calculateAge(event: any,primary) {
    const selectedDate = event.value;
    const today = new Date();
    const birthDate = new Date(selectedDate);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  
    if(primary){
      this.primaryAge=age
    }
  }
 
  getPostalCode() {
    const countryValue = this.shortReg.value.country;
    const postalCode = this.shortReg.value.zip;
    if (postalCode.toString().length > 0) {
      if (postalCode.toString()) {
        this.clinicServices.getPincodeDetails(postalCode, countryValue).subscribe(res => {
          if (!res?.data) {
            // Example values for city and state

            // Patching the values
            this.postalLocation = []
            this.shortReg.patchValue({
              city: '',
              state: '',
              postalLocation: ''
            });

            this.toastrService.error("Incorrect pincode")
          }
          this.postalLocation = res['data'];
          // this.latitude = res['result'][0]['latitude'];
          // this.longitude = res['result'][0]['longitude'];
          // Example values for city and state
          const newCity = res['data'][0]['district'];
          const newState = res['data'][0]['state'];

          // Patching the values
          this.shortReg.patchValue({
            city: newCity,
            state: newState
          });

          this.shortReg.patchValue({
            postalLocation: this.postalLocation[0]['postalLocation']
          })



        })
      } else {
        this.toastrService.error("Pincode must be 6 Digits")

      }
    }
  }
  checkEmail(){
    const emailId = this.shortReg.value.emailId;
    if (emailId.length > 0 && this.isValidEmail(emailId)) {
      this.clinicServices.userExist(emailId).subscribe(res => {
        if (res?.['data']) {
          this.toastrService.error('Email ID Already Exists.');
          this.shortReg.patchValue(
            {
              emailId: ''
            }
          )
        }
      })
    } else if (emailId.length > 0) {
      this.toastrService.error('Enter a Valid Email');
    }
  }
  isValidEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  isValidEmail2(email) {
    var re = /\S+@\S+\.\S+/;
    if(re.test(email)){
      return email
    }else{
      return null;
    }
  }
  

  getMobileNumbers(inputElement) {
    if (inputElement && inputElement.nativeElement) {
      // Make sure intlTelInput is initialized on the element
      const intlTelInputInstance = window.intlTelInputGlobals.getInstance(inputElement.nativeElement);
      if (intlTelInputInstance) {
        const number = intlTelInputInstance.getNumber();
        if (intlTelInputInstance) {
          // const countryData = intlTelInputInstance.getSelectedCountryData();
          return number;

        } else {
          console.error('Invalid phone number : ', number);
          return '';
        }
      } else {
        console.error('intlTelInput not initialized');
        return ''
      }
    } else {
      console.error('Input element is not provided or does not exist');
      return '';
    }
  }
  closeModal(){
    this.activeModal.close();
  }

  bookAppointment(patient){
    const modalRef = this.modal.open(PatAssignmentComponent,{
     centered: false,
      windowClass : 'modal-right hw-40',
      keyboard:false,
     
    })
    modalRef.componentInstance.Id=patient?.userId || patient?.patientReferenceId
    modalRef.componentInstance.Info=patient

  }

 
}



  

