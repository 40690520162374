import { Component, ElementRef, ViewChild, AfterViewInit, Renderer2, EventEmitter, Output, ViewEncapsulation, OnDestroy } from '@angular/core';
import { createLocalVideoTrack, LocalVideoTrack } from 'twilio-video';
import { StorageService } from '../../services/storage.service';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
@Component({
    selector: 'app-doctor-cameras',
    templateUrl: './doctor-cameras.component.html',
    styleUrls: ['./doctor-cameras.component.css']
})
export class DoctorCamerasComponent implements AfterViewInit,OnDestroy {

    @ViewChild('preview', { static: false }) previewElement: ElementRef;
    @Output() valueChange = new EventEmitter();

    isInitializing: boolean = true;
    videoTrack: LocalVideoTrack = null;

    constructor(
        private readonly storageService: StorageService,
        private readonly renderer: Renderer2, private doctorPatient: DoctorPatientService) {

        this.doctorPatient.joinCallObservable.subscribe(scheduler => {
            this.LoadCamara();
        });

        this.doctorPatient.closeVideoCallingObservable.subscribe(scheduler => {
            this.closeCemera();
        })
    }


    LoadCamara() {
        if (this.previewElement && this.previewElement.nativeElement) {
            const selectedVideoInput = this.storageService.get('videoInputId');
            this.initializeDevice(selectedVideoInput);
        }
    }

    async ngAfterViewInit() {
        // if (this.previewElement && this.previewElement.nativeElement) {
        //     const selectedVideoInput = this.storageService.get('videoInputId');
        //     await this.initializeDevice(selectedVideoInput);
        // }

    }

    async initializePreview(deviceId: string) {
        await this.initializeDevice(deviceId);
    }

    finalizePreview() {
        try {
            if (this.videoTrack) {
                this.videoTrack.stop();
                this.videoTrack.detach().forEach(element => element.remove());
                this.videoTrack.mediaStreamTrack.stop(); // Ensure the media stream track is stopped
                this.videoTrack = null;
            }
            this.videoTrack = null;
        } catch (e) {
            console.error(e);
        }
    }

    private async initializeDevice(deviceId?: string) {
        try {
            this.isInitializing = true;
            console.log('doctor camera is initializing')


            this.finalizePreview();

            this.videoTrack = deviceId
                ? await createLocalVideoTrack({ deviceId })
                : await createLocalVideoTrack();

            const videoElement = this.videoTrack.attach();
            this.renderer.setStyle(videoElement, 'height', '100%');
            this.renderer.setStyle(videoElement, 'width', '100%');
            this.renderer.appendChild(this.previewElement.nativeElement, videoElement);
        } finally {
            this.isInitializing = false;
        }
    }

    closeCemera() {
        if (this.videoTrack != null) {
            this.valueChange.emit(this.videoTrack);
            this.videoTrack.stop();
            this.videoTrack.detach().forEach(element => element.remove());
            this.videoTrack.mediaStreamTrack.stop(); // Ensure the media stream track is stopped
            this.videoTrack = null;
            // this.videoTrack.localParticipant.tracks.array.forEach(element => { element.stop()
            // });
        }
    }

    ngOnDestroy() {
        this.closeCemera()
    }

}
