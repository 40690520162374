import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';
@Component({
  selector: 'app-add-item-order',
  templateUrl: './add-item-order.component.html',
  styleUrls: ['./add-item-order.component.css']
})
export class AddItemOrderComponent implements OnInit {

  @Input() addressId: any;
  @Input() supplierInfoObj: any;
  @Input() service: any;
  gstOptions: string[] = ["GST", "I-GST"];
  subCategoryForm: FormGroup;
  submitted: boolean = false;
  categorylist: any[] = [];
  subcategory: any[] = [];
  categorySelected: any;
  subCategorySelected: any;
  category: any;
  showSet: any;
  selectedOption: any;
  showDiscount: boolean = false;
  showOffer: boolean = false;
  percentages = [0, 5, 12, 18, 28];
  options: string[] = ['Discount on price (%)', 'Addtional Items Offer'];
  minDate;selectOptions = [
    { value: 'Y', label: 'Yes' },
    { value: 'N', label: 'No' }
  ];

  showGstFields: boolean = false;
  isSubCatEdit: any;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private inventoryService: InventoryService) {
      this.minDate = new Date();
     }

  ngOnInit(): void {
    this.intiliazeForm();
    this.gstCheckBox({ value: this.selectedOption });
    this.getcategory();
     this.fetchManufacturers();
  }
  manufacturersData;
  fetchManufacturers() {
    let body = {
      "active": "Y",
      "addressId": this.addressId
    }
    this.inventoryService.manufacturerList(body).subscribe(res => {
      this.manufacturersData = res?.['data'] || [];
      if (this.isSubCatEdit) {
        this.subCategoryForm.patchValue({
          manufacturer: this.subcategory['manufacturer']
        })
      }
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  intiliazeForm() {
    this.subCategoryForm = this.formBuilder.group({
      category: ['', [Validators.required]],
      subCategory: ['', [Validators.required]],
      supplier: [this.supplierInfoObj?.name],
      price: ['', [Validators.required]],
      deal: [''],
      dealFromDate: [''],
      dealToDate: [''],
      discount: [''],
      buyQuantity: [''],
      freeQuantity: [''],
      description:[''],
      manufacturer:[''],
      disposal:[''],
      usageUnits:[''],
      rack:[''],
      hsn:[''],
      potency:[''],
      prescription:[''],
      injectable:[''],
      alternate:[''],
      freezable:[''],
      cgst:[''],
      sgst:[''],
      igst:[''],
      tax:['']


    })
  }
 get Subf() { return this.subCategoryForm?.controls; }

  get subCatFormControl() {
    return this.subCategoryForm.controls;
  }

  closeModal() {
    this.activeModal.close(true);
  }

  selectedCategory(categorySelected) {
    this.category=categorySelected
    this.catIdfromObj=categorySelected.catagoryId
    // console.log(categorySelected)
   }

  selectedsubCategory(subCategorySelected) { }

  checkBoxCheck(a, e) {
    a.value.toUpperCase()
    if (a.value == 'Discount on price (%)') {
      this.selectedOption = a.value
      this.showDiscount = true;
      this.showOffer = false;
      this.subCategoryForm.get('buyQuantity').setValue(null)
      this.subCategoryForm.get('freeQuantity').setValue(null)
      // this.subCategoryForm.controls['discount'].setValidators([Validators.required, Validators.minLength(1)])
      // this.subCategoryForm.controls['buyQuantity'].clearValidators()
      // this.subCategoryForm.controls['freeQuantity'].clearValidators()
    }
    if (a.value == 'Addtional Items Offer') {
      this.selectedOption = a.value
      this.showDiscount = false;
      this.showOffer = true;
      this.subCategoryForm.get('discount').setValue(null)
      // this.subCategoryForm.controls['freeQuantity'].setValidators([Validators.required, Validators.minLength(1)])
      // this.subCategoryForm.controls['buyQuantity'].setValidators([Validators.required, Validators.minLength(1)])
      // this.subCategoryForm.controls['discount'].clearValidators()
    }
  }
  gstCheckBox(a) {
    if (a.value == 'GST') {
      this.selectedOption = a.value;
      this.showGstFields = true;
      this.showIGstFields = false;
      this.subCategoryForm.controls['cgst'].setValidators([Validators.required])
      this.subCategoryForm.controls['sgst'].setValidators([Validators.required])
      this.subCategoryForm.controls['igst'].clearValidators()
      this.subCategoryForm.get('igst').setValue(null)
    } if (a.value == 'I-GST') {
      this.selectedOption = a.value;
      this.showGstFields = false;
      this.showIGstFields = true;
      this.subCategoryForm.controls['igst'].setValidators([Validators.required])
      this.subCategoryForm.controls['cgst'].clearValidators()
      this.subCategoryForm.controls['sgst'].clearValidators()
      this.subCategoryForm.get('cgst').setValue(null)
      this.subCategoryForm.get('sgst').setValue(null)
    }
  }
  
  showIGstFields: boolean = false;
  getcategory() {
    var body = {
      "flag": "Y",
      "addressId": this.addressId
    }
    this.inventoryService.category(body).subscribe(res => {
      this.categorylist = res?.['data'];
    },
      error => {
        this.toaster.error(error?.[error]?.['message']);
      }
    )
  }

  catIdfromObj;
  getcategyById(catId) {
    this.catIdfromObj = catId;
    var body = {
      "catagoryId": this.catIdfromObj,
      "flag": "Y",
      "addressId": this.addressId
    }
    this.inventoryService.subcategory(body).subscribe(res => {
      this.subcategory = res?.['data'];
    },
      error => {
        this.toaster.error(error?.[error]?.['message']);
      }
    )
  }

  itemTypidFromObj;
  getsubcategyById(subCat) {
    this.itemTypidFromObj = subCat?.itemTypeId
  }

  saveItems() {
    var body = {
      "catagoryId": this.catIdfromObj,
      "cgst": null,
      "deal": this.subCategoryForm?.value?.deal,
      "dealFromDate": this.subCategoryForm?.value?.dealFromDate,
      "dealToDate": this.subCategoryForm?.value?.dealToDate,
      "igst": null,
      "name": this.subCategoryForm?.value.subCategory,
      "sgst": null,
      "supplierId": this.supplierInfoObj?.supplierId,
      "unitPrice": this.subCategoryForm?.value?.price,
      "manufacturer":this.subCategoryForm?.value?.manufacturer,
      "potency":this.subCategoryForm?.value?.potency,
      "buyQuantity":this.subCategoryForm?.value?.buyQuantity,
      "rack":this.subCategoryForm?.value?.rack,
      "hsn":this.subCategoryForm?.value.hsn,
      "prescription":this.subCategoryForm?.value.prescription,
      "injectable":this.subCategoryForm?.value.injectable,
      "alternate":this.subCategoryForm?.value.alternate,
      "freezable":this.subCategoryForm?.value.freezable,
      "subCategory":this.subCategoryForm?.value.subcategory,
      "price":this.subCategoryForm?.value.price,
      "discount":this.subCategoryForm?.value.discount,
      "usageUnits":this.subCategoryForm?.value.usageUnits,
      "disposal":this.subCategoryForm?.value.disposal,
      "description":this.subCategoryForm?.value.description,
      "freeQuantity":this.subCategoryForm?.value.freeQuantity,
      "tax":this.subCategoryForm?.value.tax

    }

    this.inventoryService.assignSupplierForItem(body).subscribe(res => {
      this.toaster.success("Item Added Succesfully.");
      this.subCategoryForm.reset();
      this.activeModal.close(res?.data);
      
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }
}
