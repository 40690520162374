import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, Output, EventEmitter, Input, DoCheck, ViewEncapsulation } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { ChangePasswordComponent } from 'src/app/patient-pages/change-password/change-password.component';
import { MatDialog } from '@angular/material';
import { formatDate } from '@angular/common';
import { PatientService } from '../../services/patient.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { interval, Subscription } from 'rxjs';
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
import { gpsService } from '../../services/gps.service';
import { ApicallsService } from 'src/app/apicalls.service';
import { ObservableService } from '../../services/observable.service';
import { resolve } from 'path';
import { VitalsService } from '../add-vitals/vitals.service';
import { CookieService } from 'ngx-cookie-service';
import { takeUntil } from 'rxjs/operators';
import { CartDetailsComponent } from '../cart-details/cart-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;


@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']

})
export class NavBarComponent implements OnInit {
  @Input() item: any;
  @Input() notificationsData: any;
  @Input() associationUsersList: any
  @Input() personalDetails: any
  subscription: Subscription;
  intervalId: number;
  isAssociation: boolean = false;
  userName
  userIdBefore
  minutes
  notifyToView
  associationUsers
  associatedUserData
  firstTimeLogin = false
  rhours
  rminutes
  lastLogin
  imageSource
  message
  currentAppointments;
  navigationExtras: NavigationExtras
  setPersonalDetails
  today
  currenttime
  msg
  userId
  keyArray
  fromTime
  sampleArray
  reminders
  notificationBell = true
  notificationLength
  ViewedNotifications
  nonViewData
  str = []
  firstName
  lastName
  middleName
  p2 = 1;source
  associatedUserId; addedByYou; addedBySomeone
  constructor(private globalStorage: GlobalStorageService, private patientServices: AuthService,
    private cookieService: CookieService,
    private toaster: ToastrService,
    private sanitizer: DomSanitizer, private scripts: ScriptsService,
    private reports: PatientScheduleControlService,private modalService:NgbModal,
    private router: Router, private gps: gpsService, private observable: ObservableService, private patientService: PatientService, private apiservice: ApicallsService) {
    this.reports.notifyObservable$.subscribe(res => {
      if (res.refresh) {
        // get your grid data again. Grid will refresh automatically
        this.getAssociatedUsers()
      }
      this.observable.update_AssociateUsers.subscribe(value => {
        if (value === 'someValue') {
          this.getAssociatedUsers()
        }
      });
    })


    this.patientServices.loginUsertObservable.subscribe(res => {

      if (res != null) {
        this.lastLogin = res['lastLogin']
        this.setPersonalDetails = res['personalData']
        if (this.setPersonalDetails['profilePic'] != null) {
          this.imageSource = "data:image/jpeg;base64," + this.setPersonalDetails['profilePic']
          this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageSource);
        } else {
          this.imageSource = "/assets/img/avatar.png";
        }
      } else {
        this.imageSource = "/assets/img/avatar.png";
      }
    }, eror => {
      this.toaster.error("Error getting user data")
    });


  }

  ngOnInit(): void {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });

      $('[data-toggle="tooltip"]').on('click', function () {
        $(this).tooltip('hide')
      });
    });

    $("button, a, body").on("click", function (e) {
      $('.tooltip').hide();
      $('[data-toggle="tooltip"]').tooltip("hide");
    });


    this.reports.saveDoctorData(null);
    var data = this.globalStorage.getItem('userData')
    this.scripts.loadScripts()
    var obj = JSON.parse(data)
    this.userId = obj['userId']
    this.getAssociatedUsers()
    this.patientService.myMethod$.subscribe((data) => {
      this.associatedUserData = data; // And he have data here too!
    })
    this.associatedUserData = this.reports.sharingData
    // this.associatedUserData = this.reports.sharingData
    if (this.associatedUserData) {
      this.associatedUserId = this.associatedUserData.relationUserId
      this.globalStorage.setItem('forUserId', this.associatedUserData.relationUserId)
    }
    else {
      this.associatedUserId = obj['userId']
    }
    //  this.reports.isPatientProfileCompletedOrNot(obj['userId'],this.associatedUserId).subscribe(res=>{
    //    if(res['data']['isPersonalDetailcompleted']=='N'){
    //     this.router.navigate(['/pat-personal-details'])
    //    }
    //    if(res['data']['isPersonalDetailcompleted']=='Y'){
    //    if(res['data']['isAddrssComplted']=='N'){
    //      this.router.navigate(['/pat-address-details'])
    //    }}
    //  })


    //  this.getAssociatedUsers()
    if(this.router?.url!='/p-dashboard'){
    this.loadNotifications()
    }

    this.checkHomeOrNot();
    // this.source = interval(1000);
    // this.subscription = this.source.subscribe(val => this.checkHomeOrNot());
    //setTimeout(()=> this.subscription.unsubscribe(), 2000);
    function currentTime() {
      this.today = new Date();
      this.currenttime = this.today.getHours() + ':' + this.today.getMinutes() + ':' + this.today.getSeconds()
    }

    //setInterval(currentTime,1000)
    this.loaduserName()
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
      // var request = {
      //   "pageNum": 1,
      //   "patientId": obj['userId'],
      //   "viewAppointmentEnum": [
      //     "CURRENT", "UPCOMING"
      //   ]
      // }
      // this.reports.getTodayAppointments(request, 'appointment/view/patientself', obj['userId']).subscribe(res => {
      //   this.currentAppointments = res['body']['data']['CURRENT']['data']
      //   this.keyArray = this.currentAppointments.map(function (item) { return item["fromTime"]; });
      //   this.sampleArray = this.keyArray
      //   const source = interval(1000);
      //   const text = this.sampleArray;
      //   this.subscription = source.subscribe(val => this.updater(text));
      // })
      ;
  }
  checkHomeOrNot(){
    if (window.location.pathname == '/p-dashboard' || window.location.pathname == '/cureMetric/p-dashboard') {
      this.item = undefined
      this.notificationBell = false
    }
    else {
      this.notificationBell = true
      this.item = this.item
     // this.cancelInterval()
    }
  }
  cancelInterval(){
    this.subscription.unsubscribe()
    this.loadNotifications()
  }
  updater(text) {
    this.today = new Date();
    var min = this.today.getMinutes()
    var sec = this.today.getSeconds()
    if (min < 10) {
      min = '0' + this.today.getMinutes()
    }
    if (sec < 10) {
      sec = '0' + this.today.getSeconds()
    }

    this.currenttime = this.today.getHours() + ':' + min + ':' + sec
    this.currenttime = this.currenttime.slice(0, -3)
    for (var i = 0; i <= text.length; i++) {
      this.str = text[i]
      if (this.currenttime <= this.str) {
        this.minutes = this.parseTime(this.str) - this.parseTime(this.currenttime);
        var num = this.minutes;
        var hours = (num / 60);
        this.rhours = Math.floor(hours);
        var minutes = (hours - this.rhours) * 60;
        this.rminutes = Math.round(minutes);
        // return num + " minutes = " + rhours + ":" + rminutes + ;
        break
      }
    }
  }


  parseTime(s) {
    var c = s?.split(':');
    return parseInt(c[0]) * 60 + parseInt(c[1]);
  }

  closeModal() {
    //$('#notificationToView').modal('hide')
    $('#notificationViewerrr').modal('hide')
    $("#notificationViewerrr").val(null).trigger("change");
    $("#notificationToView").val(null).trigger("change");
    // window.top.location = window.top.location
    // $('#viewreminder').modal('hide')
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    this.loadNotifications()

  }
  loadNotifications() {
    this.associatedUserData = this.reports.sharingData
    var id
    if (this.associatedUserData) {
      id = this.associatedUserData.relationUserId
    }
    else {
      var data = this.globalStorage.getItem('userData')
      var obj = JSON.parse(data)
      id = obj['userId']
    }
    this.reports.getGlobal('notificationalerts/get/all?maxResult=6&pageNumber=' + this.p2 + '&userId=' + id, id).subscribe(res => {
      this.reminders = res['data']
      this.notificationLength = this.reminders?.filter((obj) => obj.viewed == 'N').length;
      this.nonViewData = this.reminders?.filter((obj) => obj.viewed == 'N')
      this.ViewedNotifications = this.reminders?.filter((obj) => obj.viewed == 'Y')
    })
  }
  updateNotify(data) {
    $('#notificationViewerrr').modal('show')
    var req = {
      "active": data.active,
      "notificationalertsId": data.notificationalertsId,
      "objectkey": data.objectkey,
      "objecttype": data.objecttype,
      "patientId": data.patientId,
      "reason": data.reason,
      "viewed": "Y"
    }
    this.reports.globalPostBody(data.notificationalertsId, data.patientId, req).subscribe(res => {
      this.notificationLength = this.notificationLength - 1

      this.loadNotifications()
    })

  }
  loginUser;
  patientName;

  loaduserName() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    this.associatedUserData = this.reports.sharingData
    if (this.associatedUserData) {
      this.firstName = this.associatedUserData['firstName']
      this.lastName = this.associatedUserData['lastName']
      this.loginUser = obj['firstName'] + obj['lastName']
      this.patientName = this.associatedUserData['firstName'] + " " + this.associatedUserData['lastName']
    }
    else {
      this.patientName = this.globalStorage.getItem('firstName') + " " +  this.globalStorage.getItem('lastName')
      var req = {
        "forUserId": obj['userId'],
        "userId": obj['userId']
      }
      // this.reports.getUserPersonalDetails('personaldetails/get/', obj['userId'], req).subscribe(res => {
      //   this.patientName = this.personalDetails?.['patientName']
      // })
      this.userName = obj['userName']
      // this.firstName = obj['firstName']
      // this.lastName = obj['lastName']
      // this.middleName = obj['middleName']
      // if (this.personalDetails == undefined || this.personalDetails == null || this.personalDetails?.length == 0) {
      //   var data = this.globalStorage.getItem('userData')
      //   var obj = JSON.parse(data)
      //   this.patientName = obj?.firstName + " " + obj?.lastName
      // }
    }
    var dateDate = new Date(obj['lastLogin'])
    const date2 = formatDate(dateDate, "medium", 'en-us');
    this.lastLogin = date2
    if (this.lastLogin == "Jan 1, 1970, 5:30:00 AM") {
      this.firstTimeLogin = true
    }
    else {
      this.firstTimeLogin = false
    }
  }

  signOut() {
    var data = this.globalStorage.getItem('userData');
    var obj = JSON.parse(data);

    // Call the logout API
    this.patientServices.logOut(obj['userId']).subscribe(
      (res) => {
        this.cookieService.deleteAll();
        // Handle successful logout response
        // this.toaster.success(res['message'])
        //this.globalStorage.clear();
        sessionStorage.clear();

        // Clear local storage

        // Reset other state variables or perform additional cleanup
        this.apiservice.isUserSessionExits = false;
        this.apiservice.isforUserIdExits = false;
        this.apiservice.ismoduleNameExits = false;
        this.reports.saveData(null);

        // Redirect to the login page
        this.router.navigate(['/patientLogin']);
      }




      ,
      (error) => {
        // Handle error from the logout API
        this.toaster.error(error['error']);

        // Redirect to the login page even in case of an error
        this.router.navigate(['/patientLogin']);
      }
    );


  }

  // openDialog() {
  //   const dialogRef = this.dialog.open(ChangePasswordComponent,{
  //     data:{
  //       message: 'Are you sure want to delete?',
  //       buttonText: {
  //         ok: 'Save',
  //         cancel: 'No'
  //       }
  //     }p
  //   });

  // }
  openDialog() {
    // const dialogRef =this.dialog.open(ChangePasswordComponent)

  }
  backToMainUser() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    this.globalStorage.setItem('forUserId', this.globalStorage.getItem('userId'));
    var body={
      "userId": this.globalStorage.getItem('forUserId'),
      "forUserId": this.globalStorage.getItem('forUserId')
    }
    this.reports.switchTo(body).subscribe(res=>{
      this.switchedUser=res['data']
      this.globalStorage.setItem('userData', JSON.stringify(res.data));
      var datas = this.globalStorage.getItem('userData')
      var obj = JSON.parse(datas)
      this.globalStorage.setItem('firstName',obj?.firstName)
      this.globalStorage.setItem('lastName',obj?.lastName)
      this.globalStorage.setItem('isPersonalDetailcompleted',obj?.isPersonalDetailcompleted)
      this.globalStorage.setItem('isAddrsscompleted',obj?.['isAddrsscompleted'])
      this.globalStorage.setItem('height',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='height'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('bloodGroup',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='bloodgroup'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('pulse',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='pulse'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('glucolseLevelPreLunch',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='fasting'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('glucolseLevelPostLunch',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='random'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('bp',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='bloodPressure'))?.[0]?.['vitalValue'])
      this.loaduserName()
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      // this.router.navigate([currentUrl]);
      this.router.navigate(['/p-dashboard'])
      this.reports.saveData(null);

      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
      this.loaduserName()
      this.loadNotifications()
      this.getAssociatedUsers()
    },error=>{
      this.toaster.error(error?.['error']?.['message'])
    })

  }

  // getAssociatedUsers(){
  //   var obj =this.globalStorage.getItem('userData')
  //    obj = JSON.parse(obj)
  //    this.userId = obj['userId']
  //    this.reports.getPatientAsoociatedUsers(this.userId,obj['userId']).subscribe(res=>{
  //      this.associationUsers=res['body']['data']
  //    })
  //  }
  goToPatSettings() {
    this.router.navigate(['/pat-settings'], { state: { data: this.item } })
  }
  //  switchTo(){
  //   // this.patientServices.myMethod(data);
  //   // this.navigationExtras = data
  //   // this.router.navigate(['/p-associated-dashboard'], {
  //   //   state: { example: this.navigationExtras }
  //   // })
  //  }

  file
  onSend(data) {
    if (!data.description) {
      this.toaster.warning('Please enter description')
      return
    }
    var formData = new FormData();
    formData.append('screenshot', data.screenshot);
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + ' ' + time;
    var request = {
      "clientId": 12,
      "userId": this.associatedUserId,
      "feedBackType": "TEST",
      "status": "NEW",
      "description": data.description,
      "userAgent": "test",
      "os": "Windows",
      "browser": "Chrome",
      "os_version": "windows-10",
      "browser_version": "85.0.4183.102",
      "device": "Unknown",
      "latitude": this.gps.latitude,
      "longitudes": this.gps.longitude,
      "feedbackTime": formatDate(dateTime, "yyyy-MM-dd", 'en-us')
    }
    formData.append('feedbackLoop', JSON.stringify(request));
    this.reports.sendFeedback(formData).subscribe(res => {
      this.toaster.success('Thanks for your submission we will get back to you soon.')
    }, error => {
      this.toaster.error(error['error']['message'])
    })
  }

  getAssociatedUsers() {
    var obj = this.globalStorage.getItem('userData')
    obj = JSON.parse(obj)
    this.userId = obj['userId']
    var body = {
      "maxresult": 100,
      "pageNumber": 1,
      "userId": this.userId
    }
    this.reports.getPatientAsoociatedUsers(obj['userId'], body).subscribe(res => {
      this.associationUsers = res['body']['data']
      this.addedByYou = res['body']['data']?.['associatedUsers']
      this.addedByYou = this.addedByYou?.filter(obj => obj.approved == 'Y')
      this.addedBySomeone = res['body']['data']?.['reverseAssociatedUsers']
    })
  }
  switchedUser
  switchTo(data) {
    this.userIdBefore = this.globalStorage.getItem('forUserId')
    this.reports.saveData(data);
    this.patientService.myMethod(data);
    this.navigationExtras = data
    this.globalStorage.setItem('forUserId', data.relationUserId)
    var body={
      "userId": this.globalStorage.getItem('forUserId'),
      "forUserId": this.globalStorage.getItem('forUserId')
    }
    this.reports.switchTo(body).subscribe(res=>{
      this.switchedUser=res['data']
      this.globalStorage.setItem('userData', JSON.stringify(res.data));
      var datas = this.globalStorage.getItem('userData')
      var obj = JSON.parse(datas)
      this.globalStorage.setItem('firstName',obj?.firstName)
      this.globalStorage.setItem('lastName',obj?.lastName)
      this.globalStorage.setItem('isPersonalDetailcompleted',obj?.isPersonalDetailcompleted)
      this.globalStorage.setItem('isAddrsscompleted',obj?.['isAddrsscompleted'])
      this.globalStorage.setItem('height',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='height'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('bloodGroup',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='bloodgroup'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('pulse',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='pulse'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('glucolseLevelPreLunch',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='fasting'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('glucolseLevelPostLunch',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='random'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('bp',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='bloodPressure'))?.[0]?.['vitalValue'])
      if(this.globalStorage.getItem('isPersonalDetailcompleted')=='N' || this.globalStorage.getItem('isPersonalDetailcompleted')!='Y'){
        this.router.navigate(['/pat-personal-details'])
      }
      if (this.globalStorage.getItem('isPersonalDetailcompleted') == 'Y') {
            if (this.globalStorage.getItem('isAddrsscompleted') == 'N' || this.globalStorage.getItem('isAddrsscompleted') != 'Y' ) {
              this.router.navigate(['/pat-address-details'])
            }
          }
          if (this.globalStorage.getItem('isPersonalDetailcompleted') == 'Y' && this.globalStorage.getItem('isAddrsscompleted') == 'Y') {
             let currentUrl = this.router.url;
             this.router.routeReuseStrategy.shouldReuseRoute = () => false;
             this.router.onSameUrlNavigation = 'reload';
             // this.router.navigate([currentUrl]);
             this.router.navigate(['/p-dashboard'], {
              state: { example: this.navigationExtras }
            })
            this.observable.callMethodOfSecondComponent();
            this.isAssociation = true;
          }
    },error=>{
      this.toaster.error(error?.['error']?.['message'])
    })


  }
  checkProfile(){
    if(this.globalStorage.getItem('isProfileCompleted')=='N'){
      this.router.navigate(['/pat-settings'])
    }
    else{
      if(this.globalStorage.getItem('isPersonalDetailcompleted')=='N' || this.globalStorage.getItem('isPersonalDetailcompleted')!='Y'){
        this.router.navigate(['/pat-personal-details'])
      }
      if (this.globalStorage.getItem('isPersonalDetailcompleted') == 'Y') {
        if (this.globalStorage.getItem('isAddrsscompleted') == 'N' || this.globalStorage.getItem('isAddrsscompleted') != 'Y' ) {
        this.router.navigate(['/pat-address-details'])
        }
       }
       if(this.router.url=='/p-dashboard'){
        this.notificationBell = false
       }
    }
  }
  cartDetails
  getCart(){
    var userId
    if (this.associatedUserData) {
      userId = this.associatedUserData.relationUserId
    }
    else{
      var data = this.globalStorage.getItem('userData')
      var obj = JSON.parse(data)
      userId = obj['userId']
    }
    const modalRef = this.modalService.open(CartDetailsComponent, {
      centered: false,
      windowClass: 'modal-right hw-40',
      backdrop: 'static',
        keyboard: false,
    });
    modalRef.componentInstance.userId = userId;
    modalRef.result.then(
      (data) => {
        if (data) {
          console.log('value',data)
          var pharmacyData={
            "pharmacyaddressId":data?.pharmacyAddressId,
            "name":data?.pharmacyname
          }
          const navigationExtras: NavigationExtras = {
            state: {
              pharmacy: pharmacyData
            }
          };
          if(this.router.url=='/pharma-cart'){
            let currentUrl = this.router.url;
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([currentUrl]);
            this.router.navigate(['/pharma-cart'], navigationExtras)
          }
          else{
            this.router.navigate(['/pharma-cart'], navigationExtras)
          }
        }
      },
      () => { }  // Handle dismiss callback
    );
  }
}
