<div *ngIf="!isPatientList">
  <ol class="breadcrumb">
    <li><a href="javascript:void(0)" routerLink="/dashboard"><i class="fa fa-home" aria-hidden="true">
        </i></a></li>
    <li> <a href="javascript:void(0)" class="cur-none">All Appointments</a></li>
  </ol>
</div>

<div class="row">


  <div class="col-md-12 ">
    <app-all-appointments></app-all-appointments>
  </div>




</div>

<div class="modal fade modal-right" id="mypreviousappointment" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="myappointment" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:35%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Previous Appointment Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">




        <div class="schedule-tab">




          <div class="tab-content">

            <div class="tab-pane active" id="tab_start">
              <div class=" ">
                <div class="row backgound_imgsidebar mb-3 pt-2 pb-2 pr-2">
                  <div class="col-md-9 col-sm-12">

                    <div class="d-flex flex-row  ">
                      <a class="" href="javascript:void(0)"><img src="./assets/img/profile.png"></a>
                      <div class="pl-1  d-flex flex-column flex-grow-1  ">
                        <a href="javascript:void(0)">
                          <p class="font-weight-medium mb-0 mt-2">Dr.{{referenceEntityName}}<i
                              class="fa fa-check-circle fa-sm faa-shake text_color_green animated"
                              aria-hidden="true"></i></p>
                          <p class="text-muted mb-1 text-small mr-2">Cardiologist</p>
                          <span class="fa fa-star checked fs-8" aria-hidden="true"></span><span
                            class="fa fa-star checked fs-8" aria-hidden="true"></span><span
                            class="fa fa-star checked fs-8" aria-hidden="true"></span>
                          <span class="fa fa-star checked fs-8" aria-hidden="true"></span><span
                            class="fa fa-star checked fs-8" aria-hidden="true"></span><span
                            class="color_light2 fs-12">(5) | </span>
                          <span class="available fs-10 ">10 Years</span>

                        </a>
                      </div>
                    </div>


                    <div class="row text_left padding_left20 padding_bt p-3">

                      <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                        <div class="row">
                          <div class="col-md-5">Appointment Date :</div> <span class="col-md-7"> {{ appointemntDate }}
                          </span>
                        </div>
                      </div>
                      <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                        <div class="row">
                          <div class="col-md-5">Appointment Time :</div> <span class="col-md-7"> {{ appointmentTime}}
                          </span>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="col-md-3 col-sm-4">
                    <div class="row mt-2">
                      <div class="col-md-12">
                        <h6 class="font_14 font_Poppins text_center">Download Video</h6>
                      </div>
                      <div class="col-sm-12 col-md-12 text_center"> <a><img src="./assets/img/video_01.png"> </a>
                        <!--<a href="javascript:void(0)" ><img src="./assets/img/chat_01.png"> </a>  <a href="javascript:void(0)" ><img src="./assets/img/phone_01.png"> </a> -->
                      </div>

                    </div>
                  </div>

                </div>





                <!-- Slot listing 5 end -->
              </div>
              <div class="priscription">
                <h3><i class="fa fa-file-prescription"></i> Medical Test Prescription <div class="float-right"> <button
                      class="btn btn-xs btn0col1" data-backdrop="static" data-target="#viewpdf">
                      <i aria-hidden="true" class="fa fa-upload icon-head patientc2"></i></button></div>
                </h3>


                <div class="row">
                  <div class="col-md-6">
                    <div class=" d-flex flex-row mb-0 p-2 border-bottom w-100 card  float-left  blt-2 mb-2 mt-1">
                      <a class="p-2 icon-appointments  " href="javascript:void(0)"><i aria-hidden="true"
                          class="fa fa-tablets icon-col-1 icon-reports"></i></a>
                      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
                        <a href="javascript:void(0)">
                          <p class="font-weight-medium mb-1 mt-1">Blood Report </p>
                          <p class="text-muted mb-1 text-small">10/12/2020</p>
                        </a>
                      </div>
                      <div class="btn-group float-right">
                        <button class="btn btn-xs btn0col1" data-backdrop="static" data-target="#viewpdf">
                          <i aria-hidden="true" class="fa fa-upload icon-head patientc2"></i></button>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-6">
                    <div class=" d-flex flex-row mb-0 p-2 border-bottom w-100 card  float-left  blt-2 mb-2 mt-1">
                      <a class="p-2 icon-appointments  " href="javascript:void(0)"><i aria-hidden="true"
                          class="fa fa-syringe icon-col-2 icon-reports"></i></a>
                      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
                        <a href="javascript:void(0)">
                          <p class="font-weight-medium mb-1 mt-1">Blood Report </p><i
                            class="fa fa-check-circle fa-sm faa-shake text_color_green animated" aria-hidden="true"></i>
                          <p class="text-muted mb-1 text-small">10/12/2020</p>
                        </a>
                      </div>
                      <div class="btn-group float-right">
                        <button class="btn btn-xs btn0col1" data-backdrop="static" data-target="#viewpdf">
                        </button>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-6">
                    <div class=" d-flex flex-row mb-0 p-2 border-bottom w-100 card  float-left  blt-2 mb-2 mt-1">
                      <a class="p-2 icon-appointments  " href="javascript:void(0)"><i aria-hidden="true"
                          class="fa fa-pills icon-col-3 icon-reports"></i></a>
                      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
                        <a href="javascript:void(0)">
                          <p class="font-weight-medium mb-1 mt-1">Blood Report </p>
                          <p class="text-muted mb-1 text-small">10/12/2020</p>
                        </a>
                      </div>
                      <div class="btn-group float-right">
                        <button class="btn btn-xs btn0col1" data-backdrop="static" data-target="#viewpdf">
                          <i aria-hidden="true" class="fa fa-upload icon-head patientc2"></i></button>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-6">
                    <div class=" d-flex flex-row mb-0 p-2 border-bottom w-100 card  float-left  blt-2 mb-2 mt-1">
                      <a class="p-2 icon-appointments  " href="javascript:void(0)"><i aria-hidden="true"
                          class="fa fa-band-aid icon-col-4 icon-reports"></i></a>
                      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
                        <a href="javascript:void(0)">
                          <p class="font-weight-medium mb-1 mt-1">Blood Report </p>
                          <p class="text-muted mb-1 text-small">10/12/2020</p>
                        </a>
                      </div>
                      <div class="btn-group float-right">
                        <button class="btn btn-xs btn0col1" data-backdrop="static" data-target="#viewpdf">
                          <i aria-hidden="true" class="fa fa-upload "></i></button>
                      </div>
                    </div>

                  </div>


                </div>
              </div>
              <div class="medical-tests mt-3">
                <h3><i class="fa fa-x-ray"></i> Prescription</h3>


                <div class="row">


                  <div class="col-md-6">
                    <div class=" d-flex flex-row mb-0 p-2 border-bottom w-100 card  float-left  blt-1 mb-2 mt-1">
                      <a class="p-2 icon-appointments  " href="javascript:void(0)"><i aria-hidden="true"
                          class="fa fa-microscope icon-col-1 icon-reports"></i></a>
                      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
                        <a href="javascript:void(0)">
                          <p class="font-weight-medium mb-1 mt-1">Prescription1 </p>
                          <p class="text-muted mb-1 text-small">10/12/2020</p>
                        </a>
                      </div>
                      <div class="btn-group float-right">
                        <button class="btn btn-xs btn0col1" data-backdrop="static" data-target="#viewpdf">
                          <i aria-hidden="true" class="fa fa-upload icon-head patientc2"></i></button>
                      </div>
                    </div>

                  </div>

                  <div class="col-md-6">
                    <div class=" d-flex flex-row mb-0 p-2 border-bottom w-100 card  float-left  blt-1 mb-2 mt-1">
                      <a class="p-2 icon-appointments  " href="javascript:void(0)"><i aria-hidden="true"
                          class="fa fa-microscope icon-col-2 icon-reports"></i></a>
                      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
                        <a href="javascript:void(0)">
                          <p class="font-weight-medium mb-1 mt-1">Prescription </p>
                          <p class="text-muted mb-1 text-small">10/12/2020</p>
                        </a>
                      </div>
                      <div class="btn-group float-right">
                        <button class="btn btn-xs btn0col1" data-backdrop="static" data-target="#viewpdf">
                          <i aria-hidden="true" class="fa fa-upload icon-head patientc2"></i></button>
                      </div>
                    </div>

                  </div>

                </div>
              </div>
            </div>
            <!-- Tab pane end -->



            <!-- Tab pane 1 end -->

            <!-- Tab pane 2 end -->

            <!-- Tab pane 3 end -->


            <!-- Tab pane 5 end -->
            <div class="tab-pane " id="tab_four">





            </div>
            <!-- Tab pane end -->

          </div>
          <!-- tab content -->

        </div>




      </div>




    </div>
  </div>
</div>

<div class="modal fade modal-right" id="myupcomingappointmentMain1" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="myappointment" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content ">
      <div class="modal-header">
        <h5 class="modal-title">Upcoming Appointment Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">




        <div class="schedule-tab">




          <!-- <ul class="nav nav-tabs nav-stacked " id="nav-tabs">
 <li class="active" >
 <a class="animated fadeIn active" href="#tab_start" data-toggle="tab" aria-expanded="true">
 <i class="fa fa-user themecold5 icon-head" ></i><br>
 <span class="tab-head">
 <span class="tab-text-title">Doctor Details</span>
 </span>
 </a>
 </li>
 </ul> -->
          <div class="tab-content">

            <div class="tab-pane active" id="tab_start">
              <div class=" ">
                <div class="row backgound_imgsidebar mb-3 pt-2 pb-2 pr-2">
                  <div class="col-md-9 col-sm-12">

                    <div class="d-flex flex-row  ">
                      <a class="" href="javascript:void(0)"><img src="./assets/img/profile.png"></a>
                      <div class="pl-1  d-flex flex-column flex-grow-1  ">
                        <a href="javascript:void(0)">
                          <p class="font-weight-medium mb-0 mt-2">Dr.{{referenceEntityName}} <i
                              class="fa fa-check-circle fa-sm faa-shake text_color_green animated"
                              aria-hidden="true"></i></p>
                          <p class="text-muted mb-1 text-small mr-2">Cardiologist</p>
                          <span class="fa fa-star checked fs-8" aria-hidden="true"></span><span
                            class="fa fa-star checked fs-8" aria-hidden="true"></span><span
                            class="fa fa-star checked fs-8" aria-hidden="true"></span>
                          <span class="fa fa-star checked fs-8" aria-hidden="true"></span><span
                            class="fa fa-star checked fs-8" aria-hidden="true"></span><span
                            class="color_light2 fs-12">(5) | </span>
                          <span class="available fs-10 ">10 Years</span>

                        </a>
                      </div>
                      <div class="col-md-3 col-sm-4">
                        <div class="row mt-2">

                          <div class="col-sm-6 text_center"> <a><img src="./assets/img/video_01.png"> </a>
                            <!--<a href="javascript:void(0)" ><img src="./assets/img/chat_01.png"> </a>  <a href="javascript:void(0)" ><img src="./assets/img/phone_01.png"> </a> -->
                          </div>

                        </div>
                      </div>
                    </div>


                    <div class="row text_left padding_left20 padding_bt p-3">
                      <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                        <div class="row">
                          <div class="col-md-6">Ailment :</div> <span class="col-md-6"> {{ailment}}</span>
                        </div>
                      </div>
                      <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                        <div class="row">
                          <div class="col-md-6">Appointment Date :</div> <span class="col-md-6">
                            {{appointemntDate}}</span>
                        </div>
                      </div>
                      <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                        <div class="row">
                          <div class="col-md-6">Appointment Time :</div> <span class="col-md-6">
                            {{appointmentTime}}</span>
                        </div>
                      </div>

                      <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                        <div class="row">
                          <div class="col-md-6">Amount Paid :</div> <span class="col-md-6"><i class="fa fa-inr"
                              aria-hidden="true"></i>&nbsp;{{amount}}</span>
                        </div>
                      </div>

                    </div>
                  </div>


                </div>





                <!-- Slot listing 5 end -->
              </div>

              <!-- Tab pane end -->

            </div>
            <!-- tab content -->

          </div>





        </div>



      </div>
    </div>
  </div>
