<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="keywords" content="HTML5 Template" />
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
  <title>Curemetric</title>
  <link rel="dns-prefetch" href="https://fonts.gstatic.com/">
  <!-- favicon icon -->
  <link rel="shortcut icon" href="./../../../assets/landing/images/favicon.png" />

  <!-- owl-carousel -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/owl.carousel.css">

  <!-- shortcodes -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/base.css" />

  <!-- main -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/core.css" />

  <!-- responsive -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/helper.css" />
  <link rel="stylesheet" type="text/css"
    href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/css/intlTelInput.css" />
    <script>


    // if (navigator.userAgent.match(/Android/i)) {
    //   window.location.href = 'https://play.google.com/store/apps/details?id=com.cure.metric.curemetric'
    // }
    // else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    //   window.location.href = 'https://apps.apple.com/in/app/curemetric/id1659587794'
    // }


    </script>
  <style>
    body,
    p,
    span,
    input,
    button,
    a {
      font-family: 'Poppins', sans-serif !important;
      font-size: 13px !important
    }

    h1,
    h2,
    h3,
    h4 {
      font-family: 'Poppins', sans-serif !important;
    }

    .sign-info {
      border-top: 1px solid #cdd1f3;
      margin-top: 10px;
      padding-top: 20px;
      margin-bottom: 30px
    }

    .iq-social-media {
      margin: 0;
      padding: 0;
      float: right;
    }

    .iq-social-media li {
      list-style: none;
      float: left;
      margin-right: 10px;
    }

    .iq-social-media li:last-child {
      margin-right: 0;
    }

    .iq-social-media li a {
      height: 30px;
      width: 30px;
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      display: inline-block;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
      background: #eff7f8;
      color: #40648c !important;
    }

    .iq-social-media li a:hover {
      text-decoration: none;
    }


    .form-control {
      border-top-width: 0px !important;
      border-left-width: 0px !important;
      border-right-width: 0px !important;
      border-color: #424242 !important;
      border-bottom-width: 1px !important;
      height: calc(2rem + 1px) !important;
      background: transparent !important;
      padding: 9px 0px !important;
    }


    .bg-login {
      background-repeat: no-repeat;
      background-image: url(./assets/landing/images/vector_page/bg-login.jpg);
      background-size: cover;
      background-position: top center;
      width: 100%;
      height: 100%;
      opacity: 1;
      visibility: inherit;
      z-index: 20;
    }


    .signin {
      padding: 0px 0px 0px 0px !important;
      margin-top: 120px;
      max-width: 450px;
      font-family: 'Poppins', sans-serif;
      margin-left: 18px !important;
    }

    .signin h1 {
      font-size: 26px
    }

    .rightimg img {
      height: 450px;
      margin-top: 100px
    }
  </style>

</head>

<body class="ttm-one-page-site">

  <!--page start-->
  <div class="page" id="ttm-home">

    <!-- preloader start -->
    <!-- <div id="preloader"> <div id="status">&nbsp;</div> </div> -->
    <!-- preloader end -->

    <!--header start-->
    <app-main-header></app-main-header>
    <!--header end-->

    <!-- <div class="error-message error">
    <p class="error">errormessage</p>
</div> -->
    <div class="container-fulid">
      <div class="bg-login">
        <div class=" container">
          <div class="row usr">

            <div class="signin col-lg-4 col-md-8 col-sm-12">
              <h1 class="mb-0">Sign in as User</h1>
              <!-- <p>Enter your email address and password</p> -->
              <div class="error" *ngIf="invalidCredentials">
                {{message}}
              </div>
              <div class="col-md-12">
                <button type="submit" *ngIf="verifyNowButton" class="btn btn-sm btn-danger "
                  (click)="verifyNow()">Verify
                  Now</button>
              </div>
              <div *ngIf="loginWithPin">
                <form [formGroup]="mobileLogin" class="mt-4" autocomplete="off">
                  <div class="col-md-8 form-group position-relative">
                    <label for="exampleInputPassword1">Mobile Number <span class="text-danger">*</span></label>
                    <div class="telephone">
                      <input type="text" id="demo" style="margin-left: 35%;" ng2TelInput #phNum
                        [ng2TelInputOptions]="{autoHideDialCode: false, initialCountry: 'in', separateDialCode:true}"
                        (ng2TelOutput)="getNumber($event,phNum.value)" (countryChange)="onCountryChange($event)"
                        class="form-control" formControlName="contactNum" (keypress)="numberOnly($event,phNum)"
                        maxlength="10" placeholder="Enter mobile number" id="telephone" [ngClass]="{
                              'is-invalid':
                                (submitted || m.contactNum.touched) &&
                                m.contactNum.errors
                            }" required />

                        <div style="position: absolute;top: 35px;right: 10px;cursor: pointer;">
                          <span (click)="checkPhoneNumber(phNum.value)" title="Sign In"><i style="font-size: 14px;" class="fa fa-arrow-right"></i></span>
                        </div>

                      <div *ngIf="
                              (submitted || m.contactNum.touched) &&
                              m.contactNum.errors
                            " class="invalid-feedback position-absolute mt-4 dis">
                        <div *ngIf="m.contactNum.errors.required"> Phone Number is required </div>
                        <div *ngIf="m.contactNum.errors.pattern"> Should be a valid Phone Number </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 mb-2" *ngIf="otpEnable">
                    <label for="exampleInputEmail1">OTP</label><span class="text-danger">*</span>
                    <div id="otp-holder2">
                      <div id="otp-content2">
                        <input id="otp-input2" class="otp-input" type="tel" maxlength="6" pattern="\d{6}" #otp
                          (keyup)="verifyOtp(otp.value)" formControlName="otp" (keypress)="numberOnly($event,otp.value)"
                          [ngClass]="{ 'is-invalid':(submitted || m.otp.touched) && m.otp.errors }" />
                      </div>
                    </div>
                    <div *ngIf="(submitted ||m.otp.touched )&& m.otp.errors" class="invalid-feedback">
                      <div *ngIf="m.otp.errors.required">OTP is required</div>
                    </div>
                  </div>
                  <div class="col-md-12 mb-2" *ngIf="otpValid && otpEnable">
                    <label for="exampleInputEmail1">Create Pin</label><span class="text-danger">*</span>
                    <div id="otp-holder2">
                      <div id="otp-content2">
                        <input id="otp-input2" class="otp-input" type="tel" maxlength="6" pattern="\d{6}" #otp
                          formControlName="pin" (keypress)="numberOnly($event,otp.value)"
                          [ngClass]="{ 'is-invalid':(submitted || m.pin.touched) && m.pin.errors }" />
                      </div>
                    </div>
                    <div *ngIf="(submitted ||m.pin.touched )&& m.pin.errors" class="invalid-feedback">
                      <div *ngIf="m.pin.errors.required">Pin is required</div>
                    </div>
                  </div>
                  <div class="col-md-12 mb-2" *ngIf="otpValid && otpEnable">
                    <label for="exampleInputEmail1">Confirm Pin</label><span class="text-danger">*</span>
                    <div id="otp-holder2">
                      <div id="otp-content2">
                        <input id="otp-input2" class="otp-input" type="tel" maxlength="6" pattern="\d{6}" #otp
                          formControlName="cpin" (keypress)="numberOnly($event,otp.value)"
                          [ngClass]="{ 'is-invalid':(submitted || m.cpin.touched) && m.cpin.errors }" />
                      </div>
                    </div>
                    <div *ngIf="(submitted ||m.cpin.touched )&& m.cpin.errors" class="invalid-feedback">
                      <div *ngIf="m.cpin.errors.required">Confirm Pin is required</div>
                      <div *ngIf="m.cpin.errors.mustMatch"> Pins must match </div>
                    </div>
                  </div>
                  <div class="col-md-12 mb-2" *ngIf="isPhoneNumberExists">
                    <label for="exampleInputEmail1">Pin</label><span class="text-danger">*</span>
                    <div id="otp-holder2">
                      <div id="otp-content2">
                        <input id="otp-input2" class="otp-input" type="tel" maxlength="6" pattern="\d{6}" #otp
                          formControlName="lpin" (keypress)="numberOnly($event,otp.value)"
                          [ngClass]="{ 'is-invalid':(submitted || m.lpin.touched) && m.lpin.errors }" />
                      </div>
                    </div>
                    <div *ngIf="(submitted ||m.lpin.touched )&& m.lpin.errors" class="invalid-feedback">
                      <div *ngIf="m.lpin.errors.required"> Pin is required</div>

                    </div>
                  </div>
                  <div class="row ml-1">
                    <div class="col-md-12 mr-2" style="max-height: 40px;">
                      <span class="timer2 mt-2" *ngIf="isTimeOn2" id="timer2"
                        style="padding-top:15px ;color:red"></span>
                      <button class="btn btn-sm btn-success" (click)="getOTP()" *ngIf="!isTimeOn2 && !isResend && !isPhoneNumberExists">Get
                        OTP</button>
                      <button class="btn btn-sm btn-success" (click)="getOTP()" *ngIf="isResend">Resend
                        OTP</button>
                      <!-- <button class="btn btn-sm btn-primary mr-2" (click)="verifyPhoneOTP()">Verify Now</button>
                  <button  class="btn btn-sm btn-success" (click)="resendPhoneOTP()" [disabled]="isTimeOn" >Resend OTP</button>
                  <span class="timer" id="timer"></span> -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-2">
                      <a style="color:green;text-decoration:underline" class="float-left" href="javascript:void(0)"
                        (click)="forgotPassword()">Forgot password?</a>
                    </div>
                    <div class="col-md-6 mr-2" style="max-height: 40px;">
                      <button class="btn btn-sm btn-success" (click)="register()"
                        *ngIf="otpEnable && otpValid">Register</button>
                      <button class="btn btn-sm btn-success" (click)="loginUser()"
                        *ngIf="!otpEnable && isPhoneNumberExists">Submit</button>
                    </div>
                  </div>
                  <div class="sign-info">
                    <span class="dark-color d-inline-block line-height-2">Don't have an account? <a
                        [routerLink]="['/patientRegister']"
                        style="color:green;text-decoration:underline">Register</a></span>
                    <ul class="iq-social-media">
                      <li><a href="https://www.facebook.com/Curemetric" target="_blank"><i
                            class="fa fa-facebook  m-r-5"></i></a></li>
                      <li><a target="_blank" href="https://twitter.com/Cure_Metric"><i
                            class="fa fa-twitter m-r-5"></i></a></li>
                      <li><a href="https://www.linkedin.com/company/curemetric" target="_blank"><i
                            class="fa fa-linkedin m-r-5"></i></a></li>
                    </ul>
                    <p>
                      <a href="javascript:void(0)" (click)="loginWithPwd()"
                        style="color:green;text-decoration:underline">Login with Password</a>
                    </p>
                  </div>
                </form>
              </div>
              <div *ngIf="loginWithPassword">
                <form [formGroup]="registerForm" class="mt-4" autocomplete="off">
                  <div class="form-group">
                    <label for="exampleInputEmail1">Username / Email ID <span style="color:red">*</span></label>
                    <input type="text" autocomplete="nope" tabIndex="1" id="email" class="form-control mb-0"
                      formControlName="email" [(ngModel)]="email" placeholder="Enter Username / Email ID"
                      [ngClass]="{'is-invalid':(submitted || f.email.touched)  && f.email.errors }">
                    <div *ngIf="(submitted || f.email.touched) && f.email.errors" class="invalid-feedback">
                      <div *ngIf="f.email.errors.required">Username / Email is required </div>
                      <div *ngIf="f.email.errors.minlength">This field must be at least 3 characters long</div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Password <span style="color:red">*</span></label>
                    <a style="color:green;text-decoration:underline" class="float-right" href="javascript:void(0)"
                      (click)="forgotPassword()">Forgot password?</a>
                    <div class="input-group" id="show_hide_password">
                      <input type="password" autocomplete="chrome-off" (paste)="preventCopy($event)"
                        (copy)="preventCopy($event)" (keydown.space)="$event.preventDefault()" tabIndex="2"
                        id="password"
                        class="form-control mb-0" (keypress)="checkcapslockon($event)" formControlName="password"
                        minlength="8" [(ngModel)]="password" placeholder="Password"
                        [ngClass]="{'is-invalid':(submitted || f.password.touched) && f.password.errors}"
                        id="exampleInputPassword1" placeholder="Enter Password" />
                      <div class="input-group-addon">
                        <a href="javascript:void(0)"><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                      </div>
                      <div *ngIf="(submitted || f.password.touched) && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">
                          Password is required
                        </div>
                        <div *ngIf="pwd.errors?.minlength">
                          Password must be at least 8 characters long
                        </div>
                      </div>
                      <div *ngIf="showValidationMessage" class="validation-message">Copying password is not allowed
                      </div>
                    </div>
                  </div>
                  <div class="d-inline-block w-100">
                    <div class="custom-control custom-checkbox d-inline-block mt-2">
                      <input type="checkbox" class="custom-control-input" id="customCheck1" [checked]="rememberme"
                        (change)="remembermeCheck()">
                      <label style="margin-left:5px;" class="custom-control-label" for="customCheck1">Remember
                        Me</label>
                    </div>
                    <button type="submit" id="signin" class="btn btn-primary float-right effect effect-5" (click)="onSubmit()"
                      [disabled]="!registerForm.valid">Sign in</button>
                  </div>
                  <div class="sign-info">
                    <span class="dark-color d-inline-block line-height-2">Don't have an account? <a
                        [routerLink]="['/patientRegister']"
                        style="color:green;text-decoration:underline">Register</a></span>
                    <ul class="iq-social-media">
                      <li><a href="https://www.facebook.com/Curemetric" target="_blank"><i
                            class="fa fa-facebook  m-r-5"></i></a></li>
                      <li><a target="_blank" href="https://twitter.com/Cure_Metric"><i
                            class="fa fa-twitter m-r-5"></i></a></li>
                      <li><a href="https://www.linkedin.com/company/curemetric" target="_blank"><i
                            class="fa fa-linkedin m-r-5"></i></a></li>
                    </ul>
                    <p>
                      <a href="javascript:void(0)" (click)="loginWithMobile()"
                        style="color:green;text-decoration:underline">Login with Pin</a>
                    </p>
                  </div>
                </form>
              </div>


            </div>

            <div class=" rightimg col-lg-7  col-md-5 col-sm-5">
              <img src="./assets/landing/images/p-login.png" style="" />
            </div>
          </div>
        </div>
      </div>
    </div><!-- page end -->

    <!-- Footer -->
    <app-main-footer></app-main-footer>
    <!-- Footer Ends -->
