<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="keywords" content="HTML5 Template" />
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
  <title>Curemetric</title>
  <link rel="dns-prefetch" href="https://fonts.gstatic.com/">
  <!-- favicon icon -->
  <link rel="shortcut icon" href="./../../../assets/landing/images/favicon.png" />

  <!-- owl-carousel -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/owl.carousel.css">

  <!-- shortcodes -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/base.css" />

  <!-- main -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/core.css" />

  <!-- responsive -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/helper.css" />


  <style>
    body,
    p,
    span,
    input,
    button,
    a {
      font-family: 'Poppins', sans-serif !important;
      font-size: 13px !important
    }

    h1,
    h2,
    h3,
    h4 {
      font-family: 'Poppins', sans-serif !important;
    }

    .sign-info {
      border-top: 1px solid #cdd1f3;
      margin-top: 10px;
      padding-top: 20px;
      margin-bottom: 30px
    }

    .iq-social-media {
      margin: 0;
      padding: 0;
      float: right;
    }

    .iq-social-media li {
      list-style: none;
      float: left;
      margin-right: 10px;
    }

    .iq-social-media li:last-child {
      margin-right: 0;
    }

    .iq-social-media li a {
      height: 30px;
      width: 30px;
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      display: inline-block;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
      background: #eff7f8;
      color: #40648c !important;
    }

    .iq-social-media li a:hover {
      text-decoration: none;
    }


    .form-control {
      border-top-width: 0px !important;
      border-left-width: 0px !important;
      border-right-width: 0px !important;
      border-color: #424242 !important;
      border-bottom-width: 1px !important;
      height: calc(2rem + 1px) !important;
      background: transparent !important;
      padding: 9px 0px !important;
    }


    .bg-login {
      background-repeat: no-repeat;
      background-position: top left;
      background-image: url(./assets/landing/images/vector_page/bg-login.jpg);
      background-size: cover;

      width: 100%;
      height: 100%;
      opacity: 1;
      visibility: inherit;
      z-index: 20;
    }


    .signin {
      padding: 0px 0px 0px 0px !important;
      margin-top: 120px;
      max-width: 450px;
      font-family: 'Poppins', sans-serif;
      margin-left: 18px !important;
    }

    .signin h1 {
      font-size: 26px
    }

    .rightimg img {
      height: 450px;
      margin-top: 100px
    }
  </style>

</head>

<body class="ttm-one-page-site">

  <!--page start-->
  <div class="page" id="ttm-home">

    <!-- preloader start -->

    <!-- preloader end -->

    <!--header start-->
    <app-main-header></app-main-header>
    <!--header end-->


    <div class="container-fulid">
      <div class="bg-login">
        <div class="container ">
          <div class="row">

            <div class="signin col-md-4 ml-4">
              <h1 class="mb-0">OTP Verification</h1>
              <p *ngIf="!emailVerificationDone">Enter the OTP sent to your email ID</p>
              <p *ngIf="emailVerificationDone">Enter the OTP sent to your mobile number</p>
              <div class="error" *ngIf="invalidCredentials">
                {{message}}
              </div>
              <div class="text-success" *ngIf="successCredentials">
                {{message}}
              </div>
              <form id="rulesForm" class="tooltip-label-right" novalidate>
                <div class="form-group" style="display: none;">
                  <label style="font-size: 14px; font-weight: 600">Email</label>
                  <input type="text" style="color: #b4b4b4" class="form-control" [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="email" placeholder="Enter your Email" style="font-size: 13px; font-weight: 600"
                    readonly />
                </div>

              </form>

              <form [formGroup]="otpVerificationForm">
                <div class="row d-flex flex-column justify-content-center">
                  <div *ngIf="!emailVerificationDone">
                    <div class="col-md-12 form-group">
                      <label for="exampleInputEmail1">Email Id</label><span class="text-danger">*</span>
                      <input type="emailId" formControlName="email" class="form-control mb-0"
                        placeholder="Enter your emailId" placeholder="Enter Email id" readonly>
                    </div>
                    <div class="col-md-6 form-group">
                      <label style="font-size: 14px; font-weight: 600">OTP</label>
                      <!-- <input
                  type="text"
                  style="color: #b4b4b4"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  [(ngModel)]="OTP"
                  placeholder="Enter your OTP  Number"
                  style="font-size: 13px; font-weight: 600"
                /> -->
                      <div id="otp-holder">
                        <div id="otp-content">
                          <input id="otp-input" #emailOtp class="otp-input" type="tel" maxlength="6" pattern="\d{6}"
                            (keypress)="numberOnly($event)" (keyup)="otpEntered(emailOtp.value)"
                            [ngModelOptions]="{ standalone: true }" [(ngModel)]="OTP" autocomplete="off" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12" style="max-height: 40px;">
                      <button class="btn btn-sm btn-primary mr-2" (click)="verifyOTP()"
                        [disabled]="verifyNowButton">Verify Now</button>
                      <span class="timer" *ngIf="isTimeOn" id="timer" style="padding-top:10px ;color:red"></span>
                      <button class="btn btn-sm btn-success" (click)="getOtp()" *ngIf="!isTimeOn">Resend OTP</button>
                    </div>
                  </div>
                  <div *ngIf="emailVerificationDone">
                    <div class="row ml-1">
                      <div class="col-md-6 form-group">
                        <label for="exampleInputPassword1">Mobile Number</label><span class="text-danger">*</span>
                        <!-- <div class="phone-default"> +91
                        </div> -->
                        <input type="phonenumber" #phNum formControlName="phoneNumber" maxlength="10"
                          class="form-control w-85 float-left" placeholder="Phone Number" readonly />
                      </div>
                      <!-- <div class="col-md-6 form-group">
              <button class="btn btn-sm btn-primary" style="margin-top: 18%;" (click)="getOtp()">Get Otp</button>
            </div> -->
                    </div>
                    <div class="col-md-12 form-group">
                      <label style="font-size: 14px; font-weight: 600">OTP</label>
                      <!-- <input
                  type="text"
                  style="color: #b4b4b4"
                  [ngModelOptions]="{ standalone: true }"
                  class="form-control"
                  [(ngModel)]="OTP"
                  placeholder="Enter your OTP  Number"
                  style="font-size: 13px; font-weight: 600"
                /> -->
                      <div id="otp-holder">
                        <div id="otp-content">
                          <input id="otp-input" #phOtp class="otp-input" type="tel" maxlength="6" pattern="\d{6}"
                            (keypress)="numberOnly($event)" (keyup)="otpEntered(phOtp.value)"
                            [ngModelOptions]="{ standalone: true }" [(ngModel)]="PhOTP" autocomplete="off" />
                        </div>
                      </div>
                    </div>
                    <div class="row ml-1">
                      <div class="col-md-12 mr-2" style="max-height: 40px;">
                        <button class="btn btn-sm btn-primary mr-2" (click)="verifyPhoneOTP()"
                          [disabled]="verifyNowButton">Verify Now</button>
                        <span class="timer" *ngIf="isTimeOn2" id="timer2" style="padding-top:10px ;color:red"></span>
                        <button class="btn btn-sm btn-success" (click)="getPhoneOtp()" *ngIf="!isTimeOn2">Resend
                          OTP</button>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="col-md-12 form-group">
        <label for="exampleInputEmail1">Phone Number</label><span class="text-danger">*</span>
        <input type="emailId" formControlName="phoneNumber"  class="form-control mb-0"  placeholder="Enter your emailId" placeholder="Enter Email id" readonly>
      </div> -->
                </div>
              </form>

            </div>

            <div class=" rightimg col-lg-7  col-md-5 col-sm-5">
              <img src="./assets/landing/images/p-login.png" />
            </div>
          </div>
        </div>
      </div>
    </div><!-- page end -->

<!-- Footer -->
<app-main-footer></app-main-footer>
<!-- Footer Ends -->
