import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-display-prescription-modal',
  templateUrl: './display-prescription-modal.component.html',
  styleUrls: ['./display-prescription-modal.component.css']
})
export class DisplayPrescriptionModalComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }
  @Input() prescription: any;

  ngOnInit(): void {
  }

}
