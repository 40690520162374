import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { gpsService } from '../../services/gps.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientService } from '../../services/patient.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-patient-address',
  templateUrl: './new-patient-address.component.html',
  styleUrls: ['./new-patient-address.component.css']
})
export class NewPatientAddressComponent implements OnInit {
  @Input() userId:any;
  primaryAddress: FormGroup;
  zip;postalLocations=[]; countryCodeSelected;
  latitude;
  longitude;
  primarysubmitted=false;selectedValue;formOpen=false
  constructor(private fb:FormBuilder,private activeModal:NgbActiveModal,
    private toasterService:ToastrService,private patientService: PatientService,private gps: gpsService) { }

  ngOnInit(): void {
    this.primaryAddress = this.fb.group({
      addressId: [],
      isPrimary: [''],
      contactName: ['', Validators.required],
      contactPhoneNumber: [''],
      city: ['', Validators.required],
      country: ['', Validators.required],
      longitude: [''],
      state: ['', Validators.required],
      latitude: [''],
      street1: ['', Validators.required],
      street2: [''],
      zip: ['', Validators.required],
      cc: ['', Validators.required],
      postalLocation: ['', Validators.required]
    });
  }
  get f() { return this.primaryAddress.controls; }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  countryCodeEntered(cc) {
    this.patientService.getPincodeDetails(this.zip, cc).subscribe(res => {
      if (res['data'].length == 0) {
        this.primaryAddress.patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.toasterService.error("Incorrect pincode")
      }
      this.postalLocations = res['data'];
      this.latitude = res['data'][0]['latitude'];
      this.longitude = res['data'][0]['longitude'];
      this.primaryAddress.patchValue({
        country: res['data'][0]['country'],
        city: res['data'][0]['province'],
        state: res['data'][0]['state'],

      })
    })
  }
  resetForm(){
    this.primaryAddress.reset()
  }
  drpdownSelection(data){

  }
  percChanged(zip) {
    this.zip = zip;
    // this.countryCodeSelected = null;
    // this.primaryAddress.patchValue({
    //   country: '',
    //   city: '',
    //   state: '',
    //   postalLocation: ''
    // });
    if (this.zip?.length < 6) {
      this.countryCodeSelected = null;
      this.primaryAddress.patchValue({
        country: '',
        city: '',
        state: '',
        postalLocation: ''
      })
      this.postalLocations = [];
    }
  }
  savePrimaryAddress(address){
    this.gps.getCoordinates()
    this.primarysubmitted = true;
    if (this.primaryAddress.invalid) {
      return
    }
    address.value.userId = this.userId;
    address.value.longitude = this.longitude;
    address.value.latitude = this.latitude;
    this.patientService.saveAddress(address.value, this.userId).subscribe(res => {
      this.formOpen=false
      this.activeModal.close(true)
      this.toasterService.success("Details saved.")
    },
      error => {
        this.toasterService.error("Error in saving details. Try again.")
      })
  }
  closeModal(){
    this.activeModal.close()
  }
}
