import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalStorageService {
  private storage: Map<string, string> = new Map();
  private storageChanged = new Subject<void>(); // Observable to track changes

  constructor() { }

  getItem(key: string): string | null {
    return this.storage.has(key) ? this.storage.get(key) : null;
  }

  setItem(key: string, value): void {
    this.storage.set(key, value);
    this.storageChanged.next(); // Notify of change
  }

  removeItem(key: string): void {
    this.storage.delete(key);
    this.storageChanged.next(); // Notify of change
  }

  clear(): void {
    // this.storage.clear();
    // this.storageChanged.next(); // Notify of change
  }

  getStorageChanges() {
    return this.storageChanged.asObservable(); // Provide observable for storage changes
  }

  saveToLocalStorage(): void {
    const storageObject: { [key: string]: string } = {};
    this.storage.forEach((value, key) => {
      storageObject[key] = value;
    });
    localStorage.setItem('appStorage', JSON.stringify(storageObject));
  }


  loadFromLocalStorage(): void {
    const storageObject = localStorage.getItem('appStorage');
    if (storageObject) {
      const entries = JSON.parse(storageObject);
      this.storage = new Map(Object.entries(entries));
      this.storageChanged.next(); // Notify of restored values
    }
    localStorage.removeItem('appStorage');
  }
}
