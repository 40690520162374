import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms'
import { DoctorService } from '../../AppServices/doctor.service'
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ThrowStmt } from '@angular/compiler';
import { ThisMonthInstance } from 'twilio/lib/rest/api/v2010/account/usage/record/thisMonth';
import { DomSanitizer } from '@angular/platform-browser';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll'
import { MatAccordion, MatExpansionPanel } from '@angular/material';
import { ViewChild } from '@angular/core';
import { DeleteConfirmationComponent } from '../../DoctorPages/delete-confirmation/delete-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { PatientService } from 'src/app/shared/services/patient.service';
import { Router } from '@angular/router';
import { gpsService } from 'src/app/shared/services/gps.service';
@Component({
  selector: 'app-doctor-address-details',
  templateUrl: './doctor-address-details.component.html',
  styleUrls: ['./doctor-address-details.component.css']
})
export class DoctorAddressDetailsComponent implements OnInit {

  primaryAddress: FormGroup;
  secondaryAddress: FormGroup;
  addressArray: FormArray;
  ViewMode: boolean = true;
  ViewMode1: boolean = true;
  isDisabled: boolean = true;
  firstPrimaryaddress
  beforeSecondaryAddress
  addressDtsMessage; selectedValue; postalLocations: any[]; searchTxt; selected; searchTxt1; postalLocationsSec: any[]
  latitude;
  longitude;

  latitude2;
  longitude2;
  isSave;

  // @ViewChild('primary', {static: false}) primary: MatExpansionPanel;
  //@ViewChild('secondary', {static: false}) secondary: MatExpansionPanel;

  constructor(private globalStorage: GlobalStorageService, private patientService: PatientService, public dialog: MatDialog, private fb: FormBuilder, private sanitizer: DomSanitizer, private DoctorService: DoctorService, private ToastrService: ToastrService, private forgot: AuthService, private router: Router, private gps: gpsService) {
    this.primaryAddress = this.fb.group({
      addressId: [],
      isPrimary: ['Y'],
      contactName: ['', [Validators.required,Validators.minLength(3), Validators.maxLength(20)]],
      contactPhoneNumber: ['', [Validators.required]],
      city: ['', Validators.required],
      country: ['', Validators.required],
      cc: ['', Validators.required],
      longitude: [],
      state: ['', Validators.required],
      latitude: [],
      street1: ['', Validators.required],
      street2: [''],
      zip: ['', Validators.required],
      postalLocation: ['', Validators.required]
    });

    this.secondaryAddress = this.fb.group({
      addressId: [],
      isPrimary: ['N'],
      contactName: ['', [Validators.required,Validators.minLength(3), Validators.maxLength(20)]],
      contactPhoneNumber: ['', [Validators.required]],
      city: ['', Validators.required],
      country: ['', Validators.required],
      cc: ['', Validators.required],
      longitude: [],
      state: ['', Validators.required],
      latitude: [],
      street1: ['', Validators.required],
      street2: [''],
      zip: ['', Validators.required],
      postalLocation: ['', Validators.required]
    });
  }

  loginUser
  primaryPanal: boolean = false;
  ngOnInit(): void {
    var data = JSON.parse(this.globalStorage.getItem('LoginDetails'));
    this.loginUser = data.data;
    this.LoadAddress();
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  bidndAddress
  LoadAddress() {
    this.DoctorService.getAddressDetails(this.loginUser.doctorId).subscribe(res => {

      var address = res['data'];
      if (address.length == 0) {
        this.addressDtsMessage = "* Please fill address details"
      }
      else {
        this.addressDtsMessage = " "
      }
      if (address.length > 0) {
        for (var i = 0; i < address.length; i++) {

          if (address[i].isPrimary == 'Y') {
            //this.percChanged(address[i].zip)
            this.primaryAddress.patchValue({
              postalLocation: address[i]?.postalLocation
            })
            this.primaryAddress.controls['addressId'].patchValue(address[i].addressId);
            this.primaryAddress.controls['contactName'].patchValue(address[i].contactName);
            // this.secondaryAddress.controls['contactName'].patchValue(address[i].contactName);
            this.primaryAddress.controls['contactPhoneNumber'].patchValue(address[i].contactPhoneNumber);
            this.primaryAddress.controls['city'].patchValue(address[i].city);
            this.primaryAddress.controls['country'].patchValue(address[i].country);
            this.primaryAddress.controls['cc'].patchValue(address[i].country);
            this.primaryAddress.controls['state'].patchValue(address[i].state);
            this.primaryAddress.controls['street1'].patchValue(address[i].street1);
            this.primaryAddress.controls['street2'].patchValue(address[i].street2);
            this.primaryAddress.controls['zip'].patchValue(address[i].zip);
            this.primaryAddress.controls['postalLocation'].patchValue(address[i].postalLocation);
          }
          if (address[i].isPrimary == 'N') {
            this.secondaryAddress.patchValue({
              postalLocation: address[i]?.postalLocation
            })
            this.secondaryAddress.controls['addressId'].patchValue(address[i].addressId);
            this.secondaryAddress.controls['contactName'].patchValue(address[i].contactName);
            this.secondaryAddress.controls['contactPhoneNumber'].patchValue(address[i].contactPhoneNumber);
            this.secondaryAddress.controls['city'].patchValue(address[i].city);
            this.secondaryAddress.controls['country'].patchValue(address[i].country);
            this.secondaryAddress.controls['cc'].patchValue(address[i].country);
            this.secondaryAddress.controls['state'].patchValue(address[i].state);
            this.secondaryAddress.controls['street1'].patchValue(address[i].street1);
            this.secondaryAddress.controls['street2'].patchValue(address[i].street2);
            this.secondaryAddress.controls['zip'].patchValue(address[i].zip);
            this.secondaryAddress.controls['postalLocation'].patchValue(address[i].postalLocation);
          }
          if (address[i].isPrimary == null){
            var user_data = JSON.parse(this.globalStorage.getItem('LoginDetails'));
            var docDetails = user_data['data'];
            var docName = docDetails?.firstName + ' ' + docDetails?.lastName;
            var docNumber = docDetails?.cellNumber;
            this.primaryAddress.controls['contactName'].patchValue(docName);
            this.primaryAddress.controls['contactPhoneNumber'].patchValue(docNumber);
            // this.primaryAddress.controls['zip'].patchValue(address[i].zip);
            // this.primaryAddress.controls['cc'].patchValue(address[i].country);
            // this.primaryAddress.controls['postalLocation'].patchValue(address[i].postalLocation);
            // this.primaryAddress.controls['street1'].patchValue(address[i].street1);
            // this.primaryAddress.controls['street2'].patchValue(address[i].street2);
            // this.primaryAddress.controls['city'].patchValue(address[i].city);
            // this.primaryAddress.controls['state'].patchValue(address[i].state);

            this.secondaryAddress.controls['contactName'].patchValue(docName);
          }
        }
        this.BindAddress(address[0]);
        this.BindAddress(address[1]);
      } else {
        this.Prepoplate()
      }
    }, error => {
      this.ToastrService.error("Error in getting address")
    });
  }


  Prepoplate() {
    this.DoctorService.getDoctorDetailsById(this.loginUser.doctorId).subscribe(res => {
      var profileDetails = res['data']
      this.primaryAddress.controls['contactName'].patchValue(profileDetails?.firstName);
      this.secondaryAddress.controls['contactName'].patchValue(profileDetails?.firstName);
      this.primaryAddress.controls['contactPhoneNumber'].patchValue(profileDetails?.cellNumber);
    }, error => {
      this.ToastrService.error("Error in  getting doctor Details")
    })
  }

  BindAddress(address) {

    if (address.isPrimary == 'Y') {
      // this.getZip(address?.zip)
      this.latitude=address?.latitude
      this.longitude=address?.longitude
      this.postalLocations = [{ "postalLocation": address?.postalLocation }]
      this.selectedValue = address?.postalLocation
      this.primaryAddress.patchValue({
        postalLocation: address?.postalLocation
      })
      this.primaryAddress.controls['addressId'].patchValue(address?.addressId);
      this.primaryAddress.controls['contactName'].patchValue(address?.contactName);
      this.secondaryAddress.controls['contactName'].patchValue(address?.contactName);
      this.primaryAddress.controls['contactPhoneNumber'].patchValue(address?.contactPhoneNumber);
      this.primaryAddress.controls['city'].patchValue(address?.city);
      this.primaryAddress.controls['country'].patchValue(address?.country);
      this.primaryAddress.controls['cc'].patchValue(address?.country);
      this.primaryAddress.controls['state'].patchValue(address?.state);
      this.primaryAddress.controls['street1'].patchValue(address?.street1);
      this.primaryAddress.controls['street2'].patchValue(address?.street2);
      this.primaryAddress.controls['zip'].patchValue(address?.zip);
      this.primaryAddress.controls['postalLocation'].patchValue(address?.postalLocation);
      this.firstPrimaryaddress = address
    }
    else {
      // this.getZip2(address?.zip)
      this.latitude2=address?.latitude
      this.longitude2=address?.longitude
      this.postalLocationsSec = [{ "postalLocation": address?.postalLocation }]
      this.selected = address?.postalLocation
      this.secondaryAddress.patchValue({
        postalLocation: address?.postalLocation
      })
      this.secondaryAddress.controls['addressId'].patchValue(address?.addressId);
      //this.secondaryAddress.controls['contactName'].patchValue(address?.contactName);
      this.secondaryAddress.controls['contactPhoneNumber'].patchValue(address?.contactPhoneNumber);
      this.secondaryAddress.controls['city'].patchValue(address?.city);
      this.secondaryAddress.controls['country'].patchValue(address?.country);
      this.secondaryAddress.controls['cc'].patchValue(address?.country);
      this.secondaryAddress.controls['state'].patchValue(address?.state);
      this.secondaryAddress.controls['street1'].patchValue(address?.street1);
      this.secondaryAddress.controls['street2'].patchValue(address?.street2);
      this.secondaryAddress.controls['zip'].patchValue(address?.zip);
      this.secondaryAddress.controls['postalLocation'].patchValue(address?.postalLocation);
      this.beforeSecondaryAddress = address
    }

  }

  primarysubmitted: boolean = false

  get f() { return this.primaryAddress.controls; }

  savePrimaryAddress(address) {
    this.gps.getCoordinates()
    this.primarysubmitted = true;
    if (this.primaryAddress.invalid) {
      return
    }
    address.value.doctorId = this.loginUser.doctorId;
    address.value.active = 'Y';

    address.value.longitude = this.longitude;
    address.value.latitude = this.latitude;
    this.DoctorService.saveAddressDetails(address.value).subscribe(res => {
      this.ViewMode = true;
      this.ToastrService.success("Doctor address saved successfully")
      this.globalStorage.setItem('isAddressCompleted','Y')
      if (this.globalStorage.getItem('isFeeDetailCompleted') != 'Y') {
        this.router.navigate(['/EditProfile'])
        return
      }
    },
      error => {
        this.ToastrService.error("Error in  save  address")
      })
  }

  secondarysubmitted: boolean = false

  get sf() { return this.secondaryAddress.controls; }

  saveSecondaryAddress(address) {

    this.secondarysubmitted = true;
    if (this.secondaryAddress.invalid) {
      return
    }

    this.gps.getCoordinates()
    address.value.longitude = this.longitude;
    address.value.latitude = this.latitude;

    address.value.doctorId = this.loginUser.doctorId
    address.value.active = 'Y'
    address.value.longitude = this.longitude2;
    address.value.latitude = this.latitude2;
    this.DoctorService.saveAddressDetails(address.value).subscribe(res => {
      this.LoadAddress();
      this.ViewMode1 = true;
      this.ToastrService.success("Doctor address saved successfully")
      this.addressDtsMessage = ''
    },
      error => {
        this.ToastrService.error(error['error']['message'])
      })
  }

  deleteAddress(address) {

    if (address.value.addressId == undefined) {
      this.ToastrService.warning("Address is not avalable")
      return
    }

    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        status: null
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result['data'] == 'YES') {
        this.DoctorService.deleteDoctorAddress(address.value.addressId).subscribe(res => {
          this.ToastrService.success("Doctor address deleted successfully");
          this.LoadAddress();
        },
          error => {
            this.ToastrService.error("Error in  delete  address")
          })
      }
    });

  }
  checkForm(item) {
    if (this.firstPrimaryaddress != undefined) {
      if (item.value.contactName != this.firstPrimaryaddress.contactName ||
        item.value.contactPhoneNumber != this.firstPrimaryaddress.contactPhoneNumber ||
        item.value.street1 != this.firstPrimaryaddress.street1 ||
        item.value.street2 != this.firstPrimaryaddress.street2 ||
        this.selectedValue != null) {
        this.isSave = false
      } else {
        this.isSave = true
      }
    }
    else{
      if (item.value.contactName  &&
        item.value.contactPhoneNumber  &&
        item.value.street1 && 
        this.selectedValue != null) 
    {
      this.isSave = false
    } else {
      this.isSave = true
    }
    }
    if (this.primaryAddress.invalid) {
      this.isSave = true
    }
  }
  
  checkSecForm(item) {
    if (this.beforeSecondaryAddress != undefined) {
      if (item.value.contactName != this.beforeSecondaryAddress.contactName ||
        item.value.contactPhoneNumber != this.beforeSecondaryAddress.contactPhoneNumber ||
        item.value.street1 != this.beforeSecondaryAddress.street1 ||
        item.value.street2 != this.beforeSecondaryAddress.street2 ||
        this.selected != null) {
        this.isSecSave = false
      } else {
        this.isSecSave = true
      }
    }
    else{
      if (item.value.contactName  &&
        item.value.contactPhoneNumber  &&
        item.value.street1 && 
        this.selected != null) 
    {
      this.isSecSave = false
    } else {
      this.isSecSave = true
    }
    }
    if (this.secondaryAddress.invalid) {
      this.isSecSave = true
    }
  }

  isChecked = true
  checkBoxCheck(e) {
    // var request={
    //   "forUserId": this.userId,
    //   "relationType": "DOCTOR",
    //   "userId":this.userId
    // }
    this.DoctorService.getAddressDetails(this.loginUser.doctorId).subscribe(res => {
      var address = res['body']['data'];
      if ($("#cbbox").prop('checked') == true) {
        this.secondaryAddress.controls['addressId'].patchValue(address[0].addressId);
        this.secondaryAddress.controls['contactName'].patchValue(address[0].contactName);
        this.secondaryAddress.controls['contactPhoneNumber'].patchValue(address[0].contactPhoneNumber);
        this.secondaryAddress.controls['city'].patchValue(address[0].city);
        this.secondaryAddress.controls['country'].patchValue(address[0].country);
        this.secondaryAddress.controls['cc'].patchValue(address[0].country);
        this.secondaryAddress.controls['state'].patchValue(address[0].state);
        this.secondaryAddress.controls['street1'].patchValue(address[0].street1);
        this.secondaryAddress.controls['street2'].patchValue(address[0].street2);
        this.secondaryAddress.controls['zip'].patchValue(address[0].zip);
        this.secondaryAddress.controls['latitude'].patchValue(address[0].latitude);
        this.secondaryAddress.controls['longitudes'].patchValue(address[0].longitudes);
        this.beforeSecondaryAddress = address[0]
      }
      else {
        this.secondaryAddress.controls['addressId'].patchValue(address[1].addressId);
        this.secondaryAddress.controls['contactName'].patchValue(address[1].contactName);
        this.secondaryAddress.controls['contactPhoneNumber'].patchValue(address[1].contactPhoneNumber);
        this.secondaryAddress.controls['city'].patchValue(address[1].city);
        this.secondaryAddress.controls['country'].patchValue(address[1].country);
        this.secondaryAddress.controls['cc'].patchValue(address[1].country);
        this.secondaryAddress.controls['state'].patchValue(address[1].state);
        this.secondaryAddress.controls['street1'].patchValue(address[1].street1);
        this.secondaryAddress.controls['street2'].patchValue(address[1].street2);
        this.secondaryAddress.controls['zip'].patchValue(address[1].zip);
        this.secondaryAddress.controls['latitude'].patchValue(address[1].latitude);
        this.secondaryAddress.controls['longitudes'].patchValue(address[1].longitudes);
        this.beforeSecondaryAddress = address[1]
      }
    }, error => {
      this.ToastrService.error("Error in getting address")
    });
  }

  btnEdit() {
    this.ViewMode = false;
    this.isDisabled = false;
    this.isSave = true;
    this.primaryAddress.markAsUntouched();
  }
  isSecSave = true
  btnSecEdit() {
    this.ViewMode1 = false;
    this.isDisabled = false;
    this.isSecSave = true;
    this.isChecked = false;
    this.secondaryAddress.markAsUntouched();
  }

  primaryClose() {
    this.LoadAddress()
    if (this.primaryAddress.invalid) {
      this.primarysubmitted = false;
    }
    this.ViewMode = true;
  }

  secondaryClose() {
    this.LoadAddress()
    if (this.secondaryAddress.invalid) {
      this.secondarysubmitted = false;
    }
    this.ViewMode1 = true;
  }

  zipChanged(Zip, Address) {
    this.patientService.getAddressAll(Zip).subscribe(res => {
      if (res['result'].length == 0) {
        this.primaryAddress.patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.ToastrService.error("Wrong pincode")
      }
      this.postalLocations = res['result']
      if (Address == 'P') {
        this.primaryAddress.patchValue({
          country: res['result'][0]['country'],
          city: res['result'][0]['province'],
          state: res['result'][0]['state'],

        })
      }
      if (Address == 'S') {
        this.postalLocationsSec = res['result']
        this.secondaryAddress.patchValue({
          country: res['result'][0]['country'],
          city: res['result'][0]['province'],
          state: res['result'][0]['state'],
        })
      }
    })
  }

  zip1;
  zip2;
  countryCodeSelected;
  countryCodeSelected2;
  percChanged(zip) {
    this.zip1 = zip;
    if (this.zip1?.length < 6) {
      this.countryCodeSelected = null;
      this.primaryAddress.patchValue({
        country: '',
        city: '',
        state: '',
        postalLocation: ''
      })
      this.postalLocations = [];
    }
  }

  perChanged2(zip) {
    if (zip.length == 6) {
      this.zip2 = zip;
      this.countryCodeSelected2 = null;
      this.secondaryAddress.patchValue({
        country: '',
        city: '',
        state: ''
      });
    }
  }

  countryCodeEntered(cc) {
    this.selectedValue = null;
    this.patientService.getPincodeDetails(this.zip1, cc).subscribe(res => {
      if (res['data'].length == 0) {
        this.primaryAddress.patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.ToastrService.error("Incorrect pincode")
      }
      this.postalLocations = res['data'];
      // this.latitude = res['result'][0]['latitude'];
      // this.longitude = res['result'][0]['longitude'];
      this.primaryAddress.patchValue({
        country: res['data'][0]['country'],
        city: res['data'][0]['province'],
        state: res['data'][0]['state'],

      })
    })
  }

  countryCodeEntered2(cc) {
    this.selected = null;
    this.patientService.getPincodeDetails(this.zip2, cc).subscribe(res => {
      if (res['data'].length == 0) {
        this.secondaryAddress.patchValue({
          country: '',
          city: '',
          state: '',
          postalLocation: ''
        })
        this.ToastrService.error("Incorrect pincode")
      }
      this.postalLocationsSec = res['data'];
      // this.latitude2 = res['result'][0]['latitude'];
      // this.longitude2 = res['result'][0]['longitude'];
      this.secondaryAddress.patchValue({
        country: res['data'][0]['country'],
        city: res['data'][0]['province'],
        state: res['data'][0]['state'],

      });
    })
  }

  drpdownSelection(e) {
    this.checkForm(this.primaryAddress);
    var selectedItem = this.postalLocations.filter(item => item.postalLocation == e)
    this.latitude=selectedItem[0]?.latitude
    this.longitude=selectedItem[0]?.longitude
  }

  drpdownSelection2(e) {
    this.checkSecForm(this.secondaryAddress);
    var selectedItem = this.postalLocationsSec.filter(item => item.postalLocation == e)
    this.latitude2=selectedItem[0]?.latitude
    this.longitude2=selectedItem[0]?.longitude
  }

}


