import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DiagnosticServiceController } from 'src/app/shared/services/diagnostic.service';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import { MustMatch } from 'src/app/_helpers/must_match.validators';
import { interval, Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DiagnosticService } from 'src/app/AppServices/diagnostic.service';

@Component({
  selector: 'app-diagnostic-forgot-password',
  templateUrl: './diagnostic-forgot-password.component.html',
  styleUrls: ['./diagnostic-forgot-password.component.css']
})
export class DiagnosticForgotPasswordComponent implements OnInit {
  forgetPassword = false
  resetPassword: FormGroup
  submitted = false
  verificationId
  isResend = false
  isSend = true
  isGetOtp = false
  isResendOtp = false
  timerOn = true;
  subscription: Subscription;
  invalidCredentials: boolean = false
  successCredentials: boolean = false
  message
  subscribeTimer
  timeLeft: number = 120;
  uValid: boolean = true;
  eValid: boolean = true;
  showTimer: boolean = false;
  npValid: boolean = true;
  cpValid: boolean = true;
  bothVerifcation = false

  constructor(private globalStorage: GlobalStorageService, private fb: FormBuilder, private otpService: AuthService, private reset: DiagnosticServiceController, private route: Router,
    private toastr: ToastrService, private router: ActivatedRoute, private diagnosticService: DiagnosticService) {
    this.resetPassword = this.fb.group({
      userName: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email]],
      phoneNumber: [''],
      otp: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.required,
      Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{8,15})')]],
      confirmPassword: ['', [Validators.required]]
    },
      {
        validator: MustMatch('newPassword', 'confirmPassword')
      })
    this.router.queryParams.subscribe(params => {
      this.verificationId = params['verificationid'];

    });
  }
  isAssociateUser = false
  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  ngOnInit(): void {
    if (this.route.url == '/diagnosticForgot') {
      this.forgetPassword = true;
    } 
    if(this.route.url == '/verifyProfileDia'){
      this.forgetPassword = false;
    }
    if (this.route.url == '/pharmaCreatePassword') {
      this.isAssociateUser = true
    }
    var data = this.globalStorage.getItem('diagnosticData')
    var obj = JSON.parse(data)
  }
  get f() { return this.resetPassword.controls; }
  submit(item: any) {
    this.submitted = true;
    this.successCredentials = false
    this.invalidCredentials = false
    // stop here if form is invalid
    if (this.resetPassword.invalid) {
      return;
    }
    var data = this.globalStorage.getItem('diagnosticData')
    var obj = JSON.parse(data)

    var request = {
      // "diagnosticcenteruserId": obj['diagnosticCenterUserId'],
      "userName": item.value.userName,
      "emailId": item.value.emailId,
      "newPassword": item.value.newPassword,
      "otp": this.PhOTP,
      // "verificationid":obj.verificationId,
      //"uiHostURL":window.location.origin
    }
    this.reset.createNewPassword(request).subscribe(res => {
      this.toastr.success("Specified password has been saved successfully")
      this.route.navigate(['/DiagnosticLogin'])
    }, error => {
      this.successCredentials = false
      this.invalidCredentials = true
      this.message = error['error']['message']
      // this.toastr.error(error['error']['message'])
    })
  }
  isOtpSent; isResentOtp: boolean = false; emailVerificationDone = false
  isOtpValid = true
  otpCheck(otp) {
    this.successCredentials = false
    this.invalidCredentials = false
    this.verifyOTP()
    if (otp.length == 6) {
      // this.reset.emailVerify(this.resetPassword.value.emailId, otp).subscribe(res => {
      //   this.isOtpValid = true;
      //   this.showTimer = false;
      // }, error => {
      //   this.isOtpValid = false
      //   this.successCredentials = false
      //   this.invalidCredentials = true
      //   this.message = error['error']['message']
      // })
    }
    // else {
    //   this.invalidCredentials = false
    // }
  }
  verifyNowButton = false
  verifyOTP() {

    this.successCredentials = false
    this.invalidCredentials = false


    this.otpService.verifyEmailOtpForDiagnosticCenter(this.resetPassword.value.emailId, this.resetPassword.value.otp).subscribe(res => {
      this.toastr.success('Your Email ID is now verified.')
      this.isTimeOn1 = true
      this.showTimer = false;
      this.verifyNowEnable = false
      this.observableTimer2();
      this.emailVerificationDone = true;
      this.verifyNowButton = true;
    }, error => {
      this.invalidCredentials = true
      this.message = error.error.message
      this.toastr.error('The Entered OTP is incorrect')
    })
  }
  isTimeOn1 = true;
  observableTimer2() {
    this.isTimeOn1 = true
    this.isSend = false
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer2').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn1 = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.successCredentials = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        //this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  getPhoneOtp() {
    if (this.resetPassword.value.phoneNumber == "" || this.resetPassword.value.phoneNumber == undefined) {
      this.toastr.warning('Enter the mobile number to send verification OTP')
      return
    }
    this.otpService.resendPhoneOtpForDiagnosticCenter(this.resetPassword.value.phoneNumber.replace(/\+/gi, '%2B'), this.resetPassword.value.userName).subscribe(res => {
      this.invalidCredentials = false
      this.toastr.success('OTP sent successfully.')
      this.observableTimer2()
    }, error => {
      this.invalidCredentials = true
      //this.message=error.error.message
      //this.toaster.error(error['error']['message'])
    })

  }
  PhOTP
  verifyPhoneOTP() {
    if (this.PhOTP == undefined || this.resetPassword.value.phoneNumber == undefined) {
      return
    }

    if (this.PhOTP == "" || this.resetPassword.value.phoneNumber == "") {
      return
    }
    this.otpService.verifyPhoneOTPForDiagnosticCenter(this.cellNumber.replace(/\+/gi, '%2B'), this.PhOTP).subscribe(res => {
      this.toastr.success('Your phone number is now verified.')
      //this.route.navigate(['/DiagnosticLogin'])
      this.bothVerifcation = true
    }, error => {
      this.toastr.error('Your Phone number not verifed')
    })
  }

  verifyNowEnable = false; cellNumber
  getOtp() {
    this.invalidCredentials = false
    this.successCredentials = false
    if (this.resetPassword.value.userName != "" && this.resetPassword.value.emailId != "") {
      var req = {
        "emailId": this.resetPassword.value.emailId,
        "resetPasswordLink": window.location.origin
      }
      this.otpService.resendEmailOtpForDiagnosticCenter(this.resetPassword.value.emailId, this.resetPassword.value.userName).subscribe(res => {
        this.toastr.success('OTP sent successfully');
        this.successCredentials = true
        this.message = 'Check your email for OTP.'
        this.verifyNowEnable = true
        this.cellNumber = res['data']
        this.resetPassword.patchValue({
          phoneNumber: res['data']?.replace(/.(?=.{4})/g, 'x')
        })
        this.isOtpSent = true
        this.isResentOtp = true;
        this.uValid = true;
        this.eValid = true;
        this.isResend = false;
        this.showTimer = true;
        this.observableTimer()
      }, error => {
        this.invalidCredentials = true
        this.message = error?.['error']?.['message']
      })

    } else {
      this.invalidCredentials = true
      this.message = "Please enter userName and emailId"
    }
  }
  observableTimer() {
    if (this.resetPassword.value.emailId == "") {
      return
    }
    this.timeLeft = 120
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isSend = false
        this.isResend = true
        this.isResentOtp = false
        this.isGetOtp = false
        this.isResendOtp = false
        this.showTimer = false
        this.toastr.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }

  showHidePwd() {
    if ($('#show_hide_password input').attr("type") == "text") {
      $('#show_hide_password input').attr('type', 'password');
      $('#show_hide_password i').addClass("fa-eye-slash");
      $('#show_hide_password i').removeClass("fa-eye");
    } else if ($('#show_hide_password input').attr("type") == "password") {
      $('#show_hide_password input').attr('type', 'text');
      $('#show_hide_password i').removeClass("fa-eye-slash");
      $('#show_hide_password i').addClass("fa-eye");
    }
  }
  showHidePwd1() {
    if ($('#show_hide_password1  input').attr("type") == "text") {
      $('#show_hide_password1 input').attr('type', 'password');
      $('#show_hide_password1 i').addClass("fa-eye-slash");
      $('#show_hide_password1 i').removeClass("fa-eye");
    } else if ($('#show_hide_password1 input').attr("type") == "password") {
      $('#show_hide_password1 input').attr('type', 'text');
      $('#show_hide_password1 i').removeClass("fa-eye-slash");
      $('#show_hide_password1 i').addClass("fa-eye");
    }
  }
  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z\_]+$/);
    return pattern.test(value);
  }

  checkUserName() {

    this.invalidCredentials = false;
    this.uValid = false;
    if (this.f.userName.errors == null) {

      this.diagnosticService.existingUsername(this.resetPassword.controls['userName'].value).subscribe(res => {
        if (res['data'] == false) {
          this.invalidCredentials = true;
          this.message = res['message']
          this.submitted = false;
          return
        }
        if (res['data'] == true) { }
      }, (error) => {
        this.toastr.error((error['error']['message']))

      });
    }
  }
  otpEntered(data) {
    if (data?.length == 6) {
      this.verifyNowButton = false
    }
  }

  emailEntered() {
    if (this.resetPassword.controls['emailId'].status === "VALID")
      this.eValid = false;

    else
      this.eValid = true;

  }

  npEntered() {
    if (this.resetPassword.controls['newPassword'].status === "VALID") {
      this.npValid = false;
    }
    else {
      this.npValid = true;
    }
  }
  cpEntered() {
    if (this.resetPassword.controls['confirmPassword'].status === "VALID") {
      this.cpValid = false;
    }
    else {
      this.cpValid = true;
    }
  }
}

