<div class="modal-header">
    <h4 class="modal-title">Book Appointment</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div>
        <div>
            <form [formGroup]="bookingForm" class="mt-2" #myForm="ngForm">
                <div class="row">
                    <div class="col-md-6 form-group">
                        <label for="exampleInputEmail1">First Name</label>
                        <input type="text" formControlName="firstName" style="text-transform:capitalize" class="form-control mb-0" placeholder="Enter First Name"
                            maxlength="50" readonly />
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="exampleInputEmail1">Middle Name</label>
                        <input type="text" formControlName="middleName" style="text-transform:capitalize"
                            class="form-control mb-0" placeholder="Enter Middle Name" maxlength="50" readonly />
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="exampleInputEmail1">Last Name</label>
                        <input type="text" formControlName="lastName" style="text-transform:capitalize"
                            class="form-control mb-0" placeholder="Enter Last Name" maxlength="50" readonly />
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="exampleInputPassword1">Mobile Number</label>
                        <div class="telephone">
                            <input type="tel" id="demo" #phNum class="form-control" formControlName="contactNum"
                                placeholder="Enter mobile number" id="telephone" readonly />
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="row">
            <h4 class="card-title mt-2 col-md-12 mb-2 p-2 cardtitlenew bg-primary font-size-14"> Search </h4>
            <div class="col-md-11  form-group">
                <label for="exampleInputEmail1">Search By Doctor</label><span class="text-danger">*</span>
                <input type="text" class="form-control mb-0" (keyup)="doctorSearching()" [(ngModel)]="search1" #dctrname
                    placeholder="Enter Doctor Name">
            </div>
            <div class="col-md-1 form-group" style="align-self: end;">
                <button type="submit" class="btn btn-primary btn-sm" (click)="checkDctr(dctrname.value)"><i
                        class="fa fa-search"></i></button>
            </div>
        </div>


        <div class="row" >
            <table class="table table-bordered table-hover table-styled table-striped mt-2" role="grid"
                aria-describedby="example2_info">
                <thead>
                    <tr role="row">
                        <th><a class="sort-by" href="javascript:void(0)"  (click)="onSort('unpaid','firstName')">Name</a></th>
                        <th><a class="sort-by" href="javascript:void(0)"  (click)="onSort('unpaid','specialization')">Specialization</a></th>
                        <th>Fee </th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="doctorsList?.length == 0 || doctorsList==null">
                        <td colspan="4">
                            <img src="./assets/img/noo-doctors.jpg">
                        </td>
                    </tr>
                    <tr class="odd" data-id="9"
                        *ngFor="let user of doctorsList|search : search1|paginate: { id: 'dNoo', itemsPerPage: 10, currentPage: dNoo }">
                        <td>{{user?.firstName}} {{user?.lastName}}</td>
                        <td>{{user?.specialization}}</td>
                        <td>
                            <!-- <div *ngFor="let fees of user?.hospitalfeedetails">{{fees?.fee==null?0:fees?.fee}}</div> -->
                            {{user?.fee==null?0:user?.fee}}
                        </td>
                        <td>
                            <button type="button" class="btn btn-success btn-xs" title="Book appointment"
                                (click)="bookDoctor(user)">Book now</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="row" *ngIf="doctorsList?.length>15">
                <div class="col-lg-12 mt-3 text_right ">
                    <ul class="pagination">
                        <pagination-controls autoHide="true" id="dNoo" (pageChange)="dNoo=$event"></pagination-controls>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

