import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';
import { SaveSupplierComponent } from '../save-supplier/save-supplier.component';
import { CreateOrdersComponent } from '../../inventory/create-orders/create-orders.component';

@Component({
  selector: 'app-view-supplier-info',
  templateUrl: './view-supplier-info.component.html',
  styleUrls: ['./view-supplier-info.component.css']
})
export class ViewSupplierInfoComponent implements OnInit {

  @Input() supplierInfo: any;
  @Input() addressId: any;
  @Input() service: any;
  p1 = 1;
  constructor(private globalStorage: GlobalStorageService, private activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private inventoryService: InventoryService,
    private modalService : NgbModal) { }

  ngOnInit() {
    this.fetchSuppliercatgeries();
  }

  closeModal() {
    this.activeModal.close(true);
  }

  categoriesData;
  fetchSuppliercatgeries(){
    this.inventoryService.getSuppliercatgeryBySupplierId(this.supplierInfo?.supplierId).subscribe(res => {
      this.categoriesData = res?.data || [];
      this.p1 = 1;
    },
    error => {
      this.toaster.error(error?.['error']?.['message']);
    }
    )
  }

  openSaveSupplier(object){
    // console.log('inv obj:',object);
    let wClass = 'left';
    if(this.globalStorage.getItem("loggedInAs")=='IVF'){
      wClass='right hw-40';
    }
    if(this.globalStorage.getItem("loggedInAs")=='HOSPITAL'){
      wClass='modal-left hw-40';
    }
    const modalRef = this.modalService.open(SaveSupplierComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.componentInstance.assignTime = true;
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.supplier = this.supplierInfo;
    modalRef.componentInstance.supplierCategory = object;
    modalRef.componentInstance.service = this.service;
  }

  openPlaceOrder(data){
    let wClass = 'left';
    if(this.globalStorage.getItem("loggedInAs")=='IVF'){
      wClass='right hw-40';
    }
    if(this.globalStorage.getItem("loggedInAs")=='HOSPITAL'){
      wClass='modal-left hw-40';
    }
    const modalRef = this.modalService.open(CreateOrdersComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.componentInstance.assignAssociatedItems = true;
    modalRef.componentInstance.associatedItems = data;
    modalRef.componentInstance.addressId = this.addressId;
  }

  activateSubCategory(data) {
    let suppliercatgeryId = data?.suppliercatgeryId;
    this.inventoryService.ActiveAssignSupplier(this.addressId, suppliercatgeryId).subscribe(res => {
      this.toaster.success('Supplier Details Activated.')
      this.fetchSuppliercatgeries();
    }, error => {
      this.toaster.error(error?.['error']?.['message'])
    }
    )
  }

  deactivateSubCatagory(data) {
    let suppliercatgeryId = data?.suppliercatgeryId;
    this.inventoryService.deleteAssignSupplier(this.addressId, suppliercatgeryId).subscribe(res => {
      this.toaster.success('Supplier Details Deactivated.');
      this.fetchSuppliercatgeries();
    }, error => {
      this.toaster.error(error?.['error']?.['message'])
    }
    )
  }

}
