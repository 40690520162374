<div class="modal-header">
    <h5 class="modal-title">Add Vitals</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="userVitalsForm">
        <div class="row">
            <div class="col-md-6 col-xs-6 form-group">
                <label>Date & Time <span class="text-danger"></span></label>
                <input type="text" class="form-control mb-0" formControlName="date" readonly />
            </div>
            <div class="col-md-6 col-xs-6 form-group">
                <label>Blood group <span class="text-danger"></span></label>
                <input type="text" class="form-control mb-0" placeholder="Blood Group" formControlName="bloodgroup" readonly >
            </div>
            <div class="col-md-6 col-xs-6 form-group">
                <label>Weight <span class="text-secondary p-1">(Kg)</span></label>
                <input type="number" placeholder="Weight" class="form-control mb-0" formControlName="weight" maxlength="6"
                [ngClass]="{ 'is-invalid': (vitalFormControls.weight.touched) && vitalFormControls.weight.errors}" />
                <div
                  *ngIf="(vitalFormControls.weight.touched) && vitalFormControls.weight.errors"
                  class="invalid-feedback">
                  <div *ngIf="vitalFormControls.weight.errors.required">Value should be between 10-750</div>
                  <div *ngIf="vitalFormControls.weight.errors.min">Value should not be less than 10</div>
                  <div *ngIf="vitalFormControls.weight.errors.max">Value should not be more than 750</div>

                  <div *ngIf="wtlessMsg" class="text-warning">
                    <div class="small">Normal weight range {{weightLevel}}</div>
                  </div>
                  <div *ngIf="wtExtraMsg" class="text-warning">
                    <div class="small">Normal weight range {{weightLevel}}</div>
                  </div>
                </div>
            </div>
            <div class="col-md-6 col-xs-6 form-group">
                <label>Height <span class="text-secondary p-1">(Feet&Inches)</span></label>
                <div class="input-group">
                    <div class="col-md-6 col-xs-6">
                        <mat-select placeholder="Feet" class="form-control" formControlName="feet"
                        [ngClass]="{ 'is-invalid': ( vitalFormControls.feet.touched) && vitalFormControls.feet.errors}">
                            <mat-option *ngFor="let item of feets" [value]="item">
                                {{item}}
                              </mat-option>
                        </mat-select>

                        <div *ngIf="(vitalFormControls.feet.touched) && vitalFormControls.feet.errors" class="invalid-feedback">
                            <div *ngIf="vitalFormControls.feet.errors.required">Please specify Height in Feet between 1 to 10</div>
                        </div>
                    </div>
                    <div class="col-md-6 col-xs-6">
                        <mat-select placeholder="Inch" class="form-control" formControlName="inch"
                        [ngClass]="{ 'is-invalid': ( vitalFormControls.inch.touched) && vitalFormControls.inch.errors}">
                            <mat-option *ngFor="let item of inchs" [value]="item">
                                {{item}}
                              </mat-option>
                        </mat-select>

                        <div *ngIf="( vitalFormControls.inch.touched) && vitalFormControls.inch.errors" class="invalid-feedback">
                            <div *ngIf="vitalFormControls.inch.errors.required">Please specify Height in Inches between 0 to 11</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xs-6 form-group">
                <span class="badge badge-pill badge-success float-right">%</span>
                <label>Oxygen Level <span class="text-danger"></span></label>
                <input type="text" maxlength="3" placeholder="Oxygen Levels" class="form-control mb-0" formControlName="oxygenLevel"
                [ngClass]="{ 'is-invalid': ( vitalFormControls.oxygenLevel.touched) && vitalFormControls.oxygenLevel.errors}" />
                <div *ngIf="( vitalFormControls.oxygenLevel.touched) && vitalFormControls.oxygenLevel.errors" class="invalid-feedback">
                    <div *ngIf="vitalFormControls.oxygenLevel.errors.required">Value should be between 90-100</div>
                    <div *ngIf="vitalFormControls.oxygenLevel.errors.min">Value should not be less than 90</div>
                    <div *ngIf="vitalFormControls.oxygenLevel.errors.max">Value should not be more than 100</div>
                </div>
            </div>
            <div class="col-md-6 col-xs-6 form-group">
                <label>Temp <span class="text-secondary p-1">(F)</span></label>
                <input type="text" maxlength="5" placeholder="Temperature" class="form-control mb-0" formControlName="temperature"
                [ngClass]="{ 'is-invalid': ( vitalFormControls.temperature.touched) && vitalFormControls.temperature.errors}" />
                <div *ngIf="(vitalFormControls.temperature.touched) && vitalFormControls.temperature.errors" class="invalid-feedback">
                    <div *ngIf="vitalFormControls.temperature.errors.required">Value should be between 95-105</div>
                    <div *ngIf="vitalFormControls.temperature.errors.min">Value should not be less than 95</div>
                    <div *ngIf="vitalFormControls.temperature.errors.max">Value should not be more than 105</div>
                </div>
            </div>
            <div class="col-md-6 col-xs-6 form-group">
                <span class="badge badge-pill badge-warning float-right">BPM</span>
                <label>Heart Rate <span class="text-danger"></span></label>
                <input type="text" maxlength="3" placeholder="Pulse" class="form-control mb-0" formControlName="pulse"
                [ngClass]="{ 'is-invalid': ( vitalFormControls.pulse.touched) && vitalFormControls.pulse.errors}" />
                <div *ngIf="( vitalFormControls.pulse.touched) && vitalFormControls.pulse.errors" class="invalid-feedback">
                    <div *ngIf="vitalFormControls.pulse.errors.required">Value should be between 40-220</div>
                    <div *ngIf="vitalFormControls.pulse.errors.min">Value should not be less than 40</div>
                    <div *ngIf="vitalFormControls.pulse.errors.max">Value should not be more than 220</div>
                </div>
            </div>
            <div class="col-md-6 col-xs-6 form-group">
                <span class="badge badge-pill badge-info float-right">MM/HG</span>
                <label>Blood Pressure</label>
                <div class="input-group">
                    <div class="col-md-5 col-xs-5 form-group p-0 m-0">
                        <input type="text" class="form-control" maxlength="3" placeholder="Systolic" formControlName="systolic"
                        [ngClass]="{ 'is-invalid': (submitted || vitalFormControls.systolic.touched) && vitalFormControls.systolic.errors}" />
                        <div *ngIf="(submitted || vitalFormControls.systolic.touched) && vitalFormControls.systolic.errors" class="invalid-feedback">
                            <div *ngIf="vitalFormControls.systolic.errors.required">Value should be between 80-200</div>
                            <div *ngIf="vitalFormControls.systolic.errors.min">Value should not be less than 80</div>
                            <div *ngIf="vitalFormControls.systolic.errors.max">Value should not be more than 200</div>
                        </div>
                    </div>
                    <div class="col-md-2 col-xs-2 input-group-addon p-0 m-0">
                        <p class="slash">/</p>
                    </div>
                    <div class="col-md-5 col-xs-5 form-group p-0 m-0">
                        <input type="text" class="form-control" maxlength="3" placeholder="Diastolic" formControlName="diastolic"
                        [ngClass]="{ 'is-invalid': (vitalFormControls.diastolic.touched) && vitalFormControls.diastolic.errors}" />
                        <div *ngIf="( vitalFormControls.diastolic.touched) && vitalFormControls.diastolic.errors" class="invalid-feedback">
                            <div *ngIf="vitalFormControls.diastolic.errors.required">Value should be between 50-140</div>
                            <div *ngIf="vitalFormControls.diastolic.errors.min">Value should not be less than 50</div>
                            <div *ngIf="vitalFormControls.diastolic.errors.max">Value should not be more than 140</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-xs-6 form-group">
                <span class="badge badge-pill badge-info float-right">mg/dL</span>
                <label>Blood Glucose <span class="text-danger"></span></label>
                <div class="input-group">
                    <div class="col-md-5 col-xs-5 form-group p-0 m-0">
                        <input type="text" class="form-control" placeholder="Fasting" formControlName="fasting" minlength="2" maxlength="3"
                        [ngClass]="{ 'is-invalid': (vitalFormControls.fasting.touched) && vitalFormControls.fasting.errors}" />
                        <div *ngIf="( vitalFormControls.fasting.touched) && vitalFormControls.fasting.errors" class="invalid-feedback">
                            <div *ngIf="vitalFormControls.fasting.errors.required">Value before fasting should be above 80-140</div>
                            <div *ngIf="vitalFormControls.fasting.errors.min">Value should not be less than 80</div>
                            <div *ngIf="vitalFormControls.fasting.errors.max">Value should not be more than 140</div>
                        </div>
                    </div>
                    <div class="col-md-2 col-xs-2 input-group-addon p-0 m-0">
                        <p class="slash">|</p>
                    </div>
                    <div class="col-md-5 col-xs-5 form-group p-0 m-0">
                        <input type="text" class="form-control" placeholder="Random" formControlName="random" minlength="2" maxlength="3"
                        [ngClass]="{ 'is-invalid': (vitalFormControls.random.touched) && vitalFormControls.random.errors}" />
                        <div *ngIf="( vitalFormControls.random.touched) && vitalFormControls.random.errors" class="invalid-feedback">
                            <div *ngIf="vitalFormControls.random.errors.required">Value after fasting should be above 80-220</div>
                            <div *ngIf="vitalFormControls.random.errors.min">Value should not be less than 80</div>
                            <div *ngIf="vitalFormControls.random.errors.max">Value should not be more than 220</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button type="button" class="btn btn-sm btn-primary float-right" (click)="updateVitals(userVitalsForm)"> Submit </button>
    </form>

</div>
