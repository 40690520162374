<div class="modal-header">
    <h4 class="modal-title">Cart Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <!-- <div class="">
        <table class="table table-bordered table-hover table-styled table-striped mt-2">
          <thead>
            <tr>
              <th>Pharmacy Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of cartDetails">
              <td>{{item?.pharmacyname}}</td>
              <td>
                <button  class="btn btn-success btn-xs" href="javascript:void(0)" data-placement="top"
                  data-toggle="tooltip" data-original-title="View" (click)="viewCart(item)"><i
                    class="fa fa-user-check fs-10"></i></button>
                <button  class="btn btn-danger btn-xs ml-2" data-placement="top" data-toggle="tooltip"
                  data-original-title="Delete" (click)="deleteCartItem(item)"><i class="fa fa-trash-o"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="cartDetails?.length==0 || cartDetails == null">
          <div class="no-data-found"></div>
        </div>
      </div> -->
      <div class="phrm">
        <div class="srtipdiv">
        <h3>Pharmacy Details</h3>
        </div>
            <table class="table table-striped">
                <tbody>
                  <tr>
                    <th>Pharmacy Name</th>
                    <th>Action</th>
                  </tr>
                  <tr *ngFor="let item of cartDetails">
                    <td><img src="./../../../../assets/img/information.png"><p class="description pt-2 fw-bold">{{item?.pharmacyname}}</p></td>
                    <td> 
                      <span><i class="fas fa-eye pr-2" (click)="viewCart(item)"></i></span>
                      <span><i class="fas fa-trash" (click)="deleteCartItem(item)"></i></span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div *ngIf="cartDetails?.length==0 || cartDetails == null">
                <div class="no-data-found"></div>
              </div>
            </div> 
</div>
