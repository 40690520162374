<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="keywords" content="HTML5 Template" />
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
  <title>Curemetric</title>
  <!-- <link rel="dns-prefetch" href="https://fonts.gstatic.com/" > -->
  <!-- favicon icon -->
  <link rel="shortcut icon" href="./../../../assets/landing/images/favicon.png" />
  <!-- owl-carousel -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/owl.carousel.css">
  <!-- shortcodes -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/base.css" />
  <!-- main -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/core.css" />
  <!-- responsive -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/helper.css" />
  <link rel="stylesheet" type="text/css"  href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/css/intlTelInput.css" />
  <style>
    body,
    p,
    span,
    input,
    button,
    a {
      font-family: 'Poppins', sans-serif !important;
      font-size: 13px !important
    }

    h1,
    h2,
    h3,
    h4 {
      font-family: 'Poppins', sans-serif !important;
    }

    .sign-info {
      border-top: 1px solid #cdd1f3;
      margin-top: 10px;
      padding-top: 20px;
      margin-bottom: 30px
    }

    .iq-social-media {
      margin: 0;
      padding: 0;
      float: right;
    }

    .iq-social-media li {
      list-style: none;
      float: left;
      margin-right: 10px;
    }

    .iq-social-media li:last-child {
      margin-right: 0;
    }

    .iq-social-media li a {
      height: 30px;
      width: 30px;
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      display: inline-block;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
      background: #eff7f8;
      color: #40648c !important;
    }

    .iq-social-media li a:hover {
      text-decoration: none;
    }

    .form-control {
      border-top-width: 0px !important;
      border-left-width: 0px !important;
      border-right-width: 0px !important;
      border-color: #424242 !important;
      border-bottom-width: 1px !important;
      height: calc(2rem + 1px) !important;
      background: transparent !important;
      padding: 9px 0px !important;
    }

    .bg-login {
      background-repeat: no-repeat;
      background-image: url(./assets/landing/images/vector_page/bg-login.jpg);
      background-size: cover;
      background-position: top center;
      width: 100%;
      height: 100%;
      opacity: 1;
      visibility: inherit;
      z-index: 20;
    }

    .signin {
      padding: 0px 0px 0px 0px !important;
      margin-top: 120px;
      max-width: 450px;
      font-family: 'Poppins', sans-serif;
      margin-left: 18px !important;
    }

    .signin h1 {
      font-size: 26px
    }

    .rightimg img {
      height: 450px;
      margin-top: 100px
    }
  </style>
</head>

<body class="ttm-one-page-site">
  <!--page start-->
  <div class="page" id="ttm-home">
    <!-- preloader start -->
    <!-- preloader end -->
    <!--header start-->
    <app-main-header></app-main-header>
    <!--header end-->
    <div class="container-fulid">
      <div class="bg-login">
        <div class="container ">
          <div class="row">
            <div class="signin col-lg-5  col-md-8 ml-4">
              <h1 class="mb-0">Register as User</h1>
              <p>Enter the details to register</p>
              <div class="error" *ngIf="isUserNameExists">
                {{isUserNameExistsMessage}}
              </div>
              <div class="error" *ngIf="isEmailExists">
                {{isEmailExistsMessage}}
              </div>
              <div class="error" *ngIf="isPhoneNumberExists">
                {{isPhoneNumberExistsMessage}}
              </div>
              <div class="error" *ngIf="invalidCredentials">
                {{message}}
              </div>
              <div class="text-success" *ngIf="successCredentials">
                {{message}}
              </div>
              <form [formGroup]="registerForm" class="mt-4" #myForm="ngForm">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <label for="exampleInputPassword1">Username <span class="text-danger">*</span></label>
                    <input autocomplete="new-password" type="text" formControlName="userName" class="form-control mb-0" (focusout)="checkUserName()"
                      (keypress)="alphaNumarics($event)" [ngClass]="{
                        'is-invalid':
                          (submitted || f.userName.touched) && f.userName.errors
                      }" placeholder="Enter Username" />
                    <div *ngIf="(submitted || f.userName.touched) && f.userName.errors" class="invalid-feedback">
                      <div *ngIf="isExist">This Username already exists</div>
                      <div *ngIf="f.userName.errors.required"> Username is required </div>
                      <div *ngIf="f.userName.errors.pattern"> Username should be alphabets and numbers. </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="exampleInputPassword1">Email ID <span class="text-danger">*</span></label>
                    <input type="text" (keydown.space)="$event.preventDefault()" autocomplete="new-password" formControlName="email" id="directLink"
                      class="form-control mb-0" (focusout)="checkEmail()" [ngClass]="{
                        'is-invalid':
                          (submitted || f.email.touched) && f.email.errors
                      }" placeholder="Enter Email ID" />
                    <div *ngIf="(submitted || f.email.touched) && f.email.errors" class="invalid-feedback">
                      <div *ngIf="isExist">This email already exists</div>
                      <div *ngIf="f.email.errors.required"> email is required </div>
                      <div *ngIf="f.email.errors.email"> email should be valid </div>
                      <div *ngIf="f.email.errors.email || f.email.errors.pattern"> email should be valid </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="exampleInputEmail1">First Name <span class="text-danger">*</span></label>
                    <input type="text" formControlName="firstName" maxlength="50" style="text-transform:capitalize"
                      (keypress)="alphaOnly($event)" class="form-control mb-0" [ngClass]="{
                        'is-invalid':
                          (submitted || f.firstName.touched) &&
                          f.firstName.errors
                      }" placeholder="Enter First Name" />
                    <div *ngIf="
                        (submitted || f.firstName.touched) && f.firstName.errors
                      " class="invalid-feedback">
                      <div *ngIf="f.firstName.errors.required"> First Name is required </div>
                      <div *ngIf="f.firstName.errors.pattern"> only 1 space and Letters are allowed </div>
                      <div *ngIf="f.firstName.errors.minlength"> Name should be minimum 3 characters Long </div>
                      <div *ngIf="f.firstName.errors.maxlength"> Name should not be 50 characters long </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="exampleInputEmail1">Middle Name</label>
                    <input type="text" formControlName="middleName" maxlength="50" style="text-transform:capitalize"
                      (keypress)="alphaOnly($event)" class="form-control mb-0" placeholder="Enter Middle Name" />
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="exampleInputEmail1">Last Name <span class="text-danger">*</span></label>
                    <input type="text" formControlName="lastName" style="text-transform:capitalize" maxlength="50"
                      (keypress)="alphaOnly($event)" class="form-control mb-0" [ngClass]="{
                        'is-invalid':
                          (submitted || f.lastName.touched) && f.lastName.errors
                      }" placeholder="Enter Last Name" />
                    <div *ngIf="
                        (submitted || f.lastName.touched) && f.lastName.errors
                      " class="invalid-feedback">
                      <div *ngIf="f.lastName.errors.required"> Last Name is required </div>
                      <div *ngIf="f.lastName.errors.pattern"> only 1 space and Letters are allowed </div>
                      <div *ngIf="f.lastName.errors.minlength"> Name should be minimum 3 characters Long </div>
                      <div *ngIf="f.lastName.errors.maxlength"> Name should not be 50 characters long </div>
                      <div *ngIf="f.lastName.errors.noMatch"> Last Name should not match First Name </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="exampleInputPassword1">Mobile Number <span class="text-danger">*</span></label>
                    <div class="telephone">
                      <input autocomplete="new-password" type="tel" id="demo" style="margin-left: 35%;" #phNum
                        (focusout)="checkPhoneNumber(phNum.value)" class="form-control" formControlName="contactNum"
                        (keypress)="numberOnly($event,phNum)" maxlength="10" placeholder="Enter mobile number"
                        id="telephone" [ngClass]="{
                            'is-invalid':
                              (submitted || f.contactNum.touched) &&
                              f.contactNum.errors
                          }" (focusout)="isItValid()" />
                      <div *ngIf="
                            (submitted || f.contactNum.touched) &&
                            f.contactNum.errors
                          " class="invalid-feedback position-absolute mt-4">
                        <div *ngIf="f.contactNum.errors.required"> Phone Number is required </div>
                        <div *ngIf="f.contactNum.errors.pattern"> Should be a valid phone number </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="exampleInputPassword1">Password <span class="text-danger">*</span></label>
                    <div class="input-group" id="show_hide_password">
                      <input type="password" (paste)="(false)" (keydown.space)="$event.preventDefault()" autocomplete="new-password" (copy)="(false)"
                      (keydown.space)="$event.preventDefault()"
                        formControlName="password" class="form-control mb-0" [ngClass]="{
                            'is-invalid':
                              (submitted || f.password.touched) && f.password.errors
                          }" id="exampleInputPassword1" placeholder="Enter Password" />
                      <div class="input-group-addon">
                        <a href="javascript:void(0)"><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                      </div>
                      <div *ngIf="
                            (submitted || f.password.touched) && f.password.errors
                          " class="invalid-feedback">
                        <div *ngIf="f.password.errors.required"> Password is required </div>
                        <div *ngIf="f.password.errors.NoSameFirstNamePassword"> No firstName should be included in
                          password </div>
                        <div *ngIf="f.password.errors.NoSameLastNamePassword"> No last should be included in password
                        </div>
                        <div *ngIf="f.password.errors.pattern"> Password must be at least 8 characters long,Password
                          must contain 1 Number,1 LowerCase,1 UpperCase,1 special Character,no spaces allowed </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="exampleInputPassword1">Confirm Password <span class="text-danger">*</span></label>
                    <div class="input-group" id="show_hide_password1">
                      <input type="password" (paste)="(false)" autocomplete="new-password" (copy)="(false)" formControlName="confirmPassword"
                        class="form-control mb-0"
                        (keydown.space)="$event.preventDefault()"
                        [ngClass]="{
                            'is-invalid':
                              (submitted || f.confirmPassword.touched) &&
                              f.confirmPassword.errors
                          }" id="exampleInputPassword1" placeholder="Enter Password" />
                      <div class="input-group-addon">
                        <a href="javascript:void(0)"><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                      </div>
                      <div *ngIf="
                            (submitted || f.confirmPassword.touched) &&
                            f.confirmPassword.errors
                          " class="invalid-feedback">
                        <div *ngIf="f.confirmPassword.errors.required"> Confirm Password is required </div>
                        <div *ngIf="f.confirmPassword.errors.mustMatch"> Passwords must match </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-inline-block w-100">
                  <button type="submit" class="btn btn-primary float-right effect effect-5"
                    [disabled]="!registerForm.valid || isEmailExists || isPhoneNumberExists || isUserNameExists" (click)="onSubmit(registerForm)"> Register Now </button>
                </div>
                <div class="sign-info">
                  <span class="dark-color d-inline-block line-height-2">Already have an account? <a
                      [routerLink]="['/patientLogin']" style="color:green;text-decoration:underline">Login</a></span>
                  <ul class="iq-social-media">
                    <li><a href="https://www.facebook.com/Curemetric" target="_blank"><i
                          class="fa fa-facebook  m-r-5"></i></a></li>
                    <li><a target="_blank" href="https://twitter.com/Cure_Metric"><i
                          class="fa fa-twitter m-r-5"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/curemetric" target="_blank"><i
                          class="fa fa-linkedin m-r-5"></i></a></li>
                  </ul>
                </div>
              </form>
            </div>
            <div class=" rightimg col-md-6">
              <img src="./assets/landing/images/p-register.png" />
            </div>
          </div>
        </div>
      </div>
    </div><!-- page end -->
    <!-- Footer -->
    <app-main-footer></app-main-footer>
    <!-- Footer Ends -->
