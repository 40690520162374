<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="keywords" content="HTML5 Template" />
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
  <title>Curemetric</title>
  <link rel="dns-prefetch" href="https://fonts.gstatic.com/"><!-- favicon icon -->
  <link rel="shortcut icon" href="./../../../assets/landing/images/favicon.png" />
  <!-- owl-carousel -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/owl.carousel.css">
  <!-- shortcodes -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/base.css" />
  <!-- main -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/core.css" />
  <!-- responsive -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/helper.css" />
  <link rel="stylesheet" type="text/css"  href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/css/intlTelInput.css" />
  <style>
    body,
    p,
    span,
    input,
    button,
    a {
      font-family: 'Poppins', sans-serif !important;
      font-size: 13px !important
    }

    h1,
    h2,
    h3,
    h4 {
      font-family: 'Poppins', sans-serif !important;
    }

    .sign-info {
      border-top: 1px solid #cdd1f3;
      margin-top: 10px;
      padding-top: 20px;
      margin-bottom: 30px
    }

    .iq-social-media {
      margin: 0;
      padding: 0;
      float: right;
    }

    .iq-social-media li {
      list-style: none;
      float: left;
      margin-right: 10px;
    }

    .iq-social-media li:last-child {
      margin-right: 0;
    }

    .iq-social-media li a {
      height: 30px;
      width: 30px;
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      display: inline-block;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
      background: #eff7f8;
      color: #40648c !important;
    }

    .iq-social-media li a:hover {
      text-decoration: none;
    }

    .form-control {
      border-top-width: 0px !important;
      border-left-width: 0px !important;
      border-right-width: 0px !important;
      border-color: #424242 !important;
      border-bottom-width: 1px !important;
      height: calc(2rem + 1px) !important;
      background: transparent !important;
      padding: 9px 0px !important;
    }

    .bg-login {
      background-repeat: no-repeat;
      background-image: url(./assets/landing/images/vector_page/bg-login.jpg);
      background-size: cover;
      background-position: top center;
      width: 100%;
      height: 100%;
      opacity: 1;
      visibility: inherit;
      z-index: 20;
    }

    .signin {
      padding: 0px 0px 0px 0px !important;
      margin-top: 120px;
      max-width: 450px;
      font-family: 'Poppins', sans-serif;
      margin-left: 18px !important;
    }

    .signin h1 {
      font-size: 26px
    }

    .rightimg img {
      height: 450px;
      margin-top: 100px
    }
  </style>
</head>

<body class="ttm-one-page-site">
  <!--page start-->
  <div class="page" id="ttm-home">
    <!-- preloader start -->
    <!-- preloader end -->
    <!--header start-->
    <app-main-header></app-main-header>
    <!--header end-->
    <div class="container-fulid">
      <div class="bg-login">
        <div class="container ">
          <div class="row">
            <div class="signin  col-md-8 col-lg-6 ml-4 ">
              <h1 class="mb-0">Register as Caretaker</h1>
              <p>Enter the Details to register</p>
              <div class="error" *ngIf="isUserNameExists">
                {{isUserNameExistsMessage}}
              </div>
              <div class="error" *ngIf="isEmailExists">
                {{isEmailExistsMessage}}
              </div>
              <div class="error" *ngIf="isPhoneNumberExists">
                {{isPhoneNumberExistsMessage}}
              </div>
              <div class="error" *ngIf="invalidCredentials">
                {{message}}
              </div>
              <div class="text-success" *ngIf="successCredentials">
                {{message}}
              </div>
              <form class="mt-4" [formGroup]="doctorForm" autocomplete="off">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <label for="exampleInputPassword1">Username <span class="text-danger">*</span></label>
                    <input type="text" formControlName="userName" class="form-control mb-0" (focusout)="checkUserName()"
                      (keypress)="alphaNumarics($event)" [ngClass]="{
                        'is-invalid':
                          (submitted || f.userName.touched) && f.userName.errors
                      }" placeholder="Enter Username" />
                    <div *ngIf="(submitted || f.userName.touched) && f.userName.errors" class="invalid-feedback">
                      <div *ngIf="isExist">This Username already exists</div>
                      <div *ngIf="f.userName.errors.required"> Username is required </div>
                      <div *ngIf="f.userName.errors.pattern"> Username should be alphabets and numbers. </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1"> Email ID <span style="color:red">*</span></label>
                      <input type="text" autocomplete="nope" class="form-control mb-0" placeholder="Enter Email ID"
                        #mail formControlName="emailId" (keyup)="checkEmail()"
                        [ngClass]="{ 'is-invalid': (submitted || f.emailId.touched) && f.emailId.errors}">
                      <div *ngIf="(submitted || f.emailId.touched) && f.emailId.errors" class="invalid-feedback">
                        <div *ngIf="f.emailId.errors.required">Email is required</div>
                        <div *ngIf="f.emailId.errors.pattern">Invalid Email</div>
                        <div *ngIf="f.emailId.errors.email">Email must be a valid email address </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">First Name <span style="color:red">*</span></label>
                      <input type="text" class="form-control" style="text-transform:capitalize" maxlength="50"
                        (keypress)="alphaOnly($event)" placeholder="Enter First Name" formControlName="firstName"
                        [ngClass]="{ 'is-invalid': (submitted || f.firstName.touched) && f.firstName.errors}">
                      <div *ngIf="(submitted || f.firstName.touched) && f.firstName.errors" class="invalid-feedback">
                        <div *ngIf="f.firstName.errors.required">First Name is required</div>
                        <div *ngIf="f.firstName.errors.pattern"> only 1 space and Letters are allowed </div>
                        <div *ngIf="f.firstName.errors.minlength"> Name should be minimum 3 characters Long </div>
                        <div *ngIf="f.firstName.errors.maxlength"> Name should not be 50 characters long </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Middle Name </label>
                      <input type="text" class="form-control" style="text-transform:capitalize" maxlength="50"
                        (keypress)="alphaOnly($event)" placeholder="Enter Middle Name" formControlName="middleName"
                        [ngClass]="{ 'is-invalid': (submitted || f.middleName.touched) && f.middleName.errors}">
                      <div *ngIf="(submitted || f.middleName.touched)  && f.middleName.errors" class="invalid-feedback">
                        <div *ngIf="f.middleName.errors.required">Middle Name is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Last Name <span style="color:red">*</span></label>
                      <input type="text" class="form-control" style="text-transform:capitalize" maxlength="50"
                        (keypress)="alphaOnly($event)" placeholder="Enter Last Name" formControlName="lastName"
                        [ngClass]="{ 'is-invalid': (submitted || f.lastName.touched) && f.lastName.errors}">
                      <div *ngIf="(submitted || f.lastName.touched) && f.lastName.errors" class="invalid-feedback">
                        <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                        <div *ngIf="f.lastName.errors.pattern"> only 1 space and Letters are allowed </div>
                        <div *ngIf="f.lastName.errors.minlength"> Name should be minimum 3 characters Long </div>
                        <div *ngIf="f.lastName.errors.maxlength"> Name should not be 50 characters long </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group ">
                      <label for="exampleInputEmail1">Services <span style="color:red">*</span></label>
                      <mat-select class="form-control" formControlName="services" placeholder="Select Services" multiple
                        [ngClass]="{'is-invalid': (submitted || f.services.touched) && f.services.errors}">
                        <input [(ngModel)]="search1" matInput placeholder="Select Services" formControlName="services"
                          class="form-control pl-20">
                        <mat-option *ngFor="let item of services| search : search1" [value]="item.lookupValue">
                          {{item.lookupValue}}
                        </mat-option>
                      </mat-select>
                      <div *ngIf="(submitted || f.services.touched) && f.services.errors" class="invalid-feedback">
                        <div *ngIf="f.services.errors.required"> Services is required </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Password <span style="color:red">*</span></label>
                      <div class="input-group" id="show_hide_password">
                        <input type="password" autocomplete="nope" (paste)="(false)" (copy)="(false)"
                          class="form-control mb-0" placeholder="Enter Password" formControlName="password" (keydown.space)="$event.preventDefault()"
                          [ngClass]="{ 'is-invalid': (submitted || f.password.touched) && f.password.errors}">
                        <div class="input-group-addon">
                          <a href="javascript:void(0)"><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                        </div>
                        <div *ngIf="(submitted || f.password.touched) && f.password.errors" class="invalid-feedback">
                          <div *ngIf="f.password.errors.required">Password is required</div>
                          <div *ngIf="f.password.errors.NoSameFirstNamePassword"> No firstName should be included in
                            password </div>
                          <div *ngIf="f.password.errors.NoSameLastNamePassword"> No last should be included in password
                          </div>
                          <div *ngIf="f.password.errors.pattern"> Password must be at least 8 characters long,Password
                            must contain 1 Number,1 LowerCase,1 UpperCase,1 special Character,no spaces allowed</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Confirm password<span style="color:red">*</span></label>
                                            <div class="input-group" id="show_hide_password1">
                                            <input type="password" class="form-control mb-0" placeholder="Confirm password" formControlName="confirmpassword"  [ngClass]="{ 'is-invalid': (submitted || f.confirmpassword.touched) && f.confirmpassword.errors}" required>
                                            <div class="input-group-addon">
                                                <a href="javascript:void(0)"><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                                              </div>
                                            <div *ngIf="(submitted || f.confirmpassword.touched) && f.confirmpassword.errors" class="invalid-feedback">
                                                <div *ngIf="f.confirmpassword.errors.required">Confirm password is required</div>
                                                <div *ngIf="f.confirmpassword.errors.mustMatch">Passwords must match</div>

                                            </div>
                                        </div>
                                        </div>
                                    </div> -->
                  <div class="col-md-6 form-group">
                    <label for="exampleInputPassword1">Confirm Password <span class="text-danger">*</span></label>
                    <div class="input-group" id="show_hide_password1">
                      <input type="password" (paste)="(false)" (copy)="(false)" formControlName="confirmpassword" (keydown.space)="$event.preventDefault()"
                        class="form-control mb-0" [ngClass]="{
                            'is-invalid':
                              (submitted || f.confirmpassword.touched) &&
                              f.confirmpassword.errors
                          }" id="exampleInputPassword1" placeholder="Enter Confirm Password" />
                      <div class="input-group-addon">
                        <a href="javascript:void(0)"><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                      </div>
                      <div *ngIf="
                            (submitted || f.confirmpassword.touched) &&
                            f.confirmpassword.errors
                          " class="invalid-feedback">
                        <div *ngIf="f.confirmpassword.errors.required"> Confirm Password is required </div>
                        <div *ngIf="f.confirmpassword.errors.mustMatch"> Passwords must match </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1">Mobile <span style="color:red">*</span></label>
                      <div class="telephone">
                        <input type="tel" id="demo" style="margin-left: 35%;" #phNum
                          (focusout)="checkPhoneNumber(phNum.value)" class="form-control" formControlName="cellNumber"
                          (keypress)="numberOnly($event,phNum.value)" maxlength="10" placeholder="Enter mobile number"
                          id="telephone" [ngClass]="{
                            'is-invalid':
                              (submitted || f.cellNumber.touched) &&
                              f.cellNumber.errors
                          }" />
                        <div *ngIf="
                            (submitted || f.cellNumber.touched) &&
                            f.cellNumber.errors
                          " class="invalid-feedback position-absolute mt-4">
                          <div *ngIf="f.cellNumber.errors.required"> Phone Number is required </div>
                          <div *ngIf="f.cellNumber.errors.pattern"> Should be a valid Phone Number </div>
                        </div>
                      </div>
                      <!-- <input type="text" class="form-control w-85 float-left   ml-5 mb-0" #phNum (keyup)="checkPhoneNumber(phNum.value)" placeholder="Mobile" formControlName="cellNumber" (keypress)="numberOnly($event)" maxlength="10" [ngClass]="{ 'is-invalid': (submitted || f.cellNumber.touched)  && f.cellNumber.errors}">
                                            <div class="phone-default">
                                                +91
                                                </div>
                                            <div *ngIf="(submitted || f.cellNumber.touched)  && f.cellNumber.errors" class="invalid-feedback invalid-feedback mt-4 pt-3">
                                                <div *ngIf="f.cellNumber.errors.required">Mobile Number is required</div>
                                                <div *ngIf="f.cellNumber.errors.pattern">Enter valid Mobile Number</div>
                                            </div> -->
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="exampleInputEmail1" class="mb-3">Gender <span style="color:red">*</span></label>
                      <br />
                      <div class="custom-control custom-radio custom-control-inline pl-1">
                        <input type="radio" id="customRadioInline1" value="male" formControlName="gender"
                          class="custom-control-input"
                          [ngClass]="{ 'is-invalid': (submitted || f.gender.touched) && f.gender.errors}">
                        <label class="custom-control-label" for="customRadioInline1">Male</label>
                      </div>
                      <div class="custom-control custom-radio custom-control-inline pl-0">
                        <input type="radio" id="customRadioInline2" value="female" formControlName="gender"
                          class="custom-control-input"
                          [ngClass]="{ 'is-invalid': (submitted || f.gender.touched) && f.gender.errors}">
                        <label class="custom-control-label" for="customRadioInline2">Female</label>
                      </div>
                      <div *ngIf="(submitted || f.gender.touched) && f.gender.errors">
                        <div *ngIf="f.gender.errors.required" style="color:#dc3545;font-size:10.24px;">Gender is
                          required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group ">
                      <label for="exampleInputEmail1">Type of ID Proof <span style="color:red">*</span></label>
                      <select class="form-control mb-0" placeholder="Specialization" [(value)]="selectedValue"
                        formControlName="specialization"
                        [ngClass]="{ 'is-invalid': (submitted || f.specialization.touched) && f.specialization.errors}"
                        (ngModelChange)="proofSelected($event)">
                        <option value="" selected hidden disabled>Select Proof</option>
                        <option>Aadhaar Card</option>
                        <option>Passport</option>
                        <option>PAN Card</option>
                      </select>
                      <div *ngIf="(submitted || f.specialization.touched) && f.specialization.errors"
                        class="invalid-feedback">
                        <div *ngIf="f.specialization.errors.required">Type of ID Proof is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <div *ngIf="isAadhar">
                        <label for="exampleInputEmail1"> ID proof Number <span style="color:red">*</span></label>
                        <input type="text" #aadhar class="form-control mb-0" (keypress)="keyPressAlphaNumeric($event)"
                          [minlength]="idMinLength" [maxlength]="idMaxLength" placeholder="Enter Aadhaar Number"
                          formControlName="aadhar"
                          [ngClass]="{ 'is-invalid': (submitted || f.aadhar.touched) && f.aadhar.errors}">
                        <div *ngIf="(submitted || f.aadhar.touched) && f.aadhar.errors" class="invalid-feedback">
                          <div *ngIf="f.aadhar.errors.required">ID proof Number is required </div>
                          <div *ngIf="f.aadhar.errors.minlength">Enter valid ID proof Number </div>
                        </div>
                      </div>
                      <div *ngIf="isPassport">
                        <label for="exampleInputEmail1"> ID proof Number <span style="color:red">*</span></label>
                        <input type="text" #passport class="form-control mb-0" (keypress)="keyPressAlphaNumeric($event)"
                          [minlength]="idMinLength" [maxlength]="idMaxLength" placeholder="Enter passport Number"
                          formControlName="passport"
                          [ngClass]="{ 'is-invalid': (submitted || f.passport.touched) && f.passport.errors}">
                        <div *ngIf="(submitted || f.passport.touched) && f.passport.errors" class="invalid-feedback">
                          <div *ngIf="f.passport.errors.required">ID proof Number is required </div>
                          <div *ngIf="f.passport.errors.minlength">Enter valid ID proof Number </div>
                        </div>
                      </div>
                      <div *ngIf="isPan">
                        <label for="exampleInputEmail1"> ID proof Number <span style="color:red">*</span></label>
                        <input type="text" #pan class="form-control mb-0" (keypress)="keyPressAlphaNumeric($event)"
                          [minlength]="idMinLength" [maxlength]="idMaxLength" placeholder="Enter PAN Number"
                          formControlName="pan"
                          [ngClass]="{ 'is-invalid': (submitted || f.pan.touched) && f.pan.errors}">
                        <div *ngIf="(submitted || f.pan.touched) && f.pan.errors" class="invalid-feedback">
                          <div *ngIf="f.pan.errors.required">ID proof Number is required</div>
                          <div *ngIf="f.pan.errors.minlength">Enter valid ID proof Number </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-inline-block w-100">
                  <button type="submit" class="btn btn-primary float-right effect effect-5" (click)="saveDoctor()"
                    [disabled]="!doctorForm.valid || isEmailExists || isPhoneNumberExists || isUserNameExists">Register Now</button>
                </div>
                <!-- <div class="form-group">
                                    <label for="exampleInputEmail1">  Emergency ContactNumber</label>
                                    <input type="text" class="form-control" formControlName="emergencyContactNumber">
                                </div> -->
                <!-- <div class="form-group">
                                    <label for="exampleInputEmail1">  Ethinicity</label>
                                    <input type="text" class="form-control" formControlName="ethinicity">
                                </div> -->
                <!-- <div class="form-group">
                                    <label for="exampleInputEmail1">  Medical degrees</label>
                                    <input type="text" class="form-control mb-0" formControlName="highestDegree">
                                </div> -->
                <!-- <div class="form-group">
                                    <label for="exampleInputEmail1">  Medical license expiry date</label>
                                    <input type="date" class="form-control mb-0" formControlName="medicalLicenceExpiryDate">
                                </div> -->
                <!-- <div class="form-group">
                                    <label for="exampleInputEmail1">  Total Experience</label>
                                    <input type="text" class="form-control"  formControlName="totalExperience">
                                </div> -->
                <div class="d-inline-block w-100">
                  <div class="sign-info"><span class="dark-color d-inline-block line-height-2">Already have an account?
                      <a style="color:green;text-decoration:underline"
                        [routerLink]="['/caretakerLogin']">Login</a></span>
                    <ul class="iq-social-media">
                      <li><a href="https://www.facebook.com/Curemetric" target="_blank"><i class="fa fa-facebook  m-r-5"
                            aria-hidden="true"></i></a></li>
                      <li><a href="https://twitter.com/Cure_Metric" target="_blank"><i class="fa fa-twitter m-r-5"
                            aria-hidden="true"></i></a></li>
                      <li><a href="https://www.linkedin.com/company/curemetric" target="_blank"><i
                            class="fa fa-linkedin m-r-5"></i></a></li>
                    </ul>
                  </div>
                  <!-- <h5><a style="color:blue;text-decoration:underline" routerLink="/Login">Home </a></h5> -->
                </div>
              </form>
            </div>
            <div class=" rightimg col-md-4 col-lg-4">
              <img src="./assets/landing/images/caretaker.png" />
            </div>
          </div>
        </div>
      </div>
    </div><!-- page end -->
  </div>
  <!-- Footer -->
  <app-main-footer></app-main-footer>
  <!-- Footer Ends -->
