import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-pharma-sidebar',
  templateUrl: './pharma-sidebar.component.html',
  styleUrls: ['./pharma-sidebar.component.css']
})
export class PharmaSidebarComponent implements OnInit {
  @Input() notificationsFromLayout: any
  @Input() primaryDetailsFromLayout: any
  imageSource
  firstName
  lastName
  notifications = []; p2 = 1;
  hai; notificationLength; nonViewData = []; ViewedNotifications
  age
  gender
  file
  pharmacyAddessId; loginUser;

  constructor(private globalStorage: GlobalStorageService, private service: pharmaService, private formBuilder: FormBuilder, private router: Router,
    private ToastrService: ToastrService, private sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {

    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    this.pharmacyAddessId = obj.pharmacyaddressId
    this.loginUser = obj
    this.getCounts()
    this.loadNotif()
    this.getProfilePic()
    this.getPersonalDetails()
    // this.service.GetPathSession('primarydetails?pharmacyuserid=', obj['pharmacyUserId']).subscribe(res => {
    // if (res['body']['data']) {
    this.firstName = this.primaryDetailsFromLayout?.['firstName']
    this.lastName = this.primaryDetailsFromLayout?.['lastName']
    // }

    // })


  }
  allCounts
  getCounts() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    this.service.globalPostPath('inventoryController/getTotalCount?pharmcayId=' + obj['pharmacyaddressId']).subscribe(res => {
      this.allCounts = res['body']['data']
    })
  }
  getPersonalDetails() {
    this.service.getPersonalDts(this.loginUser.pharmacyUserId).subscribe(res => {
      if (res['data']) {
        var d1 = moment(res['data'].dob);
        var d2 = moment(new Date());
        var days = moment.duration(d2.diff(d1)).asDays();
        var years = days / 360
        this.age = years.toFixed(0);
        this.gender = res['data'].gender
        if (res['data']['profilePic'] != null) {
          var src = "data:image/jpeg;base64," + res['body']['data']['profilePic']
          this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(src);
        }
        else {
          // this.imageSource = './assets/img/profile-icon.jpg'
        }
      }

    })
  }

  onFileInput(files: File) {
    const f = files;
    if ((f[0].type) == "image/jpeg" || (f[0].type) == "image/png") {
      if (f[0].size > 1048769) {
        this.ToastrService.warning('file should be 1 Mb')
      } else {
        this.file = files
      }
    } else {
      this.ToastrService.warning('file should be in jpeg or PNG')
    }
    this.updateProfilePic();
  }
pharmacyId
  getProfilePic() {
    let pharmacyId = parseInt(this.globalStorage.getItem('pharmacyId'));
    this.service.fetchPharma('id/', pharmacyId).subscribe(res => {
      this.pharmacyId=res?.['body']?.['data']?.['pharmacyId']
      if (res['body']['data']['profilePic'] != null) {
        var src = "data:image/jpeg;base64," + res['body']['data']['profilePic']
        this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(src);
      }
      else {
        // this.imageSource = './assets/img/profile-icon.jpg'
      }
    })
  }

  updateProfilePic() {
    let pharmacyId = this.pharmacyId;
    var formData = new FormData();
    for (let i = 0; i < this.file.length; i++) {
      formData.append('profilePic', this.file[i], this.file[i].name);
    }
    this.service.updateProfile('profilepic/', pharmacyId, formData).subscribe((res) => {
      this.ToastrService.success('Profile picture uploaded successfully.');
      this.getProfilePic();
    },
      (error) => {
        this.ToastrService.error('Profile picture should be less than 65KB');
      }
    )
  }

  p1 = 1;
  loadNotif() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    // this.service.globalGetPath('notificationalerts/get/all?maxResult=100&pageNumber='+this.p2+'&pharmacyaddressId='+ obj['pharmacyaddressId']).subscribe(res => {
    this.notifications = this.notificationsFromLayout
    this.notificationLength = this.notifications.filter((obj) => obj.viewed == 'N').length;
    this.nonViewData = this.notifications.filter((obj) => obj.viewed == 'N')
    this.ViewedNotifications = this.notifications.filter((obj) => obj.viewed == 'Y')
    // })
  }

  updateNotify(data) {
    var req = {
      "active": data.active,
      "notificationalertsId": data.notificationalertsId,
      "objectkey": data.objectkey,
      "objecttype": data.objecttype,
      "pharmacyaddressId": data.pharmacyaddressId,
      "reason": data.reason,
      "viewed": "Y"
    }
    this.service.updateNotifications(data.notificationalertsId, req).subscribe(res => {
      this.notificationLength = this.notificationLength - 1
      // this.loadNotif()
    })
  }

  navigate(url) {
    this.router.navigate([url]);
  }

}
