
<main class="l-main">
   <div class="content-wrapper content-wrapper--with-bg"> 


<div class="container-fluid rounded-sm ">
    <div class="row">
   
 
       <div class="col-lg-8 col-md-8 col-sm-12 mb-2  " *ngIf=isHome>
          <app-pharma-navbar [notificationsFromLayout]="notifications" [primaryDetailsFromLayout]="primaryDetailsFromLayout" *ngIf="isHome && notifications && primaryDetailsFromLayout"></app-pharma-navbar>
       <router-outlet  *ngIf="isHome && primaryDetailsFromLayout"></router-outlet>
       </div>
       <div class="col-lg-12 col-md-12 col-sm-12 mb-2  " *ngIf=!isHome>
         <app-pharma-navbar  [notificationsFromLayout]="notifications" [primaryDetailsFromLayout]="primaryDetailsFromLayout" *ngIf=!isHome></app-pharma-navbar>
      <router-outlet  *ngIf="!isHome && primaryDetailsFromLayout"></router-outlet>
      </div>
 
       <div class="col-lg-4 col-md-4 col-sm-12 mb-0  " *ngIf=isHome>
   
          <app-pharma-sidebar  [notificationsFromLayout]="notifications"  [primaryDetailsFromLayout]="primaryDetailsFromLayout" *ngIf="isHome && notifications && primaryDetailsFromLayout"></app-pharma-sidebar>
        </div>
 </div>
 
 </div>

 </div>
 </main>
 <app-footer></app-footer>