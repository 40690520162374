import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApicallsService } from 'src/app/apicalls.service';
import { Observable, Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class pharmaService {
  pharmaUrl = environment.pharmaUrl
  baseUrl = environment.patientBaseUrl
  utilitiesUrl = environment.utilitiesUrl
  elesticUrl = environment.elesticSearchUrl;
  pdfUrl = environment.pdfUrl;

  sharingData;
  dataStringSource = new Subject<any>();

  constructor(private globalStorage: GlobalStorageService, private http: HttpClient, private apiServices: ApicallsService) {
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      this.baseUrl = environment.patientBaseUrl1
      this.elesticUrl = environment.elesticSearchUrl1;
      this.pdfUrl = environment.pdfUrl1;
      this.pharmaUrl = environment.pharmaUrl1
      this.utilitiesUrl=environment.utilitiesUrl1
    }
  }
  
  globalPostPath(endpoint) {
    return this.apiServices.post(this.pharmaUrl + endpoint, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }

  downloadCsv(pharmacyId) {
    //inventoryController/inventory/export?pharmacyaddresId
    return this.apiServices.get(this.pharmaUrl + 'inventoryController/inventory/export?pharmacyaddresId=' + pharmacyId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), responseType: "text" })
  }
  downloadCsvFormat(){    
    //inventoryController/inventory/export?pharmacyaddresId
    return this.apiServices.get(this.pharmaUrl + 'inventoryController/exportToCSVToTemplate', { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), responseType: "text" })
  }
  searchDrug(drugName, pharmacyId) {
    return this.apiServices.get(this.elesticUrl + 'medicine/find/allmedicine/byname/' + drugName + '/' + pharmacyId, {})
  }
  searchDrugByChar(drugName, pharmacyId) {
    return this.apiServices.post(this.pharmaUrl + 'inventoryController/searchbydrugname?drugname=' + drugName+'&pharmacyaddresId='+pharmacyId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  
  saveUser(body){
    return this.apiServices.post(this.pharmaUrl+'patients/save',body,
    { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getUsersList(id){
    if(id!='undefined' || id!='null'){
      return this.apiServices.get(this.pharmaUrl + 'patients/getPatient/' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
    }
  }

  getPharmaInvoiceByStatus(body){
    return this.apiServices.post(this.pharmaUrl + 'invoice/get/bystatus', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': 'PHARMACY', 'forUserId':this.globalStorage.getItem('forUserId') }), observe: 'response' });
  }

  globalGetPath(endpoint) {
    return this.apiServices.get(this.pharmaUrl + endpoint, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getNotificications(endpoint, fromDate) {
    return this.apiServices.get(this.pharmaUrl + endpoint + fromDate, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  globalGetPathSession(endpoint, path, id) {
    return this.apiServices.get(this.pharmaUrl + endpoint + path + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  fetchPharma(endpoint, id) {
    return this.apiServices.get(this.pharmaUrl + endpoint + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getPharmaPrescription(id) {
    return this.apiServices.get(this.pharmaUrl + 'prescription/prescriptionprice/bypfr/' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  
  getDiscountDetails(id) {
    return this.apiServices.get(this.pharmaUrl + 'prescription/invoice/bypfr/' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getPharmaAddress(id) {
    return this.apiServices.get(this.pharmaUrl + 'getAddressesOfPharmacy?pharmacyaddressId=' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getPatientAddress(endpoint, body, userId) {
    return this.apiServices.post(this.baseUrl + endpoint, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': userId.toString() }) })
  }
  GetPathSession(endpoint, path) {
    return this.apiServices.get(this.pharmaUrl + endpoint + path, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  globalPostPharmaSession(endpoint, body) {
    return this.apiServices.post(this.pharmaUrl + endpoint, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': 'PHARMACY', 'forUserId':this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }
  changepassword(endpoint, body) {
    return this.apiServices.post(this.pharmaUrl + endpoint, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': 'PHARMACY', 'forUserId':this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }
  lookupstatic(module, service) {
    return this.apiServices.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=' + module + '&type=' + service, {})
  }
  uploadCvs(endpoint, body) {
    return this.apiServices.post(this.pharmaUrl + endpoint, body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }

  sendInvoice(endpoint, body, userId) {
    return this.apiServices.post(this.pharmaUrl + endpoint, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': userId.toString() }), observe: 'response' })
  }
  globalPostPharma(endpoint, body) {
    return this.apiServices.post(this.pharmaUrl + endpoint, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
  }

  updatePharma(body,userId)
  {
    return this.apiServices.post(this.pharmaUrl+'updatePharmacyuser',body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': userId.toString() }), observe: 'response' })
  }

  globalPostQuery(endpoint, query) {
    return this.apiServices.post(this.pharmaUrl + endpoint + '?pharmacyId=' + query, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  sendFeedback(formData) {
    return this.apiServices.post(this.utilitiesUrl + 'feedback/create', formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  getPersonalDetails(endpoint, query) {
    return this.apiServices.post(this.pharmaUrl + endpoint + '?pharmacyuserId=' + query, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  getInvoiceData(endpoint, payment_type) {
    return this.apiServices.post(this.pharmaUrl + endpoint + payment_type, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  updateProfile(endpoint, pharmcyId, data) {
    return this.apiServices.post(this.pharmaUrl + endpoint + pharmcyId, data, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  updateNotifications(endpoint, request) {
    return this.apiServices.post(this.pharmaUrl + 'notificationalerts/updateNotificationalerts/' + endpoint, request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  globalGetPharma(endpoint, query) {
    let params = new HttpParams().set('email', query);
    return this.apiServices.get(this.pharmaUrl + endpoint, { params: params })
  }
  checkPhoneNumber(endpoint, query) {
    let params = new HttpParams().set('phone', query);
    return this.apiServices.get(this.pharmaUrl + endpoint+query, {  })
  }

  getPatientByMobile(body){
    return this.apiServices.post(this.pharmaUrl + 'patients/findPatientByCellNumer', body, {})

  }
  isPhoneExits(phone){
    return this.apiServices.get(this.pharmaUrl + "isPhoneNumberExistOrNot?cellNumber="+phone+"&pharmacyAddressId="+ this.globalStorage.getItem("pharmacyAddressId"),{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': this.globalStorage.getItem('forUserId')  }) })
  }
  globalPostPatient(endpoint, body, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiServices.post(this.baseUrl + endpoint, body,
      { params: params, headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  getPrescriptionImage(prescriptionId, userId) {
    return this.apiServices.post(this.baseUrl + 'prescription/getprescriptionswithmedaction?medicalPresciptionId=' + prescriptionId, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': userId.toString() }) })
  }
  globalPut(endpoint, body) {
    return this.apiServices.put(this.pharmaUrl + endpoint, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
updatePrimary(endpoint, body){
  return this.http.put(this.pharmaUrl + endpoint, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': 'PHARMACY', 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
}
saveData(data){
  this.sharingData = data;
  this.dataStringSource.next(this.sharingData);
}
  getOtp(endpoint, passwordType, verificationId) {

    let params = new HttpParams()
    params = params.set('passwordType', passwordType),
      params = params.set('verificationId', verificationId)
    return this.apiServices.post(this.pharmaUrl + endpoint, {},
      { params: params })
  }
  getForgotOtp(emailId, resetPwdLink) {
    return this.apiServices.post(this.pharmaUrl + 'password/forgot?emailId=' + emailId + '&resetPwdLink=' + resetPwdLink, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  changePwd(req) {
    return this.apiServices.post(this.pharmaUrl + 'password/reset', req, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  changePwdUser(req) {
    return this.apiServices.post(this.pharmaUrl + 'resetPasswordForPharmacyUserNew', req, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  getPersonalDts(id) {
    return this.apiServices.post(this.pharmaUrl + 'get/personaldetail?pharmacyuserId=' + id, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  GetPath(endpoint, path) {
    return this.apiServices.get(this.pharmaUrl + endpoint + path, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getDrugNames() {
    return this.apiServices.get(this.pharmaUrl + 'drug/all', { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }

  userNameExistOrNot(userName) {
    return this.apiServices.get(this.pharmaUrl + 'isuserNameExists?userName=' + userName, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' });
  }

  drugType() {
    return this.apiServices.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=PHARMACY&type=MEDICINETYPE', { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }

  addBulkSelfPrescription(body){
    return this.apiServices.post(this.pharmaUrl + "selfprescription/addBulkSelfPrescription",body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) });
  }

  selfPayment(body){
    return this.apiServices.post(this.pharmaUrl + "prescription/selfPayment",body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) });
  }

  deactivateBranch(pharmacyId) {
    return this.apiServices.post(this.pharmaUrl + "deactivate/" + pharmacyId, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) });
  }
  activateBranch(pharmacyId) {
    return this.apiServices.post(this.pharmaUrl + "activate/" + pharmacyId, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) });
  }
  checkProfileCompleted(pharmacyUserId)
  {
    return this.apiServices.post(this.pharmaUrl + 'isProfileComplted/' + pharmacyUserId, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) });
  }


  fetchInventaryList(body){
    return this.apiServices.post(this.pharmaUrl + 'inventoryController/getinventorybypharmacyaddresId', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }

  searchOutPatientofPharma(body){
    return this.apiServices.post(this.pharmaUrl + 'patients/searchPatientByName', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }

  getPharmaPatientsInfo(patientId, pharmacyAddressId) {
    return this.apiServices.post(this.pharmaUrl + 'patients/fetchPatientDetal?patientId=' + patientId + '&pharmacyAddresId=' + pharmacyAddressId, {} , { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  pharmaPatientInvoiceDetails(fillRequestId,addressId) {
    return this.apiServices.get(this.pharmaUrl + 'prescription/prescriptionprice/bypfr/' + fillRequestId + '/' + addressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getPharmacyInvoiceDetails(invoiceId, pharmacyAddressId, userId) {
    return this.apiServices.get(this.pharmaUrl + 'prescription/prescriptionprice/by/invoiceid?invoiceid=' + invoiceId + '&pharmacyaddressId=' + pharmacyAddressId + '&userid=' + userId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getInvoiceDataByPharmacy(invoiceId){
    return this.apiServices.get(this.pharmaUrl + 'invoice/fetchInvoiceByInvoiceId?invoiceId=' + invoiceId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  downloadPharmaPdf(body): Observable<any>{
    return this.apiServices.post(this.pdfUrl + 'pharmacy', body,{responseType: 'blob' as 'json'});
  }

}
