import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryService } from '../inventory/inventory.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-order-payment',
  templateUrl: './add-order-payment.component.html',
  styleUrls: ['./add-order-payment.component.css']
})
export class AddOrderPaymentComponent implements OnInit {

  @Input() orderDetails;
  cashModes: any[] = [{ id: 1, value: 'CASH' }, { id: 2, value: 'CARD' }, { id: 3, value: 'UPI' }]


  constructor(public activeModal: NgbActiveModal, private inventoryService: InventoryService, private toaster: ToastrService) { }

  ngOnInit(): void {
  }

  selectedPaymentType: any;
  txnId: any

  addPayment() {
    if (this.selectedPaymentType) {
      const body = {
        "paymentMode": this.selectedPaymentType,
        "supplierordersId": this.orderDetails?.supplierordersId,
        "txnId": this.txnId,
      }
      this.inventoryService.orderPayment(body).subscribe(res => {
        if (res) {
          this.toaster.success('Payment added successfully')
          this.activeModal.close()
        }
      })

    } else {
      this.toaster.error('Please select payment mode.')
    }
  }

}
