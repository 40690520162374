<div class="modal-header">
    <h4 class="modal-title">Ambulance History ({{ambulanceInfo?.ambulanceNumber}})</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
  <table class="table table-bordered table-hover table-styled table-striped mt-2" role="grid"
  aria-describedby="example2_info">
  <thead>
    <tr role="row">
      <th>Case ID</th>
      <th>Patient Name</th>
      <th>Driver Name</th>
      <th>Pickup Location</th>
      <th>Drop Location</th>
      <th>Date / Time</th>
      <th>Amount Paid</th>
    </tr>
  </thead>
  <tbody>
    <tr class="odd" data-id="9"
      *ngFor="let ambulance of history | paginate: { id: 'p4', itemsPerPage: 10, currentPage: p4 }">
      <!-- <td><a href="javascript:void(0)" (click)="navigateToInPatient(ambulance)">{{ambulance?.inpatientId}}</a>
      </td>
      <td><a href="javascript:void(0)" (click)="openPatientDetails(ambulance)">{{ambulance?.patientName}}</a>
      </td> -->
      <td>{{ambulance?.inpatientId}}</td>
      <td>{{ambulance?.patientName}}</td>
      <td>{{ambulance?.driverName}}</td>
      <td>{{ambulance?.pickLocation}}</td>
      <td>{{ambulance?.dropLocation}}</td>
      <td>{{ambulance?.addCheckInTime | date:'dd-MMM-yyyy hh:mm a'}}</td>
      <td>{{ambulance?.servciecost}}</td>
    </tr>
  </tbody>
  </table>
  <div *ngIf="history?.length==0 || history == null">
      <div class="no-data-found"></div>
    </div>
    <div class="row" *ngIf="history?.length>10">
      <div class="col-lg-12 mt-3 text_right">
        <ul class="pagination">
          <pagination-controls autoHide="true" id="p4" (pageChange)="p4=$event"></pagination-controls>
        </ul>
      </div>
    </div>
</div>
