import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DiagnosticNotificationService } from 'src/app/shared/diagnostic-notification.service';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-diagnostic-layout',
  templateUrl: './diagnostic-layout.component.html',
  styleUrls: ['./diagnostic-layout.component.css']
})
export class DiagnosticLayoutComponent implements OnInit {
  subscription: Subscription;
  isHome
  constructor(private globalStorage: GlobalStorageService, private notification:DiagnosticNotificationService) { }
  ngOnInit(): void {
    this.notification.requestPermission(this.globalStorage.getItem('diagnosticCenterAddressId'));
    this.notification.receiveMessage();
    this.notification.currentMessage
    const source = interval(1000);
    this.subscription = source.subscribe(val => this.checkHomeOrNot());
  }
  checkHomeOrNot(){
    if(window.location.pathname == '/diagnosticDashboard' || window.location.pathname == '/cureMetric/diagnosticDashboard'){
      this.isHome = true;
     }
    else{
      this.isHome = false;
    }
  }
}
