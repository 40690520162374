import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from 'src/app/shared/services/patient.service';
import * as libphonenumber from 'google-libphonenumber';
import { InventoryService } from '../inventory.service';
@Component({
  selector: 'app-add-supplier',
  templateUrl: './add-supplier.component.html',
  styleUrls: ['./add-supplier.component.css']
})
export class AddSupplierComponent implements OnInit {
  @Input() isEdit = false;
  @Input() supplierData: any;
  @Input() fromWhere;
  @Input() addressId;
  @Input() service;
  @Input() isView;
  selectedCC: string = ''
  editdataSupplier: any;
  supplierForm: FormGroup;
  primarysubmitted: boolean = false;
  submitted: boolean = false;
  zip: any;
  countryCodeSelected: any;
  selectedValue: any;
  postalLocations: any;
  SupplierInfo: any;
  constructor(private formBuilder: FormBuilder, private patientService: PatientService, private toastrService: ToastrService, public activeModal: NgbActiveModal, private inventoryService: InventoryService) { }

  ngOnInit(): void {
    this.supplierForm = this.formBuilder.group({
      supplierName: ['', Validators.required],
      primaryContact: ['', Validators.required],
      primaryContactNumber: ['', Validators.required],
      email: ['',[Validators.required, Validators.email]],
      gstin: ['', [Validators.required, Validators.minLength(15)]],
      city: ['', Validators.required],
      state: ['', Validators.required],
      street1: ['', Validators.required],
      postalLocation: ['', Validators.required],
      zip: ['', Validators.required],
      cc: ['', Validators.required],
    })
    if (this.isEdit) {
      var supplierId = this.supplierData?.supplierId
      this.inventoryService.SupplierInformation(supplierId).subscribe(res => {
        this.editdataSupplier = res['data']
        this.postalLocations = [{ "postalLocation": this.editdataSupplier?.['supplieraddress']['postalLocation'] }]
        this.selectedValue = this.editdataSupplier?.['supplieraddress']['postalLocation']
        this.supplierForm.patchValue({
          supplierName: this.editdataSupplier?.['name'],
          primaryContact: this.editdataSupplier?.['primaryContact'],
          gstin: this.editdataSupplier?.['gstin'],
          // primaryContactNumber: this.editdataSupplier?.['primaryContactNumber'],
          primaryContactNumber: this.parseLocalNumber(this.editdataSupplier?.['primaryContactNumber']),
          email : this.editdataSupplier?.emailId,
          postalLocation: this.editdataSupplier?.['supplieraddress']['postalLocation'],
          street1: this.editdataSupplier?.['supplieraddress']['street1'],
          // street2:this.editdataSupplier?.['supplieraddress']['street2'],
          zip: this.editdataSupplier?.['supplieraddress']['zip'],
          city: this.editdataSupplier?.['supplieraddress']['city'],
          state: this.editdataSupplier?.['supplieraddress']['state'],
          cc: this.editdataSupplier?.['supplieraddress']['country'],
        })
      })
    }

    if (this.isView) {
      var supplierinfoId = this.supplierData?.supplierId;
      this.inventoryService.SupplierInformation(supplierinfoId).subscribe(res => {
        this.SupplierInfo = res?.['data'];
        this.supplierForm.patchValue({
          supplierId: this.SupplierInfo?.supplierId,
          supplierName: this.SupplierInfo?.name,
          primaryContact: this.SupplierInfo?.primaryContact,
          email : this.SupplierInfo?.emailId,
          gstin: this.SupplierInfo?.['gstin'],
          primaryContactNumber: this.parseLocalNumber(this.SupplierInfo?.primaryContactNumber),
          zip: this.SupplierInfo?.['supplieraddress']?.['zip'],
          cc: this.SupplierInfo?.['supplieraddress']?.['country'],
          postalLocation: this.SupplierInfo?.supplieraddress?.postalLocation,
          street1: this.SupplierInfo?.['supplieraddress']?.['street1'],
          city: this.SupplierInfo?.['supplieraddress']?.['city'],
          state: this.SupplierInfo?.['supplieraddress']?.['state'],
        })
        this.selectedCC = this.SupplierInfo?.['supplieraddress']?.['country'];
      })
    }

  }
  parseLocalNumber(fullNumber: string) {
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    try {
      // 'IN' is the country code here for India. You should adjust based on the selected country in your form or component's logic.
      const phoneNumber = phoneUtil.parse(fullNumber, 'IN');
      const localNumber = phoneNumber.getNationalNumber();
      return localNumber.toString();
    } catch (error) {
      console.error('Invalid number', error);
      return fullNumber; // Return the full number if parsing fails.
    }
  }
  countryCodeEntered() {
    this.postalLocations = [];
    this.patientService.getPincodeDetails(this.zip, this.supplierForm.value.cc).subscribe(res => {
      if (res['data'].length == 0) {
        this.supplierForm.patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.toastrService.error("Incorrect pincode")
      }
      this.postalLocations = res['data'];
      // this.latitude = res['result'][0]['latitude'];
      // this.longitude = res['result'][0]['longitude'];
      this.supplierForm.patchValue({
        country: res['data'][0]['country'],
        city: res['data'][0]['province'],
        state: res['data'][0]['state'],

      })
    })
  }
  get f() { return this.supplierForm.controls; }

  get h() { return this.supplierForm.controls; }
  alphaOnly(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/[a-zåäö ]/i);
    return pattern.test(value);
  };
  numberOnly(event, phn): boolean {
    console.log(phn)
    const charCode = (event.which) ? event.which : event.keyCode;

    if (phn.length < 1) {
      if (charCode < 54 || charCode > 57) {
        return false;
      }
      return true;
    }
    else {
      if (charCode < 48 || charCode > 57) {
        return false;
      }
      return true;

    }
  }

  percChanged(zip) {
    this.zip = zip;
    if (zip.length < 6) {
      this.selectedCC = null;
      this.supplierForm.patchValue({
        country: '',
        city: '',
        state: '',
        postalLocation: ''
      });
      this.postalLocations = [];
    }
  }

  latitude; longitude
  drpdownSelectionForAddress(e) {
    this.latitude = e?.latitude
    this.longitude = e?.longitude
  }

  saveSupplier() {
    var body = {
      "name": this.supplierForm?.value?.supplierName,
      "primaryContact": this.supplierForm?.value?.primaryContact,
      "primaryContactNumber": "+91" + this.supplierForm?.value?.primaryContactNumber,
      "emailId": this.supplierForm?.value?.email,
      "addressId": this.addressId,
      "gstin" : this.supplierForm?.value?.gstin,
      "supplieraddress": {
        "active": "Y",
        "addressId": this.addressId,
        "postalLocation": this.supplierForm?.value?.postalLocation,
        "street1": this.supplierForm?.value?.street1,
        "street2": this.supplierForm?.value?.street2,
        "zip": this.supplierForm?.value?.zip,
        "city": this.supplierForm?.value?.city,
        "state": this.supplierForm?.value?.state,
        "country": this.supplierForm?.value?.cc,
      }
    }

    if (this.supplierForm.valid) {
      this.inventoryService.saveSupplierdetails(body).subscribe(res => {
        this.toastrService.success('Supplier saved')
        this.supplierForm.reset()
        this.activeModal.close('')
      }, error => {
        this.toastrService.error(error?.['error']?.['message'])
      })
    }
  }

  updateSupplier() {
    var body = {
      "supplierId": this.supplierData?.supplierId,
      "name": this.supplierForm?.value?.supplierName,
      "primaryContact": this.supplierForm?.value?.primaryContact,
      "primaryContactNumber": "+91" + this.supplierForm?.value?.primaryContactNumber,
      "emailId": this.supplierForm?.value?.email,
      "addressId": this.addressId,
      "gstin" : this.supplierForm?.value?.gstin,
      "supplieraddress": {
        "active": "Y",
        "addressId": this.addressId,
        "postalLocation": this.supplierForm?.value?.postalLocation,
        "street1": this.supplierForm?.value?.street1,
        "street2": this.supplierForm?.value?.street2,
        "zip": this.supplierForm?.value?.zip,
        "city": this.supplierForm?.value?.city,
        "state": this.supplierForm?.value?.state,
        "country": this.supplierForm?.value?.cc,
        "supplieraddressId": this.editdataSupplier?.supplieraddress?.supplieraddressId
      }
    }
    // this.fromWhere ? (body['ivfAddressId'] = this.addressId) : (body['hospitalAddresId'] =this.addressId);
    this.fromWhere ? (body['addressId'] = this.addressId) : (body['addressId'] = this.addressId);
    this.inventoryService.saveSupplierdetails(body).subscribe(res => {
      this.toastrService.success('Supplier data updated')
      this.isEdit = false
      this.supplierForm.reset()
      this.activeModal.close('')
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    })
  }

}
