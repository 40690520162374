import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { CaretakerService } from 'src/app/shared/services/caretaker.service';
import { gpsService } from 'src/app/shared/services/gps.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
import { ApicallsService } from 'src/app/apicalls.service';
declare var $;
@Component({
  selector: 'app-caretaker-navbar',
  templateUrl: './caretaker-navbar.component.html',
  styleUrls: ['./caretaker-navbar.component.css']
})
export class CaretakerNavbarComponent implements OnInit {
  p2 = 1; notifyToView;
  loginUser; notificationCount; notifications; notificationLength; notViewedNotifications; viewedNotifications; notificationBell = true;
  constructor(private globalStorage: GlobalStorageService, private router: Router, private scripts: ScriptsService, private reports: PatientScheduleControlService, private apiservice: ApicallsService,
    private toaster: ToastrService, private gps: gpsService, private caretakerService: CaretakerService,) { }
    isrpmcaretaker=false;
  ngOnInit(): void {
    if(this.globalStorage.getItem('rpmadminAddressId')=='null'){
      this.isrpmcaretaker=true;
    }
    this.scripts.loadScripts()
    var data = JSON.parse(this.globalStorage.getItem('caretakerData'));
    this.loginUser = data;
    this.checkProfileCompletedOrNot()
    this.loadNotification()
    this.scripts.loadScripts()
    if (window.location.pathname == '/caretaker-dashboard' || window.location.pathname == '/cureMetric/caretaker-dashboard') {
      this.notificationBell = false
    }
    $("button, a, body").on("click", function (e) {
      $('.tooltip').hide();
      $('[data-toggle="tooltip"]').tooltip("hide");
    });
    //  this.getAssociatedUsers()
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });

      $('[data-toggle="tooltip"]').on('click', function () {
        $(this).tooltip('hide')
      });
    });
  }

  checkProfileCompletedOrNot() {
    this.caretakerService.caretakerProfileCompletedOrNot(this.loginUser.caretakerId).subscribe(res => {
      if (res['body']['data']['isPersonalDetailCompleted'] == 'N') {
        this.router.navigate(['/caretaker-personal-details'])
        return
      }
      else {
        if (res['body']['data']['isAddressCompleted'] == 'N') {
          this.router.navigate(['/caretaker-address-details'])
        }
        else{
          if (res['body']['data']['isFeeDetailCompleted'] == 'N') {
            this.router.navigate(['/caretaker-profile'])
          }
        }
      }
      // if (res['body']['data']['isFeeDetailCompleted'] == 'N') {
      //   this.router.navigate(['/caretaker-profile'])
      // }
      // else {
      //   if (res['body']['data']['isPersonalDetailCompleted'] == 'N') {
      //     this.router.navigate(['/caretaker-personal-details'])
      //     return
      //   }
      //   else if (res['body']['data']['isAddressCompleted'] == 'N') {
      //     this.router.navigate(['/caretaker-address-details'])
      //   }
      // }
    })
  }

  logOut() {
    this.router.navigate(['/caretakerLogin'])

    this.apiservice.isUserSessionExits = false
    this.apiservice.isforUserIdExits = false
    this.apiservice.ismoduleNameExits = false
    this.globalStorage.clear();
    this.reports.saveData(null)
    // this.caretakerService.logout().subscribe(res=>{

    // });
  }

  isAssociatedHosp(){
    return this.globalStorage.getItem('hospitalAddresId') != "null";
  }
  
  loadNotification() {

    this.caretakerService.getNotificationsAlertsCaretaker(this.loginUser.caretakerId).subscribe(res => {

      this.notificationCount = res['body']['data'].length
      this.notifications = res['body']['data']
      this.notificationLength = this.notifications.filter((obj) => obj.viewed == 'N').length;
      this.notViewedNotifications = this.notifications.filter((obj) => obj.viewed == 'N')
      this.viewedNotifications = this.notifications.filter((obj) => obj.viewed == 'Y')
    }, error => {
    })
  }
  closeModal() {
    $('#notificationToView').modal('hide')
    this.loadNotification()
  }
  onSend(data) {

    var formData = new FormData();
    formData.append('screenshot', data.screenshot);
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + ' ' + time;
    var request = {
      "clientId": 11,
      "userId": this.loginUser.caretakerId,
      "feedBackType": "TEST",
      "status": "NEW",
      "description": data.description,
      "userAgent": "test",
      "os": "Windows",
      "browser": "Chrome",
      "os_version": "windows-10",
      "browser_version": "85.0.4183.102",
      "device": "Unknown",
      "latitude": this.gps.latitude,
      "longitudes": this.gps.longitude,
      "feedbackTime": formatDate(dateTime, "yyyy-MM-dd", 'en-us')
    }
    formData.append('feedbackLoop', JSON.stringify(request));
    this.caretakerService.sendFeedback(formData).subscribe(res => {
      this.toaster.success('Feedback sent successfully')
    })
  }
  updateNotify(data) {
    $('#notificationViewer').modal('show');
    this.notifyToView = data;
    var req = {
      "active": data.active,
      "notificationalertsId": data.notificationalertsId,
      "objectkey": data.objectkey,
      "objecttype": data.objecttype,
      "caretakerId": data.caretakerId,
      "reason": data.reason,
      "viewed": "Y"
    }
    this.caretakerService.updateNotifications(data.notificationalertsId, data.caretakerId, req).subscribe(res => {
      this.notificationLength = this.notificationLength - 1
      this.loadNotification();
    })
  }

  closeNotificationViewerModal() {
    $('#notificationViewer').modal('hide');
    $("#notificationViewer").val(null).trigger("change");
    $('#viewremindersDoctor').modal('hide');
    this.loadNotification();
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

}
