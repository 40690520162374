import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {
  @Input() message:any
  constructor(public activeModal:NgbActiveModal,) { }

  ngOnInit(): void {
  }
  cancelVisit(){
    this.activeModal.close()
  }
  submit(){
    this.activeModal.close('true')
  }
}
