<!--
<footer>
    &copy; All rights reserved 2019
</footer> -->
<footer class="main-footer p-0">
  <div class=" bg-footer-top p-20" style="background-color: #28a745;color: #ffffff;;">
    <div class="container">
      <div class="row">
        <div class="widget-area col-xs-6 col-sm-12 col-md-6 col-lg-6">
          <!-- <i class="fa fa-map"></i> -->
          <aside class="widget widget-text">
            <div class="featured-icon-box iconalign-before-heading">
              <div class="featured-title">

                <h5 style="padding: 7px;margin: 0;font-size: 11px;">Have a question ? Call us : +91 40-31537601
                </h5>
              </div>
            </div>
          </aside>
        </div>
        <div class="widget-area col-xs-6 col-sm-12 col-md-6 col-lg-6">
          <aside class="widget widget-text">
            <div class="featured-icon-box iconalign-before-heading">
              <div class="featured-title" style="display: block !important;">

                <h5 style="padding: 7px;margin: 0;font-size: 11px;"><a style="color:#fff;font-size: 11px;"
                    href="mailto:info@curemetric.com">Need support ? Mail us: info@curemetric.com</a></h5>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>

  </div>
  <div class="  text-center p-10 bg-blue pt-1 pb-1" style="background-color: #153b64;color: #ffffff;font-size:11px">
    © 2024, CureMetric Pvt. Ltd. All Rights Reserved.{{ currentYear }}. All Rights Reserved. (build version 26.0)
  </div>

</footer>
