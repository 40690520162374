import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as saveAs from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { GlobalStorageService } from 'src/app/global-storage.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import * as moment from 'moment';
import { PdfMakerService } from 'src/app/pdf-maker.service';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-booking-confirm-screen',
  templateUrl: './booking-confirm-screen.component.html',
  styleUrls: ['./booking-confirm-screen.component.css']
})
export class BookingConfirmScreenComponent implements OnInit {

  @Input() bookingDetails:any;
  @Input() isDc:boolean=false;
  constructor(public activeModal:NgbActiveModal,private ivfService:IvfService,private toastrService:ToastrService,
    private globalStorage: GlobalStorageService,private clinicService:ClinicService, private pdfMakeService:PdfMakerService) { }

  ngOnInit(): void {
    console.log(this.bookingDetails);
    this.getInvoiceDetails()
  }

  getInvoiceDetails(){
    if(this.isDc){
      this.getInvoiceDetailsforDc()
    }
    else if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
      this.getInvoiceForHospital()
    }
    else if(this.globalStorage.getItem('loggedInAs')=='IVF'){
      this.getInvoiceDetailsforDoctor()
    }
  }

  invoiceDate:any;
  hospitalInfo:any;invoiceData;
  getInvoiceForHospital(){
    this.clinicService.getInvoiceByAppointmentId(this.globalStorage.getItem('hospitalAddressId'),this.bookingDetails?.appointmentId).subscribe(res=>{
      console.log('ivoice response',res)
      this.pdfData = res?.body
      this.invoiceData=res?.body?.data
      this.hospitalInfo = this.invoiceData?.hospitaladdress;
      this.genPDF()
      //this.printPdf()
    })
  }
  getInvoiceDetailsforDoctor(){
    this.ivfService.findInvoiceByDoctorAppointmentId(this.bookingDetails?.appointmentId).subscribe(res=>{
      this.pdfData = res?.body
      this.downloadPdf()
    })
  }

  getInvoiceDetailsforDc(){
    this.ivfService.findPatientInvoiceForDcAppotment(this.bookingDetails?.appointmentId).subscribe(res=>{
      this.pdfData = res?.body
      this.downloadPdf()
    })
  }
  

  pdfData: any;
  objectUrl:any;
  downloadPdf() {
    this.ivfService.downloadInvoicePDF(this.pdfData).subscribe(res => {
      let entity_name = this.bookingDetails?.appointmentId + '.pdf';
      let blob = new Blob([res], { type: 'application/pdf' });

      saveAs(blob, entity_name);
    },
      error => {
        this.toastrService.error('Error downloading PDF.', error);
      }
    )
  }
  printPdf(type) {
    if(type){
      pdfMake.createPdf(this.printData).print();
    }else{
      pdfMake.createPdf(this.printData).download();
    }
  }

  printData: any;
  printDataWithoutHeader:any;
  genPDF() {

    const fromAddress = this.invoiceData?.hospitalname + '\n' + this.hospitalInfo?.address1 + '\n' + this.hospitalInfo?.city + '\n' + this.hospitalInfo?.state + '\n'+this.invoiceData?.patientAddress?.country+'\n' + this.hospitalInfo?.zip;
    const toAddress =this.invoiceData?.patientAddress?.contactName + '\n' + this.invoiceData?.patientAddress?.street1+'\n'+ this.invoiceData?.patientAddress?.city + '\n' + this.invoiceData?.patientAddress?.state+'\n'+ this.invoiceData?.patientAddress?.country+'\n'+this.invoiceData?.patientAddress?.zip;
   // const logo = 'data:image/jpeg;base64,' +'./assets/img/logo.png';
    
    const invoiceData = []

    const inv_date = moment(this.invoiceData)?.format('DD-MM-YYYY')

    invoiceData.unshift([
      { text: 'Type of Appointment', style: 'tableHeader',alignment: 'left' },
      { text: 'Reason', style: 'tableHeader',alignment: 'center' },
      { text: 'Total', style: 'tableHeader',alignment: 'right' }
    ]);
    this.printData = {
      pageMargins: [20, 130, 20, 10], // [left, top, right, bottom]
      header: function (currentPage, pageCount) {
        return {
          columns: [
            {
              text: fromAddress,
              width: 130,
              height:120,
              alignment: 'left'
            },
            {
              text: toAddress,
              alignment: 'right',
              width: '*',
            },
          ],
          margin: [20, 10, 20, 10],
        };
      },
      content: [
        {
          columns: [
            { text: 'Payment Method : ' + this.invoiceData?.transactiontype , bold: true },
          ],
          margin: [0, 10, 0, 0],
        },
        // {
        //   columns: [
        //     { text: this.invoiceData?.transactiontype, alignment: 'left', bold: false },
        //   ],
        //   margin: [0, 10, 0, 0],
        // },
        {
          style: 'tableExample',
          table: {
            widths: [250,170, 100],
            body: invoiceData,
          },
          layout: 'lightHorizontalLines',
        },
        {
          columns: [
            { text: this.invoiceData?.chatType, bold: false, alignment: 'left' },
            { text: this.invoiceData?.aliment, bold: false, alignment: 'center' },
            { text: this.invoiceData?.amount, alignment: 'right', width: 100 },
          ],
          margin: [0, 10, 0, 10],
        },
        {
          columns: [
            { text: 'Sub Total', bold: true, alignment: 'right' },
            { text: this.invoiceData?.amount, alignment: 'right', width: 100 },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          columns: [
            { text: 'Discount(%)', bold: true, alignment: 'right' },
            { text: 0+'%', alignment: 'right', width: 100 },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          columns: [
            { text: 'Total Amount', bold: true, alignment: 'right' },
            { text: this.invoiceData?.amount, alignment: 'right', width: 100 },
          ],
          margin: [0, 10, 0, 0],
        },
      ],
      styles: {
        tableExample: {
          margin: [0, 10, 0, 0],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
      },
    };
  }
  downloadAsPDF() {
  this.pdfMakeService.downloadPdf(this.printData)
  }
}
