<div class="modal-header">
  <h4 class="modal-title "><img src="assets/modal-title-icons/mritesticon.png" alt="icon" style="width: 25px;">Test Report</h4>
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
<meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1" />
<title>SELF SEMEN FREEZING</title>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
h4{font-size:15px;}
table {border:0 !important}
.table td, table tr{border:0 }
.table td{border-bottom:1px dashed !important; }
.table1 td{border:1px dashed !important; }
h1,h2,h3{background-color:#ccc; padding:5px 0; margin:0;-webkit-print-color-adjust: exact;color-adjust: exact;  }
.style1 {
	font-size: 14px;
	font-weight: bold;
	background-color:#DADADA
}



</style>
</head>

<body>

  <table width="100%" border="0" cellspacing="0" cellpadding="0" *ngIf="appointmentData?.documentLocation==null">


    <tr>
      <td><hr /></td>
    </tr>
    <tr *ngIf="dcReportData?.testdto==null || dcReportData?.testdto?.length==0">
      <div class="no-reports"></div>
    </tr>
     <tbody *ngIf="dcReportData?.testdto!=null || dcReportData?.testdto?.length>0">
    <tr>
      <td>
    <table border="1" cellspacing="0" cellpadding="8" align="left" width="100%" style="padding:0px" class="table">
        <tr>
          <td align="left" valign="middle"><strong>Patient Name</strong></td>
          <td width="10" align="center" valign="top"><strong>:</strong></td>
          <td align="left" valign="middle"><strong> {{dcReportData?.patientName}}</strong></td>
          <td width="50" align="left"  valign="middle"><strong>AGE</strong></td>
          <td width="10" align="center" valign="top"><strong>:</strong></td>
          <td align="left" valign="middle">&nbsp;{{dcReportData?.age}} Yrs</td>
          <td align="left" valign="middle"><strong>Lab No </strong></td>
          <td width="10" align="center" valign="top"><strong>:</strong></td>
          <td align="left" valign="middle">{{dcReportData?.appoitmentId}}</td>
        </tr>
        <tr>
          <td align="left" valign="middle"><strong>Collected</strong></td>
          <td width="10" align="center" valign="top"><strong>:</strong></td>
          <td align="left" valign="middle">&nbsp;</td>
          <td width="50" align="left"  valign="middle"><strong>Reported</strong></td>
          <td width="10" align="center" valign="top"><strong>:</strong></td>
          <td align="left" valign="middle">{{dcReportData?.reported | date:'dd-MMM-yyyy'}}</td>
          <td align="left" valign="middle"><strong>Date</strong></td>
          <td width="10" align="center" valign="top"><strong>:</strong></td>
          <td align="left" valign="middle">{{dcReportData?.date | date:'dd-MMM-yyyy'}}</td>
        </tr>
        <tr>
          <td align="left" valign="middle">&nbsp;</td>
          <td align="center" valign="top">&nbsp;</td>
          <td align="left" valign="middle">&nbsp;</td>
          <td align="left"  valign="middle">&nbsp;</td>
          <td align="center" valign="top">&nbsp;</td>
          <td align="left" valign="middle">&nbsp;</td>
          <td align="left" valign="middle">&nbsp;</td>
          <td align="center" valign="top">&nbsp;</td>
          <td align="left" valign="middle">&nbsp;</td>
        </tr>



      </table></td>
    </tr>


    <tr *ngFor="let item of dcReportData?.testdto">
      <td valign="top"><table width="100%" border="0" cellspacing="0" cellpadding="0">

        <tr>
          <td width="50%" align="left"><table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td width="50%" align="center"><h2  style="background-color:#ccc; padding:5px 0; margin:0 "><span align="left">Test Name</span></h2></td>
              <td width="50%" align="center"><h2  style="background-color:#ccc; padding:5px 0; margin:0 "><span align="right"> {{item?.testName}} </span> </h2></td>
            </tr>
          </table></td>
        </tr>



        <tr>
          <td valign="top"><table border="0" cellspacing="0" cellpadding="4" align="left" width="100%" style="padding:0px" class="table" >
           <tbody>
        </tbody>

<tr  *ngFor="let testParameter of item?.testParameterDTOs;let i=index">
<td colspan="10" >
  <table width="100%" border="0" cellpadding="8" cellspacing="0">
    <tbody>
      <tr><td colspan="10"><table border="0" cellpadding="8" cellspacing="0" width="100%"><tbody>


      <tr  style="background-color:#DADADA; padding:5px 0; margin:0 ;-webkit-print-color-adjust: exact;color-adjust: exact;">
        <th width="40%" align="left" valign="middle" bgcolor="#DADADA"  style="background-color:#DADADA;"><span class="style1">Investigations</span></th>
        <th width="10%" align="left" valign="middle" bgcolor="#DADADA"  style="background-color:#DADADA;"><span class="style1">Results</span></th>
        <th width="10%" align="left" valign="middle" bgcolor="#DADADA"  style="background-color:#DADADA;"><span class="style1">Units</span></th>
        <th width="20%" align="left" valign="middle" bgcolor="#DADADA"  style="background-color:#DADADA;"><span class="style1">Bio. Ref. Interval</span></th>
      </tr>

      </tbody></table></td></tr>


      <tr>
        <td colspan="10">
<table width="100%" border="0" cellpadding="8" cellspacing="0">
<tr>
<td align="left" valign="middle"><strong>{{testParameter?.parameterName}}
  </strong><br />({{testParameter?.method}})</td>
  <td align="left" valign="middle" >{{testParameter?.testParameterResults?.result}}</td>
  <td align="left" valign="middle">{{testParameter?.quantityMetric}}</td>
  <td align="left" valign="middle">{{testParameter?.numberOfDecimals}} </td>
</tr>
</table>
</td>
      </tr>

      <tr *ngIf="testParameter?.testParameterQuantities?.length>0 || testParameter?.testParameterQuantities!=null">
        <td colspan="10"><h2 align="center" style="background-color:#ccc; padding:5px 0; margin:0 ;-webkit-print-color-adjust: exact;color-adjust: exact;">Normal Ranges</h2></td>
      </tr>

      <tr  style="background-color:#DADADA; padding:5px 0; margin:0 ;-webkit-print-color-adjust: exact;color-adjust: exact;" *ngIf="testParameter?.testParameterQuantities?.length>0 || testParameter?.testParameterQuantities!=null">


      <th align="left" ><strong >
           Gender           </strong></th>
        <th align="left"  ><strong>
           From(age)                       </strong></th>
        <th align="left"  ><strong>
           To(age)          </strong></th>
        <th align="left"  ><strong>Low            </strong></th>
        <th align="left" ><strong>
            High         </strong></th>
        <th align="left" ><strong>
             Operator                         </strong></th>
        <th align="left"><strong>
      Normal
                Range          </strong></th>
        <th align="left" ><strong>
        C.V Min                    </strong></th>
        <th align="left" ><strong>
          C.V Max                     </strong></th>
        <th align="left" ><strong>
        Critical
                Value            </strong></th>
        </tr>
      <tr  *ngFor="let range of testParameter?.testParameterQuantities">
        <td > {{range?.gender}} </td>
        <td  > {{range?.ageFrom}}  </td>
        <td  > {{range?.ageTo}} </td>
        <td> {{range?.low}}</td>
        <td> {{range?.high}}</td>
        <td>{{range?.operator}}</td>
        <td> {{range?.normalRange}}</td>
        <td>{{range?.cvMin}}</td>
        <td>{{range?.cvMax}}</td>
        <td>{{range?.criticalValue}}</td>
      </tr>
      <tr *ngIf="item?.testReferenceDTOs?.length>0 || item?.testReferenceDTOs!=null">
        <td colspan="10"><h2 align="center" style="background-color:#ccc; padding:5px 0; margin:0 ;-webkit-print-color-adjust: exact;color-adjust: exact;">Test References</h2></td>
      </tr>

      <tr><td colspan="10"><table border="0" cellpadding="8" cellspacing="0" width="100%"><tbody>

        <tr  style="background-color:#DADADA; padding:5px 0; margin:0 ;-webkit-print-color-adjust: exact;color-adjust: exact;"
          *ngIf="item?.testReferenceDTOs?.length>0 || item?.testReferenceDTOs!=null">
          <th align="left" ><strong >
            Regerence Type           </strong></th>
            <th align="left" ><strong >
            Reference Details </strong></th>
        </tr>
        <tr *ngFor="let testReference of item?.testReferenceDTOs">
          <td > {{testReference?.referenceType}} </td>
          <td  > {{testReference?.referenceInfo}}  </td>
        </tr>
        </tbody></table></td></tr>




      <tr *ngIf="item?.testSignificanceDTOs?.length>0 || item?.testSignificanceDTOs!=null">
        <td colspan="10"><h2 align="center" style="background-color:#ccc; padding:5px 0; margin:0 ;-webkit-print-color-adjust: exact;color-adjust: exact;">Test Significance</h2></td>
      </tr>

      <tr><td colspan="10"><table border="0" cellpadding="8" cellspacing="0" width="100%"><tbody>

      <tr  style="background-color:#DADADA; padding:5px 0; margin:0 ;-webkit-print-color-adjust: exact;color-adjust: exact;"
        *ngIf="item?.testSignificanceDTOs?.length>0 || item?.testSignificanceDTOs!=null">
        <th align="left" ><strong >
          Type           </strong></th>
          <th align="left" ><strong >
          Details </strong></th>
      </tr>
      <tr *ngFor="let testSignificance of item?.testSignificanceDTOs">
        <td > {{testSignificance?.type}} </td>
        <td  > {{testSignificance?.details}}  </td>
      </tr>
      </tbody></table></td></tr>



      <tr *ngIf="item?.testSpecification?.length>0 || item?.testSpecification!=null">
        <td colspan="10"><h2 align="center" style="background-color:#ccc; padding:5px 0; margin:0 ;-webkit-print-color-adjust: exact;color-adjust: exact;">Test Specifications</h2></td>
      </tr>
      <tr><td colspan="10"><table border="0" cellpadding="8" cellspacing="0" width="100%"><tbody>


      <tr  style="background-color:#DADADA; padding:5px 0; margin:0 ;-webkit-print-color-adjust: exact;color-adjust: exact;"
        *ngIf="item?.testSpecification?.length>0 || item?.testSpecification!=null">
        <th align="left" ><strong >
          Type           </strong></th>
          <th align="left" ><strong >
          Details </strong></th>
      </tr>
      <tr *ngFor="let testSpecification of item?.testSpecification">
        <td > {{testSpecification?.type}} </td>
        <td  > {{testSpecification?.details}}  </td>
      </tr>
      </tbody></table></td></tr>




</tbody></table>
</td>

</tr>

            <tr>
              <td align="left" valign="middle">&nbsp;</td>
              <td align="left" valign="middle">&nbsp;</td>
              <td align="left" valign="middle">&nbsp;</td>
              <td align="left" valign="middle">&nbsp;</td>
            </tr>
            <tr>
              <td align="left" valign="middle">&nbsp;</td>
              <td align="left" valign="middle">&nbsp;</td>
              <td align="left" valign="middle">&nbsp;</td>
              <td align="left" valign="middle">&nbsp;</td>
            </tr>

            <tr>
              <td align="left" valign="middle">&nbsp;</td>
              <td align="left" valign="middle">&nbsp;</td>
              <td align="left" valign="middle">&nbsp;</td>
              <td align="left" valign="middle">&nbsp;</td>
            </tr>



















          </table></td>
        </tr>
        <tr>
          <td valign="top">&nbsp;</td>
        </tr>
        <tr>
          <td valign="top">&nbsp;</td>
        </tr>


      </table></td>
    </tr>


    <tr>
      <td><hr /></td>
    </tr>



    <tr>
      <td height="100" valign="top"><strong>Remarks:</strong>
        <p><strong>{{remarks}}</strong></p>
      </td>
    </tr>

    <tr>
      <td>

    </td>
    </tr>

    <tr>
      <td>&nbsp;</td>
    </tr>
    <tbody>


  </table>
  <div *ngIf="appointmentData?.documentLocation!=null">
    <img *ngIf="showImage==true" [src]="newsrc" alt="" style="height:auto;width: auto;">
    <iframe *ngIf="showPdf==true" [src]="newsrc" class="pdf-viewer" frameborder="0" scrolling="no"
      style="height: 600px;">
      <p>It appears your web browser doesn't support iframes.</p>
    </iframe>
  </div>
  <!-- <div class="header"><table width="100%" border="0" cellpadding="0" cellspacing="0"  >
    <tr>
      <td><img src="https://app.curemetric.com/IVF/assets/images/navya-logo.png" alt="IVF" width="100"/></td>
      <td align="right"><table border="0" cellspacing="0" cellpadding="2">

        <tr>
          <td align="left">&nbsp;</td>
          <td align="left">&nbsp;</td>
        </tr>
        <tr>
          <td align="left"><strong><a target="_blank" data-stringify-link="mailto:vavulurunavya@gmail.com" delay="150" data-sk="tooltip_parent" aria-haspopup="menu" href="mailto:vavulurunavya@gmail.com" rel="noopener noreferrer">vavulurunavya@gmail.com</a></strong></td>
          <td width="30" align="left">&nbsp;</td>
        </tr>
        <tr>
          <td align="left"><strong>+91 94408 98255 </strong></td>
          <td align="left">&nbsp;</td>
        </tr>
        <tr>
          <td align="left"><strong>Chelrasta Center, Chemmangiri Peta, </strong></td>
          <td align="left">&nbsp;</td>
        </tr>
        <tr>
          <td align="left"><strong>Machilipatnam - 521001</strong></td>
          <td align="left">&nbsp;</td>
        </tr>
        <tr>
          <td align="left">&nbsp;</td>
          <td align="left">&nbsp;</td>
        </tr>
      </table></td>
      <td align="right"><strong><br />
          <a target="_blank" data-stringify-link="mailto:vavulurunavya@gmail.com" delay="150" data-sk="tooltip_parent" aria-haspopup="menu" href="mailto:vavulurunavya@gmail.com" rel="noopener noreferrer"></a><br />
          <br />
      </strong></td>
    </tr>
  </table></div> -->

</body>
</html>
</div>
