import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IvfService } from '../services/ivf.service';

@Component({
  selector: 'app-egg-monitor-sheet',
  templateUrl: './egg-monitor-sheet.component.html',
  styleUrls: ['./egg-monitor-sheet.component.css']
})
export class EggMonitorSheetComponent implements OnInit {

  @Input() details:any;

  ovaryScanList= []
  constructor(private globalStorage: GlobalStorageService, public activeModal:NgbActiveModal,private ivfService:IvfService) { }

  ngOnInit(): void {
    if(this.details){
      this.getEggData();
    }
  }

  getEggData(){
    const body={
      ivfAddressId:this.globalStorage.getItem('ivfAddressId'),
      patientId:this.details.patientId,
      casecycleId:this.details.casecycleId
    }
    this.ivfService.oocyteScanDetail(body).subscribe(res=>{
      this.ovaryScanList=res?.data?.ocOocyteDTOs || []
    })
  }

}
