import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { NoMatch } from 'src/app/_helpers/must_match.validators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { pharmaService } from 'src/app/shared/services/pharma.service';

@Component({
  selector: 'app-pharma-payment',
  templateUrl: './pharma-payment.component.html',
  styleUrls: ['./pharma-payment.component.css']
})
export class PharmaPaymentComponent implements OnInit {

  @Input() invoice:any;
  orderArray: any = []
  subtotal:number = 0;
  totalAmount:number = 0;
  taxAmount:number=0;
  totalDiscount:number=0;
  filteredOptions: Observable<any[]>;
  medicineControl = new FormControl();
  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder,private toaster: ToastrService, private pharmaService: pharmaService, public activeModal: NgbActiveModal, private authService: AuthService) { }

  ngOnInit(): void {

    this.paymentMethod=this.cashModes[0].value;

    this.getOrderDetails();

    this.filteredOptions = this.medicineControl.valueChanges
    .pipe(
      startWith(''),
      debounceTime(300),
      switchMap(value => this.fetchFilteredOptions(value)),
      catchError(error => {
        console.error('Error fetching data: ', error);
        return [];
      })
    );
  }

  userFound: boolean = true;

  pharmaInvoiceData:any;
  getOrderDetails() {
    this.pharmaService.getInvoiceDataByPharmacy(this.invoice?.invoiceId).subscribe(res => {
      this.pharmaInvoiceData = res?.['data'];
      this.orderArray = this.pharmaInvoiceData?.['prescriptionprices'];
      this.totalAmount = this.pharmaInvoiceData?.total;
      this.orderArray.forEach((res,index)=>{this.calPrice(index)})
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  fetchFilteredOptions(value: string): Observable<any[]> {

    if (!value.trim()) {
      // If not search term, return empty array.
      return of([]);
    }
    return this.pharmaService.searchDrugByChar(value, this.globalStorage.getItem('pharmacyId')).pipe(
      map(res => res['body']['data']),
      catchError(error => {
        console.error('Error fetching medicines:', error);
        return of([]);
      })
    );
  }

  onDrugSelect(index, drug) {
    this.orderArray[index].inventoryId = drug?.inventoryId;
    this.orderArray[index].type = drug?.drugtype;
    this.orderArray[index].unitPrice=drug?.price;
    this.orderArray[index].quantityAvailable=drug?.quantityavailable;
    this.orderArray[index].rack=drug?.rack;
    this.orderArray[index].cgst=drug?.cgst || 0;
    this.orderArray[index].sgst=drug?.sgst|| 0;
  }

  calPrice(i){
    
    if(this.orderArray[i].unitPrice && this.orderArray[i].quantity){
      let priceAfterGst=1;
      if(this.orderArray[i].sgst>0 || this.orderArray[i].cgst>0){
        priceAfterGst=(((this.orderArray[i].cgst+this.orderArray[i].sgst)/100));
        this.orderArray[i].taxedAmount=priceAfterGst*this.orderArray[i].unitPrice;
      }else{
        this.orderArray[i].taxedAmount=0;
      }
      this.orderArray[i].price=this.orderArray[i].quantity*(this.orderArray[i].taxedAmount+this.orderArray[i].unitPrice);
      this.orderArray[i].totalPrice=this.orderArray[i].quantity*(this.orderArray[i].taxedAmount+this.orderArray[i].unitPrice);
      this.orderArray[i].itemTotalPrice=this.orderArray[i].quantity*(this.orderArray[i].taxedAmount+this.orderArray[i].unitPrice);

      this.calTotals()
    }
  }

  calTotals(){
    this.subtotal = this.orderArray.reduce((a, { quantity,unitPrice }) => a + (quantity*unitPrice), 0);
    this.taxAmount = this.orderArray.reduce((a, { taxedAmount }) => a + parseFloat(taxedAmount), 0);
    this.totalAmount = Number(this.subtotal)+Number(this.taxAmount);
    if(this.totalDiscount>0){
      const discAmt = (this.totalDiscount/100)*this.totalAmount
      this.totalAmount = this.totalAmount-discAmt
    }


  }
  paymentMethod:any;
  cashModes: any[] = [{ id: 1, value: 'CASH' }, { id: 2, value: 'CARD' },{ id: 3, value: 'UPI' }]

  saveOrder(){
    const bodyArr = this.orderArray.filter(res => {
      if (res?.inventoryId && res?.inventoryId != "") {
        return {
          price: res?.price,
          cgst: res?.cgst,
          sgst: res?.sgst,
          prescriptionFillRequestId: res?.prescriptionFillRequestId,

        }
      }
    })
    if(bodyArr.length>0){
      const body={

        discount:0,
        invoiceId:this.invoice?.invoiceId,
        selfPrescriptionFillRequestUpdateDTOs:bodyArr,
        total:this.totalAmount,
        transactiontype:this.paymentMethod
      }

      this.pharmaService.selfPayment(body).subscribe(res => {
        this.toaster.success('Payment Success.')
        this.activeModal.close();
      }, error => {
        this.toaster.error(error?.['error']?.['message'])
      })
    }else{
      this.toaster.error('Please select medicines.')
    }
  }

  addItem() {
    const index=this.orderArray.length;
    if(index==0){
      this.orderArray.push({
        inventoryId: "",
        quantity: 0,
        unitPrice: 0,
        taxedAmount: 0,
        price: 0,
        totalPrice: 0,
        itemTotalPrice: 0,
        type: "",
        rack: "",
        cgst: 0,
        sgst: 0,
        isEditable: true,
        quantityAvailable: 0,
        prescriptionFillRequestId: ""
      })
      return
    }
    
    if (this.orderArray[index - 1].medicine && this.orderArray[index - 1].quantity) {
      if (this.orderArray.length > 0) {
        this.orderArray[index - 1].isEditable = false;
      }

      if (this.orderArray.length == (index)) {
        this.orderArray.push({
          "inventoryId": '',
          "medicineName": "",
          "type": "",
          "numberOfDays": 0,
          "power": "100",
          "quantity": 0,
          "quantityAvailable": 0,
          "rack": "",
          "unitPrice": 0,
          "taxedAmount":0,
          "price": 0,
          "cgst": 0,
          "sgst": 0,
          "discount": 0,
          "itemTotalPrice": 0,
          "isEditable": true
        })
      }
    } else {
      this.toaster.error('Please fill all the fields in current row');
    }
  }

  editItem(index: number) {
    this.orderArray[index].isEditable = true;
  }

  saveItem(index: number) {
    this.orderArray[index].isEditable = false;
  }

  removeItem(index: number): void {
    this.orderArray.splice(index, 1);
  }

}
