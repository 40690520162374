import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PharmaNotificationService } from 'src/app/shared/pharma-notification.service';
import { interval, Subscription } from 'rxjs';
import { pharmaService } from 'src/app/shared/services/pharma.service';

@Component({
  selector: 'app-pharmacy-layout',
  templateUrl: './pharmacy-layout.component.html',
  styleUrls: ['./pharmacy-layout.component.css']
})
export class PharmacyLayoutComponent implements OnInit {
  subscription: Subscription;
  isHome=true;notifications;p1=1;primaryDetailsFromLayout;
  constructor(private globalStorage: GlobalStorageService, private notification:PharmaNotificationService,private orders: pharmaService,) {
   }

  ngOnInit(): void {
    this.notification.requestPermission(this.globalStorage.getItem('pharmacyId'));
    this.notification.receiveMessage();
    this.notification.currentMessage
    this.getNotifications()
    this.getPrimaryDetails()
    const source = interval(1000);
    this.subscription = source.subscribe(val => this.checkHomeOrNot());
  }

  checkHomeOrNot(){
    if(window.location.pathname == '/pharmaDashboard' || window.location.pathname == '/cureMetric/pharmaDashboard'){
      this.isHome = true;
     }
    else{
      this.isHome = false;
    }
  }

    getNotifications() {
      var data = this.globalStorage.getItem('userData')
      var obj = JSON.parse(data)
      this.orders.globalGetPath('notificationalerts/get/all?maxResult=100&pageNumber='+this.p1+'&pharmacyaddressId='+ obj['pharmacyaddressId']).subscribe(res => {
        this.notifications = res['body']['data'].reverse()
      })
    }
    getPrimaryDetails(){
      var data = this.globalStorage.getItem('userData')
      var obj = JSON.parse(data)
      this.orders.GetPathSession('primarydetails?pharmacyuserid=', obj['pharmacyUserId']).subscribe(res => {
        this.orders.saveData(res['body']['data'])
        this.primaryDetailsFromLayout=res['body']['data']
      })
    }
 
}
