<div class="modal-header">
  <h4 class="modal-title">Egg Monitoring Sheet</h4>

  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>

</div>

<div class="modal-body">

  <table width="100%" border="0" cellpadding="0" cellspacing="0" class="table table1 table-bordered">
    <thead>
      <tr>
        <th width="120" valign="top">
          <p align="right"><strong>DATE</strong></p>
        </th>
        <th *ngFor="let scan of ovaryScanList" colspan="2" valign="top">
          <p></p>{{scan?.scanDate | date:'yyyy-MM-dd'}}
        </th>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>DAY OF STIMU</strong></p>
        </th>
        <th *ngFor="let scan of ovaryScanList" colspan="2" valign="top">
          <p align="center">{{scan?.dayOfStimulation}}</p>
        </th>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>OVARY</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <th valign="top">
            <p align="center">L</p>
          </th>
          <th valign="top">
            <p align="center">R</p>
          </th>
        </ng-container>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th valign="top">
          <p align="right"><strong>&ge;21mm</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <td align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.greaterThanTwentyOnemm || ''}}</td>
          <td align="center" valign="middle">{{scan?.oocytescanRightOvaryDTO?.greaterThanTwentyOnemm || ''}}</td>
        </ng-container>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>20mm</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <td align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.twentymm || ''}}</td>
          <td align="center" valign="middle">{{scan?.oocytescanRightOvaryDTO?.twentymm || ''}}</td>
        </ng-container>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>19mm</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <td align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.nineteenmm || ''}}</td>
          <td align="center" valign="middle">{{scan?.oocytescanRightOvaryDTO?.nineteenmm || ''}}</td>
        </ng-container>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>18mm</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <td align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.eighteenmm || ''}}</td>
          <td align="center" valign="middle">{{scan?.oocytescanRightOvaryDTO?.eighteenmm || ''}}</td>
        </ng-container>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>17mm</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <td align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.seventeenmm || ''}}</td>
          <td align="center" valign="middle">{{scan?.oocytescanRightOvaryDTO?.seventeenmm || ''}}</td>
        </ng-container>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>16mm</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <td align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.sixteenmm || ''}}</td>
          <td align="center" valign="middle">{{scan?.oocytescanRightOvaryDTO?.sixteenmm || ''}}</td>
        </ng-container>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>15mm</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <td align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.fifteenmm || ''}}</td>
          <td align="center" valign="middle">{{scan?.oocytescanRightOvaryDTO?.fifteenmm || ''}}</td>
        </ng-container>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>14mm</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <td align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.fourteenmm || ''}}</td>
          <td align="center" valign="middle">{{scan?.oocytescanRightOvaryDTO?.fourteenmm || ''}}</td>
        </ng-container>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>13mm</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <td align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.thirteenmm || ''}}</td>
          <td align="center" valign="middle">{{scan?.oocytescanRightOvaryDTO?.thirteenmm || ''}}</td>
        </ng-container>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>12mm</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <td align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.twelvemm || ''}}</td>
          <td align="center" valign="middle">{{scan?.oocytescanRightOvaryDTO?.twelvemm || ''}}</td>
        </ng-container>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>11mm</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <td align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.elevenmm || ''}}</td>
          <td align="center" valign="middle">{{scan?.oocytescanRightOvaryDTO?.elevenmm || ''}}</td>
        </ng-container>
      </tr>
      <tr>
        <th valign="top">
          <p align="right"><strong>&le;10mm</strong></p>
        </th>
        <ng-container *ngFor="let scan of ovaryScanList">
          <td align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.lessThanTenmm || ''}}</td>
          <td align="center" valign="middle">{{scan?.oocytescanRightOvaryDTO?.lessThanTenmm || ''}}</td>
        </ng-container>
      </tr>
      <tr>
        <td width="154" height="40" align="center" valign="middle"><strong>Endometrium Thickness</strong></td>
        <td *ngFor="let scan of ovaryScanList" colspan="2" align="center" valign="middle">
          {{scan?.endoThickness || ''}}</td>
      </tr>
      <tr>
        <td width="154" height="40" align="center" valign="middle"><strong>Endometrium Cavity Length</strong></td>
        <td *ngFor="let scan of ovaryScanList" colspan="2" align="center" valign="middle">
          {{scan?.endometrial || ''}}</td>
      </tr>
      <tr>
        <td width="154" height="40" align="center" valign="middle"><strong>Uterus</strong></td>
        <td *ngFor="let scan of ovaryScanList" colspan="2" align="center" valign="middle">
          {{scan?.uterus || ''}}</td>
      </tr>
      <tr>
        <td width="154" height="40" align="center" valign="middle"><strong>UCL</strong></td>
        <td *ngFor="let scan of ovaryScanList" colspan="2" align="center" valign="middle">
          {{scan?.ucl || ''}}</td>
      </tr>
      <!-- <tr>
       <td height="40" align="center" valign="middle"></td>
     </tr> -->
      <tr>
        <td height="40" align="center" valign="middle"><strong>INJECTIONS</strong></td>
        <td *ngFor="let scan of ovaryScanList" colspan="2" align="center" valign="middle">
          {{scan?.oocytescanLeftOvaryDTO?.greaterThanTwentyOnemm || ''}}</td>
      </tr>
      <!-- <tr>
       <td height="40" align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.greaterThanTwentyOnemm || ''}}</td>
     </tr> -->
      <tr>
        <td height="40" align="center" valign="middle"><strong>HORMONES</strong></td>
        <td *ngFor="let scan of ovaryScanList" colspan="2" align="center" valign="middle">
          {{scan?.oocytescanLeftOvaryDTO?.greaterThanTwentyOnemm || ''}}</td>
      </tr>
      <tr>
        <td height="40" align="center" valign="middle"><strong>FSH</strong></td>
        <td *ngFor="let scan of ovaryScanList" colspan="2" align="center" valign="middle">
          {{scan?.oocytescanLeftOvaryDTO?.greaterThanTwentyOnemm || ''}}</td>
      </tr>
      <tr>
        <td height="40" align="center" valign="middle"><strong>LH</strong></td>
        <td *ngFor="let scan of ovaryScanList" colspan="2" align="center" valign="middle">
          {{scan?.oocytescanLeftOvaryDTO?.greaterThanTwentyOnemm || ''}}</td>
      </tr>
      <tr>
        <td height="40" align="center" valign="middle"><strong>PROGESTERONE</strong></td>
        <td *ngFor="let scan of ovaryScanList" colspan="2" align="center" valign="middle">
          {{scan?.oocytescanLeftOvaryDTO?.greaterThanTwentyOnemm || ''}}</td>
      </tr>
      <tr>
        <td height="40" align="center" valign="middle"><strong>E2</strong></td>
        <td *ngFor="let scan of ovaryScanList" colspan="2" align="center" valign="middle">
          {{scan?.oocytescanLeftOvaryDTO?.greaterThanTwentyOnemm || ''}}</td>
      </tr>
      <!-- <tr>
       <td height="40" align="center" valign="middle">{{scan?.oocytescanLeftOvaryDTO?.greaterThanTwentyOnemm || ''}}</td>
     </tr> -->
    </tbody>
  </table>

</div>