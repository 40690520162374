import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from '../../services/patient.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { gpsService } from '../../services/gps.service';
import { NewPatientAddressComponent } from '../new-patient-address/new-patient-address.component';

@Component({
  selector: 'app-patient-address-details',
  templateUrl: './patient-address-details.component.html',
  styleUrls: ['./patient-address-details.component.css']
})
export class PatientAddressDetailsComponent implements OnInit {
  @Input() pharmacyInfo:any;
  @Input() userId:any;
  @Output() sendPharmacy=new EventEmitter()

  addressList: any = [];
  selectedIndex: number | null = null;
    primaryAddress: FormGroup;
  zip;postalLocations=[]; countryCodeSelected;
  latitude;
  longitude;
  primarysubmitted=false;selectedValue;formOpen=false
  pharmacyaddressId: any;
  constructor(private fb:FormBuilder,private activeModal:NgbActiveModal,private patientScheduleService:PatientScheduleControlService,
    private toaster:ToastrService,private modal:NgbModal ) { }

  ngOnInit(): void {
    this.getUserAddressList()
    this.pharmacyInfo
  }
  getUserAddressList(){
    var request = {
      "forUserId": this.userId,
      "userId": this.userId
    }
    this.patientScheduleService.globalPost('address/get', request, this.userId).subscribe(res => {
      console.log('address List',res)
      this.addressList=res['data']
    },error=>{
      this.toaster.error(error?.error?.message)
    })
  }
  openAddressForm(){
    this.formOpen=true
  }
  closeModal(){
    this.activeModal.close()
  }
  toggleSelection(address){

  }
  addNewAddress(){
      const addressModal=this.modal.open(NewPatientAddressComponent, {
    centered: false,
    backdrop: 'static',
    keyboard: false,
    windowClass: 'modal-right hw-40'
  });
  addressModal.componentInstance.userId=this.userId
  addressModal.result.then(
    (reason)=>{
      if(reason){
        this.getUserAddressList()
      }
    },
    (result)=>{
     
    }
  )
  }
 



selectAddressId:any;
selectAddress(index: number): void {
  this.selectedIndex = index; 
  const selectedAddress = this.addressList[index];
   this.selectAddressId= selectedAddress.addressId;

}
emitOUt(){
  this.sendPharmacy.emit(this.selectAddressId)
}


}

