import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dc-appointment-booking',
  templateUrl: './dc-appointment-booking.component.html',
  styleUrls: ['./dc-appointment-booking.component.css']
})
export class DcAppointmentBookingComponent implements OnInit {
  @Input() testData:any
  @Input() selectedUserData:any
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    console.log('test data',this.testData)
  }

  bookSlot(event){

  }
  

  appointmentSelected(){}

  closeMyBooking(): void {
    // Logic to handle closing the modal
    this.activeModal.close();
  }
}
