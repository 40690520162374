<div *ngIf="type=='certifications'">
  <div class="d-flex justify-content-between border-bottom">
    <h3 class="mt-2 pt-2 head" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
      aria-controls="collapseOne"> Certification Details
    </h3>
    <div>
      <button class="btn btn-success pull-right add-btn" href="javascript:void(0)" data-toggle="modal"
        data-target="#add-certifications">
        <i class="fa fa-plus" aria-hidden="true"></i> Add
      </button>
    </div>
  </div>

  <div class="modal modal-right fade" id="add-certifications" data-backdrop="static">
    <div class="modal-dialog" style="width:550px">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h5 class="modal-title"> <i class="fa fa-calendar-plus"></i> Add Certification Details</h5>
          <button type="button" class="close text-white" data-dismiss="modal" (click)="closeCModal()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div [formGroup]="addForm" (keyup)="checkValid(addForm)">
            <div class="row">
              <div class="col-md-12 mb-2">
                <input type="text" class="form-control list-item-heading truncate"
                  formControlName="instituteName" placeholder="Institute Name"
                  [ngClass]="{ 'is-invalid': submitted && cf.instituteName.errors}">
                <div *ngIf="submitted && cf.instituteName.errors" class="invalid-feedback">
                  <div *ngIf="cf.instituteName.errors.required">Institute Name is required</div>
                </div>
              </div>

              <div class="col-md-12 mb-2">
                <mat-select placeholder="Select Degree" [(ngModel)]="selectedDegree" formControlName="degree"
                  class="form-control" [ngClass]="{ 'is-invalid': submitted && cf.degree.errors}">
                  <mat-option *ngFor="let certificate of certificatesList" [value]="certificate">
                    {{certificate}}
                  </mat-option>
                </mat-select>

                <div *ngIf="submitted && cf.degree.errors" class="invalid-feedback">
                  <div *ngIf="cf.degree.errors.required">Degree is required <span
                      class="font-size-10 text-muted">(MM/DD/YYYY)</span></div>
                </div>
              </div>

              <div class="col-md-12 position-relative mb-1">
                <input id="matDate" matInput placeholder="mm/dd/yyyy" [min]="minDate" [max]="today"
                  [matDatepicker]="picker" class="form-control" placeholder="Graduation Date"
                  formControlName="yearGraduated" (click)="picker.open()" readonly
                  [ngClass]="{ 'is-invalid': submitted && cf.yearGraduated.errors}">

                <div class="position-datepicker">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>

                <div *ngIf="submitted && cf.yearGraduated.errors" class="invalid-feedback">
                  <div *ngIf="cf.yearGraduated.errors.required">Year Graduated is required</div>
                  <div *ngIf="cf.yearGraduated.errors.max">Enter valid data</div>
                  <div *ngIf="cf.yearGraduated.errors.min">Enter valid data</div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-success btn-sm mt-2" (click)="saveCerification()" [disabled]="isSave"
                    data-placement="top" data-toggle="tooltip" data-original-title="Save"><i class="fa fa-save"
                      aria-hidden="true"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="no-image-bg1" *ngIf="certificationList?.length==0"></div>
  <div *ngFor="let item of certificationList;let index = index ;let  lastIndex=last;let even=even"
    class="card d-flex flex-row mb-3 p-2">
    <div class="d-flex flex-grow-1 min-width-zero">
      <div
        class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
        <input style="display:none" type="text"
          class="form-control tr_{{index}} list-item-heading mb-1 mr-4 truncate w-20 w-xs-100"
          [(ngModel)]="item.instituteName" placeholder="Institute Name" required>
        <p class="lblView_{{index}}  list-item-heading mb-1 truncate w-20 w-xs-100 mr-4" style="margin-bottom: 1px !important;
                padding-bottom: 7px;
                border-bottom: 1px solid;"> {{item.instituteName}} </p>
        <div *ngIf="item.instituteName.errors" class="invalid-feedback">
          <div *ngIf="item.get('instituteName').errors.required">Enter institute Name</div>
        </div>

        <!-- <select class="form-control mb-1 mr-2 addSelect" formControlName="degree" [ngClass]="{ 'is-invalid': submitted && cf.degree.errors}" (change)="certificateChoosed($event.target.value)">
                    <option *ngFor="let certificate of certificatesList">
                        {{certificate}}
                    </option>
                </select> -->

        <select style="display:none;background-color: inherit;"
          class="form-control  mb-1  mr-4 w-30 w-xs-100 tr_{{index}} Myselect" [value]="item.degree"
          [(ngModel)]="item.degree">
          <option *ngFor="let certificate of certificatesList">
            {{certificate}}
          </option>
        </select>


        <select [disabled]="true" [readOnly]="true" class="form-control mb-1 mr-4  w-30 w-xs-100 selectView_{{index}}"
          [(ngModel)]="item.degree" [value]="item.degree">
          <option *ngFor="let certificate of certificatesList">
            {{item.degree}}
          </option>
        </select>


        <p class="lblView_{{index}}  list-item-heading mb-1 truncate w-25 w-xs-100 text-center" style="margin-bottom: 1px !important;
            padding-bottom: 7px;
            border-bottom: 1px solid;"> {{item.yearGraduated | date:'dd-MMM-yyyy'}} </p>
        <input style="display:none" type="text" id="matDate" matInput placeholder="mm/dd/yyyy" readonly [min]="minDate"
          [max]="today" [matDatepicker]="picker" placeholder="Year Graduated"
          class="form-control tr_{{index}} mb-1 text-muted  w-15 w-xs-100 mr-4" (keypress)="numberOnly($event)"
          [(ngModel)]="item.yearGraduated">
        <div class="col-md-1 col-xs-2 " style="margin-left: -12px;">
          <mat-datepicker-toggle style="display:none" class="tr_{{index}}" matSuffix [for]="picker">
          </mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>

        <!-- <div *ngIf="certificationSubmitted && item.get('yearGraduated').errors" class="invalid-feedback">
                    <div *ngIf="item.get('yearGraduated').errors.max">Enter valid Data</div>
                    <div *ngIf="item.get('yrGearaduated').errors.required">Year Graduated required</div>
                 </div>  -->

        <div class="w-15 w-xs-100 text-right mt-1">
          <div class=" float-right">
            <div id="closeEdit_{{index}}" class="  " style="display:none">
              <button type="button" class="btn btn-success  btn-sm mr-2" (click)="updateCertification(item,index)"
                data-placement="top" data-toggle="tooltip" data-original-title="Save"><i
                  class="fa fa-save"></i></button>
              <button type="button" class="btn btn-danger  btn-sm" (click)="closeEdit(index)"><i class="fa fa-close"
                  data-placement="top" data-toggle="tooltip" data-original-title="Cancel"></i></button>
            </div>


            <div id="btnEdit_{{index}}" class="  pr-2  " style="float:right">
              <button class="btn btn-primary btn-sm mr-2" (click)="editButton(index)" data-placement="top"
                data-toggle="tooltip" data-original-title="Edit"><i class="fa fa-edit" data-placement="top"
                  data-toggle="tooltip" data-original-title="Edit"></i></button>
              <button class="btn btn-danger  btn-sm" (click)="deleteCertification(index,item)" data-placement="top"
                data-toggle="tooltip" data-original-title="Delete"><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="type=='associations'">
  <h6 class="mt-2 mb-3 border-bottom pt-2 pb-2 head"> Association Details <button
      class="btn btn-success btn-xs pull-right" href="javascript:void(0)" data-toggle="modal"
      data-target="#add-association" style="display:none;"><i class="fa fa-plus" aria-hidden="true"></i> Add</button>
  </h6>
  <div id="Association">

    <div class="col-md-12 text-center">

    </div>
    <div class="modal modal-right fade" id="add-association">
      <div class="modal-dialog" style="width:550px">
        <div class="modal-content">
          <div class="modal-header bg-primary">
            <h5 class="modal-title"> <i class="fa fa-calendar-plus"></i> Add Association</h5>
            <button type="button" class="close text-white" data-dismiss="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body p-2">





            <div [formGroup]="frmAssociation" class="  d-flex flex-row mb-3 ">
              <div class="d-flex flex-grow-1 min-width-zero">
                <div
                  class="row  w-100 align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">

                  <!-- <p class="list-item-heading mb-1 truncate w-30 w-xs-100">
                        Andhra University </p> -->

                  <!-- <input type="text" placeholder="Enter Association" class="form-control list-item-heading mb-1 truncate w-30 w-xs-100"   formControlName="association" [ngClass]="{ 'is-invalid': (submit2 || fa.association.touched) && fa.association.errors}">
                        <div *ngIf="submit2 && fa.association.errors" class="invalid-feedback">
                            <div *ngIf="fa.association.errors.required">
                                Association Name is required</div>
                            </div> -->

                  <!-- <select id="feeType" class="form-control  w-78 w-xs-100" formControlName="hospitalId" [ngClass]="{ 'is-invalid': (submit2 || fa.hospitalId.touched) && fa.hospitalId.errors}">
                            <option value="" selected hidden disabled>Select association</option>
                                    <option value="1">Hospital 1</option>
                                    <option value="2">Hospital 2</option>
                                    <option value="3">Hospital 3</option>
                                    <option value="4">Hospital 4</option>
                                    <option value="5">Hospital 5</option>
                        </select> -->
                  <div class="col-md-12">
                    <mat-form-field class="example-full-width w-100">
                      <mat-label>Select Association</mat-label>
                      <input type="text" placeholder="Select" class="form-control mat-input-border" aria-label="Number"
                        matInput formControlName="hospitalId" (keyup)="getHos($event.target.value)"
                        [matAutocomplete]="auto"
                        [ngClass]="{ 'is-invalid': (submit2 || fa.hospitalId.touched) && fa.hospitalId.errors}">
                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
                        <mat-option *ngFor="let option of MyAssociation" [value]="option">
                          {{option.hospitalName}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>

                  <!--
                        <div *ngIf="submit2 || fa.hospitalId.touched" class="invalid-feedback">
                        <div *ngIf="fa.hospitalId.errors.required">
                            Select association</div>
                        </div> -->


                  <!-- <p class="mb-1  ">MBBS - Bachelor of Medicin...</p> -->
                  <!-- <p class="mb-1 text-muted  w-15 w-xs-100">18.02.2018</p> -->
                  <div class="w-10 w-xs-100 pr-2 pt-2 text-right" style="flex: auto;">
                    <div class="btn-group float-right">
                      <button class="btn btn-success btn-sm" (click)="saveAssociationDetails()"
                        [disabled]="!fa.hospitalId.valid" data-placement="top" data-toggle="tooltip"
                        data-original-title="Save"><i class="fa fa-save" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>







          </div>





        </div>
      </div>
    </div>


    <div class="no-data-found" *ngIf="associationList?.length==0"></div>
    <div *ngFor="let item of associationList;let index=index" class="card d-flex flex-row mb-3 p-2 ">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div
          class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">


          <!-- <input placeholder="association" *ngIf="edit" type="text"
          class="form-control list-item-heading mb-1 truncate w-30 w-xs-100 tr_view_{{index}}" [disabled]="true"
          [(ngModel)]="item.entityName"> -->
          <!-- <input style="display: none" placeholder="association" type="text"
          class="form-control list-item-heading mb-1 truncate w-30 w-xs-100 tr_edit_{{index}}"
          [(ngModel)]="item.entityId"> -->

          <input class="form-control tr_view_{{index}} mb-1 w-100" [disabled]="true" [value]="item.entiyName" />
          <!-- <option *ngFor="let item of selectMyAssociation" value="{{item.entityId}}">{{item.entiyName}}
          </option>
        </select> -->


          <select style="display:none" class="form-control tr_edit_{{index}} mb-1   w-90 w-xs-100" value="HOSPITAL"
            [(ngModel)]="item.entityId">
            <option *ngFor="let item of selectMyAssociation" value="{{item.hospitalAddressId}}">{{item.hospitalName}}
            </option>
          </select>

          <!-- <p class="form-control list-item-heading mb-1 truncate w-30 w-xs-100 tr_view_{{index}}" [disabled]="true" >{{item.fee}}</p> -->
          <!-- <input  placeholder="Associatin"  type="text" class="form-control list-item-heading mb-1 truncate w-30 w-xs-100 tr_view_{{index}}"  [disabled]="true"  (keypress)="numberOnly($event)" [(ngModel)]="item.fee">
                    <input style="display: none" placeholder="Associatin"  type="text" class="form-control list-item-heading mb-1 truncate w-30 w-xs-100 tr_edit_{{index}}"  (keypress)="numberOnly($event)" [(ngModel)]="item.fee"> -->

          <!-- <div class="w-25 w-xs-100 text-right">
          <div class=" float-right">
            <div class=" tr_view_{{index}}">
              <button class="btn btn-primary btn-sm mr-2" (click)="btnEdit(index)"> <i class="fa fa-edit"></i></button>
              <button class="btn btn-success btn-sm mr-2" (click)="baseSchedule(item,index)"> <i
                  class="fa fa-calendar-alt"></i></button>

            </div>
            <div class="tr_edit_{{index}}" style="display:none">
              <button class="btn btn-success  btn-sm  mr-2" type="button" (click)="updateAssociation(item,index)"
                data-placement="top" data-toggle="tooltip" data-original-title="Save"><i
                  class="fa fa-save"></i></button>
              <button class="btn btn-danger  btn-sm" type="button" (click)="cancelAssociation(index)"
                data-placement="top" data-toggle="tooltip" data-original-title="Cancel"><i
                  class="fa fa-close"></i></button>
            </div>
          </div>
        </div> -->
        </div>
      </div>
    </div>



    <!-- <table style="width:50%;margin-top: 20px;">
            <tr>
                <th width="200px">Association</th>
                <th width="200px">Hospital</th>
                <th><button class="btn btn-success  btn-sm" type="button" (click)="addNewAssociation()">+</button>
                </th>
            </tr>
            <tbody>
            <tr  *ngFor="let item of docCertiGroup.get('association')?.controls;let index = index ;let  lastIndex=last;">
                <td width="200px">
                    <select class="form-control" [formControl]="item.get('associationId')">
                        <option value="1">Association 1</option>
                        <option value="2">Association 2</option>
                    </select>
                </td>
                <td width="200px">
                    <select class="form-control" [formControl]="item.get('hospitalId')">
                        <option value="1">hospital 1</option>
                        <option value="2">hospital 2</option>
                    </select>
                </td>
                <td>
                    <button class="btn btn-danger  btn-sm" type="button" (click)="deleteAssociation(index,item)">-</button>
                </td>
            </tr>
        </tbody>
            </table> -->

  </div>
</div>
