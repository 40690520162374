<div class="modal-header">
    <h4 class="modal-title">Discount Requests</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
 </div>
 <div class="modal-body">
    <div class="">
        <table class="table table-bordered table-hover table-styled table-striped mt-2">
          <thead>
            <tr>
              <th>Patient Name</th>
              <th>Net Amount</th>
              <th>Discount Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of requestList">
              <td>{{item?.patientName}}</td>
              <td scope="row tableview">{{item?.netAmount}} </td>
              <td><input type="text" numeric class="form-control custom-border-none" #amount [value]="item?.amount" placeholder="" formControlName="notfragment"></td>
              <td>
                <button  class="btn btn-success btn-xs" href="javascript:void(0)" data-placement="top"
                  data-toggle="tooltip" data-original-title="Approve" (click)="approve(item,amount?.value)"><i
                    class="fa fa-user-check fs-10"></i></button>
                <button  class="btn btn-danger btn-xs ml-2" data-placement="top" data-toggle="tooltip"
                  data-original-title="Reject" (click)="reject(item)"><i class="fa fa-trash-o"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="requestList?.length==0 || requestList == null">
          <div class="no-data-found"></div>
        </div>
        <!-- <div class="row" *ngIf="requestList?.length>10">
          <div class="col-lg-12 mt-3 text_right ">
            <ul class="pagination">
              <pagination-controls autoHide="true" id="dpgNo" (pageChange)="dpgNo=$event"></pagination-controls>
            </ul>
          </div>
        </div> -->
      </div>
 </div>
