





<div class="schedule-tab ">
  <div class=" ">

    <div class="row backgound_imgsidebar mb-3 hosp">

      <div class="col-md-6 col-sm-12">
        <div class="row text_left padding_left20 padding_bt">


          <div class="col-md-12 col-sm-12 textbig_14white pb-1"> Name :
            <span>{{patientPersonalDetails?.patientName}}</span>  <span *ngIf="patientPersonalDetails?.userType=='rpmUsers'"> ({{patientPersonalDetails?.cellNumber}})</span>
          </div>
          <div class="col-md-12 col-sm-12 textbig_14white pb-1" *ngIf="patientPersonalDetails?.userType=='rpmUsers'"> Clinic Name:
            <span>{{patientPersonalDetails?.clinicName}}</span>  <span> ({{patientPersonalDetails?.clinicHomeNumber}})</span>
          </div>
          <div class="col-md-12 col-sm-12 textbig_14white pb-1"
            *ngIf="doctoraptime && patientPersonalDetails?.AppointmentDetails?.fromTime && !patientPersonalDetails?.isIPD"> Time :
            <span>{{patientPersonalDetails?.AppointmentDetails?.latestAppointmentDate | date:'dd-MMM-yyyy'}}
              {{patientPersonalDetails?.AppointmentDetails?.fromTime|convertFrom24To12Format}} </span> - <span>
              {{patientPersonalDetails?.AppointmentDetails?.toTime|convertFrom24To12Format}} </span>
          </div>
          <div class="col-md-12 col-sm-12 textbig_14white pb-1" *ngIf="hospitalaptime && !patientPersonalDetails?.isIPD && patientPersonalDetails?.ptype!='OPDUser'"> Time :
           <span>{{patientPersonalDetails?.AppointmentDetails?.appointmentDate | date:'dd-MMM-yyyy'}}
              {{patientPersonalDetails?.AppointmentDetails?.fromTime|convertFrom24To12Format}} </span> - <span>
              {{patientPersonalDetails?.AppointmentDetails?.toTime|convertFrom24To12Format}} </span>
          </div>
          <div class="col-md-12 col-sm-12 textbig_14white pb-1" *ngIf="patientPersonalDetails?.ptype=='OPDUser'"> Age : <span
            style="padding-left:37px;">{{patientPersonalDetails?.age}}</span> <span *ngIf="patientPersonalDetails?.userType=='rpmUsers'"> ({{patientPersonalDetails?.dob | date:'dd-MMM-yyyy'}})</span></div>

          <div class="col-md-12 col-sm-12 textbig_14white pb-1"
            *ngIf="patientPersonalDetails?.isIPD=='Y'"> Check in Time :
            <span>
              {{patientPersonalDetails?.checkInTime | date:'dd-MMM-yyyy hh:mm a'}}</span>
          </div>
          <div class="col-md-12 col-sm-12 textbig_14white pb-1" *ngIf="patientPersonalDetails?.isIPD=='N'"> Age : <span
            style="padding-left:37px;">{{patientPersonalDetails?.age}}</span></div>
        </div>
      </div>


      <div class="col-md-3 col-sm-4">
        <div class="row ">
          <div class="col-md-12 col-sm-12 textbig_14white pb-1"> Gender : <span
              style="padding-left:16px;">{{patientPersonalDetails?.gender}} </span></div>
          <div class="col-md-12 col-sm-12 textbig_14white pb-1" *ngIf="patientPersonalDetails?.isIPD!='N' && patientPersonalDetails?.ptype!='OPDUser'"> Age : <span
              style="padding-left:37px;">{{patientPersonalDetails?.age}}</span> <span *ngIf="patientPersonalDetails?.userType=='rpmUsers'"> ({{patientPersonalDetails?.dob | date:'dd-MMM-yyyy'}})</span></div>
        </div>
        <!-- <div class="col-md-12 col-sm-12 textbig_14white pb-1" *ngIf="isIvf && currentUserDetails?.caseDetailsID">Start Cycle :
          <button [disabled]="!currentUserDetails?.caseDetailsID" (click)="onCycleClick(currentUserDetails,'start')"><i
              class="fa-solid fa-circle-plus buttonplus pull-right" style="cursor: pointer;"></i></button>
        </div> -->
        <!-- <div class="col-md-12 col-sm-12 textbig_14white pb-1" *ngIf="isIvf">
          <a *ngIf="!currentCycle?.procedures" [class.disabled-link]="!currentCycle?.simulationDate" href="javascript:void(0)" (click)="onCycleClick(currentUserDetails,'procedure')">
            Start Procedure
          </a>
          <div *ngIf="currentCycle?.procedures" class="col-md-12 col-sm-12 textbig_14white pb-1"> Procedure :
            <span>{{currentCycle?.procedures}}</span>
          </div>
        </div> -->
        <!-- <div class="col-md-12 col-sm-12 textbig_14white pb-1" *ngIf="isIvf">
          <a *ngIf="!currentCycle?.triggerStartDate" [class.disabled-link]="!currentCycle?.procedures" href="javascript:void(0)" (click)="onCycleClick(currentUserDetails,'trigger')">
            Start Trigger
          </a>
          <div *ngIf="currentCycle?.hCGInjection" class="col-md-12 col-sm-12 textbig_14white pb-1"> Injection :
            <span>{{currentCycle?.hCGInjection}}</span>
          </div>
        </div> -->
      </div>

      <div class="col-md-1 col-sm-4"  *ngIf="type=='Doctor'">
        <div class="row ">
          <div class="col-sm-12 col-md-12 text_center" >
            <a href="javascript:void(0)" data-placement="top" data-toggle="tooltip" data-original-title="Support"
              class="btn btn-sm btn-light-secondery ptNull mr-2 mt-1 p-1 nav-btns animated fadeIn"
              *ngIf="patientPersonalDetails?.AppointmentDetails?.chatType=='Video'">
              <i class="fa fa-video themecol4   mt-1  fa-2x" aria-hidden="true"></i> </a>
            <a href="javascript:void(0)" data-placement="top" data-toggle="tooltip" data-original-title="Support"
              class="btn btn-sm btn-light-secondery ptNull mr-2 mt-1 p-1 nav-btns animated fadeIn"
              *ngIf="patientPersonalDetails?.AppointmentDetails?.chatType=='Inperson'">
              <i class="fa-solid fa-hospital-user color_green mt-1  fa-2x"></i> </a>
            <a href="javascript:void(0)" data-placement="top" data-toggle="tooltip" data-original-title="Support"
              class="btn btn-sm btn-light-secondery ptNull mr-2 mt-1 p-1 nav-btns animated fadeIn"
              *ngIf="patientPersonalDetails?.AppointmentDetails?.chatType=='Telephone'">
              <i class="fa fa-phone-alt color_yellow mt-1  fa-2x"></i> </a>
            <a href="javascript:void(0)" data-placement="top" data-toggle="tooltip" data-original-title="Support"
              class="btn btn-sm btn-light-secondery ptNull mr-2 mt-1 p-1 nav-btns animated fadeIn"
              *ngIf="patientPersonalDetails?.AppointmentDetails?.chatType=='Chat'">
              <i class="fa fa-comments color_orange mt-1  fa-2x"> </i> </a>
          </div>
</div>
</div>
<div class="col-md-1 col-sm-4" *ngIf="Diagnostic_center && appointment?.userappointmentId && appointment?.isReportGiven!='Y' && showPrescriptions">
  <div class="row " >
          <div class="col-sm-12 col-md-12 text_center">
            <a>
              <input type="file" id="upload" hidden (change)="onFileInput2($event.target.files,appointment)" />
              <label for="upload" style="border:1px; cursor: pointer;" class="btn btn-sm mr-1 mt-2 p-1" title="Upload">
                <i aria-hidden="true" class="fa fa-upload icon-head patientc2"></i> </label>
            </a>
          </div>
        </div>
        </div>



      <div class="col-md-1" *ngIf="type=='Caretaker'"  >
        <button class="btn btn-sm mr-1 mt-2 p-1">
          <i class="fa-book fa-solid patientc2" data-placement="top" data-toggle="tooltip"
          data-original-title="Caretaker Notes" (click)="viewcaretakerNots(patientPersonalDetails)"></i>
        </button>
      </div>
      <!-- <div class="col-md-1 col-sm-4" style="display: none">
        <div class="row ">
          <div class="col-sm-12 col-md-12 text_center">
            <button data-toggle="modal" data-backdrop="false" data-target="#medicaltests"
              class="btn btn-sm btn-light-secondery  mr-2 mt-1 p-1 nav-btns animated fadeIn">
              <i class="fa fa-microscope themecol4   mt-1  fa-2x" aria-hidden="true" data-placement="top"
                data-toggle="tooltip" data-original-title="Medical Test"></i> </button>
           </div>

        </div>
      </div> -->
<!-- fa-file-prescription *ngIf="prescriptionEnabled"-->
      <div class="col-md-1 col-sm-1" *ngIf="(showPrescriptions || prescriptionEnabled) && patientPersonalDetails?.AppointmentDetails?.appointmentStatus!='ENTITY_CANCELLED' && patientPersonalDetails?.AppointmentDetails?.prescriptionDone!='Y'  && !Diagnostic_center
      && patientPersonalDetails?.AppointmentDetails?.isBedAssign!='Y'">
        <div class="row " *ngIf="!isRpmCaretaker">
          <div class="col-sm-12 col-md-12 text_center">
            <button  (click)="openMedication()"

              class="btn btn-sm btn-light-secondery  mr-2 mt-1 p-1 nav-btns">
              <i class="fa fa-file-prescription themecol2   mt-1  fa-2x" data-placement="top" data-toggle="tooltip"
                data-original-title="Add Prescription" aria-hidden="true"></i> </button>
          </div>

        </div>
      </div>
      <div class="col-md-1 col-sm-1"  *ngIf="showPrescriptions && filled!='Y' && !Diagnostic_center && patientPersonalDetails?.AppointmentDetails?.isBedAssign!='Y'">
        <div class="row " *ngIf="!isRpmCaretaker">
          <div class="col-sm-12 col-md-12 text_center">
            <!-- <button
              *ngIf="prescriptionEnabled"
              (click)="openImagePrescription()" class="btn btn-sm btn-light-secondery  mr-2 mt-1 p-1 nav-btns">
              <i class="fas fa-upload themecol3   mt-1  fa-2x" data-placement="top" data-toggle="tooltip"
                data-original-title="Add Prescription" aria-hidden="true"></i> </button> -->
            <!-- <button
            *ngIf="filled == 'N' && prescriptionEnabled && patientPersonalDetails?.AppointmentDetails?.appointmentDate == today"
              class="btn btn-sm btn-light-secondery  mr-2 mt-1 p-1 nav-btns">
              <i class="fas fa-upload themecol3   mt-1  fa-2x" data-placement="top" data-toggle="tooltip"
                data-original-title="Add Prescription" aria-hidden="true"></i> </button> -->
                <button
              (click)="openImagePrescription()" class="btn btn-sm btn-light-secondery  mr-2 mt-1 p-1 nav-btns">
              <i class="fas fa-upload themecol3   mt-1  fa-2x" data-placement="top" data-toggle="tooltip"
                data-original-title="Add Prescription" aria-hidden="true"></i> </button>
                <!-- <button
            *ngIf="filled == 'N'"
              class="btn btn-sm btn-light-secondery  mr-2 mt-1 p-1 nav-btns">
              <i class="fas fa-upload themecol3   mt-1  fa-2x" data-placement="top" data-toggle="tooltip"
                data-original-title="Add Prescription" aria-hidden="true"></i> </button>  -->
          </div>

        </div>
      </div>

      <div class="col-md-1 col-sm-1" *ngIf="showPrescriptions && patientPersonalDetails?.AppointmentDetails?.patientType == 'OPD'">
        <div class="row ">
          <div class="col-sm-12 col-md-12 text_center">
            <button *ngIf="roles?.roomsPermission"
               class="btn btn-sm btn-light-secondery  mr-2 mt-1 p-1 nav-btns" (click)="openRooms()">


               <i class="fas fa-bed themecol4   mt-1  fa-2x" data-placement="top" data-toggle="tooltip"
                data-original-title="Assign Bed" aria-hidden="true"></i> </button>

          </div>

        </div>
      </div>




      <div *ngIf="patientPersonalDetails?.Appointment=='Current' && patientPersonalDetails?.AppointmentDetails?.chatType=='Video' "  class="col-md-1 col-sm-4">
        <div class="row ">
          <div class="col-sm-12 col-md-12 text_center">
            <a href="javascript:void(0)" style="display:none" (click)="OpenVideoCalling(patientPersonalDetails)"
              id="vdo" data-placement="top" data-toggle="tooltip" data-original-title="Join Now"><img
                src="assets/img/video_01.png"> </a>
          </div>

        </div>
      </div>

    </div>











    <!-- Slot listing 5 end -->
  </div>

  <ul class="nav nav-tabs nav-stacked " id="nav-tabs">
    <li class="active" *ngIf="patDetailsTab">
      <a id="startTab" class="animated fadeIn active show dtsTab" href="#patdetails" data-toggle="tab"
        (click)="tabPatientDetails()">
        <i class="fa fa-user themecold5 icon-head"></i><br>
        <span class="tab-head">
          <span class="tab-text-title">Patient Details</span>
        </span>
      </a>
    </li>

    <li  *ngIf="medHistory">
      <a class="animated fadeIn medicalhistory" href="#medicalhistory" data-toggle="tab" (click)="tabMedicalHistory()">
        <i class="fa fa-file-medical-alt themecold5 icon-head"></i><br>
        <span class="tab-head">
          <span class="tab-text-title">Medical History</span>
        </span>
      </a>
    </li>
    <li  *ngIf="isDoctor">
      <a class="animated fadeIn prescriptions" href="#prescriptions" data-toggle="tab" (click)="tabPrescriptions()">
        <i class="fa fa-file-medical-alt themecold5 icon-head"></i><br>
        <span class="tab-head">
          <span class="tab-text-title">Prescriptions</span>
        </span>
      </a>
    </li>
    <li class="" *ngIf="priorAppointmentTab">
      <a class="animated fadeIn preior-appointment" href="#tab_three" data-toggle="tab"
        (click)="tabPrescriptionAndPrior(patientPersonalDetails?.Appointment)">
        <i class="fa fa-calendar-check-o themecold5 icon-head"></i><br>
        <span class="tab-head">
          <span class="tab-text-title" *ngIf="patientPersonalDetails?.Appointment!='Current'"> Prior Appointments</span>
          <span class="tab-text-title" *ngIf="patientPersonalDetails?.Appointment=='Current' " (click)="getPatientChange()">Prescription</span>
        </span>
      </a>
    </li>

    <li *ngIf="previousAppointmentTab">
      <a class="animated fadeIn previousapp" href="#previousapp" data-toggle="tab"
        (click)="tabPrevious(patientPersonalDetails?.AppointmentType)">
        <i class="fa fa-calendar-check-o themecold5 icon-head"></i><br>
        <span class="tab-head">
          <span class="tab-text-title" *ngIf="patientPersonalDetails?.AppointmentType!='Previous'"> Previous
            Appointments</span>
        </span>
      </a>
    </li>

    <!-- current day medication -->
    <li *ngIf="patDetailsTab && !isRpmCaretaker">
      <a class="animated fadeIn daymedication" href="#dayMedication" data-toggle="tab"
      (click)="getTodayMedications()">
        <i class="fa fa-file-medical-alt themecold5 icon-head"></i><br>
        <span class="tab-head">
          <span class="tab-text-title">Current Day Medication</span>
        </span>
      </a>
    </li>
    <!-- current day medication /-->

    <li *ngIf="inPatientTab">
      <a class="animated fadeIn" href="#inPatientRecord" data-toggle="tab" aria-expanded="true"
        (click)="inPatientRecord()">
        <i class="fa fa-file-pdf-o icon-head themecold5"></i><br>
        <span class="tab-head">
          <span class="tab-text-title">In-Patient Records </span>
        </span>
      </a>
    </li>

    <li *ngIf="callRecordsTab && isRpmCaretaker">
      <a class="animated fadeIn rpmusers" href="#callRecords" data-toggle="tab" aria-expanded="true"
        (click)="rpmCallRecord()">
        <i class="fa fa-file-pdf-o icon-head themecold5"></i><br>
        <span class="tab-head">
          <span class="tab-text-title">Call Records </span>
        </span>
      </a>
    </li>
  </ul>

  <div class="tab-content pl-3 pr-3 pt-1">
    <div class="tab-pane active myclick" id="patdetails">


      <app-active-medication [patientMedication]="patientMedication"></app-active-medication>
      <app-vitals [patientVitals]="patientVitals"></app-vitals>

    </div>
    <!-- Tab pane end -->

    <!-- Tab pane 1 end -->
    <div class="tab-pane myclick2Tab" id="medicalhistory">
      <app-medical-history *ngIf="isMedicalHistory" [patientPersonalDetails]="patientPersonalDetails">
      </app-medical-history>
    </div>
    <!-- Tab pane 2 end -->

    <div class="tab-pane pat-prescription" id="prescriptions" >
      <app-patient-prescrtions *ngIf="isPrescriptionClicked" [userId]="patientPersonalDetails?.AppointmentDetails?.userId"></app-patient-prescrtions>
    </div>

    <div class="tab-pane removecls" id="tab_three">
      <app-previous-appointment-list *ngIf="isPrevious && isPrescriptionAndPrior"
        [patientPreviousAppointmentList]="patientPreviousAppointmentList"></app-previous-appointment-list>
      <app-view-prescription *ngIf="!isPrevious && isPrescriptionAndPrior"
        [prescriptionListView]="prescriptionListView"></app-view-prescription>

    </div>
    <!-- Tab pane 3 end -->
    <!-- Tab pane 5 end -->
    <div class="tab-pane pre-appoint" id="previousapp">
      <app-previous-appointment-list *ngIf="isPreviousAppointments && patientPreviousAppointmentList"
        [patientPreviousAppointmentList]="patientPreviousAppointmentList"></app-previous-appointment-list>
    </div>

    <div class="tab-pane myclick1" id="tab_four">
      <div class="col-12 list">
        <!-- <app-patient-medical-records *ngIf="isMedicalReports" [patientPersonalDetails]="patientPersonalDetails"></app-patient-medical-records> -->
        <app-medical-reports-list *ngIf="isMedicalReports" [patient]="patientPersonalDetails">
        </app-medical-reports-list>
      </div>
    </div>

    <div *ngIf="patientPersonalDetails" class="tab-pane caseDetails" id="tab_caseDetails">
      <app-ivf-case-details *ngIf="patientPersonalDetails"
        [patientPersonalDetails]="patientPersonalDetails" ></app-ivf-case-details>
    </div>


    <!-- currentday medication tab -->
    <div class="tab-pane" id="dayMedication" *ngIf="todayMedication">
      <div class="cure-tabtab">
        <table class="table table-striped table-styled table-hover">
          <thead>
            <tr>
              <th scope="col">Medicine Name</th>
              <th scope="col">Doctor Name</th>
              <th scope="col"> Visit Date</th>
              <th scope="col"> <i class="fas fa-cloud-sun"></i> &nbsp; Morning </th>
              <th scope="col"><i class="fas fa-sun"></i> &nbsp; Afternoon </th>
              <th scope="col"><i class="fas fa-cloud-moon"></i> &nbsp; Evening </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="(activeMedicationList) as result">
              <tr *ngFor="let medicine of result | paginate: { id: 'pagemed', itemsPerPage: 8, currentPage: pagemed }">
                <td> &nbsp; &nbsp; {{medicine?.medicineName}}</td>
                <td><strong>{{medicine?.doctorName}}</strong></td>
                <td><strong>{{medicine?.appointmentDate | date:'dd-MMM-yyyy'}}</strong></td>
                <td>
                  <strong>
                    <i class="fas fa-check blue_bc mr-2" *ngIf="medicine?.morning?.dosageConsumed=='Y'"></i>
                  </strong>
                  <strong>
                    <i class="fas fa-times delete_red_bc" *ngIf="medicine?.morning?.dosageConsumed=='N'"></i>
                  </strong>
                </td>
                <td>
                  <strong>
                    <i class="fas fa-check blue_bc mr-2"
                      *ngIf="medicine?.afternoon?.dosageConsumed=='Y'"></i>
                  </strong>
                  <strong>
                    <i class="fas fa-times delete_red_bc"
                      *ngIf="medicine?.afternoon?.dosageConsumed=='N'"></i>
                  </strong>
                </td>
                <td>
                  <strong>
                    <i class="fas fa-check blue_bc mr-2"
                      *ngIf="medicine?.evening?.dosageConsumed=='Y'"></i>
                  </strong>
                  <strong>
                    <i class="fas fa-times delete_red_bc"
                      *ngIf="medicine?.evening?.dosageConsumed=='N'"></i>
                  </strong>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>

        <div *ngIf="activeMedicationList?.length==0">
          <div class="no-medication"></div>
        </div>

        <div *ngIf="activeMedicationList?.length > 8">
          <div class="text-right mt-2">
            <ul class="pagination">
              <pagination-controls autoHide="true" id="pagemed" (pageChange)="pagemed=$event"></pagination-controls>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- currentday medication tab /-->

    <div class="tab-pane rpmCallRecords" id="callRecords">
      <button id="appointmentButton" class="btn btn-success btn-xs mb-2 float-right callBtn" (click)="startCall()"
        *ngIf="patientPersonalDetails?.AppointmentDetails?.callDone=='N'">Start Call</button>
      <!-- <button id="appointmentButton" class="btn btn-success btn-xs mb-2 float-right callBtn" (click)="openQndA()"
        *ngIf="patientPersonalDetails?.AppointmentDetails?.callDone=='Y'">Open Q and A</button> -->
      <table class="table table-bordered table-hover table-styled table-striped mt-2" role="grid"
        aria-describedby="example2_info">
        <thead>
          <tr role="row">
            <th>Patient Name</th>
            <th>Caretaker Name</th>
            <th>Call Start Time</th>
            <th>Call End Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="odd" data-id="9"
            *ngFor="let item of callDetails | paginate: { id: 'page1', itemsPerPage: 8, currentPage: page1 }">
            <td>{{item?.patientName}}</td>
            <td>{{item?.caretakerName}}</td>
            <td>{{item?.callStartDate| date:'dd-MMM-yyyy hh:mm a'}}</td>
            <td>{{item?.callEndDate| date:'dd-MMM-yyyy hh:mm a'}}</td>
            <td><button class="btn  icon-button" data-original-title="View" data-placement="top" data-toggle="tooltip"
                (click)="viewCallData(item)"><i aria-hidden="true" class="fa fa-eye themecol1"
                  tabindex="0"></i></button></td>
          </tr>
        </tbody>
      </table>
      <div class="no-reports" *ngIf="callDetails?.length == 0 || callDetails==null"></div>

      <div *ngIf="callDetails?.length > 8">
        <div class="text-right mt-3">
          <ul class="pagination">
            <pagination-controls autoHide="true" id="page1" (pageChange)="page1=$event"></pagination-controls>
          </ul>
        </div>
      </div>

    </div>

    <div class="tab-pane removecls" id="inPatientRecord" *ngIf="isHospital">

      <ul class="nav nav-tabs navhos navlistscroll nav-justified">

        <li class="nav-item ">
          <a href="#prescriptions" class="medication active show" data-toggle="tab" aria-expanded="true"><i
              class="fas fa-file-prescription" aria-hidden="true"></i> Prescriptions</a>
        </li>

        <li class="nav-item">
          <a href="#labinvestigation" data-toggle="tab" aria-expanded="true"><i class="fas fa-diagnoses"></i> Lab
            Reports</a>
        </li>


        <li class="nav-item">
          <a href="#operationtheatre" class="operationtheatre" data-toggle="tab" aria-expanded="true"><i
              class="fas fa-cut" aria-hidden="true"></i> Surgeries</a>

        </li>


        <li class="nav-item">
          <a href="#rooms" data-toggle="tab" aria-expanded="true"><i class="fas fa-donate"></i> Rooms</a>
        </li>



        <li class="nav-item">
          <a href="#emergency" data-toggle="tab" aria-expanded="true"><i class="fas fa-hourglass-half"></i>
            Emergency</a>
        </li>
        <li class="nav-item">
          <a href="#payment" data-toggle="tab" aria-expanded="true"><i class="fas fa-hand-holding-usd"></i> Payments</a>
        </li>
      </ul>
      <div class="tab-content">
        <div id="prescriptions" class="tab-pane active">
          <div class="row list">
            <div class="col-md-3 mb-2" *ngFor=" let reports of hospitalPrescriptions">
              <div class="d-flex flex-row mb-0 p-1    blt-1 card " (click)="viewPrescription(reports,'medicine')"
                [ngClass]="{' blt-3': getClass(reports.doctorAppointmentId), ' blt-1':reports.doctorName!='self' }">
                <a href="javascript:void(0)" class="p-2 icon-appointments mt-1 " *ngIf="reports.doctorName=='self'"
                  data-placement="top">
                  <i class="fa fa-user themecol3 fa-2x" aria-hidden="true"></i>
                </a>
                <a href="javascript:void(0)" class="p-2 icon-appointments mt-1 " *ngIf="reports.doctorName!='self'"
                  data-placement="top" data-toggle="tooltip" data-original-title="View">
                  <i class="fa fa-user-md themecol1 fa-2x" aria-hidden="true"></i>
                </a>
                <div class="pl-1 pr-2 d-flex  flex-column flex-grow-1 mr-2 ">
                  <a href="javascript:void(0)" href="javascript:void(0)">
                    <p class="font-weight-medium mb-1 mt-1">{{reports.doctorName | titlecase}}
                    </p>
                    <p class="text-muted mb-1 text-small"> {{ reports.appointmentDate| date: "dd-MMM-yyyy" }}</p>
                  </a>
                </div>
                <div class=" float-right  ">
                  <div><span class="date-col">
                      <div class="fixedtag bg-red" *ngIf="reports?.prescriptionFill == 'N'"
                        (click)="viewPrescription(reports,'medicine')">Not Filled</div>
                      <div class="fixedtag" *ngIf="reports?.prescriptionFill == 'Y'"
                        (click)="viewPrescription(reports,'medicine')">
                        Filled</div>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="operationtheatre" class="tab-pane fade">
          <form [formGroup]="surgeryForm" autocomplete="off" class="mt-4">
            <div class="row">
              <div class="col-md-4 form-group">
                <label for="exampleInputPassword1">Surgery Name</label><span class="text-danger">*</span>
                <input type="text" formControlName="surgeryName" class="form-control mb-0"
                  style="text-transform: capitalize;" [ngClass]="{
                      'is-invalid':
                        (submitted || f.surgeryName.touched) && f.surgeryName.errors
                    }" placeholder="Enter Surgery Name" />

                <div *ngIf="(submitted || f.surgeryName.touched) && f.surgeryName.errors" class="invalid-feedback">
                  <div *ngIf="f.surgeryName.errors.required">
                    Surgery Name is required
                  </div>
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label for="exampleInputPassword1">Surgery Type</label><span class="text-danger">*</span>
                <mat-select class="form-control" name="item" formControlName="surgeryType" id="surgeryType"
                  placeholder="Select Surgery Type" [ngClass]="{
                            'is-invalid': (submitted || f.surgeryType.touched) && f.surgeryType.errors
                            }">
                  <mat-option *ngFor="let item of surgeryTypes" [value]="item.value">
                    {{item.value}}
                  </mat-option>
                </mat-select>
                <!-- <input type="text" formControlName="surgeryType" class="form-control mb-0"
                    (keypress)="alphaNumarics($event)" [ngClass]="{
                      'is-invalid':
                        (submitted || f.surgeryType.touched) && f.surgeryType.errors
                    }" placeholder="Enter Surgery Type" /> -->
                <div *ngIf="(submitted || f.surgeryType.touched) && f.surgeryType.errors" class="invalid-feedback">
                  <div *ngIf="f.surgeryType.errors.required">
                    Surgery Type is required
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group ">
                  <label for="exampleInputEmail1">Doctor Name <span style="color:red">*</span></label>
                  <mat-select class="form-control mb-0" placeholder="Select Doctor" formControlName="doctorName"
                    [ngClass]="{ 'is-invalid': (submitted || f.doctorName.touched) && f.doctorName.errors}">
                    <mat-option *ngFor="let item of doctorList" [value]="item?.doctorId">
                      {{item.firstName}} {{item.lastName}}</mat-option>
                  </mat-select>
                  <div *ngIf="(submitted || f.doctorName.touched) && f.doctorName.errors" class="invalid-feedback">
                    <div *ngIf="f.doctorName.errors.required">Doctor Name is required</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label for="exampleInputPassword1">Description</label><span class="text-danger">*</span>
                <input type="text" formControlName="description" class="form-control mb-0"
                  (keypress)="alphaNumarics($event)" [ngClass]="{
  'is-invalid':
    (submitted || f.description.touched) && f.description.errors
}" placeholder="Enter Description" />

                <div *ngIf="(submitted || f.description.touched) && f.description.errors" class="invalid-feedback">

                  <div *ngIf="f.description.errors.required">
                    Description is required
                  </div>
                </div>
              </div>
              <div class="col-md-4 form-group">
                <label for="exampleInputPassword1">Price</label><span class="text-danger">*</span>
                <input type="text" autocomplete="nope" formControlName="price" id="directLink"
                  class="form-control mb-0 inr" [ngClass]="{
                      'is-invalid':
                        (submitted || f.price.touched) && f.price.errors
                    }" placeholder="Enter Price" />

                <div *ngIf="(submitted || f.price.touched) && f.price.errors" class="invalid-feedback">

                  <div *ngIf="f.price.errors.required">
                    Price required
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="card">
            <div class="card-body">
              <div class="association">
                <h3 class="mt-2 mb-3 border-bottom pt-2 ml-2 pb-2 head"><i class="fa fa-chart-bar themecol2 icon-head"
                    aria-hidden="true"></i> Surgery Details
                </h3>
              </div>
              <table class="table table-bordered table-hover table-styled table-striped mt-2" role="grid"
                aria-describedby="example2_info">
                <thead>
                  <tr role="row">
                    <th width="">Surgery Name</th>
                    <th width="">Surgery Type</th>
                    <th width="">Doctor Name </th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="inPatientSurgeries?.length==0">
                    <td colspan="6">
                      <div class="no-reports"></div>
                    </td>
                  </tr>
                  <tr class="odd" data-id="9" *ngFor="let surgery of inPatientSurgeries">
                    <td>{{surgery?.surgeryName}}</td>
                    <td>{{surgery?.surgeryType}} </td>
                    <td>{{surgery?.doctorName}}</td>
                    <td>{{surgery?.price}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div id="rooms" class="tab-pane fade">
          <form [formGroup]="roomForm">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group ">
                  <label for="exampleInputEmail1">Patient Name <span style="color:red">*</span></label>
                  <input type="text" autocomplete="nope" formControlName="patientName" id="directLink"
                    class="form-control mb-0 inr" [ngClass]="{
                      'is-invalid':
                        (submitted || r.patientName.touched) && r.patientName.errors
                    }" placeholder="Enter Patient Name" />
                  <div *ngIf="(submitted || r.patientName.touched) && r.patientName.errors" class="invalid-feedback">

                    <div *ngIf="r.patientName.errors.required">
                      Patient name required
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group ">
                  <label for="exampleInputEmail1">Bed number <span style="color:red">*</span></label>
                  <input type="text" autocomplete="nope" formControlName="bedNumber" id="directLink"
                    class="form-control mb-0 inr" [ngClass]="{
                      'is-invalid':
                        (submitted || r.bedNumber.touched) && r.bedNumber.errors
                    }" placeholder="Enter Bed Number" />
                  <div *ngIf="(submitted || r.bedNumber.touched) && r.bedNumber.errors" class="invalid-feedback">
                    <div *ngIf="r.bedNumber.errors.required">
                      Bed number name required
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group ">
                  <label for="exampleInputEmail1">Price <span style="color:red">*</span></label>
                  <input type="text" autocomplete="nope" formControlName="price" id="directLink"
                    class="form-control mb-0 inr" [ngClass]="{
                      'is-invalid':
                        (submitted || r.price.touched) && r.price.errors
                    }" placeholder="Enter Price" />
                  <div *ngIf="(submitted || r.price.touched) && r.price.errors" class="invalid-feedback">
                    <div *ngIf="r.price.errors.required">
                      price required
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>


        <div id="payment" class="tab-pane fade">

        </div>
      </div>
    </div>
    <!-- Tab pane end -->

  </div>
  <!-- tab content -->

</div>

<!-- <app-precription [patientPersonalDetails]="patientPersonalDetails" *ngIf="isMedication"></app-precription> -->

<div class="modal fade modal-right" id="medicaltests" role="dialog" data-backdrop="static"
  data-keyboard="false" aria-labelledby="medicaltests" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:60%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Medical Tests</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">


          <div class="w-30 form-group pr-4 pl-3">
            <input type="" class="form-control mb-0" placeholder="Name of Test">


          </div>

          <div class="w-40 form-group pr-4">
            <input type="" class="form-control mb-0" placeholder="Instruction">


          </div>

          <div class="w-15 form-group pr-4">
            <input type="" class="form-control mb-0" placeholder="Reference">

          </div>


          <div class=" w-15 btn-group form-group pr-2 text-right">
            <button class="btn btn-success btn-sm pull-right"><i class="fa fa-save" aria-hidden="true"></i></button>

            <button class="btn btn-danger btn-sm pull-right" data-dismiss="modal" aria-label="Close"><i
                class="fa fa-save" aria-hidden="true"></i></button>
          </div>


          <div class="col-12 list" style="display:none">
            <div class="card d-flex flex-row mb-0 p-2 border-bottom w-33 float-left  blt-2 mb-2 mt-1"><a
                class="p-2 icon-appointments  " href="javascript:void(0)"><i aria-hidden="true"
                  class="fa fa-heart themecol1 icon-reports"></i></a>
              <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 "><a href="javascript:void(0)">
                  <p class="font-weight-medium mb-1 mt-1">Blood Report </p>
                  <p class="text-muted mb-1 text-small">17.09.2018 - 04:45</p>
                </a></div>
              <div class="btn-group float-right"><button class="btn  btn-xs btn0col2"><i aria-hidden="true"
                    class="fa fa-edit"></i></button><button class="btn btn-xs patientc4" data-backdrop="static"
                  data-target="#viewpdf" data-toggle="modal"><i aria-hidden="true"
                    class="fa fa-window-close"></i></button></div>
            </div>

            <div class="card d-flex flex-row mb-0 p-2 border-bottom w-33 float-left  blt-2 mb-2 mt-1"><a
                class="p-2 icon-appointments  " href="javascript:void(0)"><i aria-hidden="true"
                  class="fa fa-tooth themecol2 icon-reports"></i></a>
              <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 "><a href="javascript:void(0)">
                  <p class="font-weight-medium mb-1 mt-1">Blood Report </p>
                  <p class="text-muted mb-1 text-small">17.09.2018 - 04:45</p>
                </a></div>
              <div class="btn-group float-right"><button class="btn  btn-xs btn0col2"><i aria-hidden="true"
                    class="fa fa-edit"></i></button><button class="btn btn-xs patientc4" data-backdrop="static"
                  data-target="#viewpdf" data-toggle="modal"><i aria-hidden="true"
                    class="fa fa-window-close"></i></button></div>
            </div>

            <div class="card d-flex flex-row mb-0 p-2 border-bottom w-33 float-left  blt-2 mb-2 mt-1"><a
                class="p-2 icon-appointments  " href="javascript:void(0)"><i aria-hidden="true"
                  class="fa fa-virus themecol3 icon-reports"></i></a>
              <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 "><a href="javascript:void(0)">
                  <p class="font-weight-medium mb-1 mt-1">Blood Report </p>
                  <p class="text-muted mb-1 text-small">17.09.2018 - 04:45</p>
                </a></div>
              <div class="btn-group float-right"><button class="btn  btn-xs btn0col2"><i aria-hidden="true"
                    class="fa fa-edit"></i></button><button class="btn btn-xs patientc4" data-backdrop="static"
                  data-target="#viewpdf" data-toggle="modal"><i aria-hidden="true"
                    class="fa fa-window-close"></i></button></div>
            </div>

            <div class="card d-flex flex-row mb-0 p-2 border-bottom w-33 float-left  blt-2 mb-2 mt-1"><a
                class="p-2 icon-appointments  " href="javascript:void(0)"><i aria-hidden="true"
                  class="fa fa-brain themecol4 icon-reports"></i></a>
              <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 "><a href="javascript:void(0)">
                  <p class="font-weight-medium mb-1 mt-1">Blood Report </p>
                  <p class="text-muted mb-1 text-small">17.09.2018 - 04:45</p>
                </a></div>
              <div class="btn-group float-right"><button class="btn  btn-xs btn0col2"><i aria-hidden="true"
                    class="fa fa-edit"></i></button><button class="btn btn-xs patientc4" data-backdrop="static"
                  data-target="#viewpdf" data-toggle="modal"><i aria-hidden="true"
                    class="fa fa-window-close"></i></button></div>
            </div>

            <div class="card d-flex flex-row mb-0 p-2 border-bottom w-33 float-left  blt-2 mb-2 mt-1"><a
                class="p-2 icon-appointments  " href="javascript:void(0)"><i aria-hidden="true"
                  class="fa fa-lungs themecol1 icon-reports"></i></a>
              <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 "><a href="javascript:void(0)">
                  <p class="font-weight-medium mb-1 mt-1">Blood Report </p>
                  <p class="text-muted mb-1 text-small">17.09.2018 - 04:45</p>
                </a></div>
              <div class="btn-group float-right"><button class="btn  btn-xs btn0col2"><i aria-hidden="true"
                    class="fa fa-edit"></i></button><button class="btn btn-xs patientc4" data-backdrop="static"
                  data-target="#viewpdf" data-toggle="modal"><i aria-hidden="true"
                    class="fa fa-window-close"></i></button></div>
            </div>

            <div class="card d-flex flex-row mb-0 p-2 border-bottom w-33 float-left  blt-2 mb-2 mt-1"><a
                class="p-2 icon-appointments  " href="javascript:void(0)"><i aria-hidden="true"
                  class="fa fa-microscope themecol3 icon-reports"></i></a>
              <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 "><a href="javascript:void(0)">
                  <p class="font-weight-medium mb-1 mt-1">Blood Report </p>
                  <p class="text-muted mb-1 text-small">17.09.2018 - 04:45</p>
                </a></div>
              <div class="btn-group float-right"><button class="btn  btn-xs btn0col2"><i aria-hidden="true"
                    class="fa fa-edit"></i></button><button class="btn btn-xs patientc4" data-backdrop="static"
                  data-target="#viewpdf" data-toggle="modal"><i aria-hidden="true"
                    class="fa fa-window-close"></i></button></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade modal-right" id="prescriptionImage" tabindex="1" role="dialog" data-backdrop="static"
  data-keyboard="false" aria-labelledby="prescription" aria-hidden="true" style="overflow-y: auto">
  <div class="modal-dialog" role="document" style="min-width:53%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title ">Add Prescription</h5>
        <div class="position-absolute card-top-buttons">
          <div class="w-100 btn-group form-group mt-2">


            <!-- <button type="submit" class=" btn btn-success btn-xs" (click)="OnSubmit(documentGrp.value)"><i
        class="fa fa-file-export"></i>
      Save Prescription
    </button> -->

          </div>
        </div>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="p-2 ">

          <div class="row">



            <div class="col-md-12">
              <div class="block inv-section p-2">
                <div class="top-inv-col">
                  <div class="inv-logo"><img alt="" src="./assets/img/logo.png"></div>
                  <div class="float-right text-left order-details">
                    <!-- <p>Prescripiton ID: <span>73</span></p> -->
                    <p>Date: <span>{{patientPersonalDetails?.AppointmentDetails?.appointmentDate |
                        date:'dd-MMM-yyyy'}}</span></p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 col-xs-6">
                    <h5>Prescribed by</h5>
                    <ul class="inv-receiver">
                      <li class="">Dr {{loginUser?.firstName}} {{loginUser?.lastName}} <br>{{dstreet1}} {{dstreet2}}
                        {{dcity}} <br>{{dstate}} {{dcountry}}<br> {{dzip}} </li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-xs-6 text-right">
                    <h5>Prescribed to</h5>
                    <ul class="inv-receiver">
                      <li class="">{{patientName}} <br>{{pstreet1}} {{pstreet2}} {{pcity}} <br>{{pstate}}
                        {{pcountry}}<br>{{pzip}} </li>
                    </ul>
                  </div>

                </div>

                <div class="card no-bg">




                  <h3 class="mt-0 p-2 border-bottom font-size-12 bg-primary-light"><i class="fas fa-upload "></i> Upload
                    Prescripiton</h3>

                  <div class="row">
                    <div class="col-md-4">

                      <div class="form-group inputDnD">
                        <label class="sr-only" for="inputFile">File Upload</label>

                        <input type="file" #fileInput (change)="onFileInput($event.target.files,'Medical Prescripiton')"
                          class="form-control-file text-primary " id="inputFile" accept="image/*"
                          onchange="readUrl(this)" data-title='Medical Prescripiton'>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="form-group inputDnD">
                        <label class="sr-only" for="inputFile">File Upload</label>
                        <input type="file" #fileInput (change)="onFileInput($event.target.files,'Test Prescription')"
                          class="form-control-file text-primary " id="inputFile" accept="image/*"
                          onchange="readUrl(this)" data-title="Test Prescription">
                      </div>

                    </div>

                    <div class="col-md-4">

                      <div class="form-group inputDnD">
                        <label class="sr-only" for="inputFile">File Upload</label>
                        <input type="file" #fileInput
                          (change)="onFileInput($event.target.files,'Medical & Test Prescripiton')"
                          class="form-control-file text-primary " id="inputFile" accept="image/*"
                          onchange="readUrl(this)" data-title="Medical & Test Prescripiton">
                      </div>
                    </div>

                  </div>




                </div>


                <div class=" text-right">
                  <button class="btn btn-success btn-xs mr-2" (click)="savePrescriptionImage()"><i
                      class="fas fa-save"></i> Save</button>
                  <!-- <button class="btn btn-primary btn-xs mr-2"><i class="fas fa-eye"></i> Preview</button> -->
                  <button class="btn btn-danger btn-xs" (click)="addPrescripClose()"><i class="fas fa-times"></i> Cancel</button>
                </div>




              </div>
            </div>














          </div>








        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade modal-left" id="patientNote" role="dialog" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog" role="document" style="min-width:55%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Caretaker Notes</h5>
        <button type="button" class="close" aria-label="Close" (click)="closepatientNote()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card p-3 mb-2" *ngFor="let note of notesData">
          <div class="row">
            <div class="col-lg-12">
              <div class="notsall">
                <p class="mb-0">{{note?.notes}}</p>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="notesData?.length==0">
          <div class="no-data-found"></div>
        </div>
      </div>
    </div>
  </div>
</div>
