<div class="modal-header">
    <h4 class="modal-title">Edit Staff Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
<div class="modal-body">
    <div class="mt-0 p-2 card">
        <form [formGroup]="associateUser" id="myform" autocomplete="off" class="mt-0" (keyup)="checkForm(associateUser)">
        <div class="error" *ngIf="invalidCredentials">
            {{message}}
        </div>
        <div class="error" *ngIf="isPhoneNumberExists">
            {{isPhoneNumberExistsMessage}}
        </div>
        <div class="error" *ngIf="isEmailExists">
            {{isEmailExistsMessage}}
        </div>
        <div class="text-success" *ngIf="successCredentials">
            {{message}}
        </div>
        <div class="row">
            <div class="col-md-3 col-xs-6 form-group">
            <label for="exampleInputEmail1">First Name <span class="text-danger">*</span></label>
            <input type="text" maxlength="50" style="text-transform: capitalize;" class="form-control mb-0" appAppTitleCase
                formControlName="firstName"
                [ngClass]="{ 'is-invalid': (submitted ||d.firstName.touched) && d.firstName.errors }"
                placeholder="Enter First Name">
            <div *ngIf="(submitted ||d.firstName.touched) && d.firstName.errors" class="invalid-feedback">
                <div *ngIf="d.firstName.errors.required">First Name is required</div>
            </div>
            </div>
            <div class="col-md-3  col-xs-6 form-group">
            <label for="exampleInputEmail1">Middle Name</label>
            <input type="text" maxlength="50" style="text-transform: capitalize;" class="form-control mb-0" appAppTitleCase
                formControlName="middleName" placeholder="Enter Middle Name">
            </div>
            <div class="col-md-3  col-xs-6 form-group">
            <label for="exampleInputEmail1">Last Name <span class="text-danger">*</span></label>
            <input type="text" maxlength="50" style="text-transform: capitalize;" class="form-control mb-0" appAppTitleCase
                formControlName="lastName"
                [ngClass]="{ 'is-invalid': (submitted ||d.lastName.touched) && d.lastName.errors }"
                placeholder="Enter Last Name">
            <div *ngIf="(submitted ||d.lastName.touched) && d.lastName.errors" class="invalid-feedback">
                <div *ngIf="d.lastName.errors.required">Last Name is required</div>
            </div>
            </div>

            <div class="col-md-3  col-xs-6 form-group">
            <label for="exampleInputEmail1">Email Id <span class="text-danger">*</span></label>
            <input type="text" autocomplete="nope" class="form-control mb-0" formControlName="emailId"
                maxlength="40"
                [ngClass]="{ 'is-invalid':(submitted || d.emailId.touched) && d.emailId.errors }"
                placeholder="Enter Email id">
            <div *ngIf="(submitted ||d.emailId.touched )&& d.emailId.errors" class="invalid-feedback">

                <div *ngIf="d.emailId.errors.email">Email should be valid</div>
            </div>
            </div>
            <!-- <div class="col-md-3 col-xs-6 form-group">
            <label for="exampleInputPassword1">Password <span class="text-danger">*</span></label>
            <div class="input-group" id="show_hide_password">
                <input type="password" autocomplete="new-password" id="password" (paste)="(false)"
                (copy)="(false)" formControlName="password" class="form-control mb-0"
                (keydown.space)="$event.preventDefault()"
                [ngClass]="{'is-invalid': (submitted || d.password.touched) && d.password.errors}"
                id="exampleInputPassword1" placeholder="Enter Password" />
                <div class="input-group-addon">
                <a href="javascript:void(0)"><i class="fa fa-eye-slash" (click)="toggle1()"
                    aria-hidden="true"></i></a>
                </div>
                <div *ngIf="(submitted || d.password.touched) && d.password.errors" class="invalid-feedback">
                <div *ngIf="d.password.errors.required"> Password is required </div>
                <div *ngIf="d.password.errors.pattern"> Password must contain 1 Number,1 LowerCase,1
                    UpperCase,1 special Character ,no spaces allowed </div>
                </div>
            </div>
            </div>
            <div class="col-md-3 col-xs-6 form-group">
            <label for="exampleInputPassword1">Confirm Password <span class="text-danger">*</span></label>
            <div class="input-group" id="show_hide_password1">
                <input type="password" (paste)="(false)" (copy)="(false)" formControlName="confirmPassword"
                class="form-control mb-0" (keydown.space)="$event.preventDefault()"
                [ngClass]="{'is-invalid':(submitted || d.confirmPassword.touched) && d.confirmPassword.errors}"
                id="exampleInputPassword1" placeholder="Enter Password" />
                <div class="input-group-addon">
                <a href="javascript:void(0)"><i class="fa fa-eye-slash" (click)="toggle2()"
                    aria-hidden="true"></i></a>
                </div>
                <div *ngIf="(submitted || d.confirmPassword.touched) && d.confirmPassword.errors"
                class="invalid-feedback">
                <div *ngIf="d.confirmPassword.errors.required"> Confirm Password is also required </div>
                <div *ngIf="d.confirmPassword.errors.mustMatch"> Confirm Password & Passwords must match
                </div>
                </div>
            </div>
            </div> -->
            <div class="col-md-3  col-xs-6 form-group" *ngIf="phoneNumberPresent">
            <label for="exampleInputEmail1">Primary Number <span class="text-danger">*</span></label>
            <div class="staffTeleNumber">
                <input type="text"  #ph_no
                (focusout)="checkStaffPhoneNumber()" class="form-control mb-0" formControlName="cellNumber"
                (keypress)="numberOnly($event, ph_no.value)"  (keyup)="checknum()"
                [ngClass]="{ 'is-invalid': (submitted || d.cellNumber.touched) && d.cellNumber.errors }"
                placeholder="Phone Number" maxlength="13">
                <div *ngIf="(submitted || d.cellNumber.touched) && d.cellNumber.errors"
                class="invalid-feedback dpBk">
                <div *ngIf="d.cellNumber.errors.required">Phone Number is required</div>
                <div *ngIf="d.cellNumber.errors.pattern">Should be a valid Phone Number</div>
                </div>
            </div>
            </div>
            <div class="col-md-3  col-xs-6 form-group" *ngIf="!phoneNumberPresent">
                <label for="exampleInputEmail1">Primary Number <span class="text-danger">*</span></label>
                <div class="staffTeleNumber">
                    <input type="text" ng2TelInput
                    [ng2TelInputOptions]="{autoHideDialCode: false, initialCountry: 'in', separateDialCode:true}"
                    (ng2TelOutput)="getNumber($event)" (countryChange)="onCountryChange($event)" #ph_no
                    (focusout)="checkStaffPhoneNumber()" class="form-control mb-0" formControlName="cellNumber"
                    (keypress)="numberOnly($event, ph_no.value)" 
                    [ngClass]="{ 'is-invalid': (submitted || d.cellNumber.touched) && d.cellNumber.errors }"
                    placeholder="Phone Number" maxlength="10">
                    <div *ngIf="(submitted || d.cellNumber.touched) && d.cellNumber.errors"
                    class="invalid-feedback dpBk">
                    <div *ngIf="d.cellNumber.errors.required">Phone Number is required</div>
                    <div *ngIf="d.cellNumber.errors.pattern">Should be a valid Phone Number</div>
                    </div>
                </div>
                </div>
            <div class="col-md-3  col-xs-6  form-group">
            <label for="exampleInputEmail1">Roles <span class="text-danger">*</span></label>
            <mat-select class="form-control" formControlName="roleId" placeholder="Select services">
            <input  matInput placeholder="Select Role"
                formControlName="roleId" class="form-control"
                [ngClass]="{ 'is-invalid': (submitted || d.roleId.touched) && d.roleId.errors}">
                <mat-option  *ngFor="let item of roles" [value]="item.roleName" (click)="selectRole(item)">{{item?.roleName}}</mat-option>
            </mat-select>
            <div *ngIf="(submitted || d.roleId.touched) && d.roleId.errors" class="invalid-feedback dpBk">
                <div *ngIf="d.roleId.errors.required">Role required</div>
            </div>
            </div>

            <div class="col-md-3  col-xs-6">
            <div class="form-group">
                <label>Department </label>
                <mat-select placeholder="Select..." [(ngModel)]="selectedDepartment"
                class="form-control" formControlName="department">
                <mat-option *ngFor="let department of departments" [value]="department.departmentName"
                    (click)="selectedDepartmentFromList(department)">
                    {{ department.departmentName }}
                </mat-option>
            </mat-select>
            </div>
        </div>

            <div class="d-inline-block w-100 mt-3">
            <div class="btn-group float-right">
                <button
                [disabled]="!associateUser.valid  || invalidCredentials || isPhoneNumberExists"
                class="btn btn-primary float-right" (click)=" submit(associateUser)">
                <i aria-hidden="true" class="fa fa-user-plus"></i> Update User</button>
            </div>
            </div>
        </div>
        </form>
    </div>
</div>
