
            <div class="modal-header">
                <h5 class="modal-title">Items List</h5>
                <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container user-curetab">
                    <div class="tab-pane subcategory" id="subcategory">
                        <div>
                            <a aria-hidden="true" class="btn btn-success al mt-3 float-right btn-xs ml-2"
                                href="javascript:void(0)" title="Add SubCategory"
                                style=" position: absolute;right: 31px;top: 8px; " (click)="addSubCategory()">
                                <i aria-hidden="true" class="fa fa-list-alt"></i>
                                ADD</a>

                            <h3 class="mt-2 font-size-14 border-bottom p-2 bg-primary-light ">Category Name: <span style="color:#000 !important">{{category?.catagory}}</span></h3>

                            <input class="input-simple  inventorysearch mb-2" type="text" placeholder="Search Sub Category by Name" #subcname (keyup)="searchBySubCategoryName(subcname.value)">

                            <div class="">
                                <table class="table table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Description</th>
                                            <th>Supplier</th>
                                            <th>Status</th>
                                            <th width="80">Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr
                                            *ngFor="let sub of subcategory | paginate: { id: 'p5', itemsPerPage: 10, currentPage: p5 }">

                                            <td>{{sub.itemTypeId}}</td>
                                            <td>{{sub.name}}</td>

                                            <td>{{sub.description}}</td>
                                            <td><button data-target="#AssignSupplier" data-toggle="modal"
                                                    class="btn btn-primary btn-xs" (click)="getAssignSupplier(sub)"
                                                    *ngIf="sub.active=='Y'"><i
                                                        class="fas fa-people-arrows"></i></button></td>
                                            <td *ngIf="sub?.active=='Y'"><span class="bg-success p-1">Active</span></td>
                                            <td *ngIf="sub?.active=='N'"><span class="bg-danger p-1">Inactive</span></td>
                                            <td>
                                                <button class="btn btn-primary btn-xs btn-icon" data-toggle="tooltip"
                                                    data-original-title="Edit" (click)="editSubcategory(sub)"
                                                    *ngIf="sub.active=='Y'"><i class="fa fa-edit"></i></button>
                                                <button class="btn btn-success btn-xs  btn-icon"
                                                 (click)="activateSubCategory(sub)"
                                                    *ngIf="sub.active=='N'" data-toggle="tooltip"
                                                    data-original-title="Active"><i
                                                        class="fa fa-user-check fs-10"></i></button>
                                                <button class="btn btn-danger btn-xs"
                                                     *ngIf="sub.active=='Y'" (click)="deactivateSubCatagory(sub)"
                                                    data-toggle="tooltip" data-original-title="Deactivate"><i
                                                        class="fa fa-trash-o"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="subcategory?.length == 0 || subcategory?.length == null">
                                    <div class="no-data-found"></div>
                                </div>
                                <div *ngIf="subcategory?.length > 10">
                                    <div class="text-right">
                                        <ul class="pagination">
                                            <pagination-controls autoHide="true" id="p5"
                                                (pageChange)="p5=$event"></pagination-controls>
                                        </ul>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
