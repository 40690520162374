import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { NotificationService } from './shared/notification.service';
import { gpsService } from './shared/services/gps.service';

import { Event, Router, NavigationStart, NavigationEnd, RouterEvent, NavigationError } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BnNgIdleService } from 'bn-ng-idle';
import { threadId } from 'worker_threads';
import { event } from 'jquery';
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
import { ApicallsService } from './apicalls.service';
import { CookieService } from 'ngx-cookie-service';
import { BilldeskService } from './billdesk.service';
import { LocationStrategy } from '@angular/common';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'cureMetric';
  isLoaded
  constructor(private globalStorage: GlobalStorageService, private gps: gpsService, private apiservice: ApicallsService,
    private scripts: ScriptsService, private location: LocationStrategy) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });


    this.apiservice.windowReload$.subscribe(res => {
      if(res){
        window.onbeforeunload = function (e) {
          e = e || window.event;
          // For IE and Firefox prior to version 4
          if (e) {
            e.returnValue = 'Sure?';
            //this.globalStorage.clear();
            // const cookieValue = this.cookieService.get('usersession');
            // if(cookieValue){
            //   this._cookieService.delete('usersession');
            // }
            this.apiservice.isUserSessionExits = false;
            this.apiservice.isforUserIdExits = false;
            this.apiservice.ismoduleNameExits = false;
          }

          // For Safari
          return 'Sure?';
        };
      }else{
        window.onbeforeunload=null
      }
    })


    if (document.hidden) {
      window.onload = function (e) {
        // this.globalStorage.clear()
        this.apiservice.isUserSessionExits = false
        this.apiservice.isforUserIdExits = false
        this.apiservice.ismoduleNameExits = false
      }
    }

    if (this.globalStorage.getItem('loggedInAs') == 'DOCTOR') {
      sessionStorage.removeItem('patientLoginDetails')
      sessionStorage.removeItem('diagnosticData')
      sessionStorage.removeItem('pharmacyData')

      this.apiservice.isUserSessionExits = false
      this.apiservice.isforUserIdExits = false
      this.apiservice.ismoduleNameExits = false
    }
    if (this.globalStorage.getItem('loggedInAs') == 'PATIENT') {

      this.apiservice.isUserSessionExits = false
      this.apiservice.isforUserIdExits = false
      this.apiservice.ismoduleNameExits = false
      sessionStorage.removeItem('loginDetails')
      sessionStorage.removeItem('diagnosticData')
      sessionStorage.removeItem('pharmacyData')
    }
    if (this.globalStorage.getItem('loggedInAs') == 'DIAGNOSTIC_CENTER') {

      this.apiservice.isUserSessionExits = false
      this.apiservice.isforUserIdExits = false
      this.apiservice.ismoduleNameExits = false
      sessionStorage.removeItem('loginDetails')
      sessionStorage.removeItem('patientLoginDetails')
      sessionStorage.removeItem('pharmacyData')
    }
    if (this.globalStorage.getItem('loggedInAs') == 'PHARMACY') {
      this.apiservice.isUserSessionExits = false
      this.apiservice.isforUserIdExits = false
      this.apiservice.ismoduleNameExits = false
      sessionStorage.removeItem('loginDetails')
      sessionStorage.removeItem('patientLoginDetails')
      sessionStorage.removeItem('patientLoginDetails')
    }
    if (sessionStorage.getItem('loginDetails') == null) {

      this.apiservice.isUserSessionExits = false
      this.apiservice.isforUserIdExits = false
      this.apiservice.ismoduleNameExits = false
      this.globalStorage.removeItem('LoginDetails');
    }
    else {
      this.globalStorage.getItem('LoginDetails');
    }
    if (sessionStorage.getItem('patientLoginDetails') == null) {

      this.apiservice.isUserSessionExits = false
      this.apiservice.isforUserIdExits = false
      this.apiservice.ismoduleNameExits = false
      // this.globalStorage.removeItem('user-session')
    }
    else {
      this.globalStorage.getItem('userData');
      this.globalStorage.getItem('user-session')
    }
    if (sessionStorage.getItem('diagnosticData') == null) {

      this.apiservice.isUserSessionExits = false
      this.apiservice.isforUserIdExits = false
      this.apiservice.ismoduleNameExits = false
      // this.globalStorage.removeItem('diagnosticData');
      // this.globalStorage.removeItem('userSession2')
      this.globalStorage.removeItem('userAndSession')
    }
    else {
      this.globalStorage.getItem('diagnosticData');
      this.globalStorage.getItem('userSession')
    }
    if (sessionStorage.getItem('pharmacyData') == null) {
      this.globalStorage.removeItem('userSession2')

      // this.apiservice.isUserSessionExits = false
      // this.apiservice.isforUserIdExits = false
      // this.apiservice.ismoduleNameExits = false
    }
    else {
      this.globalStorage.getItem('userSession')
    }
  }
  ngOnInit() {
    this.scripts.loadScripts();
    this.gps.getCoordinates();
    this.loadBilldeskSDK();

    // window.onbeforeunload = function (e) {
    //   e = e || window.event;
    //   // For IE and Firefox prior to version 4
    //   if (e) {
    //     e.returnValue = 'Sure?';
    //     //this.globalStorage.clear();
    //   }
    //   return 'Sure?';
    // };

  }

  loadBilldeskSDK() {
    // Load CSS
    const link = document.createElement('link');
    link.href = environment.billdeskSDK.css;
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    // Load JS
    const scriptModule = document.createElement('script');
    scriptModule.type = 'module';
    scriptModule.src = environment.billdeskSDK.esmJs;
    document.body.appendChild(scriptModule);

    const scriptNoModule = document.createElement('script');
    scriptNoModule.noModule = true;
    scriptNoModule.src = environment.billdeskSDK.js;
    document.body.appendChild(scriptNoModule);
  }

}
