import { GlobalStorageService } from 'src/app/global-storage.service';
import { connect, ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ApicallsService } from 'src/app/apicalls.service';
import { PatientScheduleControlService } from './patientScedulecontrol.service';
interface AuthToken {
    token: string
}


export interface NamedRoom {
    id: string;
    name: string;
    maxParticipants?: number;
    participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable({
    providedIn: 'root'
})
export class VideoService {
    $roomsUpdated: Observable<boolean>;
    sid
    private roomBroadcast = new ReplaySubject<boolean>();
    userName: any;
    public tokenString: any
    tokenStringFinal: any;

    baseUrl = "http://localhost:3000"
    doctorUrl = environment.doctorBaseUrl
    patUrl = environment.patientBaseUrl
    constructor(private globalStorage: GlobalStorageService, private http: HttpClient, private toaster: ToastrService, public patSchedule: PatientScheduleControlService, private api: ApicallsService) {
        const hostname = window.location.hostname;

        if (hostname === 'localhost') {
          this.baseUrl = environment.patientBaseUrl1
          this.doctorUrl = environment.doctorBaseUrl1
          this.patUrl = environment.patientBaseUrl1
        }
        this.$roomsUpdated = this.roomBroadcast.asObservable();
    }

    getAuthToken(userName: any) {
        var data = this.globalStorage.getItem('userData')
        var obj = JSON.parse(data)
        let params = new HttpParams()
        params = params.set('forUserId', obj['userId']);
        const httpOptions = { params: params, headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) };



        return this.api.post(this.patUrl + 'appointment/joinroom/' + userName, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': obj['userId'].toString() }), observe: 'response' })
    }
    getUserName(value: any) {
        this.patSchedule.sendError(null)
        this.userName = value
        if (this.userName != undefined || this.userName != null) {
            this.getAuthToken(this.userName).subscribe(res => {
                this.tokenString = res['body']['data']['tokenForChat']
                this.sid = res['body']['data']['chatRoomSID']
                this.tokenGenerate(this.tokenString)

            }, error => {
                this.patSchedule.sendError(error['error']['message'])
            })
        }
    }
    tokenGenerate(tokenString: any) {
        this.tokenStringFinal = tokenString
    }


    getAllRooms() {
        return this.api
            .get(this.baseUrl + '/rooms', {})

    }


    async joinOrCreateRoom(name: string, tracks: LocalTrack[]) {

        let room: Room = null;

        try {
            const token = this.tokenStringFinal
            room =
                await connect(
                    token, {
                        name,
                        tracks,
                        dominantSpeaker: true
                    } as ConnectOptions);
        } catch (error) {
            console.error(`Unable to connect to Room: ${error.message}`);
        } finally {
            if (room) {
                this.roomBroadcast.next(true);
            }
        }

        return room;
    }



    nudge() {
        this.roomBroadcast.next(true);
    }
}
