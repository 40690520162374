import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';
import { AddCategoryComponent } from '../../inventory/add-category/add-category.component';
import { GlobalStorageService } from 'src/app/global-storage.service';
import { AddManufacturerComponent } from '../add-manufacturer/add-manufacturer.component';

@Component({
  selector: 'app-save-subcategory',
  templateUrl: './save-subcategory.component.html',
  styleUrls: ['./save-subcategory.component.css']
})
export class SaveSubcategoryComponent implements OnInit {

  SubcategoryForm: FormGroup;
  submitted: boolean = false;
  pharmaVisible: boolean = false;
  @Input() category: any;
  @Input() addressId: any;
  @Input() itemTypeId: any;
  @Input() subCategoryObj: any;
  @Input() isSubCatEdit: boolean = false;
  selectedManufact;
  percentages = [0, 5, 12, 18, 28];


  selectedOption: string = 'GST';
  gstOptions: string[] = ["GST", "I-GST"];
  selectOptions = [
    { value: 'Y', label: 'Yes' },
    { value: 'N', label: 'No' }
  ];
  showGstFields: boolean = false;
  showIGstFields: boolean = false;
  inventId;
  

  constructor(private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private inventoryService: InventoryService, private modalService: NgbModal, private globalStorage: GlobalStorageService) { }

  ngOnInit(): void {
    this.fetchManufacturers();
    this.initializeSubCategoryForm();
    // this.gstCheckBox({ value: this.selectedOption });
    if (this.isSubCatEdit) {
      this.manufactId = this.subCategoryObj?.manufacturerId
      this.SubcategoryForm.patchValue({
        name: this.subCategoryObj?.name,
        description: this.subCategoryObj?.description,
        manufacturer: this.subCategoryObj?.manufacturer,
        disposal: this.subCategoryObj?.disposable,
        usageUnits: this.subCategoryObj?.usageUnit,
        rack: this.subCategoryObj?.rack,
        hsn: this.subCategoryObj?.hSNCode,
        potency: this.subCategoryObj?.potency,
        prescription: this.subCategoryObj?.prescriptionrequired,
        injectable: this.subCategoryObj?.injectable,
        alternate: this.subCategoryObj?.alternate,
        freezable: this.subCategoryObj?.fezabile,
        cgst: this.subCategoryObj?.cgst,
        sgst: this.subCategoryObj?.sgst,
        igst: this.subCategoryObj?.igst,
        tax: this.subCategoryObj?.tax
      });

      this.inventId = this.subCategoryObj?.inventoryId;
    }

    this.getcategory()
  }

  categorylist = []
  getcategory() {
    var body = {
      "flag": "Y",
      "addressId": this.addressId
    }
    this.inventoryService.category(body).subscribe(res => {
      this.categorylist = res?.['data'] || [];
      this.categorylist.push({
        catagory: 'Add New',
        add: true
      })
    },
      error => {
        this.toaster.error(error?.[error]?.['message']);
      }
    )
  }

  catIdfromObj: any;
  selectedCategory(categorySelected) {
    if (categorySelected?.add) {
      this.addCat()
    } else {
      this.catIdfromObj = categorySelected.catagoryId
    }
  }

  addCat() {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'left hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-right hw-40';

    } var addCategory = this.modalService.open(AddCategoryComponent, {
      centered: true,
      backdrop: 'static',
      windowClass: wClass
    })
    addCategory.result.then(
      (result) => {
        this.getcategory()
      },
      (reason) => {
        this.getcategory()
      }
    )
  }


  addManufactur() {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'left hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-right hw-40';
    }
    const modalRef = this.modalService.open(AddManufacturerComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.result.then(
      (result) => {
        this.fetchManufacturers();
      },
      (reason) => {
      }
    );
  }

  initializeSubCategoryForm() {
    this.SubcategoryForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: [''],
      manufacturer: [''],
      disposal: [''],
      usageUnits:1,
      rack: [''],
      hsn: [''],
      potency: [''],
      prescription: [''],
      injectable: [''],
      alternate: [''],
      freezable: [''],
      cgst: [''],
      sgst: [''],
      igst: [''],
      tax: ['']
    })

    this.SubcategoryForm.get('cgst').valueChanges.subscribe((cgstValue) => {
      this.SubcategoryForm.get('sgst').setValue(cgstValue);
    })
  }

  get Subf() { return this.SubcategoryForm?.controls; }

  closeModal() {
    this.activeModal.close(true);
  }

  optionSelected(event) { }
  drpdownSelection(data) { }

  manufacturersData;
  fetchManufacturers() {
    let body = {
      "active": "Y",
      "addressId": this.addressId
    }
    this.inventoryService.manufacturerList(body).subscribe(res => {
      this.manufacturersData = res?.['data'] || [];

      this.manufacturersData.push({
        manufacturer: 'Add New',
        add: true
      })

      

      if (this.isSubCatEdit) {
        this.SubcategoryForm.patchValue({
          manufacturer: this.subCategoryObj?.manufacturer
        })
      }
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  gstCheckBox(a) {
    if (a.value == 'GST') {
      this.selectedOption = a.value;
      this.showGstFields = true;
      this.showIGstFields = false;
      this.SubcategoryForm.controls['cgst'].setValidators([Validators.required])
      this.SubcategoryForm.controls['sgst'].setValidators([Validators.required])
      this.SubcategoryForm.controls['igst'].clearValidators()
      this.SubcategoryForm.get('igst').setValue(null)
    } if (a.value == 'I-GST') {
      this.selectedOption = a.value;
      this.showGstFields = false;
      this.showIGstFields = true;
      this.SubcategoryForm.controls['igst'].setValidators([Validators.required])
      this.SubcategoryForm.controls['cgst'].clearValidators()
      this.SubcategoryForm.controls['sgst'].clearValidators()
      this.SubcategoryForm.get('cgst').setValue(null)
      this.SubcategoryForm.get('sgst').setValue(null)
    }
  }
  manufactId
  selectedManufacter(data) {
    if (data?.add) {
      this.addManufactur();
    } else {
      this.manufactId = data?.manufacturerId
    }
  }
  savesubcategoryform() {
    this.submitted = true;
    if (this.SubcategoryForm.invalid) {
      return false;
    }
    else {
      var body = {
        "name": this.SubcategoryForm.value.name,
        "description": this.SubcategoryForm.value.description,
        "manufacturerId": this.manufactId,
        "disposable": this.SubcategoryForm.value.disposal,
        "usageUnit": this.SubcategoryForm.value.usageUnits,
        "rack": this.SubcategoryForm.value.rack,
        "hSNCode": this.SubcategoryForm.value.hsn,
        "alternate": this.SubcategoryForm.value.alternate,
        "fezabile": this.SubcategoryForm.value.freezable,
        "injectable": this.SubcategoryForm.value.injectable,
        "potency": this.SubcategoryForm.value.potency,
        "prescriptionrequired": this.SubcategoryForm.value.prescription,
        "cgst": this.SubcategoryForm.value.cgst,
        "sgst": this.SubcategoryForm.value.sgst,
        "igst": this.SubcategoryForm.value.igst,
        "catagoryId": this.category?.catagoryId || this.catIdfromObj,
        "addressId": this.addressId,
        "tax": this.SubcategoryForm.value.tax
      }

      // if (this.isSubCatEdit) {
      //   body["itemTypeId"] = this.itemTypeId
      //   body['inventoryId'] = this.inventId
      // }
      this.inventoryService.saveSubCategory(body).subscribe(res => {
        this.toaster.success('Item Added Successfully.');
        this.submitted = false;
        this.SubcategoryForm.reset();
        this.closeModal();
      }, error => {
        this.toaster.error(error?.['error']?.['message']);
      })
    }
  }

  upDateSubCat() {
    let body = {
      "description": this.SubcategoryForm.value.description,
      "manufacturerId": this.manufactId,
      "itemTypeId": this.itemTypeId,
      "disposable": this.SubcategoryForm.value.disposal,
      "usageUnit": this.SubcategoryForm.value.usageUnits,
      "rack": this.SubcategoryForm.value.rack,
      "hSNCode": this.SubcategoryForm.value.hsn,
      "alternate": this.SubcategoryForm.value.alternate,
      "fezabile": this.SubcategoryForm.value.freezable,
      "injectable": this.SubcategoryForm.value.injectable,
      "potency": this.SubcategoryForm.value.potency,
      "prescriptionrequired": this.SubcategoryForm.value.prescription,
      "cgst": this.SubcategoryForm.value.cgst,
      "sgst": this.SubcategoryForm.value.sgst,
      "igst": this.SubcategoryForm.value.igst,
      "tax": this.SubcategoryForm.value.tax
    }
    this.inventoryService.updateSubCategory(body).subscribe(res => {
      this.toaster.success('Item Updated Successfully.');
      this.submitted = false;
      this.SubcategoryForm.reset();
      this.closeModal();
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

}
