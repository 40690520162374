
<div class="row">
      <div class="col-md-12" *ngIf="activeMedicationList.length!=0"><h6 class=" font_Poppins  p_14">Current Activity Medication   </h6>
     </div>

    <div class="row ml-2">
    <div *ngFor="let row of activeMedicationList;let i =index">
      <div class=" d-flex flex-column flex-grow-1 w-100  blt-2 mb-2 card">
      <a href="javascript:void(0)" class="float-left">
      <p class="font-weight-medium p_btm line_height float-left  pt-1 d-inline-block text-truncate text-nowrap  overflow-hidden "> 
    
        <!-- style="text-overflow: ellipsis;width: 100px;" -->
       
       <span class="mt-2 text-muted p-1 "> {{row.medicineName | titlecase }} </span> 
       <span class="text-muted text-small">{{row.power}}</span>
        </p>
       <div class=" float-right">
        <button class="btn btn-sm  p-1"><i class="fa fa-pills themecol1    icon-head" aria-hidden="true"></i></button> 
  
    </div>
      </a>
      
      
      </div>
  
</div>
</div>

        </div>
