import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-prescriptions-modal',
  templateUrl: './view-prescriptions-modal.component.html',
  styleUrls: ['./view-prescriptions-modal.component.css']
})
export class ViewPrescriptionsModalComponent implements OnInit {

  @Input() appointment:any;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
