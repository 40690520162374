import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DoctorService } from '../../AppServices/doctor.service'
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { ToastrService } from 'ngx-toastr'
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { formatDate } from '@angular/common';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as saveAs from 'file-saver';
declare var $: any;
@Component({
  selector: 'app-paint-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {

  constructor(private globalStorage: GlobalStorageService, private doctorService: DoctorService, private view: pharmaService,
    private patientService: DoctorPatientService, private ToastrService: ToastrService,
    private patientController: PatientScheduleControlService, private sanitizer: DomSanitizer,) {

  }
  NewlyRegPatients: any; newsrc; MedicationPrescriptions; precId; showImage: boolean = false; showPdf: boolean = false;
  BindingNewlyRegPatients; patientsList; firstName; lastName; medicalReportsData; activeMedicationList
  setItemsPerPage = 6; selectedText;
  totalItem = 6; searchText; medicalTestPrescription;
  p3 = 1; isPatientList = true; isAllAppointments = false
  public displayedColumns: string[] = ['select', 'firstName', 'middleName', 'lastName', 'emailId', 'primaryContact', 'Action'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  selection = new SelectionModel<any>(true, []);
  previousAppointmentsList = []
  ngOnInit(): void {
    // this.LoadNewlyRegistered();
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })

    this.patientList()

  }
  patientLists() {
    this.isPatientList = true
    this.isAllAppointments = false
    $('#pills-contact-tab').removeClass('active')
    $('#pills-profile-tab').removeClass('active')
    $('#pills-home-tab').addClass('active')
    $('#pills-home').addClass('show active')
    $('#pills-profile').removeClass('show active')
    $('#pills-contact').removeClass('show active')
  }
  allAppointments() {
    this.isPatientList = false
    this.isAllAppointments = true
    $('#pills-contact-tab').removeClass('active')
    $('#pills-profile-tab').addClass('active')
    $('#pills-home-tab').removeClass('active')
    $('#pills-home').removeClass('show active')
    $('#pills-profile').addClass('show active')
    $('#pills-contact').removeClass('show active')
  }
  patientList() {
    var data = this.globalStorage.getItem('LoginDetails')
    var obj = JSON.parse(data)
    this.firstName = obj['data']['firstName']
    this.lastName = obj['data']['lastName']

    this.doctorService.getAllPatientData(parseInt(this.globalStorage.getItem('doctorId'))).subscribe(res => {
      this.patientsList = res['body']['data']
    })
  }
  searchData(searchText) {
    this.patientsList = this.patientsList.filter(o => o?.patientName?.toLowerCase()?.includes(searchText.toLowerCase()));
    if (searchText?.length == 0) {
      this.patientList()
    }
  }
  LoadNewlyRegistered() {
    this.doctorService.getAllPatients().subscribe(res => {
      this.NewlyRegPatients = new MatTableDataSource(res["data"])
      this.BindingNewlyRegPatients = this.NewlyRegPatients.filteredData.NEWLY_REGISTERED;
      this.BindingNewlyRegPatients.paginator = this.paginator;
      this.BindingNewlyRegPatients.sort = this.sort;
    }, error => {
      this.ToastrService.error("Error on getting newly registered patients")
    })
  }
  verifiedPatients
  BindingverifiedPatients
  Verified() {
    this.doctorService.getAllVerifiedPatients().subscribe(res => {
      this.verifiedPatients = new MatTableDataSource(res["data"])
      this.BindingverifiedPatients = this.verifiedPatients.filteredData.VERIFIED
      this.BindingverifiedPatients.paginator = this.paginator;
      this.BindingverifiedPatients.sort = this.sort;
    }, error => {
      this.ToastrService.error("Error on getting verified patients")
    })
  }

  approvedPatients
  BindingapprovedPatients
  Approved() {
    this.doctorService.getAllApprovedPatients().subscribe(res => {
      this.approvedPatients = new MatTableDataSource(res["data"])
      this.BindingapprovedPatients = this.approvedPatients.filteredData.VERIFIED
      this.BindingapprovedPatients.paginator = this.paginator;
      this.BindingapprovedPatients.sort = this.sort;
    }, error => {
      this.ToastrService.error("Error on getting approved patients")
    })
  }

  public VisibleTab1: boolean;
  public VisibleTab2: boolean;
  public VisibleTab3: boolean;
  TabChanges(tab) {
    if (tab._indexToSelect == 0) {
      this.VisibleTab1 = true;
      this.VisibleTab2 = false;
      this.VisibleTab3 = false;
      this.LoadNewlyRegistered();
    }
    else if (tab._indexToSelect == 1) {

      this.VisibleTab1 = false;
      this.VisibleTab2 = true;
      this.VisibleTab3 = false;
      this.Verified()
    } else if (tab._indexToSelect == 2) {
      this.VisibleTab1 = false;
      this.VisibleTab2 = false;
      this.VisibleTab3 = true;
      this.Approved()
    }
  }

  viewPatientDerails(patient) {
    this.doctorService.OpenPatientSideNave(patient);
  }

  onEvent(event) {
    event.stopPropagation();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.NewlyRegPatients.data.length;
    return numSelected === numRows;
  }
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() :
      this.BindingNewlyRegPatients.data.forEach(row => this.selection.select(row));
  }

  search(search) {
    this.BindingNewlyRegPatients = this.filter(search);
    this.BindingNewlyRegPatients = this.NewlyRegPatients
    this.BindingNewlyRegPatients.paginator = this.paginator;
    this.BindingNewlyRegPatients.sort = this.sort;
  }

  filter(searchString: string): any[] {
    this.NewlyRegPatients.filter = searchString.trim().toLowerCase();
    return this.NewlyRegPatients.filteredData;
  }
  prescriptionOpen(data) {
    this.patientName1 = data?.patientName
    var req = {
      "forUserId": data.userId,
      "userId": data.userId
    }
    this.doctorService.getPrescriptionPostSession('prescription/getprecriptionbyuserId/' + this.p3 + '/1000/' + data.userId, req, data.userId).subscribe(res => {
      this.medicalReportsData = res['data'].filter(obj => obj.doctorName != 'SELF')
    })
    $('#medicalPrescriptionDoctor').modal('show')

  }
  getSavedPrescriptions() {

  }
  dStreet1; dStreet2; dState; dZip; dCountry; dCity; doctorAddress
  doctorAddressDetails(id) {
    this.patientController.getAddressDetails(id).subscribe(res => {
      this.doctorAddress = res['data'][0]
      this.dStreet1 = res['data']?.[0]?.['street1']
      this.dStreet2 = res['data']?.[0]?.['street2']
      this.dState = res['data']?.[0]?.['state']
      this.dCountry = res['data']?.[0]?.['country']
      this.dCity = res['data']?.[0]?.['city']
      this.dZip = res['data']?.[0]?.['zip']
    })
  }
  dId;
  prescriptionImageData; prescriptionFill; total; subtotal; medications; pAddress1; src; prescriptionImageLocation; doctorSignatureSrc;
  pAddress2; pZip; pCountry; pState; pCity; pharmacyName; numberOfRefills; precName; imageIsPresent; Createddate; DoctorAppointmentId;
  viewPrescription(reports) {
    this.prescriptionImageData = reports
    this.doctorAddressDetails(reports['doctorId'])
    this.dId = reports['doctorId']
    this.doctorName = reports['doctorName']
    this.prescriptionFill = reports['prescriptionFill']
    this.numberOfRefills = reports['numberofrefills']
    //this.pharmaAddress(1)
    this.precName = reports?.['documentName']
    if (reports?.['prescriptioncontainsImage'] == 'Y') {
      this.imageIsPresent = true
      this.patientController.postGlobal('prescription/getPrescriptionDetailsById?presciptionId=' + reports?.['prescriptionId'], reports['patientId']).subscribe(res => {
        if (res['data'] != null && (res['data']?.['documentName'].includes(".png") || res['data']?.['documentName'].includes(".jpeg"))) {
          this.src = "data:image/jpeg;base64," + res['data']['image']

          this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
          this.showImage = true

        } else if (res['data'] != null && (res['data']?.['image'].includes(".pdf"))) {
          this.newsrc = null
          this.showPdf = true

          this.src = "data:application/pdf;base64," + res['data']['image']
          this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
        }
      })
    }
    else {
      this.imageIsPresent = false
    }
    this.prescriptionImageLocation = reports['documentLocation']

    this.precId = reports['medicalPrescriptionId']
    this.Createddate = reports['appointmentDate']
    this.DoctorAppointmentId = reports['doctorAppointmentId']
    if (reports['doctorId'] != -1 || reports['doctorId'] != undefined || reports['doctorId'] != null) {
      this.patientController.getDoctorSignature('personaldetail/doctorid/', reports['doctorId']).subscribe(res => {
        this.doctorSignatureSrc = "data:image/jpeg;base64," + res['data']?.['doctorSignature']
        this.doctorSignatureSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.doctorSignatureSrc)
      })
    }
    if (reports?.['medicalPrescriptionId'] != null) {
      this.patientController.postGlobal('prescription/getprescriptionswithmedaction?medicalPresciptionId=' + reports['medicalPrescriptionId'], reports['patientId']).subscribe(res => {
        // this.controller.globalgetBody('prescription/id/',reports['medicalPrescriptionId'],this.userId).subscribe(res=>{

        this.medicalTestPrescription = res['data']['medicalTests']
        this.MedicationPrescriptions = res['data']['medication']

        this.newsrc = null
        this.showPdf = false
        this.showImage = false

        // if(res['data']['medicalPrescriptionFile']!=null || (res['data']?.['documentName'].includes(".png")|| res['data']?.['documentName'].includes(".jpeg"))){
        //   this.src="data:image/jpeg;base64,"+res['data']['medicalPrescriptionFile']

        //   this.newsrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
        //   this.showImage=true
        // }else if(res['data']['medicalPrescriptionFile']!=null && (res['data']?.['documentName'].includes(".pdf"))){
        //   this.newsrc=null
        //   this.showPdf=true
        //   this.src="data:application/pdf;base64,"+res['data']['medicalPrescriptionFile']
        //   this.newsrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
        // }
        $('#viewPrescription').modal('show')
      }, error => {
        // this.toaster.error(error['error']['message'])
      })
    }
    if (reports?.['medicaltestprescriptionId'] != null) {
      this.patientController.postGlobal('prescription/getmedicaltestprescriptiondetails?medicalTestPresciptionId=' + reports?.['medicaltestprescriptionId'], reports['patientId']).subscribe(res => {
        // this.controller.globalgetBody('prescription/id/',reports['medicalPrescriptionId'],this.userId).subscribe(res=>{

        this.medicalTestPrescription = res['data']['medicaltest']
        this.MedicationPrescriptions = res['data']['medication']

        this.newsrc = null
        this.showPdf = false
        this.showImage = false

        // if(res['data']['medicalPrescriptionFile']!=null || (res['data']?.['documentName'].includes(".png")|| res['data']?.['documentName'].includes(".jpeg"))){
        //   this.src="data:image/jpeg;base64,"+res['data']['medicalPrescriptionFile']

        //   this.newsrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
        //   this.showImage=true
        // }else if(res['data']['medicalPrescriptionFile']!=null && (res['data']?.['documentName'].includes(".pdf"))){
        //   this.newsrc=null
        //   this.showPdf=true
        //   this.src="data:application/pdf;base64,"+res['data']['medicalPrescriptionFile']
        //   this.newsrc=this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
        // }
        $('#viewPrescription').modal('show')
      }, error => {
        // this.toaster.error(error['error']['message'])
      })
    }

    this.getPatientAddress(reports['patientId'], parseInt(this.globalStorage.getItem('doctorId')))
  }
  closeModal() {
    this.globalStorage.setItem('forUserId', this.globalStorage.getItem('doctorId'))
    $('#viewPrescription').modal('hide')
  }
  viewCurrentAppontment(appointmentDetails) {

    this.getPatientDetails(appointmentDetails);
  }
  getPatientDetails(appointmentDetails) {
    var req = {
      "forUserId": appointmentDetails.userId,
      "userId": JSON.parse(this.globalStorage.getItem('doctorId'))
    }

    this.patientService.getUserPersonalDetails('personaldetails/get/', appointmentDetails.userId, req).subscribe(res => {
      var patientDetails = res['body']['data'];
      patientDetails.AppointmentDetails = appointmentDetails;
      patientDetails.Appointment = 'Current';
      //patientDetails.Appointment='Upcoming';
      this.doctorService.OpenPatientSideNave(patientDetails);
    }, error => {
      this.ToastrService.error("Error in getting patient details");
    })
  }
  activeMedications(data) {
    var date: any = new Date()
    date = formatDate(date, "yyyy-MM-dd", 'en-us')
    var body = {
      "medicinedate": date,
      "patientId": data.userId,
      "maxResult": 100,
      "pageNumber": 1,
    }
    this.patientService.getMedicationBydate(body, data.userId).subscribe(res => {
      this.activeMedicationList = res['body']['data']
    }, error => {
    })
    $('#activeMedication').modal('show')
  }
  patientPersonalDetails
  patientHistory(data) {
    this.patientPersonalDetails = data
    $('#patientHistory').modal('show')
  }
  previousAppointments(data) {
    $('#previousAppointments').modal('show')
    var body = {
      "doctorId": parseInt(this.globalStorage.getItem('doctorId')),
      "maxResult": 100,
      "pageNum": 1,
      "patientId": data.userId,
      "viewAppointmentEnum": [
        "PREVIOUS"
      ]
    }
    this.doctorService.getPreviousAppointmentsOfPatient(body).subscribe(res => {
      this.previousAppointmentsList = res['data']['PREVIOUS']['data']
    })
  }
  prescriptionList(appointment) {

    // this.patientService.openPrescriptionList(appointment);
    $('#myprescription').modal('show')
    this.doctorService.getPrescription(appointment['appointmentId'], appointment['userId']).subscribe(res => {
      if (res['data']['medications'] != undefined) {
        if (res['data']['medications'].length > 0) {
          this.MedicationPrescriptions = res['data']['medications']
        }
      }
      if (res['data']['medicaltests'] != undefined) {
        if (res['data']['medicaltests'].length > 0) {
          this.medicalTestPrescription = res['data']['medicaltests']
        }
      }

    }, error => {
    })

  }
  debitCard; tType; paidAmount; issuedDate; invoiceID; aliment; appoitmentType;
  patientName1; pstreet1; pstreet2; pstate; pcity; pcountry; pzip
  doctorName; dstreet1; dstreet2; dstate; dcity; dcountry; dzip
  invoiceData(appointment) {
    this.appoitmentType = appointment['Appointment']
    this.patientName1 = ""
    this.pstreet1 = ""
    this.pstreet2 = ""
    this.pstate = ""
    this.pcity = ""
    this.pcountry = ""
    this.pzip = ""
    this.doctorName = ""
    this.dstreet1 = ""
    this.dstreet2 = ""
    this.dstate = ""
    this.dcity = ""
    this.dcountry = ""
    this.dzip = ""
    this.paidAmount = appointment['amountPaid']
    this.debitCard = appointment['cardnumber']
    this.tType = appointment['transactiontype']
    this.aliment = appointment['ailment']
    this.issuedDate = appointment['lastUpdatedTs']
    this.getDocotrAddress(appointment['doctorId'])
    var req = {
      "doctorappointmentid": appointment['appointmentId'],
      "doctorid": appointment['doctorId'],
      "maxResults": 10,
      "pageNum": 1,
      "patientid": appointment['userId']
    }
    this.doctorService.getInvoiveData(req).subscribe(res => {
      var data = JSON.parse(res['data'])
      this.invoiceID = JSON.parse(data['data'][0]['invoiceId'])
    })
    this.getPatientAddress(appointment['userId'], parseInt(this.globalStorage.getItem('doctorId')))
    $('#invoice').modal('show')
  }

  docinvoiceData;doctorobjres;
  getCompleteInvoiceDetails(appointment) {
    this.doctorService.getDoctorInvoiceDetails(appointment?.appointmentId, appointment?.doctorId).subscribe(res => {
      this.doctorobjres = res;
      this.docinvoiceData = res?.['data']?.[0];
      $('#doc-invoice').modal('show');
    })
  }

  downloadAsPDF() {
    this.doctorService.downloadDoctorPdf(this.doctorobjres).subscribe(res => {
      console.log(res);
      let entity_name = this.docinvoiceData?.['patientAddressDTO']?.['contactName'] + '.pdf';
      var blob = new Blob([res], { type: 'application/pdf' });
      saveAs(blob, entity_name);
    },
    error => {
      this.ToastrService.error('Error downloading PDF.', error);
    }
    )
  }

  getPatientDetailsView(appointmentDetails) {
    var req = {
      "forUserId": appointmentDetails.userId,
      "userId": JSON.parse(this.globalStorage.getItem('doctorId'))
    }
    this.patientService.getPatientPersonalDetails(appointmentDetails.userId, req).subscribe(res => {
      var patientDetails = res['body'].data;
      patientDetails.AppointmentDetails = appointmentDetails;
      patientDetails.Appointment = appointmentDetails.Appointment;
      patientDetails.Appointment = 'Current';
      //patientDetails.Appointment='Upcoming';

      this.doctorService.OpenPatientSideNave(patientDetails);
      // if(patientDetails.Appointment=='Current'){
      //   this.DoctorVideo.getUserName(patientDetails.AppointmentDetails.appointmentId)//patientDetails.AppointmentDetails.appointmentId
      // }

    }, error => {
      this.ToastrService.error("Error in getting patient details");
    })
  }
  getDocotrAddress(id) {
    this.patientService.getDoctorAddressDetails(id).subscribe(res => {
      this.doctorName = res['data'][0]['contactName']
      this.dstreet1 = res['data'][0]['street1']
      this.dstreet2 = res['data'][0]['street2']
      this.dstate = res['data'][0]['state']
      this.dcity = res['data'][0]['city']
      this.dcountry = res['data'][0]['country']
      this.dzip = res['data'][0]['zip']
    })
  }
  getPatientAddress(id, dId) {
    var request = {
      "forUserId": id,
      "userId": dId
    }
    this.globalStorage.setItem('forUserId', id)
    this.patientService.getPatientAddress('address/get', request, id).subscribe(res => {
      this.patientName1 = res['data'][0]['contactName']
      this.pstreet1 = res['data'][0]['street1']
      this.pstreet2 = res['data'][0]['street2']
      this.pstate = res['data'][0]['state']
      this.pcity = res['data'][0]['city']
      this.pcountry = res['data'][0]['country']
      this.pzip = res['data'][0]['zip']
    })
  }
}
