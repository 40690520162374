import { GlobalStorageService } from 'src/app/global-storage.service';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subject, BehaviorSubject, Observable} from 'rxjs';
import { ApicallsService } from 'src/app/apicalls.service';

@Injectable({
  providedIn: 'root'
})
export class PatientScheduleControlService {

  baseUrl = environment.patientBaseUrl
  ivfUrl =environment.ivfUrl
  doctorUrl = environment.doctorBaseUrl
  diagnosticUrl = environment.diagnosticBaseUrl
  pharmaUrl = environment.pharmaUrl
  caretakerUrl = environment.caretakerUrl
  elasticSearchUrl = environment.elesticSearchUrl
  utilitiesUrl = environment.utilitiesUrl
  clinicUrl=environment.hospitalUrl;
  pdfUrl = environment.pdfUrl;
  // Associated user
  sharingData
  doctorData
  patientData
  errorMessage
  patientDetails
  webData
  rpmcallData
  this: any;
  // Observable string source
  _window(): any {
    // return the global native browser window object
    return window;
  }
  public notify = new BehaviorSubject<any>('');

  notifyObservable$ = this.notify.asObservable();
  dataStringSource = new Subject<any>();
  constructor(private globalStorage: GlobalStorageService, private http: HttpClient, private apiService: ApicallsService) { 
    const hostname = window.location.hostname;
    if (hostname === 'localhost') {
      this.baseUrl = environment.patientBaseUrl1
      this.ivfUrl =environment.ivfUrl1
      this.doctorUrl = environment.doctorBaseUrl1
      this.diagnosticUrl = environment.diagnosticBaseUrl1
      this.pharmaUrl = environment.pharmaUrl1
      this.caretakerUrl = environment.caretakerUrl1
      this.elasticSearchUrl = environment.elesticSearchUrl1
      this.utilitiesUrl = environment.utilitiesUrl1
      this.clinicUrl=environment.hospitalUrl1
      this.pdfUrl = environment.pdfUrl1;
    }
  }
  get nativeWindow(): any {
    return this._window();
  }
  notifyOther(data: any) {
    if (data) {
      console.log('notify')
        this.notify.next(data);
    }
}

private sortOrder: string = 'asc';
private sortField: string = '';

sort(array: any[], field: string): any[] {
  if (this.sortField === field) {
    this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
  } else {
    this.sortOrder = 'asc';
  }

  this.sortField = field;
  const comparison = this.sortOrder === 'asc' ? 1 : -1;

  return array.sort((a, b) => {
    if (typeof a[field] === 'string' && typeof b[field] === 'string') {
      return a[field].localeCompare(b[field]) * comparison;
    } else if (typeof a[field] === 'number' && typeof b[field] === 'number') {
      return (a[field] - b[field]) * comparison;
    } else {
      return 0;
    }
  });
}
saveRpmCallData(data){
 this.rpmcallData=data
 this.dataStringSource.next(this.rpmcallData)
}
saveWebData(value){
    this.webData = value;
    this.dataStringSource.next(this.webData);
}
  saveData(value) {
    this.sharingData = value;
    this.dataStringSource.next(this.sharingData);
  }
  vitalsData
  sendVitals(value){
    this.vitalsData = value;
    this.dataStringSource.next(this.vitalsData);
  }
  sendError(value) {
    this.errorMessage = value
    this.dataStringSource.next(this.errorMessage);
  }
  savePatientData(value) {
    this.patientData = value
    this.dataStringSource.next(this.patientData);
  }
  savePersonalData(value){
    this.patientDetails = value
    this.dataStringSource.next(this.patientDetails);
  }
  saveDoctorData(value) {
    this.doctorData = value
    this.dataStringSource.next(this.doctorData);
  }
  saveMedicalReports(formDatas, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'Schedule/medicalreports/add', formDatas, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  getCaretakerRatings(id){
    return this.apiService.get(this.caretakerUrl + 'caretakerrating/get/' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId').toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  updatePrefferedCenter(body, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'save/saveuserprefrencediagnosticcenter', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  searchByTests(testname) {
    return this.apiService.post(this.diagnosticUrl + 'searchByserviceName?serviceName=' + testname, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  searchByTestTypeAhead(testname){
    return this.apiService.get(this.elasticSearchUrl+'elasticsearchDiagnosticcenterController/searchDiagnosticcenterTestName/'+testname,{})
  }

  // search diagnostic center test in internal
  searchDCtestInter(keyw, id){
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDiagnosticcenterController/searchDiagnosticcenterTest/' + keyw + '/' + id , {});
  }

  prefferedDiagnosticCenter(id, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'getallUserreferencediagnosticcenter/' + id + '/DIAGNOSTICENNTER', {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  saveAssociatedUser(formData, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'assocation/addManagedUser', formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache', })
  }
  updateAssociatedUser(userId, formData) {
    //let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'assocation/updateAssocateUser', formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getPatientAsoociatedUsers(userId, body) {
    return this.apiService.post(this.baseUrl + 'assocation/allAssociateUser', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json','user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  changeExistingUserStatus(forUserId, relationshipId, type) {
    return this.apiService.get(this.baseUrl + 'assocation/activateAssociateUserbyRelationUserId?forUserId=' + forUserId + '&relationshipId=' + relationshipId + '&status=' + type, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': forUserId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  resendEmailToAssociatedUser(email, userId) {
    return this.apiService.get(this.baseUrl + 'assocation/resendEmailForAssocateUser?emailId=' + email, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  removeAssociatedUser(forUserId, userId) {
    return this.apiService.delete(this.baseUrl + 'assocation/deleteAssocateUser?associateUserId=' + forUserId + '&userId=' + userId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': forUserId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  sendPrescription(formDatas, userId) {

    return this.apiService.post(this.baseUrl + 'prescription/fromdoctor/save', formDatas, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  sendPrescriptionImage(formDatas, userId) {

    return this.apiService.post(this.baseUrl + 'prescription/save/medical/prescriptionimage/fromdoctor', formDatas, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getDoctorFeeDetailByType(body){
    return this.apiService.post(this.doctorUrl + 'doctorFeeDetail',body,{})
  }
  sendPrescriptionImageFromPatient(formDatas, userId) {
    return this.apiService.post(this.baseUrl + 'prescription/save/medical/prescriptionimage/frompatient', formDatas, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  savePrescription(body, userId) {
    return this.apiService.post(this.baseUrl + 'prescription/save/medical/prescription/fromdoctor', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  saveHospitalPrescription(body, userId){
    return this.apiService.post(this.clinicUrl+'addPrescription',body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  saveIvfPatientPrescription(body, userId){
    return this.apiService.post(this.ivfUrl+'saveInPatientPrescription',body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  savePrescriptionFromPatient(body, userId) {
    return this.apiService.post(this.baseUrl + 'prescription/save/medical/prescription/fromdoctor', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getDoctorDetailssById(endpoint, id) {
    return this.apiService.get(this.doctorUrl + endpoint + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }

  getCaretakerDetailssById(id) {
    if(id){
    return this.apiService.get(this.caretakerUrl + 'get/caretaker?caretakerId=' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
}

  savePreviousPrescription(formDatas) {

    return this.apiService.post(this.baseUrl + 'prescription/frompatient/save', formDatas, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  updateMedicineUsage(id, body) {
    return this.apiService.post(this.baseUrl + 'prescription/updateMedicationusage', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': id.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getAppointmentsReports(json, id) {
    return this.apiService.post(this.baseUrl + 'reports/excutereport', json, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': id.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getMedicalReports(request: any, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'Schedule/medicalreports/retrieve/user', request,
      { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getMedicalReportsByReportId(request: any) {

    return this.apiService.post(this.baseUrl + 'Schedule/medicalreports/retrieve/user', request,
      { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getUpcominAppointments(request, userId: any) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'appointment/view/upcoming', request, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }

  getTodayAppointments(request, endpoint, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + endpoint, request, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }

  getDiagnoDescription(request) {
    return this.apiService.post(this.diagnosticUrl + 'appointment/view', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  downloadPdf(body): Observable<any>{
    return this.apiService.post(this.pdfUrl + 'patient', body,{responseType: 'blob' as 'json'});
  }
  getDiagnosticAddress(id) {
    return this.apiService.get(this.diagnosticUrl + 'address/id/' + id ,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  // get appointmentRoom Id/appointment/id/{appointmentId}

  getDoctorBySpecial(request) {
    return this.apiService.post(this.doctorUrl + 'all/search/critieria', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getRatings(request) {
    return this.apiService.post(this.doctorUrl + 'rating/doctorratingBYserchcritera', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getPersonalDetails(doctorId) {
    if(doctorId!=-1 || doctorId!=undefined || doctorId!=null){
      return this.apiService.get(this.doctorUrl + 'personaldetail/doctorid/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" ,'Cache-Control': 'no-store, no-cache'}) })
    }
  }
  isPatientProfileCompletedOrNot(patientId, forUserId) {
    return this.apiService.post(this.baseUrl + 'userProfileCompleted/' + patientId, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getAllPharmacies() {
    return this.apiService.get(this.pharmaUrl + 'all', { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) ,'Cache-Control': 'no-store, no-cache'}) })
  }
  invoiceData(id,module){
    return this.apiService.get(this.baseUrl + 'invoice/fetchInvoiceByInvoiceId?invoiceId='+id +'&moduleType='+module, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) ,'Cache-Control': 'no-store, no-cache'}) })

  }
  getAllPharmaciesByPost(body) {
    return this.apiService.post(this.pharmaUrl + 'searchpharmacy', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache'}) })
  }
  getAllPharmaciesByPrimary(body) {
    return this.http.get(this.baseUrl + 'getPrimaryLocationForPharmacy/' + body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "forUserId": this.globalStorage.getItem('forUserId').toString(),
    "moduleName": 'PATIENT','Cache-Control': 'no-store, no-cache' }) })
  }
  getAllDiagByPrimary(body) {
    return this.apiService.get(this.baseUrl + 'getPrimaryLocationForDiagnostics/' + body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache'}) })
  }
  getAllCaretakerByPrimary(body) {
    return this.http.get(this.baseUrl + 'getPrimaryLocationForCareTaker/' + body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId').toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getElasticSearchByPharmacyName(searchText) {
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchPharmacyController/find/pharmacy/byName/' + searchText, {})
  }

  getByPharmacyAddressId(id) {
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchPharmacyController/findBypharmacyaddressId/' + id, {})
  }

  getAllPharmaciesByName(name){
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchPharmacyController/pharmacy/byName/' + name, {})
  }

  postFcmToken(request, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'fcm/token', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() ,'Cache-Control': 'no-store, no-cache'}) })

  }
  postFcmTokenDoctor(request) {

    return this.apiService.post(this.doctorUrl + 'fcm/token', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })

  }
bookAppointmentNew(request, userId) {

  return this.apiService.post(this.baseUrl + 'appointment/booking/newRequestWithPayU', request,
    {
      headers: new HttpHeaders({
        "Content-Type": 'application/json','Cache-Control': 'no-store, no-cache',
        'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId
      })
    })
}
  bookAppointment(request, userId) {

    return this.apiService.post(this.baseUrl + 'appointment/booking/newRequest', request,
      {
        headers: new HttpHeaders({
          "Content-Type": 'application/json','Cache-Control': 'no-store, no-cache',
          'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId
        })
      })
  }
  bookDcAppointment(request, userId) {

    return this.apiService.post(this.baseUrl + 'appointment/bookAppointmentWithDC', request,
      {
        headers: new HttpHeaders({
          "Content-Type": 'application/json','Cache-Control': 'no-store, no-cache',
          'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId
        })
      })
  }
  bookDcAppointmentPayU(request, userId) {

    return this.apiService.post(this.baseUrl + 'appointment/bookAppointmentWithDC/newPayu', request,
      {
        headers: new HttpHeaders({
          "Content-Type": 'application/json','Cache-Control': 'no-store, no-cache',
          'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId
        })
      })
  }
  generateInvoice(request, userId) {
    return this.apiService.post(this.baseUrl + 'appointment/buildInvoicePatientForAppointmentBooking', request,
      {
        headers: new HttpHeaders({
          "Content-Type": 'application/json','Cache-Control': 'no-store, no-cache',
          'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString()
        })
      })
  }

  sendHistory(request, userId) {
    let params = new HttpParams()
    params = params.set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'medicalhistorycontroller/save', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  saveHistory(request, userId) {
    let params = new HttpParams()
    params = params.set('forUserId', userId);
    return this.apiService.post(this.ivfUrl + 'save', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  saveInsurenceDetails(request, userId) {
    return this.apiService.post(this.baseUrl + 'medicalinsurance/save', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getInsurenceDetails(request, userId) {
    return this.apiService.post(this.baseUrl + 'medicalinsurance/get', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getMedicineTakenOrNot(userId, medicineId, request) {
    return this.apiService.post(this.baseUrl + 'prescription/is/medication/taken/orNot?forUserId=' + userId + '&medicationId=' + medicineId, request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }

  // GlobalGET
  getNotificationsAlertsDoctor(endpoint,req) {

    return this.apiService.get(this.doctorUrl + endpoint +req,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getLatestDoctorNotificationAlerts(endpoint) {

    return this.apiService.get(this.doctorUrl + endpoint,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getpatientHistory(userId) {
    let params = new HttpParams()
    params = params.set('forUserId', userId);
    return this.apiService.get(this.baseUrl + 'medicalhistorycontroller/getAllMedicalhistoryDetail/' + userId,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getCardDetails(userId) {
    let params = new HttpParams()
    params = params.set('forUserId', userId);
    return this.apiService.get(this.baseUrl + 'payment/carddetails/get/user',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }

  getActiveMedications(userId) {
    let params = new HttpParams()
    params = params.set('forUserId', userId);
    return this.apiService.get(this.baseUrl + 'prescription/get/activemedications/' + userId,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getPreviousPrescriptions(userId) {
    let params = new HttpParams()
    params = params.set('forUserId', userId);
    return this.apiService.get(this.baseUrl + 'prescription/view/previous',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getDoctorDetailsByID(doctorId) {
    if(doctorId!=-1 || doctorId!=undefined || doctorId!=null){
      return this.apiService.get(this.doctorUrl + 'personaldetail/doctorid/' + doctorId,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
    }
  }
  getGlobal(endpoint, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.get(this.baseUrl + endpoint,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getPrescriptionPostSession(endpoint,body,userId){
    return this.apiService.post(this.baseUrl + endpoint, body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  postGlobal(endpoint, userId) {
    return this.apiService.post(this.baseUrl + endpoint, {},
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  ivfPrescription(prescId,ivfaddressId,userId){
    return this.apiService.post(this.ivfUrl+'getAllMedication?hospitalprescriptionId='+prescId+'&ivfaddressId='+ivfaddressId+'&patientId='+userId,{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  ivfTestPrescription(body,userId){
    return this.apiService.post(this.ivfUrl+'medicaltest/view/all',body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getUserPersonalDetails(endpoint, userId, req) {
    // let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + endpoint, req,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId':this.globalStorage.getItem('forUserId'),'Cache-Control': 'no-store, no-cache' }) })
  }
  getPatientActiveMedication(medication) {

    let params = new HttpParams().set('forUserId', medication.forUserId);
    return this.apiService.post(this.baseUrl + 'prescription/medication/view/all', medication, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': medication.forUserId.toString(), "accept": "*/*" ,'Cache-Control': 'no-store, no-cache'}) })
  }

  getPrescriptions(request) {
    let params = new HttpParams().set('forUserId', request);
    return this.apiService.get(this.baseUrl + 'prescription/view/all/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': request.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  // got doctor detail by doctorId
  getDoctorAddress(id: any) {
    return this.apiService.get(this.doctorUrl + 'address/doctorid/' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getPharmaAddress(id) {
    return this.apiService.get(this.pharmaUrl + 'getAddressesOfPharmacy?pharmacyaddressId=' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getDoctorSpecialization(specilization) {
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDoctorController/find/bydoctorspecialization/' + specilization, {})
  }

  getDoctorSpecializationForHsptl(specialization, hospitalAddrId){
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDoctorController/doctorSpecializationForHospital?doctorspecialization=' + specialization + '&hospitalAddressId='+ hospitalAddrId, {});
  }

  getPharmaDetails(id) {
    return this.apiService.get(this.pharmaUrl + 'id/' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getPharmaNewFillRequest(id) {
    return this.apiService.get(this.pharmaUrl + 'prescription/newrequests/' + id + '/' + 1 + '/' + 20,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getPharmaPrescription(id) {
    return this.apiService.get(this.pharmaUrl + 'prescription/prescriptionprice/bypfr/' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getDiscountDetails(id) {
    return this.apiService.get(this.pharmaUrl + 'prescription/invoice/bypfr/' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  globalPharmaPostPath(endpoint) {
    return this.apiService.post(this.pharmaUrl + endpoint, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getNotifications(request) {
    let params = new HttpParams().set('forUserId', request);
    return this.apiService.get(this.baseUrl + 'notificationalerts/get/week/{patientId}',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': request.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getNotificationForWeek(date, request, foruserId) {
    let params = new HttpParams().set('forUserId', foruserId);
    return this.apiService.get(this.baseUrl + 'notificationalerts/getnotifications/fordate/' + date + '/' + request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': foruserId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getNotificationDoctorForWeek(date, request, foruserId) {
    let params = new HttpParams().set('forUserId', foruserId);
    return this.apiService.get(this.doctorUrl + 'notificationalerts/getAllNotificationalertsByDate/fordate/' + date + '/' + request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache'}) })
  }
  getDoctorFeeDetails(id: any) {
    return this.apiService.get(this.doctorUrl + 'feeDetails/' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getDoctorSpecializaiton(id: any) {
    return this.apiService.get(this.doctorUrl + 'all/specilazations/' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getDocument(medicalTestId, userId) {
    let params = new HttpParams();
    //params=params.set('forUserId',userId)
    //params = params.set('medicalreportId', medicalTestId)
    // params=params.set('moduleName',this.globalStorage.getItem('loggedInAs'))
    return this.apiService.get(this.baseUrl + 'Schedule/medicalreports/document/get?medicalreportId='+medicalTestId,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getAppointmentId(id: any, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.get(this.baseUrl + 'appointment/id/' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getDoctorByName(name) {
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDoctorController/searchDocotor/' + name, {})
  }

  getDoctorDetailById(id) {
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDoctorController/findbyid?id=' + id, {})
  }

  getDoctorDataById(id){
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDoctorController/findBydoctorId?doctorId=' + id, {})
  }
  getDoctorByFirstName(firstname){
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDoctorController/searchDocotorByName/' + firstname, {})
  }

  getDoctorBySpecilization(specilization) {
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDoctorController/find/bydoctorspecialization/' + specilization, {})
  }

  getSpecilizations(specilization){
  return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDoctorController/find/doctorspecialization/' + specilization, {})
  }

  getDoctorsList(endpoint, body) {

    // return this.apiService.post(this.doctorUrl+'all/flags',verified,{ headers: new HttpHeaders({'Content-Type': 'application/json', 'user-session':JSON.parse(this.globalStorage.getItem('userSession')),"accept": "*/*"})})
    // return this.apiService.post(this.doctorUrl+'all/flags',verified,
    // { headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'user-session':JSON.parse(this.globalStorage.getItem('userSession'))})})
    return this.apiService.post(this.doctorUrl + endpoint, body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getCaretakersList(endpoint) {
    return this.apiService.post(this.caretakerUrl + endpoint, {},
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getCaretakersByName(name) {
    return this.apiService.get(this.elasticSearchUrl + 'caretakercontroller/searchcaretakerByName/' + name, {})
  }
  getCaretakerID(id){
    return this.apiService.get(this.elasticSearchUrl + 'caretakercontroller/findbyid?id=' + id, {})
  }
  getAllCaretakersbyName(name){
    return this.apiService.get(this.elasticSearchUrl + 'caretakercontroller/searchcaretakerByfullName/' + name, {})
  }

  getCaretakerServices(servicename){
    return this.apiService.get(this.elasticSearchUrl + 'caretakercontroller/findByServcieName/' + servicename, {})
  }
  getDoctorScheduleById(request) {
    return this.apiService.post(this.doctorUrl + 'schedule/all/bycriteria', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getPreviousAppointments(userId: any) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.get(this.baseUrl + 'appointment/view/previous', { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  // Global Post service with body
  globalPost(endpoint, body, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + endpoint, body,
      { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'),'Cache-Control': 'no-store, no-cache' }) })
  }
  downloadReportPDF(url,body) : Observable<any>{
    return this.apiService.post(this.pdfUrl + url, body,{responseType: 'blob' as 'json'});
  }

  ivfPreviousAppointments(url,body){
    return this.apiService.post(this.ivfUrl + url, body,
      { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'),'Cache-Control': 'no-store, no-cache' }) })
  }
  globalgetBody(endpoint, body, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.get(this.baseUrl + endpoint + body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getDoctorSignature(endpoint, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.get(this.doctorUrl + endpoint + userId,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }

  getPrescription(appointmentId, userId) {
    let params = new HttpParams()
    params = params.set('forUserId', userId);
    return this.apiService.get(this.baseUrl + 'prescription/view/appointment/' + appointmentId,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getPrescriptionPost(appointmentId, userId) {
    let params = new HttpParams()
    params = params.set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'prescription/getprescriptionBydoctorappointmentId?doctorAppointmentId=' + appointmentId, {},
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(), "accept": "*/*",'Cache-Control': 'no-store, no-cache' }) })
  }
  getIvfPrescription(appointmentId, userId){
    let params = new HttpParams()
    params = params.set('forUserId', userId);
    const body= {
      "ivfAddressId": this.globalStorage.getItem('ivfAddressId'),
      "maxResult": 20,
      "pageNumber": 1,
      "patientId": userId
    }
    return this.apiService.post(this.ivfUrl + 'getIvfPatientPrescription',body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(), "accept": "*/*",'Cache-Control': 'no-store, no-cache' }) })
  }
  getivfPrescBypost(prescId,ivfAddressId,patientId){
    return this.apiService.post(this.ivfUrl + 'getAllMedication?hospitalprescriptionId='+prescId+'&ivfaddressId='+ivfAddressId+'&patientId='+patientId, {},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': patientId.toString(), "accept": "*/*",'Cache-Control': 'no-store, no-cache' }) })
  }
  getAppointmentPrescription(addressId,userId,appointmentId){
    return this.apiService.get(this.ivfUrl+'getIvfPatientPrescriptionforAppointment?ivfaddressId='+addressId+'&patient='+userId+'&patientAppointmentId='+appointmentId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(), "accept": "*/*",'Cache-Control': 'no-store, no-cache' }) })
  }
  globalPostPath(endpoint, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + endpoint, {},
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  getAddressDetails(doctorId) {
    return this.apiService.get(this.doctorUrl + 'address/doctorid/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*",'Cache-Control': 'no-store, no-cache' }) })
  }
  getCTAddressDetails(id) {
    return this.apiService.get(this.caretakerUrl + 'address?caretakerId=' +id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*",'Cache-Control': 'no-store, no-cache' }) })
  }
  getCaretakerAddress(caretakerId) {
    return this.apiService.get(this.caretakerUrl + 'address?caretakerId=' + caretakerId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getPatientAddress(request: any, userId: any) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'address/get',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getDoctorRating(doctorId) {
    return this.apiService.get(this.doctorUrl + 'rating/get/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*",'Cache-Control': 'no-store, no-cache' }) })
  }
  postDoctorRating(request) {
    return this.apiService.post(this.doctorUrl + 'rating/save/rating', request,
      { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  globalPostBody(endpoint, userId, body) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'notificationalerts/updateNotificationalerts/' + endpoint, body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  doctorNotificationUpdate(endpoint, userId, body) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.doctorUrl + 'notificationalerts/updateNotificationalerts/' + endpoint, body,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  globalDelete(endpoint, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.delete(this.baseUrl + endpoint,
      { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }

  globalDignosticPost(endpoint, body) {
    return this.apiService.post(this.diagnosticUrl + endpoint, body,
      { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'Cache-Control': 'no-store, no-cache' }) })
  }
  getHeartRateReports(selectedPeriod, formData, userId) {
    if(formData?.userId){
      return this.apiService.post(this.baseUrl + 'vitalsReportController/executeReport?time=' + selectedPeriod + '&vital=pulse', formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache'})
    }
  }
  getTemperatureReports(selectedPeriod, formData, userId) {
    return this.apiService.post(this.baseUrl + 'vitalsReportController/executeReport?time=' + selectedPeriod + '&vital=temperature', formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getPressureReports(selectedPeriod, formData, userId) {
    return this.apiService.post(this.baseUrl + 'vitalsReportController/executeReport?time=' + selectedPeriod + '&vital=bloodPressure', formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache'})

  }
  getWeightReports(selectedPeriod, formData, userId) {
    return this.apiService.post(this.baseUrl + 'vitalsReportController/executeReport?time=' + selectedPeriod + '&vital=weight', formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })

  }
  getOxygenLevels(selectedPeriod, formData, userId) {
    return this.apiService.post(this.baseUrl + 'vitalsReportController/executeReport?time=' + selectedPeriod + '&vital=oxygenLevel', formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })

  }
  getUserVitalsForGraph(body,userId){
    return this.apiService.post(this.baseUrl+'vitalsReportController/executeReportForVitals',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getElasticSearchBySpecialization(searchText) {
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDoctorController/find/bydoctorspecialization/' + searchText, {})
  }
  getDiagnosticByUserLocation(body) {
    return this.apiService.post(this.diagnosticUrl + 'findDiagnosticcenterByLocation', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  diagnosticGetPathSession(path) {
    return this.apiService.get(this.diagnosticUrl + path, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getLookupStatic() {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/get/diagnosticcenter/testList?module=DIAGNOSTICCENTER&type=TEST', {})
  }
  sendFeedback(formData) {
    return this.apiService.post(this.utilitiesUrl + 'feedback/create', formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  getMedicationDaily(userId, body) {
    return this.apiService.post(this.baseUrl + 'prescription/getMedicationsForTheDay', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' ,'Cache-Control': 'no-store, no-cache'})
  }
  getAssociationPermissions(id, userId) {
    return this.apiService.get(this.baseUrl + 'assocation/associateUserPrivalge?relationshipId=' + id,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }) })
  }
  updateAssociationPermissions(body, userId) {
    return this.apiService.post(this.baseUrl + 'assocation/associateUserPrivalgeUpdate', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getMedicationBySearch(body, userId) {
    return this.apiService.post(this.baseUrl + 'prescription/medication/view/all', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getAllPrescriptions(userId) {
    return this.apiService.post(this.baseUrl + 'prescription/getAllMedicalprescriptionByuserId?userId=' + userId, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(),'Cache-Control': 'no-store, no-cache' }), observe: 'response' })
  }
  getPrescriptionById(endpoint, body, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.get(this.baseUrl + endpoint + body,
      { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getMedicationBydate(body, userId) {
    return this.apiService.post(this.baseUrl + 'prescription/getMedicationsForTheDay', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getMedicationBydateHospital(body, userId) {
    return this.apiService.post(this.clinicUrl + 'getMedicationsForTheDay', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getAllMecations(body, userId) {
    return this.apiService.post(this.baseUrl + 'prescription/medication/view/all', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getChargePatientInvoice(body, userId) {
    return this.apiService.post(this.baseUrl + 'payment/chargethepatientforinvoice', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getChargeCustomer(body,userId){
    return this.apiService.post(this.baseUrl+'payment/chargeTheCustomer',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getChargeInvoiceByPayu(body, userId) {
    return this.apiService.post(this.baseUrl + 'invoice/populate/paymentdetail', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response','Cache-Control': 'no-store, no-cache' })
  }
  getSurgeryAndAilments(service) {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=' + service + '&type=' + service, {})
  }
  searchPatientDetails(body) {
    return this.apiService.post(this.elasticSearchUrl + 'searchPatient', body,{})
  }
  lookupstatic(module, service) {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=' + module + '&type=' + service, {})
  }
  searchByName(input) {
    return this.apiService.get(this.elasticSearchUrl + 'medicine/search/medicines/by/name/' + input, {})
  }
  hitPayU(body) {
    let params = new HttpParams()
    // params=params.set('',body)
    return this.apiService.post('https://secure.payu.in/_payment', body, {})
  }

  getSpecializationBySearch(search) {
    return this.apiService.post(this.elasticSearchUrl + 'specializationController/searchSpecialization?specialization=' + search, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }

  getDoctorBySearch(search) {
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDoctorController/searchDocotor/' + search, {})
  }
  searchDoctorByName(search,type){
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDoctorController/searchDocotorByName/' + search+'/'+type, {})
  }
  getCaretakerDetailsByName(name) {
    return this.apiService.get(this.elasticSearchUrl + 'caretakercontroller/searchcaretakerByName/' + name, {});
  }
  getCareterDetailsByService(service){
    return this.apiService.get(this.elasticSearchUrl + 'caretakercontroller/searchCaretakerByServiceName/' + service, {});
  }

  getDiagnosticsList(diag) {
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDiagnosticcenterController/find/diagnosticcenter/byName/' + diag, {})
  }

  getDCbyId(dcAddressId){
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDiagnosticcenterController/searchDiagnosticcenterBydiagnosticcenteraddresId/' + dcAddressId, {})
  }

  getDCbyName(name){
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDiagnosticcenterController/diagnosticcenter/byName/' + name, {})
  }

  getDiagnosticsTestList(test) {
    return this.apiService.get(this.elasticSearchUrl + 'elasticsearchDiagnosticcenterController/searchDiagnosticcenterTest/' + test, {})
  }
  getMedicineNames(medicine) {
    return this.apiService.get(this.elasticSearchUrl + 'medicine/findmedicineByName/' + medicine, {})
  }

  getMedicineNameByPharmacy(medicine,pharmacyaddressId){
    return this.apiService.get(this.elasticSearchUrl+ 'medicine/find/allmedicine/byname/'+medicine+'/'+pharmacyaddressId,{})
  }

  getDiagnosticCentersCurrLoc(data) {
    return this.apiService.post(this.elasticSearchUrl + 'elasticsearchDiagnosticcenterController/getLocationMembers', data, {});
  }

  getAllAddresses(entityId,mType,userId)
  {
    return this.apiService.get(this.baseUrl + 'invoice/findInvoiceBypatientAppointmentId?UserId=' + userId + '&moduleType=' + mType + '&patientAppointmentId=' + entityId, {});
  }

  getMedicalHistory(userId)
  {
    return this.apiService.get(this.baseUrl + 'medicalhistorycontroller/getAllMedicalhistoryDetail/' + userId, {});
  }
  getMedicalHistoryIfNoData(){
    return this.apiService.get(this.utilitiesUrl+'lookupstatic/getlookupstaticbylookuptypeandname?module=PATIENT&type=MEDICAL_HISTORY',{})
  }
  labTestList(type, test)
  {
    return this.apiService.get(this.utilitiesUrl +'lookupstatic/getlookupstaticbylookuptypeandname?module=' + type + '&type=' + test, {});
  }

  getPrescriptionDetails(invoiceReferenceId)
  {
    return this.apiService.post(this.baseUrl +'prescription/getPrescriptions?prescriptionId='+invoiceReferenceId, {}, {});
  }

  getPharmacyInvoiceDetails(invoiceId, pharmacyId, userId)
  {
    return this.apiService.get(this.pharmaUrl + 'prescription/prescriptionprice/by/invoiceid?invoiceid=' + invoiceId + '&pharmacyaddressId=' + pharmacyId + '&userid=' + userId, {})
  }
  getAllHospitalsList(pageNo,maxResults){
    return this.apiService.post(this.clinicUrl+'getAll/'+pageNo+'/'+maxResults,{},{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getHospitalDoctors(id){
    return this.apiService.get(this.clinicUrl+'getDoctorDetails?hospitalAddressId='+id,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getAssociatedDoctors(body){
    return this.apiService.post(this.clinicUrl+'getDashboardInformation',body, {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }

  //get Hospital by primary location
  getHospitalByPrimaryLocation(id){
    return this.apiService.get(this.baseUrl + 'getPrimaryLocationForHospital' + '/' + id, {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }

  //get Hospital by current location
  getHospitalByCurrentLocation(body){
    return this.apiService.post(this.elasticSearchUrl + 'hospitalcontroller/getLocationMembers',body, {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  getHospitalList(hosptalName){
    return this.apiService.get(this.elasticSearchUrl + 'hospitalcontroller/hospital/byName/'+hosptalName, {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  getHospitalDetailsByHospitalName(hospitalName){
    return this.apiService.get(this.elasticSearchUrl+'hospitalcontroller/find/hospital/byName/'+hospitalName, {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  getHospitalBySpecilization(specilization){
    return this.apiService.get(this.elasticSearchUrl+'hospitalcontroller/find/hospital/BySpecialization/'+specilization, {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }

  //medical reports
  retrieveAppointmentInDC(dcID,pageNo,maxR){
    return this.apiService.post(this.diagnosticUrl + 'medicalreports/retrieve/appointment/' + dcID + '/' + pageNo + '/' + maxR, {},{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }

  openDcUploadReport(dcID,reportId){
    return this.apiService.post(this.diagnosticUrl + 'medicalreportsDetails?diagnosticCenterAddresId=' + dcID + '&medicalReportsId=' + reportId , {},{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }

  // Billdesk Token Decrypt API
  getBDecryptedToken(token){
    return this.apiService.get(this.baseUrl + 'appointment/getBillDeskToken?token=' + token, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId').toString(),'Cache-Control': 'no-store, no-cache' }) })
  }

  passTransactionData(token){
    return this.apiService.post(this.baseUrl + 'chargeThePatientByBilldesk?transaction_response=' + token,{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId').toString(),'Cache-Control': 'no-store, no-cache' }) })
  }

  CancelInvoiceByUser(invoiceId, reason){
    return this.apiService.get(this.baseUrl + 'invoice/updateInvoiceShippingByCancleByUser?invoiceId='+ invoiceId + '&status=' + reason, {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }

  getAllAppointmentsForUser(request){
    return this.apiService.post(this.baseUrl + 'appointment/getAllAppointmentsForUser', request , {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  switchTo(body){
    return this.apiService.post(this.baseUrl+'getUserDetail',body,{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }

  // reports API
  getAllReportsData(request){
    return this.apiService.post(this.baseUrl + 'reports/excuteDashboard', request, {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  getHospitalReports(request){
    return this.apiService.post(this.clinicUrl+'reports/excuteDashboard',request,{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('hospitalAddressId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  saveIvfCaseDetails(body){
    return this.apiService.post(this.ivfUrl+'saveCaseDetailsInformation',body,{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  getIvfCaseDetails(ivfAddressId,userId){
    return this.apiService.get(this.ivfUrl + 'retriveCaseDetailsInformation?ivfAddressId='+ivfAddressId+'&patientId='+userId, {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  postIvfClinicalExaminationDetails(body){
    return this.apiService.post(this.ivfUrl+'saveClinicalExamination',body,{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  getIvfClinicalExaminationDetails(ivfAddressId,caseDetailInformationId){
    return this.apiService.get(this.ivfUrl + 'retriveClinicalExamination?ivfAddressId='+ivfAddressId+'&caseDetailInformationId='+caseDetailInformationId, {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }

  postIvfInvestigations(body){
    return this.apiService.post(this.ivfUrl+'insertInvestigations',body,{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  getIvfInvestigations(ivfAddressId,patientId){
    return this.apiService.get(this.ivfUrl + 'investigationsInformation?ivfAddressId='+ivfAddressId+'&patientId='+patientId, {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  getDcReport(appointmentId,userId){
    return this.apiService.get(this.ivfUrl + 'findReportByAppointmentIdAndUserId?appointmentId='+appointmentId+'&userId='+userId, {headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  saveUser(body){
    return this.apiService.post(this.baseUrl+'addUserDetail',body,{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  searchPatientByPhoneNumber(phoneNumber){
    return this.apiService.post(this.baseUrl+'findPatientByCellNumer?cellNumber='+phoneNumber,{},{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  addCart(body){
    return this.apiService.post(this.baseUrl+'saveCartItem',body,{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  getCartData(pharmacyAddressId,userId){
    if(pharmacyAddressId && userId){
      return this.apiService.get(this.baseUrl+'getCartItems?pharmacyAddressId='+pharmacyAddressId+'&userId='+userId,{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
    }
  }
  deleteCartItem(inventoryId,pharmacyAddressId,userId){
    return this.apiService.delete(this.baseUrl+'deleteCartItem?inventoryId='+inventoryId+'&pharmacyAddressId='+pharmacyAddressId+'&userId='+userId,{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  getCartDetails(userId){
    return this.apiService.get(this.baseUrl+'getPharmacyByUserId?userId='+userId,{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  deleteCartDetails(pharmacyAddressId,userId){
    return this.apiService.delete(this.baseUrl+'deletePharmacyInCart?pharmacyAddressId='+pharmacyAddressId+'&userId='+userId,{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
  getInvoiceByFillRequestId(fillReqId,userId){
    return this.apiService.get(this.baseUrl+'invoice/fetchInvoiceByInvoiceEntityReferenceId?invoiceEntityReferenceId='+fillReqId+'&moduleType=PHARMACY&patientId='+userId,{headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),"forUserId": this.globalStorage.getItem('forUserId'),"moduleName": this.globalStorage.getItem('loggedInAs')})})
  }
}
