import { Injectable } from '@angular/core'
import * as CryptoJS from 'crypto-js'; 
@Injectable({
  providedIn: 'root'
})
export class Crypto {

    encryption(conversion: string,conversion1: string):string {
      console.log('remember',conversion,conversion1)
     return CryptoJS.AES.encrypt(conversion?.trim(),conversion1?.trim())
    }

    Decrypt(encryptText: string,decPassword: string):string {
        return CryptoJS.AES.decrypt(encryptText?.trim(),decPassword.trim())?.toString(CryptoJS.enc.Utf8);; 
       }
}