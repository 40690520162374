<!DOCTYPE html>
<html lang="en">

<head>
  <link rel="stylesheet" href="https://curemetric.com/assets/css/bootstrap.min.css">
  <link rel="stylesheet" href="https://curemetric.com/assets/css/style.css">

</head>

<body>

  <section class="cure-service-area cure-features-area pt-90 pb-100" id="service">
    <div class="container cureicon">
      <div class="filters_listing">
        <div class="container">
          <ul class="clearfix">
            <li>
              <div class="switch-field">
                <input type="radio" id="doctors" name="type_patient" value="doctors" checked=""
                  (click)="getDoctorsList()">
                <label for="doctors"><i class="fa fa-user-md pb-1 fa-2x"></i> <br>Doctors</label>
                <input type="radio" id="caretakers" name="type_patient" (click)="caretakers()" value="caretakers">
                <label for="caretakers"><i class="fa fa-user-nurse pb-1 fa-2x"></i> <br>
                  Caretakers</label>
                <input type="radio" id="nutritionists" name="type_patient" value="nutritionists"
                  (click)="nutritionistList()">
                <label for="nutritionists"><i class="fa-brands fa-nutritionix pb-1 fa-2x"></i> <br>
                  Nutritionists</label>
                <input type="radio" id="diagnosticall" name="type_patient" value="diagnosticall"
                  (click)="diagnosticall()">
                <label for="diagnosticall"><i class="fa fa-microscope pb-1 fa-2x"></i> <br>
                  Diagnostic</label>


                <input type="radio" id="hospital" name="type_patient" value="hospital" (click)="hospitals()">
                <label for="hospital"><i class="fa fa-hospital-o pb-1 fa-2x"></i> <br>
                  Hospitals</label>
              </div>
            </li>


          </ul>
        </div>
        <!-- /container -->
      </div>
    </div>
    <div id="doctors" class="doctors desc container" *ngIf="doctorsOn">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="cure-section-title text-center">
            <h3 class="cure-title">Doctors</h3>
            <p>Verified Doctors</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">

          <!-- <form class=""> -->
          <div class="input-group input-group-sm input-group-solid  mb-2" style="height:35px;">
            <input type="text" class="form-control doctorName" id="kt_subheader_search_form" #doctorName
              [(ngModel)]="doctorSelected" value="" aria-label="Number" matInput [matAutocomplete]="auto1"
              (keypress)="alphaCharsOnly1($event)" (keyup)="searchDoctorByName(doctorName.value,$event)"
              placeholder="Search by Doctor name">
            <mat-autocomplete #auto1="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptionsD | async" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>
            <div class="input-group-append " (click)="getDoctorsLists(doctorSelected)"
              style="height: 35px;width: 35px;padding-left:3px;background-color: #5db3a8;color: #fff;padding-top: 2px;border-radius: 0px 4px 4px 0px;">
              <i class="fa fa-search fs-16 p-2 "></i>
            </div>
          </div>
          <!-- </form> -->

        </div>
        <div class="col-md-6">

          <!-- <form class=""> -->
          <div class="input-group input-group-sm input-group-solid  mb-2" style="height:35px;">
            <input type="text" class="form-control" id="kt_subheader_search_form" #specialization
              [(ngModel)]="specializationSelected" (keypress)="alphaCharsOnly2($event)"
              (keyup)="getSpecializationsByName(specialization.value, $event)"
              aria-label="Number" matInput [matAutocomplete]="auto2" placeholder="Search by specialization name">

            <mat-autocomplete #auto2="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptionsS | async" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>

            <div class="input-group-append " (click)="getSpecializationDoctors(specializationSelected)"
              style="height: 35px;width: 35px;padding-left:3px;background-color: #5db3a8;color: #fff;padding-top: 2px;border-radius: 0px 4px 4px 0px;">
              <i class="fa fa-search fs-16 p-2 "></i>
            </div>
          </div>
          <!-- </form> -->

        </div>
        <div class="col-lg-4 col-md-6" *ngFor="let doctor of doctors">
          <div class="strip_list wow fadeIn">
            <a href="#0" class="wish_bt"></a>
            <figure>
              <a href="#"><img src="https://curemetric.com/images/doctor_listing_1.jpg" alt=""></a>
            </figure>
            <small>{{doctor?.specialization}}</small>
            <h3>Dr. {{doctor?.firstName}} {{doctor?.lastName}}</h3>
            <p>Exp:{{doctor?.totalExperience==null?0:doctor?.totalExperience}} years
            </p>
            <div class="d-block  text-align-left">
              <div *ngIf="doctor.averagerating==1">
                <span class="fa fa-star checked fs-12"
                  *ngFor='let in of doctor.averagerating | demoNumber ;let i = index'></span>
                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2,3]'></span> <a
                  href="javascript:void(0)"></a>
              </div>
              <div *ngIf="doctor.averagerating==2">
                <span class="fa fa-star checked fs-12"
                  *ngFor='let in of doctor.averagerating | demoNumber ;let i = index'></span>
                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2]'></span> <a
                  href="javascript:void(0)"></a>
              </div>
              <div *ngIf="doctor.averagerating==3">
                <span class="fa fa-star checked fs-12"
                  *ngFor='let in of doctor.averagerating | demoNumber ;let i = index'></span>
                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1]'></span> <a
                  href="javascript:void(0)"></a>
              </div>
              <div *ngIf="doctor.averagerating==4">
                <span class="fa fa-star checked fs-12"
                  *ngFor='let in of doctor.averagerating | demoNumber ;let i = index'></span>
                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0]'></span> <a
                  href="javascript:void(0)"></a>
              </div>

              <div *ngIf="doctor.averagerating==5 || doctor.averagerating>5">
                <span class="fa fa-star checked fs-12"
                  *ngFor='let in of doctor.averagerating | demoNumber ;let i = index'></span>
                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of []'></span> <a
                  href="javascript:void(0)"></a>
              </div>

              <div *ngIf="doctor.averagerating==0 || doctor.averagerating ==null">
                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2,3,4]'></span>
              </div>

            </div>
            <!-- <span class="rating"><i class="fa fa-star voted"></i><i class="fa fa-star voted"></i><i
                                class="fa fa-star voted"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                            <small>(145)</small></span> -->

            <ul>


              <li>
                <h3><i class="fa fa-video "></i> ₹ {{doctor?.fee}}</h3>
              </li>
              <li><a href="javascript:void(0)" (click)="booknow(doctor)">Book now</a></li>
            </ul>
          </div>

        </div>








      </div>


    </div>
    <div id="caretakers" class="doctors desc container" *ngIf="caretakersOn">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="cure-section-title text-center">
            <h3 class="cure-title">Caretakers</h3>
            <p>Verified Caretakers</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">

          <!-- <form class=""> -->
          <div class="input-group input-group-sm input-group-solid" style="height:35px;">
            <input type="text" class="form-control" id="kt_subheader_search_form" [(ngModel)]="searchText" #name
              aria-label="Number" matInput [matAutocomplete]="auto4" (keypress)="alphaCharsOnly($event)"
              (keyup)="searchCaretaker(name.value, $event)">
            <mat-autocomplete #auto4="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptionsC | async" [value]="option">
                {{option}}
              </mat-option>
            </mat-autocomplete>

            <div class="input-group-append " (click)="getCareTakerDetailsByName(searchText)"
              style="height: 35px;width: 35px;padding-left:3px;background-color: #5db3a8;color: #fff;padding-top: 2px;border-radius: 0px 4px 4px 0px;">
              <i class="fa fa-search fs-16 p-2 "></i>
            </div>
          </div>
          <!-- </form> -->

        </div>

        <div class="col-md-3 mb-0 p-0">


          <!-- <form class="" [formGroup]="searchForm"> -->
          <div class="row">
            <div class="col-md-6 ">
              <input type="text" maxlength="6" #pincode [(ngModel)]="pincodeEntered" (keypress)="numberOnly($event)"
                class="form-control mb-0" placeholder="Enter pincode" />
            </div>
            <div class="col-md-6 form-group">
              <select name="country" class="form-control mb-0" id="aioConceptName" [(ngModel)]='ngSelectedType'
                (change)="choosePincode(pincode.value)">
                <option value="{{option?.value}}" *ngFor="let option of countryCodes">{{option?.value}}</option>
                <!-- <option selected hidden disabled value="">Select</option>
                    <option value="IN">IN</option>
                    <option value="US">US</option> -->
              </select>
            </div>
          </div>
          <!-- </form> -->

        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let caretaker of caretakersList">
          <div class="strip_list wow fadeIn">
            <a href="#0" class="wish_bt"></a>
            <figure>
              <a href="#"><img src="https://curemetric.com/images/caretaker-icon.jpg" alt=""></a>
            </figure>
            <small>{{caretaker?.specialization}}</small>
            <h3>Dr. {{caretaker?.firstName}} {{caretaker?.lastName}}</h3>
            <p>Exp:{{caretaker?.totalExperience==null?0:caretaker?.totalExperience}} years
            </p>
            <div class="d-block  text-align-left">
              <div *ngIf="caretaker?.averagerating==1">
                <span class="fa fa-star checked fs-12"
                  *ngFor='let in of caretaker?.averagerating | demoNumber ;let i = index'></span>
                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2,3]'></span> <a
                  href="javascript:void(0)"></a>
              </div>
              <div *ngIf="caretaker?.averagerating==2">
                <span class="fa fa-star checked fs-12"
                  *ngFor='let in of caretaker?.averagerating | demoNumber ;let i = index'></span>
                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2]'></span> <a
                  href="javascript:void(0)"></a>
              </div>
              <div *ngIf="caretaker?.averagerating==3">
                <span class="fa fa-star checked fs-12"
                  *ngFor='let in of caretaker?.averagerating | demoNumber ;let i = index'></span>
                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1]'></span> <a
                  href="javascript:void(0)"></a>
              </div>
              <div *ngIf="caretaker?.averagerating==4">
                <span class="fa fa-star checked fs-12"
                  *ngFor='let in of caretaker?.averagerating | demoNumber ;let i = index'></span>
                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0]'></span> <a
                  href="javascript:void(0)"></a>
              </div>

              <div *ngIf="caretaker?.averagerating==5 || caretaker?.averagerating>5">
                <span class="fa fa-star checked fs-12"
                  *ngFor='let in of caretaker?.averagerating | demoNumber ;let i = index'></span>
                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of []'></span> <a
                  href="javascript:void(0)"></a>
              </div>

              <div *ngIf="caretaker?.averagerating==0 || caretaker?.averagerating ==null">
                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2,3,4]'></span>
              </div>

            </div>
            <!-- <span class="rating"><i class="fa fa-star voted"></i><i class="fa fa-star voted"></i><i
                                class="fa fa-star voted"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
                            <small>(145)</small></span> -->
            <ul>
              <li>
                <h3><i class="fa fa-video "></i> ₹ {{caretaker?.fee}}</h3>
              </li>
              <li><a href="javascript:void(0)" (click)="booknow(caretaker)">Book now</a></li>
            </ul>
          </div>

        </div>
      </div>
    </div>
    <div id="nutritionists" class="doctors desc container" *ngIf="nutritionistOn">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="cure-section-title text-center">
            <h3 class="cure-title">Nutritionists</h3>
            <p>Verified Nutritionists</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let doctor of nutritionists">
          <div class="strip_list wow fadeIn">
            <a href="#0" class="wish_bt"></a>
            <figure>
              <a href="#"><img src="https://curemetric.com/images/doctor_listing_1.jpg" alt=""></a>
            </figure>
            <small>{{doctor?.specialization}}</small>
            <h3>Dr. {{doctor?.firstName}} {{doctor?.lastName}}</h3>
            <div *ngIf="doctor.averagerating==0 || doctor.averagerating ==null">
              <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2,3,4]'></span>
            </div>
            <span class="rating"><i class="fa fa-star voted"></i><i class="fa fa-star voted"></i><i
                class="fa fa-star voted"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
              <small>(145)</small></span>

            <ul>


              <li>
                <h3><i class="fa fa-video "></i> ₹ {{doctor?.fee}}</h3>
              </li>
              <li><a href="javascript:void(0)" (click)="booknow(doctor)">Book now</a></li>
            </ul>
          </div>

        </div>
      </div>


    </div>
    <div id="diagnosticall" class="doctors desc container" *ngIf="diagnosticOn">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="cure-section-title text-center">
            <h3 class="cure-title">Diagnostic Centers</h3>
            <p>Verified Diagnostic Centers</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let diagnosticall of diagnosticList">
          <div class="strip_list wow fadeIn">
            <a href="#0" class="wish_bt"></a>
            <figure>
              <a href="#"><img src="https://curemetric.com/images/diagnostic-icon.jpg" alt=""></a>
            </figure>
            <small>{{diagnosticall?.primaryUserEmailId}}</small>
            <h3> {{diagnosticall?.name}}</h3>
            <p>{{diagnosticall?.primaryDataCenterAddress}}</p>
            <div *ngIf="diagnosticall.averagerating==0 || diagnosticall.averagerating ==null">
              <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2,3,4]'></span>
            </div>


            <ul>

              <li>
                <h3> </h3>
              </li>

              <li><a href="javascript:void(0)" (click)="booknow(diagnosticall)">Book now</a></li>
            </ul>
          </div>

        </div>
      </div>


    </div>


    <div id="hospital" class="doctors desc container" *ngIf="hospitalOn">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="cure-section-title text-center">
            <h3 class="cure-title">Hospitals</h3>
            <p>Verified Hospitals</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6" *ngFor="let hospitals of hospitalList">
          <div class="strip_list wow fadeIn">
            <a href="#0" class="wish_bt"></a>
            <figure>
              <a href="#"><img src="https://curemetric.com/images/hospital.jpg" alt=""></a>
            </figure>
            <small>{{hospitals?.websiteURL}}</small>
            <h3>{{hospitals?.name}}</h3>
            <p>{{hospitals?.city}}
            </p>
            <span class="rating"><i class="fa fa-star voted"></i><i class="fa fa-star voted"></i><i
                class="fa fa-star voted"></i><i class="fa fa-star"></i><i class="fa fa-star"></i>
            </span>

            <ul>

              <li>
                <h3> </h3>
              </li>

              <li><a href="javascript:void(0)" (click)="booknow(hospitals)">Book now</a></li>
            </ul>
          </div>

        </div>
      </div>


    </div>

    <!-- <div class="features-shape-1">
        <img src="assets/images/shape/shape-6.png" alt="shape">
    </div>
    <div class="features-shape-2">
        <img src="assets/images/shape/shape-7.png" alt="shape">
    </div>
    <div class="features-shape-3">
        <img src="assets/images/shape/shape-8.png" alt="shape">
    </div> -->
  </section>

</body>

</html>