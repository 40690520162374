import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SharedModule } from './shared/shared.module';

import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import resourceTimelinePlugin from '@fullcalendar/daygrid'; // a plugin
import timeGrigPlugin from '@fullcalendar/timegrid';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
// import { AdminLayoutModule } from './layout/doctor-layout/doctor-layout.module';
import { AuthLayoutModule } from './layout/auth-layout/auth-layout.module';
// import { PatientLayoutModule } from './layout/patient-layout/patient-layout.module';
// import { CaretakerLayoutModule } from './layout/caretaker-layout/caretaker-layout.module';

import { MaterialModule } from './shared/material/material.module';

// import { PatientPagesModule } from './patient-pages/patient-pages.module';
// import { CaretakerPagesModule } from './caretaker-pages/caretaker-pages.module';
// import { ClinicPagesModule } from './clinic-pages/clinic-pages.module';
import { ToastrModule } from 'ngx-toastr';
// import { GoogleChartsModule } from 'angular-google-charts';
import { StorageService } from './shared/services/storage.service';


// import { DoctorListComponent } from './DoctorPages/doctor-registration/doctor-list/doctor-list.component';
// import { PaintListComponent } from './DoctorPages/doctor-registration/paint-list/paint-list.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ErrorComponent } from './error/error.component';
import { NotificationService } from './shared/notification.service';
import { environment } from 'src/environments/environment';
import { AsyncPipe, DatePipe } from '@angular/common';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';
//import { SidebarModule } from 'ng-sidebar';
import { BookAppointmentComponent } from './DoctorPages/book-appointment/book-appointment.component';
import { RejectAppontmentComponent } from './DoctorPages/reject-appontment/reject-appontment.component';
//import { ChangePasswordComponent } from './DoctorPages/change-password/change-password.component';
// import { DragScrollModule } from 'ngx-drag-scroll';
import { CancelAppintmentComponent } from './DoctorPages/cancel-appintment/cancel-appintment.component';
import { RescheduleAppointmentComponent } from './DoctorPages/reschedule-appointment/reschedule-appointment.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DeleteConfirmationComponent } from './DoctorPages/delete-confirmation/delete-confirmation.component';
import { DoctorDetailsComponent } from './DoctorSideNavePages/doctor-details/doctor-details.component';
import { PharmacyLayoutComponent } from './layout/pharmacy-layout/pharmacy-layout.component';
import { PharmacyPagesModule } from './pharmacy-pages/pharmacy-pages.module';
import { ObservableService } from './shared/services/observable.service';
import { DiagnosticLayoutComponent } from './layout/diagnostic-layout/diagnostic-layout.component';
// import { DiagnosticLayoutModule } from './layout/diagnostic-layout/diagnostic-layout.module';
import { DoctorGuardGuard } from './layout/doctor-layout/doctor-guard.guard';
import { BnNgIdleService } from 'bn-ng-idle';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { MatInputModule } from '@angular/material/input';
import { DiagnosticMenuComponent } from './diagnostic-pages/diagnostic-menu/diagnostic-menu.component';
import { DiagnosticAppointmentsComponent } from './diagnostic-pages/diagnostic-appointments/diagnostic-appointments.component';
import { DiagnosticMedicalRecordsComponent } from './diagnostic-pages/diagnostic-medical-records/diagnostic-medical-records.component';
import { DiagnosticReportsComponent } from './diagnostic-pages/diagnostic-reports/diagnostic-reports.component';
import { DiagnosticManagePricingComponent } from './diagnostic-pages/diagnostic-manage-pricing/diagnostic-manage-pricing.component';
import { DiagnosticOrderManagementComponent } from './diagnostic-pages/diagnostic-order-management/diagnostic-order-management.component';
// import { ClinicLayoutModule } from './layout/clinic-layout/clinic-layout.module';
// import { NutritionistPagesModule } from './nutritionist-pages/nutritionist-pages.module';
// import { NutritionistLayoutModule } from './layout/nutritionist-layout/nutritionist-layout.module';
import { DiagnosticScheduleCalanderComponent } from './diagnostic-pages/diagnostic-schedule-calander/diagnostic-schedule-calander.component';
import { PatientHistoryComponent } from './DoctorPages/patient-history/patient-history.component';
import { DoctorAllReportsComponent } from './DoctorPages/doctor-all-reports/doctor-all-reports.component';
import { DiagnosticAllReportsComponent } from './diagnostic-pages/diagnostic-all-reports/diagnostic-all-reports.component';
import { ClinicGuard } from './layout/clinic-layout/clinic.guard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

//Api call Services here
import { ApicallsService } from './apicalls.service';
import { WebAppointmentComponent } from './web-appointment/web-appointment.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { FertilityComponent } from './fertility/fertility.component';
// import { FertilityNavComponent } from './fertility-nav/fertility-nav.component';
import { FertilityDashboardComponent } from './fertility/fertility-dashboard/fertility-dashboard.component';
import { BilldeskService } from './billdesk.service';
import { PatientDetailsNewComponent } from './common-components/patient-details-new/patient-details-new.component';
import { AdminLayoutModule } from './layout/doctor-layout/doctor-layout.module';
// import { ArtSummaryPdfComponent } from './art-summary-pdf/art-summary-pdf.component';
// import { IvfAdddepartmentComponent } from './ivf-adddepartment/ivf-adddepartment.component';
// import { InventoryAdminComponent } from './inventory-admin/inventory-admin.component';
// import { ProfileiconPipe } from './profileicon.pipe';


FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  resourceTimelinePlugin,
  timeGrigPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    RejectAppontmentComponent,
    CancelAppintmentComponent,
    RescheduleAppointmentComponent,
    DeleteConfirmationComponent,
    DoctorDetailsComponent,
    // PharmacyLayoutComponent,
    // DiagnosticLayoutComponent,
    DiagnosticMenuComponent,
    DiagnosticAppointmentsComponent,
    DiagnosticMedicalRecordsComponent,
    DiagnosticReportsComponent,
    DiagnosticManagePricingComponent,
    DiagnosticOrderManagementComponent,
    DiagnosticScheduleCalanderComponent,
    PatientHistoryComponent,
    DoctorAllReportsComponent,
    DiagnosticAllReportsComponent,
    WebAppointmentComponent,
    FertilityComponent,
    FertilityComponent,
    FertilityDashboardComponent,
    PatientDetailsNewComponent,
   
    // IvfAdddepartmentComponent,
    // InventoryAdminComponent,

  ],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    Ng2SearchPipeModule,
    FullCalendarModule,
    BrowserModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    // DragScrollModule,
    AppRoutingModule,
    SharedModule,
    NgxPaginationModule,
    PdfViewerModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AdminLayoutModule,
    // AdminLayoutModule,
    AuthLayoutModule,
    // PatientLayoutModule,
    // CaretakerPagesModule,
    // ClinicPagesModule,
    // CaretakerLayoutModule,
    //ClinicLayoutModule,
    MatInputModule,
    // PatientPagesModule,
    //  PharmacyPagesModule,
    // DiagnosticLayoutModule,
    MaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    // NutritionistPagesModule,
    //NutritionistLayoutModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }
    ),
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    // SidebarModule.forRoot(),
    NgbModule,
    // ProfileiconPipe,
  ],
  entryComponents: [BookAppointmentComponent, RejectAppontmentComponent, CancelAppintmentComponent, RescheduleAppointmentComponent, DeleteConfirmationComponent, PatientDetailsNewComponent],
  providers: [DatePipe, ApicallsService, StorageService, ClinicGuard, NotificationService, BnNgIdleService, DoctorGuardGuard, AsyncPipe, PdfViewerComponent, ObservableService,
    BilldeskService],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class AppModule { }

