import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'demoNumber'})
export class DemoNumber implements PipeTransform {
  transform(value) : any {
    let res = [];
    if(value==0){
      return res;
    }
    for (let i = 0; i < value; i++) {
        res.push(i);
      }
      return res;
  }
}