<link rel="stylesheet" type="text/css"
  href="https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.8/css/intlTelInput.css" />


  <nav id="sidebaradi" class="active side-nav-cure">
    <a href="javascript:void(0)" class="logocure"> <img src="./assets/images/indexpageicons/logo.svg"> </a>
    <ul class="list-unstyled components mb-5">
       <li class="active home_icon"> <a href="javascript:void(0)"  (click)="navigateToRelatedDashboard()" data-toggle="tooltip" data-placement="right" title="Home"> <span class="homeiconclss"> <img class="hs1" src="./assets/images/indexpageicons/home_line.svg"> </span>  Home</a> </li>
 <li class="inner_icon" *ngIf="adminPermissions"> <a href="javascript:void(0)" routerLink="/clinic-admin" (click)="navigateTo('clinic-admin')" data-toggle="tooltip" data-placement="right" title="Admin"> <span> <img class="hs8"  src="./assets/images/indexpageicons/admin.svg"> </span>Admin</a> </li>
             <li class="inner_icon" *ngIf="loginUser?.roleId!=10"> <a  href="javascript:void(0)" routerLink="/clinic-appointments" (click)="navigateTo('clinic-appointments')" data-toggle="tooltip" data-placement="right" title="Appointments"> <span> <img class="hs2" src="./assets/images/indexpageicons/reports_white.svg"> </span> Appoinments</a> </li>
       <li class="inner_icon"> <a  routerLink="/in-patient-list" data-toggle="tooltip" data-placement="right" title="In-patient-list"> <span> <img class="hs3" src="./assets/images/indexpageicons/ipdlist.svg"> </span>IPD List</a> </li>
       <!-- <li class="inner_icon"> <a routerLink="/clinic-users" (click)="navigateTo('clinic-users')" data-toggle="tooltip" data-placement="right" title="Out-patient-list"> <span> <img class="hs4" src="./assets/images/indexpageicons/outpatient.svg"> </span>OPD List</a> </li> -->
       <!-- <li class="inner_icon"> <a href="#" data-toggle="tooltip" data-placement="right" title="Pharmacy"> <span> <img class="hs5" src="./assets/images/indexpageicons/pharma.svg"> </span>Pharmacy</a> </li>
       <li class="inner_icon"> <a href="#" data-toggle="tooltip" data-placement="right" title="Diagnostic Center"> <span> <img class="hs6" src="./assets/images/indexpageicons/diagnostic.svg"> </span>Diagnostic Center</a> </li> -->
       <li class="inner_icon"> <a href="javascript:void(0)" routerLink="/clinic-users" (click)="navigateTo('clinic-users')" data-toggle="tooltip" data-placement="right" title="Patients"> <span> <img class="hs7" src="./assets/images/indexpageicons/patients.svg"> </span> Patients</a> </li>

       <li class="inner_icon">
        <a href="javascript:void(0)" routerLink="/patient-records" title="" *ngIf="adminPermissions" data-toggle="tooltip"
          data-original-title="Patient Records" data-placement="right">
          <span> <img class="hs7" src="./assets/images/indexpageicons/patients.svg"> </span>
            Patient Records
        </a>
      </li>
      <li class="inner_icon" *ngIf="loginUser?.roleId==1">
        <a href="javascript:void(0)" routerLink="/allDiscountsRequests" title="" data-toggle="tooltip"
          data-original-title="Discount Requests" data-placement="right">
          <span> <img class="hs7" src="./assets/images/indexpageicons/patients.svg"> </span>
            Discount Requests
        </a>
      </li>

       <li class="inner_icon"> <a href="javascript:void(0)" routerLink="/clinic-bloodbank"  (click)="navigateTo('clinic-bloodbank')" data-toggle="tooltip" data-placement="right" title="Blood Bank"> <span> <img class="hs8" src="./assets/images/indexpageicons/blood_bank.svg"> </span>Blood Bank</a> </li>
       <li class="inner_icon"> <a href="javascript:void(0)"  routerLink="/clinic-inventory" (click)="navigateTo('clinic-inventory')" data-toggle="tooltip" data-placement="right" title="Inventory"> <span> <img class="hs8" src="./assets/images/indexpageicons/inventory.svg"> </span>Inventory</a> </li>
       <li class="inner_icon"> <a href="javascript:void(0)" routerLink="/clinic-all-reports" data-toggle="tooltip" data-placement="right" title="Reports" *ngIf="adminPermissions"> <span> <img class="hs9" src="./assets/images/indexpageicons/profile.svg"> </span> Reports</a> </li>
    </ul>
 </nav>





<header class="l-header">
  <div class="l-header__inner clearfix">
    <!-- <div class="c-header-icon js-hamburger no-mobile">
      <div class="hamburger-toggle"><span class="bar-top"></span><span class="bar-mid"></span><span
          class="bar-bot"></span></div>
    </div> -->
    <img   height="60"  *ngIf="imgPresent" [src]="imgURL" class="image">
    <div style="float: right;" class="ml-2 mr-4"><div><b class="ml-1 mr-1 captilize">Welcome to {{loginUser?.hospitalName}}</b></div>
    <div class=" d-none d-lg-block mr-2 nmobile ml-1">     {{hospitalAddress?.city}}</div></div>
    <div class="c-search p-0 no-desktop">
      <img src="./assets/img/logo-mobile.png" />
    </div>
    <fieldset class="no-mobile" style="  margin: 0; padding: 0; border: 1px solid #ccc; padding-bottom: 5px;
    padding-left: 6px; min-width: 500px; margin-left:16px" *ngIf="loginUser?.roleId==1 || loginUser?.roleId==11">
      <div class="c-search  d-flex flex-row" style="padding: 0;" >
        <!-- <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2"
          style="margin-left: 2%;padding-left: 0px;padding-top: 7px;font-size: 10px;font-weight: 600;width: 234px;">
          <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="selectedOption">
            <mat-radio-button class="example-radio-button" *ngFor="let option of options" [value]="option"
              (change)="checkBoxCheck($event,option)">
              {{option}}
            </mat-radio-button>
          </mat-radio-group>
          <p style=" padding: 0; margin-top: 2px; margin: 0; margin-top: 3px; font-size: 12px; ">{{radioMessage}}</p>
        </div> -->
        <div class="pr-2 d-flex flex-column flex-grow-1 mr-2 w-100"> <input class="form-control" placeholder="Search Patient"
            #patientName (keypress)="typeAheadeSearchByName(patientName.value)" [(ngModel)]="patientSelected" matInput
            [formControl]="myControl" [matAutocomplete]="auto1" />
          <!-- <mat-autocomplete #auto1="matAutocomplete" (optionSelected)='getSelectedValue($event.option.value,$event)' [panelWidth]="'auto'">
            <mat-option *ngFor="let option of filteredOptionsD | async" [value]="option?.name" min-width="100%">
              {{option?.name option?.cellNumber}}
            </mat-option>
          </mat-autocomplete> --> 
          <mat-autocomplete #auto1="matAutocomplete"  [panelWidth]="'auto'">
            <mat-option *ngFor="let option of filteredOptionsD | async" [value]="option.name" (click) = "getSelectedValue(option,'y')">
              {{option.name}} {{option.number}}
            </mat-option>
          </mat-autocomplete>

          <span style="position: absolute;margin-left: 278px;margin-top: 0;"><i class="fa fa-search fs-16 p-2"
              (click)="getSelectedValue(patientSelected,'s')"></i></span>
        </div>
        <div class=" d-flex flex-column flex-grow-1 mr-2" style="  ">
          <button class="btn btn-sm btn-primary float-right" type="submit" style="width: 150px;" data-toggle="modal"
            (click)="userCreation()"><i aria-hidden="true" class="fa fa-user-plus"></i>
            New Patient</button>
        </div>

        <!-- <span class="float-left pt-0 d-none d-lg-block mr-2" *ngIf="firstTimeLogin">
              Welcome to CureMetric. This is your first login.
              </span> -->
      </div>
    </fieldset>

    <div class="header-icons-group">
      <div class="mt-2" style="float: right;">
        <div>Logged in <b class="ml-1 mr-1 captilize">{{loginUser?.firstName |
            titlecase}} {{loginUser?.lastName | titlecase}}</b> </div>
        <div class=" d-none d-lg-block mr-2 nmobile">
          Last login : {{loginUser?.lastLogin | date:'dd-MMM-yyyy hh:mm a'}}
        </div>
      </div>
      <!-- messages start -->
      <div class="c-header-icon has-dropdown bg-success-light" aria-hidden="true"  data-placement="left" data-toggle="tooltip" data-original-title="Create Message" (click)="createMessage()">
        <i class="fa fa-paper-plane" aria-hidden="true" ></i>
      </div>
      <!-- messageS End -->
       <!-- messages get start -->
       <div class="c-header-icon has-dropdown bg-primary-light" aria-hidden="true"  data-placement="left" data-toggle="tooltip" data-original-title="Get Messages" (click)="getMessages()">
        <!-- <span class="c-badge c-badge--header-icon ">{{messagesCount?messagesCount:0}}</span> -->
        <i class="fa-solid fa-message" ></i>
      </div>
      <!-- messageS get End -->
      <div class="c-header-icon has-dropdown bg-danger-light" aria-hidden="true"  data-placement="left" data-toggle="tooltip" data-original-title="Discount Requests" (click)="getRequestList()">
      <span class="c-badge c-badge--header-icon ">{{requestList==null? 0 :requestList?.length}}</span><i class="fa-solid fa-percent" ></i>
    </div>
      <div class="c-header-icon has-dropdown bg-primary-light" aria-hidden="true" data-toggle="modal"
        data-target="#viewreminders">
        <span class="c-badge c-badge--header-icon ">{{nonviewLength}}</span><i class="fa fa-bell"></i>
      </div>
      <!-- <div class="c-header-icon has-dropdown bg-danger-light" data-toggle="tooltip" data-placement="left" title="Help">
        <i class="fas fa-question-circle" id="help-icon" feedback (send)="onSend($event)"></i>
      </div> -->
      <div class="c-header-icon has-dropdown
      bg-success-light " data-placement="left" data-toggle="tooltip" data-original-title="Profile"  (click)="removeModal()">
        <i class="fa fa-user" routerLink="/clinic-profile"  (click)="removeModal()"></i>
      </div>
      <div class="c-header-icon logout bg-warning-light" data-placement="left" data-toggle="tooltip"
        data-original-title="Logout"><i class="fa fa-sign-out-alt" (click)="logOut()"></i></div>

    </div>
  </div>
</header>



<div class="on-mobile">
  <footer class="footer-mobile ">
    <div class="container-fulid">
      <ul class="nav nav-pills nav-justified">
        <li class="nav-item" *ngIf="loginUser?.roleId!=10">
          <a class="nav-link" href="javascript:void(0)" routerLink="/clinic-appointments" (click)="removeModal()" data-toggle="tooltip" title=""
            data-original-title="Appointments">
            <span>
              <i class="nav-icon fa fa-calendar-check m-col1"></i>
              <span class="nav-text">Appoinments</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/in-patient-list"  (click)="removeModal()" data-toggle="tooltip" title=""
            data-original-title="IPD List">
            <span>
              <i class="nav-icon fas fa-hospital-user m-col2"></i>
              <span class="nav-text">IPD List</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/clinic-users"  (click)="removeModal()" data-toggle="tooltip" title=""
            data-original-title="Patients">
            <span><i class="nav-icon fa fa-users m-col3"></i>
              <span class="nav-text">Patients</span>
            </span>
          </a>
        </li>
        <li class="nav-item center-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/dashboard"  (click)="removeModal()" data-toggle="tooltip" title=""
            data-original-title="Home">
            <span>
              <i class="nav-icon fa fa-home"></i>
              <span class="nav-text">Home</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/patient-records" title=""  (click)="removeModal()" data-toggle="tooltip"
            data-original-title="Birth/Death Records">
            <span><i class="nav-icon fa fa-user m-col3"></i>
              <span class="nav-text">Birth/Death Records</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/clinic-admin"  (click)="removeModal()" data-toggle="tooltip" title=""
            data-original-title="Admin">
            <span>
              <i class="nav-icon fas fa-users-cog m-col1"></i>
              <span class="nav-text">Admin</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/EditProfile" data-toggle="tooltip" title=""  (click)="removeModal()"
            data-original-title="Profile">
            <span>
              <i class="nav-icon fa fa-user-cog m-col4"></i>
              <span class="nav-text">Profile</span>
            </span>
          </a>
        </li>
        <li class="c-menu__item has-submenu  " data-toggle="tooltip" data-placement="right" title="Reports">
          <a href="javascript:void(0)" routerLink="/clinic-all-reports"  (click)="removeModal()">
            <div class="c-menu__item__inner bg-danger-light">
              <i class="fa fa-chart-bar"></i>
              <div class="c-menu-item__title"><span>Reports</span></div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</div>
<div class="modal fade" id="userFoundConfirmation" role="dialog" data-backdrop="static" data-keyboard="false" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="schedule-tab">
          <div class="tab-content">
            <div class="tab-pane active" id="tab_start">
              <div class=" ">
                <div class="row backgound_imgsidebar mb-3 pt-2 pb-2 pr-2">
                  Patient Details not found. Do you want to create?
                </div>
                <!-- Slot listing 5 end -->
              </div>
              <!-- Tab pane end -->
            </div>
            <!-- tab content -->
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-xs" data-dismiss="modal" (click)="naviagte()">Close</button>
        <button type="button" class="btn btn-primary btn-xs" data-dismiss="modal" (click)="createUser()">Create
          user</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade modal-right" id="add-user" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="add-user"
  aria-hidden="true" style="background: none !important;">
  <div class="modal-dialog" role="document" style="min-width:40%; ">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Create User</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="error" *ngIf="invalidCredentials">
          {{message}}
        </div>
        <div class="error" *ngIf="isPhoneNumberExists">
          {{isPhoneNumberExistsMessage}}
        </div>
         <!-- <div  class="error" *ngIf="isExist">This email already exists</div> -->
        <div class="text-success" *ngIf="successCredentials">
          {{message}}
        </div>
        <div *ngIf="registerForm">
          <div class="col-md-6 form-group" *ngIf="!userFound && searchphNum">
            <label for="exampleInputPassword1">Search Mobile Number</label><span class="text-danger">*</span>
            <div class="searchPhone">
              <input type="text" id="demo" ng2TelInput
                [ng2TelInputOptions]="{autoHideDialCode: false, initialCountry: 'in', separateDialCode:true}"
                (ng2TelOutput)="getNumber($event)" #phNum class="form-control" [(ngModel)]="searchPhoneNum" (keyup)="searchPatientByPhone(phNum.value)"
               (keyup)="numberOnly($event,phNum)" appInputRestrict="mobileNumber"
                maxlength="10" placeholder="Enter mobile number" id="telephone" matInput
                [formControl]="myControlUser" [matAutocomplete]="phNumberSearch"/>
                <mat-autocomplete #phNumberSearch="matAutocomplete"  [panelWidth]="'auto'">
                  <mat-option *ngFor="let option of filteredUsers | async" [value]="option.name" (click)="selectedOne(option)">
                    {{option.name}}
                  </mat-option>
                </mat-autocomplete>
                <!-- <span style="position: absolute;margin-left: -32px;margin-top: 4px;"><i class="fa fa-search fs-16 p-2"
                  (click)="searchPatientByPhone(searchPhoneNum)"></i></span> -->
            </div>
          </div>
          <div *ngIf="messageOpen" class="align-self-center d-flex flex-column flex-md-row justify-content-between" style="position: absolute;">
            <p class="align-self-center ml-2" style="margin-bottom: 0px;">User doesn't exists.<br>Do you want to create
              user?</p>
            <button class="btn btn-sm btn-success ml-3" (click)="userNotExistConfirmationYes()">Yes</button>
            <button class="btn btn-sm btn-danger ml-2" (click)="userNotExistConfirmationNo()">No</button>
          </div>
          <form [formGroup]="userFoundForm" *ngIf="userFound && !createNewUser">
            <div class="row">
              <div class="col-md-6 form-group">
                <label for="exampleInputEmail1">First Name</label><span class="text-danger">*</span>
                <input type="text" formControlName="firstName" style="text-transform:capitalize" noWhiteSpaceAtTheStart
                  class="form-control mb-0" maxlength="50"  placeholder="Enter First Name" readonly/>
              </div>
              <div class="col-md-6 form-group">
                <label for="exampleInputEmail1">Last Name</label><span class="text-danger">*</span>
                <input type="text" formControlName="lastName" style="text-transform:capitalize" noWhiteSpaceAtTheStart
                  class="form-control mb-0" maxlength="50"  placeholder="Enter last Name" readonly/>
              </div>
              <div class="col-md-6 form-group">
                <label for="exampleInputEmail1">Email Id</label><span class="text-danger">*</span>
                <input type="text" formControlName="email"  noWhiteSpaceAtTheStart
                  class="form-control mb-0" maxlength="50"  placeholder="Enter Email Id" readonly/>
              </div>
              <div class="col-md-6 form-group">
                <label for="exampleInputPassword1">Mobile Number</label><span class="text-danger">*</span>
                <div class="telephone">
                  <input type="text" id="demo"  class="form-control" formControlName="contactNum" 
                    maxlength="10" placeholder="Enter mobile number" id="telephone" readonly/>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <div class="row">
                  <label for="exampleInputEmail1" class="width-100 ml-1">DOB <span
                      class="font-size-10 text-muted">(MM/DD/YYYY)</span><span class="text-danger">*</span></label>
                  <div class="col-md-12 col-xs-12">
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" class="form-control mb-0"
                      formControlName="dob" datePickerFormat="MMMM DD YYYY" (keyup)="picker.open()"
                      (keypress)="picker.open()" (click)="picker.open()">
                  </div>
                  <div class="col-md-1 col-xs-2" style="margin-top: -5px;margin-left: -55px;">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="exampleInputPassword1">Gender</label><span class="text-danger">*</span>
                <input type="text" id="demo"  class="form-control" formControlName="gender" 
                 readonly/>
              </div>
              <div class="col-md-12">
                <div class="d-inline-block w-100">
                  <button type="submit" class="btn btn-primary float-right" 
                    (click)="addUser()">
                    Add User
                  </button>
                </div>
              </div>
            </div>
          </form>
          <form [formGroup]="registerForm" novalidate class="mt-2" #myForm="ngForm" *ngIf="!addressDetailsOn && createNewUser" (keyup)="getFormValue(registerForm)">
            <div class="row" >
              <!-- <div class="col-md-6 form-group">
                <label for="exampleInputPassword1">Username</label><span class="text-danger">*</span>
                <input type="text" formControlName="userName" class="form-control mb-0" (focusout)="checkUserName()"
                  (keypress)="alphaNumarics($event)" [ngClass]="{
                          'is-invalid':
                            (submitted || f.userName.touched) && f.userName.errors
                        }" placeholder="Enter Username" />

                <div *ngIf="(submitted || f.userName.touched) && f.userName.errors" class="invalid-feedback">

                  <div *ngIf="f.userName.errors.required">
                    Username is required
                  </div>

                  <div *ngIf="f.userName.errors.pattern">
                    Username should be alphabets and numbers.
                  </div>
                </div>
              </div> -->
         
              <div class="col-md-6 form-group">
                <label for="exampleInputPassword1">Email ID</label><span class="text-danger">*</span>
                <input type="text" formControlName="email" autocomplete="nope" id="directLink" class="form-control mb-0"
                  (focusout)="checkEmail()" [ngClass]="{
                          'is-invalid':
                            (submitted || f.email.touched) && f.email.errors
                        }" placeholder="Enter Email ID" />

                <div *ngIf="(submitted || f.email.touched) && f.email.errors" class="invalid-feedback">

                  <div *ngIf="f.email.errors.required">
                    Email is required
                  </div>

                  <div *ngIf="f.email.errors.email">
                    Email should be valid
                  </div>
                  <!-- <div *ngIf="f.email.errors.email || f.email.errors.pattern">
                    Email should be valid
                  </div> -->
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="exampleInputEmail1">First Name</label><span class="text-danger">*</span>
                <input type="text" formControlName="firstName" style="text-transform:capitalize" noWhiteSpaceAtTheStart
                  (keypress)="alphaOnly($event)" class="form-control mb-0" maxlength="50" [ngClass]="{
                          'is-invalid':
                            (submitted || f.firstName.touched) &&
                            f.firstName.errors
                        }" placeholder="Enter First Name" />
                <div *ngIf="
                          (submitted || f.firstName.touched) && f.firstName.errors
                        " class="invalid-feedback">
                  <div *ngIf="f.firstName.errors.required">
                    First Name is required
                  </div>
                  <div *ngIf="f.firstName.errors.pattern">
                    only 1 space and Letters are allowed
                  </div>
                  <div *ngIf="f.firstName.errors.minlength">
                    Name should be minimum 3 characters Long
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="exampleInputEmail1">Middle Name</label>
                <input type="text" formControlName="middleName" style="text-transform:capitalize" noWhiteSpaceAtTheStart
                  (keypress)="alphaOnly($event)" class="form-control mb-0" placeholder="Enter Middle Name"
                  maxlength="50" />
              </div>

              <div class="col-md-6 form-group">
                <label for="exampleInputEmail1">Last Name</label><span class="text-danger">*</span>
                <input type="text" formControlName="lastName" style="text-transform:capitalize" noWhiteSpaceAtTheStart
                  (keypress)="alphaOnly($event)" class="form-control mb-0" maxlength="50" [ngClass]="{
                          'is-invalid':
                            (submitted || f.lastName.touched) && f.lastName.errors
                        }" placeholder="Enter Last Name" />
                <div *ngIf="
                          (submitted || f.lastName.touched) && f.lastName.errors
                        " class="invalid-feedback">
                  <div *ngIf="f.lastName.errors.required">
                    Last Name is required
                  </div>
                  <div *ngIf="f.lastName.errors.pattern">
                    only 1 space and Letters are allowed
                  </div>
                  <div *ngIf="f.lastName.errors.minlength">
                    Name should be minimum 3 characters Long
                  </div>
                  <div *ngIf="f.lastName.errors.noMatch">
                    Last Name should not match First Name
                  </div>
                </div>
              </div>

              <!-- <div class="col-md-6 form-group">
                <label for="exampleInputPassword1">Password</label><span class="text-danger">*</span>
                <div class="input-group" id="show_hide_password">
                  <input type="password" (paste)="(false)" autocomplete="nope" (copy)="(false)"
                    (keydown.space)="$event.preventDefault()" formControlName="password" class="form-control mb-0"
                    [ngClass]="{
                              'is-invalid':
                                (submitted || f.password.touched) && f.password.errors
                            }" id="exampleInputPassword1" placeholder="Enter Password" />
                  <div class="input-group-addon">
                    <a href="javascript:void(0)"><i class="fa fa-eye-slash" aria-hidden="true"
                        (click)="showHidePwd()"></i></a>
                  </div>
                  <div *ngIf="
                              (submitted || f.password.touched) && f.password.errors
                            " class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">
                      Password is required
                    </div>

                    <div *ngIf="f.password.errors.NoSameFirstNamePassword">
                      No firstName should be included in password
                    </div>
                    <div *ngIf="f.password.errors.NoSameLastNamePassword">
                      No last should be included in password
                    </div>
                    <div *ngIf="f.password.errors.pattern">
                      Password must be at least 8 characters long,Password must contain 1
                      Number,1 LowerCase,1 UpperCase,1
                      special Character,no spaces allowed
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-6 form-group">
                <label for="exampleInputPassword1">Confirm Password</label><span class="text-danger">*</span>
                <div class="input-group" id="show_hide_password1">
                  <input type="password" (paste)="(false)" (copy)="(false)" formControlName="confirmPassword"
                    (keydown.space)="$event.preventDefault()" class="form-control mb-0" [ngClass]="{
                              'is-invalid':
                                (submitted || f.confirmPassword.touched) &&
                                f.confirmPassword.errors
                            }" id="exampleInputPassword1" placeholder="Enter Password" />
                  <div class="input-group-addon">
                    <a href="javascript:void(0)"><i class="fa fa-eye-slash" aria-hidden="true"
                        (click)="showHidePwd1()"></i></a>
                  </div>
                  <div *ngIf="
                              (submitted || f.confirmPassword.touched) &&
                              f.confirmPassword.errors
                            " class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.required">
                      Password is required
                    </div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">
                      Passwords must match
                    </div>
                  </div>
                </div>

              </div> -->

              <div class="col-md-6 form-group">
                <label for="exampleInputPassword1">Mobile Number</label><span class="text-danger">*</span>
                <div class="telephone">
                  <input type="text" id="demo" ng2TelInput
                    [ng2TelInputOptions]="{autoHideDialCode: false, initialCountry: 'in', separateDialCode:true}"
                    (ng2TelOutput)="getNumber($event)" #phNum (focusout)="checkPhNumber(phNum.value)"
                    class="form-control" formControlName="contactNum" (keyup)="numberOnly($event,phNum)"
                    maxlength="10" placeholder="Enter mobile number" id="telephone" [ngClass]="{
                              'is-invalid':
                                (submitted || f.contactNum.touched) &&
                                f.contactNum.errors
                            }" (focusout)="isItValid()" />


                  <div *ngIf="
                              (submitted || f.contactNum.touched) &&
                              f.contactNum.errors
                            " class="invalid-feedback">
                    <div *ngIf="f.contactNum.errors.required">
                      Phone Number is required
                    </div>
                    <div *ngIf="f.contactNum.errors.pattern">
                      Invalid Phone Number
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-md-6 form-group">

                <div class="row">
                  <label for="exampleInputEmail1" class="width-100 ml-1">DOB <span
                      class="font-size-10 text-muted">(MM/DD/YYYY)</span><span class="text-danger">*</span></label>

                  <div class="col-md-12 col-xs-12">
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" class="form-control mb-0"
                      formControlName="dob" datePickerFormat="MMMM DD YYYY" (keyup)="picker.open()"
                      (keypress)="picker.open()" (click)="picker.open()">
                  </div>
                  <div class="col-md-1 col-xs-2" style="margin-top: -5px;margin-left: -55px;">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="exampleInputPassword1">Blood Group</label><span class="text-danger">*</span>
                <mat-select class="form-control" name="item" formControlName="bloodgroup" [(ngModel)]="selectedValue3"
                  (selectionChange)="drpdownSelection(selectedValue3)" id="bloodGroup" tabindex="2"
                  placeholder="Enter blood group" [ngClass]="{
                            'is-invalid': (submitted || f.bloodgroup.touched) && f.bloodgroup.errors
                            }">
                  <mat-option *ngFor="let item of bloodGroupList | search : search3" [value]="item.value">
                    {{item.value}}
                  </mat-option>
                </mat-select>
                <div *ngIf="(submitted || f.bloodgroup.touched) && f.bloodgroup.errors" class="invalid-feedback">
                  <div *ngIf="f.bloodgroup.errors.required">Blood Group is required</div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="exampleInputPassword1">Gender</label><span class="text-danger">*</span>
                <mat-select class="form-control" name="item" formControlName="gender" [(ngModel)]="selectedValue4"
                  (selectionChange)="drpdownSelection(selectedValue4)" id="gender" placeholder="Select" [ngClass]="{
                              'is-invalid': (submitted || f.gender.touched) && f.gender.errors
                              }">
                  <mat-option *ngFor="let item of genderList | search : search4" [value]="item.value">
                    {{item.value}}
                  </mat-option>
                </mat-select>
                <div *ngIf="(submitted || f.gender.touched) && f.gender.errors" class="invalid-feedback">
                  <div *ngIf="f.gender.errors.required">Gender is required</div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="exampleInputEmail1">Emergency Contact Name</label><span class="text-danger">*</span>
                <input type="text" formControlName="emergencyContactName" style="text-transform:capitalize"
                  noWhiteSpaceAtTheStart (keypress)="alphaOnly($event)" class="form-control mb-0" [ngClass]="{
                          'is-invalid':
                            (submitted || f.emergencyContactName.touched) && f.emergencyContactName.errors
                        }" placeholder="Enter Emergency Contact Name" />
                <div *ngIf="
                          (submitted || f.emergencyContactName.touched) && f.emergencyContactName.errors
                        " class="invalid-feedback">
                  <div *ngIf="f.emergencyContactName.errors.required">
                    Emergency Contact Name is required
                  </div>
                  <div *ngIf="f.emergencyContactName.errors.pattern">
                    only 1 space and Letters are allowed
                  </div>
                  <div *ngIf="f.emergencyContactName.errors.minlength">
                    Emergency Contact Name should be minimum 3 characters Long
                  </div>
                  <div *ngIf="f.emergencyContactName.errors.maxlength">
                    Emergency Contact Name should not be 50 characters long
                  </div>
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label for="exampleInputPassword1">Emergency Contact  Number</label><span class="text-danger">*</span>
                <div class="telephone">
                  <input type="text" id="demo" ng2TelInput
                    [ng2TelInputOptions]="{autoHideDialCode: false, initialCountry: 'in', separateDialCode:true}"
                    (ng2TelOutput)="getEmrNumber($event)" #phNumm
                    class="form-control" formControlName="emergenyContactNumber" (keypress)="numberOnly($event,phNumm)"
                    maxlength="10" placeholder="Enter mobile number" id="telephone" [ngClass]="{
                              'is-invalid':
                                (submitted || f.emergenyContactNumber.touched) &&
                                f.emergenyContactNumber.errors
                            }" (focusout)="isItValid()" />


                  <div *ngIf="
                              (submitted || f.emergenyContactNumber.touched) &&
                              f.emergenyContactNumber.errors
                            " class="invalid-feedback">
                    <div *ngIf="f.emergenyContactNumber.errors.required">
                      Phone Number is required
                    </div>
                    <div *ngIf="f.emergenyContactNumber.errors.pattern">
                      Invalid Phone Number
                    </div>
                    <div *ngIf="f.emergenyContactNumber.errors.noMatch">
                      Emergency Contact Number should not match Mobile Number
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="d-inline-block w-100" >
              <button type="submit" class="btn btn-primary float-right"
                [disabled]="!registerForm.valid || isPhoneNumberExists || invalidCredentials" (click)="next(registerForm)">
                Next
              </button>
            </div>
          </form>
        </div>
        <div [formGroup]="primaryAddress" class="row" style="background-color:white;margin-top:15px;"
          *ngIf="addressDetailsOn">
          <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputEmail1"> Zip/Pin Code <span class="text-danger">*</span></label>
              <input #zipCode type="text" placeholder="Enter Zip/Pin Code" maxlength="6" minlength="6"
                class="form-control" (keyup)="percChanged(zipCode.value)" (keyup)="zipEntered()"
                (keypress)="numberOnly1($event)" formControlName="zip"
                [ngClass]="{ 'is-invalid': (primarysubmitted) && h.zip.errors}">
              <div *ngIf="(primarysubmitted)  && h.zip.errors" class="invalid-feedback">
                <div *ngIf="h.zip.errors.required">Zip is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <label> Country Code</label><span class="text-danger">*</span>
            <mat-select [(value)]="countryCodeSelected" (selectionChange)="countryCodeEntered(countryCodeSelected)"
              class="form-control" formControlName="cc">
              <mat-option value="IN">IN</mat-option>
              <mat-option value="US">US</mat-option>
            </mat-select>
          </div>
          <div class="col-md-6 form-group">
            <label for="exampleInputPassword1">Postal Location</label><span class="text-danger">*</span>
            <mat-select placeholder="Select postal location" [(value)]="selectedValue"
              (selectionChange)="drpdownSelectionForAddress(selectedValue)" class="form-control"
              formControlName="postalLocation" name="item" [ngClass]="{
                    'is-invalid': (primarysubmitted || h.postalLocation.touched) && h.postalLocation.errors
                }" placeholder="Enter postal location">

              <mat-option *ngFor="let item of postalLocations" [value]="item.postalLocation">
                {{item.postalLocation}}
              </mat-option>
            </mat-select>
            <div *ngIf="(primarysubmitted || h.postalLocation.touched) && h.postalLocation.errors"
              class="invalid-feedback">
              <div *ngIf="h.postalLocation.errors.required">Postal location is required</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputEmail1"> Street1 <span class="text-danger">*</span></label>
              <input type="text" class="form-control" maxlength="45" placeholder="Enter street1" formControlName="street1"
                [ngClass]="{ 'is-invalid': (primarysubmitted) && h.street1.errors}">
              <div *ngIf="(primarysubmitted)  && h.street1.errors" class="invalid-feedback">
                <div *ngIf="h.street1.errors.required">Street1 is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputEmail1"> Street2</label>
              <input type="text" class="form-control" maxlength="45" placeholder="Enter street2" formControlName="street2">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputEmail1"> City <span class="text-danger">*</span></label>
              <input type="text" class="form-control" placeholder="Enter city" formControlName="city"
                (keypress)="omit_special_char_and_numbers($event)"
                [ngClass]="{ 'is-invalid': (primarysubmitted) && h.city.errors}">
              <div *ngIf="(primarysubmitted)  && h.city.errors" class="invalid-feedback">
                <div *ngIf="h.city.errors.required">City is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="exampleInputEmail1"> State <span class="text-danger">*</span></label>
              <input type="text" class="form-control" placeholder="Enter state" formControlName="state"
                [ngClass]="{ 'is-invalid': (primarysubmitted) && h.state.errors}" readonly>
              <div *ngIf="(primarysubmitted)  && h.state.errors" class="invalid-feedback">
                <div *ngIf="h.state.errors.required">State is required</div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-inline-block w-100">
              <button type="submit" class="btn btn-primary float-right" [disabled]="!primaryAddress.valid"
                (click)="onSubmit(registerForm)">
                Add User
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Notifications modal -->
<div class="modal fade modal-right" id="viewreminders" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notifications</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- <app-view-doctor-remainders></app-view-doctor-remainders> -->
      <div class="row">
        <div class="col-lg-12 mt-3 text_right ">
          <ul class="pagination">
            <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
          </ul>
        </div>
      </div>

      <div *ngIf="reminders?.length == 0" class="no-notifications"></div>

      <div class="row d-flex ml-2" *ngIf="!loadRemainders">
        <div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left blt-2 mb-2 mt-1"
          *ngFor="let reports of reminders | paginate: { id: 'p2', itemsPerPage: 6, currentPage: p2 }"
          [ngClass]="{' blt-3': reports.viewed == 'Y'}" (click)="updateNotify(reports)">
            <a href="javascript:void(0)" class="p-2 icon-appointments  ">
              <i *ngIf="reports.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3" aria-hidden="true"></i>
              <i *ngIf="reports.viewed != 'Y'" class="fa fa-bell fa-lg pt-2  icon-col-3" aria-hidden="true"></i>
            </a>
            <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
              <a href="javascript:void(0)">
                <p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
                </p>
                <p class="text-muted mb-1 text-small">{{reports.reason}}</p>
              </a>
            </div>
  
            <div class="btn-group float-right">
              <button class="btn btn-xs btn0col1" data-toggle="modal" data-backdrop="static" data-target="#viewpdf"><i
                  class="fa fa-eye" aria-hidden="true" (click)="updateNotify(reports)"></i></button>
            </div>
        </div>
      </div>
      <div *ngIf="loadRemainders">
        <app-skelton-loader [numRows]="6" shape="card"></app-skelton-loader>
      </div>
    </div>
  </div>
</div>
<!-- Notifications modal /-->

<!-- Notifications View Modal -->
<div class="modal fade" id="notificationToView" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered notificationViewerModal_Style" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notification Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="closeNotificationViewerModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="block inv-section">
          <div class="mb-2 text-center">
            <img alt="Logo" src="./assets/img/logo.png">
          </div>
          <div class="text-center">
            <p class="font-weight-medium mb-1 mt-1">{{notifyToView?.objecttype | titlecase}}</p>
            <p class="text-muted mb-1 text-small">{{notifyToView?.reason}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Notifications View Modal Ends -->


<!-- <div class="modal fade modal-right" id="caseDetailsForm" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Add IN Patient</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="addInPatientForm" class="mt-4" #myForm="ngForm">
          <div class="row">
            <div class="col-md-6 form-group">
              <div class="row">
                <label for="exampleInputEmail1" class="width-100 ml-1">Admission Date <span
                    class="font-size-10 text-muted">(MM/DD/YYYY)</span><span class="text-danger">*</span></label>

                <div class="col-md-12 col-xs-12">
                  <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" class="form-control mb-0"
                    formControlName="dob" datePickerFormat="MMMM Do YYYY">
                </div>
                <div class="col-md-1 col-xs-2" style="margin-top: -5px;margin-left: -55px;">

                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>

                </div>
              </div>
            </div>
            <div class="col-md-6 form-group">
              <label for="exampleInputPassword1">Complaint</label><span class="text-danger">*</span>
              <input placeholder="Complaint" class="form-control">
            </div>
            <div class="col-md-6 form-group">
              <label for="exampleInputPassword1">TPA</label><span class="text-danger">*</span>
              <mat-select class="form-control" name="item" formControlName="gender" [(ngModel)]="selectedValue4"
                (selectionChange)="drpdownSelection(selectedValue4)" id="gender" placeholder="Select TPA" [ngClass]="{
                            'is-invalid': (submitted || f.gender.touched) && f.gender.errors
                            }">
                <mat-option *ngFor="let item of genderList | search : search4" [value]="item.value">
                  {{item.value}}
                </mat-option>
              </mat-select>
              <div *ngIf="(submitted || f.gender.touched) && f.gender.errors" class="invalid-feedback">
                <div *ngIf="f.gender.errors.required">Gender is required</div>
              </div>
            </div>
            <div class="col-md-6 form-group">
              <label for="exampleInputPassword1">Credit Limit</label><span class="text-danger">*</span>
              <input placeholder="Credit Limit" class="form-control">
            </div>
            <div class="col-md-6 form-group">
              <label for="exampleInputPassword1">Primary Doctor</label><span class="text-danger">*</span>
              <mat-select class="form-control" name="item" formControlName="gender" [(ngModel)]="selectedValue"
                (selectionChange)="drpdownSelection(selectedValue4)" id="gender" placeholder="Select Doctor" [ngClass]="{
                            'is-invalid': (submitted || f.gender.touched) && f.gender.errors
                            }">
                <mat-option *ngFor="let item of genderList | search : search4" [value]="item.value">
                  {{item.value}}
                </mat-option>
              </mat-select>
              <div *ngIf="(submitted || f.gender.touched) && f.gender.errors" class="invalid-feedback">
                <div *ngIf="f.gender.errors.required">Gender is required</div>
              </div>
            </div>
          </div>
          <div class="float-right">
            <button class="btn btn-primary btn-xs">Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->
