import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoginService } from '../../AppServices/login.service';
import { Router } from '@angular/router'
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { timer } from 'rxjs';


@Component({
  selector: 'app-otpverification',
  templateUrl: './otpverification.component.html',
  styleUrls: ['./otpverification.component.css']
})
export class OTPverificationComponent implements OnInit {
  otpVerificationForm: FormGroup;
  timerOn = true;
  subscription: Subscription;
  subscribeTimer
  timeLeft: number = 120;
  timeLeft2: number = 120;
  mbNumber;
  invalidCredentials;
  successCredentials;
  message;
  isTimeOn = true;
  isTimeOn2 = false
  eobileOPTError;
  mobileOPTError;

  constructor(private otpService: AuthService, private formBuilder: FormBuilder, private loginService: LoginService, private router: Router,
    private toaster: ToastrService, private activatedRoute: ActivatedRoute) {

    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      emailId: string,
      userName: string,
      mobileNumber: string,
    };
    this.otpVerificationForm = this.formBuilder.group({
      email: ['', Validators.required],
      userName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
    })
    this.mbNumber = state.mobileNumber
    this.otpVerificationForm.patchValue({
      email: state.emailId,
      userName: state.userName,
      phoneNumber: state.mobileNumber.replace(/.(?=.{4})/g, 'x').padStart(10, 'x').slice(-10),
    })
  }

  Verification: boolean = false
  ngOnInit(): void {

    this.email = this.activatedRoute.snapshot.queryParams.verificationId
    this.observableTimer()
  }


  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }


  OTP
  email; PhOTP; emailVerificationDone = false
  verifyOTP() {
    this.invalidCredentials = false;
    this.successCredentials = false;

    if (this.OTP == undefined || this.otpVerificationForm.value.email == undefined) {
      return
    }

    if (this.OTP == "" || this.otpVerificationForm.value.email == "") {
      return
    }
    this.otpService.getEmailVerification(this.otpVerificationForm.value.email, this.OTP).subscribe(res => {
      this.invalidCredentials = false;
      //this.toaster.success("Your email ID is now verified.");
      this.emailVerificationDone = true;
      this.verifyNowButton = true;
      this.isTimeOn = false;
      this.observableTimer2()
    }, error => {
      //this.toaster.error('error ');
      this.isTimeOn;
      this.eobileOPTError = true;
    }
    )
  }
  verifyPhoneOTP() {
    this.invalidCredentials = false
    this.successCredentials = false
    if (this.PhOTP == undefined || this.otpVerificationForm.value.phoneNumber == undefined) {
      return
    }

    if (this.PhOTP == "" || this.otpVerificationForm.value.phoneNumber == "") {
      return
    }
    this.otpService.getPhoneVerification(this.PhOTP, this.mbNumber.replace(/\+/gi, '%2B')).subscribe(res => {
      this.toaster.success('Your phone number is now verified.')
      if (this.router.url == '/otp-verifcation-nutritionist') {
        this.router.navigate(['/nutritionistLogin'])
      }
      if (this.router.url == '/otp-verifcation-psychiatrist') {
        this.router.navigate(['/psychiatristLogin'])
      }
      if (this.router.url == '/otp-verifcation-physiotherapist') {
        this.router.navigate(['/physiotherapistLogin'])
      }
      if (this.router.url == '/otp-verification-doctor') {
        this.router.navigate(['/Login'])
      }
    }, error => {
      //this.toaster.error('Your Phone number not verifed');
      this.mobileOPTError = true;
    })
  }


  resendOTP() {
    this.isTimeOn = false
    this.invalidCredentials = false
    this.successCredentials = false
    if (this.otpVerificationForm.value.email == undefined || this.otpVerificationForm.value.email == "") {
      this.toaster.warning("Please enter email");
      return
    }
    this.loginService.resendEmailOTPForVerification(this.otpVerificationForm.value.email, this.otpVerificationForm.value.userName).subscribe(res => {
      //this.toaster.success("OTP send to your EmailId")
      this.successCredentials = true
      this.message = "OTP send to your EmailId"
      this.observableTimer()
    }, error => {
      this.toaster.error("Error in resend OTP")
    })

  }
  observableTimer() {
    this.timeLeft = 120
    this.isTimeOn = true
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.successCredentials = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        // this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  observableTimer2() {
    this.isTimeOn = false
    this.isTimeOn2 = true
    this.timeLeft2 = 120
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft2 - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer2').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn2 = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.successCredentials = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        // this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  resendPhoneOTP() {
    this.isTimeOn = true
    this.invalidCredentials = false
    if (this.otpVerificationForm.value.phoneNumber == undefined || this.otpVerificationForm.value.phoneNumber == "") {
      this.toaster.warning("Please enter email");
      return
    }
    this.loginService.resendPhoneOtp(this.mbNumber.replace(/\+/gi, '%2B')).subscribe(res => {
      this.toaster.success("OTP send to your mobile number")
      this.observableTimer2()
    }, error => {
      this.toaster.error("Error in resend OTP")
    })

  }
  verifyNowButton = true
  otpEntered(value) {
    if (value.length == 6) {
      this.verifyNowButton = false
    }
    else {
      this.verifyNowButton = true
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }

}
