import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { Console } from 'console';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'src/app/shared/services/auth.service';
import { MustMatch, NoMatch, NoSameFirstName, NoSamelastName } from 'src/app/_helpers/must_match.validators'
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';

declare var $: any;
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  isExist = false
  isEmailExists = false
  isEmailExistsMessage
  isUserNameExists = false
  isUserNameExistsMessage
  isPhoneNumberExists = false
  isPhoneNumberExistsMessage
  Url
  registerForms: FormGroup;
  loading = false;
  submitted = false;
  cond_vall = false
  message
  invalidCredentials: boolean = false
  successCredentials: boolean = false
  telOptions = { initialCountry: 'us' }
  constructor(private globalStorage: GlobalStorageService, 
    private formBuilder: FormBuilder, private script: ScriptsService,
    private router: Router, private toastr: ToastrService,
    private service: AuthService) { }
  // (^(?=.{10,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$)
  // ((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      middleName: [''],
      userName: ['', Validators.required],
      name: ['hello', Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      contactNum: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      password: ['', [Validators.required,
      Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)*\\S{8,15})')]],
      confirmPassword: ['', [Validators.required]]
    },
      {
        validators: [MustMatch('password', 'confirmPassword'), NoMatch('firstName', 'lastName')]
      }
    );
    this.globalStorage.setItem('registeredEmail', JSON.stringify(this.registerForm.value.email));
    var input = document.querySelector("#telephone");
    jQuery(document).ready(function ($) {
      (<any>window).intlTelInputGlobals.loadUtils("./../../../assets/build/js/utils.js");

      if (jQuery('#telephone').length) {
        var input = document.querySelector("#telephone");
        (<any>window).intlTelInput(input, {
          preferredCountries: ['in'],
          separateDialCode: true, placeholderNumberType: "MOBILE",
          autoPlaceholder: "polite", autoHideDialCode: true,
          hiddenInput: "full_number", nationalMode: "false",

          customPlaceholder: null,
          formatOnDisplay: true,

        });
      }

    });
    input.addEventListener("countrychange", function () {
      (<any>input).intlTelInput();
      (<any>input).val('')
    });
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  onSubmit(item: any) {
    this.submitted = true;
    this.checkUserName()
    this.service.userExist(this.registerForm.controls['email'].value).subscribe(res => {
      if (res.data == true) {
        this.invalidCredentials = true
        this.message = res?.message
        return
      }
    }, (error) => {
      this.toastr.error((error?.error?.message))
    });
    if (this.invalidCredentials) {
      return
    }
    this.service.checkPhoneNumber(this.registerForm.controls['contactNum'].value).subscribe(res => {
      if (res?.data?.isExist == true) {
        this.invalidCredentials = true
        this.message = res?.message
        return
      }
    })
    if (this.registerForm.invalid) {
      return;
    }
    if (this.isExist) {
      return;
    }
    var request = {
      'primaryContact': 'CELL_NUMBER',
      'emailId': this.registerForm.value.email,
      'password': this.registerForm.value.password,
      'userName': this.registerForm.value.userName,
      "firstName": this.registerForm.value.firstName,
      "lastName": this.registerForm.value.lastName,
      "middleName": this.registerForm.value.middleName,
      "cellNumber": $('.iti__selected-dial-code').text() + this.registerForm.value['contactNum'],
      // "uiHostURL": window.location.origin,
      "dependent": "N"
    }
    this.service.registeredEmail(this.registerForm.value.email)
    if (this.isPhoneExist == false && this.isEmailExist == false && this.isUserNameExist == false)
      this.service.signup(request).subscribe(res => {
        const navigationExtras: NavigationExtras = {
          state: {
            emailId: this.registerForm.value.email,
            mobileNumber: $('.iti__selected-dial-code').text() + this.registerForm.value['contactNum'],
            userName: this.registerForm.value.userName
          }
        };
        this.router.navigate(['/otp-verification-patient'], navigationExtras);
        this.successCredentials = true;
        this.message = res['body']['message'];
      }, error => {
        this.invalidCredentials = true;
        this.message = error?.['error']?.['message'];
        // this.router.navigate(['/FailureRegistration']);
      });
  }
  numberOnly(event, phn): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    if (phn.value.length < 1) {
      if (charCode < 54 || charCode > 57) {
        return false;
      }
      return true;
    }
    else {
      if (charCode < 48 || charCode > 57) {
        return false;
      }
      return true;

    }
  }

  alphaOnly(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/[a-zåäö ]/i);
    return pattern.test(value);
  };
  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z]+$/);
    return pattern.test(value);
  }
  checkEmail() {
    if(this.registerForm.controls['email'].value ==''){
      this.isExist = false
      this.isEmailExists = false
      this.invalidCredentials = false
      return
    }
    // this.invalidCredentials = false
    if (this.f.email.errors == null) {
      this.isExist = false
      this.service.userExist(this.registerForm.controls['email'].value).subscribe(res => {
        if (res.data == true) {
          this.invalidCredentials = true
          this.isEmailExists = true
          this.isEmailExistsMessage = res?.message
          var ema = document.getElementById('directLink').innerHTML
          $('#directLink').addClass("email");
          // this.message = res?.message
          // this.toastr.error("A user with this email address has already been registered in the system and the associated already exists.");
          this.isExist = true
          this.submitted = false;
          this.isEmailExist = true
          this.isEmailExists = false
          return
        }
        else {
          this.isEmailExist = false
          this.invalidCredentials = false
          this.isEmailExists = false
        }
      }, (error) => {
        // this.toastr.error((error['error']['message']))

      });
    }else{
      this.isEmailExist = false
      this.invalidCredentials = false
      this.isExist = false

    }
  }
  isUserNameExist = false; isEmailExist = false;
  checkUserName() {
    if(this.registerForm.controls['userName'].value ==''){
      this.isUserNameExist = false
      this.isUserNameExists = false
      this.invalidCredentials = false
      return
    }
    this.invalidCredentials = false
    if (this.f.userName.errors == null) {
      this.isExist = false
      this.service.userNameExist(this.registerForm.controls['userName'].value).subscribe(res => {
        if (res.data == true) {
          this.invalidCredentials = true
          this.isUserNameExists = true
          //this.message = res?.message
          this.isUserNameExistsMessage = res?.message
          // this.toastr.error("A user with this email address has already been registered in the system and the associated already exists.");
          this.isExist = true
          this.submitted = false;
          this.isUserNameExist = true
          return
        }
        else {
          this.isUserNameExist = false
          this.isUserNameExists = false
        }
      }, (error) => {
        // this.toastr.error((error['error']['message']))

      });
    }
  }
  isDisable = true
  checkForm() {
    if (this.registerForm.value.email && this.registerForm.value.userName && this.registerForm.value.contactNum && this.registerForm.value.contactNum.length == 10
      && this.registerForm.value.firstName && this.registerForm.value.lastName && (this.registerForm.value.password === this.registerForm.value.confirmPassword)) {
      return false;
    }
    else {
      return true;
    }
  }
  isPhoneExist = false
  checkPhoneNumber(num) {
    var number = $('.iti__selected-dial-code').text() + num
    //$("#txtPhone").intlTelInput("getSelectedCountryData").dialCode;
    this.invalidCredentials = false
    if (num.length == 10) {
      this.service.checkPhoneNumber((number).replace(/\+/gi, '%2B')).subscribe(res => {
        if (res?.data?.isExist == true) {
          this.invalidCredentials = true
          this.isPhoneNumberExists = true
          // this.message = res?.message
          this.isPhoneNumberExistsMessage = res?.message
          this.isPhoneExist = true
          return
        }
        else {
          this.isPhoneExist = false
          this.isPhoneNumberExists = false
        }
      }, (error) => {
        this.checkForm()
        this.message = error['error']['message']
      });
    }else{
      this.isPhoneExist = false
      this.isPhoneNumberExists = false
    }
  }
  isItValid() {
  }
  showError
  percChanged(Zip) {
    if (Zip.includes(this.registerForm.value.firstName)) {
      this.showError = true
    }
  }

  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });

    $("#show_hide_password1 a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password1 input').attr("type") == "text") {
        $('#show_hide_password1 input').attr('type', 'password');
        $('#show_hide_password1 i').addClass("fa-eye-slash");
        $('#show_hide_password1 i').removeClass("fa-eye");
      } else if ($('#show_hide_password1 input').attr("type") == "password") {
        $('#show_hide_password1 input').attr('type', 'text');
        $('#show_hide_password1 i').removeClass("fa-eye-slash");
        $('#show_hide_password1 i').addClass("fa-eye");
      }
    });
  }

}
