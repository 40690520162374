<div class="modal-header">
    <h5 class="modal-title">Add Address</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body ">
    <div [formGroup]="primaryAddress" class="row" style="background-color:white;margin-top:15px;">
        <div class="col-md-4">
            <div class="form-group">
                <label>Contact Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter contact name" formControlName="contactName"
                    [ngClass]="{ 'is-invalid': (primarysubmitted || f.contactName.touched) && f.contactName.errors}">
                <div *ngIf="(primarysubmitted || f.contactName.touched)  && f.contactName.errors" class="invalid-feedback">
                    <div *ngIf="f.contactName.errors.required">Contract Name required</div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label> Zip/Pin Code <span class="text-danger">*</span></label>
                <input #zipCode type="text" placeholder="Enter Zip/Pin Code" maxlength="6" minlength="6"
                    class="form-control" (keyup)="percChanged(zipCode.value)" (keypress)="numberOnly($event)"
                    formControlName="zip" [ngClass]="{ 'is-invalid': (primarysubmitted) && f.zip.errors}">
                <div *ngIf="(primarysubmitted)  && f.zip.errors" class="invalid-feedback">
                    <div *ngIf="f.zip.errors.required">Zip is required</div>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <label> Country Code</label><span class="text-danger">*</span>
            <mat-select [(value)]="countryCodeSelected" (selectionChange)="countryCodeEntered(countryCodeSelected)"
                class="form-control" formControlName="cc">
                <mat-option value="IN">IN</mat-option>
                <mat-option value="US">US</mat-option>
            </mat-select>
        </div>
    
        <div class="col-md-4 form-group">
            <label for="exampleInputPassword1">Postal Location</label><span class="text-danger">*</span>
            <mat-select placeholder="Select postal location" [(value)]="selectedValue"
                (selectionChange)="drpdownSelection(selectedValue)" class="form-control" formControlName="postalLocation"
                name="item" [ngClass]="{
                        'is-invalid': (primarysubmitted || f.postalLocation.touched) && f.postalLocation.errors
                    }" placeholder="Enter postal location">
    
                <mat-option *ngFor="let item of postalLocations" [value]="item.postalLocation">
                    {{item.postalLocation}}
                </mat-option>
            </mat-select>
            <div *ngIf="(primarysubmitted || f.postalLocation.touched) && f.postalLocation.errors" class="invalid-feedback">
                <div *ngIf="f.postalLocation.errors.required">Postal location is required</div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label> Street1 <span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter street1" formControlName="street1"
                    [ngClass]="{ 'is-invalid': (primarysubmitted) && f.street1.errors}">
                <div *ngIf="(primarysubmitted)  && f.street1.errors" class="invalid-feedback">
                    <div *ngIf="f.street1.errors.required">Street1 is required</div>
                </div>
    
            </div>
        </div>
    
        <div class="col-md-4">
            <div class="form-group">
                <label> Street2</label>
                <input type="text" class="form-control" placeholder="Enter street2" formControlName="street2">
    
    
            </div>
        </div>
        <div class="col-md-4">
            <div class="form-group">
                <label> City <span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter city" formControlName="city"
                    [ngClass]="{ 'is-invalid': (primarysubmitted) && f.city.errors}">
                <div *ngIf="(primarysubmitted)  && f.city.errors" class="invalid-feedback">
                    <div *ngIf="f.city.errors.required">City is required</div>
                </div>
            </div>
        </div>
    
        <div class="col-md-4">
            <div class="form-group">
                <label> State <span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter state" formControlName="state"
                    [ngClass]="{ 'is-invalid': (primarysubmitted) && f.state.errors}" readonly>
                <div *ngIf="(primarysubmitted)  && f.state.errors" class="invalid-feedback">
                    <div *ngIf="f.state.errors.required">State is required</div>
                </div>
    
            </div>
        </div>
        <div class="col-md-12  text-right">
            <div style="float:right; ; margin-top:22px;">
                <div class=" text-right ">
                    <button class="btn btn-danger btn-sm mr-2" (click)="resetForm()"><i
                            class="fa fa-refresh"></i>&nbsp;Reset</button>
                    <button class="btn btn-success btn-sm mr-2" (click)="savePrimaryAddress(primaryAddress)"><i
                            class="fa fa-save"></i>&nbsp;Save</button>
                    <!-- <button *ngIf="ViewMode" class="btn btn-primary btn-sm" (click)="btnEdit(primaryAddress)"><i
                                class="fa fa-edit"></i>&nbsp;Edit</button> -->
                </div>
            </div>
        </div>
    
    </div>
</div>
