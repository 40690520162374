/// <reference lib="dom" />

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { ApicallsService } from 'src/app/apicalls.service';
import { GlobalStorageService } from 'src/app/global-storage.service';
import { GpsServiceService } from 'src/app/gps-service.service';
import { PatientAddressDetailsComponent } from 'src/app/shared/components/patient-address-details/patient-address-details.component';
import { PatientService } from 'src/app/shared/services/patient.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';

@Component({
  selector: 'app-pharma-steps',
  templateUrl: './pharma-steps.component.html',
  styleUrls: ['./pharma-steps.component.css']
})
export class PharmaStepsComponent implements OnInit {
  pharmaciesList: any[] = [];
  latitude: any;
  longitude: any;
  p2: number = 1;
  permissionDenied = false;
  errorMessage: string | null = null;
  myControl = new FormControl(''); filteredOptionsP: any; 
  optionsp = [];
  @Input() userId;
  @Output() pharmaSelect=new EventEmitter()
  constructor(
    private globalStorage: GlobalStorageService,
    private patientServices: PatientScheduleControlService,
    private gps: GpsServiceService,
    private patient: PatientService,
    private Toaster: ToastrService,
    private modal:NgbModal
    ) { }

  ngOnInit(): void {
    this.checkAndRequestLocation();


    this.myControl.valueChanges.subscribe(res=>{
      this.search(res)
    })

  }
 

  checkAndRequestLocation() {
    this.gps.requestGeolocationPermission().subscribe(
      (permissionStatus) => {
        if (permissionStatus === 'granted') {
          this.getCurrentLocation();
        } else {
          this.permissionDenied = true;
          this.Toaster.warning('Location access is blocked. Please enable location services to use this feature.');
        }
      },
      (error) => {
        this.errorMessage = 'Error checking location permission: ' + error;
        this.Toaster.error(this.errorMessage);
      }
    );
  }

  getCurrentLocation() {
    this.gps.getCurrentPosition().subscribe(
      (position: GeolocationPosition) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.choosePincode();
      },
      (error) => {
        if (error.code === 1) {
          this.permissionDenied = true;
          this.Toaster.warning('Location permission denied. Please enable location services to use this feature.');
        } else {
          this.errorMessage = 'Error getting current position: ' + error.message;
          this.Toaster.error(this.errorMessage);
        }
      }
    );
  }

  choosePincode() {
    const req = {
      "distance": 5,
      "latitude": this.latitude,
      "longitude": this.longitude
    };
    this.patient.getPharmacyByLatAndLong(req).subscribe(
      (res) => {
        this.pharmaciesList = [];
        const pharmacies = JSON.parse(res['data']);
        for (const pharmacy of pharmacies) {
          this.pharmaciesList.push({
            pharmacyname: pharmacy['pharmacyname'],
            city: pharmacy['city'],
            pharmacyId: pharmacy['pharmacyId'],
            pharmacyaddressId: pharmacy['pharmacyaddressId'],
          });
        }
      },
      (error) => {
        this.Toaster.error('Error fetching pharmacies: ' + error.message);
      }
    );
  }

  
  getPharmaById(option){
    const pharmaAddressID = option?.id;
    if(pharmaAddressID){
      this.patientServices.getByPharmacyAddressId(pharmaAddressID).subscribe(res => {
        console.log('pharma res :', res);
        this.pharmaciesList = [];
        var pharma = {
          name: res['data']['pharmacyname'],
          city: res['data']['city'],
          rating: res['data']['averagerating'],
          pharmacyId: res['data']['pharmacyId'],
          pharmacyaddressId: res['data']['pharmacyaddressId']
        }
        this.pharmaciesList.push(pharma);
      })
    }
  }
  getPharmasByName(pharma) {
    const ph = pharma;
    this.patientServices.getAllPharmaciesByName(pharma).subscribe(res => {
      this.pharmaciesList = [];
      for (var i = 0; i < res['data'].length; i++) {
          var pharma = {
            name: res['data'][i]['pharmacyname'],
            city: res['data'][i]['city'],
            rating: res['data']['averagerating'],
            pharmacyId: res['data'][i]['pharmacyId'],
            pharmacyaddressId: res['data'][i]['pharmacyaddressId'],
          }
          this.pharmaciesList.push(pharma);
      }
    });
  }

  search(value) {
  
     this.patientServices.getElasticSearchByPharmacyName(value).subscribe(
      res => {
       this.pharmaciesList=res?.data

      })
  
    
  


}
selecteShippingAddress(pharmacyData){
  this.pharmaSelect.emit(pharmacyData?.pharmacyaddressId||pharmacyData?.pharmacyAddressId )

}



}
