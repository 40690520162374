<div class="modal-header">
    <h5 class="modal-title">
       <i aria-hidden="true" class="fa fa-user-plus"></i> Batchs 
    </h5>
    <button type="button" class="close" (click)="activeModal.close()">
       <span aria-hidden="true">&times;</span>
    </button>
 </div>
 <div class="my-2 px-2 modal-body">
    <div>
        <h5>Category: <span class="text-success">{{category_name}}</span></h5>
        <h5>Sub Category: <span class="text-success">{{subcategory_name}}</span></h5>
    </div>
    <table class="table table-hover table-striped">
        <thead>
            <tr>
                <th>Batch ID</th>
                <th>Batch Number</th>
                <th>Quantity</th>
                <th>Delivered Date</th>
                <th>Expiry Date</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let batch of batchs | paginate: { id: 'p3', itemsPerPage: 10, currentPage: p3 }">
                <td>{{batch.orderBatchId}}</td>
                <td>
                    <a href="javascript:void(0)" (click)="viewCurrentBatchInfo(batch)">{{batch.batchNumber}}</a>
                </td>
                <td>{{batch.noOfitemDeliver}}</td>
                <td>{{batch.deliveredDate | date:'dd-MMM-yyyy'}}</td>
                <td>{{batch.expectedDeliveryDate | date:'dd-MMM-yyyy'}}</td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="batchs?.length == 0">
        <div class="no-data-found"></div>
    </div>

    <div *ngIf="batchs?.length > 10">
        <div class="text-right">
            <ul class="pagination">
                <pagination-controls autoHide="true" id="p3"
                    (pageChange)="p3=$event"></pagination-controls>
            </ul>
        </div>
    </div>
 </div>