<table class="table table-bordered table-hover table-striped dataTable dtr-inline" role="grid"
    aria-describedby="example2_info">
    <thead>
        <tr role="row">
            <th>#ID</th>
            <th> Patient Info </th>
            <th>Contact Info </th>
            <th>Medical Info </th>
            <th width="200">Action </th>

        </tr>
    </thead>

    <tbody>
        <tr class="odd" data-id="9">
            <td>823</td>
            <td>Patient1, Male, 32 </td>
            <td>Email : dinu2386@gmail.com<br>
                Phone : 695840875<br>
                Address : Bhhvvvg</td>
            <td>650</td>
            <td>
                <a class="btn btn-sm mr-1 mt-2 p-1 curser-pointer" data-original-title="Prescriptions"
                    data-placement="top" data-toggle="tooltip">
                    <i class="fa fa-file-prescription themecol1   icon-head patientc2" aria-hidden="true"></i></a>

                <a class="btn btn-sm mr-1 mt-2 p-1 curser-pointer" data-original-title="Medications"
                    data-placement="top" data-toggle="tooltip">
                    <i class="fa-solid fa-hand-holding-medical themecol2   icon-head patientc3"
                        aria-hidden="true"></i></a>

                <a class="btn btn-sm mr-1 mt-2 p-1 curser-pointer" data-original-title="History" data-placement="top"
                    data-toggle="tooltip">
                    <i class="fa fa-h-square themecol3   icon-head patientc1" aria-hidden="true"></i></a>
            </td>
        </tr>
    </tbody>
</table>