<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="keywords" content="HTML5 Template" />
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
  <title>Curemetric</title>
  <!-- favicon icon -->
  <link rel="shortcut icon" href="./../../../assets/landing/images/favicon.png" />
  <!-- owl-carousel -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/owl.carousel.css">
  <!-- shortcodes -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/base.css" />
  <!-- main -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/core.css" />
  <!-- responsive -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/helper.css" />

  <script>

    // if (navigator.userAgent.match(/Android/i)) {
    //   window.location.href = 'https://play.google.com/store/apps/details?id=com.curemetric.hospital'
    // }
    // else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    //   window.location.href = 'https://apps.apple.com/in/app/curemetric/id1659587794'
    // }

  </script>
  <style>
    body,
    p,
    span,
    input,
    button,
    a {
      font-family: 'Poppins', sans-serif !important;
      font-size: 13px !important
    }

    h1,
    h2,
    h3,
    h4 {
      font-family: 'Poppins', sans-serif !important;
    }

    .sign-info {
      border-top: 1px solid #cdd1f3;
      margin-top: 10px;
      padding-top: 20px;
      margin-bottom: 30px
    }

    .iq-social-media {
      margin: 0;
      padding: 0;
      float: right;
    }

    .iq-social-media li {
      list-style: none;
      float: left;
      margin-right: 10px;
    }

    .iq-social-media li:last-child {
      margin-right: 0;
    }

    .iq-social-media li a {
      height: 30px;
      width: 30px;
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      display: inline-block;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
      background: #eff7f8;
      color: #40648c !important;
    }

    .iq-social-media li a:hover {
      text-decoration: none;
    }

    .form-control {
      border-top-width: 0px !important;
      border-left-width: 0px !important;
      border-right-width: 0px !important;
      border-color: #424242 !important;
      border-bottom-width: 1px !important;
      height: calc(2rem + 1px) !important;
      background: transparent !important;
      padding: 9px 0px !important;
    }

    .bg-login {
      background-repeat: no-repeat;
      background-image: url(./assets/landing/images/vector_page/bg-login.jpg);
      background-size: cover;
      background-position: center center;
      width: 100%;
      height: 100%;
      opacity: 1;
      visibility: inherit;
      z-index: 20;
    }

    .signin {
      padding: 0px 0px 0px 0px !important;
      margin-top: 120px;
      max-width: 450px;
      font-family: 'Poppins', sans-serif;
      margin-left: 18px !important;
    }

    .signin h1 {
      font-size: 26px
    }

    .rightimg img {
      height: 500px;
      margin-top: 100px
    }
  </style>






</head>

<body class="ttm-one-page-site">
  <!--page start-->
  <div class="page" id="ttm-home">
    <!-- preloader start -->
    <!-- preloader end -->
    <!--header start-->
    <app-main-header></app-main-header>
    <!--header end-->
    <div class="container-fulid">
      <div class="bg-login">
        <div class=" container">
          <div class="row clit">
            <div class="signin col-lg-4  col-md-8 col-sm-12">
              <h1 class="mb-0">Sign in as Hospital</h1>
              <p>Enter your email address and password</p>
              <div class="error" *ngIf="invalidCredentials">
                {{message}}
              </div>
              <button type="submit" *ngIf="verifyNowButton" class="btn btn-sm btn-primary" (click)="verifyNow()">Verify
                Now</button>
              <form [formGroup]="registerForm" class="mt-4" autocomplete="off">
                <div class="form-group">
                  <label for="exampleInputEmail1">Username / Email ID <span style="color:red">*</span></label>
                  <input type="text" autocomplete="nope" tabIndex="1" class="form-control mb-0" formControlName="email"
                    [(ngModel)]="email" placeholder="Enter Username / Email ID" noWhiteSpaceAtTheStart
                    [ngClass]="{'is-invalid':(submitted || f.email.touched) && f.email.errors}">
                  <div *ngIf="(submitted || f.email.touched) && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Username / Email ID is required </div>
                    <div *ngIf="f.email.errors.minlength">This field must be at least 3 characters long</div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Password <span style="color:red">*</span></label>
                  <a style="color:green;text-decoration:underline" class="float-right" href="javascript:void(0)"
                    (click)="forgotPassword()">Forgot password?</a>
                  <div class="input-group" id="show_hide_password">
                    <input type="password" autocomplete="new-password" (paste)="preventCopy($event)"
                      (copy)="preventCopy($event)" tabIndex="2" class="form-control mb-0" formControlName="password"
                      minlength="8" [(ngModel)]="password" placeholder="Password"
                      [ngClass]="{'is-invalid':(submitted || f.password.touched) && f.password.errors}"
                      (keydown.space)="$event.preventDefault()" id="exampleInputPassword1"
                      placeholder="Enter Password" />
                    <div class="input-group-addon">
                      <a href="javascript:void(0)"><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
                    </div>
                    <div *ngIf="(submitted || f.password.touched) && f.password.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required"> Password is required </div>
                      <div *ngIf="pwd.errors?.minlength"> Password must be at least 8 characters long </div>
                    </div>
                    <div *ngIf="showValidationMessage" class="validation-message">Copying password is not allowed</div>
                  </div>
                </div>
                <div class="d-inline-block w-100">
                  <div class="custom-control custom-checkbox d-inline-block mt-2">
                    <input type="checkbox" class="custom-control-input" id="customCheck1" [checked]="rememberme"
                      (change)="remembermeCheck()">
                    <label style="margin-left:5px;" class="custom-control-label" for="customCheck1">Remember Me</label>
                  </div>
                  <button type="submit" class="btn btn-primary float-right effect effect-5" (click)="onSubmit()"
                    [disabled]="!registerForm.valid">Sign in</button>
                </div>
                <div class="sign-info">
                  <span class="dark-color d-inline-block line-height-2">Don't have an account? <a
                    (click)="navigateToEnquiryForm()" class="pointer"
                      style="color:green;text-decoration:underline">Register</a></span>
                  <ul class="iq-social-media">
                    <li><a href="https://www.facebook.com/Curemetric" target="_blank"><i
                          class="fa fa-facebook  m-r-5"></i></a></li>
                    <li><a target="_blank" href="https://twitter.com/Cure_Metric"><i
                          class="fa fa-twitter m-r-5"></i></a></li>
                    <li><a href="https://www.linkedin.com/company/curemetric" target="_blank"><i
                          class="fa fa-linkedin m-r-5"></i></a></li>
                  </ul>
                </div>
              </form>
            </div>
            <div class=" rightimg col-lg-7  col-md-5 col-sm-5">
              <img src="./assets/landing/images/clinic.png" style="" />
            </div>
          </div>
        </div>
      </div>
    </div><!-- page end -->
    <!-- Footer -->
    <app-main-footer></app-main-footer>
    <script>
      window.addEventListener('DOMContentLoaded', (event) => {
        var userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('android') > -1) {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.curemetric.hospital';
        } else if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipod') > -1 || userAgent.indexOf('ipad') > -1) {
          window.location.href = 'https://apps.apple.com/in/app/curemetric/id1659587794';
        }
      });

    </script>
    <!-- Footer Ends -->
