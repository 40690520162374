
            <div class="modal-header">
                <h5 class="modal-title">Assign Supplier</h5>
                <button type="button" class="close" (click)="activeModal.close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container user-curetab">
                    <div class="tab-pane subcategory" id="subcategory">
                        <div>
                            <a aria-hidden="true" class="btn btn-success al mt-3 float-right btn-xs ml-2"
                                 (click)="addSupplier()"
                                href="javascript:void(0)" style="  position: absolute;right: 31px;top: 4px;">
                                <i aria-hidden="true" class="fa fa-user-plus"></i>
                                ADD</a>
                                <h3 class="mb-3 mt-1 font-size-14 border-bottom p-2 bg-primary-light ">Category Name: <span style="color:#000 !important">{{category?.catagory}}</span></h3>

                                <h3 class="mb-3 mt-1 font-size-14 border-bottom p-2 bg-danger-light ">SubCategory Name: <span style="color:#000 !important">{{subcategory?.name}}</span></h3>
                      

                            <div class="">
                                <table class="table  table-hover table-striped">
                                    <thead>
                                        <tr>

                                            <th>Supplier</th>
                                            <th>Price</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            *ngFor="let Assignsup of AssignSupplier | paginate: { id: 'p6', itemsPerPage: 10, currentPage: p6 }">
                                           
                                            <td>{{Assignsup.supplierName}}</td>
                                            <td>{{Assignsup.unitPrice}}</td>
                                            <td *ngIf="Assignsup?.active=='Y'"><span class="bg-success p-1">Active</span></td>
                                            <td *ngIf="Assignsup?.active=='N'"><span class="bg-danger p-1">Inactive</span></td>            
                                            <td> 
                                                <button class="btn btn-success btn-xs btn-icon" data-toggle="tooltip"
                                                    data-original-title="Place Order" title="Place Order" (click)="openPlaceOrder(AssignSupplier,Assignsup)"
                                                    *ngIf="Assignsup.active=='Y'"><i class="fas fa-people-carry"></i>
                                                </button>
                                                <button class="btn btn-primary btn-xs ml-2" href="javascript:void(0)"
                                                    data-toggle="tooltip" data-original-title="Edit"
                                                    (click)="editAssign(Assignsup)" *ngIf="Assignsup.active=='Y'"><i
                                                        class="fa fa-edit"></i></button>

                                                <button class="btn btn-success btn-xs" href="javascript:void(0)"
                                                    (click)="activateAssignSupplier(Assignsup)"
                                                    *ngIf="Assignsup.active=='N'" data-toggle="tooltip"
                                                    data-original-title="Active"><i
                                                        class="fa fa-user-check fs-10"></i></button>


                                                <button class="btn btn-danger btn-xs ml-2" href="javascript:void(0)"
                                                    (click)="deleteAssignSuppier(Assignsup)"
                                                    *ngIf="Assignsup.active=='Y'" data-toggle="tooltip"
                                                    data-original-title="Deactivate"><i
                                                        class="fa fa-trash-o"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="AssignSupplier?.length == 0 || AssignSupplier?.length == null">
                                    <div class="no-data-found"></div>
                                </div>
                                <div *ngIf="AssignSupplier?.length > 10">
                                    <div class="text-right">
                                        <ul class="pagination">
                                            <pagination-controls autoHide="true" id="p6"
                                                (pageChange)="p6=$event"></pagination-controls>
                                        </ul>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>
                </div>
            </div>
