import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';
import { InventoryService } from '../inventory/inventory.service';
import { ToastrService } from 'ngx-toastr';
import { AddCategoryComponent } from '../inventory/add-category/add-category.component';
import { AddSupplierComponent } from '../inventory/add-supplier/add-supplier.component';
import { AddManufacturerComponent } from '../modals/add-manufacturer/add-manufacturer.component';
@Component({
  selector: 'app-add-inventory',
  templateUrl: './add-inventory.component.html',
  styleUrls: ['./add-inventory.component.css']
})
export class AddInventoryComponent implements OnInit {
  inventoryForm: FormGroup;

  injectableArray: any = [];
  manufacturerArray: any = [];
  prescriptionrequiredArray: any = [];
  supplierNameArray: any = [];
  cattagoryId: any;
  selectOptions = [
    { value: 'Y', label: 'Yes' },
    { value: 'N', label: 'No' }
  ];
  percentages = [0, 5, 12, 18, 28];



  constructor(private globalStorage: GlobalStorageService, private fb: FormBuilder, public activeModal: NgbActiveModal, private inventoryService: InventoryService, private toaster: ToastrService,private modalService:NgbModal) { }

  ngOnInit(): void {
    this.inventoryForm = this.fb.group({

      addressId: [''],
      catagoryId: [''],
      currentBatch: [''],
      description: [''],
      disposable: [''],
      expDate: [''],
      manufacturer: [''],
      manufacturerDate: [''],
      manufacturerId: [''],
      name: ['', [Validators.required]],
      price: [''],
      quantity: [''],
      rack: [''],
      saleUnitPricel: [''],
      supplierId: [''],
      tax: [''],
      unitPrice: [''],
      warranty: ['']
    });

    this.getcategory();
    this.getsupplier();
    this.fetchManufacturers();

  }
  suplier= []
  getsupplier() {
    var body = {
      "flag": "ALL",
    }
    this.inventoryService.suplier(body).subscribe(res => {
      this.suplier = res?.data ||[];
      this.suplier.push({
        name: 'Add New',
        add: true
      })
    })
  }

  manufacturersData;
  fetchManufacturers() {
    let body = {
      "active": "Y",
    }
    this.inventoryService.manufacturerList(body).subscribe(res => {
      this.manufacturersData = res?.['data'] || [];
      this.manufacturersData.push({
        catagory: 'Add New',
        add: true
      })
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  calPrice(){
    if(this.inventoryForm.value?.quantity && this.inventoryForm.value?.unitPrice){
      this.inventoryForm.patchValue({
        price:parseFloat(this.inventoryForm.value?.quantity)*parseFloat(this.inventoryForm.value?.unitPrice)
      })
    }
  }
  selManufacturer:any;
  selectManufacturer(data){
    if(data?.add){
      this.addManufactur();
      return
    }
    this.inventoryForm.patchValue({manufacturerId:data?.manufacturerId})
  }

  addManufactur() {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'left hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-right hw-40';
    }
    const modalRef = this.modalService.open(AddManufacturerComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.result.then(
      (result) => {
        this.fetchManufacturers();
      },
      (reason) => {
      }
    );
  }

   catIdfromObj:any;
   selectedCategory(categorySelected) {
    if(categorySelected?.add){
      this.addCat()
      return
    }
     this.catIdfromObj=categorySelected.catagoryId
     // console.log(categorySelected)
    }
  saveData() {
    const body =this.inventoryForm.value;
    this.inventoryService.saveInventory(body).subscribe(res => {
      this.activeModal.close(res);
      this.toaster.success('Added Successfully');
  }, err => {
    this.toaster.error(err?.['error']?.['message'])
  
     

    })

  }

  supplierName:any
  Supplierselected:any;
  selectedSuppiler(Supplierselected) {
    if (Supplierselected?.add) {
      this.addSup()
      return
    }
    this.Supplierselected = Supplierselected?.supplierId
    this.supplierName = Supplierselected?.name
  }


  categorylist = []
  getcategory() {
    var body = {
      "flag": "Y",
    }
    this.inventoryService.category(body).subscribe(res => {
      this.categorylist = res?.['data']||[];
      this.categorylist.push({
        catagory: 'Add New',
        add: true
      })
    },
      error => {
        this.toaster.error(error?.[error]?.['message']);
      }
    )
  }
  closeModal() {
    this.activeModal.close();
  }
  getData() {

  }

  addCat() {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'left hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-right hw-40';
    }
    var addCategory = this.modalService.open(AddCategoryComponent, {
      centered: false,
      windowClass: wClass
    })
    addCategory.result.then(
      (result) => {
        this.getcategory()
      }
    )
  }

  addSup() {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'left w-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-right hw-40';
    }
    var addSuplier = this.modalService.open(AddSupplierComponent, {
      centered: false,
      windowClass: wClass
    })
    addSuplier.componentInstance.isEdit = false;
    addSuplier.result.then(
      (result) => {
        this.getsupplier()
      }
    )
  }

}
