import { GlobalStorageService } from 'src/app/global-storage.service';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { AuthService } from '../../services/auth.service';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ObservableService } from '../../services/observable.service';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from '../../services/patient.service';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';


declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class VitalsService {
  private vitalsUpdatedSubject = new Subject<any>();
  vitalsUpdated$ = this.vitalsUpdatedSubject.asObservable();

  FirstFormGroup: FormGroup;
  show = true;
  formGroupBritish: FormGroup;
  submitted = false;
  length
  BloodGroup: any;
  weightLevel;
  selectedValue;
  wtlessMsg = false;
  wtExtraMsg = false
  notificationLength
  nonViewData = []
  viewedNotifications
  ViewedNotifications = []
  notificationCount
  userName
  personalData
  userEmailId
  clicked: boolean = false;
  associatedUserData
  firstName
  lastName
  lastLogin
  imageSource
  message
  birthdate
  age
  gender
  userId
  latestVitals
  vitalsLastUpadatedDate
  notications
  Bp
  height
  weight = ""
  pulse = ""
  temperature
  oxygenLevel
  reminders = []
  setItemsPerPage = 6;
  p4 = 1
  file
  hai
  PatientpersonalData
  setPersonalDetails
  medicalTests
  notViewedNotifications
  loadVitals: FormGroup;
  unitsForm: FormGroup;
  formGroupType;
  isChecked: boolean = false;
  selectedCountry;
  dateTime = new Date();
  buttonName = "British";
  medications = []
  hide: any;
  notifyToView
  p2 = 1;
  date
  selectedValue2
  converted; diastolic; systolic;
  bloodGlucosePostlunchId; bloodGlucosePrelunchId; glucolseLevelPreLunch; glucolseLevelPostLunch; diastolicId; systolicId;
  currentDate; heightVitalId; weightVitalId; pulseVitalId; bloodPressureVitalId; temperatureVitalId; oxygenLevelVitalId
  feets: any[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  inchs: any[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
  constructor(private globalStorage: GlobalStorageService, private getPrescrtion: DoctorPatientService, private patientServices: AuthService, private sanitizer: DomSanitizer, private observable: ObservableService,
    private toaster: ToastrService, private patientController: PatientScheduleControlService, private fb: FormBuilder, private router: Router,
    private profileImage: PatientService,
    private ivfService: IvfService) {
    this.observable.invokeEvent.subscribe(value => {
      if (value === 'someValue') {
        this.patientVitals()
      }
    });

    const t1 = setInterval(() => {

      this.date = formatDate(new Date(), "dd-MM-yyyy HH:mm:ss", "en-us")
      if (fb && fb.group) {
        this.FirstFormGroup = fb.group({
          date: [this.date],
          weight: [
            "",
            [Validators.required, Validators.min(10), Validators.max(750)],
          ],
          oxygenLevel: [
            "",
            [Validators.required, Validators.min(90), Validators.max(100)],
          ],
          pulse: [
            "",
            [Validators.required, Validators.min(40), Validators.max(220)],
          ],
          bloodPressure: [
            "",
            [],
          ],
          diastolic: ['', [ Validators.min(50), Validators.max(140)]],
          systolic: ['', [ Validators.min(80), Validators.max(200)]],
          afterLunch: ['', [Validators.min(70), Validators.min(80), Validators.max(220)]],
          beforeLunch: ['', [Validators.min(70), Validators.max(140)]],
          temperature: [
            "",
            [Validators.required, Validators.min(95), Validators.max(105),Validators.pattern(/^\d*\.?\d*$/),
            this.bodyTemperatureRangeValidator()]
          ],
          feet: ["", [Validators.required, Validators.min(1), Validators.max(10)]],
          inch: ["", [Validators.required, Validators.min(0), Validators.max(11)]],
        });




        this.formGroupBritish = fb.group({
          date: [this.date],
          weight: [
            "",
            [Validators.required, Validators.min(10), Validators.max(750)],
          ],
          oxygenLevel: [
            "",
            [Validators.required, Validators.min(90), Validators.max(100)],
          ],
          pulse: [
            "",
            [Validators.required, Validators.min(40), Validators.max(220)],
          ],
          bloodPressure: [
            "",
            [

            ],
          ],
          diastolic: ['', [Validators.min(50), Validators.max(140)]],
          systolic: ['', [Validators.min(80), Validators.max(200)]],
          afterLunch: ['', [Validators.min(80), Validators.min(70), Validators.max(220)]],
          beforeLunch: ['', [Validators.min(70), Validators.max(140)]],
          temperature: [
            "",
            [Validators.required, Validators.min(95), Validators.max(105)],
          ],
          feet: ["", [Validators.required, Validators.min(1), Validators.max(10)]],
          inch: ["", [Validators.required, Validators.min(0), Validators.max(11)]],
        });
        this.loadVitals = fb.group({
          recordedDate: [""],
        });
        clearInterval(t1);
        // clearInterval(t2)
      }
    }, 100)
  }


  bodyTemperatureRangeValidator() {
    return (control) => {
      const temperature = control.value;
      if (temperature !== null) {
        const value = parseFloat(temperature);
        if (isNaN(value) || value < 35 || value > 42) {
          return { bodyTemperatureRange: true };
        }
      }
      return null;
    };
  }

  get temperatureFormControl() {
    return this.temperature.controls;
  }



  userIdfromCaretaker;
  saveData(value) {
    // console.log('value from careaker appointments',value)
    if (value) {
      this.userIdfromCaretaker = value;
      this.userId = this.userIdfromCaretaker
    }
  }

  inPatientData;
  saveInPatient(data) {
    if(data){
      this.inPatientData = data
      this.bloodgroup = data?.bloodgroup;
    }
  }

  vtlsOnInit() {
    var req = {
      "forUserId": this.userId,
      "userId": this.userId
    }
    // this.patientController.getUserPersonalDetails('personaldetails/get/', this.userId, req).subscribe(res => {
    //   this.personalData = res['data']
    //   this.height = this.personalData?.height.split('.')
    //   this.selectedValue = this.height?.[0]
    //   this.selectedValue2 = this.height?.[1]
    //   this.FirstFormGroup.patchValue({
    //     feet: this.height?.[0],
    //     inch: this.height?.[1]
    //   })
    //   this.formGroupBritish.patchValue({
    //     feet: this.height?.[0],
    //     inch: this.height?.[1]
    //   })
    // }
    // )
  }

  vitalsOnInit() {
    var data
    var obj
    this.submitted = false
    data = this.globalStorage.getItem('userData')
    obj = JSON.parse(data)
    // obj['userId']=obj['userId']
    if (this.router.url == '/caretaker-dashboard' || this.router.url == '/caretaker-appointments' || this.router.url == '/caretaker-assignedUsers') {
      data = JSON.parse(this.globalStorage.getItem('caretakerData'));
      obj = data
      obj['userId'] = obj['caretakerId']
    }
    else if (this.router.url == '/in-patient-details' || this.globalStorage.getItem('loggedInAs') == 'IVF') {
      console.log('vitals check from ivf', this.userIdfromCaretaker)
      this.userId = this.userIdfromCaretaker
      obj['userId'] = this.userIdfromCaretaker
    }
    else {
      data = this.globalStorage.getItem('userData')
      obj = JSON.parse(data)
      this.userId = obj?.['userId']
      obj['userId'] = obj?.['userId']
    }
    this.associatedUserData = this.patientController.sharingData
    if (this.associatedUserData) {
      this.userId = this.associatedUserData?.relationUserId
    }
    else if (this.router.url == '/in-patient-details' || this.globalStorage.getItem('loggedInAs') == 'IVF') {
      this.userId = this.userIdfromCaretaker
      obj['userId'] = this.userIdfromCaretaker
    }
    else {
      this.userId = obj?.['userId']
    }
    if (this.router.url == '/caretaker-dashboard') {
      this.userId = this.userIdfromCaretaker
    }
    var req = {
      "forUserId": this.userId,
      "userId": obj?.['userId']
    }

    // this.patientController.getUserPersonalDetails('personaldetails/get/', this.userId, req).subscribe(res => {
    //   this.personalData = res['data']
    //   this.height = this.personalData?.height.split('.')
    //   this.selectedValue = this.height?.[0]
    //   this.selectedValue2 = this.height?.[1]
    //   this.FirstFormGroup.patchValue({
    //     feet: this.height?.[0],
    //     inch: this.height?.[1]
    //   })
    //   this.formGroupBritish.patchValue({
    //     feet: this.height?.[0],
    //     inch: this.height?.[1]
    //   })
    // }
    // )

    const t1 = setInterval(() => {
      if (this.FirstFormGroup && this.FirstFormGroup.value) {
        this.FirstFormGroup.patchValue({
          date: formatDate(this.dateTime, "dd-MM-yyyy HH:mm:ss", "en-us"),
          feet: this.height?.[0],
          inch: this.height?.[1],
          bloodgroup: this.bloodgroup
        });
        this.formGroupBritish.patchValue({
          date: formatDate(this.dateTime, "dd-MM-yyyy HH:mm:ss", "en-us"),

        });
        clearInterval(t1);
      }
    }, 100);


  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  numberOnly1(event): boolean {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;

  }


  dotFunction(x) {
    var j: string = x;
    if (j.length == 1) {
      return this.FirstFormGroup.patchValue({
        height: j + '.'
      })
    }
    if (j.length == 3 && parseInt(j.charAt(2)) > 1) {
      return this.length = 3
    }
    if (j.length == 3 && parseInt(j.charAt(2)) == 1) {
      return this.length = 4
    }
    if (parseInt(j.charAt(3)) > 1) {
      return this.length = 3
    }
  }
  checkWight(weight, height) {
    if (height == '4.6') this.weightLevel = "63-77";
    if (height == '4.7') this.weightLevel = "68-84"; if (height == '4.8') this.weightLevel = "72-88"; if (height == '4.9') this.weightLevel = "77-94";
    if (height == '4.10') this.weightLevel = "81-99"; if (height == '4.11') this.weightLevel = "86-105"; if (height == '5.0') this.weightLevel = "90-110";
    if (height == '5.1') this.weightLevel = "95-116"; if (height == '5.2') this.weightLevel = "99-121"; if (height == '5.3') this.weightLevel = "104-127";
    if (height == '5.4') this.weightLevel = "108-132"; if (height == '5.5') this.weightLevel = "113-138"; if (height == '5.6') this.weightLevel = "117-143";
    if (height == '5.7') this.weightLevel = "122-149"; if (height == '5.8') this.weightLevel = "126-154"; if (height == '5.9') this.weightLevel = "131-160";
    if (height == '5.10') this.weightLevel = "135-165"; if (height == '5.11') this.weightLevel = "140-171"; if (height == '6.0') this.weightLevel = "144-176";
    if (height == '6.1') this.weightLevel = "149-182"; if (height == '6.2') this.weightLevel = "153-187"; if (height == '6.3') this.weightLevel = "158-193";
    if (height == '6.4') this.weightLevel = "162-198"; if (height == '6.5') this.weightLevel = "167-204";
    var arr = this.weightLevel?.split("-");
    if (weight < arr[0]) this.wtlessMsg = true; else { this.wtlessMsg = false }
    if (weight > arr[1]) this.wtExtraMsg = true; else { this.wtExtraMsg = false }
  }
  get f() {
    return this.FirstFormGroup.controls;
  }
  get b() {
    return this.formGroupBritish.controls;
  }

  vitalsobj
  submit(item: any) {
    var data
    var obj = {}
    //this.userId = obj['userId']
    if (this.router.url == '/caretaker-dashboard' || this.router.url == '/caretaker-appointments' || this.router.url == '/caretaker-assignedUsers') {
      data = JSON.parse(this.globalStorage.getItem('caretakerData'));
      obj = data
      this.userId = this.userIdfromCaretaker
      obj['userId'] = this.userIdfromCaretaker
    }
    else if (this.router.url == '/in-patient-details' || this.globalStorage.getItem('loggedInAs') == 'IVF') {
      this.userId = this.userIdfromCaretaker
      obj['userId'] = this.userIdfromCaretaker
    }
    else {
      if (this.associatedUserData) {
        this.userId = this.associatedUserData.relationUserId
      }
      else {
        data = this.globalStorage.getItem('userData')
        obj = JSON.parse(data)
        this.userId = obj['userId']
        obj['userId'] = obj['userId']
      }
    }
    const date = formatDate(
      this.dateTime,
      "yyyy-MM-dd'T'HH:mm:ss.SSS",
      "en-us"
    );
if (this.temperature.valid) {
      // Perform actions with the valid form data
      console.log('Form is valid and submitted: ', this.temperature.value);
    }
    this.submitted = true;

    // stop here if form is invalid
    // if (item.invalid) {
    //   return;
    // }

    delete item.value.date;

    // var user = {
    //   forUserId: this.userId,
    //   userId: obj['userId'],
    // };
    // var data = this.globalStorage.getItem('userData')
    // var obj = JSON.parse(data);


    if (this.show == true) {
      this.vitalsobj = []
      if (item.value.oxygenLevel != this.oxygenLevel) {
        var oxy = {
          "vitalsId": this.oxygenLevelVitalId,
          "userId": this.userId,
          "metricType": "%",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.oxygenLevel,
          "vitalname": "oxygenLevel"
        }
        this.vitalsobj.push(oxy)
      }
      if (item.value.beforeLunch != this.glucolseLevelPreLunch) {
        var bg = {
          "vitalsId": this.bloodGlucosePrelunchId,
          "userId": this.userId,
          "metricType": "%",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.beforeLunch,
          "vitalname": "fasting"
        }
        this.vitalsobj.push(bg)
      }
      if (item.value.afterLunch != this.glucolseLevelPostLunch) {
        var bgp = {
          "vitalsId": this.bloodGlucosePostlunchId,
          "userId": this.userId,
          "metricType": "%",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.afterLunch,
          "vitalname": "random"
        }
        this.vitalsobj.push(bgp)
      }
      if (item.value.pulse != this.pulse) {
        var pl = {
          "vitalsId": this.pulseVitalId,
          "userId": this.userId,
          "metricType": "BPM",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.pulse,
          "vitalname": "pulse"
        }
        this.vitalsobj.push(pl)
      }
      if (item.value.temperature != this.temperature) {
        var tem = {
          "vitalsId": this.temperatureVitalId,
          "userId": this.userId,
          "metricType": "C",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.temperature,
          "vitalname": "temperature"
        }
        this.vitalsobj.push(tem)
      }
      if (item.value.systolic != this.systolic || item.value.diastolic != this.diastolic) {
        var bp = {
          "vitalsId": this.bloodPressureVitalId,
          "userId": this.userId,
          "metricType": "mm/Hg",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.systolic + '/' + item.value.diastolic,
          "vitalname": "bloodPressure"
        }
        this.vitalsobj.push(bp)
      }
      if (item.value.systolic != this.systolic) {
        var sys = {
          "vitalsId": this.systolicId,
          "userId": this.userId,
          "metricType": "mm/Hg",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.systolic,
          "vitalname": "systolic"
        }
        this.vitalsobj.push(sys)
      }
      if (item.value.diastolic != this.diastolic) {
        var dc = {
          "vitalsId": this.diastolicId,
          "userId": this.userId,
          "metricType": "mm/Hg",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.diastolic,
          "vitalname": "diastolic"
        }
        this.vitalsobj.push(dc)
      }
      if (item.value.feet != this.feet || item.value.inch != this.inch) {
        var ht = {
          "vitalsId": this.heightVitalId,
          "userId": this.userId,
          "metricType": "Cms",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.feet + '.' + item.value.inch,
          "vitalname": "height"
        }
        this.vitalsobj.push(ht)
      }
      if (item.value.weight != this.weight) {
        var wt = {
          "vitalsId": this.weightVitalId,
          "userId": this.userId,
          "metricType": "lb",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.weight,
          "vitalname": "weight"
        }
        this.vitalsobj.push(wt)
      }
      var request = {
        "forUser": {
          "forUserId": this.userId,
          "userId": obj['userId']
        },

        vitals: this.vitalsobj
      };
    }
    else {
      if (this.router.url == '/caretaker-dashboard' || this.router.url == '/caretaker-appointments' || this.router.url == '/caretaker-assignedUsers') {
        data = JSON.parse(this.globalStorage.getItem('caretakerData'));
        obj = data
        this.userId = this.userIdfromCaretaker
        obj['userId'] = this.userIdfromCaretaker
      }
      else if (this.router.url == '/in-patient-details' || this.globalStorage.getItem('loggedInAs') == 'IVF') {
        this.userId = this.userIdfromCaretaker
        obj['userId'] = this.userIdfromCaretaker
      }
      else {
        data = this.globalStorage.getItem('userData')
        obj = JSON.parse(data)
        this.userId = obj['userId']
        obj['userId'] = obj['userId']
      }
      this.associatedUserData = this.patientController.sharingData
      if (this.associatedUserData) {
        this.userId = this.associatedUserData.relationUserId
      }
      else {
        this.userId = obj['userId']
      }
      if (this.router.url == '/caretaker-dashboard' || this.router.url == '/caretaker-appointments' || this.router.url == '/caretaker-assignedUsers' || this.router.url == '/in-patient-details' || this.globalStorage.getItem('loggedInAs') == 'IVF') {
        this.userId = this.userIdfromCaretaker;
        console.log(this.userIdfromCaretaker, "Here it is")
      }
      this.vitalsobj = []
      if (item.value.oxygenLevel != this.oxygenLevel) {
        var oxy = {
          "vitalsId": this.oxygenLevelVitalId,
          "userId": this.userId,
          "metricType": "%",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.oxygenLevel,
          "vitalname": "oxygenLevel"
        }
        this.vitalsobj.push(oxy)
      }
      if (item.value.beforeLunch != this.glucolseLevelPreLunch) {
        var bg = {
          "vitalsId": this.bloodGlucosePrelunchId,
          "userId": this.userId,
          "metricType": "%",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.beforeLunch,
          "vitalname": "fasting"
        }
        this.vitalsobj.push(bg)
      }
      if (item.value.afterLunch != this.glucolseLevelPostLunch) {
        var bgp = {
          "vitalsId": this.bloodGlucosePostlunchId,
          "userId": this.userId,
          "metricType": "%",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.afterLunch,
          "vitalname": "random"
        }
        this.vitalsobj.push(bgp)
      }
      if (item.value.pulse != this.pulse) {
        var pl = {
          "vitalsId": this.pulseVitalId,
          "userId": this.userId,
          "metricType": "BPM",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.pulse,
          "vitalname": "pulse"
        }
        this.vitalsobj.push(pl)
      }
      if (item.value.temperature != this.temperature) {
        var tem = {
          "vitalsId": this.temperatureVitalId,
          "userId": this.userId,
          "metricType": "C",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.temperature,
          "vitalname": "temperature"
        }
        this.vitalsobj.push(tem)
      }
      if (item.value.systolic != this.systolic || item.value.diastolic != this.diastolic) {
        var bp = {
          "vitalsId": this.bloodPressureVitalId,
          "userId": this.userId,
          "metricType": "mm/Hg",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.systolic + '/' + item.value.diastolic,
          "vitalname": "bloodPressure"
        }
        this.vitalsobj.push(bp)
      }
      if (item.value.systolic != this.systolic) {
        var sys = {
          "vitalsId": this.systolicId,
          "userId": this.userId,
          "metricType": "mm/Hg",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.systolic,
          "vitalname": "systolic"
        }
        this.vitalsobj.push(sys)
      }
      if (item.value.diastolic != this.diastolic) {
        var dc = {
          "vitalsId": this.diastolicId,
          "userId": this.userId,
          "metricType": "mm/Hg",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.diastolic,
          "vitalname": "diastolic"
        }
        this.vitalsobj.push(dc)
      }
      if (item.value.feet != this.feet || item.value.inch != this.inch) {
        var ht = {
          "vitalsId": this.heightVitalId,
          "userId": this.userId,
          "metricType": "Cms",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.feet + '.' + item.value.inch,
          "vitalname": "height"
        }
        this.vitalsobj.push(ht)
      }
      if (item.value.weight != this.weight) {
        var wt = {
          "vitalsId": this.weightVitalId,
          "userId": this.userId,
          "metricType": "lb",
          "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
          "vitalValue": item.value.weight,
          "vitalname": "weight"
        }
        this.vitalsobj.push(wt)
      }
      request = {
        "forUser": {
          "forUserId": this.userId,
          "userId": obj['userId']
        },
        vitals: this.vitalsobj
      };
    }
    // var vital = {
    //   recordedDate: date.concat("Z"),
    //   userId: this.userId,
    //   vitalValue: JSON.stringify(item.value),
    // };
    this.height = item.value.height

    // var req = {
    //   "dob": this.personalData.DOB,
    //   "ethinicity": this.personalData.ethinicity,
    //   "eyeColor": this.personalData.eyeColor,
    //   "hairColor": this.personalData.hairColor,
    //   "height": this.selectedValue + '.' + this.selectedValue2,
    //   "highestDegree": this.personalData.highestDegree,
    //   "nationality": this.personalData.nationality,
    //   "profilePic": this.personalData.profilePic,
    //   "specialization": this.personalData.specialization,
    //   "gender": this.personalData.gender,
    //   "martialstatus": this.personalData.martialstatus,
    //   "bloodgroup": this.personalData.bloodgroup,
    //   "preferredlanguages": this.personalData?.preferredlanguages?.toString(),
    //   "userId": this.userId
    // }
    // req['personalDetailId'] = this.personalData.personalDetailId
    if(this.globalStorage.getItem('loggedInAs')=='IVF'){
      this.ivfService.saveVitals(request, this.userId).subscribe((res) => {
        if (res.body.status == "SUCCESS") {
          this.toaster.success("Vitals updated.");
          this.globalStorage.setItem('pulse', item.value.pulse)
          this.globalStorage.setItem('glucolseLevelPreLunch', item.value.beforeLunch)
          this.globalStorage.setItem('glucolseLevelPostLunch', item.value.afterLunch)
          this.globalStorage.setItem('bp', item.value.systolic + '/' + item.value.diastolic)
          this.FirstFormGroup.reset();
          this.patientVitals()//for updating  vitals obj
          this.vitalsobj = []
          this.observable.updateVitals();
          this.observable.updateVitalsRpm(this.userIdfromCaretaker)
          this.submitted = false
          // this.ptvitals()//for updating  vitals obj
          //        let currentUrl = this.router.url;
          // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          // this.router.onSameUrlNavigation = 'reload';
          // this.router.navigate([currentUrl]);
          $('#addvitals').modal('hide');
          $('#vitalsEdit').modal('hide')
        } else {
          this.toaster.error(res.body.message);
        }
      }, error => {
      });
    }
    else{
      this.profileImage.saveVitals(request, this.userId).subscribe((res) => {
        if (res.body.status == "SUCCESS") {
          this.toaster.success("Vitals updated.");
          this.globalStorage.setItem('pulse', item.value.pulse)
          this.globalStorage.setItem('glucolseLevelPreLunch', item.value.beforeLunch)
          this.globalStorage.setItem('glucolseLevelPostLunch', item.value.afterLunch)
          this.globalStorage.setItem('bp', item.value.systolic + '/' + item.value.diastolic)
          this.FirstFormGroup.reset();
          this.patientVitals()//for updating  vitals obj
          this.vitalsobj = []
          this.observable.updateVitals();
          this.observable.updateVitalsRpm(this.userIdfromCaretaker)
          this.submitted = false
          // this.ptvitals()//for updating  vitals obj
          //        let currentUrl = this.router.url;
          // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          // this.router.onSameUrlNavigation = 'reload';
          // this.router.navigate([currentUrl]);
          $('#addvitals').modal('hide');
          $('#vitalsEdit').modal('hide')
        } else {
          this.toaster.error(res.body.message);
        }
      }, error => {
      });
    }
    $('#addvitals').modal('hide')

  }

  bloodgroup; feet; inch
  ptvitals() {
    var request = {
      "forUserId": this.userId,

      "userId": this.userId,

    }
    this.profileImage.getAllVitalsByDayByDay(request, this.userId).subscribe(res => {
      var array = res.body.data
      this.vitalsLastUpadatedDate = array.reverse()?.[0]?.['lastUpdatedTs']
      var vitals = [];
      for (var i = 0; i < array.length; i++) {
        vitals.push((array[i].vitalValue))
        if (array[i].vitalname == "height") {
          this.height = this.height
          this.heightVitalId = array[i].vitalsId
        }
        if (array[i].vitalname == "weight") {
          this.weight = array[i].vitalValue
          this.weightVitalId = array[i].vitalsId
        }
        if (array[i].vitalname == "pulse") {
          this.pulse = array[i].vitalValue
          this.pulseVitalId = array[i].vitalsId
        }
        if (array[i].vitalname == "bloodPressure") {
          this.Bp = array[i].vitalValue
          this.bloodPressureVitalId = array[i].vitalsId
        }
        if (array[i].vitalname == "temperature") {
          this.temperature = array[i].vitalValue
          this.temperatureVitalId = array[i].vitalsId
        }
        if (array[i].vitalname == "oxygenLevel") {
          this.oxygenLevel = array[i].vitalValue
          this.oxygenLevelVitalId = array[i].vitalsId
        }
        if (array[i].vitalname == "random") {
          this.glucolseLevelPostLunch = array[i].vitalValue
          this.bloodGlucosePostlunchId = array[i].vitalsId
        }
        if (array[i].vitalname == "fasting") {
          this.glucolseLevelPreLunch = array[i].vitalValue
          this.bloodGlucosePrelunchId = array[i].vitalsId
        }
        if (array[i].vitalname == "systolic") {
          this.systolic = array[i].vitalValue
          this.systolicId = array[i].vitalsId
        }
        if (array[i].vitalname == "diastolic") {
          this.diastolic = array[i].vitalValue
          this.diastolicId = array[i].vitalsId
        }
        if (array[i].vitalname == 'bloodgroup') {
          this.bloodgroup = array[i].vitalValue
        }
        if (this.router?.url == '/in-patient-details' || this.router?.url == '/caretaker-appointments' || this.router?.url == '/caretaker-dashboard' ) {
          this.bloodgroup = this.inPatientData?.bloodgroup
        }
      }
      this.latestVitals = vitals[0]
      // this.latestVitals = JSON.parse(this.latestVitals)
      if (this.latestVitals != undefined) {
        // this.Bp=this.latestVitals['bloodPressure']
        // this.height=this.latestVitals['height']
        // this.weight=this.latestVitals['weight']
        // this.pulse=this.latestVitals['pulse']
        // this.temperature=this.latestVitals['temperature']
        // this.oxygenLevel=this.latestVitals['oxygenLevel']
        var str = this.weight;
        // str = str.toString();
        str = str.slice(0, -2);

        var htstr = this.height
        // htstr = htstr.toString();
        //htstr = htstr.slice(0, -1);
        var pulsestr = this.pulse
        pulsestr = pulsestr.toString();
        pulsestr = pulsestr.slice(0, -3);
        var tempstr = this.temperature
        tempstr = tempstr.toString();
        tempstr = tempstr.slice(0, -1);
        var oxystr = this.oxygenLevel
        oxystr = oxystr.toString();
        oxystr = oxystr.slice(0, -1);
        var bpstr = this.Bp
        bpstr = bpstr.toString();
        bpstr = bpstr.slice(0, -5);
        var htstr = this.height;
        this.formGroupBritish.patchValue({
          date: this.date,
          weight: this.weight,
          feet: this.height?.[0],
          inch: this.height?.[1],
          pulse: this.pulse,
          temperature: this.temperature,
          oxygenLevel: this.oxygenLevel,
          bloodPressure: this.Bp,
          afterLunch: this.glucolseLevelPostLunch,
          beforeLunch: this.glucolseLevelPreLunch,
          systolic: this.systolic,
          diastolic: this.diastolic
        });

      }
    })
  }
  patientVitals() {
    this.weight = ''
    this.pulse = ''
    this.temperature = ''
    this.oxygenLevel = ''
    this.Bp = ''
    this.glucolseLevelPostLunch = ''
    this.glucolseLevelPreLunch = ''
    this.systolic = ''
    this.diastolic = ''
    var data
    var obj = {}
      if (this.formGroupBritish) {
        this.formGroupBritish.reset()
      }
    this.userId = ''
    if (this.router.url == '/caretaker-dashboard' || this.router.url == '/caretaker-appointments' || this.router.url == '/caretaker-assignedUsers') {
      data = JSON.parse(this.globalStorage.getItem('caretakerData'));
      obj = data
    }
    else if (this.router.url == '/in-patient-details' || this.globalStorage.getItem('loggedInAs') == 'IVF') {
      this.userId = this.userIdfromCaretaker
    }
    else {
      data = this.globalStorage.getItem('userData')
      obj = JSON.parse(data)
      this.userId = obj?.['userId']
    }
    this.associatedUserData = this.patientController.sharingData
    if (this.router.url == '/caretaker-dashboard' || this.router.url == '/caretaker-appointments' || this.router.url == '/caretaker-assignedUsers') {
      data = JSON.parse(this.globalStorage.getItem('caretakerData'));
      obj = data
      this.userId = this.userIdfromCaretaker;
      obj['userId'] = this.userIdfromCaretaker;
    }
    else if (this.router.url == '/in-patient-details' || this.globalStorage.getItem('loggedInAs') == 'IVF') {
      this.userId = this.userIdfromCaretaker
      obj['userId'] = this.userIdfromCaretaker
    }
    else {
      if (this.associatedUserData) {
        this.userId = this.associatedUserData.relationUserId
      }
      else {
        this.userId = obj?.['userId']
      }
    }
    var request = {
      "forUserId": this.userId,
      "userId": obj['userId'],
    }
    if (this.globalStorage.getItem('loggedInAs') == 'IVF') {
      this.profileImage.getIvfPatientVitals(this.globalStorage.getItem('ivfAddressId'), this.userIdfromCaretaker).subscribe(res => {
        console.log('ivf vitals res', res)
        this.vitalsUpdatedSubject.next(this.userIdfromCaretaker);
        var array = res.body.data
        this.vitalsLastUpadatedDate = array?.reverse()?.[0]?.['lastUpdatedTs']
        var vitals = []
        for (var i = 0; i < array?.length; i++) {
          vitals.push((array?.[i]?.vitalValue))
          if (array?.[i]?.vitalname == "height") {
            this.height = array?.[i]?.vitalValue?.toString()
            this.heightVitalId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "weight") {
            this.weight = array[i].vitalValue
            this.weightVitalId = array[i].vitalsId
          }
          if (array?.[i]?.vitalname == "pulse") {
            this.pulse = array?.[i]?.vitalValue
            this.pulseVitalId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "bloodPressure") {
            this.Bp = array?.[i]?.vitalValue
            this.bloodPressureVitalId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "temperature") {
            this.temperature = array?.[i]?.vitalValue
            this.temperatureVitalId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "oxygenLevel") {
            this.oxygenLevel = array?.[i]?.vitalValue
            this.oxygenLevelVitalId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "random") {
            this.glucolseLevelPostLunch = array?.[i]?.vitalValue
            this.bloodGlucosePostlunchId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "fasting") {
            this.glucolseLevelPreLunch = array?.[i]?.vitalValue
            this.bloodGlucosePrelunchId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "systolic") {
            this.systolic = array[i]?.vitalValue
            this.systolicId = array[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "diastolic") {
            this.diastolic = array?.[i]?.vitalValue
            this.diastolicId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == 'bloodgroup') {
            this.bloodgroup = array?.[i]?.vitalValue
          }
          if (this.router?.url == '/in-patient-details' || this.router?.url == '/caretaker-appointments' || this.router?.url == '/caretaker-dashboard') {
            console.log('data from care', this.inPatientData)
            this.bloodgroup = this.inPatientData?.bloodgroup
          }
        }
        this.latestVitals = vitals[0]
        // this.latestVitals = JSON.parse(this.latestVitals)
        if (this.latestVitals != undefined) {
          // this.Bp=this.latestVitals['bloodPressure']
          // this.height=this.latestVitals['height']
          // this.weight=this.latestVitals['weight']
          // this.pulse=this.latestVitals['pulse']
          // this.temperature=this.latestVitals['temperature']
          // this.oxygenLevel=this.latestVitals['oxygenLevel']
          var str = this.weight;
          // str = str.toString();
          str = str.slice(0, -2);

          var htstr = this.height
          // htstr = htstr.toString();
          //htstr = htstr.slice(0, -1);
          var pulsestr = this.pulse
          pulsestr = pulsestr?.toString();
          pulsestr = pulsestr?.slice(0, -3);
          var tempstr = this.temperature
          tempstr = tempstr?.toString();
          tempstr = tempstr?.slice(0, -1);
          var oxystr = this.oxygenLevel
          oxystr = oxystr?.toString();
          oxystr = oxystr?.slice(0, -1);
          var bpstr = this.Bp
          bpstr = bpstr?.toString();
          bpstr = bpstr?.slice(0, -5);
          var htstr = this.height?.toString()
          this.height = this.height?.split('.')
          this.feet = this.height?.[0]
          this.inch = this.height?.[1]
          this.formGroupBritish.patchValue({
            date: this.date,
            weight: this.weight,
            feet: this.height?.[0],
            inch: this.height?.[1],
            pulse: this.pulse,
            temperature: this.temperature,
            oxygenLevel: this.oxygenLevel,
            bloodPressure: this.Bp,
            afterLunch: this.glucolseLevelPostLunch,
            beforeLunch: this.glucolseLevelPreLunch,
            systolic: this.systolic,
            diastolic: this.diastolic
          });

        }
      })
    }
    else {
      this.profileImage.getAllVitalsByDayByDay(request, this.userId).subscribe(res => {
        var array = res.body.data
        this.vitalsLastUpadatedDate = array?.reverse()?.[0]?.['lastUpdatedTs']
        var vitals = []
        for (var i = 0; i < array?.length; i++) {
          vitals.push((array?.[i]?.vitalValue))
          if (array?.[i]?.vitalname == "height") {
            this.height = array?.[i]?.vitalValue?.toString()
            this.heightVitalId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "weight") {
            this.weight = array[i].vitalValue
            this.weightVitalId = array[i].vitalsId
          }
          if (array?.[i]?.vitalname == "pulse") {
            this.pulse = array?.[i]?.vitalValue
            this.pulseVitalId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "bloodPressure") {
            this.Bp = array?.[i]?.vitalValue
            this.bloodPressureVitalId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "temperature") {
            this.temperature = array?.[i]?.vitalValue
            this.temperatureVitalId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "oxygenLevel") {
            this.oxygenLevel = array?.[i]?.vitalValue
            this.oxygenLevelVitalId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "random") {
            this.glucolseLevelPostLunch = array?.[i]?.vitalValue
            this.bloodGlucosePostlunchId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "fasting") {
            this.glucolseLevelPreLunch = array?.[i]?.vitalValue
            this.bloodGlucosePrelunchId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "systolic") {
            this.systolic = array[i]?.vitalValue
            this.systolicId = array[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == "diastolic") {
            this.diastolic = array?.[i]?.vitalValue
            this.diastolicId = array?.[i]?.vitalsId
          }
          if (array?.[i]?.vitalname == 'bloodgroup') {
            this.bloodgroup = array?.[i]?.vitalValue
          }
          if (this.router?.url == '/in-patient-details' || this.router?.url == '/caretaker-appointments' || this.router?.url == '/caretaker-dashboard' || this.router?.url == '/caretaker-assignedUsers') {
            console.log('data from care', this.inPatientData)

            this.bloodgroup = this.inPatientData?.bloodgroup
          }
        }
        this.vitalsUpdatedSubject.next(this.userIdfromCaretaker);
        this.latestVitals = vitals[0]
        // this.latestVitals = JSON.parse(this.latestVitals)
        if (this.latestVitals != undefined) {
          // this.Bp=this.latestVitals['bloodPressure']
          // this.height=this.latestVitals['height']
          // this.weight=this.latestVitals['weight']
          // this.pulse=this.latestVitals['pulse']
          // this.temperature=this.latestVitals['temperature']
          // this.oxygenLevel=this.latestVitals['oxygenLevel']
          var str = this.weight;
          // str = str.toString();
          str = str.slice(0, -2);

          var htstr = this.height
          // htstr = htstr.toString();
          //htstr = htstr.slice(0, -1);
          var pulsestr = this.pulse
          pulsestr = pulsestr?.toString();
          pulsestr = pulsestr?.slice(0, -3);
          var tempstr = this.temperature
          tempstr = tempstr?.toString();
          tempstr = tempstr?.slice(0, -1);
          var oxystr = this.oxygenLevel
          oxystr = oxystr?.toString();
          oxystr = oxystr?.slice(0, -1);
          var bpstr = this.Bp
          bpstr = bpstr?.toString();
          bpstr = bpstr?.slice(0, -5);
          var htstr = this.height?.toString()
          this.height = this.height?.split('.')
          this.feet = this.height?.[0]
          this.inch = this.height?.[1]
          this.formGroupBritish.patchValue({
            date: this.date,
            weight: this.weight,
            feet: this.height?.[0],
            inch: this.height?.[1],
            pulse: this.pulse,
            temperature: this.temperature,
            oxygenLevel: this.oxygenLevel,
            bloodPressure: this.Bp,
            afterLunch: this.glucolseLevelPostLunch,
            beforeLunch: this.glucolseLevelPreLunch,
            systolic: this.systolic,
            diastolic: this.diastolic
          });

        }
      })
    }

    var id
    if (this.router.url == '/caretaker-dashboard' || this.router.url == '/caretaker-appointments' || this.router.url == '/caretaker-assignedUsers') {
      data = JSON.parse(this.globalStorage.getItem('caretakerData'));
      obj = data
      this.userId = obj['caretakerId']
      obj['userId'] = this.userId
    }
    else {
      if (this.globalStorage.getItem('loggedInAs') == 'IVF') {

      }
      else if (this.associatedUserData) {
        id = this.associatedUserData.relationUserId
      }
      else {
        data = this.globalStorage.getItem('userData')
        obj = JSON.parse(data)
        id = obj['userId']
      }
    }
    this.lastLogin = new Date(obj['lastLogin'])
    // this.patientController.getGlobal('notificationalerts/get/all?maxResult=100&pageNumber=' + this.p4 + '&userId=' + this.userId, id).subscribe(res => {
    //   this.reminders = res['data']
    //   this.notificationLength = this.reminders.filter((obj) => obj.viewed == 'N').length;
    //   this.nonViewData = this.reminders.filter((obj) => obj.viewed == 'N')
    //   this.ViewedNotifications = this.reminders.filter((obj) => obj.viewed == 'Y')
    // })
  }
  heightCheck(height: string) {
    // if(phn.value.length < 1)
    // {
    //   const charCode = (event.which) ? event.which : event.keyCode;
    //   if (charCode > 31 && (charCode < 54 || charCode > 57))
    //   {
    //     return false;
    //   }
    //   return true;
    // }
    if (height.includes('.')) {
      if (height.length > 3) {
        return false;
      }


    }
  }
  // profileYwo() {
  //   var data = this.globalStorage.getItem('userData')
  //   var obj = JSON.parse(data)
  //   if (this.associatedUserData) {
  //     this.userId = this.associatedUserData.relationUserId
  //     var req = {
  //       "forUserId": this.userId,
  //       "relationType": "DOCTOR",
  //       "userId": obj['userId']
  //     }
  //   }
  //   else {
  //     this.userId = obj['userId']
  //     var req = {
  //       "forUserId": this.userId,
  //       "relationType": "DOCTOR",
  //       "userId": obj['userId']
  //     }
  //   }
  //   this.patientController.getUserPersonalDetails('personaldetails/get/', this.userId, req).subscribe(res => {

  //     this.BloodGroup = res['data']['bloodgroup']
  //   }, error => {
  //     this.imageSource = "./assets/img/img_avatar.png";
  //   })
  // }
}
