import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';

@Component({
  selector: 'app-caretaker-layout',
  templateUrl: './caretaker-layout.component.html',
  styleUrls: ['./caretaker-layout.component.css']
})
export class CaretakerLayoutComponent implements OnInit {
  subscription: Subscription;
  isHome
  constructor(private scripts:ScriptsService,) { }

  ngOnInit(): void {
    
    const source = interval(1000);
    this.subscription = source.subscribe(val => this.checkHomeOrNot());
  }
  checkHomeOrNot(){
    if(window.location.pathname == '/caretaker-dashboard' || window.location.pathname == '/cureMetric/caretaker-dashboard'){
      this.isHome = true;
     }
    else{
      this.isHome = false;
    }
  }
}
