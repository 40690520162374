<!-- <div style="float:right" >
    <button type="button"   class="btn btn-primary btn-sm"  aria-hidden="true" data-toggle="modal"  data-target="#addSchedule" style="position: absolute;z-index: 9999;"><i class="fa fa-plus"></i></button>
</div> -->
<ol class="breadcrumb" *ngIf="!isHospital">
  <li><a href="javascript:void(0)" [routerLink]="routelink"><i class="fa fa-home" aria-hidden="true"></i></a></li>
  <li> <a href="javascript:void(0)">Calendar</a></li>
</ol>


<div class="schedule-calender" id="schedule-calender">
    <!-- <button type="button" (click)="changeIntialView()" style="position: absolute;z-index: 9999;">timeGridWeek</button> -->
    <full-calendar deepChangeDetection="true" [options]="calendarOptions"></full-calendar>
    <!-- <div id="calendar"></div> -->
</div>

<div class="modal fade modal-right" id="addSchedule" role="dialog" tabindex="-1" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Add Schedule</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div style="padding: 10px;">
                <div class="row">
                    <div class="col-md-6">
                        <!-- Date :<input type="text" class="form-control" [(ngModel)]="date"> -->
                        <div class="row">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Date <span
                                            class="font-size-10 text-muted">(MM/DD/YYYY)</span><span
                                            style="color:red">*</span></label>
                                    <input id="matDate" class="form-control" readonly matInput [matDatepicker]="scDate"
                                        [(ngModel)]="newDate" placeholder="mm/dd/yyyy">
                                </div>
                            </div>

                            <div class="col-md-4" style="margin-top: 24px; margin-left: -44px;"><mat-datepicker
                                    #scDate></mat-datepicker>
                                <mat-datepicker-toggle matSuffix [for]="scDate"
                                    ng-model-options="{ timezone: 'utc' }"></mat-datepicker-toggle>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        From time <span style="color:red">*</span>: <select class="form-control" [(ngModel)]="fromTime"
                            (change)="fromTimeChange()">
                            <option value="" selected hidden disabled>select from time</option>
                            <option *ngFor="let h of h" value="{{h}}">{{h|convertFrom24To12Format}}</option>
                        </select>
                    </div>

                    <div class="col-md-6">
                        To time <!--<span [(ngModel)]="toTime">{{toTime}}</span> -->
                        <input type="text" class="form-control" placeholder="To time" [(ngModel)]="toTime"
                            [readOnly]="true">
                        <!-- <span style="color:red">*</span>: <select  class="form-control" [(ngModel)]="toTime">
                <option value="" selected hidden disabled>select to time</option>
                <option *ngFor="let h of toTimeList" value="{{h}}">{{h|convertFrom24To12Format}}</option>
            </select>   -->
                    </div>
                </div>
                <div style="padding-top:20px;">
                    <button style="float: right;" class="btn btn-primary btn-sm"><i class="fas fa-save"></i>
                        Save</button>
                </div>
            </div>
        </div>
    </div>
</div>
<style>
    .fc-timeGridWeek-button .fc-button .fc-button-primary .fc-button-active {
        background-color: orange !important;
    }
</style>
