import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from 'rxjs';
import { LoginService } from 'src/app/AppServices/login.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ClinicService } from '../clinic.service';

@Component({
  selector: 'app-clinic-verification',
  templateUrl: './clinic-verification.component.html',
  styleUrls: ['./clinic-verification.component.css']
})
export class ClinicVerificationComponent implements OnInit {
  otpVerificationForm: FormGroup;
  timerOn = true;
  isTimeOn2 = false;
  subscription: Subscription;
  subscribeTimer
  timeLeft: number = 120;
  timeLeft2: number = 120;
  emailOPTError = false;
  mobileOPTError = false;
  mbNumber
  constructor(private otpService: AuthService, private formBuilder: FormBuilder,
    private loginService: LoginService, private router: Router, private clinicServices: ClinicService,
    private toaster: ToastrService, private activatedRoute: ActivatedRoute) {
    //   this.activatedRoute.params.subscribe(params => {

    //     this.email = params.emailId;
    // });
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      emailId: string,
      userName: string,
      mobileNumber: string,
    };
    this.otpVerificationForm = this.formBuilder.group({
      email: ['', Validators.required],
      userName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
    })
    this.mbNumber = state.mobileNumber
    this.otpVerificationForm.patchValue({
      email: state.emailId,
      userName: state.userName,
      phoneNumber: state.mobileNumber.replace(/.(?=.{4})/g, 'x').padStart(10, 'x').slice(-10),
    })
  }

  Verification: boolean = false
  ngOnInit(): void {
    this.email = this.activatedRoute.snapshot.queryParams.verificationId;
    this.observableTimer();
  }
  OTP
  email; PhOTP; emailVerificationDone = false
  verifyOTP() {

    this.emailOPTError = false;
    if (this.OTP == undefined || this.otpVerificationForm.value.email == undefined) {
      return
    }

    if (this.OTP == "" || this.otpVerificationForm.value.email == "") {
      return
    }
    this.clinicServices.emailOtpVerify(this.OTP, this.otpVerificationForm.value.email).subscribe(res => {
      //this.isTimeOn = true;
      this.toaster.success("Your email ID is now verified.")
      this.emailVerificationDone = true;
      this.observableTimer2();
      this.isTimeOn2 = true;
    }, error => {
      this.toaster.error(error?.['error']?.['message']);
      this.emailOPTError = true;
    }
    )
  }
  verifyPhoneOTP() {
    this.mobileOPTError = false;
    if (this.PhOTP == undefined || this.otpVerificationForm.value.phoneNumber == undefined) {
      return
    }

    if (this.PhOTP == "" || this.otpVerificationForm.value.phoneNumber == "") {
      return
    }
    this.clinicServices.phoneOtpVerify(this.mbNumber.replace(/\+/gi, '%2B'), this.PhOTP).subscribe(res => {
      this.toaster.success('Your phone number is now verified.')
      this.router.navigate(['/clinicLogin'])
    }, error => {
      this.toaster.error(error?.['error']?.['message']);
      this.mobileOPTError = true;
    })
  }


  isTimeOn = false;

  resendOTP() {
    if (this.otpVerificationForm.value.email == undefined || this.otpVerificationForm.value.email == "") {
      this.toaster.warning("Please enter email");
      return
    }
    this.clinicServices.resneEmailOtpforH(this.otpVerificationForm.value.email, this.otpVerificationForm.value.userName).subscribe(res => {
      this.isTimeOn = true;
      this.toaster.success("OTP send to your EmailId")
      this.observableTimer();
    }, error => {
      this.toaster.error("Error in resend OTP")
    })

  }
  observableTimer() {
    this.isTimeOn = true
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  observableTimer2() {
    this.isTimeOn = false
    this.isTimeOn2 = true
    this.timeLeft2 = 120
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft2 - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer2').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn2 = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        // this.successCredentials = false
        this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
        // this.invalidCredentials = true
        // this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  resendPhoneOTP() {
    if (this.otpVerificationForm.value.phoneNumber == undefined || this.otpVerificationForm.value.phoneNumber == "") {
      this.toaster.warning("Please enter phone number");
      return
    }
    this.clinicServices.resendPhnOtpforH(this.mbNumber.replace(/\+/gi, '%2B'), this.otpVerificationForm.value.userName).subscribe(res => {
      this.toaster.success("OTP send to your mobile number");
      this.isTimeOn = true;
      this.observableTimer2();
    }, error => {
      this.toaster.error("Error in resend OTP")
    })

  }


  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  showEverify = true
  emaiLOtpEntered(value) {
    if (value.length == 6) {
      this.showEverify = false
    }
    else {
      this.showEverify = true
    }
  }



  showPverify = true
  phoneOtpEntered(value) {
    if (value.length == 6) {
      this.showPverify = false
    }
    else {
      this.showPverify = true
    }
  }
}
