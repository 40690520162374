<div class="modal-header">
    <h4 class="modal-title">Add Payment</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-lg-6 mb-2">
            <b>Amount:</b> {{ orderDetails?.totalPrice | number:'1.2-2' }}
        </div>
        <div class="col-lg-6 mb-2">
            <b> Due Date </b> :{{orderDetails?.paymenDueDate | date :'dd:MM:yyyy'}}
        </div>
        <div class="col-lg-12">
            <label class="form-label">Payment Mode</label>
            <mat-select class="form-control" name="item" [(ngModel)]="selectedPaymentType" placeholder="Payment Mode">
            <mat-option *ngFor="let item of cashModes" [value]="item.value">
              {{item.value}}
            </mat-option>
          </mat-select>
        </div>

        <div class="col-lg-12">
            <label class="form-label">Reference Number</label>
            <input type="text" class="form-control"  [(ngModel)]="txnId" placeholder="Enter Transaction Number">
        </div>

        <div class="col-lg-12 text-right">
            <div class="mt-0">
                <button [disabled]="!selectedPaymentType" style="margin-top: 10px;" type="button"  class="btn btn-success"
                    (click)="addPayment()">Save</button>
            </div>
        </div>

    </div>
</div>