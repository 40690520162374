import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { GlobalStorageService } from 'src/app/global-storage.service';

@Component({
  selector: 'app-bed-info',
  templateUrl: './bed-info.component.html',
  styleUrls: ['./bed-info.component.css']
})
export class BedInfoComponent implements OnInit {
  bedinfo;bedData;
  @Input() roombedId:any;
  p2=1;
  constructor(private modal:NgbActiveModal,private globalStorage: GlobalStorageService,public clinicServices: ClinicService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.getBedInfo()
  }
  getBedInfo(){
    this.clinicServices.getBedInfo(parseInt(this.globalStorage.getItem('hospitalAddressId')),this.roombedId).subscribe(res=>{
      this.bedData=res['data']
      this.bedinfo=res['data']?.[0]
      var inputDate=moment(this.bedinfo?.startDate).format('YYYY-MM-DD')
      var dateObj = new Date(inputDate);
      var options = { day: "numeric", month: "long", year: "numeric" }as const;
      var formattedDate = dateObj.toLocaleDateString("en-US", options) ;
    },error=>{
      this.toastr.error(error?.['error']?.['message'])
    })
  }
  closeModal(){
    this.modal.close(false)
  }
  changeRoom(){
    this.modal.close(true)
  }
}
