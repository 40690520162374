<div class="modal-header">
    <h4 class="centerHeader mb-0">{{supplierInfo?.name}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
 
    <div>
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th>Category</th>
                    <th>Sub Category</th>
                    <th>Unit Price</th>
                    <th>Deal</th>
                    <th>Status</th>
                    <th>Actions</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let data of categoriesData | paginate: { id: 'p1', itemsPerPage: 10, currentPage: p1 }">
                    <td>{{data?.catagoryname}}</td>
                    <td>{{data?.itemTypeName}}</td>
                    <td>{{data?.unitPrice}}</td>
                    <td>{{data?.deal}}</td>
                    <td *ngIf="data?.active=='Y'"><span class="bg-success p-1">Active</span></td>
                    <td *ngIf="data?.active=='N'"><span class="bg-danger p-1">Inactive</span></td>            
                    <td>
                        <button class="btn btn-success btn-xs btn-icon" data-toggle="tooltip"
                        data-original-title="Place Order" title="Place Order" (click)="openPlaceOrder(data)"
                        *ngIf="data.active=='Y'"><i class="fa fa-user-plus"></i>
                    </button>
                        <button class="btn btn-primary btn-xs btn-icon" data-toggle="tooltip"
                            data-original-title="Edit" title="Edit" (click)="openSaveSupplier(data)"
                            *ngIf="data.active=='Y'"><i class="fa fa-edit"></i></button>
                        <button class="btn btn-success btn-xs btn-icon"
                         (click)="activateSubCategory(data)"
                            *ngIf="data.active=='N'" data-toggle="tooltip"
                            data-original-title="Active"><i
                                class="fa fa-user-check fs-10"></i></button>
                        <button class="btn btn-danger btn-xs"
                             *ngIf="data.active=='Y'" (click)="deactivateSubCatagory(data)"
                            data-toggle="tooltip" data-original-title="Deactivate"><i
                                class="fa fa-trash-o"></i></button>
                    </td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="categoriesData?.length == 0">
            <div class="no-data-found"></div>
        </div>
        <div *ngIf="categoriesData?.length > 10">
            <div class="text-right">
                <ul class="pagination">
                    <pagination-controls autoHide="true" id="p1"
                        (pageChange)="p1=$event"></pagination-controls>
                </ul>
            </div>
        </div>
    </div>
</div>