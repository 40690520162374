<div class="modal-header">
    <h5 class="modal-title">
    Batch Details: <span>{{currentBatchData?.orderBatch?.batchNumber}}</span>
    </h5>
    <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <div class="card bg-light text-dark">
                <div class="card-body ml-2 pt-2">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <h5>Supplier Name: <span class="fs-16 text-success">{{currentBatchData?.supplierName}}</span></h5>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h5>Category: <span class="fs-16 text-success">{{currentBatchData?.categoryName}}</span></h5>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h5>Invoice ID: <span class="fs-16 text-success">{{currentBatchData?.orderBatch?.invoiceId}}</span></h5>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <h5>Sub Category: <span class="fs-16 text-success">{{currentBatchData?.itemTypeName}}</span></h5>
                        </div>
                        <div class="col-lg-6 col-md-6" *ngIf="currentBatchData?.orderBatch?.supplierordersId != null">
                            <h5>Order ID: <span class="fs-16 text-primary">
                                <a href="javascript:void(0)" 
                                (click)="orderInvoice(currentBatchData?.orderBatch)">{{currentBatchData?.orderBatch?.supplierordersId}}</a>
                            </span></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <table class="table table-hover table-striped">
            <thead>
                <tr>
                    <th>Batch Number</th>
                    <th>Total Stock Ordered</th>
                    <th>No of Items Deliver</th>
                    <th>Expected Delivery Date</th>
                    <th>Delivered Date</th>
                    <th>Price</th>
                    <th>Expiry Date</th>
                </tr>
            </thead>

            <tbody>
                <tr>
                    <td>{{currentBatchData?.orderBatch?.batchNumber}}</td>
                    <td>{{currentBatchData?.orderBatch?.totalStockOrdered}}</td>
                    <td>{{currentBatchData?.orderBatch?.noOfitemDeliver}}</td>
                    <td>{{currentBatchData?.orderBatch?.expectedDeliveryDate | date: 'dd-MMM-yyyy'}}</td>
                    <td>{{currentBatchData?.orderBatch?.deliveredDate | date: 'dd-MMM-yyyy'}}</td>
                    <td>{{currentBatchData?.orderBatch?.price | number:'1.2-2'}}</td>
                    <td>{{currentBatchData?.orderBatch?.endDate | date: 'dd-MMM-yyyy'}}</td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="currentBatchData == null">
            <div class="no-data-found"></div>
        </div>
    </div>
</div>