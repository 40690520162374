<!-- Appointments Tabs -->
<div class="card cm-height">
  <div class="card-body">
    <ul class="nav nav-tabs navhos navlistscroll">
      <li class="nav-item">
        <a href="#current" class="medication active" data-toggle="tab" role="tab" aria-expanded="true">Current</a>
      </li>
      <li class="nav-item">
        <a href="#upcoming" data-toggle="tab" role="tab" aria-expanded="true">Upcoming</a>
      </li>
      <li class="nav-item">
        <a href="#previous" data-toggle="tab" role="tab" aria-expanded="true"> Previous</a>
      </li>
      <li class="nav-item">
        <a href="#cancelled" data-toggle="tab" role="tab" aria-expanded="true"> Cancelled</a>
      </li>
      <li class="nav-item">
        <a href="#assigned-patients" class="assigned" data-toggle="tab" role="tab" aria-expanded="true">Assigned
          Patients</a>
      </li>
      <li class="nav-item">
        <a href="#followup" class="followup" data-toggle="tab" role="tab" aria-expanded="true">Followup
          Appointments</a>
      </li>
    </ul>

    <div class="tab-content px-0" id="myTabContent">

      <div class="tab-pane fade show active" id="current" role="tabpanel">
        <div>
          <table class="table table-striped  table-styled table-hover">
            <thead>
              <tr>
                <th>Patient Name<a class="sort-by" href="javascript:void(0)"
                    (click)="onSort('current','patientName')"></a> </th>
                <th>Date / Time<a class="sort-by" href="javascript:void(0)"
                    (click)="onSort('current','appointmentDate')"></a></th>
                <th>Reason</th>
                <th>Rx Fill </th>
                <th>Type</th>

                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="currentApointments.length>0">
                <tr
                  *ngFor="let appointment of currentApointments | paginate: { id: 'p11', itemsPerPage: 10, currentPage: p11 }">

                  <td>
                    <a href="javascript:void(0)" (click)="getPatientDetails(appointment)">{{appointment.patientName |
                      titlecase}} </a>

                  </td>

                  <td>{{appointment.fromTime|convertFrom24To12Format}} {{appointment.appointmentDate | date:
                    'dd-MMM-yyyy'}}
                  </td>

                  <td>{{appointment.ailment}}</td>
                  <td>{{appointment.prescriptionfill}}</td>
                  <td> <i class="fa fa-phone-alt color_yellow"
                      *ngIf="appointment?.chatType=='Telephone' || appointment?.chatType=='AUDIO_CHAT'"
                      aria-hidden="true"></i>

                    <i class="fa fa-comments  color_red"
                      *ngIf="appointment?.chatType=='Chat' || appointment?.chatType=='TEXT_CHAT'"></i>

                    <i class="fa fa-video color_green"
                      *ngIf="appointment?.chatType=='Video' || appointment?.chatType=='VIDEO_CHAT'"
                      aria-hidden="true"></i>
                    <i *ngIf="appointment?.chatType=='Inperson'" class="fa-solid fa-hospital-user color_green"
                      aria-hidden="true"></i>
                  </td>
                  <td>
                    <button class="btn btn-sm mr-1 mt-2 p-1" data-placement="left"
                      [ngClass]="{' visible': presentTime > convertH2M(appointment?.toTime)}" data-toggle="tooltip"
                      data-original-title="Join "
                      (click)="videoCall(appointment,appointment.appointmentDate,appointment.fromTime,appointment.userId)"><i
                        class="fa fa-play-circle themecol4    patientc4" aria-hidden="true"></i></button>


                    <button class="btn btn-sm mr-1 mt-2 p-1"
                      [ngClass]="{' visible': presentTime > convertH2M(appointment?.fromTime)}"
                      (click)="rescheduleAppointment(appointment)" data-placement="left" data-toggle="tooltip"
                      data-original-title="Rechedule "><i class="fa fa-calendar-alt themecol2   patientc2 "
                        aria-hidden="true"></i></button>

                    <button class="btn btn-sm mr-1 mt-2 p-1" (click)="getCompleteInvoiceDetails(appointment)"><i
                        class="fa fa-file-invoice icon-head patientc2" data-placement="left" data-toggle="tooltip"
                        data-original-title="Invoice " aria-hidden="true"></i></button>
                    <button class="btn btn-sm mr-1 mt-2 p-1" (click)="viewNotes(appointment)"><i
                        class="fa-book fa-solid patientc2" data-placement="left" data-toggle="tooltip"
                        data-original-title="Notes " aria-hidden="true"></i></button>
                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="currentApointments.length==0" style="background:#fff">
                <td colspan="6">
                  <div class="no-appointments"></div>
                </td>
              </tr>
            </tbody>
          </table>

          <div *ngIf="currentApointments?.length > 10">
            <div class="text-right">
              <ul class="pagination">
                <pagination-controls autoHide="true" autoHide="true" id="p11" (pageChange)="p11=$event">
                </pagination-controls>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="tab-pane fade" id="upcoming" role="tabpanel">
        <div>
          <table class="table table-striped table-styled table-hover">
            <thead>
              <tr>
                <th>Patient Name<a class="sort-by" href="javascript:void(0)"
                    (click)="onSort('upComing','patientName')"></a> </th>
                <th>Date / Time<a class="sort-by" href="javascript:void(0)"
                    (click)="onSort('upComing','appointmentDate')"></a></th>
                <th>Reason</th>
                <th>Rx Fill </th>
                <th>Type</th>

                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="upComingApointments.length > 0">
                <tr
                  *ngFor="let appointment of upComingApointments | paginate: { id: 'p2', itemsPerPage: 10, currentPage: p2 }">
                  <td>
                    <a href="javascript:void(0)" (click)="getPatientDetails(appointment)">{{appointment.patientName |
                      titlecase}} </a>

                  </td>

                  <td>{{appointment.fromTime|convertFrom24To12Format}} {{appointment.appointmentDate | date:
                    'dd-MMM-yyyy'}}
                  </td>

                  <td>{{appointment.ailment}}</td>
                  <td>{{appointment.prescriptionfill}}</td>
                  <td> <i class="fa fa-phone-alt color_yellow"
                      *ngIf="appointment?.chatType=='Telephone' || appointment?.chatType=='AUDIO_CHAT'"
                      aria-hidden="true"></i>

                    <i class="fa fa-comments  color_red"
                      *ngIf="appointment?.chatType=='Chat' || appointment?.chatType=='TEXT_CHAT'"></i>

                    <i class="fa fa-video color_green"
                      *ngIf="appointment?.chatType=='Video' || appointment?.chatType=='VIDEO_CHAT'"
                      aria-hidden="true"></i>
                    <i *ngIf="appointment?.chatType=='Inperson'" class="fa-solid fa-hospital-user color_green"
                      aria-hidden="true"></i>
                  </td>
                  <td> <button class="btn btn-sm mr-1 mt-2 p-1" (click)="rescheduleAppointment(appointment)"
                      data-placement="top" data-toggle="tooltip" data-original-title="Reschedule"><i
                        class="fa fa-calendar-alt themecol2  patientc2 " aria-hidden="true"></i></button>
                    <button class="btn btn-sm mr-1 mt-2 p-1" (click)="rejectAppointment(appointment)"
                      data-placement="top" data-toggle="tooltip" data-original-title="Cancel"><i
                        class="fa fa-calendar-times themecol4   patientc4" aria-hidden="true"></i></button>
                    <button class="btn btn-sm mr-1 mt-2 p-1" (click)="getCompleteInvoiceDetails(appointment)"
                      data-placement="left" data-toggle="tooltip" data-original-title="Invoice "><i
                        class="fa fa-file-invoice icon-head patientc2" aria-hidden="true"></i></button>
                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="upComingApointments.length==0" style="background:#fff">
                <td colspan="6">
                  <div class="no-appointments"></div>
                </td>
              </tr>
            </tbody>
          </table>

          <div *ngIf="upComingApointments?.length > 10">
            <div class="text-right mt-3">
              <ul class="pagination">
                <pagination-controls autoHide="true" autoHide="true" id="p2" (pageChange)="p2=$event">
                </pagination-controls>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <div class="tab-pane fade" id="previous" role="tabpanel">
        <table class=" table table-striped  table-styled table-hover">
          <thead>
            <tr>
              <th>Patient Name <a class="sort-by" href="javascript:void(0)"
                  (click)="onSort('previous','patientName')"></a></th>
              <th>Date / Time<a class="sort-by" href="javascript:void(0)"
                  (click)="onSort('previous','appointmentDate')"></a></th>
              <th>Reason</th>
              <th>Rx Fill </th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="previousApointments.length > 0">
              <tr
                *ngFor="let appointment of previousApointments | paginate: { id: 'p3', itemsPerPage: 10, currentPage: p3 }">
                <td>
                  <a href="javascript:void(0)" (click)="getPatientDetails(appointment)">{{appointment.patientName |
                    titlecase}} </a>

                </td>

                <td>{{appointment.fromTime|convertFrom24To12Format}} {{appointment.appointmentDate | date:
                  'dd-MMM-yyyy'}}
                </td>

                <td>{{appointment.ailment}}</td>
                <td>{{appointment.prescriptionfill}}</td>
                <td> <i class="fa fa-phone-alt color_yellow"
                    *ngIf="appointment?.chatType=='Telephone' || appointment?.chatType=='AUDIO_CHAT'"
                    aria-hidden="true"></i>

                  <i class="fa fa-comments  color_red"
                    *ngIf="appointment?.chatType=='Chat' || appointment?.chatType=='TEXT_CHAT'"></i>

                  <i class="fa fa-video color_green"
                    *ngIf="appointment?.chatType=='Video' || appointment?.chatType=='VIDEO_CHAT'"
                    aria-hidden="true"></i>
                  <i *ngIf="appointment?.chatType=='Inperson'" class="fa-solid fa-hospital-user color_green"
                    aria-hidden="true"></i>
                </td>
                <td>
                  <button class="btn btn-sm mr-1 mt-2 p-1" (click)="getPatientDetails(appointment)"><i
                      class="fa fa-user themecol2    patientc2 " aria-hidden="true" data-placement="top"
                      data-toggle="tooltip" data-original-title="Patient Details"></i></button>
                  <button class="btn btn-sm mr-1 mt-2 p-1" *ngIf="appointment.prescriptionfill == 'Y'"
                    (click)="prescriptionList(appointment)"><i class="fa fa-file-prescription themecol4   patientc4 "
                      aria-hidden="true" data-placement="top" data-toggle="tooltip" data-target="#myprescription"
                      data-original-title="Prescription"></i></button>
                  <button class="btn btn-sm mr-1 mt-2 p-1" (click)="getCompleteInvoiceDetails(appointment)"
                    data-placement="left" data-toggle="tooltip" data-original-title="Invoice"><i
                      class="fa fa-file-invoice icon-head patientc2" aria-hidden="true"></i></button>
                  <button class="btn btn-sm mr-1 mt-2 p-1" (click)="viewNotes(appointment)"><i
                      class="fa-book fa-solid patientc2" data-placement="left" data-toggle="tooltip"
                      data-original-title="Notes " aria-hidden="true"></i></button>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="previousApointments.length==0" style="background:#fff">
              <td colspan="6">
                <div class="no-appointments"></div>
              </td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="previousApointments?.length > 10">
          <div class="text-right mt-3">
            <ul class="pagination">
              <pagination-controls autoHide="true" autoHide="true" id="p3" (pageChange)="p3=$event">
              </pagination-controls>
            </ul>
          </div>
        </div>

      </div>

      <div class="tab-pane fade" id="cancelled" role="tabpanel">
        <table class=" table table-striped  table-styled table-hover">
          <thead>
            <tr>
              <th>Patient Name <a class="sort-by" href="javascript:void(0)"
                  (click)="onSort('canceled','patientName')"></a></th>
              <th>Date / Time<a class="sort-by" href="javascript:void(0)"
                  (click)="onSort('canceled','appointmentDate')"></a></th>
              <th>Reason</th>

              <th>Rx Fill </th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="canceledApointments.length > 0">
              <tr
                *ngFor="let appointment of canceledApointments | paginate: { id: 'p4', itemsPerPage: 10, currentPage: p4 }">
                <td>
                  {{appointment.patientName}}

                </td>

                <td>{{appointment.fromTime|convertFrom24To12Format}} {{appointment.appointmentDate | date:
                  'dd-MMM-yyyy'}}
                </td>

                <td>{{appointment.ailment}}</td>
                <td>{{appointment.prescriptionfill}}</td>
                <td> <i class="fa fa-phone-alt color_yellow"
                    *ngIf="appointment?.chatType=='Telephone' || appointment?.chatType=='AUDIO_CHAT'"
                    aria-hidden="true"></i>

                  <i class="fa fa-comments  color_red"
                    *ngIf="appointment?.chatType=='Chat' || appointment?.chatType=='TEXT_CHAT'"></i>

                  <i class="fa fa-video color_green"
                    *ngIf="appointment?.chatType=='Video' || appointment?.chatType=='VIDEO_CHAT'"
                    aria-hidden="true"></i>
                  <i *ngIf="appointment?.chatType=='Inperson'" class="fa-solid fa-hospital-user color_green"
                    aria-hidden="true"></i>
                </td>
                <td>
                  <div class="btn-group payment-resize">
                    <button class="btn btn-sm mr-1 mt-2 p-1" (click)="getCompleteInvoiceDetails(appointment)"
                      data-placement="top" data-toggle="tooltip" data-original-title="Invoice "><i
                        class="fa fa-file-invoice icon-head patientc2" aria-hidden="true"></i></button>
                  </div>
                </td>
              </tr>
            </ng-container>
            <tr *ngIf="canceledApointments.length==0" style="background:#fff">
              <td colspan="6">
                <div class="no-appointments"></div>
              </td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="canceledApointments?.length > 10">
          <div class="text-right mt-3">
            <ul class="pagination">
              <pagination-controls autoHide="true" autoHide="true" id="p4" (pageChange)="p4=$event">
              </pagination-controls>
            </ul>
          </div>
        </div>

      </div>

      <div class="tab-pane fade assigned-patients" id="assigned-patients" role="tabpanel">
        <div>
          <table class=" table table-striped  table-styled table-hover">
            <thead>
              <tr>
                <th>Patient Name <a class="sort-by" href="javascript:void(0)"
                    (click)="onSort('assigned','patientName')"></a></th>
                <th>Hospital Name<a class="sort-by" href="javascript:void(0)"
                    (click)="onSort('assigned','hospitalName')"></a></th>
                <th>Assigned Date<a class="sort-by" href="javascript:void(0)" (click)="onSort('assigned','date')"></a>
                </th>
                <th>Rx Fill </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let appointment of assignedPatientList | paginate: { id: 'p5', itemsPerPage: 10, currentPage: p5 }">
                <td>
                  <a href="javascript:void(0)" (click)="getInPatientDetails(appointment)">{{appointment.patientName |
                    titlecase}} </a>

                </td>
                <td>{{appointment.hospitalName}}</td>
                <td>{{appointment.date|date:'dd-MMM-yyyy'}}
                </td>

                <td>N</td>

                <td>
                  <button class="btn btn-sm mr-1 mt-2 p-1" (click)="getInPatientDetails(appointment)"><i
                      class="fa fa-user themecol2    patientc2 " aria-hidden="true" data-placement="top"
                      data-toggle="tooltip" data-original-title="Patient Details"></i></button>
                  <button class="btn btn-sm mr-1 mt-2 p-1" (click)="viewNotesOfCaretaker(appointment)"><i
                      class="fa-book fa-solid patientc2" data-placement="left" data-toggle="tooltip"
                      data-original-title="Notes " aria-hidden="true"></i></button>
                </td>
              </tr>
            </tbody>
          </table>

          <div *ngIf="assignedPatientList?.length == 0">
            <div class="d-flex justify-content-center">
              <img src="./assets/img/no-assigned-patients.jpg" alt="No assigned patients">
            </div>
          </div>

          <div *ngIf="assignedPatientList?.length > 10">
            <div class="text-right mt-3">
              <ul class="pagination">
                <pagination-controls autoHide="true" autoHide="true" id="p5" (pageChange)="p5=$event">
                </pagination-controls>
              </ul>
            </div>
          </div>

        </div>
      </div>

      <div class="tab-pane fade folowup" id="followup" role="tabpanel">
        <div>
          <table class=" table table-striped  table-styled table-hover">
            <thead>
              <tr>
                <th>Patient Name <a class="sort-by" href="javascript:void(0)"
                    (click)="onSort('assigned','patientName')"></a></th>
                <th>Followup Date<a class="sort-by" href="javascript:void(0)" (click)="onSort('assigned','date')"></a>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let appointment of followUpAppointments | paginate: { id: 'p6', itemsPerPage: 10, currentPage: p6 }">
                <td>
                  <a href="javascript:void(0)" (click)="getInPatientDetails(appointment)">{{appointment.patientName |
                    titlecase}} </a>

                </td>
                <td>{{appointment.date|date:'dd-MMM-yyyy'}}
                </td>

                <td>
                  <button class="btn btn-sm mr-1 mt-2 p-1"><i
                      class="fa fa-bell themecol2 patientc2" aria-hidden="true" data-placement="top"
                      data-toggle="tooltip" data-original-title="Notify Patient"></i></button>

                </td>
              </tr>
            </tbody>
          </table>

          <div *ngIf="followUpAppointments?.length == 0">
            <div class="d-flex justify-content-center">
              <img src="./assets/img/no-assigned-patients.jpg" alt="No assigned patients">
            </div>
          </div>

          <div *ngIf="followUpAppointments?.length > 10">
            <div class="text-right mt-3">
              <ul class="pagination">
                <pagination-controls autoHide="true" autoHide="true" id="p6" (pageChange)="p6=$event">
                </pagination-controls>
              </ul>
            </div>
          </div>

        </div>
      </div>


    </div>
  </div>
</div>
<!-- Appointments Tabs End -->

<div class="modal fade modal-right" id="doc-invoice" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Invoice</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="contentToConvertTwo">
        <div class="block inv-section">
          <div class="top-inv-col">
            <div class="inv-logo">
              <img alt="" src="./assets/img/logo.png">
            </div>
            <div class="float-right text-left order-details">
              <p>Order ID: <span>{{docinvoiceData?.invoiceId}}</span></p>
              <p>Date: <span>{{docinvoiceData?.lastUpdatedTs| date:'dd-MMM-yyyy'}}</span></p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-xs-6">
              <h5>Invoice From</h5>
              <ul class="inv-receiver">
                <li class="">Dr. {{docinvoiceData?.address?.contactName |titlecase}}<br>
                  {{docinvoiceData?.address?.street1}}
                  {{docinvoiceData?.address?.street2}} <br> {{docinvoiceData?.address?.city}}
                  <br> {{docinvoiceData?.address?.state}},{{docinvoiceData?.address?.country}} -
                  {{docinvoiceData?.address?.zip}}
                </li>
              </ul>
            </div>
            <div class="col-md-6 col-xs-6">
              <h5>Invoice To</h5>
              <ul class="inv-receiver">
                <li class="">{{docinvoiceData?.patientAddressDTO?.contactName |titlecase}}<br>
                  {{docinvoiceData?.patientAddressDTO?.street1}}
                  {{docinvoiceData?.patientAddressDTO?.street2}} <br> {{docinvoiceData?.patientAddressDTO?.city}}<br>
                  {{docinvoiceData?.patientAddressDTO?.state}},{{docinvoiceData?.patientAddressDTO?.country}} -
                  {{docinvoiceData?.patientAddressDTO?.zip}}
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <div class="payment-method">
                <h5>Payment Method</h5>
                <ul>
                  <li>{{docinvoiceData?.transactiontype}}</li>
                </ul>
              </div>
            </div>
          </div>
          <table class="inv-table table table-striped">
            <tbody>
              <tr>
                <th class="text-align-left">Type of Appointment</th>
                <th class="text-align-center" style="text-align: center;padding-left: 6%;">Total</th>
              </tr>
              <tr>
                <td class="">
                  {{docinvoiceData?.chatType}}
                </td>
                <td class="" style="text-align:right !important;padding-left: 8%;"><span class="inr"><i
                      class="fa fa-inr" aria-hidden="true"></i>&nbsp;</span>{{docinvoiceData?.amount}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td><b>Sub-total:</b></td>
                <td><span class="inr"><i class="fa fa-inr"
                      aria-hidden="true"></i>&nbsp;</span>{{docinvoiceData?.amount}}</td>
              </tr>
              <tr>
                <td><b>Discount:</b></td>
                <td>0</td>
              </tr>
              <tr>
                <td><b>Total Amount:</b></td>
                <td><span class="inr"><i class="fa fa-inr"
                      aria-hidden="true"></i>&nbsp;</span>{{docinvoiceData?.amount}}</td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="invoice-info">
          <button class="btn btn-sm colr-3 ml-2 mt-2 p-2 patientbg3 pull-right" type="submit" (click)="downloadAsPDF()">
            Download Invoice </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade modal-right" id="invoice" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Invoice</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="contentToConvert">
        <div class="block inv-section">
          <div class="top-inv-col">
            <div class="inv-logo">
              <img alt="" src="./assets/img/logo.png">
            </div>
            <div class="float-right text-left order-details">
              <p>Order ID: <span>{{invoiceID}}</span></p>
              <p>Date: <span>{{issuedDate | date:'dd-MMM-yyyy'}}</span></p>
            </div>
          </div>
          <div class="row">


            <div class="col-md-6 col-xs-4">
              <h5>Invoice From</h5>
              <ul class="inv-receiver">
                <li class="">Dr.{{doctorName |titlecase}}<br> {{dstreet1}} {{dstreet2}} {{dcity}}
                  <br> {{dstate}} {{dcountry}} {{dzip}}
                </li>
              </ul>

            </div>
            <div class="col-md-6 col-xs-4 float-right">
              <h5>Invoice To</h5>
              <ul class="inv-receiver">
                <li class="">{{patientName}}<br> {{pstreet1}} {{pstreet2}} {{pcity}}<br> {{pstate}} {{pcountry}}
                  {{pzip}} </li>
              </ul>
            </div>


          </div>
          <div class="payment-method float-left">
            <h5>Payment Method</h5>
            <ul class="m-0">
              <li>{{tType}}</li>
            </ul>
          </div> <br /><br /><br />
          <table class="inv-table table table-striped">
            <tbody>
              <tr>
                <th class="text-align-left">Description</th>
                <th class="text-right">Paid Amount</th>
              </tr>

              <tr class="border-bottom">
                <td>
                  {{aliment}}
                </td>
                <td class="text-align-center float-right"><i class="fa fa-inr"
                    aria-hidden="true"></i>&nbsp;{{paidAmount}}</td>
              </tr>
            </tbody>
            <tfoot>

              <tr *ngIf="appoitmentType == 'Canceled'">
                <td><b>Refund amount</b></td>
                <td><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{paidAmount}}</td>
              </tr>
              <tr *ngIf="appoitmentType != 'Canceled'">
                <td><b>Total amount</b></td>
                <td><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{paidAmount}}</td>
              </tr>

              <!-- <tr>
                          <td colspan="3"><b>Total Amount:</b></td>
                          <td colspan="1"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{paidAmount}}</td>
                      </tr> -->
            </tfoot>
          </table>



          <div class="invoice-info">

            <button (click)="downloadAsPDF()" class="btn btn-primary btn-xs float-right"> <i
                class="fa fa-file-download"></i> Download Invoice</button>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<div class="modal fade modal-right" id="myprescription" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="myappointment" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:45%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Previous Prescription Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">


        <app-prescription-screen [fromAllAppointmentsDoctors]="fromAllAppointmentsDoctors"
          *ngIf="isData"></app-prescription-screen>






      </div>


    </div>
  </div>

</div>
<div class="modal fade modal-right" id="viewNotes" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="viewNotes" aria-hidden="true"
  style=" overflow-y:auto !important; background: none !important;z-index: 999999;">
  <div class="modal-dialog" role="document" style="min-width:35%; ">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notes</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-3">
        <div class="container">
          <div class="row">
            <div class="no-reports" *ngIf="notesData?.length==0"></div>
            <div class="col-lg-12" *ngFor="let note of notesData">
              <div class="notsall">
                <!-- <div class="float-left"><b>From-Time:18:00Hrs</b></div>
                    <div class="float-right"><b>To-Time:19:00Hrs</b></div> -->
                <div>
                  <p>{{note?.notes}}</p>
                </div>

              </div>
            </div>
          </div>
        </div>


      </div>

    </div>
  </div>
</div>