import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr'
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-diagnostic-otp-verification',
  templateUrl: './diagnostic-otp-verification.component.html',
  styleUrls: ['./diagnostic-otp-verification.component.css']
})
export class DiagnosticOtpVerificationComponent implements OnInit {
  otpVerificationForm: FormGroup;
  timerOn = true;
  subscription: Subscription;
  subscribeTimer
  timeLeft: number = 120;
  mbNumber
  invalidCredentials
  successCredentials
  message;
  isTimeOn: boolean = true;
  isTimeOn2: boolean = true;
  constructor(private otpService: AuthService, private routes: Router,
    private toaster: ToastrService, private route: ActivatedRoute, private formBuilder: FormBuilder,) {

    //  this.email=this.route.snapshot.queryParams.verificationId
    //  this.email=this.route.snapshot.queryParams.verificationId
    const navigation = this.routes.getCurrentNavigation();
    const state = navigation.extras.state as {
      emailId: string,
      mobileNumber: string,
      userName: string
    };
    this.otpVerificationForm = this.formBuilder.group({
      userName: ['', Validators.required],
      email: ['', Validators.required],
      phoneNumber: ['', Validators.required],
    })
    this.mbNumber = state.mobileNumber
    this.otpVerificationForm.patchValue({
      email: state.emailId,
      userName: state.userName,
      phoneNumber: state.mobileNumber.replace(/.(?=.{4})/g, 'x').padStart(10, 'x').slice(-10),
    })
  }

  ngOnInit(): void {
    this.observableTimer()
  }

  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }

  OTP
  email; PhOTP; emailVerificationDone = false
  verifyOTP() {

    this.successCredentials = false
    this.invalidCredentials = false

    if (this.OTP == undefined || this.otpVerificationForm.value.email == undefined || this.otpVerificationForm.value.userName == undefined) {
      return
    }

    if (this.OTP == "" || this.otpVerificationForm.value.email == "" || this.otpVerificationForm.value.userName == "") {
      return
    }
    this.otpService.verifyEmailOtpForDiagnosticCenter(this.otpVerificationForm.value.email, this.OTP).subscribe(res => {
      this.toaster.success('Your Email ID is now verified.')
      this.observableTimer2();
      this.emailVerificationDone = true;
      this.verifyNowButton = true;
    }, error => {
      this.invalidCredentials = true
      this.message = error.error.message
      this.toaster.error('The Entered OTP is incorrect')
    })
  }
  verifyPhoneOTP() {
    if (this.PhOTP == undefined || this.otpVerificationForm.value.phoneNumber == undefined) {
      return
    }

    if (this.PhOTP == "" || this.otpVerificationForm.value.phoneNumber == "") {
      return
    }
    this.otpService.verifyPhoneOTPForDiagnosticCenter(this.mbNumber.replace(/\+/gi, '%2B'), this.PhOTP).subscribe(res => {
      this.toaster.success('Your phone number is now verified.')
      this.routes.navigate(['/DiagnosticLogin'])
    }, error => {
      this.toaster.error('Your Phone number not verifed')
    })
  }

  getOtp() {
    this.invalidCredentials = false;
    if (this.otpVerificationForm.value.email == "" || this.otpVerificationForm.value.email == undefined) {
      this.toaster.warning('Enter the Email to send verification Link')
      return
    }
    this.otpService.resendEmailOtpForDiagnosticCenter(this.otpVerificationForm.value.email, this.otpVerificationForm.value.userName).subscribe(res => {
      // this.toaster.success('Check your email for OTP.')
      this.successCredentials = true
      this.message = 'Check your email for OTP.'
      this.observableTimer()
    }, error => {
      this.invalidCredentials = true
      this.message = error?.error?.message
      //this.toaster.error(error['error']['message'])
    })

  }


  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  observableTimer() {
    this.isTimeOn = true
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.successCredentials = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        //this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  observableTimer2() {
    this.isTimeOn2 = true
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer2').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn2 = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.successCredentials = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        //this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }

  getPhoneOtp() {
    if (this.otpVerificationForm.value.phoneNumber == "" || this.otpVerificationForm.value.phoneNumber == undefined) {
      this.toaster.warning('Enter the mobile number to send verification OTP')
      return
    }
    this.otpService.resendPhoneOtpForDiagnosticCenter(this.otpVerificationForm.value.phoneNumber.replace(/\+/gi, '%2B'), this.otpVerificationForm.value.userName).subscribe(res => {
      this.invalidCredentials = false
      this.toaster.success('OTP sent successfully.')
      this.observableTimer2()
    }, error => {
      this.invalidCredentials = true
      //this.message=error.error.message
      //this.toaster.error(error['error']['message'])
    })

  }
  verifyNowButton = true
  otpEntered(value) {
    if (value.length == 6) {
      this.verifyNowButton = false
    }
    else {
      this.verifyNowButton = true
    }
  }

}
