import { GlobalStorageService } from 'src/app/global-storage.service';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Injectable} from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";


@Injectable()
export class AppointmentServices {

  constructor(private globalStorage: GlobalStorageService, private router :Router,private httpClient: HttpClient,){}
  baseURL = environment.doctorBaseUrl;
  patientBaseURL = environment.patientBaseUrl;
  diagnosticBaseURL = environment.diagnosticBaseUrl;

 transactionIdPayu:string;
 hashCodehash:string;
  payUKeykey:string;
  payUFee:string;
  payUFirstName:string;
  aliment:string;
  payUemail:string;
  sUrl :string;
  fUrl:string;
  payUPhone:string ;
  session:any;
  saveDetails(res:any){
      this.transactionIdPayu=res['data']?.['paymentDetail']?.['txnId']
      this.hashCodehash=res['data']?.['paymentDetail']?.['hash']
      this.payUKeykey=res['data']?.['paymentDetail']?.['key']
      this.aliment = res['data']?.['paymentDetail']?.['aliment']
      this.payUFee = res['data']?.['paymentDetail']?.['amount']
      this.payUemail = res['data']?.['paymentDetail']?.['email']
      this.payUFirstName = res['data']?.['paymentDetail']?.['name']
      this.session=this.globalStorage.getItem('userSession')
      this.sUrl=res['data']?.['paymentDetail']?.sUrl
      this.fUrl=res['data']?.['paymentDetail']?.fUrl
      this.payUPhone = res['data']?.['paymentDetail']?.['phone']
  }

  getdata(){
    var data = {
      "transId": this.transactionIdPayu,
      "hashcode": this.hashCodehash,
      "key" : this.payUKeykey,
      "amount":this.payUFee,
      "aliment":this.aliment,
      "email":this.payUemail,
      "sUrl":this.sUrl,
      "fUrl":this.fUrl,
      "phone":this.payUPhone,
      "name":this.payUFirstName

    }
    return data;
  }

  payUPay(data:any){
    return this.httpClient.post('https://secure.payu.in/_payment',data,{headers: new HttpHeaders({ "Content-Type": "multipart/form-data"})} )
  }

}


