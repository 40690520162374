import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';

@Component({
  selector: 'app-image-prescription',
  templateUrl: './image-prescription.component.html',
  styleUrls: ['./image-prescription.component.css']
})
export class ImagePrescriptionComponent implements OnInit {
@Input() patientPersonalDetails :any;
  constructor(private globalStorage: GlobalStorageService, private toastrService:ToastrService,private controller: PatientScheduleControlService,public activeModal:NgbActiveModal) { }

  ngOnInit(): void {
    function readUrl(input) {

      if (input.files && input.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          let imgData = e.target.result;
          let imgName = input.files[0].name;
          input.setAttribute("data-title", imgName);
        }
        reader.readAsDataURL(input.files[0]);
      }

    }
    
  }
  containsMedicalPrescription:any;
   containsTestPrescription:any;file:any
  isMedChecked:boolean
  isLabChecked:boolean
  onFileInput(files: File) {
    const f = files
    if (this.isMedChecked && this.isLabChecked) {
      this.containsMedicalPrescription = "Y"
      this.containsTestPrescription = "Y"
    }else  if (this.isMedChecked) {
      this.containsMedicalPrescription = "Y"
      this.containsTestPrescription = "N"
    }else  if (this.isLabChecked) {
      this.containsMedicalPrescription = "N"
      this.containsTestPrescription = "Y"
    }
   
    if ((f[0].type) == "image/png" || (f[0].type) == "image/jpg" || (f[0].type) == "image/jpeg" || (f[0].type) == "application/pdf") {
      if (f[0].size > 1048769) {
        this.toastrService.warning('file should be 1 Mb')
      } else {
        this.file = files
      }
    } else {
      this.toastrService.warning('file should be in jpeg or PNG')
    }

  }
  savePrescriptionImage() {
    var data = JSON.parse(this.globalStorage.getItem('LoginDetails'));
   var loginUser = data?.data;
    var body = {
      "appointmentFor": "DOCTOR",
      "containsMedicalPrescription": this.containsMedicalPrescription,
      "containsTestPrescription": this.containsTestPrescription,
      "appointmentDate": this.patientPersonalDetails?.AppointmentDetails?.appointmentDate,
      "doctorAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
      "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
      "medicalPrescritionFor": "IMAGE",
      // "medicalprescription":{"patientId": this.patientPersonalDetails['AppointmentDetails']['userId']},
      "patientId": this.patientPersonalDetails['AppointmentDetails']['userId'],
      "doctorId": this.globalStorage.getItem('userId'),
      "doctorName": !this.patientPersonalDetails['AppointmentDetails']['doctorName']?loginUser.firstName + ' ' + loginUser.lastName:this.patientPersonalDetails['AppointmentDetails']['doctorName']
    }
    if (this.containsMedicalPrescription == 'Y') {
      body['medicalprescription'] = { "patientId": this.patientPersonalDetails['AppointmentDetails']['userId'] }
    }
    if (this.containsTestPrescription == 'Y') {
      body['medicaltestprescription'] = { "patientId": this.patientPersonalDetails['AppointmentDetails']['userId'] }
    }
    var formData = new FormData();
    if (this.file != undefined) {
      for (let i = 0; i < ( this.file.length); i++) {
        formData.append('prescriptionPic', this.file[i], this.file[i].name);
      }
    }
    formData.append('userprescriptionJson', JSON.stringify(body));
    formData.append('forUserId', JSON.stringify(this.patientPersonalDetails['AppointmentDetails']['userId']));
    if (this.globalStorage.getItem('loggedInAs')=="HOSPITAL") {
      this.controller.sendPrescriptionImage(formData, this.patientPersonalDetails.userId).subscribe(res => {
        this.file = []
        this.toastrService.success('Prescription sent successfully')
        this.activeModal.close()
      }, error => {
        this.toastrService.error(error['error']['message'])
      })
    }
    else{this.controller.sendPrescriptionImage(formData, this.patientPersonalDetails.userId).subscribe(res => {
      this.file = []
      this.toastrService.success('Prescription sent successfully')
      this.activeModal.close()
    }, error => {
      this.toastrService.error(error['error']['message'])
    })}
  }
}
