<div class="skeleton-loader">
  <ng-container *ngFor="let row of rows">
    <!-- Circle Shape -->
    <div *ngIf="shape === 'circle'" class="skeleton-circle shimmer"></div>
    
    <div *ngIf="shape === 'square'" class="skeleton-square shimmer"></div>
    <div *ngIf="shape === 'square'" class="skeleton-line shimmer"></div>

    <!-- Line Shape -->
    <div *ngIf="shape === 'line'" class="skeleton-line shimmer"></div>
    
    <!-- Card Shape -->
    <div *ngIf="shape === 'card'" class="skeleton-card">
      <div class="skeleton-circle shimmer"></div>
      <div class="skeleton-card-content">
        <div class="skeleton-line card-line shimmer" *ngFor="let line of [1,2,3]"></div>
      </div>
    </div>
  </ng-container>
</div>
