import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {DoctorService} from '../../AppServices/doctor.service';
import { ToastrService } from 'ngx-toastr';
import {MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { MatPaginator, MatSidenav, MatSort, MatTableDataSource } from '@angular/material';
import { BlockScrollStrategy } from '@angular/cdk/overlay';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { interval, Subscription } from 'rxjs';
declare var jQuery: any;
declare var $: any;
@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.css']
})
export class AppointmentsComponent implements OnInit {

  frmAdvanceSearch:FormGroup
  patient
  vitals
  PatientDetails

  userId
  referenceEntityName
  appointemntDate
  appointmentTime
  contactNumber
  upcomingAppointments
  previousAppointments
  todayAppointments
  showToday:boolean=false;
  showUpcoming:boolean =false;Ks
  showPrevious:boolean =false;
  showAll:boolean =false;
  showCancel:boolean =false;
  showCanceled:boolean =false;
  showGLOBAL:boolean =false;

  IsshowAll:boolean =false;
  IsshowToday:boolean=false;
  IsshowUpcoming:boolean =false;
  IsshowPrevious:boolean =false;
  IsshowCanceled:boolean =false;

  ailment
  amount
  isPatientList=false
  constructor(private appointment:PatientScheduleControlService,private toaster:ToastrService,
    private doctorService:DoctorService,private patientService:DoctorPatientService,
    private toastrService:ToastrService,private matDialog:MatDialog,private fb :FormBuilder,private router:Router,) {

   }

 

  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild(MatSort,{static:false}) sort: MatSort;
  advanceSearch:boolean=false;
  maxDate
  presentTime;subscription: Subscription;
  public TabIndex = 0;
  ngOnInit(): void {
    var toDay=new Date()
    var today = moment(toDay).format('YYYY-MM-DD');
    var date:any =new Date()
    var hours = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  minutes = minutes < 10 ? '0'+minutes : minutes;
  seconds = seconds < 10 ? '0'+seconds : seconds;
  var strTime = hours + ':' + minutes + ':' + seconds
      const source = interval(1000);
      const text = strTime;
      this.subscription = source.subscribe(val => this.updater(text));
      this.showAllAp();
      if(this.router.url=='/PatientsList'){
      this.isPatientList=true
      } 
      if(this.router.url=='/assigned-Appoinments'){
        
      }
  }

  convertH2M(timeInHour){
    var timeParts = timeInHour.split(":");
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
  }
  updater(time){
    this.presentTime=this.convertH2M(time)
    }
@ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;

public scrollRight(): void {
  this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 250), behavior: 'smooth' });
}

public scrollLeft(): void {
  this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 250), behavior: 'smooth' });
}

showTodayAp(){
this.showToday=true
this.showUpcoming=false
this.showPrevious=false
this.showCanceled=false
this.showAll=false


this.IsshowAll=false;
this.IsshowToday=true;
this.IsshowUpcoming=false;
this.IsshowPrevious=false;
this.IsshowCanceled=false;
this.IsSearch=false;

//this.LoadCurent();
}
showUpcomingAp(){
  this.showUpcoming=true
  this.showPrevious=false
  this.showToday=false
  this.showCanceled=false
  this.showAll=false

  this.IsshowAll=false;
this.IsshowToday=false;
this.IsshowUpcoming=true;
this.IsshowPrevious=false;
this.IsshowCanceled=false;
this.IsSearch=false;
  //this.LoadUpcoming();
}
showPreviousAp(){
  this.showPrevious=true
  this.showToday=false
   this.showUpcoming=false
   this.showCanceled=false
   this.showAll=false

   this.IsshowAll=false;
   this.IsshowToday=false;
   this.IsshowUpcoming=false;
   this.IsshowPrevious=true;
   this.IsshowCanceled=false;
   this.IsSearch=false;
  // this.LoadPrevious()
}
showCanceledAp(){
  this.showPrevious=false
  this.showToday=false
   this.showUpcoming=false
   this.showCanceled=true
   this.showAll=false

   this.IsshowAll=false;
   this.IsshowToday=false;
   this.IsshowUpcoming=false;
   this.IsshowPrevious=false;
   this.IsshowCanceled=true;
   this.IsSearch=false;
}
showAllAp(){

 // this.IsshowAll=true
 // this.showPrevious=true
  this.showAll=true
  this.showToday=true
  this.showUpcoming=true
  this.showPrevious=true
  this.showCanceled=true
  this.IsSearch=false;

  this.IsshowAll=true;
  this.IsshowToday=false;
  this.IsshowUpcoming=false;
  this.IsshowPrevious=false;
  this.IsshowCanceled=false;
  this.IsSearch=false;
}
IsSearch:boolean=false
Search(){
  this.showAll=false
  this.showToday=false
  this.showUpcoming=false
  this.showPrevious=false
  this.showCanceled=false



  this.IsshowAll=false;
  this.IsshowToday=false;
  this.IsshowUpcoming=false;
  this.IsshowPrevious=false;
  this.IsshowCanceled=false;
 this.IsSearch=true;
}
 
}
