import { GlobalStorageService } from 'src/app/global-storage.service';
import { formatDate } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { PaymentInfoComponent } from '../payment-info/payment-info.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-appointment-booking-new',
  templateUrl: './appointment-booking.component.html',
  styleUrls: ['./appointment-booking.component.css']
})
export class AppointmentBookingComponentNew implements OnInit {
  @Input() doctorDetails: any;
  @Input() patientDetails:any;
  reasonForm: FormGroup;
  currentMonth: string = "";
  // currentYear : string = "";
  isClinicOrIvf: boolean = false;
  morningHours: any[] = [];
  afternoonHours: any[] = [];
  eveningHours: any[] = [];
  selectedItem: any = moment(new Date()).format('YYYY-MM-DD');;
  typeOfAppointmentList: any[];
  reason
  doctorName: any
  specializ: any
  ttlExperience: any;
  currentYear: any;
  module: any;
  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder, private doctorSearch: PatientScheduleControlService,
     private clinicServices: ClinicService, private ivfService: IvfService, public activeModal: NgbActiveModal,
     public modalService:NgbModal, private toaster:ToastrService) { }

  ngOnInit(): void {
    console.log(this.doctorDetails,"Doctor Details \n",this.patientDetails,"Patient Details");
    this.typeOfAppointmentList = this.doctorDetails?.hospitalfeedetails;
    this.module = this.globalStorage.getItem('loggedInAs');
    if (this.module == "IVF") {
      this.isClinicOrIvf = true;
      this.typeselected=true;
    }
    this.reasonForm = this.formBuilder.group({
      reason: ['', Validators.required]
    })
    this.doctorName = this.doctorDetails?.firstName + ' ' + this.doctorDetails?.lastName;
    this.specializ = this.doctorDetails['specialization'];
    this.ttlExperience = this.doctorDetails['totalExperience'];
    this.getDoctorBaseschedule(this.doctorDetails)
    var d = new Date()
    this.currentYear = d.getFullYear()
    this.items = this.getDates(
      new Date(),
      new Date("2223-12-30T14:34:19.579Z")
    );
    this.currentMonth = new Date(this.currentDate).toLocaleString("default",
      {
        month: "long"
      });

      var toDay = new Date()
      var today = moment(toDay).format('YYYY-MM-DD');
      var date: any = new Date()
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds()
      var ampm = hours >= 12 ? 'PM' : 'AM';
      // hours = hours % 12;
      // hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      this.strTime = hours + ':' + minutes + ':' + seconds
  }


  isIvf(){
    if(this.module == "IVF"){
      return true;
    }
    else{
      return false;
    }
  }
  submittedReason: string = ""
  get r() { return this.reasonForm.controls; }
  booknowEnabled: boolean = false;
  reasonEnter(data) {
    if (data) {

      this.booknowEnabled = true
    }
    else {
      this.booknowEnabled = false
    }
  }
  @ViewChild('widgetsContent', { read: ElementRef }) public widgetsContent: ElementRef<any>;
  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 250), behavior: 'smooth' });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 250), behavior: 'smooth' });
  }
  today: any;
  ar: any[] = [];
  strTime: any;
  typeofAppointment: any;
  fee: any;
  sceduleHoursRecorded: any[] = []
  daysSelected: any[] = []
  dateObject: any;
  //   select(item: any) {
  //     const toDay = moment(new Date()).format('YYYY-MM-DD');
  //     this.today = toDay;
  //     this.ar = [];

  //     this.selectedItem = item;
  //     this.morningHours = this.filterAndSortHours("00:00:00", "11:30:00", this.morningHours);
  //     this.afternoonHours = this.filterAndSortHours("12:00:00", "15:30:00", this.afternoonHours);
  //     this.eveningHours = this.filterAndSortHours("16:00:00", "23:30:00", this.eveningHours);
  // }


  timeStringToSeconds(timeStr) {
    const parts = timeStr.split(':');
    return parseInt(parts[0], 10) * 3600 + parseInt(parts[1], 10) * 60 + parseInt(parts[2], 10);
}
  select(item: any) {
    this.changeTab(this.tabs[0].title)
    var toDay = new Date()
    this.today = moment(toDay).format('YYYY-MM-DD');
    this.ar.length = 0
    this.selectedItem = item;
    this.morningHours.length = 0;
    this.afternoonHours.length = 0;
    this.eveningHours.length = 0;
    for (var i = 0; i < this.daysSelected.length; i++) {
      if (formatDate(this.daysSelected[i]['scheduleDate'], "yyyy-MM-dd", 'en-us') === this.selectedItem) {
        this.ar.push(...this.daysSelected[i].scheduleHours)

      }
    }
    this.sceduleHoursRecorded = this.ar
    for (var i = 0; i < this.sceduleHoursRecorded.length; i++) {
      if (item == this.today) {
        if (this.sceduleHoursRecorded[i].fromTime >= "00:00:00" && this.sceduleHoursRecorded[i].fromTime <= "11:30:00" && this.timeStringToSeconds(this.strTime) < this.timeStringToSeconds(this.sceduleHoursRecorded[i].fromTime)) {

          this.morningHours.push(this.sceduleHoursRecorded[i])
          this.morningHours.push(this.sceduleHoursRecorded[i]['associateEntityName'])
          this.morningHours = this.morningHours?.filter(obj => obj?.scheduleStatus == "AVAILABLE")
          this.morningHours.sort(function (a, b) {
            return a.fromTime?.localeCompare(b.fromTime);
          });
        } else if (this.sceduleHoursRecorded[i].fromTime >= "12:00:00" && this.sceduleHoursRecorded[i].fromTime <= "15:30:00" && this.timeStringToSeconds(this.strTime) < this.timeStringToSeconds(this.sceduleHoursRecorded[i].fromTime)) {
          this.afternoonHours.push(this.sceduleHoursRecorded[i])
          this.afternoonHours = this.afternoonHours?.filter(obj => obj?.scheduleStatus == "AVAILABLE")
          this.afternoonHours.sort(function (a, b) {
            return a.fromTime?.localeCompare(b.fromTime);
          });
        } else if (this.sceduleHoursRecorded[i].fromTime >= "16:00:00" && this.sceduleHoursRecorded[i].fromTime <= "23:30:00" && this.timeStringToSeconds(this.strTime) < this.timeStringToSeconds(this.sceduleHoursRecorded[i].fromTime)) {
          this.eveningHours.push(this.sceduleHoursRecorded[i])
          this.eveningHours = this.eveningHours?.filter(obj => obj?.scheduleStatus == "AVAILABLE")
          this.eveningHours.sort(function (a, b) {
            return a.fromTime?.localeCompare(b.fromTime);
          });
        }
      }
      else {
        if (this.sceduleHoursRecorded[i].fromTime >= "00:00:00" && this.sceduleHoursRecorded[i].fromTime <= "11:30:00") {

          this.morningHours.push(this.sceduleHoursRecorded[i])
          this.morningHours = this.morningHours?.filter(obj => obj?.scheduleStatus == "AVAILABLE")
        } else if (this.sceduleHoursRecorded[i].fromTime >= "12:00:00" && this.sceduleHoursRecorded[i].fromTime <= "15:30:00") {
          this.afternoonHours.push(this.sceduleHoursRecorded[i])
          this.afternoonHours = this.afternoonHours?.filter(obj => obj?.scheduleStatus == "AVAILABLE")
        } else if (this.sceduleHoursRecorded[i].fromTime >= "16:00:00" && this.sceduleHoursRecorded[i].fromTime <= "23:30:00") {
          this.eveningHours.push(this.sceduleHoursRecorded[i])
          this.eveningHours = this.eveningHours?.filter(obj => obj?.scheduleStatus == "AVAILABLE")
        }
      }

    }
    // console.log(this.morningHours, "morning");
    // console.log(this.eveningHours, " evening ");
    // console.log(this.afternoonHours, " afternoon");
    if (this.isClinicOrIvf) {
      this.morningHours = this.morningHours?.filter(obj => obj?.appointmentType == 'Inperson' && obj?.scheduleStatus == 'AVAILABLE')
    this.afternoonHours = this.afternoonHours?.filter(obj => obj?.appointmentType == 'Inperson' && obj?.scheduleStatus == 'AVAILABLE')
    this.eveningHours = this.eveningHours?.filter(obj => obj?.appointmentType == 'Inperson' && obj?.scheduleStatus == 'AVAILABLE')

    }
  }
  changeTab(item) {
    switch (item) {
      case 'home':
        $('#home').addClass('active show')
        $('#profile').removeClass('active show')
        $('#contact').removeClass('active show')
        break;
      case 'profile':
        $('#home').removeClass('active show')
        $('#profile').addClass('active show')
        $('#contact').removeClass('active show')
        break;
      case 'contact':
        $('#home').removeClass('active show')
        $('#profile').removeClass('active show')
        $('#contact').addClass('active show')
        break;
      default:
        break;
    }
  }
  filterAndSortHours(startTime: string, endTime: string, hoursArray: any[]) {
    const filteredHours = this.sceduleHoursRecorded.filter(hour =>
      (this.today === this.selectedItem && this.strTime < hour.fromTime) ||
      (this.today !== this.selectedItem)
    );

    const result = filteredHours.filter(hour =>
      hour.fromTime >= startTime && hour.fromTime <= endTime
    ).filter(obj =>
      obj.appointmentType == this.typeofAppointment && obj.scheduleStatus == "AVAILABLE"
    ).sort((a, b) =>
      a.fromTime.localeCompare(b.fromTime)
    );

    hoursArray.push(...result);
    if (this.today === this.selectedItem) {
      hoursArray.push(result.map(item => item.associateEntityName));
    }

    return hoursArray;
  }

  returnWeekDay(item: any) {
    // console.log(item,"returnWeekDay");

    return new Date(item).toLocaleDateString("default", { weekday: "short" });
  }
  getDates(startDate: any, stopDate: any) {
    let dateArray = [];
    let currentDate = moment(startDate);
    stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY-MM-DD"));

      currentDate = moment(currentDate).add(1, "days");
    }
    return dateArray;
  }
  getDoctorBaseschedule(doctorDetails) {
    this.morningHours.length = 0
    this.eveningHours.length = 0
    this.afternoonHours.length = 0
    this.ar.length = 0
    this.daysSelected.length = 0


    var dateS = new Date(this.selectedItem)
    // var request = {
    //   "doctorId": doctorId,

    //   "fromDate": formatDate(this.selectedItem, "yyyy-MM-dd", 'en-us'),

    //   "toDate": formatDate((dateS.setMonth(dateS.getMonth() + 1)), "yyyy-MM-dd", 'en-us')
    // }

    if (this.module == "IVF") {
      this.ivfService.getDoctorSlots(this.globalStorage.getItem('ivfAddressId'), doctorDetails?.referenceentityId).subscribe(res => {
        this.dateObject = res['data']
        this.sceduleDate(this.dateObject)
      })
    } else {
      this.clinicServices.getDoctorSlots(doctorDetails?.doctorReferenceId).subscribe(res => {
        this.dateObject = res['data'];
        this.sceduleDate(this.dateObject)
      })
    }


  }
  sceduleDate(item: any) {
    for (var i = 0; i < item.length; i++) {
      this.daysSelected.push(item[i])
    }
    this.select(this.selectedItem)
  }
  currentDate: any;
  items: any[] = [];
  changeMonth(e: any) {

    this.currentDate = this.items[e];

    this.currentMonth = new Date(this.currentDate).toLocaleString("default",
      {
        month: "long"
      });
  }
typeselected:boolean=false;
  typeOfAppointment(cls, data, price) {this.typeselected=true;
    this.morningHours = []
    this.afternoonHours = []
    this.eveningHours = []
    this.typeofAppointment = data
    if (cls == 1) {
      $('.phone').addClass("type-select");
      $('.comments').removeClass("type-select");
      $('.video').removeClass("type-select");
      $('.person').removeClass("type-select");

      // type of appointment
      this.select(this.selectedItem)
      this.morningHours = this.morningHours?.filter(obj => obj?.appointmentType == data && obj?.scheduleStatus == 'AVAILABLE')
      this.afternoonHours = this.afternoonHours?.filter(obj => obj?.appointmentType == data && obj?.scheduleStatus == 'AVAILABLE')
      this.eveningHours = this.eveningHours?.filter(obj => obj?.appointmentType == data && obj?.scheduleStatus == 'AVAILABLE')
      // type of appointment
    }
    if (cls == 2) {
      this.morningHours = []
      this.afternoonHours = []
      this.eveningHours = []
      $('.comments').addClass("type-select");
      $('.phone').removeClass("type-select");
      $('.video').removeClass("type-select"); $('.person').removeClass("type-select");
      // type of appointment
      this.select(this.selectedItem)
      this.morningHours = this.morningHours?.filter(obj => obj?.appointmentType == data && obj?.scheduleStatus == 'AVAILABLE')
      this.afternoonHours = this.afternoonHours?.filter(obj => obj?.appointmentType == data && obj?.scheduleStatus == 'AVAILABLE')
      this.eveningHours = this.eveningHours?.filter(obj => obj?.appointmentType == data && obj?.scheduleStatus == 'AVAILABLE')
      // type of appointment
    }
    if (cls == 3) {
      this.morningHours = []
      this.afternoonHours = []
      this.eveningHours = []
      $('.video').addClass("type-select");
      $('.phone').removeClass("type-select");
      $('.comments').removeClass("type-select");
      $('.person').removeClass("type-select");
      // type of appointment
      this.select(this.selectedItem)
      this.morningHours = this.morningHours?.filter(obj => obj?.appointmentType == data && obj?.scheduleStatus == 'AVAILABLE')
      this.afternoonHours = this.afternoonHours?.filter(obj => obj?.appointmentType == data && obj?.scheduleStatus == 'AVAILABLE')
      this.eveningHours = this.eveningHours?.filter(obj => obj?.appointmentType == data && obj?.scheduleStatus == 'AVAILABLE')
      // type of appointment
    } if (cls == 4) {
      this.morningHours = []
      this.afternoonHours = []
      this.eveningHours = []
      $('.person').addClass("type-select");
      $('.phone').removeClass("type-select");
      $('.comments').removeClass("type-select");
      $('.video').removeClass("type-select");
      // type of appointment
      this.select(this.selectedItem)
      this.morningHours = this.morningHours?.filter(obj => obj?.appointmentType == data && obj?.scheduleStatus == 'AVAILABLE')
      this.afternoonHours = this.afternoonHours?.filter(obj => obj?.appointmentType == data && obj?.scheduleStatus == 'AVAILABLE')
      this.eveningHours = this.eveningHours?.filter(obj => obj?.appointmentType == data && obj?.scheduleStatus == 'AVAILABLE')
      // type of appointment
    }

    this.typeofAppointment = data
    this.fee = price
  }
  closeMyBooking() { }

  openModel(item: any) {
    // this.submittedReason = true
    if (this.reasonForm.value.reason == "" || this.reasonForm.value.reason == undefined ) {
      this.reasonForm.controls['reason'].markAsTouched()
    }
    else {
      this.typeselected= false
      // this.showModalToPay = true
      // this.checkAgree = false;
      // this.paymentModal = item
      // this.fromTime = this.paymentModal['fromTime'].slice(0, -3),
      //   this.toTime = this.paymentModal['toTime'].slice(0, -3)
      // this.typeofConsultation = this.typeOfAppointment
      // this.reason = this.reasonForm.value.reason
      // $('#appointmentDetailsLeft').modal('show')
      let classW= 'modal fade modal-left hw-50'

      console.log(item,"Items When Selecting the slot",this.patientDetails,"PatientDetails")
      if (this.module == "IVF") {
        classW= 'left w-40'
      }

      const paymentInfo = this.modalService.open(PaymentInfoComponent,{
        windowClass: classW,
        backdrop: 'static'
      })

      paymentInfo.result.then(
        (result) => {
            this.activeModal.close()
        },
        (reason) => {
          // this.activeModal.close()
        }
      );

      console.log(this.doctorDetails,"Doctor Details")
      paymentInfo.componentInstance.patientDetails = this.patientDetails
      paymentInfo.componentInstance.scheduleHoursId = item?.scheduleHoursId
      paymentInfo.componentInstance.docID = this.doctorDetails?.doctorReferenceId || this.doctorDetails?.referenceentityId
      paymentInfo.componentInstance.fromTime = item?.fromTime.slice(0, -3)
      paymentInfo.componentInstance.toTime = item?.toTime.slice(0, -3)
      paymentInfo.componentInstance.reason = this.reasonForm.value.reason
      paymentInfo.componentInstance.fee = this.doctorDetails?.hospitalfeedetails?.[0]?.fee ||  this.doctorDetails?.feedetails?.[0]?.fee
      paymentInfo.componentInstance.doctorName = this.doctorDetails?.firstName + ' ' + this.doctorDetails?.lastName
      paymentInfo.componentInstance.gender = this.doctorDetails?.gender
      paymentInfo.componentInstance.typeOfAppointment = item?.appointmentType
      paymentInfo.componentInstance.specializ = this.doctorDetails?.specialization
      paymentInfo.componentInstance.exp = this.doctorDetails?.totalExperience
      paymentInfo.componentInstance.rating = this.doctorDetails?.averagerating || this.doctorDetails?.avgRating
    paymentInfo.componentInstance.selectedItem = this.selectedItem
    }
  }

  activeTab = 'Morning';

  tabs = [
    { title: 'Morning', icon: 'fas fa-cloud-sun' },
    { title: 'Afternoon', icon: 'fas fa-cloud-moon' },
    { title: 'Evening', icon: 'fas fa-moon' }
  ];

  setActiveTab(tabTitle: string) {
    this.activeTab = tabTitle;
  }
}
