<div class="modal-header">
    <h5 class="modal-title">
        <i aria-hidden="true" class="fas fa-shipping-fast"></i> Dispatch
    </h5>
    <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="dispatchForm">
        <div class="row">
            <div class="col-lg-6 col-md-6 mb-3">
                <div class="form-group">
                    <label>Requested By <span class="text-danger">*</span></label>
                    <input type="text" class="form-control text-capitalize" formControlName="dispatchRequestedBy" 
                    [ngClass]="{ 'is-invalid': (submitted || dispatchFormControls.dispatchRequestedBy.touched) && dispatchFormControls.dispatchRequestedBy.errors}">

                    <div *ngIf="(submitted || dispatchFormControls.dispatchRequestedBy.touched) && dispatchFormControls.dispatchRequestedBy.errors"
                        class="invalid-feedback">
                        <div *ngIf="dispatchFormControls.dispatchRequestedBy.errors.required">
                           This field is required.</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 mb-3">
                <div class="form-group">
                    <label>Requested To <span class="text-danger">*</span></label> 
                    <input type="text" class="form-control text-capitalize" formControlName="dispatchRequestedTo"
                    [ngClass]="{ 'is-invalid': (submitted || dispatchFormControls.dispatchRequestedTo.touched) && dispatchFormControls.dispatchRequestedTo.errors}">

                    <div *ngIf="(submitted || dispatchFormControls.dispatchRequestedTo.touched) && dispatchFormControls.dispatchRequestedTo.errors"
                        class="invalid-feedback">
                        <div *ngIf="dispatchFormControls.dispatchRequestedTo.errors.required">
                           This field is required.</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 mb-3">
                <div class="form-group">
                    <label>Requested On <span class="text-danger">*</span></label>
                    <input matInput [matDatepicker]="requestedDate" class="form-control" formControlName="dispatchRequestedOn"
                            (keypress)="requestedDate.open()" (keyup)="requestedDate.open()"
                            (click)="requestedDate.open()" [min]="minDate"
                    [ngClass]="{ 'is-invalid': (submitted || dispatchFormControls.dispatchRequestedOn.touched) && dispatchFormControls.dispatchRequestedOn.errors}">

                    <div *ngIf="(submitted || dispatchFormControls.dispatchRequestedOn.touched) && dispatchFormControls.dispatchRequestedOn.errors"
                        class="invalid-feedback">
                        <div *ngIf="dispatchFormControls.dispatchRequestedOn.errors.required">
                           Requested Date is required.</div>
                    </div>
                    
                    <mat-datepicker-toggle matIconSuffix [for]="requestedDate"
                        style="position: absolute;top: 30px;right: 10px;"></mat-datepicker-toggle>
                    <mat-datepicker #requestedDate></mat-datepicker>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 mb-3">
                <div class="form-group">
                    <label>Dispatched By <span class="text-danger">*</span></label>
                    <input type="text" class="form-control text-capitalize" formControlName="dispatchedBy"

                    [ngClass]="{ 'is-invalid': (submitted || dispatchFormControls.dispatchedBy.touched) && dispatchFormControls.dispatchedBy.errors}">

                    <div *ngIf="(submitted || dispatchFormControls.dispatchedBy.touched) && dispatchFormControls.dispatchedBy.errors"
                        class="invalid-feedback">
                        <div *ngIf="dispatchFormControls.dispatchedBy.errors.required">
                           This field is required.</div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 mb-3">
                <div class="form-group">
                    <label>Dispatched To <span class="text-danger">*</span></label>
                    <input type="text" class="form-control text-capitalize" formControlName="dispatchedTo"

                    [ngClass]="{ 'is-invalid': (submitted || dispatchFormControls.dispatchedTo.touched) && dispatchFormControls.dispatchedTo.errors}">

                    <div *ngIf="(submitted || dispatchFormControls.dispatchedTo.touched) && dispatchFormControls.dispatchedTo.errors"
                        class="invalid-feedback">
                        <div *ngIf="dispatchFormControls.dispatchedTo.errors.required">
                           This field is required.</div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-6 col-md-6 mb-3">
                <div class="form-group">
                    <label>Dispatched On <span class="text-danger">*</span></label>
                    <input matInput [matDatepicker]="dispatchedDate" class="form-control" formControlName="dispachedOn"
                            (keypress)="dispatchedDate.open()" (keyup)="dispatchedDate.open()"
                            (click)="dispatchedDate.open()"
                    
                  [ngClass]="{ 'is-invalid': (submitted || dispatchFormControls.dispachedOn.touched) && dispatchFormControls.dispachedOn.errors}">

                    <div *ngIf="(submitted || dispatchFormControls.dispachedOn.touched) && dispatchFormControls.dispachedOn.errors"
                        class="invalid-feedback">
                        <div *ngIf="dispatchFormControls.dispachedOn.errors.required">
                          Dispatched Date is required.</div>
                    </div>

                    <mat-datepicker-toggle matIconSuffix [for]="dispatchedDate"
                        style="position: absolute;top: 30px;right: 10px;"></mat-datepicker-toggle>
                    <mat-datepicker #dispatchedDate></mat-datepicker>
                </div>
            </div> -->

            <div class="col-lg-6 col-md-6 mb-3">
                <div class="form-group">
                    <label>Quantity <span class="text-danger">*</span></label>
                    <input type="text" class="form-control text-capitalize" formControlName="qtyDispatched" appInputRestrict="decimalOnly" maxlength="5"

                    [ngClass]="{ 'is-invalid': (submitted || dispatchFormControls.qtyDispatched.touched) && dispatchFormControls.qtyDispatched.errors}">

                    <div *ngIf="(submitted || dispatchFormControls.qtyDispatched.touched) && dispatchFormControls.qtyDispatched.errors"
                        class="invalid-feedback">
                        <div *ngIf="dispatchFormControls.qtyDispatched.errors.required">
                           Quantity is required.</div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12 text-right">
                <button type="button" class="btn btn-primary" (click)="saveDispatch()"
                    [disabled]="dispatchForm.invalid">Save</button>
            </div>
        </div>
    </form>
</div>