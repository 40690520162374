<div class="modal-header">
    <h4 class="centerHeader mb-0" *ngIf="!isAssignedSupplierEdit">Add Supplier</h4>
    <h4 class="centerHeader mb-0" *ngIf="isAssignedSupplierEdit">Edit Supplier</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="Assignsupform">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-6 cureadd" *ngIf="!assignTime && !editTime">
                <div class="form-group">
                    <label class="">Supplier <b class="text-danger">*</b></label>
                    <mat-select placeholder="Select Supplier" formControlName="Supplier" class="form-control" (selectionChange)="supplierSelected($event)"
                    [ngClass]="{ 'is-invalid':
                          (submitted || asnf.Supplier.touched) && asnf.Supplier.errors }">
                        <mat-option *ngFor="let supplier of suplier" [value]="supplier.name" (click)="getsupplierIdbyClick(supplier)">{{supplier.name}}</mat-option>
                    </mat-select>
                    <div *ngIf="(submitted || asnf.Supplier.touched) && asnf.Supplier.errors" class="invalid-feedback">
                        <div *ngIf="asnf.Supplier.errors.required"> Please Select Supplier. </div>
                    </div>
                </div>
            </div>

            <!-- cat & sub cat -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-6 mb-3" *ngIf="assignTime || editTime">
                <div class="form-group">
                    <label class="">Supplier <b class="text-danger">*</b></label>
                    <input type="text" class="form-control" formControlName="Supplier" readonly>
                </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 mb-3" *ngIf="assignTime || editTime">
                <div class="form-group">
                    <label class="">Category <b class="text-danger">*</b></label>
                    <input type="text" class="form-control" formControlName="category" readonly>
                </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 mb-3" *ngIf="assignTime || editTime">
                <div class="form-group">
                    <label class="">Sub Category <b class="text-danger">*</b></label>
                    <input type="text" class="form-control" formControlName="subcategory" readonly>
                </div>
            </div>

            <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-3" *ngIf="assignTime">
                <div class="form-group">
                    <label>Category <span class="text-danger">*</span></label>
                    <mat-select placeholder="Select Category" formControlName="category" class="form-control" (selectionChange)="categorySelected($event)"
                    [ngClass]="{ 'is-invalid':
                          (submitted || asnf.category.touched) && asnf.category.errors }">
                        <mat-option *ngFor="let cat of actCategory" [value]="cat.catagory" (click)="getActivesubCategories(cat)">{{cat.catagory}}</mat-option>
                    </mat-select>
                    <div *ngIf="(submitted || asnf.category.touched) && asnf.category.errors" class="invalid-feedback">
                        <div *ngIf="asnf.category.errors.required"> Please Select Category. </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 mb-3" *ngIf="assignTime">
                <div class="form-group">
                    <label>Sub Category <span class="text-danger">*</span></label>
                    <mat-select placeholder="Select Sub Category" formControlName="subcategory" class="form-control" (selectionChange)="subcategorySelected($event)"
                    [ngClass]="{ 'is-invalid':
                          (submitted || asnf.subcategory.touched) && asnf.subcategory.errors }">
                        <mat-option *ngFor="let subc of actSubCategory" [value]="subc.name">{{subc.name}}</mat-option>
                    </mat-select>
                    <div *ngIf="(submitted || asnf.subcategory.touched) && asnf.subcategory.errors" class="invalid-feedback">
                        <div *ngIf="asnf.subcategory.errors.required"> Please Select Category to Select SubCategory. </div>
                    </div>
                </div>
            </div> -->
            <!-- cat & sub cat /-->

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label for="" class="">Price<b class="text-danger">*</b></label>
                <div class="form-group input-material">
                    <input type="text" class="form-control" formControlName="price" maxlength="10" appInputRestrict="numberOnly"
                    [ngClass]="{ 'is-invalid':
                          (submitted || asnf.price.touched) && asnf.price.errors }">
                    <div *ngIf="(submitted || asnf.price.touched) && asnf.price.errors" class="invalid-feedback">
                        <div *ngIf="asnf.price.errors.required"> Price is Required </div>
                    </div>
                </div>
            </div>

            <h3  class="mb-3 mt-2 font-size-16 border-bottom p-2 bg-primary-light w-100 ">Deal</h3>

            <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-2">

                <div class="form-group input-material">
                    <input type="text" class="form-control" formControlName="deal" [(ngModel)]="showSet"
                    [ngClass]="{ 'is-invalid':
                          (submitted || asnf.deal.touched) && asnf.deal.errors }">
                    <div *ngIf="(submitted || asnf.deal.touched) && asnf.deal.errors" class="invalid-feedback">
                    </div>
                </div>
            </div>

            <ng-container *ngIf="showSet">

                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label>Deal From <span class="text-danger">*</span></label>
                        <input matInput [matDatepicker]="dealFromDt" class="form-control" formControlName="dealFromDate"
                            (keypress)="dealFromDt.open()" (keyup)="dealFromDt.open()" [max]="minDate"
                            (click)="dealFromDt.open()"
                        >
                        <mat-datepicker-toggle matIconSuffix [for]="dealFromDt"
                            style="position: absolute;top: 30px;right: 10px;"></mat-datepicker-toggle>
                        <mat-datepicker #dealFromDt></mat-datepicker>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label>Deal To <span class="text-danger">*</span></label>
                        <input matInput [matDatepicker]="dealToDt" class="form-control" formControlName="dealToDate"
                            (keypress)="dealToDt.open()" (keyup)="dealToDt.open()" [min]="minDate"
                            (click)="dealToDt.open()"
                        >
                        <mat-datepicker-toggle matIconSuffix [for]="dealToDt"
                            style="position: absolute;top: 30px;right: 10px;"></mat-datepicker-toggle>
                        <mat-datepicker #dealToDt></mat-datepicker>
                    </div>
                </div>

                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3">
                    <label for="" class="col-form-label">Set Offer</label>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3">
                    <mat-radio-group [disabled]="assignTime" aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="selectedOption" [ngModelOptions]="{standalone: true}">
                        <mat-radio-button class="example-radio-button" type="radio" (change)="checkBoxCheck($event,option)" *ngFor="let option of options" [value]="option">
                            {{option}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </ng-container>

            <ng-container *ngIf="showSet && showDiscount">
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3">
                    <label for="" class="">Discount on price</label>
                    <div class="form-group input-material">
                        <input type="text" class="form-control" formControlName="discount"
                        maxlength="3" appInputRestrict="numberOnly"
                        [ngClass]="{ 'is-invalid':
                              (submitted || asnf.discount.touched) && asnf.discount.errors }">
                        <div *ngIf="(submitted || asnf.discount.touched) && asnf.discount.errors" class="invalid-feedback">
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="showSet && showOffer">
                <div class="col-xl-6 col-lg-6 col-md-6 col-xs-6 form-group mb-3">
                    <label for="" class="">Buy Quantity</label>
                    <div class="form-group input-material">
                        <input type="text" class="form-control" formControlName="buyQuantity"
                        maxlength="3" appInputRestrict="numberOnly"
                        [ngClass]="{ 'is-invalid':
                              (submitted || asnf.buyQuantity.touched) && asnf.buyQuantity.errors }">
                        <div *ngIf="(submitted || asnf.buyQuantity.touched) && asnf.buyQuantity.errors" class="invalid-feedback">
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-xs-6 form-group mb-3">
                    <label for="" class="">Deal Quantity</label>
                    <div class="form-group input-material">
                        <input type="text" class="form-control" formControlName="freeQuantity"
                        maxlength="3" appInputRestrict="numberOnly"
                        [ngClass]="{ 'is-invalid':
                              (submitted || asnf.freeQuantity.touched) && asnf.freeQuantity.errors }">
                        <div *ngIf="(submitted || asnf.freeQuantity.touched) && asnf.freeQuantity.errors" class="invalid-feedback">
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class=" float-right w-100">
              <button type="button" class="btn btn-danger al mt-3 float-right btn-sm ml-2" (click)="closeModal()"
              >
              Cancel
           </button>
                <button type="button" class="btn btn-success al mt-3 float-right btn-sm ml-2" (click)="saveAssigntable()" [disabled]='Assignsupform.invalid' *ngIf="!isAssignedSupplierEdit"><i class="fa fa-save"></i> Save </button>
                <button type="button" class="btn btn-primary al mt-3 float-right btn-sm ml-2" (click)="saveAssigntable()" *ngIf="isAssignedSupplierEdit"><i class="fa fa-pencil"></i> Update </button>
            </div>
        </div>
    </form>
</div>
