import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BilldeskService {

  constructor(@Inject(DOCUMENT) private document: Document) { }

 public loadBillDeskScript(scriptUrl: string) {
    const script = this.document.createElement('script');
    script.type = 'text/javascript';
    script.src = scriptUrl;
    script.async = true;

    // Wait for the DOM to be ready before appending the script element.
    document.addEventListener('DOMContentLoaded', () => {
      document.head.appendChild(script);
    });
  }
}
