import { GlobalStorageService } from 'src/app/global-storage.service';
  
import { Injectable } from '@angular/core';

export type StorageKey = 'audioInputId' | 'audioOutputId' | 'videoInputId';

@Injectable()
export class StorageService {

    constructor(private globalStorage:GlobalStorageService){

    }
    get(key: StorageKey): string {
        return this.globalStorage.getItem(this.formatAppStorageKey(key));
    }

    set(key: StorageKey, value: string) {
        if (value && value !== 'null') {
            this.globalStorage.setItem(this.formatAppStorageKey(key), value);
        }
    }

    remove(key: StorageKey) {
        this.globalStorage.removeItem(this.formatAppStorageKey(key));
    }

    private formatAppStorageKey(key: StorageKey) {
        return `iEvangelist.videoChat.${key}`;
    }
}