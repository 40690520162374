import { GlobalStorageService } from 'src/app/global-storage.service';
import { formatDate } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Route, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import { ApicallsService } from 'src/app/apicalls.service';

import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
import { DiagnosticServiceController } from '../../services/diagnostic.service';
import { gpsService } from '../../services/gps.service';
import { ObservableService } from '../../services/observable.service';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { SendMessageComponent } from 'src/app/commonComponents/internal-message/send-message/send-message.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InternalMessageComponent } from 'src/app/commonComponents/internal-message/internal-message.component';
declare var $;
@Component({
  selector: 'app-diagnostic-navebar',
  templateUrl: './diagnostic-navebar.component.html',
  styleUrls: ['./diagnostic-navebar.component.css']
})
export class DiagnosticNavebarComponent implements OnInit {
  lastLogin
  firstTimeLogin = false
  firstName
  lastName
  notificationBell = true
  notifications
  nonViewData
  notificationLength
  ViewedNotifications; notifyToView; p2 = 1;
  constructor(private globalStorage: GlobalStorageService, private DiagnosticService: DiagnosticServiceController, private observable: ObservableService, private scripts: ScriptsService, private reports: PatientScheduleControlService,
    private gps: gpsService, private route: Router, private toaster: ToastrService, private apiservice: ApicallsService,
    private modal:NgbModal) {
    this.observable.invokeEvent.subscribe(value => {
      if (value === 'someValue') {
        this.getPrimaryDetails()
      }
    });
  }



  roles; AppointmentsPermission = true;
  TestsPermission = true;
  UsersPermission = true;
  ReportsPermission = true;
  ProfilePermission = true;
  servicesPermission  =true
  roleMenus
  presentRole
  ngOnInit(): void {
    // var myvar= setInterval(currentTime, 1000);
    //roles
     var role = JSON.parse(this.globalStorage.getItem('roles'))
     console.log('roles',role)
     this.presentRole=role[0]?.['roleName']
     this.roles = role[0]?.['roleMenus']
    var setAppointmentsPermission = this.roles.filter(obj => obj?.menuName == "Appointments")
    var setTestsPermission = this.roles.filter(obj => obj?.menuName == "Tests")
    var setServicesPermissions=this.roles.filter(obj => obj?.menuName == "Services")
    var setUsersPermission = this.roles.filter(obj => obj?.menuName == "patients")
    var setReportsPermission = this.roles.filter(obj => obj?.menuName == "Reports")
    var setProfilePermission = this.roles.filter(obj => obj?.menuName == "profile")
    this.AppointmentsPermission=setAppointmentsPermission?.[0]?.active == 'Y' ? true :false
    this.servicesPermission=setServicesPermissions?.[0]?.active == 'Y' ? true :false
    this.ReportsPermission = setReportsPermission?.[0]?.active == 'Y' ? true :false
    this.UsersPermission=setUsersPermission?.[0]?.active == 'Y' ? true :false
    this.ProfilePermission=setProfilePermission?.[0]?.active == 'Y' ? true :false
    // if (setAppointmentsPermission?.[0]?.active == 'Y') {
    //   this.AppointmentsPermission = true
    // }
    // else{
    //   this.AppointmentsPermission = false
    // }
    // if (setTestsPermission?.[0]?.active == 'Y') {
    //   this.TestsPermission = true
    // }
    // if (setUsersPermission?.[0]?.active == 'Y') {
    //   this.UsersPermission = true
    // }
    // if (setReportsPermission?.[0]?.active == 'Y') {
    //   this.ReportsPermission = true
    // }
    // if (setProfilePermission?.[0]?.active == 'Y') {
    //   this.ProfilePermission = true
    // }
    //roles end

    if (window.location.pathname == '/diagnosticDashboard' || window.location.pathname == '/cureMetric/diagnosticDashboard') {
      this.notificationBell = false
    }

    var data = this.globalStorage.getItem('diagnosticData')
    var obj = JSON.parse(data)
    this.scripts.loadScripts()
    this.lastLogin = obj['lastLogin']
    var data = this.globalStorage.getItem('diagnosticData')
    var obj = JSON.parse(data)
    this.DiagnosticService.diagnosticGetPathSession('primarydetails?diagnosticcenteid=' + obj['diagnosticCenterUserId']).subscribe(res => {
      this.firstName = res['data']['firstName']
      this.lastName = res['data']['lastName']
    })
    this.getPrimaryDetails()
    this.loadNotifications()
    var dateDate = new Date(obj['lastLogin'])
    const date2 = formatDate(dateDate, "medium", 'en-us');
    this.lastLogin = date2
    if (this.lastLogin == "Jan 1, 1970, 5:30:00 AM") {
      this.firstTimeLogin = true
    }
    else {
      this.firstTimeLogin = false
    }

    $("button, a, body").on("click", function (e) {
      $('.tooltip').hide();
      $('[data-toggle="tooltip"]').tooltip("hide");
    });
    //  this.getAssociatedUsers()
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });

      $('[data-toggle="tooltip"]').on('click', function () {
        $(this).tooltip('hide')
      });
    });

  }
  p1 = 1;
  loadNotifications() {
    this.DiagnosticService.diagnosticGetPathSession('notificationalerts/get/all?diagnoticCenterAddressId=' + this.globalStorage.getItem('diagnosticCenterAddressId') + '&maxResult=100&pageNumber=' + this.p1).subscribe(res => {
      this.notifications = res['data']
      this.notificationLength = this.notifications.filter((obj) => obj.viewed == 'N').length;
      this.nonViewData = this.notifications.filter((obj) => obj.viewed == 'N')
      this.ViewedNotifications = this.notifications.filter((obj) => obj.viewed == 'Y')
    })
  }
  getPrimaryDetails() {

  }
  onSend(data) {
    var formData = new FormData();
    formData.append('screenshot', data.screenshot);
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + ' ' + time;
    var request = {
      "clientId": 11,
      "userId": this.globalStorage.getItem('diagnosticCenterAddressId'),
      "feedBackType": "TEST",
      "status": "NEW",
      "description": data.description,
      "userAgent": "test",
      "os": "Windows",
      "browser": "Chrome",
      "os_version": "windows-10",
      "browser_version": "85.0.4183.102",
      "device": "Unknown",
      "latitude": this.gps.latitude,
      "longitudes": this.gps.longitude,
      "feedbackTime": formatDate(dateTime, "yyyy-MM-dd", 'en-us')
    }
    formData.append('feedbackLoop', JSON.stringify(request));
    this.DiagnosticService.sendFeedback(formData).subscribe(res => {
      this.toaster.success('Feedback sent successfully')
    })
  }
  logOut() {

    this.DiagnosticService.diagnosticpPathSession('logoutasdiagnosticcenter').subscribe(res => {
      //this.toaster.success('Succesfully Logged Out')
      this.route.navigate(['/DiagnosticLogin'])

      this.apiservice.isUserSessionExits = false
      this.apiservice.isforUserIdExits = false
      this.apiservice.ismoduleNameExits = false
     // this.globalStorage.clear();
    });
  }

  updateNotify(data) {
    this.notifyToView = data;
    $('#notificationToViewSid').modal('show');
    var req = {
      "active": data.active,
      "diagnosticcenternotifiactionalertsid": data.diagnosticcenternotifiactionalertsid,
      "diagnosticCenterAddressId": data.diagnosticCenterAddressId,
      "objectkey": data.objectkey,
      "objecttype": data.objecttype,
      "doctorId": data.doctorId,
      "reason": data.reason,
      "viewed": "Y"
    }

    this.DiagnosticService.updateNotifications(data.diagnosticcenternotifiactionalertsid, req).subscribe(res => {
      this.notificationLength = this.notificationLength - 1
      this.loadNotifications();
    }, error => {
      this.toaster.error(error?.['error']?.['message']);
    })
  }
  createMessage(){
    const message = this.modal.open(SendMessageComponent, {
      centered: false,
      windowClass: 'modal-right hw-40',
      backdrop: 'static',
      keyboard: false,
    });
    message.componentInstance.addressId=parseInt(this.globalStorage.getItem('diagnosticCenterAddressId'))
  }
  getMessages(){
    const modalRef = this.modal.open(InternalMessageComponent, {
      centered: false,
      windowClass: 'modal-right hw-30',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.addressId = parseInt(this.globalStorage.getItem('diagnosticCenterAddressId'))
  }
}
