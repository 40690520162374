import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { GlobalStorageService } from 'src/app/global-storage.service';

@Component({
  selector: 'app-surgery-notes',
  templateUrl: './surgery-notes.component.html',
  styleUrls: ['./surgery-notes.component.css']
})
export class SurgeryNotesComponent implements OnInit {
  notesForm: FormGroup;currentDateAndTime;
  @Input() surgery;notesData;submitted=false;
  @Input() isView=false
  constructor(private fb: FormBuilder,public activeModal: NgbActiveModal,private clinicServices:ClinicService,
    private globalStorage: GlobalStorageService,private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.notesForm = this.fb.group({
      startTime: ['', Validators.required],
      description: ['', Validators.required,],
      endTime: ['']
    })
  this.getNotes()
  }
  get n() { return this.notesForm.controls; }
  giveNotes(surgery){
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    this.currentDateAndTime = date + ' ' + time;
    this.notesForm.patchValue({
      startTime: this.currentDateAndTime
    })
  }
  getNotes(){
    this.clinicServices.getSurgeryNotes(this.surgery?.surgeryId).subscribe(res=>{
      console.log('notes Data',res)
      if(res['data']?.length==0){
        this.isView=false
        this.giveNotes(this.surgery)
      }
      else{
        this.isView=true
        this.notesData=res?.['data']?.[0]
        this.notesForm.patchValue({
          "description":this.notesData?.notes
        })
      }
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
  saveNotes(notesData){
    var body={
      "active": "Y",
      "notes":this.notesForm?.value?.description,
      "surgeryId": this.surgery?.surgeryId,
    }
      this.clinicServices.saveSurgeryNotes(body).subscribe(res=>{
        this.toastr.success('Notes saved')
        this.activeModal.close('true')
      },error=>{
        this.toastr.error(error?.error?.message)
      })
  }
}
