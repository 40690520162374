import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { LoginService } from '../../AppServices/login.service';
import { Router } from '@angular/router'
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { CaretakerService } from 'src/app/shared/services/caretaker.service';

@Component({
  selector: 'app-caretaker-otp-verification',
  templateUrl: './caretaker-otp-verification.component.html',
  styleUrls: ['./caretaker-otp-verification.component.css']
})
export class CaretakerOtpVerificationComponent implements OnInit {
  otpVerificationForm: FormGroup;
  timerOn = true;
  subscription: Subscription;
  subscribeTimer;
  timeLeft: number = 120;
  mbNumber;
  invalidCredentials
  successCredentials
  message;
  otpInvalidMsg = "Otp is invalid";
  mOtpError: boolean = false;
  eOtpError: boolean = false;
  isTimeOn = true;
  isTimeOn2 = true;


  constructor(private otpService: AuthService, private caretakerService: CaretakerService, private formBuilder: FormBuilder, private loginService: LoginService, private router: Router,
    private toaster: ToastrService, private activatedRoute: ActivatedRoute) {

    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      emailId: string,
      mobileNumber: string,
      userName: string
    };
    this.otpVerificationForm = this.formBuilder.group({
      email: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      userName: ['', Validators.required],

    })
    this.mbNumber = state.mobileNumber;
    this.otpVerificationForm.patchValue({
      email: state.emailId,
      phoneNumber: state.mobileNumber.replace(/.(?=.{4})/g, 'x').padStart(10, 'x').slice(-10),
      userName: state.userName
    })
  }

  Verification: boolean = false
  ngOnInit(): void {

    this.email = this.activatedRoute.snapshot.queryParams.verificationId
    this.observableTimer()
  }
  OTP
  email; PhOTP; emailVerificationDone = false
  verifyOTP() {
    this.successCredentials = false;
    this.invalidCredentials = false;
    //this.isTimeOn=false;
    //alert()
    if (this.OTP == undefined || this.otpVerificationForm.value.email == undefined) {
      return
    }

    if (this.OTP == "" || this.otpVerificationForm.value.email == "") {
      return
    }
    this.caretakerService.getEmailVerification(this.otpVerificationForm.value.email, this.OTP).subscribe(res => {
      this.toaster.success("Your email ID is now verified.")
      // this.isTimeOn=true
      this.observableTimer2()
      this.emailVerificationDone = true;
      this.isTimeOn2 = true;
      this.verifyNowButton = true
      this.eOtpError = true;
    }, error => {
      //this.toaster.error('error ');

      this.eOtpError = true;
    }
    )
  }
  verifyPhoneOTP() {
    this.successCredentials = false
    this.invalidCredentials = false
    if (this.PhOTP == undefined || this.otpVerificationForm.value.phoneNumber == undefined) {
      return
    }

    if (this.PhOTP == "" || this.otpVerificationForm.value.phoneNumber == "") {
      return
    }
    this.caretakerService.getPhoneVerification(this.PhOTP, this.mbNumber.replace(/\+/gi, '%2B')).subscribe(res => {
      this.toaster.success('Your phone number is now verified.')
      this.router.navigate(['/caretakerLogin'])
      this.mOtpError = false;

    }, error => {
      this.mOtpError = true;
      //this.toaster.error('Your Phone number not verifed');
    })
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }

  resendOTP() {
    this.isTimeOn = true
    this.successCredentials = false
    this.invalidCredentials = false

    if (this.otpVerificationForm.value.email == undefined || this.otpVerificationForm.value.userName == "") {
      this.toaster.warning("Please enter email");
      return
    }
    this.caretakerService.resendEmailOtpCT(this.otpVerificationForm.value.email, this.otpVerificationForm.value.userName).subscribe(res => {
      // this.toaster.success("OTP send to your EmailId")
      this.successCredentials = true
      this.message = "OTP send to your EmailId"
      this.observableTimer()
    }, error => {
      this.toaster.error("Error in resend OTP")
    })

  }
  observableTimer() {
    this.isTimeOn = true
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.successCredentials = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        // this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  observableTimer2() {
    this.isTimeOn2 = true
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer2').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn2 = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.successCredentials = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        // this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  resendPhoneOTP() {
    this.isTimeOn = true
    this.successCredentials = false
    this.invalidCredentials = false
    if (this.otpVerificationForm.value.phoneNumber == undefined || this.otpVerificationForm.value.phoneNumber == "") {
      this.toaster.warning("Please enter mobile number");
      return
    }
    this.caretakerService.resendMobileOtpCT(this.mbNumber.replace(/\+/gi, '%2B'), this.otpVerificationForm.value.userName).subscribe(res => {

      //this.toaster.success("OTP send to your mobile number")
      this.successCredentials = true
      this.message = "OTP send to your mobile number"
      this.observableTimer2()
    }, error => {
      this.toaster.error("Error in resend OTP")
    })

  }

  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }

  verifyNowButton = true
  otpEntered(value) {
    if (value.length == 6) {
      this.verifyNowButton = false
    }
    else {
      this.verifyNowButton = true
    }
  }
}
