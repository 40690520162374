import { Component, OnInit, ViewEncapsulation } from '@angular/core';
//import{FeedbackService} from '../../AppServices/feedback.service';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }



  public onSend(val) {


  }

}
