import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PatientConsentFormComponent } from 'src/app/patient-pages/patient-consent-form/patient-consent-form.component';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { PaymentConsentComponent } from '../payment-consent/payment-consent.component';
import { BookingConfirmationComponent } from '../booking-confirmation/booking-confirmation.component';
import * as moment from 'moment';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';
import { IvfBookingConfirmationComponent } from 'projects/ivf/src/app/ivf-booking-confirmation/ivf-booking-confirmation.component';
import { Router } from '@angular/router';
declare let window: any;
@Component({
  selector: 'app-payment-info',
  templateUrl: './payment-info.component.html',
  styleUrls: ['./payment-info.component.css']
})
export class PaymentInfoComponent implements OnInit {
  @Input() patientDetails: any;
  @Input() scheduleHoursId: any;
  @Input() docID: any;
  @Input() gender: string = "Male"
  @Input() doctorName: string = ""
  @Input() specializ: string = ""
  @Input() typeOfAppointment: string = ""
  @Input() reason: string
  @Input() selectedItem
  @Input() fromTime: any
  @Input() toTime: any
  @Input() fee: any
  @Input() exp: any;
  @Input() rating: any;
  show: boolean = true
  formProcess: boolean = false;
  paymentTypeSelected: string;
  selectedPaymentType
  checkAgree: boolean = false;
  cashModes: any[] = [{ id: 1, value: 'CASH' }, { id: 2, value: 'CARD' }]
  isIvf: boolean = false;
  disableCB
  consent
  discountAmount: number = 0;
  appointmentType: any;
  constructor(private globalStorage: GlobalStorageService, private toastr: ToastrService, private patientScheduleService: PatientScheduleControlService,
    private modal: NgbModal, public activeModal: NgbActiveModal, private ivfService: IvfService, private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
    if (this.globalStorage.getItem('loggedInAs') === "PATIENT" || this.globalStorage.getItem('loggedInAs') === "CARETAKER") {
      this.show = false;
      this.paymentTypeSelected = "ONLINE"
    }
    if (this.globalStorage.getItem('loggedInAs') === "IVF") {
      this.isIvf = true;
      this.cashModes = [{ id: 1, value: 'CASH' }]
      this.selectedPaymentType = "CASH";
      this.drpdownSelectionChange(this.selectedPaymentType)
      this.typeOfAppointment = 'Inperson';

    }

    if (this.globalStorage.getItem('loggedInAs') === "HOSPITAL") {
      this.selectedPaymentType = "CASH";
      this.drpdownSelectionChange(this.selectedPaymentType)
      this.typeOfAppointment = 'Inperson';
    }
    this.discountAmount=0;
    this.advanceAmount = 0;
    this.getAppointmentType();
  }
  appointmentTypeArray=[]

  getAppointmentType() {
    this.ivfService.getDoctorAppointmentTypes().subscribe(res => {
      this.appointmentTypeArray = res?.data || [];
      this.appointmentType=this.appointmentTypeArray[0].lookupValue
    })
  }
  consentForm() {
    let wClass = 'modal-right hw-50'

    if (this.globalStorage.getItem('loggedInAs') === "IVF") {
      wClass = 'right w-40';
    }

    const consentFormRef = this.modal.open(PaymentConsentComponent, {
      // centered: true,
      windowClass: wClass,
      backdrop: 'static'
    })
    consentFormRef.componentInstance.consent = this.checkAgree
    consentFormRef.result.then((reason) => {
      this.checkAgree = reason;
    },
      (result) => {
        this.checkAgree = result
      })
  }
  advanceAmount: number;
  dueAmount: number;
  amountAfterDiscount: number;
  calculateDueAmount(): number {
    const consultingFee = this.fee;
    this.amountAfterDiscount = this.fee;
    let dscntAmt = 0;
    if (this.discountAmount) {
      dscntAmt = consultingFee * (this.discountAmount / 100);
    }
    this.amountAfterDiscount = consultingFee - dscntAmt;
    const advance = this.advanceAmount;
    this.dueAmount = this.amountAfterDiscount - advance;

    // if(this.dueAmount < 0){
    //   this.toastr.warning("Amount Paid cannot be greater than Actual Amount")
    //   this.advanceAmount=this.amountAfterDiscount;
    //   this.dueAmount=0;
    //   return 0;
    // }

    return this.dueAmount

  }
  drpdownSelectionChange(selectedPaymentType) {
    // if (this.checkAgree == false) {
    //   this.toastr.warning('Please agree the consent form.')
    // }
    this.paymentTypeSelected = selectedPaymentType
  }
  bdSendingMercId;
  bdSendingToken;
  bdResponseToken;
  fetchedBDorderId;
  fetchedMercId;
  fetchedAuthToken;
  getUserSessionFromLS;
  getForUserIdFromLS;
  getClinicDataFromLS;
  showPayment = false; appointemntFor; showModal: boolean;
  transactionIdPayu; hashCode; payUKey; aliment; payUFee; payUemail; payUFirstName; sUrl; fUrl; payUPhone;


  openPayU() {
    if (this.globalStorage.getItem('loggedInAs') === "IVF") {
      this.ivfPayUBook()
    } else {
      this.payUBook()
    }
  }


  appointmentId:any

  payUBook() {
    var request = {
      "appointment": {
        "amountPaid": this.fee,
        "ailment": this.reason,
        "appointmentDate": this.selectedItem,
        "appointmentFor": "DOCTOR",
        "chatType": this.typeOfAppointment,
        "description": this.reason,
        "fromTime": this.fromTime,
        "referenceEntityId": this.docID,
        "referenceEntityName": this.doctorName,
        "referenceEntitySpecialization": this.specializ,
        "hospitalAddresId": this.globalStorage.getItem('hospitalAddressId'),
        "patientName": this.patientDetails?.['userName'],
        "schedulehoursId": this.scheduleHoursId,
        "toTime": this.toTime,
        "userId": this.patientDetails['userId'],
        "createdBy": this.patientDetails['firstName'] + " " + this.patientDetails['lastName'],
        "paymentDetail": {
          "aliment": this.reason,
          "amount": this.fee,
          "email": this.patientDetails?.['emailId'],
          "name": this.patientDetails['firstName'] + " " + this.patientDetails['lastName'],
          "phone": this.patientDetails?.cellNumber
        },
      },
      "forUser": {
        "forUserId": this.patientDetails?.['userId'],
        "userId": this.patientDetails?.['userId']
      },
      'paymentId': null,
      'paymentType': this.selectedPaymentType == 'CASH' ? "CASH" : "ONLINE"
    }
    this.patientScheduleService.bookAppointmentNew(request, this.patientDetails['userId']).subscribe(res => {
      if (this.selectedPaymentType == 'CASH') {
        /*
        doctorName,
specializ
aRating
ttlExperience
reason
selectedItem
fromTime
fee
         */
        var details = {
          "doctorName": this.doctorName,
          "specializ": this.specializ,
          "aRating": this.rating,
          "ttlExperience": this.exp,
          "reason": this.reason,
          "selectedItem": this.selectedItem,
          "fromTime": this.fromTime,
          "fee": this.fee,
        }
        // var bookingconfirm = this.modal.open(BookingConfirmationComponent,{
        //   centered: true,
        //   windowClass: 'modal-centered w-30'
        // })
        // bookingconfirm.componentInstance.details = details
        this.openBookingConfirmationModal()
      }
      else {
        this.fetchedBDorderId = res['data']?.['billdeskJsonResponseDTO']?.bdorderid;
        this.fetchedMercId = res['data']?.['billdeskJsonResponseDTO']?.mercid;
        this.fetchedAuthToken = res['data']?.['billdeskJsonResponseDTO']?.authorization;

        // Store user session using cookies
        this.getUserSessionFromLS = this.globalStorage.getItem('userSession');
        this.getForUserIdFromLS = this.globalStorage.getItem('forUserId');
        this.getClinicDataFromLS = this.globalStorage.getItem('clinicDetails');

        const d = new Date();
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = "usersession = " + this.getUserSessionFromLS + ";" + expires + ";path=/";
        document.cookie = "foruserid = " + this.getForUserIdFromLS + ";" + expires + ";path=/";
        document.cookie = "clinicDetails = " + this.getClinicDataFromLS + ";" + expires + ";path=/";
        // document.cookie = "transResponse=qerty" + ";" + expires + ";path=/";

        if (this.fetchedAuthToken) {
          var flow_config = {
            merchantId: this.fetchedMercId,
            bdOrderId: this.fetchedBDorderId,
            authToken: this.fetchedAuthToken,
            childWindow: false,
            returnUrl: 'https://dev.cure-metric.com/cureMetric/clinic-dashboard',
            crossButtonHandling: 'Y',
            retryCount: 0
          };
          var config = {
            flowConfig: flow_config,
            flowType: "payments"
          };
          $(window).off('beforeunload.windowReload');
          window.onbeforeunload = null;
          window.loadBillDeskSdk(config);
        }

        // $('#exampleModalRight').modal('hide');
        this.showPayment = true;
        this.showModal = true;
      }
      this.activeModal.close()
    }, error => {
      this.toastr.error(error?.['error']?.['message'])
    })
  }

  openBookingConfirmationModal() {
    const modalRef = this.modalService.open(IvfBookingConfirmationComponent, {
      centered: this.globalStorage.getItem('loggedInAs') === "IVF",
      windowClass: this.globalStorage.getItem('loggedInAs') === "IVF" ? 'modal-centered hw-30' : ''
    });

    modalRef.componentInstance.bookingDetails = {
      doctorName: this.doctorName,
      specializ: this.specializ,
      aRating: this.rating,
      ttlExperience: this.exp,
      reason: this.reason,
      selectedItem: this.selectedItem,
      fromTime: this.fromTime,
      advanceAmount: this.globalStorage.getItem('loggedInAs') === "IVF" ? this.advanceAmount : this.fee,
      dueAmount: this.dueAmount,
      appointmentId: this.appointmentId
    };

    modalRef.result.then(
      (result) => {
        if (this.globalStorage.getItem('loggedInAs') === "IVF") {
          this.activeModal.close()
          this.navigateIvf();
        } else {
          this.router.navigate(['/clinic-dashboard']);
        }

      },
      (reason) => {
        // Handle dismissal of modal
      }
    );
  }

  navigateIvf() {
    if (this.router.url.includes('ivf-dashboard')) {
      this.ivfService.triggerRefresh();
    }
    this.router.navigate(['/ivf-dashboard'])
  }

  transactionId: any = '';
  ivfPayUBook() {
    console.log('userReferenceId', this.patientDetails);
    this.dueAmount = this.calculateDueAmount();
    if(this.advanceAmount<=0){
      this.toastr.warning('Amount Paid should be greater than 0');
      return
    }
    var request = {
      "appointment": {
        'advancePay': this.advanceAmount,
        'duePay': this.dueAmount,
        'discount': this.discountAmount,
        'appointmentType': this.appointmentType,
        'transactionId': this.transactionId,
        "amountPaid": this.fee,
        "ailment": this.reason,
        "appointmentDate": this.selectedItem,
        "appointmentFor": "DOCTOR",
        "chatType": this.typeOfAppointment,
        "description": this.reason,
        "fromTime": this.fromTime,
        "referenceEntityId": this.docID,
        "referenceEntityName": this.doctorName,
        "referenceEntitySpecialization": this.specializ,
        "ivfaddressId": this.globalStorage.getItem('ivfAddressId'),
        "patientName": this.patientDetails['firstName'] + ' ' + this.patientDetails['lastName'],
        "schedulehoursId": this.scheduleHoursId,
        "toTime": this.toTime,
        "userId": this.patientDetails['userReferenceId'],
        "createdBy": this.patientDetails['firstName'] + ' ' + this.patientDetails['lastName'],
        "spouseName": this.patientDetails?.spouseName,
        "ivfspouseId": this.patientDetails?.ivfspouseId,
        "paymentDetail": {
          "aliment": this.reason,
          "amount": this.fee,
          "email": this.patientDetails?.['emailId'],
          "name": this.patientDetails['firstName'] + ' ' + this.patientDetails['lastName'],
          "phone": this.patientDetails?.cellNumber
        },
      },
      "forUser": {
        "forUserId": this.patientDetails['userReferenceId'],
        "userId": this.globalStorage.getItem('userId'),
      },
      'paymentId': null,
      'paymentType': this.selectedPaymentType

    }
    this.ivfService.appointmentBook('booking/newRequestWithPayU', request, this.globalStorage.getItem('ivfAddressId')).subscribe(res => {
      this.appointmentId=res?.body?.data
        this.openBookingConfirmationModal()

    }, error => {
      this.toastr.error(error?.['error']?.['message'])
    })
  }
}

