import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DoctorLayoutComponent } from './layout/doctor-layout/doctor-layout.component';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { PatientLayoutComponent } from './layout/patient-layout/patient-layout.component';
import { ErrorComponent } from './error/error.component';
import { ResetPasswordComponent } from './shared/reset-password/reset-password.component';
import { PatientVerificationComponent } from './patient-pages/patient-verification/patient-verification.component';
import { OTPverificationComponent } from './pages/otpverification/otpverification.component';
import { PharmacyLayoutComponent } from './layout/pharmacy-layout/pharmacy-layout.component';
import { InfoComponentComponent } from './shared/info-component/info-component.component';
import { PharmacyCreatePasswordComponent } from './pharmacy-pages/pharmacy-create-password/pharmacy-create-password.component';
import { DiagnosticLayoutComponent } from './layout/diagnostic-layout/diagnostic-layout.component';
import { AfterRegisterComponent } from './shared/doctor-redirection-pages/after-register.component';
import { DiagnosticCreatePasswordComponent } from './diagnostic-pages/profilePages/diagnostic-create-password/diagnostic-create-password.component';
import { DiagnosticRedirectPagesComponent } from './shared/diagnostic-redirect-pages/diagnostic-redirect-pages.component'
import { DiagnosticOtpVerificationComponent } from './diagnostic-pages/diagnostic-otp-verification/diagnostic-otp-verification.component';
import { PharmacyRedirectPagesComponent } from './shared/pharmacy-redirect-pages/pharmacy-redirect-pages.component';
import { DoctorGuardGuard } from './layout/doctor-layout/doctor-guard.guard'
import { CaretakerLayoutComponent } from './layout/caretaker-layout/caretaker-layout.component';
import { ClinicLayoutComponent } from './layout/clinic-layout/clinic-layout.component';
import { NutritionistLayoutComponent } from './layout/nutritionist-layout/nutritionist-layout/nutritionist-layout.component';
import { CaretakerRedirectPagesComponent } from './shared/caretaker-redirect-pages/caretaker-redirect-pages.component';
import { WebAppointmentComponent } from './web-appointment/web-appointment.component';
import { LoginComponent } from './patient-pages/pat-login/login.component';
import { EnquiryFormComponent } from './enquiry-form/enquiry-form.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'patientLogin',
    pathMatch: 'full'
  },
  { path: 'patientLogin', component: LoginComponent},
  {
    path: '',
    component: DoctorLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/doctor-layout/doctor-layout.module').then(m => m.AdminLayoutModule)
      }
    ]
  },

  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
      }
    ]
  },
  {
    path: '',
    component: DiagnosticLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/diagnostic-layout/diagnostic-layout.module').then(m => m.DiagnosticLayoutModule)
      }
    ]
  },
  {
    path: '',
    component: PharmacyLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/pharmacy-layout/pharmacy-layout.module').then(m => m.PharmacyLayoutModule)
      }
    ]
  },
  {
    path: '',
    component: PatientLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/patient-layout/patient-layout.module').then(m => m.PatientLayoutModule)
      }
    ]
  },
  {
    path: '',
    component: CaretakerLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/caretaker-layout/caretaker-layout.module').then(m => m.CaretakerLayoutModule)
      }
    ]
  },
  {
    path: '',
    component: ClinicLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/clinic-layout/clinic-layout.module').then(m => m.ClinicLayoutModule)
      }
    ]
  },
  {
    path: '',
    component: NutritionistLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layout/nutritionist-layout/nutritionist-layout.module').then(m => m.NutritionistLayoutModule)
      }
    ]
  },

  {
    path: 'succesfullRegistration',
    component: InfoComponentComponent
  },
  {
    path: 'FailureRegistration',
    component: InfoComponentComponent
  },
  {
    path: 'doctor-succesfull-Registration',
    component: AfterRegisterComponent
  },
  {
    path: 'doctor-Failure-Registration',
    component: AfterRegisterComponent
  },
  {
    path: 'diagnostic-succesfull-Registration',
    component: DiagnosticRedirectPagesComponent
  },
  {
    path: 'diagnostic-Failure-Registration',
    component: DiagnosticRedirectPagesComponent
  },
  {
    path: 'pharmacy-succesfull-Registration',
    component: PharmacyRedirectPagesComponent
  },
  {
    path: 'pharmacy-Failure-Registration',
    component: PharmacyRedirectPagesComponent
  },
  {
    path: 'caretaker-Failure-Registration',
    component: CaretakerRedirectPagesComponent
  },

  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent
  },
  {
    path: 'verify',
    component: ResetPasswordComponent
  },
  { path: 'dia-verify', component: ResetPasswordComponent },
  { path: 'create-password-pharmacy', component: PharmacyCreatePasswordComponent },
  { path: 'create-password-diagnostic', component: DiagnosticCreatePasswordComponent },
  { path: 'otp-verification-patient', component: PatientVerificationComponent },
  { path: 'otp-verification-diagnosticcenter', component: DiagnosticOtpVerificationComponent },
  { path: 'web-appointmentBooking', component: WebAppointmentComponent },
  //{path:'otp-verification-doctor',component:OTPverificationComponent},
  { path: 'enquiry-form', component: EnquiryFormComponent },

  {
    path: '**',
    redirectTo: 'error'
  }
];

// @NgModule({
//   imports: [RouterModule.forRoot(routes,{ enableTracing: false  })],
//       {
//     path:'**',
//     redirectTo:'Login'
//   }
// })

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }



