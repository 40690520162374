import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Crypto } from '../../_helpers/CryptoJ'
import * as moment from 'moment';
import { CaretakerService } from 'src/app/shared/services/caretaker.service';
import { ToastrService } from 'ngx-toastr';
import { ApicallsService } from 'src/app/apicalls.service';
declare var $: any;

@Component({
  selector: 'app-caretaker-login',
  templateUrl: './caretaker-login.component.html',
  styleUrls: ['./caretaker-login.component.css']
})
export class CaretakerLoginComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  cond_vall = false
  rememberme: boolean = false;
  email
  invalidCredentials: boolean = false
  verifyNowButton = false
  message; age
  password; loginUser
  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder, private caretakerService: CaretakerService, private apiservice: ApicallsService,
    private router: Router, private toastrService: ToastrService, private cookieService: CookieService, private crpto: Crypto) {
    if (this.cookieService.get('rememberme') == 'Yes' && this.cookieService.get('module') == 'Caretaker') {
      this.rememberme = true;
      this.email = this.crpto.Decrypt(this.cookieService.get('email'), 'email');
      this.password = this.crpto.Decrypt(this.cookieService.get('password'), 'password');
    }
  }

  ngOnInit(): void {

    // if (navigator.userAgent.match(/Android/i)) {
    //   window.location.href = 'https://play.google.com/store/apps/details?id=com.curemetric.caretaker'
    // }
    // else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    //   window.location.href = 'https://apps.apple.com/in/app/curemetric-caretaker/id1670612089'
    // }

    $(function () {
      $(window).off('beforeunload');
      $("#preloader").fadeOut();
      $("#status").fadeOut(9000);
    });
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }
  get f() { return this.registerForm.controls; }
  get pwd() {
    return this.registerForm.get('password');
  }
  forgotPassword() {

  }
  change() {
    this.cond_vall = !this.cond_vall

  }

  navigateToEnquiryForm(): void {
    this.router.navigate(['/enquiry-form'], { queryParams: { module:'caretaker', returnUrl: this.router.url } });
}

  showValidationMessage: boolean = false;
  preventCopy(event: ClipboardEvent) {
    event.preventDefault();
    this.showValidationMessage = true;
    setTimeout(() => {
      this.showValidationMessage = false;
    }, 5000);
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
  }
  onSubmit() {
    this.submitted = true;
    this.registerForm.value.email.toLowerCase()
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    if (this.rememberme) {
      this.cookieService.set('email', this.crpto.encryption(this.email, "email"));
      this.cookieService.set('password', this.crpto.encryption(this.password, 'password',));
      this.cookieService.set('rememberme', 'Yes')
      this.cookieService.set('module', 'Caretaker')
    } else {
      this.cookieService.set('email', "");
      this.cookieService.set('password', "");
      this.cookieService.set('rememberme', "")
    }
    var request = {
      'password': this.registerForm.value.password,
      'userName': this.registerForm.value.email
    }
    this.caretakerService.signin((request)).subscribe(res => {
      if (res.body.data.verificationDone == 'N') {
        this.verifyNowButton = true;
      }
      else {
        if (res.body.status == "SUCCESS") {
          // this.ToastrService.success('Logged in successfully')
          sessionStorage.setItem('caretakerLogDetails', JSON.stringify(res.body))
          this.globalStorage.setItem('caretakerLogDetails', JSON.stringify(res.body));
          sessionStorage.setItem('caretakerLoginDetails', JSON.stringify(res.body.data))
          this.globalStorage.setItem('caretakerLoginDetails', JSON.stringify(res.body.data));
          this.globalStorage.setItem('caretakerCredentials', JSON.stringify(request))
          this.globalStorage.setItem('caretakerData', JSON.stringify(res.body.data));
          this.globalStorage.setItem('loggedInAs', 'CARETAKER')
          this.globalStorage.setItem('hospitalAddresId', JSON.stringify(res?.body?.data?.hospitalAddresId))
          this.globalStorage.setItem('hospitalAddressId', JSON.stringify(res?.body?.data?.hospitalAddresId))
          this.globalStorage.setItem('rpmadminAddressId',JSON.stringify(res.body.data?.rpmadminAddressId))
          this.globalStorage.setItem('forUserId', JSON.stringify(res.body.data.caretakerId))
          this.globalStorage.setItem('userId', JSON.stringify(res.body.data.caretakerId))
          this.globalStorage.setItem('entityId', JSON.stringify(res.body.data.caretakerId))
          this.globalStorage.setItem('ppswd', this.registerForm.value.password)
          this.globalStorage.setItem('userSession', JSON.stringify(res.body.userSession))
          this.cookieService.set('usersession',res.body.userSession)
          this.apiservice.isUserSessionExits = true
          this.apiservice.isforUserIdExits = true
          this.apiservice.ismoduleNameExits = true
          var data = JSON.parse(this.globalStorage.getItem('caretakerLogDetails'));
          // this.loginUser=data.data;
          // this.caretakerService.doctorProfileCompletedOrNot(this.loginUser.doctorId).subscribe(res=>{
          // })
          this.router.navigate(['/caretaker-dashboard'])
          // this.scripts.loadCssStyles()
          var datas = this.globalStorage.getItem('caretakerData')
          var obj = JSON.parse(datas)
          var today = moment(obj['lastLogin']).format('YYYY-MM-DD HH:mm:ss');
        }
      }

    }
      , error => {
        if (error['status'] == 0) {
          this.toastrService.error("Certification Authority Invalid")
        } if (error['error']['message'] == 'Error logging in Your Email Id Is  Unverified.' || error['error']['message'] == 'Error logging in Your Email Id Is  Unverified.') {
          this.invalidCredentials = true
          this.message = error['error']['message']
        }
        else {
          this.invalidCredentials = true
          this.message = error['error']['message']
          this.router.navigate(['/caretakerLogin'])
        }
      }
    )
  }
  verifyNow() {
    this.router.navigate(['/caretakerVerify'])
  }
  remembermeCheck() {
    if (this.rememberme) {
      this.rememberme = false;
    } else {
      this.rememberme = true;
    }
  }

  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });
  }
  resetMessage(){
    this.invalidCredentials = false
  }
}
