import { Directive, Renderer2, ElementRef, HostListener, Input, OnInit, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[autoResizeRows]'
})
export class AutoResizeRowsDirective implements OnInit,AfterViewInit  {
  
  @Input() minRows: number = 1;
  @Input() maxRows: number = 3;
  @Input() maxChars: number = 250; // default character limit
  
  private counterDiv: HTMLElement; // Element to display the character count
  
  constructor(private el: ElementRef, private renderer: Renderer2) {}
  
  ngOnInit(): void {
    this.renderer.setAttribute(this.el.nativeElement, 'rows', this.minRows.toString());
    this.renderer.setAttribute(this.el.nativeElement, 'maxlength', this.maxChars.toString());

    
    // Create the counter div and add the 'character-counter' class
    this.counterDiv = this.renderer.createElement('div');
    this.renderer.addClass(this.counterDiv, 'float-right');
    this.renderer.addClass(this.counterDiv, 'font-size-10');
    this.renderer.addClass(this.counterDiv, 'mt-1');
    this.updateCounter();
    
    // Append the counter div below the textarea
    this.renderer.appendChild(this.el.nativeElement.parentNode, this.counterDiv);
    this.updateCounter();

  }

  ngAfterViewInit(): void {
    // Initial update for asynchronously loaded content
    setTimeout(() => this.updateCounter(), 0); // Ensuring timeout to allow for data binding
  }
  
  @HostListener('input')
  onInput() {
    // Update character count and adjust textarea rows as necessary
    this.updateCounter();
    // this.renderer.setAttribute(this.el.nativeElement, 'maxlength', this.maxChars.toString());
  }

  @HostListener('focus')
  onFocus() {
    this.updateCounter();
    this.renderer.setAttribute(this.el.nativeElement, 'rows', this.maxRows.toString());
    // this.renderer.setAttribute(this.el.nativeElement, 'maxlength', this.maxChars.toString());

  }
  

  @HostListener('blur')
  onBlur() {
    this.renderer.setAttribute(this.el.nativeElement, 'rows', this.minRows.toString());
  }
  
  private updateCounter() {
    // Update character count
    const textLength = this.el.nativeElement.value.length;
    const text = `${textLength}/${this.maxChars}`;
    this.renderer.setProperty(this.counterDiv, 'innerText', text);
    
    // Limit the characters entered to maxChars
    if (textLength > this.maxChars) {
      const trimmedText = this.el.nativeElement.value.substr(0, this.maxChars);
      this.renderer.setProperty(this.el.nativeElement, 'value', trimmedText);
    }
  }
}
