import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientService } from 'src/app/shared/services/patient.service';
import { ToastrService } from 'ngx-toastr';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';

@Component({
  selector: 'app-user-vitals',
  templateUrl: './user-vitals.component.html',
  styleUrls: ['./user-vitals.component.css']
})
export class UserVitalsComponent implements OnInit {
  @Input() user_id: any;
  @Input() userPersonalData: any;
  userVitalsForm: FormGroup;
  todayDate;
  feets: any[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  inchs: any[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];
  submitted: boolean = false;
  weightLevel;
  wtlessMsg = false;
  wtExtraMsg = false;
  userVitals;

  constructor(private globalStorage: GlobalStorageService, private fb: FormBuilder, private activeModal: NgbActiveModal,
    private patientService: PatientService,
    private ivfService: IvfService,
    private toastr: ToastrService) {
    this.todayDate = formatDate(new Date(), "dd-MM-yyyy HH:mm:ss", "en-us");
  }

  ngOnInit(): void {
    console.log('user data', this.userPersonalData)
    this.initializeuserVitalsForm();
    this.getPatientVitals();
  }

  initializeuserVitalsForm() {
    this.userVitalsForm = this.fb.group({
      date: [this.todayDate],
      bloodgroup: [this.userPersonalData?.bloodgroup],
      weight: ['', [Validators.min(10), Validators.max(750)]],
      feet: ['', [Validators.min(1), Validators.max(10)]],
      inch: ['', [Validators.min(0), Validators.max(11)]],
      oxygenLevel: ['', [Validators.min(90), Validators.max(100)]],
      temperature: ['', [Validators.min(95), Validators.max(105)]],
      pulse: ['', [Validators.min(40), Validators.max(220)]],
      systolic: ['', [Validators.min(80), Validators.max(200)]],
      diastolic: ['', [Validators.min(50), Validators.max(140)]],
      fasting: ['', [Validators.min(80), Validators.max(140)]],
      random: ['', [Validators.min(80), Validators.max(220)]]
    });
  }

  get vitalFormControls() {
    return this.userVitalsForm.controls;
  }

  closeModal() {
    this.activeModal.close('Close click');
  }

  checkWight(weight, height) {
    if (height == '4.6') this.weightLevel = "63-77";
    if (height == '4.7') this.weightLevel = "68-84"; if (height == '4.8') this.weightLevel = "72-88"; if (height == '4.9') this.weightLevel = "77-94";
    if (height == '4.10') this.weightLevel = "81-99"; if (height == '4.11') this.weightLevel = "86-105"; if (height == '5.0') this.weightLevel = "90-110";
    if (height == '5.1') this.weightLevel = "95-116"; if (height == '5.2') this.weightLevel = "99-121"; if (height == '5.3') this.weightLevel = "104-127";
    if (height == '5.4') this.weightLevel = "108-132"; if (height == '5.5') this.weightLevel = "113-138"; if (height == '5.6') this.weightLevel = "117-143";
    if (height == '5.7') this.weightLevel = "122-149"; if (height == '5.8') this.weightLevel = "126-154"; if (height == '5.9') this.weightLevel = "131-160";
    if (height == '5.10') this.weightLevel = "135-165"; if (height == '5.11') this.weightLevel = "140-171"; if (height == '6.0') this.weightLevel = "144-176";
    if (height == '6.1') this.weightLevel = "149-182"; if (height == '6.2') this.weightLevel = "153-187"; if (height == '6.3') this.weightLevel = "158-193";
    if (height == '6.4') this.weightLevel = "162-198"; if (height == '6.5') this.weightLevel = "167-204";
    var arr = this.weightLevel?.split("-");
    if (weight < arr[0]) this.wtlessMsg = true; else { this.wtlessMsg = false }
    if (weight > arr[1]) this.wtExtraMsg = true; else { this.wtExtraMsg = false }
  }

  sys;
  dys;
  vitalObj: any;
  vitalsArray;
  feet_s;
  inche_s;
  getPatientVitals() {
    let req = {
      forUserId: this.user_id,
      userId: this.globalStorage.getItem('userId')
    }

    if (this.globalStorage.getItem('loggedInAs') == 'IVF') {
      this.ivfService.getIvfPatientVitals(this.globalStorage.getItem('ivfAddressId'), this.user_id).subscribe(res => {
        this.vitalObj = {};
        this.userVitals = res?.['body']?.['data']?.map((vital) => {
          this.vitalObj[vital.vitalname] = {
            'vitalsId': vital.vitalsId,
            'userId': vital.userId,
            'vitalValue': vital.vitalValue,
            'vitalname': vital.vitalname,
            'recordedDate': vital.recordedDate,
            'metricType': vital.metricType
          };
        })

        this.patchValues();
      })
    } else {
      let req = {
        forUserId: this.userPersonalData?.userId ? this.userPersonalData?.userId : this.user_id,
        userId: this.globalStorage.getItem('userId')
      }
      console.log('calllinndhshs', req)
      this.patientService.getAllVitalsByDayByDay(req, req.userId).subscribe(res => {
        this.vitalObj = {};
        this.userVitals = res?.['body']?.['data']?.map((vital) => {
          this.vitalObj[vital.vitalname] = {
            'vitalsId': vital.vitalsId,
            'userId': vital.userId,
            'vitalValue': vital.vitalValue,
            'vitalname': vital.vitalname,
            'recordedDate': vital.recordedDate,
            'metricType': vital.metricType
          };
        })

        this.patchValues();
      },
        error => {
          this.toastr.error(error?.['error']?.['message']);
        }
      )
    }

  }


  patchValues() {
    console.log('vitals object', this.vitalObj.bloodgroup, this.userPersonalData)
    const heightValue = this.vitalObj?.height?.vitalValue;
    const heigth = heightValue?.split('.');
    this.sys = this.vitalObj.systolic?.vitalValue;
    this.dys = this.vitalObj.diastolic?.vitalValue;
    this.feet_s = heigth?.[0];
    this.inche_s = heigth?.[1];
    this.userVitalsForm.patchValue({
      //bloodgroup: this.vitalObj.bloodgroup?.vitalValue ? this.vitalObj.bloodgroup?.vitalValue : null ,
      bloodgroup: this.userPersonalData?.bloodgroup ? this.userPersonalData?.bloodgroup : this.vitalObj.bloodgroup?.vitalValue,
      weight: this.vitalObj.weight?.vitalValue ? this.vitalObj.weight?.vitalValue : null,
      feet: heigth?.[0] ? heigth?.[0] : null,
      inch: heigth?.[1] ? heigth?.[1] : null,
      oxygenLevel: this.vitalObj.oxygenLevel?.vitalValue ? this.vitalObj.oxygenLevel?.vitalValue : null,
      temperature: this.vitalObj.temperature?.vitalValue ? this.vitalObj.temperature?.vitalValue : null,
      pulse: this.vitalObj.pulse?.vitalValue ? this.vitalObj.pulse?.vitalValue : null,
      systolic: this.vitalObj.systolic?.vitalValue ? this.vitalObj.systolic?.vitalValue : null,
      diastolic: this.vitalObj.diastolic?.vitalValue ? this.vitalObj.diastolic?.vitalValue : null,
      fasting: this.vitalObj.fasting?.vitalValue ? this.vitalObj.fasting?.vitalValue : null,
      random: this.vitalObj.random?.vitalValue ? this.vitalObj.random?.vitalValue : null
    });
  }

  updateVitals(form) {

    let reqVitalsObj = [];
    if (this.globalStorage.getItem('loggedInAs') == 'IVF') {
      reqVitalsObj = this.createVitalsRequestBody(form, this.user_id).vitals;
    }
    else {
      reqVitalsObj = this.createVitalsRequestBody(form, this.userPersonalData?.userId ? this.userPersonalData?.userId : this.user_id).vitals;
    }

    let req = {
      "forUser": {
        "forUserId": this.user_id,
        "userId": this.globalStorage.getItem('userId')
      },
      vitals: reqVitalsObj
    }

    if (this.globalStorage.getItem('loggedInAs') == 'IVF') {
      this.ivfService.saveVitals(req, req.forUser.userId).subscribe(res => {
        if (res.body.status == "SUCCESS") {
          this.toastr.success("Vitals updated successfully.");
          // this.getPatientVitals();
          this.closeModal();
        }
      },
        error => {
          this.toastr.error(error?.['error']?.['message']);
        }
      )
    } else {
      req = {
        "forUser": {
          "forUserId": this.userPersonalData?.userId ? this.userPersonalData?.userId : this.user_id,
          "userId": this.globalStorage.getItem('userId')
        },
        vitals: reqVitalsObj
      }
      this.patientService.saveVitals(req, req.forUser.userId).subscribe(res => {
        if (res.body.status == "SUCCESS") {
          this.toastr.success("Vitals updated successfully.");
          this.getPatientVitals();
          this.closeModal();
        }
      },
        error => {
          this.toastr.error(error?.['error']?.['message']);
        }
      )
    }
  }

  getBMI(h,w) {
    if (h && w) {
      const height = parseFloat(h);
      const weightInLb = parseFloat(w);

      if (height && weightInLb) {
        const heightInMeters = height * 0.3048;
        const weightInKg = weightInLb; // Convert lb to kg
        // const weightInKg = weightInLb * 0.453592; // Convert lb to kg
        const bmi_temp = weightInKg / (heightInMeters * heightInMeters);
        return bmi_temp.toFixed(2);
      }
    }
    return null;
  }

  getHeight(vitalArray){
    return ((this.userVitalsForm.value.feet != null ? this.userVitalsForm.value.feet?.toString() : "") +
    (this.userVitalsForm.value.feet != null && this.userVitalsForm.value.inch != null ? "." : "") +
    (this.userVitalsForm.value.inch != null ? this.userVitalsForm.value.inch?.toString() : ""))
    // const vital= vitalArray.filter(v => v?.vitalname== "height")
    // return vital[0]?.vitalValue
  }

  getWeight(vitalArray){
    return this.userVitalsForm.value.weight?.toString();
    // const vital= vitalArray.filter(v => v?.vitalname== "weight")
    // return vital[0]?.vitalValue

  }

  createVitalsRequestBody(form, userId) {
    // const recordedDate = form.value.date; // Assuming this is in the correct format
    // const heightInInches = (form.value.feet * 12) + form.value.inch; // Convert height to inches
    // const heightInCms = heightInInches * 2.54; // Convert inches to centimeters

    const vitalsArray = [
      {
        userId: userId,
        metricType: "%",

        vitalValue: form.value.oxygenLevel?.toString() != this.vitalObj?.oxygenLevel?.vitalValue ? form.value.oxygenLevel?.toString() : null,
        vitalname: "oxygenLevel",
        vitalsId: this.vitalObj.oxygenLevel?.vitalsId || null
      },
      {
        userId: userId,
        metricType: "%",

        vitalValue: form.value.fasting?.toString() != this.vitalObj?.fasting?.vitalValue ? form.value.fasting?.toString() : null,
        vitalname: "fasting",
        vitalsId: this.vitalObj.fasting?.vitalsId || null
      },
      {
        userId: userId,
        metricType: "%",

        vitalValue: form.value.random?.toString() != this.vitalObj?.random?.vitalValue ? form.value.random?.toString() : null,
        vitalname: "random",
        vitalsId: this.vitalObj.random?.vitalsId || null
      },
      {
        userId: userId,
        metricType: "BPM",

        vitalValue: form.value.pulse?.toString() != this.vitalObj?.pulse?.vitalValue ? form.value.pulse?.toString() : null,
        vitalname: "pulse",
        vitalsId: this.vitalObj.pulse?.vitalsId || null
      },
      {
        userId: userId,
        metricType: "C",
        vitalValue: form.value.temperature?.toString() != this.vitalObj?.temperature?.vitalValue ? form.value.temperature?.toString() : null,
        vitalname: "temperature",
        vitalsId: this.vitalObj.temperature?.vitalsId || null
      },
      {
        userId: userId,
        metricType: "mm/Hg",
        vitalValue: ((form.value.systolic != null && form.value.diastolic != null) ?
          form.value.systolic?.toString() + "/" + form.value.diastolic?.toString() : null) != this.vitalObj?.bloodPressure?.vitalValue ? ((form.value.systolic != null && form.value.diastolic != null) ?
            form.value.systolic?.toString() + "/" + form.value.diastolic?.toString() : null) : null,
        vitalname: "bloodPressure",
        vitalsId: this.vitalObj.bloodPressure?.vitalsId || null
      },
      // {
      //   userId: userId,
      //   metricType: "mm/Hg",
      //   vitalValue: form.value.systolic?.toString() != this.vitalObj?.systolic?.vitalValue ? form.value.systolic?.toString() : null,
      //   vitalname: "systolic",
      //   vitalsId: this.vitalObj.systolic?.vitalsId || null
      // },
      // {
      //   userId: userId,
      //   metricType: "mm/Hg",
      //   vitalValue: form.value.diastolic?.toString() != this.vitalObj?.diastolic?.vitalValue ? form.value.diastolic?.toString() : null,
      //   vitalname: "diastolic",
      //   vitalsId: this.vitalObj.diastolic?.vitalsId || null
      // },
      {
        userId: userId,
        metricType: "Cms",
        vitalValue: ((form.value.feet != null ? form.value.feet?.toString() : "") +
          (form.value.feet != null && form.value.inch != null ? "." : "") +
          (form.value.inch != null ? form.value.inch?.toString() : "")) != this.vitalObj?.height?.vitalValue ? ((form.value.feet != null ? form.value.feet?.toString() : "") +
          (form.value.feet != null && form.value.inch != null ? "." : "") +
          (form.value.inch != null ? form.value.inch?.toString() : "")):null,
        vitalname: "height",
        vitalsId: this.vitalObj.height?.vitalsId || null
      },
      {
        userId: userId,
        metricType: "lb",
        vitalValue: form.value.weight?.toString() != this.vitalObj?.weight?.vitalValue ? form.value.weight?.toString() : null,
        vitalname: "weight",
        vitalsId: this.vitalObj.weight?.vitalsId || null
      }
    ]

    if(vitalsArray.filter(v => v?.vitalname== "weight")[0].vitalValue || vitalsArray.filter(v => v?.vitalname== "height")[0].vitalValue){
      vitalsArray.push({
        userId: userId,
          metricType: "kg/m2",
          vitalValue: this.getBMI(this.getHeight(vitalsArray),this.getWeight(vitalsArray)),
          vitalname: "bmi",
          vitalsId: this.vitalObj.bmi?.vitalsId || null
      })
    }
    if(form.value.systolic?.toString() != this.vitalObj?.systolic?.vitalValue || form.value.diastolic?.toString() != this.vitalObj?.diastolic?.vitalValue){
      vitalsArray.push({
        userId: userId,
        metricType: "mm/Hg",
        vitalValue: form.value.systolic?.toString(),
        vitalname: "systolic",
        vitalsId: this.vitalObj.systolic?.vitalsId
      },
      {
        userId: userId,
        metricType: "mm/Hg",
        vitalValue:  form.value.diastolic?.toString(),
        vitalname: "diastolic",
        vitalsId: this.vitalObj.diastolic?.vitalsId
      })
    }

    return {
      vitals: vitalsArray.filter(vitalObj => vitalObj?.vitalValue)
    };
  }

}
