import { GlobalStorageService } from 'src/app/global-storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ThirdPartyDraggable } from '@fullcalendar/interaction';
import { Observable, Subject } from 'rxjs';
import { ApicallsService } from 'src/app/apicalls.service';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PatientService {

  private joinCallSubject = new Subject<any>();
  joinCallObservable = this.joinCallSubject.asObservable();

  myMethod$: Observable<any>;
  private myMethodSubject = new Subject<any>();

  private closeVideoCallingSubject = new Subject<any>();
  closeVideoCallingObservable = this.closeVideoCallingSubject.asObservable();
  baseUrl = environment.patientBaseUrl
  diagnosticUrl = environment.diagnosticBaseUrl
  elesticUrl = environment.elesticSearchUrl
  utilitiesUrl=environment.utilitiesUrl
  lookup = environment.utilitiesUrl
  ivfUrl=environment.ivfUrl
  //  baseUrl='http://localhost:8089'

  constructor(private globalStorage: GlobalStorageService, private http: HttpClient, private apiService: ApicallsService) {
    this.myMethod$ = this.myMethodSubject.asObservable();
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      this.baseUrl = environment.patientBaseUrl1
      this.diagnosticUrl = environment.diagnosticBaseUrl1
      this.elesticUrl = environment.elesticSearchUrl1
      this.utilitiesUrl=environment.utilitiesUrl1
      this.lookup = environment.utilitiesUrl1
      this.ivfUrl=environment.ivfUrl1
    }
  }

  myMethod(data) {
    this.myMethodSubject.next(data);
  }

  languages() {
    return this.apiService.get(this.lookup + 'lookupstatic/getlookupstaticbylookuptypeandname?module=LANGUAGE&type=LANGUAGE', { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) });
  }



  saveVitals(request: any, userId: any): Observable<any> {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'vitals/save',
      request, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
        , 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId?.toString()
      }), observe: 'response'
    })
  }
  getAllVitalsByDayByDay(request: any, userId: any): Observable<any> {
    let params = new HttpParams().set('forUserId', userId);
    // let params2 =new HttpParams().set('&moduleName', this.globalStorage.getItem('loggedInAs'));
    return this.apiService.post(this.baseUrl + 'vitals/get',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }
  getIvfPatientVitals(addressId,userId){
    if(userId){
      return this.apiService.get(this.ivfUrl+'getUserVitals?ivfAddressId='+addressId+'&userId='+userId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
    }
  }
  getAllVitals(request: any, userId: any): Observable<any> {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'vitals/latest',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }

  // updateUser(request):Observable<any>{
  //   let params = new HttpParams().set('forUserId', userId);
  //   return this.apiService.post(this.baseUrl+'updateUser',
  //   request, { headers: new HttpHeaders({ 'user-session':JSON.parse(this.globalStorage.getItem('userSession')) }),observe: 'response' })
  // }
  updateProfilePic(request: any, userId: any): Observable<any> {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'profilepic/' + userId,
      request, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  saveAddress(request: any, userId: any): Observable<any> {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'address/save',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  getAddress(request: any, userId: any): Observable<any> {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'address/get',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  getPersonalDetails(request: any, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'personaldetails/get/', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  getPatientAsoociatedUsers(userId, body) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'assocation/allAssociateUser', body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }

  deletepatientAddress(request: any, userId: any) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.delete(this.baseUrl + 'address/delete/' + request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  jointCall(appointment) {
    this.joinCallSubject.next(appointment)
  }

  closeVideoCalling() {
    this.closeVideoCallingSubject.next();
  }

  getAddressAll(postal) {
    return this.apiService.get('https://api.worldpostallocations.com/pincode?postalcode=' + postal + '&countrycode=IN', {})
  }

  getAddressPatient(postal, cc) {
    return this.apiService.get('https://api.worldpostallocations.com/pincode?postalcode=' + postal + '&countrycode=' + cc, {})
  }
  getPincodeDetails(postal, cc){
    return this.apiService.get(this.utilitiesUrl+'countrycodecontroller/getCountrycode?countrycode='+cc+'&postalcode='+postal,{ })
  }

  getAddressDoctor(postal, cc) {
    return this.apiService.get('https://api.worldpostallocations.com/pincode?postalcode=' + postal + '&countrycode=' + cc, {})
  }

  getAddressDiag(postal, cc) {
    return this.apiService.get('https://api.worldpostallocations.com/pincode?postalcode=' + postal + '&countrycode=' + cc, {})
  }

  getAddressByPincode(postal, countryCode) {
    return this.apiService.get('https://api.worldpostallocations.com/pincode?postalcode=' + postal + '&countrycode=' + countryCode, {})
  }
  getPharmacyByLatAndLong(body) {
    return this.apiService.post(this.elesticUrl + 'elasticsearchPharmacyController/getLocationMembers', body, {})
  }
  getDcByLatAndLong(body) {
    return this.apiService.post(this.elesticUrl + 'elasticsearchDiagnosticcenterController/getLocationMembers', body, {})
  }
  getCaretakerLatAndLong(body) {
    return this.apiService.post(this.elesticUrl + 'caretakercontroller/getLocationMembers', body, {})
  }
  updateUser(body, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'updateUserNew', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  updateUserMobile(body, userId){
    return this.apiService.post(this.baseUrl + 'updateUserNew', body, {})
  }
  removePreferredPharmacy(refId, userId) {
    return this.apiService.delete(this.baseUrl + 'delete/user/refrence?userreferenceId=' + refId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  removePreferredCT(refId, userId) {
    return this.apiService.delete(this.baseUrl + 'delete/user/refrence?referenceType=CARETAKER&userId=' + userId + '&userreferenceId=' + refId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  updatePreffered(body, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'save/userreference', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  deletePrefferedDoctor(refType, userId, userRefId) {
    return this.apiService.delete(this.baseUrl + "delete/user/refrence?referenceType=" + refType + "&userId=" + userId + "&userreferenceId=" + userRefId, {})
  }
  selectPrefferedCT(body, userId) {
    return this.apiService.post(this.baseUrl + 'save/userreference', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  getPrefferedDoctor(id, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'getaluserbyreferencetype/' + id, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }
  getPrefferedDiagnostic(id, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'getaluserbyreferencetype/' + id, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }

  getPrefferedCareTakers(id, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'getaluserbyreferencetype/' + id, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }

  getPrefferedPharma(id, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.baseUrl + 'getaluserbyreferencetype/' + id, {}, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': id.toString() }), observe: 'response' })
  }

  diagnosticGetPathSession(path) {
    return this.apiService.get(this.diagnosticUrl + path, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getNationalty() {
    return this.apiService.get(this.lookup + "lookupstatic/getlookupstaticbylookuptypeandname?module=COUNTRY&type=COUNTRY", { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }

  getDiagnosticCentersPrimaryLoc(userId) {
    return this.apiService.get(this.baseUrl + "getPrimaryLocationForDiagnostics/" + userId.toString(), { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' });
  }
}
