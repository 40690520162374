import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Crypto } from '../../_helpers/CryptoJ'
import { NotificationService } from 'src/app/shared/notification.service';
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { PatientService } from 'src/app/shared/services/patient.service';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import { DiagnosticService } from 'src/app/AppServices/diagnostic.service';
import { DiagnosticServiceController } from 'src/app/shared/services/diagnostic.service';
import * as moment from 'moment';
import { ApicallsService } from 'src/app/apicalls.service';
import { DiagnosticNotificationService } from 'src/app/shared/diagnostic-notification.service';
declare var $: any;
@Component({
  selector: 'app-diagnostic-login',
  templateUrl: './diagnostic-login.component.html',
  styleUrls: ['./diagnostic-login.component.css']
})
export class DiagnosticLoginComponent implements OnInit {
  forgetPassword = false
  verifyNowButton = false
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  cond_vall = false
  rememberme: boolean = false;
  email
  message
  password
  invalidCredentials: boolean = false
  constructor(private globalStorage: GlobalStorageService, 
    private formBuilder: FormBuilder, private apiservice: ApicallsService,
    private scripts: ScriptsService, private diagnosticService: DiagnosticServiceController,
    private router: Router, private cookieService: CookieService,
    private crpto: Crypto,
    private notification: NotificationService,
    private ToastrService: ToastrService,
    private dcnotificationService:DiagnosticNotificationService
    ) {
    if (this.cookieService.get('rememberme') == 'Yes' && this.cookieService.get('module') == 'DiagnosticCenter') {
      this.rememberme = true;
      this.email = this.crpto.Decrypt(this.cookieService.get('email'), 'email');
      this.password = this.crpto.Decrypt(this.cookieService.get('password'), 'password');
    }
  }

  ngOnInit() {

    // if (navigator.userAgent.match(/Android/i)) {
    //   window.location.href = 'https://play.google.com/store/apps/details?id=com.curemetric.diagnostic_center.diagnostic_center'
    // }
    // else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    //   window.location.href = 'https://apps.apple.com/in/app/curemetric-diagnostics/id6450884757'
    // }

    $(function () {
      $(window).off('beforeunload');
      $("#preloader").fadeOut();
      $("#status").fadeOut(9000);
    });
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  get pwd() {
    return this.registerForm.get('password');
  }
  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
  }


  navigateToEnquiryForm(): void {
    this.router.navigate(['/enquiry-form'], { queryParams: { module:'dc', returnUrl: this.router.url } });
}
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }


    if (this.rememberme) {
      this.cookieService.set('email', this.crpto.encryption(this.email, "email"));
      this.cookieService.set('password', this.crpto.encryption(this.password, 'password',));
      this.cookieService.set('rememberme', 'Yes')
      this.cookieService.set('module', 'DiagnosticCenter')
    } else {
      this.cookieService.set('email', "");
      this.cookieService.set('password', "");
      this.cookieService.set('rememberme', "")
    }
    this.apiservice.isUserSessionExits = false
    this.apiservice.isforUserIdExits = false
    this.apiservice.ismoduleNameExits = false
    this.globalStorage.clear()

    var loginDetails = { "userName": this.registerForm.value.email, "password": this.registerForm.value.password }
    this.diagnosticService.diagnosticPostBody('login', loginDetails).subscribe(res => {
      if (res?.data?.verficationDone == 'N') {
        this.verifyNowButton = true;
        this.invalidCredentials = true;
        this.message == "Your Email Id is not verified";
      }
      if (res['data'] != null && res['userSession'] != null) {
        var user = res
        var userData = user['data']                //JSON.parse(res["data"])
        this.globalStorage.setItem('diagnosticData', JSON.stringify(userData))
        var data = this.globalStorage.getItem('diagnosticData')
        var objs = JSON.parse(data)
        var today = moment(objs['lastLogin']).format('YYYY-MM-DD HH:mm:ss');
        this.globalStorage.setItem('dgpswd', this.registerForm.value.password)
        sessionStorage.setItem('diagnosticData', JSON.stringify(userData))
        this.globalStorage.setItem('userAndSession', JSON.stringify(res))
        this.globalStorage.setItem('userSession', JSON.stringify(user['userSession']));
        
        this.cookieService.set('usersession',JSON.stringify(user['userSession']))
        this.globalStorage.setItem('loggedInAs', 'DIAGNOSTIC_CENTER'),
          this.globalStorage.setItem('forUserId', JSON.stringify(userData.diagnosticCenterUserId))
        this.globalStorage.setItem('diagnosticCenterAddressId', JSON.stringify(userData.diagnosticCenterAddressId));
        this.globalStorage.setItem('diagnosticCenterUserId', JSON.stringify(userData.diagnosticCenterUserId));
        this.globalStorage.setItem('diagnosticCenterId', JSON.stringify(userData.diagnosticcenterId));
        this.globalStorage.setItem('userId', JSON.stringify(userData.diagnosticCenterAddressId));
        this.globalStorage.setItem('entityId', JSON.stringify(userData.diagnosticCenterAddressId));
        this.globalStorage.setItem('roles', JSON.stringify(userData.roles));

        this.apiservice.isUserSessionExits = true
        this.apiservice.isforUserIdExits = true
        this.apiservice.ismoduleNameExits = true

        //roles
        //roles
        //this.doctorSaervice.navigationDetails();
        //this.ToastrService.success("Login Successfully","Login");

        //  this.patientController.getNotificationsAlertsDoctor('notificationalerts/get/week/',(this.globalStorage.getItem('doctorId'))).subscribe(res=>{
        //     this.globalStorage.setItem('notificationCount',res['data'].length)
        //     var array=res['data'].reverse().slice(-5)
        //    for (var i=0;i<array.length;i++){
        //     this.ToastrService.info(res['data'][i]['reason'],res['data'][i]['objectType'],{
        //      positionClass: 'toast-bottom-right'
        //   })
        //    }
        //  },error=>{
        //  })
        this.router.navigate(['/diagnosticDashboard'])
        this.dcnotificationService.requestPermission(parseInt(this.globalStorage.getItem('diagnosticCenterAddressId')));
        
        this.diagnosticService.diagnosticGetPathSession('address/id/' + this.globalStorage.getItem('diagnosticCenterAddressId')).subscribe(res => {
          if (res['data']['address1'] == 'test' && res['data']['address2'] == 'test' && res['data']['city'] == 'test' && res['data']['zip'] == 'test' && res['data']['state'] == 'test' && res['data']['country'] == 'test') {
            this.router.navigate(['/diagnosticProfile'])
            this.ToastrService.info('Please fill address and personal details')
          }
        })

      }
      else {
        // this.ToastrService.error(res['message']);
      }

    }, error => {
      this.message = error.error.message
      if (this.message != null) {
        this.invalidCredentials = true
        this.message = error.error.message
        //this.ToastrService.error("Invalid Login - Either User Name or Password is wrong. Please check.","Login");
        //this.LoginDetails.reset();
        if (error.status == 400) {
          // this.verifyNowButton = true
        }
        if(error?.error?.message == 'Error logging in Your Email Id Is  Unverified.'){
          this.verifyNowButton = true
        }
      }
      else {
        this.invalidCredentials = true
        this.message = "Error in Login service"
      }

    })


  }
  change() {
    this.cond_vall = !this.cond_vall

  }
  forgotPassword() {
    this.router.navigate(['/diagnosticForgot'])
  }
  remembermeCheck() {
    if (this.rememberme) {
      this.rememberme = false;
    } else {
      this.rememberme = true;
    }
  }
  verifyNow() {
    this.router.navigate(['/verifyProfileDia'])
  }

  showValidationMessage: boolean = false;
  preventCopy(event: ClipboardEvent) {
    event.preventDefault();
    this.showValidationMessage = true;
    setTimeout(() => {
      this.showValidationMessage = false;
    }, 5000);
  }


  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });
  }
  resetMessage(){
    this.invalidCredentials = false
  }

}
