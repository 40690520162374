import { Component, ElementRef, ViewChild, AfterViewInit, Renderer2, ViewEncapsulation, OnDestroy } from '@angular/core';
import { createLocalVideoTrack, LocalVideoTrack } from 'twilio-video';
import { PatientService } from '../../services/patient.service';
import { StorageService } from '../../services/storage.service';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-camera',
    styleUrls: ['./camera.component.css'],
    templateUrl: './camera.component.html',
    encapsulation: ViewEncapsulation.None
})
export class CameraComponent implements AfterViewInit ,OnDestroy{
    @ViewChild('preview', { static: false }) previewElement: ElementRef;

    isInitializing: boolean = true;
    videoTrack: LocalVideoTrack = null;
    private destroy$ = new Subject<void>();


    constructor(
        private readonly storageService: StorageService, private video: PatientService, private doctorPatient: DoctorPatientService,
        private readonly renderer: Renderer2) {
            this.video.joinCallObservable.pipe(takeUntil(this.destroy$)).subscribe(scheduler => {
                this.LoadCamara();
            });
    
            this.video.closeVideoCallingObservable.pipe(takeUntil(this.destroy$)).subscribe(scheduler => {
                this.closeCemera();
            });
        // this.doctorPatient.closeVideoCallingObservable.subscribe(scheduler => {
        //     this.closeCemera();
        // })
    }


    LoadCamara() {
        if (this.previewElement && this.previewElement.nativeElement) {
            const selectedVideoInput = this.storageService.get('videoInputId');
            this.initializeDevice(selectedVideoInput);
        }
    }
    async ngAfterViewInit() {
        // if (this.previewElement && this.previewElement.nativeElement) {
        //     const selectedVideoInput = this.storageService.get('videoInputId');
        //     await this.initializeDevice(selectedVideoInput);
        // }
    }

    async initializePreview(deviceId: string) {
        await this.initializeDevice(deviceId);
    }

    finalizePreview() {
        try {
            if (this.videoTrack) {
                this.videoTrack.stop();
                this.videoTrack.detach().forEach(element => element.remove());
                this.videoTrack.mediaStreamTrack.stop(); // Ensure the media stream track is stopped
                this.videoTrack = null;
            }
            this.videoTrack = null;
        } catch (e) {
            console.error(e);
        }
    }

    private async initializeDevice(deviceId?: string) {
        try {
            this.isInitializing = true;

            this.finalizePreview();
            console.log('camera is initializing')
            this.videoTrack = deviceId
                ? await createLocalVideoTrack({ deviceId })
                : await createLocalVideoTrack();

            const videoElement = this.videoTrack.attach();
            this.renderer.setStyle(videoElement, 'min-height', '50%');
            this.renderer.setStyle(videoElement, 'width', '35%');
            this.renderer.appendChild(this.previewElement.nativeElement, videoElement);
        } finally {
            this.isInitializing = false;
        }
    }

    closeCemera() {
        if (this.videoTrack != null) {
            this.videoTrack.stop();
            this.videoTrack.detach().forEach(element => element.remove());
            this.videoTrack.mediaStreamTrack.stop(); // Ensure the media stream track is stopped
            this.videoTrack = null;
        }
    }

    ngOnDestroy() {
        this.closeCemera()
        this.destroy$.next();
        this.destroy$.complete();
    }

}
