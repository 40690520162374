import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms'
import { Router } from '@angular/router';
import { DoctorService } from '../../AppServices/doctor.service'
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
import * as moment from 'moment'; import { PatientService } from 'src/app/shared/services/patient.service';
import { filter, startWith, map, switchMap } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-doctor-personal-details',
  templateUrl: './doctor-personal-details.component.html',
  styleUrls: ['./doctor-personal-details.component.css']
})
export class DoctorPersonalDetailsComponent implements OnInit {
  personalDetails: FormGroup;
  documentGrp: FormGroup; phoneNumberField = false;
  file
  minDate = new Date(1945, 0, 1);
  maxDate = new Date();
  @Input() visable: any;
  remoteData
  @Output() childComponentValue = new EventEmitter<any>();
  expLength = 2;
  dotTimes = 0;
  constructor(private globalStorage: GlobalStorageService, private fb: FormBuilder,private router: Router, private update: PatientService, private sanitizer: DomSanitizer, private DoctorService: DoctorService, private ToastrService: ToastrService, private forgot: AuthService) {

    this.remoteData = this.nationality2
    this.documentGrp = this.fb.group({
      // report_name: ['', Validators.required],
      // report_description: ['', Validators.required],
      // report_Date: ['', Validators.required],
      signature: [''],
      documentFile: new FormControl(File)

    });
  }

  loginUser; maxLength
  nativeLanguage: any[] = [
    { id: 1, value: 'English' }, { id: 2, value: 'Tamil' }, { id: 3, value: 'Telugu' }, { id: 4, value: 'Hindi' }, { id: 5, value: 'Kannada' }, { id: 6, value: 'Malayalam' }
  ]
  nationality: any[] = [
    { id: 1, value: "Afghan" }, { id: 2, value: "Albanian" }, { id: 3, value: "Algerian" }, { id: 4, value: "American" }, { id: 5, value: "Andorran" }, { id: 6, value: "Angolan" }, { id: 7, value: "Anguillan" }, { id: 8, value: "Argentine" }, { id: 9, value: "Armenian" }, { id: 10, value: "Australian" }, { id: 11, value: "Austrian" }, { id: 12, value: "Azerbaijani" }, { id: 13, value: "Bahamian" }, { id: 14, value: "Bahraini" }, { id: 15, value: "Bangladeshi" }, { id: 16, value: "Barbadian" }, { id: 17, value: "Belarusian" }, { id: 18, value: "Belgian" }, { id: 19, value: "Belizean" }, { id: 20, value: "Beninese" },
    { id: 21, value: "Bermudian" }, { id: 22, value: "Bhutanese" }, { id: 23, value: "Bolivian" }, { id: 24, value: "Botswanan" }, { id: 25, value: "Brazilian" }, { id: 26, value: "British" }, { id: 27, value: "British Virgin Islander" }, { id: 28, value: "Bruneian" }, { id: 29, value: "Bulgarian" }, { id: 30, value: "Burkinan" }, { id: 31, value: "Burmese" }, { id: 32, value: "Burundian" }, { id: 33, value: "Cambodian" }, { id: 34, value: "Cameroonian" }, { id: 35, value: "Canadian" }, { id: 36, value: "Cape Verdean" }, { id: 37, value: "Cayman Islander" }, { id: 38, value: "Central African" }, { id: 39, value: "Chadian" }, { id: 40, value: "Chilean" },
    { id: 41, value: "Chinese" }, { id: 42, value: "Citizen of Antigua and Barbuda" }, { id: 43, value: "Citizen of Bosnia and Herzegovina" }, { id: 44, value: "Citizen of Guinea-Bissau" }, { id: 45, value: "Citizen of Kiribati" }, { id: 46, value: "Citizen of Seychelles" }, { id: 47, value: "Citizen of the Dominican Republic" }, { id: 48, value: "Citizen of Vanuatu" }, { id: 49, value: "Colombian" }, { id: 50, value: "Comoran" }, { id: 51, value: "Congolese (Congo)" }, { id: 52, value: "Congolese (DRC)" }, { id: 53, value: "Cook Islander" }, { id: 54, value: "Costa Rican" }, { id: 55, value: "Croatian" }, { id: 56, value: "Cuban" }, { id: 57, value: "Cymraes" }, { id: 58, value: "Cymro" }, { id: 59, value: "Cypriot" }, { id: 60, value: "Czech" },
    { id: 61, value: "Danish" }, { id: 62, value: "Djiboutian" }, { id: 63, value: "Dominican" }, { id: 64, value: "Dutch" }, { id: 65, value: "East Timorese" }, { id: 66, value: "Ecuadorean" }, { id: 67, value: "Egyptian" }, { id: 68, value: "Emirati" }, { id: 69, value: "English" }, { id: 70, value: "Equatorial Guinean" }, { id: 71, value: "Eritrean" }, { id: 72, value: "Estonian" }, { id: 73, value: "Ethiopian" }, { id: 74, value: "Faroese" }, { id: 75, value: "Fijian" }, { id: 76, value: "Filipino" }, { id: 77, value: "Finnish" }, { id: 78, value: "French" }, { id: 79, value: "Gabonese" }, { id: 80, value: "Gambian" },
    { id: 81, value: "Georgian" }, { id: 82, value: "German" }, { id: 83, value: "Ghanaian" }, { id: 84, value: "Gibraltarian" }, { id: 85, value: "Greek" }, { id: 86, value: "Greenlandic" }, { id: 87, value: "Grenadian" }, { id: 88, value: "Guamanian" }, { id: 89, value: "Guatemalan" }, { id: 90, value: "Guinean" }, { id: 91, value: "Guyanese" }, { id: 92, value: "Haitian" }, { id: 93, value: "Honduran" }, { id: 94, value: "Hong Konger" }, { id: 95, value: "Hungarian" }, { id: 96, value: "Icelandic" }, { id: 97, value: "Indian" }, { id: 98, value: "Indonesian" }, { id: 99, value: "Iranian" }, { id: 100, value: "Iraqi" },
    { id: 101, value: "Irish" }, { id: 102, value: "Israeli" }, { id: 103, value: "Italian" }, { id: 104, value: "Ivorian" }, { id: 105, value: "Jamaican" }, { id: 106, value: "Japanese" }, { id: 107, value: "Jordanian" }, { id: 108, value: "Kazakh" }, { id: 109, value: "Kenyan" }, { id: 110, value: "Kittitian" }, { id: 111, value: "Kosovan" }, { id: 112, value: "Kuwaiti" }, { id: 113, value: "Kyrgyz" }, { id: 114, value: "Lao" }, { id: 115, value: "Latvian" }, { id: 116, value: "Lebanese" }, { id: 117, value: "Liberian" }, { id: 118, value: "Libyan" }, { id: 119, value: "Liechtenstein citizen" }, { id: 120, value: "Lithuanian" },
    { id: 121, value: "Luxembourger" }, { id: 122, value: "Macanese" }, { id: 123, value: "Macedonian" }, { id: 124, value: "Malagasy" }, { id: 125, value: "Malawian" }, { id: 126, value: "Malaysian" }, { id: 127, value: "Maldivian" }, { id: 128, value: "Malian" }, { id: 129, value: "Maltese" }, { id: 130, value: "Marshallese" }, { id: 131, value: "Martiniquais" }, { id: 132, value: "Mauritanian" }, { id: 133, value: "Mauritian" }, { id: 134, value: "Mexican" }, { id: 135, value: "Micronesian" }, { id: 136, value: "Moldovan" }, { id: 137, value: "Moldovan" }, { id: 138, value: "Monegasque" }, { id: 139, value: "Mongolian" }, { id: 140, value: "Montenegrin" },
    { id: 141, value: "Montserratian" }, { id: 142, value: "Moroccan" }, { id: 143, value: "Mosotho" }, { id: 144, value: "Mozambican" }, { id: 145, value: "Namibian" }, { id: 146, value: "Nauruan" }, { id: 147, value: "Nepalese" }, { id: 148, value: "New Zealander" }, { id: 149, value: "Nicaraguan" }, { id: 150, value: "Nigerian" }, { id: 151, value: "Nigerien" }, { id: 152, value: "Niuean" }, { id: 153, value: "North Korean" }, { id: 154, value: "Northern Irish" }, { id: 155, value: "Norwegian" }, { id: 156, value: "Omani" }, { id: 157, value: "Pakistani" }, { id: 158, value: "Palauan" }, { id: 159, value: "Palestinian" }, { id: 160, value: "Panamanian" },
    { id: 161, value: "Papua New Guinean" }, { id: 162, value: "Paraguayan" }, { id: 163, value: "Peruvian" }, { id: 164, value: "Pitcairn Islander" }, { id: 165, value: "Polish" }, { id: 166, value: "Portuguese" }, { id: 167, value: "Prydeinig" }, { id: 168, value: "Puerto Rican" }, { id: 169, value: "Qatari" }, { id: 170, value: "Romanian" }, { id: 171, value: "Russian" }, { id: 172, value: "Rwandan" }, { id: 173, value: "Salvadorean" }, { id: 174, value: "Sammarinese" }, { id: 175, value: "Samoan" }, { id: 176, value: "Sao Tomean" }, { id: 177, value: "Saudi Arabian" }, { id: 178, value: "Scottish" }, { id: 179, value: "Senegalese" }, { id: 180, value: "Serbian" },
    { id: 181, value: "Sierra Leonean" }, { id: 182, value: "Singaporean" }, { id: 183, value: "Slovak" }, { id: 184, value: "Slovenian" }, { id: 185, value: "Solomon Islander" }, { id: 186, value: "Somali" }, { id: 187, value: "South African" }, { id: 188, value: "South Korean" }, { id: 189, value: "South Sudanese" }, { id: 190, value: "Spanish" }, { id: 191, value: "Sri Lankan" }, { id: 192, value: "St Helenian" }, { id: 193, value: "St Lucian" }, { id: 194, value: "Stateless" }, { id: 195, value: "Sudanese" }, { id: 196, value: "Surinamese" }, { id: 197, value: "Swazi" }, { id: 198, value: "Swedish" }, { id: 199, value: "Swiss" }, { id: 200, value: "Syrian" },
    { id: 201, value: "Taiwanese" }, { id: 202, value: "Tajik" }, { id: 203, value: "Thai" }, { id: 204, value: "Tanzanian" }, { id: 205, value: "Thai" }, { id: 206, value: "Togolese" }, { id: 207, value: "Tongan" }, { id: 208, value: "Trinidadian" }, { id: 209, value: "Tristanian" }, { id: 210, value: "Tunisian" }, { id: 211, value: "Turkish" }, { id: 212, value: "Turkmen" }, { id: 213, value: "Turks and Caicos Islander" }, { id: 214, value: "Tuvaluan" }, { id: 215, value: "Ugandan" }, { id: 216, value: "Ukrainian" }, { id: 217, value: "Uruguayan" }, { id: 218, value: "Uzbek" }, { id: 219, value: "Vatican citizen" }, { id: 220, value: "Venezuelan" },
    { id: 221, value: "Vietnamese" }, { id: 222, value: "Vincentian" }, { id: 223, value: "Wallisian" }, { id: 224, value: "Welsh" }, { id: 225, value: "Yemeni" }, { id: 226, value: "Zambian" }, { id: 227, value: "Zimbabwean" }
  ];
  nationality2 = [];

  selected = [
    { id: 97, value: "Indian" }
  ];
  // degree: any[] = [{ id: "1", value: 'Master of Clinical Medicine (MCM)' }, { id: "2", value: 'Master of Medical Science (MMSc, MMedSc)' }, { id: "3", value: 'Master of Medicine (MM, MMed)' },
  // { id: "4", value: 'Master of Surgery (MS, MSurg, MChir, MCh, ChM, CM)' }, { id: "5", value: 'Master of Science in Medicine or Surgery (MSc)' }, { id: "6", value: 'Doctor of Clinical Medicine (DCM)' },
  // { id: "7", value: 'Doctor of Clinical Surgery (DClinSurg)' }, { id: "8", value: 'Doctor of Medical Science (DMSc, DMedSc)' }, { id: "9", value: 'Doctor of Surgery (DS, DSurg)' },
  // { id: "10", value: 'MBBS – Bachelor of Medicine, Bachelor of Surgery' }, { id: "11", value: 'BDS – Bachelor of Dental Surgery' },
  // { id: "12", value: 'BAMS – Bachelor of Ayurvedic Medicine and Surgery' },
  // { id: "13", value: 'BUMS – Bachelor of Unani Medicine and Surgery' },
  // { id: "14", value: 'BHMS – Bachelor of Homeopathy Medicine and Surgery' },
  // { id: "15", value: 'BYNS- Bachelor of Yoga and Naturopathy Sciences' },
  // { id: "16", value: 'B.V.Sc & AH- Bachelor of Veterinary Sciences and Animal Husbandry' },
  // { id: "17", value: 'Bachelor of Occupational Therapy' },
  // { id: "18", value: 'Bachelor of Science in Biotechnology' },
  // { id: "19", value: 'Bachelor of Technology in Biomedical Engineering' }, { id: "20", value: 'Bachelor of Science in Microbiology (Non-Clinical)' },
  // { id: "21", value: 'Bachelor of Science in Cardiac or Cardiovascular Technology' },
  // { id: "22", value: 'Bachelor of Perfusion Technology or Bachelor of Science in Cardio-Pulmonary Perfusion Technology' },
  // { id: "23", value: 'Bachelor of Respiratory Therapy' },
  // { id: "24", value: 'Bachelor of Science in Nutrition and Dietetics' },
  // { id: "25", value: 'Bachelor of Science in Genetics' }];

  degree: [];


  search2: any
  search3: any
  search1: any
  selectedValue: any;
  searchTxt: any;
  languagesList: any[];
  module;

  ngOnInit(): void {
    this.personalDetails = this.fb.group({
      dob: ['', Validators.required],
      personalDetailId: [],
      doctorId: [],
      profilePic: [],
      emergencyContactNumber: ['', [Validators.required]],
      preferredlanguages: [''],
      nativelanguage: [''],
      highestDegree: ['', Validators.required],
      nationality: [],
      specialization: [],
      ethinicity: [],
      doctorSignature: [''],
      totalExperience: ['', [Validators.required, Validators.min(0)]]
      //totalExperience: [, [Validators.required, Validators.max(50), Validators.min(1), Validators.minLength(2), Validators.maxLength(5)]],
    });
    $(document).ready(function() {
      $('.iti__selected-flag').attr('tabindex', '-1');
  });
    var curDate = new Date();
    var year2 = curDate.getFullYear();
    var month2 = curDate.getMonth();
    var day2 = curDate.getDay();
    this.maxDate = new Date(year2 - 18, month2, day2);

    this.getLanguages();
    var data = JSON.parse(this.globalStorage.getItem('LoginDetails'));
    this.module = data['data']['type'];

    this.loginUser = data.data;
    this.getPersonalDetails();
    this.getNationality();
    this.getDegrees();
    $(this).tooltip('dispose');
    $('.selectpicker').selectpicker();
    if(this.isEdit==false){
      const mySelect = document.getElementById('sur');
      this.condition=true
      mySelect.tabIndex = this.condition ? 0 : -1;
      console.log('asdsf')
      $('#sur').addClass('p-no')
      $('.emer').addClass('p-no')
    }
  }

  get p() {
    return this.personalDetails.controls;
  }
  personalDtsMessage
  getPersonalDetails() {
    this.DoctorService.getPersonalDetails(this.loginUser.doctorId).subscribe(res => {
      if (res['data'] == null) {
        this.personalDtsMessage = '*  Fill personal details.'
      }
      this.LoadPersonalDetails(res['data']);
      this.globalStorage.setItem('Age', res['data']['DOB'])
    }, error => {
      this.ToastrService.error("Error in getting personal details")
    });
  }
  // onSelect(val) {
  //   return this.nationality2 = this.nationality2.filter(x => x.value == val)
  // }
  getNationality() {
    this.update.getNationalty().subscribe(res => {
      this.nationality2 = res['body']['data'];
    });
  }

  getDegrees() {

    this.DoctorService.getCertificationDegrees(this.module, "DEGREE").subscribe(res => {
      this.degree = res['data'];
    })
  }
  ImageSrc
  setPersonalDetails; maxlengthphno
  imgURL; isSignature; docSignatureImg
  LoadPersonalDetails(profileDetails) {

    this.setPersonalDetails = profileDetails
    if (this.setPersonalDetails?.emergencyContactNumber != null || this.setPersonalDetails?.emergencyContactNumber != undefined) {
      this.phoneNumberField = true
      this.maxlengthphno = 13
    }
    else {
      this.phoneNumberField = false
      this.maxLength = 10
    }
    this.childComponentValue.emit(this.setPersonalDetails);
    if (this.setPersonalDetails?.doctorSignature != null) {
      this.docSignatureImg = "data:image/jpeg;base64," + this.setPersonalDetails?.doctorSignature
      this.docSignatureImg = this.sanitizer.bypassSecurityTrustResourceUrl(this.docSignatureImg);
      this.isSignature = true
      this.documentGrp.controls['signature'].patchValue('Doctor-Signature')
    }
    else {
      this.isSignature = false
    }
    if (this.setPersonalDetails != undefined && this.setPersonalDetails != null) {
      // personalDetailId
      this.personalDetails.controls['personalDetailId'].patchValue(this.setPersonalDetails?.personalDetailId)
      var dob = new Date(this.setPersonalDetails?.dob)
      this.personalDetails.controls['dob'].patchValue(dob)
      this.personalDetails.controls['doctorId'].patchValue(this.setPersonalDetails?.doctorId)
      this.personalDetails.controls['emergencyContactNumber'].patchValue(this.setPersonalDetails?.emergencyContactNumber)
      var array = this.setPersonalDetails?.preferredlanguages?.split(",")
      this.personalDetails.controls['preferredlanguages'].patchValue(array)
      this.personalDetails.controls['nativelanguage'].patchValue(this.setPersonalDetails?.nativelanguage)
      this.personalDetails.controls['highestDegree'].patchValue(this.setPersonalDetails?.highestDegree)
      this.personalDetails.controls['nationality'].patchValue(this.setPersonalDetails?.nationality)
      this.personalDetails.controls['ethinicity'].patchValue(this.setPersonalDetails?.ethinicity)
      this.personalDetails.controls['specialization'].patchValue(this.setPersonalDetails?.specialization);
      this.personalDetails.controls['totalExperience'].patchValue(this.setPersonalDetails?.totalExperience);

      if (this.setPersonalDetails['profilePic'] != null) {
        this.personalDetails.controls['profilePic'].patchValue(this.setPersonalDetails?.profilePic)
        this.ImageSrc = "data:image/jpeg;base64," + this.setPersonalDetails['profilePic']
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.ImageSrc);
      } else {

        this.imgURL = "/assets/img/avatar.png";
      }

    }
  }
  ngAfterViewInit() {
    $('.iti').toggleClass('w-100')
  }
  sendToParent() {
    this.childComponentValue.emit(this.setPersonalDetails);
  }
  phnum(value) {
    if (value?.length == 0) {
      this.phoneNumberField = false
      this.maxLength = 10
    }
    if (value?.length > 10 && value?.length < 12) {
      this.phoneNumberField = true
      this.maxLength = 13
      this.maxlengthphno = 13
    }
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  uploadFile
  filename
  submitted: boolean = false;
  condition=false
  checkForm(item,event) {
    if (this.setPersonalDetails != undefined) {
      if (item.value.highestDegree != this.setPersonalDetails.highestDegree ||
        item.value.emergencyContactNumber != this.setPersonalDetails.emergencyContactNumber || item.value.nativelanguage != this.setPersonalDetails.nativelanguage ||
        item.value.preferredlanguages != this.setPersonalDetails.preferredlanguages || item.value.ethinicity != this.setPersonalDetails.ethinicity ||
        item.value.nationality != this.setPersonalDetails.nationality || item.value.totalExperience != this.setPersonalDetails.totalExperience) {
        this.isSave = false
      } else {
        this.isSave = true
      }
    }
    if(!this.isEdit){
        var key = event.keyCode || event.charCode
        if( key == 9 )
            return false;
    }
  }
  nationalityChanged(data) {
    //this.isSave=false
  }
  nativeLangChanged(data) {
    // this.isSave=false
  }
  langChanged(data) {
    //this.isSave=false
  }
  degreeChanged(data) {
    //this.isSave=false
  }
  search(items: any[], searchTxt: string): any[] {
    if (!items || !items.length) return items;
    if (!searchTxt || !searchTxt.length) return items;
    return items.filter(item => {
      return item.value.toString().toLowerCase().indexOf(searchTxt.toLowerCase()) > -1
    });
  }

  isEdit: boolean = false;
  isDisabled: boolean = true;
  isSave
  Edit() {
    this.isEdit = true;
    this.isDisabled = false;
    this.isSave = true;
    this.personalDetails.markAsUntouched();
    this.search2 = "";
  }
  Age
  savePersonalDetails(formValue: any) {
    this.submitted = true;
    if (this.personalDetails.invalid) {
      return
    }
    // start
    var formData = new FormData();
    if (this.file != undefined) {
      for (let i = 0; i < this.file.length; i++) {
        formData.append('doctorSignature', this.file[i], this.file[i].name);
      }
    }
    if (this.file == undefined) {
      // this.file[0].name= null
      var personalDetailsValues = {
        "dob": this.personalDetails.value.dob,
        "personalDetailId": this.setPersonalDetails?.personalDetailId,
        "doctorId": this.loginUser.doctorId,
        "emergencyContactNumber": $('.iti__selected-dial-code').text() + this.personalDetails.value.emergencyContactNumber,
        "preferredlanguages": this.personalDetails.value.preferredlanguages.toString(),
        "nativelanguage": this.personalDetails.value.nativelanguage,
        "highestDegree": this.personalDetails.value.highestDegree,
        "nationality": this.personalDetails.value.nationality,
        "ethinicity": this.personalDetails.value.ethinicity,
        "documentName": null,
        "active": "Y",
        "profilePic": null,
        "doctorSignature":this.setPersonalDetails?.doctorSignature,
        "totalExperience": this.personalDetails.value.totalExperience
      }
    }
    else {
      var personalDetailsValues = {
        "dob": this.personalDetails.value.dob,
        "personalDetailId": this.setPersonalDetails?.personalDetailId,
        "doctorId": this.loginUser.doctorId,
        "emergencyContactNumber": $('.iti__selected-dial-code').text() + this.personalDetails.value.emergencyContactNumber,
        "preferredlanguages": this.personalDetails.value.preferredlanguages.toString(),
        "nativelanguage": this.personalDetails.value.nativelanguage,
        "highestDegree": this.personalDetails.value.highestDegree,
        "nationality": this.personalDetails.value.nationality,
        "ethinicity": this.personalDetails.value.ethinicity,
        "documentName": this.file[0].name,
        "doctorSignature":this.setPersonalDetails?.doctorSignature,
        "active": "Y",
        "profilePic": null,
        "totalExperience": this.personalDetails.value.totalExperience
      }
    }

    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)

    var user = {
      "forUserId": this.loginUser.doctorId,
      "userId": this.loginUser.doctorId
    }
    //const date = formatDate(formValue.report_Date, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');

    // "DOB": "1994-04-16",
    // "active": "y",

    // "doctorId": 11,
    // "doctorSignature": "null",
    // "emergencyContactNumber": "string",
    // "ethinicity": "test",

    // "highestDegree": "5",

    // "nationality": "Indian",
    // "nativelanguage": "Telugu",
    // "personalDetailId": 2,
    // "preferredlanguages": "Hindi,Telugu",
    // "profilePic": null

    var request = {
      personalDetailsValues
    }
    // formData.append('uploadpersonaldetailJson', JSON.stringify(personalDetailsValues));

    this.personalDetails.value.doctorId = this.loginUser.doctorId;
    this.personalDetails.value.personalDetailId = this.setPersonalDetails?.personalDetailId
    if (this.file == undefined) {
      this.personalDetails.value.doctorSignature = null
    }
    else {
      this.personalDetails.value.doctorSignature = this.file[0].name
    }


    var PrimaryNumber = parseInt(this.globalStorage.getItem('PrimaryNumber'))
    var emergencyContactNumber = parseInt(this.personalDetails.value.emergencyContactNumber)

    if (PrimaryNumber == emergencyContactNumber) {
      this.ToastrService.warning("Primary number and emergency number should not be same")
      return
    }

    // if(this.setPersonalDetails['profilePic']!=null){
    //   this.personalDetails.value.profilePic=this.setPersonalDetails['profilePic']
    // }else{
    //   this.personalDetails.value.profilePic=null;
    // }

    const dob = formatDate(this.personalDetails.value.dob, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    this.personalDetails.value.dob = dob.concat('Z')

    var d1 = moment(dob);
    var d2 = moment(new Date());
    var days = moment.duration(d2.diff(d1)).asDays();
    var years = days / 360
    this.Age = years.toFixed(0);

    if (this.Age < 25) {
      this.ToastrService.warning("Enter valid Dob")
      return
    }

    this.Age = this.Age - 1;
    var diff = this.Age - 25;

    var totalExp = Math.round(this.personalDetails.value.totalExperience);

    if (totalExp > diff) {
      this.ToastrService.error("Experience cannot be greater than " + diff)
      return;
    }

    this.personalDetails.value.active = 'Y'
    this.personalDetails.value.preferredlanguages = this.personalDetails.value.preferredlanguages.toString();
    //var request=this.personalDetails.value
    //formData.append('uploadpersonaldetailJson',JSON.stringify(personalDetailsValues));
    this.DoctorService.savePersonalDetails(personalDetailsValues).subscribe(res => {

      if (formData.has('doctorSignature')) {
        this.DoctorService.uploadDoctorSignature(formData, this.globalStorage.getItem('doctorId')).subscribe(res => {
          this.getPersonalDetails();
         })
      }
      this.DoctorService.refreshSideMenu();
      this.isEdit = false
      this.isSave = false
      this.ToastrService.success("Details saved.")
      this.isDisabled = true;
      this.personalDtsMessage = ' '
      this.globalStorage.setItem('isPersonalDetailCompleted',"Y")
      if(this.globalStorage.getItem('isAddressCompleted')=='N' || this.globalStorage.getItem('isAddressCompleted')!='Y'){
        this.router.navigate(['/addressDetails'])
      }
      this.sendToParent()
    },
      error => {
        // this.ToastrService.error("Error in save personal details");
        this.ToastrService.error(error['error']['message'])
      })
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('visable' in changes) {
      //  alert()
    }

  }
  public OnSubmit(formValue: any) {
    this.submitted = true

    var formData = new FormData();
    for (let i = 0; i < this.file.length; i++) {
      formData.append('medicalreportfile', this.file[i], this.file[i].name);
    }

    var data = this.globalStorage.getItem('doctorId')
    var obj = JSON.parse(data)

    var user = {
      "forUserId": obj['userId'],
      "userId": obj['userId']
    }
    // const date = formatDate(formValue.report_Date, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    //   var medicalReports = {
    //     "alignment": formValue.report_description,
    //     "appointmentId":-1,
    //     "documentLocation": "D:new",
    //     "documentName": this.file[0].name,
    //     "medicalTestId": -1,
    //     "reportTakenOn": date.concat('Z'),
    //     "reportName"	:formValue.report_name,
    //     "userId": obj['userId']

    //   }

    // var request = {
    //   'forUser': user,
    //   'medicalreports': medicalReports
    // }
    // formData.append('uploadMedicalReportRequestJson', JSON.stringify(request));
    // this.controller.saveMedicalReports(formData,obj['userId']).subscribe(res => {
    //   this.toaster.success('Your report has been saved')
    //   this.medicalReports()
    //   this.documentGrp.reset()
    //   this.submitted=false
    //   this.file=undefined
    // })


  }
  onFileInput(files: File) {
    const f = files
    this.documentGrp.patchValue({
      signature: f[0]['name']
    })
    if (((f[0].type) == "image/png" || (f[0].type) == "image/jpeg") || (f[0].type) == "application/pdf") {
      if (f[0].size > 1048769) {
        this.ToastrService.warning('File should be 1 Mb')
      } else {
        this.file = files
      }


    } else {
      this.ToastrService.warning('File should be in jpeg ,PNG or PDF')
    }

  }
  getLanguages() {
    this.update.languages().subscribe(e => {
      this.languagesList = e["data"];
      this.nativeLanguage = e['data'];
    }, error => {

    })
  }
  onCountryChange(event) {
  }
  getNumber(event) {
  }
  numberOnlyExp(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;

    if (this.dotTimes == 0) {
      if (charCode == 46) {
        this.dotTimes = 1;
        return true;
      }
    }

    else {
      if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    }
  }

  expEntered() {
    var s1 = String(this.personalDetails.value.totalExperience);

    if (s1.charAt(1) == '.') {
      this.expLength = 4;
    }
    else if (s1.charAt(2) == '.') {
      this.expLength = 5;
    }
    else if (s1.length == 2) {
      this.dotTimes = 0;
      this.expLength = 5;
    }
  }
  viewSignature() {
    $('#location').modal('show')
  }
  close() {
    $('.modal').removeClass('show')
    $('#location').modal('hide')
  }
}
