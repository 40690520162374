import { GlobalStorageService } from 'src/app/global-storage.service';
import { connect, ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ReplaySubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ObservableService } from './observable.service';
import { ApicallsService } from 'src/app/apicalls.service';
interface AuthToken {
    token: string
}


export interface NamedRoom {
    id: string;
    name: string;
    maxParticipants?: number;
    participantCount: number;
}

export type Rooms = NamedRoom[];

@Injectable({
    providedIn: 'root'
})
export class DoctorVideoService {
    $roomsUpdated: Observable<boolean>;
    sid
    private roomBroadcast = new ReplaySubject<boolean>();
    appointmentId: any;
    public tokenString: any
    tokenStringFinal: any;

    baseUrl = "http://localhost:3000"
    doctorUrl = environment.doctorBaseUrl
    patUrl = environment.patientBaseUrl
    constructor(private globalStorage: GlobalStorageService, private http: HttpClient, private observable: ObservableService, private api: ApicallsService) {
        const hostname = window.location.hostname;

        if (hostname === 'localhost') {
            this.baseUrl = environment.patientBaseUrl1
            this.doctorUrl = environment.doctorBaseUrl1
            this.patUrl = environment.patientBaseUrl1
          }
        this.$roomsUpdated = this.roomBroadcast.asObservable();
    }

    getUserName(room: any, patientId) {
        const httpOptions = {
            headers: new HttpHeaders({

                'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'DOCTOR', 'forUserId': patientId.toString()
            })
        };
        if (room != 'undefined' || room != 'null') {
            this.api.post(this.doctorUrl + 'appointment/room/join/' + room, {}, httpOptions).subscribe(res => {
                this.tokenString = res['data']['tokenForChat']
                this.globalStorage.setItem('roomName', res['data']['chatRoomName'])
                this.globalStorage.setItem('AppointmentId', res['data']['appointmentId'])
                this.observable.callMethodOfinsert_doctor()
                this.tokenGenerate(this.tokenString)
            })
        }


    }
    tokenGenerate(tokenString: any) {
        this.tokenStringFinal = tokenString
    }


    getAllRooms() {
        return this.api
            .get(this.baseUrl + '/rooms', {})

    }


    joinOrCreateRoom(name: string, tracks: LocalTrack[]) {

        let room: Room

        try {
            const token = this.tokenStringFinal
            room =
                connect(
                    token, {
                        name,
                        tracks,
                        dominantSpeaker: true
                    } as ConnectOptions);
        } catch (error) {
            console.error(`Unable to connect to Room: ${error.message}`);
        } finally {
            if (room) {
                this.roomBroadcast.next(true);
            }
        }
        return room;
    }



    nudge() {
        this.roomBroadcast.next(true);
    }
}
