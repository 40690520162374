import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatExpansionPanel } from '@angular/material';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';

@Component({
  selector: 'app-view-appoinment',
  templateUrl: './view-appoinment.component.html',
  styleUrls: ['./view-appoinment.component.css']
})
export class ViewAppoinmentComponent implements OnInit {

  constructor(private doctorPatient:DoctorPatientService) { }
  @ViewChild('expPnlAppointment', {static: false}) expPnlAppointment: MatExpansionPanel;
  @Input() appointmentDetails:any; 

  ngOnInit(): void {
     //this.expPnlAppointment.open();
  }

  patientPrescription
  viewPrecription(appointment){
  var appointmentObj={
    "appointmentId":appointment.appointmentId,
    "patientId":appointment.userId
  }
    this.patientPrescription=appointmentObj
  }
}
