import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';
import { SupplierOrderInvoiceComponent } from '../supplier-order-invoice/supplier-order-invoice.component';

@Component({
  selector: 'app-current-batch-info',
  templateUrl: './current-batch-info.component.html',
  styleUrls: ['./current-batch-info.component.css']
})
export class CurrentBatchInfoComponent implements OnInit {

  currentBatchData;
  p1 = 1;
  @Input() stockObj:any;
  constructor(private globalStorage: GlobalStorageService, private activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private inventoryService: InventoryService,
    private modalService : NgbModal) { }

  ngOnInit(): void {
    this.fetchCurrentBatchDetails();
  }

  closeModal() {
    this.activeModal.close(true);
  }

  fetchCurrentBatchDetails(){
    this.inventoryService.getCurrentbatchInformation(this.stockObj?.orderBatchId).subscribe(res => {
      this.currentBatchData = res?.data;
    })
  }

  orderInvoice(data) {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'right hw-60';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-left hw-60';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-60';
    }
    const modalRef = this.modalService.open(SupplierOrderInvoiceComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    })
    modalRef.componentInstance.supplierOrder = data;
  }

}
