import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DiagnosticService } from 'src/app/AppServices/diagnostic.service';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { DomSanitizer } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-dc-report',
  templateUrl: './dc-report.component.html',
  styleUrls: ['./dc-report.component.css']
})
export class DcReportComponent implements OnInit {
  @Input() appointmentData:any
  @Input() dcAddressId:any
  @Input() rType:any
  dcReportData;remarks;report;ImageSrc;showImage=false;newsrc;showPdf=false
  constructor(private globalStorage: GlobalStorageService, private dcServices:DiagnosticService,private toastr: ToastrService,private activeModal: NgbActiveModal,private patientService:PatientScheduleControlService,
    private clinicService:ClinicService,private sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    this.getAppointmentReport()
  }
  getAppointmentReport(){
    console.log('dc report',this.appointmentData)
    
    if(this.rType=='HospitalReport'){
      if(this.appointmentData?.documentLocation!=null || this.appointmentData?.documentName!=null){
        this.clinicService.getMedicalReportImage(parseInt(this.globalStorage.getItem('hospitalAddressId')),this.appointmentData?.hospitalprescriptionId,this.appointmentData?.inpatientId).subscribe(res=>{
          this.dcReportData=res['data']
          this.report=res['data']
      if (this.report?.documentName.includes(".png") || this.report?.documentName.includes(".jpg")) {
        this.ImageSrc = "data:image/jpeg;base64," + this.report?.["medicalReportFile"];
        this.showImage = true;
        this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.ImageSrc);
        $('#viewpdfNEW').modal('show');
      }
      if (this.report?.documentName.includes(".pdf")) {
        this.newsrc = null;
        this.showPdf = true;
        this.ImageSrc = "data:application/pdf;base64," + this.report?.["medicalReportFile"];
        this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.ImageSrc);
        $('#viewpdfNEW').modal('show');
      }
          console.log('dc report',this.dcReportData)
        },error=>{
          this.toastr.error(error?.error?.message)
        })
      }
      else{
        this.dcServices.getHospitalDcReport(parseInt(this.globalStorage.getItem('hospitalAddressId')),this.appointmentData?.hospitalprescriptionId,this.appointmentData?.patientId).subscribe(res=>{
          this.dcReportData=res['data']
          this.remarks=this.dcReportData?.testdto?.[0]?.['testParameterDTOs']?.[0]?.['testParameterResults']?.['remarks']
          console.log('dc report',this.dcReportData)
        },error=>{
          this.toastr.error(error?.error?.message)
        })
      }
    }
    else{
      this.dcServices.getReport(this.appointmentData?.diagnosticcenterAppointmentId,this.appointmentData?.diagnosticcenterAddresId).subscribe(res=>{
        this.dcReportData=res['data']
        this.remarks=this.dcReportData?.testdto?.[0]?.['testParameterDTOs']?.[0]?.['testParameterResults']?.['remarks']
        console.log('dc report',this.dcReportData)
      },error=>{
        this.toastr.error(error?.error?.message)
      })
    }
  }
  closeModal(){
    this.activeModal.close(true);
  }
}
