import { FullCalendarModule } from '@fullcalendar/angular';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import resourceTimelinePlugin from '@fullcalendar/daygrid'; // a plugin
import timeGrigPlugin from '@fullcalendar/timegrid';

import { CommonModule, DatePipe } from '@angular/common';
import { doctorDashboardComponent } from '../../DoctorPages/dashboard/dashboard.component';
import { AdminLayoutRoutes } from './doctor-layout.routing';
import { RouterModule } from '@angular/router';
import { DoctorLayoutComponent } from './doctor-layout.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { DoctorListComponent } from '../../DoctorPages/doctor-list/doctor-list.component';
import { PatientListComponent } from '../../DoctorPages/patient-list/patient-list.component';
import { SchedulerComponent } from '../../DoctorPages/scheduler/scheduler.component'
import { DoctorProfileComponent } from '../../DoctorPages/doctor-profile/doctor-profile.component';
import { BasesehedulerComponent } from '../../DoctorPages/baseseheduler/baseseheduler.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
// the main connector. must go first
import { ErrorComponent } from 'src/app/DoctorPages/error/error.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModule } from 'src/app/shared/shared.module';
import { BookAppointmentComponent } from '../../DoctorPages/book-appointment/book-appointment.component';
import { AppointmentsComponent } from 'src/app/DoctorPages/appointments/appointments.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { NgSlimScrollModule } from 'ngx-slimscroll';
import { PatientDetailsComponent } from 'src/app/DoctorSideNavePages/patient-details/patient-details.component';
import { PreviousAppointmentsComponent } from '../../DoctorSideNavePages/previous-appointments/previous-appointments.component';
import { CurrentAppointmentsComponent } from 'src/app/DoctorSideNavePages/current-appointments/current-appointments.component';
import { UpcomingAppointmentsComponent } from 'src/app/DoctorSideNavePages/upcoming-appointments/upcoming-appointments.component';
import { AppointmentSearchResultComponent } from 'src/app/DoctorSideNavePages/appointment-search-result/appointment-search-result.component';
import { DoctorProfessionalDetailsComponent } from 'src/app/DoctorSideNavePages/doctor-professional-details/doctor-professional-details.component';
import { DoctorPersonalDetailsComponent } from 'src/app/DoctorSideNavePages/doctor-personal-details/doctor-personal-details.component';
import { DoctorAddressDetailsComponent } from 'src/app/DoctorSideNavePages/doctor-address-details/doctor-address-details.component';
import { DoctorCertificationDetailsComponent } from 'src/app/DoctorSideNavePages/doctor-certification-details/doctor-certification-details.component';
import { PrecriptionComponent } from 'src/app/DoctorSideNavePages/precription/precription.component';
import { ViewAppoinmentComponent } from 'src/app/DoctorSideNavePages/view-appoinment/view-appoinment.component';

import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

import { PrescriptionListComponent } from 'src/app/DoctorSideNavePages/prescription-list/prescription-list.component';
// import { ViewPrescriptionComponent } from 'src/app/DoctorSideNavePages/view-prescription/view-prescription.component';

import { VitalsComponent } from 'src/app/DoctorSideNavePages/vitals/vitals.component';
import { MedicalReportsListComponent } from 'src/app/DoctorSideNavePages/medical-reports-list/medical-reports-list.component';
import { PreviousAppointmentListComponent } from 'src/app/DoctorSideNavePages/previous-appointment-list/previous-appointment-list.component';
import { MedicalHistoryComponent } from 'src/app/DoctorSideNavePages/medical-history/medical-history.component';
import { DoctorChangePasswordComponent } from 'src/app/DoctorPages/doctor-change-password/doctor-change-password.component';
import { ActiveMedicationComponent } from 'src/app/DoctorSideNavePages/active-medication/active-medication.component';
import { InvoiceDetailsComponent } from 'src/app/DoctorSideNavePages/invoice-details/invoice-details.component';

// import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { CancelAppointmentComponent } from 'src/app/DoctorSideNavePages/cancel-appointment/cancel-appointment.component';
import { AllAppointmentsComponent } from 'src/app/DoctorSideNavePages/all-appointments/all-appointments.component';
import { DoctorGuardGuard } from '../doctor-layout/doctor-guard.guard'

import { ChartsModule } from 'ng2-charts';

import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import {Ng2TelInputModule} from 'ng2-tel-input';
import { PatientCallRecordsComponent } from 'src/app/DoctorSideNavePages/patient-call-records/patient-call-records.component';
import { IvfCompleteSemenAnalysisComponent } from 'projects/ivf/src/app/ivf-complete-semen-analysis/ivf-complete-semen-analysis.component';
import { IvfConfirmModalComponent } from 'projects/ivf/src/app/ivf-confirm-modal/ivf-confirm-modal.component';
import { IvfShowInvestigationsComponent } from 'projects/ivf/src/app/ivf-show-investigations/ivf-show-investigations.component';
import { IvfShowReportsComponent } from 'projects/ivf/src/app/ivf-show-reports/ivf-show-reports.component';
import { SpermDnaintergrityTestComponent } from 'projects/ivf/src/app/sperm-dnaintergrity-test/sperm-dnaintergrity-test.component';
import { IvfSemenReportComponent } from 'projects/ivf/src/app/ivf-semen-report/ivf-semen-report.component';
import { IvfAddSemenCultureComponent } from 'projects/ivf/src/app/ivf-add-semen-culture/ivf-add-semen-culture.component';
import { IvfIuiDComponent } from 'projects/ivf/src/app/ivf-iui-d/ivf-iui-d.component';
import { IvfIuiHComponent } from 'projects/ivf/src/app/ivf-iui-h/ivf-iui-h.component';
import { SemenAnalysisViewComponent } from 'projects/ivf/src/app/semen-analysis-view/semen-analysis-view.component';
import { IvfMesaGenericComponent } from 'projects/ivf/src/app/ivf-mesa-generic/ivf-mesa-generic.component';
import { IvfBulkFileUploadComponent } from 'projects/ivf/src/app/ivf-bulk-file-upload/ivf-bulk-file-upload.component';
import { IvfSpermFreezingReportComponent } from 'projects/ivf/src/app/ivf-sperm-freezing-report/ivf-sperm-freezing-report.component';
import { ProgressiveNotesComponent } from 'projects/ivf/src/app/progressive-notes/progressive-notes.component';
import { IvfEmbryoFreezingComponent } from 'projects/ivf/src/app/ivf-embryo-freezing/ivf-embryo-freezing.component';
import { IvfGametInfoComponent } from 'projects/ivf/src/app/ivf-gamet-info/ivf-gamet-info.component';
import { IvfOocyteFreezingComponent } from 'projects/ivf/src/app/ivf-oocyte-freezing/ivf-oocyte-freezing.component';
import { IvfFemalePrescriptionComponent } from 'projects/ivf/src/app/ivf-female-prescription/ivf-female-prescription.component';
import { IvfAddOocyteScanComponent } from 'projects/ivf/src/app/ivf-add-oocyte-scan/ivf-add-oocyte-scan.component';
import { IvfRegistrationHolderComponent } from 'projects/ivf/src/app/ivf-registration-holder/ivf-registration-holder.component';
// import { DialogComponentComponent } from 'projects/ivf/src/app/dialog-component/dialog-component.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OocytePickupComponent } from 'projects/ivf/src/app/oocyte-pickup/oocyte-pickup.component';
import { IvfDoctorNotesComponent } from 'projects/ivf/src/app/ivf-doctor-notes/ivf-doctor-notes.component';
import { IvfBookingConfirmationComponent } from 'projects/ivf/src/app/ivf-booking-confirmation/ivf-booking-confirmation.component';
import { VitalChartsComponent } from 'src/app/commonComponents/vital-charts/vital-charts.component';
import { BookDoctorAppointmentComponent } from 'projects/ivf/src/app/book-doctor-appointment/book-doctor-appointment.component';
import { ImagePrescriptionComponent } from 'src/app/DoctorSideNavePages/patient-details/image-prescription/image-prescription.component';
import { MedicalHistoryHolderComponent } from 'projects/ivf/src/app/medical-history-holder/medical-history-holder.component';
import { IvfStartTreatmentComponent } from 'projects/ivf/src/app/ivf-start-treatment/ivf-start-treatment.component';
import { IvfChecklistComponent } from 'projects/ivf/src/app/ivf-checklist/ivf-checklist.component';
import { EggMonitorSheetComponent } from 'projects/ivf/src/app/egg-monitor-sheet/egg-monitor-sheet.component';
import { AddDisposablesComponent } from 'projects/ivf/src/app/add-disposables/add-disposables.component';
import { DcAppointmentBookingComponent } from 'src/app/DoctorPages/dc-appointment-booking/dc-appointment-booking.component';
import { PatientPrescrtionsComponent } from 'src/app/DoctorSideNavePages/patient-prescrtions/patient-prescrtions.component';
import { ClinicOpdPrescriptionsComponent } from 'src/app/clinic-pages/clinic-opd-prescriptions/clinic-opd-prescriptions.component';

export const customCurrencyMaskConfig = {
  align: "right",
  allowNegative: true,
  allowZero: false,
  decimal: ".",
  precision: 0,
  prefix: "",
  suffix: "",
  thousands: "",
  nullable: true,
  min: null,
  max: 999,
  inputMode: CurrencyMaskInputMode.FINANCIAL
};

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  resourceTimelinePlugin,
  timeGrigPlugin
]);
@NgModule({
  declarations: [
    doctorDashboardComponent, ErrorComponent, BookAppointmentComponent, AppointmentsComponent, PatientDetailsComponent, PreviousAppointmentsComponent,
    DoctorLayoutComponent, DoctorListComponent, PatientListComponent, SchedulerComponent, DoctorProfileComponent, BasesehedulerComponent,
    CurrentAppointmentsComponent,DcAppointmentBookingComponent,
    UpcomingAppointmentsComponent,
    AppointmentSearchResultComponent,
    DoctorProfessionalDetailsComponent,
    DoctorPersonalDetailsComponent,
    DoctorCertificationDetailsComponent,
    DoctorAddressDetailsComponent, PrecriptionComponent, ViewAppoinmentComponent, MedicalReportsListComponent,
    PreviousAppointmentListComponent,SemenAnalysisViewComponent,
    PrescriptionListComponent,
    // ViewPrescriptionComponent,
    VitalsComponent,PatientCallRecordsComponent,
    MedicalHistoryComponent, DoctorChangePasswordComponent, ActiveMedicationComponent, InvoiceDetailsComponent, CancelAppointmentComponent, AllAppointmentsComponent,
    IvfCompleteSemenAnalysisComponent,IvfConfirmModalComponent,IvfShowInvestigationsComponent,IvfShowReportsComponent,SpermDnaintergrityTestComponent,IvfSemenReportComponent,
    IvfAddSemenCultureComponent,IvfGametInfoComponent,IvfSemenReportComponent,IvfIuiDComponent,IvfIuiHComponent,IvfMesaGenericComponent,IvfBulkFileUploadComponent,IvfSpermFreezingReportComponent,ProgressiveNotesComponent,IvfEmbryoFreezingComponent
    ,IvfOocyteFreezingComponent,IvfFemalePrescriptionComponent,IvfAddOocyteScanComponent,IvfRegistrationHolderComponent,IvfDoctorNotesComponent,
    OocytePickupComponent,VitalChartsComponent,BookDoctorAppointmentComponent,ImagePrescriptionComponent,
    MedicalHistoryHolderComponent
    ,IvfStartTreatmentComponent
    ,IvfChecklistComponent,
    EggMonitorSheetComponent,AddDisposablesComponent,PatientPrescrtionsComponent
  ],
  imports: [
    NgbModule,
    CommonModule,Ng2TelInputModule, FullCalendarModule, NgSlimScrollModule, SharedModule,ChartsModule,
    RouterModule.forChild(AdminLayoutRoutes), NgxPaginationModule,
    MaterialModule, FormsModule, ReactiveFormsModule, PdfViewerModule, VgCoreModule, VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule, NgxCurrencyModule.forRoot(customCurrencyMaskConfig)
  ],
  exports:[BasesehedulerComponent,PrecriptionComponent,VitalsComponent,PatientDetailsComponent,MedicalHistoryComponent,SchedulerComponent,
    PatientCallRecordsComponent,DcAppointmentBookingComponent,PatientPrescrtionsComponent
    // ,ViewPrescriptionComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  providers: [DoctorGuardGuard],
  entryComponents: [PrecriptionComponent,ImagePrescriptionComponent, EggMonitorSheetComponent,DcAppointmentBookingComponent,ClinicOpdPrescriptionsComponent]
})
export class AdminLayoutModule { }
