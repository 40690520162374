<ol class="breadcrumb">
  <li><a href="javascript:void(0)" routerLink="/dashboard"><i class="fa fa-home" aria-hidden="true">

      </i></a></li>
  <li> <a href="javascript:void(0)"> Patients List</a></li>
</ol>




<div class="row">
  <div class=" col-md-12">

    <div class="card ">

      <div class="card-body cm-height">

        <h5 class="card-title3"> Patients List</h5>




        <div class=" card-top-buttons  sort-app">

          <input type="text" class="form-control" id="kt_subheader_search_form" #patientName [(ngModel)]="selectedText"
            [(value)]="selectedText" aria-label="Number" matInput (keyup)="searchData(patientName.value)"
            placeholder="Search by Patient name">

        </div>

        <div class="new-widget" *ngIf="isPatientList" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
          <div class="">
            <div class="p-0">

              <table class="table table-striped table-styled table-hover " role="grid" aria-describedby="example2_info">
                <thead>
                  <tr role="row">
                    <th class="t-head"> Patient Name</th>
                    <th class="t-head"> Reason</th>
                    <th class="t-head">Last Appointment Date</th>
                    <th class="t-head">Action</th>
                  </tr>
                </thead>
                <tbody>


                  <tr class="odd" data-id="9" *ngFor="let patient of patientsList">
                    <td><a href="javascript:void(0)"
                        (click)="viewCurrentAppontment(patient)">{{patient.patientName}}</a> </td>
                    <td>{{patient.ailmentdata}}</td>
                    <td><a href="javascript:void(0)"
                        (click)="previousAppointments(patient)">{{patient.latestAppointmentDate |
                        date:'dd-MMM-yyyy'}}</a>
                    </td>


                    <td>
                      <!-- <button  type="button" class="btn btn-sm mr-1 mt-2 p-1 curser-pointer" data-toggle="tooltip" data-original-title="Add Prescription" data-placement="left" title="Add Prescription">
                        <i  class="fas fa-upload themecol3  icon-head patientc1 " ></i> </button>  -->
                      <a class="btn btn-sm mr-1 mt-0 p-0 curser-pointer" (click)="prescriptionOpen(patient)"
                        data-toggle="tooltip" data-placement="top" title="Prescriptions">
                        <i class="fa fa-file-prescription themecol1   icon-head patientc2" aria-hidden="true"></i></a>

                      <a class="btn btn-sm mr-1 mt-0 p-0 curser-pointer" data-original-title="Medications"
                        data-placement="top" data-toggle="tooltip" (click)="activeMedications(patient)">
                        <i class="fa-solid fa-hand-holding-medical themecol2   icon-head patientc3"
                          aria-hidden="true"></i></a>

                      <a class="btn btn-sm mr-1  mt-0 p-0 curser-pointer" data-original-title="History"
                        data-placement="top" data-toggle="tooltip" (click)="patientHistory(patient)">
                        <i class="fa fa-h-square themecol3   icon-head patientc1" aria-hidden="true"></i></a>



                    </td>
                  </tr>

                  <tr *ngIf="patientsList?.length==0" style="background:#fff">
                    <td colspan="4">
                      <div class="no-image-bg1"></div>
                    </td>
                  </tr>

                </tbody>
              </table>

              <div class="modal fade modal-right" id="addproducts" role="dialog" data-backdrop="static" data-keyboard="false"
                aria-labelledby="exampleModalRight" aria-hidden="true">
                <div class="modal-dialog" role="document" style="min-width:35%">

                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Add Products</h5>

                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">


                    </div>


                  </div>
                </div>
              </div>



            </div>
          </div>
        </div>



      </div>

    </div>

  </div>
</div>










<div class="" style="display: none;">
  <div class="main box-shadow">
    <div>
      <div class="col-md-12">
        <mat-tab-group #tab [@.disabled]="true" class="mat_tab_width" (click)="TabChanges(tab)">
          <mat-tab label="New Registration">
            <div class="input-group" style="margin-top: 10px;margin-bottom: 10px;">
              <input type="text" class="form-control" placeholder="Search" style="width: 100%;"
                (keyup)="search($event.target.value)">
            </div>
            <div class="col-sm-12" style="padding:0px;">
              <table style="width: 100%;" class="demo table" (click)="onEvent($event)" #table mat-table
                [dataSource]="BindingNewlyRegPatients" matSort class="mat-elevation-z8">

                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation();" (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="firstName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"><a style="text-decoration: underline;color:blue"
                        (click)="viewPatientDerails(element)">{{element.firstName}}</a></div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="middleName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Middle Name </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"> {{element.middleName}} </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"> {{element.lastName}} </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="emailId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> EmailId </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"> {{element.emailId}} </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="primaryContact">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Contact </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"> {{element.primaryContact}} </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Action">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-right: 5px;">
                    <!-- <div class="btn-group" style="float: right;">
<button type="button" class="btn btn-success btn-sm">
<i class="fa fa-edit"></i></button>
<button type="button" class="btn btn-warning btn-sm"><span
class="fa fa-user"></span></button>
<button type="button" class="btn btn-danger btn-sm">
<span class="fa fa-trash"></span>
</button>
</div>  -->
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <div class="btn-group" style="float: right;">
                      <button type="button" class="btn btn-success btn-sm">
                        <i class="fa fa-edit"></i></button>
                      <button type="button" class="btn btn-warning btn-sm"><span class="fa fa-user"></span></button>
                      <button type="button" class="btn btn-danger btn-sm">
                        <span class="fa fa-trash"></span>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

              <mat-paginator (click)="onEvent($event)" [pageSize]="6" [pageSizeOptions]="[10, 25, 50]"
                showFirstLastButtons></mat-paginator>
            </div>
          </mat-tab>
          <mat-tab label="Verify">
            <div class="input-group" style="margin-top: 10px;margin-bottom: 10px;">
              <input type="text" class="form-control" placeholder="Search" style="width: 100%;"
                (keyup)="search($event.target.value)">
            </div>
            <div class="col-sm-12" style="padding:0px;">
              <table style="width: 100%;" class="demo table" (click)="onEvent($event)" #table mat-table
                [dataSource]="BindingverifiedPatients" matSort class="mat-elevation-z8">

                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation();" (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="firstName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"><a style="text-decoration: underline;color:blue"
                        (click)="viewPatientDerails(element)">{{element.firstName}}</a></div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="middleName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Middle Name </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"> {{element.middleName}} </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"> {{element.lastName}} </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="emailId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> EmailId </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"> {{element.emailId}} </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="primaryContact">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Contact </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"> {{element.primaryContact}} </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Action">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>

                  </th>
                  <td mat-cell *matCellDef="let row">
                    <div class="btn-group" style="float: right;">
                      <button type="button" class="btn btn-success btn-sm">
                        <i class="fa fa-edit"></i></button>
                      <button type="button" class="btn btn-warning btn-sm"><span class="fa fa-user"></span></button>
                      <button type="button" class="btn btn-danger btn-sm">
                        <span class="fa fa-trash"></span>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

              <mat-paginator (click)="onEvent($event)" [pageSize]="6" [pageSizeOptions]="[10, 25, 50]"
                showFirstLastButtons></mat-paginator>
            </div>
          </mat-tab>
          <mat-tab label="Approved">
            <div class="input-group" style="margin-top: 10px;margin-bottom: 10px;">
              <input type="text" class="form-control" placeholder="Search" style="width: 100%;"
                (keyup)="search($event.target.value)">
            </div>
            <div class="col-sm-12" style="padding:0px;">
              <table style="width: 100%;" class="demo table" (click)="onEvent($event)" #table mat-table
                [dataSource]="BindingapprovedPatients" matSort class="mat-elevation-z8">

                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                  </th>
                  <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation();" (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                  </td>
                </ng-container>

                <ng-container matColumnDef="firstName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"><a style="text-decoration: underline;color:blue"
                        (click)="viewPatientDerails(element)">{{element.firstName}}</a></div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="middleName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Middle Name </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"> {{element.middleName}} </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="lastName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"> {{element.lastName}} </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="emailId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> EmailId </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"> {{element.emailId}} </div>
                  </td>
                </ng-container>

                <ng-container matColumnDef="primaryContact">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Contact </th>
                  <td mat-cell *matCellDef="let element">
                    <div class="table_name_ellipsis"> {{element.primaryContact}} </div>
                  </td>
                </ng-container>
                <ng-container matColumnDef="Action">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                  <td mat-cell *matCellDef="let row">
                    <div class="btn-group" style="float: right;">
                      <button type="button" class="btn btn-success btn-sm">
                        <i class="fa fa-edit"></i></button>
                      <button type="button" class="btn btn-warning btn-sm"><span class="fa fa-user"></span></button>
                      <button type="button" class="btn btn-danger btn-sm">
                        <span class="fa fa-trash"></span>
                      </button>
                    </div>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

              <mat-paginator (click)="onEvent($event)" [pageSize]="6" [pageSizeOptions]="[10, 25, 50]"
                showFirstLastButtons></mat-paginator>
            </div>
          </mat-tab>
        </mat-tab-group>

      </div>


    </div>

  </div>
</div>
<div class="modal fade modal-left" id="activeMedication" style="margin-left:5%" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:75%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Active Medications</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="p-3">
          <!-- <div class="col-md-12 ml-3" >    <h3 class="mt-0 mb-3 border-bottom pt-2 pb-2 head">Current Medication</h3>
           </div> -->

          <table class="table table-striped table-hover " role="grid" aria-describedby="example2_info">
            <thead>
              <tr role="row">
                <th width="200" class="t-head"> Medicine Name </th>
                <th width="200" class="t-head"> Doctor Name </th>
                <!-- <th width="200">  Doctor Specilization	</th> -->
                <th width="200" class="t-head"> Appointment Date </th>
                <th width="200" class="t-head"> Morning <img src="./assets/img/morning.png" /></th>
                <th width="200" class="t-head"> Afternoon <img src="./assets/img/afternoon.png" /> </th>
                <th width="200" class="t-head"> Evening <img src="./assets/img/evening.png" /></th>
              </tr>
            </thead>
            <tbody>

              <ng-container *ngIf="( activeMedicationList) as result">
                <tr *ngIf="result.length==0">
                  <div style="position: absolute;top: 25%;" class="no-medication">

                  </div>
                </tr>
                <tr class="odd" data-id="9" *ngFor="let medicine of  result ">


                  <td><a href="javascript:void(0)">{{medicine.medicineName}}</a></td>
                  <td><a href="javascript:void(0)">{{medicine.doctorName}}</a></td>
                  <!-- <td>{{medicine.doctorSpecialization}}</td> -->
                  <td>{{medicine.appointmentDate | date:'dd-MMM-yyyy'}}</td>
                  <td class="text-center"><i class="fas fa-check themecol1 mr-4" aria-hidden="true"
                      *ngIf="medicine?.morning?.dosageConsumed=='Y'"></i>
                    <i class="fas fa-times themecol3 mr-4" aria-hidden="true"
                      *ngIf="medicine?.morning?.dosageConsumed=='N'"></i>
                    <!-- <i class="fas fa-times themecol3" aria-hidden="true"></i> -->
                  </td>
                  <td class="text-center"><i class="fas fa-check themecol1 mr-4" aria-hidden="true"
                      *ngIf="medicine?.afternoon?.dosageConsumed=='Y'"></i>
                    <i class="fas fa-times themecol3 mr-4" aria-hidden="true"
                      *ngIf="medicine?.afternoon?.dosageConsumed=='N'"></i>
                    <!-- <i class="fas fa-times themecol3" aria-hidden="true"></i>  -->
                  </td>
                  <td class="text-center"><i class="fas fa-check themecol1 mr-4" aria-hidden="true"
                      *ngIf="medicine?.evening?.dosageConsumed=='Y'"></i>
                    <i class="fas fa-times themecol3 mr-4" aria-hidden="true"
                      *ngIf="medicine?.evening?.dosageConsumed=='N'"></i>
                    <!--<i class="fas fa-times themecol3" aria-hidden="true"></i> -->
                  </td>
                </tr>
              </ng-container>





            </tbody>
          </table>
        </div>

      </div>

    </div>

  </div>
</div>
<div class="modal fade modal-left" id="medicalPrescriptionDoctor"  role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:47%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Prescripiton Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="card no-shadow">
          <div class="card-body">
            <!-- <h5 class="card-title">
                    <i
                      class="fa fa-file-prescription themecol2"
                      aria-hidden="true"
                    ></i>
                    Prescriptions
                  </h5> -->
            <div *ngIf="medicalReportsData?.length == 0">
              <div class="no-prescriptions"></div>
            </div>


            <table class="table table-striped table-hover " role="grid" aria-describedby="example2_info">
              <thead>
                <tr role="row">
                  <th width="200" class="t-head"> Prescription </th>
                  <th width="200" class="t-head"> Appointment Date </th>
                  <!-- <th width="200">  Doctor Specilization	</th> -->
                  <th width="200" class="t-head"> Action </th>
                </tr>
              </thead>
              <tbody>

                <ng-container *ngIf="( medicalReportsData) as result">
                  <tr *ngIf="result.length==0">
                    <div style="position: absolute;top: 107%;" class="no-medication">

                    </div>
                  </tr>
                  <tr class="odd" data-id="9" *ngFor="let medicine of  result ">


                    <td><a href="javascript:void(0)" > Prescription {{ medicine.prescriptionId }}</a></td>

                    <td><a href="javascript:void(0)">{{medicine.appointmentDate | date:'dd-MMM-yyyy'}}</a></td>
                    <!-- <td>{{medicine.doctorSpecialization}}</td> -->
                    <td><button class="btn btn-xs btn0col1">
                        <i aria-hidden="true" class="fa fa-eye" data-toggle="modal" data-backdrop="static"
                          data-target="#medicalPrescriptionDoctor" (click)="viewPrescription(medicine)"></i>
                      </button></td>
                  </tr>
                </ng-container>





              </tbody>
            </table>


            <!-- <div class="row list">
                    <div
                      class="col-md-4"
                      *ngFor="
                        let reports of medicalReportsData
                          | paginate
                            : {
                                id: 'p3',
                                itemsPerPage: 15,
                                currentPage: p3,
                                totalItems: totalItem
                              }
                      "
                    >
                      <div
                        class=" d-flex flex-row mb-0 p-2 border-bottom w-100 card  float-left  blt-2 mb-2 mt-1"
                        data-toggle="modal"
                        data-backdrop="static"

                      >
                        <a class="p-2 icon-appointments" href="javascript:void(0)">
                          <i
                            aria-hidden="true"
                            class="fa fa-file-prescription themecol2"
                          ></i
                        ></a>
                        <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2">
                          <a href="javascript:void(0)">
                            <p class="font-weight-medium mb-1 mt-1 text-custom">
                             Prescription {{ reports.medicalPrescriptionId }}
                            </p>
                            <p class="text-muted mb-1 text-small">
                              {{ reports.appointmentDate | date: "dd-MMM-yyyy" }}
                            </p></a
                          >
                        </div>
                        <div class="btn-group float-right">
                          <button class="btn btn-xs btn0col1">
                            <i
                              aria-hidden="true"
                              class="fa fa-eye"
                              data-toggle="modal"
                              data-backdrop="static"
                              data-target ="#medicalPrescriptionDoctor"
                  (click)="viewPrescription(reports)"
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> -->
          </div>

          <!-- <div class="row">
                  <div class="col-lg-12 mt-3 text_right">
                    <ul class="pagination">
                      <pagination-controls
                        id="p3"
                        (pageChange)="p3 = $event"
                        (click)="getSavedPrescriptions()"
                      ></pagination-controls>
                    </ul>
                  </div>
                </div> -->
        </div>

      </div>

    </div>

  </div>
</div>
<div class="modal fade modal-left" id="prescription" style="margin-left:5%" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:40%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Prescripiton Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="block inv-section">
          <div class="top-inv-col">
            <div class="inv-logo">
              <img alt="" src="./assets/img/logo.png">
            </div>
            <div class="float-right text-left order-details">
              <p>Order Id: <span>{{precId}}</span></p>
              <p>Date: <span>20/07/2019</span></p>
            </div>
          </div>



          <img *ngIf="showImage==true" [src]="newsrc" style="height: 264px" alt="" />
          <iframe *ngIf="showPdf==true" [src]="newsrc" class="pdf-viewer" frameborder="0" scrolling="no"
            style="height: 500px;width: 200px;">
            <p>It appears your web browser doesn't support iframes.</p>
          </iframe>
          <table class="pnv-table table table-striped">
            <tr>
              <th class="text-align-left t-head">Medicine</th>
              <th class="text-align-left t-head">Power</th>
              <th class="text-align-center t-head">Usage</th>
              <th class="text-align-center t-head">No of Days</th>
              <th class="text-align-center t-head">Morning</th>
              <th class="text-align-center t-head">Evening</th>
              <th class="text-align-center t-head">Afternoon</th>
            </tr>
            <tbody>
              <tr *ngFor="let medicine of MedicationPrescriptions">
                <td>{{medicine.medicineName}}</td>
                <td>{{medicine.power}}</td>
                <td>{{medicine.usage}}</td>
                <td>{{medicine.numberOfDays}}</td>
                <td>{{medicine.morning}}</td>
                <td>{{medicine.afternoon}}</td>
                <td>{{medicine.evening}}</td>

              </tr>
            </tbody>

          </table>


        </div>

      </div>

    </div>

  </div>
</div>
<div class="modal fade modal-left" id="activeMedication" style="margin-left:5%" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:75%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Active Medications</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="p-3">
          <!-- <div class="col-md-12 ml-3" >    <h3 class="mt-0 mb-3 border-bottom pt-2 pb-2 head">Current Medication</h3>
           </div> -->

          <table class="table table-striped table-hover " role="grid" aria-describedby="example2_info">
            <thead>
              <tr role="row">
                <th width="200"> Medicine Name </th>
                <th width="200"> Doctor Name </th>
                <!-- <th width="200">  Doctor Specilization	</th> -->
                <th width="200"> Appointment Date </th>
                <th width="200"> Morning <img src="./assets/img/morning.png" /></th>
                <th width="200"> Afternoon <img src="./assets/img/afternoon.png" /> </th>
                <th width="200"> Evening <img src="./assets/img/evening.png" /></th>
              </tr>
            </thead>
            <tbody>

              <ng-container *ngIf="( activeMedicationList) as result">
                <tr *ngIf="result.length==0">
                  <div style="position: absolute;top: 107%;" class="no-medication">

                  </div>
                </tr>
                <tr class="odd" data-id="9" *ngFor="let medicine of  result ">


                  <td><a href="javascript:void(0)">{{medicine.medicineName}}</a></td>
                  <td><a href="javascript:void(0)">{{medicine.doctorName}}</a></td>
                  <!-- <td>{{medicine.doctorSpecialization}}</td> -->
                  <td>{{medicine.appointmentDate | date:'dd-MMM-yyyy'}}</td>
                  <td class="text-center"><i class="fas fa-check themecol1 mr-4" aria-hidden="true"
                      *ngIf="medicine?.morning?.dosageConsumed=='Y'"></i>
                    <i class="fas fa-times themecol3 mr-4" aria-hidden="true"
                      *ngIf="medicine?.morning?.dosageConsumed=='N'"></i>
                    <!-- <i class="fas fa-times themecol3" aria-hidden="true"></i> -->
                  </td>
                  <td class="text-center"><i class="fas fa-check themecol1 mr-4" aria-hidden="true"
                      *ngIf="medicine?.afternoon?.dosageConsumed=='Y'"></i>
                    <i class="fas fa-times themecol3 mr-4" aria-hidden="true"
                      *ngIf="medicine?.afternoon?.dosageConsumed=='N'"></i>
                    <!-- <i class="fas fa-times themecol3" aria-hidden="true"></i>  -->
                  </td>
                  <td class="text-center"><i class="fas fa-check themecol1 mr-4" aria-hidden="true"
                      *ngIf="medicine?.evening?.dosageConsumed=='Y'"></i>
                    <i class="fas fa-times themecol3 mr-4" aria-hidden="true"
                      *ngIf="medicine?.evening?.dosageConsumed=='N'"></i>
                    <!--<i class="fas fa-times themecol3" aria-hidden="true"></i> -->
                  </td>
                </tr>
              </ng-container>





            </tbody>
          </table>
        </div>

      </div>

    </div>

  </div>
</div>
<div class="modal fade modal-left" id="patientHistory" style="margin-left:5%" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:75%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Patient History</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-medical-history [patientPersonalDetails]="patientPersonalDetails"></app-medical-history>

      </div>

    </div>

  </div>
</div>
<div class="modal fade modal-left" id="previousAppointments" style="margin-left:5%" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:75%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Previous Appointments</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div *ngIf="previousAppointmentsList.length==0">
          <div class="no-appointments"></div>
        </div>
        <div *ngIf="previousAppointmentsList.length > 0" class="row">
          <div class="col-md-3 " *ngFor="let appointment of previousAppointmentsList" data-toggle="modal"
            data-backdrop="static" data-target="#mypreviousappointment1">
            <div class="patient-widget mt-1 mb-1 p-2 previous-ap">
              <div class=" patient-top-details">
                <div><span class="invoice-id"> {{appointment.appointmentDate | date: 'dd-MMM-yyyy'}}</span></div>
                <div><span class="date-col"> {{appointment.fromTime|convertFrom24To12Format}}</span> </div>
              </div>
              <div class="invoice-widget">
                <div class="pat-info-left">
                  <div class="pat-info-cont">
                    <h4 class="pat-name"><a href="javascript:void(0)"
                        (click)="getPatientDetails(appointment)">{{appointment.patientName | titlecase}} </a></h4>
                    <div class="patient-details-col"><span></span></div>
                  </div>
                </div>
                <div class="pat-info-right float-right">
                  <div class=" ">
                    <div class="btn-group float-right payment-resize">

                      <!-- <button class="btn btn-sm mr-1 mt-2 p-1" (click)="getPatientDetails(appointment)"><i class="fa fa-user themecol2    patientc2 " aria-hidden="true" data-placement="top" data-toggle="tooltip" data-original-title="Patient Details"></i></button> -->
                      <button class="btn btn-sm mr-1 mt-2 p-1" (click)="prescriptionList(appointment)"><i
                          class="fa fa-file-prescription themecol4   patientc4 " aria-hidden="true" data-placement="top"
                          data-toggle="tooltip" data-target="#myprescription"
                          data-original-title="Prescription"></i></button>
                      <button class="btn btn-sm mr-1 mt-2 p-1" (click)="getCompleteInvoiceDetails(appointment)" data-placement="top"
                      data-toggle="tooltip" data-original-title="Invoice"><i
                          class="fa fa-file-invoice icon-head patientc2" aria-hidden="true"></i></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade modal-right" id="doc-invoice" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight" aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Invoice</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body" id="contentToConvertTwo">
				<div class="block inv-section">
					<div class="top-inv-col">
						<div class="inv-logo">
							<img alt="" src="./assets/img/logo.png">
						</div>
						<div class="float-right text-left order-details">
							<p>Order ID: <span>{{docinvoiceData?.invoiceId}}</span></p>
							<p>Date: <span>{{docinvoiceData?.lastUpdatedTs| date:'dd-MMM-yyyy'}}</span></p>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6 col-xs-6">
							<h5>Invoice From</h5>
							<ul class="inv-receiver">
								<li class="">Dr. {{docinvoiceData?.address?.contactName |titlecase}}<br> {{docinvoiceData?.address?.street1}}
									{{docinvoiceData?.address?.street2}} <br> {{docinvoiceData?.address?.city}}
									<br> {{docinvoiceData?.address?.state}},{{docinvoiceData?.address?.country}} -
									{{docinvoiceData?.address?.zip}}
								</li>
							</ul>
						</div>
						<div class="col-md-6 col-xs-6">
							<h5>Invoice To</h5>
							<ul class="inv-receiver">
								<li class="">{{docinvoiceData?.patientAddressDTO?.contactName |titlecase}}<br> {{docinvoiceData?.patientAddressDTO?.street1}}
									{{docinvoiceData?.patientAddressDTO?.street2}} <br> {{docinvoiceData?.patientAddressDTO?.city}}<br>
									{{docinvoiceData?.patientAddressDTO?.state}},{{docinvoiceData?.patientAddressDTO?.country}} -
									{{docinvoiceData?.patientAddressDTO?.zip}}
								</li>
							</ul>
						</div>
						<div class="col-md-6">
							<div class="payment-method">
								<h5>Payment Method</h5>
								<ul>
									<li>{{docinvoiceData?.transactiontype}}</li>
								</ul>
							</div>
						</div>
					</div>
					<table class="inv-table table table-striped">
						<tbody>
							<tr>
								<th class="text-align-left">Type of Appointment</th>
								<th class="text-align-center" style="text-align: center;padding-left: 6%;">Total</th>
							</tr>
							<tr>
								<td class="">
									{{docinvoiceData?.chatType}}
								</td>
								<td class="" style="text-align:right !important;padding-left: 8%;"><span class="inr"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;</span>{{docinvoiceData?.amount}}</td>
							</tr>
						</tbody>
						<tfoot>
							<tr>
								<td><b>Sub-total:</b></td>
								<td><span class="inr"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;</span>{{docinvoiceData?.amount}}</td>
							</tr>
							<tr>
								<td><b>Discount:</b></td>
								<td>0</td>
							</tr>
							<tr>
								<td><b>Total Amount:</b></td>
								<td><span class="inr"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;</span>{{docinvoiceData?.amount}}</td>
							</tr>
						</tfoot>
					</table>
				</div>
				<div class="invoice-info">
					<button class="btn btn-sm colr-3 ml-2 mt-2 p-2 patientbg3 pull-right" type="submit" (click)="downloadAsPDF()">
					 Download Invoice </button>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal fade modal-right" id="myprescription" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="myappointment"
  aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:45%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Prescription Details</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">




        <div class="schedule-tab">




          <div class="tab-content">
            <div class="tab-pane active" id="tab_start">
              <div class="priscription">
                <h3><i class="fa fa-file-prescription"></i> Medication Prescription <div class="float-right">
                    <!-- <button  class="btn btn-xs btn0col1" data-backdrop="static" data-target="#viewpdf">
  <i  aria-hidden="true" class="fa fa-download"></i></button> -->
                  </div>
                </h3>

                <div class="row list">
                  <div class="col-md-6" *ngFor="let row of MedicationPrescriptions">
                    <div class="d-flex flex-row mb-0 p-2 border-bottom w-100 card float-left blt-2 mb-2 mt-1">
                      <a class="p-2 icon-appointments" href="javascript:void(0)"><i aria-hidden="true"
                          class="fa fa-tablets icon-col-1 icon-reports"></i></a>
                      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2">
                        <a href="javascript:void(0)">
                          <p class="font-weight-medium mb-1 mt-1">
                            {{ row.medicineName }}
                          </p>
                          <p class="text-muted mb-1 text-small">{{ row.power }}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <h3><i class="fa fa-file-prescription"></i> Lab Tests <div class="float-right">
                    <!-- <button  class="btn btn-xs btn0col1" data-backdrop="static" data-target="#viewpdf">
   <i  aria-hidden="true" class="fa fa-download"></i></button> -->
                  </div>
                </h3>

                <div class="row list">
                  <div class="col-md-6" *ngFor="let row of medicalTestPrescription">
                    <div class="d-flex flex-row mb-0 p-2 border-bottom w-100 card float-left blt-2 mb-2 mt-1">
                      <a class="p-2 icon-appointments" href="javascript:void(0)"><i aria-hidden="true"
                          class="fa fa-tablets icon-col-1 icon-reports"></i></a>
                      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2">
                        <a href="javascript:void(0)">
                          <p class="font-weight-medium mb-1 mt-1">
                            {{ row.testName }}
                          </p>
                          <p class="text-muted mb-1 text-small">{{ row.instructions }}</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <!-- Tab pane end -->



            <!-- Tab pane 1 end -->

            <!-- Tab pane 2 end -->

            <!-- Tab pane 3 end -->


            <!-- Tab pane 5 end -->
            <div class="tab-pane " id="tab_four">





            </div>
            <!-- Tab pane end -->

          </div>
          <!-- tab content -->

        </div>





      </div>

    </div>
  </div>

</div>
<div class="modal fade modal-right" id="viewPrescription" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:45%">
    <div class="modal-content">

      <div class="modal-header">
        <h5 class="modal-title">Prescripiton Viewer</h5>

        <button type="button" class="close" (click)="closeModal()" title="Close" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="contentToConvertTwo">
        <div class="block inv-section">
          <div class="top-inv-col">
            <div class="inv-logo">
              <img alt="" src="./assets/img/logo.png">
            </div>
            <div class="float-right text-left order-details">
              <p>Order ID: <span>{{precId}}</span></p>
              <p>Date: <span>{{Createddate | date: "dd-MMM-yyyy" }}</span></p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6" *ngIf="doctorName!='SELF'">
              <h5>Prescription From</h5>
              <ul class="inv-receiver">
                <li class="">Dr.{{doctorName}}<br>{{dStreet1}}<br>
                  {{dStreet2}}
                  {{dCity}}-{{dZip}}<br>{{dState}}&nbsp;{{dCountry}}</li>
              </ul>

            </div>
            <div class="col-md-6" *ngIf="doctorName=='SELF'">
              <h5>Prescription From</h5>
              <ul class="inv-receiver ">
                <li class="">{{doctorName}}<br>{{pstreet1}} {{pstreet2}}<br>
                  {{pcity}}-{{pzip}}<br>{{pstate}}&nbsp;{{pcountry}}</li>
              </ul>

            </div>
            <div class="col-md-6">
              <div class="float-right text-left">
                <h5>Prescription To</h5>
                <ul class="inv-receiver ">
                  <li class="">{{patientName1}}<br>{{pstreet1}} {{pstreet2}}<br>
                    {{pcity}}-{{pzip}}<br>{{pstate}}&nbsp;{{pcountry}}</li>
                </ul>
              </div>
            </div>
          </div>

          <!-- <img *ngIf="showImage==true" [src]="prescriptionImage" style="height: 264px" alt="" />
      <iframe *ngIf="showPdf==true" [src]="prescriptionImage" class="pdf-viewer" frameborder="0" scrolling="no"
        style="height: 500px;width: 200px;">
        <p>It appears your web browser doesn't support iframes.</p>
      </iframe> -->

      <div>
        <ol class="prescription-ol pl-0">
          <li *ngFor="let row of medicalTestPrescription;let i =index"><i>{{row.testType}}</i> <b>
              {{row.testName}} </b><span> ({{row.instructions}})</span>
            <!-- <button  class="btn btn-danger btn-sm mr-2 float-right"><i  class="fa fa-minus "></i></button>
                  <button  class="btn btn-primary btn-sm mr-2 float-right"><i  class="fa fa-pencil-alt "></i></button> -->
            <!-- <button href="javascript:void(0)" class="btn  btn-sm btn-success mr-1 mt-2 p-1 float-right"
              type="submit" style="line-height: 11px;
                  font-size: 11px;" *ngIf="row?.fill=='N'" (click)="navigateToDiagnosticSearch([row])">
              Book Appointment</button> -->

            <ul style="padding-left: 10px">
              <li style="list-style: none">
                {{row.notes}}</li>
              <li style="list-style: none"></li>
            </ul>
          </li>
        </ol>
        <!-- <button  href="javascript:void(0)" class="btn  btn-sm btn-success mr-1 mt-2 p-1" type="submit"  (click)="navigateToDiagnosticSearch(medicalTestPrescription)" >
              Book Appointment</button> -->
      </div>



          <ol class="prescription-ol pl-0" *ngIf="!imageIsPresent">

            <li *ngFor="let med of MedicationPrescriptions ;let i=index"><i>{{med.type}}</i> <b> {{med.medicineName}}
                {{med.power}} </b><span> ({{med.instructions}})</span>
              <!-- <button  class="btn btn-danger btn-sm mr-2 float-right" ><i  class="fa fa-minus "></i></button>
        <button  class="btn btn-primary btn-sm mr-2 float-right" ><i  class="fa fa-pencil-alt "></i></button> -->

              <span class="float-right" style="border-left: 1px solid #000;  padding-left: 10px;margin-right: 70px;
        ">{{med.numberOfDays}}days - {{med.refill}} Refill(s)</span>

              <ul style="padding-left: 10px">
                <li style="list-style: none">
                  {{med.morning}}+{{med.afternoon}}+{{med.evening}} &emsp; {{med.instructions}}</li>
                <li style="list-style: none"></li>
              </ul>
            </li>

          </ol>
          <div class="prescriptionImage mb-5" *ngIf="imageIsPresent">
            <div class="col-md-6">
              <img *ngIf="showImage==true" [src]="newsrc" style="height: 264px" alt="" />
              <iframe *ngIf="showPdf==true" [src]="newsrc" class="pdf-viewer" frameborder="0" scrolling="no"
                style="height: 600px;">
                <p>It appears your web browser doesn't support iframes.</p>
              </iframe>
              <!-- <div class="patient-widget mt-1 ">
           <div  class="patient-top-details">
             <div >
           <span  class="invoice-id">{{doctorName}} </span></div><div >
           <span  class="date-col "> 03:42 PM</span></div></div>
           <div  class="invoice-widget"><div  class="pat-info-left">
           <div  class="mr-2"><a  href="javascript:void(0)" data-toggle="modal" data-backdrop="static"
             >
             <i  aria-hidden="true" class="fa fa-file-pdf icon-col-2 icon-reports"></i>
           </a></div><div  class="pat-info-cont"><h4  class="pat-name">
           <a  href="javascript:void(0)" data-toggle="modal" data-backdrop="static"
           >{{precName}}</a></h4><div  class="patient-details-col"><span ></span></div></div></div>
           <div  class="pat-info-right float-right">
           <div  class=" ">


         </div></div>
       </div>
       </div> -->
            </div>
          </div>
          <div class="prescribedBy" style="display: none;">
            <div>Prescribed By</div>
            <div> <img *ngIf="showImage==true" [src]="doctorSignatureSrc" style="height: 50px" alt="" /></div>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>
