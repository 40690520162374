import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, OnDestroy, EventEmitter, Output, Input, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

import { DoctorVideoService } from '../../services/doctorvideo.service';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { ObservableService } from '../../services/observable.service';
import { PatientService } from '../../services/patient.service';

@Component({
    selector: 'app-doctor-rooms',
    templateUrl: './doctor-rooms.component.html',
    styleUrls: ['./doctor-rooms.component.css']
})
export class DoctorRoomsComponent implements OnInit {
    @Output() roomChanged = new EventEmitter<string>();
    @Input() activeRoomName: string;

    roomName: string;
    appointmentId: string;
    // rooms: NamedRoom[];
    rooms: any
    param1
    param2
    private subscription: Subscription;
    roomsInP: any;
    roomNameIn: any;

    constructor(private globalStorage: GlobalStorageService, 
        private videoChatService: DoctorVideoService, private route: ActivatedRoute, private observable: ObservableService,private patientService:PatientService) {

        this.observable.invokeEvent_insert_doctor.subscribe(value => {
            if (value === 'someValue') {
                this.roomName = this.globalStorage.getItem('roomName')
                this.appointmentId = this.globalStorage.getItem('AppointmentId')
            }
        });
    }

    ngOnInit() {



        // this.subscription =
        //     this.videoChatService
        //         .$roomsUpdated
        //         .pipe(tap(_ => this.updateRooms()))
        //         .subscribe();
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onTryAddRoom() {
        if (this.roomName) {
            this.onAddRoom(this.roomName, this.appointmentId);
        }
    }

    onAddRoom(roomName: string, appointmentId: string) {
        this.patientService.closeVideoCalling()
        this.globalStorage.getItem('videoUserId')
        var patientId
        this.roomName = null;
        this.roomChanged.emit(roomName);
        this.videoChatService.getUserName(appointmentId, this.globalStorage.getItem('videoUserId'))

    }

    onJoinRoom(roomName: string) {
        this.roomChanged.emit(roomName);

    }


    updateRooms() {
        this.videoChatService.getAllRooms().subscribe(res => {
            this.rooms = res
            this.roomNameIn = res.room.Name
        })
    }

}
