import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import { Subject, observable, Observable } from 'rxjs'
import { HttpClient, HttpHandler, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ApicallsService } from '../apicalls.service';

@Injectable({
  providedIn: 'root'
})
export class DoctorPatientService {


  constructor(private globalStorage: GlobalStorageService, private http: HttpClient, private apiServices: ApicallsService) {
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      this.baseURL = environment.doctorBaseUrl1;
      this.patientBaseURL = environment.patientBaseUrl1;
      this.pharmaUrl = environment.pharmaUrl1
      this.ivfUrl = environment.ivfUrl1
    }
   }

  baseURL = environment.doctorBaseUrl;
  patientBaseURL = environment.patientBaseUrl;
  pharmaUrl = environment.pharmaUrl
  ivfUrl=environment.ivfUrl
  headers = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) }

  private patientDetailsSubject = new Subject<any>();
  patientObservable = this.patientDetailsSubject.asObservable();

  private viewMedicalReportSubject = new Subject<any>();
  viewMedicalReportObservable = this.viewMedicalReportSubject.asObservable();

  private viewAppointment = new Subject<any>();
  viewAppointmentObservable = this.viewAppointment.asObservable();

  private viewPrescriptionList = new Subject<any>();
  viewPrescriptionObservable = this.viewPrescriptionList.asObservable();

  private viewMedicalReportsList = new Subject<any>();
  viewMedicalReportsListObservable = this.viewMedicalReportsList.asObservable();

  private videoCallingSubject = new Subject<any>();
  videoCallingObservable = this.videoCallingSubject.asObservable();

  private joinCallSubject = new Subject<any>();
  joinCallObservable = this.joinCallSubject.asObservable();

  private closeVideoCallingSubject = new Subject<any>();
  closeVideoCallingObservable = this.closeVideoCallingSubject.asObservable();

  getAllPatientDetails(patientId) {
    return this.apiServices.get(this.patientBaseURL + 'patientSummeryRecord/' + patientId, this.headers)
  }


  getPatientPersonalDetails(patientId: any, body) {
    let params = new HttpParams().set('forUserId', patientId);
    return this.apiServices.post(this.patientBaseURL + 'personaldetails/get/', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': patientId.toString(), "accept": "*/*" }), observe: 'response' })
  }
  getUserPersonalDetails(endpoint, userId, req) {
    // let params = new HttpParams().set('forUserId', userId);
    return this.apiServices.post(this.patientBaseURL + endpoint, req,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString(), "accept": "*/*" }), observe: 'response' })
  }

  getPatientPreviousPrecription(patient) {
    let params = new HttpParams();
    params = params.set('patientId', patient.patientId),
      params = params.set('referenceEntityId', patient.referenceEntityId)
    return this.apiServices.get(this.baseURL + 'appointment/searchappointment', { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }


  getPatientActiveMedication(medication) {

    let params = new HttpParams().set('forUserId', medication.forUser.forUserId);
    return this.apiServices.post(this.patientBaseURL + 'prescription/medication/view/all', medication, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': medication.forUser.forUserId.toString(), "accept": "*/*" }) })
  }
  getIVFPatientActiveMedication(medication) {
    //let params = new HttpParams().set('forUserId', medication.forUser.forUserId);
    return this.apiServices.post(this.ivfUrl + 'getMedicationsForTheDay', medication, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': medication.patientId.toString(), "accept": "*/*" }) })
  }
  getMedicationDaily(body) {
    return this.apiServices.post(this.patientBaseURL + 'prescription/getMedicationsForTheDay', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': body?.forUser?.forUserId.toString() }), observe: 'response' })
  }
  getPatientVitalDetails(vitals) {
    //let params = new HttpParams().set('forUserId',vitals.forUserId);
    return this.apiServices.post(this.patientBaseURL + 'vitals/get', vitals, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': vitals.forUserId.toString(), "accept": "*/*" }) })
  }
  getIvfPatientVitals(addressId,userId){
    if(userId){
      return this.apiServices.get(this.ivfUrl+'getUserVitals?ivfAddressId='+addressId+'&userId='+userId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
    }
  }
  getPatientMedicalReports(reqObj, pageNum, maxResults) {
    let params = new HttpParams().set('forUserId', reqObj.forUserId);
    //return this.apiServices.post(this.patientBaseURL+'/Schedule/medicalreports/retrieve/user',patient,{params:params,headers: new HttpHeaders({ 'Content-Type': 'application/json' ,'user-session':JSON.parse(this.globalStorage.getItem('userSession'))}),observe: 'response' })
    return this.apiServices.post(this.patientBaseURL + 'Schedule/medicalreports/retrieve/user/' + pageNum + '/' + maxResults, reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': reqObj.forUserId.toString() }), observe: 'response' })

  }

  getPatientMedicalReportsByMrId(patient) {
    let params = new HttpParams();
    params = params.set('forUserId', patient.forUserId)
    //params=params.set('medicalReportId',patient.medicalReportId)
    return this.apiServices.get(this.patientBaseURL + 'Schedule/medicalreports/retrieve/id/' + patient.medicalReportId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': patient.forUserId.toString() }), observe: 'response' })
  }

  getPatientPrecriptionByAppointmentId(appointment) {
    let params = new HttpParams();
    //  params=params.set('patientId',appointment.patientId)
    params = params.set('appointmentId', appointment.appointmentId)

    return this.apiServices.get(this.baseURL + 'prescription/appointment/' + appointment.patientId, { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  getPatientPreviosAppointments(patient) {
    let params = new HttpParams();
    params = params.set('forUserId', patient.forUserId),
      params = params.set('referenceEntityId', patient.referenceEntityId)
    return this.apiServices.post(this.patientBaseURL + 'appointment/view/patientself',patient, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getPatientAppointmentByAppointmentId(appointmentId) {
    let params = new HttpParams();
    //params=params.set('appointmentId',appointmentId.appointmentId),
    params = params.set('forUserId', appointmentId.forUserId)
    return this.apiServices.get(this.patientBaseURL + 'appointment/id/' + appointmentId.appointmentId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': appointmentId.forUserId.toString(), "accept": "*/*" }) })
  }


  //*********** * patientDetails *******************/
  getPatientMedicalHistory(medical) {
    let params = new HttpParams();
    params = params.set('forUserId', medical.forUserId)
    return this.apiServices.get(this.patientBaseURL + 'medicalhistorycontroller/getAllMedicalhistoryDetail/' + medical.userId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': medical?.forUserId.toString(), "accept": "*/*" }) })
  }
getIvfPatientMedicalHistory(medical){
  let params = new HttpParams();
  params = params.set('forUserId', medical.forUserId)
  return this.apiServices.get(this.ivfUrl + 'getAllMedicalhistory/' + medical.userId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': medical?.forUserId.toString(), "accept": "*/*" }) })
}


  //*********** * Notifications *******************/
  getNotificationsAlertsDoctor(request) {

    return this.apiServices.get(this.baseURL + 'notificationalerts/get/week/' + request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }

  getMedicationBydate(body, userId) {
    return this.apiServices.post(this.patientBaseURL + 'prescription/getMedicationsForTheDay', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }


  OpenPatientDetails(data: any) {
    this.patientDetailsSubject.next(data)
  }

  viewMedicalReport(report) {
    this.viewMedicalReportSubject.next(report);
  }
  videoCalling(appointmentDetails) {
    this.videoCallingSubject.next(appointmentDetails);
  }

  jointCall(appointment) {
    this.joinCallSubject.next(appointment)
  }

  closeVideoCalling() {
    this.closeVideoCallingSubject.next();
  }

  openAppointment(appointment) {
    this.viewAppointment.next(appointment);
  }

  openPrescriptionList(patient) {
    this.viewPrescriptionList.next(patient)
  }
  openMedicalReportList(patient) {
    this.viewMedicalReportsList.next(patient)
  }
  getGlobal(endpoint, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiServices.get(this.patientBaseURL + endpoint,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }) })
  }
  getPrescriptionPostSession(endpoint,body,userId){
    return this.apiServices.post(this.patientBaseURL + endpoint, body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }) })
  }
  globalgetBody(endpoint, body, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiServices.get(this.patientBaseURL + endpoint + body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }) })
  }
  globalpostBody(endpoint, body, userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiServices.post(this.patientBaseURL + endpoint , body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }) })
  }
  getPatientAddress(endpoint, body, userId) {
    return this.apiServices.post(this.patientBaseURL + endpoint, body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }) })
  }
  getDoctorAddressDetails(doctorId) {
    return this.apiServices.get(this.baseURL + 'address/doctorid/' + doctorId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  getPharmaAddress(id) {
    return this.apiServices.get(this.pharmaUrl + 'address/' + id + '/' + 1 + '/' + 1,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  getHeartRateReports(selectedPeriod, formData, userId) {
    if(formData?.userId){
      return this.apiServices.post(this.patientBaseURL + 'vitalsReportController/executeReport?time=' + selectedPeriod + '&vital=pulse', formData, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId?.toString() }), observe: 'response' })
    }
  }
  getUserVitalsForGraph(body,userId){
    return this.apiServices.post(this.patientBaseURL+'vitalsReportController/executeReportForVitals',body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId?.toString() }), observe: 'response' })
  }
  getTemperatureReports(selectedPeriod, formData, userId) {
    return this.apiServices.post(this.patientBaseURL + 'vitalsReportController/executeReport?time=' + selectedPeriod + '&vital=temperature', formData, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })
  }
  getPressureReports(selectedPeriod, formData, userId) {
    return this.apiServices.post(this.patientBaseURL + 'vitalsReportController/executeReport?time=' + selectedPeriod + '&vital=bloodPressure', formData, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })

  }
  getWeightReports(selectedPeriod, formData, userId) {
    return this.apiServices.post(this.patientBaseURL + 'vitalsReportController/executeReport?time=' + selectedPeriod + '&vital=weight', formData, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })

  }
  getOxygenLevels(selectedPeriod, formData, userId) {
    return this.apiServices.post(this.patientBaseURL + 'vitalsReportController/executeReport?time=' + selectedPeriod + '&vital=oxygenLevel', formData, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': userId.toString() }), observe: 'response' })

  }
}
