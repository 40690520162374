import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
declare var $;
@Component({
  selector: 'app-discount-definition',
  templateUrl: './discount-definition.component.html',
  styleUrls: ['./discount-definition.component.css']
})
export class DiscountDefinitionComponent implements OnInit {
  @Input() hospitalData:any
  usersList;submitted=false;selectedPerson;
  discountForm:FormGroup;allRoles;selectedRole;selectedDepartment
  constructor(private globalStorage: GlobalStorageService, private toastr: ToastrService,public activeModal: NgbActiveModal,private fb:FormBuilder,private clinicService:ClinicService,) { }
  @Input() data:any
  ngOnInit(): void {
    console.log('dadadada',this.data)
     this.getALLroles()
     this.fetchDepartments()
    this.discountForm=this.fb.group({
      amount:['',[Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1)]],
      person:['',Validators.required],
      role:['',Validators.required],
      apprver:[''],
      department:['',Validators.required]
    })
    if(this.data?.mode=='EDIT'){
      this.getALLroles()
      this.fetchDepartments()
      this.discountForm.patchValue({
        amount:this.data?.info?.amountUpto,
        apprver:this.data?.info?.name,
        department:this.data?.info?.departmentName
        //role:this.data?.info?.roleName
      })
      this.departmenId=this.data?.info?.departmentId
     }
  }
 get d(){return this.discountForm.controls;}
 getALLroles(){
  this.clinicService.allroles().subscribe(res=>{
    this.allRoles=res['data']?.filter(obj=>obj?.roleId!=1 && obj?.roleId!=12)
    if(this.data?.mode=='EDIT'){
      this.allRoles=this.allRoles?.filter(obj=>obj?.roleId==this.data?.info?.roleId)
      this.selectedRole=this.allRoles[0]?.roleName
      this.roleSelect=this.allRoles[0]?.roleName
      this.departmenId=this.data?.info?.departmentId
      this.discountForm.patchValue({
        role:this.selectedRole,
        department:this.data?.info?.departmentName
      })
    }
  })
 }
  getAllUsers(){
    this.clinicService.getUsersByRole(parseInt(this.globalStorage.getItem('hospitalAddressId')),this.departmenId,this.selectedRole?.roleId).subscribe(res=>{
      this.usersList=res['data']
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
  drpdownForSelection(data){
    console.log('selected',data)
    this.selectedData=data
  }
  close(){
    this.activeModal.close()
  }
  selectedData
  selectedPersonData(data){
    // this.selectedData=data
  }

  add(){
    // if(this.discountForm.invalid){
    //   this.toastr.warning('Please Fill all Fields')
    //   return 
    // }

    if(this.discountForm.value.amount == 0 || !this.discountForm.value.amount){
      this.toastr.warning('Amount must be greater than zero')
      return
    }
    if(this.data?.mode=='EDIT'){
      this.selectedData=[]
      var obj={
        "flag":'Y',
        "amount": this.discountForm.value.amount,
        "hospitalAddressId": this.globalStorage.getItem('hospitalAddressId'),
        "hospitalUserId": this.data?.info?.hospitalUserId,
        "name":this.data?.info?.name,
        "roleId":this.data?.info?.roleId,
        "departmentId":this.departmenId,
        "discountApprovalEligibilityId":this.data?.info?.discountApprovalEligibilityId
        }
       // this.selectedData.push(obj)
        this.clinicService.updateDiscount(obj).subscribe(res=>{
          this.toastr.success('Discount flow created.')
          this.activeModal.close()
        },error=>{
          this.toastr.error(error?.error?.message)
        })
    }
    else{
      for(var i=0;i<this.selectedData?.length;i++){
        var object={
        "amountUpto": this.discountForm.value.amount,
        "hospitalAddressId": this.globalStorage.getItem('hospitalAddressId'),
        "hospitalUserId": this.selectedData[i]?.hospitalUserId,
        "departmentId":this.departmenId,
        "name":this.selectedData[i]?.firstName+' '+this.selectedData[i]?.lastName,
        "roleId":this.selectedRole?.roleId
        }
        this.selectedData[i]=object
      }
      console.log('add def',this.selectedData)
      var body={
        "discountApprovalEligibility":this.selectedData
      }
      this.clinicService.saveDiscount(body).subscribe(res=>{
        this.toastr.success('Discount flow created.')
        this.activeModal.close()
      },error=>{
        this.toastr.error(error?.error?.message)
      })
    }
  }
  roleSelect
  selectedRoles(data){
    console.log('roles',data)
    this.selectedRole=data
    this.roleSelect=data?.roleName
    this.getAllUsers()
  }
  drpdownForSelectionRole(data){

  }
  departmenId
  getSelectedDept(department){
    this.departmenId = department?.departmentId;
  }
  drpdownSelectionDepartment(data){

  }
  departments
  fetchDepartments() {
    let hospitaAddressId = parseInt(this.globalStorage.getItem('hospitalAddressId'));
    this.clinicService.getdepartmentInformation(hospitaAddressId).subscribe(res => {
      this.departments = res?.['data'];
    },
      error => {
        this.toastr.error(error?.['error']?.['message']);
      }
    )
  }
}
