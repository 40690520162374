import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from '@angular/router';
import * as moment from 'moment';
@Component({
  selector: 'app-book-appointment',
  templateUrl: './book-appointment.component.html',
  styleUrls: ['./book-appointment.component.css']
})
export class BookAppointmentComponent implements OnInit {

  constructor(private globalStorage: GlobalStorageService, public dialogRef: MatDialogRef<BookAppointmentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private router: Router,) { }
    slotDetails
    selectedDate
    fromTime
    toTime
    selectedOption
    isRpmcaretaker=false
    options: string[] = ['Video','Inperson'];
  ngOnInit(): void {

    this.selectedOption='Video'
    if(this.globalStorage.getItem('loggedInAs')=='IVF'){
      // this.options= ['Inperson'];
      this.selectedOption='Inperson'
    }
    this.slotDetails=this.data
    this.slotDetails['type']=this.selectedOption
    this.selectedDate = moment(this.slotDetails.startStr).format('YYYY-MM-DD');
   var start = new Date(this.slotDetails.startStr);
   var end = new Date(this.slotDetails.endStr);
    if(this.globalStorage.getItem('rpmadminAddressId') || this.router.url=='/in-patient-details'){
      this.isRpmcaretaker=true
    }
    else{
      this.isRpmcaretaker=false
    }
    var fromTime=start.toLocaleTimeString();
    var ToTime=end.toLocaleTimeString();
     //var fromTime=this.selectedDate.startStr.toLocaleTimeString();
    // var ToTime=this.selectedDate.startStr.toLocaleTimeString();
     this.fromTime=this.getTwentyFourHourTimeWithoutSec(fromTime),
     this.toTime=this.getTwentyFourHourTimeWithoutSec(ToTime)
  }
  getTwentyFourHourTimeWithoutSec(str) { 
    // var d = new Date("1/1/2013 " + amPmString); 
     //return d.getHours() + ':' + d.getMinutes()+ ':'+ '00'; 
     str = String(str).toLowerCase().replace(/\s/g, '');
     var has_am = str.indexOf('am') >= 0;
     var has_pm = str.indexOf('pm') >= 0;
     // first strip off the am/pm, leave it either hour or hour:minute
     str = str.replace('am', '').replace('pm', '');
     // if hour, convert to hour:00
     if (str.indexOf(':') < 0) str = str + ':00';
     // now it's hour:minute
     // we add am/pm back if striped out before 
     if (has_am) str += ' am';
     if (has_pm) str += ' pm';
     // now its either hour:minute, or hour:minute am/pm
     // put it in a date object, it will convert to 24 hours format for us 
     var d = new Date("1/1/2011 " + str);
     // make hours and minutes double digits
     var doubleDigits = function(n){
         return (parseInt(n) < 10) ? "0" + n : String(n);
     };
     return doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes());
  }

  getTwentyFourHourTime(str) { 
    // var d = new Date("1/1/2013 " + amPmString); 
     //return d.getHours() + ':' + d.getMinutes()+ ':'+ '00'; 
     str = String(str).toLowerCase().replace(/\s/g, '');
     var has_am = str.indexOf('am') >= 0;
     var has_pm = str.indexOf('pm') >= 0;
     // first strip off the am/pm, leave it either hour or hour:minute
     str = str.replace('am', '').replace('pm', '');
     // if hour, convert to hour:00
     if (str.indexOf(':') < 0) str = str + ':00';
     // now it's hour:minute
     // we add am/pm back if striped out before 
     if (has_am) str += ' am';
     if (has_pm) str += ' pm';
     // now its either hour:minute, or hour:minute am/pm
     // put it in a date object, it will convert to 24 hours format for us 
     var d = new Date("1/1/2011 " + str);
     // make hours and minutes double digits
     var doubleDigits = function(n){
         return (parseInt(n) < 10) ? "0" + n : String(n);
     };
     return doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes()) + ':' +'00';
 }

  
  Yes() {
    this.dialogRef.close({ event: 'close', data:'YES' });
  }
  No() {
    this.dialogRef.close({ event: 'close', data:'NO' });
  }
  checkBoxCheck(a,b){
    this.selectedOption=a.value
    this.slotDetails['type']=this.selectedOption
  }
}
