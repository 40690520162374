import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NoMatch } from 'src/app/_helpers/must_match.validators';
import { GlobalStorageService } from 'src/app/global-storage.service';
import { DoctorScheduleComponent } from '../doctor-schedule/doctor-schedule.component';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { DoctorService } from 'src/app/AppServices/doctor.service';

@Component({
  selector: 'app-patient-creat-and-book-appointment',
  templateUrl: './patient-creat-and-book-appointment.component.html',
  styleUrls: ['./patient-creat-and-book-appointment.component.css']
})
export class PatientCreatAndBookAppointmentComponent implements OnInit {
  registerForm: FormGroup;
  userFound=false; reasonForm: FormGroup;booknowEnabled = false;
  @ViewChild('cellInput') cellInput: ElementRef;
  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder,private toaster: ToastrService,
    private doctorSearch: PatientScheduleControlService,private clinicServices: ClinicService,private doctorService: DoctorService,
    private modalService:NgbModal, public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.reasonForm = this.formBuilder.group({
      reason: ['', Validators.required]
    })
    this.initializeForm();
    this.getDoctorData()
    this.getBaseschedule()
  }
  get f() { return this.reasonForm?.controls; }
  reasonEnter(data) {
    if (data) {
      this.doctorSearch.sendError(null)
      this.booknowEnabled = true
    }
    else {
      this.booknowEnabled = false
    }
  }
  phNumberModify(){
    this.phNum=this.registerForm.value.cellNumber
      this.userFound = false;
        this.registerForm = this.formBuilder.group(
          {
            "active":"Y",
            "patientReferenceId": null,
            "emailId": null,
            "lastName": [''],
            "cellNumber":this.phNum,
            "firstName": [''],
            "doctorId": this.globalStorage.getItem('doctorId')
          })
  }
  phNum
  checkPhNumber() {
    // console.log(num)
    if (this.registerForm.value.cellNumber.length == 10) {
      
      const body = {
        "cellNumber":this.getMobileNumbers(this.cellInput),
        "pharmacyaddressId": this.globalStorage.getItem('pharmacyId')
      }
      this.doctorSearch.searchPatientByPhoneNumber(this.getMobileNumbers(this.cellInput).replace(/\+/gi, '%2B')).subscribe(res => {
        console.log('res',res?.data)
        this.reasonForm.patchValue({
          "emailId": '',
           "lastName": '',
           "firstName": '',
         })
        if (res?.data) {
          this.userId=res?.data?.[0]?.userId
          this.getPatientData()
          this.registerForm.patchValue(res?.data[0])
          this.reasonForm.patchValue({
            "cellNumber":this.getMobileNumbers(this.cellInput),
          })
          this.userFound = true;
        } else {
          this.reasonForm.patchValue({
           "emailId": '',
            "lastName": '',
            "firstName": '',
          })
         // this.initializeForm()
        //  this.registerForm = this.formBuilder.group(
        //   {
        //     "active":"Y",
        //     "patientReferenceId": null,
        //     "emailId": null,
        //     "lastName": ['', Validators.required],
        //     "firstName": ['', Validators.required],
        //     "doctorId": this.globalStorage.getItem('doctorId')
        //   },
        //   {
        //     validators: [NoMatch('firstName', 'lastName')]
        //   });
          this.userFound = false;
        }
      })
    } else {
      this.toaster.error('Mobile Number must be 10 digits');
    }
  }
  initializeForm() {
    this.registerForm = this.formBuilder.group(
      {
        "active":"Y",
        "patientReferenceId": null,
        "emailId": null,
        "lastName": ['', Validators.required],
        "firstName": ['', Validators.required],
        "cellNumber": ['', Validators.required],
        "doctorId": this.globalStorage.getItem('doctorId')
      },
      {
        validators: [NoMatch('firstName', 'lastName')]
      });
  }
  getBaseschedule(){

  }
  getMobileNumbers(inputElement) {
    if (inputElement && inputElement.nativeElement) {
      // Make sure intlTelInput is initialized on the element
      const intlTelInputInstance = window.intlTelInputGlobals.getInstance(inputElement.nativeElement);
      if (intlTelInputInstance) {
        const number = intlTelInputInstance.getNumber();
        if (intlTelInputInstance) {
          // const countryData = intlTelInputInstance.getSelectedCountryData();
          return number;

        } else {
          console.error('Invalid phone number : ', number);
          return '';
        }
      } else {
        console.error('intlTelInput not initialized');
        return ''
      }
    } else {
      console.error('Input element is not provided or does not exist');
      return '';
    }
  }
  userId;
  getDoctorData(){
    this.doctorService.getDoctorDetailsById(this.globalStorage.getItem('doctorId')).subscribe(res=>{
      console.log('doctor details',res)
      this.doctorDetails=res['data']
      this.getDoctorFeeDetails()
    })
  }
  getDoctorFeeDetails(){
    this.doctorService.getDoctorFeeDetailsById(this.globalStorage.getItem('doctorId')).subscribe(res=>{
      console.log('fee response',res)
      this.doctorDetails['hospitalfeedetails']=res['data']?.['doctorsFee']
    })
  }
  savePatient(){
    const body=this.registerForm.value;
    body.cellNumber=this.getMobileNumbers(this.cellInput)
    body.userName=this.getMobileNumbers(this.cellInput)
    console.log('sending body',body)
    this.doctorSearch.saveUser(body).subscribe(res => {
      this.userId=res?.data?.['userId']
      this.registerForm.patchValue({
        patientReferenceId: res?.body?.data})
      this.toaster.success('User added.')
      this.userFound=true;
    this.getPatientData()
    }, error => {
      this.toaster.error(error?.['error']?.['message'])
      this.userFound=false;
    })
  }
  getPatientData(){
    this.clinicServices.findUserById(this.userId).subscribe(res=>{
      console.log('user data',res)
      this.patientDetails = JSON.parse(res['data'])})
  }
  patientDetails;doctorDetails={}
  bookDoctor(doctorDetails) {
    const modalRef = this.modalService.open(DoctorScheduleComponent, {
      centered: false,
      windowClass: 'modal-left hw-50',
      backdrop: 'static',
        keyboard: false,
    });
    modalRef.componentInstance.doctorDetails = doctorDetails;
    modalRef.componentInstance.patientDetails=this.patientDetails

    modalRef.result.then(
      (userConfirmed: boolean) => {
        this.activeModal.close()
        if (userConfirmed) {
          // this.getProtocols();
        }
      },
      () => { }  // Handle dismiss callback
    );
  }
  scrollLeft(){

  }
  scrollRight(){

  }
  currentMonth;currentYear
  typeOfAppointments(a,b){

  }
  openModel(){

  }
}
