<div class="p-2">
  <table class="table" style="width: 100%;">
    <thead>
      <tr>
        <th>Medicine</th>
        <th>Type</th>
        <th>Power</th>
        <th>Medicine Usage</th>
        <th>Number Of Days</th>
        <th>Notes</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let precription of patientPrecriptionList;let index=index">
        <td>{{precription.medicine}}</td>
        <td>{{precription.type}}</td>
        <td>{{precription.power}}</td>
        <td>{{precription.medicineUsage}}</td>
        <td>{{precription.numberOfDays}}</td>
        <td>{{precription.notes}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div class="no-image-bg1" *ngIf="patientPrecriptionList.length==0"></div>