import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { InventoryService } from '../inventory.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SaveBulkOrderComponent } from '../../modals/save-bulk-order/save-bulk-order.component';
import { AddCategoryComponent } from '../add-category/add-category.component';
import { Observable, of } from 'rxjs';
import { startWith, debounceTime, switchMap, catchError, map } from 'rxjs/operators';
import { AddSubcatAtSupplieritemsComponent } from '../../modals/add-subcat-at-supplieritems/add-subcat-at-supplieritems.component';
import { AddItemOrderComponent } from '../add-item-order/add-item-order.component';
import { SaveSubcategoryComponent } from '../../modals/save-subcategory/save-subcategory.component';
import { AddSupplierComponent } from '../add-supplier/add-supplier.component';

@Component({
  selector: 'app-order-items',
  templateUrl: './order-items.component.html',
  styleUrls: ['./order-items.component.css']
})
export class OrderItemsComponent implements OnInit {
  Supplierselected: any; createorders: FormGroup; suplier; submitted = false; supplierId; addressId; p1 = 1

  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder, private modalService: NgbModal, private inventoryService: InventoryService, private toastrService: ToastrService, public activeModal: NgbActiveModal, private invService: InventoryService) { }


  filteredOptions: Observable<any[]>;
  itemControl = new FormControl();

  ngOnInit(): void {
    this.createorders = this.formBuilder.group({
      Supplier: ['', Validators.required]
    })
    this.getsupplier()
    this.getItemsList()
    this.getCategoryList()

    this.filteredOptions = this.itemControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        switchMap(value => this.fetchFilteredOptions(value)),
        catchError(error => {
          console.error('Error fetching data: ', error);
          return [];
        })
      );
  }

  searchedItem: any;
  onItemSel(item) {
    this.selctedItemsData.push(item)
  }

  subCategoryAdd() {
    if (!this.Supplierselected) {
      this.toastrService.warning('Please Select Supplier')
      return
    }
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-right hw-40';
    }

    // const modalRef = this.modalService.open(AddItemOrderComponent, {


    const modalRef = this.modalService.open(SaveSubcategoryComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    // modalRef.componentInstance.supplierInfoObj = {
    //   name:this.supplierName,
    //   supplierId:this.Supplierselected
    // };
    modalRef.result.then(
      (result) => {
        this.getItemsList();
        // if (result) {
        //   this.selctedItemsData.push(result);
        // }
      }
    );
  }

  addSuplier() {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-right hw-40';
    }
    var addSuplier = this.modalService.open(AddSupplierComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    })
    addSuplier.componentInstance.isEdit = false;
    addSuplier.result.then(
      (result) => {
        this.getsupplier()
      }
    );


  }

  fetchFilteredOptions(value: string): Observable<any[]> {

    if (!value.trim()) {
      // If not search term, return empty array.
      return of([]);
    }

    const body = {
      "name": value
    }
    return this.invService.searchItemDetails(body).pipe(
      map(res => res['data']),
      catchError(error => {
        console.error('Error fetching medicines:', error);
        return of([]);
      })
    );
  }

  get createordersControls() {
    return this.createorders.controls;
  }
  supplierName: any
  selectedSuppiler(Supplierselected) {
    if (Supplierselected?.add) {
      this.addSuplier()
    }
    this.Supplierselected = Supplierselected?.supplierId
    this.supplierName = Supplierselected?.name
  }
  supplierInfo; supplierSelected = false
  getsupplierIdbyClick(sup) {
    this.supplierId = sup?.supplierId;
    this.supplierInfo = sup
    this.supplierSelected = true
    this.getItemsList(this.supplierId, null)
  }



  categoryList = []
  getCategoryList() {
    this.invService.getAllCatagories().subscribe(res => {
      this.categoryList = res['data'] || [];
      this.categoryList.unshift({
        catagory: 'ALL',
        catagoryId: null
      })
    })

  }
  getsupplier() {
    var body = {
      "flag": "ALL",
      "addressId": this.addressId
    }
    this.inventoryService.suplier(body).subscribe(res => {
      this.suplier = res?.data||[];
      this.suplier.push({
        name: 'Add New',
        add: true
      })
      this.p1 = 1;
    })
  }

  itemsData: any = []
  itemsLoading: boolean = false;
  getItemsList(supplierId?: number, catagoryId?: number) {
    var body = {}
    // if (supplierId) {
    //   body['supplierId'] = supplierId
    // }

    if (catagoryId) {
      body['categryId'] = catagoryId
    }

    this.itemsLoading = true;
    this.inventoryService.searchItemTypeForOrder(body).subscribe(res => {
      this.itemsData = res?.data || [];
      this.itemsLoading = false;
    })
  }

  selctedItemsData: any = []
  addToSelectedList(data: any) {
    const isItemAlreadySelected = this.selctedItemsData.some(item => item.itemTypeId === data.itemTypeId);

    if (!isItemAlreadySelected) {
      this.selctedItemsData.push(data);
    }
  }

  removeFromSelectedList(index) {
    if (index > -1) {
      this.selctedItemsData.splice(index, 1);
    }
  }

  isSelected(data: any): boolean {
    return this.selctedItemsData.some(item => item.itemTypeId == data.itemTypeId);
  }

  createPO() {
    if (this.Supplierselected && this.selctedItemsData.length > 0) {

      let wClass = 'left';
      if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
        wClass = 'left hw-80';
      }
      if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
        wClass = 'modal-right hw-80';
      }
      if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
        wClass = 'modal-right hw-80';
      }
      const modalRef = this.modalService.open(SaveBulkOrderComponent, {
        centered: false,
        backdrop: 'static',
        keyboard: false,
        windowClass: wClass
      });
      modalRef.componentInstance.allItems = this.selctedItemsData;
      modalRef.componentInstance.supplieId = this.Supplierselected;
      modalRef.result.then((result) => {
        this.activeModal.close();
      })

    } else {
      if (!this.Supplierselected) {
        this.toastrService.warning('Please Select Supplier')
        return
      }

      if (this.selctedItemsData.length == 0) {
        this.toastrService.warning('Please Select atleast one Item')
        return
      }
    }
  }

  addCat() {
    if (!this.Supplierselected) {
      this.toastrService.warning('Please Select Supplier')
      return
    }

    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'left hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-right hw-40';
    }
    var addCategory = this.modalService.open(AddCategoryComponent, {
      centered: false,
      windowClass: wClass
    })
    addCategory.result.then(
      (result) => {
        this.getCategoryList()
      }
    )
  }


}
