import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';

@Component({
  selector: 'app-add-subcat-at-supplieritems',
  templateUrl: './add-subcat-at-supplieritems.component.html',
  styleUrls: ['./add-subcat-at-supplieritems.component.css']
})
export class AddSubcatAtSupplieritemsComponent implements OnInit {

  @Input() addressId: any;
  @Input() supplierInfoObj: any;
  @Input() service: any;

  subCategoryForm: FormGroup;
  submitted: boolean = false;
  categorylist: any[] = [];
  subcategory: any[] = [];
  categorySelected: any;
  subCategorySelected: any;
  showSet: any;
  selectedOption: any;
  showDiscount: boolean = false;
  showOffer: boolean = false;
  options: string[] = ['Discount on price (%)', 'Addtional Items Offer'];
  minDate;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private inventoryService: InventoryService) {
      this.minDate = new Date();
     }

  ngOnInit(): void {
    this.intiliazeForm();

    this.getcategory();
  }


  intiliazeForm() {
    this.subCategoryForm = this.formBuilder.group({
      category: ['', [Validators.required]],
      subCategory: ['', [Validators.required]],
      supplier: [this.supplierInfoObj?.name],
      price: ['', [Validators.required]],
      deal: [''],
      dealFromDate: [''],
      dealToDate: [''],
      discount: [''],
      buyQuantity: [''],
      freeQuantity: ['']
    })
  }

  get subCatFormControl() {
    return this.subCategoryForm.controls;
  }

  closeModal() {
    this.activeModal.close(true);
  }

  selectedCategory(categorySelected) { }

  selectedsubCategory(subCategorySelected) { }

  checkBoxCheck(a, e) {
    a.value.toUpperCase()
    if (a.value == 'Discount on price (%)') {
      this.selectedOption = a.value
      this.showDiscount = true;
      this.showOffer = false;
      this.subCategoryForm.get('buyQuantity').setValue(null)
      this.subCategoryForm.get('freeQuantity').setValue(null)
      // this.subCategoryForm.controls['discount'].setValidators([Validators.required, Validators.minLength(1)])
      // this.subCategoryForm.controls['buyQuantity'].clearValidators()
      // this.subCategoryForm.controls['freeQuantity'].clearValidators()
    }
    if (a.value == 'Addtional Items Offer') {
      this.selectedOption = a.value
      this.showDiscount = false;
      this.showOffer = true;
      this.subCategoryForm.get('discount').setValue(null)
      // this.subCategoryForm.controls['freeQuantity'].setValidators([Validators.required, Validators.minLength(1)])
      // this.subCategoryForm.controls['buyQuantity'].setValidators([Validators.required, Validators.minLength(1)])
      // this.subCategoryForm.controls['discount'].clearValidators()
    }
  }

  getcategory() {
    var body = {
      "flag": "Y",
      "addressId": this.addressId
    }
    this.inventoryService.category(body).subscribe(res => {
      this.categorylist = res?.['data'];
    },
      error => {
        this.toaster.error(error?.[error]?.['message']);
      }
    )
  }

  catIdfromObj;
  getcategyById(catId) {
    this.catIdfromObj = catId;
    var body = {
      "catagoryId": this.catIdfromObj,
      "flag": "Y",
      "addressId": this.addressId
    }
    this.inventoryService.subcategory(body).subscribe(res => {
      this.subcategory = res?.['data'];
    },
      error => {
        this.toaster.error(error?.[error]?.['message']);
      }
    )
  }

  itemTypidFromObj;
  getsubcategyById(subCat) {
    this.itemTypidFromObj = subCat?.itemTypeId
  }

  saveItems() {
    var body = {
      "active": 'Y',
      "categryId": this.catIdfromObj,
      "itemTypeId": this.itemTypidFromObj,
      "supplierId": this.supplierInfoObj?.supplierId,
      "supplierName": this.supplierInfoObj?.name,
      "unitPrice": this.subCategoryForm?.value?.price,
      "deal": this.subCategoryForm?.value?.deal,
      "discount": this.subCategoryForm?.value?.discount,
      "buyQuantity": this.subCategoryForm?.value?.buyQuantity,
      "freeQuantity": this.subCategoryForm?.value?.freeQuantity,
      "addressId": this.addressId
    }
    this.service.savesuppliercategory(body).subscribe(res => {
      this.toaster.success("Item Added.");
      this.subCategoryForm.reset();
      this.closeModal();
      
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

}
