import { Component, Input, OnInit } from '@angular/core';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-requests',
  templateUrl: './new-requests.component.html',
  styleUrls: ['./new-requests.component.css']
})
export class NewRequestsComponent implements OnInit {
  @Input() prescriptionInfo:any;
  newsrc;MedicationPrescriptions=[];
  prescriptionImage:boolean=false;src;
  showImage:boolean=false;showPdf:boolean=false;
  nextFillRequestDate;precId;
  constructor(private patientController:PatientScheduleControlService,private sanitizer: DomSanitizer,private router:Router,private activaModal:NgbActiveModal) { }

  ngOnInit(): void {
    console.log('prescription info',this.prescriptionInfo)
    this.getPrescriptionData(this.prescriptionInfo)
  }
  navigateToPharmacy(){
    this.activaModal.close()
    this.patientController.saveDoctorData(this.precId);
    this.router.navigate(['/select-pharma'], {
      state: { example: this.precId }
    })
  }
  getPrescriptionData(prescription){
    this.MedicationPrescriptions=[]
    this.precId = prescription['medicalPrescriptionId']
    if (prescription.prescriptioncontainsImage == 'Y') {
      this.prescriptionImage = true
      if (prescription['prescriptionId'] != null) {
        this.patientController.postGlobal('prescription/getPrescriptionDetailsById?presciptionId=' + prescription['prescriptionId'], prescription['patientId']).subscribe(res => {
          if (res['data'] != null && (res['data']?.['documentName'].includes(".png") || res['data']?.['documentName'].includes(".jpeg"))) {
            this.src = "data:image/jpeg;base64," + res['data']['image']

            this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
            this.showImage = true

          } else if (res['data'] != null && (res['data']?.['image'].includes(".pdf"))) {
            this.newsrc = null
            this.showPdf = true

            this.src = "data:application/pdf;base64," + res['data']['image']
            this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
          }
        })
      }
    }
    else {
      this.prescriptionImage = false
      if (prescription['medicalPrescriptionId'] != null) {
        this.patientController.postGlobal('prescription/getprescriptionswithmedaction?medicalPresciptionId=' + prescription['medicalPrescriptionId'], prescription['patientId']).subscribe(res => {
          if (res) {
            this.nextFillRequestDate = moment(res['data']?.['medicalprescription']?.[0]?.['nextfilrequestdate']).format('YYYY-MM-DD');
          }
          this.MedicationPrescriptions = res['data']['medication']
          this.newsrc = null
          this.showPdf = false
          this.showImage = false
        })
      }

    }
  }
}
