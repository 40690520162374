import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../inventory/inventory.service';

@Component({
  selector: 'app-single-supplier-info',
  templateUrl: './single-supplier-info.component.html',
  styleUrls: ['./single-supplier-info.component.css']
})
export class SingleSupplierInfoComponent implements OnInit {

  @Input() addressId: any;
  @Input() singleSupplierInfo: any;

  constructor(
    private activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private inventoryService: InventoryService
  ) { }

  ngOnInit(): void {
    this.fetchSupplier();
  }

  closeModal() {
    this.activeModal.close(true);
  }

  supplierData;
  fetchSupplier() {
    this.inventoryService.SupplierInformation(this.singleSupplierInfo?.supplierId).subscribe(res => {
      this.supplierData = res?.['data'];
      console.log('signle obj:', this.supplierData);
    },
      error => {
        this.toaster.error(error?.['error']?.['message'])
      }
    )
  }

}
