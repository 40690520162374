import { GlobalStorageService } from 'src/app/global-storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApicallsService } from '../apicalls.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {
  private sortOrder: string = 'asc';
  private sortField: string = '';
  sort(array: any[], field: string): any[] {
    if (this.sortField === field) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortOrder = 'asc';
    }

    this.sortField = field;
    const comparison = this.sortOrder === 'asc' ? 1 : -1;

    return array.sort((a, b) => {
      if (typeof a[field] === 'string' && typeof b[field] === 'string') {
        return a[field].localeCompare(b[field]) * comparison;
      } else if (typeof a[field] === 'number' && typeof b[field] === 'number') {
        return (a[field] - b[field]) * comparison;
      } else {
        return 0;
      }
    });
  }

  baseUrl = environment.patientBaseUrl
  doctorUrl = environment.doctorBaseUrl
  diagnosticUrl = environment.diagnosticBaseUrl
  pharmaUrl = environment.pharmaUrl
  caretakerUrl = environment.caretakerUrl
  elasticSearchUrl = environment.elesticSearchUrl
  utilitiesUrl = environment.utilitiesUrl
  clinicUrl = environment.hospitalUrl
  lookup = environment.utilitiesUrl
  ivfUrl = environment.ivfUrl
  pdfUrl = environment.pdfUrl;
  adminUrl= environment.adminUrl


  constructor(private globalStorage: GlobalStorageService, private http: HttpClient, private apiService: ApicallsService) { 
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      this.baseUrl = environment.patientBaseUrl1;
      this.doctorUrl = environment.doctorBaseUrl1;
      this.diagnosticUrl = environment.diagnosticBaseUrl1
      this.pharmaUrl = environment.pharmaUrl1
      this.caretakerUrl = environment.caretakerUrl1
      this.elasticSearchUrl = environment.elesticSearchUrl1
      this.utilitiesUrl = environment.utilitiesUrl1
      this.clinicUrl = environment.hospitalUrl1
      this.lookup = environment.utilitiesUrl1
      this.ivfUrl = environment.ivfUrl1
      this.pdfUrl = environment.pdfUrl1;
      this.adminUrl= environment.adminUrl1
    }
  }

  register(body) {
    return this.apiService.post(this.clinicUrl + 'register', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
  }
  registerHospitalUser(body) {
    return this.apiService.post(this.clinicUrl + 'registerUser', body, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }
  updateStaff(body){
    return this.apiService.put(this.clinicUrl+'update',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }
  hospitalEnquire(body){
    return this.apiService.post(this.adminUrl + 'saveRegistrationEnquiry',body ,  { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), observe: 'response' })
  }

  getServicesList() {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=CARETAKER&type=SERVICE', {})
  }
  emailOtpVerify(otp, email) {
    // let params = new HttpParams().set('uiHostURL',window.location.origin+'/cureMetric' );
    return this.apiService.get(this.clinicUrl + 'emailVerification?emailotp=' + otp + "&emailid=" + email, {},)
  }
  phoneOtpVerify(phoneNumber, otp) {
    return this.http.get(this.clinicUrl + 'phoneVerification?cellNumber=' + phoneNumber + "&otp=" + otp, {},)
  }
  resendEmailOTP(emailid) {
    return this.apiService.get(this.clinicUrl + 'resendOTPForEmail?emailid=' + emailid, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }
  resendPhoneOtp(mobilenumber) {
    return this.apiService.get(this.clinicUrl + 'resendOTPForPhone?phoneNumber=' + mobilenumber, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }
  userExist(request: any) {
    return this.http.get(this.clinicUrl + 'isEmailRegistered?userEmail=' + request)
  }
  checkPhoneNumber(number) {
    return this.http.get(this.clinicUrl + 'isPhoneNumberRegistered?cellNumber=' + number, {})
  }
  checkTPAPhone(number){

  }
  login(body) {
    return this.apiService.post(this.clinicUrl + 'login', body, {})
  }
  clinicProfileCompletedOrNot(hospitalUserId) {
    return this.apiService.post(this.clinicUrl + 'profileCompleted/' + hospitalUserId, {}, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }
  logOut() {
    return this.apiService.post(this.clinicUrl + 'logoutAsHospital', {}, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }
  savePersonalDetails(body) {
    return this.apiService.post(this.clinicUrl + 'save/personalDetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }
  getPersonalDetails(hospitalId) {
    return this.apiService.post(this.clinicUrl + 'get/personalDetail?hospitaluserId=' + hospitalId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }
  getNationalty() {
    return this.apiService.get(this.lookup + "lookupstatic/getlookupstaticbylookuptypeandname?module=COUNTRY&type=COUNTRY", { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response' })
  }
  getPrimaryDetails(hospitalUserId) {
    return this.apiService.get(this.clinicUrl + 'primaryDetails?hospitaluserId=' + hospitalUserId, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), } })
  }
  updatePrimaryDetails(body) {
    return this.apiService.put(this.clinicUrl + 'update', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') }), })
  }

  getHospitalAddress(hospitalAddressId) {
    return this.apiService.post(this.clinicUrl + 'getAddressByhospitaladdresId?hospitalId=' + hospitalAddressId, {}, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }

  saveHospitalAddress(value) {
    return this.apiService.post(this.clinicUrl + 'save/address', value, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }
  sendEmailOTPForPswdChange(email) {
    return this.apiService.get(this.clinicUrl + 'resendOTPForEmail?emailid=' + email, {})
  }
  getRecords(id) {
    return this.apiService.post(this.clinicUrl + 'getHospitalCount?hospitalAddressId=' + id, {}, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }
  getDoctorDashbordCount(body){
    return this.apiService.post(this.clinicUrl + 'doctorCount', body, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }
  getCaretakerDashboardCount(hospitalUserId,hospitalAddressId){
    return this.apiService.post(this.clinicUrl + 'caretakerDashBoardCount?hospitalAddressId='+hospitalAddressId+'&hospitaluserId='+hospitalUserId, {}, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }
  getAppointments(body) {
    return this.apiService.post(this.clinicUrl + 'myAppointments', body, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }
  getDoctorAppointments(body){
    return this.apiService.post(this.clinicUrl + 'myAppointmentsForDoctor', body, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }
  getivfAppointments(body) {
    return this.apiService.post(this.ivfUrl + 'myAppointments', body, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } },true)
  }
  getAddressAll(postal) {
    return this.apiService.get('https://api.worldpostallocations.com/pincode?postalcode=' + postal + '&countrycode=IN', {})
  }
  getSurgeryAndAilments(service) {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=' + service + '&type=' + service, {})
  }
  languages() {
    return this.apiService.get(this.lookup + 'lookupstatic/getlookupstaticbylookuptypeandname?module=LANGUAGE&type=LANGUAGE', { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) });
  }
  checkHospitalUsername(name) {
    return this.apiService.get(this.clinicUrl + 'isUserNameExists?userName=' + name, {});
  }
  resneEmailOtpforH(email, userName) {
    return this.apiService.get(this.clinicUrl + 'resendOTPForEmail?emailId=' + email + '&userName=' + userName, {});
  }
  resendPhnOtpforH(phn, userName) {
    return this.apiService.get(this.clinicUrl + 'resendOTPForPhone?phoneNumber=' + phn + '&userName=' + userName, {});
  }
  emailVerifyforH(email, otp) {
    return this.apiService.get(this.clinicUrl + 'emailVerification?emailid=' + email + '&emailotp=' + otp, {});
  }
  passwordReset(data) {
    return this.apiService.post(this.clinicUrl + 'password/reset', data, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }

  verifyOldPswd(email, password) {
    return this.apiService.get(this.clinicUrl + 'oldPasswordVerification?emailId=' + email + '&password=' + password, {});
  }

  createPassword(data) {
    return this.apiService.post(this.clinicUrl + 'create/password', data, {})
  }
  getAllAssociatedUsers(request) {
    return this.apiService.post(this.clinicUrl + 'fetchAssociatedUsers',request, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }

  getActiveAssociatesFromDashboard(flag,hospatiluserId,type ){
    return this.apiService.get(this.clinicUrl + 'fetchAssociatedUsers?flag=' + flag + '&hospitAladdressId=' + hospatiluserId + '&type=' + type, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }

  getAllAssociatedUserss(hospatiluserId, pageNumber, recordsPerPage) {
    return this.apiService.get(this.clinicUrl + 'associated/' + hospatiluserId + '/' + pageNumber + '/' + recordsPerPage, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }

  getDoctorDetailsByMedNumber(medLicNum) {
    return this.apiService.get(this.doctorUrl + 'getDoctorByMedicalLicNumber?medicalLicNumber=' + medLicNum, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId') } })
  }
  postFcmToken(request, addressId) {
    //let params = new HttpParams().set('forUserId', userId);
    return this.apiService.post(this.clinicUrl + 'fcm/token', request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': addressId.toString(), 'Cache-Control': 'no-store, no-cache' }) })

  }
  getAllNotifications(endpoint) {
    return this.apiService.get(this.clinicUrl + endpoint,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  createRoom(body) {
    return this.apiService.post(this.clinicUrl + 'saveRoom', body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  userPreVitalsHistory(body){
    return this.apiService.post(this.clinicUrl + 'userVitals', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  cancellSurgery(id){
    return this.apiService.post(this.clinicUrl + 'cancelPatientSurgery?inpatientsurgeryId='+id, {},
    { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  createOperationTheater(body) {
    return this.apiService.post(this.clinicUrl + 'saveOperationtheatre', body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getOperationTheaters(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getoperationtheatreByhospitalAddresId?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getRoom(addressId) {
    return this.apiService.get(this.clinicUrl + 'getRoom?hospitalAddresId=' + addressId,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  assignOpdBed(body){
    return this.apiService.post(this.clinicUrl + 'addOpdPatientroom', body,
    { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getAllBranches(addressId) {
    return this.apiService.post(this.clinicUrl + 'getAssoctedHospital?hospitalParentId=' + addressId, {},
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getAssociatedDoctorsList(addressId) {
    return this.apiService.get(this.clinicUrl + 'getDoctorDetails?hospitalAddressId=' + addressId + '&moduleType=' + 'DOCTOR',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getAssociatedCaretakerList(addressId, moduleType) {
    return this.apiService.get(this.clinicUrl + 'getCaretakerDetails?hospitalAddressId=' + addressId + '&moduleType=' + moduleType,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getAssociatedCaretakerDetails(caretakerId, hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getCaretakerDetailsByCaretakerIdAndHospialId?caretakerId=' + caretakerId + '&hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveSurgery(body) {
    return this.apiService.post(this.clinicUrl + 'surgery/save', body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deleteSurgeryFromList(hsptaddressId, surgeryId) {
    return this.apiService.delete(this.clinicUrl + 'surgery/deleteSurgery?hospitalAddresId=' + hsptaddressId + '&surgeryId=' + surgeryId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  activateAssocaiteFeeData(hsptaddressId, feeId) {
    return this.apiService.post(this.clinicUrl + 'activeAssociatedUserFee?hospitalAddressId=' + hsptaddressId + '&hospitalFeeDetailsId=' + feeId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deleteAssocaiteFeeData(hsptaddressId, feeId) {
    return this.apiService.delete(this.clinicUrl + 'deactiveAssociatedUserFee?hospitalAddressId=' + hsptaddressId + '&hospitalFeeDetailsId=' + feeId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getIPDOrOPDSurgery(body){
    return this.apiService.post(this.clinicUrl+'surgery/surgeriesDetail',body,{})
  }
  getAllSurgeriesList(id) {
    return this.apiService.post(this.clinicUrl + 'surgery/getSurgeriesByhospitalAddresId?age=0&hospitalAddresId=' + id, {},
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getSurgeriesList(id){
    return this.apiService.post(this.clinicUrl+'surgery/surgeriesByhospitalAddresId?hospitalAddresId='+id,{},
    { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveUser(body) {
    return this.apiService.post(this.clinicUrl + 'savePatient', body,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getUsers(addressId,pageNo) {
    return this.apiService.get(this.clinicUrl + 'getPatientDetails?hospitalAddressId=' + addressId + '&maxResult=' + 10 + '&pageNumber=' + pageNo,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getDischargedUsers(addressId) {
    return this.apiService.get(this.clinicUrl + "getDischargeDetail?hospitalAddresId=" + addressId,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveScheduleHours(reqObj) {
    return this.apiService.post(this.clinicUrl + 'schedule/save', reqObj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  deleteScheduleHours(schedulehoursId) {
    return this.apiService.delete(this.clinicUrl + 'schedulehours/id/' + schedulehoursId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getDoctorBaseschedule(surgeryId, month) {  //CURRENT_WEEK, CURRENT_DAY, CURRENT_MONTH
    var obj = {
      "hospitalAddressId": parseInt(this.globalStorage.getItem('hospitalAddressId')),
      // "hospitalUserId":this.globalStorage.getItem('forUserId'),
      "surgeryId": surgeryId,
      // "fromDate": "",
      "maxResutls": 100,
      // "monthNumber": month,
      "pageNum": 1,
      // "scheduleCalenderEnum": "CURRENT_MONTH",
      // "toDate": "",
      // "weekOfYear": 0,
      // "year": 0
    }
    return this.apiService.post(this.clinicUrl + 'schedule/all/bycriteria', obj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  saveCaretakerPatient(body) {
    return this.apiService.post(this.clinicUrl + 'saveCaretakerPatientInformation', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  reAssignCaretaker(body){
    return this.apiService.post(this.clinicUrl + 'reAssignedCaretaker', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  getHospitalAssociationDetails(id, moduleType) {
    return this.apiService.get(this.clinicUrl + 'getDoctorDetailsWithFee?doctorId=' + id + '&hospitalAddressId=' + parseInt(this.globalStorage.getItem('hospitalAddressId')) + '&moduleType=' + moduleType, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  unAssignCaretaker(body) {
    return this.apiService.post(this.clinicUrl + 'unassignedCaretaker', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  updateHospitalFeeDetails(body) {
    return this.apiService.post(this.clinicUrl + 'saveHospitalFeedetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getPatientDetails(value) {
    return this.apiService.get(this.baseUrl + 'getuserDetailByfirstNameorcellNumber?firstName=' + value, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getHospitalDoctors(id, moduleType) {
    return this.apiService.get(this.clinicUrl + 'getDoctorDetails?hospitalAddressId=' + id + '&moduleType=' + moduleType, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getDoctorSlots(id) {
    const fromDate = new Date().toISOString().split('T')[0];
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 14);
    const toDateStr = toDate.toISOString().split('T')[0];
    var obj = {
      "associateEntityId": parseInt(this.globalStorage.getItem('hospitalAddressId')),
      // "hospitalUserId":this.globalStorage.getItem('forUserId'),
      "doctorId": id,
      "maxResutls": 100,
      // "monthNumber": month,
      "pageNum": 1,
      "fromDate": fromDate,
      "toDate": toDateStr,
    }
    return this.apiService.post(this.doctorUrl + 'schedule/all/bycriteria', obj, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  searchPatientDetails(body) {
    return this.apiService.post(this.elasticSearchUrl + 'searchHospitalPatient', body, {})
  }
  findUserByName(name,type) {
    return this.apiService.get(this.baseUrl + 'findUserDetailByFirstNameAndcellNumber/' + name +"/" + type, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  findUserById(userId){
    return this.apiService.get(this.baseUrl + "findUserById/" + userId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  findUserByHospitalAddressIdAndUserId(hospitalAddressId,userId){
    return this.apiService.get(this.clinicUrl + "findPatientDetail?hospitalAddressId="+hospitalAddressId+'&patientId='+ userId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  finduserByName(name){
    return this.apiService.get(this.baseUrl + 'getuserDetailByfirstNameorcellNumber?firstName=' + name,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  assinInPatient(body) {
    return this.apiService.post(this.clinicUrl + 'assignPatient', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInPatientDetails() {
    return this.apiService.get(this.clinicUrl + 'getInpatient?hospitalAddresId=' + this.globalStorage.getItem('hospitalAddressId') + '&maxResult=' + 100 + '&pageNumber=' + 1, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getDoctorInpatientsList(body){
    return this.apiService.post(this.clinicUrl + 'inpatientDetailForDoctor',body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getCaretakerInpatientsList(hospitalAddressId,hospitalUserId){
    return this.apiService.post(this.clinicUrl+'inPatientForCaretaker?hospitalAddressId='+hospitalAddressId+'&hospitaluserId='+hospitalUserId,{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getCaretakerInpatients(hospitalAddressId,hospitalUserId){
    return this.apiService.post(this.clinicUrl+'inpatientDetailForCaretaker?hospitalAddressId='+hospitalAddressId+'&hospitaluserId='+hospitalUserId,{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getCaretakerOpdList(hospitalAddressId,hospitalUserId){
    return this.apiService.post(this.clinicUrl+'outPatientForCaretaker?hospitalAddressId='+hospitalAddressId+'&hospitaluserId='+hospitalUserId,{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getPatientsForCaretaker(hospitalAddressId,hospitalUserId){
    return this.apiService.post(this.clinicUrl+'patientDetailsForCaretaker?hospitalAddressId='+hospitalAddressId+'&hospitalUserId='+hospitalUserId+'&maxResult=100&pageNumber=1',{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getPatientForDoctor(hospitalAddressId,hospitalUserId,pageNo){
    return this.apiService.post(this.clinicUrl+'patientDetailsForDoctor?hospitalAddressId='+hospitalAddressId+'&hospitalUserId='+hospitalUserId+'&maxResult=100&pageNumber='+pageNo,{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInPatientSurgeryList() {
    return this.apiService.post(this.clinicUrl + 'getInpatientservice?hospitalAddresId=' + this.globalStorage.getItem('hospitalAddressId'), {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInPatientSurgeryDetails(addressId, inPatientId) {
    return this.apiService.get(this.clinicUrl + 'getassigenSurgeryDetailByInpatientId?hospitalAddresId=' + addressId + '&inpatientId=' + inPatientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveInPatientservice(body) {
    return this.apiService.post(this.clinicUrl + 'saveInpatientservice', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getHospitalSurgeries(age,hospitalAddressId) {
    return this.apiService.post(this.clinicUrl + 'surgery/getSurgeriesByhospitalAddresId?age='+age+'&hospitalAddresId=' + hospitalAddressId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getSurgeryInformation(hospitalAddressId, surgery) {
    return this.apiService.get(this.clinicUrl + 'surgery/findBysurgeryName?hospitalAddresId=' + hospitalAddressId + '&surgeryName=' + surgery, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveSurgeryService(body) {
    return this.apiService.post(this.clinicUrl + 'saveInpatientsurgery', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  assignInPatientDoctor(body) {
    return this.apiService.post(this.clinicUrl + 'assigenDoctor', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  assignInpatientCaretaker(body) {
    return this.apiService.post(this.clinicUrl + 'saveInpatientcaretakerinformation', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getAssignedList(addressId, inPatientId) {
    return this.apiService.post(this.clinicUrl + 'getassigenDoctorByInpatientIdAndhospitalAddresId?hospitalAddresId=' + addressId + '&inpatientId=' + inPatientId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  searchService(body){
    return this.apiService.post(this.clinicUrl+'searchService',body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')),'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  updateNotifications(endpoint, request) {
    return this.apiService.post(this.clinicUrl + 'notificationalerts/updateNotificationalerts/' + endpoint, request, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "user-session": JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }
  allotRoomToPatient(body) {
    return this.apiService.post(this.clinicUrl + 'saveInPatientroom', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getRoomDetails(hospitalAddressId, inPatientId) {
    return this.apiService.post(this.clinicUrl + 'getInPatientroomWithInpatientId?hospitalAddresId=' + hospitalAddressId + '&inpatientId=' + inPatientId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveAmbulance(body) {
    return this.apiService.post(this.clinicUrl + 'saveAmbulancedetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getAmbulanceService(hospitalAddressId, inpatientId) {
    return this.apiService.post(this.clinicUrl + 'getInpatientAmbulance?hospitalAddresId=' + hospitalAddressId + '&inpateintId=' + inpatientId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInpatientPrescription(hospitalAddressId, inPatientId,pageNumber) {
    return this.apiService.get(this.clinicUrl + 'getInPatientPrescription?hospitalAddresId=' + hospitalAddressId + '&inPatient=' + inPatientId+'&maxResult=6'+'&pageNum='+pageNumber, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInpatientTests(body) {
    return this.apiService.post(this.clinicUrl + 'medicaltest/view/all', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInpatientInfoWithCaretaker(hospitalAddressId, inPatientId) {
    return this.apiService.get(this.clinicUrl + 'getCaretakerPatientInformation?hospitalAddresId=' + hospitalAddressId + '&inpateintId=' + inPatientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getEquipmentList(module, type) {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=' + module + '&type=' + type, {})
  }

  getSurgeryChecklists(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'checkListDetails/' + hospitalAddressId , { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveProfilePhoto(id, data) {
    return this.apiService.post(this.clinicUrl + 'uploadHospitalProfile/' + id, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveClinicLogo(hospitalAddressId, data) {
    return this.apiService.post(this.clinicUrl + 'profilePick/' + hospitalAddressId, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveCaseDetails(body) {
    return this.apiService.post(this.clinicUrl + 'saveCasedetails', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveTpa(body) {
    return this.apiService.post(this.clinicUrl + 'saveTpamanagement', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getTpaList(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getTpamanagementByHospitalAddresId?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getCaseDetailsList(hospitalAddressId, inPatientId) {
    return this.apiService.get(this.clinicUrl + 'getCasedetailsByPatientId?hospitalAddresId=' + hospitalAddressId + '&inpatientId=' + inPatientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  activateTPA(mngid) {
    return this.apiService.post(this.clinicUrl + 'activeTpamanagement?tpamanagementId=' + mngid, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deleteTpa(tpaManagementId) {
    return this.apiService.delete(this.clinicUrl + 'deleteTpamanagement?tpamanagementId=' + tpaManagementId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveInPatientTpa(body) {
    return this.apiService.post(this.clinicUrl + 'saveInpatienttpamanagement', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInPatientTpa(hospitalAddressId, inPatientId) {
    return this.apiService.get(this.clinicUrl + 'getInpatienttpamanagement?hospitaAddresId=' + hospitalAddressId + '&inpatientId=' + inPatientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveVisitNote(body) {
    return this.apiService.post(this.clinicUrl + 'saveVisitdetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getVisitNote(hospitalAddressId, inPatientId) {
    return this.apiService.get(this.clinicUrl + 'getVisitdetail?hospitalAddressId=' + hospitalAddressId + '&inpatientId=' + inPatientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  savePrimaryDoctor(body) {
    return this.apiService.post(this.clinicUrl + 'savePrimaryDoctor', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getPrimaryDoctor(hospitalAddressId, inPatientId) {
    return this.apiService.get(this.clinicUrl + 'getPrimarydoctor?hospitaAddresId=' + hospitalAddressId + '&inpatientId=' + inPatientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getMedicationBydateHospital(body) {
    return this.apiService.post(this.clinicUrl + 'getMedicationsForTheDay', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveHospitalAmbulance(body) {
    return this.apiService.post(this.clinicUrl + 'saveAmbulancedetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getHospitalsAmbulances(hospitalAddressId) {
    return this.apiService.post(this.clinicUrl + 'getAmbulancedetail?flag=ALL&hospitalAddresId=' + hospitalAddressId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  activateHospitalAmbulance(id) {
    return this.apiService.post(this.clinicUrl + 'activeAmbulancedetailByAmbulancedetailId?ambulancedetailId=' + id, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deleteAmbulanceFromList(id) {
    return this.apiService.delete(this.clinicUrl + 'deleteAmbulancedetailBAmbulancedetailId?ambulancedetailId=' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  assignAmbulance(body) {
    return this.apiService.post(this.clinicUrl + 'saveInpatientAmbulance', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getAssignedAmbulanceDetails(hospitalAddressId, inPatientId) {
    return this.apiService.get(this.clinicUrl + 'getInpatientAmbulance?hospitalAddresId=' + hospitalAddressId + '&inpatientId=' + inPatientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  savePayment(body) {
    return this.apiService.post(this.clinicUrl + 'savePayment', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getPayments(hospitalAddressId, inPatientId) {
    return this.apiService.get(this.clinicUrl + 'getPaymentByInpatientId?hospitalAddresId=' + hospitalAddressId + '&inPatientid=' + inPatientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getHospitalDrivers(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getAssociatedDriver/' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getDashboard(hospitalAddressId, inPatientId) {
    return this.apiService.get(this.clinicUrl + 'getInpatientDashBoard?hospitalAddresId=' + hospitalAddressId + '&inpateintId=' + inPatientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getOpdInpatientDashboard(hospitalAddressId, inPatientId) {
    return this.apiService.get(this.clinicUrl + 'opdPatientDashBoard?hospitalAddresId=' + hospitalAddressId + '&pateintId=' + inPatientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getVisitedPrescription(body) {
    return this.apiService.post(this.clinicUrl + 'getAllVistNotePrescriptionDetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getVisitedTest(body) {
    return this.apiService.post(this.clinicUrl + 'getAllVistNotePrescriptionDetailForTest', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getDoctorDetsils(doctorId) {
    return this.apiService.get(this.clinicUrl + 'getDoctorDetailsWithFee?doctorId=' + doctorId + '&moduleType=' + 'DOCTOR', { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getPaymentsOfHospital(hospitalId) {
    return this.apiService.get(this.clinicUrl + 'invoice/findInvoiceByhospitalAddresId?hospitalAddresId=' + hospitalId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInpatientAmbulanceList(hospitalAddresId) {
    return this.apiService.post(this.clinicUrl + 'getAmbulancedetail?flag=ALL&hospitalAddresId=' + hospitalAddresId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getDischargeInvoice(hospitalAddressId, inPatientId) {
    return this.apiService.get(this.clinicUrl + 'invoice/findInvoiceByinpatientId?hospitalAddresId=' + hospitalAddressId + '&inpatientId=' + inPatientId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getDoctorsDetails(doctorId, hospitalId) {
    return this.apiService.get(this.clinicUrl + 'getDoctorDetailsByDoctorIdAndHospialId?doctorid=' + doctorId + '&hospitalAddresId=' + hospitalId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getMedicineUsingHospitalAddressId(hospitalAddressId,medicine,type) {
    return this.apiService.get(this.clinicUrl + 'find/allmedicine/byname?hospitalAddresId=' + hospitalAddressId + '&medicineName=' + medicine + '&type=' + type, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getInpatintPaymentDetails(hospitalAddressId, inPatientId,reason) {
    return this.apiService.get(this.clinicUrl + 'getInpatientPayment?hospitalAddresId=' + hospitalAddressId + '&inpateintId=' + inPatientId+'&reason='+reason, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInpatientPaymentDTO(body){
    return this.apiService.post(this.clinicUrl+'getInpatientPayment',body,{})
  }
  getReasonsForDischared(){
    return this.apiService.get(this.utilitiesUrl+'lookupstatic/getlookupstaticbylookuptypeandname?module=ReasonOfDischarge&type=ReasonOfDischarge',{})
  }
  updateInpatientBill(body) {
    return this.apiService.post(this.clinicUrl + 'updateInpatientbill', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getDischargedList(body) {
    return this.apiService.post(this.clinicUrl + 'getDischargeDetail', body , { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInvoiceDts(hospitalAddressId, invoiceId) {
    return this.apiService.get(this.clinicUrl + 'invoice/findInvoiceById?hospitalAddresId=' + hospitalAddressId + '&invoiceId=' + invoiceId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  downloadHospitalInvoicePDF(body) : Observable<any>{
    return this.apiService.post(this.pdfUrl + 'hospital', body,{responseType: 'blob' as 'json'});
  }

  cancelHospitalAppointment(appointment) {
    var cancel = {
      "appointmentId": appointment.appointmentId,
      "appointmentStatus": "ENTITY_CANCELLED",
      "hospitalAddressId": parseInt(this.globalStorage.getItem('hospitalAddressId')),
      "reason": 'test',
      "scheduleHoursId": appointment.schedulehoursId
    }
    return this.apiService.post(this.clinicUrl + 'request/update/appointmentstatus', cancel, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  rescheduleHospitalAppointment(appointment) {
    var cancel = {
      "appointmentId": appointment.appointmentId,
      "appointmentStatus": "ENTITY_RESCHEDULED",
      "hospitalAddressId": parseInt(this.globalStorage.getItem('hospitalAddressId')),
      "reason": 'test',
      "scheduleHoursId": appointment.schedulehoursId
    }
    return this.apiService.post(this.clinicUrl + 'request/update/appointmentstatus', cancel, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  rescheduleIvfAppointment(body) {
    return this.apiService.post(this.ivfUrl + 'request/update/appointmentstatus', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  decryptTokenForHospitalPayment(token) {
    return this.apiService.get(this.clinicUrl + 'getBillDeskToken?token=' + token, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getBedInfo(hospitalAddressId, bedno) {
    return this.apiService.get(this.clinicUrl + 'getInPatientroomWithRoomBeddId?hospitalAddresId=' + hospitalAddressId + '&roomBedId=' + bedno, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  updateTheHospitalBranch(body) {
    return this.apiService.put(this.clinicUrl + 'updateBranch', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  editBranch(body) {
    return this.apiService.put(this.clinicUrl + 'update', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  deleteBranch(id) {
    return this.apiService.delete(this.clinicUrl + 'deleteAssoctedHospital?hospitalId=' + id, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  activeBranch(id) {
    return this.apiService.post(this.clinicUrl + 'activeAssoctedHospital?hospitalId=' + id, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  activateOperationTheater(id, theaterId) {
    return this.apiService.post(this.clinicUrl + 'activeoperationtheatre?hospitalAddresId=' + id + '&operationTheatreId=' + theaterId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deleteOperationTheater(hospitalAddressId, theaterId) {
    return this.apiService.delete(this.clinicUrl + 'deleteoperationtheatre?hospitalAddresId=' + hospitalAddressId + '&operationTheatreId=' + theaterId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  deactivateAssociation(id, hospitalAddressId) {
    return this.apiService.delete(this.clinicUrl + 'deactiveAssociatedUsers?hospitalUserId=' + id + '&hospitaladdressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  unassignedCaretaker(patientId, caretakerId, hospitalAddressId) {
    const body={
      patientId:patientId,
      caretakerId:caretakerId,
      hospitalAddresId:hospitalAddressId
    }

    return this.apiService.post(this.clinicUrl + 'unassignedCaretaker',body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })

  }


  activateAssociation(id, hospitalAddressId) {
    return this.apiService.post(this.clinicUrl + 'activeAssociatedUsers?hospitalUserId=' + id + '&hospitaladdressId=' + hospitalAddressId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  searchByTest(testName, hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'searchByMedicaltest/' + testName + '/{hospitalAddresId}?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getAmbulanceHistory(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getInpatientAmbulanceByhospitalAddresId?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveBirthRecord(body) {
    return this.apiService.post(this.clinicUrl + 'saveBirthrecord', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getPatientsBirthDetails(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getBirthrecord?hospitaladdressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveDeathRecord(body) {
    return this.apiService.post(this.clinicUrl + 'saveDeathrecord', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getPatientsDeathDetails(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getDeathrecord?hospitaladdressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  // deactivatePatientBirthInfo(birthId, hospitalAddressId) {
  //   return this.apiService.delete(this.clinicUrl + 'deleteBirthrecord?birthRecordId=' + birthId + '&hospitaladdressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  // }

  // deactivatePatientDeathInfo(deathId, hospitalAddressId) {
  //   return this.apiService.delete(this.clinicUrl + 'deleteDeathrecord?deathrecordId=' + deathId + '&hospitaladdressId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  // }

  // Search API's

  searchAssociates(body) {
    return this.apiService.post(this.clinicUrl + 'associatedUsersSaarch', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  searchSurgery(body) {
    return this.apiService.post(this.clinicUrl + 'surgery/searchSurgery', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  searchTPAManagement(body) {
    return this.apiService.post(this.clinicUrl + 'searchTpamanagement', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  searchOperationTheater(body) {
    return this.apiService.post(this.clinicUrl + 'searchOperationtheatre', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  searchAmbulance(body) {
    return this.apiService.post(this.clinicUrl + 'searchAmbulancedetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  searchAmbulanceCallListData(body) {
    return this.apiService.post(this.clinicUrl + 'searchInpatientAmbulance', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  searchInPatient(body) {
    return this.apiService.post(this.clinicUrl + 'searchInpatient', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  searchOutPatient(body) {
    return this.apiService.post(this.clinicUrl + 'searchPatientDetails', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  searchDischargePatient(body){
    return this.apiService.post(this.clinicUrl + 'searchDischargePatient', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getOpdList(pageNumber,hospitalAddressId){
    return this.apiService.get(this.clinicUrl + 'opdPatient?hospitalAddresId=' + hospitalAddressId +' &maxResult=10&pageNumber='+pageNumber, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  searchClinicAppointments(body) {
    return this.apiService.post(this.clinicUrl + 'searchMyAppointments', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  searchBirthRecord(body) {
    return this.apiService.post(this.clinicUrl + 'sarchBirthrecord', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  searchDeathRecord(body) {
    return this.apiService.post(this.clinicUrl + 'searchDeathrecord', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  searchInvoiceForIpdAndOpd(body) {
    return this.apiService.post(this.clinicUrl + 'invoice/searchInvoiceForIpdAndOpd', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getHospitalPayments(body) {
    return this.apiService.post(this.clinicUrl + 'invoice/findInvoiceByhospitalAddresIdAndStatus', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  // Clinic blood bank API's
  getBloodBank(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getBloodbank?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getBloodBankDashboard(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'bloodbankDashBoard?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getBloodInformation(bloodgroup, hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'bloodInformation?bloodGroup=' + bloodgroup + '&hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveBloodComponent(body) {
    return this.apiService.post(this.clinicUrl + 'saveBloodcomponent', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getBloodComponent(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getBloodcomponent?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveDonorData(body) {
    return this.apiService.post(this.clinicUrl + 'saveDonor', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }


  getDonorsData(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getDonor?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getSingleDonorData(donorId, hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getDonorWithBloodissue?donorId=' + donorId + '&hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getNewDonors(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'newDonor?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getBloodIssue(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getBloodissueByHospitalAddresId?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getBloodTests(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getBloodtestdetail?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveBloodTest(body) {
    return this.apiService.post(this.clinicUrl + 'saveBloodtest', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveBloodtestdetail(body) {
    return this.apiService.post(this.clinicUrl + 'saveBloodtestdetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveBloodDonation(body) {
    return this.apiService.post(this.clinicUrl + 'saveBlooddonation', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getSelectedTests(donationId, hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getBloodtestWithbloodDonation?bloodDonationId=' + donationId + '&hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveBloodbank(body) {
    return this.apiService.post(this.clinicUrl + 'saveBloodbank', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveBloodissue(body) {
    return this.apiService.post(this.clinicUrl + 'saveBloodissue', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveComponentissue(body) {
    return this.apiService.post(this.clinicUrl + 'saveComponentissue', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }


  getBloodComponentDashboard(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getBloodcomponentDashBoard?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getComponentissue(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getComponentissueByHospitalAddresId?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getBloodbankExpiredDetails(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getBloodbankExpiredDetail?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getBloodcomponentInformation(body) {
    return this.apiService.post(this.clinicUrl + 'getBloodcomponentInformation', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getListofComponentTypes(component, type) {
    return this.apiService.get(this.utilitiesUrl + 'lookupstatic/getlookupstaticbylookuptypeandname?module=' + component + '&type=' + type, {})
  }
  searchBloodComponentInformation(body) {
    return this.apiService.post(this.clinicUrl + 'getBloodcomponentSearchInformation', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getExpiringBloodBagsInformation(hospitalAddressId, expiring) {
    return this.apiService.get(this.clinicUrl + 'bloodInformationForexpiredAndexpiredSoon?hospitalAddresId=' + hospitalAddressId + '&value=' + expiring, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getExpiredBloodBagInformation(hospitalAddressId, expired) {
    return this.apiService.get(this.clinicUrl + 'bloodInformationForexpiredAndexpiredSoon?hospitalAddresId=' + hospitalAddressId + '&value=' + expired, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deleteExpiredBlood(bloodbankId, hospitalAddressId) {
    return this.apiService.delete(this.clinicUrl + 'deleteExpiredBlood?bloodbankId=' + bloodbankId + '&hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getBlooddonationinformation(bloodDonationId) {
    return this.apiService.get(this.clinicUrl + 'getBlooddonationinformation?bloodDonationId=' + bloodDonationId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  // check uniques
  isEmailExistForDonor(emailId, hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'isEmailExitsOrNot?email=' + emailId + '&hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  isCellNumberExistForDonor(cellNumber, hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'isCellNumberExitsOrNot?cellNumber=' + cellNumber + '&hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  isUsernameExistForDonor(hospitalAddressId, username) {
    return this.apiService.get(this.clinicUrl + 'isUserNameExitsOrNot?hospitalAddresId=' + hospitalAddressId + '&username=' + username, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  Inventory(body) {
    return this.apiService.get(this.clinicUrl + 'inventoryList/'+ body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  suplier(hospitalAddressId) {
    return this.apiService.post(this.clinicUrl + 'supplierList' , hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  inventoryorders(body) {
    return this.apiService.post(this.clinicUrl + 'supplierordersList', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }



  category(body) {
    return this.apiService.post(this.clinicUrl + 'categoryList' , body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }


  subcategory(body) {
    return this.apiService.post(this.clinicUrl + 'itemtypeList' , body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  SupplierInformation(supplierId) {
    return this.apiService.get(this.clinicUrl + 'getSupplierInformation?supplierId=' + supplierId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  supplierOrderDeliver(hospitalAddressId, supplierordersId) {
    return this.apiService.get(this.clinicUrl + 'supplierOrderDeliver?hospitalAddresId=' + hospitalAddressId + '&supplierordersId=' + supplierordersId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveBloodForTest(body) {
    return this.apiService.post(this.clinicUrl + 'saveBloodtest', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveSupplierdetails(body) {
    return this.apiService.post(this.clinicUrl + 'saveSupplier', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveinventory(body) {
    return this.apiService.post(this.clinicUrl + 'saveInventory', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveCategory(body) {
    return this.apiService.post(this.clinicUrl + 'saveCatagory', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveSubCategory(body) {
    return this.apiService.post(this.clinicUrl + 'saveItemtype', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  savesuppliercategory(body) {
    return this.apiService.post(this.clinicUrl + 'saveSuppliercatgery', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })

  }
  // https://dev.cure-metric.com/api/hospital/saveSuppliercatgery
  savesupplierOrders(body) {
    return this.apiService.post(this.clinicUrl + 'saveSupplierorders', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })

  }

  SuppliercatgeryByItemType(hospitalAddressId, itemTypeId) {
    return this.apiService.get(this.clinicUrl + 'getSuppliercatgeryByItemType?hospitalAddresId=' + hospitalAddressId + '&itemTypeId=' + itemTypeId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deactivateSupplier(supplierId) {
    return this.apiService.delete(this.clinicUrl + 'deleteSupplier?supplierId=' + supplierId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInvoice(hospitalAddressId, appointmentId) {
    return this.apiService.get(this.clinicUrl + 'invoice/findInvoiceByAppointmentId?hospitalAddresId=' + hospitalAddressId + '&referenceappointmentId=' + appointmentId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getSurgeryEquipments(hospitalAddressId, surgeryId) {
    return this.apiService.get(this.clinicUrl + 'surgery/getSurgeriesByhospitalAddresId?hospitalAddresId=' + hospitalAddressId + '&surgeryId=' + surgeryId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getSurgeryBySurgeryName(hospitalAddressId, surgeryName){
    return this.apiService.get(this.clinicUrl + 'surgery/findBysurgeryName?hospitalAddresId=' + hospitalAddressId + '&surgeryName=' + surgeryName, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getSurgeryById(addressId,surgeryId){
    return this.apiService.get(this.clinicUrl+'surgery/findBysurgeryId?hospitalAddresId='+addressId+'&surgeryId='+surgeryId,{})
  }
  getInventoryByInventoryId(hospitalAddressId, inventoryId) {
    return this.apiService.get(this.clinicUrl + 'getInventoryByInventoryId?hospitalAddresId=' + hospitalAddressId + '&inventoryId=' + inventoryId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  updateinventory(body) {
    return this.apiService.put(this.clinicUrl + 'updateInventory', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getSupplierordersBySupplierordersId(hospitalAddressId, supplierordersId) {
    return this.apiService.get(this.clinicUrl + 'getSupplierordersBySupplierordersIdAndhospitalAddresId?hospitalAddresId=' + hospitalAddressId + '&supplierordersId=' + supplierordersId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  updateSupplierorders(body) {
    return this.apiService.post(this.clinicUrl + 'editSupplierorders', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  cancelSupplierorders(body) {
    return this.apiService.post(this.clinicUrl + 'cancleSupplierorders', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  // https://dev.cure-metric.com/api/hospital/cancleSupplierorders
  editSubCategory(hospitalAddressId, itemTypeId) {
    return this.apiService.get(this.clinicUrl + 'getItemtypeByhospitalAddresIdanditemTypeId?hospitalAddresId=' + hospitalAddressId + '&itemTypeId=' + itemTypeId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  updateSubcategory(body) {
    return this.apiService.post(this.clinicUrl + 'updateItemtype', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  editCatagory(catagoryId, hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getCatagoryByhospitalAddresIdAndcatagoryId?catagoryId=' + catagoryId + '&hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }



  getAssignSupplierDetails(hospitalAddressId) {
    return this.apiService.get(this.clinicUrl + 'getSuppliercatgery?hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  editAssignSupplier(body) {
    return this.apiService.put(this.clinicUrl + 'editSuppliercatgeryrelation', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  activateCatagory(catagoryId, hospitalAddressId) {
    return this.apiService.post(this.clinicUrl + 'activateCatagory?catagoryId=' + hospitalAddressId + '&hospitalAddresId=' + catagoryId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deleteCatagory(catagoryId, hospitalAddressId) {
    return this.apiService.delete(this.clinicUrl + 'deleteCatagory?catagoryId=' + catagoryId + '&hospitalAddresId=' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  activateSubCatagory(hospitalAddressId, itemTypeId) {
    return this.apiService.post(this.clinicUrl + 'activateItemtype?addressId=' + hospitalAddressId + '&itemTypeId=' + itemTypeId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deleteSubCatagory(hospitalAddressId, itemTypeId) {
    return this.apiService.delete(this.clinicUrl + 'deleteItemtype?addressId=' + hospitalAddressId + '&itemTypeId=' + itemTypeId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  ActiveAssignSupplier(hospitalAddressId, suppliercatgeryId) {
    return this.apiService.post(this.clinicUrl + 'activateSuppliercatgery?hospitalAddresId=' + hospitalAddressId + '&suppliercatgeryId=' + suppliercatgeryId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  deleteAssignSupplier(hospitalAddressId, suppliercatgeryId) {
    return this.apiService.delete(this.clinicUrl + 'deleteSuppliercatgery?hospitalAddresId=' + hospitalAddressId + '&suppliercatgeryId=' + suppliercatgeryId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  ActiveSupplierCategory(hospitalAddressId, supplierId) {
    return this.apiService.post(this.clinicUrl + 'activateSupplier?hospitalAddresId=' + hospitalAddressId + '&supplierId=' + supplierId, {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getSuppliercatgeryByItemTypeId(hospitalAddressId, itemTypeId) {
    return this.apiService.get(this.clinicUrl + 'getSuppliercatgeryByItemTypeId?hospitalAddresId=' + hospitalAddressId + '&itemTypeId=' + itemTypeId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveInventory(body) {
    return this.apiService.post(this.clinicUrl + 'saveInventory', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  getSupplierInformation(supplierId) {
    return this.apiService.get(this.clinicUrl + 'getSupplierInformation?supplierId=' + supplierId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  saveDepartment(body) {
    return this.apiService.post(this.clinicUrl + 'saveDepartment', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getSingleDepartment(body) {
    return this.apiService.post(this.clinicUrl + 'departmentByDepartmentId', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  activateDepartment(body) {
    return this.apiService.post(this.clinicUrl + 'activateDepartment', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  deactivateDepartment(body) {
    return this.apiService.post(this.clinicUrl + 'deactivateDepartment', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getDepartmentList(hospitalAddressId){
    return this.apiService.get(this.clinicUrl + 'departmentList/' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  saveService(body) {
    return this.apiService.post(this.clinicUrl + 'saveService', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getSingleService(body) {
    return this.apiService.post(this.clinicUrl + 'serviceByServiceId', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  activateService(body) {
    return this.apiService.post(this.clinicUrl + 'activateService', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  deactivateService(body) {
    return this.apiService.post(this.clinicUrl + 'deactivateService', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getserviceList(hospitalAddressId){
    return this.apiService.get(this.clinicUrl + 'serviceList/' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  getActiveServicesList(hospitalAddressId){
    return this.apiService.get(this.clinicUrl + 'serviceDetails/' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  saveServiceType(body) {
    return this.apiService.post(this.clinicUrl + 'saveServiceType', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getSingleServiceType(body) {
    return this.apiService.post(this.clinicUrl + 'serviceTypeByServiceTypeId', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  activateServiceType(body) {
    return this.apiService.post(this.clinicUrl + 'activateServiceType', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  deactivateServiceType(body) {
    return this.apiService.post(this.clinicUrl + 'deactivateServiceType', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getserviceTypesList(hospitalAddressId){
    return this.apiService.get(this.clinicUrl + 'serviceTypeList/' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getserviceTypeInformation(hospitalAddressId){
    return this.apiService.get(this.clinicUrl + 'serviceTypeInformation/' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getdepartmentInformation(hospitalAddressId){
    return this.apiService.get(this.clinicUrl + 'departmentInformation/' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  saveRoomType(body) {
    return this.apiService.post(this.clinicUrl + 'saveRoomType', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  surgicalEquipmentItemList(body) {
    return this.apiService.post(this.clinicUrl + 'surgicalEquipmentItemList', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getSingleRoomType(body) {
    return this.apiService.post(this.clinicUrl + 'roomTypeByRoomTypeId', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  activateroomType(body) {
    return this.apiService.post(this.clinicUrl + 'activateRoomType', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  deactivateroomType(body) {
    return this.apiService.post(this.clinicUrl + 'deactivateRoomType', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getroomTypeList(hospitalAddressId){
    return this.apiService.get(this.clinicUrl + 'roomTypeList/' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getroomTypeInformation(hospitalAddressId){
    return this.apiService.get(this.clinicUrl + 'roomTypeInformation/' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  saveSurgeryChecklist(body) {
    return this.apiService.post(this.clinicUrl + 'saveCheckList', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getSingleChecklist(body) {
    return this.apiService.post(this.clinicUrl + 'checkListByCheckListId', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  activatesurgeryCheckList(body) {
    return this.apiService.post(this.clinicUrl + 'activateCheckList', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  deactivatesurgeryCheckList(body) {
    return this.apiService.post(this.clinicUrl + 'deactivateCheckList', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  hospitalappointmentNotesList(body) {
    return this.apiService.post(this.clinicUrl + 'appointmentNotesList', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  unAssignDoctorForIPD(body) {
    return this.apiService.post(this.clinicUrl + 'unssigenDoctor', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  unAssignCaretakerForIPD(body) {
    return this.apiService.post(this.clinicUrl + 'unassignedInpatientcaretaker', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getsurgeryCheckList(hospitalAddressId){
    return this.apiService.get(this.clinicUrl + 'checkListInformation/' + hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getOpdPrescription(body){
    return this.apiService.post(this.clinicUrl + 'fetchPatientPrescriptionForAppAppointment', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getClinicUserDetails(body){
    return this.apiService.post(this.baseUrl + 'personaldetails/get/', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  getDiscountList(hospitalAddressId){
    return this.apiService.get(this.clinicUrl+'discountApprovalEligibilityList/?hospitalAddressId='+hospitalAddressId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  saveDiscount(body){
    return this.apiService.post(this.clinicUrl + 'addDiscountApprovalEligibility', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  updateDiscount(body){
    return this.apiService.post(this.clinicUrl + 'updateAmountDetail', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  sendDiscountApproval(body){
    return this.apiService.post(this.clinicUrl+'discountrequest/sendApproval',body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  sendDiscountApprovalByDepartment(amount,departmentId,hospitalAddressId){
    return this.apiService.get(this.clinicUrl+'amountDetailByDepartmentId?amount='+amount+'&departmentId='+departmentId+'&hospitalAddressId='+hospitalAddressId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  getRequests(hospitalAddressId,hospitalUserId){
    return this.apiService.get(this.clinicUrl+'discountrequest/findApproval?hospitalAddressId='+hospitalAddressId+'&hospitalUserId='+hospitalUserId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  approveRequest(body){
    return this.apiService.post(this.clinicUrl+'discountrequest/updateApproval',body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  rejectRequest(body){
    return this.apiService.post(this.clinicUrl+'discountrequest/cancleApproval',body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  allroles(){
    return this.apiService.post(this.clinicUrl+'allRole',{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  getOpdSurgeriesList(hospitalAddressId){
    return this.apiService.post(this.clinicUrl+'surgery/opdSurgeriesByhospitalAddresId?hospitalAddresId='+hospitalAddressId,{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  deleteDiscountDef(discountReqId,hospitalAddressId){
    return this.apiService.delete(this.clinicUrl + 'deleteAmountDetail?discountApprovalEligibilityId='+discountReqId+'&hospitalAddressId='+hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  findRoleMenuByRoleName(roleName){
    return this.apiService.get(this.clinicUrl+'findRoleMenuByRoleName?roleName='+roleName,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  activateDiscount(discountReqId,hospitalAddressId){
    return this.apiService.get(this.clinicUrl+'activeAmountDetail?discountApprovalEligibilityId='+discountReqId+'&hospitalAddressId='+hospitalAddressId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  approvalListByAmount(amount,hospitalAddressId){
    return this.apiService.get(this.clinicUrl+'amountDetail?amount='+amount+'&hospitalAddressId='+hospitalAddressId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  getInfoByUserId(hospitalUserId,hospitalAddressId,roleName){
    return this.apiService.get(this.clinicUrl+'personalDetailInformation?hospitalUserId='+hospitalUserId+'&hospitaladdressId='+hospitalAddressId+'&role='+roleName,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  saveRoleMenu(body){
    return this.apiService.post(this.clinicUrl + 'saveRoleMenu', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  saveServiceForSurgery(body){
    return this.apiService.post(this.clinicUrl + 'saveSurgeryService', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  surgeryServiceList(hsptaddressId,surgeryId){
    return this.apiService.get(this.clinicUrl + 'surgeryServiceList?hospitalAddressId=' + hsptaddressId + '&surgeryId=' + surgeryId, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  bookdcAppointment(body){
    return this.apiService.post(this.clinicUrl + 'selfAppointment', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getOpdListForDotor(body){
    return this.apiService.post(this.clinicUrl+'opdPatientDetailForDoctor',body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getOpdPatientsForCaretaker(hospitalAddressId,hospitalaUserId){
    return this.apiService.post(this.clinicUrl + 'opdpatientDetailForCaretaker?hospitalAddressId=' + hospitalAddressId + '&hospitaluserId=' + hospitalaUserId,{}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getDischargeListForCaretaker(hospitalAddressId,hospitalaUserId){
    return this.apiService.post(this.clinicUrl + 'dischargeOpdpatientDetailForCaretaker?hospitalAddressId=' + hospitalAddressId + '&hospitaluserId=' + hospitalaUserId,{}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }

  getDischargeListForDoctor(body){
    return this.apiService.post(this.clinicUrl+'dischargeOpdPatientDetailForDoctor',body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  getDiscountRequestsAll(body){
    return this.apiService.post(this.clinicUrl+'discountrequest/discountRequestDetail',body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  getDiscountDetailsByDiscountReqId(discountReqId,hospitalAddressId){
    return this.apiService.get(this.clinicUrl+'discountrequest/findDiscountRequest?discountRequestId='+discountReqId+'&hospitalAddressId='+hospitalAddressId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });
  }
  getInventoryDetails(body){
    return this.apiService.post(this.clinicUrl + 'inventoryDetails', body, {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) });;
  }
  getMultipleOrdersList(addressId,supplierOrderId){
    return this.apiService.get(this.clinicUrl+'multipleOrderItemList?addressId='+addressId+"&supplierordersId="+supplierOrderId,{headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveBulkOrder(body){
    return this.apiService.post(this.clinicUrl + 'saveMultipleItemOrders', body, {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getInventoryInvoice(addressId,supplierOrderId){
    return this.apiService.get(this.clinicUrl+'supplierordersForInvoiceBySupplierordersId/'+addressId+"/"+supplierOrderId,{headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  deliverBulkOrder(body){
    return this.apiService.post(this.clinicUrl + 'multipulItemOrderDeliver', body, {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveInvoicePdf(body,addressId,supplierOrdersId){
    return this.apiService.post(this.clinicUrl+'uploadInvoice?addressId='+addressId+'&supplierordersId='+supplierOrdersId,body,{})
  }
  getPincodeDetails(postal, cc){
    return this.apiService.get(this.utilitiesUrl+'countrycodecontroller/getCountrycode?countrycode='+cc+'&postalcode='+postal,{ })
  }
  getUsersByRole(addressId,departmentId,roleId){
    return this.apiService.get(this.clinicUrl+'userroleDetails?addressId='+addressId+'&departmentId='+departmentId+'&roleId='+roleId,{})
  }
  getMedicalReportImage(hospitalAddressId,hospitalPrescriptionId,inPatientId){
    return this.apiService.get(this.clinicUrl+'medicalreportsDetails?hospitalAddressId='+hospitalAddressId+'&hospitalprescriptionId='+hospitalPrescriptionId+'&inPatientId='+inPatientId,{})
  }
  searchUserByNumber(body){
    return this.apiService.post(this.elasticSearchUrl+'searchPatient',body,{headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  sendFeedback(formData) {
    return this.apiService.post(this.utilitiesUrl + 'feedback/create', formData, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  checkLicenseNumber(number){
    return this.apiService.get(this.clinicUrl+'isLicencenumberExistsOrNot?licencenumber='+number,{})
  }
  activateRoomOrDeactivateRoom(flag,hospitalAddressId,roomId){
   return  this.apiService.get(this.clinicUrl+'activeRoom?flag='+flag+'&hospitalAddresId='+hospitalAddressId+'&roomId='+roomId, { headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  activateBedOrDeactivateBed(flag,hospitalAddressId,roomBedId){
    return this.apiService.get(this.clinicUrl+'activeRoomBed?flag='+flag+'&hospitalAddresId='+hospitalAddressId+'&roombedId='+roomBedId,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  getRoomDetailsByRoomId(hospitalAddressId,roomId){
    return this.apiService.get(this.clinicUrl+'roomDetails?hospitalAddresId='+hospitalAddressId+'&roomId='+roomId,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  updateRoom(body){
    return this.apiService.post(this.clinicUrl+'updateRoom',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  getOpdInvoices(body){
    return this.apiService.post(this.clinicUrl+'dischargeDetail',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  getAppointmentPayments(body){
    return this.apiService.post(this.clinicUrl+'appotemntDetails',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  searchAppointmentPaymentsPatient(body){
    return this.apiService.post(this.clinicUrl+'searchAppotemntDetails',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  searchIpdOpdPaymentsPatient(body){
    return this.apiService.post(this.clinicUrl+'searchDischargeDetail',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }

  getTemplatesList(templateName,addressId){
    return this.apiService.get(this.clinicUrl+'prescriptionTemplateDetails?addressId='+addressId+'&name='+templateName,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  getTemplateById(templateId){
    return this.apiService.get(this.clinicUrl+'findTemplateDetails?prescriptionTemplateId='+templateId,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  getInvoiceByAppointmentId(hospitalAddressId,id){
    return this.apiService.get(this.clinicUrl+'invoice/findInvoiceByAppointmentId?hospitalAddresId='+hospitalAddressId+'&referenceappointmentId='+id,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  getSurgeryBySurgery(body){
    return this.apiService.post(this.clinicUrl+'surgery/searchSurgery',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  getPatientInfo(addressId,cellNumber){
    return this.apiService.get(this.clinicUrl+'userDetail?addressId='+addressId+'&cellNumber='+cellNumber,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  getDoctorandNutritionistsList(addressId){
    return this.apiService.get(this.clinicUrl+'doctorDetailsWithFee?hospitaAddressId='+addressId,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  } 
  getMessagesCount(addressId,userId){
    return this.apiService.get(this.clinicUrl+'message/messageCount?addressId='+addressId+'&userId='+userId,{})
  }
  saveAppointmentNotes(body){
    return this.apiService.post(this.clinicUrl+'saveAppointmentNotes',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  getAppointmentNotes(body){
    return this.apiService.post(this.clinicUrl+'appointmentNotesList',body,{ headers: new HttpHeaders({ 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }), observe: 'response','Cache-Control': 'no-store, no-cache'})
  }
  getDetailedInvoiceReport(hospitalAddressId,inPatientId){
    return this.apiService.get(this.clinicUrl+'invoice/inPatientPaymentByDate?hospitalAddresId='+hospitalAddressId+'&inpatientId='+inPatientId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  saveSurgeryNotes(body){
    return this.apiService.post(this.clinicUrl+'saveSurgerynotes',body,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getSurgeryNotes(surgeryId){
    return this.apiService.get(this.clinicUrl+'getSurgerynotes?surgeryId='+surgeryId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getAmbulanceHistoryByAmbulanceNumber(ambulanceNumber,hospitalAddressId){
    return this.apiService.get(this.clinicUrl+'ambulanceDetailByAmbulanceNumber?ambulanceNumber='+ambulanceNumber+'&hospitalAddresId='+hospitalAddressId,{ headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getUserDetailsByHospitalUserId(hospitalAddressId,hospitalUserId){
    return this.apiService.get(this.clinicUrl+'associatedUserDetails?hospitalAddressId='+hospitalAddressId+'&hospitalUserId='+hospitalUserId, {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  convertOPDtoIPD(hospitalAddressId,userId){
    return this.apiService.get(this.clinicUrl+'convertOpdToIpd?hospitaAddressId='+hospitalAddressId+'&userId='+userId, {headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }
  getFollowupAppointmentList(hospitalAddressId,pageNo){
    return this.apiService.get(this.clinicUrl+'followupAppointmentList?hospitalAddressId='+hospitalAddressId+'&maxResult=10&pageNumber='+pageNo,{headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })
  }

  searchHospitalUser(id,num){
    return this.apiService.get(this.clinicUrl+'userInformation?addressId='+id +'&cellNumber='+num,{headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': this.globalStorage.getItem('loggedInAs'), 'forUserId': this.globalStorage.getItem('forUserId'), 'Cache-Control': 'no-store, no-cache' }) })

  }
}



