import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClinicGuard implements CanActivate {
  constructor(private globalStorage: GlobalStorageService, private router:Router,private cookieService: CookieService ) {
 
  }
  userName
  password
  obj
  doctor
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot){
      if(JSON.parse(this.globalStorage.getItem('userSession')) == null || JSON.parse(this.globalStorage.getItem('userSession')) == undefined){
        // this.globalStorage.setItem('userSession',this.cookieService.get('usersession'));
        // this.globalStorage.setItem('forUserId',JSON.parse(this.cookieService.get('forUserid')));
        // this.globalStorage.setItem('clinicDetails',JSON.parse(this.cookieService.get('clinicDetails')));

        // this.globalStorage.setItem('transResponse', this.cookieService.get('transResponse'));
      }
      if(sessionStorage.getItem('clinicDetails')!=null){
        this.globalStorage.getItem('clinicDetails')
      }
      else{
        // this.globalStorage.removeItem('clinicDetails')
      }
      this.doctor=JSON.parse(this.globalStorage.getItem('userSession'))
      if(this.doctor!=undefined || this.doctor!=null){
        return true;
      }
      else{
      this.router.navigate(["/clinicLogin"])
       return false
      }
  }
  
}
