<div class="row">
    <div class="col-md-12">
        <div class="table-responsive m-t-40">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="text-left">Medicine Name</th>
                        <th class="text-center">Medicine Type</th>
                        <th class="text-center">Instructions</th>
                        <th class="text-center">Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let medicine of MedicationPrescriptions">
                        <td class="text-left">{{medicine?.medicineName}}</td>
                        <td class="text-center">{{medicine?.type}}</td>
                        <td class="text-center">{{medicine?.instructions}}</td>
                        <td class="text-center">{{medicine?.quantity}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!-- <div class="col-md-12 mt-2 mb-2 text-right" *ngIf="prescriptionInfo?.status=='New'">
        <button class="btn btn-sm btn-primary" (click)="navigateToPharmacy()">Sent to Pharmacy</button>
    </div> -->
</div>
