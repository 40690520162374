import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr'
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { timer } from 'rxjs';
import * as moment from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-patient-verification',
  templateUrl: './patient-verification.component.html',
  styleUrls: ['./patient-verification.component.css']
})
export class PatientVerificationComponent implements OnInit {
  otpVerificationForm: FormGroup;
  timerOn = true;
  isTimeOn = true;
  isTimeOn2 = false
  subscription: Subscription;
  subscribeTimer
  timeLeft: number = 120;
  timeLeft2: number = 120;
  invalidCredentials
  successCredentials
  message
  mbnumber
  constructor(private globalStorage: GlobalStorageService, private otpService: AuthService, private formBuilder: FormBuilder, private routes: Router,
    private toaster: ToastrService, private route: ActivatedRoute, private reset: AuthService, private toastr: ToastrService) {
    this.email = this.route.snapshot.queryParams.verificationId
    const navigation = this.routes.getCurrentNavigation();
    const state = navigation.extras.state as {
      emailId: string,
      mobileNumber: string,
      userName: string
    };
    this.otpVerificationForm = this.formBuilder.group({
      email: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      userName: ['', Validators.required],
    })
    this.mbnumber = state.mobileNumber
    this.otpVerificationForm.patchValue({
      email: state.emailId,
     phoneNumber: state.mobileNumber.replace(/.(?=.{4})/g, 'x').padStart(10, 'x').slice(-10),
      userName: state.userName
    })
  }

  ngOnInit(): void {
    this.globalStorage.getItem('registeredEmail')
    this.observableTimer()
  }

  OTP
  email; PhOTP; emailVerificationDone = false
  verifyOTP() {
    //alert()
    //this.isTimeOn=false
    if (this.OTP == undefined || this.otpVerificationForm.value.email == undefined) {
      return
    }

    if (this.OTP == "" || this.otpVerificationForm.value.email == "") {
      return
    }
    if(this.globalStorage.getItem('isProfileCompleted')=='N'){
      this.otpService.verifyEmailOtpNew(this.otpVerificationForm.value.email, this.OTP).subscribe(res=>{
        this.invalidCredentials = false
        this.verifyNowButton = true
        this.invalidCredentials = false
        this.verifyNowButton = true
        this.emailVerificationDone = true
        this.isTimeOn = false;
        this.observableTimer2()
        // this.timeLeft = 120
        //this.message='Your Email ID is now verified.'
        // this.successCredentials=true
        //this.toaster.success('Your Email ID is now verified.')
        this.globalStorage.setItem('isProfileCompleted','Y')
        sessionStorage.setItem('patientLoginDetails', JSON.stringify(res.data))
        this.globalStorage.setItem('patientCredentials', JSON.stringify(request))
        this.globalStorage.setItem('userData', JSON.stringify(res.data));
        this.globalStorage.setItem('forUserId', JSON.stringify(res.data.userId))
        this.globalStorage.setItem('userId', JSON.stringify(res.data.userId))
        this.globalStorage.setItem('loggedInAs', 'PATIENT')
       // this.globalStorage.setItem('userSession', JSON.stringify(res.userSession))


        // this.scripts.loadCssStyles()
        var datas = this.globalStorage.getItem('userData')
        var obj = JSON.parse(datas)
        var today = moment(obj['lastLogin']).format('YYYY-MM-DD');
        var request = {
          "forUserId": obj['userId'],
          "userId": obj['userId']
        }
        this.globalStorage.setItem('firstName',obj?.firstName)
        this.globalStorage.setItem('lastName',obj?.lastName)
        this.globalStorage.setItem('isPersonalDetailcompleted',obj?.isPersonalDetailcompleted)
        this.globalStorage.setItem('isAddrsscompleted',obj?.['isAddrsscompleted'])
        this.globalStorage.setItem('isProfileCompleted',obj?.isProfileCompleted)
        this.globalStorage.setItem('height',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='height'))?.[0]?.['vitalValue'])
        this.globalStorage.setItem('bloodGroup',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='bloodgroup'))?.[0]?.['vitalValue'])
        this.globalStorage.setItem('pulse',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='pulse'))?.[0]?.['vitalValue'])
        this.globalStorage.setItem('glucolseLevelPreLunch',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='fasting'))?.[0]?.['vitalValue'])
        this.globalStorage.setItem('glucolseLevelPostLunch',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='random'))?.[0]?.['vitalValue'])
        this.globalStorage.setItem('bp',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='bloodPressure'))?.[0]?.['vitalValue'])

      },error=>{
        this.invalidCredentials = true
        this.message = error?.error?.message
      })
    }
    else{
      this.otpService.verifyEmailOtp(this.otpVerificationForm.value.email, this.OTP, this.otpVerificationForm.value.userName).subscribe(res => {
        this.invalidCredentials = false
        this.verifyNowButton = true

        // this.timeLeft = 120
        //this.message='Your Email ID is now verified.'
        // this.successCredentials=true
        //this.toaster.success('Your Email ID is now verified.')

        this.emailVerificationDone = true
        this.isTimeOn = false;
        this.observableTimer2()
      }, error => {
        this.invalidCredentials = true
        this.message = error.error.message
        //this.toaster.error('Your Email not verifed')
      })
    }

  }

  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  verifyPhoneOTP() {
    if (this.PhOTP == undefined || this.otpVerificationForm.value.phoneNumber == undefined) {
      return
    }

    if (this.PhOTP == "" || this.otpVerificationForm.value.phoneNumber == "") {
      return
    }
    this.otpService.verifyPhoneOtp(this.mbnumber.replace(/\+/gi, '%2B'), this.PhOTP, this.otpVerificationForm.value.userName, 0).subscribe(res => {
      this.invalidCredentials = false
      this.successCredentials = true
      this.toaster.success('Your phone number is now verified.')
      this.routes.navigate(['/patientLogin'])

      this.observableTimer()

    }, error => {
      this.invalidCredentials = true
      this.message = error.error.message
      //this.toaster.error('Your Phone number not verifed')
    })
  }

  getOtp() {
    this.invalidCredentials = false
    this.successCredentials = false
    this.isTimeOn = true
    if (this.otpVerificationForm.value.email == "" || this.otpVerificationForm.value.email == undefined) {
      this.toastr.warning('Enter the Email to send verification Link')
      return
    }
    this.reset.resendNewEmailOtpUserReg(this.otpVerificationForm.value.email, this.otpVerificationForm.value.userName).subscribe(res => {
      this.invalidCredentials = false
      this.message = "Check your email for OTP."
      this.observableTimer()
    }, error => {
      this.invalidCredentials = true
      this.message = error.error.message
    })

  }
  observableTimer() {
    this.timeLeft = 120
    this.isTimeOn = true
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.successCredentials = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        // this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  observableTimer2() {
    this.isTimeOn = false
    this.isTimeOn2 = true
    this.timeLeft2 = 120
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft2 - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer2').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn2 = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.successCredentials = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        // this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  verifyNowButton = true
  otpEntered(value) {
    if (value.length == 6) {
      this.verifyNowButton = false
    }
    else {
      this.verifyNowButton = true
    }
  }
  getPhoneOtp() {
    if (this.otpVerificationForm.value.phoneNumber == "" || this.otpVerificationForm.value.phoneNumber == undefined) {
      this.toastr.warning('Enter the mobile number to send verification OTP')
      return
    }
    this.reset.resendNewPhoneOtpUserReg(this.mbnumber.replace(/\+/gi, '%2B'), this.otpVerificationForm.value.userName).subscribe(res => {
      this.invalidCredentials = false
      this.toastr.success('OTP sent successfully.')
      this.observableTimer2()
      this.timeLeft = 120
    }, error => {
      this.invalidCredentials = true
      this.message = error.error.message
      //this.toastr.error(error['error']['message'])
    })

  }
}
