<div class="row align-items-center vitals_bc">
  <div class="col-md-6">
    <h6 class=" font_Poppins  p_14">Latest Vitals </h6>
  </div>
  <div class="col-md-6 mt-2" *ngIf="checkCareTaker()">
    <button *ngIf="roles?.addUpdateVitalsPermission" type="button" class="btn float-right mb-2 btn-xs btn-primary text-white btncolor_vitals" (click)="showUserVitals()"><i class="fa-solid fa-circle-plus pr-1"></i>Add Vitals</button>
  </div>

  <div class="col-md-12 mb-2">
    <div class="row">
      <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12  pr-0 cursor-pointer" (click)="viewUserVitalsHistory(heartRate,'pulse')">
        <a href="javascript:void(0)" class="btn vitals-b1 w-100 curser-null">
          <div class="fixedtag bg-red" *ngIf="(heartRate?.vitalValue>0)&&(60>heartRate?.vitalValue || heartRate?.vitalValue>90)">Alert</div> 
         <div class="float-left mr-2 vt_padding"><img  src="assets/img/heart.svg"></div>  
          <div class="vitalsbccolor pl-2"> <span>Heart Rate<div
            class="clear"> </div>
          <p class="m-0">{{heartRate?.vitalValue |split2}} <sup> BPM </sup></p>
        </span></div>
        
        
        </a>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12 pr-0 cursor-pointer" (click)="viewUserVitalsHistory(height,'height')">
        <a href="javascript:void(0)" class="btn   vitals-b1 w-100 curser-null">   
          <div class="float-left mr-2 vt_padding"><img  src="assets/img/height.svg"></div>  
          <div class="vitalsbccolor pl-2"> <span>Height
            <div class="clear"> </div>
            <p class="m-0">{{height?.vitalValue}} <sup> Feet </sup></p>
          </span>
        </div>
        </a>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12 pr-0 cursor-pointer" (click)="viewUserVitalsHistory(weight,'weight')">
        <a href="javascript:void(0)" class="btn vitals-b2 w-100 curser-null">
          <div class="fixedtag bg-red" *ngIf="(weight?.vitalValue>0) &&  (1>weight?.vitalValue || weight?.vitalValue>120)">
            
            Alert</div> 
            
            <div class="float-left mr-2 vt_padding"><img  src="assets/img/weight.svg"></div>  
            <div class="vitalsbccolor pl-2">
            <span>Weight <div class="clear"> </div>
            <p class="m-0">{{weight?.vitalValue |split2}} <sup>Kg</sup></p>
          </span>
        </div>
        </a>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12  cursor-pointer" (click)="viewUserVitalsHistory(bmi,'bmi')">
        <a href="javascript:void(0)" class="btn vitals-b4 w-100 curser-null">
          <div class="fixedtag bg-red" *ngIf="(bmi?.vitalValue>0)&&(bmi?.vitalValue<18 || bmi?.vitalValue>30)">Alert</div>
            <div class="float-left mr-2 vt_padding"><img  src="assets/img/bmi.svg"></div>  
            <div class="vitalsbccolor pl-2">
            <span>BMI
            <div class="clear"> </div>
            <p class="m-0">{{bmi?.vitalValue}}</p>
          </span>
        </div>
        </a>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12 pr-0 cursor-pointer" (click)="viewUserVitalsHistory(pulse,'bloodPressure')">
        <a href="javascript:void(0)" class="btn  vitals-b3 w-100 curser-null  mt-2">
            <div class="fixedtag bg-red blink"
                *ngIf="(systolic>0 || diastolic>0)&&(110>systolic || systolic>140 || 70>diastolic || diastolic>90)">
                Alert</div>      <div class="float-left mr-2 vt_padding"><img  src="assets/img/pressure.svg"></div>  
                
                <div class="vitalsbccolor pl-2">
                <span>Pressure


            <div class="clear"> </div>
            <p class="m-0">{{systolic ? systolic:'-'}} / {{diastolic?diastolic:'-'}} <sup>mm/Hg</sup></p>
          </span>
        </div>
        </a>

      </div>
      <!-- <div class="col-md-3 cursor-pointer" (click)="showUserVitals()">
        <a href="javascript:void(0)" class="btn btn-sm ve vitals-b4 w-100 curser-null"> <i
            class="fa fa-tint faa-flash animated faa-slow" aria-hidden="true"></i> <span>Blood Group


            <div class="clear"> </div>
            <p class="m-0">{{patientVitals?.bloodgroup}}</p>
          </span></a>
      </div> -->

      <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12 pr-0 cursor-pointer" (click)="viewUserVitalsHistory(temp,'temperature')">
        <div class="fixedtag bg-red" *ngIf="(temp?.vitalValue>0) && (96>temp?.vitalValue|| temp?.vitalValue>100)">Alert</div>
        <a href="javascript:void(0)" class="btn  vitals-b3 w-100 curser-null mt-2"> 
          <div class="float-left mr-2 vt_padding"><img  src="assets/img/temperature.svg"></div>  
          <div class="vitalsbccolor pl-2">
          <span>Temperature
            <div class="clear"> </div>
            <p class="m-0">{{temp?.vitalValue}} <sup> F </sup></p>
          </span>
        </div>
        </a>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12 pr-0 cursor-pointer" (click)="viewUserVitalsHistory(oxygen,'oxygenLevel')">
        <a href="javascript:void(0)" class="btn  vitals-b4 w-100 curser-null mt-2">
          <div class="fixedtag bg-red" *ngIf="(oxygen?.vitalValue>0)&&(95>oxygen?.vitalValue)">Alert</div> 
          <div class="float-left mr-2 vt_padding"><img  src="assets/img/o2.svg"></div>  
          <div class="vitalsbccolor pl-2">
          <span
            class="patientc2 fa-2x mr-3" aria-hidden="true">Oxygen Level
            <div class="clear"> </div>
            <p class="m-0">{{oxygen?.vitalValue}} %</p>
          </span>
        </div>
        </a>
      </div>

      <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12 cursor-pointer" (click)="viewUserVitalsHistory(fasting,'bloodglucose')">
        <a href="javascript:void(0)" class="btn vitals-b2 w-100 curser-null mt-2"> 
          <div class="float-left mr-2 vt_padding"><img  src="assets/img/bloodglucose.svg"></div>  
          <div class="vitalsbccolor pl-2">
          <span>Blood Glucose
            <div class="clear"> </div>
            <p class="m-0">{{fasting?.vitalValue}} | {{random?.vitalValue}} <sup>mg/dL</sup></p>
          </span>
        </div>
        </a>
      </div>
    </div>

  </div>
</div>
<div class="row" *ngIf="!isHospital">
  <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12 mb-4 pl-1 pr-0">
    <div class="card ">
      <div class="card-body">
        <h5 class="card-title bcmap text-white font-size-12 p-1">
          <i class="fa fa-male  icon-head" aria-hidden="true"></i> Blood Pressure
        </h5>
        <div class="chart-height-p">
          <div class="position-absolute card-top-buttons"><button class="btn icon-button" data-placement="top"
              data-toggle="tooltip" data-original-title="View"></button></div>
          <div class="card-body oxy-font">
            <div class="row" style="display: none;">
              <span class="col-sm-6 mt-10" style="font-size: 12px;">Select Period : </span>
              <span class="col-sm-6">
                <select [(ngModel)]="selectedPressurePeriod" class="form-control1"
                  style="font-size: 12px;position: absolute;right: 8%;width: 105%;"
                  (change)="ChangeBloodPressureuePeriod(selectedPressurePeriod)">
                  <option selected value="CURRENT_DATE">CURRENT_DATE</option>
                  <option value="YESTERDAY">YESTERDAY</option>
                  <option value="WEEK">WEEK</option>
                  <option value="THIRTY_DAY">THIRTY_DAY</option>
                  <option value="START_OF_MONTH">START_OF_MONTH</option>
                  <option value="SIXTY_DAY">SIXTY_DAY</option>
                  <option value="NINETY_DAY">NINETY_DAY</option>
                </select>
              </span>
            </div>
            <!-- <canvas baseChart class="chart-min-height-p " height="190" [datasets]="pressure" [labels]="pressurelabel"
              [options]="lineChartOptions" [colors]="pressurecol" [legend]="lineChartLegend" [chartType]="lineChartType"
              [plugins]="lineChartPlugins">
            </canvas> -->
            <div style="display: block;">
              <canvas baseChart class="chart-min-height-p " height="200" 
                      [datasets]="scatterChartData"
                      [options]="scatterChartOptions"
                      [chartType]="scatterChartType">
              </canvas>
            </div>


          </div>
        </div>


      </div>
    </div>
  </div>

  <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12 mb-4 pr-0">
    <div class="card ">

      <div class="card-body">
        <h5 class="card-title bcmap text-white font-size-12 p-1"> <i class="fa fa-heartbeat themecol7 icon-head" aria-hidden="true"></i> Heart Rate</h5>

        <div class="chart-height-p" style="padding-bottom: 12px;">
          <div class="position-absolute card-top-buttons"><button class="btn icon-button" data-placement="top"
              data-toggle="tooltip" data-original-title="View"></button></div>
          <div class="card-body oxy-font">

            <canvas baseChart class="chart-min-height-p " height="190" [datasets]="heartrate" [labels]="heartratelabels"
              [options]="lineChartOptions" [colors]="heartratecol" [legend]="lineChartLegend"
              [chartType]="lineChartType" [plugins]="lineChartPlugins">
            </canvas>

          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12 mb-4 pr-0">
    <div class="card ">

      <div class="card-body">
        <h5 class="card-title bcmap text-white font-size-12 p-1"> <i class="fa fa-temperature-high  icon-head" aria-hidden="true"></i>
          Temperature</h5>

        <div class="chart-height-p" style="padding-bottom: 11px;">
          <div class="position-absolute card-top-buttons"><button class="btn icon-button" data-placement="top"
              data-toggle="tooltip" data-original-title="View"></button></div>

          <div class="card-body  temp-font">

            <canvas baseChart class="chart-min-height-p " height="190" [datasets]="temparature"
              [labels]="temperaturelabels" [options]="lineChartOptions" [colors]="temparaturecol"
              [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
            </canvas>
          </div>
        </div>

      </div>

    </div>
  </div>


  <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12 mb-4">
    <div class="card ">

      <div class="card-body">
        <h5 class="card-title bcmap text-white font-size-12 p-1"> <i class="fa fa-weight icon-head" aria-hidden="true"></i> Weight</h5>
        <div class="chart-height-p" style="padding-bottom: 19px;">
          <div class="card-body  temp-font">
            <div class="row" style="display: none;">
              <span class="col-sm-6 mt-10" style="font-size: 12px;">Select Period : </span>
              <span class="col-sm-6">
                <select [(ngModel)]="selectedWeightPeriod" class="form-control1"
                  style="font-size: 12px;position: absolute;right: 8%;width: 105%;"
                  (change)="ChangeWeightPeriod(selectedWeightPeriod)">
                  <option selected value="CURRENT_DATE">CURRENT_DATE</option>
                  <option value="YESTERDAY">YESTERDAY</option>
                  <option value="WEEK">WEEK</option>
                  <option value="THIRTY_DAY">THIRTY_DAY</option>
                  <option value="START_OF_MONTH">START_OF_MONTH</option>
                  <option value="SIXTY_DAY">SIXTY_DAY</option>
                  <option value="NINETY_DAY">NINETY_DAY</option>
                </select>
              </span>
            </div>
            <canvas baseChart class="chart-min-height-p " height="190" [datasets]="weightm" [labels]="weightlabel"
              [options]="lineChartOptions" [colors]="weightlabelcol" [legend]="lineChartLegend"
              [chartType]="lineChartType" [plugins]="lineChartPlugins">
            </canvas>
          </div>
        </div>

      </div>
    </div>
  </div>

</div>


<!-- <div class="container">
    <div class="row">
      <div class="col-md-4 col-lg-2 col-sm-4 col-6 mb-2 pd-2 ">
        <a href="javascript:void(0)" class="card vital-card">
          <div class="card-body text-center pd-2e">
            <img src="assets/img/temp.png">
            <p class="card-text font-weight-semibold p1 mb-0">Tempature</p>
            <p class="lead text-center p2">{{vitals?.weight}}<span>c</span></p>
          </div>
        </a>
      </div>

      <div class="col-md-4 col-lg-2 col-sm-4 col-6 mb-1 pd-2 ">
        <a href="javascript:void(0)" class="card vital-card">
          <div class="card-body text-center pd-2e">
            <img src="assets/img/blood.png">
            <p class="card-text font-weight-semibold p1 mb-0">Blood</p>
            <p class="lead text-center p2">N/A<span>+</span></p>
          </div>
        </a>
      </div>
      <div class="col-md-4 col-lg-2 col-sm-4 col-6 mb-1 pd-2 ">
        <a href="javascript:void(0)" class="card vital-card">
          <div class="card-body text-center pd-2e">
            <img src="assets/img/blood-pressure.png">
            <p class="card-text font-weight-semibold p1 mb-0">Pressure</p>
            <p class="lead text-center p2">{{vitals?.bloodPressure}} <span>c</span></p>
          </div>
        </a>
      </div>
      <div class="col-md-4 col-lg-2 col-sm-4 col-6 mb-1 pd-2 ">
        <a href="javascript:void(0)" class="card vital-card">
          <div class="card-body text-center pd-2e">
            <img src="assets/img/heart-rate.png">
            <p class="card-text font-weight-semibold p1 mb-0">Heart Rate</p>
            <p class="lead text-center p2">{{vitals?.pulse}} <span> bpm</span></p>
          </div>
        </a>
      </div>
      <div class="col-md-4 col-lg-2 col-sm-4 col-6 mb-1 pd-2 ">
        <a href="javascript:void(0)" class="card vital-card">
          <div class="card-body text-center pd-2e">
            <img src="assets/img/height.png">
            <p class="card-text font-weight-semibold p1 mb-0">Height</p>
            <p class="lead text-center p2">{{vitals?.height}} <span>c</span></p>
          </div>
        </a>
      </div>
      <div class="col-md-4 col-lg-2 col-sm-4 col-6 mb-1 pd-2 ">
        <a href="javascript:void(0)" class="card vital-card">
          <div class="card-body text-center pd-2e">
            <img src="assets/img/weight.png">
            <p class="card-text font-weight-semibold p1 mb-0">Weight</p>
            <p class="lead text-center p2">{{vitals?.weight}} <span>kg</span></p>
          </div>
        </a>
      </div>
    </div>
  </div> -->
