import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DoctorService } from '../../AppServices/doctor.service';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CancelAppintmentComponent } from '../cancel-appintment/cancel-appintment.component';
import { RescheduleAppointmentComponent } from '../reschedule-appointment/reschedule-appointment.component';
import * as moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { DoctorVideoService } from 'src/app/shared/services/doctorvideo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})





export class doctorDashboardComponent implements OnInit {
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true
  };


  public barChartLabels = ['Jan', 'Feb', 'Mar', 'April', 'May', 'June', 'July'];
  public barChartType = 'bar';
  public barChartLegend = true;

  public barChartData = [

    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Recovered Patient  ' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'New Patient' }

  ];


  public pieChartLabels: string[] = ['Appointments', 'Revenue', 'Referrals', 'Associate', 'Diagnostic'];
  public pieChartData: number[] = [40, 30, 50, 20, 10];
  public pieChartType: string = 'pie';


  constructor(private globalStorage: GlobalStorageService, private doctorService: DoctorService, private sanitizer: DomSanitizer, private videoService: DoctorVideoService,
    private patientController: PatientScheduleControlService, private patientService: DoctorPatientService,private modalService:NgbModal,
    private toastrService: ToastrService, private matDialog: MatDialog) {
    this.doctorService.rescheduleAppointmentObservable.subscribe(res => {
      this.LoadCurrentUpontments();

    })


  }

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  p1 = 1; noOfAppointments; appointmentValue; revenueValue
  p2 = 1
  notificationCount
  notifyToView
  primaryDetailsPercentage: number = 20
  feeDetailsPercentage: number = 0
  totalNumberOfAppointments: number = 0
  personalDetailsPercentage: number = 0
  addressDetailsPercentage: number = 0
  certificationPercentage: number = 0
  currentNotificationLength: number = 0
  currentNotifications
  notications
  notificationLength
  notViewedNotifications
  viewedNotifications
  feeList
  imageSource; presentTime; subscription: Subscription;
  ngOnInit(): void {
    this.globalStorage.setItem('forUserId', JSON.parse(this.globalStorage.getItem('doctorId')))
    var toDay = new Date()
    var today = moment(toDay).format('YYYY-MM-DD');
    var date: any = new Date()
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds
    const source = interval(1000);
    const text = strTime;
    this.subscription = source.subscribe(val => this.updater(text));
    this.LoadCurrentUpontments();
    this.getNotifications();
    this.loadMyReports();
    this.LoadDoctor()
    this.getFollowUps()
    // this.LoadUpcomingUpontments();
    var data = this.globalStorage.getItem('LoginDetails')
    var obj = JSON.parse(data)
    this.firstName = obj['data']['firstName']
    this.lastName = obj['data']['lastName']
    this.LoadAppointmentsGraphs(obj['data']['doctorId'], 'THIRTY_DAY')
    this.doctorService.getPersonalDetails(obj['data']['doctorId']).subscribe(res => {
      if (res['data']) {
        this.personalDetailsPercentage = 25
      };
    }, error => {
      this.toastrService.error("Error in getting personal details")
    });

    this.doctorService.getDoctorFeeDetails(parseInt(this.globalStorage.getItem('doctorId'))).subscribe(res => {

      this.feeList = res['data']
      if (this.feeList?.length > 0) {
        this.feeDetailsPercentage = 5
      }
    })
    this.doctorService.getAddressDetails(obj['data']['doctorId']).subscribe(res => {
      if (res['data']?.[0]?.['zip']) {
        this.addressDetailsPercentage = 25
      }
    })
    this.doctorService.getCertificationDetails(obj['data']['doctorId']).subscribe(res => {
      if (res['data']?.length > 0) {
        this.certificationPercentage = 25
      }
    })

  }
  cancelCount
  AllCount
  firstName
  lastName
  currentApointments: any = []
  current: any[] = []
  upcoming: any[] = []
  previous = []
  cancelled: any[] = []
  BindCurrentApointments

  reports
  convertH2M(timeInHour) {
    var timeParts = timeInHour.split(":");
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
  }

  followUpAppointments=[]
  getFollowUps(){
    this.doctorService.getFollowUps(this.globalStorage.getItem('doctorId'),4,1).subscribe(res => {
      this.followUpAppointments = res['data']
    })
  }
  updater(time) {
    this.presentTime = this.convertH2M(time)
  }
  loadMyReports() {
    this.doctorService.getMyReports(this.globalStorage.getItem('doctorId')).subscribe(res => {
      this.reports = res['data']
      // this.cancelCount=res['data']['CANCELLED'].length
      // this.AllCount=(res['data']['PREVIOUS'].length+res['data']['UPCOMING'].length+res['data']['CURRENT'].length)
    })
  }
  profileDetails
  LoadDoctor() {
  //  this.doctorService.getDoctorDetailsById(this.globalStorage.getItem('doctorId')).subscribe(res => {

      this.profileDetails = this.globalStorage.getItem('doctorFirstName')+ ' '+ this.globalStorage.getItem('doctorLastName')
    // }, error => {
    //   this.toastrService.error("Error in  getting doctor Details")
    // })
  }

  LoadCurrentUpontments() {
    this.doctorService.myAppoinments(this.globalStorage.getItem('doctorId')).subscribe(res => {

      this.current.length = 0;
      this.upcoming.length = 0;//res['data']["PREVIOUS"]['data']
      this.current = res['data']['CURRENT']['data']
      this.upcoming = res['data']['UPCOMING']['data']
      this.totalNumberOfAppointments = this.current.length + this.upcoming.length
      // if(this.current.length>0){
      //   this.currentApointments=this.upcoming
      // }else{
      // this.currentApointments=this.current.concat(this.upcoming)
      // }
      if (this.current.length >= 4) { this.noOfAppointments = 0 }
      if (this.current.length == 4) { this.noOfAppointments = 0 }
      if (this.current.length == 3) { this.noOfAppointments = 1 }
      if (this.current.length == 2) { this.noOfAppointments = 2 }
      if (this.current.length == 1) { this.noOfAppointments = 3 }
      if (this.current.length == 0) { this.noOfAppointments = 4 }
      this.current.sort(function (a, b) {
        if (a.appointmentDate > b.appointmentDate) return 1;
        if (a.appointmentDate < b.appointmentDate) return -1;
        return 0;
      })
      this.upcoming.sort(function (a, b) {
        if (a.appointmentDate > b.appointmentDate) return 1;
        if (a.appointmentDate < b.appointmentDate) return -1;
        return 0;
      })
      this.doctorService.myALLAppoinments(this.globalStorage.getItem('doctorId')).subscribe(res => {
        this.previous = res['data']['PREVIOUS']['data'].reverse()
        // for(var i=0;i<=this.previous.length;i++){
        //   this.patientService.getPatientPersonalDetails(this.previous[i]?.userId).subscribe(res=>{
        //     var img
        //         if(res['body']['data']['profilePic'] !=null){
        //           var src="data:image/jpeg;base64,"+res['body']['data']['profilePic']
        //           img=this.sanitizer.bypassSecurityTrustResourceUrl(src);
        //         }
        //         else{
        //           img='assets/img/user/11.png'
        //         }
        //         this.previous.forEach(function (element) {
        //           element.ImageUrl =  img;
        //         });
        //       })
        //  }
        this.totalNumberOfAppointments = this.totalNumberOfAppointments + this.previous.length
      })
      //this.BindCurrentApointments
    }, error => {
      this.toastrService.error('Error in getting current appointments')
    });

  }

  // upComingApointments:any[]=[]
  // BindUPComingApointments
  // LoadUpcomingUpontments(){
  //   this.doctorService.getUpcomingAppoinments(this.globalStorage.getItem('doctorId')).subscribe(res=>{
  //     this.upComingApointments=res["data"]
  //    // this.BindUPComingApointments= this.upComingApointments.filteredData
  //     //this.BindUPComingApointments.paginator = this.paginator;
  //   },
  //   error=>{
  //            this.toastrService.error('Error in getting upcoming appointments')
  //   });
  // }

  viewPdf(pdf) {
    //this.patientService.viewPdf();
  }

  approveAppointment(appintment) {
    var appintmentObj = {
      appointmentId: appintment.appointmentId
    }
    this.doctorService.acceptAppointment(appintmentObj).subscribe(res => {
      this.toastrService.success("Appointment accepted successfully");
    }, error => {
      this.toastrService.error("Error in accepted appontment");
    })
  }

  LoadAppointmentsGraphs(id, time) {
    this.doctorService.getAppointmentGraphValues(id, time).subscribe(res => {
      var value = JSON.parse(res['data']['data'])
      this.appointmentValue = value['rows'][0]['c'][0]['v']
      this.pieChartData = [this.appointmentValue, 30, 10, 20, 10]
      this.loadRevenueGraph(id, time)
    })
  }
  loadRevenueGraph(id, time) {
    this.doctorService.getRevenueGraphValue(id, time).subscribe(res => {
      var value = JSON.parse(res['data']['data'])
      this.revenueValue = value?.['rows']?.[0]?.['c']?.[0]?.['v']
      this.pieChartData = [this.appointmentValue, this.revenueValue, 30, 20, 10]
    })

  }
  rejectAppointment(appintment) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = appintment;
    this.matDialog.open(CancelAppintmentComponent, dialogConfig);

  }

  rescheduleAppointmentNew(appointment){
    const modalRef = this.modalService.open(RescheduleAppointmentComponent, {
      centered: false,
      windowClass: 'modal-left hw-50',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.appintmentData = appointment;

    modalRef.result.then(
      (userConfirmed: boolean) => {
        if (userConfirmed) {
        }
      },
      () => { }  // Handle dismiss callback
    );
  }


  viewCurrentAppontment(appointmentDetails) {
    console.warn("appointmentDetails ::::::::::::;;", appointmentDetails)
    this.doctorService.appointments = appointmentDetails
    this.getPatientDetails(appointmentDetails);
  }

  getPatientDetails(appointmentDetails) {
    let mainUserId = JSON.parse(this.globalStorage.getItem('forUserId'))

    this.globalStorage.setItem('forUserId', appointmentDetails.userId)
    var req = {
      "forUserId": appointmentDetails.userId,
      "userId": JSON.parse(this.globalStorage.getItem('doctorId')),
    }

    this.patientService.getUserPersonalDetails('personaldetails/get/', appointmentDetails.userId, req).subscribe(res => {
      this.globalStorage.setItem('forUserId', mainUserId)
      var patientDetails = res['body']['data'];
      patientDetails.AppointmentDetails = appointmentDetails;
      patientDetails.Appointment = 'Current';
      patientDetails.AppointmentType = 'Privious';
      // patientDetails.type = 'Hospital';
      //patientDetails.Appointment='Upcoming';
      this.doctorService.OpenPatientSideNave(patientDetails);
    }, error => {
      this.globalStorage.setItem('forUserId', mainUserId)
      this.toastrService.error("Error in getting patient details");
    })
  }
  profilePicOfPatient(id) {
    // if(id != 0){
    //   this.patientService.getPatientPersonalDetails(id).subscribe(res=>{
    //     if(res['body']['data']['profilePic'] !=null){
    //       var src="data:image/jpeg;base64,"+res['body']['data']['profilePic']
    //       this.imageSource=this.sanitizer.bypassSecurityTrustResourceUrl(src);
    //     }
    //     else{
    //       this.imageSource='assets/img/user/11.png'
    //     }
    //   },error=>{ this.imageSource='assets/img/user/11.png'})
    // }

    // return this.imageSource
  }
  appointmentData
  checkTime(appointment, x, y) {
    this.appointmentData = appointment
    var date: any = new Date()
    var hours: any = date.getHours();

    var minutes = date.getMinutes();
    var seconds = date.getSeconds()
    date = moment(date).format('YYYY-MM-DD');
    var ampm = hours >= 12 ? 'PM' : 'AM';
    //hours = hours % 12;

    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    if (date == x) {
      y = (y.slice(0, -6) * 60)
      hours = (hours * 60)
      if ((y - hours) <= 5 || hours > y) {
        //this.toastrService.warning("yes")
      }
      else {
        this.toastrService.warning("Video can only open 5 minutes before of the appointment.")
        return
      }
      // this.appointmentData.fromTime?.slice(0,-6)*60
    }

  }
  videoCall(appointment, x, y, z) {
    let a5 = Date.now()
    this.checkTime(appointment, x, y)
    this.globalStorage.setItem('videoUserId', appointment['userId'])
    // appointment.postion='right'
    this.getPatientDetails(appointment);
    this.videoService.getUserName(appointment['appointmentId'], appointment['userId'])
    this.patientService.videoCalling(appointment);
  }



  MyNotification: any[] = []
  // MyNotification:any[]=[{'notification':'General Notification','type':'General'},{'notification':'New Appointment','type':'Appointment'},{'notification':'Cancel Appointment','type':'Appointment'},{'notification':'Video Call','type':'Video'}]
  getNotifications() {
    this.patientController.getLatestDoctorNotificationAlerts('notificationalerts/get/all?doctorId=' + this.globalStorage.getItem('doctorId') + '&maxResult=100&pageNumber=' + this.p1).subscribe(res => {

      this.notificationCount = res['data'].length
      this.notications = res['data']
      this.notificationLength = this.notications.filter((obj) => obj.viewed == 'N').length;
      this.notViewedNotifications = this.notications.filter((obj) => obj.viewed == 'N')
      var today = new Date()
      this.currentNotifications = this.notications.filter((obj) => obj.viewed == 'N' && formatDate(obj.lastUpdatedTs, "yyyy-MM-dd", 'en-us') == formatDate(today, "yyyy-MM-dd", 'en-us'))
      this.viewedNotifications = this.notications.filter((obj) => obj.viewed == 'Y')
      for (var i = 0; i <= this.notViewedNotifications.length; i++) {
        var today = new Date()
        var month = today.getMonth() + 1
        var year = today.getFullYear()
        var d = today.getDate()

        if (this.notViewedNotifications[i]?.lastUpdatedTs) {
          var y = new Date(this.notViewedNotifications[i]?.lastUpdatedTs).getMonth() + 1
          var z = new Date(this.notViewedNotifications[i]?.lastUpdatedTs).getFullYear()
          var p = new Date(this.notViewedNotifications[i]?.lastUpdatedTs).getDate()
          if (y == month && year == z && d == p) {
            this.currentNotificationLength = this.currentNotificationLength + 1
          }
        }
      }
    })
  }


  doctorRating
  loadDoctorRating() {
    this.doctorService.getDoctorRating(this.globalStorage.getItem("doctorId")).subscribe(res => {
      this.doctorRating = res['data']
    })
  }

  updaterNotify(data) {
    $('#notificationToView').modal('show')
    this.notifyToView = data
    var req = {
      "active": data.active,
      "notificationalertsId": data.notificationalertsId,
      "objectkey": data.objectkey,
      "objecttype": data.objecttype,
      "doctorId": data.doctorId,
      "reason": data.reason,
      "viewed": "Y"
    }
    this.doctorService.updateNotifications(data.notificationalertsId, data.doctorId, req).subscribe(res => {
    })

  }
  closeModal() {
    this.currentNotificationLength = 0
    this.getNotifications()
  }
}


export class AppComponent {
  title = 'Fruits distribution';
  type = 'ComboChart';
  data = [
    ["Apples", 3, 2, 2.5],
    ["Oranges", 2, 3, 2.5],
    ["Pears", 1, 5, 3],
    ["Bananas", 3, 9, 6],
    ["Plums", 4, 2, 3]
  ];
  columnNames = ['Fruits', 'Jane', 'Jone', 'Average'];
  options = {
    hAxis: {
      title: 'Person'
    },
    vAxis: {
      title: 'Fruits'
    },
    seriesType: 'bars',
    series: { 2: { type: 'line' } }
  };
  width = 550;
  height = 400;
}
