import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import { ToastrService } from 'ngx-toastr';
import { DiagnosticServiceController } from '../../services/diagnostic.service';
import { DiagnosticService } from 'src/app/AppServices/diagnostic.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ObservableService } from '../../services/observable.service';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-diagnostic-sidebar',
  templateUrl: './diagnostic-sidebar.component.html',
  styleUrls: ['./diagnostic-sidebar.component.css']
})
export class DiagnosticSidebarComponent implements OnInit {

  imageSource
  lastLogin
  setPersonalDetails
  firstName
  lastName
  file
  notifications = []
  dcCenterUserId
  hai
  age; gender
  profileImage; notifyToView; p2 = 1;
  notificationLength; nonViewData; ViewedNotifications
  constructor(private globalStorage: GlobalStorageService, private service: DiagnosticService, private formBuilder: FormBuilder, private sanitizer: DomSanitizer, private router: Router,
    private toaster: ToastrService, private observable: ObservableService, private getProfile: DiagnosticServiceController) {

  }

  ngOnInit(): void {

    var data = this.globalStorage.getItem('diagnosticData')
    var obj = JSON.parse(data)
    this.dcCenterUserId = obj['diagnosticCenterUserId']
    this.lastLogin = obj['lastLogin']
    this.getProfile.diagnosticGetPathSession('primarydetails?diagnosticcenteid=' + obj['diagnosticCenterUserId']).subscribe(res => {
      this.firstName = res['data']['firstName']
      this.lastName = res['data']['lastName']
    })
    this.getProfilePic()
    this.loadNotifications()
    this.getPersonalDetails()
  }

  onFileInput(files: File) {
    const f = files
    if ((f[0].type) == "image/jpeg" || (f[0].type) == "image/png") {
      if (f[0].size > 1048769) {
        this.toaster.warning('file should be 1 Mb')
      } else {
        this.file = files
      }
    } else {
      this.toaster.warning('file should be in jpeg or PNG')
    }
    this.updateProfilePic()
  }

  getProfilePic() {
    let addressId = this.globalStorage.getItem('diagnosticCenterAddressId');
    this.getProfile.diagnosticGetPathSession('id/' + addressId).subscribe(res => {
      if (res['data'] != null) {
        var src = "data:image/jpeg;base64," + res['data']?.['profilePic']
        this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(src);
      }
      else {
        // this.imageSource='./assets/img/profile-icon.jpg'
      }
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  updateProfilePic() {
    let dcCenterID = parseInt(this.globalStorage.getItem('diagnosticCenterId'));
    var formData = new FormData();
    for (let i = 0; i < this.file.length; i++) {
      formData.append('profilePic', this.file[i], this.file[i].name);
    }
    this.getProfile.updateProfile('profilepic/', dcCenterID, formData).subscribe(res => {
      this.toaster.success('Profile pic uploaded')
      this.getProfilePic();
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }
  getPersonalDetails() {
    this.getProfile.getPersonalDetails(this.dcCenterUserId).subscribe(res => {
      if (res['data'] != null) {
        var d1 = moment(res['data'].dob);
        var d2 = moment(new Date());
        var days = moment.duration(d2.diff(d1)).asDays();
        var years = days / 360
        this.age = years.toFixed(0);
        this.gender = res['data'].gender
      }
      else {
        this.router.navigate(['/dcPersonalDetails']);
      }

    })
  }
  p1 = 1;
  loadNotifications() {
    this.getProfile.diagnosticGetPathSession('notificationalerts/get/all?diagnoticCenterAddressId=' + this.globalStorage.getItem('diagnosticCenterAddressId') + '&maxResult=100&pageNumber=' + this.p1).subscribe(res => {
      this.notifications = res['data'];
      this.notificationLength = this.notifications.filter((obj) => obj.viewed == 'N').length;
      this.nonViewData = this.notifications.filter((obj) => obj.viewed == 'N');
      this.ViewedNotifications = this.notifications.filter((obj) => obj.viewed == 'Y');
    }, error => {
      this.toaster.error(error?.['error']?.['message']);
    })
  }

  updateNotify(data) {
    this.notifyToView = data;
    $('#notificationToViewSidbar').modal('show');
    var req = {
      "active": data.active,
      "diagnosticcenternotifiactionalertsid": data.diagnosticcenternotifiactionalertsid,
      "diagnosticCenterAddressId": data.diagnosticCenterAddressId,
      "objectkey": data.objectkey,
      "objecttype": data.objecttype,
      "doctorId": data.doctorId,
      "reason": data.reason,
      "viewed": "Y"
    }

    this.getProfile.updateNotifications(data.diagnosticcenternotifiactionalertsid, req).subscribe(res => {
      this.notificationLength = this.notificationLength - 1
      this.loadNotifications();
    }, error => {
      this.toaster.error(error?.['error']?.['message']);
    })
  }

}
