<div class="card  doctor-right-bg  pb-4 no-mobile">

  <div class="card m-0 border_bgcolor2 pt-0 no-mobile">
    <div class="position-absolute card-top-buttons">
      <button class="btn btn-header-white text-white icon-button p-3 mr-3 mt-2">
        <i class="fa fa-bell fa-lg faa-shake animated" aria-hidden="true" data-toggle="modal"
          data-target="#viewremindersDoctor"></i>
      </button>
      <span class="count"> {{notificationLength}}</span>
    </div>
    <div class="card-body  ">
      <div class="  d-flex flex-row ml-2 mr-2 mb-o mt-0 ">
        <a class="d-flex pt-2 profile-image" href="javascript:void(0)" (click)="fileInput.click()" data-toggle="tooltip"
          data-original-title="Upload Profile pic">
          <input #fileInput type="file" (change)="onFileInput($event.target.files)" style="display:none;" />
          <img alt="Profile" [src]="imgURL" style="border-radius: 50%;background: none;"
            class="img-thumbnail border-0 m-1 list-thumbnail align-self-center xs-small">
          <div class="profile-overlay">
            <a href="javascript:void(0)" class="profile-icon">
              <i class="fa fa-image"></i>
            </a>
          </div>
        </a>
        <h5 class="card-title border-0 mb-0 pb-2 mt-0 text-white">Dr. {{profileDetails?.firstName | titlecase }}
          {{profileDetails?.lastName | titlecase}} <br>
          <span class="text-white text-small pt-2"><span class="eclips"
              style="float: left; margin-top: 3px; ">{{profileDetails?.specialization}} - {{Age}} years - {{profileDetails?.gender | titlecase}}</span>
              </span>
        </h5>
      </div>


    </div>
  </div>

  <div class="card pb-1 mt-2 mb-2 no-mobile">
    <div class="card-body" style="min-height: 152px;" (click)="navigateToAssociation()">
      <h5 class="card-title"> <i class="fa fa-users themecol2 icon-head" aria-hidden="true"></i>
        My Associations</h5>
      <button class="btn  icon-button1" data-placement="top" data-toggle="tooltip" data-original-title="View">
        <a href="javascript:void(0)"><i class="fa fa-eye themecol1" aria-hidden="true"></i></a>
      </button>
      <div *ngIf="associationList?.length==0" class="no-sm-associate" style="min-height: 112px;"></div>

      <div *ngIf="associationList?.length>0">
        <div *ngFor="let accociation of associationList | slice:0:2">

          <div class="d-flex flex-row mb-0 p-1   ">

            <a class="btn btn-sm btn-light-secondery  mr-2 mt-2 p-2 nav-btns">
              <img src="assets/img/asociat1.svg" width="20" /> </a>

            <a *ngIf="accociation.type=='Appointment'" class="btn btn-sm btn-light-secondery  mr-2 mt-2 p-2 nav-btns">
              <img src="assets/img/asociat2.svg" width="20" /> </a>

            <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
              <span href="javascript:void(0)">
                <p class="font-weight-medium mb-0 mt-2">{{accociation.entiyName}}</p>
                <p class="text-muted mb-1 text-small">{{accociation?.hospitaladdress === null ? '-' :
                  accociation.hospitaladdress?.address1 + ', ' + accociation.hospitaladdress?.address2}} </p>
              </span>

            </div>


          </div>


        </div>
      </div>



    </div>
  </div>




  <div class="card d-min-height mb-3  no-mobile">
    <div class="position-absolute card-top-buttons">
      <button class="btn  icon-button" data-placement="top" data-toggle="tooltip" data-original-title="View">
        <i class="fa fa-eye themecol1" aria-hidden="true" routerLink="/assigned-Appoinments"></i>
      </button>
    </div>
    <div class="card-body ">
      <h5 class="card-title3"> <i class="fa fa-calendar-day themecol3 mr-2"></i>Assigned Patients</h5>
      <div *ngIf="assignedPatientList?.length==0">
        <div class="no-appointments"></div>
      </div>
      <div *ngFor="let item of assignedPatientList?.slice(0,4); let index=index">
        <div class="card m-2 d-flex flex-row  p-1 border-bottom  blt-1  ">
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
            <a href="javascript:void(0)" (click)="viewCurrentAppontment(item)">
              <p class="font-weight-medium mb-1 mt-1">{{item.patientName}}
              </p>
              <p class="text-muted mb-1 text-small">{{item.lastUpdatedTs | date:'dd-MMM-yyyy'}} </p>
            </a>
          </div>
          <div class=" float-right ">

          </div>
        </div>
      </div>
    </div>
    <!-- <div class="card-body">
      <h5 class="card-title"> <i class="fa fa-star-half-alt themecol3 icon-head" aria-hidden="true"></i>
        My Ratings</h5>

      <div class="d-flex flex-row mb-1 p-1">
        <a class="btn btn-sm btn-light-secondery  mr-2 mt-2 mb-1 p-1 nav-btns">
          <img src="assets/img/asociat4.png" width="20" /> </a>
        <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
          <span>
            <a href="javascript:void(0)" (click)="reviewOpen()" class="font-weight-medium mb-0 mt-2">Reviews
              <span class=" checked fs-10">{{rating}}</span>
              <span class="fa fa-star checked fs-10" *ngFor='let in of array ;let i = index'></span>

            </a>
            <div>
              <a href="javascript:void(0)" (click)="reviewOpen()" class="text-muted mb-1 text-small">Based on
                {{this.a.length}} reviews</a>
            </div>

          </span>
        </div>
      </div>


      <div class="d-flex flex-row mb-1 p-1" (click)="openPayment()">
        <a>
          <img src="assets/img/asociat8.png" /> </a>
        <div class="pl-2 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
          <a>
            <a href="javascript:void(0)" routerLink="/Payments" class="font-weight-medium mb-0 mt-0">Payments
            </a>
            <div>
              <a href="javascript:void(0)" routerLink="/Payments" class="text-muted mb-1 text-small">Review your
                Payments </a>
            </div>

          </a>
        </div>
      </div>

      <div class="d-flex flex-row mb-1 p-1" (click)="openAllAppointments()">
        <a>
          <img src="assets/img/asociat17.png" /> </a>
        <div class="pl-2 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
          <a>
            <a href="javascript:void(0)" routerLink="/Appoinments" class="font-weight-medium mb-0 mt-0">Appointments
            </a>
            <div>
              <a href="javascript:void(0)" routerLink="/Appoinments" class="text-muted mb-1 text-small">Reviews on
                Appointments </a>
            </div>

          </a>
        </div>
      </div>


    </div> -->
  </div>


  <div class="card min-height  no-mobile">
    <div class="position-absolute card-top-buttons">
      <button class="btn btn-header-light icon-button">
        <i class="fa fa-ellipsis-h" aria-hidden="true" data-toggle="modal" data-target="#viewremindersDoctor"></i>
      </button>
    </div>
    <div class="card-body" style="min-height: 364px;">
      <h5 class="card-title"> <i class="fa fa-bell themecol1 icon-head" aria-hidden="true"></i>
        Notifications</h5>



      <div *ngIf="notViewedNotifications?.length==0" class="no-notifications" style="min-height: 325px;"></div>
      <div *ngIf="notViewedNotifications?.length>0">
        <div class="d-flex flex-row mb-1 p-2 border-bottom  " *ngFor="let notif of notViewedNotifications.slice(0,5)"
          [ngClass]="{' blt-3': notif.viewed == 'Y'}" (click)="updateNotify(notif)">

          <a href="javascript:void(0)" class="btn btn-sm btn-light-secondery  mr-2 mt-1 p-2 nav-btns">
            <i *ngIf="notif.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3" aria-hidden="true"></i>
            <i *ngIf="notif.viewed != 'Y'" class="fa fa-calendar-day icon-head" aria-hidden="true"></i> </a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
            <a href="javascript:void(0)" id="notifyId">
              <p class="font-weight-medium mb-0 mt-1">{{notif.objecttype |titlecase}}
              </p>
              <p class="text-muted mb-1 text-small eclips">{{notif.reason}}</p>
            </a>

          </div>


        </div>
      </div>
      <div class="modal fade modal-right" id="viewremindersDoctor"  role="dialog" data-backdrop="static" data-keyboard="false"
      aria-labelledby="exampleModalRight">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Notifications</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- <app-view-doctor-remainders></app-view-doctor-remainders> -->
          <div class="row">
            <div class="col-lg-12 mt-3 text_right">
              <ul class="pagination">
                <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event">
                </pagination-controls>
              </ul>
            </div>
          </div>
          <div class="row d-flex  ml-2">
            <div *ngIf="notViewedNotifications?.length==0" class="no-notifications"></div>
            <div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left  blt-2 mb-2 mt-1 "
              *ngFor="let reports of notViewedNotifications |paginate: { id: 'p2', itemsPerPage: 6, currentPage: p2 }"
              [ngClass]="{' blt-3': reports.viewed == 'Y'}" (click)="updateNotify(reports)">

              <a href="javascript:void(0)" class="p-2 icon-appointments  ">
                <i *ngIf="reports.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3" aria-hidden="true"></i>
                <i *ngIf="reports.viewed != 'Y'" class="fa fa-bell fa-lg pt-2  icon-col-3" aria-hidden="true"></i>
              </a>
              <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
                <a href="javascript:void(0)">
                  <p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
                  </p>
                  <p class="text-muted mb-1 text-small">{{reports.reason}}</p>
                </a>

              </div>

              <div class="btn-group float-right">
                <button class="btn btn-xs btn0col1" data-toggle="modal" data-backdrop="static" data-target="#viewpdf"><i
                    class="fa fa-eye" aria-hidden="true"></i></button>
              </div>



            </div>
          </div>

        </div>
      </div>
    </div>

      <div class="modal fade center" id="notificationViewer123"  role="dialog" data-backdrop="static" data-keyboard="false"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document"
          style="min-width:300px !important;height: 50% !important;margin-left: 39%;">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Notification Viewer</h5>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="block inv-section">
                <div class="">
                  <div class="text-center">
                    <img alt="" src="./assets/img/logo.png" style="border-radius: none !important;">
                  </div>
                </div>
                <div class="text-center">
                  <p class="font-weight-medium mb-0 mt-1">{{notifyToView?.objecttype |titlecase}}
                  </p>
                  <p class="text-muted mb-1 text-small">{{notifyToView?.reason}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>




    </div>
  </div>





</div>






<div class="modal fade" id="notificationToView"  role="dialog" data-backdrop="static" data-keyboard="false"
aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document"
  style="min-width:300px !important;height: 50% !important;">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">Notification Viewer</h5>

      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="block inv-section">
        <div class="mb-2 text-center">
          <img alt="Logo" src="./assets/img/logo.png" style="border-radius: none !important;">
        </div>
        <div class="text-center">
          <p class="font-weight-medium mb-1 mt-1">{{notifyToView?.objecttype | titlecase}}
          </p>
          <p class="text-muted mb-1 text-small">{{notifyToView?.reason}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<div class="modal fade modal-right" id="viewRatings"  role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Feedback</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <app-view-ratings class="modal-body"></app-view-ratings>



    </div>
  </div>
</div>
