<div id="participant-list">
    <div id="alone" [ngClass]="{ 'table': isAlone, 'd-none': !isAlone }">
        <p class="text-center text-monospace h3" style="display: table-cell">
            You're the only one in this room. <i class="far fa-frown"></i>
            <br />
            <br />
            As others join, they'll start showing up here...
        </p>
    </div>
    <div [ngClass]="{ 'd-none': isAlone }">

        <div #list></div>
    </div>
    <div class="video-bottombar">
        <div class="text-center w-100 float-left p-2">
            <!-- <button  class="btn btn-sm  p-2 nav-btns  mr-2 float-left" (click)="clear()"  ><i class="fa fa-times icon-head pt_4  "></i></button> -->
            <a href="javascript:void(0)" class="btn btn-sm  p-2 nav-btns  mr-2 mute" (click)="muteUnmute($event)"> <i class="fa fa-microphone-slash icon-head pt_4  "></i></a>
            <a href="javascript:void(0)" class="btn btn-sm  p-2 nav-btns  mr-2 videoPause" (click)="videoPassed($event)"> <i class="fa fa-video-slash icon-head pt_4  "></i></a>
            <a class="btn btn-sm  p-2 nav-btns  " (click)="onLeaveRoom()" > <i class="fa fa-phone-hangup icon-head pt_4  "></i></a>
            <a href="javascript:void(0)" class="btn btn-sm  p-2 nav-btns  float-right" ><i class="fa fa-expand-arrows-alt icon-head pt_4  "></i></a>

        </div>







    </div>
</div>
<script>
    (function() {

  const video = document.querySelector('#basic video');
  const audio = document.querySelector('#basic audio');

  const captureVideoButton = document.querySelector('#basic .capture-button');
  const stopVideoButton = document.querySelector('#basic #stop-button');

  //Capture Video
  captureVideoButton.onclick = function() {
     navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true
    })
    .then(stream => {
      window.localStream = stream;
      video.srcObject = stream;
      audio.srcObject = stream;
    })
    .catch((err) => {
    });
  };

  stopVideoButton.onclick = function() {
    localStream.getVideoTracks()[0].stop();
    video.src = '';

    localStream.getAudioTracks()[0].stop();
    audio.src = '';
  };
})();
</script>
