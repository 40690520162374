<div class="modal-header">
    <h4 class="modal-title">Appointment Booking Confirmation</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body" style="max-height: 282px;overflow-y: hidden;">

    <div class="schedule-tab">
        <div class="tab-content">

            <div class="tab-pane active" id="tab_start">
                <div class=" ">
                    <div class="row backgound_imgsidebar mb-3 pt-2 pb-2 pr-2">
                        <div class="col-md-9 col-sm-12">

                            <div class="d-flex flex-row  ">
                                <a class="" href="javascript:void(0)"><img src="./assets/img/profile.png"></a>
                                <div class="pl-1  d-flex flex-column flex-grow-1  ">
                                    <a href="javascript:void(0)">
                                        <p *ngIf="!isDc" class="font-weight-medium mb-0 mt-2">
                                            Dr.{{bookingDetails?.doctorName |titlecase}} </p>
                                        <p *ngIf="isDc" class="font-weight-medium mb-0 mt-2">
                                             {{bookingDetails?.doctorName |titlecase}} </p>
                                        <p class="text-muted mb-1 text-small mr-2">
                                            {{bookingDetails?.specializ}}</p>
                                        <p class="text-muted mb-1 text-small mr-2" *ngIf="!isDc">
                                            <!-- <span class="fa fa-star checked fs-8"
                                                *ngFor="let rate of bookingDetails?.aRating| demoNumber"></span>({{bookingDetails?.aRating}}) -->
                                             {{bookingDetails?.ttlExperience}} Years
                                        </p>
                                    </a>
                                </div>
                                <div class="col-md-3 col-sm-4">
                                    <div class="row mt-2">

                                        <div class="btn btn-sm mr-1 mt-2 p-1">
                                            <a href="javascript:void(0)" *ngIf="!isDc"
                                                class="person  btn btn-sm colr-5 ml-2 mt-2 p-2 profile-btns mm_40 fs-23"
                                                style="cursor: default;">
                                                <i class="fa-solid fa-hospital-user color_green" aria-hidden="true"></i> </a>
                                        </div>
                                        <!-- <div class="col-sm-6 text_center"> <a><img src="./assets/img/video_01.png"> </a>
                          </div> -->

                                    </div>
                                </div>
                            </div>


                            <div class="row text_left padding_left20 padding_bt p-3">
                                <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                                    <div class="row">
                                        <div class="col-md-6">Purpose :</div> <span class="col-md-6">
                                            {{bookingDetails?.reason}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                                    <div class="row">
                                        <div class="col-md-6">Appointment Date :</div> <span class="col-md-6">
                                            {{bookingDetails?.selectedItem |
                                            date:'dd-MMM-yyyy'}}</span>
                                    </div>
                                </div>
                                <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                                    <div class="row">
                                        <div class="col-md-6">Appointment Time :</div> <span class="col-md-6">
                                            {{bookingDetails?.fromTime |
                                            convertFrom24To12Format}}</span>
                                    </div>
                                </div>

                                <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                                    <div class="row">
                                        <div class="col-md-6">Amount Paid :</div>
                                        <span class="col-md-6">
                                            <span class="inr"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;
                                            </span>{{bookingDetails?.advanceAmount}}
                                        </span>
                                    </div>
                                </div>

                                <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                                    <div class="row">
                                        <div class="col-md-6">Payment Mode :</div>
                                        <span class="col-md-6">
                                            <!-- <span class="inr"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;
                                            </span> -->
                                            {{bookingDetails?.paymentMode}}
                                        </span>
                                    </div>
                                </div>

                                <div *ngIf="bookingDetails?.dueAmount>0"
                                    class="col-md-12 col-sm-12 textbig_14white pb-1">
                                    <div class="row">
                                        <div class="col-md-6">Amount Due:</div>
                                        <span class="col-md-6">
                                            <span class="inr"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;
                                            </span>{{bookingDetails?.dueAmount}}
                                        </span>
                                    </div>
                                </div>


                            </div>
                        </div>


                    </div>

                </div>

            </div>

        </div>

    </div>

</div>
<div class="modal-footer  d-flex justify-content-center">
    <button type="button" class="btn btn-secondary btn-xs float-right" data-dismiss="modal"
        (click)="activeModal.close()">
        Close
    </button>
    <button type="button" *ngIf="bookingDetails?.appointmentId" (click)="downloadAsPDF()"
        class="btn btn-secondary btn-xs float-right" data-dismiss="modal">
        Download Invoice
    </button>
</div>