<main class="l-main">
   <div class="content-wrapper content-wrapper--with-bg"> 
   <div class="container-fluid rounded-sm ">
   <div class="row">
  

      <div id="top_nav" class="home">
         <div *ngIf="!isAssociated">
            <div *ngIf="isErrorMessage" class="err">{{errorMessage}}</div>
            <app-nav-bar [notificationsData]="reminders" [personalDetails]="personalData" [associationUsersList]="associationUsersList" [item]="associatedUserData"></app-nav-bar>
         </div>
      <router-outlet></router-outlet>
      </div>
      <!-- <div class="home col-lg-12 col-sm-12 " *ngIf="!isHome">
         <div *ngIf="!isAssociated">
            <div *ngIf="isErrorMessage" class="err">{{errorMessage}}</div>
            <app-nav-bar [notificationsData]="reminders" [personalDetails]="personalData" [associationUsersList]="associationUsersList" [item]="associatedUserData"></app-nav-bar>
         </div>
      <router-outlet></router-outlet>
      </div> -->

      <div class="col-lg-4 col-md-4" *ngIf="isHome">
         <app-sidebar [notificationsData]="reminders" [item]="associatedUserData" [personalDetails]="personalData"></app-sidebar>
       </div> 
</div> 
</div>
</div>
</main>

<app-footer></app-footer>