<div class="card patient-right-bg pb-3">
  <div class="card ml-0 mr-0 mt-0 mb-2 ph-profile-widget no-mobile">
    <div class="position-absolute card-top-buttons">
      <button class="btn btn-header-white text-white icon-button p-3 mr-3 mt-2" data-toggle="modal" data-target="#viewreminders">
        <i class="fa fa-bell fa-lg pt-2 faa-shake animated" aria-hidden="true"></i>
      </button>
      <span class="count"> {{notificationLength}}</span>
    </div>
    <div class="card-body no-mobile">
      <div class="d-flex flex-row ml-2 mr-2 mb-1 mt-0">
        <!-- <a class="d-flex" href="javascript:void(0)" >
    <img alt="Profile" src="./assets/img/profile-icon.jpg" class="img-thumbnail border-0 m-1 list-thumbnail align-self-center xs-small">
    </a> -->
        <a class="d-flex pt-2 profile-image" href="javascript:void(0)" (click)="fileInput.click()" data-toggle="tooltip" data-original-title="Upload Profile pic">
          <input #fileInput type="file" (change)="onFileInput($event.target.files)" style="display:none;" />
          <img alt="Profile" *ngIf="(imageSource == null && gender == 'Male') || (imageSource == null && gender == 'male')" src="./assets/img/img_avatar.png" class="img-thumbnail border-0  p-0 list-thumbnail align-self-center xs-small male" style="border-radius: 50%;background: none;">
          <img alt="Profile" *ngIf="(imageSource == null && gender == 'Female') || (imageSource == null && gender == 'female')" src="./assets/img/user/11.png" class="img-thumbnail border-0  p-0 list-thumbnail align-self-center xs-small female" style="border-radius: 50%;background: none;">
          <img alt="Profile" *ngIf="imageSource != null" [src]="imageSource" class="img-thumbnail border-0  p-0 list-thumbnail align-self-center xs-small null" style="border-radius: 50%;background: none;">
          <div class="profile-overlay">
            <a href="javascript:void(0)" class="profile-icon">
              <i class="fa fa-image"></i>
            </a>
          </div>
        </a>
        <h5 class="card-title border-0 mb-0 pb-2 mt-0">{{firstName |titlecase}} {{lastName|titlecase}}<br>
          <span class=" text-small pt-2"> {{age}} years - {{gender}}</span>
        </h5>
      </div>
    </div>
  </div>
  <div class="pt-0">
    <div class="row ">
      <div class="col-lg-6 col-md-4 col-xs-6 mb-2">
        <a class="card detail-box1 details-box pull-up" routerLink="/total-medicines">
          <div class="card-body p-2">
            <div class="dash-contetnt">
              <div class="mb-1"><span class=""><i class="fa fa-tablets mr-1 font-size-24"></i></span></div>
              <h4 class="font-size-14 mb-1">Total Medicine - {{allCounts?.totalDrugs}}</h4>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-6">
        <a class="card detail-box5  details-box pull-up" routerLink="/inactive-medicines">
          <div class="card-body p-2">
            <div class="dash-contetnt">
              <div class="mb-1"><span class=""><i class="fa fa-pills mr-1 font-size-24"></i></span></div>
              <h4 class="font-size-14 mb-1">InActive Medicine - {{allCounts?.totalInactive}}</h4>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-6">
        <a class="card detail-box4 details-box pull-up" routerLink="/outofstock-medicines">
          <div class="card-body p-2">
            <div class="dash-contetnt">
              <div class="mb-1"><span class=""><i class="fa fa-info-circle mr-1 font-size-24"></i></span></div>
              <h4 class="font-size-14">Out of Stock - {{allCounts?.totaloutofstock}}</h4>
            </div>
          </div>
        </a>
      </div>
      <div class="col-lg-6 col-md-6 col-xs-6">
        <a class="card detail-box6 details-box pull-up" routerLink="/expired-medicines">
          <div class="card-body p-2">
            <div class="dash-contetnt">
              <div class="mb-1"><span class=""><i class="fa fa-history mr-1 font-size-24"></i></span></div>
              <h4 class="font-size-14">Expired Medicine - {{allCounts?.totalexpdate}}</h4>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <div class="card notification-height">
      <div class="card-body">
        <h5 class="card-title"> <i class="fa fa-bell themecol1 icon-head" aria-hidden="true"></i> Notifications</h5>
        <div *ngIf="notifications?.length == 0">
          <div class="no-notifications"></div>
        </div>
        <div class="d-flex flex-row mb-0 p-2 border-bottom" *ngFor="let notif of notifications ?.slice(0,4)">
          <a href="javascript:void(0)" class="btn btn-sm btn-light-secondery  mr-2 mt-2 p-2 nav-btns">
            <img src="./assets/img/asociat1.svg" width="30"> </a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2">
            <!-- <a href="javascript:void(0)">
              <p class="font-weight-medium mb-0 mt-2">{{notif.objecttype}}
              </p>
              <p class="text-muted mb-1 text-small d-inline-block text-truncate text-nowrap  overflow-hidden" style="text-overflow: ellipsis;width: 325px;">{{notif.reason}}</p>
            </a> -->
            <a href="javascript:void(0)" *ngIf="notif.objecttype=='Payment Done by Patient'"
              (click)="navigate('/payments')">
              <p class="font-weight-medium mb-1 mt-1">{{ notif.objecttype }}
              </p>
              <p class="text-muted mb-1 text-small">{{notif.reason}}</p>
            </a>
            <a href="javascript:void(0)" *ngIf="notif.objecttype!='Payment Done by Patient'"
              (click)="navigate('/pharmaPrescriptions')">
              <p class="font-weight-medium mb-1 mt-1">{{ notif.objecttype }}
              </p>
              <p class="text-muted mb-1 text-small">{{notif.reason}}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade modal-right" id="viewreminders" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notifications</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="row">
        <div class="col-lg-12 mt-3 text_right">
          <ul class="pagination">
            <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
          </ul>
        </div>
      </div>
      <div class="row d-flex ml-2">
        <div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left blt-2 mb-2 mt-1" *ngFor="let reports of notifications | paginate: { id: 'p2', itemsPerPage: 7, currentPage: p2 }" [ngClass]="{' blt-3': reports.viewed == 'Y'}" (click)="updateNotify(reports)">
          <a href="javascript:void(0)" class="p-2 icon-appointments">
            <i *ngIf="reports.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3" aria-hidden="true"></i>
            <i *ngIf="reports.viewed != 'Y'" class="fa fa-bell fa-lg pt-2  icon-col-3" aria-hidden="true"></i>
          </a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2">
            <a href="javascript:void(0)">
              <p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
              </p>
              <p class="text-muted mb-1 text-small">{{reports.reason}}</p>
            </a>
          </div>
          <div class="btn-group float-right">
            <!-- <button class="btn btn-xs btn0col1"><i class="fa fa-eye" aria-hidden="true"></i></button> -->
          </div>
        </div>
      </div>
      <!-- <app-view-remainders-pharmacy></app-view-remainders-pharmacy> -->
    </div>
  </div>
</div>
