
      <div class="modal-header">
        <h5 class="modal-title ">Add Prescription</h5>
        <div class="position-absolute card-top-buttons">
          <div class="w-100 btn-group form-group mt-2">


            <!-- <button type="submit" class=" btn btn-success btn-xs" (click)="OnSubmit(documentGrp.value)"><i
        class="fa fa-file-export"></i>
      Save Prescription
    </button> -->

          </div>
        </div>
        <button type="button" class="close"  aria-label="Close" (click)="activeModal.close('')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="p-2 ">

          <div class="row">



            <div class="col-md-12">
              <div class="block inv-section p-2">
                <div class="top-inv-col">
                  <div class="inv-logo"><img alt="" src="./assets/img/logo.png"></div>
                  <div class="float-right text-left order-details">
                    <!-- <p>Prescripiton ID: <span>73</span></p> -->
                    <p>Date: <span>{{patientPersonalDetails?.AppointmentDetails?.appointmentDate |
                        date:'dd-MMM-yyyy'}}</span></p>
                  </div>
                </div>
                <!-- <div class="row">
                  <div class="col-md-6 col-xs-6">
                    <h5>Prescribed by</h5>
                    <ul class="inv-receiver">
                      <li class="">Dr {{loginUser?.firstName}} {{loginUser?.lastName}} <br>{{dstreet1}} {{dstreet2}}
                        {{dcity}} <br>{{dstate}} {{dcountry}}<br> {{dzip}} </li>
                    </ul>
                  </div>
                  <div class="col-md-6 col-xs-6 text-right">
                    <h5>Prescribed to</h5>
                    <ul class="inv-receiver">
                      <li class="">{{patientName}} <br>{{pstreet1}} {{pstreet2}} {{pcity}} <br>{{pstate}}
                        {{pcountry}}<br>{{pzip}} </li>
                    </ul>
                  </div>

                </div> -->

                <div class="card no-bg">




                  <h3 class="mt-0 p-2 border-bottom font-size-12 bg-primary-light"><i class="fas fa-upload "></i> Upload
                    Prescripiton</h3>

                  <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-xs-6 form-group mb-3">
                        <mat-checkbox [(ngModel)]="isMedChecked">Medical Prescripiton</mat-checkbox>
                     </div>
                     <div class="col-xl-6 col-lg-6 col-md-6 col-xs-6 form-group mb-3">
                        <mat-checkbox [(ngModel)]="isLabChecked">Lab Prescripiton</mat-checkbox>
                     </div>
                    <div class="col-md-4">

                      <div class="form-group inputDnD">
                        <label class="sr-only" for="inputFile">File Upload</label>

                        <input type="file" #fileInput (change)="onFileInput($event.target.files)"
                          class="form-control-file text-primary " id="inputFile" accept="image/*"
                          onchange="readUrl(this)" data-title='Prescripiton'>
                      </div>
                    </div>

                    <!-- <div class="col-md-4">
                      <div class="form-group inputDnD">
                        <label class="sr-only" for="inputFile">File Upload</label>
                        <input type="file" #fileInput (change)="onFileInput($event.target.files,'Test Prescription')"
                          class="form-control-file text-primary " id="inputFile" accept="image/*"
                          onchange="readUrl(this)" data-title="Test Prescription">
                      </div>

                    </div>

                    <div class="col-md-4">

                      <div class="form-group inputDnD">
                        <label class="sr-only" for="inputFile">File Upload</label>
                        <input type="file" #fileInput
                          (change)="onFileInput($event.target.files,'Medical & Test Prescripiton')"
                          class="form-control-file text-primary " id="inputFile" accept="image/*"
                          onchange="readUrl(this)" data-title="Medical & Test Prescripiton">
                      </div>
                    </div> -->

                  </div>




                </div>


                <div class=" text-right">
                  <button class="btn btn-success btn-xs mr-2" (click)="savePrescriptionImage()"><i
                      class="fas fa-save"></i> Save</button>
                  <!-- <button class="btn btn-primary btn-xs mr-2"><i class="fas fa-eye"></i> Preview</button> -->
                  <button class="btn btn-danger btn-xs" (click)="activeModal.close()"><i class="fas fa-times"></i> Cancel</button>
                </div>




              </div>
            </div>














          </div>








        </div>
      </div>
