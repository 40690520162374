import { GlobalStorageService } from 'src/app/global-storage.service';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PatientScheduleControlService } from '../shared/services/patientScedulecontrol.service';
import { debounceTime, distinctUntilChanged, switchMap, catchError, tap, map, startWith } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { gpsService } from 'src/app/shared/services/gps.service';
import { PatientService } from '../shared/services/patient.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-web-appointment',
  templateUrl: './web-appointment.component.html',
  styleUrls: ['./web-appointment.component.css']
})
export class WebAppointmentComponent implements OnInit {
  @Input() docType
  doctors; caretakersList; hospitalList; diagnosticList; caretakersOn = false; diagnostic = false; doctorsOn = true; p2 = 1; specializationSelected; nutritionSelected
  totalDoctors; nutritionists; nutritionistOn = false; diagnosticOn = false; hospitalOn = false; doctorSelected; doctorsDetails2 = []; pincodeEntered;
  countryCodes = [{ 'id': 1, 'value': 'IN' }, { 'id': 2, 'value': 'US' }]
  myControl = new FormControl(''); ctName;
  preferredList; ngSelectedType; latitude; longitude; pincode; checked;
  filteredOptionsS: Observable<string[]>;
  filteredOptionsD: Observable<string[]>;
  filteredOptionsN: Observable<string[]>;
  filteredOptionsC: Observable<string[]>;
  totalList; doctorsName = []; searchText; special; ImageSrc;
  optionsS = [];
  optionsD = [];
  optionsN = [];
  optionsC = [];
  isCurrent: boolean = false;
  isPreferredList = true;
  isCentersList = true;
  baseUrl = environment.patientBaseUrl
  doctorUrl = environment.doctorBaseUrl
  diagnosticUrl = environment.diagnosticBaseUrl
  pharmaUrl = environment.pharmaUrl
  caretakerUrl = environment.caretakerUrl
  elasticSearchUrl = environment.elesticSearchUrl
  utilitiesUrl = environment.utilitiesUrl
  hospitalUrl = environment.hospitalUrl

  constructor(private globalStorage: GlobalStorageService, private httpClient: HttpClient, private router: Router, private sanitizer: DomSanitizer, private Toaster: ToastrService,
    private patientServices: PatientScheduleControlService, private gps: gpsService, private patient: PatientService,) { }

  ngOnInit(): void {
    this.getDoctorsList()
    this.caretakersOn = false
    this.filteredOptionsS = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterS(value || '')),
    );
    this.filteredOptionsD = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterD(value || '')),
    );
    this.filteredOptionsC = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterC(value || '')),
    );
    this.filteredOptionsN = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterN(value || '')),
    );
    this.filteredOptionsD = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterD(value || '')),
    );
    this.filteredOptionsN = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterN(value || '')),
    );
  }
  private _filterS(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsS.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterC(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsC.filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filterD(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsD.filter(option => option.toLowerCase().includes(filterValue));
  }


  private _filterN(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.optionsN.filter(option => option.toLowerCase().includes(filterValue));
  }
  alphaCharsOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))
      return true;
    else
      return false;

  }
  alphaCharsOnly1(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))
      return true;
    else
      return false;
  }

  alphaCharsOnly2(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))
      return true;
    else
      return false;
  }
  alphaCharsOnly3(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))
      return true;
    else
      return false;
  }
  removeDuplicateItems(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  searchDoctorByName(doctor, event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
      this.patientServices.getDoctorBySearch(doctor).subscribe(res => {
        this.optionsD = [];

        for (var sp of res['data']) {
          if (sp.type == "DOCTOR") {
            var fullName = sp.firstName + " " + sp.lastName;
            this.optionsD.push(fullName);
          }
        }

        this.optionsD = this.removeDuplicateItems(this.optionsD);

        this.filteredOptionsD = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterD(value || '')),
        );
      });

    }
    else if (doctor.length == 0 && charCode === 8) {
      this.getAllDoctors();
    }

  }
  getAllDoctors() {
    this.doctorsDetails2.length = 0
    this.doctors = []
    var body = {
      "doctorName": this.searchText,
      "specializations": this.special
    }

    this.patientServices.getDoctorsList('all/search/critieria', body).subscribe(res => {
      for (var i = 0; i < res['data'].length; i++) {
        var doctor = {
          active: res['data'][i]['active'],
          name: res['data'][i]['firstName'] + ' ' + res['data'][i]['lastName'],
          firstName: res['data'][i]['firstName'],
          lastName: res['data'][i]['lastName'],
          specialization: res['data'][i]['specialization'],
          doctorId: res['data'][i]['doctorId'],
          rating: res['data'][i]['averagerating'],
          averagerating: res['data'][i]['averagerating'],
          totalExperience: res['data'][i]['totalExperience'],
          type: res['data'][i]['type'],
          profilePic: res['data']?.[i]?.['personaldetail']?.['profilePic'],
          gender: res['data']?.[i]?.['gender'],
          count: res['data']?.[i]?.['count'],
          feedetails: res['data'][i]?.['feedetails'],
          fee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Video')?.[0]?.fee,
          videoFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Video')?.[0]?.fee,
          audioFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Telephone')?.[0]?.fee,
          chatFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Chat')?.[0]?.fee,
          inPersonFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'In person')?.[0]?.fee,
        }
        this.doctorsName.push(doctor.name);
        if (doctor['profilePic'] != null) {
          this.ImageSrc = "data:image/jpeg;base64," + doctor['profilePic']
          doctor['profilePic'] = this.sanitizer.bypassSecurityTrustResourceUrl(this.ImageSrc);
        } else {
          if (doctor['gender'] == 'male') {
          }
          else {
          }
        }
        this.doctorsDetails2.push(doctor);
        this.doctors.push(doctor)
        this.totalList = this.doctorsDetails2
        if ((this.router.url == '/search-nutriotionist') || (this.router.url == '/caretaker-dashboard' && this.docType == 'NUTRITIONIST')) {
          this.doctorsDetails2 = this.doctorsDetails2.filter(obj => obj.type == "NUTRITIONIST")
          this.doctors = this.doctors.filter(obj => obj.type == "NUTRITIONIST")
        }
        if ((this.router.url == '/search-Doctor') || (this.router.url == '/caretaker-dashboard' && this.docType != 'NUTRITIONIST')) {
          this.doctorsDetails2 = this.doctorsDetails2.filter(obj => obj.type == "DOCTOR")
          this.doctors = this.doctors.filter(obj => obj.type == "DOCTOR")
          this.doctorsDetails2 = this.doctorsDetails2.sort((a, b) => {
            return a.rating - b.rating;
          }).reverse();
          this.doctors = this.doctors.sort((a, b) => {
            return a.rating - b.rating;
          }).reverse();
        }
      }

      this.filteredOptionsS = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterS(value || '')),
      );
    })
  }

  getDoctorsLists(name) {

    if (name === '' || name === undefined || name === null) {
      this.getAllDoctors();
    }
    else {
      this.patientServices.getDoctorByName(name).subscribe(res => {
        this.doctorsDetails2 = [];
        this.doctors = [];
        for (var i = 0; i < res['data'].length; i++) {
          if (res['data'][i]['doctorspecialization'] !== "NUTRITIONIST") {
            var doctor = {
              name: res['data'][i]['firstName'] + ' ' + res['data'][i]['lastName'],
              firstName: res['data'][i]['firstName'],
              lastName: res['data'][i]['lastName'],
              specialization: res['data'][i]['doctorspecialization'],
              doctorId: res['data'][i]['doctorId'],
              rating: res['data'][i]['averagerating'],
              averagerating: res['data'][i]['averagerating'],
              totalExperience: res['data'][i]['totalExperience'],
              type: res['data'][i]['type'],
              profilePic: res['data']?.[i]?.['personaldetail']?.['profilePic'],
              gender: res['data']?.[i]?.['gender'],
              count: res['data']?.[i]?.['count'],
              fee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Video')?.[0]?.fee,
              videoFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Video')?.[0]?.fee,
              audioFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Telephone')?.[0]?.fee,
              chatFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Chat')?.[0]?.fee,
              inPersonFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'In person')?.[0]?.fee,
              feedetails: res['data'][i]?.['feedetails']
            }
            this.doctorsDetails2.push(doctor);
            this.doctors.push(doctor);
          }
        }

        if (res['data'].length < 6) {
          this.p2 = 1
        }
      });

    }
  }
  getSpecializationsByName(specialization, event) {
    var specializations = [];
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
      this.patientServices.getDoctorBySpecilization(specialization).subscribe(res => {
        this.optionsS = [];

        for (var sp of res['data']) {
          if (sp.doctorspecialization !== "NUTRITIONIST") {
            specializations.push(sp.doctorspecialization);
            this.optionsS.push(sp.doctorspecialization);

            this.optionsS = this.removeDuplicateItems(this.optionsS);

            this.filteredOptionsS = this.myControl.valueChanges.pipe(
              startWith(''),
              map(value => this._filterS(value || '')),
            );
          }

        }
      });
    }
    else if (specialization.length == 0 && charCode === 8) {
      this.getAllDoctors();
    }
  }
  searchNutritionByName(nutritionist, event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
      this.patientServices.getDoctorBySearch(nutritionist).subscribe(res => {
        this.optionsN = [];
        for (var sp of res['data']) {
          if (sp.type == "NUTRITIONIST") {
            var fullName = sp.firstName + " " + sp.lastName;
            this.optionsD.push(fullName);
          }

          var fullName = sp.firstName + " " + sp.lastName;
          this.optionsN.push(fullName);
        }

        this.optionsN = this.removeDuplicateItems(this.optionsN);

        this.filteredOptionsN = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterN(value || '')),
        );
      });
    }
    else if (nutritionist.length == 0 && charCode == 8) {
      this.getAllDoctors();
    }
  }
  getNutritionistByName(nutriotionist) {
    this.patientServices.getDoctorByName(nutriotionist).subscribe(res => {
      this.doctorsDetails2 = [];
      this.nutritionists = []
      for (var i = 0; i < res['data'].length; i++) {
        if (res['data'][i]['doctorspecialization'] === "NUTRITIONIST") {
          var doctor = {
            name: res['data'][i]['firstName'] + ' ' + res['data'][i]['lastName'],
            specialization: res['data'][i]['doctorspecialization'],
            firstName: res['data'][i]['firstName'],
            lastName: res['data'][i]['lastName'],
            doctorId: res['data'][i]['doctorId'],
            rating: res['data'][i]['averagerating'],
            averagerating: res['data'][i]['averagerating'],
            totalExperience: res['data'][i]['totalExperience'],
            type: res['data'][i]['type'],
            count: res['data'][i]?.['count'],
            fee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Video')?.[0]?.fee,
            videoFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Video')?.[0]?.fee,
            audioFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Telephone')?.[0]?.fee,
            chatFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Chat')?.[0]?.fee,
            inPersonFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'In person')?.[0]?.fee,
            feedetails: res['data'][i]?.['feedetails'],
            profilePic: res['data']?.[i]?.['personaldetail']?.['profilePic'],
            //gender:res['data']?.[i]?.['gender']
          }
          this.doctorsDetails2.push(doctor);
          this.nutritionists.push(doctor);
        }
      }

      if (res['data'].length < 6) {
        this.p2 = 1
      }
    });

  }
  getDoctorsList() {
    this.doctorsOn = true
    this.caretakersOn = false
    this.nutritionistOn = false
    this.diagnosticOn = false
    this.hospitalOn = false
    this.httpClient.get(this.doctorUrl + 'newDoctorsList').subscribe(res => {
      this.totalDoctors = res['data']
      this.doctors = res['data'].filter((obj) => obj?.type == 'DOCTOR')
    })
  }
  nutritionistList() {
    this.doctorsOn = false
    this.caretakersOn = false
    this.nutritionistOn = true
    this.hospitalOn = false
    this.diagnosticOn = false
    this.nutritionists = this.totalDoctors.filter((obj) => obj?.type == "NUTRITIONIST")
  }
  caretakers() {
    this.caretakersOn = true
    this.doctorsOn = false
    this.nutritionistOn = false
    this.hospitalOn = false
    this.diagnosticOn = false
    this.httpClient.get(this.caretakerUrl + 'findAllCaretakerlist').subscribe(res => {
      this.caretakersList = res['data']
    })
  }

  diagnosticall() {
    this.caretakersOn = false
    this.doctorsOn = false
    this.nutritionistOn = false
    this.hospitalOn = false
    this.diagnosticOn = true
    this.httpClient.get(this.diagnosticUrl + 'all').subscribe(res => {
      this.diagnosticList = res['data']
    })
  }
  hospitals() {
    this.caretakersOn = false
    this.doctorsOn = false
    this.nutritionistOn = false
    this.hospitalOn = true
    this.diagnosticOn = false
    this.httpClient.post(this.hospitalUrl + 'getAll/' + 1 + '/' + 100, {}).subscribe(res => {
      this.hospitalList = res['data']
    })
  }
  searchCaretaker(name, event) {
    this.ctName = name;

    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
      this.patientServices.getCaretakersByName(name).subscribe(res => {
        this.optionsC = [];
        for (var ct of res['data']) {
          var fullName = ct.firstName + " " + ct.lastName;
          this.optionsC.push(fullName);
        }

        this.optionsC = this.removeDuplicateItems(this.optionsC);

        this.filteredOptionsC = this.myControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterC(value || '')),
        );
      });

    }
    else if (name.length == 0 && charCode == 8) {
      //this.getAllPrimary()
      this.isPreferredList = true;
      this.isCentersList = true;

      if (this.isCurrent)
        this.currentLocation();
      else
        this.primaryAddress();

    }
  }
  choosePincode(pincode) {
    var caretaker
    this.pincode = pincode;
    this.caretakersList = []
    var conceptName = $('#aioConceptName').val()
    this.patient.getAddressByPincode(pincode, conceptName).subscribe(res => {
      if (res['result'].length == 0) {
        this.Toaster.error("Wrong pincode")
      }
      else {
        this.latitude = res['result'][0]['latitude']
        this.longitude = res['result'][0]['longitude']
        $('#location').modal('hide')
        this.checked = true
        var req = {
          "distance": 10,
          "latitude": this.latitude,
          "longitude": this.longitude
        }
        this.patient.getCaretakerLatAndLong(req).subscribe(res => {
          if (JSON.parse(res['data'])?.length > 0) {
            for (var i = 0; i < JSON.parse(res['data']).length; i++) {
              caretaker = {
                firstName: JSON.parse(res['data'])?.[i]['firstName'],
                lastName: JSON.parse(res['data'])?.[i]['lastName'],
                fullName: JSON.parse(res['data'])?.[i]['firstName'] + ' ' + JSON.parse(res['data'])?.[i]['lastName'],
                caretakerId: JSON.parse(res['data'])?.[i]['caretakerId'],
                rating: JSON.parse(res['data'])?.[i]['averagerating'],
                averagerating: JSON.parse(res['data'])?.[i]['averagerating'],
                totalExperience: JSON.parse(res['data'])?.[i]['totalExperience'],
                specialization: 'CARETAKER',
                gender: JSON.parse(res['data'])?.[i]['gender'],
                profilePic: JSON.parse(res['data'])?.[i]?.['personaldetail']?.['profilePic'],
                fee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Video')?.[0]?.fee,
                videoFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Video')?.[0]?.fee,
                audioFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Telephone')?.[0]?.fee,
                chatFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Chat')?.[0]?.fee,
                inPersonFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'In person')?.[0]?.fee,
                feedetails: res['data'][i]?.['feedetails']
              }
              this.caretakersList.push(caretaker);
            }

          }
          if (caretaker != null || caretaker != undefined) {
          }
        })
      }
    })
  }
  currentLocation() {
    $('#location').modal('hide')
    this.checked = true
    this.checked = true
    $("#current").prop("checked", true);

    var req = {
      "distance": 10,
      "latitude": this.gps.latitude,
      "longitude": this.gps.longitude
    }

    this.patient.getCaretakerLatAndLong(req).subscribe(res => {
      this.caretakersList = JSON.parse(res['data'])
      this.isCurrent = true;
      this.updateDiagnosticCentersList();
    })



    //this.getAllCaretakers()
  }
  primaryAddress() {
    $('#location').modal('hide')
    $("#primary").prop("checked", true);
    this.checked = true
    this.caretakers()

  }
  getCareTakerDetailsByName(careTaker) {
    var caretaker
    this.patientServices.getCaretakerDetailsByName(this.ctName).subscribe(res => {
      this.caretakersList = []
      if (res['data'].length > 0) {
        for (var i = 0; i < res['data'].length; i++) {
          caretaker = {
            firstName: res['data'][i]['firstName'],
            lastName: res['data'][i]['lastName'],
            fullName: res['data'][i]['firstName'] + ' ' + res['data'][i]['lastName'],
            caretakerId: res['data'][i]['caretakerId'],
            rating: res['data'][i]['averagerating'],
            averagerating: res['data'][i]['averagerating'],
            totalExperience: res['data'][i]['totalExperience'],
            specialization: 'CARETAKER',
            gender: res['data'][i]['gender'],
            profilePic: res['data']?.[i]?.['personaldetail']?.['profilePic'],
            fee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Video')?.[0]?.fee,
            videoFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Video')?.[0]?.fee,
            audioFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Telephone')?.[0]?.fee,
            chatFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Chat')?.[0]?.fee,
            inPersonFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'In person')?.[0]?.fee,
            feedetails: res['data'][i]?.['feedetails']
          }
        }

      }
      if (res['data'].length < 6) {
        this.p2 = 1
      }
      if (caretaker != null || caretaker != undefined) {
        this.caretakersList.push(caretaker);
      }
    });
  }
  getAllPrimary() {
    // var userId = JSON.parse(this.globalStorage.getItem('forUserId'))
    // this.patientServices.getAllCaretakerByPrimary(userId).subscribe(res => {
    //   this.caretakersList = res['data'];
    //   this.isCurrent = false;

    //   var data = this.globalStorage.getItem('userData');
    //   data = JSON.parse(data);
    //   this.userId = data['userId'];
    //   this.updateDiagnosticCentersList();
    // })
  }
  updateDiagnosticCentersList() {
    var tempList = [];
    var no = 0;
    for (var p = 0; p < this.caretakersList?.length; p++) {
      no = 0;
      for (var q = 0; q < this.preferredList?.length; q++) {
        if (this.caretakersList[p]['caretakerId'] != this.preferredList[q]['caretakerId'])
          no++;
      }

      if (no === this.preferredList?.length)
        tempList.push(this.caretakersList[p]);
    }

    this.caretakersList = tempList;
  }

  getSpecializationDoctors(specialization) {

    if (specialization === '' || specialization === undefined || specialization === null) {
      this.getAllDoctors();
    }
    else {
      this.doctorsDetails2 = [];
      this.doctors = []
      this.patientServices.getDoctorBySpecilization(specialization).subscribe(res => {
        if (res['data'].length < 6) {
          this.p2 = 1
        }

        for (var i = 0; i < res['data'].length; i++) {
          var doctor = {
            name: res['data'][i]['firstName'] + ' ' + res['data'][i]['lastName'],
            firstName: res['data'][i]['firstName'],
            lastName: res['data'][i]['lastName'],
            specialization: res['data'][i]['doctorspecialization'],
            doctorId: res['data'][i]['doctorId'],
            rating: res['data'][i]['averagerating'],
            averagerating: res['data'][i]['averagerating'],
            totalExperience: res['data'][i]['totalExperience'],
            type: res['data'][i]['type'],
            profilePic: res['data']?.[i]?.['personaldetail']?.['profilePic'],
            count: res['data'][i]?.['count'],
            gender: res['data']?.[i]?.['gender'],
            videoFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Video')?.[0]?.fee,
            fee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Video')?.[0]?.fee,
            audioFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Telephone')?.[0]?.fee,
            chatFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'Chat')?.[0]?.fee,
            inPersonFee: res['data'][i]?.['feedetails']?.filter(obj => obj.type == 'In person')?.[0]?.fee,
            feedetails: res['data'][i]?.['feedetails']
          }
          this.doctorsDetails2.push(doctor);
          this.doctors.push(doctor);
        }

        if (res['data'].length < 6) {
          this.p2 = 1
        }
      });
    }

  }
  booknow(data) {
    this.globalStorage.setItem('webData', JSON.stringify(data))
    this.patientServices.saveWebData(data)
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    //window.parent.location.href = 'https://dev.curemetric.com/cureMetric/patientLogin'
    window.parent.location.href = window.location.origin + '/patientLogin'
    // const navigationExtras: NavigationExtras = {
    //   state: {
    //    object:data
    //   }
    // };
    // this.router.navigate(['/patientLogin'],navigationExtras)
  }
}
