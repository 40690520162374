<div class="modal-header">
    <h5 class="modal-title"> <span class="spaneeditem2"><i class="fas fa-notes-medical"></i>&nbsp; 
        Alert </span>
    </h5>
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"
     > <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12 form-group">
        <p>{{message}}</p>
      </div>
</div>
<div class="modal-footer">
    <button class="btn btn-success btn-xs float-right ml-2" (click)="submit()">Continue</button>
    <button class="btn btn-danger btn-xs float-right ml-2" (click)="cancelVisit()">Cancel</button>
  </div>