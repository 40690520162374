import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';
import { CurrentBatchInfoComponent } from '../current-batch-info/current-batch-info.component';

@Component({
  selector: 'app-order-batch-information',
  templateUrl: './order-batch-information.component.html',
  styleUrls: ['./order-batch-information.component.css']
})
export class OrderBatchInformationComponent implements OnInit {

  @Input() ordersData: any;
  @Input() through:any
  p1 = 1;

  constructor(private globalStorage: GlobalStorageService, 
    private activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private inventoryService: InventoryService,
    private modalService : NgbModal) { }

  ngOnInit(): void {
    this.getOrderBatchInformation();
  }

  closeModal() {
    this.activeModal.close(true);
  }

  ordersBatchInfo;
  getOrderBatchInformation() {
    if(this.through=='SUPPLIERLEVEL'){
      this.inventoryService.getBatchInfo(this.ordersData?.addressId,this.ordersData?.supplierordersId).subscribe(res => {
        this.ordersBatchInfo = res?.['data'];
        this.p1 = 1;
      },
        error => {
          this.toaster.error(error?.['error']?.['message']);
        }
      )
    }
    else{
      this.inventoryService.orderBatchInformation(this.ordersData?.supplierordersId).subscribe(res => {
        this.ordersBatchInfo = res?.['data'];
        this.p1 = 1;
      },
        error => {
          this.toaster.error(error?.['error']?.['message']);
        }
      )
    }
  }

  getbatchDetails(data){
    let wClass = 'left';
    if(this.globalStorage.getItem("loggedInAs")=='IVF'){
      wClass='left hw-60';
    }
    if(this.globalStorage.getItem("loggedInAs")=='HOSPITAL'){
      wClass='modal-right hw-60';
    }
    if(this.globalStorage.getItem("loggedInAs")=='PHARMACY'){
      wClass='modal-right hw-60';
    }
    const modalRef = this.modalService.open(CurrentBatchInfoComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    })
    modalRef.componentInstance.stockObj = data;
  }

}
