import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: ['./appointment-details.component.css']
})
export class AppointmentDetailsComponent implements OnInit {
  @Input() appointmentData:any;ImageSrc;img;
  appointmentFor;referenceEntityName;specilization;rating;exp;ailment;testName;appointemntDate;fromTime;toTime;amount;
  constructor(public activeModal: NgbActiveModal,private reports: PatientScheduleControlService,private sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    console.log('appointmentsdata',this.appointmentData)
    this.getRatingDoctor(this.appointmentData?.doctorId)
    this.getDoctorData(this.appointmentData?.doctorId)
  }
  data = []
  getRatingDoctor(id) {
    this.reports.sendError(null)
    this.reports.getDoctorRating(id).subscribe(res => {
      res['data'].forEach((value) => {
        this.data.push(value.rating)
      })
      this.rating = (this.data.reduce((a, b) => a + b, 0) / this.data.length).toFixed(0)
    })
  }
  getDoctorData(id){
    this.reports.getDoctorDetailssById('id/', id).subscribe(res => {
      this.rating = res['data']['averagerating']
      this.exp = res['data']['totalExperience']
      this.specilization = res['data']['specialization']
      if (res['data']?.['personaldetail']?.['profilePic'] != null) {
        //this.personalDetails.controls['profilePic'].patchValue(this.doctorDetails?.profilePic)
        this.ImageSrc = "data:image/jpeg;base64," + res['data']?.['personaldetail']?.['profilePic']
        this.img = this.sanitizer.bypassSecurityTrustResourceUrl(this.ImageSrc);
      } else {
        if (res['data']?.['gender'] == 'male') {
          this.img = "./assets/img/profile-male.png";
        }
        else {
          this.img = "./assets/img/profile-female.png";
        }
      }
    })
  }
 
}
