<div class="modal-header">
    <h4 class="centerHeader mb-0">Purchase Order</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body p-2">
    <div class="block inv-section">
        <div class="top-inv-col card rounded-0">
            <div class="row">
                <div class="inv-logo col-md-6">
                    <span class="inv-receiver rounded-0">
                        <h4 class="mb-0 mt-1 font-size-14 border-bottom p-2 bg-primary-light "> Vendor Details:</h4>
                        <address class="p-2" *ngIf="!dataLoading">
                            <b>{{supplierDetails?.supplierName}}</b> <br>
                            {{supplierDetails?.street1}},{{supplierDetails?.street2}},{{supplierDetails?.city}},{{supplierDetails?.zip}}<br>
                            Mobile: {{supplierDetails?.primaryContactNumber}}<br>
                            Email: {{supplierDetails?.emailId}}<br>
                            GSTIN: {{supplierDetails?.gstin}}<br>
                            State: {{supplierDetails?.state}}<br>
                            Country: {{supplierDetails?.country}}<br>
                        </address>
                        <div *ngIf="dataLoading">
                            <app-skelton-loader [numRows]="5"></app-skelton-loader>
                        </div>
                    </span>
                </div>
                <div class="float-right text-left order-details1 col-md-6">
                    <div class="inv-logo">
                        <span class="inv-receiver  rounded-0">
                            <h4 class="mb-0 mt-1 font-size-14 border-bottom p-2 bg-primary-light "> Ship To:</h4>
                            <address class="p-2" *ngIf="!dataLoading">
                                <b>{{entityName?.hospitalName}}</b> <br>
                                {{entityData?.address1}},{{entityData?.address2}},{{entityData?.city}},{{entityData?.zip}}<br>
                                Mobile: {{entityName?.cellNumber}}<br>
                                Email: {{entityName?.emailId}}<br>
                                <!-- GSTIN: {{entityData?.gstin}}<br> -->
                                State: {{entityData?.state}}<br>
                                Country: {{entityData?.country}}<br>
                            </address>
                            <div *ngIf="dataLoading">
                                <app-skelton-loader [numRows]="5"></app-skelton-loader>
                            </div>
                        </span>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <table class="table table-bordered table-hover table-styled table-striped order-bulk"
        *ngIf="purchaseItems?.length !== 0">
        <thead>
            <tr role="row">
                <th>Item Name</th>
                <th>Unit Price</th>
                <th>Quantity</th>
                <!-- <th>Deal</th> -->
                <th>Discount</th>
                <th>Free Items</th>
                <th>Items to be received</th>
                <th>Tax (%)</th>
                <!-- <th>SGST (%)</th>
                <th>IGST (%)</th> -->
                <th>Tax Amount</th>
                <th>Total Amount</th>
            </tr>
        </thead>
        <tbody *ngIf="!dataLoading">
            <tr *ngFor="let data of inventoryData;let i=index;">
                <td>{{data?.itemName}}</td>
                <td><input type="number" class="form-control" [(ngModel)]="purchaseItems[i].unitPrice"
                        (focusout)="calReceiveItem(i)" [readonly]="this.mode=='ORDERVIEW'"></td>
                <td>
                    <input type="number" class="form-control" appInputRestrict="decimalOnly"
                        [(ngModel)]="purchaseItems[i].totalStockOrdered" (focusout)="calReceiveItem(i)"
                        [readonly]="this.mode=='ORDERVIEW'">
                </td>
                <!-- <td>{{data?.deal}}</td> -->
                <!-- <td *ngIf="data?.freeQuantity">Buy {{data?.buyQuantity}} Get {{data?.freeQuantity}}</td> -->
                <td><input type="number" class="form-control" [(ngModel)]="purchaseItems[i].discount"
                        (focusout)="calReceiveItem(i)" [readonly]="this.mode=='ORDERVIEW'"></td>
                <td>
                    <input type="number" class="form-control" [readonly]="this.mode=='ORDERVIEW'"
                        [(ngModel)]="purchaseItems[i].freeQuantity" (focusout)="calReceiveItem(i)">
                </td>
                <td>
                    <input type="number" class="form-control" [(ngModel)]="purchaseItems[i].quantityToBeReceived"
                        readonly>
                </td>
                <td>
                    <!-- <input type="number" class="form-control" [(ngModel)]="purchaseItems[i].tax"
                        (focusout)="calReceiveItem(i)" [readonly]="this.mode=='ORDERVIEW'"> -->
                    <select [(ngModel)]="purchaseItems[i].tax" class="form-control" (change)="calReceiveItem(i)">
                        <option *ngFor="let percentage of percentages" [value]="percentage" 
                            [disabled]="this.mode=='ORDERVIEW'">
                            {{ percentage }}%
                        </option>
                    </select>
                </td>
                <!-- <td>
                    <input type="number" class="form-control" [(ngModel)]="purchaseItems[i].sgst" [readonly]="this.mode=='ORDERVIEW'">
                </td>
                <td>
                    <input type="number" class="form-control" [(ngModel)]="purchaseItems[i].igst" (focusout)="updateIgst(i)" [readonly]="this.mode=='ORDERVIEW'">
                </td> -->
                <td>{{purchaseItems[i].gstAmount?.toFixed(2)}}</td>
                <td>{{purchaseItems[i].totalPrice?.toFixed(2)}}</td>
            </tr>
        </tbody>
        <tbody *ngIf="dataLoading">
            <tr>
                <td colspan="9">
                    <app-skelton-loader [numRows]="4"></app-skelton-loader>
                </td>
            </tr>
        </tbody>
    </table>


    <div class="row" *ngIf="!dataLoading">
        <div class="col-lg-8 mt-0 mt-lg-0 align-right-for-full-screen">

            <div class="row mt-0">
                <div class="col-lg-12 col-md-12">
                    <div class="row align-items-baseline">
                        <div class="col-lg-6 col-md-6" *ngIf="this.mode!='ORDERVIEW' && this.mode!='ORDEREDIT'">
                            <div class="form-group position-relative">
                                <label>Expected Delivery Date <span class="text-danger">*</span></label>
                                <input matInput [matDatepicker]="doexpectpicker" class="form-control"
                                    placeholder="Enter Date" (keyup)="doexpectpicker.open()"
                                    (keypress)="doexpectpicker.open()" (click)="doexpectpicker.open()"
                                    [(ngModel)]="expectedDeliveryDate" [min]="minDate">
                                <mat-datepicker-toggle class="date-controlls" matIconSuffix
                                    [for]="doexpectpicker"></mat-datepicker-toggle>
                                <mat-datepicker #doexpectpicker></mat-datepicker>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6" *ngIf="this.mode!='ORDERVIEW' && this.mode!='ORDEREDIT'">
                            <div class="form-group position-relative">
                                <label>Payment(Grace Period) Days <span class="text-danger">*</span></label>
                                <input type="number" class="form-control" [(ngModel)]="netPayDays">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6" *ngIf="this.mode=='ORDEREDIT'">
                            <div class="form-group position-relative">
                                <label>Expected Delivery Date <span class="text-danger">*</span></label>
                                <input matInput [matDatepicker]="doexpectpicker" class="form-control"
                                    placeholder="Enter Date" (keyup)="doexpectpicker.open()"
                                    (keypress)="doexpectpicker.open()" (click)="doexpectpicker.open()"
                                    [(ngModel)]="expectedDeliveryDate" [min]="minDate">
                                <mat-datepicker-toggle class="date-controlls" matIconSuffix
                                    [for]="doexpectpicker"></mat-datepicker-toggle>
                                <mat-datepicker #doexpectpicker></mat-datepicker>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-4" *ngIf="this.mode=='ORDEREDIT'">
                            <div class="form-group position-relative">
                                <label>Reason</label>
                                <input class="form-control" [(ngModel)]="reason">
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12" *ngIf="this.mode!='ORDERVIEW'">
                            <textarea rows="4" class="form-control" [(ngModel)]="terms"
                                placeholder="Enter Terms and Conditions"></textarea>
                        </div>

                    </div>


                </div>


            </div>
        </div>
        <div class="col-lg-4 ms-md-auto mt-2 mb-lg-10 mb-6">
            <div class="border-top1">
                <table class="table table-borderless box-shadow-none mb-0 mt-0">
                    <tbody *ngIf="this.mode=='ORDEREDIT'">
                        <tr>
                            <td class="ps-0">Total Tax:</td>
                            <td class="text-gray-900 text-end pe-0">
                                <span class="invoice-selected-currency"><i class="fa fa-inr"></i></span> <span
                                    id="total" class="price"> {{totalGstPrice?.toFixed(2)}}</span>

                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0">Total Amount:</td>
                            <td class="text-gray-900 text-end pe-0">
                                <span class="invoice-selected-currency"><i class="fa fa-inr"></i></span> <span
                                    id="discountAmount">{{totalAmount?.toFixed(2)}}</span>

                            </td>
                        </tr>

                        <tr *ngIf="splitGst()">
                            <td></td>
                            <td class="text-gray-900 text-end pe-0">
                                <span id="total" class="price"> CGST : {{(totalGstPrice/2)?.toFixed(2)}} </span>
                            </td>
                        </tr>
                        <tr *ngIf="splitGst()">
                            <td></td>
                            <td class="text-gray-900 text-end pe-0">
                                <span id="total" class="price"> SGST : {{(totalGstPrice/2)?.toFixed(2)}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="!splitGst()">
                            <td></td>
                            <td class="text-gray-900 text-end pe-0">
                                <span id="total" class="price"> IGST : {{(totalGstPrice)?.toFixed(2)}}</span>
                            </td>
                        </tr>

                    </tbody>

                    <tbody *ngIf="this.mode!='ORDERVIEW' && this.mode!='ORDEREDIT'">
                        <tr>
                            <td class="ps-0">Total tax:</td>
                            <td class="text-gray-900 text-end pe-0">
                                <span class="invoice-selected-currency"><i class="fa fa-inr"></i></span> <span
                                    id="total" class="price"> {{totalGstPrice?.toFixed(2)}}</span>

                            </td>
                        </tr>
                        <tr *ngIf="splitGst()">
                            <td></td>
                            <td class="text-gray-900 text-end pe-0">
                                <span id="total" class="price"> CGST : {{(totalGstPrice/2)?.toFixed(2)}} </span>
                            </td>
                        </tr>
                        <tr *ngIf="splitGst()">
                            <td></td>
                            <td class="text-gray-900 text-end pe-0">
                                <span id="total" class="price"> SGST : {{(totalGstPrice/2)?.toFixed(2)}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="!splitGst()">
                            <td></td>
                            <td class="text-gray-900 text-end pe-0">
                                <span id="total" class="price"> IGST : {{(totalGstPrice)?.toFixed(2)}}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0">Total Amount:</td>
                            <td class="text-gray-900 text-end pe-0">
                                <span class="invoice-selected-currency"><i class="fa fa-inr"></i></span> <span
                                    id="discountAmount">{{totalAmount?.toFixed(2)}}</span>

                            </td>
                        </tr>


                    </tbody>
                    <tbody *ngIf="this.mode=='ORDERVIEW'">
                        <tr>
                            <td class="ps-0">Total Tax:</td>
                            <td class="text-gray-900 text-end pe-0">
                                <span class="invoice-selected-currency"><i class="fa fa-inr"></i></span> <span
                                    id="total" class="price"> {{totalGstPrice?.toFixed(2)}}</span>

                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0">Total Amount:</td>
                            <td class="text-gray-900 text-end pe-0">
                                <span class="invoice-selected-currency"><i class="fa fa-inr"></i></span> <span
                                    id="discountAmount">{{totalAmount?.toFixed(2)}}</span>

                            </td>
                        </tr>

                        <tr *ngIf="splitGst()">
                            <td></td>
                            <td class="text-gray-900 text-end pe-0">
                                <span id="total" class="price"> CGST : {{(totalGstPrice/2)?.toFixed(2)}} </span>
                            </td>
                        </tr>
                        <tr *ngIf="splitGst()">
                            <td></td>
                            <td class="text-gray-900 text-end pe-0">
                                <span id="total" class="price"> SGST : {{(totalGstPrice/2)?.toFixed(2)}}</span>
                            </td>
                        </tr>
                        <tr *ngIf="!splitGst()">
                            <td></td>
                            <td class="text-gray-900 text-end pe-0">
                                <span id="total" class="price"> IGST : {{(totalGstPrice)?.toFixed(2)}}</span>
                            </td>
                        </tr>

                    </tbody>
                </table>


            </div>
        </div>
        <div class="float-right text-right"><button (click)="placeOrder()" class="btn btn-success mt-3 "
                *ngIf="this.mode!='ORDERVIEW'">Place Order</button>
        </div>
    </div>

    <div class="row" *ngIf="dataLoading">
        <div class="col-lg-8">
            <app-skelton-loader [numRows]="4"></app-skelton-loader>
        </div>
        <div class="col-lg-4">
            <app-skelton-loader [numRows]="4"></app-skelton-loader>
        </div>
    </div>



    <!-- <div *ngIf="purchaseItems?.length == 0">
        <div class="no-data-found"></div>
    </div> -->
</div>