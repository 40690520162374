import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryService } from '../inventory.service';
import { CurrentBatchInfoComponent } from '../../modals/current-batch-info/current-batch-info.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-inventory-batchs',
  templateUrl: './inventory-batchs.component.html',
  styleUrls: ['./inventory-batchs.component.css']
})
export class InventoryBatchsComponent implements OnInit {
  @Input() inventoryId: any;
  @Input() inventoryBatch: any;
  @Input() addressId:any
  batchs: any[] = [];
  p3=1;
  category_name;
  subcategory_name;

  constructor(private globalStorage: GlobalStorageService, public activeModal: NgbActiveModal, private service: InventoryService,
    private modalService : NgbModal, private toaster: ToastrService) { }

  ngOnInit(): void {
    this.getBatchInfo();
    this.category_name = this.inventoryBatch?.catagoryName || this.inventoryBatch?.catageryType;
    this.subcategory_name = this.inventoryBatch?.itemName || this.inventoryBatch?.subCatagery;
  }

  getBatchInfo(){
    let body = {
      "addressId":this.addressId,
      "inventoryId":this.inventoryId
    }
    this.service.getBatchInformation(body).subscribe(res => {
      this.batchs = res['data'] ? res['data'] : [];
      this.p3=1;
    },
    error => {
      this.toaster.error(error?.['error']?.['message']);
    }
    )
  }

  viewCurrentBatchInfo(batch){
    let wClass = 'left';
    if(this.globalStorage.getItem("loggedInAs")=='IVF'){
      wClass='left hw-60';
    }
    if(this.globalStorage.getItem("loggedInAs")=='HOSPITAL'){
      wClass='modal-right hw-60';
    }
    if(this.globalStorage.getItem("loggedInAs")=='PHARMACY'){
      wClass='modal-right hw-60';
    }
    const modalRef = this.modalService.open(CurrentBatchInfoComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    })
    modalRef.componentInstance.stockObj = batch;
  }

}
