
            <div class="modal-header">
                <h5 class="modal-title" *ngIf="!isEdit">
                    <i aria-hidden="true" class="fa fa-user-plus"></i> Place Order
                </h5>
                <h5 class="modal-title" *ngIf="isEdit">
                    <i aria-hidden="true" class="fa fa-edit"></i> Edit Order
                </h5>
                <button type="button" class="close" (click)="activeModal.close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body modal-bodycure pt-0">
                <div class="container user-curetab">
                    <div class="error" *ngIf="showMessage">
                        {{message}}
                      </div>
                    <form [formGroup]="createorders">
                        <div class="row mt-2 formgropnew">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3 cureadd" *ngIf="!isEdit">
                                <label for="" class="">Category<b>*</b></label>
                                <div class="form-group input-material">
                                    <div class="form-group input-material">
                                        <mat-select class="form-control"
                                            id="categoryname" formControlName="Category"
                                            (selectionChange)="selectedCategory(categorySelected)"
                                            [disabled]="assignAssociatedItems"
                                            [ngClass]="{ 'is-invalid': (submitted || createordersControls.Category.touched) && createordersControls.Category.errors }">
                                            <mat-option *ngFor="let c of categorylist"
                                                [value]="c.catagory" (click)="getcategyById(c?.catagoryId)">{{c.catagory}}</mat-option>
                                        </mat-select>
                                        <div *ngIf="(submitted || createordersControls.Category.touched) && createordersControls.Category.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="createordersControls.Category.errors.required">
                                                Please Select Category.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

<div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mb-3 cureadd" *ngIf="!isEdit">
  <label for="" class="">Sub Category<b>*</b></label>
  <div class="form-group input-material">
      <div class="form-group input-material">
          <div>
              <mat-select class="form-control"
                          id="subcategoryname" formControlName="SubCategory"
                          [disabled]="assignAssociatedItems"
                          (selectionChange)="selectedsubCategory(subCategorySelected); updateItemName()">
                  <mat-option *ngFor="let s of subcategory"
                              [value]="s.name" (click)="getsubcategyById(s)">{{s.name}}</mat-option>
              </mat-select>
              <div *ngIf="(submitted || createorders.get('SubCategory').touched) && createorders.get('SubCategory').errors"
                   class="invalid-feedback">
                  <div *ngIf="createorders.get('SubCategory').errors.required">
                      Please Select Category to Select SubCategory.
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3 cureadd" *ngIf="!isEdit && !assignAssociatedItems">
                                <label for="" class="">Supplier<b>*</b></label>
                                <div class="form-group input-material">
                                    <div class="form-group input-material">
                                        <div>
                                            <mat-select
                                                (selectionChange)="selectedSuppiler(Supplierselected)" [(ngModel)]="Supplierselected"
                                                class="form-control" id="Supplier" formControlName="Supplier"
                                                [ngClass]="{ 'is-invalid': (submitted || createordersControls.Supplier.touched) && createordersControls.Supplier.errors }">
                                                <mat-option *ngFor="let sup of suplier"
                                                    [value]="sup.supplierName" (click)="getsupplierIdbyClick(sup)">{{sup.supplierName}}</mat-option>
                                            </mat-select>
                                            <div *ngIf="(submitted || createordersControls.Supplier.touched) && createordersControls.Supplier.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="createordersControls.Supplier.errors.required">
                                                Please Select SubCategory to Select Supplier.
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- for edit -->
                            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mb-3" *ngIf="isEdit">
                                <label for="">Category<b>*</b></label>
                                <input type="text" class="form-control" formControlName="Category" readonly>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mb-3" *ngIf="isEdit">
                                <label for="">Sub Category<b>*</b></label>
                                <input type="text" class="form-control" formControlName="SubCategory" readonly>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mb-3" *ngIf="isEdit || assignAssociatedItems">
                                <label for="">Supplier<b>*</b></label>
                                <input type="text" class="form-control" formControlName="Supplier" readonly>
                            </div>
                            <!-- for edit /-->

                            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 ">
                                <label for="" class="">Item Name<b>*</b></label>
                                <input type="text" class="form-control"
                                    formControlName="itemName" [readonly]="isEdit || assignAssociatedItems"
                                    [ngClass]="{ 'is-invalid': (submitted || createordersControls.itemName.touched) && createordersControls.itemName.errors }">

                                    <div *ngIf="(submitted || createordersControls.itemName.touched) && createordersControls.itemName.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="createordersControls.itemName.errors.required">
                                                ItemName is Required.
                                            </div>
                                        </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-3">
                                <label for="" class="">Unit Price<b>*</b></label>
                                <input type="text" class="form-control" #unitPrice
                                    formControlName="unitPrice" appInputRestrict="numberOnly" maxlength="10"
                                    [ngClass]="{ 'is-invalid': (submitted || createordersControls.unitPrice.touched) && createordersControls.unitPrice.errors }">

                                    <div *ngIf="(submitted || createordersControls.unitPrice.touched) && createordersControls.unitPrice.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="createordersControls.unitPrice.errors.required">
                                                Unit Price is Required.
                                            </div>
                                        </div>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-3">
                                <label for="" class="">Quantity<b>*</b></label>
                                <input type="text" class="form-control" name="Quantity" id="Quantity" #quantity
                                    (keyup)="multiply()" noWhiteSpaceAtTheStart appInputRestrict="numberOnly" maxlength="5"
                                    placeholder="" formControlName="Quantity"
                                    [ngClass]="{ 'is-invalid': (submitted || createordersControls.Quantity.touched) && createordersControls.Quantity.errors }">

                                    <div *ngIf="(submitted || createordersControls.Quantity.touched) && createordersControls.Quantity.errors"
                                            class="invalid-feedback">
                                            <div *ngIf="createordersControls.Quantity.errors.required">
                                                Quantity is Required.
                                            </div>
                                        </div>
                            </div>



                            <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mb-3">
                                <label for="" class="">Total Price<b>*</b></label>

                                <input type="text" class="form-control" name="totalPrice" id="totalPriceInput" placeholder="" formControlName="totalPrice" readonly oninput="formatDecimal(this)">

                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3 position-relative">
                                <label>Expected Delivery Date <b>*</b></label>
                            <input matInput [matDatepicker]="picker" class="form-control" placeholder="" formControlName="ExpectedDate"
                                (keyup)="picker.open()" (keypress)="picker.open()" (click)="picker.open()" [min]="minDate"
                                [ngClass]="{ 'is-invalid': (submitted || createordersControls.ExpectedDate.touched) && createordersControls.ExpectedDate.errors }">

                                <div *ngIf="(submitted || createordersControls.ExpectedDate.touched) && createordersControls.ExpectedDate.errors"
                                class="invalid-feedback">
                                <div *ngIf="createordersControls.ExpectedDate.errors.required">
                                    Date is Required.
                                </div>
                            </div>

                            <mat-datepicker-toggle matIconSuffix [for]="picker" style="position: absolute;top: 30px;right: 5px;"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            </div>


                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-6 form-group mb-3 bg-primary-light" *ngIf="deal">
                                <label for="" class="col-form-label">Deal : <b class="text-primary">{{deal}}</b></label>
                            </div>

                            <div class="col-xl-3 col-lg-3 col-md-3 col-xs-3 form-group mb-3 bg-primary-light" *ngIf="deal && buyQuantity && sellQuantity">
                                <label for="" class="col-form-label">Buy {{buyQuantity}} get {{sellQuantity}}</label>
                            </div>
                            <div class="col-xl-3 col-lg-3 col-md-3 col-xs-3 form-group mb-3 bg-primary-light" *ngIf="deal && buyQuantity && sellQuantity">
                              <!-- <label for="" class="col-form-label">Free - {{freeItemsAvailable}}</label> -->
                              <label for="" class="col-form-label">Free</label>
                                <input type="text" class="form-control" name="freeItems" id="freeItems"
                                placeholder="No.of Free Items" formControlName="freeItems" readonly>
                            </div>
                            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-6 form-group mb-3 bg-primary-light" *ngIf="deal && discount">
                                <label for="" class="col-form-label">Get {{discount}}% off on total price</label>
                            </div>

                            <h3  class="mb-3 mt-0 font-size-16 border-bottom p-2 bg-success-light ">GST</h3>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3
                            d-flex justify-content-between align-items-baseline">
                              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                                            [(ngModel)]="selectedOption" [ngModelOptions]="{standalone: true}">
                                <mat-radio-button disabled class="example-radio-button" type="radio"
                                                    (change)="gstCheckBox($event)" *ngFor="let option of gstOptions; let i = index"
                                                    [value]="option" [checked]="i === 0">
                                    {{option}}
                                </mat-radio-button>
                            </mat-radio-group>
                            <!-- <h6><strong>Total GST :</strong> <span class="ml-1"><i class="fa fa-inr"></i> {{cGst}}</span></h6> -->
                            </div>
                            <ng-container *ngIf="showGstFields">
                                <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <label for="" class="">C-GST</label>
                                    <input type="text" class="form-control" name="cgst" id="cgst" #cgst
                                        placeholder="C GST" formControlName="cgst" (keyup)="multiply()" readonly>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <label for="" class="">S-GST</label>
                                    <input type="text" class="form-control" name="sgst" id="sgst" #sgst
                                        placeholder="S GST" formControlName="sgst" (keyup)="multiply()" readonly>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="showIGstFields">
                                <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                    <label for="" class="">I-GST</label>
                                    <input type="text" class="form-control" name="igst" id="igst" #igst
                                        placeholder="GST" formControlName="igst" (keyup)="multiply()" readonly>
                                </div>
                            </ng-container>
                        </div>
                    </form>

                    <div class="vime" *ngIf="!isEdit">
                        <button type="button" class="btn btn-success al mt-3 float-right btn-sm" (click)="saveOrder()" [disabled]="createorders.invalid">
                            <i class="plusicon fas fa-save"></i> Save
                        </button>
                        <button type="button" class="btn btn-danger al mt-3 float-right btn-sm ml-2" (click)="activeModal.close()" >
                          Cancel
                     </button>
                    </div>

                    <div class="vime" *ngIf="isEdit">
                      <button type="button" class="btn btn-primary al mt-3 float-right btn-sm" (click)="updateOrder()">
                          <i class="plusicon fas fa-pencil"></i> Update
                      </button>
                      <button type="button" class="btn btn-danger al mt-3 float-right btn-sm ml-2" (click)="activeModal.close()" >
                        Cancel
                   </button>
                  </div>


                </div>
            </div>
