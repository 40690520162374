import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { GlobalStorageService } from 'src/app/global-storage.service';

@Component({
  selector: 'app-edit-staff',
  templateUrl: './edit-staff.component.html',
  styleUrls: ['./edit-staff.component.css']
})
export class EditStaffComponent implements OnInit {
  associateUser: FormGroup;invalidCredentials = false;isEmailExistsMessage; isEmailExists = false;successCredentials = false;
  departments;roles;roleId;roleName;message;submitted=false;departmentId;selectedDepartment;isExist = false;
  @Input() staffData:any
  constructor(private fb:FormBuilder,private globalStorage: GlobalStorageService,private clinicService: ClinicService,
    private toastrService: ToastrService,public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    var input = document.querySelector("#telephone");
    jQuery(document).ready(function ($) {
      // (<any>window).intlTelInputGlobals.loadUtils("./../../../assets/build/js/utils.js");

      if (jQuery('#telephone').length) {
        var input = document.querySelector("#telephone");
        (<any>window).intlTelInput(input, {
          preferredCountries: ['in'],
          separateDialCode: true, placeholderNumberType: "MOBILE",
          autoPlaceholder: "polite", autoHideDialCode: true,
          hiddenInput: "",

          customPlaceholder: null,
          formatOnDisplay: true,

        });
      }

    });
    input?.addEventListener("countrychange", function () {
      (<any>input)?.intlTelInput();
      (<any>input)?.val('')
    });
    this.associateUser = this.fb.group({
      "active": "Y",
      "userName": [''],
      "emailId": ['', [Validators.required, Validators.email]],
      "firstName": ['', Validators.required],
      "lastName": ['', Validators.required],
      "middleName": '',
      "cellNumber": ['', [Validators.required]],
      "roleId": ['', [Validators.required]],
      "department" : ['', [Validators.required]],
      "password": [''],
      "confirmPassword": [''],
      "primaryContact": "CELL_NUMBER"
    })
    this.getDepartsmentsList()
    this.getRoles()
    this.patchData()
  }
  get d() { return this.associateUser.controls; }
  phoneNumberPresent=false
  patchData(){
    if(this.staffData){
      this.clinicService.getUserDetailsByHospitalUserId(this.globalStorage.getItem('hospitalAddressId'),this.staffData?.hospitalUserId).subscribe(res=>{
        console.log('staff details',res)
        this.selectedDepartment=this.staffData?.departmentName
        this.roleId=this.staffData?.['roleId']
        this.roleName=res['data']?.['type']
        if(res['data']?.cellNumber){
          this.phoneNumberPresent=true
        }
        else{
          this.phoneNumberPresent=false
        }
        this.associateUser.patchValue({
          "firstName":res['data']?.firstName,
          "lastName":res['data']?.lastName,
          "middleName":res['data']?.middleName,
          "cellNumber": res['data']?.cellNumber,
          "department": res['data']?.departmentName,
          "emailId":res['data']?.emailId,
          "roleId":res['data']?.type
        })
      },error=>{
        this.toastrService.error(error?.['error']?.['message'])
      })
    }
  }
  getDepartsmentsList(){
    let hospitaAddressId = parseInt(this.globalStorage.getItem('hospitalAddressId'));
    this.clinicService.getdepartmentInformation(hospitaAddressId).subscribe(res => {
      this.departments = res?.['data'];
    },
      error => {
        this.toastrService.error(error?.['error']?.['message']);
      }
    )
  }
  getRoles(){
    this.clinicService.allroles().subscribe(res=>{
      this.roles=res['data']
    },error=>{
      this.toastrService.error(error?.error?.message)
    })
  }
  checkStaffEmail(){
    this.invalidCredentials = false;
    if (this.associateUser.controls.emailId.errors == null) {
      this.clinicService.userExist(this.associateUser.controls.emailId.value).subscribe(res => {
        if (res?.['data'] == true) {
          this.invalidCredentials = true;
          this.isEmailExistsMessage = res?.['message'];
          this.isEmailExists = true;
          return
        }
        else {
          this.isEmailExists = false;
        }
      }, error => {
        this.toastrService.error(error?.['error']?.['message']);
      });
    }
  }

  isPhoneNumberExists = false; isPhoneNumberExistsMessage;
  checkStaffPhoneNumber() {
    // var number = $('.iti__selected-dial-code').text() + num
    this.invalidCredentials = false
    this.clinicService.checkPhoneNumber(this.phNum.replace(/\+/gi, '%2B')).subscribe(res => {
      if (res?.['data'] == true) {
        this.isPhoneNumberExists = true
        this.isPhoneNumberExistsMessage = res?.['message']
        return
      }
      else {
        this.isPhoneNumberExists = false
      }
    }, error => {
      this.message = error?.['error']?.['message']
    });
  }
  checkStaffUserName() {
    this.invalidCredentials = false
    if (this.d.userName.errors == null) {
      this.isExist = false
      this.clinicService.checkHospitalUsername(this.associateUser.controls['userName'].value).subscribe(res => {
        if (res?.['data'] == true) {
          this.invalidCredentials = true
          //  this.message = res?.['message']
          this.invalidCredentials = true
          this.message = res['message']
          //   this.toastr.error("A user with this email address has already been registered in the system and the associated already exists.");
          this.isExist = true
          this.submitted = false;
          return
        }
        // else {
        //   this.isUserNameExists = false
        // }
      }, (error) => {
        // this.toastr.error((error['error']['message']))

      });
    }
  }
  phNum;
  getNumber(event) {
    this.phNum = event;
  }
  selectRole(data){
    this.roleId=data?.roleId
    this.roleName=data?.roleName
  }
  checkForm(form){
    console.log('check form',form)
  }
  selectedDepartmentFromList(department){
    console.log('dept',department)
    this.departmentId=department?.departmentId
  }
  onCountryChange(event) {
  }
  checknum(){
    console.log('this',this.associateUser?.value?.cellNumber)
    if(this.associateUser?.value?.cellNumber?.length==0 || this.associateUser?.value?.cellNumber=='' || this.associateUser?.value?.cellNumber==undefined){
      this.phoneNumberPresent=false
    }
  }
  numberOnly(event, phn): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (phn.length < 1) {
      if (charCode < 54 || charCode > 57) {
        return false;
      }
      return true;
    }
    else {
      if (charCode < 48 || charCode > 57) {
        return false;
      }
      return true;
    }
  }
  submit(form) {
    var number = $('.staffTeleNumber').html()
    var str = "";
    $(number).find("div[class^='iti__selected-dial-code']").each(function () {
      str += $(this).text();
    })
    var finaNumber = str;
    var phnum
    if(this.phoneNumberPresent){
      phnum=this.associateUser.value.cellNumber
      if(this.associateUser.value.cellNumber?.length<13){
        this.toastrService.error('Enter Valid Mobile Number')
        return
      }
    }
    else{
      phnum=finaNumber+this.associateUser.value.cellNumber
      if(phnum?.length<13){
        this.toastrService.error('Enter Valid Mobile Number')
        return
      }
    }
    var staff = {
      "active": "Y",
      "userName": this.associateUser.value.cellNumber,
      "cellNumber": phnum,
      "emailId": this.associateUser.value.emailId,
      "firstName": this.associateUser.value.firstName.charAt(0).toUpperCase() + this.associateUser.value.firstName.slice(1),
      "lastName": this.associateUser.value.lastName.charAt(0).toUpperCase() + this.associateUser.value.lastName.slice(1),
      "middleName": this.associateUser.value.middleName,
      "hospitalAddressId": parseInt(this.globalStorage.getItem('hospitalAddressId')),
      //"hospitalUserId":this.globalStorage.getItem('forUserId').toString(),
      "hospitalName": this.globalStorage.getItem('hospitalName'),
      "primaryContact": "CELL_NUMBER",
      "password": '12334',
      "roleId": this.roleId,
      "roleName": this.roleName,
      "departmentId":this.departmentId,
      "departmentName": this.associateUser.value.department,
      "uuid": '12345',
      "hospitalUserId":this.staffData?.hospitalUserId
    }
    this.clinicService.updateStaff(staff).subscribe(res => {
      this.toastrService.success('User details updated')
      this.submitted = false
      this.activeModal.close(true)
    }, error => {
      this.toastrService.error(error['error']['message'])
    })
  }
}
