<div class="modal-header">
   <h5 class="modal-title">
      <i aria-hidden="true" class="fa fa-user-plus"></i> Order Delivery
   </h5>
   <button type="button" class="close" (click)="activeModal.close()">
      <span aria-hidden="true">&times;</span>
   </button>
</div>

<div class="modal-body pb-0">
   <div class="container">
      <div class="row mb-3">
         <div class="col-lg-6 col-md-6">
            <h5>Item: <span class="text-success">{{deliverOrder?.itemName}}</span></h5>         
            <h5>Quantity: <span class="text-success">{{deliverOrder?.totalStockOrdered}}</span></h5>         
            <h5>Unit Price: <span class="text-success"><span><i class="fa fa-inr fs-6"></i></span> {{deliverOrder?.unitPrice}}</span></h5>         
            <h5 *ngIf="deliverOrder?.cgst">Total GST: <span class="text-success"><span><i class="fa fa-inr fs-6"></i></span> {{ ((deliverOrder?.cgst + deliverOrder?.sgst) / 100) * (deliverOrder?.unitPrice * deliverOrder?.totalStockOrdered) }}</span></h5>         
            <h5 *ngIf="deliverOrder?.igst">Total GST: <span class="text-success"><span><i class="fa fa-inr fs-6"></i></span> {{ (deliverOrder?.igst / 100) * (deliverOrder?.unitPrice * deliverOrder?.totalStockOrdered) }}</span></h5>         
            <h5>Total Price: <span class="text-success"><span><i class="fa fa-inr fs-6"></i></span> {{deliverOrder?.totalPrice}}</span></h5>         
            <h5>Supplier: <span class="text-success">{{deliverOrder?.supplierName}}</span></h5>         
         </div>
         <div class="col-lg-6 col-md-6">
            <h5>Ordered Date: <span class="text-success">{{deliverOrder?.dateOrdered |date:'dd-MMM-yyyy'}}</span></h5>         
            <h5>Expected Delivery Date: <span class="text-success">{{deliverOrder?.expectedDeliveryDate |date:'dd-MMM-yyyy'}}</span></h5>            
         </div>
      </div>
      <form [formGroup]="orderDelivered">
         <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3 bg-primary-light p-2">
               <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                  [(ngModel)]="selectedOption" [ngModelOptions]="{standalone: true}">
                  <mat-radio-button class="example-radio-button" type="radio" (change)="checkBoxCheck($event,option)"
                     *ngFor="let option of options" [value]="option">
                     {{option}}
                  </mat-radio-button>
               </mat-radio-group>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
               <label for="">Order ID</label>
               <input type="text" class="form-control" formControlName="orderId" readonly>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3 user-curetab">
               <label class="" for="">Invoice ID <b class="text-danger">*</b></label>
               <input class="form-control " formControlName="invoiceId" maxlength="10" placeholder="Invoice ID"
                  type="text" appInputRestrict="numberOnly"
                  [ngClass]="{ 'is-invalid': (submitted || deliveryFormControls.invoiceId.touched) && deliveryFormControls.invoiceId.errors }">
               <div *ngIf="(submitted || deliveryFormControls.invoiceId.touched) && deliveryFormControls.invoiceId.errors"
                  class="invalid-feedback">
                  <div *ngIf="deliveryFormControls.invoiceId.errors.required">
                     Invoice Id is Required.
                  </div>
               </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3 user-curetab">
               <label class="" for="">Batch Number <b class="text-danger">*</b></label>
               <input class="form-control " formControlName="batchNumber" maxlength="10" placeholder="Batch Number"
                  type="text"
                  [ngClass]="{ 'is-invalid': (submitted || deliveryFormControls.batchNumber.touched) && deliveryFormControls.batchNumber.errors }">
               <div *ngIf="(submitted || deliveryFormControls.batchNumber.touched) && deliveryFormControls.batchNumber.errors"
                  class="invalid-feedback">
                  <div *ngIf="deliveryFormControls.batchNumber.errors.required">
                     Batch Number is Required.
                  </div>
               </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
               <label>Unit Sale Price <b class="text-danger">*</b></label>
               <input type="text" class="form-control" formControlName="saleUnitPrice" appInputRestrict="decimalOnly" maxlength="10" placeholder="Unit Price" 
               [ngClass]="{ 'is-invalid': (submitted || deliveryFormControls.saleUnitPrice.touched) && deliveryFormControls.saleUnitPrice.errors }">
               <div *ngIf="(submitted || deliveryFormControls.saleUnitPrice.touched) && deliveryFormControls.saleUnitPrice.errors"
                  class="invalid-feedback">
                  <div *ngIf="deliveryFormControls.saleUnitPrice.errors.required">
                     Unit Price is Required.
                  </div>
               </div>
           </div>

           <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3 position-relative">
            <label>Manufacture Date <b class="text-danger">*</b></label>
            <input matInput [matDatepicker]="manufDatePicker" class="form-control" formControlName="manufacturerDate"
               (keypress)="manufDatePicker.open()" (keyup)="manufDatePicker.open()" [max]="minDate"
               (click)="manufDatePicker.open()"
               [ngClass]="{ 'is-invalid': (submitted || deliveryFormControls.manufacturerDate.touched) && deliveryFormControls.manufacturerDate.errors }">
            <div *ngIf="(submitted || deliveryFormControls.manufacturerDate.touched) && deliveryFormControls.manufacturerDate.errors"
               class="invalid-feedback">
               <div *ngIf="deliveryFormControls.manufacturerDate.errors.required">
                  Manufacture Date is Required.
               </div>
            </div>
            <mat-datepicker-toggle matIconSuffix [for]="manufDatePicker"
               style="position: absolute;top: 30px;right: 10px;"></mat-datepicker-toggle>
            <mat-datepicker #manufDatePicker></mat-datepicker>
         </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3 position-relative">
               <label>Expiry Date <b class="text-danger">*</b></label>
               <input matInput [matDatepicker]="expDatePicker" class="form-control" formControlName="expDate"
                  (keypress)="expDatePicker.open()" (keyup)="expDatePicker.open()" [min]="minDate"
                  (click)="expDatePicker.open()"
                  [ngClass]="{ 'is-invalid': (submitted || deliveryFormControls.expDate.touched) && deliveryFormControls.expDate.errors }">
               <div *ngIf="(submitted || deliveryFormControls.expDate.touched) && deliveryFormControls.expDate.errors"
                  class="invalid-feedback">
                  <div *ngIf="deliveryFormControls.expDate.errors.required">
                     Expiry Date is Required.
                  </div>
               </div>
               <mat-datepicker-toggle matIconSuffix [for]="expDatePicker"
                  style="position: absolute;top: 30px;right: 10px;"></mat-datepicker-toggle>
               <mat-datepicker #expDatePicker></mat-datepicker>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3 position-relative">
               <label>Warrenty</label>
               <input matInput [matDatepicker]="warrentyPicker" class="form-control" formControlName="warrentyDate"
                  (keypress)="warrentyPicker.open()" (keyup)="warrentyPicker.open()" [min]="minDate"
                  (click)="warrentyPicker.open()"
               >
               <mat-datepicker-toggle matIconSuffix [for]="warrentyPicker"
                  style="position: absolute;top: 30px;right: 10px;"></mat-datepicker-toggle>
               <mat-datepicker #warrentyPicker></mat-datepicker>
            </div>

            <ng-container *ngIf="partial">
               <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3 user-curetab">
                  <label class="" for="">Items Delivered <b class="text-danger">*</b></label>
                  <input class="form-control " formControlName="itemsDelivered" maxlength="35"
                     placeholder="Items Delivered" type="text" appInputRestrict="numberOnly"
                     [ngClass]="{ 'is-invalid': (submitted || deliveryFormControls.itemsDelivered.touched) && deliveryFormControls.itemsDelivered.errors }">
                  <div
                     *ngIf="(submitted || deliveryFormControls.itemsDelivered.touched) && deliveryFormControls.itemsDelivered.errors"
                     class="invalid-feedback">
                     <div *ngIf="deliveryFormControls.itemsDelivered.errors.required">
                        Please Enter Number of Items Delivered.
                     </div>
                  </div>
               </div>

               <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3 position-relative">
                  <label>Expected Delivery Date <b class="text-danger">*</b></label>
                  <input matInput [matDatepicker]="picker" class="form-control" formControlName="expectedDelivery"
                     (keypress)="picker.open()" (keyup)="picker.open()" [min]="minDate"
                     (click)="picker.open()"
                     [ngClass]="{ 'is-invalid': (submitted || deliveryFormControls.expectedDelivery.touched) && deliveryFormControls.expectedDelivery.errors }">
                  <div
                     *ngIf="(submitted || deliveryFormControls.expectedDelivery.touched) && deliveryFormControls.expectedDelivery.errors"
                     class="invalid-feedback">
                     <div *ngIf="deliveryFormControls.expectedDelivery.errors.required">
                        Expected Delivery Date is Required.
                     </div>
                  </div>

                  <mat-datepicker-toggle matIconSuffix [for]="picker"
                     style="position: absolute;top: 30px;right: 10px;"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
               </div>
            </ng-container>
         </div>

         <div class="text-right">
          <button type="button" class="btn btn-danger al mt-3 float-right btn-sm ml-2" (click)="closeModal()">Cancel</button>
            <button class="btn btn-success al mt-3 float-right btn-sm " (click)="deliver()" [disabled]="!validateDeliveryOrderForm()">Deliver</button>
         </div>
      </form>
   </div>

</div>
