<div class="modal-content" style="width:400px !important">
  <div class="modal-body add-appoi">
    <div class="d-flex flex-row mb-0">
      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
        <p class=" mb-1 mt-1">

          <i class="fa fa-calendar-check fa-2x "> </i>
        </p>
        <h5 class="modal-title">Add new slot</h5>

        <p class="font-weight-medium mb-1 mt-1 heading">{{selectedDate|date:'dd-MMM-yyyy'}}
        </p>
        <p class="  mb-1 mt-1"> {{fromTime}} - {{toTime}}</p>
        <!-- <p class="font-weight-medium mb-1 mt-1">Charges Rs 200/-
            </p>   -->
      </div>
    </div>

  </div>
  <div class="modal-footer text-center">
    <div style=" float: left;margin-left: 3%;padding-left: 10px; padding-top:9px;width:100%" class="mb-2" *ngIf="!isRpmcaretaker">
      <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" [(ngModel)]="selectedOption">
        <mat-radio-button class="example-radio-button" *ngFor="let option of options" [value]="option" (change)="checkBoxCheck($event,option)">
          {{option}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="">
      <button type="button" class="btn btn-danger mr-2  btn-xs" (click)="No()" data-dismiss="modal">Cancel</button>
      <button type="button" class="btn btn-primary btn-xs" (click)="Yes()">Add</button>
    </div>
  </div>
</div>