import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { pharmaService } from 'src/app/shared/services/pharma.service';

@Component({
  selector: 'app-pharmacy-otp-verification',
  templateUrl: './pharmacy-otp-verification.component.html',
  styleUrls: ['./pharmacy-otp-verification.component.css']
})
export class PharmacyOtpVerificationComponent implements OnInit {
  otpVerificationForm: FormGroup;
  timerOn = true;
  subscription: Subscription;
  subscribeTimer;
  isTimeOn1 = false;
  timeLeft: number = 120;
  timeLeft2: number = 120;
  invalidCredentials;
  successCredentials;
  message;
  otpInvalidMsg = "Otp is invalid";
  mOtpError: boolean = false;
  eOtpError: boolean = false;
  isComingFromMail = false; pharmacyUserId; mbNumber
  constructor(private otpService: AuthService, private PhamacyServices: pharmaService, private routes: Router, private router: ActivatedRoute,
    private toaster: ToastrService, private formBuilder: FormBuilder, private reset: AuthService, private toastr: ToastrService) {
    const navigation = this.routes.getCurrentNavigation();
    const state = navigation.extras.state as {
      emailId: string,
      userName: string,
      mobileNumber: string,
    };
    this.otpVerificationForm = this.formBuilder.group({
      email: ['', Validators.required],
      userName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
    })
    if (state) {
      this.mbNumber = state.mobileNumber
      this.isComingFromMail = false
      this.otpVerificationForm.patchValue({
        email: state.emailId,
        userName: state.userName,
        phoneNumber: state.mobileNumber.replace(/.(?=.{4})/g, 'x').padStart(10, 'x').slice(-10),
      })

    }
    else {
      this.router.queryParams.subscribe(params => {
        this.isComingFromMail = true
        this.pharmacyUserId = params['pharmacyUserId'];
      });
      this.PhamacyServices.GetPath('primarydetails?pharmacyuserid=', this.pharmacyUserId).subscribe(res => {
        this.otpVerificationForm.patchValue({
          email: res['data'].emailId,
          userName: res['data'].userName,
          phoneNumber: res['data'].cellNumber
        })
        this.getPharmacyUserOtp()

      }, error => {
      })

    }

  }

  ngOnInit(): void {
    this.observableTimer()
  }

  sendOtp() {

  }
  OTP
  email; PhOTP; emailVerificationDone = false
  verifyOTP() {
    this.successCredentials = false;
    this.invalidCredentials = false;
    //this.isTimeOn=false;
    //alert()
    if (this.OTP == undefined || this.otpVerificationForm.value.email == undefined) {
      return
    }

    if (this.OTP == "" || this.otpVerificationForm.value.email == "") {
      return
    }
    this.otpService.verifyEmailOtpForPharmacy(this.otpVerificationForm.value.email, this.OTP).subscribe(res => {
      this.toaster.success('Your Email ID is now verified.');
      //this.observableTimer();
      this.eOtpError = false;
      this.emailVerificationDone = true;
      this.verifyNowButton = true;
      this.isTimeOn1 = true;
      this.observableTimer1();
    }, error => {
      this.eOtpError = true;
      //this.toaster.error('Your Email not verifed')
    })
  }
  getPharmacyUserOtp() {

    if (this.otpVerificationForm.value.email == "" || this.otpVerificationForm.value.email == undefined) {
      this.toaster.warning('Enter the Email to send verification Link')
      return
    }

    this.otpService.resendNewEmailOtpForPharmacyLogin(this.otpVerificationForm.value.userName, this.otpVerificationForm.value.email).subscribe(res => {
      this.toaster.success('Check your email for OTP.')
      this.observableTimer()
    }, error => {
      this.toaster.error(error['error']['message'])
    })

  }

  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  verifyUserOTP() {
    this.successCredentials = false
    this.invalidCredentials = false
    this.isTimeOn = false
    //alert()
    if (this.OTP == undefined || this.otpVerificationForm.value.email == undefined) {
      return
    }

    if (this.OTP == "" || this.otpVerificationForm.value.email == "") {
      return
    }
    this.otpService.verifyEmailOtpForPharmacyUser(this.otpVerificationForm.value.email, this.OTP).subscribe(res => {
      this.toaster.success('Your Email ID is now verified.')
      this.isTimeOn = true
      this.verifyNowButton = true
      this.observableTimer1()
      this.emailVerificationDone = true
    }, error => {
      this.toaster.error('Your Email not verifed')
    })
  }
  verifyPhoneOTP() {
    this.successCredentials = false
    this.invalidCredentials = false
    if (this.PhOTP == undefined || this.otpVerificationForm.value.phoneNumber == undefined) {
      return
    }

    if (this.PhOTP == "" || this.otpVerificationForm.value.phoneNumber == "") {
      return
    }
    this.otpService.verifyPhoneOTPForPharmacy(this.mbNumber.replace(/\+/gi, '%2B'), this.PhOTP).subscribe(res => {
      //this.toaster.success('Your phone number is now verified.')
      this.mOtpError = true;
      this.routes.navigate(['/pharmaLogin'])
    }, error => {
      // this.toaster.error('Your Phone number not verifed')
      this.mOtpError = true;
    })
  }
  verifyPhoneOTPUser() {
    this.successCredentials = false
    this.invalidCredentials = false
    if (this.PhOTP == undefined || this.otpVerificationForm.value.phoneNumber == undefined) {
      return
    }

    if (this.PhOTP == "" || this.otpVerificationForm.value.phoneNumber == "") {
      return
    }
    this.otpService.verifyPhoneOTPForPharmacyUser(this.otpVerificationForm.value.phoneNumber.replace(/\+/gi, '%2B'), this.PhOTP).subscribe(res => {
      this.toaster.success('Your phone number is now verified.')
      const navigationExtras: NavigationExtras = {
        state: {
          emailId: this.otpVerificationForm.value.email,
          mobileNumber: this.otpVerificationForm.value.phoneNumber,
        }
      };
      this.routes.navigate(['/pharmaForgot'], navigationExtras)
    }, error => {
      this.toaster.error('Your Phone number not verifed')
    })
  }
  isTimeOn = true
  getOtp() {
    this.isTimeOn = true
    this.invalidCredentials = false
    this.successCredentials = false
    if (this.otpVerificationForm.value.email == "" || this.otpVerificationForm.value.email == undefined) {
      this.toaster.warning('Enter the Email to send verification Link')
      return
    }
    this.otpService.resendNewEmailOtpForPharmacyLogin(this.otpVerificationForm.value.userName, this.otpVerificationForm.value.email).subscribe(res => {
      this.toaster.success('Check your email for OTP.')
      this.successCredentials = true
      this.message = 'Check your email for OTP.'
      this.observableTimer()
    }, error => {
      this.toaster.error(error['error']['message'])
    })
  }
  observableTimer() {
    this.isTimeOn = true
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.successCredentials = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        // this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  observableTimer1() {
    this.isTimeOn = false
    this.isTimeOn1 = true
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer1').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn1 = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.successCredentials = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        // this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  getPhoneOtp() {
    this.successCredentials = false
    this.invalidCredentials = false
    if (this.otpVerificationForm.value.phoneNumber == "" || this.otpVerificationForm.value.phoneNumber == undefined) {
      this.toaster.warning('Enter the mobile number to send verification OTP')
      return
    }
    this.otpService.resendPhoneOtpForPharmacy(this.mbNumber.replace(/\+/gi, '%2B'), this.otpVerificationForm.value.userName).subscribe(res => {
      // this.toaster.success('OTP sent successfully.')
      this.successCredentials = true
      this.message = 'OTP sent successfully.'
      this.observableTimer1()
    }, error => {
      this.toaster.error(error['error']['message'])
    })

  }

  verifyNowButton = true
  otpEntered(value) {
    if (value.length == 6) {
      this.verifyNowButton = false
    }
    else {
      this.verifyNowButton = true
    }
  }
}
