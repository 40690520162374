import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IvfService } from '../services/ivf.service';
import * as moment from 'moment';
import { ThemePalette } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { IvfBulkFileUploadComponent } from '../ivf-bulk-file-upload/ivf-bulk-file-upload.component';
import { of, forkJoin } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AddDisposablesComponent } from '../add-disposables/add-disposables.component';
import { GlobalStorageService } from 'src/app/global-storage.service';
import { PdfMakerService } from 'src/app/pdf-maker.service';
import { PdfServiceService } from 'src/app/pdf-service.service';

declare var $;

@Component({
  selector: 'app-ivf-complete-semen-analysis',
  templateUrl: './ivf-complete-semen-analysis.component.html',
  styleUrls: ['./ivf-complete-semen-analysis.component.css']
})
export class IvfCompleteSemenAnalysisComponent implements OnInit {

  @Input() prescriptionData: any;
  colorsList = ['WHITE', 'GREY', 'OPALESCENT', 'YELLOW'];

  uploadStatus: boolean[] = [];

  tabs = [
    { title: 'Semen Analysis', identifier: 'semenAnalysis' },
    { title: 'Physical Asses..', identifier: 'physicalAssessment' },
    { title: 'Sperm Motility', identifier: 'spermMotility' },
    { title: 'Morphology', identifier: 'morphologyAssessment' },
    { title: 'ASFPA', identifier: 'asfParameter' },
    { title: 'Upload Images', identifier: 'uploadImages' },
    { title: 'Reference Values', identifier: 'referenceValues' }
  ];

  // currentDate:any='';
  activeTab = this.tabs[0];
  semenAnalysisForm: FormGroup;
  physicalAssessmentForm: FormGroup;
  spermMotilityForm: FormGroup;
  morphologyAssessmentForm: FormGroup;
  asfpaForm: FormGroup;
  uploadForm: FormGroup;


  presentTime = new Date()
  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = false;
  public maxDate: string;
  public minDate = moment(new Date()).format('YYYY-MM-DD')
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  // public color: ThemePalette = 'primary';
  userSemenAnalysisId: any = null;
  ivfAddressId: any = null;
  patientId: any = null;
  medicalTestId: any = null;
  placeList = ['LAB', 'HOME', 'HOSPITAL']
  liquifactionArray = ['5 MIN', '10 MIN', '15 MIN', '20 MIN', '25 MIN', '30 MIN', '35 MIN', '40 MIN', '45 MIN', '50 MIN', '55 MIN', '60 MIN']
  phArray = ['6.0', '6.5', '7.0', '7.5', '8.0', '8.5', '9.0'];
  fructoseArray = ['POSITIVE', 'NEGATIVE'];
  rbcArray = ['PRESENT', 'NIL']
  aggArray = ['NIL', 'PRESENT +', 'PRESENT ++', 'PRESENT +++']
  physicalAssesment: any;
  semenAnalysis: any;
  morphology: any;
  spermMotality: any;
  asfpa: any;
  printData: any;
  hospitalInfo: any;
  ivfhospitalResponce: any;
  userDetails: any;

  constructor(private activeModal: NgbActiveModal, private pdfMakeService: PdfMakerService, private pdfService: PdfServiceService, private globalStorage: GlobalStorageService, private fb: FormBuilder, private ivfService: IvfService, private toastrService: ToastrService, private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.maxDate = moment().format('YYYY-MM-DDTHH:mm');
    this.initializeForms();
    if (this.userSemenAnalysisId && this.patientId) {
      this.getDetails();
    }
    this.changeResponse();
  }
  addClasses() {
    $('.mat-button-base').addClass('dpNone')
    $('.mat-icon').addClass('dpNone')
    $('mat-icon:contains("expand_less")').html("<i class='fa fa-check' aria-hidden='true'>")
  }

  formatDateForDateTimeLocal(date: Date): string {
    const year = date.getFullYear();
    const month = this.pad(date.getMonth() + 1);
    const day = this.pad(date.getDate());
    const hours = this.pad(date.getHours());
    const minutes = this.pad(date.getMinutes());

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }


  pad(number: number): string {
    return number < 10 ? `0${number}` : number.toString();
  }




  updatingForm = false;
  changeResponse() {

    //   this.semenAnalysisForm.valueChanges.subscribe(val => {
    //     if (val.timeOfCollection && val.timeOfEvaluation) {
    //         let difference = this.calculateTimeDifference(val.timeOfCollection, val.timeOfEvaluation);
    //         if(difference > 60) {
    //           difference=60
    //         }
    //         const Lval=difference+' MIN'
    //         this.semenAnalysisForm.patchValue({
    //           liquefaction: Lval
    //         })
    //     }
    // });

    this.semenAnalysisForm.get('timeOfEvaluation').valueChanges.subscribe(res => {
      let difference = this.calculateTimeDifference(this.semenAnalysisForm.get('timeOfCollection').value, res);
      if (difference > 60) {
        difference = 60
      }
      const Lval = difference + ' MIN'
      this.semenAnalysisForm.patchValue({
        liquefaction: Lval
      })
    })

    this.physicalAssessmentForm.get('spermConc').valueChanges.subscribe(res => {
      if (parseFloat(res) > 0 && parseFloat(this.physicalAssessmentForm.get('spermConc').value) > 0) {
        this.physicalAssessmentForm.patchValue({
          totalEjaculate: (parseFloat(res) * parseFloat(this.physicalAssessmentForm.get('volume').value)).toString(),
        })

        if (parseFloat(res) > 0 && parseFloat(this.physicalAssessmentForm.get('totalEjaculate').value) > 0 && (parseFloat(res) < 16 || parseFloat(this.physicalAssessmentForm.get('totalEjaculate').value) < 38)) {
          this.aspaImpression.add('Oligozoospermia ')
          this.impression.add('ABNORMAL ')
        } else {
          this.aspaImpression.delete('Oligozoospermia ')
          this.impression.delete('ABNORMAL ')
        }
        this.genImpression();
        this.genASFPAImpression()

      }
    })

    this.physicalAssessmentForm.get('volume').valueChanges.subscribe(res => {
      if (parseFloat(res) >= 0 && parseFloat(this.physicalAssessmentForm.get('spermConc').value) > 0) {
        this.aspaImpression.delete('Azospermia ')

        if (parseFloat(res) < 1.5) {
          this.impression.add('Low Volume')
        } else {
          this.impression.delete('Low Volume')
        }

        this.physicalAssessmentForm.patchValue({
          totalEjaculate: (parseFloat(res) * parseFloat(this.physicalAssessmentForm.get('spermConc').value)).toString(),
        })
        if (parseFloat(res) < 16 || parseFloat(this.physicalAssessmentForm.get('totalEjaculate').value) < 38) {
          this.aspaImpression.add('Oligozoospermia ')
          this.impression.add('ABNORMAL ')
        } else {
          this.aspaImpression.delete('Oligozoospermia ')
          this.impression.delete('ABNORMAL ')
        }
        this.genImpression();
        this.genASFPAImpression();
      } else {
        if (parseFloat(res) == 0) {
          this.aspaImpression.add('Azospermia ')
        } else {
          this.aspaImpression.delete('Azospermia ')
        }

        if (parseFloat(res) < 1.5) {
          this.impression.add('Low Volume')
        } else {
          this.impression.delete('Low Volume')
        }
        this.genImpression();
      }
    })

    this.physicalAssessmentForm.get('epithelialCells').valueChanges.subscribe(res => {
      if (!isNaN(res)) {
        this.physicalAssessmentForm.patchValue({
          toEpithelialCells: res + 1
        })
      }

    })
    this.physicalAssessmentForm.get('pusCells').valueChanges.subscribe(res => {
      if (!isNaN(res)) {
        this.physicalAssessmentForm.patchValue({
          toPusCells: res + 1
        })
      }
    })

    // this.physicalAssessmentForm.get('live').valueChanges.subscribe(res => {
    //   this.physicalAssessmentForm.patchValue({
    //     dead: (100 - parseFloat(res)).toFixed(2),
    //   })
    // })

    this.physicalAssessmentForm.get('live').valueChanges.subscribe(res => {
      if (!this.updatingForm) {
        if (parseFloat(res) > 0) {
          this.updatingForm = true;
          this.physicalAssessmentForm.patchValue({
            dead: (100 - parseFloat(res)).toFixed(2).toString(),
          });
          this.updatingForm = false;
          if (parseFloat(res) < 20) {
            this.aspaImpression.add('Necrozoospermia')
          } else {
            this.aspaImpression.delete('Necrozoospermia')
          }
          if (parseFloat(res) < 58) {
            this.impression.add('Abnormal Motiity')
          } else {
            this.impression.delete('Abnormal Motiity')
          }
          this.genImpression();
          this.genASFPAImpression();
        }
      }
    });

    this.physicalAssessmentForm.get('dead').valueChanges.subscribe(res => {
      if (!this.updatingForm) {
        if (parseFloat(res) > 0) {

          this.updatingForm = true;
          this.physicalAssessmentForm.patchValue({
            live: (100 - parseFloat(res)).toFixed(2).toString(),
          });
          this.updatingForm = false;
        }
      }
    });

    this.morphologyAssessmentForm.get('normalForms').valueChanges.subscribe(res => {
      if (!this.updatingForm) {
        if (parseFloat(res) < 4) {
          this.morphologyImpression.add('Abnormal Forms')
          this.aspaImpression.add('Teratozoosspermia')

        } else {
          this.morphologyImpression.delete('Abnormal Forms')
          this.aspaImpression.delete('Teratozoosspermia')
        }
        this.genASFPAImpression()
        this.genMorphologyImpression()
        this.updatingForm = true;
        this.morphologyAssessmentForm.patchValue({
          overAllDefects: (100 - parseFloat(res)).toFixed(2).toString(),
        })
        this.updatingForm = false;
      }
    })

    this.morphologyAssessmentForm.get('overAllDefects').valueChanges.subscribe(res => {
      if (!this.updatingForm) {
        this.updatingForm = true;
        this.morphologyAssessmentForm.patchValue({
          normalForms: (100 - parseFloat(res)).toFixed(2).toString(),
        })
        this.updatingForm = false;
      }
    })

    this.morphologyAssessmentForm.get('headDefects').valueChanges.subscribe(res => {
      this.morphologyAssessmentForm.patchValue({
        normalForms: (100 - (
          parseFloat(res) + parseFloat(this.morphologyAssessmentForm.value.midPieceAndNeckDefects)
          + parseFloat(this.morphologyAssessmentForm.value.tailDefects))).toFixed(2).toString()
      })
    })

    this.morphologyAssessmentForm.get('midPieceAndNeckDefects').valueChanges.subscribe(res => {
      this.morphologyAssessmentForm.patchValue({
        normalForms: (100 - (
          parseFloat(res) + parseFloat(this.morphologyAssessmentForm.value.headDefects)
          + parseFloat(this.morphologyAssessmentForm.value.tailDefects))).toFixed(2).toString()
      })
    })

    this.morphologyAssessmentForm.get('tailDefects').valueChanges.subscribe(res => {
      this.morphologyAssessmentForm.patchValue({
        normalForms: (100 - (
          parseFloat(res) + parseFloat(this.morphologyAssessmentForm.value.midPieceAndNeckDefects)
          + parseFloat(this.morphologyAssessmentForm.value.headDefects))).toFixed(2).toString()
      })
    })

    this.morphologyAssessmentForm.get('cytoplasmicDroplets').valueChanges.subscribe(res => {
      if (parseFloat(res) > 10) {
        this.morphologyImpression.add('ABNORMAL')
      } else {
        this.morphologyImpression.delete('ABNORMAL')
      }
      this.genMorphologyImpression();
    })

    this.spermMotilityForm.get('rapidProgressiveGradea').valueChanges.subscribe(res => {
      this.spermMotilityForm.patchValue({
        immotileGraded: (100 - (parseFloat(res) + parseFloat(this.spermMotilityForm.value.slowProgressiveGradeb)
          + parseFloat(this.spermMotilityForm.value.nonProgressiveGradec))).toFixed(2).toString()
      })
      this.checkMotility();
    })

    this.spermMotilityForm.get('slowProgressiveGradeb').valueChanges.subscribe(res => {
      this.spermMotilityForm.patchValue({
        immotileGraded: (100 - (parseFloat(res) + parseFloat(this.spermMotilityForm.value.rapidProgressiveGradea)
          + parseFloat(this.spermMotilityForm.value.nonProgressiveGradec))).toFixed(2).toString()
      })
      this.checkMotility();

    })

    this.spermMotilityForm.get('nonProgressiveGradec').valueChanges.subscribe(res => {
      this.spermMotilityForm.patchValue({
        immotileGraded: (100 - (parseFloat(res) + parseFloat(this.spermMotilityForm.value.rapidProgressiveGradea)
          + parseFloat(this.spermMotilityForm.value.slowProgressiveGradeb))).toFixed(2).toString()
      })
      this.checkMotility();
    })

  }


  checkMotility() {
    const rapidA = parseFloat(this.spermMotilityForm.get('rapidProgressiveGradea').value);
    const slowB = parseFloat(this.spermMotilityForm.get('slowProgressiveGradeb').value);
    const nonC = parseFloat(this.spermMotilityForm.get('nonProgressiveGradec').value);

    if ((rapidA + slowB) < 32 || (rapidA + slowB + nonC) < 80) {
      this.motilityImpression.add('ABNORMAL')
      this.aspaImpression.add('Asthenozoospermia')

    } else {
      this.motilityImpression.delete('ABNORMAL')
      this.aspaImpression.delete('Asthenozoospermia')

    }
    this.genMotilityImpression();
    this.genASFPAImpression();
  }

  isValueInPhRange(value: number): boolean {
    return value >= 7.2 && value <= 7.8;
  }

  calculateTimeDifference(startTime: string, endTime: string): number {
    const start = new Date(startTime);
    const end = new Date(endTime);
    const differenceInMinutes = Math.abs(end.getTime() - start.getTime()) / (1000 * 60);

    // Round to nearest 5 minutes
    return Math.round(differenceInMinutes / 5) * 5;
  }

  impression = new Set<string>();
  finalImpression: string = '';
  genImpression() {
    let myArray = Array.from(this.impression);
    this.finalImpression = myArray.join(' ');

    // this.impression.forEach(item => this.aspaImpression.add(item));
    // this.genASFPAImpression();
  }

  motilityImpression = new Set<string>();
  finalMotilityImpression: string = '';
  genMotilityImpression() {
    let myArray = Array.from(this.motilityImpression);
    this.finalMotilityImpression = myArray.join(' ');

    // this.motilityImpression.forEach(item => this.aspaImpression.add(item));
    // this.genASFPAImpression();
  }

  morphologyImpression = new Set<string>();
  finalMorphologyImpression: string = '';
  genMorphologyImpression() {
    let myArray = Array.from(this.morphologyImpression);
    this.finalMorphologyImpression = myArray.join(' ');

    // this.morphologyImpression.forEach(item => this.aspaImpression.add(item));
    // this.genASFPAImpression();
  }

  aspaImpression = new Set<string>();
  finalAsfpaImpression: string = '';
  genASFPAImpression() {
    let myArray = Array.from(this.aspaImpression);
    this.finalAsfpaImpression = myArray.join(' ');
    this.asfpaForm.patchValue({
      finalSemenAnalysis: this.finalAsfpaImpression
    })
  }

  getDetails() {
    this.setSemenAnalysisData();
    this.setPhysicalAssesmentData();
    this.setSpermMotility();
    this.setMorphologyAssessment();
    this.setASFPA();
    this.populateData();
  }

  liquefactionValue: any;
  onLiquefactionChange(): void {
    const minutes = parseInt(this.liquefactionValue);
    let viscosity = 'NORMAL';
    if (minutes <= 20) {
      viscosity = 'NORMAL';
    } else if (minutes > 20 && minutes <= 30) {
      viscosity = 'MODERATE';
    } else if (minutes > 30 && minutes <= 40) {
      viscosity = 'VISCIOUS';
    } else {
      viscosity = 'HIGH VISCOSITY';
    }
    this.semenAnalysisForm.patchValue({ viscosity: viscosity })
  }

  private initializeForms() {
    // console.log(this.prescriptionData)
    this.userSemenAnalysisId = this.prescriptionData?.referenceId || null;
    this.ivfAddressId = this.prescriptionData?.ivfaddressId;
    this.patientId = this.prescriptionData?.patientId;
    this.medicalTestId = this.prescriptionData?.medicalTestId;
    this.semenAnalysisForm = this.fb.group({
      active: 'Y',
      appearance: [''],
      color: this.colorsList[0],
      date: [''],
      daysOfAbstinence: [0],
      liquefaction: this.liquifactionArray[0],
      ph: [''],
      placeOfCollection: this.placeList[0],
      spillage: [''],
      timeAtSampleReceivedToHospital: [''],
      timeOfCollection: [''],
      timeOfEvaluation: [''],
      userSemenAnalysisId: this.userSemenAnalysisId,
      ivfAddressId: this.ivfAddressId,
      patientId: this.patientId,
      viscosity: [''],
      medicalTestId: this.medicalTestId,
      remark: ''
    });
    this.physicalAssessmentForm = this.fb.group({
      agglutination: 0,
      dead: 0,
      epithelialCells: '',
      toEpithelialCells: '',
      fructose: this.fructoseArray[0],
      impression: [''],
      live: 0,
      pusCells: '',
      toPusCells: '',
      rbc: 0,
      spermConc: 0,
      totalEjaculate: 0,
      userSemenAnalysisId: this.userSemenAnalysisId,
      ivfAddressId: this.ivfAddressId,
      patientId: this.patientId,
      physicalAssessmentId: null,
      volume: 0
    });
    this.spermMotilityForm = this.fb.group({
      immotileGraded: 0,
      impression: [''],
      nonProgressiveGradec: 0,
      rapidProgressiveGradea: 0,
      slowProgressiveGradeb: 0,
      userSemenAnalysisId: this.userSemenAnalysisId,
      ivfAddressId: this.ivfAddressId,
      patientId: this.patientId,
      spermMotilityId: null,
    });
    this.morphologyAssessmentForm = this.fb.group({
      cytoplasmicDroplets: 0,
      defectsInHeadMidPieceNeckTail: 0,
      headDefects: 0,
      impression: [''],
      midPieceAndNeckDefects: 0,
      normalForms: 0,
      overAllDefects: 0,
      tailDefects: 0,
      userSemenAnalysisId: this.userSemenAnalysisId,
      ivfAddressId: this.ivfAddressId,
      patientId: this.patientId,
      morphologyAssessmentId: null,
      tziIndex: 0
    });
    this.asfpaForm = this.fb.group({
      finalSemenAnalysis: [''],
      hos: [''],
      zona: [''],
      userSemenAnalysisId: this.userSemenAnalysisId,
      ivfAddressId: this.ivfAddressId,
      patientId: this.patientId,
      advanceSpermId: null,
    });
    this.uploadForm = this.fb.group({
      files: this.fb.array([])
    });
    this.addFileInput();
    // this.userSemenAnalysisId(this.userSemenAnalysisId);

  }

  saveSemenAnalysis() {
    const body = this.semenAnalysisForm.value;
    body['date'] = body['date'] ? body['date'] + ':00' : null
    body['timeAtSampleReceivedToHospital'] = body['timeAtSampleReceivedToHospital'] ? body['timeAtSampleReceivedToHospital'] + ':00' : null
    body['timeOfCollection'] = body['timeOfCollection'] ? body['timeOfCollection'] + ':00' : null
    body['timeOfEvaluation'] = body['timeOfEvaluation'] ? body['timeOfEvaluation'] + ':00' : null
    console.log(body)
    this.ivfService.saveSemenAnalysisInformation(body).subscribe(res => {
      this.userSemenAnalysisId = res?.['data']
      this.updateUserSemenAnalysisId(res?.['data'])
      this.tabClicked({ identifier: 'physicalAssessment' })
      this.toastrService.success('Semen Analysis Saved.')
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
      // console.log(error?.['error']?.['message'])
    })
  }

  updateUserSemenAnalysisId(newId) {
    this.semenAnalysisForm.patchValue({
      userSemenAnalysisId: newId
    });
    this.physicalAssessmentForm.patchValue({
      userSemenAnalysisId: newId
    });
    this.morphologyAssessmentForm.patchValue({
      userSemenAnalysisId: newId
    });
    this.spermMotilityForm.patchValue({
      userSemenAnalysisId: newId
    });
    this.asfpaForm.patchValue({
      userSemenAnalysisId: newId
    });
  }

  savephysicalAnalysis() {
    const body = this.physicalAssessmentForm.value;
    console.log(body)
    this.ivfService.savePhysicalAssessmentInformation(body).subscribe(res => {
      this.tabClicked({ identifier: 'spermMotility' })
      this.toastrService.success('Physical Assessment Saved.')
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
      // console.log(error?.['error']?.['message'])
    })
  }

  saveSpermMotility() {
    const body = this.spermMotilityForm.value;
    console.log(body)
    this.ivfService.saveSpermMotilityInformation(body).subscribe(res => {
      this.tabClicked({ identifier: 'morphologyAssessment' })
      this.toastrService.success('Sperm Motility Saved.')
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
      // console.log(error?.['error']?.['message'])
    })
  }

  saveMorphology() {
    const body = this.morphologyAssessmentForm.value;
    console.log(body)
    this.ivfService.saveMorphologyAssessmentInformation(body).subscribe(res => {
      this.tabClicked({ identifier: 'asfParameter' })
      this.toastrService.success('Morphology Assessment Saved.')
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
      // console.log(error?.['error']?.['message'])
    })
  }

  saveASFPA() {
    const body = this.asfpaForm.value;
    console.log(body)
    this.ivfService.saveadvanceSpermInformation(body).subscribe(res => {
      this.tabClicked({ identifier: 'uploadImages' })
      this.toastrService.success('Advanced Sperm Information Saved.')
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
      // console.log(error?.['error']?.['message'])
    })
    this.populateData();
  }

  addDisposables() {
    const modalRef = this.modalService.open(AddDisposablesComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      windowClass: 'left w-40'
    });

    modalRef.componentInstance.details = {
      patientId: this.patientId,
      reportId: this.userSemenAnalysisId,
      medicalTestId: this.medicalTestId,
      reportName: "Semen Analysis"
    }

  }

  private setSemenAnalysisData() {
    this.ivfService.getUserSemenAnalysisInformation(this.ivfAddressId, this.patientId, this.userSemenAnalysisId).subscribe(res => {
      this.semenAnalysisForm.patchValue(res['data']);
      const currentDate = new Date();
      const formattedDate = this.formatDateForDateTimeLocal(currentDate);
      this.semenAnalysisForm.patchValue({
        date: this.maxDate
      });
    })
  }

  private setPhysicalAssesmentData() {
    this.ivfService.getphysicalAssessment(this.ivfAddressId, this.patientId, this.userSemenAnalysisId).subscribe(res => {
      this.physicalAssessmentForm.patchValue(res['data']);
    })
  }

  private setSpermMotility() {
    this.ivfService.getSpermMotility(this.ivfAddressId, this.patientId, this.userSemenAnalysisId).subscribe(res => {
      this.spermMotilityForm.patchValue(res['data']);
    })
  }

  private setMorphologyAssessment() {
    this.ivfService.getMorphologyAssessment(this.ivfAddressId, this.patientId, this.userSemenAnalysisId).subscribe(res => {
      this.morphologyAssessmentForm.patchValue(res['data']);
    })
  }
  private setASFPA() {
    this.ivfService.getASFPA(this.ivfAddressId, this.patientId, this.userSemenAnalysisId).subscribe(res => {
      this.asfpaForm.patchValue(res['data']);
      this.genASFPAImpression();
    })
  }

  reportsArray = [];
  private getFiles() {
    this.ivfService.getPicSemenAnalysisReport(parseInt(this.ivfAddressId), this.prescriptionData.patientId, this.userSemenAnalysisId).subscribe(res => {
      this.reportsArray = res['data'] || [];
    })
  }

  closeModal() {
    this.activeModal.close(true);
  }
  tabClicked(tab) {

    if (tab.identifier === 'semenAnalysis') {
      this.activeTab = tab;
    } else if (tab.identifier === 'physicalAssessment' && this.userSemenAnalysisId) {
      this.activeTab = tab;
    } else if (tab.identifier === 'spermMotility' && this.userSemenAnalysisId) {
      this.activeTab = tab;
    } else if (tab.identifier === 'morphologyAssessment' && this.userSemenAnalysisId) {
      this.activeTab = tab;
    } else if (tab.identifier === 'asfParameter' && this.userSemenAnalysisId) {
      this.activeTab = tab;
    } else if (tab.identifier === 'uploadImages' && this.userSemenAnalysisId) {
      this.getFiles();
      this.activeTab = tab;
    }
    else if (tab.identifier === 'referenceValues') {
      this.activeTab = tab;
    }

  }

  get files() {
    return this.uploadForm.get('files') as FormArray;
  }

  addFileInput() {
    this.files.push(this.fb.control(''));
    this.uploadStatus.push(false);  // Initialize upload status as false

  }
  onFileSelected2(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];

    if (file.size > 2 * 1024 * 1024) { // 2MB in bytes
      alert("File size must be under 2MB!");
      return;
    }

    const body = null
    // this.ivfServices.uploadIvfDocument('uploadPic?ivfaddressId=' + this.ivfDetails?.ivfaddressId + '&userId=' + userId + '&uploadPic=' + file, body).subscribe(res => {
    //   this.toastrService.success('File Uploaded')
    // }, error => {
    //   this.toastrService.error(error?.['error']?.['message'])
    // })

  }
 

  openUploadModal() {
    const modalRef = this.modalService.open(IvfBulkFileUploadComponent, {
      size: "lg",
      centered: true, // changed to true to center the modal
      backdrop: 'static',
      keyboard: false,
      windowClass: 'custom-class'
    });


    // Handle the response from the modal
    modalRef.result.then((files: File[]) => {
      this.uploadFiles(files);
    }).catch((error) => {
      // If user closes the modal without selecting any files, an error will be thrown, you can handle it here
      console.log(error);
    });
  }



  uploadFiles(files: File[]): void {
    // Create an array to hold all the upload observables
    const uploadTasks = [];

    // Loop through the files and add upload tasks to the array
    files.forEach((file) => {
      console.log(`Uploading file: ${file.name}`);
      const formData: FormData = new FormData();
      formData.append('uploadPic', file, file.name);

      // Add the upload observable to the tasks array
      const uploadTask = this.ivfService.uploadPicSemenAnalysisReport(
        this.ivfAddressId, this.prescriptionData.patientId, this.userSemenAnalysisId, formData
      ).pipe(
        // Handling success and error for each file upload
        tap(() => this.toastrService.success('File Successfully Uploaded')),
        catchError(error => {
          this.toastrService.error(error?.['error']?.['message']);
          return of(error); // Handle the error and return an observable
        })
      );

      uploadTasks.push(uploadTask);
    });

    // Use forkJoin to wait for all uploads to complete
    forkJoin(uploadTasks).subscribe(results => {
      this.getFiles();
    });
  }

  populateData() {

    this.ivfService.populateSemenAnalysisView(this.globalStorage.getItem('ivfAddressId'), this.userSemenAnalysisId).subscribe(res => {
      this.semenAnalysis = res?.data?.usersemenanalysis
      this.physicalAssesment = res?.data?.physicalassessment
      this.morphology = res?.data?.morphologyassessment
      this.spermMotality = res?.data?.spermMotility
      this.asfpa = res?.data?.advancesperm
      this.ivfhospitalResponce = res?.data?.ivfhospitalResponceDTO
      this.userDetails= res?.data?.userReportDTO

      if (res?.data) {
        this.pdfData = res;
        this.hospitalInfo = this.ivfhospitalResponce;
        this.genPDF()
      }
    })
  }


  pdfData: any;
  pdfBlob: any;
  downloadPdf(type) {
    this.ivfService.downloadReportPDF('spermPreparation', this.pdfData).subscribe(res => {
      this.pdfBlob = new Blob([res], { type: 'application/pdf' });
      this.processPdf(type);
    },
      error => {
        this.toastrService.error('Error downloading PDF.', error);
      }
    )
  }

  processPdf(type) {
    if (this.pdfBlob) {
      switch (type) {
        case 'download': {
          let entity_name = 'SP_' + this.prescriptionData.patientId + '.pdf';
          this.pdfService.downloadPDF(this.pdfBlob, entity_name);
          break;
        }
        case 'print': {
          this.pdfService.openPrintWindow(this.pdfBlob);
          break;
        }
      }
    } else {
      this.downloadPdf(type)
    }
  }


  printPdf(type) {
    if (type) {
      this.pdfMakeService.printPdf(this.printData)
    } else {
      this.pdfMakeService.downloadPdf(this.printData)
    }
  }




  printDataWithoutHeader: any;
  genPDF() {

    const hositalAddress = this.hospitalInfo?.cellNumber + '\n' + this.hospitalInfo?.city + '\n' + this.hospitalInfo?.state + '\n' + this.hospitalInfo.zip;
    const logo = 'data:image/jpeg;base64,' + this.hospitalInfo?.logo;



    this.printData = {
      pageMargins: [20, 130, 20, 10], // [left, top, right, bottom]
      header: function (currentPage, pageCount) {
        return {
          columns: [
            {
              image: logo,
              width: 130,
              height: 120,
              alignment: 'right'
            },
            {
              text: hositalAddress,
              alignment: 'right',
              width: '*',
            },
          ],
          margin: [20, 10, 20, 120],
        };
      },

      content: [

        {
          columns: [
            { text: 'Patient ID:'+this.semenAnalysis?.patientId, alignment: 'left' },
            { text: 'Patient Name:'+this.userDetails?.firstName +this.userDetails?.lastName,  alignment: 'center' },
            { text: 'Date:'+this.semenAnalysis?.timeOfCollection, alignment: 'right' }


          ],
          margin: [0, 10, 0, 10],
        },


        {
          table: {
            widths: ['*'],
            body: [[{ text: "Sperm Collection  ", alignment: 'center', style: 'filledHeader' }]]
          }
        },


        {
          columns: [
            { text: 'Place Of collection:'+this.semenAnalysis?.placeOfCollection, alignmet: 'leftleft' },
            { text: 'Time Of Collection:'+this.semenAnalysis?.timeOfCollection, alignment: 'left' },
            { text: 'Time Of Evaluation:'+this.semenAnalysis?.timeOfEvaluation, alignment: 'left' }
          ],
          margin: [0, 10, 0, 10],
        },

        {
          columns: [
            { text: 'Liquefaction:'+this.semenAnalysis?.liquefaction, alignmet: 'left' },
            { text: 'Spillage:'+this.semenAnalysis?.spillage, alignment: 'left' },
            { text: 'Viscosity:'+this.semenAnalysis?.viscosity, alignment: 'left' }
          ],
          margin: [0, 10, 0, 10],
        },


        {
          columns: [
            { text: 'Abstinence (Days):'+this.semenAnalysis?.daysOfAbstinence, alignmet: 'left' },
            { text: 'PH:'+this.semenAnalysis?.ph, alignment: 'left' },
            { text: 'Appearance:'+this.semenAnalysis?.color, alignment: 'left' }
          ],
          margin: [0, 10, 0, 10],
        },
        
        {
          table: {
            widths: ['*'],
            body: [[{ text: "Sperm Information ", alignment: 'center', style: 'filledHeader' }]]
          }
        },
        {
          columns: [
            { text: 'Volume (ml):'+this.physicalAssesment?.volume, alignmet: 'left' },
            { text: 'Sperm Conc(millions / ml):'+this.physicalAssesment?.spermConc, alignment: 'left' },
            { text: 'Total Ejaculate:'+this.physicalAssesment?.totalEjaculate, alignment: 'left' }
          ],
          margin: [0, 10, 0, 10],
        },

        {
          columns: [
            { text: 'Epithelial Cells:'+this.physicalAssesment?.toEpithelialCells, alignmet: 'left' },
            { text: 'Live (%):'+this.physicalAssesment?.live, alignment: 'left' },
            { text: 'Dead (%):'+this.physicalAssesment?.dead, alignment: 'left' }
          ],
          margin: [0, 10, 0, 10],
        },

        {
          columns: [
            { text: 'RBC:'+this.physicalAssesment?.rbc, alignmet: 'left' },
            { text: 'Pus Cells (Hpf):'+this.physicalAssesment?.pusCells, alignment: 'left' },
            { text: 'Agglutination:'+this.physicalAssesment?.agglutination, alignment: 'left' }
          ],
          margin: [0, 10, 0, 10],
        },
        {
          columns: [
            { text: 'Fructose:'+this.physicalAssesment?.fructose, alignmet: 'left' },
            { text: 'Impression:'+this.physicalAssesment?.impression, alignment: 'center' },
          ],
          margin: [0, 10, 0, 10],
        },

        {
          table: {
            widths: ['*'],
            body: [[{ text: "Sperm Motility", alignment: 'center', style: 'filledHeader' }]]
          }
        },
        {
          columns: [
            { text: 'Rapid Progression (%):'+this.spermMotality?.rapidProgressiveGradea, alignmet: 'left', },
            { text: 'Slow Progression(%) :'+this.spermMotality?.slowProgressiveGradeb, alignment: 'left', },
          ],
          margin: [0, 10, 0, 0],
        },
        {
          columns: [
            { text: 'Non Progressive (%):'+this.spermMotality?.nonProgressiveGradec, alignmet: 'left', },
            { text: 'Immotile(%) :'+this.spermMotality?.immotileGraded, alignment: 'left', },
          ],
          margin: [0, 10, 0, 10],
        },


        {
          table: {
            widths: ['*'],
            body: [[{ text: "Morphology", alignment: 'center', style: 'filledHeader' }]]
          }
        },
        {
          columns: [
            { text: 'Head Defects(%):'+this.morphology?.headDefects, alignmet: 'left' },
            { text: 'Mid Piece And Neck Defects(%):'+this.morphology?.headDefects, alignment: 'left' },
          ],
          margin: [0, 10, 0, 10],
        },

        {
          columns: [
            { text: 'Defects InHead&Tail(%):'+this.morphology?.headDefects, alignmet: 'left' },
            { text: 'Normal Forms(%):'+this.morphology?.normalForms, alignment: 'left' },

          ],
          margin: [0, 10, 0, 10],
        },

        {
          columns: [
            { text: 'Cytoplasmic Droplets(%):'+this.morphology?.cytoplasmicDroplets, alignmet: 'left' },
            { text: 'TZI Index:'+this.morphology?.tziIndex, alignment: 'left' },

          ],
          margin: [0, 10, 0, 10],
        },

        {
          columns: [
            { text: 'Tail Defects(%):'+this.morphology?.tailDefects, alignment: 'left' },
            { text: 'Over All Defects(%):'+this.morphology?.overAllDefects, alignment: 'left' }

          ],
          margin: [0, 10, 0, 10],
        },

        {
          table: {
            widths: ['*'],
            body: [[{ text: "ASFPA ", alignment: 'center', style: 'filledHeader' }]]
          }
        },

        {
          columns: [
            { text: 'HOS:'+this.asfpa?.hos, alignmet: 'left' },
            { text: 'Zona Binding Potential Of Sperm:'+this.asfpa?.zona, alignment: 'left' },
          ],
          margin: [0, 10, 0, 20],
        },

        {
          columns: [
            { text: 'Final Impression:'+this.asfpa?.finalSemenAnalysis,alignment: 'left', bold: true },

          ],
          margin: [0, 10, 0, 10],
        },



      ],
      styles: {
        filledHeader: {
        bold: true,
        fontSize: 14,
        color: 'black',
        fillColor: 'lightgrey',
        alignment: 'center',
        }
        }
    }

  }
}