<div class="modal-header">
    <h4 class="centerHeader mb-0">Suppliers Details</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <table class="table table-bordered table-hover table-styled table-striped">
        <thead>
            <tr role="row">
                <th>Supplier</th>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Unit Price</th>
                <th>Deal</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of suppliers | paginate: { id: 'p1', itemsPerPage: 10, currentPage: p1 }">
                <td>
                    <a href="javascript:void(0)" (click)="myInfo(data)">{{data?.supplierName}}</a>
                </td>
                <td>{{data?.catagory}}</td>
                <td>{{data?.itemName}}</td>
                <td>{{data?.unitPrice}}</td>
                <td>{{data?.deal}}</td>
                <td>
                    <button class="btn btn-primary btn-xs btn-icon"
                        data-original-title="Edit" title="Edit"
                        (click)="suppliercategoryEdit(data)"><i class="fa fa-edit"></i></button>
                    <button class="btn btn-success btn-xs ml-2" data-original-title="Place Order" title="Place Order"
                        data-toggle="tooltip" (click)="placeOrder(data)"><i class="fas fa-people-carry"></i></button>
                </td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="suppliers?.length == 0 || suppliers?.length == null">
        <div class="no-data-found"></div>
    </div>
    <div *ngIf="suppliers?.length > 10">
        <div class="text-right mt-3">
            <ul class="pagination">
                <pagination-controls autoHide="true" id="p1" (pageChange)="p1=$event"></pagination-controls>
            </ul>
        </div>
    </div>
</div>