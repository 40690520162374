import { Injectable } from '@angular/core';
import * as saveAs from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class PdfServiceService {

  constructor() { }

  downloadPDF(blob,name){
    saveAs(blob, name);
  }

  openPrintWindow(blob){
    let pdfUrl = window.URL.createObjectURL(blob);

    // Create an iframe element
    let iframe = document.createElement('iframe');
    iframe.style.display = 'none'; // Hide the iframe
    iframe.src = pdfUrl;
    document.body.appendChild(iframe);

    // Wait for the PDF to load in iframe and then print
    iframe.onload = function () {
      try {
        iframe.contentWindow.print();
      } catch (e) {
        console.error('Error while printing the PDF:', e);
      }
    };
  }
}
