<div class="modal-header">
    <h4 class="modal-title">Surgery Notes</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="notesForm">
        <div class="row">
          <div class="col-md-12 form-group" *ngIf="!isView">
            <label>Start Time</label>
            <input type="text" formControlName="startTime" class="form-control mb-0" [ngClass]="{
                    'is-invalid':
                      (submitted || n.startTime.touched) && n.startTime.errors
                  }" readonly />
    
            <div *ngIf="(submitted || n.startTime.touched) && n.startTime.errors" class="invalid-feedback">
              <div *ngIf="n.startTime.errors.required">
                StartTime is required
              </div>
            </div>
          </div>
    
          <div class="col-md-12 form-group">
            <label>Description</label>
            <textarea autoResizeRows class="form-control " rows="8" cols="50" name="message" formControlName="description"
              placeholder="Enter Description" [readonly]="isView"></textarea>
    
            <div *ngIf="(submitted || n.description.touched) && n.description.errors" class="invalid-feedback">
              <div *ngIf="n.description.errors.required">
                Description is required
              </div>
            </div>
          </div>
          <div class="col-md-12 form-group" *ngIf="!isView">
            <button type="submit" class="btn btn-success float-right"
              (click)="saveNotes(notesForm)">Save</button>
          </div>
        </div>
      </form>
</div>
