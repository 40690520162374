import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { GlobalStorageService } from 'src/app/global-storage.service';

@Component({
  selector: 'app-edit-room',
  templateUrl: './edit-room.component.html',
  styleUrls: ['./edit-room.component.css']
})
export class EditRoomComponent implements OnInit {
  @Input() roomDetails:any
  submitted:boolean=false;selectedDepartment;
  roomForm:FormGroup;
  feets: any[] = ['Ground Floor',1, 2, 3, 4, 5, 6, 7, 8, 9, 10,11,12,13,14,15];
  constructor(private globalStorage: GlobalStorageService, private activeModal: NgbActiveModal,
    private clinicService: ClinicService,private formBuilder: FormBuilder,
    private toaster: ToastrService) { }

  ngOnInit(): void {
    console.log('room data',this.roomDetails)
    this.roomForm = this.formBuilder.group({
      "description": [''],
      "floorNumber": [''],
      "noOfbeds": [''],
      "hospitaladdressId": parseInt(this.globalStorage.getItem('hospitalAddressId')),
      "operationTheatreId": [''],
      "price": ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1)]],
      "roomName": ['',[Validators.required,Validators.pattern("^[a-zA-Z0-9&\\s-]*$")]],
      "roomNumber":[''],
      "roomType": ['',Validators.required],
      "roomTypeId":[''],
      "departmentId":this.departmenId,
      "roomId":[],
      "department":['']
  })
  this.fetchRoomTypes()
  this.getRoomDetails()
  }
  get f() { return this.roomForm.controls; }
  roomInfo
  getRoomDetails(){
    this.clinicService.getRoomDetailsByRoomId(parseInt(this.globalStorage.getItem('hospitalAddressId')),this.roomDetails?.roomId).subscribe(res=>{
      this.roomInfo=res['body']?.['data']
      console.log('form details',res)
      this.departmenId=this.roomInfo?.departmentId
      var floor
      if(this.roomInfo?.floorNumber==0){
        floor='Ground Floor'
      }
      else{
        floor=this.roomInfo?.floorNumber
      }
      this.roomForm.patchValue({
        "description": this.roomInfo?.description,
        "floorNumber": floor,
        "noOfbeds": this.roomInfo?.noOfbeds,
        "hospitaladdressId": this.roomInfo?.hospitaladdressId,
        "operationTheatreId": -1,
        "price": this.roomInfo?.price,
        "roomName": this.roomInfo?.roomName,
        "roomNumber":this.roomInfo?.roomNumber,
        "roomType": this.roomInfo?.roomType,
        "roomTypeId":this.roomInfo?.roomTypeId,
        "departmentId":this.departmenId,
        "roomId":this.roomInfo?.roomId,
        "department":this.roomInfo?.departmentName,
      })
    },error=>{
      this.toaster.error(error?.error?.message)
    })
  }

  closeModal(){
    this.activeModal.close();
  }
  alphaOnly(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp('^[a-zA-Z \-\']+');
    return pattern.test(value);
  }
  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z]+$/);
    return pattern.test(value);
  }
  selectedRoomType;
  selectedRoomTypeId;
  drpdownSelection(data){
    this.selectedRoomTypeId=data?.roomTypeId
  }
  drpdownSelectionDepartment(data){

  }
  departmenId;
  getSelectedDept(department){
    this.departmenId = department?.departmentId;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  roomTypesData;
  fetchRoomTypes() {
    this.fetchDepartments();
    let hospitaAddressId = parseInt(this.globalStorage.getItem('hospitalAddressId'));
    this.clinicService.getroomTypeInformation(hospitaAddressId).subscribe(res => {
      this.roomTypesData = res?.['data'];
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }
  departments
  fetchDepartments() {
    let hospitaAddressId = parseInt(this.globalStorage.getItem('hospitalAddressId'));
    this.clinicService.getdepartmentInformation(hospitaAddressId).subscribe(res => {
      this.departments = res?.['data'];
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }
  onSubmit(roomData){
    this.activeModal.close(roomData)
  }

}
