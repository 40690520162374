<ul class="dctr-bs-breadcum">
    <li *ngIf="!isDoctorDs && !isIvy"><a style="margin-right: 1px;" routerLink="/clinic-dashboard"><i class="fa fa-home"
                aria-hidden="true"></i></a></li>
    <li *ngIf="isDoctorDs"><a style="margin-right: 1px;" routerLink="/dashboard"><i class="fa fa-home"
                aria-hidden="true"></i></a></li>
    <li><a class="p-0">Base Scheduler</a></li>
</ul>



<div class="" style="width: 100%;">

    <div style="float: left;margin-left: 14px;padding-top:1px;margin-bottom: 8px;">
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
            [(ngModel)]="selectedOption">
            <mat-radio-button class="example-radio-button" *ngFor="let option of options" [value]="option"
                (change)="checkBoxCheck($event,option)">
                {{option}}
            </mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="table-responsive">
        <table class="table-baseshedule bs-height" cellpadding="0" cellspacing="0" id="our_table">
            <thead>
                <tr class="topth">
                    <th class="sticky-col first-col"><label>Days</label></th>
                    <th *ngFor="let h of h">{{h}}</th>
                </tr>
            </thead>
            <tbody>
                <tr id="{{day.day}}" *ngFor="let day of days" class="slot-selection enable-slot">
                    <th class="sticky-col first-col"><label>{{day.day}}</label></th>
                    <td *ngFor="let h of day.H" (click)="clickedOnTd(h,$event)">
                        <span></span>
                        <p style="margin:0; padding:0; font-size:11px"></p>
                        <label style="display: none;">{{h}}</label>
                    </td>
                </tr>
            </tbody>

        </table>
    </div>
</div>

<h6 class="mt-2 float-left">Note : Baseschedule will be created for one month</h6>
<div *ngIf="!isAll">
    <button *ngIf="!isEdit && !isNew" style="float: right;margin-top: 15px;" class="btn btn-primary btn-sm mr-2"
        (click)="Edit()"><i class="fa fa-edit" aria-hidden="true"></i> Edit</button>
    <button *ngIf="isEdit && !isNew" style="float: right;margin-top: 15px;" class="btn btn-success btn-sm mr-2"
        (click)="updateBaseScheduler()"><i class="fa fa-save" aria-hidden="true"></i> Update</button>
    <button *ngIf="isEdit && !isNew" style="float: right;margin-top: 15px;margin-right: 12px;"
        class="btn btn-danger btn-sm mr-2" (click)="cancellFun()"> <i class="fa fa-close" aria-hidden="true"></i>
        Cancel</button>
    <button *ngIf="isNew" style="float: right;margin-top: 15px;margin-right: 12px;" class="btn btn-danger btn-sm mr-2"
        (click)="Reset()"><i class="fa fa-sync"></i> Reset</button>
    <button *ngIf="isNew" style="float: right;margin-top: 15px;" class="btn btn-success btn-sm mr-2"
        (click)="NewBaseScheduler()"><i class="fas fa-save"></i> Save</button>
</div>