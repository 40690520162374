import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { DoctorVideoService } from '../../../app/shared/services/doctorvideo.service';
import { CancelAppintmentComponent } from 'src/app/DoctorPages/cancel-appintment/cancel-appintment.component';
import { RescheduleAppointmentComponent } from 'src/app/DoctorPages/reschedule-appointment/reschedule-appointment.component';
import { ObservableService } from 'src/app/shared/services/observable.service';
import { PatientService } from 'src/app/shared/services/patient.service';
import { DomSanitizer } from '@angular/platform-browser';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import { interval, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as saveAs from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-all-appointments',
  templateUrl: './all-appointments.component.html',
  styleUrls: ['./all-appointments.component.css']
})
export class AllAppointmentsComponent implements OnInit {

  constructor(private globalStorage: GlobalStorageService, private DoctorVideo: DoctorVideoService, private appointment: PatientScheduleControlService,private modalService:NgbModal,
    private sanitizer: DomSanitizer, private profileImage: PatientService, private observable: ObservableService, private toaster: ToastrService, private doctorService: DoctorService, private patientService: DoctorPatientService, private toastrService: ToastrService,
    private matDialog: MatDialog, private fb: FormBuilder, private router: Router,private videoService: DoctorVideoService,) { }

  ngOnInit(): void {
    var toDay = new Date()
    var today = moment(toDay).format('YYYY-MM-DD');
    var date: any = new Date()
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds
    const source = interval(1000);
    const text = strTime;
    this.subscription = source.subscribe(val => this.updater(text));
    this.AllAppointMent()
    this.getInpatientList();
    this.getFollowUps();
  }
  MedicationPrescriptions
  presentTime; subscription: Subscription;
  medicalTestPrescription
  MyAppointments
  AllAppointMents: any[] = []
  currentApointments: any[] = []
  upComingApointments: any[] = []
  previousApointments: any[] = []
  canceledApointments: any[] = []
  p1 = 1;
  p11 = 1;
  p2 = 1;
  p3 = 1;
  p4 = 1;
  p5 = 1;
  images
  imageSource
  convertH2M(timeInHour) {
    var timeParts = timeInHour.split(":");
    return Number(timeParts[0]) * 60 + Number(timeParts[1]);
  }
  updater(time) {
    this.presentTime = this.convertH2M(time)
  }

  followUpAppointments=[]
  p6=1;
  getFollowUps(){
    this.p6=1
    this.doctorService.getFollowUps(this.globalStorage.getItem('doctorId'),4,1).subscribe(res => {
      this.followUpAppointments = res['data']
    })
  }

  AllAppointMent() {
    this.doctorService.myALLAppoinments(JSON.parse(this.globalStorage.getItem('doctorId'))).subscribe(res => {
      this.currentApointments = res['data']['CURRENT']['data']
      this.upComingApointments = res['data']['UPCOMING']['data']
      this.previousApointments = res['data']['PREVIOUS']['data']
      this.canceledApointments = res['data']['CANCELLED']['data']
      //this.getProfilePic(this.canceledApointments)
      if (res['data'] != null) {

        this.previousApointments.forEach(function (element) {
          element.Appointment = "Previous";
        });
        this.canceledApointments.forEach(function (element) {
          element.Appointment = "Canceled";
        });

        this.currentApointments.forEach(function (element) {
          element.Appointment = 'Current';
        });

        this.upComingApointments.forEach(function (element) {
          element.Appointment = 'Upcoming';
        });
        this.AllAppointMents = [...this.currentApointments, ...this.upComingApointments, ...this.previousApointments, ...this.canceledApointments]
      }

    })

  }
  sort: any;
  onSort(name, field) {

    if (name == 'current') {
      this.p1 = 1;
      this.currentApointments = this.doctorService.sort(this.currentApointments, field)
    }
    if (name == 'upComing') {
      this.p1 = 1;
      this.upComingApointments = this.doctorService.sort(this.upComingApointments, field)
    }
    if (name == 'previous') {
      this.p1 = 1;
      this.previousApointments = this.doctorService.sort(this.previousApointments, field)
    }
    if (name == 'canceled') {
      this.p1 = 1;
      this.canceledApointments = this.doctorService.sort(this.canceledApointments, field)
    }
    if (name == 'assigned') {
      this.p1 = 1;
      this.assignedPatientList = this.doctorService.sort(this.assignedPatientList, field)
    }
  }

  // getProfilePic(appointmentType){
  //   for(var i=0;i<=appointmentType.length;i++){
  //     var req={
  //       "forUserId": appointmentType[i]?.userId,
  //       "relationType": "DOCTOR",
  //       "userId": appointmentType[i]?.userId
  //     }
  //     this.patientService.getPatientPersonalDetails(appointmentType[i]?.userId).subscribe(res=>{
  //       var img
  //           if(res['body']['data']['profilePic'] !=null){
  //             var src="data:image/jpeg;base64,"+res['body']['data']['profilePic']
  //             img=this.sanitizer.bypassSecurityTrustResourceUrl(src);
  //           }
  //           else{
  //             img='assets/img/user/11.png'
  //           }
  //           this.previousApointments.forEach(function (element) {
  //             element.ImageUrl =  img;
  //           });
  //         })
  //    }
  // }

  patientDetails = {}
  viewCurrentAppontment(appointmentDetails) {
    this.getPatientDetails(appointmentDetails);

  }
  debitCard; tType; paidAmount; issuedDate; invoiceID; aliment; appoitmentType;
  invoiceData(appointment) {
    this.appoitmentType = appointment['Appointment']
    this.patientName = ""
    this.pstreet1 = ""
    this.pstreet2 = ""
    this.pstate = ""
    this.pcity = ""
    this.pcountry = ""
    this.pzip = ""
    this.doctorName = ""
    this.dstreet1 = ""
    this.dstreet2 = ""
    this.dstate = ""
    this.dcity = ""
    this.dcountry = ""
    this.dzip = ""
    this.paidAmount = appointment['amountPaid']
    this.debitCard = appointment['cardnumber']
    this.tType = appointment['transactiontype']
    this.aliment = appointment['ailment']
    this.issuedDate = appointment['lastUpdatedTs']
    //this.getDocotrAddress(appointment['doctorId'])
    //this.getPatientAddress(appointment['userId'],appointment['doctorId']);

    // this.getCompleteInvoiceDetails(appointment['appointmentId'], appointment['doctorId']);

    var req = {
      "doctorappointmentid": appointment['appointmentId'],
      "doctorid": appointment['doctorId'],
      "maxResults": 10,
      "pageNum": 1,
      "patientid": appointment['userId']
    }
    this.doctorService.getInvoiveData(req).subscribe(res => {
      var data = JSON.parse(res['data']);
      this.invoiceID = JSON.parse(data['data'][0]['invoiceId'])
    })
    $('#invoice').modal('show')
  }
  closeModal() {
    $('#myprescription').modal('hide')
    this.isData = false
    // let currentUrl = this.router.url;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.navigate([currentUrl]);
  }
  getPatientAddress(id, dId) {
    var request = {

      "forUserId": id,
      "userId": dId

    }
    var localForUserId = this.globalStorage.getItem('forUserId')
    this.globalStorage.setItem("forUserId", id)
    // this.globalStorage.setItem("loggedInAs", "PATIENT")

    this.patientService.getPatientAddress('address/get', request, id).subscribe(res => {

      this.globalStorage.setItem("forUserId", localForUserId)
      // this.globalStorage.setItem("loggedInAs", "DOCTOR")
      this.patientName = res['data'][0]['contactName']
      this.pstreet1 = res['data'][0]['street1']
      this.pstreet2 = res['data'][0]['street2']
      this.pstate = res['data'][0]['state']
      this.pcity = res['data'][0]['city']
      this.pcountry = res['data'][0]['country']
      this.pzip = res['data'][0]['zip']
    });

    this.globalStorage.setItem("forUserId", localForUserId)
    // this.globalStorage.setItem("loggedInAs", "DOCTOR")
  }
  patientName; pstreet1; pstreet2; pstate; pcity; pcountry; pzip
  doctorName; dstreet1; dstreet2; dstate; dcity; dcountry; dzip
  getDocotrAddress(id) {
    this.patientService.getDoctorAddressDetails(id).subscribe(res => {
      // this.doctorName = res['data'][0]['contactName']
      this.dstreet1 = res['data'][0]['street1']
      this.dstreet2 = res['data'][0]['street2']
      this.dstate = res['data'][0]['state']
      this.dcity = res['data'][0]['city']
      this.dcountry = res['data'][0]['country']
      this.dzip = res['data'][0]['zip']
    })
  }

  getPatientDetails(appointmentDetails) {
    var localForUserId = this.globalStorage.getItem('forUserId')
    this.globalStorage.setItem("forUserId", appointmentDetails.userId)

    var req = {
      "forUserId": appointmentDetails.userId,
      "userId": JSON.parse(this.globalStorage.getItem('doctorId')),
    }
    this.patientService.getPatientPersonalDetails(appointmentDetails.userId, req).subscribe(res => {
      this.globalStorage.setItem("forUserId", localForUserId)
      var patientDetails = res['body'].data;
      patientDetails.AppointmentDetails = appointmentDetails;
      patientDetails.Appointment = appointmentDetails.Appointment;
      patientDetails.Appointment = 'Current';
      patientDetails.appointmentDate = appointmentDetails.appointmentDate

      //patientDetails.Appointment='Upcoming';
      this.doctorService.appointments = appointmentDetails
      this.doctorService.OpenPatientSideNave(patientDetails);

      // if(patientDetails.Appointment=='Current'){
      //   this.DoctorVideo.getUserName(patientDetails.AppointmentDetails.appointmentId)//patientDetails.AppointmentDetails.appointmentId
      // }

    }, error => {
      this.globalStorage.setItem("forUserId", localForUserId)
      // this.globalStorage.setItem("loggedInAs", "DOCTOR")
      this.toastrService.error(error?.['error']?.['data']);
    })
  }
  getInPatientDetails(appointmentDetails) {
    var localForUserId = this.globalStorage.getItem('forUserId')
    this.globalStorage.setItem("forUserId", appointmentDetails.patientId)

    var req = {
      "forUserId": appointmentDetails.patientId,
      "userId": appointmentDetails.patientId,
    }
    this.patientService.getPatientPersonalDetails(appointmentDetails.patientId, req).subscribe(res => {
      this.globalStorage.setItem("forUserId", localForUserId)
      var patientDetails = res['body'].data;
      if (patientDetails != null) {
        patientDetails['AppointmentDetails'] = appointmentDetails;
        patientDetails['Appointment'] = appointmentDetails.Appointment;
        patientDetails['Appointment'] = 'Current';
        patientDetails['type'] = 'Hospital';
        patientDetails['appointmentDate'] = appointmentDetails.appointmentDate
      }
      //patientDetails.Appointment='Upcoming';
      this.doctorService.appointments = appointmentDetails
      this.doctorService.OpenPatientSideNave(patientDetails);

      // if(patientDetails.Appointment=='Current'){
      //   this.DoctorVideo.getUserName(patientDetails.AppointmentDetails.appointmentId)//patientDetails.AppointmentDetails.appointmentId
      // }

    }, error => {
      this.globalStorage.setItem("forUserId", localForUserId)
      // this.globalStorage.setItem("loggedInAs", "DOCTOR")
      this.toastrService.error(error?.['error']?.['data']['message']);
    })
  }


  patientMedicalReports
  getPatientMedicalReports(appointment) {
    var patient = {
      "forUserId": appointment.userId,
      "userId": appointment.userId
    }
    // this.patientService.getPatientMedicalReports(patient).subscribe(res=>{
    //   var body=res['body']
    //   this.patientMedicalReports=body['data']
    //  },error=>{
    // this.toastrService.error("error on getting patient medical reports")
    //  })
  }

  reportDetails
  viewMedicalReport(report) {
    var reportObj = {
      'forUserId': report.userId,
      medicalReportId: report.medicalReportsId
    }
    var localForUserId = this.globalStorage.getItem('forUserId')
    this.globalStorage.setItem("forUserId", report.userId)
    // this.globalStorage.setItem("loggedInAs", "PATIENT")
    this.patientService.getPatientMedicalReportsByMrId(reportObj).subscribe(res => {
      this.globalStorage.setItem("forUserId", localForUserId)
      // this.globalStorage.setItem("loggedInAs", "DOCTOR")
      this.reportDetails = res['body']['data']
      this.patientService.viewMedicalReport(this.reportDetails);
    }, error => {
      this.globalStorage.setItem("forUserId", localForUserId)
      // this.globalStorage.setItem("loggedInAs", "DOCTOR")
      this.toastrService.error("Error in view Medical report")
    })
  }
  OpenVideoCalling(patientPersonalDetails) {
    patientPersonalDetails.postion = 'right'

    this.patientService.videoCalling(patientPersonalDetails);
    //this.videoCalling.open();
  }
  rejectAppointment(appintment) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = appintment;
    this.matDialog.open(CancelAppintmentComponent, dialogConfig);

  }
  notesData
  viewNotes(appointment) {
    $('#viewNotes').modal('show')
    this.doctorService.getNotes(appointment?.appointmentId).subscribe(res => {
      this.notesData = res['data']
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    })
  }

  viewNotesOfCaretaker(appointment) {
    $('#viewNotes').modal('show')
    this.doctorService.getCaretakerNotes(appointment?.hospitalAddresId, appointment?.inpatientId).subscribe(res => {
      this.notesData = res['data']
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    })
  }

  rescheduleAppointment(appintment) {
    const modalRef = this.modalService.open(RescheduleAppointmentComponent, {
      centered: false,
      windowClass: 'modal-left hw-50',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.appintmentData = appintment;

    modalRef.result.then(
      () => {
       this.AllAppointMent()
      },
      () => {
        this.AllAppointMent()
       }  // Handle dismiss callback
    );
  }
  fromAllAppointmentsDoctors; isData = false
  prescriptionList(appointment) {
    this.fromAllAppointmentsDoctors = appointment
    // this.patientService.openPrescriptionList(appointment);
    if (this.fromAllAppointmentsDoctors != undefined) {
      this.isData = true
    }
    $('#myprescription').modal('show')
    this.medicalTestPrescription = []
    this.MedicationPrescriptions = []
    // this.doctorService.getPrescription(appointment['appointmentId'], appointment['userId']).subscribe(res => {
    //   if (res['data'] != undefined) {

    //     if (res['data']['medicaltests']?.length > 0) {
    //       this.medicalTestPrescription = res['data']['medicaltests']
    //     }
    //     if (res['data']['medications']?.length > 0) {
    //       this.MedicationPrescriptions = res['data']['medications']
    //     }
    //   }

    // }, error => {
    // })

  }

  
  docinvoiceData;doctorobjres;
  getCompleteInvoiceDetails(appointment) {
    this.doctorService.getDoctorInvoiceDetails(appointment?.appointmentId, appointment?.doctorId).subscribe(res => {
      this.doctorobjres = res;
      this.docinvoiceData = res?.['data']?.[0];
      $('#doc-invoice').modal('show');
    })
  }

  downloadAsPDF() {
    this.doctorService.downloadDoctorPdf(this.doctorobjres).subscribe(res => {
      console.log(res);
      let entity_name = this.docinvoiceData?.['patientAddressDTO']?.['contactName'] + '.pdf';
      var blob = new Blob([res], { type: 'application/pdf' });
      saveAs(blob, entity_name);
    },
    error => {
      this.toaster.error('Error downloading PDF.', error);
    }
    )
    // var data = document.getElementById('contentToConvert');
    // $('.invoice-info').css('display', 'none');
    // html2canvas(data).then(canvas => {
    //   var imgWidth = 208;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   const contentDataURL = canvas.toDataURL('image/png')
    //   let pdf = new jspdf('p', 'mm', 'a4');
    //   var position = 0;
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
    //   pdf.save('newPDF.pdf');
    // });
    // $('.invoice-info').css('display', 'block');
  }

  assignedPatientList: any[] = [];
  getInpatientList() {
    this.doctorService.getAssignedPatients(JSON.parse(this.globalStorage.getItem('doctorId'))).subscribe(res => {
      this.assignedPatientList = res?.['data'];
    },
      error => {
        this.toastrService.error(error?.['error']?.['message'])
      }
    )
  }

  ngAfterViewInit() {
    if (this.router.url == '/assigned-Appoinments') {
      $('.medication').removeClass('active show')
      $('#current').removeClass('active show')
      $('.assigned').addClass('active show');
      $('.assigned-patients').addClass('active show');
      $('#assigned-patients').addClass('active show');
    }
  }
  getPtDetails(appointmentDetails) {
    let mainUserId = JSON.parse(this.globalStorage.getItem('forUserId'))

    this.globalStorage.setItem('forUserId', appointmentDetails.userId)
    var req = {
      "forUserId": appointmentDetails.userId,
      "userId": JSON.parse(this.globalStorage.getItem('doctorId')),
    }

    this.patientService.getUserPersonalDetails('personaldetails/get/', appointmentDetails.userId, req).subscribe(res => {
      this.globalStorage.setItem('forUserId', mainUserId)
      var patientDetails = res['body']['data'];
      patientDetails.AppointmentDetails = appointmentDetails;
      patientDetails.Appointment = 'Current';
      patientDetails.AppointmentType = 'Privious';
      // patientDetails.type = 'Hospital';
      //patientDetails.Appointment='Upcoming';
      this.doctorService.OpenPatientSideNave(patientDetails);
    }, error => {
      this.globalStorage.setItem('forUserId', mainUserId)
      this.toastrService.error("Error in getting patient details");
    })
  }
  appointmentData
  checkTime(appointment, x, y) {
    this.appointmentData = appointment
    var date: any = new Date()
    var hours: any = date.getHours();

    var minutes = date.getMinutes();
    var seconds = date.getSeconds()
    date = moment(date).format('YYYY-MM-DD');
    var ampm = hours >= 12 ? 'PM' : 'AM';
    //hours = hours % 12;

    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    if (date == x) {
      y = (y.slice(0, -6) * 60)
      hours = (hours * 60)
      if ((y - hours) <= 5 || hours > y) {
        //this.toastrService.warning("yes")
      }
      else {
        this.toastrService.warning("Video can only open 5 minutes before of the appointment.")
        return
      }
      // this.appointmentData.fromTime?.slice(0,-6)*60
    }

  }
  videoCall(appointment, x, y, z) {
    let a5 = Date.now()
    this.checkTime(appointment, x, y)
    this.globalStorage.setItem('videoUserId', appointment['userId'])
    // appointment.postion='right'
    this.getPtDetails(appointment);
    this.videoService.getUserName(appointment['appointmentId'], appointment['userId'])
    this.patientService.videoCalling(appointment);
  }
}
