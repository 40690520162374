import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CaretakerService } from 'src/app/shared/services/caretaker.service';
import { DatePipe } from '@angular/common';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';

@Component({
  selector: 'app-user-vitals-history',
  templateUrl: './user-vitals-history.component.html',
  styleUrls: ['./user-vitals-history.component.css']
})
export class UserVitalsHistoryComponent implements OnInit {

  @Input() vitalObj;
  todayDate: string = new Date().toLocaleDateString();
  @Input() userId:any;
  fromDatee;
  toDatee;
  formattedFromDate: string;
  formattedToDate: string;
  p1 = 1;today;
  @Input() vitalName;

  constructor(private globalStorage: GlobalStorageService, private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private caretakerService: CaretakerService,
    private datePipe: DatePipe, private ivfService:IvfService,private clinicService:ClinicService) { }

  ngOnInit(): void {
     console.log('incoming vital:', this.vitalObj)
    this.vitalHistory();
    this.today=this.datePipe.transform( new Date(), 'yyyy-MM-ddTHH:mm:ss')
  }

  closeModal() {
    this.activeModal.close('Close click');
  }

  vitalHist; vital_name;
  vitalHistory() {
    if (this.vitalObj?.vitalname == "pulse") {
      this.vital_name = "pulse";
    }
    if (this.vitalObj?.vitalname == "height") {
      this.vital_name = "height";
    }
    if (this.vitalObj?.vitalname == "weight") {
      this.vital_name = "weight";
    }
    if (this.vitalObj?.vitalname == "bloodPressure") {
      this.vital_name = "bloodPressure";
    }
    if (this.vitalObj?.vitalname == "temperature") {
      this.vital_name = "temperature";
    }
    if (this.vitalObj?.vitalname == "oxygenLevel") {
      this.vital_name = "oxygenLevel";
    }
    if (this.vitalObj?.vitalname == "fasting") {
      this.vital_name = "bloodglucose";
    }
    if (this.fromDatee) {
      this.formattedFromDate = this.datePipe.transform(this.fromDatee, 'yyyy-MM-ddTHH:mm:ss');
    }
    if (this.toDatee) {
      this.formattedToDate = this.datePipe.transform(this.toDatee, 'yyyy-MM-ddTHH:mm:ss');
    }
    let body = {
      // "recordedDate":  DateFormat('yyyy-MM-ddTHH:mm:ss').format(DateTime.now()),
      "fromDate": this.formattedFromDate ? this.formattedFromDate : null,
      "toDate": this.formattedToDate ? this.formattedToDate : null,
      "userId": this.vitalObj?.userId? this.vitalObj?.userId:this.userId,
      "vitalname": this.vital_name?this.vital_name:this.vitalName
    }

    this.getVitalHistory(body);

  }

  getVitalHistory(body){

    switch(this.globalStorage.getItem('loggedInAs')){
      case 'CARETAKER':
        this.caretakerService.userPreVitalsHistory(body).subscribe(res => {
          this.vitalHist = res?.['data'] || [];
          this.p1 = 1;
        },
          error => {
            this.toastr.error(error?.['error']?.['message']);
          }
        )
        break;
      case 'IVF':
        this.ivfService.userPreVitalsHistory(body).subscribe(res => {
          this.vitalHist = res?.['data'] || [];
          this.p1 = 1;
        },
          error => {
            this.toastr.error(error?.['error']?.['message']);
          }
        )
        break;
      case 'HOSPITAL':
        this.clinicService.userPreVitalsHistory(body).subscribe(res => {
          this.vitalHist = res?.['data'] || [];
          this.p1 = 1;
        },
          error => {
            this.toastr.error(error?.['error']?.['message']);
          }
        )
        break;
    }
  }

  clearDates(){
    this.fromDatee = '';
    this.toDatee = '';
  }

}
