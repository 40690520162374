<div class="modal-header">
    <h4 class="centerHeader mb-0">Edit Room</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="roomForm" (ngSubmit)="onSubmit(roomForm)" autocomplete="off" class="mt-2">
        <div class="row">
          <div class="col-md-6 form-group">
            <label for="exampleInputPassword1">Room Name</label><span class="text-danger">*</span>
            <input type="text" formControlName="roomName" (keypress)="alphaOnly($event)" class="form-control mb-0"
              style="text-transform: capitalize;" [ngClass]="{
                      'is-invalid':
                        (submitted || f.roomName.touched) && f.roomName.errors
                    }" placeholder="Enter Room Name" />

            <div *ngIf="(submitted || f.roomName.touched) && f.roomName.errors" class="invalid-feedback">

              <div *ngIf="f.roomName.errors.required">
                Room Name is required.
              </div>
              <div *ngIf="f.roomName.errors.pattern">
                only and(&) and hyphens(-) are allowed.
              </div>
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label for="exampleInputPassword1">Room Number</label><span class="text-danger">*</span>
            <input type="text" formControlName="roomNumber" appInputRestrict="numberOnly" (keypress)="alphaNumarics($event)"
              class="form-control mb-0" maxlength="6" [ngClass]="{
                      'is-invalid':
                        (submitted || f.roomNumber.touched) && f.roomNumber.errors
                    }" placeholder="Enter Room Number" readonly/>

            <div *ngIf="(submitted || f.roomNumber.touched) && f.roomNumber.errors" class="invalid-feedback">
              <div *ngIf="f.roomNumber.errors.required">
                Room Number is required.
              </div>

              <div *ngIf="f.roomNumber.errors.pattern">
                only and(&) and hyphens(-) are allowed.
              </div>
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label for="exampleInputPassword1">Room Type</label><span class="text-danger">*</span>
            <mat-select placeholder="Select..." [(ngModel)]="selectedRoomType" formControlName="roomType"
                class="form-control" 
                [ngClass]="{'is-invalid': (submitted || f.roomType.touched) && f.roomType.errors}">
                <mat-option *ngFor="let data of roomTypesData" [value]="data.roomType" (click)="drpdownSelection(data)">
                    {{ data.roomType }}
                </mat-option>
            </mat-select>

            <div *ngIf="(submitted || f.roomType.touched) && f.roomType.errors" class="invalid-feedback">
              <div *ngIf="f.roomType.errors.required">
                Room Type required
              </div>

              <div *ngIf="f.roomType.errors.pattern">
                Room Type should be alphabets.
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="form-group">
                <label>Department <span class="text-danger">*</span></label>
                <mat-select placeholder="Select..." [(ngModel)]="selectedDepartment" formControlName="department"
                    class="form-control" (selectionChange)="drpdownSelectionDepartment(selectedDepartment)"
                    [ngClass]="{ 'is-invalid': (submitted || roomForm.get('department').touched) && roomForm.get('department').errors}" disabled>
                    <mat-option *ngFor="let department of departments" [value]="department.departmentName"
                        (click)="getSelectedDept(department)">
                        {{ department.departmentName }}
                    </mat-option>
                </mat-select>
                <div *ngIf="(submitted || roomForm.get('department').touched) && roomForm.get('department').errors"
                    class="invalid-feedback">
                    <div *ngIf="roomForm.get('department').errors.required">Please Select Department.</div>
                </div>
            </div>
        </div>
          <div class="col-md-6 form-group">

            <label for="exampleInputPassword1">Number Of Beds</label><span class="text-danger">*</span>

            <input type="number" formControlName="noOfbeds" class="form-control mb-0" (keypress)="numberOnly($event)"
              [ngClass]="{
          'is-invalid': (submitted || roomForm.get('noOfbeds').touched) && roomForm.get('noOfbeds').errors
       }" placeholder="Enter Number Of Beds" readonly />

            <div *ngIf="(submitted || roomForm.get('noOfbeds').touched) && roomForm.get('noOfbeds').errors"
              class="invalid-feedback">
              <div *ngIf="roomForm.get('noOfbeds').errors.required">
                Number of beds is required
              </div>

              <div *ngIf="roomForm.get('noOfbeds').errors.pattern">
                Number of beds should be numbers
              </div>

              <div *ngIf="roomForm.get('noOfbeds').errors.min">
                Number of beds should be minimum 1
              </div>
            </div>



          </div>
          <div class="col-md-6 form-group">
            <label for="exampleInputPassword1">Floor Number</label><span class="text-danger">*</span>
            <!-- <input type="email" autocomplete="nope" formControlName="floorNumber" id="directLink" maxlength="2"
              class="form-control mb-0" (keypress)="numberOnly($event)" [ngClass]="{
                      'is-invalid':
                        (submitted || f.floorNumber.touched) && f.floorNumber.errors
                    }" placeholder="Enter Floor Number" /> -->
            <mat-select placeholder="Select Floor" class="form-control" formControlName="floorNumber" name="item" disabled
              [ngClass]="{
                  'is-invalid':
                    (submitted || f.floorNumber.touched) && f.floorNumber.errors
                }">
              <!-- <input  [(ngModel)]="selectedValue"   matInput placeholder="search"  formControlName="postalLocation" class="form-control" > -->
              <mat-option *ngFor="let item of feets" [value]="item">
                {{item}}
              </mat-option>
            </mat-select>

            <div *ngIf="(submitted || f.floorNumber.touched) && f.floorNumber.errors" class="invalid-feedback">
              <div *ngIf="f.floorNumber.errors.required">
                Floor Number is required
              </div>
            </div>
          </div>
          <div class="col-md-6 form-group">
            <label for="exampleInputEmail1">Price</label><span class="text-danger">*</span>
            <input type="text" formControlName="price" style="text-transform:capitalize" maxlength="10"
              class="form-control mb-0" [ngClass]="{
                      'is-invalid':
                        (submitted || f.price.touched) &&
                        f.price.errors
                    }" placeholder="Enter Price" />

            <div *ngIf="(submitted || roomForm.get('price').touched) && roomForm.get('price').errors"
              class="invalid-feedback">
              <div *ngIf="roomForm.get('price').errors.required">
                Price is required
              </div>

              <div *ngIf="roomForm.get('price').errors.pattern">
                Price should be numbers
              </div>

              <div *ngIf="roomForm.get('price').errors.min">
                Price should be minimum 1
              </div>
            </div>


          </div>
          <div class="col-md-12 form-group">
            <label for="exampleInputEmail1">Description</label>
            <textarea autoResizeRows rows="6" formControlName="description" style="text-transform:capitalize" noWhiteSpaceAtTheStart
              class="form-control mb-0" placeholder="Enter Description">
            </textarea>
          </div>
        </div>

        <div class="d-inline-block w-100">
          <button type="submit" class="btn btn-primary float-right" [disabled]="!roomForm.valid">
            Save
          </button>
        </div>
      </form> 
</div>
