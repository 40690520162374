<div class="modal-header">
    <h4 class="centerHeader mb-0"><i class="fas fa-archive"></i> Supplier Details</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="contact-info">
            <div class="pl-3 pt-2 pb-2"><b><i style="color: #69A75D;" class="fas pr-1 fa-chevron-circle-right"></i> Supplier Name:</b> {{supplierData?.name}}</div>
            <div class="pl-3 pt-2 pb-2"><b><i style="color: #69A75D;" class="fas pr-1 fa-chevron-circle-right"></i> Contact Name:</b> {{supplierData?.primaryContact}}</div>
            <div class="pl-3 pt-2 pb-2"><b><i style="color: #69A75D;" class="fas pr-1 fa-chevron-circle-right"></i> Contact Number:</b> {{supplierData?.primaryContactNumber}}</div>
            <div class="pl-3 pt-2 pb-2"><b><i style="color: #69A75D;" class="fas pr-1 fa-chevron-circle-right"></i> GSTIN:</b> {{supplierData?.gstin}}</div>
            <hr class="mt-2">
            <h3 class="pl-3 mt-0 pb-0 mb-0 font-size-13"><b><i style="color: #69A75D;" class="fas fa-map-marked-alt pr-2"></i>Address</b></h3>
            <p class="mt-1 font-size-12 pl-3 ml-4 pb-3">{{supplierData?.supplieraddress?.street1}} <span *ngIf="supplierData?.supplieraddress?.street2">,</span> {{supplierData?.supplieraddress?.street2}} {{supplierData?.supplieraddress?.postalLocation}}, <br> {{supplierData?.supplieraddress?.city}}, <br>
                {{supplierData?.supplieraddress?.state}} - {{supplierData?.supplieraddress?.zip}}</p>
    </div>
</div>