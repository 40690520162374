import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ivf-bulk-file-upload',
  templateUrl: './ivf-bulk-file-upload.component.html',
  styleUrls: ['./ivf-bulk-file-upload.component.css']
})
export class IvfBulkFileUploadComponent {
  @ViewChild('fileInput') fileInput: ElementRef;
  files: File[] = [];
  allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
  maxSize = 2 * 1024 * 1024; // 2 MB

  constructor(public activeModal: NgbActiveModal) { }

  onDragOver(event: Event): void {
    event.preventDefault(); // Necessary to allow us to drop.
  }

  onFilesDropped(event: DragEvent): void {
    event.preventDefault();
    this.files=[];

    const filesToAdd = event.dataTransfer?.files;
    if (filesToAdd) {
      for (let i = 0; i < filesToAdd.length; i++) {
        const file = filesToAdd.item(i);
        if (file && this.validateFile(file)) {
          this.files.push(file);
        }
      }
    }

    this.removeDragData(event);
  }


  validateFile(file: File): boolean {
    return file.size <= this.maxSize && this.allowedTypes.includes(file.type);
  }

  // Clean up the drag data
  removeDragData(event: DragEvent): void {
    if (event.dataTransfer && event.dataTransfer.items) {
      event.dataTransfer.items.clear();
    } else if (event.dataTransfer) {
      event.dataTransfer.clearData();
    }
  }

  onConfirm(): void {
    this.activeModal.close(this.files);
  }

  onFileInputClick(): void {
    // trigger the file input
    this.fileInput.nativeElement.click();
  }

  onFilesSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.files=[];
    
    if (!input.files?.length) {
      return;
    }
    
    for (let i = 0; i < input.files.length; i++) {
      const file = input.files.item(i);
      if (file && this.validateFile(file)) {
        this.files.push(file);
      }
    }
  }

  closeModal(){
    this.activeModal.close();
  }

}
