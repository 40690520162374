 
 
<div class="modal-content" style="width:300px">
        
    <div class="modal-body cancel-appoi" >

      <div class="d-flex flex-row mb-0">

        
        <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
          <p class=" mb-1 mt-1">

<i class="fa fa-user fa-2x ">  </i>
          </p>


        <p class="font-weight-medium mb-1 mt-1 heading" *ngIf="status == 'N'">Do you want to In-Activate?
        </p>
        <p class="font-weight-medium mb-1 mt-1 heading" *ngIf="status == 'Y'">Do you want to Activate?
        </p>
        <p class="font-weight-medium mb-1 mt-1 heading" *ngIf="status == null">Do you want to delete this item?
        </p>
          
        
        </div> 
   
        
        </div>



       
    </div>
    <div class="modal-footer text-center">
        <button class="btn btn-success btn-xs mr-2" (click)="Yes()">Yes</button>
        <button class="btn btn-danger btn-xs" (click)="No()">No</button>

    </div>
  </div>







