import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CalendarOptions, EventInput, FullCalendarComponent } from '@fullcalendar/angular';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from "@angular/material/dialog";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGrigPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import * as moment from 'moment';
import { interval, scheduled, Subscription } from 'rxjs';
import { CaretakerService } from 'src/app/shared/services/caretaker.service';
import { Route, Router } from '@angular/router';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DiagnosticService } from 'src/app/AppServices/diagnostic.service';
import { DialogComponentComponent } from 'projects/ivf/src/app/dialog-component/dialog-component.component';

declare var $: any;
@Component({
  selector: 'app-reschedule-appintment',
  templateUrl: './reschedule-appointment.component.html',
  styleUrls: ['./reschedule-appointment.component.css']
})
export class RescheduleAppointmentComponent implements OnInit {
  @Input() appointmentDataFromIvf: any
  @Input() appintmentData: any = {};
  @ViewChild('calender', { static: false }) public calendarComponent: FullCalendarComponent;
  calendarPlugins = [timeGrigPlugin, interactionPlugin];  //
  calendarWeekends = false;
  subscription: Subscription;
  calendarOptions: CalendarOptions = {
    initialView: 'timeGridWeek',     ///'dayGridMonth',
    // dateClick: this.dateClick.bind(this), // bind is important!
    events: [],
    weekends: true,
    editable: false,
    droppable: false,
    dayMaxEvents: true,
    //eventColor: 'blue',

    slotMinTime: '00:00:00',
    slotMaxTime: '23:30:00',
    allDaySlot: false,
    eventContent: this.renderEventContent,
    //eventAdd:this.getDoctorBaseschedule(),
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
    },
    eventClick: this.reschedule.bind(this),
    select: this.reschedule.bind(this),
    selectable: true

    // eventTimeFormat:this.eventClick.bind(this),
  };

  constructor(private globalStorage: GlobalStorageService, private doctorService: DoctorService, private caretakerService: CaretakerService, public clinicServices: ClinicService
    , public activeModal: NgbActiveModal, private toastrService: ToastrService, private router: Router, private modalService: NgbModal,
    private diagnosticService: DiagnosticService) { }

  ngOnInit(): void {
    console.log('values:', this.appintmentData);
    this.getDoctorBaseschedule();
  }


  noSlotWarning(data) {
    const startDate = new Date(data.startStr);
    const endDate = new Date(data.endStr);

    const date = startDate.toISOString().split('T')[0];

    const fromTime = startDate.toTimeString().split(' ')[0];
    const toTime = endDate.toTimeString().split(' ')[0];

    const body = {
      "appointmentId": this.appointmentDataFromIvf?.appointmentId,
      "appointmentStatus": "ENTITY_RESCHEDULED",
      "doctorId": this.appointmentDataFromIvf?.doctorId,
      "reason": null,
      "scheduleHoursId": null,
      "appointmentDate": date,
      "fromTime": fromTime,
      "toTime": toTime,
      "chatType":"Inperson"
    }

    this.clinicServices.rescheduleIvfAppointment(body).subscribe(res => {
      this.toastrService.success("Appontment Rescheduled.");
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.activeModal.close()
      this.router.navigate([currentUrl]);
    }, error => {
      this.toastrService.error(error?.['error']?.['message']);
    })

    // this.toastrService.warning('No slot available');
  }


  getCurrentTime(): string {
    const now = new Date();
    return now.toTimeString().split(' ')[0]; // Returns current time in 'HH:mm:ss' format
  }

  isEarlier(selectedDate,time1: string, time2: string): boolean {
    const today = moment().format('YYYY-MM-DD');
    if(selectedDate != today){
      return true;
    }
    return time1 >= time2; // Assuming time1 and time2 are strings in 'HH:mm:ss' format
  }

  renderEventContent(eventInfo, createElement) {

    var innerHtml;
    var openDiv
    var label2
    var label
    if (eventInfo.event._def.extendedProps.status == 'AVAILABLE') { //
      openDiv = "<div style='background-color:green;height:15px;display:flex' class='event_Avalable'>"
      label = "<label style='width:70px'>" + eventInfo.timeText + "</label>"
    } else {
      openDiv = "<div id='c' style='background-color:red;height:15px;pointer-events: none;' id='event_NotAvailable'>"
      label2 = "<label class='pe-none' style='display:inline-block;width:70px'>" + eventInfo.timeText + "</label>"
    }
    var button = "<a type='button' class='float-right' style='margin-left:8px' data-toggle='tooltip' title='Reschedule' (click)='reschedule()'><i class='fa fa-calendar '></i></a>"
    var closeDiv = "</div>"
    if (eventInfo.event._def.extendedProps.status == 'AVAILABLE') {
      innerHtml = openDiv + label + button + closeDiv + closeDiv;
    } else {
      innerHtml = openDiv + label2 + closeDiv + closeDiv;
    }


    return createElement = { html: '<div>' + innerHtml + '</div>' }

  }

  close() {
    // this.dialogRef.close();
  }
  cancel() {
    console.log('this.selectedData', this.selectedData)
    var slot = this.selectedData?.el?.innerText;
    var time = slot?.split('-')
    var fromTime = (time[0]?.trim() + ':00')?.trim();
    var toTime = (time[1]?.trim() + ':00')?.trim()
    var selectedDate = this.selectedData.el.fcSeg.end
    var month = selectedDate.getMonth() + 1
    var day = selectedDate.getDate();
    var year = selectedDate.getFullYear();
    var shortStartDate = year + "-" + month + "-" + day;

    this.appintmentData['appointmentDate'] = shortStartDate;
    this.appintmentData['fromTime'] = fromTime?.trim();
    this.appintmentData['toTime'] = toTime?.trim();

    this.appintmentData['schedulehoursId'] = this.selectedData.event._def.extendedProps.scheduleHoursId
    //schedulehoursId:this.doctorScheduler[i].scheduleHours[j].schedulehoursId
    //this.appintmentData.toTime=toTime.trim();

    if (this.selectedData.event._def.extendedProps.status != 'BLOCKED' && (this.router.url == '/dashboard' || this.router.url == '/Appoinments') && this.globalStorage.getItem('loggedInAs') !== 'IVF') {
      // this.dialogRef.close();
      this.doctorService.rescheduleAppointment(this.appintmentData).subscribe(res => {
        this.toastrService.success("Appointment rescheduled.");
        this.doctorService.reloadCurrentAppointment();
        this.getDoctorBaseschedule();
        this.activeModal.close()
      }, error => {
        this.toastrService.error(error['error']['message']);
      });
    }
    console.log('appointmentDate', this.appintmentData)

    //DC Appointments Reschedule
    if (this.selectedData.event._def.extendedProps.status != 'BLOCKED' && (this.router.url == '/diagnosticDashboard' || this.router.url == '/diagnosticAppointments')) {
      // this.dialogRef.close();
      this.diagnosticService.rescheduleDcAppointment(this.appintmentData).subscribe(res => {
        this.toastrService.success("Appointment rescheduled.");
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
        this.getDoctorBaseschedule();
        this.activeModal.close()

      }, error => {
        this.toastrService.error(error['error']['message']);
      });
    }
    //DC Appointments Reschedule End

    if (this.selectedData.event._def.extendedProps.status != 'BLOCKED' && (this.router.url == '/caretaker-dashboard' || this.router.url == '/caretaker-appointments')) {
      // this.dialogRef.close();
      this.caretakerService.rescheduleAppointment(this.appintmentData).subscribe(res => {
        this.toastrService.success("Appointment rescheduled.");
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
        this.getDoctorBaseschedule();
        this.activeModal.close()

      }, error => {
        this.toastrService.error(error['error']['message']);
      });
    }
    if (this.globalStorage.getItem('loggedInAs') == 'IVF') {
      var body = {
        "appointmentId": this.appointmentDataFromIvf?.appointmentId,
        "appointmentStatus": "ENTITY_RESCHEDULED",
        "doctorId": this.appointmentDataFromIvf?.doctorId,
        "reason": null,
        "scheduleHoursId": this.appintmentData.schedulehoursId
      }
      this.clinicServices.rescheduleIvfAppointment(body).subscribe(res => {
        this.toastrService.success("Appontment Rescheduled.");
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.activeModal.close()
        this.router.navigate([currentUrl]);
      }, error => {
        this.toastrService.error(error?.['error']?.['message']);
      })
    }
    if (this.router.url == '/clinic-dashboard' || this.router.url == '/clinic-appointments' || this.router.url == '/clinic-doctor-dashboard') {
      // this.dialogRef.close();
      this.clinicServices.rescheduleHospitalAppointment(this.appintmentData).subscribe(res => {
        this.toastrService.success("Appontment Rescheduled.");
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.activeModal.close()
        this.router.navigate([currentUrl]);
      }, error => {
        this.toastrService.error(error?.['error']?.['message']);
      })
    }
    if (this.selectedData.event._def.extendedProps.status == 'BLOCKED') {
      this.toastrService.error('This slot is already blocked.');
      return
    }
  }
  selectedData
  reschedule(sheduleDate) {

    
    if(sheduleDate?.startStr){
      const startDate = new Date(sheduleDate?.startStr);
    const date = startDate.toISOString().split('T')[0];

    const fromTime = startDate.toTimeString().split(' ')[0];

    if (!this.isEarlier(date,fromTime, this.getCurrentTime())) {
      this.toastrService.warning('Cannot reschedule appointment earlier than current time.');
      return
    }
  }


    const modal = this.modalService.open(DialogComponentComponent, {
      centered: false,
      windowClass: 'modal-centered hw-30',
      backdrop: 'static',
      keyboard: false
    });
    var body = {
      'title': 'Appointment reschedule',
      'message': 'Do you want reschedule this appointment?'
    }
    modal.componentInstance.data = body
    modal.result.then(
      (result: boolean) => {
        if (result) {
          if (sheduleDate?.event?._def?.extendedProps?.status) {
            this.selectedData = sheduleDate
            this.cancel()
          }else{
            this.noSlotWarning(sheduleDate)
          }
        }
      },
      (reason) => {
        // Handle dismissal of modal
      }
    );
  }

  doctorScheduler
  doctor
  eventsArray: any[] = []
  currentDate = new Date();
  selectedItem = formatDate(this.currentDate, "yyyy-MM-dd", 'en-us')
  getDoctorBaseschedule() {

    var toDay = new Date()
    this.calendarOptions.events = null;
    this.eventsArray.length = 0

    var check = moment(toDay, 'YYYY/MM/DD');

    var month = check.format('M');
    // var day   = check.format('D');
    // var year  = check.format('YYYY');

    var dateS = new Date(this.selectedItem)
    var request = {
      "careTakerId": this.globalStorage.getItem('forUserId'),
      "fromDate": formatDate(this.selectedItem, "yyyy-MM-dd", 'en-us'),

      "toDate": formatDate((dateS.setMonth(dateS.getMonth() + 3)), "yyyy-MM-dd", 'en-us')
    }
    if ((this.router.url == '/caretaker-dashboard' || this.router.url == '/caretaker-appointments') && this.appintmentData?.userType != 'rpmUser') {
      this.caretakerService.getCaretakerScheduleById(request).subscribe(res => {
        this.doctorScheduler = res['body']['data'];
        for (var i = 0; i < this.doctorScheduler?.length; i++) {
          var scheduleDate = new Date(this.doctorScheduler[i].scheduleDate);
          var time1 = moment(toDay).format('YYYY-MM-DD');
          var time2 = moment(scheduleDate).format('YYYY-MM-DD');
          if (time2 > time1 || time2 == time1) {
            for (var j = 0; j < this.doctorScheduler[i].scheduleHours.length; j++) {
              if (this.doctorScheduler[i].scheduleHours[j].scheduleStatus == 'AVAILABLE') {
                var obj = { title: '', scheduleId: this.doctorScheduler[i].scheduleId, date: this.doctorScheduler[i].scheduleDate, start: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime, end: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime, status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus, scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId, backgroundColor: 'green' }
              } else {
                var obj = { title: '', scheduleId: this.doctorScheduler[i].scheduleId, date: this.doctorScheduler[i].scheduleDate, start: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime, end: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime, status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus, scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId, backgroundColor: 'red' }
              }
              //var removeEvent={ title:'',scheduleId:this.doctorScheduler[i].scheduleId,date:this.doctorScheduler[i].scheduleDate,start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,backgroundColor:'orange'}
              this.eventsArray.push(obj)
              //this.eventsArray.push(removeEvent)
            }
          }
        }
        //  this.calendarOptions.events = this.eventsArray
        const source = interval(1000);
        this.subscription = source.subscribe(val => this.updater());
      }, error => {
        this.toastrService.error("Error retrieving baseshedule.")
      })
    }
    else if (this.router.url == '/clinic-dashboard') {
      var body = {
        "doctorId": this.appintmentData?.doctorId,
        "fromDate": formatDate(this.selectedItem, "yyyy-MM-dd", 'en-us'),
        "toDate": formatDate((dateS.setMonth(dateS.getMonth() + 3)), "yyyy-MM-dd", 'en-us')
      }
      this.doctorService.getDoctorBaseschedule(this.appintmentData?.doctorId, month).subscribe(res => {
        this.doctorScheduler = res['data'];
        for (var i = 0; i < this.doctorScheduler.length; i++) {
          var scheduleDate = new Date(this.doctorScheduler[i].scheduleDate);
          var time1 = moment(toDay).format('YYYY-MM-DD');
          var time2 = moment(scheduleDate).format('YYYY-MM-DD');
          if (time2 > time1 || time2 == time1) {
            for (var j = 0; j < this.doctorScheduler[i].scheduleHours.length; j++) {
              if (this.doctorScheduler[i].scheduleHours[j].scheduleStatus == 'AVAILABLE') {
                var obj = { title: '', scheduleId: this.doctorScheduler[i].scheduleId, date: this.doctorScheduler[i].scheduleDate, start: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime, end: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime, status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus, scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId, backgroundColor: 'green' }
              } else {
                var obj = { title: '', scheduleId: this.doctorScheduler[i].scheduleId, date: this.doctorScheduler[i].scheduleDate, start: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime, end: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime, status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus, scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId, backgroundColor: 'red' }
              }
              //var removeEvent={ title:'',scheduleId:this.doctorScheduler[i].scheduleId,date:this.doctorScheduler[i].scheduleDate,start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,backgroundColor:'orange'}
              this.eventsArray.push(obj)
              //this.eventsArray.push(removeEvent)
            }
          }
        }
        //  this.calendarOptions.events = this.eventsArray
        const source = interval(1000);
        this.subscription = source.subscribe(val => this.updater());
      }, error => {
        this.toastrService.error("Error retrieving baseshedule.")
      })
    }
    else if (this.globalStorage.getItem('loggedInAs') == 'IVF') {
      console.log('this is from ivf call', this.appointmentDataFromIvf)
      this.doctorService.getDoctorBaseschedule(this.appointmentDataFromIvf?.doctorId, month).subscribe(res => {
        this.doctorScheduler = res['data'];
        for (var i = 0; i < this.doctorScheduler.length; i++) {
          var scheduleDate = new Date(this.doctorScheduler[i].scheduleDate);
          var time1 = moment(toDay).format('YYYY-MM-DD');
          var time2 = moment(scheduleDate).format('YYYY-MM-DD');
          if (time2 > time1 || time2 == time1) {
            for (var j = 0; j < this.doctorScheduler[i].scheduleHours.length; j++) {
              if (this.doctorScheduler[i].scheduleHours[j].scheduleStatus == 'AVAILABLE') {
                var obj = { title: '', scheduleId: this.doctorScheduler[i].scheduleId, date: this.doctorScheduler[i].scheduleDate, start: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime, end: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime, status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus, scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId, backgroundColor: 'green' }
              } else {
                var obj = { title: '', scheduleId: this.doctorScheduler[i].scheduleId, date: this.doctorScheduler[i].scheduleDate, start: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime, end: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime, status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus, scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId, backgroundColor: 'red' }
              }
              //var removeEvent={ title:'',scheduleId:this.doctorScheduler[i].scheduleId,date:this.doctorScheduler[i].scheduleDate,start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,backgroundColor:'orange'}
              this.eventsArray.push(obj)
              //this.eventsArray.push(removeEvent)
            }
          }
        }
        //  this.calendarOptions.events = this.eventsArray
        const source = interval(1000);
        this.subscription = source.subscribe(val => this.updater());
      }, error => {
        this.toastrService.error("Error retrieving baseshedule.")
      })
    }
    else {
      this.doctorService.getDoctorBaseschedule(this.appintmentData?.doctorId, month).subscribe(res => {
        this.doctorScheduler = res['data'];
        for (var i = 0; i < this.doctorScheduler.length; i++) {
          var scheduleDate = new Date(this.doctorScheduler[i].scheduleDate);
          var time1 = moment(toDay).format('YYYY-MM-DD');
          var time2 = moment(scheduleDate).format('YYYY-MM-DD');
          if (time2 > time1 || time2 == time1) {
            for (var j = 0; j < this.doctorScheduler[i].scheduleHours.length; j++) {
              if (this.doctorScheduler[i].scheduleHours[j].scheduleStatus == 'AVAILABLE') {
                var obj = { title: '', scheduleId: this.doctorScheduler[i].scheduleId, date: this.doctorScheduler[i].scheduleDate, start: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime, end: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime, status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus, scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId, backgroundColor: 'green' }
              } else {
                var obj = { title: '', scheduleId: this.doctorScheduler[i].scheduleId, date: this.doctorScheduler[i].scheduleDate, start: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].fromTime, end: this.doctorScheduler[i].scheduleDate + 'T' + this.doctorScheduler[i].scheduleHours[j].toTime, status: this.doctorScheduler[i].scheduleHours[j].scheduleStatus, scheduleHoursId: this.doctorScheduler[i].scheduleHours[j].scheduleHoursId, backgroundColor: 'red' }
              }
              //var removeEvent={ title:'',scheduleId:this.doctorScheduler[i].scheduleId,date:this.doctorScheduler[i].scheduleDate,start: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].fromTime,end: this.doctorScheduler[i].scheduleDate+'T'+this.doctorScheduler[i].scheduleHours[j].toTime,backgroundColor:'orange'}
              this.eventsArray.push(obj)
              //this.eventsArray.push(removeEvent)
            }
          }
        }
        //  this.calendarOptions.events = this.eventsArray
        const source = interval(1000);
        this.subscription = source.subscribe(val => this.updater());
      }, error => {
        this.toastrService.error("Error retrieving baseshedule.")
      })
    }

  }

  updater() {
    var tDay = new Date()
    var tday = moment(tDay).format('YYYY-MM-DD');
    var sDate1 = formatDate(tDay, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    var date: any = new Date()
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds()
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    var newArray = this.eventsArray.filter(function (el) {
      return el.date == tday &&
        el.start >= sDate1 && el.end >= sDate1 || el.start >= sDate1
    }
    );
    // var newArray1 = this.eventsArray.filter(function (el) {
    //   return el.date == tday &&
    //     el.start >= sDate1 && el.end >= sDate1
    // }
    // );
    //newArray=newArray1.concat(newArray);
    this.calendarOptions.events = newArray
  }


}

