import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from '../../AppServices/login.service'
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-doctor-emailverification',
  templateUrl: './doctor-emailverification.component.html',
  styleUrls: ['./doctor-emailverification.component.css']
})
export class DoctorEmailverificationComponent implements OnInit {

  constructor(private login: LoginService, private toastrService: ToastrService) { }
  email;
  verificationCode
  emailVerification: boolean = false;
  ngOnInit(): void {
    // this.emailVerification=false;
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }


  EmailVerification() {

    if (this.email == undefined || this.email == null || this.email == undefined || this.email == null) {
      this.toastrService.error("Enter details")
      return
    }


    this.login.doctorVerifyEmail(this.verificationCode, this.email).subscribe(res => {
      this.emailVerification = true;
    })
  }

}

