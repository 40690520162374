import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';
import { formatDate } from '@angular/common';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';

@Component({
  selector: 'app-bulk-order-delivery',
  templateUrl: './bulk-order-delivery.component.html',
  styleUrls: ['./bulk-order-delivery.component.css']
})
export class BulkOrderDeliveryComponent implements OnInit {

  minDate;
  @Input() deliverOrder: any;
  @Input() addressId: any;
  bulkOrderList = [];

  constructor(
    private activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private clinicService:ClinicService,
    private inventoryService: InventoryService,
    private modalService: NgbModal) {
    this.minDate = new Date();
  }

  ngOnInit(): void {
    this.getMultipleOrders();
  }

  checkQty(i){
    if(this.bulkOrders[i]?.numOfItemDelivered>this.bulkOrders[i]?.quantityToBeReceived){
      this.bulkOrders[i].numOfItemDelivered=this.bulkOrders[i]?.quantityToBeReceived
    }

    if(!this.bulkOrders[i]?.numOfItemDelivered){
      this.bulkOrders[i].numOfItemDelivered=this.bulkOrders[i]?.quantityToBeReceived
    }
  }

  updateWarrantyMinDate(index: number) {
    const expireDate = this.bulkOrders[index].expireDate;
    if (expireDate) {
      const warrantyMinDate = new Date(expireDate);
      warrantyMinDate.setDate(warrantyMinDate.getDate() + 1); // Set minimum warranty date to the day after expiry
      this.bulkOrders[index].warrantyMinDate = warrantyMinDate;
    }
    this.bulkOrders[index].warranty = null;
  }

  closeModal() {
    this.activeModal.close(true);
  }

  getDisabled(i) {
    if(this.bulkOrders[i].orderStatus == 'FULL_DELIVERED'){
      this.bulkOrders[i].numOfItemDelivered=this.bulkOrders[i]?.quantityToBeReceived
    }
    return this.bulkOrders[i].orderStatus == 'FULL_DELIVERED';
  }

  bulkOrders;
  getMultipleOrders() {
    this.inventoryService.multipleOrders(this.deliverOrder?.supplierordersId).subscribe(res => {
      this.bulkOrders = res?.['data']?.['orderItem'] || [];
      console.log('my data:', this.bulkOrders);
      this.bulkOrders.map(item => {
        item['addressId'] = this.addressId;
        item['batchNumber'] = '';
        item['expireDate'] = '';
        item['manufacturerDate'] = '';
        item['nextOrderDate'] = '';
        item['numOfItemDelivered'] = item?.quantityToBeReceived;
        item['orderStatus'] = 'FULL_DELIVERED';
        item['saleUnitPrice'] = 0;
        item['warranty'] = '';
        if (item?.cgst) {
          item['totalGst'] = parseFloat(item?.cgst) + parseFloat(item?.sgst);
        }
        if (item?.igst) {
          item['totalGst'] = parseFloat(item?.igst);
        }
      })
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  supplierInvoiceId;
  deliverBulkOrder() {
    this.bulkOrderList=[]
    console.log('file ',this.file)
    if(this.file?.length==0 || this.file==undefined){
      this.toaster.warning('Please upload invoice')
      return
    }
    this.bulkOrders.map(res => {
      if (res?.expireDate) {
        var expireDate = formatDate(res?.expireDate, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
      }
      if (res?.manufacturerDate) {
        var manufDate = formatDate(res?.manufacturerDate, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
      }
      if (res?.nextOrderDate) {
        var nextOrdDate = formatDate(res?.nextOrderDate, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
      }
      if (res?.warranty) {
        var warranty = formatDate(res?.warranty, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
      }
      const item = {}
      item['addressId'] = this.addressId;
      item['batchNumber'] = res?.batchNumber;
      item['expireDate'] = expireDate ? expireDate : null;
      item['inventoryId'] = res?.inventoryId;
      item['itemTypeId']= res?.["itemTypeId"],
      item["isPharmacy"]= res?.["isPharmacy"],
      item['manufacturerDate'] = manufDate ? manufDate : null;
      item['nextOrderDate'] = nextOrdDate ? nextOrdDate : null;
      item['numOfItemDelivered'] = res?.numOfItemDelivered;
      item['orderStatus'] = res?.orderStatus;
      item['saleUnitPrice'] = res?.saleUnitPrice;
      item['supplierInvoiceId'] = parseInt(this.supplierInvoiceId);
      item['supplierordersId'] = res?.supplierordersId;
      item['warranty'] = warranty ? warranty : null;
      this.bulkOrderList.push(item);

    })


    const body = {
      "addressId": this.addressId,
      "supplierordersId": this.deliverOrder?.supplierordersId,
      "orderDeliverDTO": this.bulkOrderList
    }
    this.inventoryService.deliverBulkOrder(body).subscribe(res => {
      this.toaster.success('Order Delivered.');
      this.closeModal();
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }
  file;fileToUpload;imgURL
  onFileInput(files: File) {
    const f = files
    if ((f[0].type) == "image/png" || (f[0].type) == "application/pdf") {
      if (f[0].size > 1048769) {
        this.toaster.warning('file should be 1 Mb')
      } else {
        this.file = files
      }
    } else {
      this.toaster.warning('file should be in jpeg or PNG')
    }
    this.savePdf();
    // this.fileToUpload = files.item(0);

    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }
  savePdf(){
    var formData = new FormData();
    for (let i = 0; i < this.file?.length; i++) {
      formData.append('uploadPic', this.file?.[i], this.file?.[i]?.name);
    }
    this.inventoryService.saveInvoicePdf(formData,this.addressId,this.deliverOrder?.supplierordersId).subscribe(res=>{
      this.toaster.success('File Uploaded')
    },error=>{
      this.toaster.error(error?.error?.message)
    })
  }

}
