import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split2'
})
export class Split2Pipe implements PipeTransform {


  transform(value: any): any {
    if(value != null){
      let res= value?.split(/(\d+)/).filter(Boolean) 
      return res[0];
    }
  }

}
