<div class="modal-header">
    <h4 class="modal-title"> Discharge Summary</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="">
        <table style="width:100%" border="0" align="center" cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td>
                <table style="width:100%" border=" 1" align="center" cellpadding="10" cellspacing="0"
                  style="background:#000;padding:8px">
                  <tr>
                    <td colspan="2" bgcolor="#fff"><img [src]="imageSource"
                        height="50" /></td>
                  </tr>
                  <tr>
                    <td height="36" colspan="2" bgcolor="#fff">
                      <table width="100%" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td width="211"><strong>GSTIN :</strong> {{finalInvoice?.gstinNumber}}</td>
                          <td width="446" align="center"><strong>In Patient Bill - Bill of Supply</strong>
                          </td>
                          <td width="235"><strong>Reference No :</strong>{{finalInvoice?.referenceNo}}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td width="935" rowspan="2" align="left" valign="top" bgcolor="#fff">
                      <table width="100%" height="157" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td width="217" rowspan="2" align="left" valign="top"><strong>Name:
                            </strong>{{finalInvoice?.patientName | titlecase}}
                          </td>
                          <td width="273" height="27"><strong>Age: {{finalInvoice?.age}}</strong></td>
                        </tr>
                        <tr>
                          <td height="26"><strong>Sex: {{finalInvoice?.gender}}</strong></td>
                        </tr>
                        <tr>
                          <td height="0" colspan="2"><strong>Attende: </strong> {{finalInvoice?.spouseName | titlecase}}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2"><strong>Address: </strong> {{finalInvoice?.address?.street1 | titlecase}},
                            {{finalInvoice?.address?.city}},
                            {{finalInvoice?.address?.postalLocation}}-{{finalInvoice?.address?.zip}}
                            {{finalInvoice?.address?.state}}, {{finalInvoice?.country}}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <!-- <td width="535" height="0" bgcolor="#fff"><strong>UHID:
                      </strong>{{finalInvoice?.uhidNumber}}</td> -->
                  </tr>
                  <tr>
                    <td height="0" align="left" valign="top" bgcolor="#fff">
                      <p><strong>IP Number: </strong>{{inPatientId}}</p>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top" bgcolor="#fff">
                      <table width="568" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td width="93"><strong>Doctor Name: </strong></td>
                          <td width="475">{{finalInvoice?.doctorName | titlecase}}</td>
                        </tr>
                        <tr>
                          <td><strong>Room Details : </strong></td>
                          <td>{{finalInvoice?.floorNumber}} Floor, Room no: {{finalInvoice?.roomNumber}}</td>
                        </tr>
                        <tr>
                          <td><strong>Bed No : </strong></td>
                          <td>{{finalInvoice?.bedNumber}}</td>
                        </tr>
                      </table>
                    </td>
                    <td height="0" align="left" valign="top" bgcolor="#fff">
                      <table width="322" height="101" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td colspan="2"><strong>Bill No :</strong> {{finalInvoice?.billNumber}}</td>
                        </tr>
                        <tr>
                          <td width="158"><strong>Date : </strong> {{finalInvoice?.billGernaretDate |
                            date:'dd-MMM-yyyy'}}</td>
                          <td width="164"><strong>Time :</strong> {{finalInvoice?.billGenarateTime |
                            date:'hh:mm a'}}</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="60" colspan="2" align="left" valign="top" bgcolor="#fff">
                      <table width="907" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td width="637" height="0" rowspan="2" align="left" valign="top">
                            <strong>Billing Account Type :</strong>  Cash
                          </td>
                          <td width="270" align="left" valign="top"><strong>Admission Date &nbsp;</strong>:
                            {{finalInvoice?.admissionDate | date:'dd-MMM-yyyy hh:mm a'}}
                          </td>
                        </tr>
                        <tr>
                          <td align="left" valign="top"><strong>Discharge Date
                              &nbsp;</strong>:{{finalInvoice?.dischargeDate | date:'dd-MMM-yyyy hh:mm a'}}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" align="left" valign="top" bgcolor="#fff">
                      <table width="100%" border="0" align="center" cellpadding="0" cellspacing="0">
                        <tr>
                          <td width="30" bgcolor="#eee"><strong>SL. No</strong></td>
                          <td width="393" bgcolor="#eee"><strong>Service</strong></td>
                          <td width="211" align="right" bgcolor="#eee"><strong>Amount(INR)</strong>
                          </td>
                        </tr>
                        <tr *ngFor="let service of finalInvoice?.invoices;let i=index">
                          <td bgcolor="#fff"> {{i+1}}</td>
                          <td bgcolor="#fff">{{service?.invoiceFor}} </td>
                          <td align="right" bgcolor="#fff">{{service?.amount}}</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <!-- <tr>
                    <td align="left" valign="top" bgcolor="#fff">&nbsp;</td>
                    <td height="0" align="right" valign="top" bgcolor="#fff">&nbsp;</td>
                  </tr> -->
                  <!-- <tr>
                    <td align="left" valign="top" bgcolor="#fff"><strong>Service Amount</strong>
                    </td>
                    <td height="0" align="right" valign="top" bgcolor="#fff">
                      <strong>{{finalInvoice?.serviceAmount}}</strong>
                    </td>
                  </tr> -->
                  <tr>
                    <td align="left" valign="top" bgcolor="#fff"><strong>Total Bill Amount</strong>
                    </td>
                    <td height="0" align="right" valign="top" bgcolor="#fff">
                      <strong>{{finalInvoice?.totalbillAmount | number:'1.2-2'}}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top" bgcolor="#fff"><strong>Deposit</strong></td>
                    <td height="0" align="right" valign="top" bgcolor="#fff">
                      <strong>{{finalInvoice?.advancepay | number:'1.2-2'}}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top" bgcolor="#fff"><strong>Discount</strong></td>
                    <td height="0" align="right" valign="top" bgcolor="#fff">
                      <strong>{{finalInvoice?.discount | number:'1.2-2'}}</strong>
                    </td>
                    
                  </tr>
                  <tr>
                    <td align="left" valign="top" bgcolor="#fff"><strong>Net</strong></td>
                    <td height="0" align="right" valign="top" bgcolor="#fff">
                      <strong>{{(finalInvoice?.netpay-finalInvoice?.discount) | number:'1.2-2' }}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" valign="top" bgcolor="#fff">&nbsp;</td>
                    <td height="0" align="right" valign="top" bgcolor="#fff">&nbsp;</td>
                  </tr>
                  <tr>
                    <td height="0" colspan="2" align="left" valign="top" bgcolor="#fff" *ngIf="finalInvoice?.netpay"><strong>Bill Amount in
                        words : </strong><span style="text-transform:capitalize;">{{toWords?.convert(finalInvoice?.netpay,{ currency: true })}} </span></td>
                  </tr>
                  <tr>
                    <td height="0" colspan="2" align="left" valign="top" bgcolor="#fff">&nbsp;</td>
                  </tr>
                  <tr>
                    <td height="0" colspan="2" align="left" valign="top" bgcolor="#fff">&nbsp;</td>
                  </tr>
                </table>
                <br />
                <br />
                <br />
                <table style="width:100%" border=" 1" align="center" cellpadding="10" cellspacing="0"
                  style="background:#000">
                  <tr>
                    <td colspan="3" bgcolor="#fff"><img [src]="imageSource"
                        height="50" /></td>
                  </tr>
                  <tr>
                    <td width="1470" bgcolor="#fff"><strong>Name: </strong>{{finalInvoice?.patientName |
                      titlecase}}</td>
                    <td width="1470" bgcolor="#fff"><strong> IP No: </strong>{{inPatientId}}</td>
                    <td width="1470" bgcolor="#fff"><strong>Bill No: </strong>{{finalInvoice?.billNumber}}</td>
                  </tr>
                  <tr>
                    <td colspan="3" bgcolor="#fff">
                      <table width="100%" border="0" align="center" cellpadding="0" cellspacing="0">
                        <tr>
                          <td width="30" bgcolor="#eee"><strong>SL. No</strong></td>
                          <td width="393" bgcolor="#eee"><strong>Service</strong></td>
                          <td width="211" align="right" bgcolor="#eee"><strong>Amount(INR)</strong>
                          </td>
                        </tr>
                        <tr *ngFor="let service of finalInvoice?.inPatientInvoiceDTOs;let i=index">
                          <td bgcolor="#fff"> {{i+1}}</td>
                          <td bgcolor="#fff">{{service?.invoiceFor}} </td>
                          <td align="right" bgcolor="#fff">{{service?.totalAmount | number:'1.2-2'}}</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                        <tr>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                          <td bgcolor="#fff">&nbsp;</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
                <br />
                <br />
                <br />
                <table style="width:100%" border=" 1" align="center" cellpadding="10" cellspacing="0"
                  style="background:#000" *ngFor="let advancePay of finalInvoice?.invoiceResponse?.advancePayment">
                  <tr>
                    <td height="50" colspan="3" align="center" bgcolor="#fff"><img
                        [src]="imageSource" height="50" /></td>
                  </tr>
                  <tr>
                    <td height="50" colspan="3" align="center" bgcolor="#f9f9f9"><strong>Deposit
                        Receipt</strong>
                    </td>
                  </tr>
                  <tr>
                    <td width="1470" bgcolor="#fff"><strong>Invoice ID:</strong>{{finalInvoice?.invoiceNumber}}</td>
                    <td width="1470" bgcolor="#fff"><strong> IP No:</strong>{{inPatientId}}</td>
                    <td width="1470" bgcolor="#fff"><strong>Bill No:</strong>{{advancePay?.orderId}}</td>
                  </tr>
                  <tr>
                    <td height="90" bgcolor="#fff">
                      <table width="100%" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td height="40" colspan="2"><strong>Name: </strong>{{finalInvoice?.patientName |
                            titlecase}}</td>
                        </tr>
                        <tr>
                          <td height="80" colspan="2"><strong>Address: </strong>
                            {{finalInvoice?.address?.street1}}, {{finalInvoice?.address?.city}},
                            {{finalInvoice?.address?.postalLocation}}-{{finalInvoice?.address?.zip}}
                            {{finalInvoice?.address?.state}}, {{finalInvoice?.country}}
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td bgcolor="#fff">&nbsp;</td>
                    <td bgcolor="#fff">
                      <table width="100%" border="0" cellpadding="0" cellspacing="0">
                        <tr>
                          <td height="0" colspan="2"><strong>Recipt Date:<br />
                              <br />
                            </strong>{{advancePay?.paymentDate | date:'dd-MMM-yyyy hh:mm a'}}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td height="100" colspan="3" valign="top" bgcolor="#fff"><strong>Transcation Type :
                      </strong>{{advancePay?.paymentMode}}<br />
                      <br />
                      <br />
                      <strong>Transcation Amount : {{advancePay?.amount | number:'1.2-2'}}</strong><br />
                      <br />
                      <br />
                      <strong>Transcation Amount in words : </strong>{{toWords?.convert(advancePay?.amount, { currency: true })}}
                    </td>
                  </tr>
                  <tr>
                    <td height="80" bgcolor="#fff"><strong>Mode of Payment : </strong>Cash</td>
                    <td bgcolor="#fff">&nbsp;</td>
                    <td bgcolor="#fff"><strong>Cashier</strong></td>
                  </tr>
                  <tr>
                    <td colspan="3" bgcolor="#fff">&nbsp;</td>
                  </tr>
                </table>
                <br />
                <br />
                <br />
                <table width="100%" border=" 1" align="center" cellpadding="10" cellspacing="0"
                  style="background:#000">
                  <tr>
                    <td height="50" align="center" bgcolor="#fff"><img
                        [src]="imageSource" height="50" /></td>
                  </tr>
                  <tr>
                    <td height="50" align="center" bgcolor="#f9f9f9">
                      <h3>Discharge Summary</h3>
                    </td>
                  </tr>
                  <tr>
                    <td bgcolor="#fff">
                      <table width="100%" border="0" cellspacing="0" cellpadding="0">
                        <tbody>
                          <tr>
                            <td width="11%" height="50"><strong>Patient ID</strong></td>
                            <td width="39%">{{inPatientId}}</td>
                            <td width="8%"><strong>Name</strong></td>
                            <td width="42%">{{finalInvoice?.patientName | titlecase}}</td>
                          </tr>
                          <tr>
                            <td height="50"><strong>Sex</strong></td>
                            <td>{{finalInvoice?.gender}}</td>
                            <td><strong>Age</strong></td>
                            <td>{{finalInvoice?.age}}</td>
                          </tr>
                          <tr>
                            <td height="50"><strong>Date of Discharge</strong></td>
                            <td>{{presentTime | date:'dd-MMM-yyyy hh:mm a'}}</td>
                            <td><strong>Date of Admission</strong></td>
                            <td>{{finalInvoice?.admissionDate | date:'dd-MMM-yyyy hh:mm a'}}</td>
                          </tr>
                          <tr>
                            <td height="50"><strong>Room/Bed No</strong></td>
                            <td>{{finalInvoice?.floorNumber}} Floor, Room No: {{finalInvoice?.roomNumber}}</td>
                            <td><strong>Primary Consultant</strong></td>
                            <td>{{finalInvoice?.doctorName | titlecase}}</td>
                          </tr>
                          <tr>
                            <td><strong>Consultants</strong></td>
                            <td>&nbsp;{{finalInvoice?.doctorName | titlecase}}</td>
                            <td><strong>Address</strong></td>
                            <td>&nbsp; {{finalInvoice?.hospitaladdress?.address1 |titlecase}} {{finalInvoice?.hospitaladdress?.address2 |titlecase}}, {{finalInvoice?.hospitaladdress?.city|titlecase}},
                              {{finalInvoice?.hospitaladdress?.postalLocation}}-{{finalInvoice?.hospitaladdress?.zip}}
                              {{finalInvoice?.hospitaladdress?.state}},
                              {{finalInvoice?.hospitaladdress?.country}}</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td colspan="2">
                              <h3>Principal Diagnosis</h3>
                            </td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td valign="top"><strong>Complaint</strong></td>
                            <td colspan="3">{{finalInvoice?.casedetails?.complain}}<br />
                            </td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td valign="top"><strong>Diagnosis</strong></td>
                            <td colspan="3">{{finalInvoice?.casedetails?.diagnosis}}
                            </td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td valign="top"><strong>Condition On Discharge:</strong></td>
                            <td colspan="3">{{finalInvoice?.casedetails?.conditiononDischarge}}
                            </td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td valign="top"><strong>Final Diagnosis:</strong></td>
                            <td colspan="3">{{finalInvoice?.casedetails?.finalDiagnosis}}<br />
                            </td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                          <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
</div>
