<h6 class="mb-3 mt-2 border-bottom pt-2 pb-2" style="font-size:15px" aria-expanded="true" aria-controls="primary">Primary Address <span class=" text-danger"
  style="margin-left: 30%;">{{addressDtsMessage}}</span></h6>

<div id="primary">
  <div class="row" [formGroup]="primaryAddress" style="background-color:white;margin-top:15px;"
    (keyup)="checkForm(primaryAddress)">

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">Contact Name <span style="color:red">*</span></label>
        <input type="text" class="form-control" [readOnly]="true" minlength="3" maxlength="20"
          placeholder="Enter contact name" formControlName="contactName"
          [ngClass]="{ 'is-invalid': (primarysubmitted) && f.contactName.errors}">
        <div *ngIf="(primarysubmitted)  && f.contactName.errors" class="invalid-feedback">
          <div *ngIf="f.contactName.errors.required">Contract Name required</div>
          <div *ngIf="f.contactName.errors.minlength">
            Contact Name should be minimum 3 characters Long
          </div>
          <div *ngIf="f.contactName.errors.maxlength">
            Contact Name should not be 20 characters long
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">Mobile <span style="color:red">*</span></label>
        <input type="text" [readonly]="true" placeholder="Enter mobile number" (keypress)="numberOnly($event)"
          maxLength="10" class="form-control w-100 float-left" formControlName="contactPhoneNumber"
          [ngClass]="{ 'is-invalid': (primarysubmitted) && f.contactPhoneNumber.errors}">
        <div *ngIf="(primarysubmitted)  && f.contactPhoneNumber.errors" class="invalid-feedback">
          <div *ngIf="f.contactPhoneNumber.errors.required">Contract Number is required</div>

          <div *ngIf="f.cellNumber.errors.pattern">Enter valid Mobile Number</div>
        </div>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label for="exampleInputEmail1"> Zip <span style="color:red">*</span></label>
        <input type="text" #zip1 [readOnly]="ViewMode" maxlength="6" placeholder="Enter Zip"
          (keyup)="percChanged(zip1.value)" class="form-control" (keypress)="numberOnly($event)" formControlName="zip"
          [ngClass]="{ 'is-invalid': ((primarysubmitted || f.zip.touched) && !isDisabled) && f.zip.errors}">
        <div *ngIf="((primarysubmitted || f.zip.touched) && !isDisabled)  && f.zip.errors" class="invalid-feedback">
          <div *ngIf="f.zip.errors.required">Zip is required</div>
        </div>
      </div>
    </div>

    <div class="col-md-2">
      <label>Country Code <span class="text-danger">*</span></label>
      <mat-select [(value)]="countryCodeSelected" (selectionChange)="countryCodeEntered(countryCodeSelected)"
        class="form-control" formControlName="cc" [disabled]="ViewMode" placeholder="Select"
        [ngClass]="{'is-invalid': (primarysubmitted || f.cc.touched) && f.cc.errors}">
        <mat-option value="IN">IN</mat-option>
        <mat-option value="US">US</mat-option>
      </mat-select>
      <div *ngIf="(primarysubmitted || f.cc.touched) && f.cc.errors" class="invalid-feedback">
        <div *ngIf="f.cc.errors.required">Country code is required</div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputPassword1">Postal Location <span class="text-danger">*</span></label>
        <mat-select placeholder="Select postal location" [(value)]="selectedValue"
          (selectionChange)="drpdownSelection(selectedValue)" class="form-control" formControlName="postalLocation"
          name="item" [disabled]="ViewMode"
          [ngClass]="{'is-invalid': (primarysubmitted || f.postalLocation.touched) && f.postalLocation.errors}"
          placeholder="Enter postal location">
          <mat-option *ngFor="let item of postalLocations | search : searchTxt" [value]="item.postalLocation">
            {{item.postalLocation}}
          </mat-option>
        </mat-select>

        <div *ngIf="(primarysubmitted || f.postalLocation.touched) && f.postalLocation.errors" class="invalid-feedback">
          <div *ngIf="f.postalLocation.errors.required">Postal location is required</div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">Street1 <span style="color:red">*</span></label>
        <input type="text" [readOnly]="ViewMode" class="form-control" placeholder="Enter street1"
          formControlName="street1"
          [ngClass]="{ 'is-invalid': ((primarysubmitted || f.street1.touched) && !isDisabled) && f.street1.errors}">
        <div *ngIf="((primarysubmitted || f.street1.touched) && !isDisabled)  && f.street1.errors"
          class="invalid-feedback">
          <div *ngIf="f.street1.errors.required">Street1 is required</div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">Street2</label>
        <input type="text" [readOnly]="ViewMode" class="form-control" placeholder="Enter street2"
          formControlName="street2">
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">City <span style="color:red">*</span></label>
        <input type="text" [readOnly]="ViewMode" class="form-control" placeholder="Enter city" formControlName="city"
          [ngClass]="{ 'is-invalid': (primarysubmitted) && f.city.errors}">
        <div *ngIf="(primarysubmitted)  && f.city.errors" class="invalid-feedback">
          <div *ngIf="f.city.errors.required">City is required</div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">State <span style="color:red">*</span></label>
        <input type="text" [readOnly]="ViewMode" class="form-control" placeholder="Enter state" formControlName="state"
          [ngClass]="{ 'is-invalid': (primarysubmitted) && f.state.errors}">
        <div *ngIf="(primarysubmitted)  && f.state.errors" class="invalid-feedback">
          <div *ngIf="f.state.errors.required">State is required</div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="form-group float-right">
        <button *ngIf="!ViewMode" class="btn btn-danger btn-sm mr-2" (click)="primaryClose()"><i
            class="fa fa-close"></i> Cancel
        </button>

        <button *ngIf="!ViewMode" class="btn btn-success btn-sm mr-2" (click)="savePrimaryAddress(primaryAddress)"
          [disabled]="isSave"><i class="fa fa-save"></i> Save
        </button>

        <button *ngIf="ViewMode" class="btn btn-primary btn-sm" (click)="btnEdit()"><i class="fa fa-edit"></i>
          Edit
        </button>
        <!-- <button  *ngIf="!ViewMode" class="btn btn-danger"  (click)="deleteAddress(primaryAddress)"><i class="fa fa-trash"></i></button> -->
      </div>
    </div>
  </div>
</div>


<!--Emergency Contact Starts-->
<!-- <h6 class="mb-3 border-bottom pt-2 pb-2 cursor-pointer" style="font-size:15px" data-toggle="collapse"
  data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo"> Emergency Contact <i
    class="fa fa-chevron-down float-right"></i></h6>

<div id="collapseTwo" class="collapse hide" data-parent="#accordion">
  <div class="row" [formGroup]="secondaryAddress" style="background-color:white;margin-top:15px;"
    (keyup)="checkSecForm(secondaryAddress)">
    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">Contact Name <span style="color:red">*</span></label>
        <input type="text" class="form-control" [readOnly]="true" minlength="3" maxlength="20" placeholder="Enter contact name"
          formControlName="contactName" [ngClass]="{ 'is-invalid': (secondarysubmitted) && sf.contactName.errors}">
        <div *ngIf="(secondarysubmitted)  && sf.contactName.errors" class="invalid-feedback">
          <div *ngIf="sf.contactName.errors.required">Contact Name is required</div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">Mobile <span style="color:red">*</span></label>
        <input type="text" [readOnly]="ViewMode1" placeholder="Enter mobile number" (keypress)="numberOnly($event)"
          maxLength="10" class="form-control w-100 float-left" formControlName="contactPhoneNumber"
          [ngClass]="{ 'is-invalid': ((secondarysubmitted || sf.contactPhoneNumber.touched) && !isDisabled) && sf.contactPhoneNumber.errors}">
        <div
          *ngIf="((secondarysubmitted || sf.contactPhoneNumber.touched) && !isDisabled) && sf.contactPhoneNumber.errors"
          class="invalid-feedback">
          <div *ngIf="sf.contactPhoneNumber.errors.required">Contact Number is required</div>
          <div *ngIf="sf.contactPhoneNumber.errors.pattern">Enter a valid mobile number</div>
        </div>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label for="exampleInputEmail1">Zip <span style="color:red">*</span></label>
        <input #zipCode type="text" (keyup)="perChanged2(zipCode.value)" [readOnly]="ViewMode1" maxlength="6"
          class="form-control" placeholder="Zip" (keypress)="numberOnly($event)" formControlName="zip"
          [ngClass]="{ 'is-invalid': ((secondarysubmitted || sf.zip.touched) && !isDisabled) && sf.zip.errors}">
        <div *ngIf="((secondarysubmitted || sf.zip.touched) && !isDisabled) && sf.zip.errors" class="invalid-feedback">
          <div *ngIf="sf.zip.errors.required">Zip is required</div>
        </div>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>Country Code <span class="text-danger">*</span></label>
        <mat-select [(value)]="countryCodeSelected2" (selectionChange)="countryCodeEntered2(countryCodeSelected2)"
          class="form-control" formControlName="cc" placeholder="Select" [disabled]="ViewMode1"
          [ngClass]="{'is-invalid': ((secondarysubmitted || sf.cc.touched) && !isDisabled) && sf.cc.errors}">
          <mat-option value="IN">IN</mat-option>
          <mat-option value="US">US</mat-option>
        </mat-select>
        <div *ngIf="((secondarysubmitted || sf.cc.touched) && !isDisabled) && sf.cc.errors" class="invalid-feedback">
          <div *ngIf="sf.cc.errors.required">
            Country code is required
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputPassword1">Postal Location <span class="text-danger">*</span></label>
        <mat-select placeholder="Select postal location" [(value)]="selected"
          (selectionChange)="drpdownSelection2(selected)" class="form-control" formControlName="postalLocation"
          name="item" [disabled]="ViewMode1"
          [ngClass]="{'is-invalid': (secondarysubmitted || sf.postalLocation.touched) && sf.postalLocation.errors}"
          placeholder="Enter postal location">
          <mat-option *ngFor="let item of postalLocationsSec | search : searchTxt1" [value]="item.postalLocation">
            {{item.postalLocation}}
          </mat-option>
        </mat-select>

        <div *ngIf="(secondarysubmitted || sf.postalLocation.touched) && sf.postalLocation.errors"
          class="invalid-feedback">
          <div *ngIf="sf.postalLocation.errors.required">Postal location is required</div>
        </div>

      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">Street1 <span style="color:red">*</span></label>
        <input type="text" [readOnly]="ViewMode1" placeholder="Enter street1" class="form-control"
          formControlName="street1"
          [ngClass]="{ 'is-invalid': ((secondarysubmitted || sf.street1.touched) && !isDisabled) && sf.street1.errors}">
        <div *ngIf="((secondarysubmitted || sf.street1.touched) && !isDisabled) && sf.street1.errors"
          class="invalid-feedback">
          <div *ngIf="sf.street1.errors.required">Street1 is required</div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">Street2</label>
        <input type="text" [readOnly]="ViewMode1" placeholder="Enter street2" class="form-control"
          formControlName="street2" [ngClass]="{ 'is-invalid': (secondarysubmitted) && sf.street2.errors}">
        <div *ngIf="(secondarysubmitted)  && sf.street2.errors" class="invalid-feedback">
          <div *ngIf="sf.street2.errors.required">Street2 is required</div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">City <span style="color:red">*</span></label>
        <input type="text" [readOnly]="ViewMode1" placeholder="Enter city" class="form-control" formControlName="city"
          [ngClass]="{ 'is-invalid': (secondarysubmitted) && sf.city.errors}">
        <div *ngIf="(secondarysubmitted)  && sf.city.errors" class="invalid-feedback">
          <div *ngIf="sf.city.errors.required">City is required</div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="form-group">
        <label for="exampleInputEmail1">State <span style="color:red">*</span></label>
        <input type="text" [readOnly]="ViewMode" class="form-control" placeholder="Enter state" formControlName="state"
          [ngClass]="{ 'is-invalid': (secondarysubmitted) && sf.state.errors}">
        <div *ngIf="(secondarysubmitted)  && sf.state.errors" class="invalid-feedback">
          <div *ngIf="sf.state.errors.required">State is required</div>
        </div>
      </div>
    </div>

    <div class="col-md-12">
      <div class="form-group float-right">
        <button *ngIf="!ViewMode1" class="btn btn-danger btn-sm mr-2" (click)="secondaryClose()"><i
            class="fa fa-close"></i> Cancel
        </button>

        <button *ngIf="!ViewMode1" class="btn btn-success btn-sm mr-2" (click)="saveSecondaryAddress(secondaryAddress)"
          [disabled]="isSecSave"><i class="fa fa-save"></i>
          Save
        </button>

        <button *ngIf="ViewMode1" class="btn btn-primary btn-sm" (click)="btnSecEdit()"><i class="fa fa-edit"></i>
          Edit
        </button>
      </div>
    </div>
  </div>
</div> -->