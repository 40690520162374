import { Directive, ElementRef, HostListener, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appProcessDate]'
})
export class ProcessDateDirective {

  constructor(
    private el: ElementRef<HTMLInputElement>,
    @Self() private ngControl: NgControl
  ) {}

  @HostListener('change')
  onInputChange() {
    const inputValue = this.el.nativeElement.value;
    let newValue = inputValue;
    const inputType = this.el.nativeElement.type;

    // Match different datetime formats
    const dateOnlyPattern = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD
    const dateTimeNoSecondsPattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/; // YYYY-MM-DDTHH:MM

    if (inputType === 'date' && dateOnlyPattern.test(inputValue)) {
      // For 'date', add time as 00:00:00
      newValue = `${inputValue}T00:00:00`;
    } else if (inputType === 'datetime-local' && dateTimeNoSecondsPattern.test(inputValue)) {
      // For 'datetime-local', add seconds as :00
      newValue = `${inputValue}:00`;
    }

    // Update the form control value
    this.ngControl.control.setValue(newValue);
  }
}
