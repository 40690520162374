import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-skelton-loader',
  templateUrl: './skelton-loader.component.html',
  styleUrls: ['./skelton-loader.component.css']
})
export class SkeltonLoaderComponent implements OnInit {

  @Input() numRows:number = 1;
  @Input() numCells:number = 1;
  @Input() shape: string = 'line';  // 'line', 'circle', or 'card'


  rows = new Array(this.numRows);
  cells = new Array(this.numCells);
  
  constructor() {
    this.updateArrays();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.numRows || changes.numCells) {
      this.updateArrays();
    }
  }

  updateArrays() {
    this.rows = Array.from({length: this.numRows}, (_, i) => i);
    this.cells = Array.from({length: this.numCells}, (_, i) => i);
  }

  ngOnInit(): void {
    this.updateArrays();
  }

}
