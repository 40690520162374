import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IvfService } from '../services/ivf.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ivf-confirm-modal',
  templateUrl: './ivf-confirm-modal.component.html',
  styleUrls: ['./ivf-confirm-modal.component.css']
})
export class IvfConfirmModalComponent implements OnInit {
  @Input() details: any;
  @Input() isStart: boolean = false;
  @Input() isEdit: boolean = false;
  @Input() isStimulation: boolean = false;
  @Input() isProcedure: boolean = false;
  @Input() isTrigger: boolean = false;

  caseCycle: FormGroup;
  editCaseCycle: FormGroup;
  stimulation: FormGroup;
  procedure: FormGroup;
  trigger: FormGroup;
  prevCycle: any;
  protocolArray = [];
  packageArray = [];
  procedureArray = [{id:1,procedure:'ICSI'},{id:2,procedure:'IVSI'}];
  injectionArray=['ABC','TEST'];
  semenTypeArray = ['HUSBAND','DONOR'];
  isFrozen = false;
  maxDate: string;


  constructor(private globalStorage: GlobalStorageService, private fb: FormBuilder, public activeModal: NgbActiveModal, private ivfService: IvfService,private toasterService:ToastrService) { }

  ngOnInit(): void {
    // console.log(this.details)
    this.maxDate = new Date().toISOString().split('T')[0];
    this.initializeForms();
    this.getPackages()
    this.getProtocols();
    this.subscribeToFormChanges();
    this.getCycle();

  }

  initializeForms(){
    this.caseCycle = this.fb.group({
      caseDetailId: this.details.caseDetailsID,
      ivfAddressId: this.globalStorage.getItem('ivfAddressId'),
      patientId: this.details.userReferenceId,
      menstrualDate: [''],
      packagesId:[''],
      procedures: [''],
      outCome: [''],
      simulationDate: [''],
      simulationDays: [''],
      protocal: [''],
      protocolDefId: [''],
    });

    this.editCaseCycle = this.fb.group({

      patientId: this.details.userReferenceId,
      casecycleId: null,
      count: null,
      cycle:null,
      menstrualDate: [''],
      procedures: [''],
      simulationDate: [''],
      simulationDays: [''],
      protocal: [''],
      startDate: [''],
      protocolDefId: [''],
    });

    this.stimulation = this.fb.group({
      casecycleId: null,
      ivfAddressId: this.globalStorage.getItem('ivfAddressId'),
      patientId: this.details.userReferenceId
    });

    this.procedure = this.fb.group({
      casecycleId: null,
      ivfAddressId: this.globalStorage.getItem('ivfAddressId'),
      patientId: this.details.userReferenceId,
      procedures:['']
    });

    this.trigger = this.fb.group({
      casecycleId: null,
      ivfAddressId: this.globalStorage.getItem('ivfAddressId'),
      patientId: this.details.userReferenceId,
      hCGInjection:[''],
      semenType:this.semenTypeArray[0],
      isFrozen:this.isFrozen,
    });

    


  }

  subscribeToFormChanges() {
    if (this.isEdit) {

      this.editCaseCycle.valueChanges.subscribe(res => {
        if (res.menstrualDate) {
          this.editCaseCycle.value.menstrualDate += 'T00:00:00';
        }
        if (res.simulationDate) {
          this.editCaseCycle.value.simulationDate += 'T00:00:00';
        }
        if (res.protocal) {
          console.log(this.editCaseCycle.value.protocal)
          const protocolObj = this.protocolArray.find(p => p.protocal === this.editCaseCycle.value.protocal);
          this.editCaseCycle.value.protocolDefId =protocolObj?.protocolDefId;
        }
      });
    } 
    if(this.isStart) {
      this.caseCycle.valueChanges.subscribe(res => {
        if (res.menstrualDate) {
          this.caseCycle.value.menstrualDate += 'T00:00:00';
        }
        if (res.simulationDate) {
          this.caseCycle.value.simulationDate += 'T00:00:00';
        }
        if (res.protocal) {
          console.log(this.caseCycle.value.protocal)
          const protocolObj = this.protocolArray.find(p => p.protocal === this.caseCycle.value.protocal);
          this.caseCycle.value.protocolDefId =protocolObj?.protocolDefId;
        }
      });

    }

  }

  getPackages() {
    const body = {
      "ivfAddressId": this.globalStorage.getItem('ivfAddressId')
    }
    this.ivfService.fetchPackagesByIvfAddressIdbyCriteria(body).subscribe(res => {
      this.packageArray = res?.data||[];
    });
  }

  getProtocols() {
    this.ivfService.fetchProtocolDefInformation(this.globalStorage.getItem('ivfAddressId')).subscribe(res => {
      this.protocolArray = res?.data||[];
    });
  }

  getCycle() {
    this.ivfService.fetchCycle(this.globalStorage.getItem('ivfAddressId'), this.details.userReferenceId).subscribe(res => {
      this.prevCycle = res?.data;
      if (this.isEdit) {
        this.editCaseCycle.patchValue(this.prevCycle);
      }
    })
  }



  protocolChanged(index){
    console.log(index,this.protocolArray[index])
    this.caseCycle.value.protocolDefId = this.protocolArray[index].protocolDefId;
  }

  closeModal() {

    if (this.caseCycle.value?.caseDetailId) {
      if (this.isStart) {
        this.saveCycle(this.caseCycle.value)
      } else if(this.isEdit){
        this.updateCycle(this.editCaseCycle.value)
      }
      else if(this.isStimulation){
        this.startStimulation(this.stimulation.value)
      }
      else if(this.isProcedure){
        this.startProcedure(this.procedure.value)
      }else if(this.isTrigger){
        this.startTrigger(this.trigger.value)
      }

    }
  }

  saveCycle(body) {
    this.ivfService.startIvfCycle(body).subscribe(res => {
      this.toasterService.success('Cycle Started Successfully');
      this.activeModal.close(true)
    }, err => {
      this.toasterService.error(err?.['error']?.['message'])
    })
  }

  startStimulation(body){
    body.casecycleId = this.prevCycle?.casecycleId;
    this.ivfService.startStimulation(body).subscribe(res => {
      this.toasterService.success('Stimulation Started Successfully');
      this.activeModal.close(true)
    }, err => {
      this.toasterService.error(err?.['error']?.['message'])
    })
  }

  startProcedure(body){
    body.casecycleId = this.prevCycle?.casecycleId;
    this.ivfService.startProcedures(body).subscribe(res => {
      this.toasterService.success('Procedure Started Successfully');
      this.activeModal.close(true)
    }, err => {
      this.toasterService.error(err?.['error']?.['message'])
    })
  }

  startTrigger(body){
    body.casecycleId = this.prevCycle?.casecycleId;
    this.ivfService.triggerHcg(body).subscribe(res => {
      this.toasterService.success('Trigger Started Successfully');
      this.activeModal.close(true)
    }, err => {
      this.toasterService.error(err?.['error']?.['message'])
    })
  }

  updateCycle(body) {
    this.ivfService.updateIvfCycle(body).subscribe(res => {
      this.toasterService.success('Cycle Updated Successfully');
      this.activeModal.close(true)
    }, err => {
      this.toasterService.error(err?.['error']?.['message'])
    })
  }

}
