<div class="l-sidebar" id="open-menu">
  <div class="logo">
    <div class="logo__txt"><img alt="logo-img" class="img-center" id="logo-img" src="./assets/img/logo-white.png"></div>
  </div>
  <div class="l-sidebar__content">
    <nav class="c-menu js-menu">
      <ul class="u-list">
        <li class="c-menu__item has-submenu" (click)="checkProfile()">
          <a href="javascript:void(0)" routerLink="/dashboard"  data-toggle="tooltip" title="" data-original-title="Home" data-placement="right" >

            <div class="c-menu__item__inner bg-warning-light">
              <i class="fa fa-home"></i>
              <div class="c-menu-item__title"><span>Home</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu is-active" data-toggle="tooltip" title="" data-placement="right"
          data-original-title="Appointments" (click)="checkProfile()">
          <a href="javascript:void(0)" routerLink="/Appoinments">
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-calendar-check"></i>
              <div class="c-menu-item__title"><span>Appointments</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu " data-toggle="tooltip" title="" data-original-title="Calendar" data-placement="right" (click)="checkProfile()">
          <a href="javascript:void(0)" routerLink="/Calendar">
            <div class="c-menu__item__inner bg-primary-light">
              <i class="fa fa-calendar-day"></i>
              <div class="c-menu-item__title"><span>Calendar</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu" data-toggle="tooltip" title="" data-original-title="Base Scheduler" data-placement="right" (click)="checkProfile()">
          <a href="javascript:void(0)" routerLink="/BasicScheduler">
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-calendar-alt"></i>
              <div class="c-menu-item__title"><span>Base Scheduler</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu" data-toggle="tooltip" title="" data-original-title="All Reports" data-placement="right" (click)="checkProfile()">
          <a href="javascript:void(0)" routerLink="/doctor-all-reports">
            <div class="c-menu__item__inner bg-primary-light">
              <i class="fa fa-chart-bar"></i>
              <div class="c-menu-item__title"><span>All Reports</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu" data-toggle="tooltip" title="" data-original-title="Profile" data-placement="right" (click)="checkProfile()">
          <a href="javascript:void(0)" routerLink="/EditProfile">
            <div class="c-menu__item__inner bg-danger-light">
              <i class="fa fa-user-cog"></i>
              <div class="c-menu-item__title"><span>Profile</span></div>
            </div>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>

<header class="l-header">
  <div class="l-header__inner clearfix">
    <!-- <div class="c-header-icon js-hamburger no-mobile">
      <div class="hamburger-toggle"><span class="bar-top"></span><span class="bar-mid"></span><span
          class="bar-bot"></span></div>
    </div> -->
    <div class="c-search p-0 no-desktop">
      <img src="./assets/img/logo-mobile.png" />
    </div>
    <div class="c-search no-mobile">
      <div style=" float: left; ">Logged in as <b class="ml-1 mr-1 captilize">Dr. {{profileDetails |titlecase}}</b> </div>

      <span class="float-right d-none d-lg-block mr-2 nmobile" *ngIf="!firstTimeLogin">
        Last login : {{lastLogin | date:'dd-MMM-yyyy hh:mm a'}}
      </span>
      <span class="float-left pt-0 d-none d-lg-block mr-2" *ngIf="firstTimeLogin">
        Welcome to CureMetric. This is your first time login.
      </span>
    </div>
    <!-- <button mat-raised-button color="primary" feedback (send)="onSend($event)">Help</button> -->
    <div class="header-icons-group">
      <div class="c-header-icon has-dropdown bg-danger-light" data-toggle="tooltip" data-placement="left" title="Help">
        <i class="fas fa-question-circle" feedback (send)="onSend($event)"></i>
      </div>
      <div class="c-header-icon has-dropdown bg-warning-light" data-toggle="tooltip" data-placement="left" title="Create Patient"> 
          <i class="fa-solid fa-file-medical" (click)="creatUserOrBookAppointment()"></i>
      </div>
      <div class="c-header-icon has-dropdown bg-primary-light" *ngIf="notificationBell" aria-hidden="true"
        data-toggle="modal" data-target="#viewremindersDoctors">
        <span class="c-badge c-badge--header-icon " data-placement="left" data-toggle="tooltip"
          data-original-title="Notifications">{{notificationLength}}</span><i class="fa fa-bell"></i>
      </div>
      <div class="c-header-icon has-dropdown bg-success-light " data-placement="left" data-toggle="tooltip"
        data-original-title="Profile"> 
        <i class="fa fa-user" routerLink="/EditProfile"></i>
      </div>
      <div class="c-header-icon logout bg-warning-light" data-placement="left" data-toggle="tooltip"
        data-original-title="Logout"><i class="fa fa-sign-out-alt" (click)="logOut()"></i></div>

    </div>
  </div>
</header>
<div class="modal fade modal-right" id="viewremindersDoctors" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notifications</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- <app-view-doctor-remainders></app-view-doctor-remainders> -->
      <div class="row">


        <div class="col-lg-12 mt-3 text_right ">
          <div *ngIf="notViewedNotifications?.length==0" class="no-notifications"></div>
          <ul class="pagination">
            <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
          </ul>
        </div>
      </div>
      <div class="row d-flex  ml-2">

        <div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left  blt-2 mb-2 mt-1 "
          *ngFor="let reports of notViewedNotifications |paginate: { id: 'p2', itemsPerPage: 6, currentPage: p2 }"
          [ngClass]="{' blt-3': reports.viewed == 'Y'}" (click)="updateNotify(reports)">

          <a href="javascript:void(0)" class="p-2 icon-appointments  ">
            <i *ngIf="reports.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3" aria-hidden="true"></i>
            <i *ngIf="reports.viewed != 'Y'" class="fa fa-bell fa-lg pt-2  icon-col-3" aria-hidden="true"></i>
          </a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
            <a href="javascript:void(0)">
              <p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
              </p>
              <p class="text-muted mb-1 text-small">{{reports.reason}}</p>
            </a>

          </div>

          <div class="btn-group float-right">
            <button class="btn btn-xs btn0col1" data-toggle="modal" data-backdrop="static" data-target="#doc-nav-bar"><i
                class="fa fa-eye" aria-hidden="true"></i></button>
          </div>



        </div>
      </div>



    </div>
  </div>
</div>












<!-- ************************************************************ -->
<!-- <div class="navbar navbar-light ">
    <div class="col-lg-3 col-xs-5"><img src="newassets/img/logo.png"  class=" float-left "/></div>
    <div class="col-lg-9 col-xs-7">
    <div class="float-right" style="margin-right: 45px;">
    <span class="float-left pt-3 d-none d-lg-block mr-2">
    Last login : {{lastLogin |date:'dd-MM-yyyy   hh:mm'}}
    </span>

      <a href="javascript:void(0)" class="btn btn-sm colr-1 ml-2 mt-2 p-2 nav-btns"> <i class="fa fa-home faa-pulse animated faa-fast icon-head"></i> </a>

     <a href="javascript:void(0)" class="btn btn-sm colr-2 ml-2 mt-2 p-2 nav-btns"> <i class="fa fa-calendar-check  faa-bounce animated icon-head"></i> </a>
     <a href="javascript:void(0)" class="btn btn-sm colr-3 ml-2 mt-2 p-2 nav-btns"> <i class="fa fa-poll  faa-flash animated faa-slow icon-head"></i> </a>
     <a href="javascript:void(0)" class="btn btn-sm colr-4 ml-2 mt-2 p-2 nav-btns"> <i class="fa fa-user-cog faa-shake animated icon-head"></i> </a>


     <div class="user d-inline-block">
        <label id="loginUserName" style="color:white"></label>
        <button class="btn btn-empty p-0" type="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <span>
                <img alt="Profile Picture" [src]="imgURL"  style="height: 40px;width:40px;" />
            </span>
        </button>

        <div class="dropdown-menu dropdown-menu-right mt-3">
            <a class="dropdown-item" routerLink="/EditProfile">Profile</a>
            <a class="dropdown-item"  (click)="changePassword()">Change Password</a>
            <a class="dropdown-item" (click)="logOut()">Sign out</a>
        </div>
    </div>


    </div>
    </div>




</div>  -->
<!-- ************************************************* -->
<!-- <nav class="navbar fixed-top">
    <div class="d-flex align-items-center"><a id="logo" href='javascript:void(0)' class="navbar-logo">
        <span class="logo d-none d-xs-block"></span>
        <span class="logo-mobile d-block d-xs-none"></span>
    </a>
        <a id="sliderButton" href="javascript:void(0)" class="menu-button d-none d-md-block">
            <svg class="main" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
                <rect x="0.48" y="0.5" width="7" height="2" />
                <rect x="0.48" y="7.5" width="7" height="2" />
                <rect x="0.48" y="15.5" width="7" height="2" />
            </svg>
            <svg class="sub" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
                <rect x="1.56" y="0.5" width="16" height="2" />
                <rect x="1.56" y="7.5" width="16" height="2" />
                <rect x="1.56" y="15.5" width="16" height="2" />
            </svg>
        </a>

        <a href="javascript:void(0)" class="menu-button-mobile d-xs-block d-sm-block d-md-none">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
                <rect x="0.5" y="0.5" width="25" height="2" />
                <rect x="0.5" y="7.5" width="25" height="2" />
                <rect x="0.5" y="15.5" width="25" height="2" />
            </svg>
        </a>

         <h3 style="color:white" id="pageHeaders"></h3>   <h3 style="color:white" id="submenu"></h3>
    </div>


    <div class="navbar-right">




        <div class="header-icons d-inline-block align-middle">


            <div class="position-relative d-none d-sm-inline-block">
                <label style="color:white;margin-right:10px;">Last Login : {{lastLogin |date:'dd-MM-yyyy   hh:mm'}}</label>
                <button class="header-icon btn btn-empty" type="button" id="iconMenuButton" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="simple-icon-grid"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right mt-3  position-absolute" id="iconMenuDropdown">
                    <a href="javascript:void(0)" class="icon-menu-item">
                        <i class="iconsmind-Equalizer d-block"></i>
                        <span>Settings</span>
                    </a>

                    <a href="javascript:void(0)" class="icon-menu-item">
                        <i class="iconsmind-MaleFemale d-block"></i>
                        <span>Users</span>
                    </a>

                    <a href="javascript:void(0)" class="icon-menu-item">
                        <i class="iconsmind-Puzzle d-block"></i>
                        <span>Components</span>
                    </a>

                    <a href="javascript:void(0)" class="icon-menu-item">
                        <i class="iconsmind-Bar-Chart d-block"></i>
                        <span>Profits</span>
                    </a>

                    <a href="javascript:void(0)" class="icon-menu-item">
                        <i class="iconsmind-File-Chart d-block"></i>
                        <span>Surveys</span>
                    </a>

                    <a href="javascript:void(0)" class="icon-menu-item">
                        <i class="iconsmind-Suitcase d-block"></i>
                        <span>Tasks</span>
                    </a>

                </div>
            </div>

            <div class="position-relative d-inline-block">
                <button class="header-icon btn btn-empty" type="button" id="notificationButton" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="simple-icon-bell"></i>
                    <span class="count">3</span>
                </button>
                <div class="dropdown-menu dropdown-menu-right mt-3 scroll position-absolute" id="notificationDropdown">

                    <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                        <a href="javascript:void(0)">
                            <img src="img/profile-pic-l-2.jpg" alt="Notification Image" class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                        </a>
                        <div class="pl-3 pr-2">
                            <a href="javascript:void(0)">
                                <p class="font-weight-medium mb-1">Joisse Kaycee just sent a new comment!</p>
                                <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                            </a>
                        </div>
                    </div>

                    <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                        <a href="javascript:void(0)">
                            <img src="img/notification-thumb.jpg" alt="Notification Image" class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                        </a>
                        <div class="pl-3 pr-2">
                            <a href="javascript:void(0)">
                                <p class="font-weight-medium mb-1">1 item is out of stock!</p>
                                <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                            </a>
                        </div>
                    </div>


                    <div class="d-flex flex-row mb-3 pb-3 border-bottom">
                        <a href="javascript:void(0)">
                            <img src="img/notification-thumb-2.jpg" alt="Notification Image" class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                        </a>
                        <div class="pl-3 pr-2">
                            <a href="javascript:void(0)">
                                <p class="font-weight-medium mb-1">New order received! It is total $147,20.</p>
                                <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                            </a>
                        </div>
                    </div>

                    <div class="d-flex flex-row mb-3 pb-3 ">
                        <a href="javascript:void(0)">
                            <img src="img/notification-thumb-3.jpg" alt="Notification Image" class="img-thumbnail list-thumbnail xsmall border-0 rounded-circle" />
                        </a>
                        <div class="pl-3 pr-2">
                            <a href="javascript:void(0)">
                                <p class="font-weight-medium mb-1">3 items just added to wish list by a user!</p>
                                <p class="text-muted mb-0 text-small">09.04.2018 - 12:45</p>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

        </div>

        <div class="user d-inline-block">
            <label id="loginUserName" style="color:white"></label>
            <button class="btn btn-empty p-0" type="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <span>
                    <img alt="Profile Picture" [src]="imgURL"  style="height: 40px;width:40px;" />
                </span>
            </button>

            <div class="dropdown-menu dropdown-menu-right mt-3">
                <a class="dropdown-item" routerLink="/EditProfile">Profile</a>
                <a class="dropdown-item"  (click)="changePassword()">Change Password</a>
                <a class="dropdown-item" (click)="logOut()">Sign out</a>
            </div>
        </div>
    </div>
</nav> -->


<div class="on-mobile">
  <footer class="footer-mobile ">
    <div class="container-fulid">
      <ul class="nav nav-pills nav-justified">
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/Appoinments" data-toggle="tooltip" title=""
            data-original-title="Appointments">
            <span>
              <i class="nav-icon fa fa-calendar-check m-col1"></i>
              <span class="nav-text">Appoinments</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/Calendar" data-toggle="tooltip" title=""
            data-original-title="Calendar">
            <span>
              <i class="nav-icon fa fa-calendar-day m-col2"></i>
              <span class="nav-text">Calendar</span>
            </span>
          </a>
        </li>
        <li class="nav-item center-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/dashboard" data-toggle="tooltip" title=""
            data-original-title="Home">
            <span>
              <i class="nav-icon fa fa-home"></i>
              <span class="nav-text">Home</span>

            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/BasicScheduler" data-toggle="tooltip" title=""
            data-original-title="Base Scheduler">
            <span>
              <i class="nav-icon fa fa-calendar-alt m-col3"></i>
              <span class="nav-text">Base Scheduler</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/EditProfile" data-toggle="tooltip" title=""
            data-original-title="Profile">
            <span>
              <i class="nav-icon fa fa-user-cog m-col4"></i>
              <span class="nav-text">Profile</span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</div>
<div class="modal fade" id="notificationViewer" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document"
    style="min-width:300px !important;height: 50% !important;margin-left: 39%;">
    <div class="modal-content" style="max-height:33% !important">
      <div class="modal-header">
        <h5 class="modal-title">Notification Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="block inv-section">
          <div class="">
            <div>
              <img alt="" src="./assets/img/logo.png" style="border-radius: none !important;">
            </div>
          </div>
          <div class="text-center">
            <div class="font-weight-medium mb-0 mt-1 text-center col-md-12">{{notifyToView?.objecttype
              |titlecase}} </div>
            <div class="text-muted mb-1 text-center text-small">{{notifyToView?.reason}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
