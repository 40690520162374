import { GlobalStorageService } from 'src/app/global-storage.service';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, interval, of } from 'rxjs';
import { DoctorPatientService } from '../../../../../src/app/AppServices/DoctorPatient.service';
import { DoctorService } from '../../../../../src/app/AppServices/doctor.service';
import { PatientScheduleControlService } from '../../../../../src/app/shared/services/patientScedulecontrol.service';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { formatDate } from '@angular/common';
import { ObservableService } from '../../../../../src/app/shared/services/observable.service';
import { IvfService } from '../services/ivf.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-ivf-female-prescription',
  templateUrl: './ivf-female-prescription.component.html',
  styleUrls: ['./ivf-female-prescription.component.css']
})
export class IvfFemalePrescriptionComponent implements AfterViewInit, OnChanges {
  isIvf = true;
  @Input() genderType;
  @Input() fromWhere;
  @Input() day;
  @Output() fromChild = new EventEmitter<any>();
  @Output() fromChild2 = new EventEmitter<any>();
  @Output() fromChild3 = new EventEmitter<any>();
  @Input() patientPersonalDetails: any;
  @Input() isSpouseView: Boolean = false;
  @Input() image: any;
  @Input() oocyteId: any;
  @Input() medicineList: any = [];
  @Input() testList: any = [];
  @Input() prescriptionOrTestIdsInfo: any
  public documentGrp: FormGroup;
  medications = []
  prescriptions = []
  isFormOpen = false
  isFirst = true
  isTestForm = false
  prescriptionForm: FormGroup;
  medicaltestForm: FormGroup
  public file
  filteredOptions: Observable<string[]>;
  filteredOptionsT: Observable<string[]>;
  options = [];
  optionsT = [];
  isSave = false
  ngSelect = 'selected'
  instructions: any[]
  ngSelectedType = 'selected'
  isNotesOpen = false
  public form: FormGroup;
  showFillUpForm: boolean = false
  minDate = new Date(1955, 0, 1);
  maxDate = new Date(2024, 0, 1);
  patientMedication
  CurrentTabIndex; ngSelectTestType;
  submitted = false;
  isDuration = false; medicineTypes; ngSelectLabType;
  labTestLists = [];
  selectedTestType: string;
  isInvestigationsVisible: boolean = false;
  investigationsList = [];
  ivfAddId = this.globalStorage.getItem('ivfAddressId');
  finalInvestigations: any[];
  constructor(private globalStorage: GlobalStorageService, private renderer: Renderer2,
    private formBuilder: FormBuilder, public dialog: MatDialog, private cd: ChangeDetectorRef,
    private drug: DoctorService,
    public router: Router, private sanitizer: DomSanitizer,
    private toaster: ToastrService,
    private route: ActivatedRoute, private observe: ObservableService,
    private controller: PatientScheduleControlService,
    private doctorPatientService: DoctorPatientService, private ivfServcie: IvfService, public activeModal: NgbActiveModal
  ) {
    this.observe.ivf_prescription_Reload.subscribe(value => {
      if (value == 'someValue') {
        this.reloadScreen()
      }
    })
    this.form = this.formBuilder.group({
      files: ['', Validators.required]
    });

    this.prescriptionForm = this.formBuilder.group({
      type: [],
      medicineName: [],
      dosage: [],
      morning: [0],
      afternoon: [0],
      evening: [0],
      numberOfDays: [0],
      instructions: [],
      refill: 0,
      numberofrefills: [],
      notes: [],
      inventoryId: 0,
      prescriptionId: []
    })

    this.medicaltestForm = this.formBuilder.group({
      "notes": [''],
      "testName": ['', Validators.required],
      "testType": [''],
      "instructions": ['', Validators.required]
    });
    if (this.fromWhere == 'TreatmentPlan' || this.fromWhere == 'showPlan') {
      this.getLabTests()
    }
  }
  loginUser; ivfDetails;
  reloadScreen() {
    this.medicaltestForm.reset()
    this.prescriptionForm.reset()
    this.prescriptions = []
    this.getLabTests()
    this.investigationsList = []
    this.medications = []
    this.submitted = false
  }


  // toggleSpouse(){
  //   // console.log('toggling spouse')
  //   this.patientPersonalDetails['patientName']= this.patientPersonalDetails?.spouseName;
  //   this.patientPersonalDetails['AppointmentDetails']['patientName']= this.patientPersonalDetails?.spouseName;
  //   this.patientPersonalDetails['userId']= this.patientPersonalDetails?.spouseId;
  //   this.patientPersonalDetails['AppointmentDetails']['userId']= this.patientPersonalDetails?.spouseId;
  //   if(this.patientPersonalDetails.gender == 'Male'){
  //     // console.log('male')
  //     this.patientPersonalDetails['gender']='Female';
  //   }else{
  //     // console.log('female')
  //     this.patientPersonalDetails['gender']='Male';
  //   }
  // }

  searchControl = new FormControl();
  filteredSearchOptions: any;

  ngAfterViewInit(): void {
    // console.log(this.patientPersonalDetails)


    interval(1000)
      .pipe(map(i => i % this.placeholders.length))
      .subscribe(i => {
        this.currentPlaceholder = this.placeholders[i];
      });


    if (this.globalStorage.getItem('loggedInAs') == 'IVF') {
      this.isIvf = true
    }
    else {
      this.isIvf = false
    }
    this.getInstructions();
    $('.carousel').carousel({
      interval: false,
    });
    var data = JSON.parse(this.globalStorage.getItem('LoginDetails'));
    this.loginUser = data?.data;
    // this.checkPrescriptionExistOrNot()
    // this.getDocotrAddress(this.loginUser?.doctorId)
    if (this.fromWhere == 'TreatmentPlan' || this.fromWhere == 'showPlan') {
      this.getMedicinesList()
    }
    this.medicineType();
    this.createPrescriptionForm();
    this.createMedicalTest();
    this.getLabTests();
    this.loadPatientActiveMedication(this.patientMedication)
    this.ivfDetails = JSON.parse(this.globalStorage.getItem('ivfDetails'))
    this.documentGrp = this.formBuilder.group({
      documentFile: new FormControl(File)
    });
    var req
    if (this.patientPersonalDetails?.type == "Hospital") {
      req = {
        "forUserId": this.patientPersonalDetails?.userId,
        "userId": this.patientPersonalDetails?.userId
      }
    }
    else {
      if (this.patientPersonalDetails?.isIPD == "Y") {
        req = {
          "forUserId": this.patientPersonalDetails?.userId,
          "userId": this.patientPersonalDetails?.userId
        }
      }
      if (this.router.url == '/clinic-appointments' || this.router.url == '/caretaker-dashboard' || this.router.url == '/in-patient-details' || this.router.url == '/ivf-patients' || this.router.url == '/ivf-dashboard' ||
        this.router.url == '/appointments' || this.router.url == '/in-patient-list' || this.router.url == '/users-list' || this.router.url == '/clinic-dashboard' || this.router.url == '/clinic-admin' || this.router.url == '/clinic-users' || this.router.url == '/diagnostic_users') {
        req = {
          "forUserId": this.patientPersonalDetails?.userId,
          "userId": this.patientPersonalDetails?.userId
        }
      }
      else {
        //this.getDocotrAddress(this.loginUser?.doctorId)
        req = {
          "forUserId": this.patientPersonalDetails?.userId,
          "userId": JSON.parse(this.globalStorage.getItem('doctorId'))
        }
      }
    }
    //this.getPatientAddress(req, this.patientPersonalDetails?.userId)
    //  this.getSavedPrescription(this.patientPersonalDetails)
    if (this.router.url == '/ivf-patients' || this.router.url == '/ivf-dashboard' || this.router.url == '/appointments') {

    }
    else {
      // this.drug.getDrugNames().subscribe(res => {
      //   this.drugNamesPush(res['data'])

      // })
    }

    this.filteredSearchOptions = this.searchControl.valueChanges.pipe(
      debounceTime(300), // Wait for 300 ms pause in events
      distinctUntilChanged(), // Ignore if next search term is same as previous
      switchMap(value => this.typeAheadeSearchByName(value)), // Switch to new search observable each time the term changes
      catchError(error => {
        // this.toaster.error(error?.['error']?.['message']);
        return of([]); // Return empty array on error
      })
    );

    this.filteredOptionsT = this.medicaltestForm.get('testName').valueChanges.pipe(
      startWith(''),
      map(value => this._filterT(value))
    );
    this.filteredOptions = this.prescriptionForm.get('medicineName').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
    $('#toggle').on('click', function (e) {
      e.preventDefault();
      $('#tweetdetails').toggleClass('open');
    });
    $('#toggle1').on('click', function (e) {
      e.preventDefault();
      $('#tweetdetails').toggleClass('open');
    });

    $(document).ready(function () {
      $("div.bhoechie-tab-menu>div.list-group>a").click(function (e) {
        e.preventDefault();
        $(this).siblings('a.active').removeClass("active");
        $(this).addClass("active");
        var index = $(this).index();
        $("div.bhoechie-tab>div.bhoechie-tab-content").removeClass("active");
        $("div.bhoechie-tab>div.bhoechie-tab-content").eq(index).addClass("active");
      });
    });
    // $('#prescription').modal('show');

    if (this.medicineList && this.medicineList.length > 0) {

      this.prescriptions = this.medicineList.map(item => {
        return {
          ...item,
          type: item?.treatmentType,
          medicineName: item?.name,
          refill: item?.numberofrefills,
          notes: item?.remark,
          numberOfDays: item?.duration,
          numberofrefills: parseInt(item?.units),
          amount: 1,
          enddate: this.calcEndDate(item?.duration),
          dosage: null,
          ivfaddressId: this.globalStorage.getItem('ivfAddressId'),
          medicineUsage: null,
          power: null,
          quantity: parseInt(item?.units)
        };
      });

      this.prescriptions.forEach(item => {
        delete item.treatmentType;
        delete item.name;
        // delete item.numberofrefills;
        delete item.remark;
        delete item.duration;
        delete item.units;
      });
      this.isSave = true
    }

  }

  searchType = 'template'
  isLoading: boolean = false;
  placeholders = ['Search Template', 'Search Ailment'];
  currentPlaceholder = 'Search Template';

  typeAheadeSearchByName(data) {
    if (data.length > 0) {


      this.isLoading = true;

      var body = {
        "addressId": this.globalStorage.getItem('ivfAddressId'),
      }

      if (this.searchType == 'template') {
        body["templateName"] = data
      }

      if (this.searchType == 'ailment') {
        body["ailment"] = data
      }

      // Return the observable instead of subscribing here
      return this.ivfServcie.searchTemplate(body).pipe(
        map(res => {
          let optionsD = [];
          // for (var sp of res['data']) {
          //   // var fullName = sp.firstName + " " + sp.lastName;
          //   var searchObject = {
          //     'firstName' : sp.firstName,
          //     'lastName' : sp.lastName,
          //     'number' : sp.cellNumber,
          //     'userId' : sp.userId,
          //   };
          //   this.optionsD.push(searchObject);
          // }
          optionsD = res['data'];

          this.isLoading = false;
          return optionsD;
        })
      );
    }
    else {
      return []
    }
  }

  isTemplateSelected: boolean = false;
  ivfPrescriptionTemplateId: any;
  getTemplate(template) {
    this.ivfServcie.ivfPrescriptionTemplateDetails(this.globalStorage.getItem('ivfAddressId'), template?.ivfPrescriptionTemplateId).subscribe(res => {
      this.prescriptions = res?.data?.ivftemplatemedication || [];
      this.selectedLabTests = res?.data?.ivftemplatemedicaltest || [];
      if (this.selectedLabTests?.length > 0) {
        this.isTestForm = !this.isTestForm
      }
      this.templateName = res?.data?.templateName;
      this.ailments = res?.data?.ailment
      this.reload = false;
      this.isTemplateSelected = true;
      this.ivfPrescriptionTemplateId = template?.ivfPrescriptionTemplateId;
      this.isSave = true
      setTimeout(() => {
        this.reload = true;
      });
    })
  }

  calcEndDate(duration) {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + duration);
    return currentDate.toISOString().slice(0, 19);
  }

  getInstructions() {
    this.drug.getIns().subscribe(e => {
      if (e['body']) {
        this.instructions = e["body"]['data']
      } else {
        this.instructions = e['data']
      }
    })
  }

  doctorName; dstreet1; dstreet2; dstate; dcity; dcountry; dzip
  getDocotrAddress(id) {
    if (this.router.url != '/in-patient-details') {
      this.drug.getAddressDetails(id).subscribe(res => {
        this.doctorName = res['data'][0]['contactName']
        this.dstreet1 = res['data'][0]['street1']
        this.dstreet2 = res['data'][0]['street2']
        this.dstate = res['data'][0]['state']
        this.dcity = res['data'][0]['city']
        this.dcountry = res['data'][0]['country']
        this.dzip = res['data'][0]['zip']
      })
    }
  }
  patientName; pstreet1; pstreet2; pstate; pcity; pcountry; pzip
  getPatientAddress(request, id) {
    this.drug.getPatientAddress(request, id).subscribe(res => {
      this.globalStorage.setItem('forUserId', JSON.parse(this.globalStorage.getItem('doctorId')))
      this.patientName = res['body']['data'][0]['contactName']
      this.pstreet1 = res['body']['data'][0]['street1']
      this.pstreet2 = res['body']['data'][0]['street2']
      this.pstate = res['body']['data'][0]['state']
      this.pcity = res['body']['data'][0]['city']
      this.pcountry = res['body']['data'][0]['country']
      this.pzip = res['body']['data'][0]['zip']
    }, error => {
      this.toaster.error(error.error.message)
      this.globalStorage.setItem('forUserId', JSON.parse(this.globalStorage.getItem('doctorId')))
    })
  }
  checkPrescriptionExistOrNot() {
    if (this.patientPersonalDetails?.['AppointmentDetails']?.['appointmentId']) {
      this.drug.checkPrescriptionExistOrNot(this.patientPersonalDetails?.['AppointmentDetails']?.['appointmentId'], this.patientPersonalDetails?.userId).subscribe(res => {
        if (res['body']?.['data']?.['medications'].length > 0 || res['body']?.['data']?.['medicaltests'].length > 0) {
          this.isFormOpen = false
          this.prescriptions = res['body']?.['data']?.['medications']
          this.medications = res['body']?.['data']?.['medicaltests']
        }
        else {
          this.prescriptions = []
        }

      })
    }
  }
  addMedication() {
    this.isFormOpen = true
    $('#upload-prescription-tab').removeClass('active')
    $('#pills-profile-tab').removeClass('active')
    $('#pills-home-tab').addClass('active')
    $('#pills-home').addClass('show active')
    $('#pills-profile').removeClass('show active')
    $('#upload-prescription').removeClass('show active')
  }
  addMedicalTest() {
    this.isTestForm = true
    $('#upload-prescription-tab').removeClass('active')
    $('#pills-profile-tab').addClass('active')
    $('#pills-home-tab').removeClass('active')
    $('#pills-home').removeClass('show active')
    $('#pills-profile').addClass('show active')
    $('#upload-prescription').removeClass('show active')
  }
  uploadPrescription() {
    $('#upload-prescription-tab').addClass('active')
    $('#pills-profile-tab').removeClass('active')
    $('#pills-home-tab').removeClass('active')
    $('#pills-home').removeClass('show active')
    $('#pills-profile').removeClass('show active')
    $('#upload-prescription').addClass('show active')
  }

  patientPrecription
  prescriptionList: any[] = []
  getSavedPrescription(appointment) {

    var appointmentObj = {
      "appointmentId": appointment.AppointmentDetails.appointmentId,
      "patientId": appointment.AppointmentDetails.userId
    }

    // this.doctorPatient.getPatientPrecriptionByAppointmentId(appointmentObj).subscribe(res=>{

    //   this.prescriptionList=res['data'];
    // },error=>{

    // })
  }
  // prescription formGroup Initialization
  createPrescriptionForm() {
    $('#pills-home-tab').addClass('active')
    this.prescriptionForm = this.formBuilder.group({
      "enddate": '',
      "medicineName": ['', Validators.required],
      "dosage": ['',],
      "medicineUsage": '',
      "notes": [''],
      "numberOfDays": ['', Validators.required],
      "quantity": [''],
      "numberofrefills": 0,
      "power": [''],
      "type": ['', Validators.required],
      "morning": [0],
      "patientId": this.patientPersonalDetails?.userId,
      "evening": [0],
      "afternoon": [0],
      "instructions": [Validators.required],
      "refill": [0, Validators.required],
      "inpatientId": this.patientPersonalDetails?.['AppointmentDetails']?.['inpatientId'],
      "hospitaladdressId": this.patientPersonalDetails?.['AppointmentDetails']?.['hospitalAddresId'],
      "ivfaddressId": this.globalStorage.getItem('ivfAddressId'),
      "amount": 0,
      "iventoryId": 0
    });

    // this.drug.getDrugNames().subscribe(res => {
    //   this.drugNamesPush(res['data'])

    // })
    this.filteredOptionsT = this.medicaltestForm.get('testName').valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.filteredOptions = this.prescriptionForm.get('medicineName').valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  get f() { return this.prescriptionForm.controls; }
  get m() { return this.medicaltestForm.controls; }
  // Medical Test Form Group Initialization
  createMedicalTest() {
    this.medicaltestForm = this.formBuilder.group({
      "notes": [''],
      "testName": ['', Validators.required],
      "testType": [''],
      "instructions": ['', Validators.required],
      "patientId": this.patientPersonalDetails?.userId,
      "inpatientId": this.patientPersonalDetails?.['AppointmentDetails']?.['inpatientId'],
      "hospitaladdressId": this.patientPersonalDetails?.['AppointmentDetails']?.['hospitalAddresId'],
      "ivfaddressId": this.patientPersonalDetails?.['AppointmentDetails']?.['ivfaddressId'],
      "amount": 300
    });

  }
  getLabTests() {
    // this.controller.labTestList('DIAGNOSTICCENTER', 'TEST').subscribe(res => {
    //   for (var item of res['data']) {

    //     this.labTestLists.push(item.lookupValue);
    //   }
    // })
    if (this.fromWhere == 'TreatmentPlan') {
      this.patientPersonalDetails = {}
      this.patientPersonalDetails.gender = this.genderType
    }
    this.labTestLists = []
    this.ivfServcie.getIvfStaticInvestigations().subscribe(res => {
      // console.log('static investigations')
      for (var item of res['data']) {
        // console.log('gender: '+this.patientPersonalDetails.gender);
        // console.log(item)
        if (item.lookupValue == 'ANDROLOGY') {
          if (this.patientPersonalDetails?.gender == 'Male' || this.genderType == 'Male') {
            this.labTestLists.push(item.lookupValue);
          }
        } else if (item.lookupValue == 'EMBRYOLOGY') {
          if (this.patientPersonalDetails?.gender == 'Female' || this.genderType == 'Female') {
            this.labTestLists.push(item.lookupValue);
          }
        } else {
          this.labTestLists.push(item.lookupValue);
        }
      }
      this.prefetchInvestigations();
    })
  }

  investigationsByTestType: { [testType: string]: any[] } = {};

  // Method to prefetch investigations for each test type
  prefetchInvestigations() {
    // this.labTestLists.forEach(testType => {
    //   this.ivfServcie.getIvfInvestigationsByType(this.patientPersonalDetails?.gender, this.ivfAddId, testType)
    //     .subscribe(res => {
    //       this.investigationsByTestType[testType] = res['body']['data'];
    //     });
    // });
    const body = {
      "gender": this.patientPersonalDetails?.gender || this.genderType,
      "ivfAddressId": this.globalStorage.getItem('ivfAddressId'),
    }

    if (this.patientPersonalDetails?.gender == 'Male' || this.genderType == 'Male') {
      body["testType"] = ["INVESTIGATION", "ANDROLOGY", "SURGERY"]
    }
    if (this.patientPersonalDetails?.gender == 'Female' || this.genderType == 'Female') {
      body["testType"] = ["INVESTIGATION", "EMBRYOLOGY", "SCAN"]
    }

    this.ivfServcie.fetchIvfMedicalTests(body).subscribe(res => {
      const data = res?.data

      if (data?.investigation) {
        this.investigationsByTestType['INVESTIGATION'] = data?.investigation
      }

      if (data?.andrology) {
        this.investigationsByTestType['ANDROLOGY'] = data?.andrology
      }

      // if(data?.embryology){
      //   this.investigationsByTestType['EMBRYOLOGY'] = data?.embryology
      // }


      if (data?.scan) {
        this.investigationsByTestType['SCANS'] = data?.scan
      }

      if (data?.surgery) {
        this.investigationsByTestType['SURGERY'] = data?.surgery
      }

      this.reload = false;
      setTimeout(() => {
        this.reload = true;
      });

    })
  }

  // getInvestigationsForTest method
  getInvestigationsForTest(testType: string): any[] {
    return this.investigationsByTestType[testType] || [];
  }

  onTestTypeChange(event: Event): void {
    this.ivfAddId = this.globalStorage.getItem('ivfAddressId')
    // console.log(this.patientPersonalDetails)
    const patientGender = this.patientPersonalDetails.gender
    this.investigationsList = []
    this.ivfServcie.getIvfInvestigationsByType(patientGender, this.ivfAddId, this.ngSelectTestType).subscribe(res => {
      this.investigationsList = []
      this.investigationsList = res['body']['data'];
      // console.log(this.investigationsList)
      this.isInvestigationsVisible = true;

    })
  }
  openForm() {
    this.isFormOpen = !this.isFormOpen
    this.submitted = false
    Object.keys(this.prescriptionForm.controls).forEach(key => {
      this.prescriptionForm.controls[key].setErrors(null)
      if (key === 'morning' || key == 'afternoon' || key == 'evening' || key == 'refill') {
        const defaultValue = 0;
        this.prescriptionForm.controls[key].setValue(defaultValue);
      }

    });
  }
  openLabTestForm() {
    this.isTestForm = !this.isTestForm
    this.submitted = false
    Object.keys(this.medicaltestForm.controls).forEach(key => {
      this.medicaltestForm.controls[key].setErrors(null)
    });
  }
  // adding row for Medical Test
  onAddMedicalTest() {

    this.medications.push(this.medicaltestForm.value)
    this.medicaltestForm.reset()
    this.isTestForm = false
    this.isSave = true
  }
  // adding row For Medications
  isPreviewOpen = false
  onAddMedications() {

    if (this.inventoryId == 0) {
      this.toaster.error('Please select appropiate Medicine from the List')
      return
    }
    this.submitted = true
    if (this.prescriptionForm.value.morning == 0 && this.prescriptionForm.value.evening == 0 && this.prescriptionForm.value.afternoon == 0) {
      this.toaster.error('Please enter one of the section(Morning,Afternoon,Evening) greater than 0')
      return
    }
    if (this.prescriptionForm.invalid) {
      return
    }
    for (var i = 0; i < this.prescriptions?.length; i++) {
      if (this.prescriptions?.[i]?.['medicineName'] === this.prescriptionForm.value.medicineName) {
        this.toaster.warning('Medicine with the same name is in the last ,moifying with the current medication properties')
        this.prescriptions.splice(i, 1)
      }
    }

    var DurationDate = new Date()
    DurationDate.setDate(DurationDate.getDate() + JSON.parse(this.prescriptionForm.value.numberOfDays));
    const DOB = formatDate(DurationDate, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');

    this.prescriptionForm.value.enddate = DOB.concat('Z')
    this.prescriptionForm.value.amount = this.prescriptionAmount;
    this.prescriptionForm.value.inventoryId = this.inventoryId;
    if (this.fromWhere == 'TreatmentPlan') {
      console.log('medications', this.prescriptionOrTestIdsInfo)
      //    this.prescriptionForm.value.prescriptionId=this.prescriptionOrTestIdsInfo?.['templatemedications']?.[0]?.['prescriptionId']
    }
    this.prescriptionForm.value.quantity = JSON.parse(this.prescriptionForm.value.morning) + JSON.parse(this.prescriptionForm.value.evening) + JSON.parse(this.prescriptionForm.value.afternoon),
      this.prescriptions.push(this.prescriptionForm.value)
    this.prescriptionForm.reset()
    this.isFormOpen = false
    this.isFirst = false
    this.isSave = true
    // this.isPreviewOpen=true
    //this.addMedication()
    //$('.carousel-control-prev').click();
  }
  // loading a file
  onFileInput(files: File) {
    const f = files

    if ((f[0].type) == "image/png" || (f[0].type) == "application/pdf") {
      if (f[0].size > 1048769) {
        this.toaster.warning('file should be 1 Mb')
      } else {
        this.file = files
        this.isSave = true
      }
      this.OnSubmit()

    } else {
      this.toaster.warning('file should be in jpeg or PNG')
    }

  }
  medicineType() {
    this.controller.lookupstatic('PHARMACY', 'MEDICINETYPE').subscribe(res => {
      this.medicineTypes = res['data']
    })
  }
  // toggle the prescription View
  showFill() {
    this.showFillUpForm = !this.showFillUpForm
  }
  // saving Prescripiton
  appointemntFor
  isTemplate: boolean = false;
  templateName = null;
  ailments = null;
  prescNotes: any;
  followUpDate: any = null;

  public OnSubmit(update?: any) {
    if (this.isTemplate && !this.templateName) {
      this.toaster.warning('Please Add Template Name')
      return
    }

    if (this.loginUser?.type == 'NUTRITIONIST') {
      this.appointemntFor = 'NUTRITIONIST'
    }
    else {
      this.appointemntFor = 'DOCTOR'
    }
    var formData = new FormData();
    if (this.file != undefined) {
      for (let i = 0; i < this.file.length; i++) {
        formData.append('prescriptionPic', this.file[i], this.file[i].name);
      }
    }
    var medicalPrescritionFor
    if (this.file) {
      medicalPrescritionFor = 'IMAGE'
    }
    else {
      medicalPrescritionFor = 'test'
    }
    var containsMedicalPrescription
    var containsTestPrescription
    if (this.prescriptions.length > 0) {
      containsMedicalPrescription = 'Y'
    }
    else {
      containsMedicalPrescription = 'N'
    }
    if (this.medications.length > 0) {
      containsTestPrescription = 'Y'
    }
    else {
      containsTestPrescription = 'N'
    }

    const checkedInvestigations = this.getAllCheckedInvestigations();
    if (this.fromWhere == 'TreatmentPlan') {
      var result = {
        "medications": this.prescriptions,
        "labTests": checkedInvestigations
      }
      this.activeModal.close(result)
      return
    }
    let body = {
      "appointmentDate": this.patientPersonalDetails['AppointmentDetails']['appointmentDate'] + 'T' + this.patientPersonalDetails['AppointmentDetails']['fromTime'] + '.000Z',
      "appointmentFor": this.appointemntFor,
      "ivfaddressId": this.ivfAddId,
      "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['referenceappointmentId'],
      "containsMedicalPrescription": this.prescriptions?.length > 0 ? "Y" : "N",
      "containsTestPrescription": checkedInvestigations?.length > 0 ? "Y" : "N",
      "medicalPrescritionFor": medicalPrescritionFor,
      "doctorId": this.patientPersonalDetails['AppointmentDetails']['doctorId'],
      "doctorName": this.patientPersonalDetails['AppointmentDetails']['doctorName'],
      "documentLocation": null,
      "documentName": '',
      "medications": this.prescriptions,
      "medicaltests": checkedInvestigations,
      "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
      "patientId": this.patientPersonalDetails?.userId,
      "patientName": this.patientPersonalDetails['AppointmentDetails']['patientName'],
      "payByPatient": 'Y',
      "addAsstemplate": this.isTemplate ? 'Y' : 'N',
      "addTreatmentPlan":this.fromWhere == 'showPlan'?'N':'Y',
      "templateName": this.templateName,
      "ailment": this.ailments,
      "notes": this.prescNotes,
      "followUpAppointmentDate": this.followUpDate
    }

    if (update) {
      body['ivfPrescriptionTemplateId'] = this.ivfPrescriptionTemplateId;
      body['addAsstemplate'] = 'Y'
    }
    if (this.oocyteId) {
      body['oocyteScanId'] = this.oocyteId;
      this.ivfServcie.savePrescriptionInOocyteScan(body, this.patientPersonalDetails?.['userId']).subscribe(res => {
        this.toaster.success('Prescription added.')
        this.isSave = false
        this.ngSelectTestType = '';
        this.investigationsList = [];
        this.finalInvestigations = [];
        this.fromChild2.emit()
        this.activeModal.close();
        // this.documentGrp.reset()
      }, error => {
        this.toaster.error(error['error']['message'])
      })
    } else {
      this.ivfServcie.savePrescriptions(body, this.patientPersonalDetails?.['userId']).subscribe(res => {
        this.toaster.success('Prescription added.')
        this.isSave = false
        this.ngSelectTestType = '';
        this.investigationsList = [];
        this.finalInvestigations = [];
        this.fromChild2.emit()
        this.activeModal.close();
        // this.documentGrp.reset()
      }, error => {
        this.toaster.error(error['error']['message'])
      })
    }

    return

    var request
    var req = {
      "appointmentDate": this.patientPersonalDetails['AppointmentDetails']['appointmentDate'],
      "appointmentFor": this.appointemntFor,
      "medicalPrescritionFor": medicalPrescritionFor,
      "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['referenceappointmentId'],
      "doctorId": this.loginUser?.doctorId,
      "doctorName": this.loginUser?.firstName + '' + this.loginUser?.lastName,
      "containsMedicalPrescription": containsMedicalPrescription,
      "containsTestPrescription": containsTestPrescription,
      "documentLocation": null,
      "documentName": '',
      "medications": this.prescriptions,
      "medicalTests": this.medications,
      "inpatientId": this.patientPersonalDetails['AppointmentDetails']['inpatientId'],
      "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']['hospitalAddresId'],
      "medicalprescription": {
        "patientId": this.patientPersonalDetails?.userId,
        "patientName": this.patientPersonalDetails?.patientName
      },
      "medicaltestprescription": {
        "patientId": this.patientPersonalDetails?.userId,
      },
      "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
      "patientId": this.patientPersonalDetails?.userId,
      "patientName": this.patientPersonalDetails?.patientName
    }
    var finalReq
    if (containsMedicalPrescription == 'Y') {
      finalReq = {
        "appointmentDate": this.patientPersonalDetails['AppointmentDetails']['appointmentDate'],
        "appointmentFor": this.appointemntFor,
        "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['referenceappointmentId'],
        "inpatientId": this.patientPersonalDetails['AppointmentDetails']['inpatientId'],
        "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']['hospitalAddresId'],
        "containsMedicalPrescription": containsMedicalPrescription,
        "containsTestPrescription": containsTestPrescription,
        "medicalPrescritionFor": medicalPrescritionFor,
        "doctorId": this.loginUser?.doctorId,
        "doctorName": this.loginUser?.firstName + ' ' + this.loginUser?.lastName,
        "documentLocation": null,
        "documentName": '',
        "medicalprescription": {
          "medications": this.prescriptions,
          "patientId": this.patientPersonalDetails?.userId
        },
        // "medicaltestprescription": {
        //   "medicalTests": this.medications,
        //   "patientId": this.patientPersonalDetails?.userId
        // },
        "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
        "patientId": this.patientPersonalDetails?.userId,
        "patientName": this.patientPersonalDetails?.patientName
      }
    }
    if (containsTestPrescription == 'Y') {
      finalReq = {
        "appointmentDate": this.patientPersonalDetails['AppointmentDetails']['appointmentDate'],
        "appointmentFor": this.appointemntFor,
        "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['referenceappointmentId'],
        "containsMedicalPrescription": containsMedicalPrescription,
        "containsTestPrescription": containsTestPrescription,
        "medicalPrescritionFor": medicalPrescritionFor,
        "doctorId": this.loginUser?.doctorId,
        "doctorName": this.loginUser?.firstName + ' ' + this.loginUser?.lastName,
        "documentLocation": null,
        "documentName": '',
        "inpatientId": this.patientPersonalDetails['AppointmentDetails']['inpatientId'],
        "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']['hospitalAddresId'],
        "medicaltestprescription": {
          "medicalTests": this.medications,
          "patientId": this.patientPersonalDetails?.userId
        },
        "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
        "patientId": this.patientPersonalDetails?.userId,
        "patientName": this.patientPersonalDetails?.patientName
      }
    }
    if (containsMedicalPrescription == 'Y' && containsTestPrescription == 'Y') {
      finalReq = {
        "appointmentDate": this.patientPersonalDetails['AppointmentDetails']['appointmentDate'],
        "appointmentFor": this.appointemntFor,
        "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['referenceappointmentId'],
        "containsMedicalPrescription": containsMedicalPrescription,
        "containsTestPrescription": containsTestPrescription,
        "medicalPrescritionFor": medicalPrescritionFor,
        "doctorId": this.loginUser?.doctorId,
        "doctorName": this.loginUser?.firstName + ' ' + this.loginUser?.lastName,
        "documentLocation": null,
        "documentName": '',
        "inpatientId": this.patientPersonalDetails['AppointmentDetails']['inpatientId'],
        "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']['hospitalAddresId'],
        "medicalprescription": {
          "medications": this.prescriptions,
          "patientId": this.patientPersonalDetails?.userId
        },
        "medicaltestprescription": {
          "medicalTests": this.medications,
          "patientId": this.patientPersonalDetails?.userId
        },
        "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
        "patientId": this.patientPersonalDetails?.userId,
        "patientName": this.patientPersonalDetails?.patientName
      }
    }

    if (this.file) {
      request = {
        "doctorSpecialization": this.loginUser.specialization,
        "appointmentFor": this.appointemntFor,
        "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['referenceappointmentId'],
        "refill": true,
        "medicalPrescritionFor": "IMAGEUPLOAD"
      }
    }
    else {
      if (this.medications.length == 0) {
        request = {
          "doctorSpecialization": this.loginUser?.specialization,
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['referenceappointmentId'],
          "refill": true,
          "medications": this.prescriptions,
          "medicalPrescritionFor": ""
        }
      }
      else {
        request = {
          "doctorSpecialization": this.loginUser?.specialization,
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']['referenceappointmentId'],
          "refill": true,
          "medications": this.prescriptions,
          "medicalTests": this.medications,
          "medicalPrescritionFor": ""
        }
      }
    }
    if (this.router.url == '/ivf-dashboard' || this.globalStorage.getItem('loggedInAs') == 'IVF') {
      if (containsMedicalPrescription == 'Y') {
        const formatedExpiryDate = moment.utc(this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        finalReq = {
          "appointmentDate": formatedExpiryDate,
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['referenceappointmentId'],
          "inpatientId": this.patientPersonalDetails['AppointmentDetails']?.['inpatientId'],
          "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']?.['hospitalAddresId'],
          "containsMedicalPrescription": containsMedicalPrescription,
          "containsTestPrescription": containsTestPrescription,
          "medicalPrescritionFor": medicalPrescritionFor,
          "doctorId": this.patientPersonalDetails?.['AppointmentDetails']?.doctorId,
          "ivfaddressId": this.ivfDetails?.ivfaddressId,
          "doctorName": this.patientPersonalDetails?.['AppointmentDetails']?.doctorName,
          "documentLocation": null,
          "documentName": '',
          "medications": this.prescriptions,
          // "medicaltestprescription": {
          //   "medicalTests": this.medications,
          //   "patientId": this.patientPersonalDetails?.userId
          // },
          "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
          "patientId": this.patientPersonalDetails?.userId,
          "patientName": this.patientPersonalDetails?.patientName,
          "prescriptionFill": "Y"
        }
      }
      if (containsTestPrescription == 'Y') {
        const formatedExpiryDate = moment.utc(this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        finalReq = {
          "appointmentDate": formatedExpiryDate,
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['referenceappointmentId'],
          "containsMedicalPrescription": containsMedicalPrescription,
          "containsTestPrescription": containsTestPrescription,
          "medicalPrescritionFor": medicalPrescritionFor,
          "doctorId": this.patientPersonalDetails?.['AppointmentDetails']?.doctorId,
          "doctorName": this.patientPersonalDetails?.['AppointmentDetails']?.doctorName,
          "ivfaddressId": this.ivfDetails?.ivfaddressId,
          "documentLocation": null,
          "documentName": '',
          "inpatientId": this.patientPersonalDetails['AppointmentDetails']?.['inpatientId'],
          "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']?.['hospitalAddresId'],
          // "medicalprescription": {
          //   "medications": this.prescriptions,
          //   "patientId": this.patientPersonalDetails?.userId
          // },

          "medicaltests": this.medications,
          "patientId": this.patientPersonalDetails?.userId,
          "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
          "patientName": this.patientPersonalDetails?.patientName,
          "prescriptionFill": "Y"
        }
      }
      if (containsTestPrescription == 'Y' && containsMedicalPrescription == 'Y') {
        const formatedExpiryDate = moment.utc(this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        finalReq = {
          "appointmentDate": formatedExpiryDate,
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['referenceappointmentId'],
          "containsMedicalPrescription": containsMedicalPrescription,
          "containsTestPrescription": containsTestPrescription,
          "medicalPrescritionFor": medicalPrescritionFor,
          "doctorId": this.patientPersonalDetails?.['AppointmentDetails']?.doctorId,
          "doctorName": this.patientPersonalDetails?.['AppointmentDetails']?.doctorName,
          "ivfaddressId": this.ivfDetails?.ivfaddressId,
          "documentLocation": null,
          "documentName": '',
          "inpatientId": this.patientPersonalDetails['AppointmentDetails']?.['inpatientId'],
          "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']?.['hospitalAddresId'],
          "medications": this.prescriptions,
          "medicaltests": this.medications,
          "patientId": this.patientPersonalDetails?.userId,
          "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
          "patientName": this.patientPersonalDetails?.patientName,
          "prescriptionFill": "Y"
        }
      }
    }
    if (this.patientPersonalDetails['AppointmentDetails']['inpatientId']) {
      if (containsMedicalPrescription == 'Y') {
        finalReq = {
          "appointmentDate": this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['referenceappointmentId'],
          "inpatientId": this.patientPersonalDetails['AppointmentDetails']?.['inpatientId'],
          "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']?.['hospitalAddresId'],
          "containsMedicalPrescription": containsMedicalPrescription,
          "containsTestPrescription": containsTestPrescription,
          "medicalPrescritionFor": medicalPrescritionFor,
          "doctorId": this.loginUser?.doctorId,
          "doctorName": this.loginUser?.firstName + ' ' + this.loginUser?.lastName,
          "documentLocation": null,
          "documentName": '',
          "medications": this.prescriptions,
          // "medicaltestprescription": {
          //   "medicalTests": this.medications,
          //   "patientId": this.patientPersonalDetails?.userId
          // },
          "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
          "patientId": this.patientPersonalDetails?.userId,
          "patientName": this.patientPersonalDetails?.patientName,
          "prescriptionFill": "Y"
        }
      }
      if (containsTestPrescription == 'Y') {
        finalReq = {
          "appointmentDate": this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],
          "appointmentFor": this.appointemntFor,
          "doctorAppointmentId": this.patientPersonalDetails['AppointmentDetails']?.['referenceappointmentId'],
          "containsMedicalPrescription": containsMedicalPrescription,
          "containsTestPrescription": containsTestPrescription,
          "medicalPrescritionFor": medicalPrescritionFor,
          "doctorId": this.loginUser?.doctorId,
          "doctorName": this.loginUser?.firstName + ' ' + this.loginUser?.lastName,
          "documentLocation": null,
          "documentName": '',
          "inpatientId": this.patientPersonalDetails['AppointmentDetails']?.['inpatientId'],
          "hospitaladdressId": this.patientPersonalDetails['AppointmentDetails']?.['hospitalAddresId'],
          // "medicalprescription": {
          //   "medications": this.prescriptions,
          //   "patientId": this.patientPersonalDetails?.userId
          // },

          "medicaltests": this.medications,
          "patientId": this.patientPersonalDetails?.userId,
          "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
          "patientName": this.patientPersonalDetails?.patientName,
          "prescriptionFill": "Y"
        }
      }


      this.controller.saveHospitalPrescription(finalReq, this.patientPersonalDetails?.['userId']).subscribe(res => {
        this.toaster.success('Prescription added.')
        this.isSave = false
        this.documentGrp.reset()
      }, error => {
        this.toaster.error(error['error']['message'])
      })


    }
    else {
      if (this.router.url == '/ivf-patients' || this.router.url == '/ivf-dashboard' || this.globalStorage.getItem('loggedInAs') == 'IVF') {
        const formatedExpiryDate = moment.utc(this.patientPersonalDetails['AppointmentDetails']?.['appointmentDate'],).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');
        this.controller.saveIvfPatientPrescription(finalReq, this.patientPersonalDetails?.['userId']).subscribe(res => {
          this.toaster.success('Prescription added.')
          this.isSave = false
          this.documentGrp.reset()
          this.activeModal.close()
        }, error => {
          this.toaster.error(error['error']['message'])
        })
      }
      else {
        this.controller.savePrescription(finalReq, this.patientPersonalDetails?.['userId']).subscribe(res => {
          this.toaster.success('Prescription added.')
          this.isSave = false
          this.documentGrp.reset()
        }, error => {
          this.toaster.error(error['error']['message'])
        })
      }
    }

    // formData.append('userprescriptionJson', JSON.stringify(request));         
    // formData.append('forUserId',JSON.stringify(this.patientPersonalDetails['AppointmentDetails']['userId']));
    // this.controller.sendPrescription(formData,this.patientPersonalDetails['AppointmentDetails']['userId']).subscribe(res => {
    //   this.toaster.success('Prescription added.')
    //   this.documentGrp.reset()
    // },error=>{
    //   this.toaster.error(error['error']['message'])
    // })
    $('.modal').modal('hide');
  }
  onRemoveRow(rowIndex: number) {
    this.prescriptions.splice(rowIndex, 1);
  }
  onPreviousRemoveRowTest(rowIndex: number) {
    this.activeMedicationList.splice(rowIndex, 1);
  }
  onRemoveRowTest(rowIndex: number) {
    this.medications.splice(rowIndex, 1);
  }
  editMedications() {
    this.prescriptionForm.patchValue({

    })
  }

  inventoryId = 0;
  selectAmount(value) {

    let drugSelected = this.drugFilteredList.filter(option => option?.drugname.includes(value))
    this.prescriptionAmount = drugSelected[0]['price']
    this.inventoryId = drugSelected[0]['inventoryId']
    this.drugFilteredList = []

  }


  private _filterT(value: string): string[] {
    if (value == undefined) {
      this.filteredOptionsT = this.medicaltestForm.get('testName').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filterT(value))
        );
    } else {
      const filterValue = value.toLowerCase();

      return this.optionsT.filter(option => option?.toLowerCase()?.includes(filterValue));
    }
  }
  private _filter(value: string): string[] {
    if (value == undefined) {
      this.filteredOptions = this.prescriptionForm.get('medicineName').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    } else {
      const filterValue = value.toLowerCase();

      return this.options
    }
  }
  prescriptionEdit(item, i) {
    this.isFormOpen = true
    this.addMedication()
    this.ngSelectedType = item['type']
    this.ngSelect = item['instructions']
    this.prescriptionForm.patchValue({
      "enddate": item['enddate'],
      "medicineName": item['medicineName'],
      "medicineUsage": item['medicineUsage'],
      "notes": item['notes'],
      "numberOfDays": item['numberOfDays'],
      "quantity": item['quantity'],
      "numberofrefills": item['numberofrefills'],
      "power": item['power'],
      "type": item['type'],
      "morning": item['morning'],
      "patientId": item['patientId'],
      "evening": item['evening'],
      "afternoon": item['afternoon'],
      "instructions": item['instructions'],
      "refill": item['refill']
    })
    this.inventoryId=item?.inventoryId;
    this.onRemoveRow(i)
  }
  presviousPrescEdit(item, i) {
    this.prescriptionForm.patchValue({
      "enddate": item['enddate'],
      "medicineName": item['medicineName'],
      "medicineUsage": item['medicineUsage'],
      "notes": item['notes'],
      "numberOfDays": item['numberOfDays'],
      "quantity": item['quantity'],
      "numberofrefills": item['numberofrefills'],
      "power": item['power'],
      "type": item['type'],
      "morning": item['morning'],
      "patientId": item['patientId'],
      "evening": item['evening'],
      "afternoon": item['afternoon'],
      "instructions": item['instructions']
    })
  }
  finalPrevi() {
    this.fromChild.emit(this.patientPersonalDetails)
    this.fromChild2.emit(this.prescriptions)
    this.fromChild3.emit(this.medications)
    //  $('#previewe').modal('show')
    //$('#tweetdetails').addClass('open');
  }
  medicalTestEdit(item, i) {
    //this.onAddMedicalTest()
    this.isTestForm = true
    this.ngSelect = item['testType']
    this.medicaltestForm.patchValue({
      "notes": item['notes'],
      "testName": item['testName'],
      "testType": item['testType'],
      "instructions": item['instructions'],
      "patientId": this.patientPersonalDetails['AppointmentDetails']['userId'],
    })
    this.onRemoveRowTest(i)
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.investigationsList) {
      if (this.investigationsList) {
        this.isInvestigationsVisible = true
      } else {
        this.isInvestigationsVisible = false
      }
    }

    if ('patientPersonalDetails' in changes) {
      // if(this.patientPersonalDetails!=undefined){
      //     if(this.patientPersonalDetails['profilePic']!=null){
      //       this.ImageSrc ="data:image/jpeg;base64,"+this.patientPersonalDetails['profilePic']
      //       this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.ImageSrc);
      //     }else{
      //     this.imgURL="/assets/img/avatar.png";
      //     }
      //     }
      if ('patientMedication' in changes) {
        if (this.patientMedication != undefined) {
          this.loadPatientActiveMedication(this.patientMedication);
        }
      }

      $('a').removeClass('active')
      $('#tab_start').addClass('active')
      $('#startTab').addClass('active')

      // this.calculateAge(this.patientPersonalDetails.dob)
      if (this.patientPersonalDetails?.['Appointment'] == 'Current') {
        this.CurrentTabIndex = 0
        this.getPatientChange();
      }
      if (this.patientPersonalDetails?.['Appointment'] == 'Upcoming') {
        // this.UpcomingTabIndex=0
        this.getPatientChange();
        this.getPatientMedicalReports(this.patientPersonalDetails);
      }
      if (this.patientPersonalDetails?.['Appointment'] == 'Previous') {
        // this.PreviousTabIndex=0
        this.getPatientChange();
        this.getPatientMedicalReports(this.patientPersonalDetails);
      }
    }
  }

  handleCheckedInvestigations(checkedInvestigations: any[]): void {
    this.isSave = checkedInvestigations.length > 0 ? true : false
    this.finalInvestigations = checkedInvestigations.map(investigation => {
      return {
        ...investigation,
        "notes": '',
        "ivfaddressId": this.ivfAddId,
        "instructions": '',
        "testType": this.ngSelectTestType,
        "patientId": this.patientPersonalDetails?.userId,
      }
    })
  }

  finalInvestigations2: { [type: string]: any[] } = {};

  handleCheckedInvestigations2(checkedInvestigations: any[], type: string): void {
    // console.log(checkedInvestigations)
    this.finalInvestigations2[type] = checkedInvestigations.map(investigation => {
      return {
        ...investigation,
        "notes": '',
        "ivfaddressId": this.ivfAddId,
        "instructions": '',
        "testType": type,
        "patientId": this.patientPersonalDetails?.userId,
      };
    });
    this.isSave = Object.keys(this.finalInvestigations2).some(type => this.finalInvestigations2[type].length > 0);
  }

  // Method to get all checked investigations across types
  getAllCheckedInvestigations(): any[] {
    let allInvestigations = [];
    for (let type in this.finalInvestigations2) {
      allInvestigations = allInvestigations.concat(this.finalInvestigations2[type]);
    }
    return allInvestigations;
  }


  getPatientChange() {
    this.patientMedication = this.patientPersonalDetails
  }
  patient
  getPatientMedicalReports(patient) {
    var patientDetails = {
      "forUserId": this.patientPersonalDetails.userId,
      "userId": this.patientPersonalDetails.userId
    }
    this.patient = patientDetails
  }
  searchTest(test) {
    this.optionsT = []
    if (this.router.url == '/ivf-dashboard' || this.router.url == '/appointments' || this.router.url == '/ivf-patients') {
      // console.log('ivf details', this.patientPersonalDetails)
      this.drug.getIvfMedicines('searchByMedicaltest/' + test + '/{hospitalAddresId}?hospitalAddresId=' + this.globalStorage.getItem('ivfAddressId')).subscribe(res => {
        // console.log('medicines', res)
        var medList = res['data'][0];
        // medList = JSON.parse(medList);
        this.optionsT.push(medList.serviceName);
        this.optionsT = this.removeDuplicateItems(this.optionsT);
      })
    }
  }
  prescriptionAmount = 0;
  drugFilteredList = []
  medicineLoading: boolean = false;
  searchMedicine(medicine) {
    this.options = [];
    this.drugFilteredList = [];
    if (this.router.url == '/ivf-dashboard' || this.router.url == '/appointments' || this.router.url == '/ivf-patients' || this.globalStorage.getItem('loggedInAs') == 'IVF') {
      // console.log('ivf details', this.patientPersonalDetails)

      // find/allmedicine/byname/{medicineName}/{hospitalAddresId}/{type}
      const addressId = this.globalStorage.getItem('ivfAddressId');
      const type = this.prescriptionForm.value.type
      if (medicine) {

        this.medicineLoading = true;
        this.drug.getIvfMedicines('find/allmedicine/byname?hospitalAddresId=' + addressId + '&medicineName=' + medicine + '&type=' + type).subscribe(res => {
          // console.log('medicines', res)
          var medList = res['data'].map(med => {
            return med.drugname;
          });
          this.drugFilteredList = res['data'];
          this.options = [];
          this.medicineLoading = false;

          // this.prescriptionAmount=medList['price']
          // medList = JSON.parse(medList);
          this.options = medList;
          // this.filteredOptions = this.prescriptionForm.get('medicineName').valueChanges
          // .pipe(
          //   startWith(''),
          //   map(value => this._filter(value))
          // );
          // this.options = this.removeDuplicateItems(this.options);
        })
      }

    }
    else {
      if (medicine) {
        // this.drug.getAllMedicines(medicine).subscribe(res => {
        //   var medList = res['data'];
        //   medList = JSON.parse(medList);
        //   this.options.push(medList.drugname);

        //   this.options = this.removeDuplicateItems(this.options);
        // })
      }
    }
  }

  removeDuplicateItems(arr) {
    return arr.filter((item,
      index) => arr.indexOf(item) === index);
  }

  public submit(files: FileList) {
  }
  drugNamesPush(res) {
    for (var i = 0; i < res.length; i++) {
      this.options.push(res[i].drugname)
    }
  }
  onKey(x) {
    if (x > 90 || x == 0) {
      this.isDuration = true;
      this.prescriptionForm.value.numberOfDays = '1'
    } else {
      this.isDuration = false;
    }
  }
  isMgml = false
  onMgMl(x) {
    if (x > 500 || x == 0) {
      this.isMgml = true;
      this.prescriptionForm.value.power = ''
    } else {
      this.isMgml = false;
    }
  }
  limit(x) {
    if (x > 5) {
      this.isUsage = true
    }
    else {
      this.isUsage = false
    }
  }
  isUsage = false
  refillscheck(q, r, s, t) {
    // if(p!=((q*t)+(r*t)+(s*t))){
    //   this.isUsage=true
    // }
    // else{
    //   this.isUsage= false
    // }
    var result = (q * t) + (r * t) + (s * t)
    this.prescriptionForm.patchValue({
      'numberofrefills': result
    })
    this.prescriptionForm.value.numberofrefills = result
  }
  activeMedicationList: any[] = []
  loadPatientActiveMedication(patient) {
    var medication = {
      // "forUserId":parseInt(patient.userId),
      // "isOnlyActive":true,
      // "pageNum": 1
      "forUser": {
        "forUserId": parseInt(patient?.userId),
        "userId": parseInt(patient?.userId)
      },
      "isOnlyActive": true,
      "maxResults": 100,
      "pageNum": 1
    }

    // this.doctorPatientService.getPatientActiveMedication(medication).subscribe(res => {
    //   this.activeMedicationList = res['data']
    // }, error => {
    //   this.toaster.error("Error retrieving medications.")
    // })

  }
  rightArrow() {
    $('.first').addClass('right-arrow-clicked')
    this.isNotesOpen = true
  }
  leftArrow() {
    this.isNotesOpen = false
  }
  closeModal() {
    this.fromChild2.emit()
    this.activeModal.close()
    // $('#prescription').modal('hide')
    // let currentUrl = this.router.url;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    // this.router.onSameUrlNavigation = 'reload';
    // this.router.navigate([currentUrl]);
  }
  selectedLabTests
  reload: boolean = true;
  getMedicinesList() {
    if (!this.day) {
      // this.prescriptions = this.prescriptionOrTestIdsInfo?.['treatmentPlanForMedicine']
      var DurationDate = new Date()
      DurationDate.setDate(DurationDate.getDate() + JSON.parse(this.prescriptionForm.value.numberOfDays));
      const DOB = formatDate(DurationDate, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
      this.isSave=true;

      let medicine = [];
      medicine = this.prescriptionOrTestIdsInfo?.['treatmentPlanForMedicine'].map((res)=>{
        return{
          medicineName: res.name,
          type: res.treatmentType,
          quantity: res.quantity,
          numberofrefills:res.numberofrefills,
          numberOfDays:res.day||1,
          afternoon:res.afternoon||0,
          evening:res.evening||0,
          morning:res.morning||0,
          notes:'',
          refill:res.refill||0,
          instructions:res.instructions,
          inventoryId:res.inventoryId,
          ivfaddressId:this.globalStorage.getItem('ivfAddressId'),
          amount:0,
          dosage:0,
          enddate:DOB.concat('Z')
        }
      });
this.prescriptions = medicine
      // this.selectedLabTests = this.prescriptionOrTestIdsInfo?.['treatmentPlanForTest']
      let tests = [];
      tests = this.prescriptionOrTestIdsInfo['treatmentPlanForTest'].map((res) => {
        return {
          testName: res.name,
          testType: res.treatmentType,
          ivfmedicaltestpriceId: res.ivfmedicaltestpriceId,
        };
      });


      this.selectedLabTests = tests;

      return
    }
    // console.log('tessss', this.prescriptionOrTestIdsInfo)
    if (this.prescriptionOrTestIdsInfo?.['templatemedications']?.[0]?.['prescriptionId'] != null || this.prescriptionOrTestIdsInfo?.['templatemedicaltests']?.[0]?.['prescriptionId'] != undefined) {
      const presId = this.prescriptionOrTestIdsInfo?.['templatemedications']?.[0]?.['prescriptionId'] || this.prescriptionOrTestIdsInfo?.['templatemedicaltests']?.[0]?.['prescriptionId']
      this.ivfServcie.getMedicinesAndTestList(this.day, this.genderType, parseInt(this.globalStorage.getItem('ivfAddressId')), presId).subscribe(res => {
        // console.log('medicine and test List', res['data'])
        this.prescriptions = res?.['data']?.['templatemedication']
        this.selectedLabTests = res?.['data']?.['templatemedicaltest']
        // console.log(this.selectedLabTests)

        // if(this.selectedLabTests?.length>0){
        //   this.isTestForm = !this.isTestForm
        // }
      }, error => {
        this.toaster.error(error?.error?.message)
      })
    }
  }
}
