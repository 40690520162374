import { GlobalStorageService } from 'src/app/global-storage.service';
import { formatDate } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApicallsService } from 'src/app/apicalls.service';
import { gpsService } from 'src/app/shared/services/gps.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
import { resetFakeAsyncZone } from '@angular/core/testing';
declare var $: any;
@Component({
  selector: 'app-pharma-navbar',
  templateUrl: './pharma-navbar.component.html',
  styleUrls: ['./pharma-navbar.component.css']
})
export class PharmaNavbarComponent implements OnInit {
  @Input() notificationsFromLayout: any
  @Input() primaryDetailsFromLayout: any
  constructor(private globalStorage: GlobalStorageService, private pharma: pharmaService, private reports: PatientScheduleControlService,
    private router: Router, private gps: gpsService, private scripts: ScriptsService,
    private toaster: ToastrService, private apiservice: ApicallsService) {

  }
  pharmacyId
  firstTimeLogin = false
  notificationLength
  notificationBell
  firstName;
  lastName;
  presentRole;
  p1: number = 1;
  lastLogin; p2: number = 1;
  roles; prescriptionPermissions = true; inventoryPermissions = true; profilePermissions = true; usersPermissions = true;
  StaffPermission=true;StockPermission=true;NewPermission=true;InPatientPermission=true;OPDPermission=true;
  UnPaidPermission=true;PaidPermission=true;OrderStatusPermission=true;CancelledPermission=true;ReturnsPermission=true;
  CreateOrderPermission=true;AddressPermission=true;PaymentsPermissions=true;
  ngOnInit(): void {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });

      $('[data-toggle="tooltip"]').on('click', function () {
        $(this).tooltip('hide')
      });
    });

    $("button, a, body").on("click", function (e) {
      $('.tooltip').hide();
      $('[data-toggle="tooltip"]').tooltip("hide");
    });

    //roles
    var role = JSON.parse(this.globalStorage.getItem('roles'))
    this.presentRole=role[0]?.['roleName']
    this.roles = role[0]?.['roleMenus']
    var setPrescriptionPermission = this.roles?.filter(obj => obj?.menuName == "Prescriptions")
    var setInventoryPermission = this.roles?.filter(obj => obj?.menuName == "Inventory")
    var setProfilePermission = this.roles?.filter(obj => obj?.menuName == "Profile")
    var setUserPermission = this.roles?.filter(obj => obj?.menuName == "Patients")
    var setStaffPermission =this.roles?.filter(obj=>obj?.menuName=='Staff')
    var setStockPermission = this.roles?.filter(obj=>obj?.menuName=='Stock')
    var setNewPermission = this.roles?.filter(obj=>obj?.menuName=='New')
    var setInPatientPermission = this.roles?.filter(obj=>obj?.menuName=='InPatient')
    var setOPDPermission = this.roles?.filter(obj=>obj?.menuName=='OPD')
    var setUnPaidPermission =this.roles?.filter(obj=>obj?.menuName=='Unpaid')
    var setPaidPermission =this.roles?.filter(obj=>obj?.menuName=='Paid')
    var setOrderStatusPermission =this.roles?.filter(obj=>obj?.menuName=='OrderStatus')
    var setCancelledPermission =this.roles?.filter(obj=>obj?.menuName=='Cancelled')
    var setReturnsPermission =this.roles?.filter(obj=>obj?.menuName=='Returns')
    var setCreateOrderPermission =this.roles?.filter(obj=>obj?.menuName=='CreateOrder')
    var setAddressPermission =this.roles?.filter(obj=>obj?.menuName=='Address')
    var setPaymentsPermissions =this.roles?.filter(obj=>obj?.menuName=='Payments')
    this.prescriptionPermissions=setPrescriptionPermission?.[0]?.active == 'Y' ? true :false
    this.usersPermissions=setUserPermission?.[0]?.active == 'Y' ? true :false
    this.profilePermissions=setProfilePermission?.[0]?.active == 'Y' ? true :false
    this.inventoryPermissions=setInventoryPermission?.[0]?.active == 'Y' ? true :false
    this.StaffPermission=setStaffPermission?.[0]?.active == 'Y' ? true :false
    this.StockPermission=setStockPermission?.[0]?.active == 'Y' ? true :false
    this.NewPermission=setNewPermission?.[0]?.active == 'Y' ? true :false
    this.InPatientPermission=setInPatientPermission?.[0]?.active == 'Y' ? true :false
    this.OPDPermission=setOPDPermission?.[0]?.active == 'Y' ? true :false
    this.UnPaidPermission=setUnPaidPermission?.[0]?.active == 'Y' ? true :false
    this.PaidPermission=setPaidPermission?.[0]?.active == 'Y' ? true :false
    this.OrderStatusPermission=setOrderStatusPermission?.[0]?.active == 'Y' ? true :false
    this.CancelledPermission=setCancelledPermission?.[0]?.active == 'Y' ? true :false
    this.ReturnsPermission=setReturnsPermission?.[0]?.active == 'Y' ? true :false
    this.CreateOrderPermission=setCreateOrderPermission?.[0]?.active == 'Y' ? true :false
    this.AddressPermission=setAddressPermission?.[0]?.active == 'Y' ? true :false
    this.PaymentsPermissions=setPaymentsPermissions?.[0]?.active == 'Y' ? true :false
    // if (setPrescriptionPermission?.[0]?.active == 'Y') {
    //   this.prescriptionPermissions = true
    // }
    // if (setInventoryPermission?.[0]?.active == 'N') {
    //   this.inventoryPermissions = true
    // }
    // if (setProfilePermission?.[0]?.active == 'Y') {
    //   this.profilePermissions = true
    // }
    // if (setUserPermission?.[0]?.active == 'Y') {
    //   this.usersPermissions = true
    // }
    //roles end

    this.loadNotif()
    if (window.location.pathname == '/pharmaDashboard' || window.location.pathname == '/cureMetric/pharmaDashboard') {
      this.notificationBell = false
    }
    else {
      this.notificationBell = true
    }
    this.scripts.loadScripts()
    this.getData()
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    // this.pharma.GetPathSession('primarydetails?pharmacyuserid=', obj.pharmacyUserId).subscribe(res => {
    //   if (res['body']['data']) {

    //   }


    // })
    this.lastLogin = obj['lastLogin']
    // this.firstName =obj['firstName']
    // this.lastName= obj['lastName']
    var dateDate = new Date(obj['lastLogin'])
    const date2 = formatDate(dateDate, "medium", 'en-us');
    this.lastLogin = date2
    if (this.lastLogin == "Jan 1, 1970, 5:30:00 AM") {
      this.firstTimeLogin = true
    }
    else {
      this.firstTimeLogin = false
    }





  }
 notifications = []; nonViewData; ViewedNotifications
  loadNotif() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    this.pharma.globalGetPath('notificationalerts/get/all?maxResult=100&pageNumber=' + this.p1 + '&pharmacyaddressId=' + obj['pharmacyaddressId']).subscribe(res => {
      this.notifications = res['body']['data']
      this.notificationLength = this.notifications?.filter((obj) => obj.viewed == 'N').length;
      this.nonViewData = this.notifications?.filter((obj) => obj.viewed == 'N')
      this.ViewedNotifications = this.notifications?.filter((obj) => obj.viewed == 'Y')
    })
  }
  getData() {
    this.firstName = this.primaryDetailsFromLayout?.['firstName']
    this.lastName = this.primaryDetailsFromLayout?.['lastName']
  }
  onSend(data) {
    var formData = new FormData();
    formData.append('screenshot', data.screenshot);
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + ' ' + time;
    var request = {
      "clientId": 11,
      "userId": this.globalStorage.getItem('pharmacyId'),
      "feedBackType": "TEST",
      "status": "NEW",
      "description": data.description,
      "userAgent": "test",
      "os": "Windows",
      "browser": "Chrome",
      "os_version": "windows-10",
      "browser_version": "85.0.4183.102",
      "device": "Unknown",
      "latitude": this.gps.latitude,
      "longitudes": this.gps.longitude,
      "feedbackTime": formatDate(dateTime, "yyyy-MM-dd", 'en-us')
    }
    formData.append('feedbackLoop', JSON.stringify(request));
    this.pharma.sendFeedback(formData).subscribe(res => {
      this.toaster.success('Feedback sent successfully')
    })
  }
  outsideClick() {
    alert('put your condition here');
  }
  signout() {
    this.pharma.globalPostQuery('user/logoutaspharmacy', this.globalStorage.getItem('pharmacyId')).subscribe(res => {
      if (res['status'] == 'SUCCESS') {
        //this.toaster.success(res['message'])
        this.router.navigate(['/pharmaLogin'])

        this.apiservice.isUserSessionExits = false
        this.apiservice.isforUserIdExits = false
        this.apiservice.ismoduleNameExits = false
        this.globalStorage.clear()
      }
    })
  }
  notificationtoshow
  updateNotify(data) {
    $('#notificationViewer').modal('show')
    this.notificationtoshow = data
    var req = {
      "active": data.active,
      "notificationalertsId": data.notificationalertsId,
      "objectkey": data.objectkey,
      "objecttype": data.objecttype,
      "pharmacyaddressId": data.pharmacyaddressId,
      "reason": data.reason,
      "viewed": "Y"
    }
    this.pharma.updateNotifications(data.notificationalertsId, req).subscribe(res => {
      // this.loadNotif()
      this.notificationLength = this.notificationLength - 1
    })
  }
  navigate(url) {
    $('#viewreminders').modal('hide')
    this.router.navigate([url])
  }
}

