 <div style="width:100%;">
 <h4 style="background-color: #166ad3; color: #fff; padding: 10px 10px;text-align: center;">Change Password
    <p style="float: right;"><a href="javascript:void(0)"   style="top: 0px;font-size: 25px;width: 100%;color: #fff;text-align: right;margin-top: -4px !important;" (click)="close()">×</a></h4> 
<div  class="container">
    <form  [formGroup]="resetPassword">
    <div class="row">
      
        <div class="col-md-6">
<div class="form-group">
            <label for="exampleInputEmail1">Old Password</label><span class="text-danger">*</span>
            <input type="password" (paste)="(false)" (copy)="(false)" formControlName="oldPassword" class="form-control"  [ngClass]="{ 'is-invalid': (submitted || f.oldPassword.touched) && f.oldPassword.errors}">
            <div *ngIf="(submitted || f.oldPassword.touched) && f.oldPassword.errors" class="invalid-feedback">
                <div *ngIf="f.oldPassword.errors.required">Old password is required</div>
            </div>
        </div>
        </div>

<div class="col-md-6">
    <div class="form-group">
    <label for="exampleInputEmail1">New Password</label><span class="text-danger">*</span>
    <input type="password" (paste)="(false)" (copy)="(false)" formControlName="newPassword"  class="form-control" [ngClass]="{ 'is-invalid': (submitted || f.newPassword.touched) && f.oldPassword.errors}">
     <div *ngIf="(submitted ||f.newPassword.touched) && f.newPassword.errors" class="invalid-feedback">
        <div *ngIf="f.newPassword.errors.required">Password is required</div>
        <div *ngIf="f.newPassword.errors.pattern">Password must contain 1 Number,1 LowerCase,1 UpperCase,1 special Character</div>
    </div>
</div>
</div>

<div class="col-md-6">
    <div class="form-group">
    <label for="exampleInputEmail1">Confirm Password</label><span class="text-danger">*</span>
    <input type="password" (paste)="(false)" (copy)="(false)" formControlName="confirmPassword"  class="form-control"  [ngClass]="{ 'is-invalid': (submitted ||f.confirmPassword.touched) && f.confirmPassword.errors}">
    <div *ngIf="(submitted ||f.confirmPassword.touched) && f.confirmPassword.errors" class="invalid-feedback">
        <div *ngIf="f.confirmPassword.errors.required">Password is required</div>
        <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
    </div>
</div>
</div>
<div class="col-md-6">
    <div class="form-group">
    <label for="exampleInputEmail1">OTP</label><span class="text-danger">*</span>
    <input type="text" (keypress)="numberOnly($event)" formControlName="otp"  class="form-control" [ngClass]="{ 'is-invalid': (submitted ||f.otp.touched ) && f.otp.errors}">
    <!-- <a class="btn" style="text-decoration: underline;color:blue;float:right" (click)="sendOTP()">Send OTP</a> -->
    <button type="button"(click)="sendOTP()" style="float:right;margin-top: 5px;" class="btn btn-success btn-sm">Send OTP</button>
    <div *ngIf="(submitted ||f.otp.touched ) && f.otp.errors" class="invalid-feedback">
        <div *ngIf="f.otp.errors.required">Otp is required</div>
    </div>
</div>
</div>
<div class="col-md-12">
    <div style="padding: 10px;float:right">
    <button  class="btn btn-primary btn-sm" (click)="submit()" type="button">Reset Password</button>
    <button style="margin-left: 15px;" class="btn btn-primary btn-sm" (click)="close()" type="button">Cancel</button>
    </div>
</div>
</div>

</form>
</div>
</div>



