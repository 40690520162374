<div class="modal-header">
    <h4 class="modal-title">Create New Patient</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <h4 class="head border-bottom p-2 mt-2 bg-primary-light">Patient Details</h4>
    <form [formGroup]="registerForm" class="mt-2">
        <div class="user row">

            <div class="col-md-3" *ngIf="!userFound">
                <label class="form-labe">Mobile Number <span class="text-danger">*</span></label>
                <input #cellInput type="text" ng2TelInput [appUnifiedValidation]="['required','minlength']"
                    [minlength]="10" maxlength="10"
                    [ng2TelInputOptions]="{autoHideDialCode: false, initialCountry: 'in', separateDialCode:true,formatOnDisplay:false}"
                    class="form-control" (focusout)="checkPhNumber()" appInputRestrict="mobileNumber"
                    formControlName="cellNumber" placeholder="Enter mobile number" />
            </div>
            <div class="col-md-3" *ngIf="userFound">
                <label class="form-labe">Mobile Number <span class="text-danger">*</span></label>
                <input type="text"  placeholder="Enter First Name" appInputRestrict="mobileNumber" [minlength]="10" maxlength="13"
                [appUnifiedValidation]="['required','minlength']" formControlName="cellNumber" (keyup)="phNumberModify()"  (focusout)="checkPhNumber()"
                    class="form-control" />
            </div>
            <div class="col-md-3">
                <label class="form-labe">First Name <span class="text-danger">*</span></label>
                <input type="text" appAppTitleCase placeholder="Enter First Name" appInputRestrict="alphaOnlyWithSpaces"
                    [appUnifiedValidation]="['required','minlength']" formControlName="firstName" [readonly]="userFound"
                    class="form-control" />
            </div>

            <div class="col-md-3">
                <label class="form-labe">Last Name <span class="text-danger">*</span></label>
                <input type="text" appAppTitleCase placeholder="Enter Last Name" appInputRestrict="alphaOnlyWithSpaces"
                    [appUnifiedValidation]="['required','minlength']" minlength="1" formControlName="lastName" [readonly]="userFound"
                    class="form-control" />
            </div>

            <div class="col-md-3">
                <label class="form-labe">Email ID</label>
                <input type="email" placeholder="Enter Email" [appUnifiedValidation]="['email']" [readonly]="userFound"
                    formControlName="emailId" class="form-control no-caps" />
            </div>

            <div class="col-md-12  float-right mt-2">
                <button type="button" class="btn btn-success float-right" *ngIf="!userFound"
                    (click)="savePatient()">Register</button>
            </div>
        </div>
    </form>
    <div class="orders mt-2">
        <h4 class="head border-bottom p-2 mt-2 bg-primary-light">Book Appointment</h4>
        <app-doctor-patient-appointment-booking [doctorDetails]="doctorDetails" [patientDetails]="patientDetails" *ngIf='userFound'></app-doctor-patient-appointment-booking>
    </div>
</div>
