import { GlobalStorageService } from 'src/app/global-storage.service';
import { EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { interval, ObjectUnsubscribedError, Subscription } from 'rxjs';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { Router } from '@angular/router';
import { DiagnosticServiceController } from 'src/app/shared/services/diagnostic.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { CaretakerService } from 'src/app/shared/services/caretaker.service';
import { ObservableService } from 'src/app/shared/services/observable.service';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IvfCompleteSemenAnalysisComponent } from 'projects/ivf/src/app/ivf-complete-semen-analysis/ivf-complete-semen-analysis.component';
import { IvfConfirmModalComponent } from 'projects/ivf/src/app/ivf-confirm-modal/ivf-confirm-modal.component';
import { RolesControllerService } from 'projects/ivf/src/app/roles-controller.service';
import { IvfFemalePrescriptionComponent } from 'projects/ivf/src/app/ivf-female-prescription/ivf-female-prescription.component';
import { IvfRegistrationHolderComponent } from 'projects/ivf/src/app/ivf-registration-holder/ivf-registration-holder.component';
// import { DialogComponentComponent } from 'projects/ivf/src/app/dialog-component/dialog-component.component';
import { PrecriptionComponent } from '../precription/precription.component';
import { ImagePrescriptionComponent } from './image-prescription/image-prescription.component';
import { ClinicRoomsForAssignComponent } from 'src/app/clinic-pages/clinic-rooms-for-assign/clinic-rooms-for-assign.component';
import { CommonPrescriptionComponent } from 'src/app/shared/components/common-prescription/common-prescription.component';
declare var $: any;
@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.css']
})
export class PatientDetailsComponent implements OnInit, OnChanges {

  page1 = 1;
  appointmentExpired: boolean = false;
  isAppointmentExpired: boolean = false;
  showPrescriptions: boolean = true;
  currentUserDetails: any;
  caseDetailAvailable: boolean = false;
  isDoctor:boolean=false
  constructor(private globalStorage: GlobalStorageService, private doctorPatient: DoctorPatientService, private doctorService: DoctorService, private ivfService: IvfService,
    private toastrService: ToastrService, private sanitizer: DomSanitizer, private controller: PatientScheduleControlService,
    private router: Router, private orders: DiagnosticServiceController, private formBuilder: FormBuilder, private observable: ObservableService,
    public clinicServices: ClinicService, private caretakerService: CaretakerService, private modalService: NgbModal, private rolesService: RolesControllerService) {
    this.observable.invokeEvent_vitals.subscribe(value => {
      if (value == 'someValue') {
        // console.log('after update',this.patientVitals)

      }
    })
    this.observable.callRecordsReload.subscribe(value => {
      if (value == 'someValue') {
        this.rpmCallRecord()

      }
    })
    this.observable.closeAndOpen.subscribe(value => {
      if (value) {
        // this.tabPatientDetails()
      }
    })
    this.rolesService.permissions$.subscribe(res => {
      if (res) {
        this.roles = res;
      }
    })
  }

  @Input() patientPersonalDetails: any;
  IvfDetails: any;
  roles: any;
  @Input() appointment: any
  @Output() childComponentValue = new EventEmitter<any>();
  @Output() childComponentValueSecond = new EventEmitter<any>()
  @Output() startCallFromPtDts = new EventEmitter<any>()
  @Output() reloadPatientDetails = new EventEmitter<any>();
  vitals; surgeryForm: FormGroup; roomForm: FormGroup; caseDetailsGeneralForm: FormGroup; menstrualHistory: FormGroup;
  obstetricHistory: FormGroup; clinicalExamination: FormGroup; hormonalassay: FormGroup; hsgOrssg: FormGroup; investigation: FormGroup
  selectedOption; allotBed; allRooms; submitted
  ImageSrc; prescriptionEnabled = false; isPreviousAppointments = false
  imgURL; isShow = false;
  p4 = 1; loginUser;
  isDisabled = true; isIvf = false
  isPatientDetails = false
  prescriptionImage
  associatedUserData
  setItemsPerPage = 8;
  medicalReportsData
  totalItems
  src; form: FormGroup;
  isHospital = false; medicalHistory = [];
  newsrc; file; subscription: Subscription;
  user = {
    skills: []
  }
  hormonalAssayList = []; maxDate; showPresAndNote = true
  minDate = new Date(1945, 0, 1);
  surgeryTypes: any[] = [{ id: 1, value: 'Elective surgery' }, { id: 2, value: 'Emergency Surgery' }]
  filled = 'Y'; type;
  ngOnInit(): void {
    this.childComponentValue.emit()
    this.setTabs();
    const passData = {
      reload: false,
      data: {}
    }
    //nav-tabsks
    // this.reloadPatientDetails.emit(passData)
    console.log(' patient component opened', this.patientPersonalDetails)
    // console.log(this.patientPersonalDetails)


    this.tabPatientDetails()
    this.caseDetailsGeneralForm = this.formBuilder.group({
      name: [''],
      age: [],
      presentingProblems: [''],
      pastTreatment: ['']
    })
    this.menstrualHistory = this.formBuilder.group({
      menarche: [''],
      cycles: [''],
      lmp: ['']
    })
    this.obstetricHistory = this.formBuilder.group({
      married: [''],
      firstOrSecondMarrige: [''],
      consanguineous: [''],
      sexualHistory: [''],
      pastContraceptiveHistory: [''],
      pastMedicalHistory: [''],
      personalHistory: [''],
      familyHistory: ['']
    })
    this.clinicalExamination = this.formBuilder.group({
      height: [],
      weight: [],
      bmi: [''],
      pr: [''],
      systolic: [''],
      diastolic: [''],
      cvsOrRs: [''],
      breast: [''],
      rt: [''],
      pOrA: [''],
      pOrS: [''],
      pOrV: [''],
      hirsuitisum: ['']
    })
    // this.investigation=this.formBuilder.group({
    //   hb:[''],
    //   fbs:[''],
    //   tlc:[''],
    //   hiv:[''],
    //   ure:[''],
    //   esr:[''],
    //   ppbs:[''],
    //   dlc:[''],
    //   hbSag:[''],
    //   cxr:[''],
    //   vdrl:[''],
    //   rbs:[''],
    //   btCt:[''],
    //   hcv:[''],
    //   ecg:[''],
    //   hba1c:[''],
    //   homo:['']
    // })
    this.hormonalassay = this.formBuilder.group({
      place: [''],
      date: [''],
      doc: [''],
      fsh: [''],
      lh: [''],
      fTwo: [''],
      prog: [''],
      prc: [''],
      t3: [''],
      t4: [''],
      tsh: [''],
      ft4: [''],
      insulion: [''],
      testo: [''],
      amh: ['']
    })
    this.hsgOrssg = this.formBuilder.group({
      date: [''],
      doc: [''],
      place: [''],
      cavity: [''],
      rttube: [''],
      lttube: [''],
      remarks: ['']
    })
    this.form = this.formBuilder.group({
      skills: this.buildSkills()
    });
    if(this.globalStorage.getItem('loggedInAs')=='DOCTOR'){
      this.isDoctor=true
    }
    else{
      this.isDoctor=false
    }
    if (this.router.url == '/diagnosticDashboard' || this.router.url == '/diagnosticAppointments' || this.router.url == '/diagnostic_users') {
      this.type = 'Diagnostic_center'
    }
    else if (this.router.url == '/in-patient-details' || this.router.url == '/in-patient-list' || this.router.url == '/clinic-admin'
      || this.router.url == '/clinic-users' || this.router.url == '/clinic-appointments' || this.router.url == '/clinic-dashboard' || this.router.url == '/users-list') {
      this.type = 'Hospital'
    }
    else if (this.router.url == '/caretaker-appointments' || this.router.url == '/caretaker-dashboard') {
      this.isIvf = false
    }
    else if (this.router.url == '/ivf-patients' || this.router.url == '/ivf-dashboard' || this.router.url == '/appointments') {
      // this.getCurrentUserDetails(this.patientPersonalDetails?.userId);
      // this.getCycle(this.patientPersonalDetails?.userId);


      this.isIvf = true
      // console.log('fullCalendar',this.IvfDetails,this.patientPersonalDetails)
      if (this.IvfDetails) {
        this.patientPersonalDetails = this.IvfDetails
        this.tabPatientDetails()
      }
      if (this.router.url == '/ivf-patients' || this.patientPersonalDetails?.AppointmentDetails?.appointmentStatus == 'ENTITY_CANCELLED') {
        this.showPresAndNote = false
      }
    }
    else {
      this.type = 'Doctor'
      this.isPreviousAppointments = true
    }
    this.checkPrescptionsAccess();
    this.surgeryForm = this.formBuilder.group({
      "description": [''],
      "doctorName": [''],
      "hospitalAddresId": [this.globalStorage.getItem('hospitalAddressId')],
      "hospitalName": [this.globalStorage.getItem('hospitalName')],
      "operationTheatreId": 1,
      "price": [''],
      "surgeryId": 0,
      "surgeryName": ['', Validators.required],
      "surgeryType": [''],
    })
    this.roomForm = this.formBuilder.group({
      "patientName": [''],
      "bedNumber": [],
      "price": []
    })
    function readUrl(input) {

      if (input.files && input.files[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          let imgData = e.target.result;
          let imgName = input.files[0].name;
          input.setAttribute("data-title", imgName);
        }
        reader.readAsDataURL(input.files[0]);
      }

    }
    
    this.filled = this.patientPersonalDetails?.AppointmentDetails?.prescriptionDone?this.patientPersonalDetails?.AppointmentDetails?.prescriptionDone:this.appointment?.prescriptionDone;
    console.warn("is filled or Not", this.filled, this.doctorService?.appointments);
    var toDay = new Date()
    var today = moment(toDay).format('YYYY-MM-DD');
    var date: any = new Date()
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds
    const source = interval(1000);
    const text = strTime;
    this.subscription = source.subscribe(val => this.updater(text));
    // this.getPatientAppointmentById(this.doctorService.appointments)
    //this.openMedication()
    var data = JSON.parse(this.globalStorage.getItem('LoginDetails'));
    this.loginUser = data?.data;
    this.isPatientDetails = true
    this.isMedicalHistory = false;
    this.isPrescription = false;
    this.isMedicalReports = false;
    this.isPrevious = false;


    this.tabPatientDetails()
    if (this.patientPersonalDetails?.type == 'Hospital') {
      this.isHospital = true
    }
    else {
      if (this.patientPersonalDetails?.isIPD == "Y") {

      }
      if (this.router.url == '/clinic-appointments') {

      }
      if (this.router.url == '/caretaker-dashboard') {

      }
      if (this.router.url == '/in-patient-details' || this.router.url == '/in-patient-list' || this.router.url == '/clinic-admin' || this.router.url == '/ivf-patients' || this.router.url == '/caretaker-appointments'
        || this.router.url == '/clinic-users' || this.router.url == '/appointments' || this.router.url == '/ivf-dashboard' || this.router.url == '/diagnostic_users' || this.router.url == '/clinic-dashboard' || this.router.url == '/users-list') {

      }
      if (this.globalStorage.getItem('loggedInAs') == "DOCTOR") {
        this.getDocotrAddress(this.loginUser?.doctorId)
      }
      this.isHospital = false
    }
    var req
    if (!this.isHospital) {
      req = {
        "forUserId": this.patientPersonalDetails?.userId,
        "userId": JSON.parse(this.globalStorage.getItem('doctorId'))
      }
    }
    else {
      req = {
        "forUserId": this.patientPersonalDetails?.userId,
        "userId": this.patientPersonalDetails?.userId
      }
    }
    
    this.getPatientAddress(req, this.patientPersonalDetails.userId);
    this.medicalReports()
    var request = {
      "forUserId": this.patientPersonalDetails?.userId,
      "userId": this.patientPersonalDetails?.userId
    }
    if (this.router.url == '/ivf-dashboard' || this.router.url == '/ivf-patients' || this.router.url == '/appointments') { }
    else {
      if (this.patientPersonalDetails?.userId) {
        this.controller.globalPost('Schedule/medicalreports/retrieve/user/' + this.p4 + '/' + this.setItemsPerPage, request, this.patientPersonalDetails?.userId).subscribe(res => {
          this.medicalReportsData = res['data']
          this.totalItems = res['totalResults']
        })
      }
    }

  }

  module: string;
  patDetailsTab: boolean = false;
  medHistory: boolean = false;
  priorAppointmentTab: boolean = false;
  previousAppointmentTab: boolean = false;
  prescriptionTab: boolean = false;
  inPatientTab: boolean = false;
  callRecordsTab: boolean = false;
  hospitalaptime: boolean = false;
  prescription: boolean = false;
  prescriptionImageupload: boolean = false;
  OpenVideoCallingbtn: boolean = false
  Diagnostic_center: boolean = false
  doctoraptime: boolean = false;

  caretakerNotesIscon:boolean = false;isRpmCaretaker=false
  setTabs() {
    this.module = this.globalStorage.getItem('loggedInAs')
    if(this.module=='CARETAKER'){
      console.log(this.globalStorage.getItem('rpmadminAddressId'))
      if(this.globalStorage.getItem('rpmadminAddressId')=='null' || this.globalStorage.getItem('rpmadminAddressId')==null){
        this.isRpmCaretaker=false
      }
      else{
        this.isRpmCaretaker=true
      }
    }
    switch (this.module) {
      case 'HOSPITAL':
        this.patDetailsTab = true;
        this.medHistory = true;
        this.prescriptionTab = true;
        this.previousAppointmentTab = true;
        this.hospitalaptime = true;
        this.prescription = true;
        this.prescriptionImageupload = true;
        this.caretakerNotesIscon = true;
        break;
      case 'DOCTOR':
        this.patDetailsTab = true;
        this.medHistory = true;
        this.previousAppointmentTab = true;
        this.prescription = true;
        this.prescriptionImageupload = true;
        this.OpenVideoCallingbtn = true;
        this.doctoraptime = true
        break;
      case 'DIAGNOSTIC_CENTER':
        this.patDetailsTab = true;
        this.medHistory = true;
        this.prescriptionTab = true;
        this.previousAppointmentTab = true;
        this.Diagnostic_center = true;
        break;
      case "PHARMACY":
        this.patDetailsTab = true;
        this.medHistory = true;
        this.prescriptionTab = true;
        this.previousAppointmentTab = true;

        break;
      case 'CARETAKER':
        this.patDetailsTab = true;
        this.medHistory = false;
        this.previousAppointmentTab = false;
        this.callRecordsTab = true
        break;
      case 'NUTRITIONIST':
        this.patDetailsTab = true;
        this.medHistory = true;
        this.previousAppointmentTab = true;
        break;
      default:
        console.error(this.module);
        break;
    }
  }


  profilePicData: String;
  getProfilePic() {
    if (this.patientPersonalDetails?.userId) {

      this.ivfService.fetchProfilePic(this.patientPersonalDetails?.userId).subscribe(res => {
        this.profilePicData = res['data']?.image;
      })
    }
  }

  onCycleClick(patientDetails, type?: string) {
    const modalRef = this.modalService.open(IvfConfirmModalComponent, {
      centered: false,
      windowClass: 'right w-50'
    });
    modalRef.componentInstance.details = patientDetails;
    if (type == 'edit') {
      modalRef.componentInstance.isEdit = true;
    } else if (type == 'stimulation') {
      modalRef.componentInstance.isStimulation = true;
    } else if (type == 'procedure') {
      modalRef.componentInstance.isProcedure = true;
    } else if (type == 'trigger') {
      modalRef.componentInstance.isTrigger = true;
    } else if (type == 'start') {
      modalRef.componentInstance.isStart = true;
    }

    modalRef.result.then(
      (userConfirmed: boolean) => {
        // this.getCurrentUserDetails(this.patientPersonalDetails?.userId)
        // this.getCycle(this.patientPersonalDetails?.userId);
        if (userConfirmed) {
          // const passData = {
          //   reload: true,
          //   data: this.patientPersonalDetails
          // }
          // this.reloadPatientDetails.emit(passData)
        }
      },
      () => { }  // Handle dismiss callback
    );
  }

  saveCycle(patientDetails) {
    console.log(patientDetails)
    const ivfAddressId = this.globalStorage.getItem('ivfAddressId');
    const patientId = patientDetails?.userReferenceId;
    const caseId = patientDetails?.caseDetailsID;
    if (caseId) {
      const body = {
        ivfAddressId: ivfAddressId,
        patientId: patientId,
        caseDetailId: caseId
      }
      this.ivfService.startIvfCycle(body).subscribe(res => {
        console.log('case created')
        const passData = {
          reload: true,
          data: this.patientPersonalDetails
        }
        // this.getCurrentUserDetails(this.patientPersonalDetails?.userId)
        // this.reloadPatientDetails.emit(passData)
      })
    }
  }

  addCycle() {
    const ivfAddressId = this.globalStorage.getItem('ivfAddressId');
    const patientId = this.currentUserDetails?.userReferenceId;
    const caseDetailId = this.currentUserDetails?.caseDetailId;
    if (this.caseDetailAvailable) {
      console.log('adding cycle', ivfAddressId, patientId, caseDetailId);
    }
  }

  currentCycle: any;
  // getCycle(userId) {
  //   // if(userId){
  //   //   this.ivfService.fetchCycle(this.globalStorage.getItem('ivfAddressId'), userId).subscribe(res => {
  //   //     this.currentCycle = res?.data;
  //   //     console.log(this.currentCycle)
  //   //   })
  //   // }
  // }

  // getCurrentUserDetails(userId) {
  //   // this.caseDetailAvailable = false;
  //   // if (userId) {
  //   //   this.ivfService.getIVFUserDetails(this.globalStorage.getItem('ivfAddressId'), userId).subscribe(res => {
  //   //     console.log('current userdetails')
  //   //     console.log(res)
  //   //     this.currentUserDetails = res['body']['data'];
  //   //     this.caseDetailAvailable = this.currentUserDetails?.caseDetailId ? true : false;
  //   //   })
  //   // }
  //   // this.getProfilePic();
  // }
  get f() { return this.surgeryForm.controls; }
  get r() { return this.roomForm.controls; }
  presentTime
  updater(time) {
    this.checkPrescrptionEnalbledOrNot()
    this.presentTime = this.convertH2M(time)
  }
  today: any
  openSemenAnalysisModaal() {
    const modalRef = this.modalService.open(IvfCompleteSemenAnalysisComponent, {
      windowClass: 'modal fade right active w-60',
      backdrop: 'static',
      keyboard: true,
      size: 'lg'
    });

  }
  checkPrescrptionEnalbledOrNot() {
    var toDay = new Date()
    const fromTime = this.appointment?.fromTime;
    const toTime = this.appointment?.toTime;
    // console.log("to Time", toTime, 'fromTime', fromTime, 'Personal Details', this.patientPersonalDetails);

    this.today = moment(toDay).format('YYYY-MM-DD');
    if (fromTime && toTime) {

      const toTimeInMinutes = this.convertH2M(this.patientPersonalDetails?.AppointmentDetails?.toTime);
      const fromTimeParts = fromTime.split(':');
      const toTimeParts = toTime.split(':');

      // Convert the hours and minutes to integers
      const fromHours = parseInt(fromTimeParts[0]);
      const fromMinutes = parseInt(fromTimeParts[1]);

      const toHours = parseInt(toTimeParts[0]);
      const toMinutes = parseInt(toTimeParts[1]);

      // Calculate the difference in minutes
      const minutesDifference = (toHours * 60 + toMinutes) - (fromHours * 60 + fromMinutes);

      // 'minutesDifference' now contains the difference in minutes between fromTime and toTime
      // console.log('Minutes Difference:', minutesDifference, this.presentTime, this.convertH2M(this.appointment.fromTime), this.convertH2M(this.appointment.toTime));
      // console.log(this.patientPersonalDetails?.AppointmentDetails?.appointmentDate == this.today, " Html condition", this.appointment?.appointmentDate == this.today, this.today, this.appointment?.appointmentDate);
      // console.log(this.convertH2M(this.appointment.fromTime), "fromTime", this.presentTime >= this.convertH2M(this.appointment.fromTime), "yes", this.presentTime <= this.convertH2M(this.appointment.toTime), "no");


    }

    if (this.appointment?.appointmentDate == this.today) {
      console.log('time',this.convertH2M(this.appointment.fromTime)+"00:30:00")
      if (this.presentTime >= this.convertH2M(this.appointment.fromTime) &&
        this.presentTime <= this.convertH2M(this.appointment.toTime)) {

        this.prescriptionEnabled = true
      } else {
        this.prescriptionEnabled = false
      }

    } else {
      this.prescriptionEnabled = false
    }
  }
  medicalReportsTestData
  convertH2M(timeInHour) {
    var timeParts = timeInHour?.split(":");
    return Number(timeParts?.[0]) * 60 + Number(timeParts?.[1]);
  }
  videoCheck() {
    var toDay = new Date()
    var today = moment(toDay).format('YYYY-MM-DD');
    // var sDate = moment(scheduleDate).format('YYYY-MM-DD');
    var date: any = new Date()
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds()
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds;
    // var fromTime=startString.toLocaleTimeString();
    // var ToTime=endString.toLocaleTimeString();
    if (this.patientPersonalDetails['AppointmentDetails']['appointmentDate'] == today) {
      if (strTime < moment.utc(this.patientPersonalDetails['AppointmentDetails']['fromTime'], 'hh:mm:ss').subtract(15, 'minutes').format('hh:mm:ss')) {
        this.toastrService.warning('Video will start before 15 mins of the appointment time')
        return this.isDisabled = false
      }
      else if (strTime > moment.utc(this.patientPersonalDetails['AppointmentDetails']['toTime'], 'hh:mm:ss').add(15, 'minutes').format('hh:mm:ss')) {
        this.toastrService.warning('Video call time expired')
        return this.isDisabled = false
      }
      else if (strTime > moment.utc(this.patientPersonalDetails['AppointmentDetails']['toTime'], 'hh:mm:ss').subtract(15, 'minutes').format('hh:mm:ss')) {
        return this.isDisabled = true
      }

    }
  }

  checkPrescptionsAccess() {
    console.log('checking appointment times')
    if (this.patientPersonalDetails?.AppointmentDetails?.appointmentDate && this.patientPersonalDetails?.AppointmentDetails.fromTime) {
      // Construct a string in the format "yyyy-mm-ddT11:30:00"
      const dateTimeString = `${this.patientPersonalDetails.AppointmentDetails.appointmentDate}T${this.patientPersonalDetails.AppointmentDetails.fromTime}`;

      // Parse the date and time string to a Date object, considering the local time zone
      const appointmentStartTimestamp = new Date(dateTimeString);

      // Get the current timestamp
      const currentTimestamp = new Date();

      // Calculate the end time, which is 24 hours after the appointment start time
      const appointmentEndTimestamp = new Date(appointmentStartTimestamp);
      appointmentEndTimestamp.setHours(appointmentEndTimestamp.getHours() + 24);
      console.log(appointmentStartTimestamp, currentTimestamp, appointmentEndTimestamp)
      appointmentStartTimestamp.setMinutes(appointmentStartTimestamp.getMinutes()-30)
      appointmentEndTimestamp.setMinutes(appointmentEndTimestamp.getMinutes()+30)
console.log('start time',appointmentStartTimestamp)
      if (currentTimestamp >= appointmentStartTimestamp && currentTimestamp <= appointmentEndTimestamp) {
      
        console.log('start time',appointmentStartTimestamp,appointmentEndTimestamp)
        this.showPrescriptions = true;
      } else {
        this.showPrescriptions = false;
      }
    } else {
      // Handle the case when appointmentDate or fromTime is missing
      this.showPrescriptions = false;
    }


  }

  public ngOnChanges(changes: SimpleChanges) {
    //     $('#tab_start').addClass('active')
    //       $('#startTab').addClass('active show')
    // $('#tab_three').removeClass('active show')

    // $('div').removeClass('active show')
    // $('#patdetails').addClass('active show')

    this.checkPrescrptionEnalbledOrNot()
    if ('patientPersonalDetails' in changes) {
      this.checkPrescrptionEnalbledOrNot();
      $('a').removeClass('active show')
      $('#startTab').addClass('active show')
      $('#medicalhistory').removeClass('active show')
      $('#tab_three').removeClass('active show')
      $('#previousapp').removeClass('active show')
      $('#tab_four').removeClass('active show')
      $('#tab_caseDetails').removeClass('active show')
      $('#callRecords').removeClass('active show')
      $('#patdetails').addClass('active show')
      // $('#tab_four').addClass('active show')


      // this.calculateAge(this.patientPersonalDetails.dob)
      if (this.patientPersonalDetails?.['Appointment'] == 'Current') {
        this.CurrentTabIndex = 0
        this.getPatientChange();
      }
      if (this.patientPersonalDetails?.['Appointment'] == 'Upcoming') {
        // this.UpcomingTabIndex=0
        this.getPatientChange();
        this.getPatientMedicalReports(this.patientPersonalDetails);
      }
      if (this.patientPersonalDetails?.['Appointment'] == 'Previous') {
        this.PreviousTabIndex = 0
        this.getPatientChange();
        this.getPatientMedicalReports(this.patientPersonalDetails);
      }
      this.checkPrescptionsAccess();

    }
  }
  doctorList
  getHospitalDoctors() {
    this.clinicServices.getAssociatedDoctorsList(this.globalStorage.getItem('hospitalAddressId')).subscribe(res => {
      this.doctorList = res['data']
    })
  }
  openPatientMedication() {
    // var date: any = new Date()
    // var hours = date.getHours();
    // var minutes = date.getMinutes();
    // var seconds = date.getSeconds()
    // date = moment(date).format('YYYY-MM-DD');
    // var ampm = hours >= 12 ? 'PM' : 'AM';
    // //hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    // hours = hours < 10 ? '0' + hours : hours;
    // minutes = minutes < 10 ? '0' + minutes : minutes;
    // seconds = seconds < 10 ? '0' + seconds : seconds;
    // var min = this.patientPersonalDetails?.AppointmentDetails?.fromTime?.slice(0, -3)
    // min = min?.substring(3)
    // this.isShow = true
    // this.prescriptionImage = false
    this.observable.openPrescrtionForPrimary()
  }
  openMedication() {
    // console.log('patientPersonalDetails',this.patientPersonalDetails)
    if (this.patientPersonalDetails?.AppointmentDetails?.isPrmary == 'Y' || this.patientPersonalDetails?.isPrmary == 'Y') {
      this.openPatientMedication()
    }
    else {
      var date: any = new Date()
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds()
      date = moment(date).format('YYYY-MM-DD');
      var ampm = hours >= 12 ? 'PM' : 'AM';
      //hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;
      var min = this.patientPersonalDetails?.AppointmentDetails?.fromTime?.slice(0, -3)
      min = min?.substring(3)
      this.isShow = true
     // var presciption = this.modalService.open(PrecriptionComponent, {
      if(this.router.url=='/dashboard' || this.router.url=='/Appoinments'){
        var presciption = this.modalService.open(CommonPrescriptionComponent, {
          centered: false,
          backdrop: 'static',
          keyboard: false,
          windowClass: 'modal-right hw-40 customz-index'
        })
      }
      else{
        var presciption = this.modalService.open(CommonPrescriptionComponent, {
          centered: false,
          backdrop: 'static',
          keyboard: false,
          windowClass: 'modal-left hw-40'
        })
      }
      presciption.componentInstance.patientPersonalDetails = this.patientPersonalDetails
      this.prescriptionImage = false
      presciption.result.then(
        (userConfirmed: boolean) => {
          if (userConfirmed) {
          this.childComponentValue.emit()
          }
        },
        () => { }  // Handle dismiss callback
      );
    }
  }
  openNotes(patientPersonalDetails) {
    this.childComponentValueSecond.emit(patientPersonalDetails)
    // this.observable.
  }
  buildSkills() {
    const arr = this.user.skills.map(skill => {
      return this.formBuilder.control(skill.symptomselectted);
    });
    return this.formBuilder.array(arr);
  }
  openImagePrescription() {
    // $('#prescriptionImage').modal('show')
    if(this.router.url=='/dashboard' || this.router.url=='/Appoinments'){
      var imagePrescription = this.modalService.open(ImagePrescriptionComponent, {
        windowClass: 'hw-40 modal-right customz-index',
        centered: false,
      })
      imagePrescription.componentInstance.patientPersonalDetails = this.patientPersonalDetails
      this.prescriptionImage = true
    }else{
      var imagePrescription = this.modalService.open(ImagePrescriptionComponent, {
        windowClass: 'hw-40 modal-left',
        centered: false,
      })
      imagePrescription.componentInstance.patientPersonalDetails = this.patientPersonalDetails
      this.prescriptionImage = true
    }
  }
  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z\_]+$/);
    return pattern.test(value);
  }
  tabPatientDetails() {
    this.checkPrescrptionEnalbledOrNot()
    $('.dtsTab').addClass('active show');
    $('.rpmusers').removeClass('active show');
    $(".myclick").addClass('active show');
    $(".myclick1").removeClass('active show');
    $(".removecls").removeClass('active show');
    $(".rpmCallRecords").removeClass('active show');
    $(".pre-appoint").removeClass('active show')
    $('.caseDetails').removeClass('active show')
    $('.rpmCallRecords').removeClass('active show')
    $('.medicalhistory').removeClass('active show')
    $('.preior-appointment').removeClass('active show')
    $('.previousapp').removeClass('active show')
    $('.daymedication').removeClass('active show')
    $('.prescriptions').removeClass('active show')
    $('.pat-prescription').removeClass('active show')
    this.isPrescriptionClicked=false
    this.isPatientDetails = true;
    this.todayMedication = false;
    // this.doctorPatient.getPatientPersonalDetails(this.patientPersonalDetails.userId).subscribe(res=>{
    //     var patientDetails=res['body'].data;
    //     patientDetails.AppointmentDetails=this.patientPersonalDetails;
    //     patientDetails.Appointment='Current';
    //     //patientDetails.Appointment='Upcoming';
    //    this.doctorService.OpenPatientSideNave(patientDetails);
    // },error=>{
    //     this.toastrService.error("Error in getting patient details");
    // })
    this.isMedicalHistory = false;
    this.isPrescriptionAndPrior = false;
    this.isPrescription = false;
    this.isMedicalReports = false;
    // this.getCurrentUserDetails(this.patientPersonalDetails?.userId)
  }
  UpcomingTabIndex
  PreviousTabIndex
  CurrentTabIndex
  vitalsArray

  caseSaved() {
    this.reloadPatientDetails.emit()
  }

  containsMedicalPrescription; containsTestPrescription
  onFileInput(files: File, type) {
    const f = files
    if (type == 'Medical Prescripiton') {
      this.containsMedicalPrescription = "Y"
      this.containsTestPrescription = "N"
    }
    if (type == 'Test Prescription') {
      this.containsMedicalPrescription = "N"
      this.containsTestPrescription = "Y"
    }
    if ((f[0].type) == "image/png" || (f[0].type) == "image/jpg" || (f[0].type) == "image/jpeg" || (f[0].type) == "application/pdf") {
      if (f[0].size > 1048769) {
        this.toastrService.warning('file should be 1 Mb')
      } else {
        this.file = files
      }
    } else {
      this.toastrService.warning('file should be in jpeg or PNG')
    }

  }
  onFileInput2(files: File, appointment) {
    const f = files
    if (((f[0].type) == "image/png" || (f[0].type) == "image/jpeg") || (f[0].type) == "application/pdf") {
      if (f[0].size > 1048769) {
        this.toastrService.warning('file should be 1 Mb')
      } else {
        this.file = files
        this.uploadReport(appointment)
      }


    } else {
      this.toastrService.warning('file should be in jpeg ,PNG or PDF')
    }

  }
  uploadReport(data) {
    console.log('my data:', data);
    var formData = new FormData();
    if (this.file != undefined) {
      for (let i = 0; i < this.file?.length; i++) {
        formData.append('medicalreport', this.file[i], this.file[i].name);
      }
    }
    formData.append('appointmentId', data?.diagnosticcenterAppointmentId);
    this.orders.uploadReport('appointment/upload/reports/topatient', formData).subscribe(res => {
      this.toastrService.success('Medical Report uploaded.')
      this.childComponentValue.emit()
      this.file=[]
    }, error => {
      this.toastrService.error(error['error']['message'])
      this.file=[]
    })
  }
  OpenVideoCalling(patientPersonalDetails) {
    this.videoCheck()
    if (this.isDisabled == true) {
      this.doctorPatient.videoCalling(patientPersonalDetails);
    }

    //this.videoCalling.open();
  }

  Age
  calculateAge(DOB): string {

    var d1 = moment(DOB);
    var d2 = moment(new Date());
    var days = moment.duration(d2.diff(d1)).asDays();
    var years = days / 360
    this.Age = years.toFixed(0);
    return this.Age
  }

  prescriptionListView
  previousAppointmentList: any[];
  patientPreviousAppointmentList
  getPatientPreviousAppointment() {

    this.patientPreviousAppointmentList = this.patientPersonalDetails
    // var patient={
    //   "forUserId":this.patientPersonalDetails.userId,
    //   "referenceEntityId":parseInt(this.globalStorage.getItem('doctorId'))
    // }
    // this.doctorPatient.getPatientPreviosAppointments(patient).subscribe(res=>{
    //           this.previousAppointmentList=res['data']
    // },error=>{

    // })
  }
  isMedicalHistory: boolean = false;
  isPrescription: boolean = false;
  isMedicalReports: boolean = false;
  isPrevious: boolean = false;
  isPrescriptionAndPrior: boolean = false
  tabMedicalHistory() {
    $("#tab_two").addClass('active show');
    $("#tab_two").toggleClass('active show');
    $('.myclick2Tab').addClass('active show');
    $('.removecls').removeClass('active show')
    $(".myclick").removeClass('active show');
    $(".rpmCallRecords").removeClass('active show');
    $(".pre-appoint").removeClass('active show')
    $('.opdList').addClass('active')
    $('.opdTab').addClass('active')
    $('.rpmusers').removeClass('active show')
    $('.caseDetails').removeClass('active show')
    $('.prescriptions').removeClass('active show')
    $('.pat-prescription').removeClass('active show')
    this.isPrescriptionClicked=false
    this.isMedicalHistory = true;
    this.todayMedication = false;
    // console.log('fullCalendar',this.IvfDetails)
    if (this.IvfDetails) {
      this.patientPersonalDetails = this.IvfDetails
    }
    this.isPrescription = false;
    this.isMedicalReports = false;
    this.isPrevious = false;
    this.isPrescriptionAndPrior = false
    var url = window.location.href;
    // var activeTab = url.substring(url.indexOf("#") + 1);
    // $(".tab-pane").removeClass("active in");
    // $("#" + activeTab).addClass("active in");
    // $('a[href="#'+ activeTab +'"]').tab('show')
  }
  isPrescriptionClicked=false
  tabPrescriptions(){
    $('.dtsTab').removeClass('active show');
    $('.medicalhistory').removeClass('active show');
    $('.previousapp').removeClass('active show');
    $('.daymedication').removeClass('active show');
    $('.pat-prescription').addClass('active show')
    $('.myclick2Tab').removeClass('active show');
    $(".myclick1").removeClass('active show');
    $(".myclick").removeClass('active show');
    $(".rpmCallRecords").removeClass('active show');
    $(".pre-appoint").removeClass('active show')
    $('.caseDetails').removeClass('active show')
    $('.prescriptions').addClass('active')
    this.isPrescriptionClicked=true
  }
  tabPrescriptionAndPrior(Appointment) {
    // $("#tab_three").addClass('active show');
    $('.removecls').addClass('active show')
    $('.myclick2Tab').removeClass('active show');
    $(".myclick1").removeClass('active show');
    $(".myclick").removeClass('active show');
    $(".rpmCallRecords").removeClass('active show');
    $(".pre-appoint").removeClass('active show')
    $('.caseDetails').removeClass('active show')
    $('.opdTab').addClass('active')
    $('.prescriptions').removeClass('active show')
    $('.pat-prescription').removeClass('active show')
    this.isPrescriptionClicked=false
    this.isPrescriptionAndPrior = true;
    this.todayMedication = false;
    if (Appointment == "Current") {
      this.isPrescription = true;
      this.isPrevious = false;
    } else {
      this.isPrescription = false;
      this.isPrevious = true;
    }
  }
  tabCaseDetails() {
    $('.caseDetails').addClass('active show')
    $('.myclick2Tab').removeClass('active show');
    $(".myclick1").removeClass('active show');
    $(".myclick").removeClass('active show');
    $(".rpmCallRecords").removeClass('active show');
    $(".pre-appoint").removeClass('active show')

    $('.dtsTab').removeClass('active show');
    $('#tab_two').removeClass('active show');
    $("#tab_three").removeClass('active show');
    $('#tab_Previous').removeClass('active show');
    $('.opdList').removeClass('active');
    $('.medicalhistory').removeClass('active show');
    $('.preior-appointment').removeClass('active show');
    $('.previousapp').removeClass('active show');
    $('.prescriptions').removeClass('active show')
    $('.pat-prescription').removeClass('active show')
    this.isPrescriptionClicked=false


    // console.log('case details tab',this.patientPersonalDetails)
    this.caseDetailsGeneralForm.patchValue({
      name: this.patientPersonalDetails?.AppointmentDetails?.patientName,
      age: this.patientPersonalDetails?.age
    })
    this.getCaseDetails()
    // this.getMedicalHistory(this.patientPersonalDetails?.AppointmentDetails?.userId)
    // this.getLookUpStaticMedicalHistory()
  }
  getMedicalHistoty() {
    if (this.router.url == '/ivf-patients' || this.router.url == '/ivf-dashboard' || this.router.url == '/appointments') {
      this.getLookUpStaticMedicalHistory()
      // var reqObj = {
      //   forUserId: this.patientPersonalDetails?.AppointmentDetails?.userId,
      //   userId: this.patientPersonalDetails?.AppointmentDetails?.userId,
      // }
      // this.doctorPatient.getIvfPatientMedicalHistory(reqObj).subscribe(res=>{
      //   console.log('medical response',res)
      //   this.medicalHistory = res['data']['personalsymptoms'];
      // this.user.skills = res['data']['personalsymptoms'];
      // this.form = this.formBuilder.group({
      //   skills: this.buildSkills()
      // });
      // if(this.user.skills?.length==0){
      //   this.getLookUpStaticMedicalHistory()
      // }
      // }, error => {
      //   this.toastrService.error("Error in getting patient medical history")
      // })
    }
  }
  editMenstrual(data, i) {
    this.menstrualHistory.patchValue({
      menarche: data?.menarche,
      cycles: data?.cycles,
      lmp: data?.lmp
    })
  }
  deleteMenstrual(i) {

  }
  caseDetails
  getCaseDetails() {
    // this.controller.getIvfCaseDetails(parseInt(this.globalStorage.getItem('ivfAddressId')), this.patientPersonalDetails?.AppointmentDetails?.userId).subscribe(res => {
    //   // console.log('casedetails',res)
    //   this.caseDetails = res['data']
    //   this.hsgArrayList = res['data']['hisHsgssgs']
    //   this.hormonalAssayList = res['data']['hormonalassays']
    //   this.menstrualHistoryList = res['data']['menstrualhistor']
    //   //this.medicalHistory=res['data']['investigations']
    //   this.medicalHistory = [];
    //   if (res['data']?.['investigations']) {
    //     for (var i = 0; i < res['data']?.['investigations']?.length; i++) {
    //       var obj = {
    //         investigationsId: res['data']?.['investigations']?.[i]?.['investigationsId'],
    //         symptomsdesc: res['data']?.['investigations']?.[i]?.['investigationsDesc'],
    //         symptomselectted: res['data']?.['investigations']?.[i]?.['investigationselectted']
    //       }
    //       this.medicalHistory.push(obj)

    //       this.user.skills = this.medicalHistory
    //       this.form = this.formBuilder.group({
    //         skills: this.buildSkills()
    //       });
    //     }
    //     // console.log('data is in investigations' + this.medicalHistory.length)
    //   }
    //   else {
    //     // console.log('no medical history')
    //     this.getMedicalHistoty()
    //   }
    //   // this.getLookUpStaticMedicalHistory();
    //   this.clinicalExamination.patchValue({
    //     height: res['data']?.['clinicalexaminations']?.[0]?.['ht'],
    //     weight: res['data']?.['clinicalexaminations']?.[0]?.['wt'],
    //     bmi: res['data']?.['clinicalexaminations']?.[0]?.['bmi'],
    //     pr: res['data']?.['clinicalexaminations']?.[0]?.['pr'],
    //     systolic: res['data']?.['clinicalexaminations']?.[0]?.['bp'],
    //     diastolic: res['data']?.['clinicalexaminations']?.[0]?.['bp'],
    //     cvsOrRs: res['data']?.['clinicalexaminations']?.[0]?.['cvs'],
    //     breast: res['data']?.['clinicalexaminations']?.[0]?.['breast'],
    //     rt: res['data']?.['clinicalexaminations']?.[0]?.['rt'],
    //     pOrA: res['data']?.['clinicalexaminations']?.[0]?.['pa'],
    //     pOrS: res['data']?.['clinicalexaminations']?.[0]?.['ps'],
    //     pOrV: res['data']?.['clinicalexaminations']?.[0]?.['pv'],
    //     hirsuitisum: res['data']?.['clinicalexaminations']?.[0]?.['hirsuitism'],
    //     localExamination: res['data']?.['clinicalexaminations']?.[0]?.['localExamination']
    //   })
    //   this.caseDetailsGeneralForm.patchValue({
    //     presentingProblems: res['data']?.['casedetailsinformation']?.['presentingProblemsAndDuration'],
    //     pastTreatment: res['data']?.['casedetailsinformation']?.['pastTreatmentHistory']
    //   })
    //   this.obstetricHistory.patchValue({
    //     married: res['data']?.['maritalhistory']?.['married'],
    //     firstOrSecondMarrige: res['data']?.['maritalhistory']?.['marriageType'],
    //     consanguineous: res['data']?.['maritalhistory']?.['consanguineous'],
    //     sexualHistory: res['data']?.['maritalhistory']?.['sexualHistory'],
    //     pastContraceptiveHistory: res['data']?.['maritalhistory']?.['pastcontraceptiveHistory'],
    //     pastMedicalHistory: res['data']?.['casedetailsinformation']?.['pastMedical'],
    //     personalHistory: res['data']?.['casedetailsinformation']?.['personalhistory'],
    //     familyHistory: res['data']?.['casedetailsinformation']?.['familyhistory']
    //   })
    // })
  }
  getMedicalHistory(userId) {
    this.controller.getMedicalHistory(userId).subscribe(res => {
      this.medicalHistory = res['data']['personalsymptoms'];
      this.user.skills = res['data']['personalsymptoms'];
      if (this.user.skills?.length == 0) {
        this.getLookUpStaticMedicalHistory()
      }
    })

  }

  tabMedicalReports() {
    $(".removecls").removeClass('active show');
    $(".pre-appoint").removeClass('active show');
    $(".myclick1").addClass('active show');
    $(".myclick").removeClass('active show');
    $(".rpmCallRecords").removeClass('active show');
    $(".pre-appoint").removeClass('active show')
    $('.opdTab').addClass('active')
    this.isMedicalReports = true;
    this.isMedicalHistory = false;

    this.isPrescription = false;
    // this.isMedicalReports = false;
    this.isPrevious = false;
    this.isPrescriptionAndPrior = false
  }
  previousAppointments
  tabPrevious(Appointment) {
    $("#tab_Previous").addClass('active show');
    $('.pre-appoint').addClass('active show')
    $('.myclick2Tab').removeClass('active show');
    $(".myclick1").removeClass('active show');
    $(".myclick").removeClass('active show');
    $(".rpmCallRecords").removeClass('active show');
    $("#tab_three").removeClass('active show');
    $('.removecls').removeClass('active show')
    // $(".pre-appoint").removeClass('active show')
    // $('.opdTab').addClass('active')
    $('.caseDetails').removeClass('active show')
    $('.prescriptions').removeClass('active show')
    $('.pat-prescription').removeClass('active show')
    this.isPrescriptionClicked=false

    this.patientPreviousAppointmentList = this.patientPersonalDetails
    this.isPreviousAppointments = true;
    this.todayMedication = false;
    console.log('previous appointments', this.patientPersonalDetails)

  }

  patientMedicalReports
  patient
  getPatientMedicalReports(patient) {
    var patientDetails = {
      "forUserId": this.patientPersonalDetails.userId,
      "userId": this.patientPersonalDetails.userId
    }
    this.patient = patientDetails
  }
  patientVitals
  patientMedication
  getPatientChange() {
    this.patientVitals = this.patientPersonalDetails;
    this.patientMedication = this.patientPersonalDetails
    this.isMedicalHistory = false;
    this.isPrescription = false;
    this.isMedicalReports = false;
    this.isPrevious = false;
    this.todayMedication = false;
    this.prescriptionListView = this.patientPersonalDetails
  }


  viewPrecription(precreption) {

  }


  appointmentDetails: any
  getPatientAppointmentById(appintment) {
    var appointment = {
      'appointmentId': appintment.appointmentId,
      "forUserId": this.patientPersonalDetails.userId,
    }
    this.doctorPatient.getPatientAppointmentByAppointmentId(appointment).subscribe(res => {
      this.appointmentDetails = res['data'];
      this.doctorPatient.openAppointment(this.appointmentDetails);
    }, error => {
      this.toastrService.error("Error in get appointment")
    })
  }
  medicalReports() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    var request = {
      "forUserId": this.patientPersonalDetails?.userId,
      "userId": this.patientPersonalDetails?.userId
    }
    if (this.router.url == '/ivf-dashboard' || this.router.url == '/ivf-patients' || this.router.url == '/appointments') { }
    else {
      if (this.patientPersonalDetails?.userId) {
        this.controller.globalPost('Schedule/medicalreports/retrieve/user/' + this.p4 + '/' + this.setItemsPerPage, request, this.patientPersonalDetails?.userId).subscribe(res => {
          this.medicalReportsData = res['data']
          this.totalItems = res['totalResults']
          var arr2 = []
        }
          , error => { }
        )
      }
    }
  }
  reportName
  showPdf; showImage
  viewReport(item: any, reportName, documentName) {
    this.reportName = reportName
    this.newsrc = null
    this.showPdf = false
    this.showImage = false
    if (documentName.includes(".png") || documentName.includes(".jpg")) {
      this.controller.getDocument(item, this.patientPersonalDetails.userId).subscribe(res => {
        this.src = "data:image/jpeg;base64," + res['data']
        this.showImage = true
        this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
        $('#viewpdfNEW').modal('show')
      })


    } else if (documentName.includes(".pdf")) {
      this.newsrc = null
      this.showPdf = true
      this.controller.getDocument(item, this.patientPersonalDetails.userId).subscribe(res => {
        this.src = "data:application/pdf;base64," + res['data']
        this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
        $('#viewpdfNEW').modal('show')
      })

    }

    //  var n= item2.includes(".pdf")
    // this.base64toPDF(item,item2)
  }

  doctorName; dstreet1; dstreet2; dstate; dcity; dcountry; dzip
  getDocotrAddress(id) {
    this.doctorService.getAddressDetails(id).subscribe(res => {
      this.doctorName = res['data'][0]['contactName']
      this.dstreet1 = res['data'][0]['street1']
      this.dstreet2 = res['data'][0]['street2']
      this.dstate = res['data'][0]['state']
      this.dcity = res['data'][0]['city']
      this.dcountry = res['data'][0]['country']
      this.dzip = res['data'][0]['zip']
    })
  }
  patientName; pstreet1; pstreet2; pstate; pcity; pcountry; pzip
  getPatientAddress(request, id) {
    var localForUserId = this.globalStorage.getItem('forUserId')
    this.globalStorage.setItem("forUserId", id)
    // this.globalStorage.setItem("loggedInAs", "PATIENT")
    if(this.router.url == '/diagnostic_users' || this.router.url == '/clinic-dashboard' || this.router.url == '/users-list'){

    }
    else{
      this.doctorService.getPatientAddress(request, id).subscribe(res => {
        this.globalStorage.setItem("forUserId", localForUserId)
        // this.globalStorage.setItem("loggedInAs", "DOCTOR")
        this.patientName = res['body']['data'][0]['contactName']
        this.pstreet1 = res['body']['data'][0]['street1']
        this.pstreet2 = res['body']['data'][0]['street2']
        this.pstate = res['body']['data'][0]['state']
        this.pcity = res['body']['data'][0]['city']
        this.pcountry = res['body']['data'][0]['country']
        this.pzip = res['body']['data'][0]['zip']
      }, error => {
        this.globalStorage.setItem("forUserId", localForUserId)
        // this.globalStorage.setItem("loggedInAs", "DOCTOR")
      })
    }
  }
  savePrescriptionImage() {
    var body = {
      "appointmentFor": "DOCTOR",
      "containsMedicalPrescription": this.containsMedicalPrescription,
      "containsTestPrescription": this.containsTestPrescription,
      "appointmentDate": this.patientPersonalDetails?.AppointmentDetails?.appointmentDate,
      "doctorAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
      "patientAppointmentId": this.patientPersonalDetails?.AppointmentDetails?.appointmentId,
      "medicalPrescritionFor": "IMAGE",
      // "medicalprescription":{"patientId": this.patientPersonalDetails['AppointmentDetails']['userId']},
      "patientId": this.patientPersonalDetails['AppointmentDetails']['userId'],
      "doctorId": this.loginUser.doctorId,
      "doctorName": this.loginUser.firstName + ' ' + this.loginUser.lastName
    }
    if (this.containsMedicalPrescription == 'Y') {
      body['medicalprescription'] = { "patientId": this.patientPersonalDetails['AppointmentDetails']['userId'] }
    }
    if (this.containsTestPrescription == 'Y') {
      body['medicaltestprescription'] = { "patientId": this.patientPersonalDetails['AppointmentDetails']['userId'] }
    }
    var formData = new FormData();
    if (this.file != undefined) {
      for (let i = 0; i < this.file.length; i++) {
        formData.append('prescriptionPic', this.file[i], this.file[i].name);
      }
    }
    formData.append('userprescriptionJson', JSON.stringify(body));
    formData.append('forUserId', JSON.stringify(this.patientPersonalDetails['AppointmentDetails']['userId']));
    this.controller.sendPrescriptionImage(formData, this.patientPersonalDetails.userId).subscribe(res => {
      this.file = []
      this.toastrService.success('Prescription sent successfully')
    }, error => {
      this.toastrService.error(error['error']['message'])
    })
  }

  addPrescripClose() {
    $('#prescriptionImage').modal('hide');
  }

  getTwentyFourHourTime(str) {
    // var d = new Date("1/1/2013 " + amPmString);
    //return d.getHours() + ':' + d.getMinutes()+ ':'+ '00';
    str = String(str).toLowerCase().replace(/\s/g, '');
    var has_am = str.indexOf('am') >= 0;
    var has_pm = str.indexOf('pm') >= 0;
    // first strip off the am/pm, leave it either hour or hour:minute
    str = str.replace('am', '').replace('pm', '');
    // if hour, convert to hour:00
    if (str.indexOf(':') < 0) str = str + ':00';
    // now it's hour:minute
    // we add am/pm back if striped out before
    if (has_am) str += ' am';
    if (has_pm) str += ' pm';
    // now its either hour:minute, or hour:minute am/pm
    // put it in a date object, it will convert to 24 hours format for us
    var d = new Date("1/1/2011 " + str);
    // make hours and minutes double digits
    var doubleDigits = function (n) {
      return (parseInt(n) < 10) ? "0" + n : String(n);
    };
    return doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes()) + ':' + '00';
  }
  hospitalPrescriptions; inPatientSurgeries; patientRoomDetails;
  inPatientRecord() {
    this.todayMedication = false;
    this.doctorService.getInpatientRecord(this.patientPersonalDetails?.inPatientId, this.patientPersonalDetails?.hospitalAddresId).subscribe(res => {
      this.hospitalPrescriptions = res['data']?.['hospitalprescription']
      this.inPatientSurgeries = res['data']?.['inpatientsurgeries']
      this.patientRoomDetails = res['data']?.['patientroom']
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    })
  }
  dStreet1; dStreet2; dState; dZip; dCountry; dCity; doctorAddress
  doctorAddressDetails(id) {
    if (id != -1 || id != undefined || id != null) {
      this.controller.getAddressDetails(id).subscribe(res => {
        this.doctorAddress = res['data'][0]
        this.dStreet1 = res['data']?.[0]?.['street1']
        this.dStreet2 = res['data']?.[0]?.['street2']
        this.dState = res['data']?.[0]?.['state']
        this.dCountry = res['data']?.[0]?.['country']
        this.dCity = res['data']?.[0]?.['city']
        this.dZip = res['data']?.[0]?.['zip']
      })
    }
  }
  getClass(data) {

  }
  getPatientAddresss(id) {
    var request = {

      "forUserId": id,
      "userId": id

    }
    this.controller.globalPost('address/get', request, id).subscribe(res => {
      this.patientName = res['data'][0]['contactName']
      this.pstreet1 = res['data'][0]['street1']
      this.pstreet2 = res['data'][0]['street2']
      this.pstate = res['data'][0]['state']
      this.pcity = res['data'][0]['city']
      this.pcountry = res['data'][0]['country']
      this.pzip = res['data'][0]['zip']
    })
  }
  fromAllPrescription; isData; prescriptionImageData; prescriptionFill; numberOfRefills; precName; imageIsPresent = false;
  medicalTestPrescription = []
  MedicationPrescriptions = []; prescriptionImageLocation; precId; userId;
  Createddate
  DoctorAppointmentId
  viewPrescription(reports, type) {
    if (type == 'test') {
      $('#viewTestPrescription').modal('show')
    }
    if (type == 'medicine') {
      $('#viewPrescription').modal('show')
    }
    this.fromAllPrescription = reports
    if (this.fromAllPrescription != undefined) {
      this.isData = true
    }
    this.prescriptionImageData = reports
    if (reports['doctorId'] != -1 || reports['doctorId'] != undefined || reports['doctorId'] != null) {
      this.doctorAddressDetails(reports['doctorId'])
    }
    this.userId = reports['patientId']
    this.doctorName = reports['doctorName']
    this.prescriptionFill = reports['prescriptionFill']
    this.numberOfRefills = reports['numberofrefills']
    this.getPatientAddresss(reports['patientId'])
    //this.pharmaAddress(1)
    this.precName = reports?.['documentName']
    if (reports?.['prescriptioncontainsImage'] == 'Y') {
      this.imageIsPresent = true
      if (reports?.['prescriptionId'] != -1 || reports?.['prescriptionId'] != undefined || reports?.['prescriptionId'] != null) {
        this.controller.postGlobal('prescription/getPrescriptionDetailsById?presciptionId=' + reports?.['prescriptionId'], this.userId).subscribe(res => {
          if (res['data'] != null && (res['data']?.['documentName'].includes(".png") || res['data']?.['documentName'].includes(".jpeg"))) {
            this.src = "data:image/jpeg;base64," + res['data']['image']

            this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
            this.showImage = true

          } else if (res['data'] != null && (res['data']?.['image'].includes(".pdf"))) {
            this.newsrc = null
            this.showPdf = true

            this.src = "data:application/pdf;base64," + res['data']['image']
            this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
          }
        })
      }
    }
    else {
      this.imageIsPresent = false
      this.medicalTestPrescription = this.medicalReportsData?.[0]?.['medicaltests']
      this.MedicationPrescriptions = this.medicalReportsData?.[0]?.['medications']
    }
    this.prescriptionImageLocation = reports['documentLocation']
    this.precId = reports['hospitalprescriptionId']
    this.Createddate = reports['lastUpdatedTs']
    this.DoctorAppointmentId = reports['doctorAppointmentId']
  }
  closeModal() {
    $('#viewPrescription').modal('hide')
  }
  getLookUpStaticMedicalHistory() {
    this.ivfService.getMedicalHistoryIVFData().subscribe(res => {
      this.medicalHistoryIfNoData = res['data']
      // console.log("investigations : "+res['data'].length)
      for (var i = 0; i < res['data']?.length; i++) {
        this.medicalHistoryIfNoData[i]['symptomselectted'] = false
        this.medicalHistoryIfNoData[i]['symptomsdesc'] = res['data'][i]['lookupValue']
      }
      // console.log('lookup' + this.medicalHistoryIfNoData.length)
      return this.user.skills = this.medicalHistoryIfNoData
    })
    // this.controller.getMedicalHistoryIfNoData().subscribe(res=>{
    //   this.medicalHistoryIfNoData=res['data']
    //   for(var i=0;i<res['data']?.length;i++){
    //     this.medicalHistoryIfNoData[i]['symptomselectted']=false
    //     this.medicalHistoryIfNoData[i]['symptomsdesc']=res['data'][i]['lookupValue']
    //   }
    //  return this.user.skills = this.medicalHistoryIfNoData
    // })
  }

  todayMedication: boolean = false;
  activeMedicationList;
  pagemed = 1;
  getTodayMedications() {
    $('.rpmusers').removeClass('active show')
    $('.prescriptions').removeClass('active show')
    $('.pat-prescription').removeClass('active show')
    this.isPrescriptionClicked=false
    this.todayMedication = true;

    let currentDay: any = new Date();
    currentDay = formatDate(currentDay, "yyyy-MM-dd", 'en-us')
    var body = {
      "appointmentDate": this.patientPersonalDetails?.AppointmentDetails?.appointmentDate,
      "medicinedate": currentDay,
      "patientId": this.patientPersonalDetails?.userId,
      "maxResult": 100,
      "pageNumber": 1
    }
    this.controller.getMedicationBydate(body, this.patientPersonalDetails?.userId).subscribe(res => {
      // console.log('current day med res:', res);
      this.activeMedicationList = res?.['body']?.['data'] || [];
    })
  }

  notesData; notingDisplay: boolean = false;
  viewcaretakerNots(data) {
    $('#patientNote').modal('show');
    if(this.patientPersonalDetails?.AppointmentDetails?.caretakerId){
      this.doctorService.getCaretakerNotes(data?.AppointmentDetails?.hospitaladdressId,data?.AppointmentDetails?.patientReferenceId).subscribe(res => {
        console.log('caretaker notrs:', res);
        this.notesData = res?.['data'] || [];
      },
        error => {
          this.toastrService.error(error?.['error']?.['message'])
        }
      )
    }else{
      this.notesData = [];
    }
  }

  closepatientNote() {
    $('#patientNote').modal('hide');
    this.notesData = [];
  }


  callDetails; callDetailsId; isShowApt = false
  rpmCallRecord() {
    $('.dtsTab').removeClass('active show')
    $('.myclick').removeClass('active show')
    $('.pre-appoint').removeClass('active show')
    $('.previousapp').removeClass('active show')
    $('.rpmusers').addClass('active show')
    $('.rpmCallRecords').addClass('active show');
    $('.daymedication').removeClass('active show')
    this.todayMedication = false;
    var toDay = new Date()
    this.today = moment(toDay).format('YYYY-MM-DD');
    // console.log('call records',this.today,this.patientPersonalDetails)
    if (this.patientPersonalDetails?.AppointmentDetails?.appointmentDate > this.today) {
      const button = document.getElementById("appointmentButton");
      if (button) {
        button.style.display = "none";
      }
    }
    else {
      this.isShowApt = false
    }

    // if(this.patientPersonalDetails?.AppointmentDetails?.caretakerrpmappointmentId && this.patientPersonalDetails?.AppointmentDetails?.caretakerId){
    //   this.caretakerService.getCaseDeatilsByAppointmentId(this.patientPersonalDetails?.AppointmentDetails?.caretakerrpmappointmentId,this.patientPersonalDetails?.AppointmentDetails?.caretakerId,this.patientPersonalDetails?.AppointmentDetails?.userId).subscribe(res=>{
    //     // console.log('call records',res)
    //     this.callDetails=res['data']
    //     this.callDetailsId=res['data']?.[0]?.['calldetailId']
    //     if(this.patientPersonalDetails){
    //       this.patientPersonalDetails['callDetailsId']=this.callDetailsId
    //     }
    //   },error=>{
    //     this.toastrService.error(error?.['error']?.['message'])
    //   })
    // }

    this.caretakerService.getPatientCallDetails(this.patientPersonalDetails?.userId, this.patientPersonalDetails?.caretakerId).subscribe(res => {
      this.callDetails = res['data'];
      this.callDetailsId = res['data']?.[0]?.['calldetailId'];
      if (this.patientPersonalDetails) {
        this.patientPersonalDetails['callDetailsId'] = this.callDetailsId;
      }
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    })
  }
  viewCallDataDetails = false; recordData
  viewCallData(data) {
    this.controller.saveRpmCallData(data)
    this.observable.rpmCallData();
    this.viewCallDataDetails = true
    this.recordData = data
    $('#viewCallData').modal('show');
  }
  showSpouseData(data) {
    this.childComponentValue.emit(data);
  }
  get skills() {
    return this.form.get('skills');
  };
  medicalHistoryIfNoData = []; personalSymptomsData = []
  checkedCheckBox(obj, id) {
    // console.log(obj)
    var selected
    if ($('#' + id).is(':checked')) {

      this.medicalHistoryIfNoData[id].symptomselectted = true

      selected = {
        personalsymptomsid: null,
        symptomselectted: true,
        symptomsdesc: obj.lookupValue,
        userId: this.patientPersonalDetails?.AppointmentDetails?.userId

      }
    }
    else {
      this.personalSymptomsData.splice(id, 1);
      this.medicalHistoryIfNoData[id].symptomselectted = false
    }
    this.personalSymptomsData.push(selected)
    //this.medicalHistoryIfNoData.map(obj => this.personalSymptomsData.find(o => o.lookupValue === obj.lookupValue) || obj);
    var selectedValues;
  }
  addHormonalAssay() {
    var obj = {
      "ameh": this.hormonalassay?.value.amh,
      "date": this.hormonalassay?.value.date,
      "doc": this.hormonalassay?.value.doc,
      "e2": this.hormonalassay?.value.fTwo,
      "fsh": this.hormonalassay?.value.fsh,
      "ft4": this.hormonalassay?.value.ft4,
      "insulin": this.hormonalassay?.value.insulion,
      "ivfaddressId": this.globalStorage.getItem('ivfAddressId'),
      "lh": this.hormonalassay?.value.lh,
      "patientId": this.patientPersonalDetails?.AppointmentDetails?.userId,
      "place": this.hormonalassay?.value.place,
      "prl": this.hormonalassay?.value.prc,
      "prog": this.hormonalassay?.value.prog,
      "t3": this.hormonalassay?.value.t3,
      "t4": this.hormonalassay?.value.t4,
      "testo": this.hormonalassay?.value.testo,
      "tsh": this.hormonalassay?.value.tsh
    }
    this.hormonalAssayList = this.hormonalAssayList || []
    this.hormonalAssayList.push(obj)
    this.hormonalassay.reset()
  }
  editForm(data, index) {
    this.hormonalassay.patchValue({
      place: data?.place,
      date: data?.date,
      doc: data?.doc,
      fsh: data?.fsh,
      lh: data?.lh,
      fTwo: data?.e2,
      prog: data?.prog,
      prc: data?.prl,
      t3: data?.t3,
      t4: data?.t4,
      tsh: data?.tsh,
      ft4: data?.ft4,
      insulion: data?.insulin,
      testo: data?.testo,
      amh: data?.ameh
    })
    this.hormonalAssayList.slice(index)
  }
  deleteItem(i) {
    this.hormonalAssayList.slice(i)
  }
  menstrualHistoryList = []
  addMenstrualHistory() {
    var obj = {
      "cycle": this.menstrualHistory?.value?.cycles,
      "ivfaddressId": this.globalStorage.getItem('ivfAddressId'),
      "lmp": this.menstrualHistory?.value?.lmp,
      "menarche": this.menstrualHistory?.value?.menarche,
      "patientId": this.patientPersonalDetails?.AppointmentDetails?.userId
    }
    this.menstrualHistoryList = this.menstrualHistoryList || []
    this.menstrualHistoryList.push(obj)
  }
  editHsgForm(data, index) {
    this.hsgOrssg.patchValue({
      date: data?.date,
      doc: data?.doc,
      place: data?.place,
      cavity: data?.cavity,
      rttube: data?.rttube,
      lttube: data?.lttube,
      remarks: data?.remarks
    })
  }
  hsgArrayList = []
  addhsg() {
    var obj = {
      "cavity": this.hsgOrssg?.value.cavity,
      "date": this.hsgOrssg?.value.date,
      "doc": this.hsgOrssg?.value.doc,
      "ivfaddressId": this.globalStorage.getItem('ivfAddressId'),
      "lttube": this.hsgOrssg?.value.lttube,
      "patientId": this.patientPersonalDetails?.AppointmentDetails?.userId,
      "place": this.hsgOrssg?.value.place,
      "remark": this.hsgOrssg?.value.remarks,
      "rttube": this.hsgOrssg?.value.rttube
    }
    this.hsgArrayList = this.hsgArrayList || []
    this.hsgArrayList.push(obj)
  }
  deleteHsgItem(i) {
    this.hsgArrayList.slice(i)
  }
  saveCaseDetails() {
    // //changing ivfadress from personalsymptom
    // for(let i=0;i<this.personalSymptomsData.length;i++){
    //   this.personalSymptomsData[0]
    // }
    let finalSymptomsData = []
    var datas = this.medicalHistoryIfNoData.filter(obj => obj?.symptomselectted == false)
    // console.log('save details' + datas.length)

    for (i = 0; i < this.personalSymptomsData.length; i++) {
      var res1 = {
        ivfaddressId: this.globalStorage.getItem('ivfAddressId'),
        investigationselectted: this.personalSymptomsData[i]['symptomselectted'],
        investigationsDesc: this.personalSymptomsData[i]['symptomsdesc'],
        patientId: this.patientPersonalDetails?.AppointmentDetails?.userId
      }
      finalSymptomsData.push(res1)

    }
    // console.log('personal symptoms'+this.personalSymptomsData)
    var res
    for (var i = 0; i < datas.length; i++) {
      res = {
        ivfaddressId: this.globalStorage.getItem('ivfAddressId'),
        investigationselectted: false,
        investigationsDesc: datas[i]?.lookupValue,
        patientId: this.patientPersonalDetails?.AppointmentDetails?.userId
      }
      this.personalSymptomsData.push(res)
      finalSymptomsData.push(res)
    }
    this.submitted = true


    const formValue = Object.assign({}, this.form.value, {
      skills: this.form.value.skills.map((symptomselectted, i) => {
        return {
          ivfaddressId: this.globalStorage.getItem('ivfAddressId'),
          investigationselectted: symptomselectted,
          investigationsDesc: this.user.skills[i].symptomsdesc,
          investigationsId: this.user.skills[i].investigationsId,
          patientId: this.patientPersonalDetails?.AppointmentDetails?.userId
        }
      })
    });
    var medHis
    // console.log(this.medicalHistory.length)

    if (this.medicalHistory?.length == 0) {
      medHis = finalSymptomsData
      // console.log("med history:"+medHis.length)
    }
    else {
      medHis = formValue.skills
      // console.log("form value:" + medHis.length)

    }
    //testing
    // console.log(formValue.skills.length)
    console.log(this.caseDetails)
    var body = {
      "casedetailsinformation": {
        "ivfaddressId": this.globalStorage.getItem('ivfAddressId'),
        "pastTreatmentHistory": this.caseDetailsGeneralForm?.value.pastTreatment,
        "patientId": this.patientPersonalDetails?.AppointmentDetails?.userId,
        "patientName": this.patientPersonalDetails?.AppointmentDetails?.patientName,
        "presentingProblemsAndDuration": this.caseDetailsGeneralForm?.value.presentingProblems,
        "pastMedical": this.obstetricHistory?.value.pastMedicalHistory,
        "personalhistory": this.obstetricHistory?.value.personalHistory,
        "familyhistory": this.obstetricHistory?.value.familyHistory,
        "casedetailsinformationId": this.caseDetails?.['casedetailsinformation']?.['casedetailsinformationId']
      },
      "clinicalexaminations": [
        {
          "bmi": this.clinicalExamination?.value.bmi,
          "bp": this.clinicalExamination?.value.systolic + '/' + this.clinicalExamination?.value.diastolic,
          "breast": this.clinicalExamination?.value.breast,
          "hirsuitism": this.clinicalExamination?.value?.hirsuitisum,
          "ht": this.clinicalExamination?.value.height,
          "ivfaddressId": this.globalStorage.getItem('ivfAddressId'),
          "lt": this.clinicalExamination?.value.rt,
          "pa": this.clinicalExamination?.value.pOrA,
          "patientId": this.patientPersonalDetails?.AppointmentDetails?.userId,
          "ps": this.clinicalExamination?.value.pOrS,
          "pv": this.clinicalExamination?.value.pOrV,
          "rt": this.clinicalExamination?.value.rt,
          "wt": this.clinicalExamination?.value.rt
        }
      ],
      "hisHsgssgs": this.hsgArrayList,
      "hormonalassays": this.hormonalAssayList,
      "investigations": medHis,
      "maritalhistory": {
        "consanguineous": this.obstetricHistory?.value.married,
        "ivfaddressId": this.globalStorage.getItem('ivfAddressId'),
        "marriageType": "civil",
        "married": this.obstetricHistory?.value.married,
        "pastcontraceptiveHistory": this.obstetricHistory?.value.pastContraceptiveHistory,
        "patientId": this.patientPersonalDetails?.AppointmentDetails?.userId,
        "sexualHistory": this.obstetricHistory?.value.sexualHistory
      },
      "menstrualhistor": this.menstrualHistoryList,
      "semenanalysis": [],
      "semenculturesensitivity": []
    }
    // console.log('save req',body)
    this.controller.saveIvfCaseDetails(body).subscribe(res => {
      // console.log(res)
      this.toastrService.success('Case Details Saved.')
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
      // console.log(error?.['error']?.['message'])
    })
  }
  startCall() {
    this.startCallFromPtDts.emit(this.patientPersonalDetails)
  }
  openQndA() {
    this.startCallFromPtDts.emit(this.patientPersonalDetails)
  }

  showPatientRegForm() {
    $('#patientDetails').modal('hide');
    $('#patientDetails12').modal('hide');
    $('#myModal').modal('show');
    setTimeout(() => {
      this.globalStorage.setItem('primaryUserId', JSON.stringify(this.currentUserDetails?.userId));
      this.globalStorage.setItem('wifeId', JSON.stringify(this.currentUserDetails?.userReferenceId));
      this.globalStorage.setItem('primaryReferenceId', JSON.stringify(this.currentUserDetails?.userReferenceId));
      this.globalStorage.setItem('isPrimaryRegistered', JSON.stringify(true));
    }, 3000)

  }

  addSpouseModal() {
    const modalRef = this.modalService.open(IvfRegistrationHolderComponent, {
      centered: false,
      windowClass: 'left w-70'
    });
    modalRef.result.then(
      (result) => {
        // console.log('Registration closed')
      },
      (reason) => {
      }
    );
    modalRef.componentInstance.patientId = this.currentUserDetails?.userReferenceId;
    modalRef.componentInstance.isPrimaryRegistered = true;
    modalRef.componentInstance.patientDetails = this.currentUserDetails;
  }

  openDialog() {
    // const modalRef = this.modalService.open(DialogComponentComponent, {
    //   centered: true,
    //   windowClass: 'modal-centered'
    // });
    // modalRef.componentInstance.data = {
    //   title: 'Add Case Details',
    //   message: 'Please Add Case Details to proceed'
    // };

    // modalRef.result.then(
    //   (result) => {
    //     if (result) {
    //       this.tabCaseDetails();
    //     }
    //   },
    //   (reason) => {
    //     // Handle dismissal of modal
    //   }
    // );
  }

  openPrescription() {
    if (!this.currentUserDetails?.caseDetailsID) {
      const modalRef = this.modalService.open(IvfFemalePrescriptionComponent, {
        centered: false,
        windowClass: 'right hw-40',
        backdrop: 'static',
        keyboard: false
      });
      modalRef.componentInstance.patientPersonalDetails = this.patientPersonalDetails;

      modalRef.result.then(
        (result) => {
          if (result) {
          }
        },
        (reason) => {
          // Handle dismissal of modal
        }
      );
    } else {
      this.openDialog();
    }
  }
  openRooms(){
    const modalRef = this.modalService.open(ClinicRoomsForAssignComponent, {
      centered: false,
      windowClass: 'modal-left hw-40',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.info = this.patientPersonalDetails;

    modalRef.result.then(
      (result) => {
        if (result) {
        }
      },
      (reason) => {
        // Handle dismissal of modal
      }
    );
  }

}
