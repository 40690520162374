import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-nutritionist-layout',
  templateUrl: './nutritionist-layout.component.html',
  styleUrls: ['./nutritionist-layout.component.css']
})
export class NutritionistLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
