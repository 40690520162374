import { GlobalStorageService } from 'src/app/global-storage.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { ApicallsService } from 'src/app/apicalls.service';
import { environment } from '../../../environments/environment';
import { PatientService } from './patient.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.patientBaseUrl
  doctorUrl = environment.doctorBaseUrl
  diagnosticUrl = environment.diagnosticBaseUrl
  pharmacyUrl = environment.pharmaUrl
  hospitalUrl=environment.hospitalUrl
  registeredNewEmail
  dataStringSource = new Subject<any>();
  private loginUserSubject = new Subject<any>();
  loginUsertObservable = this.loginUserSubject.asObservable();
  constructor(private globalStorage: GlobalStorageService, private httpClient: HttpClient, private ToastrService: ToastrService, private getDetails: PatientService, private apiServices: ApicallsService) { 
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      this.baseUrl = environment.patientBaseUrl1
      this.doctorUrl = environment.doctorBaseUrl1
      this.diagnosticUrl = environment.diagnosticBaseUrl1
      this.pharmacyUrl = environment.pharmaUrl1
      this.hospitalUrl=environment.hospitalUrl1
    }
  }

  signin(request: any): Observable<any> {

    return this.apiServices.post(this.baseUrl + 'login',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
    //   var a = btoa(JSON.stringify(request))
    // return this.apiServices.post('http://192.168.86.151:30021/api/patient/login',
    //   a, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
  }
  registeredEmail(email: any) {
    this.registeredNewEmail = email
    this.dataStringSource.next(this.registeredNewEmail);
  }
  signup(formDatas) {
    return this.apiServices.post(this.baseUrl + 'save',
    formDatas, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response','moduleName': 'PATIENT' })
  }
  logOut(userId) {
    let params = new HttpParams().set('forUserId', userId);
    return this.apiServices.post(this.baseUrl + "logoutaspatient?type=web&userId=" + userId, {}, {
      params: params,
      headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': userId.toString() }
    })
  }
  resetPassword(request: any) {

    return this.apiServices.post(this.baseUrl + 'password/reset',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response', })
  }

  checkOldPassword(email,password,username){
    return this.apiServices.get(this.baseUrl+'oldPaswwordVerification?emailId='+email+'&paswword='+password+'&userName='+username,{})
  }
  validateOldPassword(email, password, userName)
  {
    let params = new HttpParams()
    return this.apiServices.get(this.baseUrl + 'oldPaswwordVerification?emailId=' + email +  "&paswword=" + password + "&userName=" + userName,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })

  }
  userExist(request: any) {
    let params = new HttpParams().set('userEmail', request);
    return this.apiServices.get(this.baseUrl + 'userEmailRegistered', { params: params })
  }

  checkPhoneNumber(number) {
    let params = new HttpParams().set('cellNumber', number);
    return this.apiServices.get(this.baseUrl + 'userphonenumberRegistered?cellNumber='+number,{})
  }
  mobileOtp(number){
    return this.apiServices.post(this.baseUrl + 'saveUserWithMobile?moblieNumber='+number,{},{ headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
  }
  otpForPhone(number){
    return this.apiServices.get(this.baseUrl +'resendOTPForPhone?cellNumber='+number,{headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'})
  }
  createPin(pin,otp,number){
    return this.apiServices.get(this.baseUrl+'genratePin?cellNumber='+number+'&otp='+otp+'&pin='+pin,{ headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': this.globalStorage.getItem('forUserId')} })
  }
  verifyMobile(otp,phnum,pin){
    return this.apiServices.get(this.baseUrl + 'phoneVerificationNew?cellNumber='+phnum+'&otp='+otp+'&pin='+pin,{})
  }
  loginWithPin(phnum,pin){
    return this.apiServices.get(this.baseUrl + 'loginWithPin?cellNumber='+phnum+'&otp='+pin,{headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response'})
  }
  userNameExist(request: any) {
    let params = new HttpParams().set('userName', request);
    return this.apiServices.get(this.baseUrl + 'userNameExists', { params: params })
  }
  pharmaUserNameExist(request:any){
    let params = new HttpParams().set('userName', request);
    return this.apiServices.get(this.pharmacyUrl+'isuserNameExists',{ params: params })
  }
  userNameExistForHospital(request: any) {
    let params = new HttpParams().set('userName', request);
    return this.httpClient.get(this.hospitalUrl + 'isUserNameExists', { params: params })
  }
  getUserDataByEmail(email, userName, forUserId) {
    let params = new HttpParams().set('userEmail', email);
    return this.apiServices.get(this.baseUrl + 'getuserbyemail?userEmail=' + email + '&userName=' + userName, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': forUserId.toString() } })
  }
  getUserDetailsByEmail(email, userName) {
    let params = new HttpParams().set('userEmail', email);
    return this.apiServices.get(this.baseUrl + 'getUserDetailsByEmail?userEmail=' + email + '&userName=' + userName, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': this.globalStorage.getItem('forUserId') } })
  }
  getPrimaryDetails(userId, forUserId) {
    //let params = new HttpParams().set('forUserId', forUserId);
    return this.apiServices.post(this.baseUrl + 'getPrimaryDetails/' + userId, {}, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': forUserId.toString() } })
  }
  getNewProfile(body){
    return this.apiServices.post(this.baseUrl + 'getPrimaryDetail', body, { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': this.globalStorage.getItem('forUserId') } })
  }
  getRegisteredUserUserId(email, userName) {
    let params = new HttpParams()
    return this.apiServices.get(this.baseUrl + 'resendOTP?userName=' + userName + '&emailId=' + email,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  getDiaRegisteredUserUserId(email, userName) {
    let params = new HttpParams()
    return this.apiServices.get(this.baseUrl + 'resendOTP?userName=' + userName + '&emailId=' + email,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  verifyOtp(actionName, emailId) {
   // let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.baseUrl + 'verification/phone/' + actionName + "?verificationId=" + emailId, {  })
  }
  verifyPhoneOtp(cellNumber, otp, userName, forUserId) {
    //let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.baseUrl + 'verification/phoneotp?cellNumber=' + cellNumber + "&forUserId=" + forUserId + "&otp=" + otp + "&userName=" + userName, {})
  }
  verifyPhoneOTPForDiagnosticCenter(cellNumber, otp) {
    // let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.diagnosticUrl + 'phoneverification?cellNumber=' + cellNumber + "&otp=" + otp, {  })
  }
  verifyPhoneOTPForPharmacy(cellNumber, otp) {
    // let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.pharmacyUrl + 'verification/phone?otp=' + otp + "&phonenumber=" + cellNumber, {  })
  }
  verifyPhoneOTPForPharmacyUser(cellNumber, otp) {
    // let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.pharmacyUrl + 'phoneverification/for/pharmacyuser?otp=' + otp + "&phonenumber=" + cellNumber, {  })
  }
  verifyEmailOtp(email, otp, userName) {
    // let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.baseUrl + 'verification/emailotp?emailid=' + email + "&emailotp=" + otp + "&userName=" + userName, { })
  }
  verifyEmailOtpNew(email, otp){
    return this.apiServices.get(this.baseUrl + 'emailVerificationByNewUser?emailid=' + email + "&emailotp=" + otp, {  headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': this.globalStorage.getItem('forUserId') }})
  }
  otpVerificationForEmailDcForgot(email,otp){
    return this.apiServices.get(this.diagnosticUrl+'otpverification?emailId='+email+'&otp='+otp,{})
  }
  verifyEmailOtpForDiagnosticCenter(email, otp) {
    // let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.diagnosticUrl + 'emailVerification?emailid=' + email + "&emailotp=" + otp, {  })
  }
  verifyEmailOtpForPharmacy(email, otp) {
    //let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.pharmacyUrl + 'verification/email?emailotp=' + otp + "&emilid=" + email, {  })
  }
  verifyEmailOtpForPharmacyUser(email, otp) {
    //let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.pharmacyUrl + 'email/verification/for/pharmacyuser?emailotp=' + otp + "&emilid=" + email, {  })
  }
  verifyDiagnosticOtp(actionName, emailId) {
    //let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.diagnosticUrl + 'verification/phone/' + actionName + "?verificationId=" + emailId, {  })
  }
  verifyDoctorOtp(actionName, emailId) {
   // let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.doctorUrl + 'verification/phone/' + actionName + "?verificationId=" + emailId, {  })
  }
  getEmailVerification(email, otp) {
   // let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.doctorUrl + 'emailVerification/emailotp?emailid=' + email + "&emailotp=" + otp, { })
  }
  getPhoneVerification(otp, phoneNumber) {
    //let params = new HttpParams().set('uiHostURL',window.location.origin+'/cureMetric' );
    return this.apiServices.get(this.doctorUrl + 'verification/phoneotp?otp=' + otp + "&phonenumber=" + phoneNumber, {})
  }
  verifyPharmacyOtp(actionName, emailId) {
   // let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.pharmacyUrl + 'verification/phone/' + actionName + "?verificationId=" + emailId, {  })
  }
  forgotPassword(request) {
    return this.apiServices.post(this.baseUrl + 'password/forgot',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  resendOtp(request) {
    return this.apiServices.post(this.baseUrl + 'password/otp/resend',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  resendNewPhoneOtp(mobileNumber) {
    let params = new HttpParams()
    return this.apiServices.get(this.baseUrl + 'verification/request/phone/otp?phonenumber=' + mobileNumber,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  } 
  
  resendNewPhoneOtpUserReg(mobileNumber, userName) {
    let params = new HttpParams()
    return this.apiServices.get(this.baseUrl + 'verification/request/phone/otp?phonenumber=' + mobileNumber + '&userName='+userName,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  resendNewPhoneOtpForDiagnosticCenter(mobileNumber) {
    let params = new HttpParams()
    return this.apiServices.get(this.diagnosticUrl + 'requestOTPForPhone?phonenumber=' + mobileNumber,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  
  resendPhoneOtpForDiagnosticCenter(mobileNumber, userName) {
    let params = new HttpParams()
    return this.apiServices.get(this.diagnosticUrl + 'requestOTPForPhone?phonenumber=' + mobileNumber + '&userName='+userName,
      {  headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  resendNewPhoneOtpForPharmacy(mobileNumber, userName) {
    let params = new HttpParams()
    return this.apiServices.get(this.pharmacyUrl + 'requestOtpForPhone?phonenumber=' + mobileNumber + '&userName=' + userName,
      { headers: { 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), 'moduleName': 'PATIENT', 'forUserId': this.globalStorage.getItem('forUserId') } })
  }
  resendPhoneOtpForPharmacy(mobileNumber, userName) {
    let params = new HttpParams()
    return this.apiServices.get(this.pharmacyUrl + 'requestOtpForPhone?phonenumber=' + mobileNumber + '&userName=' + userName,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }

  resendNewPhoneOtpForPharmacyForgot(mobileNumber,userName) {
    let params = new HttpParams()
    return this.apiServices.get(this.pharmacyUrl + 'requestOTPForPhone?phonenumber=' + mobileNumber +'&userName='+userName,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  resendNewEmailOtp(email) {
    let params = new HttpParams()
    return this.apiServices.get(this.baseUrl + 'verification/request/email/otp?emailId=' + email,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
 
  resendNewEmailOtpUserReg(email, userName) {
    let params = new HttpParams()
    return this.apiServices.get(this.baseUrl + 'resendOTP?userName=' + userName + '&emailId=' + email,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  
  resendNewEmailOtpForDiagnosticCenter(email) {
    let params = new HttpParams()
    return this.apiServices.get(this.diagnosticUrl + 'requestOTPForEmail?email=' + email,
    { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })

  }

  resendEmailOtpForDiagnosticCenter(email, userName) {
    let params = new HttpParams()
    return this.apiServices.get(this.diagnosticUrl + 'resendOTPForEmail?email=' + email + '&userName=' + userName,
    { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })

  }

  getOtpUsingUsernameAndEmail(username,email){
    let params = new HttpParams()
    return this.apiServices.get(this.diagnosticUrl + 'requestOTPForEamilForDiagnosticcenterUser?email=' + email+'&userName='+username,
    { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  resendNewEmailOtpForPharmacy(email) {
    let params = new HttpParams()
    return this.apiServices.get(this.pharmacyUrl + 'resendOTPForEamil?email=' + email,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }

  resendEmailOtpForBranch(email, userName) {
    let params = new HttpParams()
    return this.apiServices.get(this.pharmacyUrl + 'resendOTPForEamil?email=' + email + '&userName=' + userName,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }

  resendNewEmailOtpForPharmacyLogin(userName, email) {
    let params = new HttpParams()
    return this.apiServices.get(this.pharmacyUrl + 'resendOTPForEamil?email=' + email + '&userName=' + userName,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  resendNewEmailOtpForPharmacyUser(email) {
    let params = new HttpParams()
    return this.apiServices.get(this.pharmacyUrl + 'requestOTPForEamilForPharmacyUser?email=' + email,
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  otpVerifyPharma(email, otp) {
    return this.apiServices.get(this.pharmacyUrl + 'otp/verification/forEmail?emailId=' + email + '&emailotp=' + otp, {})
  }
  changePassword(request) {
    let params = new HttpParams()
    params = params.set('actionName', request['actionName']),
      params = params.set('emailId', request['emailId'])
    return this.apiServices.get(this.baseUrl + 'verification/request/phone/otp',
      { params: params, headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  resendOTPForChangePassword(userName, email) {
    return this.apiServices.get(this.baseUrl + 'resendOTP?userName=' + userName + '&emailId=' + email, {})
  }
  otpVerify(email, otp, userName) {
    return this.apiServices.get(this.baseUrl + 'otpVerificationForEmail?emailId=' + email + '&emailotp=' + otp + '&userName=' + userName, {})
  }
  doctorEmailVerify(body) {
    return this.apiServices.post(this.doctorUrl + 'otpVerificationForEmail', body, {});
  }
  getLoginUser(value: any) {
    this.loginUserSubject.next(value)
  }
  navigationDetails() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    var request = {
      "forUserId": obj['userId'],
      "userId": obj['userId']
    }
    this.getDetails.getPersonalDetails(request, (obj['userId'])).subscribe(res => {
      var userdata = {
        'personalData': res['body']['data'],
        'lastLogin': JSON.parse(this.globalStorage.getItem('lastLogin'))
      }
      this.getLoginUser(userdata)
    }, error => {
      this.ToastrService.error("Error in getting Personal details");
    })
  }
}
