import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, Output,EventEmitter, ViewEncapsulation } from '@angular/core';
import { DoctorService } from '../../AppServices/doctor.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-baseseheduler',
  templateUrl: './baseseheduler.component.html',
  styleUrls: ['./baseseheduler.component.css']
})
export class BasesehedulerComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<string>();
  associationData; associateEntityId; associateEntityType;selectedOption;
  options: string[] = ['Inperson','Video', 'All'];
  @Output() closeBaseSchedule = new EventEmitter<any>();
  constructor(private globalStorage: GlobalStorageService, private doctorService: DoctorService, private toastrService: ToastrService, private router: Router,) {
    const navigation = this.router.getCurrentNavigation();
    if (this.router.getCurrentNavigation() != null || this.router.getCurrentNavigation() != undefined) {
      this.associationData = this.router.getCurrentNavigation()?.extras.state?.associationData
    }

  }
  g: string[] = ["01:00",  "02:00",  "03:00",  "04:00",  "05:00",  "06:00", "07:00", "08:00",  "09:00",  "10:00",  "11:00",  "12:00",  "13:00",  "14:00",
    "15:00", "16:00",  "17:00",  "18:00",  "19:00",  "20:00",  "21:00",  "22:00",  "23:00"]
  h: string[] = ["00:00", "00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00",
    "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00", "19:30", "20:00", "20:30", "21:00", "21:30", "22:00", "22:30", "23:00", "23:30"]
  // h: string[] = ["08:00","08:30","09:00","09:30","10:00","10:30","11:00","11:30","12:00","12:30","13:00","13:30","14:00",
  //   "14:30","15:00","15:30","16:00","16:30","17:00","17:30","18:00","18:30","19:00","19:30","20:00","20:30","21:00","21:30","22:00","22:30","23:00","23:30"]
  type: string[] = ["Self", "Pharmacy"]
  days: any[] = [{ 'day': "Sunday", 'H': this.h, 'T': this.type }, { 'day': "Monday", 'H': this.h, 'T': this.type },
  { 'day': "Tuesday", 'H': this.h, 'T': this.type }, { 'day': "Wednesday", 'H': this.h, 'T': this.type }, { 'day': "Thursday", 'H': this.h, 'T': this.type },
  { 'day': "Friday", 'H': this.h, 'T': this.type },
  { 'day': "Saturday", 'H': this.h, 'T': this.type }]
  doctor:any;doctorId;isDoctorDs=false;isAll=true;isIvy=false
  previousSlots; pharmacyBaseSchedule; onlineBaseSchedule; associateEntityName;
  ngOnInit(): void {
    this.selectedOption='Inperson'
    this.checkBoxCheck({value:'Inperson'},'')
    this.checkProfile()
    this.doctor = JSON.parse(this.globalStorage.getItem('doctorId'));
    this.doctorId=this.doctor
    if(this.doctorId==undefined || this.doctorId==null){
      this.doctorId=this.doctorService?.sharingData?.referenceentityId
      console.log('doctor Id',this.doctorId)
    }
    // this.loadBasesheduler();
    if(this.router.url=='/ivf-admin'){
      this.isDoctorDs=false
      this.isIvy=true
    }
    if (this.router.url == '/BasicScheduler' || window.location.pathname=='/cureMetric/BasicScheduler') {
      this.isDoctorDs=true
      this.associateEntityId = 1
      this.associateEntityType = 'Self'
      this.associateEntityName = 'Self'
      this.loadBasesheduler();
      return
      this.doctorService.getDoctorBaseSheduleView(this.doctorId).subscribe(res => {
        if (res['data'].length > 0) {
          this.isNew = false
          this.basesheduler = res['data']
          var onlineFiler = this.basesheduler.filter(obj => obj.associateEntityType == 'Self')
          this.basseScheduleId = onlineFiler[0].basseScheduleId
        }
      })

    }
    else if (this.router.url == '/associationBasicScheduler' || window.location.pathname=='/cureMetric/associationBasicScheduler') {
      var onlineFiler; var isPresent
      this.associateEntityId = this.associationData.entityId
      this.associateEntityType = this.associationData.entitytype
      this.associateEntityName = this.associationData.entiyName
      this.doctorService.getDoctorBaseSheduleView(this.doctorId).subscribe(res => {
        if (res['data'].length > 0) {
          this.basesheduler = res['data']
          this.createdUpto=this.basesheduler[0]['createdUpto'];
          for (var i = 0; i < res['data'].length; i++) {
            if (this.associationData.entitytype != this.basesheduler[i]?.associateEntityType) {
              isPresent = false
            }
            else {
              isPresent = true
            }
            if (onlineFiler = this.basesheduler.filter(obj => obj.associateEntityType == this.associationData.entitytype)) {
              this.basseScheduleId = onlineFiler[0]?.basseScheduleId
            }
          }
          if (isPresent == false) {
            this.isNew = true
            // this.isEdit=true
            if (this.isNew == true) {
              $(".slot-selection").removeClass("slot-selection");
            }
            //  this.isEdit=true;
            if (this.isEdit == true) {
              $(".slot-selection").removeClass("slot-selection");
            }
          } else {
            this.isEdit = false
            this.isNew = false
          }
          // this.basseScheduleId=onlineFiler[0]?.basseScheduleId
        }
        else {
          this.isNew = true
          if (this.isNew == true) {
            $(".slot-selection").removeClass("slot-selection");
          }
          this.isEdit = true;
          if (this.isEdit == true) {
            $(".slot-selection").removeClass("slot-selection");
          }
        }
      })
    }
    else if(this.router.url=='/ivf-admin'){
      var onlineFiler; var isPresent
      this.associationData=this.doctorService?.sharingData
      this.associateEntityId = this.associationData?.ivfaddressId
      this.associateEntityType = "IVF"
      this.associateEntityName = this.associationData.hospitalName
      this.loadBasesheduler();
      return
      this.doctorService.getDoctorBaseSheduleView(this.doctorId).subscribe(res => {
        if (res['data']?.length > 0) {
          this.basesheduler = res['data']
          for (var i = 0; i < res['data'].length; i++) {
            if (this.associateEntityType != this.basesheduler[i]?.associateEntityType) {
              isPresent = false
            }
            else {
              isPresent = true
            }
            if (onlineFiler = this.basesheduler.filter(obj => obj.associateEntityType == this.associateEntityType)) {
              this.basseScheduleId = onlineFiler[0]?.basseScheduleId
            }
          }

          if (isPresent == false) {
            this.isNew = true
            // this.isEdit=true
            if (this.isNew == true) {
              $(".slot-selection").removeClass("slot-selection");
            }
            //  this.isEdit=true;
            if (this.isEdit == true) {
              $(".slot-selection").removeClass("slot-selection");
            }
          } else {
            this.isEdit = false
            this.isNew = false
          }
          // this.basseScheduleId=onlineFiler[0]?.basseScheduleId
        }
        else {
          this.isNew = true
          if (this.isNew == true) {
            $(".slot-selection").removeClass("slot-selection");
          }
          this.isEdit = true;
          if (this.isEdit == true) {
            $(".slot-selection").removeClass("slot-selection");
          }
        }
      })
    }
    else if(this.router.url=='/clinic-admin'){
      this.doctorId=this.doctorService?.sharingData?.referenceentityId
      var basesheduler
      this.doctorService.getDoctorBaseSheduleView(this.doctorId).subscribe(res => {

        this.associateEntityId = this.globalStorage.getItem('hospitalAddressId')
        this.associateEntityType = "HOSPITAL"
        this.associateEntityName = this.globalStorage.getItem('hospitalName')

        if (res['data']?.length > 0) {
          this.isNew = false
          this.basesheduler = res['data']
          this.createdUpto=this.basesheduler[0]['createdUpto'];

          for (var i = 0; i < res['data']?.length; i++) {
            basesheduler = this.basesheduler[i];
            this.basseSchedule(basesheduler)
          
          }
        }
  
        else {
          this.isNew = true
          if (this.isNew == true) {
            $(".slot-selection").removeClass("slot-selection");
          }
          this.isEdit = true;
          if (this.isEdit == true) {
            $(".slot-selection").removeClass("slot-selection");
          }
        }
      }, error => {
        this.toastrService.error("Error retrieving base schedule.")
      }
      )
      // var basesheduler={
      //   "doctorId":  this.doctor['data'].doctorId,
      //   "friday": "10:00-12:00, 14:30-15:30",
      //   "minSlot": 30,
      //   "monday": "10:00-12:00, 14:30-15:30",
      //   "saturday": "10:00-12:00, 14:30-15:30",
      //   "sunday":  "",
      //   "thursday": "10:00-12:00, 14:30-15:30",
      //   "tuesday": "10:00-12:00, 14:30-15:30",
      //   "wednesday": "10:00-12:00, 14:30-15:30"
      // }
  
    }
    else{
      var onlineFiler; var isPresent
      this.associationData=this.doctorService?.sharingData
      this.associateEntityId = this.associationData?.hospitalAddressId
      this.associateEntityType = "Hospital"
      this.associateEntityName = this.associationData.hospitalName
      this.doctorService.getDoctorBaseSheduleView(this.doctorId).subscribe(res => {
        if (res['data']?.length > 0) {
          this.basesheduler = res['data']
          this.createdUpto=this.basesheduler[0]['createdUpto'];
          for (var i = 0; i < res['data'].length; i++) {
            if (this.associateEntityType != this.basesheduler[i]?.associateEntityType) {
              isPresent = false
            }
            else {
              isPresent = true
            }
            if (onlineFiler = this.basesheduler.filter(obj => obj.associateEntityType == this.associateEntityType)) {
              this.basseScheduleId = onlineFiler[0]?.basseScheduleId
            }
          }

          if (isPresent == false) {
            this.isNew = true
            // this.isEdit=true
            if (this.isNew == true) {
              $(".slot-selection").removeClass("slot-selection");
            }
            //  this.isEdit=true;
            if (this.isEdit == true) {
              $(".slot-selection").removeClass("slot-selection");
            }
          } else {
            this.isEdit = false
            this.isNew = false
          }
          // this.basseScheduleId=onlineFiler[0]?.basseScheduleId
        }
        else {
          this.isNew = true
          if (this.isNew == true) {
            $(".slot-selection").removeClass("slot-selection");
          }
          this.isEdit = true;
          if (this.isEdit == true) {
            $(".slot-selection").removeClass("slot-selection");
          }
        }
      })
    }
  }


  basesheduler=[]
  basseScheduleId
  onlineBasescheduleId
  ScheduleType
  isNew: boolean = false
  //   randomColor() {
  //     return 'rgb('+
  //         Math.round(Math.random()*255)+', '+
  //         Math.round(Math.random()*255)+', '+
  //         Math.round(Math.random()*255)+')'
  // }
  checkProfile(){
    if(this.globalStorage.getItem('doctorId')==undefined || this.globalStorage.getItem('doctorId')==null){
      this.globalStorage.setItem('doctorId',this.doctorService?.sharingData?.referenceentityId)
    }
    if(this.router.url == '/BasicScheduler' || this.router.url=='/associationBasicScheduler'){
      if (this.globalStorage.getItem('isPersonalDetailCompleted') == 'N' || this.globalStorage.getItem('isPersonalDetailCompleted') != 'Y') {
        this.router.navigate(['/personalDetails']);
      }
      else {
        if (this.globalStorage.getItem('isAddressCompleted') != 'Y') {
          this.router.navigate(['/addressDetails'])
        }
        else {
          if (this.globalStorage.getItem('isFeeDetailCompleted') != 'Y') {
            this.router.navigate(['/EditProfile'])
          }
          else {
            //this.router.navigate(['dashboard']);
          }
        }
      }
      // this.doctorService.doctorProfileCompletedOrNot(this.globalStorage.getItem('doctorId')).subscribe(res=>{
      //   if(res['data']['isFeeDetailComplted']=='N'){
      //     this.router.navigate(['/EditProfile'])
      //     return
      //   }
      //   else{
      //     if(res['data']['isPersonalDetailComplted']=='N'){
      //       this.router.navigate(['/personalDetails'])
      //       return
      //     }
      //     if(res['data']['isAddressCompleted']=='N'){
      //       this.router.navigate(['/addressDetails'])
      //       return
      //     }
      //   }
      // })
    }
  
  }
  basseSchedule(basesheduler) {
    // if(basesheduler.associateEntityType=='Self'){
    //   this.basseScheduleId=basesheduler.basseScheduleId
    // }
    // if (basesheduler?.associateEntityType == 'Pharmacy') {
    //   this.pharmacyBaseSchedule = basesheduler
    // }
    // if (basesheduler?.associateEntityType == 'Self') {
    //   this.onlineBaseSchedule = basesheduler
    // }
    // if (basesheduler?.associateEntityType == 'Self') {
    //   this.onlineBasescheduleId = basesheduler?.basseScheduleId
    // }
    var typeOfAppointment=this.selectedOption
    var slotType = basesheduler?.associateEntityType
    this.ScheduleType = basesheduler?.associateEntityType
    var entityName = basesheduler?.associateEntityName
    var entityType= basesheduler?.appointmentType
    var hsSlots
    var hsUrl
    if (this.router.url == '/clinic-admin') {
       hsSlots='hsSlots'
       hsUrl='true'
    }
    else{
      hsSlots='nhsSlots'
      hsUrl='false'
    }
    // var result=
    const randomColor = 'rgb(0,255,0)';
    if (basesheduler?.sunday != "") {
      var tr = $("tr:contains('Sunday')");
      var slotsArray = basesheduler?.sunday?.trim()?.split(",")

      for (var i = 0; i < slotsArray?.length; i++) {
        var slots = slotsArray[i]?.split("-")
        var slotSt = "", slotEnd = ""

        $('#our_table td.highlighted.' + slotType).each(function () {
          const randomColor = 'rgb(0,255,0)';
          $(this).css("background-color", "#" + randomColor);
        })

        for (var j = 1; j < tr[0]["cells"]?.length; j++) {
          $(".slot-selection").addClass("slot-selection");
          if ((tr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == slots[0]?.trim()) {
            slotSt = tr[0]["cells"][j]?.textContent
          }
          if ((tr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == slots[1]?.trim()) {
            slotEnd = tr[0]["cells"][j]?.textContent
          }
          if (slotSt != "" && slotEnd == "") {
            tr[0]["cells"][j].className = "highlighted" + ' ' + slotType + ' '+entityType;
             if($('.highlighted' + ' '+ entityType)){
              $('.'+entityType).each(function(){
                $(this).find('p').text(entityType)
              })
             }
               
              
            $('.' + slotType).each(function () {
              $(this).find('span').text(entityName)
              $(this).find('span').addClass(hsSlots)
              if(entityName!='Self'){
                $(this).addClass('hospital-slot')
              }
              if(hsUrl=='true'){
                $(this).removeClass('hospital-slot')
              }
              $(this).css("color", "white");
              $(this).css("font-size", "11px");
            //  $(this).css("background-color", "#" + '0014ff');
            });

            tr[0]["cells"][j].accessKey = slotType
          }

          if (slotSt != "" && slotEnd != "") {
            if (slotSt == "23:30" && slotEnd == "23:30") {
              tr[0]["cells"][j].className = "highlighted" + ' ' + slotType  + ' '+entityType;
            }

            if (slotSt == "00:00" && slotEnd == "23:30") {
              tr[0]["cells"][j].className = "highlighted" + ' ' + slotType  + ' '+entityType;
            }

            if (slotSt != slotEnd) {
               tr[0]["cells"][j].previousSibling.className = "highlighted" + ' ' + slotType  + ' '+entityType;

            }
            slotSt = "", slotEnd = ""
          }
        }
      }
    }

    if (basesheduler?.monday != "") {
      var Montr = $("tr:contains('Monday')");
      var slotsArray = basesheduler?.monday?.trim()?.split(",")
      for (var i = 0; i < slotsArray?.length; i++) {
        var slots = slotsArray[i]?.split("-")
        var slotSt = "", slotEnd = ""
        for (var j = 1; j < Montr[0]["cells"]?.length; j++) {
          if ((Montr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == slots[0]?.trim()) {
            slotSt = Montr[0]["cells"][j].textContent;
          }
          if ((Montr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == slots[1]?.trim()) {
            slotEnd = Montr[0]["cells"][j].textContent;
            // var a = moment([slotSt], "HH:mm:ss")
            // var b = moment([slotEnd], "HH:mm:ss")
            // var dif=  b.diff(a, 'minutes')
            //  if(dif==30){
            //   slotEnd=slotSt;
            //  }
          }
          if (slotSt != "" && slotEnd == "") {
            Montr[0]["cells"][j].className = "highlighted" + ' ' + slotType  + ' '+entityType;
            $('.'+entityType).each(function(){
              $(this).find('p').text(entityType)
            })
            $('.' + slotType).each(function () {
              $(this).find('span').text(entityName)
              $(this).find('span').addClass(hsSlots)
              if(entityName!='Self'){
                $(this).addClass('hospital-slot')
              }
              $(this).css("color", "white");
              $(this).css("font-size", "11px");
              $(this).css("background-color", "#" + randomColor);
            });

            Montr[0]["cells"][j].accessKey = slotType;
          }
          if (slotSt != "" && slotEnd != "") {
            Montr[0]["cells"][j].previousSibling.className = "highlighted" + ' ' + slotType  + ' '+entityType;
            if (slotEnd == "23:30") {
              // Montr[0]["cells"][j].className = "highlighted" + ' ' + slotType  + ' '+entityType;
            }
            slotSt = "", slotEnd = ""
          }

        }
      }
    }


    if (basesheduler?.tuesday != "") {
      var Tuesday_tr = $("tr:contains('Tuesday')");
      var Tuesday_slotsArray = basesheduler?.tuesday?.trim()?.split(",")
      for (var i = 0; i < Tuesday_slotsArray?.length; i++) {
        var Tuesday_slots = Tuesday_slotsArray[i]?.split("-")
        var Tuesday_slotSt = "", Tuesday_slotEnd = ""
        for (var j = 1; j < Tuesday_tr[0]["cells"]?.length; j++) {
          if ((Tuesday_tr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == Tuesday_slots[0]?.trim()) {
            Tuesday_slotSt = Tuesday_tr[0]["cells"][j].textContent
          }
          if ((Tuesday_tr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == Tuesday_slots[1]?.trim()) {
            Tuesday_slotEnd = Tuesday_tr[0]["cells"][j].textContent
          }
          if (Tuesday_slotSt != "" && Tuesday_slotEnd == "") {
            Tuesday_tr[0]["cells"][j].className = "highlighted" + ' ' + slotType  + ' '+entityType;
            $('.'+entityType).each(function(){
              $(this).find('p').text(entityType)
            })
            $('.' + slotType).each(function () {
              $(this).find('span').text(entityName)
              $(this).find('span').addClass(hsSlots)
              if(entityName!='Self'){
                $(this).addClass('hospital-slot')
              }
              $(this).css("color", "white");
              $(this).css("font-size", "11px");
              $(this).css("background-color", "#" + randomColor);
            });

            Tuesday_tr[0]["cells"][j].accessKey = slotType;
          }
          if (Tuesday_slotSt != "" && Tuesday_slotEnd != "") {
            Tuesday_tr[0]["cells"][j].previousSibling.className = "highlighted" + ' ' + slotType  + ' '+entityType;
            if (Tuesday_slotEnd == "23:30") {
              // Tuesday_tr[0]["cells"][j].className = "highlighted" + ' ' + slotType + ' '+entityType;
            }
            Tuesday_slotSt = "", Tuesday_slotEnd = ""
          }

        }
      }
    }
    if (basesheduler?.wednesday != "") {
      var Wednesday_tr = $("tr:contains('Wednesday')");
      var Wednesday_slotsArray = basesheduler?.wednesday?.trim()?.split(",")
      for (var i = 0; i < Wednesday_slotsArray?.length; i++) {
        var Wednesday_slots = Wednesday_slotsArray[i]?.split("-")
        var Wednesday_slotSt = "", Wednesday_slotEnd = ""
        for (var j = 1; j < Wednesday_tr[0]["cells"]?.length; j++) {
          if ((Wednesday_tr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == Wednesday_slots[0]?.trim()) {
            Wednesday_slotSt = Wednesday_tr[0]["cells"][j].textContent
          }
          if ((Wednesday_tr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == Wednesday_slots[1]?.trim()) {
            Wednesday_slotEnd = Wednesday_tr[0]["cells"][j].textContent
          }
          if (Wednesday_slotSt != "" && Wednesday_slotEnd == "") {
            Wednesday_tr[0]["cells"][j].className = "highlighted" + ' ' + slotType + ' '+entityType;
            $('.'+entityType).each(function(){
              $(this).find('p').text(entityType)
            })
            $('.' + slotType).each(function () {
              $(this).find('span').text(entityName)
              $(this).find('span').addClass(hsSlots)
              if(entityName!='Self'){
                $(this).addClass('hospital-slot')
              }
              $(this).css("color", "white");
              $(this).css("font-size", "11px");
              $(this).css("background-color", "#" + randomColor);
            });

            Wednesday_tr[0]["cells"][j].accessKey = slotType;
          }
          if (Wednesday_slotSt != "" && Wednesday_slotEnd != "") {
            Wednesday_tr[0]["cells"][j].previousSibling.className = "highlighted" + ' ' + slotType  + ' '+entityType;
            if (Wednesday_slotEnd == "23:30") {
              // Wednesday_tr[0]["cells"][j].className = "highlighted" + ' ' + slotType + ' '+entityType;
            }
            Wednesday_slotSt = "", Wednesday_slotEnd = ""
          }

        }
      }
    }
    if (basesheduler?.thursday != "") {
      var Thursday_tr = $("tr:contains('Thursday')");
      var Thursday_slotsArray = basesheduler?.thursday?.trim()?.split(",")
      for (var i = 0; i < Thursday_slotsArray?.length; i++) {
        var Thursday_slots = Thursday_slotsArray[i]?.split("-")
        var Thursday_slotSt = "", Thursday_slotEnd = ""
        for (var j = 1; j < Thursday_tr[0]["cells"]?.length; j++) {
          if ((Thursday_tr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == Thursday_slots[0]?.trim()) {
            Thursday_slotSt = Thursday_tr[0]["cells"][j].textContent
          }
          if ((Thursday_tr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == Thursday_slots[1]?.trim()) {
            Thursday_slotEnd = Thursday_tr[0]["cells"][j].textContent
          }
          if (Thursday_slotSt != "" && Thursday_slotEnd == "") {
            Thursday_tr[0]["cells"][j].className = "highlighted" + ' ' + slotType + ' '+entityType;
            $('.'+entityType).each(function(){
              $(this).find('p').text(entityType)
            })
            $('.' + slotType).each(function () {
              $(this).find('span').text(entityName)
              $(this).find('span').addClass(hsSlots)
              if(entityName!='Self'){
                $(this).addClass('hospital-slot')
              }
              $(this).css("color", "white");
              $(this).css("font-size", "11px");
              $(this).css("background-color", "#" + randomColor);
            });

            Thursday_tr[0]["cells"][j].accessKey = slotType;
          }
          if (Thursday_slotSt != "" && Thursday_slotEnd != "") {
            Thursday_tr[0]["cells"][j].previousSibling.className = "highlighted" + ' ' + slotType + ' '+entityType;
            if (Thursday_slotEnd == "23:30") {
              // Thursday_tr[0]["cells"][j].className = "highlighted" + ' ' + slotType + ' '+entityType;
            }
            Thursday_slotSt = "", Thursday_slotEnd = ""
          }

        }
      }
    }

    if (basesheduler?.friday != "") {
      var Friday_tr = $("tr:contains('Friday')");
      var Friday_slotsArray = basesheduler?.friday?.trim()?.split(",")
      for (var i = 0; i < Friday_slotsArray?.length; i++) {
        var Friday_slots = Friday_slotsArray[i]?.split("-")
        var Friday_slotSt = "", Friday_slotEnd = ""
        for (var j = 1; j < Friday_tr[0]["cells"]?.length; j++) {
          if ((Friday_tr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == Friday_slots[0]?.trim()) {
            Friday_slotSt = Friday_tr[0]["cells"][j].textContent
          }
          if ((Friday_tr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == Friday_slots[1]?.trim()) {
            Friday_slotEnd = Friday_tr[0]["cells"][j].textContent
          }
          if (Friday_slotSt != "" && Friday_slotEnd == "") {
            Friday_tr[0]["cells"][j].className = "highlighted" + ' ' + slotType + ' '+entityType;
            $('.'+entityType).each(function(){
              $(this).find('p').text(entityType)
            })
            $('.' + slotType).each(function () {
              $(this).find('span').text(entityName)
              $(this).find('span').addClass(hsSlots)
              if(entityName!='Self'){
                $(this).addClass('hospital-slot')
              }
              $(this).css("color", "white");
              $(this).css("font-size", "11px");
              $(this).css("background-color", "#" + randomColor);
            });

            Friday_tr[0]["cells"][j].accessKey = slotType;
          }
          if (Friday_slotSt != "" && Friday_slotEnd != "") {
            Friday_tr[0]["cells"][j].previousSibling.className = "highlighted" + ' ' + slotType + ' '+entityType;
            if (Friday_slotEnd == "23:30") {
              // Friday_tr[0]["cells"][j].className = "highlighted" + ' ' + slotType + ' '+entityType;
            }
            Friday_slotSt = "", Friday_slotEnd = ""
          }

        }
      }
    }

    if (basesheduler?.saturday != "") {
      var Saturday_tr = $("tr:contains('Saturday')");
      var Saturday_slotsArray = basesheduler?.saturday?.trim()?.split(",")
      for (var i = 0; i < Saturday_slotsArray?.length; i++) {
        var Saturday_slots = Saturday_slotsArray[i]?.split("-")
        var Saturday_slotSt = "", Saturday_slotEnd = ""
        for (var j = 1; j < Saturday_tr[0]["cells"]?.length; j++) {
          if ((Saturday_tr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == Saturday_slots[0]?.trim()) {
            Saturday_slotSt = Saturday_tr[0]["cells"][j].textContent
          }
          if ((Saturday_tr[0]["cells"][j].textContent).replace(/[^\d:]/g, '') == Saturday_slots[1]?.trim()) {
            Saturday_slotEnd = Saturday_tr[0]["cells"][j].textContent
          }
          if (Saturday_slotSt != "" && Saturday_slotEnd == "") {
            Saturday_tr[0]["cells"][j].className = "highlighted" + ' ' + slotType  + ' '+entityType;
            $('.'+entityType).each(function(){
              $(this).find('p').text(entityType)
            })
            $('.' + slotType).each(function () {
              $(this).find('span').text(entityName)
              $(this).find('span').addClass(hsSlots)
              if(entityName!='Self'){
                $(this).addClass('hospital-slot')
              }
              $(this).css("color", "white");
              $(this).css("font-size", "11px");
              $(this).css("background-color", "#" + randomColor);
            });

            Saturday_tr[0]["cells"][j].accessKey = slotType;
          }
          if (Saturday_slotSt != "" && Saturday_slotEnd != "") {
            Saturday_tr[0]["cells"][j].previousSibling.className = "highlighted" + ' ' + slotType + ' '+entityType;
            if (Saturday_slotEnd == "23:30") {
              // Saturday_tr[0]["cells"][j].className = "highlighted" + ' ' + slotType + ' '+entityType;
            }
            Saturday_slotSt = "", Saturday_slotEnd = ""
          }

        }
      }
    }
    this.selectedOption='Inperson'
    this.checkBoxCheck({value:'Inperson'},'')
  }
  loadBasesheduler() {
    var basesheduler

    this.doctorService.getDoctorBaseSheduleView(this.doctorId).subscribe(res => {
      if (res['data']?.length > 0) {
        this.isNew = false
        this.basesheduler = res['data']
        this.createdUpto=this.basesheduler[0]['createdUpto'];
        for (var i = 0; i < res['data']?.length; i++) {
          basesheduler = this.basesheduler[i];
          this.basseSchedule(basesheduler)
        
        }
        this.selectedOption='Inperson'
        this.checkBoxCheck({value:'Inperson'},'')
      }

      else {
        this.isNew = true
        if (this.isNew == true) {
          $(".slot-selection").removeClass("slot-selection");
        }
        this.isEdit = true;
        if (this.isEdit == true) {
          $(".slot-selection").removeClass("slot-selection");
        }
      }
    }, error => {
      this.toastrService.error("Error retrieving base schedule.")
    }
    )
  }

  selectArray: any[] = [];
  Sunday: string = ""; Monday: string = ""; Tuesday: string = ""; Wednesday: string = ""; Thursday: string = ""; Friday: string = ""; Saturday: string = "";
  NewBaseScheduler() {

    this.selectArray = this.selectedSlots(this.associateEntityType, this.selectedOption);
    // console.log(this.selectArray)
    // return
    this.resetStrings()

    for (var i = 0; i < this.selectArray.length; i++) {
      if (this.selectArray[i].day == 'Sunday') {
        this.Sunday = this.Sunday + this.selectArray[i].startSlot + '-' + this.selectArray[i].endSlot + ','
      }
      else if (this.selectArray[i].day == 'Monday') {
        this.Monday = this.Monday + this.selectArray[i].startSlot + '-' + this.selectArray[i].endSlot + ','
      }
      else if (this.selectArray[i].day == 'Tuesday') {
        this.Tuesday = this.Tuesday + this.selectArray[i].startSlot + '-' + this.selectArray[i].endSlot + ','
      }
      else if (this.selectArray[i].day == 'Wednesday') {
        this.Wednesday = this.Wednesday + this.selectArray[i].startSlot + '-' + this.selectArray[i].endSlot + ','
      }
      else if (this.selectArray[i].day == 'Thursday') {
        this.Thursday = this.Thursday + this.selectArray[i].startSlot + '-' + this.selectArray[i].endSlot + ','
      }
      else if (this.selectArray[i].day == 'Friday') {
        this.Friday = this.Friday + this.selectArray[i].startSlot + '-' + this.selectArray[i].endSlot + ','
      }
      else if (this.selectArray[i].day == 'Saturday') {
        this.Saturday = this.Saturday + this.selectArray[i].startSlot + '-' + this.selectArray[i].endSlot + ','
      }
    }

    var baseScheduler = {
      "active": "Y",
      "associateEntityId": this.associateEntityId,
      "appointmentType":this.selectedOption,
      "associateEntityType": this.associateEntityType,
      "associateEntityName": this.associateEntityName,
      "basseScheduleId": null,
      "doctorId": this.doctorId,
      "numberofmonths": 1,
      "createdUpto":this.createdUpto,
      "minSlot": 30,
      "sunday": this.Sunday.slice(0, -1).replace(/[A-Za-z]/g, ""),
      "monday": this.Monday.slice(0, -1).replace(/[A-Za-z]/g, ""),
      "tuesday": this.Tuesday.slice(0, -1).replace(/[A-Za-z]/g, ""),
      "wednesday": this.Wednesday.slice(0, -1).replace(/[A-Za-z]/g, ""),
      "thursday": this.Thursday.slice(0, -1).replace(/[A-Za-z]/g, ""),
      "friday": this.Friday.slice(0, -1).replace(/[A-Za-z]/g, ""),
      "saturday": this.Saturday.slice(0, -1).replace(/[A-Za-z]/g, ""),
    }

    this.doctorService.createBaseSchedule(baseScheduler).subscribe(res => {
      this.toastrService.success("Base schedule saved.")
      let currentUrl = this.router.url;
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      // this.router.onSameUrlNavigation = 'reload';
      // this.router.navigate([currentUrl]);
      this.loadBasesheduler()
      this.closeBaseSchedule.emit();
      this.isNew = !this.isNew
      this.isEdit = !this.isEdit
      this.messageEvent.emit()
      $("body").removeClass("modal-open")
      $('#baseSchedule').modal('hide')
      $("#baseSchedule").val(null).trigger("change");
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    })
  }


  createdUpto:any
  isAppointmentExist: boolean
  updateBaseScheduler() {
    this.isAppointmentExist = true
    //this.checkAppointmentExist();
    if (this.isAppointmentExist) {


      this.selectArray = this.selectedSlots(this.associateEntityType, this.selectedOption);
      console.log(this.selectArray)
      // return
       this.resetStrings()
      for (var i = 0; i < this.selectArray.length; i++) {
        if (this.selectArray[i].day == 'Sunday') {
          console.log('base error',this.selectArray[i].startSlot.substr(this.selectArray[i].startSlot.length - 5))
          this.Sunday = this.Sunday +this.selectArray[i].startSlot.substr(this.selectArray[i].startSlot.length - 5) + '-' + this.selectArray[i].endSlot.substr(this.selectArray[i].endSlot.length - 5) + ','
        }
        if (this.selectArray[i].day == 'Monday') {
          this.Monday = this.Monday + this.selectArray[i].startSlot.substr(this.selectArray[i].startSlot.length - 5) + '-' + this.selectArray[i].endSlot.substr(this.selectArray[i].endSlot.length - 5) + ','
        }
        if (this.selectArray[i].day == 'Tuesday') {
          this.Tuesday = this.Tuesday + this.selectArray[i].startSlot.substr(this.selectArray[i].startSlot.length - 5) + '-' + this.selectArray[i].endSlot.substr(this.selectArray[i].endSlot.length - 5) + ','
        }
        if (this.selectArray[i].day == 'Wednesday') {
          this.Wednesday = this.Wednesday + this.selectArray[i].startSlot.substr(this.selectArray[i].startSlot.length - 5) + '-' + this.selectArray[i].endSlot.substr(this.selectArray[i].endSlot.length - 5) + ','
        }
        if (this.selectArray[i].day == 'Thursday') {
          this.Thursday = this.Thursday + this.selectArray[i].startSlot.substr(this.selectArray[i].startSlot.length - 5) + '-' + this.selectArray[i].endSlot.substr(this.selectArray[i].endSlot.length - 5) + ','
        }
        if (this.selectArray[i].day == 'Friday') {
          this.Friday = this.Friday + this.selectArray[i].startSlot.substr(this.selectArray[i].startSlot.length - 5) + '-' + this.selectArray[i].endSlot.substr(this.selectArray[i].endSlot.length - 5) + ','
        }
        if (this.selectArray[i].day == 'Saturday') {
          this.Saturday = this.Saturday + this.selectArray[i].startSlot.substr(this.selectArray[i].startSlot.length - 5) + '-' + this.selectArray[i].endSlot.substr(this.selectArray[i].endSlot.length - 5) + ','
        }
      }
      // if(this.basseScheduleId==undefined){
      //   this.NewBaseScheduler()
      // }
      var baseScheduler = {
        "active": "Y",
        "associateEntityId": this.associateEntityId,
        "associateEntityType": this.associateEntityType,
        "appointmentType":this.selectedOption,
        "associateEntityName": this.associateEntityName,
        "basseScheduleId": this.basseScheduleId,
        "doctorId": this.doctorId,
        "numberofmonths": 1,
        "createdUpto":this.createdUpto,
        "minSlot": 30,
        "sunday": this.Sunday.slice(0, -1).replace(/[A-Za-z]/g, ""),
        "monday": this.Monday.slice(0, -1).replace(/[A-Za-z]/g, ""),
        "tuesday": this.Tuesday.slice(0, -1).replace(/[A-Za-z]/g, ""),
        "wednesday": this.Wednesday.slice(0, -1).replace(/[A-Za-z]/g, ""),
        "thursday": this.Thursday.slice(0, -1).replace(/[A-Za-z]/g, ""),
        "friday": this.Friday.slice(0, -1).replace(/[A-Za-z]/g, ""),
        "saturday": this.Saturday.slice(0, -1).replace(/[A-Za-z]/g, ""),
      }
      console.log('saving obj',baseScheduler)
      if(baseScheduler?.basseScheduleId!=null || baseScheduler?.basseScheduleId!=undefined){
        this.doctorService.updateBaseSchedule(baseScheduler).subscribe(res => {
          this.toastrService.success("Base schedule saved.")
          this.closeBaseSchedule.emit();
          this.loadBasesheduler()
          let currentUrl = this.router.url;
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      // this.router.onSameUrlNavigation = 'reload';
      // this.router.navigate([currentUrl]);
        this.messageEvent.emit()
          this.isEdit = !this.isEdit
          $("body").removeClass("modal-open")
          $('#baseSchedule').modal('hide')
          $("#baseSchedule").val(null).trigger("change");
        }, error => {
          this.toastrService.error(error?.['error']?.['message'])
        })
      }
      $(".enable-slot").addClass("slot-selection");
    }
  }

  checkAppointmentExist() {
    this.doctorService.myAppoinments(this.globalStorage.getItem('doctorId')).subscribe(res => {
      var current = res['data']['CURRENT']['data']
      var upcoming = res['data']['UPCOMING']['data']
      if (current.length > 0 || upcoming > 0) {
        this.toastrService.warning("This schedule already exixt.")
        this.isAppointmentExist = false
      }

    }, error => {
      this.toastrService.error("Error retrieving schedule.")
    })
  }

  resetStrings() {
    this.Sunday = "";
    this.Monday = "";
    this.Tuesday = "";
    this.Wednesday = "";
    this.Thursday = "";
    this.Friday = "";
    this.Saturday = "";
  }
createSelectedSlots(slotType, baseScheduleId): any[] {
  var slotArray = []
  slotArray.length = 0;
  if (this.router.url == '/BasicScheduler') {
    this.associateEntityId = 1
    this.associateEntityType = 'Self'
  }
  $("tbody tr").each(function () {
    var str = ""; var end = ""; var day = ""; var strBilder = "";
    day = $(this).find('th').text()
    $(this).find('td').each(function () {
      // if ($(this).hasClass('highlighted')){
      //   $(this).addClass(baseScheduleId)
      // }
      if ($(this).hasClass('highlighted') && $(this)[0].accessKey == '') {
        $(this)[0].accessKey = slotType
       // $(this)[0].className = 'highlighted' + ' ' + baseScheduleId
       // $(this).addClass(baseScheduleId)
      }
      if ($(this).hasClass('highlighted') && $(this)[0].accessKey == slotType) {
          if (str == "") {
            str = $(this).text()
          }
          if (str == "00:00" && $(this).text() == "23:30") {
            var obj = { 'day': day.trim(), 'startSlot': '00:00', 'endSlot': '23:30', 'type': slotType?.toString(),appointmentType:baseScheduleId }
            obj.startSlot.replace(/[A-Za-z]/g, "")
            obj.endSlot.replace(/[A-Za-z]/g, "")
            obj.type = slotType
            obj.appointmentType=baseScheduleId
            slotArray.push(obj)
          }
          if (str == "23:30" && $(this).text() == "23:30") {
            var obj = { 'day': day.trim(), 'startSlot': '23:30', 'endSlot': '23:30', 'type': slotType?.toString(),appointmentType:baseScheduleId }
            obj.startSlot.replace(/[A-Za-z]/g, "")
            obj.endSlot.replace(/[A-Za-z]/g, "")
            obj.type = slotType
            obj.appointmentType=baseScheduleId
            slotArray.push(obj)

          }
      }

      else {
        //end= $(this).prev().text();
      
        end = $(this).text();
        if (str != "" && end != "") {
          if (str == end) {
            var slot = end?.split(":")
            if (slot[1] == '00') {
              end = slot[0] + ':30'
          }
            if (slot[1] == '30') {
              var time = parseInt(slot[0]) + 1
              if (time < 10) {
                end = "0" + time.toString() + ':' + '00';
              } else {
                end = time.toString() + ':' + '00';
              }
            }
          }
          str = str.replace(/[A-Za-z]/g, "")
          // end=str.replace(/[A-Za-z]/g, "")
          var obj = { 'day': day.trim(), 'startSlot': str, 'endSlot': end, 'type': slotType?.toString(), appointmentType:baseScheduleId}
          obj.type = slotType
          obj.appointmentType=baseScheduleId
          slotArray.push(obj)
        }
        str = "", end = ""
      }
    });
  });
  return slotArray;
}
  selectedSlots(slotType, baseScheduleId): any[] {
    var slotArray = []
    slotArray.length = 0;
    if (this.router.url == '/BasicScheduler') {
      this.associateEntityId = 1
      this.associateEntityType = 'Self'
    }

    $("tbody tr").each(function () {
      var str = ""; var end = ""; var day = ""; var strBilder = "";
      day = $(this).find('th').text()
      $(this).find('td').each(function () {

        let isEnd=false;
  

        if ($(this).hasClass('highlighted') && $(this)[0].accessKey == '') {
          $(this)[0].accessKey = slotType
          $(this)[0].className = 'highlighted' + ' ' + baseScheduleId
          if($(this).text() == "23:30"){
            isEnd = true;
            }
         //$(this).addClass(baseScheduleId)
        }
        // else{

        // }
        if ($(this).hasClass('highlighted') && $(this)[0].accessKey == slotType && $(this).hasClass(baseScheduleId) && !isEnd) {
            if (str == "") {
              str = $(this).text()
            }
            if (str == "00:00" && $(this).text() == "23:30") {
              var obj = { 'day': day.trim(), 'startSlot': '00:00', 'endSlot': '23:30', 'type': slotType?.toString(),appointmentType:baseScheduleId }
              obj.startSlot.replace(/[A-Za-z]/g, "")
              console.log('bas error', obj.startSlot)
              obj.endSlot.replace(/[A-Za-z]/g, "")
              console.log('bas error', obj.endSlot)
              obj.type = slotType
              obj.appointmentType=baseScheduleId
              slotArray.push(obj)
            }
            if (str == "23:30" && $(this).text() == "23:30") {
              var obj = { 'day': day.trim(), 'startSlot': '23:30', 'endSlot': '23:30', 'type': slotType?.toString(),appointmentType:baseScheduleId }
              obj.startSlot.replace(/[A-Za-z]/g, "")
              obj.endSlot.replace(/[A-Za-z]/g, "")
              obj.type = slotType
              obj.appointmentType=baseScheduleId
              slotArray.push(obj)
  
            }
        }

        else {
          //end= $(this).prev().text();
        
          end = $(this).text();

          if(isEnd){
            end = "23:30";
            if(str==""){
              str=$(this).text();
            }
          }

          end=end.replace(/[^\d:]/g, '');
          str=str.replace(/[^\d:]/g, '');
          if (str != "" && end != "") {
            if (str == end) {
              var slot = end?.split(":")
              if (slot[1] == '00') {
                end = slot[0] + ':30'
              }
              if (slot[1] == '30') {
                var time = parseInt(slot[0]) + 1
                if (time < 10) {
                  end = "0" + time.toString() + ':' + '00';
                } else {
                  end = time.toString() + ':' + '00';
                }
              }
            }
            str = str.replace(/[A-Za-z]/g, "")
            // end=str.replace(/[A-Za-z]/g, "")
            var obj = { 'day': day.trim(), 'startSlot': str, 'endSlot': end, 'type': slotType?.toString(), appointmentType:baseScheduleId}
            obj.type = slotType
            obj.appointmentType=baseScheduleId
            slotArray.push(obj)

          }
          str = "", end = ""
        }
      });
    });
    return slotArray;
  }

  ngAfterViewInit() {
    var isMouseDown = false,
      isHighlighted;
    //  if(this.isEdit){
    $("#our_table td")
      .mousedown(function () {
        isMouseDown = true;
        $(this).toggleClass("highlighted");
        isHighlighted = $(this).hasClass("highlighted");
        return false; // prevent text selection
      })
      .mouseover(function () {
        if (isMouseDown) {
          $(this).toggleClass("highlighted", isHighlighted);
        }
      });

    $(document)
      .mouseup(function () {
        isMouseDown = false;
      });
    //this.loadBasesheduler();
    // }
  }
  isEdit: boolean = false
  Edit() {
    if(this.selectedOption!='All'){
      $(".slot-selection").removeClass("slot-selection");
    }
  else{
    $(".slot-selection").addClass("slot-selection");
  }
    this.isEdit = true;
    for(var i=0;i<this.options?.length;i++){
      if(this.options?.[i]==this.selectedOption && this.selectedOption!='All'){
        $('.'+this.options?.[i]).removeClass("slot-selection")
        if(this.router.url=='/clinic-admin' ||this.globalStorage.getItem('loggedInAs')=='IVF'){
          $(".highlighted").removeClass('hospital-slot');
        }
      }
      else{
        $('.'+this.options?.[i]).addClass("slot-selection")
        // alert('not selected type')
      }
    }
    // if($('.highlighted').hasClass(this.selectedOption)){
    //   $('.'+this.selectedOption).removeClass("slot-selection")
    // }
    // else{
    //   $('.highlighted').addClass("slot-selection")
    // }
  }
  cancellFun(){
    $("body").removeClass("modal-open")
    $('#baseSchedule').modal('hide')
    $("#baseSchedule").val(null).trigger("change");
    this.isEdit = false
  }
  Reset() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    // $(".highlighted").removeClass("highlighted");
  }

  //   ngAfterViewChecked(){
  //     if(this.isEdit){
  //     var isMouseDown = false,
  //     isHighlighted;
  //   $("#our_table td")
  //     .mousedown(function () {
  //       isMouseDown = true;
  //       $(this).toggleClass("highlighted");
  //       isHighlighted = $(this).hasClass("highlighted");
  //       return false; // prevent text selection
  //     })
  //     .mouseover(function () {
  //       if (isMouseDown) {
  //         $(this).toggleClass("highlighted", isHighlighted);
  //       }
  //     });

  //   $(document)
  //     .mouseup(function () {
  //       isMouseDown = false;
  //     });
  //   }
  // }
  checkBoxCheck(a,b){
    this.selectedOption=a.value
    var isPresent
    if(this.selectedOption=='All'){
      $('.'+this.selectedOption).addClass("slot-selection")
      this.isAll=true
      return
    }
    else{
      this.isAll=false
    }
    if(this.basesheduler?.length==0){
      this.isNew = true
      if (this.isNew == true) {
        $(".slot-selection").removeClass("slot-selection");
      }
      this.isEdit = true;
      if (this.isEdit == true) {
        $(".slot-selection").removeClass("slot-selection");
      }
    }
    else{
      var result=this.basesheduler?.filter(obj=>obj?.appointmentType==a?.value)
      this.basseScheduleId=result?.[0]?.basseScheduleId
      if(result?.length==0 || this.basesheduler?.length==0){
        this.isNew = true
        if (this.isNew == true) {
          $(".slot-selection").removeClass("slot-selection");
        }
        this.isEdit = true;
        if (this.isEdit == true) {
          $(".slot-selection").removeClass("slot-selection");
        }
      }
      else {
        this.isEdit = false
        this.isNew = false
      }
    }
    if(!this.isEdit){
      for(var i=0;i<this.options?.length;i++){
        if(this.options?.[i]==this.selectedOption && this.selectedOption!='All'){
          $('.'+this.options?.[i]).removeClass("slot-selection")
        }
        else{
          $('.'+this.options?.[i]).addClass("slot-selection")
        }
      }
    }
  }
  clickedOnTd(time,event){
  
  }
}
