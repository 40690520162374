<div class="row">
<div class="col-lg-2"></div>
<div class="col-lg-8">
<div style=" text-align:center; border-color: #e4e2e2;border-style:solid;border-width:2px;width:100%;height:100%;margin-top: 5%;">

    <h2 style="color:red; font-weight: 600; font-size:36px;" >An Error Has Occurred</h2>
    <hr>


    <p style="font-size: 18px; padding: 20px 30px;">We apologize for interruption to your work.CureMetric had problem processing your request.Our IT team takes these
        problems seriously and we're
        notified of every occurrence.</p>

    <p style="font-size: 21px; font-weight: 600;">We're sorry for the Inconvenience.</p>



   <h2 style="font-weight: 600;">Report an error</h2> 

 You can report an error by clicking  <a  href="javascript:void(0)" feedback (send)="onSend($event)">here</a> 
</div>
</div>
<div class="col-lg-2"></div>
</div>