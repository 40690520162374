<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">

<h3 class="mt-2 mb-3 border-bottom pt-2 pb-2  head"> Personal Details <span class="float-right text-danger" style="margin-right: 50%;">{{personalDtsMessage}}</span>   </h3>
<div [formGroup]="personalDetails" style="margin-top:10px;" (keyup)="checkForm(personalDetails,$event)">
    <div class="row">
      <div class="col-md-4">
        <div class="row">
          <div class="col-md-12 col-xs-12">
            <div class="form-group mb-0">
              <label for="exampleInputEmail1">DOB
                <span class="font-size-10 text-muted">(MM/DD/YYYY)</span>
                <span style="color:red;">*</span>
              </label>
              <input id="matDate"

                [disabled]="isDisabled"
                matInput (click)="picker.open()"
                placeholder="mm/dd/yyyy" readonly
                [min]="minDate"
                [max]="maxDate"
                [matDatepicker]="picker" class="form-control" formControlName="dob"
                [ngClass]="{ 'is-invalid': ((submitted || p.dob.touched) && !isDisabled) && p.dob.errors}">
            </div>

            <div *ngIf="((submitted || p.dob.touched) && !isDisabled) && p.dob.errors" class="invalid-feedback">
              <div *ngIf="p.dob.errors.required" style="font-size:10.24px;">DOB is required</div>
            </div>
          </div>

          <div class="" style="margin-top: 24px; margin-left: -44px;">
            <mat-datepicker #picker></mat-datepicker>
            <mat-datepicker-toggle [disabled]="!isEdit" matSuffix [for]="picker"></mat-datepicker-toggle>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputEmail1">Highest Degree <span style="color:red">*</span></label>
          <mat-select placeholder="Select Degree"
            (changed)="degreeChanged(search2)"
            [disabled]="!isEdit"
            formControlName="highestDegree"
            class="form-control"
            [ngClass]="{'is-invalid': (submitted || p.highestDegree.touched) && p.highestDegree.errors}">
            <mat-option *ngFor="let item of degree| search : search2" [value]="item.lookupValue">
              {{item.lookupValue}}
            </mat-option>
          </mat-select>
          <div *ngIf="(submitted || p.highestDegree.touched) && p.highestDegree.errors" class="invalid-feedback">
            <div *ngIf="p.highestDegree.errors.required" style="font-size:10.24px;">
              Highest Degree is required
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4" *ngIf="!phoneNumberField">
        <div class="form-group mb-0">
          <label for="exampleInputEmail1"> Emergency Contact Number
            <span style="color:red">*</span>
          </label>
          <input type="text" #phNum ng2TelInput [class.p-no]="condition" id="sur"
            [ng2TelInputOptions]="{autoHideDialCode: false, initialCountry: 'in', separateDialCode:true}"
            [disabled] = isDisabled  [tabIndex]="isEdit ? 0 : -1"
            [readonly] = "!isEdit"
            (ng2TelOutput)="getNumber($event)"
            (countryChange)="onCountryChange($event)" 
            class="form-control w-100 float-left mb-0 emer"
            placeholder="Emergency Contact Number"
            formControlName="emergencyContactNumber" appInputRestrict="mobileNumber"
            (keypress)="numberOnly($event)"
            [maxlength]="maxLength"
            (keyup)="phnum(phNum.value)"
            [ngClass]="{ 'is-invalid': ((submitted || p.emergencyContactNumber.touched) && !isDisabled) && p.emergencyContactNumber.errors}"
          />
          <div *ngIf="((submitted || p.emergencyContactNumber.touched) && !isDisabled)  && p.emergencyContactNumber.errors"
            class="invalid-feedback">
            <div *ngIf="p.emergencyContactNumber.errors.pattern">Enter valid Mobile Number</div>
            <div *ngIf="p.emergencyContactNumber.errors.required">Emergency contact number is required</div>
          </div>

        </div>
      </div>
      <div class="col-md-4" *ngIf="phoneNumberField">
        <div class="form-group">
          <label for="exampleInputEmail1"> Emergency Contact Number <span style="color:red">*</span></label>
          <input type="text" [tabIndex]="isEdit ? 0 : -1"  [readonly]="!isEdit" type="text" #phNumm class="form-control w-100 float-left   mb-0 emer" [class.p-no]="condition" id="sur"
            placeholder="Emergency Contact Number" formControlName="emergencyContactNumber" [maxlength]="maxlengthphno" appInputRestrict="mobileNumber"
            (keypress)="numberOnly($event)"  (keyup)="phnum(phNumm.value)">
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputEmail1">Languages</label>
          <mat-select [disabled]="isDisabled"
            (change)="langChanged(search3)"
            formControlName="preferredlanguages" multiple
            placeholder="Select">
            <mat-option value="{{item.lookupValue}}" *ngFor="let item of languagesList| search : search3">
              {{item.lookupValue}}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputEmail1">Native language</label>
          <mat-select [disabled]="isDisabled"
            (change)="nativeLangChanged(search1)"
            class="form-control"
            formControlName="nativelanguage"
            placeholder="Select">
            <mat-option *ngFor="let item of nativeLanguage| search : search1" [value]="item.lookupValue">
              {{item.lookupValue}}
            </mat-option>
          </mat-select>
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="exampsavePersonalDetailsleInputEmail1"> Ethinicity</label>
          <input type="text"
          [readonly]="!isEdit"
          [disabled]="isDisabled"
          class="form-control"
          placeholder="Ethinicity"
          formControlName="ethinicity">
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputEmail1">Nationality</label>
            <mat-select placeholder="Select Nationality" (change)="nationalityChanged(searchTxt)" [disabled]="isDisabled" [(ngModel)]="selectedValue"
            formControlName="nationality" class="form-control" name="item">
            <mat-option *ngFor="let item of nationality2 | search : searchTxt" [value]="item.lookupValue">
              {{item.lookupValue}}
            </mat-option>
          </mat-select>
        </div>

      </div>

      <div class="col-md-4">
        <div class="row" [formGroup]="documentGrp">
          <div class="col-md-12 position-relative">
            <div class="form-group">
              <label for="exampleInputEmail1">Upload Signature</label>
              <input type="text" #signature
              
              [disabled]="isDisabled"  
              class="form-control"
              placeholder="Signature"
              formControlName="signature" readonly>
            </div>

            <!-- View Signature -->
            <div class="pos-eye" *ngIf="isSignature">
              <div class="form-group btn-group ">
                <a class="btn btn-sm" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" title="View"  style="padding-top: 40px;" (click)="viewSignature()">
                  <i class="fa fa-eye themecol2 ng-star-inserted" aria-hidden="true"></i>
                </a>
                <button style="margin-top: 34%" class="btn btn-danger btn-sm mr-2 signature-upload" data-toggle="tooltip" data-placement="top" title="PNG/JPEG" [disabled]="!isEdit" (click)="fileInput.click()"><i class="fa fa-upload f-16"
                  aria-hidden="true"></i>
                <input #fileInput type="file" accept="image/*" (change)="onFileInput($event.target.files)" style="display:none !important" />
              </button>
              </div>
            </div>
            <!-- View Signature Ends -->
<!-- 
            <div class="pos-upload" *ngIf="!isSignature">
              <div class="form-group">
                <button style="margin-top: 52%" class="btn btn-danger btn-sm mr-2 signature-upload" data-toggle="tooltip" data-placement="top" title="PNG/JPEG" [disabled]="!isEdit" (click)="fileInput.click()"><i class="fa fa-upload f-16"
                    aria-hidden="true"></i>
                  <input #fileInput type="file" accept="image/*" (change)="onFileInput($event.target.files)" style="display:none !important" />
                </button>
              </div>
            </div> -->
          </div>
          <!-- <div class="col-md-6" style="display:none">
            <div class="form-group">
              <label for="exampleInputEmail1">Specialization</label>
              <input type="text" class="form-control" placeholder="Specialization" formControlName="specialization">
            </div>
          </div> -->
        </div>
      </div>

      <div class="col-md-4">
        <div class="form-group">
          <label for="exampleInputEmail1">Total Experience <span style="color:red">*</span></label>
          <input type="text" class="form-control"
          [disabled]=isDisabled
          [readonly]="!isEdit"
          (keypress)="numberOnlyExp($event)"
          placeholder="Total Experience"
          [maxlength]="expLength"
          (keyup)="expEntered()"
          formControlName="totalExperience"
          [ngClass]="{ 'is-invalid': ((submitted || p.totalExperience.touched) && !isDisabled) && p.totalExperience.errors}">
          <div *ngIf="((submitted || p.totalExperience.touched) && !isDisabled) && p.totalExperience.errors" class="invalid-feedback">
            <div *ngIf="p.totalExperience.errors.required">Total experience is required</div>
            <div *ngIf="p.totalExperience.errors.max">Enter valid data</div>
            <div *ngIf="p.totalExperience.errors.min">Enter valid experience</div>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-1">
        <button type="button signature-upload float-right " *ngIf="!isEdit" class="btn btn-primary btn-sm"
          style="float:right;margin-bottom:0px;" (click)="Edit()"><i class="fa fa-edit"></i> Edit</button>
        <button type="button" class="btn btn-success btn-sm" *ngIf="isEdit" style="float:right;margin-bottom:0px"
          (click)="savePersonalDetails(personalDetails.value)" [disabled]="!personalDetails.valid"><i
            class="fas fa-save"></i> Save</button>
      </div>

    </div>
</div>
<!-- Personal Details Form Ends /-->


<div [formGroup]="documentGrp">
  <div class="col-md-4">
    <div class="form-group">
    </div>
  </div>
</div>

<div class="modal modal" id="location">
  <div class="modal-dialog" style="width:550px;top:35%;" class="center">
    <div class="modal-content">
      <div class="modal-header bg-primary">
        <h5 class="modal-title">	<i class="fa fa-calendar-plus"></i> Doctor Signature</h5>
        <button type="button" class="close text-white" data-dismiss="modal" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <img alt="Signature" [src]="docSignatureImg" style="border-radius: 50%;background: none;">
      </div>
    </div>
  </div>
</div>
