import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ScriptsService {

  constructor() { }
  loadScripts() {
    const dynamicScripts = [
        "./assets/js/scripts.js", 
        "./assets/js/tabs.js",
        "./assets/landing/js/js-multi-select-tree.js",
        // "./assets/surgery-equipment-design/multi.min.js"
    ];
    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.type = 'text/javascript';
      node.async = false;
      node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
    }
  }
  loadCssStyles(){
    const url=[
    // "https://fonts.googleapis.com/css?family=Rubik:400,400i,500,500i,700,700i&amp;display=swap" ,
    //  "https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900&amp;display=swap",
    // "../../../assets/css/responsive.css",
    '../../../assets/css/fontawesome.css',
    // '../../../assets/css/'
    ]
 
    for (let i = 0; i < url.length; i++) {
        var head  = document.getElementsByTagName('head')[0];
        var link  = document.createElement('link');
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.href = url[i]
        link.media = 'all';
        head.appendChild(link);
    }
 
  }
  loadMenuexpand(){
    
  }
  loadInputTelephone(){
    const telephoneInputs=[
      './assets/build/js/utils.js'
    ]
  }
  // loadJs(){
  //   $(document).ready(function(){

  //     var options = {  searchable:true};
      
  //     $("select#demo").treeMultiselect(options);
      
  //     })
  // }
}