<div class="modal-header">
    <h4 class="modal-title">Discount</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>
 </div>
 <div class="modal-body">
    <div class="row" [formGroup]="discountForm">
        <div class="col-lg-6 col-md-6 col-sm-12 ">
            <div class="form-group">
                <label>Amount <span class="text-danger">*</span></label>
                <input type="text" class="form-control" formControlName="amount" placeholder="Enter Amount" maxlength="10" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" numeric [ngClass]="{ 'is-invalid': (submitted || d.amount.touched) && d.amount.errors}">
                <div *ngIf="(submitted || d.amount.touched) && d.amount.errors" class="invalid-feedback">
                    <div *ngIf="d.amount.errors.required">Amount field is required.
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-6">
          <div class="form-group">
              <label>Department <span class="text-danger">*</span></label>
              <mat-select placeholder="Select..." [(ngModel)]="selectedDepartment"
                  class="form-control" formControlName="department" (selectionChange)="drpdownSelectionDepartment(selectedDepartment)"
                  [ngClass]="{ 'is-invalid': (submitted || discountForm.get('department').touched) && discountForm.get('department').errors}">
                  <mat-option *ngFor="let department of departments" [value]="department.departmentName"
                      (click)="getSelectedDept(department)">
                      {{ department.departmentName }}
                  </mat-option>
              </mat-select>
              <div *ngIf="(submitted || discountForm.get('department').touched) && discountForm.get('department').errors"
                  class="invalid-feedback">
                  <div *ngIf="discountForm.get('department').errors.required">Please Select Department.</div>
              </div>
          </div>
      </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-1">
            <label for="" class="">Roles</label>
            <mat-select class="form-control" name="item" formControlName="role" [(ngModel)]="roleSelect" [disabled]="data?.mode=='EDIT'"
              (selectionChange)="drpdownForSelectionRole(roleSelect)" id="role"  placeholder="Select"
              [ngClass]="{
                        'is-invalid': (submitted || d.role.touched) && d.role.errors
                        }">
              <mat-option *ngFor="let item of allRoles" value="{{item.roleName}}" (click)="selectedRoles(item)">
                {{item.roleName}}
              </mat-option>
            </mat-select>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 " *ngIf="data?.mode=='EDIT'">
          <div class="form-group" >
            <label>Approver <span class="text-danger">*</span></label>
            <input type="text" class="form-control" formControlName="apprver" disabled>
          </div>
          </div>
        <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-1" *ngIf="data?.mode!='EDIT'">
            <label for="" class="col-form-label">Approver</label>
            <mat-select class="form-control" name="item" formControlName="person" [(ngModel)]="selectedPerson"
              (selectionChange)="drpdownForSelection(selectedPerson)" id="person" multiple placeholder="Select"
              [ngClass]="{
                        'is-invalid': (submitted || d.person.touched) && d.person.errors
                        }">
              <mat-option *ngFor="let item of usersList" [value]="item" (click)="selectedPersonData(item)">
                {{item.firstName}} {{item.lastName}}
              </mat-option>
            </mat-select>
          </div>
    </div>
    <div class="col-md-6 float-right ml-2">
      <button class="btn btn-success btn-sm float-right" (click)="add()">Save</button>
    </div>
 </div>
