<div class="modal-header">
    <h5 class="modal-title">
        <i aria-hidden="true" class="fa fa-cart-plus"></i> Bulk Order Delivery
    </h5>
    <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row">
        <div class="col-md-3 mb-2">
            <div class="form-group">
                <label>Invoice ID <span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter Invoice ID" appInputRestrict="decimalOnly"
                    maxlength="5" [(ngModel)]="supplierInvoiceId">
            </div>
        </div>
        <div class="col-md-4 position-relative">
            <div class="form-group">
                <label style="position: absolute;">Upload Invoice</label>
                <input style="position: absolute;top: 27px;width: 97%;" #Image type="file" class="form-control"
                    (change)="onFileInput($event.target.files)" />
            </div>
        </div>
    </div>
    <table class="table table-bordered table-hover table-styled table-striped">
        <thead>
            <tr role="row">
                <th width="200px">Item Name</th>
                <th width="120px">Buy Price(unit)</th>
                <!-- <th width="120px">Sale Price(Unit)</th> -->
                <th width="150px">Items Qty</th>
                <!-- <th>Ordered Date</th> -->
                <!-- <th>Delivery Type</th> -->
                <!-- <th>Total Amount</th> -->
                <th width="120px">Batch</th>
                <th width="120px">Location</th>
                <!-- <th>Expiry Date</th> -->
                <!-- <th>Next Delivery Date</th> -->
                <th>Manufacturer Date</th>
                <th>Warranty</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of bulkOrders;let i=index;">
                <td>
                    {{data?.itemName}}
                    <!-- <div> -->
                    <!-- <p><b>Item:</b> {{data?.itemName}}</p> -->
                    <!-- <p><b>Unit Price:</b> {{data?.unitPrice}}</p> -->
                    <!-- <p><b>Deal:</b> {{data?.deal}}</p> -->
                    <!-- <p><b>Offer: </b>
                            <span *ngIf="data?.discount">Discount: {{data?.discount}} %</span>
                            <span *ngIf="data?.freeQuantity">Free: {{data?.freeQuantity}}</span>
                            <span *ngIf="!data?.freeQuantity && !data?.discount">-</span>
                        </p> -->
                    <!-- <p><b>GST (%):</b> {{data?.totalGst}}</p> -->
                    <!-- <p><b>Quantity Ordered:</b> {{data?.totalStockOrdered}}</p> -->
                    <!-- <p><b>Items to be received:</b> {{data?.quantityToBeReceived}}</p> -->
                    <!-- </div> -->
                </td>
                <td>{{data?.unitPrice}}</td>
                <!-- <td>{{data?.dateOrdered | date: 'dd-MMM-yyyy'}}</td> -->
                <!-- <td>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="">
                                <input type="radio" value="FULL_DELIVERED" id="full{{i}}" name="{{'Full' + i}}" class=""
                                    [(ngModel)]="bulkOrders[i].orderStatus">
                                <label class="ml-1" for="full{{i}}">Full</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="">
                                <input type="radio" value="PARTLY_DELIVERED" id="part{{i}}" name="{{'Part' + i}}"
                                    class="" [(ngModel)]="bulkOrders[i].orderStatus">
                                <label class="ml-1" for="part{{i}}">Partial</label>
                            </div>
                        </div>
                    </div>
                </td> -->
                <!-- <td>{{data?.totalPrice?.toFixed(2)}}</td> -->
                <!-- <td>
                    <div class="position-relative">
                        <input matInput [matDatepicker]="expDatePicker" class="form-control"
                            (keypress)="expDatePicker.open()" (keyup)="expDatePicker.open()" [min]="minDate"
                            (dateChange)="updateWarrantyMinDate(i)"
                            (click)="expDatePicker.open()"
                            [(ngModel)]="bulkOrders[i].expireDate">
                        
                        <mat-datepicker-toggle matIconSuffix [for]="expDatePicker"
                            style="position: absolute;top: 2px;right: 0px;"></mat-datepicker-toggle>
                        <mat-datepicker #expDatePicker></mat-datepicker>
                    </div>
                </td> -->

                <!-- <td>
                    <div class="position-relative">
                        <input matInput [matDatepicker]="nextOrderDatePicker" class="form-control"
                            (keypress)="nextOrderDatePicker.open()" (keyup)="nextOrderDatePicker.open()" [min]="minDate"
                            (click)="nextOrderDatePicker.open()" [(ngModel)]="bulkOrders[i].nextOrderDate"
                            [disabled]="getDisabled(i)">

                        <mat-datepicker-toggle matIconSuffix [for]="nextOrderDatePicker"
                            style="position: absolute;top: 2px;right: 0px;"
                            [disabled]="getDisabled(i)"></mat-datepicker-toggle>
                        <mat-datepicker #nextOrderDatePicker></mat-datepicker>
                    </div>
                </td> -->
                <!-- <td><input type="number" class="form-control" appInputRestrict="decimalOnly" maxlength="10"
                        [(ngModel)]="bulkOrders[i].saleUnitPrice"></td> -->
                <td><input type="number" class="form-control" placeholder="Enter Number" appInputRestrict="decimalOnly"
                        (focusout)="checkQty(i)" maxlength="5" [(ngModel)]="bulkOrders[i].numOfItemDelivered"
                        [readonly]="getDisabled(i)"></td>

                <td><input type="text" class="form-control" [(ngModel)]="bulkOrders[i].batchNumber"></td>
                <td><input type="text" class="form-control" [(ngModel)]="bulkOrders[i].rack"></td>
                <td>
                    <div class="position-relative">
                        <input matInput [matDatepicker]="manufDatePicker" class="form-control"
                            (keypress)="manufDatePicker.open()" (keyup)="manufDatePicker.open()" [max]="minDate"
                            (click)="manufDatePicker.open()" [(ngModel)]="bulkOrders[i].manufacturerDate">

                        <mat-datepicker-toggle matIconSuffix [for]="manufDatePicker"
                            style="position: absolute;top: 2px;right: 0px;"></mat-datepicker-toggle>
                        <mat-datepicker #manufDatePicker></mat-datepicker>
                    </div>
                </td>
                <td>
                    <div class="position-relative">
                        <input matInput [matDatepicker]="warrentyPicker" class="form-control"
                            (keypress)="warrentyPicker.open()" (keyup)="warrentyPicker.open()"
                            [min]="bulkOrders[i].warrantyMinDate || minDate" (click)="warrentyPicker.open()"
                            [(ngModel)]="bulkOrders[i].warranty">

                        <mat-datepicker-toggle matIconSuffix [for]="warrentyPicker"
                            style="position: absolute;top: 2px;right: 0px;"></mat-datepicker-toggle>
                        <mat-datepicker #warrentyPicker></mat-datepicker>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>

    <div class="text-right mt-2" *ngIf="bulkOrders?.length > 0">
        <button class="btn btn-success" (click)="deliverBulkOrder()">Delivered</button>
    </div>
</div>