<div class="modal-header">
    <h4 class="centerHeader mb-0">Add Item</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="subCategoryForm">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>Supplier <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="supplier" readonly>
                </div>
            </div>
            
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>Category <span class="text-danger">*</span></label>
                    <mat-select class="form-control" formControlName="category"
                        (selectionChange)="selectedCategory(categorySelected)"
                        [ngClass]="{ 'is-invalid': (submitted || subCatFormControl.category.touched) && subCatFormControl.category.errors }">
                        <mat-option *ngFor="let c of categorylist" [value]="c.catagory"
                            (click)="getcategyById(c?.catagoryId)">{{c.catagory}}</mat-option>
                    </mat-select>
                    <div *ngIf="(submitted || subCatFormControl.category.touched) && subCatFormControl.category.errors"
                        class="invalid-feedback">
                        <div *ngIf="subCatFormControl.category.errors.required">
                            Please Select Category.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>Sub Category <span class="text-danger">*</span></label>
                    <mat-select class="form-control" formControlName="subCategory"
                        (selectionChange)="selectedsubCategory(subCategorySelected)"
                        [ngClass]="{ 'is-invalid': (submitted || subCatFormControl.subCategory.touched) && subCatFormControl.subCategory.errors }">
                        <mat-option *ngFor="let s of subcategory" [value]="s.name"
                            (click)="getsubcategyById(s)">{{s.name}}</mat-option>
                    </mat-select>
                    <div *ngIf="(submitted || subCatFormControl.subCategory.touched) && subCatFormControl.subCategory.errors"
                        class="invalid-feedback">
                        <div *ngIf="subCatFormControl.subCategory.errors.required">
                            Please Select Sub Category.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>Price <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="price" maxlength="10"
                        appInputRestrict="numberOnly" [ngClass]="{ 'is-invalid':
                          (submitted || subCatFormControl.price.touched) && subCatFormControl.price.errors }">
                    <div *ngIf="(submitted || subCatFormControl.price.touched) && subCatFormControl.price.errors"
                        class="invalid-feedback">
                        <div *ngIf="subCatFormControl.price.errors.required"> Price is Required. </div>
                    </div>
                </div>
            </div>

            <h3 class="mb-3 mt-2 font-size-16 border-bottom p-2 bg-primary-light ">Deal</h3>

            <div class="col-lg-12 col-md-12 mb-2">
                <div class="form-group input-material">
                    <input type="text" class="form-control" formControlName="deal" [(ngModel)]="showSet" [ngClass]="{ 'is-invalid':
                          (submitted || subCatFormControl.deal.touched) && subCatFormControl.deal.errors }">
                    <div *ngIf="(submitted || subCatFormControl.deal.touched) && subCatFormControl.deal.errors"
                        class="invalid-feedback">
                    </div>
                </div>
            </div>

            <ng-container *ngIf="showSet">
                <div class="col-lg-6 col-md-6 position-relative">
                    <div class="form-group">
                        <label>Deal From <span class="text-danger">*</span></label>
                        <input matInput [matDatepicker]="dealFromDt" class="form-control" formControlName="dealFromDate"
                            (keypress)="dealFromDt.open()" (keyup)="dealFromDt.open()" [max]="minDate"
                            (click)="dealFromDt.open()"
                        >

                        <mat-datepicker-toggle matIconSuffix [for]="dealFromDt"
                            style="position: absolute;top: 30px;right: 10px;"></mat-datepicker-toggle>
                        <mat-datepicker #dealFromDt></mat-datepicker>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label>Deal To <span class="text-danger">*</span></label>
                        <input matInput [matDatepicker]="dealToDt" class="form-control" formControlName="dealToDate"
                            (keypress)="dealToDt.open()" (keyup)="dealToDt.open()" [min]="minDate"
                            (click)="dealToDt.open()"
                        >

                        <mat-datepicker-toggle matIconSuffix [for]="dealToDt"
                            style="position: absolute;top: 30px;right: 10px;"></mat-datepicker-toggle>
                        <mat-datepicker #dealToDt></mat-datepicker>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 form-group mb-3">
                    <label class="col-form-label">Set Offer</label>
                </div>
                <div class="col-lg-12 col-md-12 form-group mb-3">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                        [(ngModel)]="selectedOption" [ngModelOptions]="{standalone: true}">
                        <mat-radio-button class="example-radio-button" type="radio"
                            (change)="checkBoxCheck($event,option)" *ngFor="let option of options" [value]="option">
                            {{option}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </ng-container>

            <ng-container *ngIf="showSet && showDiscount">
                <div class="col-lg-12 col-md-12 form-group mb-3">
                    <label>Discount on price</label>
                    <div class="form-group input-material">
                        <input type="text" class="form-control" formControlName="discount" maxlength="3"
                            appInputRestrict="numberOnly"
                            [ngClass]="{ 'is-invalid':
                              (submitted || subCatFormControl.discount.touched) && subCatFormControl.discount.errors }">
                        <div *ngIf="(submitted || subCatFormControl.discount.touched) && subCatFormControl.discount.errors"
                            class="invalid-feedback">
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="showSet && showOffer">
                <div class="col-lg-6 col-md-6 form-group mb-3">
                    <label>Buy Quantity</label>
                    <div class="form-group input-material">
                        <input type="text" class="form-control" formControlName="buyQuantity" maxlength="3"
                            appInputRestrict="numberOnly"
                            [ngClass]="{ 'is-invalid':
                              (submitted || subCatFormControl.buyQuantity.touched) && subCatFormControl.buyQuantity.errors }">
                        <div *ngIf="(submitted || subCatFormControl.buyQuantity.touched) && subCatFormControl.buyQuantity.errors"
                            class="invalid-feedback">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 form-group mb-3">
                    <label>Deal Quantity</label>
                    <div class="form-group input-material">
                        <input type="text" class="form-control" formControlName="freeQuantity" maxlength="3"
                            appInputRestrict="numberOnly"
                            [ngClass]="{ 'is-invalid':
                              (submitted || subCatFormControl.freeQuantity.touched) && subCatFormControl.freeQuantity.errors }">
                        <div *ngIf="(submitted || subCatFormControl.freeQuantity.touched) && subCatFormControl.freeQuantity.errors"
                            class="invalid-feedback">
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="vime al text-right">
                <button type="button" class="btn btn-danger al mt-3 float-right btn-sm ml-2" (click)="closeModal()">Cancel</button>
                <button type="button" class="btn btn-success al mt-3 float-right btn-sm ml-2" (click)="saveItems()" [disabled]='subCategoryForm.invalid'><i class="fa fa-save"></i> Save </button>
              </div>
        </div>
    </form>
</div>