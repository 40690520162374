import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import { MustMatch } from 'src/app/_helpers/must_match.validators';
import { interval, Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
@Component({
  selector: 'app-pharma-forgot-password',
  templateUrl: './pharma-forgot-password.component.html',
  styleUrls: ['./pharma-forgot-password.component.css']
})
export class PharmaForgotPasswordComponent implements OnInit {



  resetPassword: FormGroup
  submitted = false
  verificationId
  isResend = false
  isSend = true
  isGetOtp = false
  isTimeOn1 = true;
  isResendOtp = false
  timerOn = true;
  subscription: Subscription;
  invalidCredentials: boolean = false
  successCredentials: boolean = false
  message
  subscribeTimer;
  fromMail
  timeLeft: number = 120;

  uValid: boolean = true;
  eValid: boolean = true;
  showTimer: boolean = false;

  nValid: boolean = true;
  cValid: boolean = true;
  showEVerify: boolean = true;
  showMVerify: boolean = true;

  constructor(private fb: FormBuilder, private reset: pharmaService, private route: Router, private otpService: AuthService, private toastr: ToastrService, private router: ActivatedRoute) {
    const navigation = this.route.getCurrentNavigation();
    const state = navigation?.extras?.state as {
      userName: string,
      emailId: string,
      mobileNumber: string,
    };
    if (state) {
      this.fromMail = true
    }
    else {
      this.fromMail = false
    }

    this.resetPassword = this.fb.group({
      userName: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email]],
      otp: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.required,
      Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{8,15})')]],
      confirmPassword: ['', [Validators.required]]
    },
      {
        validator: MustMatch('newPassword', 'confirmPassword')
      })
    this.router.queryParams.subscribe(params => {
      this.verificationId = params['verificationid'];

    });

    this.resetPassword.patchValue({
      userName: state?.userName,
      emailId: state?.emailId
    });
  }
  isAssociateUser = false
  ngOnInit(): void {
    if (this.route.url == '/pharmaCreatePassword') {
      this.isAssociateUser = true
    }
    this.resetPass()
  }
  get f() { return this.resetPassword.controls; }
  resetPass() {
  }


  checkUserName() {
    this.invalidCredentials = false
    if (this.f.userName.errors == null) {
      this.otpService.pharmaUserNameExist(this.f.userName.value).subscribe(res => {
        if (res.data) { }
        else {
          this.invalidCredentials = true
          this.message = res?.message
        }
      }, (error) => {

      });
    }

  }
  submit(item: any) {

    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPassword.invalid) {
      return;
    }
    var request = {
      "userName": item.value.userName,
      "emailId": item.value.emailId,
      "newPassword": item.value.newPassword,
      "otp": this.PhOTP,
      //"verificationid":this.verificationId
    }
    this.reset.changePwd(request).subscribe(res => {
      this.toastr.success("Specified password has been saved successfully")
      this.route.navigate(['/pharmaLogin'])
    }, error => {
      this.invalidCredentials = true
      this.message = error['error']['message']
    })
  }
  submitUser(item: any) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPassword.invalid) {
      return;
    }
    var request = {
      "emailId": item.value.emailId,
      "newPassword": item.value.newPassword,
      "otp": this.PhOTP,
      //"verificationid":this.verificationId
    }
    this.reset.changePwdUser(request).subscribe(res => {
      this.toastr.success("Specified password has been saved successfully")
      this.route.navigate(['/pharmaLogin'])
    }, error => {
      this.invalidCredentials = true
      this.message = error['error']['message']
      //this.toastr.error(error['error']['message'])
    })
  }
  isOtpSent; isResentOtp: boolean = false; cellNumber; verifyNowEnable = false ;phone
  getOtp() {
    this.invalidCredentials = false
    if (this.resetPassword.value.emailId == "" && this.resetPassword.value.userName == "") {
      this.invalidCredentials = true
      this.message = "Please enter userName and emailId"
      return
    }
    this.otpService.resendNewEmailOtpForPharmacyLogin(this.resetPassword.value.userName, this.resetPassword.value.emailId).subscribe(res => {
      this.successCredentials = true
      this.message = 'Check your email for OTP.'
      this.isOtpSent = true
      this.isResentOtp = true;
      this.showTimer = true;
      this.verifyNowEnable = true
      this.cellNumber = res['data']
     this.phone =  this.cellNumber.toString()
     var a = this.phone.substring(this.phone.length - 4);
console.log(a);

      this.resetPassword.patchValue({
        phoneNumber: `******${a}`
      })
      this.observableTimer()
    }, error => {
      this.invalidCredentials = true
      this.message = error['error']['message'];
    })
  }
  getOtpUser() {
    this.invalidCredentials = false
    if (this.resetPassword.value.emailId == "") {
      this.invalidCredentials = true
      this.message = "Please enter emailId"
      return
    }
    this.otpService.resendNewEmailOtpForPharmacyUser(this.resetPassword.value.emailId).subscribe(res => {
      this.successCredentials = true
      this.message = 'Check your email for OTP.'
      this.isOtpSent = true
      this.isResentOtp = true;
      this.showTimer = true;
      this.observableTimer()
    }, error => {
      this.invalidCredentials = true
      this.message = error['error']['message']
      //this.toastr.error(error['error']['message'])
    })
  }
  PhOTP; mbNumber; bothVerifcation = false
  verifyPhoneOTP() {
    this.successCredentials = false
    this.invalidCredentials = false
    if (this.cellNumber == undefined) {
      return
    }

    if (this.cellNumber == "") {
      return
    }
    this.otpService.verifyPhoneOTPForPharmacy(this.cellNumber.replace(/\+/gi, '%2B'), this.PhOTP).subscribe(res => {
      //this.toaster.success('Your phone number is now verified.')
      // this.mOtpError = true;
      this.bothVerifcation = true

    }, error => {
      this.invalidCredentials = true;
      this.message = error.error.message;
    })
  }
  getPhoneOtp() {
    this.successCredentials = false
    this.invalidCredentials = false
    if (this.cellNumber == "" || this.cellNumber == undefined) {
      this.toastr.warning('Enter the mobile number to send verification OTP')
      return
    }
    this.otpService.resendPhoneOtpForPharmacy(this.cellNumber.replace(/\+/gi, '%2B'), this.resetPassword.value.userName).subscribe(res => {
      // this.toaster.success('OTP sent successfully.')
      this.successCredentials = true
      this.message = 'OTP sent successfully.'
      this.observableTimer1()
    }, error => {
      this.toastr.error(error['error']['message'])
    })

  }
  verifyOTP() {
    this.successCredentials = false;
    this.invalidCredentials = false;

    this.otpService.verifyEmailOtpForPharmacy(this.resetPassword.value.emailId, this.resetPassword.value.otp).subscribe(res => {
      this.showTimer = false;
      this.getPhoneOtp()
      //this.observableTimer1();
      this.isSend = false;
      this.verifyNowEnable = false;
      this.emailVerificationDone = true
    }, error => {
      this.invalidCredentials = true;
      this.message = error.error.message;
    })
  }
  isOtpValid = true; emailVerificationDone = false
  otpCheck(otp) {
    this.successCredentials = false
    this.invalidCredentials = false

    this.verifyOTP()
    if (otp.length == 6) {

      // this.otpService.otpVerifyPharma(this.resetPassword.value.emailId,otp).subscribe(res=>{
      //   this.isOtpValid=true;
      //   this.uValid = true;
      //   this.eValid = true;
      //   this.showTimer = false;
      // },error=>{
      //   this.isOtpValid=false
      //   this.successCredentials=false
      //   this.invalidCredentials=true;
      //   this.uValid = false;
      //   this.eValid = false;
      //   this.message=error['error']['message']
      // })
    }
    // else{
    //   this.invalidCredentials=false
    // }
  }
  observableTimer() {
    if (this.resetPassword.value.emailId == "") {
      return
    }
    this.isResend = false;
    var source = timer(1000, 1000);
    this.timeLeft = 120;
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isSend = false
        this.isResend = true;
        this.showTimer = false;
        this.isResentOtp = false
        this.isGetOtp = false
        this.isResendOtp = false
        this.toastr.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  isTimeOn;

  observableTimer1() {
    this.isTimeOn1 = true
    var source = timer(1000, 1000);
    this.timeLeft = 120;
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer1').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn1 = false
        // this.isSend=false
        // this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.successCredentials = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        // this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }

  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  showHidePwd() {
    if ($('#show_hide_password input').attr("type") == "text") {
      $('#show_hide_password input').attr('type', 'password');
      $('#show_hide_password i').addClass("fa-eye-slash");
      $('#show_hide_password i').removeClass("fa-eye");
    } else if ($('#show_hide_password input').attr("type") == "password") {
      $('#show_hide_password input').attr('type', 'text');
      $('#show_hide_password i').removeClass("fa-eye-slash");
      $('#show_hide_password i').addClass("fa-eye");
    }
  }
  showHidePwd1() {
    if ($('#show_hide_password1  input').attr("type") == "text") {
      $('#show_hide_password1 input').attr('type', 'password');
      $('#show_hide_password1 i').addClass("fa-eye-slash");
      $('#show_hide_password1 i').removeClass("fa-eye");
    } else if ($('#show_hide_password1 input').attr("type") == "password") {
      $('#show_hide_password1 input').attr('type', 'text');
      $('#show_hide_password1 i').removeClass("fa-eye-slash");
      $('#show_hide_password1 i').addClass("fa-eye");
    }
  }

  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z\_]+$/);
    return pattern.test(value);
  }

  emailEntered() {

    if (this.resetPassword.controls['emailId'].status === "VALID")
      this.eValid = false;

    else
      this.eValid = true;
  }
  npEntered() {
    if (this.resetPassword.controls['newPassword'].status === "VALID") {
      this.nValid = false;
    }
    else {
      this.nValid = true;
    }
  }

  cpEntered() {
    if (this.resetPassword.controls['confirmPassword'].status === "VALID") {
      this.cValid = false;
    }
    else {
      this.cValid = true;
    }

  }

  emailOtpEntered(otp) {
    if (otp.length == 6) {
      this.showEVerify = false;
    }
    else {
      this.showEVerify = true;
    }
  }

  phOtpEntered(otp) {
    if (otp.length == 6) {
      this.showMVerify = false;
    }
    else {
      this.showMVerify = true;
    }
  }
}
