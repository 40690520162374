<div class="block inv-section" id="contentToConvert">
    <div class="top-inv-col">
      <div class="inv-logo">
        <img alt="" src="./assets/img/logo.png">
      </div>
      <div class="float-right text-left order-details">
        <!-- <p>Order ID: <span>{{precId}}</span></p> -->
        <p>Date: <span>{{Createddate | date: "dd-MMM-yyyy" }}</span></p>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-6" *ngIf="prescriptionImageData?.doctorId!=-1">
        <h5 >Prescription From</h5>
        <ul class="inv-receiver">
          <li class="">Dr.{{doctorName}}<br>{{dStreet1}}<br>
            {{dStreet2}}
            {{dCity}}-{{dZip}}<br>{{dState}}&nbsp;{{dCountry}}</li>
        </ul>

      </div>
      <div class="col-md-6" *ngIf="prescriptionImageData?.doctorId==-1">
        <h5>Prescription From</h5>
        <ul class="inv-receiver ">
          <li class="">{{patientName}}<br>{{pstreet1}} {{pstreet2}}<br>
            {{pcity}}-{{pzip}}<br>{{pstate}}&nbsp;{{pcountry}}</li>
        </ul>

      </div>
      <div class="col-md-6">
        <div class="float-right text-left">
          <h5>Prescription To</h5>
          <ul class="inv-receiver ">
            <li class="">{{patientName}}<br>{{pstreet1}} {{pstreet2}}<br>
              {{pcity}}-{{pzip}}<br>{{pstate}}&nbsp;{{pcountry}}</li>
          </ul>
        </div>
      </div>
    </div> -->




    <h4 *ngIf="medicalTestPrescription?.length>0" class="mt-2 mb-3 border-bottom pt-2 pb-2 head">Lab Test Prescriptions</h4>
    <ol class="prescription-ol pl-0" *ngIf="medicalTestPrescription?.length>0">
      <li *ngFor="let row of medicalTestPrescription;let i =index"><i>{{row.testType}}</i> <b> {{row.testName}} </b>
        <span> ({{row.instructions}})</span>
        <!-- <ul style="padding-left: 10px">
          <li style="list-style: none">
            {{row.notes}}</li>
          <li style="list-style: none"></li>
        </ul> -->
      </li>
    </ol>
    <h4 *ngIf="MedicationPrescriptions?.length>0" class="mt-2 mb-3 border-bottom pt-2 pb-2 head">Medical Prescriptions</h4>
    <ol class="prescription-ol pl-0" >

      <li *ngFor="let med of MedicationPrescriptions ;let i=index"><i>{{med?.type}}</i> <b> {{med?.medicineName}}
          {{med?.power}} </b><span *ngIf="med.instructions"> ({{med?.instructions}})</span>

        <span class="float-right" style="border-left: 1px solid #000;  padding-left: 10px;margin-right: 70px;
    ">{{med?.numberOfDays}}days - {{med?.refill}} Refill(s)</span>

        <ul style="padding-left: 10px; display: none;"
        *ngIf="med.morning !== null && med.morning !== 0 || med.afternoon !== null && med.afternoon !== 0 || med.evening !== null && med.evening !== 0 || med.instructions !== null">
          <li style="list-style: none" 
          *ngIf="med.morning !== null && med.morning !== 0 || med.afternoon !== null && med.afternoon !== 0 || med.evening !== null && med.evening !== 0 || med.instructions !== null">
            {{med?.morning}}+{{med?.afternoon}}+{{med?.evening}} &emsp; {{med?.instructions}}</li>
          <li style="list-style: none"></li>
        </ul>
      </li>

    </ol>
    <div class="prescriptionImage mb-5" *ngIf="imageIsPresent">
      <div class="col-md-6">
        <img [src]="newsrc" style="height: 416px" alt="" />
        <iframe *ngIf="showPdf==true" [src]="newsrc" class="pdf-viewer" frameborder="0" scrolling="no"
          style="height: 600px;">
          <p>It appears your web browser doesn't support iframes.</p>
        </iframe>

      </div>
    </div>
    <div class="prescribedBy" style="display: none;">
      <div>Prescribed By</div>
      <div> <img *ngIf="showImage==true" [src]="doctorSignatureSrc" style="height: 50px" alt="" /></div>
    </div>
    <div class="invoice-info" *ngIf="isPatient">

      <div *ngIf="prescriptionFill == 'N' && prescriptionImageData?.nextfilrequestdate==null" > <button href="javascript:void(0)"
          class="btn  btn-sm btn-success  mr-2  mt-2 p-2 float-right display-btn" type="submit"
          (click)="navigateToSelectPharma()" data-dismiss="modal" aria-label="Close">
          Fill Prescription</button></div>
          <div  *ngIf="today>=prescriptionImageData?.nextfilrequestdate"> <button href="javascript:void(0)"
            class="btn  btn-sm btn-success  mr-2  mt-2 p-2 float-right display-btn" type="submit"
            (click)="navigateToSelectPharma()" data-dismiss="modal" aria-label="Close">
            Fill Prescription</button></div>
      <a href="javascript:void(0)" style="color: #ffffff"
        class="btn  btn-sm btn-primary mr-2 mt-2 p-2 float-right display-btn" type="button"
        (click)="downloadAsPDF()">
        Download</a>

    </div>
  </div>
