import { Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { PatientService } from './patient.service';

@Injectable()
export class ObservableService{
  invokeEvent: Subject<any> = new Subject();
  invokeEvent_vitals: Subject<any> = new Subject();
  invokeEvent_insert: Subject<any> = new Subject();
  invokeEvent_insert_doctor: Subject<any> = new Subject();
  invokeEvent_ChangeData: Subject<any> = new Subject();
  invokeEvent_OpenSceduler: Subject<any> = new Subject();
  updater_request: Subject<any> = new Subject();
  update_AssociateUsers: Subject<any> = new Subject();
  rpm_user_Call_Data: Subject<any> = new Subject();
  ivf_spouse_ref:Subject<any> = new Subject();
  ivf_prescription:Subject<any> = new Subject();
  ivf_appointmentNotes:Subject<any> = new Subject();
  ivf_prescription_view:Subject<any> = new Subject();
  openPrescrtionForFemale:Subject<any> = new Subject();
  ivf_vitals_edit:Subject<any> = new Subject();
  ivf_prescription_Reload:Subject<any> = new Subject();
  openPrescrtionForspouse:Subject<any> = new Subject();
  appointments_Reload:Subject<any> = new Subject();
  callRecordsReload:Subject<any> = new Subject();
  rpmUser_vitals:Subject<any> = new Subject();
  closeAndOpen:Subject<any> = new Subject();
  ivf_spouse_registered: Subject<any> = new Subject();
  ivf_check_registar_modal:  Subject<any> = new Subject();

  reset_associate_changepw_modal: Subject<any> = new Subject();

  callMethodOfSecondComponent() {
    this.invokeEvent.next('someValue')
  }
  updateVitals(){
    this.invokeEvent_vitals.next('someValue')
  }
  callMethodOfinsert() {
    this.invokeEvent_insert.next('someValue')
  }
  callMethodOfinsert_doctor() {
    this.invokeEvent_insert_doctor.next('someValue')
  }
  callMethodfrochangeDate() {
    this.invokeEvent_ChangeData.next('someValue')
  }
  callMethodtoOpenBaseScheduler(item) {
    this.invokeEvent_OpenSceduler.next(item)
  }
  updateRequests(){
    this.updater_request.next('someValue')
  }
   i  = 0;
  updateAssociateUsers(){
   this.i = this.i +1;

    console.log('updateAssociateUsers',this.i,"here")
    this.update_AssociateUsers.next(`someValue${this.i}`)
  }
  rpmCallData(){
    this.rpm_user_Call_Data.next('someValue')
  }
  patientRegOn(){
    this.ivf_spouse_ref.next('someValue')
  }
  patientRegistered(){
    this.ivf_spouse_registered.next('true')
  }
  registerModalClosed(){
    this.ivf_check_registar_modal.next('true')
  }

  assocaitePwformreset(){
    this.reset_associate_changepw_modal.next(true);
  }
  getFormResetObservable(): Observable<boolean> {
    return this.reset_associate_changepw_modal.asObservable();
  }

  prescriptionOn(value){
    this.ivf_prescription.next(value)
  }
  appointmentNotesOn(value){
    this.ivf_appointmentNotes.next(value)
  }
  prescriptionToView(value){
    this.ivf_prescription_view.next(value)
  }
  openPrescrtionForPrimary(){
    this.openPrescrtionForFemale.next('someValue')
  }
  openPrescrtionForSpouse(){
    this.openPrescrtionForspouse.next('someValue')
  }
  vitalsedit(data){
    this.ivf_vitals_edit.next(data)
  }
  reloadPrescription(){
    this.ivf_prescription_Reload.next('someValue')
  }
  getAppointments(){
    this.appointments_Reload.next('someValue')
  }
  getCallDetails(){
    this.callRecordsReload.next('someValue')
  }
  updateVitalsRpm(userId){
    this.rpmUser_vitals.next(userId)
  }
  reopenTab(){
    this.closeAndOpen.next('someValue')
  }


}
