<div class="modal-header">
    <h5 class="modal-title">
        <i aria-hidden="true" class="fa fa-user-plus"></i> Place Order
    </h5>
    <button type="button" class="close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-bodycure pt-0">
    <form [formGroup]="createorders">
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12 form-group">
                <label for="" class="">Select Supplier<b>*</b></label>
                <div class="form-group input-material">
                    <div class="form-group input-material">
                        <div>
                            <mat-select
                                 class="form-control" id="Supplier"
                                formControlName="Supplier"
                                [ngClass]="{ 'is-invalid': (submitted || createordersControls.Supplier.touched) && createordersControls.Supplier.errors }">
                                <mat-option *ngFor="let sup of suplier" [value]="sup.name"
                                    (click)="selectedSuppiler(sup)">{{sup.name}}</mat-option>
                            </mat-select>
                            <div *ngIf="(submitted || createordersControls.Supplier.touched) && createordersControls.Supplier.errors"
                                class="invalid-feedback">
                                <div *ngIf="createordersControls.Supplier.errors.required">
                                    Please Select SubCategory to Select Supplier.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12 form-group" *ngIf="itemsData">
                <label for="" class="">Select Category 
                    <!-- <i class="fa-regular fa-square-plus bg-primary cursor-pointer" title="Add New Category" (click)="addCat()"></i> -->
                </label>
                <div class="form-group input-material">
                    <div class="form-group input-material">
                        <div>
                            <mat-select class="form-control">
                                <mat-option *ngFor="let sup of categoryList" [value]="sup.catagory"
                                    (click)="getItemsList(null,sup.catagoryId)">{{sup.catagory}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-4 col-lg-4 col-md-4 col-xs-12 form-group" *ngIf="itemsData">
                <label for="" class="">Search Item <i class="fa-regular fa-square-plus bg-primary cursor-pointer" title="Add New Item" (click)="subCategoryAdd()"></i></label>
                <div class="form-group input-material">
                    <div class="form-group input-material">
                        <div>
                            <input type="text" class="form-control" placeholder="Search Item.."  matInput [(ngModel)]="searchedItem"
                            [formControl]="itemControl" [matAutocomplete]="auto1">
                            <mat-autocomplete #auto1="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async"
                                    (click)="onItemSel(option)" [value]="option.name">
                                    {{ option.name }}
                                </mat-option>
                            </mat-autocomplete>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-md-6" *ngIf="itemsData">
            <h4 class="mb-0 mt-2 font-size-16 border-bottom p-2 bg-primary-light ">Items List</h4>
        <div class="tableFixHead">
            <table class="table table-hover table-bordered">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th width="50">Actions</th>
                    </tr>
                </thead>

                <tbody *ngIf="itemsData.length>0 && !itemsLoading">
                    <tr *ngFor="let data of itemsData;let i=index" [ngClass]="{'bg-success-light': isSelected(data)}">
                        <td>{{data?.name}}</td>
                        <td>{{data?.catagory}}</td>
                        <td><button (click)="addToSelectedList(data)" class="btn btn-primary btn-xs">
                                <i class="fa fa-add"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="itemsData.length==0 && !itemsLoading">
                    <td colspan="3">
                        <div class="no-data-found"></div>
                    </td>
                </tbody>
                <tbody *ngIf="itemsLoading">
                    <tr>
                        <td colspan="3">
                            <app-skelton-loader [numRows]="30"></app-skelton-loader>
                        </td>
                    </tr>
                </tbody>
            </table></div>
        </div>
        <div class="col-md-6" *ngIf="itemsData">
            <h4 class="mb-0 mt-2 font-size-16 border-bottom p-2 bg-danger-light ">Selected List</h4>
            <div class="tableFixHead">
                  <table class="table table-hover table-bordered">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Category</th>
                        <th width="50">Actions</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let data of selctedItemsData;let i=index">
                        <td>{{data?.name}}</td>
                        <td>{{data?.catagory}}</td>
                        <td>
                            <button (click)="removeFromSelectedList(i)" class="btn btn-danger btn-xs">
                                <i class="fa fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
            <div class="text-right">
                <button class="btn btn-success" (click)="createPO()">Create Purchase Order</button>
            </div>
        </div>
    </div>
</div>
