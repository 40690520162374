<div style="text-align: center;">
    <h2 style="background-color: #166ad3; color: #fff; padding: 10px 10px;">Reject Appintment
        <p style="float: right;"> <button class="btn btn-danger" (click)="close()">X</button></p>
    </h2>

    <div style="padding: 5px 30px 5px 30px;">
        Comments
        <div class="form-group">
            <textarea autoResizeRows class="form-control rounded-0" id="exampleFormControlTextarea2" rows="2"></textarea>
        </div>
    </div>
    <button class="btn btn-danger" (click)="Reject()">Reject</button>
</div>