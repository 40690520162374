<div class="switchdetails" style="display:none"> Logged in as Balaraju</div>


<div class="l-sidebar" id="open-menu">
  <div class="logo">
    <div class="logo__txt"><img alt="logo-img" class="img-center" id="logo-img" src="./assets/img/logo-white.png"></div>
  </div>
  <div class="l-sidebar__content">
    <nav class="c-menu js-menu">
      <ul class="u-list">
        <li class="c-menu__item is-active " data-toggle="tooltip" data-placement="right" title="Home" (click)="checkProfile()">
          <a href="javascript:void(0)" id="home" routerLink="/p-dashboard">
            <div class="c-menu__item__inner bg-warning-light">
              <i class="fa fa-home"></i>
              <a class="c-menu-item__title"><span>Home</span></a>
            </div>
          </a>
        </li>

        <li class="c-menu__item has-submenu " data-toggle="tooltip" data-placement="right" title="Appointments"  (click)="checkProfile()">
          <a href="javascript:void(0)" id="appointment" routerLink="/main-Appointments">
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-calendar-alt"></i>
              <div class="c-menu-item__title"><span>Appointments</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu" data-toggle="tooltip" data-placement="right" title="Prescriptions " (click)="checkProfile()">
          <a href="javascript:void(0)" id="prescription-menu" routerLink="/prescription">
            <div class="c-menu__item__inner bg-primary-light">
              <i class="fa fa-file-prescription"></i>
              <div class="c-menu-item__title"><span>Prescriptions</span></div>
            </div>
          </a>
        </li>

        <li class="c-menu__item has-submenu  " data-toggle="tooltip" data-placement="right" title="Medical Reports" (click)="checkProfile()">
          <a href="javascript:void(0)" id="medical-report" routerLink="/patient-reports">
            <div class="c-menu__item__inner bg-danger-light">
              <i class="fas fa-file-medical-alt"></i>
              <div class="c-menu-item__title"><span>Medical Reports</span></div>
            </div>
          </a>
        </li>

        <li class="c-menu__item has-submenu  " data-toggle="tooltip" data-placement="right" title="Vitals" (click)="checkProfile()">
          <a href="javascript:void(0)" id="vitals" routerLink="/vitals-view">
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-heartbeat"></i>
              <div class="c-menu-item__title"><span>Vitals</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu  " data-toggle="tooltip" data-placement="right" title="History" (click)="checkProfile()">
          <a href="javascript:void(0)" id="history" routerLink="/pat-history">
            <div class="c-menu__item__inner bg-danger-light">
              <i class="fa fa-h-square"></i>
              <div class="c-menu-item__title"><span>History</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu" data-toggle="tooltip" data-placement="right" title="Orders" (click)="checkProfile()">
          <a href="javascript:void(0)" id="orders" routerLink="/view-Orders">
            <div class="c-menu__item__inner bg-primary-light">
              <i class="fa fa-people-carry"></i>
              <div class="c-menu-item__title"><span>Orders</span></div>
            </div>
          </a>
        </li>

        <li class="c-menu__item has-submenu  " data-toggle="tooltip" data-placement="right" title="Reports" (click)="checkProfile()">
          <a href="javascript:void(0)" id="all-reports" routerLink="/patient-all-reports">
            <div class="c-menu__item__inner bg-danger-light">
              <i class="fa fa-chart-bar"></i>
              <div class="c-menu-item__title"><span>Reports</span></div>
            </div>
          </a>
        </li>

        <li class="c-menu__item has-submenu" data-toggle="tooltip" data-placement="right" title="Profile" (click)="checkProfile()">
          <a href="javascript:void(0)" id="profile" routerLink="/pat-settings">
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-user-cog"></i>
              <div class="c-menu-item__title"><span>Profile</span></div>
            </div>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>

<header class="l-header">
  <div class="l-header__inner clearfix">
    <!-- <div class="c-header-icon js-hamburger no-mobile">
      <div class="hamburger-toggle"><span class="bar-top"></span><span class="bar-mid"></span><span
          class="bar-bot"></span></div>
    </div> -->

    <div class="c-search p-0 no-desktop">
      <img src="./assets/img/logo-mobile.png" />
    </div>
    <div class="c-search no-mobile">
      <div *ngIf="associatedUserData == undefined" style=" float: left; ">Logged in as <b
          class="ml-1 mr-1 captilize" *ngIf="patientName!='null null'">{{patientName |titlecase}}</b>
          <b
          class="ml-1 mr-1 captilize" *ngIf="patientName=='null null'">Guest</b> </div>
      <span class="float-right pt-0 d-none d-lg-block mr-2" *ngIf="!firstTimeLogin">
        Last login {{lastLogin | date:'dd-MMM-yyyy hh:mm a'}} <div *ngIf="associatedUserData!=undefined">You are logged
          in as
          <b>{{patientName}}</b>
        </div>

      </span>

      <span class="float-left d-none d-lg-block mr-2" *ngIf="firstTimeLogin">
        Welcome to CureMetric. This is your first time login.
      </span>


    </div>
    <!-- <button mat-raised-button color="primary" feedback (send)="onSend($event)">Help</button> -->
    <div class="header-icons-group">
      <div class="c-header-icon has-dropdown bg-danger-light" data-toggle="tooltip" data-placement="left" title="Help">
        <i class="fas fa-question-circle" id="help-icon" feedback (send)="onSend($event)"></i>
      </div>

      <div class="dropdown" *ngIf="!associatedUserData">
        <div class="dropdown-content">
          <ul>
            <li *ngFor="let associate of addedByYou">
              <a *ngIf="associate.approved=='Y'" (click)="switchTo(associate)"><img src="./assets/img/avatar.png"
                  width="50" /> {{associate.firstName | titlecase}} <i
                  class="fa fa-random themecol3 p-1 pull-right"></i></a>
            </li>
          </ul>
        </div>
        <div class="c-header-icon has-dropdown bg-primary-light" *ngIf="addedByYou?.length>0">
          <i class="fas fa-user-friends" id="users"></i>
        </div>
      </div>

      <div class="c-header-icon has-dropdown bg-primary-light" *ngIf="notificationBell" data-toggle="tooltip"
        data-placement="left" title="Notifications">
        <span class="c-badge c-badge--header-icon"
          *ngIf="notificationLength != 'null' || notificationLength != 'undefined'">{{notificationLength}}</span>
        <span class="c-badge c-badge--header-icon"
          *ngIf="notificationLength == 'null' || notificationLength == 'undefined'">0</span>
        <i class="fa fa-bell" data-toggle="modal" data-target="#viewreminders"></i>
      </div>
      <div class="c-header-icon logout bg-warning-light" *ngIf="associatedUserData" data-toggle="tooltip"
        data-placement="left" title="Back To Self"><i class="fas fa-arrow-alt-circle-left"
          (click)="backToMainUser()"></i></div>
      <div class="c-header-icon has-dropdown bg-success-light " data-toggle="tooltip" data-placement="left"
        title="Profile">
        <i class="fa fa-user-cog" id="profile" routerLink="/pat-settings"></i>
      </div>
      <div class="c-header-icon has-dropdown bg-primary-light float-right"  data-toggle="tooltip"
        data-placement="left" title="Your Cart">
        <!-- <span class="c-badge c-badge--header-icon"></span> -->
        <i class="fa-solid fa-cart-shopping"
          data-toggle="modal" data-target="#viewcart" (click)="getCart()"></i>
      </div>
      <div class="c-header-icon logout bg-warning-light" data-toggle="tooltip" data-placement="left" title="Logout"><i
          class="fa fa-sign-out-alt" id="logout" (click)="signOut()"></i></div>
    </div>
  </div>
</header>
<div class="modal fade modal-right" id="viewreminders" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight"
  style="z-index: 1050 !important;">
  <div class="modal-dialog" role="document" style="min-width:35%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notifications</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>


      <!-- <app-view-reminders></app-view-reminders> -->
      <div class="row">
        <div class="col-lg-12 mt-3 text_right ">

          <ul class="pagination">
            <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
          </ul>
        </div>
      </div>
      <div class="no-notifications" *ngIf="nonViewData?.length==0"></div>
      <div class="row d-flex ml-2">

        <div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left  blt-2 mb-2 mt-1 "
          *ngFor="let reports of nonViewData |paginate: { id: 'p2', itemsPerPage: 6, currentPage: p2 }"
          [ngClass]="{' blt-3': reports.viewed == 'Y'}" (click)="updateNotify(reports)">

          <a href="javascript:void(0)" class="p-2 icon-appointments  ">
            <i *ngIf="reports.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3" aria-hidden="true"></i>
            <i *ngIf="reports.viewed != 'Y'" class="fa fa-bell fa-lg pt-2  icon-col-3" aria-hidden="true"></i>
          </a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
            <a href="javascript:void(0)">
              <p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
              </p>
              <p class="text-muted mb-1 text-small">{{reports.reason}}</p>
            </a>

          </div>

          <div class="btn-group float-right">
            <button class="btn btn-xs btn0col1" data-toggle="modal" data-backdrop="static" data-target="#viewpdf"><i
                class="fa fa-eye" aria-hidden="true"></i></button>
          </div>



        </div>
      </div>
      <div class="modal fade" id="notificationToView" role="dialog" data-backdrop="static" data-keyboard="false"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document"
          style="min-width:300px !important;height: 50% !important;">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Notification Viewer</h5>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="block inv-section">
                <div class="">
                  <div>
                    <img alt="" src="./assets/img/logo.png" style="border-radius: none !important;">
                  </div>
                </div>
                <div class="row text-center">
                  <div class="font-weight-medium mb-0 mt-1 text-center col-md-12">{{notifyToView?.objecttype
                    |titlecase}} </div>
                  <div class="text-muted mb-1 text-center text-small">{{notifyToView?.reason}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>


<div class="on-mobile">
  <footer class="footer-mobile ">
    <div class="container-fulid">
      <ul class="nav nav-pills nav-justified">
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/main-Appointments" data-toggle="tooltip" title=""
            data-original-title="Appointments">
            <span>
              <i class="nav-icon fa fa-calendar-alt m-col1"></i>
              <span class="nav-text">Appointments</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/patient-reports" data-toggle="tooltip" title=""
            data-original-title="Reports">
            <span>
              <i class="nav-icon fa fa-file-prescription m-col2"></i>
              <span class="nav-text">Reports</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/vitals-view" data-toggle="tooltip" title=""
            data-original-title="Vitals">
            <span>
              <i class="nav-icon fa fa-heartbeat m-col3"></i>
              <span class="nav-text">Vitals</span>
            </span>
          </a>
        </li>
        <li class="nav-item center-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/p-dashboard" data-toggle="tooltip" title=""
            data-original-title="Home">
            <span>
              <i class="nav-icon fa fa-home"></i>
              <span class="nav-text">Home</span>

            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/pat-history" data-toggle="tooltip" title=""
            data-original-title="History">
            <span>
              <i class="nav-icon fa fa-h-square m-col1"></i>
              <span class="nav-text">History</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/view-Orders" data-toggle="tooltip" title=""
            data-original-title="Orders">
            <span>
              <i class="nav-icon fa fa-people-carry m-col2"></i>
              <span class="nav-text">Orders</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/pat-settings" data-toggle="tooltip" title=""
            data-original-title="Profile">
            <span>
              <i class="nav-icon fa fa-user-cog m-col3"></i>
              <span class="nav-text">Profile</span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</div>
<div class="modal fade center" id="notificationViewerrr" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document"
    style="min-width:300px !important;height: 50% !important;margin-left: 39%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notification Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="block inv-section">
          <div class="">
            <div>
              <img alt="" src="./assets/img/logo.png" style="border-radius: none !important;">
            </div>
          </div>
          <div class="text-center">
            <div *ngFor="let notify of ViewedNotifications?.slice(0,1)">
              <p class="font-weight-medium mb-0 mt-1">{{notify.objecttype |titlecase}}
              </p>
              <p class="text-muted mb-1 text-small">{{notify.reason}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
