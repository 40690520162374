import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-caretaker-redirect-pages',
  templateUrl: './caretaker-redirect-pages.component.html',
  styleUrls: ['./caretaker-redirect-pages.component.css']
})
export class CaretakerRedirectPagesComponent implements OnInit {

  constructor(public router: Router) { }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  ngOnInit(): void {
    if (this.router.url == "/caretaker-succesfull-Registration") {
      document.getElementById("success").style.display = "block"
      document.getElementById("fail").style.display = "none"
    } else if (this.router.url == "/caretaker-Failure-Registration") {
      document.getElementById("fail").style.display = "block"
      document.getElementById("success").style.display = "none"
    }
  }

}
