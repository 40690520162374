import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClinicService } from '../clinic-pages/clinic.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-enquiry-form',
  templateUrl: './enquiry-form.component.html',
  styleUrls: ['./enquiry-form.component.css']
})
export class EnquiryFormComponent implements OnInit {
  queryForm:FormGroup;
  enquiryList: any;
  @Input() module: any;

  constructor(private fb: FormBuilder, private clinicServices: ClinicService, private router:Router,   private toastr:ToastrService, private route: ActivatedRoute ){ }
  returnUrl: string;

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      this.module = params['module'];
      this.returnUrl = params['returnUrl'];


    });

    this.queryForm=this.fb.group({
      cellNumber: ['',[  Validators.required]],
     contactName: [''],
     emailId: ['',[  Validators.required ]],
     entityName: [''],
     licenceNumber: ['',[  Validators.required ]],
     query: ['',[  Validators.required ]],
     module:this.module
 
 });
 
  }
  saveData(){

    this.clinicServices.hospitalEnquire(this.queryForm.value).subscribe(res => {
      this.enquiryList = res?.['data'];
      this.toastr.success('Your Enquire Is Successfully Reached Us')
      this.queryForm.reset()
   this.router.navigate([this.returnUrl]);
      
    },
      error => {
        this.toastr.error('Error:', error?.error?.data?.message);
      })

  }
  
  getData() {

    this.router.navigate([this.returnUrl]);

    
  }

}
