import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms'
import { DoctorService } from '../../AppServices/doctor.service'
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmationComponent } from '../../DoctorPages/delete-confirmation/delete-confirmation.component';
import * as moment from 'moment';
import { Router } from '@angular/router';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-doctor-professional-details',
  templateUrl: './doctor-professional-details.component.html',
  styleUrls: ['./doctor-professional-details.component.css']
})
export class DoctorProfessionalDetailsComponent implements OnInit {
  maxDate
  minDate
  basicDetails: FormGroup;
  beforeData; specilization
  feeDetails: FormGroup;
  expLength = 2;
  dotTimes = 0;
  @Output() childComponentValue = new EventEmitter<any>();
  constructor(private globalStorage: GlobalStorageService, public dialog: MatDialog, private fb: FormBuilder,private sanitizer: DomSanitizer, private DoctorService: DoctorService, private ToastrService: ToastrService, private forgot: AuthService,
    private router: Router) {

    var d = new Date();
    this.minDate = new Date(d);
    this.maxDate = new Date(d);
    this.basicDetails = this.fb.group({
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      gender: ['', Validators.required],
      //totalExperience: [, [Validators.required, Validators.max(50), Validators.min(1), Validators.minLength(2), Validators.maxLength(5)]],
      medicalLicenceNumber: [],
      // medicalLicenceExpiryDate: ["11/30/2020"],
      dateOfExpiry: ['', Validators.required],
      specialization: ['', Validators.required],
      cellNumber: [, [Validators.required, Validators.pattern(new RegExp("[0-9]{10}"))]],
      emailId: ['', [Validators.required, Validators.email]]
    });

    // this.feeArray = this.fb.array([]);

    this.feeDetails = this.fb.group({
      feeDetailsId: [],
      doctorId: [parseInt(this.globalStorage.getItem('doctorId'))],
      type: ['', [Validators.required]],
      fee: [, [Validators.required]],
    })

  }

  feeDtsMessage
  loginUser
  feeArray: FormArray;
  ngOnInit(): void {
    var data = JSON.parse(this.globalStorage.getItem('LoginDetails'));
    this.loginUser = data.data;
    this.LoadDoctor();
    this.getDoctorfeeDetails();
    // this.basicDetails.addControl('fee', this.feeArray);
  }

  // addFee():FormGroup{
  //   return
  // }


  get f() { return this.basicDetails.controls; }
  submitted: boolean = false;

  profileDetails; isFemale
  dateofExpiryLicence;
  LoadDoctor() {
    this.DoctorService.getDoctorDetailsById(this.loginUser.doctorId).subscribe(res => {
      this.profileDetails = res['data']
      if(this.profileDetails.dateOfExpiry!=null){
        this.dateofExpiryLicence = formatDate(this.profileDetails.dateOfExpiry, "yyyy-MM-dd'T'HH:mm:ss.SSS", "en-us")
        this.basicDetails.controls['dateOfExpiry'].patchValue(this.dateofExpiryLicence);
      }
      
      if (this.profileDetails?.gender == 'Female' || this.profileDetails?.gender == 'female') {
        $("#female").prop("checked", true);
      }
      else {
        $("#male").prop("checked", true);
      }
      this.basicDetails.controls['firstName'].patchValue(this.profileDetails?.firstName);
      this.basicDetails.controls['middleName'].patchValue(this.profileDetails?.middleName);
      this.basicDetails.controls['lastName'].patchValue(this.profileDetails?.lastName);
      this.globalStorage.setItem('PrimaryNumber', this.profileDetails?.cellNumber)
      this.basicDetails.controls['cellNumber'].patchValue(this.profileDetails?.cellNumber);
      this.basicDetails.controls['emailId'].patchValue(this.profileDetails?.emailId);
      this.basicDetails.controls['specialization'].patchValue(this.profileDetails?.specialization);
      this.basicDetails.controls['medicalLicenceNumber'].patchValue(this.profileDetails?.medicalLicenceNumber);
      
      //this.basicDetails.controls['totalExperience'].patchValue(this.profileDetails?.totalExperience);
      this.basicDetails.controls['gender'].patchValue(this.profileDetails?.gender);
      // this.LoadPersonalDetails(this.profileDetails.personaldetail);
      //this.BindCertificationDetails(this.profileDetails);
      // this.BindAdress(this.profileDetails.doctorAddresses);
      // this.BindFeeDetails(this.profileDetails);
      //this.viewProfilePic(this.profileDetails)
      this.beforeData = this.basicDetails.value
    }, error => {
      this.ToastrService.error("Error in  getting doctor Details")
    })

  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  isNumberKey(evt): boolean {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  sendToParent() {
    this.childComponentValue.emit(this.feeDtsMessage);
  }

  clearFeeDetails(){
    this.feeDetails.reset();
    this.feesubmitted = false;
  }

  SaveDoctorDetails() {

    this.submitted = true;
    if (this.basicDetails.invalid) {
      return
    }

    const dateOfExpiry = formatDate(this.basicDetails.value.dateOfExpiry, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    var d1 = moment(dateOfExpiry);

    var d2 = moment(new Date());
    var days = moment.duration(d1.diff(d2)).asDays();
    var datediff = parseInt(days.toFixed(0));
    if (datediff <= 7) {
      this.ToastrService.error("date of expiry should not be in a week")
      return
    }

    var doctor = {
      "activatedDate": null,
      "active": 'Y',
      "associations": null,
      "averagerating": 0,
      "cellNumber": this.basicDetails.value.cellNumber,
      "certifications": null,
      "dateOfExpiry": dateOfExpiry.concat('Z'),
      "doctorAddresses": null,
      "doctorId": this.loginUser.doctorId,
      "emailId": this.basicDetails.value.emailId,
      "emailVerificationLink": "",
      "feedetails": null,
      "firstName": this.basicDetails.value.firstName,
      "gender": this.basicDetails.value.gender,
      "homeNumber": "",
      "lastName": this.basicDetails.value.lastName,
      "locked": null,
      "medicalLicenceNumber": this.basicDetails.value.medicalLicenceNumber,
      "middleName": this.basicDetails.value.middleName,
      "officeNumber": "",
      "password": "",
      "personaldetail": null,
      "primaryContact": "CELL_NUMBER",
      "retry": 0,
      "specialization": this.basicDetails.value.specialization,
      //"totalExperience": this.basicDetails.value.totalExperience,
    }

    this.DoctorService.updateDoctorProfile(doctor).subscribe(res => {
      this.isEdit = false
      this.LoadDoctor();
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
      this.ToastrService.success("Details saved.")
     this.globalStorage.setItem('doctorFirstName',this.basicDetails.value.firstName)
     this.globalStorage.setItem('doctorLastName',this.basicDetails.value.lastName)
      this.globalStorage.setItem('doctorGender', JSON.stringify(this.basicDetails.value.gender == 'male' ? 'male' : 'Female'))
    },
      error => {
        this.ToastrService.error("Error in saving details. Try again.")
      })

  }



  isAdd: boolean = false;
  addNewFee() {
    this.isAdd = true;
  }
  closeFee() {
    this.isAdd = false;
    this.feeDetails.reset();
  }

  deleteFee(row) {
    let active;
    if (row.active == 'Y') {
      active = 'N'
    } else {
      active = 'Y'
    }
    const dialogRef = this.dialog.open(DeleteConfirmationComponent,{
      data: {
        status:active
      }
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result['data'] == 'YES') {
        if (row.feeDetailsId != null) {
          this.DoctorService.deleteDoctorFee(row.feeDetailsId, active).subscribe(res => {
            this.getDoctorfeeDetails();
            if(active=='Y'){
              this.ToastrService.success("Fee details activated.")
            }
            else{
              this.ToastrService.success("Fee details deactivated.")
            }
          },
            error => {
              this.ToastrService.error("Error in deleting fee. Try again.")
            })
        }
      }
    });


  }
  feesubmitted: boolean = false

  get fe() { return this.feeDetails.controls; }



  saveFee() {
    this.feesubmitted = true;
    if (this.feeDetails.invalid) {
      return
    }

    if (this.feeDetails.value.fee > 5000 || this.feeDetails.value.fee < 0) {
      this.ToastrService.warning("Enter valid amount. Amount should be between 0-5000");
      return
    }
    this.isFeeExist = true
    var fee = {
      "active": 'Y',
      "doctorId": parseInt(this.globalStorage.getItem('doctorId')),
      "type": this.feeDetails.value.type,
      "fee": this.feeDetails.value.fee,
      "feeDetailsId": this.feeDetails.value.feeDetailsId,
    }
    this.SavecheckFeeExist({ "type": this.feeDetails.value.type })

    if (this.isFeeExist) {
      this.DoctorService.saveFee(fee).subscribe(res => {
        this.getDoctorfeeDetails();
        this.feesubmitted = false;
        this.feeDetails.reset();
        this.feeDetails.controls['type'].setValue('')
        this.ToastrService.success("Fee details saved.")
        this.globalStorage.setItem('isFeeDetailCompleted','Y')
        $('#add-fee').modal('hide')
      }, error => {
        this.ToastrService.error("Error in saving fee details.")
      })
    }

  }



  SavecheckFeeExist(obj) {
    obj
    var saveObj = JSON.stringify(obj)
    /// var count=0
    if (this.feeList == null) {
      this.isFeeExist = true;
    } else {
      for (var i = 0; i < this.feeList?.length; i++) {
        var Obj1 = { 'type': this.feeList?.[i]?.type }

        var arrayObj = JSON.stringify(Obj1)
        var saveObjUpperCase = saveObj.toUpperCase()
        var arrayObjUpperCase = arrayObj.toUpperCase()

        if (saveObjUpperCase == arrayObjUpperCase) {
          this.isFeeExist = false
          this.ToastrService.warning("Fee details already exists.")
          break
        }
      }
    }
  }

  UpdatecheckFeeExist(obj) {
    var saveObj = JSON.stringify(obj)
    var count = 0

    for (var i = 0; i < this.feeList?.length; i++) {

      var Obj1 = { 'type': this.feeList?.[i]?.type }


      var arrayObj = JSON.stringify(Obj1)
      var saveObjUpperCase = saveObj.toUpperCase()
      var arrayObjUpperCase = arrayObj.toUpperCase()


      if (saveObjUpperCase == arrayObjUpperCase) {
        count = count + 1
        if (count > 1) {
          this.isFeeExist = false
          this.ToastrService.warning("Fee details already exists")
          break
        }
      }

    }
  }

  feeList: any[]
  feeList1: any[]
  entityFees:any[]
  isFeeExist: boolean = true;
  comparefreeList: string[] = []
  getDoctorfeeDetails() {
    this.DoctorService.getDoctorFeeDetailsById(parseInt(this.globalStorage.getItem('doctorId'))).subscribe(res => {

      this.feeList = res['data']['doctorsFee']
      this.feeList1 = res['data']['doctorsFee']
      this.entityFees= res['data']['entityFees']
      if (this.feeList == null || this.feeList == undefined || this.feeList?.length==0) {
        this.feeDtsMessage = "* Fill fee Details "
      }
      else {
        this.feeDtsMessage = " "
      }
      function removeDuplicates(array, key) {
        let lookup = new Set();
        return array?.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
      }
      this.feeList = removeDuplicates(this.feeList, 'type');

      this.comparefreeList.length = 0;
      for (var i = 0; i < this.feeList1?.length; i++) {
        var myObj = JSON.stringify(this.feeList1[i])
        this.comparefreeList.push(myObj)
      }

    }, error => {
      this.ToastrService.error("Error retrieving fee details.")
    })
  }

  btnEdit(index) {
    $('.feetr_view_' + index).hide();
    $('.feetr_edit_' + index).show();
  }

  checkForm(item) {
    if (this.profileDetails != undefined) {
      if (item.value.firstName != this.beforeData.firstName ||
        item.value.middleName != this.beforeData.middleName ||
        //item.value.totalExperience != this.beforeData.totalExperience ||
        item.value.dateOfExpiry != this.beforeData.dateOfExpiry || item.value.gender ||
        item.value.specialization != this.beforeData.specialization) {
        this.isSave = false
      } else {
        this.isSave = true
      }
    }
  }
  resetForm() {
    this.basicDetails.patchValue({
      middleName: this.beforeData.middleName,
      //totalExperience: this.beforeData.totalExperience,
      dateOfExpiry: this.beforeData.dateOfExpiry,
      firstName: this.beforeData.firstName,
      lastName: this.beforeData.lastName,
      gender: this.beforeData.gender,
      medicalLicenceNumber: this.beforeData.medicalLicenceNumber,
      specialization: this.beforeData.specialization,
      cellNumber: this.beforeData.cellNumber,
      emailId: this.beforeData.emailId
    })
  }

  isEdit: boolean = false;
  isDisabled: boolean = true;
  isSave
  Edit() {
    this.isEdit = true;
    this.isDisabled = false;
    this.isSave = true;
  }

  genderChanged() {
    this.isSave = false;
  }

  fee

  updateFee(item, index) {
    this.isFeeExist = true
    if (item.fee == undefined || item.fee == "") {
      this.ToastrService.error("Enter fee details.")
      return
    }
    if (item.fee > 5000 || item.fee < 0) {
      this.ToastrService.warning("Enter valid amount. Amount should be between 0-5000");
      return
    }

    this.UpdatecheckFeeExist({ "type": item.type })

    if (this.isFeeExist) {
      var fee = {
        "active": 'Y',
        "doctorId": parseInt(this.globalStorage.getItem('doctorId')),
        "type": item.type,
        "fee": item.fee,
        "feeDetailsId": item.feeDetailsId,
      }

      this.DoctorService.saveFee(fee).subscribe(res => {
        // this.getDoctorfeeDetails();
        $('.feetr_view_' + index).show();
        $('.feetr_edit_' + index).hide();
        //this.feeDetails.reset();

        this.ToastrService.success("Fee details saved.")
      }, error => {
        this.ToastrService.error("Error in saving fee details.")
      })
    }
  }

  cancelFee(index) {
    $('.feetr_view_' + index).show();
    $('.feetr_edit_' + index).hide();
    this.getDoctorfeeDetails();
  }

  closeFeeSave() {
    this.feeDetails.reset();
    this.feeDetails.controls['type'].patchValue("");
    this.feesubmitted = false;
  }
  formateChange(value) {
    // let date = new Date(value);
    // const day = date.toLocaleString('default', { day: '2-digit' });
    // const month = date.toLocaleString('default', { month: 'short' });
    // const year = date.toLocaleString('default', { year: 'numeric' });
    // this.isSave = false;
    // return true;
  }


}
