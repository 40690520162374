import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-save-supplier',
  templateUrl: './save-supplier.component.html',
  styleUrls: ['./save-supplier.component.css']
})
export class SaveSupplierComponent implements OnInit {

  Assignsupform: FormGroup;
  submitted: boolean = false;
  options: string[] = ['Discount on price (%)', 'Addtional Items Offer'];
  gstOptions: string[] = ["GST", "I-GST"];
  showDiscount: boolean = false;
  showOffer: boolean = false;
  selectedOption: any;
  @Input() subcategory: any;
  @Input() addressId: any;
  @Input() service: any;
  @Input() editedAssignedSupplierObj: any;
  @Input() isAssignedSupplierEdit: boolean = false;
  @Input() assignTime: boolean = false;
  @Input() editTime: boolean = false;
  @Input() supplier;
  @Input() supplierCategory;
  minDate;

  constructor(private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private inventoryService: InventoryService) {
    this.minDate = new Date();
  }

  ngOnInit(): void {
    this.initAssignsupform();
    if (this.assignTime) {
      // console.log('my data:', this.supplierCategory);

      this.showSet = this.supplierCategory?.deal;
      let dealdate = formatDate(this.supplierCategory?.dealFromTime, "yyyy-MM-dd", "en-us");
      let deaToDat = formatDate(this.supplierCategory?.dealToTime, "yyyy-MM-dd", "en-us");
      this.Assignsupform.patchValue({
        Supplier: this.supplier?.name,
        category: this.supplierCategory?.catagoryname,
        subcategory: this.supplierCategory?.itemTypeName,
        price: this.supplierCategory?.unitPrice,
        deal: this.supplierCategory?.deal,
        dealFromDate: dealdate,
        dealToDate: deaToDat,
        discount: this.supplierCategory?.discount,
        buyQuantity: this.supplierCategory?.buyQuantity,
        freeQuantity: this.supplierCategory?.freeQuantity,
      });

      if (this.supplierCategory?.discount) {
        this.selectedOption = 'Discount on price (%)'
        this.showDiscount = true;
        this.showOffer = false;
      }
      if (this.supplierCategory?.buyQuantity && this.supplierCategory?.freeQuantity) {
        this.selectedOption = 'Addtional Items Offer'
        this.showDiscount = false;
        this.showOffer = true;
      }
    }

    if (this.isAssignedSupplierEdit) {
      this.supplierIdfromObj = this.editedAssignedSupplierObj?.supplierId;
      this.showSet = this.editedAssignedSupplierObj?.deal;
      var dealFromDate = formatDate(this.editedAssignedSupplierObj?.dealFromTime, "yyyy-MM-dd", "en-us");
      var dealToDate = formatDate(this.editedAssignedSupplierObj?.dealToTime, "yyyy-MM-dd", "en-us");
      this.Assignsupform.patchValue({
        category: this.editedAssignedSupplierObj?.catagory || this.editedAssignedSupplierObj?.catagoryname,
        subcategory: this.editedAssignedSupplierObj?.itemName || this.editedAssignedSupplierObj?.itemTypeName,
        Supplier: this.editedAssignedSupplierObj?.supplierName,
        supplierID: this.editedAssignedSupplierObj?.supplierId,
        price: this.editedAssignedSupplierObj?.unitPrice,
        deal: this.editedAssignedSupplierObj?.deal,
        dealFromDate: dealFromDate,
        dealToDate: dealToDate,
        discount: this.editedAssignedSupplierObj?.discount,
        buyQuantity: this.editedAssignedSupplierObj?.buyQuantity,
        freeQuantity: this.editedAssignedSupplierObj?.freeQuantity
      });

      if (this.editedAssignedSupplierObj?.discount || this.supplierCategory?.discount) {
        this.selectedOption = 'Discount on price (%)'
        this.showDiscount = true;
        this.showOffer = false;
      }
      if (this.editedAssignedSupplierObj?.buyQuantity && this.editedAssignedSupplierObj?.freeQuantity) {
        this.selectedOption = 'Addtional Items Offer'
        this.showDiscount = false;
        this.showOffer = true;
      }
    }

    this.getSuppliers();
    // this.getActiveCategories();
  }

  initAssignsupform() {
    this.Assignsupform = this.formBuilder.group({
      Supplier: ['', [Validators.required]],
      category: [''],
      subcategory: [''],
      supplierID: [''],
      price: ['', Validators.required],
      deal: [''],
      dealFromDate: [''],
      dealToDate: [''],
      discount: [''],
      buyQuantity: [''],
      freeQuantity: ['']
    })
  }

  get asnf() { return this.Assignsupform.controls; }

  closeModal() {
    this.activeModal.close(true);
  }

  showDealSection(){
  }

  suplier;
  getSuppliers() {
    var body = {
      "flag": "Y",
      "addressId": this.addressId
    }
    this.inventoryService.suplier(body).subscribe(res => {
      this.suplier = res?.['data']?.filter(data => data?.active == "Y");
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  actCategory;
  getActiveCategories() {
    this.inventoryService.allCategoryList().subscribe(res => {
      this.actCategory = res?.data;
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  actSubCategory;
  getActivesubCategories(cat) {
    this.inventoryService.allsubCategoryList(cat?.catagoryId).subscribe(res => {
      this.actSubCategory = res?.data;
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  supplierSelected(event) {
    this.Assignsupform.patchValue({
      supplierId: event.value.supplierId
    });
  }

  categorySelected(event) { }

  subcategorySelected(event) { }

  showSetOffer: boolean = false;
  showSet: any;

  checkBoxCheck(a, e) {
    a.value.toUpperCase()
    if (this.isAssignedSupplierEdit) {
      this.Assignsupform.patchValue({
        // dealFromDate: this.editedAssignedSupplierObj?.dealFromTime,
        // dealToDate: this.editedAssignedSupplierObj?.dealToTime,
        discount: this.editedAssignedSupplierObj?.discount,
        buyQuantity: this.editedAssignedSupplierObj?.buyQuantity,
        freeQuantity: this.editedAssignedSupplierObj?.freeQuantity
      });
    }
    if (this.assignTime) {
      this.Assignsupform.patchValue({
        discount: this.supplierCategory?.discount,
        buyQuantity: this.supplierCategory?.buyQuantity,
        freeQuantity: this.supplierCategory?.freeQuantity
      });
    }
    if (a.value == 'Discount on price (%)') {
      this.selectedOption = a.value
      this.showDiscount = true;
      this.showOffer = false;
      this.Assignsupform.get('buyQuantity').setValue(null)
      this.Assignsupform.get('freeQuantity').setValue(null)
      // this.Assignsupform.controls['discount'].setValidators([Validators.required, Validators.minLength(1)])
      // this.Assignsupform.controls['buyQuantity'].clearValidators()
      // this.Assignsupform.controls['freeQuantity'].clearValidators()
    }

    if (a.value == 'Addtional Items Offer') {
      this.selectedOption = a.value
      this.showDiscount = false;
      this.showOffer = true;
      this.Assignsupform.get('discount').setValue(null)
      // this.Assignsupform.controls['freeQuantity'].setValidators([Validators.required, Validators.minLength(1)])
      // this.Assignsupform.controls['buyQuantity'].setValidators([Validators.required, Validators.minLength(1)])
      // this.Assignsupform.controls['discount'].clearValidators()
    }
  }

  supplierIdfromObj;
  getsupplierIdbyClick(supplier) {
    this.supplierIdfromObj = supplier?.supplierId;
  }

  dealFrDate;dealToDateee;
  saveAssigntable() {
    if(this.Assignsupform?.value?.dealFromDate){
      this.dealFrDate = formatDate(this.Assignsupform?.value?.dealFromDate, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    }
    if(this.Assignsupform?.value?.dealToDate){
      this.dealToDateee = formatDate(this.Assignsupform?.value?.dealToDate, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    }
    var body = {
      "active": 'Y',
      "categryId": this.subcategory?.catagoryId || this.supplierCategory?.categryId,
      "itemTypeId": this.subcategory?.itemTypeId || this.supplierCategory?.itemTypeId || this.editedAssignedSupplierObj?.itemTypeId,
      "supplierId": this.supplierIdfromObj || this.supplierCategory?.supplierId,
      "supplierName": this.Assignsupform?.value?.Supplier,
      "unitPrice": this.Assignsupform?.value?.price,
      "deal": this.Assignsupform?.value?.deal,
      "dealFromTime": this.dealFrDate ? this.dealFrDate : null,
      "dealToTime": this.dealToDateee ? this.dealToDateee : null,
      "discount": this.Assignsupform?.value?.discount,
      "buyQuantity": this.Assignsupform?.value?.buyQuantity,
      "freeQuantity": this.Assignsupform?.value?.freeQuantity,
      "addressId": this.addressId
    }
    if (this.isAssignedSupplierEdit) {
      body['suppliercatgeryId'] = this.editedAssignedSupplierObj?.suppliercatgeryId
    }

    this.inventoryService.savesuppliercategory(body).subscribe(res => {
      this.isAssignedSupplierEdit ? this.toaster.success("Supplier Updated.") : this.toaster.success("Supplier Added.");
      this.Assignsupform.reset();
      this.closeModal();
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      })

  }

}
