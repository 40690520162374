import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';

@Component({
  selector: 'app-add-manufacturer',
  templateUrl: './add-manufacturer.component.html',
  styleUrls: ['./add-manufacturer.component.css']
})
export class AddManufacturerComponent implements OnInit {

  @Input() addressId;
  @Input() service;
  @Input() ManufacturerObj;
  @Input() isManufEdit: boolean = false;
  manufacturForm: FormGroup;
  public submitted: boolean = false;

  constructor(private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private inventoryService: InventoryService) { }

  ngOnInit(): void {
    this.initializeForm();

    if(this.isManufEdit){
      this.manufacturForm.patchValue({
        manufacturer : this.ManufacturerObj?.manufacturer
      })
    }
  }

  initializeForm() {
    this.manufacturForm = this.formBuilder.group({
      manufacturer: ['', [Validators.required]]
    });
  }

  get mControls() {
    return this.manufacturForm.controls;
  }

  closeModal() {
    this.activeModal.close(true);
  }

  onSaveManufacturerData() {
    this.submitted = true;
    if (this.manufacturForm.invalid) {
      return false;
    }
    else {
      let body = {
        "addressId": this.addressId,
        "manufacturer": this.manufacturForm.value.manufacturer
      }
      if (this.isManufEdit) {
        body["manufacturerId"] = this.ManufacturerObj?.manufacturerId
      }
      this.inventoryService.saveManufacturer(body).subscribe(res => {
        this.isManufEdit ? this.toaster.success('Manufacturer Details Updated successfully.') : this.toaster.success('Manufacturer added successfully.');
        this.submitted = false;
        this.closeModal();
        this.manufacturForm.reset();
      },
        error => {
          this.toaster.error(error?.['error']?.['message']);
        }
      )
    }
  }

}
