<div class="modal-header">
    <h3>Template</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row"  [formGroup]="templateForm">
        <div class="col-md-12 mb-2">
            <label for="templateName">Template Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control" formControlName="templateName">
        </div>
        <div class="col-md-12 mb-2">
            <label for="templateName">Discription</label>
            <textarea autoResizeRows class="form-control " name="message" placeholder="message"
            formControlName="description"></textarea>
        </div>
    </div>
    <div class="row float-right text-right">
        <button class="btn btn-success bt-xs" (click)="saveTemplate()">Save</button>
    </div>
</div>
