import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { GlobalStorageService } from 'src/app/global-storage.service';

@Component({
  selector: 'app-ambulance-history',
  templateUrl: './ambulance-history.component.html',
  styleUrls: ['./ambulance-history.component.css']
})
export class AmbulanceHistoryComponent implements OnInit {
  @Input() ambulanceInfo:any
  history;p4=1;
  constructor(private globalStorage: GlobalStorageService, public activeModal: NgbActiveModal,private clinicServices:ClinicService,
    private toastr: ToastrService, ) { }

  ngOnInit(): void {
    this.getAmbulanceHistory()
  }

  getAmbulanceHistory(){
    this.clinicServices.getAmbulanceHistoryByAmbulanceNumber(this.ambulanceInfo?.ambulanceNumber,this.globalStorage.getItem('hospitalAddressId')).subscribe(res=>{
      this.history=res['data']
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
}
