<div class="modal-content">
    <div class="modal-header">
      <h3>Patient Details</h3>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true" (click)="closePatModal()">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="schedule-tab ">
            <div class=" ">

              <div class="row backgound_imgsidebar mb-3 hosp">

                <div class="col-md-6 col-sm-12">
                  <div class="row text_left padding_left20 padding_bt">


                    <div class="col-md-12 col-sm-12 textbig_14white pb-1"> Name :
                      <span>{{patientPersonalDetails?.patientName}}</span>
                    </div>

                    <div class="col-md-12 col-sm-12 textbig_14white pb-1"
                      *ngIf="doctoraptime && patientPersonalDetails?.AppointmentDetails?.fromTime"> Time :
                      <span>{{patientPersonalDetails?.AppointmentDetails?.latestAppointmentDate | date:'dd-MMM-yyyy'}}
                        {{patientPersonalDetails?.AppointmentDetails?.fromTime|convertFrom24To12Format}} </span> - <span>
                        {{patientPersonalDetails?.AppointmentDetails?.toTime|convertFrom24To12Format}} </span>
                    </div>
                    <div class="col-md-12 col-sm-12 textbig_14white pb-1" *ngIf="hospitalaptime"> Time :
                      <span>
                        {{patientPersonalDetails?.AppointmentDetails?.lastUpdatedTs| date:'dd-MMM-yyyy'}}</span>
                    </div>

                    <div class="col-md-12 col-sm-12 textbig_14white pb-1"
                      *ngIf="patientPersonalDetails?.isIPD=='Y' || patientPersonalDetails?.isIPD=='N'"> Time :
                      <span>
                        {{patientPersonalDetails?.checkInTime | date:'dd-MMM-yyyy'}}</span>
                    </div>

                  </div>
                </div>


                <div class="col-md-3 col-sm-4">
                  <div class="row ">
                    <div class="col-md-12 col-sm-12 textbig_14white pb-1"> Gender : <span
                        style="padding-left:16px;">{{patientPersonalDetails?.gender}} </span></div>
                    <div class="col-md-12 col-sm-12 textbig_14white pb-1"> Age : <span
                        style="padding-left:37px;">{{patientPersonalDetails?.age}}</span></div>
                  </div>

                </div>




                <div *ngIf="patientPersonalDetails?.Appointment=='Current'"  class="col-md-1 col-sm-4">
                  <div class="row ">
                    <div class="col-sm-12 col-md-12 text_center">
                      <a href="javascript:void(0)" style="display:none" (click)="OpenVideoCalling(patientPersonalDetails)"
                        id="vdo" data-placement="top" data-toggle="tooltip" data-original-title="Join Now"><img
                          src="assets/img/video_01.png"> </a>
                    </div>

                  </div>
                </div>

              </div>











              <!-- Slot listing 5 end -->
            </div>

            <ul class="nav nav-tabs nav-stacked " id="nav-tabs">
              <li class="active" >
                <a id="startTab" class="animated fadeIn active show dtsTab" href="#patdetails" data-toggle="tab"
                  (click)="tabPatientDetails()">
                  <i class="fa fa-user themecold5 icon-head"></i><br>
                  <span class="tab-head">
                    <span class="tab-text-title">Patient Details</span>
                  </span>
                </a>
              </li>

              <li>
                <a class="animated fadeIn medicalhistory" href="#medicalhistory" data-toggle="tab" (click)="tabMedicalHistory()">
                  <i class="fa fa-file-medical-alt themecold5 icon-head"></i><br>
                  <span class="tab-head">
                    <span class="tab-text-title">Medical History</span>
                  </span>
                </a>
              </li>


<!--
              <li >
                <a class="animated fadeIn previousapp" href="#previousapp" data-toggle="tab"
                  (click)="tabPrevious(patientPersonalDetails?.AppointmentType)">
                  <i class="fa fa-calendar-check-o themecold5 icon-head"></i><br>
                  <span class="tab-head">
                    <span class="tab-text-title" *ngIf="patientPersonalDetails?.AppointmentType!='Previous'"> Previous
                      Appointments</span>
                  </span>
                </a>
              </li> -->

            </ul>

            <div class="tab-content pl-3 pr-3 pt-1">
              <div class="tab-pane active myclick" id="patdetails">
                <div class="row">
                    <div class="col-md-12" style="display: none;" *ngIf="activeMedicationList?.length != 0"><h6 class="font_Poppins  p_14">Current Activity Medication   </h6>
                   </div>

                  <div class="row ml-2">
                  <div *ngFor="let row of activeMedicationList;let i =index">
                    <div class=" d-flex flex-column flex-grow-1 w-100  blt-2 mb-2 card">
                    <a href="javascript:void(0)" class="float-left">
                    <p class="font-weight-medium p_btm line_height float-left  pt-1 d-inline-block text-truncate text-nowrap  overflow-hidden ">

                      <!-- style="text-overflow: ellipsis;width: 100px;" -->

                     <span class="mt-2 text-muted p-1 "> {{row.medicineName | titlecase }} </span>
                     <span class="text-muted text-small">{{row.power}}</span>
                      </p>
                     <div class=" float-right">
                      <button class="btn btn-sm  p-1"><i class="fa fa-pills themecol1    icon-head" aria-hidden="true"></i></button>

                  </div>
                    </a>


                    </div>

              </div>
              </div>

                      </div>
                      <app-vitals [patientVitals]="patientVitals"></app-vitals>
                      <!-- <div class="row">
                        <div class="col-md-12">
                          <h6 class=" font_Poppins  p_14">Latest Vitals </h6>
                        </div>

                        <div class="col-md-12 mb-2">
                          <div class="row">
                            <div class="col-md-3 cursor-pointer" (click)="showUserVitals()">
                              <a href="javascript:void(0)" class="btn btn-sm ve vitals-b1 w-100 curser-null"> <i
                                  class="fas fa-heartbeat faa-pulse animated faa-fast" aria-hidden="true"></i> <span>Heart Rate<div
                                    class="clear"> </div>
                                  <p class="m-0">{{heartRate?.vitalValue |split2}} <sup> BPM </sup></p>
                                </span></a>
                            </div>

                            <div class="col-md-3 cursor-pointer" (click)="showUserVitals()">
                              <a href="javascript:void(0)" class="btn btn-sm  ve vitals-b1 w-100 curser-null"> <i
                                  class="fas fa-arrows-alt-v faa-pulse animated patientc5 faa-fast fa-2x" aria-hidden="true"></i> <span>Height


                                  <div class="clear"> </div>
                                  <p class="m-0">{{height?.vitalValue}} <sup> Feet </sup></p>
                                </span></a>
                            </div>

                            <div class="col-md-3 cursor-pointer" (click)="showUserVitals()">
                              <a href="javascript:void(0)" class="btn btn-sm ve vitals-b2 w-100 curser-null"> <i
                                  class="fa fa-weight faa-bounce animated" aria-hidden="true"></i> <span>Weight <div class="clear"> </div>
                                  <p class="m-0">{{weight?.vitalValue |split2}} <sup>Kg</sup></p>
                                </span></a>
                            </div>

                            <div class="col-md-3 cursor-pointer" (click)="showUserVitals()">
                              <a href="javascript:void(0)" class="btn btn-sm ve vitals-b4 w-100 curser-null"> <i
                                  class="fas fa-tachometer-alt"></i> <span>BMI
                                  <div class="clear"> </div>
                                  <p class="m-0">{{bmi}}</p>
                                </span></a>
                            </div>

                            <div class="col-md-3 cursor-pointer" (click)="showUserVitals()">
                              <a href="javascript:void(0)" class="btn btn-sm ve vitals-b3 w-100 curser-null  mt-2"> <i
                                  class="fa fa-male faa-shake animated" aria-hidden="true"></i> <span>Pressure


                                  <div class="clear"> </div>
                                  <p class="m-0">{{pulse?.vitalValue |slice:0:6 }} <sup>mm/Hg</sup></p>
                                </span></a>

                            </div>
                        

                            <div class="col-md-3 cursor-pointer" (click)="showUserVitals()">
                              <a href="javascript:void(0)" class="btn btn-sm ve vitals-b3 w-100 curser-null mt-2"> <i
                                  class="fas fa-thermometer-full patientc1 faa-pulse animated faa-fast fa-2x" aria-hidden="true"></i>
                                <span>Temperature


                                  <div class="clear"> </div>
                                  <p class="m-0">{{temp?.vitalValue}} <sup> F </sup></p>
                                </span></a>
                            </div>
                            <div class="col-md-3 cursor-pointer" (click)="showUserVitals()">
                              <a href="javascript:void(0)" class="btn btn-sm ve vitals-b4 w-100 curser-null mt-2"> <span
                                  class="patientc2 fa-2x mr-3" aria-hidden="true"> <b>O</b> <sub>2</sub></span> <span>Oxygen Level


                                  <div class="clear"> </div>
                                  <p class="m-0">{{oxygen?.vitalValue}} %</p>
                                </span></a>
                            </div>
                            <div class="col-md-3 cursor-pointer" (click)="showUserVitals()">
                              <a href="javascript:void(0)" class="btn btn-sm ve vitals-b2 w-100 curser-null mt-2"> <i
                                  class="fas fa-vial faa-pulse animated faa-fast patientc3 fa-2x" aria-hidden="true"></i> <span>Blood Glucose


                                  <div class="clear"> </div>
                                  <p class="m-0">{{fasting?.vitalValue}} | {{random?.vitalValue}} <sup>mg/dL</sup></p>
                                </span></a>
                            </div>
                          </div>





                        </div>
                      </div>
                      <div class="row" *ngIf="!isHospital">
                        <div class="col-3 mb-4">
                          <div class="card ">
                            <div class="card-body">
                              <h5 class="card-title">
                                <i class="fa fa-male themecol1 icon-head" aria-hidden="true"></i> Blood Pressure
                              </h5>
                              <div class="chart-height-p">
                                <div class="position-absolute card-top-buttons"><button class="btn icon-button" data-placement="top"
                                    data-toggle="tooltip" data-original-title="View"></button></div>
                                <div class="card-body oxy-font">
                                  <div class="row" style="display: none;">
                                    <span class="col-sm-6 mt-10" style="font-size: 12px;">Select Period : </span>
                                    <span class="col-sm-6">
                                      <select [(ngModel)]="selectedPressurePeriod" class="form-control1"
                                        style="font-size: 12px;position: absolute;right: 8%;width: 105%;"
                                        (change)="ChangeBloodPressureuePeriod(selectedPressurePeriod)">
                                        <option selected value="CURRENT_DATE">CURRENT_DATE</option>
                                        <option value="YESTERDAY">YESTERDAY</option>
                                        <option value="WEEK">WEEK</option>
                                        <option value="THIRTY_DAY">THIRTY_DAY</option>
                                        <option value="START_OF_MONTH">START_OF_MONTH</option>
                                        <option value="SIXTY_DAY">SIXTY_DAY</option>
                                        <option value="NINETY_DAY">NINETY_DAY</option>
                                      </select>
                                    </span>
                                  </div>
                                  <canvas baseChart class="chart-min-height-p " height="190" [datasets]="pressure" [labels]="pressurelabel"
                                    [options]="lineChartOptions" [colors]="pressurecol" [legend]="lineChartLegend" [chartType]="lineChartType"
                                    [plugins]="lineChartPlugins">
                                  </canvas>


                                </div>
                              </div>


                            </div>
                          </div>
                        </div>

                        <div class="col-3 mb-4">
                          <div class="card ">

                            <div class="card-body">
                              <h5 class="card-title"> <i class="fa fa-heartbeat themecol7 icon-head" aria-hidden="true"></i> Heart Rate</h5>

                              <div class="chart-height-p">
                                <div class="position-absolute card-top-buttons"><button class="btn icon-button" data-placement="top"
                                    data-toggle="tooltip" data-original-title="View"></button></div>
                                <div class="card-body oxy-font">

                                  <canvas baseChart class="chart-min-height-p " height="190" [datasets]="heartrate" [labels]="heartratelabels"
                                    [options]="lineChartOptions" [colors]="heartratecol" [legend]="lineChartLegend"
                                    [chartType]="lineChartType" [plugins]="lineChartPlugins">
                                  </canvas>

                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                        <div class="col-3 mb-4">
                          <div class="card ">

                            <div class="card-body">
                              <h5 class="card-title"> <i class="fa fa-temperature-high themecol3 icon-head" aria-hidden="true"></i>
                                Temperature</h5>

                              <div class="chart-height-p">
                                <div class="position-absolute card-top-buttons"><button class="btn icon-button" data-placement="top"
                                    data-toggle="tooltip" data-original-title="View"></button></div>

                                <div class="card-body  temp-font">

                                  <canvas baseChart class="chart-min-height-p " height="190" [datasets]="temparature"
                                    [labels]="temperaturelabels" [options]="lineChartOptions" [colors]="temparaturecol"
                                    [legend]="lineChartLegend" [chartType]="lineChartType" [plugins]="lineChartPlugins">
                                  </canvas>
                                </div>
                              </div>

                            </div>

                          </div>
                        </div>


                        <div class="col-3 mb-4">
                          <div class="card ">

                            <div class="card-body">
                              <h5 class="card-title"> <i class="fa fa-weight themecol2 icon-head" aria-hidden="true"></i> Weight</h5>
                              <div class="chart-height-p">
                                <div class="card-body  temp-font">
                                  <div class="row" style="display: none;">
                                    <span class="col-sm-6 mt-10" style="font-size: 12px;">Select Period : </span>
                                    <span class="col-sm-6">
                                      <select [(ngModel)]="selectedWeightPeriod" class="form-control1"
                                        style="font-size: 12px;position: absolute;right: 8%;width: 105%;"
                                        (change)="ChangeWeightPeriod(selectedWeightPeriod)">
                                        <option selected value="CURRENT_DATE">CURRENT_DATE</option>
                                        <option value="YESTERDAY">YESTERDAY</option>
                                        <option value="WEEK">WEEK</option>
                                        <option value="THIRTY_DAY">THIRTY_DAY</option>
                                        <option value="START_OF_MONTH">START_OF_MONTH</option>
                                        <option value="SIXTY_DAY">SIXTY_DAY</option>
                                        <option value="NINETY_DAY">NINETY_DAY</option>
                                      </select>
                                    </span>
                                  </div>
                                  <canvas baseChart class="chart-min-height-p " height="190" [datasets]="weightm" [labels]="weightlabel"
                                    [options]="lineChartOptions" [colors]="weightlabelcol" [legend]="lineChartLegend"
                                    [chartType]="lineChartType" [plugins]="lineChartPlugins">
                                  </canvas>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div> -->

              </div>


              <!-- Tab pane 1 end -->
              <div class="tab-pane active myclick2Tab" id="medicalhistory" *ngIf="isMedicalHistory">
                <app-medical-history *ngIf="isMedicalHistory" [patientPersonalDetails]="patientPersonalDetails">
                </app-medical-history>
                <!-- <div class="row activity">

                    <div class="col-md-8" style="display:none">
                    <div class="card ">
                    <div class="position-absolute card-top-buttons">
                    <button class="btn btn-header-light icon-button">
                    <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                    </button>
                    </div>
                    <div class="card-body">
                    <h5 class="card-title">  <i class="fa fa-pills themecol2 icon-head" aria-hidden="true"></i> Current Medication & Activity</h5>
                    <form>
                    <div>
                    <input type="radio" name="size" id="small" value="small" />
                    <label for="small" style="font-weight: 600 #eee ;">Sun</label>
                    <article class="mlm_10"><p>Sunday<span style="padding-left:65px;">Jan 7 </span> </p>
                    <div class="row pl_10">
                    <div class="col-md-6 border_right mlm_17">
                    <span style="border-right:1px solid #eee;"><span class="mt-2 float-left"> Morning </span>

                    </span>
                    </div><div class="col-md-6 border_right mlm_17"><span class="mt-2 float-left"> Evening </span>

                    </div>
                    </div>

                    </article>
                    </div>
                    <div>
                    <input type="radio" name="size" id="medium" value="medium" />
                    <label for="medium"  style="font-weight: 600 #eee ;">Mon</label>
                    <article class="mlm_10"><p>Morning<span style="padding-left:65px;">Evening</span> </p>
                    <div class="row pl_10">
                    <div class="col-md-6 border_right mlm_17">

                    <ul class="medication" >
                        <li *ngFor="let item of activeMedicationList" ><i class="fa fa-tablets"></i> {{item.medicineName}}</li>
                    </ul>



                    </div>

                    <div class="col-md-6 border_right mlm_17"><span class="mt-2 float-left"> Evening </span></div>
                    </div>

                    </article>
                    </div>
                    <div>
                    <input type="radio" name="size" id="large" value="large" />
                    <label for="large"  style="font-weight: 600 #eee ;">Tue</label>
                    <article class="mlm_10"><p>Tuesday<span style="padding-left:65px;">Jan 9 </span> </p>
                    <div class="row pl_10">
                    <div class="col-md-6 border_right mlm_17"><span style="border-right:1px solid #eee;"><span class="mt-2 float-left">
                    Morning </span> </span></div><div class="col-md-6 border_right mlm_17"><span class="mt-2 float-left"> Evening </span></div>
                    </div>

                    </article>
                    </div>
                    <div>
                    <input type="radio" name="size" id="xlarge" value="xlarge" />
                    <label for="xlarge"  style="font-weight: 600 #cecece ;">Wed</label>
                    <article class="mlm_10"><p>Wednesday<span style="padding-left:35px;">Jan 10 </span> </p>
                    <div class="row pl_10">
                    <div class="col-md-6 border_right mlm_17"><span style="border-right:1px solid #eee;"><span class="mt-2 float-left">
                    Morning </span> </span></div><div class="col-md-6 border_right mlm_17"><span class="mt-2 float-left"> Evening </span></div>
                    </div>

                    </article>
                    </div>
                    <div>
                    <input type="radio" name="size" id="xxlarge" value="xxlarge" />
                    <label for="xxlarge"  style="font-weight: 600 #cecece ;">Thu</label>
                    <article class="mlm_10"><p>Thursday<span style="padding-left:45px;">Jan 10 </span> </p>
                    <div class="row pl_10">
                    <div class="col-md-6 border_right mlm_17"><span style="border-right:1px solid #eee;"><span class="mt-2 float-left">
                    Morning </span> </span></div><div class="col-md-6 border_right mlm_17"><span class="mt-2 float-left"> Evening </span></div>
                    </div>

                    </article>
                    </div>
                    <div>
                    <input type="radio" name="size" id="xxxlarge" value="xxxlarge" />
                    <label for="xxxlarge"  style="font-weight: 600 #cecece ;">Fri</label>
                    <article class="mlm_10"><p>Friday<span style="padding-left:65px;">Jan 11 </span> </p>
                    <div class="row pl_10">
                    <div class="col-md-6 border_right mlm_17"><span style="border-right:1px solid #eee;"><span class="mt-2 float-left">
                    Morning </span> </span></div><div class="col-md-6 border_right mlm_17"><span class="mt-2 float-left"> Evening </span></div>
                    </div>

                    </article>
                    </div>
                    <div>
                    <input type="radio" name="size" id="xxxxlarge" value="xxxxlarge" />
                    <label for="xxxxlarge"  style="font-weight: 600 #cecece ;">Sat</label>
                    <article class="mlm_10"><p>Saturday<span style="padding-left:50px;">Jan 12 </span> </p>
                    <div class="row pl_10">
                    <div class="col-md-6 border_right mlm_17"><span style="border-right:1px solid #eee;"><span style="font-size:12px;"><br> Morning </span> </span></div><div class="col-md-6"><span > Evening </span></div>
                    </div>

                    </article>
                    </div>
                    </form>









                    </div>
                    </div>
                    </div>

                    <div class="col-md-4">
                    <div class="card  ">
                    <div class="position-absolute card-top-buttons">
                    <button class="btn btn-header-light icon-button">
                    </button>
                    </div>
                    <div class="card-body " style="min-height: 281px; ">
                    <h5 class="card-title">  <i class="fa fa-diagnoses themecol3 icon-head" aria-hidden="true"></i> Allergies</h5>
                    <div  p-2 *ngIf="medicalHistory?.socialhistory?.length == 0">
                        <div class="no-image-bg"></div>
                    </div>

                    <div class=" p-2" >
                    <div  *ngFor="let item of allergies" class=" d-flex flex-column flex-grow-1 text-left" >

                    <div class="d-flex flex-row bg__whiterounded5 mb-2">

                    <div class="pl-1 pr-2 d-flex flex-column flex-grow-1">
                    <a href="javascript:void(0)" style="pointer-events: none;">
                    <p class="font-weight-medium p_btm">{{item}}    <span class="text-muted text-small" style="float: right;"> </span> </p>
                    </a>
                    </div>
                    </div>

                    </div>
                    </div>




                    </div>
                    </div>
                    </div>

                  

                    <div class="col-md-4 ">

                        <div class="card ">
                        <div class="position-absolute card-top-buttons">
                        <button class="btn btn-header-light icon-button">
                       
                        </button>
                        </div>

                        <div class="card-body" style=" min-height: 281px; ">
                          <h5 class="card-title"> <i class="fa fa-history themecol4 icon-head" aria-hidden="true"></i> Family History</h5>
                          <div class="d-flex flex-row  p-2">
                              <div class="pl-1  d-flex flex-column flex-grow-1 text-left " style="width: 242px;">
                                  <div p-2 *ngIf="medicalHistory?.familyhistory?.length == 0">
                                      <div class="no-image-bg"></div>
                                  </div>
                                  <div class="d-flex flex-column bg__whiterounded6 mb-2" *ngFor="let item of medicalHistory?.familyhistory">
                                      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1  ">
                                          <p class="font-weight-medium p_btm">{{item.ailment}}<span class="text-muted text-small" style="float: right;">{{item.relationship}}</span> </p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                        </div>
                    </div>


                        <div class="col-md-4">
                            <div class="card">
                            <div class="position-absolute card-top-buttons">
                            <button class="btn btn-header-light icon-button">
                       
                            </button>
                            </div>
                            <div class="card-body" style="min-height: 281px; ">
                            <h5 class="card-title">  <i class="fa fa-history themecol2 icon-head" aria-hidden="true"></i> Addictions </h5>
                            <div class="d-flex flex-row  p-2">


                                <div   class="pl-1  d-flex flex-column flex-grow-1 text-left " style="width: 242px;">
                                    <div class="d-flex flex-column bg__whiterounded5 mb-2">
                                    <div class="pl-1 pr-2 d-flex flex-column flex-grow-1  ">

                                    <p class="font-weight-medium p_btm">Alcohol<span class="text-muted text-small" style="float: right;">{{questionairre?.alcohol | titlecase}}</span>  </p>

                                </div>

                            </div>
                                    <div class="d-flex flex-column bg__whiterounded5 mb-2">
                                        <div class="pl-1 pr-2 d-flex flex-column flex-grow-1  ">

                                        <p class="font-weight-medium p_btm">Drugs<span class="text-muted text-small" style="float: right;">{{questionairre?.drugs| titlecase}}</span>  </p>

                                    </div>

                                </div>
                                        <div class="d-flex flex-column bg__whiterounded5 mb-2">
                                            <div class="pl-1 pr-2 d-flex flex-column flex-grow-1  ">

                                            <p class="font-weight-medium p_btm">Tobacco<span class="text-muted text-small" style="float: right;">{{questionairre?.tobacco| titlecase}}</span>  </p>

                                    </div>

                                    </div>





                                    </div>








                            </div>
                            </div>


                            </div>
                            </div>



                            <div class="col-md-8 ">
                              <div class="card  mt-2">
                                  <div class="position-absolute card-top-buttons">
                                  </div>
                                  <div class="card-body" style=" min-height: 281px; ">
                                      <h5 class="card-title"> <i class="fa fa-history themecol1 icon-head" aria-hidden="true"></i> Medical Journal </h5>
                                      <div class="mt-2">
                                          <a href="javascript:void(0);" style="pointer-events: none;" *ngFor="let item of medicalHistory?.suregerydetails" class="button_one active">{{item.date | date: 'yyyy'}}</a>
                                          <div class="d-flex flex-row  p-2  ">
                                              <div class="pl-1  d-flex flex-column flex-grow-1 text-left">
                                                  <div *ngIf="medicalHistory?.suregerydetails?.length ==0">
                                                      <div class="no-image-bg"></div>
                                                  </div>
                                                  <div class="history-tl-container" *ngIf="medicalHistory?.suregerydetails.length > 0">
                                                      <ul class="tl">
                                                          <li *ngFor="let item of medicalHistory?.suregerydetails" class="tl-item">
                                                              <span class="mlm_5"><img src="assets/img/icon_01.png"></span>
                                                              <div class="history_bg mlm_3">
                                                                  <div class="item-title float-left">{{item.name}} </div>
                                                                  <div class="item-date text-right"> <span style=" color: #3e7dbf; padding-left:15px;"></span>{{item.date | date: 'dd-MMM-yyyy'}} </div>
                                                              </div>
                                                          </li>
                                                      </ul>
                                                  </div>
                                              </div>
                                            
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>







                    <div class="col-md-4 ">

                        <div class="card mt-2 ">
                        <div class="position-absolute card-top-buttons">

                        </div>
                        <div class="card-body" style="min-height: 281px;">
                        <h5 class="card-title">  <i class="fa fa-calendar-day themecol4 icon-head" aria-hidden="true"></i> Personal Medical History</h5>
                        <div class="d-flex flex-row  p-2">


                        <div   class="pl-1  d-flex flex-column flex-grow-1 text-left "  >
                            <div  p-2 *ngIf="personalMedicalHistory == 0">
                                <div class="no-image-bg"></div>
                            </div>
                        <div class="d-flex flex-column bg__whiterounded6 mb-2" *ngFor="let item of personalMedicalHistory">
                        <div class="pl-1 pr-2 d-flex flex-column flex-grow-1  ">

                        <p class="font-weight-medium p_btm">{{item.symptomsdesc}}</p>

                        </div>

                        </div>





                        </div>








                        </div>
                        </div>


                        </div>
                        </div>


              </div> -->
              <!-- Tab pane 2 end -->



            </div>
            <!-- tab content -->

          </div>
        </div>

    </div>
