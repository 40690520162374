<link rel="stylesheet" href="../../../assets/css/custom.css">

<div style="width:100%;">
    <form [formGroup]="resetPassword">
      <div class="row position-relative">
        <div class="col-md-12">
          <div class="form-group">
            <label>Old Password <span class="text-danger">*</span></label>
            <div class="input-group" id="show_hide_password2">
              <input type="password"
               (paste)="(false)" 
               (copy)="(false)" 
               formControlName="oldPassword"
                (blur)="checkOldpswd()" 
                class="form-control"
                placeholder="Enter old password"
                (keydown.space)="$event.preventDefault()"
                [ngClass]="{ 'is-invalid': (submitted || f.oldPassword.touched) && f.oldPassword.errors}">
                <a class="input-group-addon eye-ico"><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
              <div *ngIf="(f.oldPassword.touched) && f.oldPassword.errors" class="invalid-feedback">
                <div *ngIf="f.oldPassword.errors.required">Old password is required</div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="isCorrect" class="old-pwd">
          <div class="old-pwd-clr">( Must match with old password )</div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label for="exampleInputE">New Password <span class="text-danger">*</span></label>
            <div class="input-group" id="show_hide_password">
              <input type="password" 
                (paste)="(false)" 
                (copy)="(false)" 
                formControlName="newPassword"
                placeholder="Enter new password" 
                class="form-control"
                (keydown.space)="$event.preventDefault()"
                [ngClass]="{ 'is-invalid': (submitted || f.newPassword.touched) && f.newPassword.errors}">
                <a class="input-group-addon eye-ico"><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
              <div *ngIf="(f.newPassword.touched) && f.newPassword.errors" class="invalid-feedback">
                <div *ngIf="f.newPassword.errors.required">Password is required</div>
                <div *ngIf="f.newPassword.errors.pattern">
                  Password must contain 1 Number,1 LowerCase,1 UpperCase,1
                  special Character, No spaces allowed
                </div>
                <div *ngIf="f.newPassword.errors.noMatch">
                  New Password should not match with old password
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12">
          <div class="form-group">
            <label>Confirm Password <span class="text-danger">*</span></label>
            <div class="input-group" id="show_hide_password1">
              <input type="password" 
                (paste)="(false)" 
                (copy)="(false)" 
                placeholder="Enter confirm password" 
                formControlName="confirmPassword" 
                class="form-control"
                (keydown.space)="$event.preventDefault()"
                [ngClass]="{ 'is-invalid': (submitted ||f.confirmPassword.touched) && f.confirmPassword.errors}">
                <a class="input-group-addon eye-ico"><i class="fa fa-eye-slash" aria-hidden="true"></i></a>
              <div *ngIf="(f.confirmPassword.touched) && f.confirmPassword.errors" class="invalid-feedback">
                <div *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match</div>
                <div *ngIf="f.confirmPassword.errors.noMatch">
                  Confirm Password should not match with old password
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- <div class="col-md-4">
          <div class="form-group">
              <label>OTP</label><span class="text-danger">*</span>
              <input type="text" (keypress)="numberOnly($event)" placeholder="otp" formControlName="otp" class="form-control" [ngClass]="{ 'is-invalid': (submitted ||f.otp.touched ) && f.otp.errors}">
              <a class="btn" style="text-decoration: underline;color:blue;float:right" (click)="sendOTP()">Send OTP</a>
          </div>
      </div>
      <div class="col-md-2 ">
          <button type="button" (click)="sendOTP()" style="float:right;margin-top: 26px;" class="btn btn-success btn-sm">Send OTP</button>
          <button class="btn btn-xs btn-primary mt-4" *ngIf="isSend" (click)="sendOTP()" [disabled]="isGetOtp">Get Otp</button>
          <button class="btn btn-xs btn-primary mt-4" *ngIf="isResend" (click)="sendOTP()" [disabled]="isResendOtp">Resend Otp</button>
          <span class="timer" id="timer"></span>
          <div *ngIf="(f.otp.touched ) && f.otp.errors" class="invalid-feedback">
              <div *ngIf="f.otp.errors.required">Otp is required</div>
          </div>
      </div> -->


        <div *ngIf="showOTP">
          <div class="col-md-12 form-group">
            <div class="w-18 float-left">
              <label>OTP <span class="text-danger">*</span></label>
              <div id="otp-holder">
                <div id="otp-content">
                  <input id="otp-input" class="otp-input" type="tel" maxlength="6" pattern="\d{6}" formControlName="otp"
                    [ngClass]="{ 'is-invalid':(submitted || f.otp.touched) && f.otp.errors }" />
                  <div *ngIf="(submitted ||f.otp.touched )&& f.otp.errors" class="invalid-feedback">
                    <div *ngIf="f.otp.errors.required">Otp is required</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="float-left ml-2 mt-4">
              <button class="btn btn-xs btn-primary" *ngIf="isResend" (click)="sendOTP()"
                [disabled]="isResendOtp">Resend Otp</button>
              <span *ngIf="isTimer" class="timer" style="padding-top:10px; color: red;" id="timer"></span>
            </div>
          </div>
        </div>

        <div class="col-md-12" *ngIf="showOTP">
          <div class="float-right my-2">
            <button [disabled]="!resetPassword.valid" class="btn btn-primary btn-sm" (click)="submit()" type="button"><i
                class="fa fa-unlock-alt"></i> Set New Password</button>
          </div>
        </div>
        <div class="col-md-12" *ngIf="!showOTP">
          <div class="float-right my-2">
            <button class="btn btn-primary btn-sm" (click)="checkPassword()" type="button"><i
                class="fa fa-unlock-alt"></i> Get Otp</button>
          </div>
        </div>
      </div>
    </form>
</div>
