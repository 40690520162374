import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { GlobalStorageService } from 'src/app/global-storage.service';
import { ToWords } from 'to-words';

@Component({
  selector: 'app-discharge-invoice',
  templateUrl: './discharge-invoice.component.html',
  styleUrls: ['./discharge-invoice.component.css']
})
export class DischargeInvoiceComponent implements OnInit {
  finalInvoice
  @Input() inPatientId:any
  imageSource;
  toWords;
  presentTime = new Date()
  constructor(public activeModal: NgbActiveModal,private clinicServices: ClinicService, private sanitizer: DomSanitizer,private toastr: ToastrService, private globalStorage:GlobalStorageService) { }

  ngOnInit(): void {
    this.toWords = new ToWords({
      localeCode: 'en-IN',
      converterOptions: {
        currency: true,
        ignoreDecimal: false,
        ignoreZeroCurrency: false,
        doNotAddOnly: false,
        currencyOptions: {
          // can be used to override defaults for the selected locale
          name: 'Rupee',
          plural: 'Rupees',
          symbol: '₹',
          fractionalUnit: {
            name: 'Paisa',
            plural: 'Paise',
            symbol: '',
          },
        },
      },
    });
    this.getInvoice()
  }
  getInvoice(){
    this.clinicServices.getDischargeInvoice(parseInt(this.globalStorage.getItem('hospitalAddressId')), this.inPatientId).subscribe(res => {
      this.finalInvoice = res['data']
      var src = "data:image/jpeg;base64," + res['data']?.['logo']
      this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(src);
    }, error => {
      this.toastr.error(error?.['error']?.['message'])
    })
  }
}
