import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { from } from 'rxjs';
declare var jQuery: any;
@Component({
  selector: 'app-appointment-search-result',
  templateUrl: './appointment-search-result.component.html',
  styleUrls: ['./appointment-search-result.component.css']
})
export class AppointmentSearchResultComponent implements OnInit {

  h: string[]= ["00:00", "00:30", "01:00", "01:30", "02:00","02:30", "03:00", "03:30", "04:00","04:30", "05:00", "05:30", "06:00", "06:30", "07:00","07:30","08:00","08:30","09:00","09:30","10:00","10:30","11:00","11:30","12:00","12:30","13:00","13:30","14:00",
  "14:30","15:00","15:30","16:00","16:30","17:00","17:30","18:00","18:30","19:00","19:30","20:00","20:30","21:00","21:30","22:00","22:30","23:00","23:30"]

  constructor(private globalStorage: GlobalStorageService, private doctorService:DoctorService,private patientService:DoctorPatientService,private toastrService:ToastrService,private fb :FormBuilder) {
    this.frmAdvanceSearch=this.fb.group({
      appointmentDate:[],
      ailment:[],
      appointmentFor:[],
      fromDate:[],
      patientName: [],
      appointmentStatus:[''],
      toDate:[],
      amountPaid:[],
      referenceEntityName:[],
    })
   }
  ngOnInit(): void {
  }
  advanceSearch:boolean=false;
  p1=1
  advanceSearchbtn(){
    this.advanceSearch=true;
  }

  
  frmAdvanceSearch:FormGroup
  searchApointments:any[]=[]
  BindsearchApointments
  isNoData:boolean=false;
  maxDate
  picker
  myDatePicker
  myDatePicker1
  IsSearch:boolean=false;
  appointmentSearch(){
 
if(this.frmAdvanceSearch.value.ailment==null &&
  this.frmAdvanceSearch.value.patientName==null &&
  this.frmAdvanceSearch.value.fromDate==null &&
  this.frmAdvanceSearch.value.toDate==null &&
  this.frmAdvanceSearch.value.appointmentStatus==""){
 
    this.toastrService.warning('Enter search criteria')
    return
}

// if(this.frmAdvanceSearch.value.fromTime!=null || this.frmAdvanceSearch.value.toTime!=null){

//   if(this.frmAdvanceSearch.value.appointmentDate==null){
//     this.toastrService.warning('Please select date')
//     return
//   }
// }


// this.isNoData=false;


// if(this.frmAdvanceSearch.value.fromTime!=undefined &&  this.frmAdvanceSearch.value.fromTime!=null){
//   var fromTime=this.getTwentyFourHourTime(this.frmAdvanceSearch.value.fromTime)
// }
// if(this.frmAdvanceSearch.value.toTime!=undefined && this.frmAdvanceSearch.value.toTime!=null){
//   var toTime=this.getTwentyFourHourTime(this.frmAdvanceSearch.value.toTime)
// }

// if(this.frmAdvanceSearch.value.appointmentDate!=undefined && this.frmAdvanceSearch.value.appointmentDate!=null){
//   var appointmentDate=formatDate(this.frmAdvanceSearch.value.appointmentDate,"yyyy-MM-dd'T'HH:mm:ss.SSS",'en-us'); 
// }

var newFromdate=null
var newTodate=null

if(this.frmAdvanceSearch.value.fromDate!=null){
var fromDate=formatDate(this.frmAdvanceSearch.value.fromDate,"yyyy-MM-dd'T'HH:mm:ss.SSS",'en-us')
 var split=fromDate?.split('.')
 var split2=split[0]?.split('T')
 newFromdate=split2[0]+' '+ '12:00:00'     //split2[1]
}

 if(this.frmAdvanceSearch.value.toDate!=null){
var toDate=formatDate(this.frmAdvanceSearch.value.toDate,"yyyy-MM-dd'T'HH:mm:ss.SSS",'en-us')
var splittoDate=toDate?.split('.')
var splittoDate2=splittoDate[0]?.split('T')
    newTodate=splittoDate2[0]+' '+ '12:00:00'                //splittoDate2[1]
 }


if(this.frmAdvanceSearch.value.appointmentStatus==''){
  this.frmAdvanceSearch.value.appointmentStatus=null
}


var obj1={
  
    "ailment":this.frmAdvanceSearch.value.ailment,
    "appointmentStatus":this.frmAdvanceSearch.value.appointmentStatus,
    "doctorId": this.globalStorage.getItem('doctorId'),
    "fromDate":newFromdate,
    "maxResults": 10,
    "pageNum": 1,
    "patientName": this.frmAdvanceSearch.value.patientName,
    "schedulehoursId": null,
    "toDate":newTodate,
    "userId": null 
}

     this.doctorService.appointmentsAdvanceSearch(obj1).subscribe(res=>{

      if(res["data"].length>0){
          this.searchApointments = res["data"]
          this.isNoData=false;
      }else{
        this.searchApointments.length=0;
        this.isNoData=true;
      }
  },error=>{
    this.toastrService.error('Error in getting search result')
  })
  }

  getTwentyFourHourTime(str) { 
    // var d = new Date("1/1/2013 " + amPmString); 
     //return d.getHours() + ':' + d.getMinutes()+ ':'+ '00'; 
     str = String(str).toLowerCase().replace(/\s/g, '');
     var has_am = str.indexOf('am') >= 0;
     var has_pm = str.indexOf('pm') >= 0;
     // first strip off the am/pm, leave it either hour or hour:minute
     str = str.replace('am', '').replace('pm', '');
     // if hour, convert to hour:00
     if (str.indexOf(':') < 0) str = str + ':00';
     // now it's hour:minute
     // we add am/pm back if striped out before 
     if (has_am) str += ' am';
     if (has_pm) str += ' pm';
     // now its either hour:minute, or hour:minute am/pm
     // put it in a date object, it will convert to 24 hours format for us 
     var d = new Date("1/1/2011 " + str);
     // make hours and minutes double digits
     var doubleDigits = function(n){
         return (parseInt(n) < 10) ? "0" + n : String(n);
     };
     return doubleDigits(d.getHours()) + ':' + doubleDigits(d.getMinutes()) + ':' +'00';
 }

 numberOnly(event): boolean {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

  btnCancel(){
    this.advanceSearch=false;
    this.frmAdvanceSearch.reset();
  }
  patientDetails={}
  viewCurrentAppontment(appointmentDetails){
    this.getPatientDetails(appointmentDetails);
  }

  getPatientDetails(appointmentDetails){
    var req={
      "forUserId": appointmentDetails.userId,
      "userId": this.globalStorage.getItem('doctorId')
    }
    this.patientService.getPatientPersonalDetails(appointmentDetails.userId,req).subscribe(res=>{
        var patientDetails=res['body'].data;
        patientDetails.AppointmentDetails=appointmentDetails;
        patientDetails.Appointment='Previous';
       this.doctorService.OpenPatientSideNave(patientDetails);
    },error=>{
        this.toastrService.error("Error in getting patient details");
    })  
  }


prescriptionList(appointment){
 
  this.patientService.openPrescriptionList(appointment);
}

patient
getPatientMedicalReports(patient){
 
var patientDetails=  {
    "forUserId": patient.userId,
    "userId":patient.userId
  }
  this.patientService.openMedicalReportList(patientDetails);
}



reportDetails
viewMedicalReport(report){
var reportObj={
  'forUserId':report.userId,
  medicalReportId:report.medicalReportsId
}
this.patientService.getPatientMedicalReportsByMrId(reportObj).subscribe(res=>{
this.reportDetails=res['body']['data']
this.patientService.viewMedicalReport(this.reportDetails);
},error=>{
    this.toastrService.error("Error in view Medical report")
})
}

Reset(){
  this.frmAdvanceSearch.reset();
  this.frmAdvanceSearch.controls['appointmentStatus'].setValue('')
  this.searchApointments.length=0;
  this.isNoData=false;
  // this.frmAdvanceSearch.value.ailment=""
  // this.frmAdvanceSearch.value.patientName=""
  // this.frmAdvanceSearch.value.appointmentDate=""
  // this.frmAdvanceSearch.value.amountPaid=""
  // this.frmAdvanceSearch.value.fromTime=""
  // this.frmAdvanceSearch.value.toTime=""
  // this.frmAdvanceSearch.value.appointmentStatus=""
}


ngAfterViewInit(){
  //jQuery('#datetimepicker').datetimepicker();
 // $('#datetimepicker').datetimepicker();

}


}
