<div class="modal-header">
    <h4 class="modal-title">Bed Information</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
<div class="modal-body">
    <div class="no-data-found" *ngIf="bedData?.length==0 || bedData==null"></div>
    <table class="table table-bordered table-hover table-styled table-striped mt-2"
      *ngIf="bedData?.length>0 || bedData!=null">
      <thead>
        <tr role="row">
          <th>Patient Name</th>
          <th>Floor Number</th>
          <th>Room Number</th>
          <th>Room Type</th>
          <th>Room Name</th>
          <th>Bed Number</th>
          <th>Price</th>
          <th>Assigned Date</th>
          <th>Checkout Date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of bedData|paginate: { id: 'p2', itemsPerPage: 10, currentPage: p2 }">
          <td>{{item?.patientName}}</td>
          <td>{{item?.floorNumber}}</td>
          <td>{{item?.roomNumber}}</td>
          <td>{{item?.roomType}}</td>
          <td>{{item?.roomName}}</td>
          <td>{{item?.bedNumber}}</td>
          <td>{{item?.price}}</td>
          <td>{{item?.startDate | date:'dd-MMM-yyyy'}}</td>
          <td *ngIf="item?.endDate!=null">{{item?.endDate | date:'dd-MMM-yyyy'}}</td>
          <td *ngIf="item?.endDate==null">
            <button type="button" class="btn btn-primary btn-xs" (click)="changeRoom()">Change Room</button>
        </td>
        </tr>
      </tbody>
    </table>
    <div class="row" *ngIf="bedData?.length>10">
      <div class="col-lg-12 mt-3 text_right ">
        <ul class="pagination">
          <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
        </ul>
      </div>
    </div>
  </div>