<div class="joinnow-top">
    <button class="btn joinnow mr-2" (click)="onAddRoom(roomName,appointmentId)"> <span class="button-video "><i class="fa fa-video"  ></i></span>Join Room</button>
</div>
<div class="jumbotron" style="visibility: hidden;">
    <div class="list-group">
        <div class="list-group-item d-flex justify-content-between align-items-center">
            <div class="input-group">
                <input type="text" class="form-control form-control-lg"
                placeholder="appointmentId" aria-label="appointmentId"
                [(ngModel)]="appointmentId">
                <input type="text" class="form-control form-control-lg"
                       placeholder="Room Name" aria-label="Room Name"
                       [(ngModel)]="roomName" >
                <div class="input-group-append">
                    <button class="btn btn-lg btn-outline-secondary twitter-red"
                            type="button" [disabled]="!roomName"
                            (click)="onAddRoom(roomName,appointmentId)">
                        <i class="far fa-plus-square"></i> Join
                    </button>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="!rooms || !rooms.length" class="list-group-item d-flex justify-content-between align-items-center" style="display: none !important;">
            <p class="lead">
                Add a room to begin. Other online participants can join or create rooms.
            </p>
        </div> -->
        <!-- *ngFor="let room of rooms" -->
        <!-- <a href="javascript:void(0)"
           (click)="onJoinRoom(roomNameIn)" [ngClass]="{ 'active': activeRoomName === roomNameIn }"
           class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
            {{ roomNameIn }} -->
            <!-- <span class="badge badge-primary badge-pill">
                {{ room.participantCount }} / {{ room.maxParticipants }}
            </span> -->
        <!-- </a> -->
    </div>
</div>

