import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit } from '@angular/core';
import { InternalMessageService } from './internal-message.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewMessageComponent } from './view-message/view-message.component';
import * as moment from 'moment';

@Component({
  selector: 'app-internal-message',
  templateUrl: './internal-message.component.html',
  styleUrls: ['./internal-message.component.css']
})
export class InternalMessageComponent implements OnInit {
  addressId:any;messagesList;p2=1;messageInfo;isIvf=false;
  constructor(private globalStorage: GlobalStorageService, private messageService:InternalMessageService,private toastr: ToastrService,public activeModal:NgbActiveModal,private modal:NgbModal) { }

  ngOnInit(): void {
    if(this.globalStorage.getItem('loggedInAs')=='IVF'){
      this.isIvf=true
    }
    else{
      this.isIvf=false
    }
    this.getMessages()
  }

  getMessages(){
    this.messageService.getMessages(this.addressId,parseInt(this.globalStorage.getItem('forUserId'))).subscribe(res=>{
      this.messagesList=res['data']
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }

  viewMessage(message){
    const modalRef = this.modal.open(ViewMessageComponent, {
      centered: false,
      windowClass: 'modal-centered hw-30',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.addressId = parseInt(this.globalStorage.getItem('hospitalAddressId'))
    modalRef.componentInstance.meessageInfo=message
    modalRef.result.then(
      (result) => {
      // this.getMessages()
      },
      (reason) => {
        this.getMessages()
      }
    );
  }
  getFormattedDateTime(dateString) {
    const date = new Date(dateString);

// Construct the new date string in the desired format
    const formattedDateString = date.toISOString().substring(0, 19);
    console.log('formattedDateString',formattedDateString)
    return formattedDateString
  }
}
