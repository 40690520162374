import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';
import { InventoryService } from '../inventory.service';
import { SaveSupplierComponent } from '../../modals/save-supplier/save-supplier.component';
import { CreateOrdersComponent } from '../create-orders/create-orders.component';
import { SaveBulkOrderComponent } from '../../modals/save-bulk-order/save-bulk-order.component';

@Component({
  selector: 'app-assign-supplier',
  templateUrl: './assign-supplier.component.html',
  styleUrls: ['./assign-supplier.component.css']
})
export class AssignSupplierComponent implements OnInit {
  @Input() addressId: any;
  @Input() service: any;
  @Input() fromWhere: boolean = false;
  @Input() category: any;
  @Input() subcategory: any;
  p6 = 1;

  showDiscount: boolean = false;
  showOffer: boolean = false;
  selectedOption: any;
  showGstFields: boolean = false;
  showIGstFields: boolean = false;
  AssignSupplier: any;
  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private toastrService: ToastrService, private invService: InventoryService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getAssignedSuppliers();
  }

  getAssignedSuppliers() {
    var body =
    {
      "flag": "ALL",
      "itemTypeId": this.subcategory.itemTypeId,
      "addressId": this.addressId
    }
    // this.fromWhere?body['ivfAddressId']=this.addressId:body['hospitalAddresId']=this.addressId
    this.invService.getSuppliercatgeryByItemTypeId(body).subscribe(res => {
      this.AssignSupplier = res?.['data'];
    });
  }

  addSupplier() {
    let wClass='left';
    if(this.globalStorage.getItem("loggedInAs")=='IVF'){
      wClass='left hw-40';
    }
    if(this.globalStorage.getItem("loggedInAs")=='HOSPITAL'){
      wClass='modal-right hw-40';
    }
    if(this.globalStorage.getItem("loggedInAs")=='PHARMACY'){
      wClass='modal-right hw-40';
    }
    const modalRef = this.modalService.open(SaveSupplierComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    })
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.category = this.category;
    modalRef.componentInstance.subcategory = this.subcategory;
    modalRef.componentInstance.service = this.service;
    modalRef.result.then(
      (result) => {
        this.getAssignedSuppliers();
      },
      (reason) => {
      }
    );
  }

  editAssign(data) {
    let wClass='left';
    if(this.globalStorage.getItem("loggedInAs")=='IVF'){
      wClass='left hw-40';
    }
    if(this.globalStorage.getItem("loggedInAs")=='HOSPITAL'){
      wClass='modal-right hw-40';
    }
    if(this.globalStorage.getItem("loggedInAs")=='PHARMACY'){
      wClass='modal-right hw-40';
    }
    const modalRef = this.modalService.open(SaveSupplierComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    })
    modalRef.componentInstance.isAssignedSupplierEdit = true;
    modalRef.componentInstance.editTime = true;
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.category = this.category;
    modalRef.componentInstance.subcategory = this.subcategory;
    modalRef.componentInstance.service = this.service;
    modalRef.componentInstance.editedAssignedSupplierObj = data;
    modalRef.result.then(
      (result) => {
        this.getAssignedSuppliers();
      },
      (reason) => {
      }
    );
  }

  activateAssignSupplier(data) {
    let suppliercatgeryId = data?.suppliercatgeryId
    this.invService.ActiveAssignSupplier(this.addressId, suppliercatgeryId).subscribe(res => {
      this.toastrService.success('Supplier Details Activated.')
      this.getAssignedSuppliers();
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    }
    )
  }

  deleteAssignSuppier(data) {
    let suppliercatgeryId = data?.suppliercatgeryId;
    this.invService.deleteAssignSupplier(this.addressId, suppliercatgeryId).subscribe(res => {
      this.toastrService.success('Supplier Details Deactivated.');
      this.getAssignedSuppliers();
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    }
    )
  }

  openPlaceOrder(data,itemData) {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'left hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-right hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-right hw-80';
    }
    const modalRef = this.modalService.open(SaveBulkOrderComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.componentInstance.assignAssociatedItems = true;
    modalRef.componentInstance.purchaseItems = data;
    modalRef.componentInstance.supplieId=itemData?.supplierId
    modalRef.componentInstance.addressId = this.addressId;
  }

}
