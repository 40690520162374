<div class="l-sidebar">
  <div class="logo">
    <div class="logo__txt"><img alt="logo-img" class="img-center" id="logo-img" src="./assets/img/logo-white.png"></div>
  </div>
  <div class="l-sidebar__content">
    <nav class="c-menu js-menu">
      <ul class="u-list">
        <li class="c-menu__item is-active " data-toggle="tooltip" title="Home">
          <a href="javascript:void(0)" routerLink='/diagnosticDashboard'>
            <div class="c-menu__item__inner bg-warning-light">
              <i class="fa fa-home"></i>
              <a class="c-menu-item__title"><span>Home</span></a>
            </div>
          </a>
        </li>

        <li *ngIf="AppointmentsPermission" class="c-menu__item has-submenu  " data-toggle="tooltip"
          title="Appointments">
          <a href="javascript:void(0)" routerLink='/diagnosticAppointments'>
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-calendar-plus"></i>
              <div class="c-menu-item__title"><span>Appointments</span></div>
            </div>
          </a>
        </li>
        <!-- <li class="c-menu__item has-submenu " data-toggle="tooltip" title="" data-original-title="Calendar">
            <a href="javascript:void(0)" routerLink="/diagnosticCalander">  <div class="c-menu__item__inner bg-primary-light">
               <i class="fa fa-calendar-day"></i>
               <div class="c-menu-item__title"><span>Calendar</span></div>
            </div> </a>
         </li>
           <li class="c-menu__item has-submenu" data-toggle="tooltip" title="Base Scheduler" >
            <a  href="javascript:void(0)" routerLink="/diagnosticBaseSchedule">
                <div class="c-menu__item__inner bg-warning-light">
                    <i class=" fa fa-calendar-alt "></i>
                    <div class="c-menu-item__title"> <span class="nav-text">Base Scheduler</span></div>
                </div>
            </a>
        </li> -->
        <li *ngIf="servicesPermission" class="c-menu__item has-submenu" data-toggle="tooltip" title="Services">
          <a href="javascript:void(0)" routerLink='/diagnosticMenuList'>
            <div class="c-menu__item__inner bg-warning-light">
              <i class="fa fa-folder-plus "></i>
              <div class="c-menu-item__title"><span>Services</span></div>
            </div>
          </a>
        </li>

        <li *ngIf="ReportsPermission" class="c-menu__item has-submenu" data-toggle="tooltip" title="Reports">
          <a href="javascript:void(0)" routerLink='/diagnosticReports'>
            <div class="c-menu__item__inner bg-primary-light">
              <i class="fa fa-notes-medical"></i>
              <div class="c-menu-item__title"><span>Reports</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu  " data-toggle="tooltip" title="Manage Pricing " style="display: none;">
          <a href="javascript:void(0)" routerLink="/diagnosticManagePricing">
            <div class="c-menu__item__inner bg-warning-light">
              <i class="fa fa-user-tag"></i>
              <div class="c-menu-item__title"><span>Manage Pricing </span></div>
            </div>
          </a>
        </li>
        <!-- <li class="c-menu__item has-submenu" data-toggle="tooltip" title="Medical Records">
          <a href="javascript:void(0)" routerLink='/diagnosticMedicalRecords'>
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-microscope "></i>
              <div class="c-menu-item__title"><span>Medical Records</span></div>
            </div>
          </a>
        </li> -->
        <li class="c-menu__item has-submenu" data-toggle="tooltip" title="Order Management" style="display: none;">
          <a href="javascript:void(0)" routerLink='/diagnosticOrderManagement'>
            <div class="c-menu__item__inner bg-primary-light">
              <i class="fa fa-people-arrows	"></i>
              <div class="c-menu-item__title"><span>Order Management</span></div>
            </div>
          </a>
        </li>
        <li *ngIf="UsersPermission" class="c-menu__item has-submenu" data-toggle="tooltip" title="Users">
          <a href="javascript:void(0)" routerLink='/diagnostic_users'>
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-users"></i>
              <div class="c-menu-item__title"><span>Users</span></div>
            </div>
          </a>
        </li>
        <li *ngIf="ProfilePermission" class="c-menu__item has-submenu" data-toggle="tooltip" title="Profile">
          <a href="javascript:void(0)" routerLink='/diagnosticProfile'>
            <div class="c-menu__item__inner bg-warning-light">
              <i class="fa fa-user-cog"></i>
              <div class="c-menu-item__title"><span>Profile</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu" data-toggle="tooltip" data-placement="right" title="Assign Roles" *ngIf="presentRole=='ADMIN'">
          <a href="javascript:void(0)" routerLink='/DcRoles'>
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-user-cog"></i>
              <div class="c-menu-item__title"><span>Assign Roles</span></div>
            </div>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>

<header class="l-header">
  <div class="l-header__inner clearfix">
    <!-- <div class="c-header-icon js-hamburger no-mobile">
      <div class="hamburger-toggle"><span class="bar-top"></span><span class="bar-mid"></span><span
          class="bar-bot"></span></div>
    </div> -->

    <div class="c-search p-0 no-desktop">
      <img src="./assets/img/logo-mobile.png" />
    </div>



    <div class="c-search no-mobile">
      <div style=" float: left; ">Logged in as <b class="ml-1 mr-1 captilize">{{firstName|titlecase}}
          {{lastName|titlecase}}</b> </div>
      <span class="float-right pt-0 d-none d-lg-block mr-2" *ngIf="!firstTimeLogin">
        Last login {{lastLogin | date:'dd-MMM-yyyy hh:mm a'}}

      </span>

      <span class="float-left d-none d-lg-block mr-2" *ngIf="firstTimeLogin">
        Welcome to CureMetric. This is your first time login.
      </span>


    </div>
    <!-- <button mat-raised-button color="primary" feedback (send)="onSend($event)">Help</button> -->
    <div class="header-icons-group">
       <!-- messages start -->
       <!-- <div class="c-header-icon has-dropdown bg-success-light" aria-hidden="true"  data-placement="left" data-toggle="tooltip" data-original-title="Create Message">
        <span class="c-badge c-badge--header-icon ">{{0}}</span><i class="fa fa-paper-plane" aria-hidden="true" (click)="createMessage()"></i>
      </div> -->
      <!-- messageS End -->
       <!-- messages get start -->
       <!-- <div class="c-header-icon has-dropdown bg-primary-light" aria-hidden="true"  data-placement="left" data-toggle="tooltip" data-original-title="Get Messages">
        <span class="c-badge c-badge--header-icon ">{{0}}</span><i class="fa-solid fa-message" (click)="getMessages()"></i>
      </div> -->
      <!-- messageS get End -->
      <div class="c-header-icon has-dropdown bg-danger-light" data-toggle="tooltip" data-placement="left" title="Help">
        <i class="fas fa-question-circle" feedback (send)="onSend($event)"></i>
      </div>
      <div class="c-header-icon has-dropdown bg-primary-light" *ngIf="notificationBell" data-placement="left"
        data-toggle="tooltip" data-original-title="Notifications">
        <span class="c-badge c-badge--header-icon ">{{notificationLength}} </span><i class="fa fa-bell"
          data-toggle="modal" data-target="#viewreminders"></i>
      </div>
      <div class="c-header-icon has-dropdown bg-success-light " data-placement="left" data-toggle="tooltip"
        data-original-title="Profile">
        <i class="fa fa-user-cog" routerLink='/diagnosticProfile'></i>
      </div>
      <div class="c-header-icon logout bg-warning-light" (click)="logOut()" data-placement="left" data-toggle="tooltip"
        data-original-title="Logout"><i class="fa fa-sign-out-alt"></i></div>
    </div>
  </div>
</header>



<div class="on-mobile">
  <footer class="footer-mobile ">
    <div class="container-fulid">
      <ul class="nav nav-pills nav-justified">
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/diagnosticPackages" data-toggle="tooltip" title=""
            data-original-title="Appointments">
            <span>
              <i class="nav-icon fa fa-calendar-alt m-col1"></i>
              <span class="nav-text">Appointments</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/pat-history" data-toggle="tooltip" title=""
            data-original-title="User Management">
            <span>
              <i class="nav-icon fa fa-users m-col2"></i>
              <span class="nav-text">User Management</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/diagnosticMenuList" data-toggle="tooltip" title=""
            data-original-title="Medical Records">
            <span>
              <i class="nav-icon fa fa-microscope m-col3"></i>
              <span class="nav-text">Medical Records</span>
            </span>
          </a>
        </li>
        <li class="nav-item center-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/dashboard" data-toggle="tooltip" title=""
            data-original-title="Home">
            <span>
              <i class="nav-icon fa fa-home"></i>
              <span class="nav-text">Home</span>

            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/diagnosticProfile" data-toggle="tooltip" title=""
            data-original-title="Reports">
            <span>
              <i class="nav-icon fa fa-notes-medical m-col1"></i>
              <span class="nav-text">Reports</span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/pat-history" data-toggle="tooltip" title=""
            data-original-title="Surge Pricing">
            <span>
              <i class="nav-icon fa fa-user-tag m-col2"></i>
              <span class="nav-text">Surge Pricing </span>
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/diagnosticMenuList" data-toggle="tooltip" title=""
            data-original-title="Order Management">
            <span>
              <i class="nav-icon fa fa-people-arrows m-col3"></i>
              <span class="nav-text">Order Management</span>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</div>

<div class="modal fade modal-right" id="viewreminders" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notifications</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <!-- <app-view-doctor-remainders></app-view-doctor-remainders> -->
      <div class="row">
        <div class="col-lg-12 mt-3 text_right ">

          <ul class="pagination">
            <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
          </ul>
        </div>
      </div>
      <div *ngIf="notifications?.length == 0" class="no-notifications"></div>
      <div class="row d-flex  ml-2">

        <div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left  blt-2 mb-2 mt-1 "
          *ngFor="let reports of notifications |paginate: { id: 'p2', itemsPerPage: 6, currentPage: p2 }"
          [ngClass]="{' blt-3': reports.viewed == 'Y'}" (click)="updateNotify(reports)">

          <a href="javascript:void(0)" class="p-2 icon-appointments  ">
            <i *ngIf="reports.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3" aria-hidden="true"></i>
            <i *ngIf="reports.viewed != 'Y'" class="fa fa-bell fa-lg pt-2  icon-col-3" aria-hidden="true"></i>
          </a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
            <a href="javascript:void(0)">
              <p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
              </p>
              <p class="text-muted mb-1 text-small">{{reports.reason}}</p>
            </a>

          </div>

          <div class="btn-group float-right">
            <button class="btn btn-xs btn0col1" data-toggle="modal" data-backdrop="static" data-target="#viewpdf"><i
                class="fa fa-eye" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Notifications View Modal -->
<div class="modal fade" id="notificationToViewSid" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered notificationViewerModal_Style" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notification Viewer</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="block inv-section">
          <div class="text-center mb-2">
            <img alt="Logo" src="./assets/img/logo.png" style="border-radius: none !important;">
          </div>
          <div class="text-center">
            <p class="font-weight-medium mb-1 mt-1">{{notifyToView?.objecttype |titlecase}}</p>
            <p class="text-muted mb-1 text-small">{{notifyToView?.reason}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Notifications View Modal Ends -->
