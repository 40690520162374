import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { NoMatch } from 'src/app/_helpers/must_match.validators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { pharmaService } from 'src/app/shared/services/pharma.service';


@Component({
  selector: 'app-new-pharma-order',
  templateUrl: './new-pharma-order.component.html',
  styleUrls: ['./new-pharma-order.component.css']
})
export class NewPharmaOrderComponent implements OnInit {

  orderArray: any = []
  subtotal:number = 0;
  totalAmount:number = 0;
  taxAmount:number=0;
  totalDiscount:number=0;

  filteredOptions: Observable<any[]>;
  medicineControl = new FormControl();
  ivfService: any;
  currentTreatmentType: string;
  registerForm: FormGroup;
  


  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder,private toaster: ToastrService, private pharmaService: pharmaService, public activeModal: NgbActiveModal, private authService: AuthService) { }

  ngOnInit(): void {

    this.paymentMethod=this.cashModes[0].value;

    this.initializeForm();

    this.orderArray.push({
      "inventoryId": '',
      "medicineName": "",
      "type": "",
      "numberOfDays": 0,
      "power": "100",
      "quantity": 0,
      "quantityAvailable": 0,
      "rack": "",
      "unitPrice": 0,
      "price": 0,
      "cgst": 0,
      "sgst": 0,
      "taxedAmount":0,
      "discount": 0,
      "itemTotalPrice": 0,
      "isEditable": true
    })

    this.filteredOptions = this.medicineControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(300),
        switchMap(value => this.fetchFilteredOptions(value)),
        catchError(error => {
          console.error('Error fetching data: ', error);
          return [];
        })
      );
  }

  initializeForm() {
    this.registerForm = this.formBuilder.group(
      {
        "active":"Y",
        "patientReferenceId": null,
        "emailId": null,
        "lastName": ['', Validators.required],
        "firstName": ['', Validators.required],
        "cellNumber": ['', Validators.required],
        "pharmacyAddressId": this.globalStorage.getItem('pharmacyId')
      },
      {
        validators: [NoMatch('firstName', 'lastName')]
      });
  }

  @ViewChild('cellInput') cellInput: ElementRef;
  userFound: boolean = false;
  checkPhNumber() {
    // console.log(num)
    if (this.registerForm.value.cellNumber.length == 10) {
      const body = {
        "cellNumber":this.getMobileNumbers(this.cellInput),
        "pharmacyaddressId": this.globalStorage.getItem('pharmacyId')
      }
      this.pharmaService.getPatientByMobile(body).subscribe(res => {
        if (res?.data) {
          this.registerForm.patchValue(res?.data[0])
          this.userFound = true;
        } else {
          this.userFound = false;
        }
      })
    } else {
      this.toaster.error('Mobile Number must be 10 digits');
    }
  }

  getMobileNumbers(inputElement) {
    if (inputElement && inputElement.nativeElement) {
      // Make sure intlTelInput is initialized on the element
      const intlTelInputInstance = window.intlTelInputGlobals.getInstance(inputElement.nativeElement);
      if (intlTelInputInstance) {
        const number = intlTelInputInstance.getNumber();
        if (intlTelInputInstance) {
          // const countryData = intlTelInputInstance.getSelectedCountryData();
          return number;

        } else {
          console.error('Invalid phone number : ', number);
          return '';
        }
      } else {
        console.error('intlTelInput not initialized');
        return ''
      }
    } else {
      console.error('Input element is not provided or does not exist');
      return '';
    }
  }

  fetchFilteredOptions(value: string): Observable<any[]> {

    if (!value.trim()) {
      // If not search term, return empty array.
      return of([]);
    }
    return this.pharmaService.searchDrugByChar(value, this.globalStorage.getItem('pharmacyId')).pipe(
      map(res => res['body']['data']),
      catchError(error => {
        console.error('Error fetching medicines:', error);
        return of([]);
      })
    );
  }

  savePatient() {
    const body=this.registerForm.value;
    body.cellNumber=this.getMobileNumbers(this.cellInput)
    body.userName=this.getMobileNumbers(this.cellInput)

    this.pharmaService.saveUser(body).subscribe(res => {
      this.registerForm.patchValue({
        patientReferenceId: res?.body?.data})
      this.toaster.success('User added.')
      this.userFound=true;
    }, error => {
      this.toaster.error(error?.['error']?.['message'])
      this.userFound=false;
    })
  }

  onDrugSelect(index, drug) {
    if(!this.orderArray?.some(item=>item?.inventoryId ==drug?.inventoryId)){
      this.orderArray[index].inventoryId = drug?.inventoryId;
      this.orderArray[index].type = drug?.drugtype;
      this.orderArray[index].unitPrice=drug?.price;
      this.orderArray[index].quantityAvailable=drug?.quantityavailable;
      this.orderArray[index].rack=drug?.rack;
      this.orderArray[index].cgst=drug?.cgst || 0;
      this.orderArray[index].sgst=drug?.sgst|| 0;
    }else{
      this.orderArray[index].medicineName=''
      this.toaster.warning(`Medicine ${drug?.name} is already added to order`)
    }
  }

  getMedicineType() {
    this.pharmaService.drugType().subscribe((res: any) => {
      // this.medicineTypeArray = res?.body?.data;
      return true
    });
  }

  calPrice(i){
    
    if(this.orderArray[i].unitPrice && this.orderArray[i].quantity){
      let priceAfterGst=1;
      if(this.orderArray[i].sgst>0 || this.orderArray[i].cgst>0){
        priceAfterGst=(((this.orderArray[i].cgst+this.orderArray[i].sgst)/100));
        this.orderArray[i].taxedAmount=priceAfterGst*this.orderArray[i].unitPrice;
      }else{
        this.orderArray[i].taxedAmount=0;
      }
      this.orderArray[i].price=this.orderArray[i].quantity*(this.orderArray[i].taxedAmount+this.orderArray[i].unitPrice);
      this.orderArray[i].totalPrice=this.orderArray[i].quantity*(this.orderArray[i].taxedAmount+this.orderArray[i].unitPrice);
      this.orderArray[i].itemTotalPrice=this.orderArray[i].quantity*(this.orderArray[i].taxedAmount+this.orderArray[i].unitPrice);

      this.calTotals()
    }
  }

  calTotals(){
    this.subtotal = this.orderArray.reduce((a, { quantity,unitPrice }) => a + (quantity*unitPrice), 0);
    this.taxAmount = this.orderArray.reduce((a, { taxedAmount }) => a + parseFloat(taxedAmount), 0);
    this.totalAmount = Number(this.subtotal)+Number(this.taxAmount);
    if(this.totalDiscount>0){
      const discAmt = (this.totalDiscount/100)*this.totalAmount
      this.totalAmount = this.totalAmount-discAmt
    }


  }
  paymentMethod:any;
  cashModes: any[] = [{ id: 1, value: 'CASH' }, { id: 2, value: 'CARD' },{ id: 3, value: 'UPI' }]

  saveOrder(){
    const bodyArr = this.orderArray.filter(res => {
      if (res?.inventoryId && res?.inventoryId != "") {
        return res
      }
    })
    if(bodyArr.length>0){
      const body={
        "patientId": this.registerForm.value.patientReferenceId,
        "patientName": this.registerForm.value.firstName +' '+this.registerForm.value.lastName,
        "pharmacyAddressId": this.globalStorage.getItem('pharmacyId'),
        "totalDiscount": this.totalDiscount,
        "unitPrice":this.totalAmount,
        "paymentType": this.paymentMethod,  
        "pharmacyPrescription": bodyArr
  
      }
      this.pharmaService.addBulkSelfPrescription(body).subscribe(res => {
        this.toaster.success('Order saved.')
        this.activeModal.close();
      }, error => {
        this.toaster.error(error?.['error']?.['message'])
      })
    }else{
      this.toaster.error('Please select medicines.')
    }
  }

  
  addItem() {
    const index=this.orderArray.length;
    if(index==0){
      this.orderArray.push({
        inventoryId: "",
        quantity: 0,
        unitPrice: 0,
        taxedAmount: 0,
        price: 0,
        totalPrice: 0,
        itemTotalPrice: 0,
        type: "",
        rack: "",
        cgst: 0,
        sgst: 0,
        isEditable: true,
        quantityAvailable: 0,
        prescriptionFillRequestId: ""
      })
      return
    }

    if (this.orderArray[index - 1].medicineName && this.orderArray[index - 1].quantity) {
      if (this.orderArray.length > 0) {
        this.orderArray[index - 1].isEditable = false;
      }

      if (this.orderArray.length == (index)) {
        this.orderArray.push({
          "inventoryId": '',
          "medicineName": "",
          "type": "",
          "numberOfDays": 0,
          "power": "100",
          "quantity": 0,
          "quantityAvailable": 0,
          "rack": "",
          "unitPrice": 0,
          "taxedAmount":0,
          "price": 0,
          "cgst": 0,
          "sgst": 0,
          "discount": 0,
          "itemTotalPrice": 0,
          "isEditable": true
        })
      }
    } else {
      this.toaster.error('Please fill all the fields');
    }
  }

  editItem(index: number) {
    this.orderArray[index].isEditable = true;
  }

  saveItem(index: number) {
    this.orderArray[index].isEditable = false;
  }

  removeItem(index: number): void {
    this.orderArray.splice(index, 1);
  }

}
