import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../AppServices/login.service'
import { ToastrService } from 'ngx-toastr'
import { CookieService } from 'ngx-cookie-service';
import { Crypto } from '../../_helpers/CryptoJ'
import { AuthService } from 'src/app/shared/services/auth.service';
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import * as moment from 'moment';
import { ApicallsService } from 'src/app/apicalls.service';
declare var $: any;
@Component({
  selector: 'app-nutritionist-login',
  templateUrl: './nutritionist-login.component.html',
  styleUrls: ['./nutritionist-login.component.css']
})
export class NutritionistLoginComponent implements OnInit {
  notFocused = false; verifyNowButton = false;
  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder, private router: Router, private forgot: AuthService, private apiservice: ApicallsService,
    private login: LoginService, private doctorSaervice: DoctorService, private patientController: PatientScheduleControlService,
    private ToastrService: ToastrService, private cookieService: CookieService, private crpto: Crypto) {
    if (this.cookieService.get('rememberme') == 'Yes' && this.cookieService.get('module') == 'Nutritionist') {
      this.rememberme = true;
      this.email = this.crpto.Decrypt(this.cookieService.get('email'), 'email');
      this.password = this.crpto.Decrypt(this.cookieService.get('password'), 'password');
    }

  }
  email
  password
  LoginDetails: FormGroup;
  submitted
  firstSession
  message
  today; type
  rememberme: boolean = false;
  invalidCredentials: boolean = false
  isNutritionist: boolean = true
  isPsychiatrist: boolean = true
  isPhysiotherapist: boolean = true
  @ViewChild('Menu', { static: false }) public Menu: ElementRef;

  ngOnInit() {

    // if (navigator.userAgent.match(/Android/i)) {
    //   window.location.href = 'https://play.google.com/store/apps/details?id=com.curemetric.nutritionist'
    // }
    // else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    //   window.location.href = 'https://apps.apple.com/in/app/curemetric-nutritionist/id1671762981'
    // }



    $(function () {
      $(window).off('beforeunload');
      $("#preloader").fadeOut();
      $("#status").fadeOut(9000);
    });
    this.LoginDetails = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(8)]],   //Validators.pattern['/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/']]
    });
    // if(window.location.pathname == '/psychiatristLogin'){
    //   this.isNutritionist =false
    //   this.isPsychiatrist=true
    //   this.isPhysiotherapist=false
    //   this.type="PSYCHIATRIST"
    // }
    if (window.location.pathname == '/nutritionistLogin') {
      this.isNutritionist = true
      this.isPsychiatrist = false
      this.isPhysiotherapist = false
      this.type = "NUTRITIONIST"
    }
    // if(window.location.pathname == '/physiotherapistLogin'){
    //   this.isNutritionist =false
    //   this.isPsychiatrist=false
    //   this.isPhysiotherapist=true
    //   this.type="PHYSIOTHERAPIST"
    // }
  }

  navigateToEnquiryForm(): void {
    this.router.navigate(['/enquiry-form'], { queryParams: { module:'nutritionist' , returnUrl: this.router.url} });
}

  forgotPassword() {

    this.router.navigate(['/nutritionistForgot'])
    //   if(this.registerForm.value.email=="" || this.registerForm.value.email==undefined){
    //     this.ToastrService.warning('Enter the Email to send verification Link')
    //   }else{
    //   var request={
    //     "emailId":this.registerForm.value.email,
    //     "resetPasswordLink":window.location.origin+"/resetPassword"
    //   }
    //   this.service.forgotPassword(JSON.stringify(request)).subscribe(res=>{
    //     this.ToastrService.success(res['message'])
    //   },error=>{
    //   this.ToastrService.error(error['error']['message'])
    //   })
    // }
  }


  get f() { return this.LoginDetails.controls; }
  get pwd() {
    return this.LoginDetails.get('password');
  }

  showValidationMessage: boolean = false;
  preventCopy(event: ClipboardEvent) {
    event.preventDefault();
    this.showValidationMessage = true;
    setTimeout(() => {
      this.showValidationMessage = false;
    }, 5000);
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
  }
  verifyNow() {
    this.router.navigate(['/nutritionist-verify'])
  }
  onSubmit() {
    //this.router.navigate(['/nutritionist-dashboard']);
    this.submitted = true;
    if (this.LoginDetails.invalid) {
      return;
    }

    this.apiservice.isUserSessionExits = false
    this.apiservice.isforUserIdExits = false
    this.apiservice.ismoduleNameExits = false
    //this.globalStorage.clear();
    if (this.rememberme) {
      this.cookieService.set('email', this.crpto.encryption(this.email, "email"));
      this.cookieService.set('password', this.crpto.encryption(this.password, 'password',));
      this.cookieService.set('rememberme', 'Yes')
      this.cookieService.set('module', 'Nutritionist')
    } else {
      this.cookieService.set('email', "");
      this.cookieService.set('password', "");
      this.cookieService.set('rememberme', "")
    }

    var loginDetails = { "userName": this.LoginDetails.value.email, "type": 'NUTRITIONIST', "password": this.LoginDetails.value.password }


    this.login.Login(loginDetails).subscribe(res => {

      if (res['data'] != null && res['userSession'] != null) {
        var user = res
        var userData = user['data']
        if (userData.verificationDone == 'N') {
          this.verifyNowButton = true;
        }
        else {
          this.globalStorage.setItem('userSession', JSON.stringify(user['userSession']));
          this.firstSession = this.globalStorage.setItem('userSession', JSON.stringify(user['userSession']));
          this.globalStorage.setItem('firstName', JSON.stringify(userData.firstName));
          this.globalStorage.setItem('lastLogin', JSON.stringify(userData.lastLogin));
          this.globalStorage.setItem('doctorFirstName', userData.firstName);
          this.globalStorage.setItem('doctorLastName', userData.lastName)
          this.globalStorage.setItem('email', userData.emailId);
          this.globalStorage.setItem('loggedInAs', 'NUTRITIONIST')
          this.globalStorage.setItem('doctorGender', userData.gender)
          sessionStorage.setItem('loginDetails', JSON.stringify(res));
          this.globalStorage.setItem('LoginDetails', JSON.stringify(res));
          this.globalStorage.setItem('dpswd', this.LoginDetails.value.password)
          this.globalStorage.setItem('doctorId', JSON.stringify(userData.doctorId));
          this.globalStorage.setItem('forUserId', JSON.stringify(userData.doctorId));
          this.globalStorage.setItem('userId', JSON.stringify(userData.doctorId));
          this.globalStorage.setItem('entityId', JSON.stringify(userData.doctorId));
          this.apiservice.isUserSessionExits = true
          this.apiservice.isforUserIdExits = true
          this.apiservice.ismoduleNameExits = true
          // this.today = moment(userData.lastLogin).format('YYYY-MM-DD HH:mm:ss');
          // this.doctorSaervice.navigationDetails();
          // this.router.navigate(['dashboard']);
          // setTimeout(() => { this.loadNotifications() }, 2000)
          this.globalStorage.setItem('isPersonalDetailCompleted', userData?.isPersonalDetailCompleted);
          this.globalStorage.setItem('isFeeDetailCompleted', userData?.isFeeDetailCompleted);
          this.globalStorage.setItem('isAddressCompleted', userData?.isAddressCompleted);
          if (this.globalStorage.getItem('isPersonalDetailCompleted') == 'N' || this.globalStorage.getItem('isPersonalDetailCompleted') != 'Y') {
            this.router.navigate(['/personalDetails']);
          }
          else {
            if (this.globalStorage.getItem('isAddressCompleted') != 'Y') {
              this.router.navigate(['/addressDetails'])
            }
            else {
              if (this.globalStorage.getItem('isFeeDetailCompleted') != 'Y') {
                this.router.navigate(['/EditProfile'])
              }
              else {
                this.router.navigate(['dashboard']);
              }
            }
          }
        }

      }
      if (this.firstSession != this.firstSession) {
        this.router.navigate(['dashboard']);
      }
      if (res['status'] == 'FAILURE') {
        this.invalidCredentials = true;
        this.message = res['message'];
      }

    }
      , error => {

        var message = error['error'].message
        if (message != null) {
          this.invalidCredentials = true
          this.message = error['error'].message
          if (this.message == 'You have not verified your email address.') {
            this.invalidCredentials = true
            //this.message=error['error']['message']
            //this.verifyNowButton = true
          }
        } else {
          this.invalidCredentials = true
          this.message = error['error'].message
        }
      })
  }

  onCancel() {
    this.LoginDetails.reset();
    this.submitted = false;
    if (this.LoginDetails.invalid) {
      return false;
    }
  }
  loginClose() {
    this.router.navigate(['']);
  }
  remembermeCheck() {
    if (this.rememberme) {
      this.rememberme = false;
    } else {
      this.rememberme = true;
    }
  }

  loadNotifications() {
    // this.patientController.getNotificationDoctorForWeek(this.today, this.globalStorage.getItem('doctorId'), this.globalStorage.getItem('doctorId')).subscribe(res => {
    //   this.globalStorage.setItem('notificationCount', res['data'].length)
    //   var array = res['data'].reverse().slice(-5)
    //   for (var i = 0; i < array.length; i++) {
    //     this.ToastrService.info(res['data'][i]['reason'], res['data'][i]['objectType'], {
    //       positionClass: 'toast-bottom-right'
    //     })
    //   }
    // }, error => {
    // })
  }

  changePassword() {
    var data = this.globalStorage.getItem('LoginDetails')
    var obj = JSON.parse(data)
    var message
    var request = {
      "emailId": obj['data']['emailId'],
      //"resetPasswordLink": "http:localhost:4200/resetPassword"
    }
    this.forgot.forgotPassword(JSON.stringify(request)).subscribe(res => {
      this.ToastrService.success(res['message'])
    }, error => {
      message = error['error'].message
    })
  }

  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });
  }


}
