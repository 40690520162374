import { GlobalStorageService } from 'src/app/global-storage.service';
import { formatDate } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { AuthService } from '../../services/auth.service';
import { ObservableService } from '../../services/observable.service';
import { PatientService } from '../../services/patient.service';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
declare var $: any;
import * as moment from 'moment';
import { resolve4 } from 'dns';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import html2canvas from 'html2canvas';
import jspdf from 'jspdf';
import { UserVitalsComponent } from 'src/app/common-components/user-vitals/user-vitals.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderStatusComponent } from '../order-status/order-status.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  @Input() item: any;
  @ViewChild('pdfTable') pdfTable: ElementRef;
  @Input() notificationsData: any
  @Input() personalDetails: any
  public downloadAsPDF() {

    var data = document.getElementById('contentToConvertTwo');
    $('.prescribedBy').css('display', 'block');
    $('.invoice-info').css('display', 'none')
    html2canvas(data).then(canvas => {
      $('.prescribedBy').css('display', 'none');
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('newPDF.pdf');
    });
    $('.invoice-info').css('display', 'block');
  }

  notificationLength
  nonViewData = []
  viewedNotifications
  ViewedNotifications = []
  notificationCount
  userName
  personalData
  userEmailId
  clicked: boolean = false;
  associatedUserData
  firstName
  lastName
  lastLogin
  imageSource
  message
  birthdate
  age
  gender
  userId
  latestVitals
  vitalsLastUpadatedDate
  notications
  Bp
  height
  weight = ""
  pulse
  temperature
  oxygenLevel
  reminders = []
  setItemsPerPage = 6;
  p4 = 1
  file
  hai
  PatientpersonalData
  setPersonalDetails
  medicalTests
  notViewedNotifications
  BloodGroup: any;
  FirstFormGroup: FormGroup;
  formGroupBritish: FormGroup;
  loadVitals: FormGroup;
  unitsForm: FormGroup;
  formGroupType;
  isChecked: boolean = false;
  selectedCountry;
  submitted = false;
  dateTime = new Date();
  show = false;
  buttonName = "British";
  medications = []
  hide: any;
  notifyToView
  p2 = 1; patientName;
  converted; diastolic; systolic;
  bloodGlucosePostlunchId; bloodGlucosePrelunchId; glucolseLevelPreLunch; glucolseLevelPostLunch; diastolicId; systolicId;
  currentDate; heightVitalId; weightVitalId; pulseVitalId; bloodPressureVitalId; temperatureVitalId; oxygenLevelVitalId; BloodGroupId
  @ViewChild("input", { static: false })
  heartPulse = '';
  // height='';
  // weight='';
  // Bp='';
  // temp='';
  // oxygenLevel='';
  // BloodGroup='';
  pulseValue = ''
  constructor(private globalStorage: GlobalStorageService, private router: Router, private getPrescrtion: DoctorPatientService, private patientServices: AuthService, private sanitizer: DomSanitizer, private observable: ObservableService,
    private toaster: ToastrService, private patientController: PatientScheduleControlService, private fb: FormBuilder,
    private profileImage: PatientService,private modalService: NgbModal) {

    // this.patientServices.loginUsertObservable.subscribe(res=>{


    //   this.lastLogin=res['lastLogin']

    //   this.setPersonalDetails=res['personalData']
    //   if(this.setPersonalDetails['profilePic']!=null){
    //     this.imageSource ="data:image/jpeg;base64,"+this.setPersonalDetails['profilePic']
    //     this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.imageSource);
    //   }else{
    //   this.imageSource="/assets/img/img_avatar.png";
    //  // this.imageSource="/assets/img/user/11.png"
    //   }

    // },eror=>{
    //    this.toaster.error("Error getting user data")
    // });

    this.observable.invokeEvent.subscribe(value => {
      if (value === 'someValue') {
        this.patientVitals()
      }
    });
    this.observable.invokeEvent_vitals.subscribe(value => {
      if (value === 'someValue') {
        this.patientVitals()
      }
    });
    this.observable.invokeEvent_ChangeData.subscribe(value => {
      if (value === 'someValue') {

        this.profileYwo(this.personalData)
      }
    });

    //edit vitals
    this.FirstFormGroup = fb.group({
      date: [""],
      weight: [
        "",
        [Validators.required, Validators.min(3), Validators.max(400)],
      ],
      oxygenLevel: [
        "",
        [Validators.required, Validators.min(95), Validators.max(100)],
      ],
      pulse: [
        "",
        [Validators.required, Validators.min(60), Validators.max(200)],
      ],
      bloodPressure: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "(29[0-9]|2[0-9][0-9]|[01]?[0-9][0-9]?)/(29[0-9]|2[0-9][0-9]|[01]?[0-9][0-9]?)"
          ),
        ],
      ],
      diastolic: [''],
      systolic: [''],
      afterLunch: [''],
      beforeLunch: [''],
      temperature: [
        "",
        [Validators.required, Validators.min(96), Validators.max(110)],
      ],
      height: ["", [Validators.required, Validators.min(1), Validators.max(10)]],
    });
    this.formGroupBritish = fb.group({
      date: [""],
      weight: [
        "",
        [Validators.required, Validators.min(6), Validators.max(400)],
      ],
      oxygenLevel: [
        "",
        [Validators.required, Validators.min(95), Validators.max(100)],
      ],
      pulse: [
        "",
        [Validators.required, Validators.min(60), Validators.max(200)],
      ],
      bloodPressure: [
        "",
        [

          Validators.pattern(
            "(29[0-9]|2[0-9][0-9]|[01]?[0-9][0-9]?)/(29[0-9]|2[0-9][0-9]|[01]?[0-9][0-9]?)"
          ),
        ],
      ],
      diastolic: [''],
      systolic: [''],
      afterLunch: ['', [Validators.required, Validators.min(70),]],
      beforeLunch: ['', [Validators.required, Validators.min(70),]],
      temperature: [
        "",
        [Validators.required, Validators.min(96), Validators.max(110)],
      ],
      height: ["", [Validators.required, Validators.min(1), Validators.max(10)]],
    });
    this.loadVitals = fb.group({
      recordedDate: [""],
    });
  }
  ImageSrc
  ngOnInit(): void {
    this.patientVitals();
    this.currentDate = new Date()
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    this.profileImage.myMethod$.subscribe((data) => {
      this.associatedUserData = data; // And he have data here too!
    })
    this.associatedUserData = this.item
    // this.associatedUserData = this.patientController.sharingData
    if (this.associatedUserData) {
      this.firstName = this.associatedUserData.firstName
      this.lastName = this.associatedUserData.lastName
      this.userId = this.associatedUserData.relationUserId
      this.patientName = this.associatedUserData.firstName + ' ' + this.associatedUserData.lastName
      this.age = obj?.['age']
      this.gender = obj?.['gender']
    }
    else {
      this.firstName = obj?.['firstName']
      this.lastName = obj?.['lastName']
      var obj = JSON.parse(data);
      this.userId = obj["userId"];
      this.patientName = this.globalStorage.getItem('firstName') + " " + this.globalStorage.getItem('lastName')
      this.age = obj?.['age']
      this.gender = obj?.['gender']
    }
    this.getSavedMedicalTests()
    var req = {
      "forUserId": this.userId,
      "userId": obj['userId']
    }
    //this.patientController.getUserPersonalDetails('personaldetails/get/', this.userId, req).subscribe(res => {
    this.personalData = this.personalDetails
    // this.patientName= this.personalDetails?.['patientName']
    // if(this.personalDetails==undefined || this.personalDetails==null){
    // this.patientController.getUserPersonalDetails('personaldetails/get/', this.userId, req).subscribe(res => {
    //   this.personalDetails=res['data']
    //   this.personalData = this.personalDetails
    //   // this.patientName= this.personalDetails?.['patientName']
    //   this.age = this.personalDetails?.['dob']

    //   this.gender = this.personalDetails?.['gender']
    //   this.BloodGroup = this.personalDetails?.['bloodgroup']
    // })
    //this.patientName=obj?.firstName + " " + obj?.lastName
    // }
    // else{

    // }
    this.height = this.globalStorage.getItem('height')
    this.FirstFormGroup.patchValue({
      height: this?.height
    })
    this.formGroupBritish.patchValue({
      height: this?.height
    })
    // })
    this.loadMedication()
    this.profile()
    this.profileYwo(this.personalData)
    this.getNotifications()
    this.getDetails()
    var data = this.globalStorage.getItem("userData");
    // var obj = JSON.parse(data);
    // this.userId = obj["userId"];

    this.formGroupType = this.FirstFormGroup;
    // this.formGroupBritish.patchValue({
    //   height:this.height
    // })
    if (this.associatedUserData) {
      this.patientServices.getUserDataByEmail(this.associatedUserData?.emailId, this.associatedUserData?.userName, this.userId).subscribe(res => {
        this.PatientpersonalData = res['data']['personaldetail']
        this.BloodGroup = this.PatientpersonalData?.bloodgroup
      })
    }
    else {
      var req = {
        "forUserId": this.userId,
        "userId": obj['userId']
      }
      // this.patientController.getUserPersonalDetails('personaldetails/get/', this.userId, req).subscribe(
      //   (res) => {
      this.BloodGroup = this.globalStorage.getItem('bloodGroup');

      if (this.globalStorage.getItem('glucolseLevelPreLunch') != 'undefined') {
        this.glucolseLevelPreLunch = this.globalStorage.getItem('glucolseLevelPreLunch')
      }
      if (this.globalStorage.getItem('glucolseLevelPostLunch') != 'undefined') {
        this.glucolseLevelPostLunch = this.globalStorage.getItem('glucolseLevelPostLunch')
      }
      if (this.globalStorage.getItem('pulse') != 'undefined') {
        this.pulse = this.globalStorage.getItem('pulse')
      }
      if (this.globalStorage.getItem('bp') != 'undefined') {
        this.Bp = this.globalStorage.getItem('bp')
      }
      this.formGroupBritish.patchValue({
        // height:res['body']['data']['height'],
      })

      //   },
      //   (error) => {
      //     // this.toaster.info('Please fill the personal and address details')
      //     //this.router.navigate(["/pat-settings"]);
      //   }
      // );
    }



  }

  get f() {
    return this.FirstFormGroup.controls;
  }
  get b() {
    return this.formGroupBritish.controls;
  }
  check(x) {
    var today = new Date()
    var month = today.getMonth() + 1
    var year = today.getFullYear()
    var d = today.getDate()
    var y = new Date(x).getMonth() + 1
    var z = new Date(x).getFullYear()
    var p = new Date(x).getDate()
    if (y == month && year == z && d == p) {
      return true
    }
  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  getSavedMedicalTests() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    var req = {
      "forUserId": this.userId,
      "userId": obj['userId']
    }
    this.patientController.getPrescriptionPostSession('prescription/getprecriptionbyuserId/' + this.p2 + '/4/' + this.userId, req, this.userId).subscribe(res => {
      // this.patientController.postGlobal('prescription/getallprescription',this.userId).subscribe(res=>{
      this.medications = res['data']
      //this.medications =this.medications.filter(obj=>obj.prescriptionFill == 'N')
    }, error => {
      this.toaster.error(error['error']['message'])
    })
  }
  Clicked() {
    this.clicked = true;
  }
  vitals() {
    this.router.navigate(['/vitals-view'])
  }

  dashboard() {
    this.router.navigate(['/p-dashboard'])
  }
  appointments() {
    this.router.navigate(['/main-Appointments'])
  }
  pro
  profile() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    var id
    if (this.associatedUserData) {
      id = this.associatedUserData.relationUserId
    }
    else {
      id = obj['userId']
    }
    this.userId = obj['userId']
    var request = {
      "forUserId": id,
      "userId": obj['userId']
    }

    // this.profileImage.getPersonalDetails(request, id).subscribe(res => {

    if (this.personalDetails?.['profilePic']) {
      var src = "data:image/jpeg;base64," + this.personalDetails?.['profilePic']
      this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(src);
    } else {
      //   if(res['body']['data']?.['gender']=="Male"){
      //     this.imageSource="./assets/img/img_avatar.png";
      //   }
      //  else{
      //   this.imageSource="./assets/img/user/11.png";gen
      //  }
    }

    // }, error => {

    //   // this.router.navigate(['/pat-settings'])

    // })
  }
  length
  dotFunction(x) {
    var j: string = x;
    if (j.length == 1) {
      return this.FirstFormGroup.patchValue({
        height: j + '.'
      })
    }
    if (j.length == 3 && parseInt(j.charAt(2)) > 1) {
      return this.length = 3
    }
    if (j.length == 3 && parseInt(j.charAt(2)) == 1) {
      return this.length = 4
    }
    if (parseInt(j.charAt(3)) > 1) {
      return this.length = 3
    }
  }
  loadMedication() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    if (this.associatedUserData) {
      this.userId = this.associatedUserData.relationUserId
      var body = {
        "forUserId": this.associatedUserData.relationUserId,
        "isOnlyActive": true,
        "pageNum": 1
      }
    }
    else {
      this.userId = obj['userId']
      var body = {
        "forUserId": obj['userId'],
        "isOnlyActive": true,
        "pageNum": 1
      }
    }

    this.patientController.globalPost('prescription/medicaltest/view/all', body, this.userId).subscribe(res => {

      this.medicalTests = res['data'].filter((obj) => obj.patientId == this.userId)
    })
  }
  getNotifications() {
    var id
    if (this.associatedUserData) {
      id = this.associatedUserData.relationUserId
    }
    else {
      var data = this.globalStorage.getItem('userData')
      var obj = JSON.parse(data)
      id = obj['userId']
    }
    this.lastLogin = new Date(obj?.['lastLogin'])
    this.patientController.getGlobal('notificationalerts/get/all?maxResult=100&pageNumber=' + this.p4 + '&userId=' + this.userId, id).subscribe(res => {

      this.reminders = res['data'];
      this.notificationLength = this.reminders?.filter((obj) => obj?.viewed == 'N')?.length;
      this.nonViewData = this.reminders?.filter((obj) => obj?.viewed == 'N');
      this.ViewedNotifications = this.reminders?.filter((obj) => obj?.viewed == 'Y');
    })
  }
  bloodgroup
  patientVitals() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    if (this.associatedUserData) {
      this.userId = this.associatedUserData.relationUserId
    }
    else {
      var data = this.globalStorage.getItem('userData')
      var obj = JSON.parse(data)
      this.userId = obj['userId']
    }
    var request = {
      "forUserId": this.globalStorage.getItem('forUserId'),

      "userId": this.globalStorage.getItem('userId'),

    }
    this.profileImage.getAllVitalsByDayByDay(request, this.userId).subscribe(res => {
      var array = res.body.data
      this.vitalsLastUpadatedDate = array?.[0]?.['lastUpdatedTs'];
      var vitals = []

      for (var i = 0; i < array?.length; i++) {
        vitals.push((array?.[i]?.vitalValue))
        if (array?.[i]?.vitalname == "height") {
          this.height = this.height
          this.heightVitalId = array?.[i]?.vitalsId
        }
        if (array?.[i]?.vitalname == "weight") {
          this.weight = array?.[i]?.vitalValue
          this.weightVitalId = array?.[i]?.vitalsId
        }
        if (array?.[i]?.vitalname == "pulse") {
          this.pulse = array?.[i]?.vitalValue
          this.pulseVitalId = array?.[i]?.vitalsId
        }
        if (array?.[i]?.vitalname == "bloodPressure") {
          this.Bp = array?.[i]?.vitalValue
          this.bloodPressureVitalId = array?.[i]?.vitalsId
        }
        if (array?.[i]?.vitalname == "temperature") {
          this.temperature = array?.[i]?.vitalValue
          this.temperatureVitalId = array?.[i]?.vitalsId
        }
        if (array?.[i]?.vitalname == "oxygenLevel") {
          this.oxygenLevel = array?.[i]?.vitalValue
          this.oxygenLevelVitalId = array?.[i]?.vitalsId
        }
        if (array?.[i]?.vitalname == "random") {
          this.glucolseLevelPostLunch = array?.[i]?.vitalValue
          this.bloodGlucosePostlunchId = array?.[i]?.vitalsId
        }
        if (array?.[i]?.vitalname == "fasting") {
          this.glucolseLevelPreLunch = array?.[i]?.vitalValue
          this.bloodGlucosePrelunchId = array?.[i]?.vitalsId
        }
        if (array?.[i]?.vitalname == "systolic") {
          this.systolic = array?.[i]?.vitalValue
          this.systolicId = array?.[i]?.vitalsId
        }
        if (array?.[i]?.vitalname == "diastolic") {
          this.diastolic = array?.[i]?.vitalValue
          this.diastolicId = array?.[i]?.vitalsId
        }
        if (array?.[i]?.vitalname == "bloodgroup") {
          this.BloodGroup = array?.[i]?.vitalValue
          this.BloodGroupId = array?.[i]?.vitalsId
        }
        if (array?.[i]?.vitalname == '') {
          this.bloodgroup = array?.[i]?.vitalValue
        }
      }
      this.latestVitals = vitals[0]

      // this.latestVitals = JSON.parse(this.latestVitals)
      if (this.latestVitals != undefined) {
        // this.Bp=this.latestVitals['bloodPressure']
        // this.height=this.latestVitals['height']
        // this.weight=this.latestVitals['weight']
        // this.pulse=this.latestVitals['pulse']
        // this.temperature=this.latestVitals['temperature']
        // this.oxygenLevel=this.latestVitals['oxygenLevel']
        var str = this.weight;
        // str = str.toString();
        str = str.slice(0, -2);

        var htstr = this.height
        // htstr = htstr.toString();
        //htstr = htstr.slice(0, -1);
        var pulsestr = this.pulse
        pulsestr = pulsestr?.toString();
        pulsestr = pulsestr?.slice(0, -3);
        var tempstr = this.temperature
        tempstr = tempstr?.toString();
        tempstr = tempstr?.slice(0, -1);
        var oxystr = this.oxygenLevel
        oxystr = oxystr?.toString();
        oxystr = oxystr?.slice(0, -1);
        var bpstr = this.Bp
        bpstr = bpstr?.toString();
        bpstr = bpstr?.slice(0, -5);
        var htstr = this.height
        this.formGroupBritish.patchValue({
          weight: this?.weight,
          height: this?.height,
          pulse: this?.pulse,
          temperature: this?.temperature,
          oxygenLevel: this?.oxygenLevel,
          bloodPressure: this?.Bp,
          afterLunch: this?.glucolseLevelPostLunch,
          beforeLunch: this?.glucolseLevelPreLunch,
          systolic: this?.systolic,
          diastolic: this?.diastolic
        });

      }
    })
  }
getDetails(){
  var data = this.globalStorage.getItem('userData')
  var obj = JSON.parse(data)
  var request = {
    "forUserId": this.userId,
    "userId": obj['userId']
  }
  this.profileImage.getPersonalDetails(request, obj['userId']).subscribe(res => {
    console.log('res',res)
  var src = "data:image/jpeg;base64," + res['body']?.['data']?.['profilePic']


  this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(src);


   })
}

  submitPrimary() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)



    var formData = new FormData();
    for (let i = 0; i < this.file.length; i++) {
      formData.append('profilePic', this.file[i], this.file[i].name);
    }
    this.profileImage.updateProfilePic(formData, this.userId).subscribe(res => {
      this.patientServices.navigationDetails()
      this.toaster.success('user Profile Updated')
      var request = {
        "forUserId": obj['userId'],
        "userId": obj['userId']
      }
       this.profileImage.getPersonalDetails(request, obj['userId']).subscribe(res => {
        console.log('res',res)
      var src = "data:image/jpeg;base64," + res['body']?.['data']?.['profilePic']


      this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(src);


       })
    })
  }
  onFileInput(files: File) {
    const f = files

    if ((f[0].type) == "image/jpeg" || (f[0].type) == "image/png") {
      if (f[0].size > 1048769) {
        this.toaster.warning('file should be 1 Mb')
      } else {
        this.file = files
      }


    } else {
      this.toaster.warning('file should be in jpeg or PNG')
    }
    this.submitPrimary()
  }

  profileYwo(ptdata) {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    if (this.associatedUserData) {
      this.userId = this.associatedUserData.relationUserId
      var req = {
        "forUserId": this.userId,
        "userId": obj['userId']
      }
    }
    else {
      this.userId = obj['userId']
      var req = {
        "forUserId": this.userId,
        "userId": obj['userId']
      }
    }
    // this.patientController.getUserPersonalDetails('personaldetails/get/', this.userId, req).subscribe(res => {
    if (ptdata?.['profilePic'] == "") {
      // this.imageSource = "./assets/img/img_avatar.png";
    }
    if (ptdata?.['dob'] == null) {
      // this.router.navigate(['/pat-personal-details'])
    }

    // }, error => {
    //   // this.imageSource = "./assets/img/img_avatar.png";
    // })
  }

  updateNotify(data) {
    $('#notificationViewer').modal('show');
    var req = {
      "active": data.active,
      "notificationalertsId": data.notificationalertsId,
      "objectkey": data.objectkey,
      "objecttype": data.objecttype,
      "patientId": data.patientId,
      "reason": data.reason,
      "viewed": "Y"
    }
    this.patientController.globalPostBody(data.notificationalertsId, data.patientId, req).subscribe(res => {
      this.notificationLength = this.notificationLength - 1;
      this.getNotifications();
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  weightLevel; wtlessMsg = false; wtExtraMsg = false
  checkWight(weight, height) {
    if (height == '4.6') this.weightLevel = "63-77";
    if (height == '4.7') this.weightLevel = "68-84"; if (height == '4.8') this.weightLevel = "72-88"; if (height == '4.9') this.weightLevel = "77-94";
    if (height == '4.10') this.weightLevel = "81-99"; if (height == '4.11') this.weightLevel = "86-105"; if (height == '5.0') this.weightLevel = "90-110";
    if (height == '5.1') this.weightLevel = "95-116"; if (height == '5.2') this.weightLevel = "99-121"; if (height == '5.3') this.weightLevel = "104-127";
    if (height == '5.4') this.weightLevel = "108-132"; if (height == '5.5') this.weightLevel = "113-138"; if (height == '5.6') this.weightLevel = "117-143";
    if (height == '5.7') this.weightLevel = "122-149"; if (height == '5.8') this.weightLevel = "126-154"; if (height == '5.9') this.weightLevel = "131-160";
    if (height == '5.10') this.weightLevel = "135-165"; if (height == '5.11') this.weightLevel = "140-171"; if (height == '6.0') this.weightLevel = "144-176";
    if (height == '6.1') this.weightLevel = "149-182"; if (height == '6.2') this.weightLevel = "153-187"; if (height == '6.3') this.weightLevel = "158-193";
    if (height == '6.4') this.weightLevel = "162-198"; if (height == '6.5') this.weightLevel = "167-204";
    var arr = this.weightLevel?.split("-");
    if (weight < arr[0]) this.wtlessMsg = true; else { this.wtlessMsg = false }
    if (weight > arr[1]) this.wtExtraMsg = true; else { this.wtExtraMsg = false }
  }
  editVitals() {
    $('#addvitals').modal('show')
    this.FirstFormGroup.value.weight = this.weight
  }

  submit(item: any) {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    this.userId = obj['userId']
    if (this.associatedUserData) {
      this.userId = this.associatedUserData.relationUserId
    }
    else {
      var data = this.globalStorage.getItem('userData')
      var obj = JSON.parse(data)
      this.userId = obj['userId']
    }

    const date = formatDate(
      this.dateTime,
      "yyyy-MM-dd'T'HH:mm:ss.SSS",
      "en-us"
    );

    this.submitted = true;

    // stop here if form is invalid
    if (item.invalid) {
      return;
    }

    delete item.value.date;

    var user = {
      forUserId: this.userId,
      relationType: "DOCTOR",
      userId: obj['userId'],
    };
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    if (this.show == true) {
      var request = {
        "forUser": {
          "forUserId": this.userId,
          "userId": obj['userId']
        },
        vitals: [
          {
            "vitalsId": this.oxygenLevelVitalId,
            "userId": this.userId,
            "metricType": "%",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.oxygenLevel,
            "vitalname": "oxygenLevel"
          },
          {
            "vitalsId": this.bloodGlucosePrelunchId,
            "userId": this.userId,
            "metricType": "%",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.beforeLunch,
            "vitalname": "fasting"
          },
          {
            "vitalsId": this.bloodGlucosePostlunchId,
            "userId": this.userId,
            "metricType": "%",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.afterLunch,
            "vitalname": "random"
          },
          {
            "vitalsId": this.pulseVitalId,
            "userId": this.userId,
            "metricType": "BPM",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.pulse,
            "vitalname": "pulse"
          },
          {
            "vitalsId": this.temperatureVitalId,
            "userId": this.userId,
            "metricType": "C",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.temperature,
            "vitalname": "temperature"
          },
          {
            "vitalsId": this.bloodPressureVitalId,
            "userId": this.userId,
            "metricType": "mm/Hg",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.systolic + '/' + item.value.diastolic,
            "vitalname": "bloodPressure"
          },
          {
            "vitalsId": this.systolicId,
            "userId": this.userId,
            "metricType": "mm/Hg",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.systolic,
            "vitalname": "systolic"
          },
          {
            "vitalsId": this.diastolicId,
            "userId": this.userId,
            "metricType": "mm/Hg",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.diastolic,
            "vitalname": "diastolic"
          },
          {
            "vitalsId": this.heightVitalId,
            "userId": this.userId,
            "metricType": "Cms",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.height,
            "vitalname": "height"
          },
          {
            "vitalsId": this.weightVitalId,
            "userId": this.userId,
            "metricType": "lb",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.weight,
            "vitalname": "weight"
          },
        ],
      };
    } else {

      request = {
        "forUser": {
          "forUserId": this.userId,
          "userId": obj['userId']
        },
        vitals: [
          {
            "vitalsId": this.oxygenLevelVitalId,
            "userId": this.userId,
            "metricType": "%",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.oxygenLevel,
            "vitalname": "oxygenLevel"
          },
          {
            "vitalsId": this.bloodGlucosePrelunchId,
            "userId": this.userId,
            "metricType": "%",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.beforeLunch,
            "vitalname": "fasting"
          },
          {
            "vitalsId": this.bloodGlucosePostlunchId,
            "userId": this.userId,
            "metricType": "%",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.afterLunch,
            "vitalname": "random"
          },
          {
            "vitalsId": this.pulseVitalId,
            "userId": this.userId,
            "metricType": "BPM",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.pulse,
            "vitalname": "pulse"
          },
          {
            "vitalsId": this.temperatureVitalId,
            "userId": this.userId,
            "metricType": "C",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.temperature,
            "vitalname": "temperature"
          },
          {
            "vitalsId": this.bloodPressureVitalId,
            "userId": this.userId,
            "metricType": "mm/Hg",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.systolic + '/' + item.value.diastolic,
            "vitalname": "bloodPressure"
          },
          {
            "vitalsId": this.systolicId,
            "userId": this.userId,
            "metricType": "mm/Hg",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.systolic,
            "vitalname": "systolic"
          },
          {
            "vitalsId": this.diastolicId,
            "userId": this.userId,
            "metricType": "mm/Hg",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.diastolic,
            "vitalname": "diastolic"
          },
          {
            "vitalsId": this.heightVitalId,
            "userId": this.userId,
            "metricType": "Cms",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.height,
            "vitalname": "height"
          },
          {
            "vitalsId": this.weightVitalId,
            "userId": this.userId,
            "metricType": "lb",
            "recordedDate": moment(this.dateTime).format('YYYY-MM-DD'),
            "vitalValue": item.value.weight,
            "vitalname": "weight"
          },
        ],
      };
    }
    var vital = {
      recordedDate: date.concat("Z"),
      userId: this.userId,
      vitalValue: JSON.stringify(item.value),
    };
    // var request = {
    //   forUser: user,
    //   vitals: [vital],
    // };
    this.height = item.value.height
    // this.height=this.height.toString();
    // this.height = this.height.slice(0, -3);
    var req = {
      "dob": this.personalData.DOB,
      "ethinicity": this.personalData.ethinicity,
      "eyeColor": this.personalData.eyeColor,
      "hairColor": this.personalData.hairColor,
      "height": this.height,
      "highestDegree": this.personalData.highestDegree,
      "nationality": this.personalData.nationality,
      "profilePic": this.personalData.profilePic,
      "specialization": this.personalData.specialization,
      "gender": this.personalData.gender,
      "martialstatus": this.personalData.martialstatus,
      "bloodgroup": this.personalData.bloodgroup,
      "preferredlanguages": this.personalData?.preferredlanguages?.toString(),
      "userId": this.userId
    }
    req['personalDetailId'] = this.personalData.personalDetailId
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)
    this.patientController.globalPost('personaldetails/add', req, this.userId).subscribe(res => { })
    this.profileImage
      .saveVitals(JSON.stringify(request), this.userId)
      .subscribe((res) => {
        if (res.body.status == "SUCCESS") {
          this.toaster.success("Vitals updated.");
          this.FirstFormGroup.reset();
          // this.patientVitals();
          this.observable.callMethodOfSecondComponent();
          this.submitted = false
          $('#addvitals').modal('hide')
        } else {
          this.toaster.error(res.body.message);
        }
      }, error => {
      });
    $('#addvitals').modal('hide')
  }

  onExpiryKeyup($event: KeyboardEvent) {
    // if (!isFinite(Number($event.key))) {
    //   return;
    // }

    // const input = $event.target as HTMLInputElement;
    // const num = Number(input.value.replace(/\s\/\s/, ''));
    // if (num > 1 && num < 10) {
    //   input.value = `${num} / `;
    // } else if (num > 12 && num < 20) {
    //   input.value = `01 / ${num - 10}`;
    // }
  }

  closeModal() {
    $('#notificationToView').modal('hide')
    $('#notificationViewer').modal('hide')
    this.getNotifications();
    $("#notificationViewer").val(null).trigger("change");
    $("#notificationToView").val(null).trigger("change");
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    $('#viewreminder').modal('hide')
  }

  toggle() {
    this.show = !this.show;
    if (this.show) {
      this.buttonName = "American";
      this.FirstFormGroup.patchValue({
        weight: (this.formGroupBritish.value.weight / 2.205).toFixed(2),
        // height: (this.formGroupBritish.value.height / 30.48).toFixed(2),
        height: (this.formGroupBritish.value.height),
        // temperature: (this.formGroupBritish.value.temperature * 9) / 5 + 32,
        temperature: (this.formGroupBritish.value.temperature),
        bloodPressure: this.formGroupBritish.value.bloodPressure,
        pulse: this.formGroupBritish.value.pulse,
        oxygenLevel: this.formGroupBritish.value.oxygenLevel,
      });
    } else {
      this.buttonName = "British";
      this.formGroupBritish.patchValue({
        weight: this.formGroupBritish.value.weight,
        height: this.formGroupBritish.value.height,
        // temperature: ((this.FirstFormGroup.value.temperature - 32) * 5) / 9,
        temperature: (this.FirstFormGroup.value.temperature),
        bloodPressure: this.FirstFormGroup.value.bloodPressure,
        pulse: this.FirstFormGroup.value.pulse,
        oxygenLevel: this.FirstFormGroup.value.oxygenLevel,
      });
    }
  }
  dStreet1; dStreet2; dState; dZip; dCountry; dCity; doctorAddress
  doctorAddressDetails(id) {
    this.patientController.getAddressDetails(id).subscribe(res => {
      this.doctorAddress = res['data']?.[0]
      this.dStreet1 = res['data']?.[0]?.['street1']
      this.dStreet2 = res['data']?.[0]?.['street2']
      this.dState = res['data']?.[0]?.['state']
      this.dCountry = res['data']?.[0]?.['country']
      this.dCity = res['data']?.[0]?.['city']
      this.dZip = res['data']?.[0]?.['zip']
    })
  }
  pstreet1; pstreet2; pstate; pcity; pcountry; pzip
  closeMedPresModal() { }
  downloadPDF() {
    var data = document.getElementById('contentToConvert');
    $('.invoice-info').css('display', 'none');

    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('newPDF.pdf');
    });
    $('.invoice-info').css('display', 'block')

  }
  checkProfile() {
    if (this.globalStorage.getItem('isProfileCompleted') == 'N') {
      this.router.navigate(['/pat-settings'])
    }
    else {
      if (this.globalStorage.getItem('isPersonalDetailcompleted') == 'N' || this.globalStorage.getItem('isPersonalDetailcompleted') != 'Y') {
        this.router.navigate(['/pat-personal-details'])
      }
      if (this.globalStorage.getItem('isPersonalDetailcompleted') == 'Y') {
        if (this.globalStorage.getItem('isAddrsscompleted') == 'N' || this.globalStorage.getItem('isAddrsscompleted') != 'Y') {
          this.router.navigate(['/pat-address-details'])
        }
      }
    }
  }
  addVitalsShow = false
  editOrAddVitals() {
    if (this.globalStorage.getItem('isPersonalDetailcompleted') == 'Y' && this.globalStorage.getItem('isAddrsscompleted') == 'Y' && this.globalStorage.getItem('isProfileCompleted') == 'Y') {
      // $('#addvitals').modal('show')
      // this.addVitalsShow = true
      const userVitals = this.modalService.open(UserVitalsComponent, {
        centered: false,
        backdrop: 'static',
        keyboard: false,
        windowClass: 'modal-left hw-30'
      });
      userVitals.componentInstance.user_id = this.userId;
      userVitals.result.then(
        (reason)=>{
          this.patientVitals();
        },
        (result)=>{
          this.patientVitals();
        }
      )
    }
    else {
      this.checkProfile()
    }
  }
  getPatientAddress(id) {
    var request = {

      "forUserId": id,
      "userId": id

    }


    this.patientController.getPatientAddress(request, id).subscribe(res => {
      this.patientName = res['body']['data'][0]['contactName']
      this.pstreet1 = res['body']['data'][0]['street1']
      this.pstreet2 = res['body']['data'][0]['street2']
      this.pstate = res['body']['data'][0]['state']
      this.pcity = res['body']['data'][0]['city']
      this.pcountry = res['body']['data'][0]['country']
      this.pzip = res['body']['data'][0]['zip']
    })
  }
  doctorName; precName; precId; nextFillRequestDate; MedicationPrescriptions; newsrc; showPdf; showImage; src; prescriptionImage = false; appointmentDate
  presentDate = new Date()
  today = moment(this.presentDate).format('YYYY-MM-DD');
  viewPrescription(prescription) {
    this.MedicationPrescriptions=[]
    $('#medicalPrescriptionDoctor').modal('show')
    this.doctorAddressDetails(prescription['doctorId'])
    this.getPatientAddress(this.userId)
    // this.pharmaAddress(1)
    this.appointmentDate = prescription['appointmentDate']
    this.doctorName = prescription['doctorName']
    this.precName = prescription?.['documentName']
    this.precId = prescription['medicalPrescriptionId']
    if (prescription.prescriptioncontainsImage == 'Y') {
      this.prescriptionImage = true
      if (prescription['prescriptionId'] != null) {
        this.patientController.postGlobal('prescription/getPrescriptionDetailsById?presciptionId=' + prescription['prescriptionId'], prescription['patientId']).subscribe(res => {
          if (res['data'] != null && (res['data']?.['documentName'].includes(".png") || res['data']?.['documentName'].includes(".jpeg"))) {
            this.src = "data:image/jpeg;base64," + res['data']['image']

            this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
            this.showImage = true

          } else if (res['data'] != null && (res['data']?.['image'].includes(".pdf"))) {
            this.newsrc = null
            this.showPdf = true

            this.src = "data:application/pdf;base64," + res['data']['image']
            this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
          }
        })
      }
    }
    else {
      this.prescriptionImage = false
      if (prescription['medicalPrescriptionId'] != null) {
        this.patientController.postGlobal('prescription/getprescriptionswithmedaction?medicalPresciptionId=' + prescription['medicalPrescriptionId'], prescription['patientId']).subscribe(res => {
          if (res) {
            this.nextFillRequestDate = moment(res['data']?.['medicalprescription']?.[0]?.['nextfilrequestdate']).format('YYYY-MM-DD');
          }
          this.MedicationPrescriptions = res['data']['medication']
          this.newsrc = null
          this.showPdf = false
          this.showImage = false
        })
      }

    }
    //  this.patientController.globalgetBody('prescription/getmedicalprescriptionbyid/',prescription['medicalPrescriptionId'],prescription['patientId']).subscribe(res=>{

  }
  medicalTestPrescription; testPrescDate
  viewTestPrescription(reports) {
    this.doctorAddressDetails(reports['doctorId'])
    this.getPatientAddress(this.userId)
    //this.pharmaAddress(3)
    this.precId = reports['medicaltestprescriptionId']
    this.doctorName = reports['doctorName']
    this.testPrescDate = reports['appointmentDate']
    this.patientController.postGlobal('prescription/getmedicaltestprescriptiondetails?medicalTestPresciptionId=' + reports['medicaltestprescriptionId'], this.userId).subscribe(res => {
      this.medicalTestPrescription = res['data']?.['medicaltest']?.reverse()

      this.newsrc = null
      this.showPdf = false
      this.showImage = false
      if (res['data']?.['prescriptions'] != null || (res['data']?.['prescriptions']?.[0]?.['documentName'].includes(".png") || res['data']?.['prescriptions']?.[0]?.['documentName'].includes(".jpeg"))) {
        this.src = "data:image/jpeg;base64," + res['data']?.['prescriptions']?.[0]?.['documentLocation']
        this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
        this.showImage = true
      } else if (res['data']?.['prescriptions'] != null && (res['data']?.['prescriptions']?.[0]?.['documentName'].includes(".pdf"))) {
        this.newsrc = null
        this.showPdf = true
        this.src = "data:application/pdf;base64," + res['data']?.['prescriptions']?.[0]?.['documentLocation']
        this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
      }
      $('#viewTestPrescription').modal('show')
    }, error => {
      this.toaster.error(error?.['error']?.['message'])
    })

  }
  navigateToDiagnosticSearch(test) {
    this.patientController.saveDoctorData(test)
    const navigationExtras: NavigationExtras = {
      state: {
        tests: test
      }
    };
    this.router.navigate(['/search-diagnostic'], navigationExtras)
  }
  navigateToSelectPharma() {
    this.patientController.saveDoctorData(this.precId);
    this.router.navigate(['/select-pharma'], {
      state: { example: this.precId }
    })
  }
  printDiv(divName) {
    var printContents = document.getElementById(divName).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    $('.display-btn').addClass('dis')

    window.print();

    document.body.innerHTML = originalContents;
    this.close()
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  close() {
    $('#viewTestPrescription').modal('hide')
    $('#medicalPrescriptionDoctor').modal('hide')
  }
  showStatus(orderDetails){
    const statusModal = this.modalService.open(OrderStatusComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: 'modal-left hw-60'
    });
    statusModal.componentInstance.prescriptionInfo = orderDetails;
    statusModal.result.then(
      (reason)=>{
        
      },
      (result)=>{
       
      }
    )
  }
}
