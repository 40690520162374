
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class gpsService {
  latitude
  longitude
  geoInfo
  constructor() {
    this.getCoordinates()
  }
  getCoordinates() {
    navigator.geolocation.getCurrentPosition(pos => {
      this.longitude = pos.coords.longitude,
        this.latitude = pos.coords.latitude,
        this.geoInfo = { latitude: this.latitude, longitudes: this.longitude }
    })
  }
}