import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
@Component({
  selector: 'app-medical-reports-list',
  templateUrl: './medical-reports-list.component.html',
  styleUrls: ['./medical-reports-list.component.css']
})
export class MedicalReportsListComponent implements OnInit {

  constructor(private doctorPatient:DoctorPatientService,private toastrService:ToastrService,private sanitizer:DomSanitizer) { }

  @Input() patient:any
  
  ngOnInit(): void {
    if(this.patient!=undefined){
      this.getPatientMedicalReports(this.patient)
    }
    
  }


  patientMedicalReports
  getPatientMedicalReports(appointment){
    var request=  {
        // "forUserId": appointment.forUserId,
        // "userId":appointment.userId
        "forUserId":appointment?.userId,
        "userId":appointment?.userId
      }



    this.doctorPatient.getPatientMedicalReports(request,1,50,).subscribe(res=>{
      var body=res['body']
      
      this.patientMedicalReports=body['data']
     },error=>{
    this.toastrService.error("error on getting patient medical reports")
     })
}

reportDetails
viewMedicalReport(report){
  var reportObj={
    'forUserId':report?.userId,
    medicalReportId:report?.medicalReportsId
  }
 this.doctorPatient.getPatientMedicalReportsByMrId(reportObj).subscribe(res=>{
  this.reportDetails=res['body']['data']
  this.doctorPatient.viewMedicalReport(this.reportDetails);
 },error=>{
      this.toastrService.error("Error in view Medical report")
 })
}
public ngOnChanges(changes: SimpleChanges){
  if ('patient' in changes){
    if(this.patient!=undefined){
    this.getPatientMedicalReports(this.patient);
    }
  }
}
}
