<div class="modal-header">
    <h4 class="centerHeader mb-0">Order Details</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="block inv-section">
        <div class="top-inv-col">
            <div class="inv-logo">
                <img alt="" src="./assets/img/logo.png">
            </div>
            <div class="float-right text-left order-details">
                <p>Order ID: <span> {{orderItems?.supplierordersId}} </span></p>
                <p>Date: <span>{{orderItems?.lastUpdatedTs|date:'dd-MMM-yyyy'}}</span></p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-xs-6">
                <h5>Invoice From</h5>
                <ul class="inv-receiver">
                    <li class=""> {{orderItems?.supplierName | titlecase }}
                        <br> <span
                            *ngIf="orderItems?.supplieraddress?.contactPhoneNumber != null">{{orderItems?.supplieraddress?.contactPhoneNumber}}</span>
                        {{orderItems?.supplieraddress?.street1}} <span
                            *ngIf="orderItems?.supplieraddress?.street2">,</span>{{orderItems?.supplieraddress?.street2}}
                        <br>
                        {{orderItems?.supplieraddress?.postalLocation}} ,
                        {{orderItems?.supplieraddress?.city}}, <br> {{orderItems?.supplieraddress?.state}}
                        , {{orderItems?.supplieraddress?.country}}
                        <br> zip - {{orderItems?.supplieraddress?.zip}}
                    </li>
                </ul>
            </div>
            <!-- <div class="col-md-6 col-xs-6">
                <h5>Invoice To</h5>
                <ul class="inv-receiver">
                    <li class="">
                        <br>
                        <br>

                        <br>
                    </li>
                </ul>
            </div> -->
            <!-- <div class="col-md-6">
                <div class="payment-method">
                    <h5>Payment Method</h5>
                    <ul>
                        <li> UPI </li>
                    </ul>
                </div>
            </div> -->
        </div>
        <!-- <table class="inv-table table table-striped">
            <tbody>
                <tr>
                    <th class="text-left">Catagory</th>
                    <th class="text-center">Sub Category</th>
                    <th class="text-center">Unit Price</th>
                    <th class="text-center">Quantity</th>
                    <th class="text-center">CGST</th>
                    <th class="text-center">SGST</th>
                    <th class="text-center">IGST</th>
                    <th class="text-center">Discount(%)</th>
                    <th class="text-right">Total</th>
                </tr>
                <tr>
                    <td>
                        <span class="eclips">{{orderItems?.catagoryName}}</span>
                    </td>
                    <td class="text-center">{{orderItems?.itemName}}</td>
                    <td class="text-center">{{orderItems?.unitPrice}}</td>
                    <td class="text-center">{{orderItems?.totalStockOrdered}}</td>
                    <td class="text-center">{{orderItems?.cgst ? orderItems?.cgst : '-'}}</td>
                    <td class="text-center">
                        {{orderItems?.sgst ? orderItems?.sgst : '-'}}
                    </td>
                    <td class="text-center">{{orderItems?.igst ? orderItems?.igst : '-'}}</td>
                    <td class="text-center">{{orderItems?.discount ? orderItems?.discount : '-'}}
                    </td>
                    <td class="float-right"><i class="fa fa-inr"
                            aria-hidden="true"></i>&nbsp;{{orderItems?.totalPrice}}
                    </td>
                </tr>
            </tbody>
        </table> -->

        <div>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Sub Category</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of orderItems">
                      <td>{{ orderItems.itemName }}</td>
                      <td><i class="fa fa-inr"></i> {{ orderItems.unitPrice }}</td>
                      <td>{{ orderItems.totalStockOrdered }}</td>
                      <td>{{ orderItems.deal }}</td>
                      <td>{{ orderItems.discount }}</td>
                      <td>{{ orderItems.igst }}</td>
                      <td>{{ orderItems.quantityToBeReceived }}</td>
                      <td>{{ orderItems.totalPrice }}</td>

                    </tr>
                </tbody>
                <tfoot>
                    <tr *ngIf="orderItems?.cgst">
                        <td colspan="4" class="text-right"><b>Tax ({{orderItems?.cgst + orderItems?.sgst}}
                                %):</b></td>
                        <td colspan="1"><i class="fa fa-inr" aria-hidden="true"></i>
                            {{ ((orderItems?.cgst + orderItems?.sgst) / 100) *
                            (orderItems?.unitPrice *
                            orderItems?.totalStockOrdered) }}
                        </td>
                    </tr>

                    <tr *ngIf="orderItems?.igst">
                        <td colspan="4" class="text-right"><b>Tax ({{orderItems?.igst}} %):</b></td>
                        <td colspan="1"><i class="fa fa-inr" aria-hidden="true"></i>
                            {{ ((orderItems?.igst) / 100) * (orderItems?.unitPrice *
                            orderItems?.totalStockOrdered) }}
                        </td>
                    </tr>

                    <tr *ngIf="orderItems?.cgst">
                        <td colspan="4" class="text-right"><b>Discount ({{orderItems?.discount}} %):</b></td>
                        <td colspan="1"><i class="fa fa-inr" aria-hidden="true"></i>
                            {{ ((orderItems?.unitPrice * orderItems?.totalStockOrdered) +
                                ((orderItems?.cgst + orderItems?.sgst) / 100) * (orderItems?.unitPrice *
                                orderItems?.totalStockOrdered))
                                *
                                (orderItems?.discount / 100) }}
                        </td>
                    </tr>

                    <tr *ngIf="orderItems?.igst">
                        <td colspan="4" class="text-right"><b>Discount ({{orderItems?.discount}} %):</b></td>
                        <td colspan="1"><i class="fa fa-inr" aria-hidden="true"></i>
                            {{ ((orderItems?.unitPrice * orderItems?.totalStockOrdered) +
                                ((orderItems?.igst) / 100) * (orderItems?.unitPrice *
                                orderItems?.totalStockOrdered))
                                * (orderItems?.discount / 100) }}
                        </td>
                    </tr>

                    <tr>
                        <td colspan="4" class="text-right"><b>Grand Total: </b></td>
                        <td colspan="1"><i class="fa fa-inr" aria-hidden="true"></i> {{orderItems?.totalPrice}}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
    <!-- <div class="invoice-info">
        <button class="btn btn-sm colr-3 ml-2 mt-2 p-2 patientbg3 pull-right" type="submit"
            (click)="downloadAsPDF()"> Download Invoice </button>
    </div> -->
</div>
