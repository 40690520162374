import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DiagnosticServiceController } from 'src/app/shared/services/diagnostic.service';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import { MustMatch } from 'src/app/_helpers/must_match.validators';

@Component({
  selector: 'app-diagnostic-create-password',
  templateUrl: './diagnostic-create-password.component.html',
  styleUrls: ['./diagnostic-create-password.component.css']
})
export class DiagnosticCreatePasswordComponent implements OnInit {

  resetPassword: FormGroup
  submitted = false
  verificationId
  successCredentials = false
  invalidCredentials = false
  message
  constructor(private fb: FormBuilder, private route: Router, private getProfile: DiagnosticServiceController, private toastr: ToastrService, private router: ActivatedRoute) {
    this.resetPassword = this.fb.group({
      otp: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.required,
      Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{8,15})')]],
      confirmPassword: ['', [Validators.required]]
    },
      {
        validator: MustMatch('newPassword', 'confirmPassword')
      })
    this.router.queryParams.subscribe(params => {
      this.verificationId = params['verificationid'];

    });
  }

  ngOnInit(): void {
    this.resetPass()
  }

  get f() { return this.resetPassword.controls; }
  resetPass() {
  }
  submit(item: any) {
    this.submitted = true;
    // stop here if form is invalid
    if (this.resetPassword.invalid) {
      return;
    }
    var request = {
      "newPassword": item.value.newPassword,
      "otp": item.value.otp,
      "verificationid": this.verificationId
    }
    this.getProfile.diagnosticPostSessionBody('resetPasswordForDiagnosticcenteruser', request).subscribe(res => {
      this.toastr.success("Specified password has been saved successfully")
      this.route.navigate(['/DiagnosticLogin'])
    }, error => {
      this.invalidCredentials = true
      this.message = error['error']['message']
      //this.toastr.error(error['error']['message'])
    })
  }
  getOtp() {
    this.getProfile.getOtp('verificationid', 'CREATE', this.verificationId).subscribe(res => {
      this.successCredentials = true
      this.message = "Check your email for OTP."
      //this.toastr.success(" Check your email for OTP.")
    }, error => {
      this.invalidCredentials = true
      this.message = error['error']['message']
      //this.toastr.error(error[error])
    })
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  showHidePwd() {
    if ($('#show_hide_password input').attr("type") == "text") {
      $('#show_hide_password input').attr('type', 'password');
      $('#show_hide_password i').addClass("fa-eye-slash");
      $('#show_hide_password i').removeClass("fa-eye");
    } else if ($('#show_hide_password input').attr("type") == "password") {
      $('#show_hide_password input').attr('type', 'text');
      $('#show_hide_password i').removeClass("fa-eye-slash");
      $('#show_hide_password i').addClass("fa-eye");
    }
  }
  showHidePwd1() {
    if ($('#show_hide_password1  input').attr("type") == "text") {
      $('#show_hide_password1 input').attr('type', 'password');
      $('#show_hide_password1 i').addClass("fa-eye-slash");
      $('#show_hide_password1 i').removeClass("fa-eye");
    } else if ($('#show_hide_password1 input').attr("type") == "password") {
      $('#show_hide_password1 input').attr('type', 'text');
      $('#show_hide_password1 i').removeClass("fa-eye-slash");
      $('#show_hide_password1 i').addClass("fa-eye");
    }
  }

}


