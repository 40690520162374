import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';

@Component({
  selector: 'app-prescription-list',
  templateUrl: './prescription-list.component.html',
  styleUrls: ['./prescription-list.component.css']
})
export class PrescriptionListComponent implements OnInit {
  constructor(private doctorPatient:DoctorPatientService,private toasterService:ToastrService) { }
  @Input() patientPrescription:any; 

  ngOnInit(): void {
    if(this.patientPrescription!=undefined){
      this.viewPrecription(this.patientPrescription)
  }
  }

  patientPrecriptionList:any[]=[];
  viewPrecription(appointment){
  var appointmentObj={
    "appointmentId":appointment.appointmentId,
    "patientId": appointment.userId
  }

  this.doctorPatient.getPatientPrecriptionByAppointmentId(appointmentObj).subscribe(res=>{
    this.patientPrecriptionList=res['data'];
   // this.viewPrescription.open();
  },error=>{
     this.toasterService.error("Error in geting prescription")
  })
  }

  public ngOnChanges(changes: SimpleChanges){
    if ('patientPrescription' in changes){
      if(this.patientPrescription!=undefined){
          this.viewPrecription(this.patientPrescription)
      }
    }
  }


}
