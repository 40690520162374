<div class="modal-header">
  <h5 class="modal-title">Add Vitals</h5>
  <!-- <mat-slide-toggle (click)="toggle()" class="ml-2" >{{buttonName}}</mat-slide-toggle> -->
  <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal($event)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <!-- <form [formGroup]="vs.FirstFormGroup" *ngIf="(vs.show)">
    <div class="row d-flex">
      <div class="col-md-6 col-xs-6 form-group" style="display: none;">
        <label for="exampleInputPassword1">Date & Time</label><span class="text-danger"></span>
        <input type="text" class="form-control mb-0" formControlName="date" [value]="vs.date" readonly />
      </div>

      <div class="col-md-6 col-xs-6 form-group">
        <label for="exampleInputPassword1">Weight</label><span class="text-secondary p-1">(Kg)</span>
        <input type="number" (keypress)="vs.numberOnly($event)" placeholder="Weight" class="form-control mb-0"
          formControlName="weight" [ngClass]="{
        'is-invalid':
          (vs.submitted || vs.f.weight.touched) && vs.f.weight.errors
      }" />
        <div *ngIf="(vs.submitted || vs.f.weight.touched) && vs.f.weight.errors" class="text-danger">
          <div *ngIf="vs.f.weight.errors" class="small">
            Value should be between 10-800
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-6 form-group">
        <label for="exampleInputPassword1">Height</label><span class="text-secondary p-1">(ft.In)</span>
        <input type="text" #num placeholder="Feet" [maxlength]="vs.length" (keyup)="vs.dotFunction(num.value)"
          class="form-control mb-0 float-left mr-2" formControlName="height" [ngClass]="{
        'is-invalid':
          (vs.submitted || vs.f.height.touched) && vs.f.height.errors
      }" />

        <div *ngIf="(vs.submitted || vs.f.height.touched) && vs.f.height.errors" class="text-danger">
          <div *ngIf="vs.f.height.errors" class="small">
            Value should be between 1-10
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-6 form-group">
        <span class="badge badge-pill badge-success float-right">%</span>
        <label for="exampleInputPassword1">Oxygen Level</label><span class="text-danger"></span>
        <input type="number" placeholder="Oxygen Levels" class="form-control mb-0" formControlName="oxygenLevel"
          [ngClass]="{
        'is-invalid':
          (vs.submitted || vs.f.oxygenLevel.touched) &&
          vs.f.oxygenLevel.errors
      }" />
        <div *ngIf="
        (vs.submitted || vs.f.oxygenLevel.touched) && vs.oxygenLevel.errors
      " class="text-danger">
          <div *ngIf="vs.oxygenLevel.errors" class="small">
            Value should be between 90-100
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-6 form-group">
        <label for="exampleInputPassword1">Temp</label><span class="text-secondary p-1">(F)</span>
        <input type="number" (keypress)="vs.numberOnly($event)" placeholder="Temperature" class="form-control mb-0"
          formControlName="temperature" [ngClass]="{
        'is-invalid':
          (vs.submitted || vs.temperature.touched) &&
          vs.temperature.errors
      }" />
        <div *ngIf="
        (vs.submitted || vs.temperature.touched) && vs.temperature.errors
      " class="text-danger">
          <div *ngIf="vs.temperature" class="small">
            Value should be between 95-105
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xs-6 form-group">
        <span class="badge badge-pill badge-warning float-right">BPM</span>
        <label for="exampleInputPassword1">Heart Rate</label><span class="text-danger"></span>
        <input type="number" (keypress)="vs.numberOnly($event)" placeholder="Pulse" class="form-control mb-0"
          formControlName="pulse" [ngClass]="{
        'is-invalid':
          (vs.submitted || vs.f.pulse.touched) && vs.f.pulse.errors
      }" />
        <div *ngIf="(vs.submitted || vs.f.pulse.touched) && vs.f.pulse.errors" class="text-danger">
          <div *ngIf="vs.f.pulse.errors.required" class="small">
            Blood Pressure is required
          </div>
          <div *ngIf="vs.f.pulse.errors" class="small">
            Value should be between 40-220
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xs-6 form-group">
        <span class="badge badge-pill badge-info float-right">mm/HG</span>
        <label for="exampleInputPassword1">Blood Pressure</label><span class="text-danger"></span>
        <input type="text" (keypress)="vs.numberOnly($event)" placeholder="Systolic"
          class="form-control  mb-0 float-left mr-2" formControlName="bloodPressure"
          [ngClass]="{'is-invalid':(vs.submitted || vs.f.bloodPressure.touched) &&vs.f.bloodPressure.errors}" />

        <div *ngIf="(vs.submitted || vs.f.bloodPressure.touched) &&vs.f.bloodPressure.errors" class="text-danger">
          <div *ngIf="vs.f.bloodPressure.errors.required" class="small">
            Blood Pressure is required
          </div>
          <div *ngIf="vs.f.bloodPressure.errors.pattern" class="small">
            Value should be between 90/60mm/Hg 210/120mm/Hg
          </div>

        </div>
      </div>
    </div>
  </form> -->
  <form [formGroup]="vs.formGroupBritish" *ngIf="(vs.show)">
    <div class="row d-flex">
      <div class="col-md-6 col-xs-6  form-group">
        <label>Date & Time</label><span class="text-danger"></span>
        <input type="text" class="form-control mb-0" id="date" formControlName="date" [value]="vs.date" readonly />
      </div>
      <div class="col-md-6 col-xs-6  form-group">
        <label>Blood group</label><span class="text-danger"></span>
        <input type="text" class="form-control mb-0" id="bld-grp" formControlName="bloodgroup" readonly [value]="vs.bloodgroup">
      </div>
      <div class="col-md-6 col-xs-6  form-group">
        <label for="exampleInputPassword1">Weight</label><span class="text-secondary p-1">(Kg)</span>
        <input type="number" #weight placeholder="Weight" id="weight" class="form-control mb-0" formControlName="weight" [ngClass]="{
        'is-invalid':
          (vs.submitted || vs.b.weight.touched) && vs.b.weight.errors
      }" />
        <div *ngIf="(vs.submitted || vs.b.weight.touched) && vs.b.weight.errors" class="text-danger">
          <div *ngIf="vs.b.weight.errors" class="small">
            Value should be between 10-750
          </div>
        </div>
        <div *ngIf="vs.wtlessMsg" class="text-warning">
          <div class="small">
            Normal weight range {{vs.weightLevel}}
          </div>
        </div>
        <div *ngIf="vs.wtExtraMsg" class="text-warning">
          <div class="small">
            Normal weight range {{vs.weightLevel}}
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-6  form-group">
        <label>Height</label><span class="text-secondary p-1">(Feet&Inches)</span>
        <div class="input-group">
          <div class="col-md-6 col-xs-6">
            <mat-select placeholder="Feet" [(value)]="vs.selectedValue" id="feet" class="form-control" formControlName="feet"
              name="item" [ngClass]="{
            'is-invalid':
              (vs.submitted || vs.b.feet.touched) && vs.b.feet.errors
          }">
              <!-- <input  [(ngModel)]="selectedValue"   matInput placeholder="search"  formControlName="postalLocation" class="form-control" > -->
              <mat-option *ngFor="let item of vs.feets" [value]="item">
                {{item}}
              </mat-option>
            </mat-select>
            <div *ngIf="(vs.submitted || vs.b.feet.touched) && vs.b.feet.errors" class="text-danger">
              <div *ngIf="vs.b.feet.errors" class="small">
                This is a mandatory field. Please specify Height in Feet between 1 and 10
              </div>
            </div>
          </div>

          <div class="col-md-6 col-xs-6">
            <mat-select placeholder="Inch" [(value)]="vs.selectedValue2" id="inches" class="form-control" formControlName="inch"
              name="item" [ngClass]="{
          'is-invalid':
            (vs.submitted || vs.b.inch.touched) && vs.b.inch.errors
        }">
              <!-- <input  [(ngModel)]="selectedValue"   matInput placeholder="search"  formControlName="postalLocation" class="form-control" > -->
              <mat-option *ngFor="let item of vs.inchs" [value]="item">
                {{item}}
              </mat-option>
            </mat-select>
            <div *ngIf="(vs.submitted || vs.b.inch.touched) && vs.b.inch.errors" class="text-danger">
              <div *ngIf="vs.b.inch.errors" class="small">
                This is a mandatory field. Please specify Height in Inches between 0 and 11
              </div>
            </div>
          </div>
        </div>


        <!-- <input type="number" #height placeholder="Feet" class="form-control mb-0 float-left mr-2" (selectionChange)="drpdownSelection(selectedValue)"
          (keypress)="vs.heightCheck(height.value)" [maxlength]="vs.length" formControlName="feet" [ngClass]="{
        'is-invalid':
          (vs.submitted || vs.b.feet.touched) && vs.b.feet.errors
      }" /> -->



        <!-- <input type="number" #height placeholder="Inch" class="form-control mb-0 float-left mr-2"
          (keypress)="vs.heightCheck(height.value)" [maxlength]="vs.length" formControlName="inch" [ngClass]="{
      'is-invalid':
        (vs.submitted || vs.b.inch.touched) && vs.b.inch.errors
    }" /> -->


      </div>
      <div class="col-md-6 col-xs-6  form-group">
        <span class="badge badge-pill badge-success float-right">%</span>
        <label>Oxygen Level</label><span class="text-danger"></span>
        <input type="text" (keypress)="vs.numberOnly($event)" maxlength="3" placeholder="Oxygen Levels" id="oxygenlevel" class="form-control mb-0"
          formControlName="oxygenLevel" [ngClass]="{
        'is-invalid':
          (vs.submitted || vs.b.oxygenLevel.touched) &&
          vs.b.oxygenLevel.errors
      }" />
        <div *ngIf="
        (vs.submitted || vs.b.oxygenLevel.touched) && vs.b.oxygenLevel.errors
      " class="text-danger">
          <div *ngIf="vs.b.oxygenLevel.errors" class="small">
            Value should be between 90-100
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xs-6 form-group">
        <label>Temp</label><span class="text-secondary p-1">(F)</span>

        <input type="text" (keypress)="vs.numberOnly1($event)" maxlength="5" placeholder="Temperature" id="temperature" class="form-control mb-0"
          formControlName="temperature" [ngClass]="{
        'is-invalid':
          (vs.submitted || vs.b.temperature.touched) &&
          vs.b.temperature.errors
      }" />
        <div *ngIf="
        (vs.submitted || vs.b.temperature.touched) && vs.b.temperature.errors
      " class="text-danger">
          <div *ngIf="vs.b.temperature.errors" class="small">
            Value should be between 95-105
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xs-6 form-group">
        <span class="badge badge-pill badge-warning float-right">BPM</span>
        <label>Heart Rate</label><span class="text-danger"></span>
        <input type="text" (keypress)="vs.numberOnly($event)"  maxlength="3" placeholder="Pulse"
        id="heartrate"
        class="form-control mb-0"
          formControlName="pulse" [ngClass]="{
        'is-invalid':
          (vs.submitted || vs.b.pulse.touched) && vs.b.pulse.errors
      }" />
        <div *ngIf="(vs.submitted || vs.b.pulse.touched) && vs.b.pulse.errors" class="text-danger">
          <div *ngIf="vs.b.pulse.errors.required" class="small">
            Blood Pressure is required
          </div>
          <div *ngIf="vs.b.pulse.errors" class="small">
            Value should be between 40-220
          </div>
        </div>
      </div>

      <div class="col-md-6 col-xs-6 form-group">
        <span class="badge badge-pill badge-info float-right">MM/HG</span>
        <label>Blood Pressure</label>
        <!-- <input type="text" id="dotted" (keypress)="vs.numberOnly($event)" placeholder="Systolic" class="form-control  mb-0 float-left mr-2"
                        formControlName="bloodPressure" (keyup)="onExpiryKeyup($event)"  [ngClass]="{'is-invalid':(vs.submitted || vs.b.bloodPressure.touched) && vs.b.bloodPressure.errors}" />
                      -->
        <div class="input-group">
          <div class="col-md-5 col-xs-5 form-group p-0 m-0">
            <!-- <label for="exampleInputEmail1">Pre-Lunch</label><span class="text-danger">*</span> -->
            <input type="text" #num class="form-control"  maxlength="3" placeholder="Systolic" 
            id="systolic"
            formControlName="systolic"
            (keypress)="vs.numberOnly($event)" [maxlength]="vs.length" [ngClass]="{
                            'is-invalid': (vs.submitted || vs.b.systolic.touched) && vs.b.systolic.errors
                          }" placeholder="Systolic" />
            <div *ngIf="(vs.submitted || vs.b.systolic.touched) && vs.b.systolic.errors" class="invalid-feedback">
              <!-- <div *ngIf="vs.f.feet.errors.required">height is required</div><span>Feet</span> -->
              <div *ngIf="vs.b.systolic.errors" class="small">
                Value should be between 80-200
              </div>
            </div>
          </div>
          <div class="col-md-2 col-xs-2 input-group-addon p-0 m-0">
            <p class="slash">/</p>
          </div>
          <div class="col-md-5 col-xs-5 form-group p-0 m-0">
            <!-- <label for="exampleInputEmail1">Post-Lunch</label><span class="text-danger">*</span> -->
            <input type="text" #num class="form-control" placeholder="Diastolic" id="diastolic" formControlName="diastolic"
              [maxlength]="3" (keypress)="vs.numberOnly($event)" [maxlength]="vs.length" [ngClass]="{
                              'is-invalid': (vs.submitted || vs.b.diastolic.touched) && vs.b.diastolic.errors
                            }" placeholder="Diastolic" />
            <div *ngIf="(vs.submitted || vs.b.diastolic.touched) && vs.b.diastolic.errors" class="invalid-feedback">
              <!-- <div *ngIf="vs.f.feet.errors.required">height is required</div><span>Feet</span> -->
              <div *ngIf="vs.b.diastolic.errors" class="small">
                Value should be between 50-140
              </div>
            </div>
          </div>

        </div>

        <!-- <div *ngIf="(vs.submitted || vs.b.bloodPressure.touched) && vs.b.bloodPressure.errors" class="text-danger">
      <div *ngIf="vs.b.bloodPressure.errors.required" class="small">
        Blood Pressure is required
    </div>
                        <div *ngIf="vs.b.bloodPressure.errors.pattern" class="small">
                            Value should be between MM/Hg
                        </div>

                    </div> -->
      </div>
      <div class="col-md-6 col-xs-6 form-group">
        <span class="badge badge-pill badge-info float-right">mg/dL</span>
        <label>Blood Glucose</label><span class="text-danger"></span>
        <div class="input-group">
          <div class="col-md-5 col-xs-5 form-group p-0 m-0">
            <!-- <label for="exampleInputEmail1">Pre-Lunch</label><span class="text-danger">*</span> -->
            <input type="text" #num class="form-control" placeholder="Glucose Level" formControlName="beforeLunch"
              [minlength]="2" [maxlength]="3" id="beforelunch" (keypress)="vs.numberOnly($event)" [maxlength]="vs.length" [ngClass]="{
                            'is-invalid': (vs.submitted || vs.b.beforeLunch.touched) && vs.b.beforeLunch.errors
                          }" placeholder="Fasting" />
            <div *ngIf="(vs.submitted || vs.b.beforeLunch.touched) && vs.b.beforeLunch.errors" class="invalid-feedback">
              <!-- <div *ngIf="vs.f.feet.errors.required">height is required</div><span>Feet</span> -->
              <div *ngIf="vs.b.beforeLunch.errors" class="small">
                Value before fasting should be above 80-140
              </div>
            </div>
          </div>
          <div class="col-md-2 col-xs-2 input-group-addon p-0 m-0">
            <p class="slash">|</p>
          </div>
          <div class="col-md-5 col-xs-5 form-group p-0 m-0">
            <!-- <label for="exampleInputEmail1">Post-Lunch</label><span class="text-danger">*</span> -->
            <input type="text" #num class="form-control" placeholder="Glucose Level" formControlName="afterLunch"
              [minlength]="2" [maxlength]="3" id="afterlunch" (keypress)="vs.numberOnly($event)" [maxlength]="vs.length" [ngClass]="{
                              'is-invalid': (vs.submitted || vs.b.afterLunch.touched) && vs.b.afterLunch.errors
                            }" placeholder="Random" />
            <div *ngIf="(vs.submitted || vs.b.afterLunch.touched) && vs.b.afterLunch.errors" class="invalid-feedback">
              <!-- <div *ngIf="vs.f.feet.errors.required">height is required</div><span>Feet</span> -->
              <div *ngIf="vs.b.afterLunch.errors" class="small">
                Value after fasting should be above 80-220
              </div>
            </div>
          </div>

        </div>


      </div>
    </div>
  </form>

  <!-- <button type="button" *ngIf="(vs.show)" class="btn btn-sm btn-primary float-right"
    (click)="vs.submit(vs.FirstFormGroup)">
    Submit
  </button> -->

  <button type="button" *ngIf="(vs.show)" id="submitVitals" class="btn btn-sm btn-primary float-right hideme"
    (click)="vs.submit(vs.formGroupBritish)">
    Submit
  </button>
</div>
