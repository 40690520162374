<!DOCTYPE html>
<html lang="en">

<head>
  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="keywords" content="HTML5 Template" />
  <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
  <title>Curemetric</title>
  <link rel="dns-prefetch" href="https://fonts.gstatic.com/">
  <!-- favicon icon -->
  <link rel="shortcut icon" href="./../../../assets/landing/images/favicon.png" />

  <!-- owl-carousel -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/owl.carousel.css">

  <!-- shortcodes -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/base.css" />

  <!-- main -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/core.css" />

  <!-- responsive -->
  <link rel="stylesheet" type="text/css" href="./../../../assets/landing/css/helper.css" />


  <style>
    body,
    p,
    span,
    input,
    button,
    a {
      font-family: 'Poppins', sans-serif !important;
      font-size: 13px !important
    }

    h1,
    h2,
    h3,
    h4 {
      font-family: 'Poppins', sans-serif !important;
    }

    .sign-info {
      border-top: 1px solid #cdd1f3;
      margin-top: 10px;
      padding-top: 20px;
      margin-bottom: 30px
    }

    .iq-social-media {
      margin: 0;
      padding: 0;
      float: right;
    }

    .iq-social-media li {
      list-style: none;
      float: left;
      margin-right: 10px;
    }

    .iq-social-media li:last-child {
      margin-right: 0;
    }

    .iq-social-media li a {
      height: 30px;
      width: 30px;
      text-align: center;
      font-size: 18px;
      line-height: 30px;
      display: inline-block;
      -webkit-border-radius: 7px;
      -moz-border-radius: 7px;
      border-radius: 7px;
      background: #eff7f8;
      color: #40648c !important;
    }

    .iq-social-media li a:hover {
      text-decoration: none;
    }


    .form-control {
      border-top-width: 0px !important;
      border-left-width: 0px !important;
      border-right-width: 0px !important;
      border-color: #424242 !important;
      border-bottom-width: 1px !important;
      height: calc(2rem + 1px) !important;
      background: transparent !important;
      padding: 9px 0px !important;
    }


    .bg-login {
      background-repeat: no-repeat;
      background-position: top left;
      background-image: url(./assets/landing/images/vector_page/bg-login.jpg);
      background-size: cover;

      width: 100%;
      height: 100%;
      opacity: 1;
      visibility: inherit;
      z-index: 20;
    }


    .signin {
      padding: 0px 0px 0px 0px !important;
      margin-top: 120px;
      max-width: 450px;
      font-family: 'Poppins', sans-serif;
      margin-left: 18px !important;
    }

    .signin h1 {
      font-size: 26px
    }

    .rightimg img {
      height: 450px;
      margin-top: 100px
    }
  </style>

</head>

<body class="ttm-one-page-site">

  <!--page start-->
  <div class="page" id="ttm-home">

    <!-- preloader start -->

    <!-- preloader end -->

    <!--header start-->
    <app-main-header></app-main-header>
    <!--header end-->


    <div class="container-fulid">
      <div class="bg-login">
        <div class=" container">
          <div class="row">

            <div class="signin col-md-4 ml-4">
              <h1 class="mb-0" *ngIf="!verify">Forgot Password</h1>
              <h1 class="mb-0" *ngIf="verify">Verification</h1>
              <div class="error" *ngIf="invalidCredentials">
                {{message}}
              </div>
              <!-- <div class="text-success" *ngIf="successCredentials">
                {{message}}
              </div> -->

              <div *ngIf="!verify">
                <form [formGroup]="forgotPassword" class="m-2">
                  <div class="row d-flex flex-column justify-content-center">

                    <div *ngIf="!emailVerificationDone && !changePassword">

                      <div class="col-md-12 form-group">
                        <label for="exampleInputPassword1">Username</label><span class="text-danger">*</span>
                        <input type="text" formControlName="userName" class="form-control mb-0"
                          (focusout)="checkUserName()" (keypress)="alphaNumarics($event)" [ngClass]="{
       'is-invalid':
       (submitted || f.userName.touched) && f.userName.errors
       }" placeholder="Enter Username" />

                        <div *ngIf="(submitted || f.userName.touched) && f.userName.errors" class="invalid-feedback">
                          <!-- <div *ngIf="isExist">This Username already exists</div> -->
                          <div *ngIf="f.userName.errors.required">
                            Username is required
                          </div>

                          <div *ngIf="f.userName.errors.pattern">
                            Username should be alphabets and numbers.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 form-group">
                        <label for="exampleInputEmail1">Email Id</label><span class="text-danger">*</span>

                        <input type="emailId" formControlName="emailId" class="form-control mb-0"
                         noWhiteSpaceAtTheStart
                          [ngClass]="{ 'is-invalid':(submitted || f.emailId.touched) && f.emailId.errors }"
                          placeholder="Enter Email id" (keyup)="emailEntered()">

                        <div *ngIf="(submitted ||f.emailId.touched )&& f.emailId.errors" class="invalid-feedback">
                          <div *ngIf="f.emailId.errors.required">Email is required</div>
                          <div *ngIf="f.emailId.errors.email">Email should be valid</div>
                        </div>
                      </div>

                      <div class="col-md-12" *ngIf="isOtpSent">
                        <label for="exampleInputEmail1">OTP</label><span class="text-danger">*</span>
                        <div id="otp-holder2">
                          <div id="otp-content2">
                            <input id="otp-input2" class="otp-input" type="tel" maxlength="6" pattern="\d{6}" #otp
                              (keyup)="otpCheck(otp.value)" formControlName="otp" (keypress)="numberOnly($event)"
                              [ngClass]="{ 'is-invalid':(submitted || f.otp.touched) && f.otp.errors }" />
                          </div>
                        </div>
                        <button class="btn bt4.n-sm btn-primary" style="position:absolute;bottom:5px;left: 52%;"
                          (click)="verifyEmail()" [disabled]="showEVerify">Verify Now</button>

                        <div *ngIf="(submitted ||f.otp.touched )&& f.otp.errors" class="invalid-feedback">
                          <div *ngIf="f.otp.errors.required">OTP is required</div>

                        </div>
                      </div>

                    </div>
                  </div>
                  <div *ngIf="emailVerificationDone && !changePassword">

                    <div class="row ml-1">
                      <div class="col-md-6 form-group">
                        <label for="exampleInputPassword1">Mobile Number</label><span class="text-danger">*</span>

                        <input type="phonenumber" #phNum formControlName="phoneNumber"
                          class="form-control w-100 float-left" placeholder="Phone Number" readonly />
                      </div>

                    </div>
                    <div class="col-md-12 form-group">
                      <label style="font-size: 14px; font-weight: 600">OTP</label>

                      <div id="otp-holder2">
                        <div id="otp-content2">
                          <input id="otp-input2" class="otp-input" #phOtp class="otp-input" type="tel" maxlength="6"
                            pattern="\d{6}" (keypress)="numberOnly($event)" [ngModelOptions]="{ standalone: true }"
                            [(ngModel)]="PhOTP" autocomplete="off" (keyup)="phOtpEntered(phOtp.value)" />
                        </div>
                      </div>
                    </div>
                    <div class="row ml-1">
                      <div class="col-md-12 mr-2" style="max-height: 40px;">
                        <button class="btn btn-sm btn-primary mr-2" (click)="verifyPhoneOTP()"
                          [disabled]="showPVerify">Verify
                          Now</button>
                        <span class="timer2" id="timer2" style="padding-top:10px ;color:red" *ngIf="isTimeOn1"></span>
                        <button class="btn btn-sm btn-success" (click)="getPhoneOtp()" *ngIf="!isTimeOn1">Resend
                          OTP</button>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="changePassword">

                    <div class="col-md-12 form-group">
                      <label for="exampleInputEmail1">New Password</label><span class="text-danger">*</span>
                      <div class="input-group" id="show_hide_password">
                        <input type="password" (paste)="(false)" (copy)="(false)" formControlName="newPassword"
                        (keydown.space)="$event.preventDefault()"
                          class="form-control mb-0" placeholder="Enter your desired password" (keyup)="npEntered()"
                          [ngClass]="{ 'is-invalid':(submitted || f.newPassword.touched) && f.newPassword.errors }">
                        <div class="input-group-addon">
                          <a href="javascript:void(0)"><i class="fa fa-eye-slash" aria-hidden="true"
                              (click)="showHidePwd()"></i></a>
                        </div>
                        <div *ngIf="(submitted ||f.newPassword.touched )&& f.newPassword.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.newPassword.errors.required">Password is required
                          </div>
                          <div *ngIf="f.newPassword.errors.pattern">Password must contain 1
                            Number,1 LowerCase,1 UpperCase,1 special Character</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 form-group">
                      <label for="exampleInputEmail1">Confirm Password</label><span class="text-danger">*</span>
                      <div class="input-group" id="show_hide_password1">
                        <input type="password" (paste)="(false)" (copy)="(false)" formControlName="confirmPassword"
                        (keydown.space)="$event.preventDefault()"
                          class="form-control mb-0" placeholder="Enter your desired password" (keyup)="cpEntered()"
                          [ngClass]="{ 'is-invalid':(submitted || f.confirmPassword.touched) && f.confirmPassword.errors }">
                        <div class="input-group-addon">
                          <a href="javascript:void(0)"><i class="fa fa-eye-slash" aria-hidden="true"
                              (click)="showHidePwd1()"></i></a>
                        </div>
                        <div *ngIf="(submitted ||f.confirmPassword.touched )&& f.confirmPassword.errors"
                          class="invalid-feedback">
                          <div *ngIf="f.confirmPassword.errors.required">Password is required
                          </div>
                          <div *ngIf="f.confirmPassword.errors.mustMatch">Passwords must match
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>


                </form>
              </div>
              <div *ngIf="verify">

                <form [formGroup]="forgotPassword" class="m-2">
                  <div class="row d-flex flex-column justify-content-center">

                    <div class="col-md-12 form-group">
                      <label for="exampleInputPassword1">Username<span class="text-danger">*</span></label>

                      <input type="text" formControlName="userName" class="form-control mb-0"
                        (focusout)="checkUserName()" (keypress)="alphaNumarics($event)"
                        [ngClass]="{'is-invalid': (submitted || f.userName.touched) && f.userName.errors }"
                        placeholder="Enter Username" />

                      <div *ngIf="(submitted || f.userName.touched) && f.userName.errors" class="invalid-feedback">
                        <div *ngIf="f.userName.errors.required"> Username is required </div>
                        <div *ngIf="f.userName.errors.pattern"> Username should be alphabets and
                          numbers. </div>
                      </div>
                    </div>
                    <div class="col-md-12 form-group">
                      <label for="exampleInputEmail1">Email Id</label><span class="text-danger">*</span>
                      <input type="emailId" formControlName="emailId" class="form-control mb-0"
                        placeholder="Enter your emailId"
                        [ngClass]="{ 'is-invalid':(submitted || f.emailId.touched) && f.emailId.errors }"
                        placeholder="Enter Email id" (keyup)="emailEntered()">

                      <div *ngIf="(submitted ||f.emailId.touched )&& f.emailId.errors" class="invalid-feedback">
                        <div *ngIf="f.emailId.errors.required">email is required</div>
                        <div *ngIf="f.emailId.errors.email">email should be valid</div>
                      </div>
                    </div>
                  </div>

                  <button class="btn btn-sm btn-primary" (click)="verifyNow()">Continue</button>
                </form>
              </div>
              <div>
                <button class="btn btn-sm btn-primary" style="margin-left: 2%;" *ngIf="isSend && !verify"
                  (click)="sendOTP()" [disabled]="(!uValid && !eValid) ? false : true"> Get OTP</button>
                <button class="btn btn-sm btn-primary" style="margin-left:2%;" *ngIf="isResend && !verify"
                  (click)="sendOTP()" [disabled]="isResentOtp"> Resend OTP</button>
                &nbsp; <span class="timer" id="timer" style="padding-top:10px ;color:red" *ngIf="showTimer"></span>

                <button  style="margin-top: 0%; float: right;" class="btn btn-primary" (click)="ChangePassword()"
                  type="submit" *ngIf="!verify && changePassword" [disabled]="(!npValid && !cpValid)?false:true">Change
                  Password</button>
              </div>

            </div>
            <div class=" rightimg col-lg-7  col-md-5 col-sm-5">
              <img src="./assets/landing/images/slide-img-04.png" />
            </div>
          </div>
        </div>
      </div>
    </div><!-- page end -->

    <!-- Footer -->
    <app-main-footer></app-main-footer>
    <!-- Footer Ends -->
