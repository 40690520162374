<main class="l-main">
    <div class="content-wrapper content-wrapper--with-bg"> 
 
 
 <div class="container-fluid rounded-sm ">
     <div class="row">
    
  
        <div class="col-lg-8  col-md-8 col-sm-12 mb-2  " *ngIf=isHome>
        <app-caretaker-navbar></app-caretaker-navbar>
        <router-outlet></router-outlet>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 mb-2  " *ngIf=!isHome>
          <app-caretaker-navbar></app-caretaker-navbar>
       <router-outlet></router-outlet>
       </div>
  
  
        <div class="col-lg-4 col-md-4 col-sm-12 mb-0  " *ngIf=isHome>
    
           <app-caretaker-sidebar></app-caretaker-sidebar>
         </div>
  </div>
  
  </div>
 
  </div>
  </main>
  <app-footer></app-footer>
