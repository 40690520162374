import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { interval, Subscription } from 'rxjs';
import { ClinicNotificationsService } from 'src/app/shared/clinic-notifications.service';

@Component({
  selector: 'app-clinic-layout',
  templateUrl: './clinic-layout.component.html',
  styleUrls: ['./clinic-layout.component.css']
})
export class ClinicLayoutComponent implements OnInit {

  subscription: Subscription;
  isHome=true
  constructor(private globalStorage: GlobalStorageService, private notification:ClinicNotificationsService) { }

  ngOnInit(): void {
    this.notification.requestPermission(this.globalStorage.getItem('doctorId'));
           this.notification.receiveMessage();
           this.notification.currentMessage
    const source = interval(1000);
    this.subscription = source.subscribe(val => this.checkHomeOrNot());
  }
  checkHomeOrNot(){
    if(window.location.pathname == '/clinic-dashboard' || window.location.pathname == '/cureMetric/clinic-dashboard'){
      this.isHome = true;
     }
    else{
      this.isHome = false;
    }
  }

}
