import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms'
import { DoctorService } from '../../AppServices/doctor.service'
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ThrowStmt } from '@angular/compiler';
import { ThisMonthInstance } from 'twilio/lib/rest/api/v2010/account/usage/record/thisMonth';
import { DomSanitizer } from '@angular/platform-browser';
import { ISlimScrollOptions, SlimScrollEvent } from 'ngx-slimscroll'
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
import { Router } from '@angular/router';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-doctor-profile',
  templateUrl: './doctor-profile.component.html',
  styleUrls: ['./doctor-profile.component.css']
})
export class DoctorProfileComponent implements OnInit {
  personalDtsMessage;feeDtsMessage
  constructor(private globalStorage: GlobalStorageService, private scripts: ScriptsService, private router: Router, private fb: FormBuilder, private doctorService: DoctorService) {


  }



  ngOnInit(): void {
    $('.professional').removeClass('noshow')
    this.scripts.loadScripts()
    this.checkFee()
    

    if (this.router.url == '/Payments') {
      this.isInvoice = true;
      this.isProfessional = false
      $('#professional').removeClass('active')
      $('.icon-appointments').removeClass('active-tabs')
      $('.professional').addClass('noshow')
      $('#payments').addClass('active')
      $('.paymt').addClass('active-tabs')
      $('.payments').removeClass('noshow')
    }
    if(this.router.url=='/EditProfile'){
      $('.icon-appointments').addClass('profile-tabs')
      $('.professional').addClass('active') 
      $('.paymt').click(function() {
        $('.icon-appointments').removeClass('profile-tabs');
    });
    $('.personalDts').click(function() {
      $('.icon-appointments').removeClass('profile-tabs');
  });
  $('.addressDts').click(function() {
    $('.icon-appointments').removeClass('profile-tabs');
});
$('.cer-ass').click(function() {
  $('.icon-appointments').removeClass('profile-tabs');
});
$('.paymt').click(function() {
  $('.icon-appointments').removeClass('profile-tabs');
});
$('.association').click(function() {
  $('.icon-appointments').removeClass('profile-tabs');
});
$('.changePassword').click(function() {
  $('.icon-appointments').removeClass('profile-tabs');
});
    }
    if (this.router.url == '/associations' || this.router.url == '/EditProfileAssociation') {
      this.isProfessional = false
      this.isCertification = true
      this.associations()
      $('.asso').addClass('active-tabs')
      $('.icon-appointments').removeClass('active')
      $('.icon-appointments').removeClass('active-tabs')
      $('#professional').removeClass('active')
      $('.professional').addClass('noshow')
      $('#certification').addClass('active')
      $('.certification').removeClass('noshow')
    }
    if (this.router.url == '/personalDetails') {
      this.isPersonal = true;
      this.isProfessional = false
      $('.icon-appointments').removeClass('active')
      $('.icon-appointments').removeClass('active-tabs')
      $('.professional').addClass('noshow')
      $('#personal').addClass('active')
      $('.personalDts').addClass('active-tabs')
      $('.personal').removeClass('noshow')
    }
    if (this.router.url == '/addressDetails') {
      this.isContactdetails = true;
      this.isProfessional = false
      $('.icon-appointments').removeClass('active')
      $('.icon-appointments').removeClass('active-tabs')
      $('.professional').addClass('noshow')
      $('#contactdetails').addClass('active')
      $('.addressDts').addClass('active-tabs')
      $('.contactdetails').removeClass('noshow')
    }
    if (this.router.url == '/feeDetails') {
      this.isProfessional = true
    }

  }
  checkFee(){
    // this.doctorService.getDoctorFeeDetails(this.globalStorage.getItem('doctorId')).subscribe(res => {
    //   if (res['data'] == null) {
    //     this.feeDtsMessage = '*  Fill Fee details.'
    //   }
    //   else{

    //   }
    // })
  }

  isProfessional: boolean = true;
  isPersonal: boolean = false;
  isCertification: boolean = false;
  isContactdetails: boolean = false;
  isInvoice: boolean = false;
  personal() {
    this.isPersonal = true;
    this.isProfessional = false
    $('#personal').addClass('active')
    $('#professional').removeClass('active')
    $('#payments').removeClass('active')
    $('#contactdetails').removeClass('active')
    $('#certification').removeClass('active')
    $('#changePassword').removeClass('active')
  }

  primary() {
    this.isProfessional = true
    $('#personal').removeClass('active')
    $('#professional').addClass('active')
    $('#payments').removeClass('active')
    $('#contactdetails').removeClass('active')
    $('#certification').removeClass('active')
    $('#changePassword').removeClass('active')
  }
  certOrAssociation
  certification() {
    this.isCertification = true;
    this.isProfessional = false
    this.certOrAssociation = 'certifications'
    $('#personal').removeClass('active')
    $('#professional').removeClass('active')
    $('#payments').removeClass('active')
    $('#contactdetails').removeClass('active')
    $('#certification').addClass('active')
    $('#changePassword').removeClass('active')
  }
  isAssociation
  associations() {
    this.isCertification = true;
    this.isProfessional = false
    this.certOrAssociation = 'associations'
    $('#personal').removeClass('active')
    $('#professional').removeClass('active')
    $('#payments').removeClass('active')
    $('#contactdetails').removeClass('active')
    $('#associations').addClass('active')
    $('#changePassword').removeClass('active')
  }

  changePassword() {
    this.isProfessional = false
    $('#personal').removeClass('active')
    $('#professional').removeClass('active')
    $('#payments').removeClass('active')
    $('#contactdetails').removeClass('active')
    $('#certification').removeClass('active')
    $('#changePassword').addClass('active')
  }

  contactdetails() {
    this.isContactdetails = true;
    this.isProfessional = false
    $('#personal').removeClass('active')
    $('#professional').removeClass('active')
    $('#payments').removeClass('active')
    $('#contactdetails').addClass('active')
    $('#certification').removeClass('active')
    $('#changePassword').removeClass('active')
  }

  invoice() {
    //alert()
    this.isInvoice = true;
    this.isProfessional = false
    $('#personal').removeClass('active')
    $('#professional').removeClass('active')
    $('#payments').addClass('active')
    $('#contactdetails').removeClass('active')
    $('#certification').removeClass('active')
    $('#changePassword').removeClass('active')
  }
  recievedFromChild(event) {
    // use the emitted event
    if (event != null) {
      this.personalDtsMessage = ''
      //this.feeDtsMessage=event
    }
    if(event){
      //this.feeDtsMessage=event
    }
  }

}
