
<div class="row">
    <div class="position-relative ivfinputcolor">
        <div class="input-group input-group-solid searchContainer pl-0">
            <input type="text" class="form-control pl-2"  aria-label="Number"
              [formControl]="myControl" placeholder="Search Pharma">
            <!-- <mat-autocomplete #auto6="matAutocomplete">
              <mat-option *ngFor="let option of filteredOptionsP | async" [value]="option.pharmacyName" (click)="getPharmaById(option)">
                {{option.pharmacyName}}
              </mat-option>
            </mat-autocomplete>
            <div class="input-group-append" (click)="getPharmasByName(pharma.value)">
              <i class="fa fa-search fs-16 p-2 "></i>
            </div> -->
      </div>
    <div class="col-md-12">
        <div class="table-responsive m-t-40">
<table class="table table-styled mb-0 table-hover table-striped">
    <thead>
      <tr>
        <th>Pharmacy Name</th>
        <th>Location </th>
        <th>Rating </th>
        <th width="150"></th>
      </tr>
    </thead>
    <tbody>
    
        <tr *ngFor="let pharmacy of pharmaciesList|paginate: { id: 'p2', itemsPerPage: 10, currentPage: p2 }">
          <td class="is-image-cell">
            <span class="img-thumb ">
              <img src="./assets/img/pharma-icon.png" alt=" ">
              <span class="ml-2 ">{{pharmacy.pharmacyname | titlecase}}</span>
            </span>
          </td>
          <td data-label="Location"> <span>{{pharmacy.city}}</span></td>
          <td></td>

          <td class="is-actions-cell">
            <button class="btn btn-xs btn-success" (click)="selecteShippingAddress(pharmacy)">Select</button>
            <i class="fas float-right mt-2 fa-heart fa-sm text_color_green animated "
              style="margin-top:0px;  float:right;  border-radius: 15px; text-align: center;    padding: 5px;"></i>

          </td>

        </tr>
    </tbody>
  </table>
  <div *ngIf="permissionDenied">
    <p>Location access is blocked.
        Allow Location permission Or Use Search For Your Nearest Pharmacies  </p>
  </div>
</div>
</div>