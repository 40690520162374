<div class="modal-header">
    <h4 class="modal-title">Appointment Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
  
  
  
    <div class="d-flex flex-row mb-0 p-2 border-bottom  ">
  
      <a class="" href="javascript:void(0)"><img alt="" *ngIf="gender == 'Male' || gender == 'male'"
          src="./assets/img/profile-male.png" style="width: 50px;height: 50px;" class=" border-0 m-1 profileimagecss">
        <img alt="" *ngIf="gender == 'Female' || gender == 'female'" src="./assets/img/profile-female.png"
          style="width: 50px;height: 50px;" class=" border-0 m-1 profileimagecss"></a>
      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
        <a href="javascript:void(0)">
          <p class="font-weight-medium mb-0 mt-2"> {{doctorName | titlecase}}<i
              class="fa fa-check-circle fa-sm faa-shake text_color_green animated" aria-hidden="true"></i></p>
          <p class="text-muted mb-1 text-small">{{specializ}}</p>
  
          <!-- <div class="float-left">
                  <div *ngIf="aRating==0">
                    <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2,3,4]'></span>
                  </div>
                  <div *ngIf="aRating==1">
                    <span class="fa fa-star checked fs-12" *ngFor='let in of aRating | demoNumber ;let i = index'></span>
                    <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2,3]'></span>
                  </div>
                  <div *ngIf="aRating==2">
                    <span class="fa fa-star checked fs-12" *ngFor='let in of aRating | demoNumber ;let i = index'></span>
                    <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2]'></span>
                  </div>
                  <div *ngIf="aRating==3">
                    <span class="fa fa-star checked fs-12" *ngFor='let in of aRating | demoNumber ;let i = index'></span>
                    <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1]'></span>
                  </div>
                  <div *ngIf="aRating==4">
                    <span class="fa fa-star checked fs-12" *ngFor='let in of aRating | demoNumber ;let i = index'></span>
                    <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0]'></span>
                  </div>
                  <div *ngIf="aRating==5">
                    <span class="fa fa-star checked fs-12" *ngFor='let in of aRating | demoNumber ;let i = index'></span>
                  </div>
  
                </div> -->
          <span class="float-right" *ngIf="typeOfAppointment=='In person' || typeOfAppointment=='Inperson' "><i
              class="fa-solid fa-hospital-user color_green" aria-hidden="true"></i> In person</span>
        </a>
      </div>
    </div>
    <div class="summary-col">
      <div class="summary-col-inner">
        <div> Purpose <span>{{this.reason}}</span></div>
        <div class="">Date <span>{{this.selectedItem| date:'dd-MMM-yyyy'}}</span></div>
        <div>Time<span>{{fromTime |convertFrom24To12Format}}-{{toTime |convertFrom24To12Format}}</span></div>
        <div class="">Consulting Fee<span class="inr"><i class="fa fa-inr"></i>&nbsp;<span>{{fee}}.00</span></span>
        </div>
        <div *ngIf="isIvf">Appointment Type
          <span>
            <select class="form-control" [(ngModel)]="appointmentType">
              <option *ngFor="let option of appointmentTypeArray" [value]="option.lookupValue">{{option.lookupValue}}
              </option>
            </select>
            <!-- <select class="form-control" style="width: 115px;" [(ngModel)]="appointmentType">
              <option value="option1">New Appointment</option>
              <option value="option2">Follow Up</option>
              <option value="option3">Camp</option>
              <option value="option4">Package</option>
            </select> -->
          </span>
        </div>
      </div>
      <div class="summary-col-inner">
  
        <div *ngIf="isIvf" class="float-left w-100">Discount %<span class="inr">
            <span><input class="text-end form-control" type="text" appInputRestrict="decimalOnly" style="width: 115px;"
                [(ngModel)]="discountAmount"></span>
          </span></div>
  
        <div *ngIf="isIvf" class="float-left w-100">Amount(After Discount)<span class="inr"><i
              class="fa fa-inr"></i>&nbsp;<span>{{amountAfterDiscount | number:'1.2-2'}}</span></span></div>
  
  
  
        <div *ngIf="isIvf" class="float-left w-100">Amount Paid<span class="inr">
            <span><input class="text-end form-control" type="text" appInputRestrict="numberOnly" style="width: 115px;"
                [(ngModel)]="advanceAmount"></span>
          </span></div>
  
          <div *ngIf="isIvf" class="float-left w-100">Amount Paid(Words)<span class="inr">
            <span><label style="text-transform: capitalize;">Rupees {{advanceAmount | convetToWords}} Only</label></span>
          </span></div>
  
        <div *ngIf="isIvf" class="float-left w-100">Due Amount <span class="inr"><i
              class="fa fa-inr"></i>&nbsp;<span>{{calculateDueAmount() ?
              calculateDueAmount() : 0 | number:'1.2-2'}}</span></span></div>
        <!-- <div>Taxes<span>$10</span></div> -->
        <div class="float-left w-100"><b>Total Amount</b><span class="inr"><b><i
                class="fa fa-inr"></i>&nbsp;<span>{{fee?fee:0}}.00</span></b></span>
        </div>
  
      </div>
    </div>
  
    <div class="d-flex flex-row mb-0 pl-1 border-bottom border-top">
      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
        <a href="javascript:void(0)" class="mt-2" (click)="consentForm()">
          <p class="font-weight-medium mb-1 mt-1" style="margin-left:10px;">
            <input type="checkbox" style="position: relative; top: 3px;" id="consent" [(ngModel)]="consent" name=""
              value="" [disabled]="disableCB" [checked]="checkAgree">
            <label class="ml-1"> I Agree</label>
          </p>
        </a>
      </div>
      <div class=" float-right mt-2">
        <a href="javascript:void(0)" class="btn btn-sm mr-3 mt-2 p-1"
          style="text-decoration: underline; margin-right: 16px; margin-top: 7px;" class="float-right"
          (click)="consentForm()"> Patient Consent Form </a>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-1">
        <label for="" class="col-form-label">Payment Mode<b>*</b></label>
        <!-- <input type="text" class="form-control" name="name" formControlName="paymentMode" id="name" placeholder="Payment Mode"> -->
        <mat-select class="form-control" name="item" [(ngModel)]="selectedPaymentType"
          (selectionChange)="drpdownSelectionChange(selectedPaymentType)" id="surgeryType" placeholder="Payment Mode">
          <mat-option *ngFor="let item of cashModes" [value]="item.value">
            {{item.value}}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="p-3">
      <form>
        <div class="panel-footer">
          <div class="row">
            <div class="col-xs-12 col-md-12">
              <button class="btn btn-primary btn-lg btn-block float-right" *ngIf="!formProcess"
                [disabled]="!checkAgree || !paymentTypeSelected" (click)="openPayU()">Proceed to Pay</button>
              <span class="btn btn-danger btn-lg btn-block" *ngIf="formProcess">Processing please wait...</span>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>