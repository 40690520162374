<div class="modal-header">
    <h5 class="modal-title">
        <i aria-hidden="true" class="fa fa-user-plus"></i> {{isEdit? " Edit Category" : " Add Category"}}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body modal-bodycure pt-0" >
    <div class="container user-curetab">
        <form [formGroup]="categoryForm">
            <div class="row mt-2 formgropnew">
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3">
                    <label for="" class="col-form-label">Category Name<b>*</b></label>
                    <input type="text" class="form-control" name="catagory" id="catagory" placeholder="Enter Category Name" formControlName="catagory" 
                    [ngClass]="{ 'is-invalid': (submitted || categoryFormControls.catagory.touched) && categoryFormControls.catagory.errors}" [readonly]="isEdit">
                    <div *ngIf="(submitted || categoryFormControls.catagory.touched) && categoryFormControls.catagory.errors"
                        class="invalid-feedback">
                        <div *ngIf="categoryFormControls.catagory.errors.required">Category Name
                            is required.</div>
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3">
                    <label for="" class="col-form-label">Category Description<b></b></label>
                    <textarea autoResizeRows rows="4" class="form-control" formControlName="Description"> </textarea>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3" *ngIf="checkPharmacy()">
                    <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck1" (change)="onCheckboxChange($event)">
                        <label class="custom-control-label ml-2" for="customCheck1">Check this If Pharmacy</label>
                    </div>
                </div>                
            </div>
        </form>
        <button type="button" class="btn btn-danger float-right btn-sm ml-2" (click)="activeModal.close('')"> Cancel </button>
        <button type="button" class="btn btn-success float-right btn-sm " (click)="saveCategory()" [disabled]="!categoryForm.valid"> Save </button>

    </div>
</div>