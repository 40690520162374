<div class="card patient-right-bg  pb-3">
  <div class="card ml-0 mr-0 mt-0 mb-2 p-profile-widget no-mobile">
    <div class="position-absolute card-top-buttons p-3 mr-0">
      <button class="btn btn-header-light icon-button">
        <i class="fa fa-bell fa-lg faa-shake animated" aria-hidden="true" data-toggle="modal"
          data-target="#viewNotificationPopup" aria-hidden="true"></i>
      </button>
      <span class="count"> {{notificationLength}}</span>
    </div>
    <div class="card-body">
      <div class="d-flex flex-row ml-2 mr-2 mb-1 mt-0">
        <a class="d-flex pt-2 profile-image" href="javascript:void(0)" (click)="fileInput.click()" data-toggle="tooltip"
          data-original-title="Upload Profile pic">
          <input #fileInput type="file" (change)="onFileInput($event.target.files)" style="display:none;" />
          <img *ngIf="(imageSource == null || imageSource == 'null') && gender == 'Male'"
            src="./assets/img/diagnostic-1.png"
            class="img-thumbnail border-0  p-0 list-thumbnail align-self-center xs-small"
            style="border-radius: 50%;background: #fff;">
          <img *ngIf="(imageSource == null || imageSource == 'null') && gender == 'Female'"
            src="./assets/img/user/diagnostic-1.png"
            class="img-thumbnail border-0  p-0 list-thumbnail align-self-center xs-small"
            style="border-radius: 50%;background:  #fff;">
          <img *ngIf="imageSource != null" [src]="imageSource"
            class="img-thumbnail border-0  p-0 list-thumbnail align-self-center xs-small"
            style="border-radius: 50%;background:  #fff;">
          <div class="profile-overlay">
            <a href="javascript:void(0)" class="profile-icon">
              <i class="fa fa-image"></i>
            </a>
          </div>
        </a>
        <!-- <a class="d-flex" href="javascript:void(0)" >
    <img alt="Profile" src="./assets/img/profile-icon.jpg" class="img-thumbnail border-0 m-1 list-thumbnail align-self-center xs-small">
    </a> -->
        <h5 class="card-title border-0 mb-0 pb-2 mt-0">{{firstName}} {{lastName}}<br>
          <span class="text-muted text-small pt-2"> {{age}} years - {{gender}} </span>
        </h5>
      </div>
    </div>
  </div>
  <div class="card mb-2 ">
    <div class="card-body bg-reports1">
      <h5 class="card-title">Diagnostic Details </h5>
      <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2">
        <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
          <a href="javascript:void(0)" routerLink="/branches">
            <p class="font-weight-medium mb-1 mt-1"> Branches </p>
            <p class="text-muted mb-1 text-small">Manage Branches</p>
          </a>
        </div>
        <div class=" float-right ">
          <a href="javascript:void(0)" routerLink="/branches" class="btn btn-sm mr-1 mt-2 p-1"> <i class="fa  fa-store-alt icon-head patientc3"
              aria-hidden="true"></i> </a>
        </div>
      </div>
      <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-1">
        <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
          <a href="javascript:void(0)" routerLink="/associate-users">
            <p class="font-weight-medium mb-1 mt-1">Associate </p>
            <p class="text-muted mb-1 text-small">Manage User</p>
          </a>
        </div>
        <div class=" float-right ">
          <a href="javascript:void(0)" routerLink="/associate-users" class="btn btn-sm mr-1 mt-2 p-1"> <i class="fa fa-user icon-head patientc4"
              aria-hidden="true"></i> </a>
        </div>
      </div>
    </div>
  </div>
  <div class="card p-min-height ">
    <div class="position-absolute card-top-buttons">
      <button class="btn btn-header-light icon-button"><i aria-hidden="true" class="fa fa-ellipsis-h" aria-hidden="true"
          data-toggle="modal" data-target="#viewNotificationPopup"></i></button>
    </div>
    <div class="card-body bg-reports1">
      <h5 class="card-title">Notifications</h5>
      <div class="no-notification"></div>
      <div *ngIf="nonViewData?.length == 0" class="no-notifications"></div>
      <div class="d-flex flex-row mb-0 p-2 border-bottom" *ngFor="let notif of nonViewData?.slice(0,4)"
      (click)="updateNotify(notif)">
        <a href="javascript:void(0)" class="btn btn-sm btn-light-secondery  mr-2 mt-2 p-2 nav-btns">
          <img src="./assets/img/asociat1.svg" width="30"> </a>
        <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
          <a href="javascript:void(0)">
            <p class="font-weight-medium mb-0 mt-2"> {{notif.objecttype}}
            </p>
            <p class="text-muted mb-1 text-small d-inline-block text-truncate text-nowrap  overflow-hidden"
              style="text-overflow: ellipsis;width: 325px;">{{notif.reason}}</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Notifications Modal -->
<div class="modal fade modal-right" id="viewNotificationPopup" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notifications</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="row">
        <div class="col-lg-12 mt-3 text_right">
          <ul class="pagination">
            <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
          </ul>
        </div>
      </div>
      <div *ngIf="notifications?.length == 0" class="no-notifications"></div>
      <div class="row d-flex  ml-2">
        <div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left blt-2 mb-2 mt-1"
          *ngFor="let reports of notifications | paginate: { id: 'p2', itemsPerPage: 6, currentPage: p2 }"
          [ngClass]="{'blt-3': reports.viewed == 'Y'}" (click)="updateNotify(reports)">
          <a href="javascript:void(0)" class="p-2 icon-appointments">
            <i *ngIf="reports.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3" aria-hidden="true"></i>
            <i *ngIf="reports.viewed != 'Y'" class="fa fa-bell fa-lg pt-2  icon-col-3" aria-hidden="true"></i>
          </a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
            <a href="javascript:void(0)">
              <p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
              </p>
              <p class="text-muted mb-1 text-small">{{reports.reason}}</p>
            </a>
          </div>
          <div class="btn-group float-right">
            <button class="btn btn-xs btn0col1" data-toggle="modal" data-backdrop="static"
              data-target="#notificationToView"><i class="fa fa-eye" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Notifications Modal Ends -->

<!-- Notifications View Modal -->
<div class="modal fade" id="notificationToViewSidbar" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered notificationViewerModal_Style" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notification Viewer</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="block inv-section">
          <div class="text-center mb-2">
            <img alt="Logo" src="./assets/img/logo.png" style="border-radius: none !important;">
          </div>
          <div class="text-center">
            <p class="font-weight-medium mb-1 mt-1">{{notifyToView?.objecttype |titlecase}}</p>
            <p class="text-muted mb-1 text-small">{{notifyToView?.reason}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Notifications View Modal Ends -->
