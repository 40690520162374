<div class="row activity">

    <div class="col-lg-12 text-right" *ngIf="isIvf() && roles?.editMedicalHistoryPermission">
        <div class="mt-0">
            <button style="margin-top: -6px;
            margin-right: 0px;
            margin-bottom: 12px;" type="button"  class="btn btn-success btn-xs"
                (click)="openMedicalHistory()"><i  class="fa-solid fa-circle-plus pr-1" aria-hidden="true"></i> Add History</button>
        </div>
    </div>

    <div class="col-lg-12 text-right" *ngIf="isClinic()">
        <div class="mt-0">
            <button style="margin-top: 10px;" type="button"  class="btn btn-success btn-sm"
                (click)="openMedicalHistory()">Add History</button>
        </div>
    </div>

    <div class="col-md-8" style="display:none">
    <div class="card">
    <div class="position-absolute card-top-buttons">
    <button class="btn btn-header-light icon-button">
    <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
    </button>
    </div>
    <div class="card-body">
    <h5 class="card-title">  <i class="fa fa-pills themecol2 icon-head" aria-hidden="true"></i> Current Medication & Activity</h5>
    <form>
    <div>
    <input type="radio" name="size" id="small" value="small" />
    <label for="small" style="font-weight: 600 #eee ;">Sun</label>
    <article class="mlm_10"><p>Sunday<span style="padding-left:65px;">Jan 7 </span> </p>
    <div class="row pl_10">
    <div class="col-md-6 border_right mlm_17">
    <span style="border-right:1px solid #eee;"><span class="mt-2 float-left"> Morning </span>

    </span>
    </div><div class="col-md-6 border_right mlm_17"><span class="mt-2 float-left"> Evening </span>

    </div>
    </div>

    </article>
    </div>
    <div>
    <input type="radio" name="size" id="medium" value="medium" />
    <label for="medium"  style="font-weight: 600 #eee ;">Mon</label>
    <article class="mlm_10"><p>Morning<span style="padding-left:65px;">Evening</span> </p>
    <div class="row pl_10">
    <div class="col-md-6 border_right mlm_17">

    <ul class="medication" >
        <li *ngFor="let item of activeMedicationList" ><i class="fa fa-tablets"></i> {{item.medicineName}}</li>
    </ul>



    </div>

    <div class="col-md-6 border_right mlm_17"><span class="mt-2 float-left"> Evening </span></div>
    </div>

    </article>
    </div>
    <div>
    <input type="radio" name="size" id="large" value="large" />
    <label for="large"  style="font-weight: 600 #eee ;">Tue</label>
    <article class="mlm_10"><p>Tuesday<span style="padding-left:65px;">Jan 9 </span> </p>
    <div class="row pl_10">
    <div class="col-md-6 border_right mlm_17"><span style="border-right:1px solid #eee;"><span class="mt-2 float-left">
    Morning </span> </span></div><div class="col-md-6 border_right mlm_17"><span class="mt-2 float-left"> Evening </span></div>
    </div>

    </article>
    </div>
    <div>
    <input type="radio" name="size" id="xlarge" value="xlarge" />
    <label for="xlarge"  style="font-weight: 600 #cecece ;">Wed</label>
    <article class="mlm_10"><p>Wednesday<span style="padding-left:35px;">Jan 10 </span> </p>
    <div class="row pl_10">
    <div class="col-md-6 border_right mlm_17"><span style="border-right:1px solid #eee;"><span class="mt-2 float-left">
    Morning </span> </span></div><div class="col-md-6 border_right mlm_17"><span class="mt-2 float-left"> Evening </span></div>
    </div>

    </article>
    </div>
    <div>
    <input type="radio" name="size" id="xxlarge" value="xxlarge" />
    <label for="xxlarge"  style="font-weight: 600 #cecece ;">Thu</label>
    <article class="mlm_10"><p>Thursday<span style="padding-left:45px;">Jan 10 </span> </p>
    <div class="row pl_10">
    <div class="col-md-6 border_right mlm_17"><span style="border-right:1px solid #eee;"><span class="mt-2 float-left">
    Morning </span> </span></div><div class="col-md-6 border_right mlm_17"><span class="mt-2 float-left"> Evening </span></div>
    </div>

    </article>
    </div>
    <div>
    <input type="radio" name="size" id="xxxlarge" value="xxxlarge" />
    <label for="xxxlarge"  style="font-weight: 600 #cecece ;">Fri</label>
    <article class="mlm_10"><p>Friday<span style="padding-left:65px;">Jan 11 </span> </p>
    <div class="row pl_10">
    <div class="col-md-6 border_right mlm_17"><span style="border-right:1px solid #eee;"><span class="mt-2 float-left">
    Morning </span> </span></div><div class="col-md-6 border_right mlm_17"><span class="mt-2 float-left"> Evening </span></div>
    </div>

    </article>
    </div>
    <div>
    <input type="radio" name="size" id="xxxxlarge" value="xxxxlarge" />
    <label for="xxxxlarge"  style="font-weight: 600 #cecece ;">Sat</label>
    <article class="mlm_10"><p>Saturday<span style="padding-left:50px;">Jan 12 </span> </p>
    <div class="row pl_10">
    <div class="col-md-6 border_right mlm_17"><span style="border-right:1px solid #eee;"><span style="font-size:12px;"><br> Morning </span> </span></div><div class="col-md-6"><span > Evening </span></div>
    </div>

    </article>
    </div>
    </form>









    </div>
    </div>
    </div>
    </div>
    <div class="row">
        <div class="col-md-4 ">
            <div class="card  h-100">
            <div class="position-absolute card-top-buttons">
            <button class="btn btn-header-light icon-button">
            <!-- <i class="fa fa-ellipsis-h" aria-hidden="true"></i> -->
            </button>
            </div>
            <div class="card-body ">
            <h5 class="card-title bcmap text-white">  <i class="fa fa-diagnoses text-white icon-head" aria-hidden="true"></i> Allergies</h5>
            <div  p-2 *ngIf="medicalHistory?.socialhistory == 0">
                <div class="no-image-bg"></div>
            </div>
        
            <div class=" p-2" >
            <div  *ngFor="let item of allergies" class=" d-flex flex-column flex-grow-1 text-left" >
        
            <div class="d-flex flex-row bg__whiterounded5 mb-2">
        
            <div class="pl-1 pr-2 d-flex flex-column flex-grow-1">
            <a href="javascript:void(0)" style="pointer-events: none;">
            <p class="font-weight-medium p_btm">{{item}}    <span class="text-muted text-small" style="float: right;"> </span> </p>
            </a>
            </div>
            </div>
        
            </div>
            </div>
        
        
        
        
            </div>
            </div>
            </div>
        
            <!-- Slot listing 2 end -->
        
            <div class="col-md-4 ">
        
                <div class="card h-100">
                <div class="position-absolute card-top-buttons">
                <button class="btn btn-header-light icon-button">
                <!-- <i class="fa fa-ellipsis-h" aria-hidden="true"></i> -->
                </button>
                </div>
                <div class="card-body" >
                <h5 class="card-title bcmap text-white">  <i class="fa fa-history text-white icon-head" aria-hidden="true"></i> Family History</h5>
                <div class="d-flex flex-row  p-2">
        
        
                <div   class="pl-1  d-flex flex-column flex-grow-1 text-left " style="width: 242px;">
                    <div  p-2 *ngIf="medicalHistory?.familyhistory == 0">
                        <div class="no-image-bg"></div>
                    </div>
                <div class="d-flex flex-column bg__whiterounded6 mb-2" *ngFor="let item of medicalHistory?.familyhistory">
                <div class="pl-1 pr-2 d-flex flex-column flex-grow-1  ">
        
                <p class="font-weight-medium p_btm">{{item.ailment}}<span class="text-muted text-small" style="float: right;">{{item.relationship}}</span>  </p>
        
                </div>
        
                </div>
        
        
        
        
        
                </div>
        
        
        
        
        
        
        
        
                </div>
                </div>
        
        
                </div>
                </div>
        
        
                <div class="col-md-4 ">
        
                    <div class="card h-100">
                    <div class="position-absolute card-top-buttons">
                    <button class="btn btn-header-light icon-button">
                    <!-- <i class="fa fa-ellipsis-h" aria-hidden="true"></i> -->
                    </button>
                    </div>
                    <div class="card-body" >
                    <h5 class="card-title text-white bcmap">  <i class="fa fa-history text-white icon-head" aria-hidden="true"></i> Addictions </h5>
                    <div class="d-flex flex-row  p-2">
        
        
                        <div   class="pl-1  d-flex flex-column flex-grow-1 text-left " style="width: 242px;">
                            <div class="d-flex flex-column bg__whiterounded5 mb-2">
                            <div class="pl-1 pr-2 d-flex flex-column flex-grow-1  ">
        
                            <p class="font-weight-medium p_btm">Alcohol<span class="text-muted text-small" style="float: right;">{{questionairre?.alcohol | titlecase}}</span>  </p>
        
                        </div>
        
                    </div>
                            <div class="d-flex flex-column bg__whiterounded5 mb-2">
                                <div class="pl-1 pr-2 d-flex flex-column flex-grow-1  ">
        
                                <p class="font-weight-medium p_btm">Drugs<span class="text-muted text-small" style="float: right;">{{questionairre?.drugs| titlecase}}</span>  </p>
        
                            </div>
        
                        </div>
                                <div class="d-flex flex-column bg__whiterounded5 mb-2">
                                    <div class="pl-1 pr-2 d-flex flex-column flex-grow-1  ">
        
                                    <p class="font-weight-medium p_btm">Tobacco<span class="text-muted text-small" style="float: right;">{{questionairre?.tobacco| titlecase}}</span>  </p>
        
                            </div>
        
                            </div>
        
        
        
        
        
                            </div>
        
        
        
        
        
        
        
        
                    </div>
                    </div>
        
        
                    </div>
                    </div>
        
    </div>
    





<div class="row">


    <div class="col-md-8">
    <div class="card  mt-2 h-100">
    <div class="position-absolute card-top-buttons">
    </div>
    <div class="card-body" >
    <h5 class="card-title text-white bcmap">  <i class="fa fa-history text-white icon-head" aria-hidden="true"></i> Medical Journal </h5>


    <div class="mt-2">

    <!-- <a href="javascript:void(0);" style="pointer-events: none;" *ngFor="let item of medicalHistory?.suregerydetails" class="button_one active">{{item.date | date: 'yyyy'}}</a> -->
    <div class="d-flex flex-row  p-2  ">
    <div class="pl-1  d-flex flex-column flex-grow-1 text-left">
    <div *ngIf="medicalHistory?.suregerydetails.length ==0">
        <div class="no-image-bg"></div>
    </div>
    <div class="history-tl-container" *ngIf="medicalHistory?.suregerydetails.length >0">
    <ul class="tl">
    <li  *ngFor="let item of medicalHistory?.suregerydetails" class="tl-item">
    <span class="mlm_5"><img src="assets/img/icon_01.png"></span>
    <div class="history_bg mlm_3">
    <div class="item-title float-left">{{item.name}} </div>
    <div class="item-date text-right"> <span style=" color: #3e7dbf; padding-left:15px;"></span>{{item.date | date: 'dd-MMM-yyyy'}} </div>
    </div>
    </li>
    </ul>

    </div>


    </div>
    <!-- Slot listing 5 end -->
    </div>
    </div>




    </div>
    </div>
    </div>

    <div class="col-md-4">

        <div class="card mt-2 h-100">
        <div class="position-absolute card-top-buttons">

        </div>
        <div class="card-body" style="min-height: 181px;">
        <h5 class="card-title text-white bcmap">  <i class="fa fa-calendar-day text-white icon-head" aria-hidden="true"></i> Personal Medical History</h5>
        <div class="d-flex flex-row  p-2">


        <div   class="pl-1  d-flex flex-column flex-grow-1 text-left "  >
            <div  p-2 *ngIf="personalMedicalHistory == 0">
                <div class="no-image-bg"></div>
            </div>
        <div class="d-flex flex-column bg__whiterounded6 mb-2" *ngFor="let item of personalMedicalHistory">
        <div class="pl-1 pr-2 d-flex flex-column flex-grow-1  ">

        <p class="font-weight-medium p_btm">{{item.symptomsdesc}}</p>

        </div>

        </div>





        </div>








        </div>
        </div>


        </div>
        </div>

    </div>