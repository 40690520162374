import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { VitalsService } from './vitals.service';
import { ClincInPatientDetailsComponent } from 'src/app/clinic-pages/clinc-in-patient-details/clinc-in-patient-details.component';
import { ObservableService } from '../../services/observable.service';
declare var $: any;


@Component({
  selector: 'app-add-vitals',
  templateUrl: './add-vitals.component.html',
  styleUrls: ['./add-vitals.component.css']
})
export class AddVitalsComponent implements OnInit {
  @Output() fromVital= new EventEmitter<any>();
  constructor(public vs: VitalsService,private router: Router,private obs:ObservableService) {
    this.obs.ivf_vitals_edit.subscribe(value=>{
      console.log('vitaals subscribed')
        this.vs.patientVitals();
        this.vs.vitalsOnInit();
        this.fromVital.emit()
    })
  }
  date;
  ngOnInit(): void {
    this.date = formatDate(new Date(), "dd-MM-yyyy HH:mm:ss", "en-us")
    this.vs.patientVitals();
    this.vs.vitalsOnInit();
    this.vs.submitted=false
  }
  
  closeModal(data){
    this.vs.submitted=false;
    $('#addvitals').modal('hide')
    $("#addvitals").val(null).trigger("change");
  }
}
