<div class="row">
    <div class="col-md-12">
        <div class="table-responsive m-t-40">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="text-left">Medicine</th>
                        <th class="text-center">Quantity</th>
                        <th class="text-center">Unit Price</th>
                        <th class="text-center">Tax</th>
                        <th class="text-center">Price</th>
                        <th class="text-center">Discount(%)</th>
                        <th class="text-center">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let medicine of medicationPrescriptions">
                        <td>
                          <span class="eclips"> {{medicine?.medicine}}</span>
                        </td>
                        <td class="text-center">{{medicine?.quantity}}</td>
                        <td class="text-center">{{medicine?.unitPrice}}</td>
                        <td class="text-center">0</td>
                        <td class="text-center">
                          {{((medicine.unitPrice*medicine.quantity)+((medicine.unitPrice*medicine.quantity*medicine.cgst)/100)+((medicine.unitPrice*medicine.quantity*medicine.sgst)/100)).toFixed(2)}}
                        </td>
                        <td class="text-center">{{medicine.discount}}</td>
                        <td class="text-center float-right"><i class="fa fa-inr"
                            aria-hidden="true"></i>&nbsp;{{(((medicine.unitPrice*medicine.quantity)+((medicine.unitPrice*medicine.quantity*medicine.cgst)/100)+((medicine.unitPrice*medicine.quantity*medicine.sgst)/100))-(((medicine.unitPrice*medicine.quantity)+((medicine.unitPrice*medicine.quantity*medicine.cgst)/100)+((medicine.unitPrice*medicine.quantity*medicine.sgst)/100))*medicine.discount)/100).toFixed(2)}}
                        </td>
                      </tr>
                </tbody>
                <tfoot class="text-right">
                    <tr>
                      <td colspan="6"><b>Sub-total:</b></td>
                      <td colspan="3"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{paidAmount}}</td>
                    </tr>
                    <tr>
                      <td colspan="6"><b>Total Amount:</b></td>
                      <td colspan="3"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp;{{paidAmount}}</td>
                    </tr>
                  </tfoot>
            </table>
        </div>
    </div>
    <hr>
    <!-- <div class="col-md-12">
        <hr>
        <div class="float-end m-t-0 text-right">
            <p>Sub - Total amount: $2600</p>
            <p>Discount : $100 </p>
            <p>vat (10%) : $160 </p>
            <hr>
            <h6><b>Total :</b> $2760</h6>
        </div>
        <div class="clearfix"></div>
        <hr>
    </div> -->
    <div class="invoice-info col-md-12" *ngIf="prescriptionInfo?.status=='Paid' || prescriptionInfo?.status=='Shipped' || prescriptionInfo?.status=='DELIVERED'">
         <button type="button" class="btn btn-danger btn-xs m-1 mr-2 float-right" aria-label="Close" (click)="downloadAsPDF()">
            <i class="fa fa-download"></i> Download Invoice</button>
      </div>
    <div *ngIf="prescriptionInfo?.status=='REQUEST_PAYMENT' || prescriptionInfo?.status=='REQUEST_TO_PAY' || prescriptionInfo?.status=='Confirmed'" 
        class="col-md-12">
        <div class="d-flex flex-row mb-0 pl-1 border-bottom border-top">
            <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
                <a href="javascript:void(0)" class="mt-2" (click)="consentForm()">
                    <p class="font-weight-medium mb-1 mt-1" style="margin-left:10px;">
                        <input type="checkbox" style="position: relative; top: 3px;" id="consent" [(ngModel)]="consent"
                            name="" value="" [disabled]="disableCB" [checked]="checkAgree">
                        <label class="ml-1"> I Agree</label>
                    </p>
                </a>
            </div>
            <div class=" float-right mt-2">
                <a href="javascript:void(0)" class="btn btn-sm mr-3 mt-2 p-1"
                    style="text-decoration: underline; margin-right: 16px; margin-top: 7px;" class="float-right"
                    (click)="consentForm()"> Patient Consent Form </a>
            </div>
        </div>
        <form [formGroup]="customStripeForm"
            *ngIf="prescriptionInfo?.status=='REQUEST_PAYMENT' || prescriptionInfo?.status=='REQUEST_TO_PAY' || prescriptionInfo?.status=='Confirmed'">
            <ng-template matStepLabel>Card Details</ng-template>
            <div class="panel-footer">
                <div class="row">
                    <div class="col-xs-12 col-md-12">
                        <button class="btn btn-primary btn-lg btn-block" *ngIf="!formProcess" (click)="pay()"
                            [disabled]="!checkAgree">Proceed to
                            Pay</button>
                        <span class="btn btn-danger btn-lg btn-block" *ngIf="formProcess">Processing please
                            wait...</span>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>