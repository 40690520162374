import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DoctorGuardGuard implements CanActivate {
  constructor(private globalStorage: GlobalStorageService, private router: Router,private cookieServices : CookieService) {}

  doctor;
  hospital;

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (sessionStorage.getItem('loginDetails') != null) {
      this.globalStorage.getItem('LoginDetails')
    }
    else {
      this.globalStorage.removeItem('LoginDetails')
    }
    if (!this.globalStorage.getItem('userSession')) {
    //  this.globalStorage.setItem('userSession',this.cookieServices.get('usersession'))
    }
    this.doctor = JSON.parse(this.globalStorage.getItem('LoginDetails'))
    this.hospital = JSON.parse(this.globalStorage.getItem('userSession'))
    if (this.doctor?.userSession != undefined || this.doctor?.userSession != null || this.hospital != null || this.hospital != undefined) {
      return true;
    }
    else {
      this.router.navigate(["/Login"])
      return false
    }
  }

}
