import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import { ApicallsService } from 'src/app/apicalls.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InternalMessageService {
  pharmaUrl = environment.pharmaUrl
  clinicUrl = environment.hospitalUrl
  ivfUrl = environment.ivfUrl
  baseUrl: string = '';
  moduleName = this.globalStorage.getItem('loggedInAs')
  addressId: any;
  constructor(private globalStorage: GlobalStorageService, private api: ApicallsService) {
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
      this.pharmaUrl = environment.pharmaUrl1
      this.clinicUrl = environment.hospitalUrl1
      this.ivfUrl = environment.ivfUrl1
    }
    switch (this.moduleName) {
      case 'HOSPITAL':
        this.baseUrl = this.clinicUrl;
        this.addressId = this.globalStorage.getItem('hospitalAddressId')
        break;
      case 'IVF':
        this.baseUrl = this.ivfUrl;
        this.addressId = this.globalStorage.getItem('ivfAddressId')
        break;
      case 'PHARMACY':
        this.baseUrl = this.pharmaUrl;
        this.addressId = this.globalStorage.getItem('pharmacyId')
        break;
    }
  }

  getdepartmentInformation(hospitalAddressId){
    return this.api.get(this.baseUrl + 'departmentInformation/' + hospitalAddressId, {});
  }

  getAllAssociatedUsers(body){
    return this.api.post(this.baseUrl+'fetchAssociatedUsers',body,{})
  }

  allroles(){
    return this.api.post(this.baseUrl+'allRole',{},{})
  }

  createMessage(body){
    return this.api.post(this.baseUrl+'message/createMessage',body,{})
  }

  getMessages(addressId,userId){
    return this.api.get(this.baseUrl+'message/getMessage?addressId='+addressId+'&userId='+userId,{})
  }

  getUsersByRole(addressId,roleId,departmentId){
    return this.api.get(this.baseUrl+'userroleDetails?addressId='+addressId+'&departmentId='+departmentId+'&roleId='+roleId,{})
  }
  
  updateMessage(messageId){
    return this.api.post(this.baseUrl+'message/updateMessage?userMessageId='+messageId,{},{}) 
  }
}
