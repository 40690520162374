import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IvfService } from '../services/ivf.service';
import { NavigationExtras, Router } from '@angular/router';
import { BookDoctorAppointmentComponent } from '../book-doctor-appointment/book-doctor-appointment.component';

@Component({
  selector: 'app-ivf-registration-holder',
  templateUrl: './ivf-registration-holder.component.html',
  styleUrls: ['./ivf-registration-holder.component.css']
})

export class IvfRegistrationHolderComponent implements OnInit {

  @Input() patientId:any;
  @Input() spouseId:any;
  @Input() isPrimaryRegistered:boolean=false;
  @Input() patientDetails:any;
  @Input() isEdit:false;
  referenceId:any
  // primaryPatientDetails:any;
  spouseDetails:any;


  isPatientSingle:boolean = false;

  tabs = [
    { title: 'Register', identifier: 'registrationTab'},
    { title: 'Personal Details', identifier: 'personalDetailsTab' },
    // { title: 'Medical History', identifier: 'medicalHistoryTab' },
  ];
  activeTab = this.tabs[0];
  // userId:any;

  constructor(private globalStorage: GlobalStorageService, private modalService:NgbModal,private activeModal:NgbActiveModal,private ivfService:IvfService,private router:Router) { }

  ngOnInit(): void {
    if(this.isEdit){
      if(this.isPrimaryRegistered){
        this.referenceId=this.spouseId;
      }else{
        this.referenceId=this.patientId;
      }
    }
  }

  tabClicked(tab) {

    if (tab.identifier === 'registrationTab') {
      this.activeTab = tab;
    } else if (tab.identifier === 'personalDetailsTab' && this.patientId) {
      this.activeTab = tab;
    }
    // else if (tab.identifier === 'medicalHistoryTab' && this.patientId) {
    //   this.activeTab = tab;
    // }
  }

  primaryRegCompleted(Id){
    if(!this.isPrimaryRegistered){
      this.patientId=Id;
    }else{
      this.spouseId=Id;
    }
    this.referenceId=Id;
    this.tabClicked(this.tabs[1]);
  }

  setPatientDetails(details){
    if(!this.isPrimaryRegistered){
      this.patientDetails=details;
    }else{
      this.spouseDetails=details;
    }

    if(details.single == 'Y'){
      this.isPatientSingle=true;
    }else{
      this.isPatientSingle=false;
    }
  }

  personalDetailsSaved(data){
    if(this.isEdit){
      this.activeModal.close();
    }else{
      this.isPrimaryRegistered = true;
      this.tabClicked(this.tabs[0]);
    }
    // this.tabClicked(this.tabs[2]);
  }

  patHistoryCompleted(){
    if(this.isEdit){
      this.activeModal.close();
    }else{
      this.isPrimaryRegistered = true;
      this.tabClicked(this.tabs[0]);
    }
  }

  navigateToPatients() {
    console.log('navigating')
    const navigationExtras: NavigationExtras = {
      state: {
        patientName: this.patientDetails.firstName +' '+this.patientDetails.lastName,
        userIdFromReg: this.patientId,
      }
    };
    this.router.navigate(['/ivf-patients'], navigationExtras)
    this.closeModal();
    // this.globalStorage.removeItem('wifeId')
    // this.globalStorage.removeItem('husbandId')
    // this.globalStorage.removeItem('isPrimaryRegistered')
    // this.globalStorage.removeItem('primaryUserId')
    // this.globalStorage.removeItem('primaryAddressId')
    // this.globalStorage.removeItem('primaryReferenceId')
    // this.globalStorage.removeItem('spouseUserId')
    // this.globalStorage.removeItem('spouseAddressId')
    // this.globalStorage.removeItem('spouseReferenceId')
  }

  bookDoctorAppointment(id) {

    if(!this.isEdit){
      this.ivfService.triggerPatientRegistered();
    const modalRef = this.modalService.open(BookDoctorAppointmentComponent, {
      centered: false,
      windowClass: 'left w-40',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.userId = id;

    modalRef.result.then(
      (userConfirmed: boolean) => {
        if (userConfirmed) {
        }
      },
      () => { }  // Handle dismiss callback
    );

    this.closeModal()
    }else{
      this.closeModal()
    }

  }


  closeModal() {
    this.ivfService.triggerPatientRegistered();
    this.activeModal.close(true);
  }

}
