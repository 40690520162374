import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../AppServices/login.service'
import { ToastrService } from 'ngx-toastr'
import { CookieService } from 'ngx-cookie-service';
import { Crypto } from '../../_helpers/CryptoJ'
import { AuthService } from 'src/app/shared/services/auth.service';
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import * as moment from 'moment';
import { ApicallsService } from 'src/app/apicalls.service';
import { DoctorNotificationService } from 'src/app/shared/doctor-notification.service';
declare var $: any;

@Component({
  selector: 'app-doctorlogin',
  templateUrl: './doctorlogin.component.html',
  styleUrls: ['./doctorlogin.component.css']
})
export class DoctorloginComponent implements OnInit {
  notFocused = false;
  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder, private router: Router, private forgot: AuthService, private apiservice: ApicallsService,
    private login: LoginService, private doctorService: DoctorService, private patientController: PatientScheduleControlService,private docnotificationService:DoctorNotificationService,
    private ToastrService: ToastrService, private cookieService: CookieService, private crpto: Crypto) {
    if (this.cookieService.get('rememberme') == 'Yes' && this.cookieService.get('module') == 'Doctor') {
      this.rememberme = true;
      this.email = this.crpto.Decrypt(this.cookieService.get('email'), 'email');
      this.password = this.crpto.Decrypt(this.cookieService.get('password'), 'password');
    }

  }
  email
  password
  LoginDetails: FormGroup;
  submitted
  firstSession
  message
  today
  doctor: any;
  rememberme: boolean = false;
  invalidCredentials: boolean = false
  verifyNowButton = false
  @ViewChild('Menu', { static: false }) public Menu: ElementRef;

  ngOnInit() {



    // if (navigator.userAgent.match(/Android/i)) {
    //   window.location.href = 'https://play.google.com/store/apps/details?id=com.curemetric.doctor'
    // }
    // else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    //   window.location.href = 'https://apps.apple.com/in/app/curemetric-doctor/id1670558834'
    // }


    $(function () {
      $(window).off('beforeunload');
      $("#preloader").fadeOut();
      $("#status").fadeOut(9000);
    });
    this.LoginDetails = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(8)]],   //Validators.pattern['/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/']]
    });
  }

  get f() { return this.LoginDetails.controls; }
  get pwd() {
    return this.LoginDetails.get('password');
  }

  onSubmit() {

    // this.router.navigate(['dashboard']);
    this.submitted = true;
    if (this.LoginDetails.invalid) {
      return;
    }

    this.apiservice.isUserSessionExits = false
    this.apiservice.isforUserIdExits = false
    this.apiservice.ismoduleNameExits = false
    //this.globalStorage.clear();
    if (this.rememberme) {
      this.cookieService.set('email', this.crpto.encryption(this.email, "email"));
      this.cookieService.set('password', this.crpto.encryption(this.password, 'password',));
      this.cookieService.set('rememberme', 'Yes')
      this.cookieService.set('module', 'Doctor')
    } else {
      this.cookieService.set('email', "");
      this.cookieService.set('password', "");
      this.cookieService.set('rememberme', "")
    }

    var loginDetails = { "userName": this.LoginDetails.value.email, "type": "DOCTOR", "password": this.LoginDetails.value.password }


    this.login.Login(loginDetails).subscribe(res => {
      if (res['data'] != null && res['userSession'] != null) {
        var user = res
        var userData = user['data']
        if (userData.verificationDone == 'N') {
          this.verifyNowButton = true;
        }
        else {
          this.globalStorage.setItem('userSession', JSON.stringify(user['userSession']));
          this.firstSession = this.globalStorage.setItem('userSession', JSON.stringify(user['userSession']));
          this.globalStorage.setItem('firstName', JSON.stringify(userData.firstName));
          this.globalStorage.setItem('lastName', JSON.stringify(userData.lastName));
          this.globalStorage.setItem('email', userData.emailId);
          this.globalStorage.setItem('doctorFirstName', userData.firstName);
          this.globalStorage.setItem('doctorLastName', userData.lastName)
          this.globalStorage.setItem('lastLogin', JSON.stringify(userData.lastLogin));
          this.globalStorage.setItem('loggedInAs', 'DOCTOR')
          sessionStorage.setItem('loginDetails', JSON.stringify(res))
          this.globalStorage.setItem('LoginDetails', JSON.stringify(res));
          this.globalStorage.setItem('doctorGender', JSON.stringify(userData.gender))
          this.globalStorage.setItem('dpswd', this.LoginDetails.value.password)
          this.globalStorage.setItem('doctorId', JSON.stringify(userData.doctorId));
          this.globalStorage.setItem('userId', JSON.stringify(userData.doctorId));
          this.globalStorage.setItem('entityId', JSON.stringify(userData.doctorId));
          this.globalStorage.setItem('forUserId', JSON.stringify(userData.doctorId));
          this.today = moment(userData.lastLogin).format('YYYY-MM-DD HH:mm:ss');
          this.apiservice.isUserSessionExits = true
          this.apiservice.isforUserIdExits = true
          this.apiservice.ismoduleNameExits = true
          this.globalStorage.setItem('isPersonalDetailCompleted', userData?.isPersonalDetailCompleted);
          this.globalStorage.setItem('isFeeDetailCompleted', userData?.isFeeDetailCompleted);
          this.globalStorage.setItem('isAddressCompleted', userData?.isAddressCompleted);
          if (this.globalStorage.getItem('isPersonalDetailCompleted') == 'N' || this.globalStorage.getItem('isPersonalDetailCompleted') != 'Y') {
            this.router.navigate(['/personalDetails']);
          }
          else {
            if (this.globalStorage.getItem('isAddressCompleted') != 'Y') {
              this.router.navigate(['/addressDetails'])
            }
            else {
              if (this.globalStorage.getItem('isFeeDetailCompleted') != 'Y') {
                this.router.navigate(['/EditProfile'])
              }
              else {
                this.router.navigate(['/dashboard']);
                this.docnotificationService.requestPermission(parseInt(this.globalStorage.getItem('userId')));
              }
            }
          }
        }
      }
      if (this.firstSession != this.firstSession) {
        this.router.navigate(['/dashboard']);
        this.docnotificationService.requestPermission(parseInt(this.globalStorage.getItem('userId')));
        // this.docnotificationService.receiveMessage();
        // this.docnotificationService.currentMessage;
      }
      if (res['status'] == 'FAILURE') {
        this.invalidCredentials = true
        this.message = res['message']
        //this.ToastrService.error("Your email is not verified. Please check your email to verify. Contact us at support@qontrack.com for any help.");
      }


    }
      , error => {
        var message = error['error'].message
        this.message = error['error'].message
        if (message != null) {
          // this.ToastrService.error("Invalid Login - Either User Name or Password is wrong. Please check.","Login");
          // this.ToastrService.error(message);
          this.invalidCredentials = true
          this.message = error['error'].message

          if (this.message == 'login As Doctor Failed:Your Email Id Is  Unverified' || error['error']['message'] == 'Your Email Id Is  Unverified') {
            this.invalidCredentials = true
            this.message = error['error']['message']
            //this.verifyNowButton = true
          }
          //this.LoginDetails.reset();
        } else {
          this.invalidCredentials = true
          this.message = error['error'].message
          // this.ToastrService.error(error['error'].message);

        }
      })
    //if (this.message == 'login As Doctor Failed:Your Email Id Is  Unverified' || this.message == 'Your Email Id Is  Unverified')
    // {
    //   this.verifyNowButton = true
    // }
  }
  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
  }
  onCancel() {
    this.LoginDetails.reset();
    this.submitted = false;
    if (this.LoginDetails.invalid) {
      return false;
    }
  }
  loginClose() {
    this.router.navigate(['']);
  }

  navigateToEnquiryForm(): void {
    this.router.navigate(['/enquiry-form'], { queryParams: { module:'doctor' , returnUrl: this.router.url} });
}



  remembermeCheck() {
    if (this.rememberme) {
      this.rememberme = false;
    } else {
      this.rememberme = true;
    }
  }
  verifyNow() {
    this.router.navigate(['/doctor-verify'])
  }

  // this.patientController.getNotificationForWeek(today,obj['userId'],obj['userId']).subscribe(res=>{
  //   var array=res['data'].reverse().slice(-5)
  //   for (var i=0;i<array.length;i++){
  //     this.ToastrService.info(res['data'][i]['reason'],res['data'][i]['objectType'],{
  //     positionClass: 'toast-bottom-right'
  //  })
  loadNotifications() {
    // this.patientController.getNotificationDoctorForWeek(this.today, this.globalStorage.getItem('doctorId'), this.globalStorage.getItem('doctorId')).subscribe(res => {
    //   this.globalStorage.setItem('notificationCount', res['data'].length)
    //   var array = res['data'].reverse().slice(-5)
    //   for (var i = 0; i < array.length; i++) {
    //     this.ToastrService.info(res['data'][i]['reason'], res['data'][i]['objectType'], {
    //       positionClass: 'toast-bottom-right'
    //     })
    //   }
    // }, error => {
    // })
  }

  showValidationMessage: boolean = false;
  preventCopy(event: ClipboardEvent) {
    event.preventDefault();
    this.showValidationMessage = true;
    setTimeout(() => {
      this.showValidationMessage = false;
    }, 5000);
  }

  changePassword() {
    var data = this.globalStorage.getItem('LoginDetails')
    var obj = JSON.parse(data)
    var message
    var request = {
      "emailId": obj['data']['emailId'],
      //"resetPasswordLink": "http:localhost:4200/resetPassword"
    }
    this.forgot.forgotPassword(JSON.stringify(request)).subscribe(res => {
      this.ToastrService.success(res['message'])
    }, error => {
      //this.ToastrService.error(error[error])
      message = error['error'].message
    })
  }

  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });
  }

  resetMessage(){
    this.invalidCredentials = false
  }

}
