import { Component, Input, OnInit } from '@angular/core';
import { InventoryService } from '../../inventory/inventory.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-save-order-dispatch',
  templateUrl: './save-order-dispatch.component.html',
  styleUrls: ['./save-order-dispatch.component.css']
})
export class SaveOrderDispatchComponent implements OnInit {

  dispatchForm: FormGroup;
  submitted: boolean = false;
  minDate
  @Input() stockObj: any;
  @Input() addressId: any;

  constructor(
    private activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private inventoryService: InventoryService,
    private fb: FormBuilder
  ) {
    this.minDate = new Date();
   }

  ngOnInit(): void {
    this.initializeForm();
  }

  closeModal() {
    this.activeModal.close(true);
  }

  initializeForm() {
    this.dispatchForm = this.fb.group({
      dispatchRequestedBy: ['',[Validators.required]],
      dispatchRequestedTo: ['',[Validators.required]],
      dispatchRequestedOn: ['',[Validators.required]],
      dispatchedBy: ['',[Validators.required]],
      dispatchedTo: ['',[Validators.required]],
      // dispachedOn: ['',[Validators.required]],
      qtyDispatched: ['',[Validators.required]]
    });
  }

  get dispatchFormControls() {
    return this.dispatchForm.controls;
  }

  saveDispatch() {
    this.submitted = true;
    if (this.dispatchForm.invalid) {
      return false;
    }
    else {
      const dispatchedDate = formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
      const requestedDate = formatDate(this.dispatchForm.value.dispatchRequestedOn, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
      let req = {
        "addressId": this.addressId,
        "orderBatchId": this.stockObj?.orderBatchId,
        "batchNumber": this.stockObj?.currentBatch,
        "dispachedOn": dispatchedDate,
        "dispatchRequestedBy": this.dispatchForm?.value?.dispatchRequestedBy,
        "dispatchRequestedOn": requestedDate,
        "dispatchedBy": this.dispatchForm?.value?.dispatchedBy,
        "dispatchedTo": this.dispatchForm?.value?.dispatchedTo,
        "inventoryId": this.stockObj?.inventoryId,
        "qtyDispatched": this.dispatchForm?.value?.qtyDispatched,
        "dispatchRequestedTo": this.dispatchForm?.value?.dispatchRequestedTo,
      }
      this.inventoryService.saveOrderDispatch(req).subscribe(res => {
        this.toaster.success('Order Dispatched.');
        this.closeModal();
        this.dispatchForm.reset();
        this.submitted = false;
      },
        error => {
          this.toaster.error(error?.['error']?.['message']);
        }
      )
    }
  }

}
