import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';
import { take } from 'rxjs/operators';
import { BehaviorSubject, UnsubscriptionError } from 'rxjs'
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as firebase from 'firebase'
import { PatientScheduleControlService } from './services/patientScedulecontrol.service';
import { pharmaService } from './services/pharma.service';
import { DiagnosticServiceController } from './services/diagnostic.service';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class DiagnosticNotificationService {

  // messaging=firebase.messaging()
  messaging = null;
  currentMessage = new BehaviorSubject(null);
  im: any;
  users: any;

  constructor(private globalStorage: GlobalStorageService, 
    private httpClient: HttpClient, private diagnosticController: DiagnosticServiceController,
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private toaster: ToastrService) {
    this.messaging = null;
    if (firebase.messaging.isSupported()) {
      this.messaging = firebase.messaging();
    }
    this.angularFireMessaging.messaging.subscribe(
      (_messaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);

        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  /**
   * update token in firebase database
   * 
   * @param userId userId as a key 
   * @param token token as a value
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token
        this.angularFireDB.object('fcmTokensDiagnostic/').update(data)
      })
  }

  getAllData(): any {
    this.users = this.angularFireDB.object('fcmTokensDiagnostic').valueChanges()
    return this.users

  }

  /**
   * request permission for notification from firebase cloud messaging
   * 
   * @param userId userId
   */
  requestPermission(userId) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        var datas = this.globalStorage.getItem('userData')
        var obj = JSON.parse(datas)
        var request = {
          "browsertoken": token,
          "diagnosticCenterAddressId": parseInt(this.globalStorage.getItem('diagnosticCenterAddressId')),
          "diagnosticCenterUserId": parseInt(this.globalStorage.getItem('diagnosticCenterUserId')),
          "active": "Y"
        }

        this.diagnosticController.diagnosticPostSessionBody('fcm/token/save', request).subscribe(res => {
        }, error => {
        })
        this.updateToken(userId, token);

      },
      (err) => {

        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    this.messaging.onMessage((payload) => {
      // this.showNotifications(payload)
      // this.toaster.info(payload['notification']['body'])
      // this.currentMessage.next(payload);
    })
    // this.angularFireMessaging.messages.subscribe(
    //   (payload) => {  
    //     this.currentMessage.next(payload);
    //   })


  }
  showNotifications(payload: any) {
    let notify_data = payload['notification']
    let title = notify_data['title']
    let options = {
      body: notify_data['body'],
      color: 'black',
      background: 'green'

    }
    let notify: Notification = new Notification(title, options)

  }
  // Sending the payload with fcm url
  // this requires server token

}
