import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { PdfServiceService } from 'src/app/pdf-service.service';
import { PdfMakerService } from 'src/app/pdf-maker.service';

@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.css']
})
export class InvoiceDetailsComponent implements OnInit {
  invoice: any;
  id2: any;
  id1: any;
  invoicedata: any;
  invoiceList: any;

  constructor(private globalStorage: GlobalStorageService,private pdfService:PdfServiceService,
    private pdfMakeService:PdfMakerService, private doctorService:DoctorService,
    private ToastrService:ToastrService) { }
  p2=1;
  appointmentId;
  doctorId;
  ailment;
  paymentType;
  doctorAddress
  patientAddress
  


  ngOnInit(): void {
    this.loadInvoice();
    this.getData()

  }
  

  getData(): void {
    this.doctorService.doctorInvoice().subscribe(res => {
      this.invoiceList = res?.['data'];
    }, error => {
      this.ToastrService.error('Error fetching data: ', error);
    });
  }

  invoiceDetails:any[]=[]
  loadInvoice(){

    var invoice={
        "doctorid":this.globalStorage.getItem('doctorId'),
        "maxResults": 1000,
        "pageNum": 1
        }
    
    this.doctorService.getInvoiceDetails(invoice).subscribe(res=>{
    

      var list =JSON.parse(res['data'])
      this.invoiceDetails=list.data.reverse()

    },error=>{

    });
  }

//   viewInvoiceData:any
//   viewInvoice(invoice)
//   {
//    this.viewInvoiceData=invoice;
//    this.appointmentId = invoice.doctorappointmentid;
//    this.doctorId = invoice.doctorid;

//     this.getCompleteInvoiceDetails(this.doctorId,this.appointmentId);
// // this.genPDF()

//   }




  // public downloadAsPDF() {
  //   this.doctorService.downloadDoctorPdf(this.doctorobjres).subscribe(res => {
  //     console.log(res);
  //     let entity_name = this.patientAddress['contactName'] + '.pdf';
  //     var blob = new Blob([res], { type: 'application/pdf' });
  //     saveAs(blob, entity_name);
  //   },
  //   error => {
  //     this.ToastrService.error('Error downloading PDF.', error);
  //   }
  //   )
  
  //  }

getCompleteInvoiceDetails(invoice)
{
  this.invoicedata=invoice
  this.id1=invoice?.doctorid
  this.id2 =invoice?.doctorappointmentid
  this.doctorService.getDoctorInvoiceDetails(this.id2,this.id1 ).subscribe(res => 
  {
    this.pdfData=res?.data[0];
    this.doctorAddress = res['data'][0]['address'];
    this.patientAddress = res['data'][0]['patientAddressDTO'];
    this.ailment = res['data'][0]['aliment'];
    this.paymentType = res['data'][0]['transactiontype'];
    this.genPDF()
  })
}
// formatDate(date: number | string) {
//   if (date) {
//     return moment(date).format('DD-MM-YYYY');
//   } 
// }



pdfData: any;
pdfBlob: any;
downloadPdf(type) {
  this.doctorService.downloadDoctorPdf( this.pdfData).subscribe(res => {
    this.pdfBlob = new Blob([res], { type: 'application/pdf' });
    this.processPdf(type);
  },
    error => {
      this.ToastrService.error('Error downloading PDF.', error);
    }
  )
}

processPdf(type) {
  if (this.pdfBlob) {
    switch (type) {
      case 'download': {
        let entity_name = 'invoice'+'.pdf';
        this.pdfService.downloadPDF(this.pdfBlob, entity_name);
        break;
      }
      case 'print': {
        this.pdfService.openPrintWindow(this.pdfBlob);
        break;
      }
    }
  }else{
    this.downloadPdf(type)
  }
}



printPdf(type) {
  if (type) {
    this.pdfMakeService.printPdf(this.printData)
  } else {
    this.pdfMakeService.downloadPdf(this.printData)
  }
}

printData: any;
genPDF() {

this.printData = {
  
    content: [
        
         {
            columns: [
            { text: 'Invoice ID:'+this.pdfData?.invoiceId, alignment:'right'},
  
  
            ],
            margin: [0, 10, 0, 10],
          },  {
            columns: [
                { text: 'Date:'+this.pdfData?.paidOn, alignment:'right'},
  
  
            ],
            margin: [0, 10, 0, 10],
          },
          {
            text: '_'.repeat(95),
            
            margin: [0, 0, 0, 5],
          },
  
   {
        columns: [
          { text: 'Invoice From:', alignment: 'left', bold: true },
          { text: 'Invoice To:', alignment: 'left', bold: true },
        ],
        margin: [0, 10, 0, 10],
      },
      {
        columns: [
          [
            { text:'Dr.' +this.doctorAddress?.contactName, alignment: 'left'},
            { text: this.doctorAddress?.street1, alignment: 'left'},
            { text: this.doctorAddress?.city, alignment: 'left'},
            { text: this.doctorAddress?.postalLocation, alignment: 'left'},
            { text: this.doctorAddress?.state, alignment: 'left'},
            { text: this.doctorAddress?.zip, alignment: 'left'},

          ],
          [
            { text: this.patientAddress?.contactName, alignment: 'left'},
            { text: this.patientAddress?.street1, alignment: 'left'},
            { text: this.patientAddress?.city, alignment: 'left'},
            { text: this.patientAddress?.postalLocation, alignment: 'left'},
            { text: this.patientAddress?.state, alignment: 'left'},
            { text: this.patientAddress?.zip, alignment: 'left'}

          ]
        ],
        margin: [0, 5, 0, 5], 
      },
          
     
          
          
         
           
           {
        table: {
          widths: ['50%', '50%'],
          body: [
            [{ text: 'Reason', style: 'tableHeader', alignment: 'center' }, { text: 'Total', style: 'tableHeader', alignment:'center' }],
           [{ text: this.pdfData?.aliment, style: 'tableData',alignment: 'center' }, { text: +this.pdfData?.amount, style: 'tableData',alignment: 'center' }],
  
          ]
          
        },
        layout: 'noBorders',
        margin: [0, 10, 0, 0],
      },
      {
        text: '_'.repeat(95),
        
        margin: [0, 0, 0, 5],
      },
      
   { text: 'Subtotal: '+this.pdfData?.amount, style: 'tableData',alignment: 'right',bold:true },
   {
    text: '_'.repeat(95),
    
    margin: [0, 0, 0, 5],
  },     
  { text: 'Total: '+this.pdfData?.amount, style: 'tableData',alignment: 'right',bold:true },
  {
    columns: [
      { text: 'Payment Method:   '  +this.pdfData?.transactiontype , alignment: 'left' , bold:true },

    ],
    margin: [0, 10, 0, 0],
  },    
            
    ]
    ,
    styles: {
  filledHeader: {
        fillColor: '#cccccc',
        fontSize: 14,
        bold: true,
        margin: [0, 5, 0, 5]
      },
      tableHeader: {
        fillColor: '#eeeeee',
        fontSize: 12,
        bold: true,
        margin: [0, 5, 0, 5]
      },
      tableData: {
        fontSize: 10,
        margin: [0, 5, 0, 5]
      }
    
  }

}
}
}

