import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormArray } from '@angular/forms'
import { DoctorService } from '../../AppServices/doctor.service'
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DeleteConfirmationComponent } from '../../DoctorPages/delete-confirmation/delete-confirmation.component';
import { MatDialog } from '@angular/material/dialog';


import { Observable } from 'rxjs';
import { MatAutocomplete, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { NavigationExtras, Router } from '@angular/router';
import { isNgTemplate } from '@angular/compiler';
import { Console } from 'console';
import { formatDate } from "@angular/common";


declare var $: any;

@Component({
  selector: 'app-doctor-certification-details',
  templateUrl: './doctor-certification-details.component.html',
  styleUrls: ['./doctor-certification-details.component.css']
})
export class DoctorCertificationDetailsComponent implements OnInit {
  docCertiGroup: FormGroup;
  certiArray: FormArray;
  edit: boolean = true
  addForm: FormGroup;
  frmAssociation: FormGroup;
  isEdit: boolean = false;
  @Input() type: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  entityName; entityId
  myControl = new FormControl();
  options: string[] = ['One', 'Two', 'Three'];
  filteredOptions: Observable<string[]>;
  selectedDegree;
  minDate;
  maxDate
  today = new Date();
  certificatesList = [];
  selectedCertificate;
  constructor(private globalStorage: GlobalStorageService, public dialog: MatDialog, private fb: FormBuilder, private sanitizer: DomSanitizer, private DoctorService: DoctorService, private router: Router, private ToastrService: ToastrService, private forgot: AuthService) {
    this.docCertiGroup = this.fb.group({
      test: [''],
    });


    this.frmAssociation = this.fb.group({
      associationId: [],
      association: [''],
      doctorId: [this.globalStorage.getItem('doctorId')],
      hospitalId: ['', Validators.required],
    })


    // this.certiArray = this.fb.array([]);
    // this.associationArray = this.fb.array([]);
    var age = parseInt(this.globalStorage.getItem('Age'))
    var toDay = new Date()
    var year = toDay.getFullYear()
    var yeartovalidate = (age) + 25;
    this.minDate = new Date(yeartovalidate, 0, 1)
    var d = new Date();
    this.maxDate = d.getFullYear();
  }
  loginUser
  submitted: boolean = false;
  submit2: boolean = false;

  MyAssociation: any[]

  selectMyAssociation: any[]

  module;
  NewlyRegDoctors


  filteredValues
  ngOnInit(): void {
    var data = JSON.parse(this.globalStorage.getItem('LoginDetails'));
    this.module = data['data']['type'];
    this.DoctorService.getCertificationList(this.module).subscribe(res => {
      for (var item of res['data']) {
        this.certificatesList.push(item.lookupValue);
      }
    });

    this.NewlyRegDoctors = new MatTableDataSource(this.MyAssociation)
    this.checkFormIsEmptyOrNot()
    this.myControl.valueChanges.subscribe(newValue => {
      this.filteredValues = this.filterValues(newValue);
    })

    var data = JSON.parse(this.globalStorage.getItem('LoginDetails'));
    this.loginUser = data.data;
    this.getCertificationDetails();
    this.getAssociation();

    this.addForm = this.fb.group({
      certificationId: [],
      doctorId: JSON.parse(this.globalStorage.getItem('doctorId')),
      instituteName: ['', Validators.required],
      degree: ['', Validators.required],
      yearGraduated: [, [Validators.required, Validators.min(1920), Validators.max(this.maxDate), Validators.pattern(new RegExp("[0-9]{4}"))]]
    })
  }

  filterValues(search: string) {
    return this.MyAssociation.filter(value =>
      value.toLowerCase().indexOf(search.toLowerCase()) === 0);
  }

  checkValid(item) {
    if (item != undefined) {
      this.isSave = false
    }
  }

  getHos(name) {
    this.DoctorService.getAllHospitals(name).subscribe(e => {
      this.MyAssociation = e['data']
      this.selectMyAssociation = e['data']
    })
  }

  search(search) {
    this.MyAssociation = this.filter(search);
  }

  filter(searchString: string): any[] {
    this.NewlyRegDoctors = this.MyAssociation.filter(e => { e.hospitalName.toLowerCase.includes(searchString.toLowerCase) })
    return this.NewlyRegDoctors;
  }

  //get f(){return this.docCertiGroup.controls}
  get cf() { return this.addForm.controls }

  saveCerification() {
    this.addForm.controls['degree'].patchValue(this.selectedDegree);
    this.submitted = true;
    if (!this.addForm.valid) {
      return
    }

    var age = parseInt(this.globalStorage.getItem('Age'))
    var toDay = new Date()
    var year = toDay.getFullYear()
    var yeartovalidate = (year - age) + 21
    if (new Date(this.addForm.value.yearGraduated).getFullYear() < yeartovalidate) {
      this.ToastrService.warning("Enter valid year graduated")
      return
    }

    this.isCertificationExists = true;
    this.isYearExists = true;
    this.addForm.value.doctorId = this.loginUser.doctorId
    this.addForm.value.active = 'Y'
    const d = new Date(this.addForm.value.yearGraduated);
    const date = Date.UTC(d.getFullYear(), d.getMonth(), d.getDate());
    var compareObj = {
      "doctorId": this.globalStorage.getItem('doctorId'),
      "instituteName": this.addForm.value.instituteName,
      "degree": this.addForm.value.degree,
      "yearGraduated": date,
    }

    this.checkInstNameExisting(compareObj);
    this.checkDegreeExisting(compareObj);
    // this.chekExistingYear(this.addForm.value.yearGraduated);

    var fomattedDate = formatDate(this.addForm.value.yearGraduated, "yyy-MM-dd", "en-us");
    this.addForm.controls['yearGraduated'].patchValue(fomattedDate);
    this.addForm.controls['doctorId'].patchValue(this.globalStorage.getItem('doctorId'))

    if (this.isYearExists && this.isCertificationExists) {
      this.DoctorService.saveCertificationDetails(this.addForm.value).subscribe(res => {
        this.submitted = false;
        this.getCertificationDetails();
        this.isCertificationExists = true;
        this.ToastrService.success("Certification details saved.");
        this.addForm.reset();

        //this.router.navigate(['/associations']);
        // let currentUrl = this.router.url;
        // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        // this.router.onSameUrlNavigation = 'reload';
        // this.router.navigate(['/associations']);
      }, error => {
        this.ToastrService.error(error['error']['message']);
      })
    }
    $("body").removeClass("modal-open")
    $('#add-certifications').hide()
  }

  isCertificationExists: boolean = true;
  checkInstNameExisting(institute){
    var inst = {
      "doctorId": institute.doctorId,
      "instituteName": institute.instituteName,
      "degree": institute.degree,
      "yearGraduated": institute.yearGraduated.toString()
    }
    var existInstitute;
    this.certificationList.forEach(obj => {
      if(obj.instituteName === inst.instituteName){
        existInstitute = true;
        return;
      }
    })
    if(existInstitute){
      this.ToastrService.warning("Institute details already exist.")
      this.isCertificationExists = false;
    }
  }

  checkDegreeExisting(certification) {
    var Obj1 = {
      "doctorId": certification.doctorId,
      "instituteName": certification.instituteName,
      "degree": certification.degree,
      "yearGraduated": certification.yearGraduated.toString()
    }
    var existsCheck;
    this.certificationList.forEach(obj => {
      if (obj.degree === Obj1.degree) {
        existsCheck = true;
        return;
      }
    })
    if (existsCheck) {
      this.ToastrService.warning("Certification details already exist.")
      this.isCertificationExists = false;
    }
  }

  isYearExists;
  chekExistingYear(year) {
    var Obj1 = { "yearGraduated": year.toString() }
    var saveObj = JSON.stringify(Obj1);

    for (var i = 0; i < this.certificationList.length; i++) {
      var Obj = { "yearGraduated": this.certificationList[i].yearGraduated.toString() }
      var arrayObj = JSON.stringify(Obj);
      var saveObjUpperCase = saveObj.toUpperCase();
      var arrayObjUpperCase = arrayObj.toUpperCase();
      if (saveObjUpperCase == arrayObjUpperCase) {
        this.ToastrService.warning("Year of  Graduated is exists")
        this.isYearExists = false;
        break
      }
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.MyAssociation.filter(option => option.toLowerCase().includes(filterValue));
  }

  getOptionText(option) {
    // this.frmAssociation.patchValue({
    //   'hospitalName': option.hospitalName, 'hospitalId': option.hospitalAddressId
    // })
    // this.frmAssociation.controls['hospitalName'].patchValue(option.hospitalName);
    // this.frmAssociation.controls['hospitalId'].patchValue(option.hospitalAddressId);
    this.entityName = option.hospitalName
    this.entityId = option.hospitalAddressId
    return option.hospitalName;
  }

  chooseFirstOption(): void {
    this.matAutocomplete.options.first.select();
  }

  @ViewChild(MatAutocomplete) matAutocomplete: MatAutocomplete;

  get fa() { return this.frmAssociation.controls }

  closeSaveAssociationDetails() {
    this.submit2 = false;
    this.frmAssociation.controls['hospitalId'].patchValue("");
  }


  isAssociationExist: boolean = true; entiyName; entitytype
  saveAssociationDetails() {
    this.isAssociationExist = true;
    this.submit2 = true;
    if (!this.frmAssociation.valid) {
      return
    }
    // if (this.frmAssociation.value.hospitalId.entityId == undefined) {
    //   this.ToastrService.warning("Select valid date.")
    //   return
    // }
    // if (this.frmAssociation.value.hospitalId.entityId == 1) {
    //   this.entitytype = "Apollo"
    //   this.entiyName = "Apollo"
    // }
    // if (this.frmAssociation.value.hospitalId.entityId == 2) {
    //   this.entitytype = "Med Plus"
    //   this.entiyName = "Med Plus"
    // }
    // if (this.frmAssociation.value.hospitalId.entityId == 3) {
    //   this.entitytype = "Vijay Clinic"
    //   this.entiyName = "Vijay Clinic"
    // }
    // if (this.frmAssociation.value.hospitalId.entityId == 4) {
    //   this.entitytype = "Clinic 4"
    //   this.entiyName = "Clinic 4"
    // }
    // if (this.frmAssociation.value.hospitalId.entityId == 5) {
    //   this.entitytype = "Clinic 5"
    //   this.entiyName = "Clinic 5"
    // }
    // if (this.frmAssociation.value.hospitalId.entityId == 6) {
    //   this.entitytype = "Clinic 6"
    //   this.entiyName = "Clinic 6"
    // }
    var association = {
      "active": 'Y',
      "associationId": null,
      "doctorId": parseInt(this.globalStorage.getItem('doctorId')),
      "entityId": this.frmAssociation.value.hospitalId.hospitalAddressId,
      "entitytype": "HOSPITAL",
      "entiyName": this.frmAssociation.value.hospitalId.hospitalName
    }

    this.checkAssociationExisting({
      "doctorId": parseInt(this.globalStorage.getItem('doctorId')),
      "entityId": this.frmAssociation.value.hospitalId.entityId
    })

    if (this.isAssociationExist) {
      this.DoctorService.saveAssociation(association).subscribe(res => {
        this.submit2 = false;
        this.frmAssociation.reset();
        this.frmAssociation.controls['hospitalId'].setValue('')
        this.getAssociation();
        this.DoctorService.updateMyassocians();
        this.ToastrService.success("Association details saved.");
      }, error => {
        this.ToastrService.error("Error in saving association details. Try again.");
      })
    }
    $("body").removeClass("modal-open")
    $('#add-association').hide()

  }

  checkAssociationExisting(obj) {
    for (var i = 0; i < this.associationList.length; i++) {
      if (this.associationList[i].entityId == obj.entityId) {
        this.ToastrService.warning("Association details already exist.")
        this.isAssociationExist = false;
        break
      }
    }
    // obj.hospitalId=obj.entityId.toString();
    //  var saveObj=JSON.stringify(obj)

    //  for(var i=0;i<this.associationList.length;i++){

    //   var Obj={"doctorId":this.associationList[i].doctorId,
    //            "associationId": this.associationList[i].associationId.toString()}
    //     var arrayObj=JSON.stringify(Obj)
    //     var saveObjUpperCase=saveObj.toUpperCase()
    //     var arrayObjUpperCase=arrayObj.toUpperCase()


    //     if(saveObjUpperCase==arrayObjUpperCase){
    //       this.ToastrService.warning("Association details already exist.")
    //      this.isAssociationExist=false;
    //       break
    //      }
    //  }
  }




  associationList: any[] = []
  associationList1: any[] = []
  compareAssociationString: string[] = []
  getAssociation() {
    this.DoctorService.getAssociationDetails(this.loginUser.doctorId).subscribe(res => {
      this.associationList = res['data']?.filter(obj => obj?.active == 'Y')
      // function removeDuplicates(array, key) {
      //   let lookup = new Set();
      //   return array.filter(obj => !lookup.has(obj[key]) && lookup.add(obj[key]));
      // }
      // this.associationList = removeDuplicates(this.associationList, 'associationId');
      // this.associationList = this.associationList.filter(e => e.active = "Y")
      // this.associationList1 = res['data']

      // this.compareAssociationString.length = 0
      // for (var i = 0; i < this.associationList.length; i++) {
      //   var stringObj = JSON.stringify(this.associationList[i])
      //   this.compareAssociationString.push(stringObj)
      // }

    }, error => {
      this.ToastrService.error(error.message)
    });
  }

  certificationList: any[] = []
  compareCertificationList = []
  stringCertificationList: string[] = []

  getCertificationDetails() {
    var age = parseInt(this.globalStorage.getItem('Age'))
    var toDay = new Date()
    var year = toDay.getFullYear()
    //var yeartovalidate = (year - age) + 25
    var yeartovalidate = (age) + 25
    this.minDate = new Date(yeartovalidate, 0, 1)
    this.DoctorService.getCertificationDetails(this.loginUser.doctorId).subscribe(res => {
      this.certificationList = res['data'];

      var certiArray = this.certificationList
      $(".addSelect option").each(function () {
        for (var i = 0; i < certiArray.length; i++) {
          if (certiArray[i].degree == this.value) {
            $(this).prop('disabled', 'disabled');
          }
        }
        // alert(this.text + ' ' + this.value);
      });

    }, error => {
      this.ToastrService.error("Error retrieving certification details. Try again")
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // addCertification():FormGroup{
  //   return   this.fb.group({
  //     certificationId:[],
  //     doctorId:[this.loginUser.doctorId],
  //     instituteName: [,Validators.required],
  //     degree: [,Validators.required],
  //     yearGraduated: [,[Validators.required,Validators.max(this.maxDate)]]
  //   });
  // }


  cancelAdd() {
    this.IsAdd = false;
    this.addForm.reset();
  }

  IsAdd: boolean = false;
  addNewCertification() {
    this.IsAdd = true
    //this.certiArray.push(this.addCertification());
  }

  deleteCertification(index, row) {

    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        status: null
      }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result['data'] == 'YES') {

        if (row.certificationId != null) {
          this.DoctorService.deleteDoctorEducation(row.certificationId).subscribe(res => {
            this.getCertificationDetails();
            this.ToastrService.success("Certification details removed.");
          },
            error => {
              this.ToastrService.error("Error in deleting certification details. Try again.");
            })
        }
      }
    });

  }


  indexArray: any[] = []
  editButton(index) {
    this.isEdit = true;
    var certiArray = this.certificationList
    $(".Myselect option").each(function () {
      for (var i = 0; i < certiArray.length; i++) {
        if (certiArray[i].degree == this.value) {
          $(this).prop('disabled', 'disabled');
        }

      }
    });

    $(".addSelect option").each(function () {
      for (var i = 0; i < certiArray.length; i++) {
        if (certiArray[i].degree == this.value) {
          $(this).prop('disabled', 'disabled');
        }

      }
    });

    $("#btnEdit_" + index).hide();
    $("#closeEdit_" + index).show();
    $(".tr_" + index).each(function () {
      $(this).show();
    });
    $(".lblView_" + index).each(function () {
      $(this).hide();
    });
    $(".selectView_" + index).each(function () {
      $(this).hide();
    });
  }

  closeEdit(index) {

    $("#closeEdit_" + index).hide();
    $("#btnEdit_" + index).show();

    $(".tr_" + index).each(function () {
      $(this).hide();
    });

    $(".lblView_" + index).each(function () {
      $(this).show();
    });
    $(".selectView_" + index).each(function () {
      $(this).show();
    });

    this.getCertificationDetails();
  }

  certificationSubmitted: boolean = false
  updateCertification(certification, index) {

    this.isUpdateCertificationExists = true;
    if (certification.instituteName == "" || certification.degree == "" || certification.yearGraduated == "") {
      this.ToastrService.error("Enter mandatory fields.")
      return
    }
    if (new Date(certification.yearGraduated).getFullYear() > this.maxDate) {
      this.ToastrService.error("Enter valid Graduated year.")
      return
    }

    var age = parseInt(this.globalStorage.getItem('Age'))
    var toDay = new Date()
    var year = toDay.getFullYear()
    var yeartovalidate = (year - age) + 21
    if (new Date(certification.yearGraduated).getFullYear() < yeartovalidate) {
      this.ToastrService.warning("Enter valid Graduated year.")
      return
    }

    var yearGraduatedStr = certification.yearGraduated.toString();

    this.certificationSubmitted = true;
    if (certification.invalid) {
      this.ToastrService.warning("Enter certification details.")
      return
    }



    this.newCheckCertificationUpdate(certification)


    if (this.isUpdateCertificationExists) {
      certification.active = 'Y'
      this.DoctorService.saveCertificationDetails(certification).subscribe(res => {
        $("#closeEdit_" + index).hide();
        $("#btnEdit_" + index).show();
        $(".tr_" + index).each(function () {
          $(this).hide();
        });
        $(".lblView_" + index).each(function () {
          $(this).show();
        });
        $(".selectView_" + index).each(function () {
          $(this).show();
        });

        this.ToastrService.success("Certification details saved.");
        $("body").removeClass("modal-open")
      }, error => {
        this.ToastrService.error("Error in saving certification details. Try again.");
        this.addForm.reset();
      })
    }

  }


  isUpdateCertificationExists: boolean = true
  CheckUpadteDublicate(certification) {

    var Myarray = []
    Myarray.length = 0
    for (var i = 0; i < this.stringCertificationList.length; i++) {
      Myarray.push(JSON.parse(this.stringCertificationList[i]))
    }
    var Obj1 = {
      "doctorId": certification.doctorId,
      "instituteName": certification.instituteName,
      "degree": certification.degree,
      "yearGraduated": certification.yearGraduated.toString()
    }

    var count = 0

    var saveObj = JSON.stringify(Obj1)
    for (var i = 0; i < Myarray.length; i++) {

      var Obj = {
        "doctorId": Myarray[i].doctorId,
        "instituteName": Myarray[i].instituteName,
        "degree": Myarray[i].degree,
        "yearGraduated": Myarray[i].yearGraduated.toString()
      }

      var arrayObj = JSON.stringify(Obj)
      var saveObjUpperCase = saveObj.toUpperCase()
      var arrayObjUpperCase = arrayObj.toUpperCase()

      if (saveObjUpperCase == arrayObjUpperCase) {

        count = count + 1
        if (count > 1) {
          this.ToastrService.warning("Certification details already exist.")
          this.isUpdateCertificationExists = false;
          break
        }
      }
    }

  }

  newCheckCertificationUpdate(certification) {

    var count = 0
    var Obj1 = {
      "doctorId": certification.doctorId,
      "instituteName": certification.instituteName,
      "degree": certification.degree,
      "yearGraduated": certification.yearGraduated.toString()
    }
    var saveObj = JSON.stringify(Obj1)

    for (var i = 0; i < this.certificationList.length; i++) {

      var Obj = {
        "doctorId": this.certificationList[i].doctorId,
        "instituteName": this.certificationList[i].instituteName,
        "degree": this.certificationList[i].degree,
        "yearGraduated": this.certificationList[i].yearGraduated.toString()
      }

      var arrayObj = JSON.stringify(Obj)

      var saveObjUpperCase = saveObj.toUpperCase()
      var arrayObjUpperCase = arrayObj.toUpperCase()

      if (saveObjUpperCase == arrayObjUpperCase) {

        count = count + 1
        if (count > 1) {
          this.ToastrService.warning("Certification details already exist.")
          this.isUpdateCertificationExists = false;
          break
        }
      }
    }
  }

  btnEdit(index) {
    this.edit = false;
    $('.tr_view_' + index).hide();
    $('.tr_edit_' + index).show();
  }


  isUpdateAssociationExist: boolean = true;
  updateAssociation(item, index) {
    this.isUpdateAssociationExist = true

    var association = {
      "active": 'Y',
      "associationId": item.associationId,
      'association': item.association,
      "doctorId": item.doctorId,
      "entityId": item.entityId,
      "entitytype": this.entitytype,
      "entiyName": this.entiyName
    }

    this.UpdateAssociationExist({
      "doctorId": item.doctorId,
      "entityId": item.entityId
    })


    if (this.isUpdateAssociationExist) {

      this.DoctorService.saveAssociation(association).subscribe(res => {
        $('.tr_view_' + index).show();
        $('.tr_edit_' + index).hide();
        // this.getAssociation();
        this.DoctorService.updateMyassocians();
        this.ToastrService.success("Association Details updated.")
        $("body").removeClass("modal-open")
      }, error => {
        this.ToastrService.error("Error in saving association details. Try again.")
      })
    }
  }

  UpdateAssociationExist(obj) {
    var count = 0
    obj.entityId = obj.entityId.toString();
    var saveObj = JSON.stringify(obj)

    for (var i = 0; i < this.associationList?.length; i++) {

      var Obj = {
        "doctorId": this.associationList[i].doctorId,
        "entityId": this.associationList[i].entityId.toString()
      }
      var arrayObj = JSON.stringify(Obj)
      var saveObjUpperCase = saveObj.toUpperCase()
      var arrayObjUpperCase = arrayObj.toUpperCase()


      if (saveObjUpperCase == arrayObjUpperCase) {
        count = count + 1
        if (count > 1) {
          this.ToastrService.warning("Association details already exist.")
          this.isUpdateAssociationExist = false;
          break
        }
      }
    }



    // var saveObj=JSON.stringify(obj)
    // var Myarray=[]
    // Myarray.length=0;
    // for(var i=0;i<this.compareAssociationString.length;i++){
    //   Myarray.push(JSON.parse(this.compareAssociationString[i]))
    // }

    // for(var i=0;i<Myarray.length;i++){
    //   var Obj={"doctorId": Myarray[i].doctorId,
    //   "hospitalId":JSON.stringify(Myarray[i].hospitalId)}

    //     var arrayObj=JSON.stringify(Obj)
    //     var saveObjUpperCase=saveObj.toUpperCase()
    //     var arrayObjUpperCase=arrayObj.toUpperCase()
    //     if(saveObjUpperCase==arrayObjUpperCase){
    //      this.ToastrService.warning("Certification details already exists")
    //          this.isUpdateAssociationExist=false;
    //       break
    //      }
    //     }

  }

  cancelAssociation(index) {
    $('.tr_view_' + index).show();
    $('.tr_edit_' + index).hide();
    this.edit = true;
    this.getAssociation()
  }
  baseSchedule(data, i) {
    const navigationExtras: NavigationExtras = {
      state: {
        associationData: data
      }
    };
    this.router.navigate(['/associationBasicScheduler'], navigationExtras)
  }
  deleteAssociation(row) {

    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        status: null
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result['data'] == 'YES') {
        if (row.associationId != null) {
          this.DoctorService.deleteDoctorAssociation(row.associationId).subscribe(res => {
            this.getAssociation();
            this.DoctorService.updateMyassocians();
            this.ToastrService.success("Association Details removed.")
          },
            error => {
              this.ToastrService.error("Error in deleting association details.")
            })
        }
      }
    });

  }
  isSave
  checkFormIsEmptyOrNot() {
    if (this.addForm == undefined) {
      this.isSave = true
    }
  }

  certificateChoosed(certificate) {
    this.selectedCertificate = certificate;
  }

  closeCModal() {
    this.addForm.reset();
    this.submitted = false;
    // $('#add-certifications').modal('hide');
    // $("#add-certifications").val(null).trigger("change");
  }
}
