<div class="l-sidebar">
  <div class="logo">
    <div class="logo__txt"><img alt="logo-img" class="img-center" id="logo-img" src="./assets/img/logo-white.png"></div>
  </div>
  <div class="l-sidebar__content">
    <nav class="c-menu js-menu">
      <ul class="u-list">
        <li class="c-menu__item is-active " data-toggle="tooltip" data-placement="right" title="Home">
          <a href="javascript:void(0)" routerLink='/pharmaDashboard'>
            <div class="c-menu__item__inner bg-warning-light">
              <i class="fa fa-home"></i>
              <a class="c-menu-item__title"><span>Home</span></a>
            </div>
          </a>
        </li>

        <li class="c-menu__item has-submenu " data-toggle="tooltip" data-placement="right" title="Prescription" *ngIf="prescriptionPermissions">
          <a href="javascript:void(0)" routerLink='/pharmaPrescriptions'>
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-file-prescription"></i>
              <div class="c-menu-item__title"><span>Prescription</span></div>
            </div>
          </a>
        </li>

        <li class="c-menu__item has-submenu " data-toggle="tooltip" data-placement="right" title="Inventory" *ngIf="inventoryPermissions">
          <a href="javascript:void(0)" routerLink='/pharmaInvoices'>
            <div class="c-menu__item__inner bg-primary-light">
              <i class="fa fa-store"></i>
              <div class="c-menu-item__title"><span>Inventory</span></div>
            </div>
          </a>
        </li>

        <li class="c-menu__item has-submenu" data-toggle="tooltip" data-placement="right" title="Patients" *ngIf="usersPermissions">
          <a href="javascript:void(0)" routerLink='/pharma_users'>
            <div class="c-menu__item__inner bg-primary-light">
              <i class="fa fa-users"></i>
              <div class="c-menu-item__title"><span>Patients</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu" data-toggle="tooltip" data-placement="right" title="Staff" *ngIf="StaffPermission">
          <a href="javascript:void(0)" routerLink='/pharma-associates'>
            <div class="c-menu__item__inner bg-warning-light">
              <i class="fas fa-user-friends"></i>
              <div class="c-menu-item__title"><span>Staff</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu" data-toggle="tooltip" data-placement="right" title="Profile" *ngIf="profilePermissions">
          <a href="javascript:void(0)" routerLink='/pharmaProfile'>
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-user-cog"></i>
              <div class="c-menu-item__title"><span>Profile</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu" data-toggle="tooltip" data-placement="right" title="Inventory" *ngIf="inventoryPermissions">
          <a href="javascript:void(0)" routerLink='/pharmacy-inventory'>
            <div class="c-menu__item__inner bg-danger-light">
              <i class="fa fa-store"></i>
              <div class="c-menu-item__title"><span>Inventory</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu" data-toggle="tooltip" data-placement="right" title="Admin" *ngIf="inventoryPermissions">
          <a href="javascript:void(0)" routerLink='/pharmacy-inventory-admin'>
            <div class="c-menu__item__inner bg-danger-light">
              <i class="fa fa-store"></i>
              <div class="c-menu-item__title"><span>Inventory Admin</span></div>
            </div>
          </a>
        </li>
        <li class="c-menu__item has-submenu" data-toggle="tooltip" data-placement="right" title="Assign Roles" *ngIf="presentRole=='ADMIN'">
          <a href="javascript:void(0)" routerLink='/pharmaRoles'>
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-user-cog"></i>
              <div class="c-menu-item__title"><span>Assign Roles</span></div>
            </div>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</div>

<header class="l-header">
  <div class="l-header__inner clearfix">
    <!-- <div class="c-header-icon js-hamburger no-mobile">
      <div class="hamburger-toggle"><span class="bar-top"></span><span class="bar-mid"></span><span
          class="bar-bot"></span></div>
    </div> -->
    <div class="c-search p-0 no-desktop">
      <img src="./assets/img/logo-mobile.png" />
    </div>
    <div class="c-search no-mobile">
      <div style=" float: left; ">Logged in as <b class="ml-1 mr-1 captilize">{{firstName | titlecase}} {{lastName}}</b>
      </div>
      <span class="float-right pt-0 d-none d-lg-block mr-2" *ngIf="!firstTimeLogin">
        Last login {{lastLogin | date:'dd-MMM-yyyy hh:mm a'}}
        <!-- <div *ngIf="item != undefined">You are logged in as <b>{{item.firstName}}</b> </div> -->

      </span>

      <span class="float-left d-none d-lg-block mr-2" *ngIf="firstTimeLogin">
        Welcome to CureMetric. This is your first time login.
      </span>

    </div>
    <!-- <button mat-raised-button color="primary" feedback (send)="onSend($event)">Help</button> -->
    <div class="header-icons-group">
      <div class="c-header-icon has-dropdown bg-danger-light" data-toggle="tooltip" data-placement="left" title="Help">
        <i class="fas fa-question-circle" feedback (send)="onSend($event)"></i>
      </div>
      <div class="c-header-icon has-dropdown bg-primary-light" *ngIf="notificationBell" data-placement="left"
        data-toggle="tooltip" title="" data-original-title="Notifications">
        <span class="c-badge c-badge--header-icon "
          *ngIf="notificationLength != 'null' || notificationLength != 'undefined'">{{notificationLength}}</span>
        <span class="c-badge c-badge--header-icon"
          *ngIf="notificationLength == 'null' || notificationLength == 'undefined'">0</span><i class="fa fa-bell"
          data-toggle="modal" data-target="#viewreminders"></i>
      </div>
      <div class="c-header-icon has-dropdown bg-success-light " data-placement="left" data-toggle="tooltip" title=""
        data-original-title="Profile" *ngIf="profilePermissions">
        <i class="fa fa-user" routerLink="/pharmaProfile"></i>
      </div>
      <div class="c-header-icon logout bg-warning-light" data-placement="left" data-toggle="tooltip" title=""
        data-original-title="Logout"><i class="fa fa-sign-out-alt" (click)="signout()"></i></div>
    </div>
  </div>
</header>



<div class="on-mobile">
  <footer class="footer-mobile ">
    <div class="container-fulid">
      <ul class="nav nav-pills nav-justified">
        <li class="nav-item"  *ngIf="prescriptionPermissions">
          <a class="nav-link" href="javascript:void(0)" routerLink="/pharmaPrescriptions" data-toggle="tooltip" title=""
            data-original-title="Prescription">
            <span>
              <i class="nav-icon fa fa-file-prescription m-col1"></i>
              <span class="nav-text">Prescription</span>
            </span>
          </a>
        </li>
        <li class="nav-item" *ngIf="inventoryPermissions">
          <a class="nav-link" href="javascript:void(0)" routerLink="/pharmaInvoices" data-toggle="tooltip" title=""
            data-original-title="Inventory">
            <span>
              <i class="nav-icon fa fa-store m-col2"></i>
              <span class="nav-text">Inventory</span>
            </span>
          </a>
        </li>
        <li class="nav-item center-item">
          <a class="nav-link" href="javascript:void(0)" routerLink="/pharmaDashboard" data-toggle="tooltip" title=""
            data-original-title="Home">
            <span>
              <i class="nav-icon fa fa-home"></i>
              <span class="nav-text">Home</span>

            </span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)" data-toggle="tooltip" title="" data-original-title="Reports">
            <span>
              <i class="nav-icon  fa fa-chart-bar m-col3"></i>
              <span class="nav-text">Reports</span>
            </span>
          </a>
        </li>
        <li class="nav-item" *ngIf="profilePermissions">
          <a class="nav-link" href="javascript:void(0)" routerLink="/pharmaProfile" data-toggle="tooltip" title=""
            data-original-title="Profile">
            <span>
              <i class="nav-icon fa fa-user-cog m-col4"></i>
              <span class="nav-text">Profile</span>
            </span>
          </a>
        </li>
        <li class="c-menu__item has-submenu" data-toggle="tooltip" data-placement="right" title="Assign Roles" *ngIf="presentRole=='ADMIN'">
          <a href="javascript:void(0)" routerLink='/pharmaRoles'>
            <div class="c-menu__item__inner bg-success-light">
              <i class="fa fa-user-cog"></i>
              <div class="c-menu-item__title"><span>Assign Roles</span></div>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </footer>
</div>

<div class="modal fade modal-right" id="viewreminders" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notifications</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div *ngIf="notifications?.length==0">
        <div class="no-notifications"></div>
      </div>
      <div class="row">
        <div class="col-lg-12 mt-3 text-right">
          <ul class="pagination">
            <pagination-controls id="p1" (pageChange)="p1=$event"></pagination-controls>
          </ul>
        </div>
      </div>
      <div class="row d-flex ml-2">
        <div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left  blt-2 mb-2 mt-1"
          *ngFor="let reports of notifications | paginate: { id: 'p1', itemsPerPage: 7, currentPage: p1 }"
          [ngClass]="{' blt-3': reports.viewed == 'Y'}">

          <a href="javascript:void(0)" class="p-2 icon-appointments  ">
            <i *ngIf="reports.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3" aria-hidden="true"></i>
            <i *ngIf="reports.viewed != 'Y'" class="fa fa-bell fa-lg pt-2  icon-col-3" aria-hidden="true"></i>
          </a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
            <a href="javascript:void(0)" *ngIf="reports.objecttype=='Payment Done by Patient'"
              (click)="navigate('/payments')">
              <p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
              </p>
              <p class="text-muted mb-1 text-small">{{reports.reason}}</p>
            </a>
            <a href="javascript:void(0)" *ngIf="reports.objecttype!='Payment Done by Patient'"
              (click)="navigate('/pharmaPrescriptions')">
              <p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
              </p>
              <p class="text-muted mb-1 text-small">{{reports.reason}}</p>
            </a>
          </div>

          <div class="btn-group float-right">
            <button class="btn btn-xs btn0col1" data-toggle="modal" data-backdrop="static" data-target="#viewpdf"><i
                class="fa fa-eye" aria-hidden="true" (click)="updateNotify(reports)"></i></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade center" id="notificationViewer" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document"
    style="min-width:300px !important;height: 50% !important;margin-left: 39%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notification Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="block inv-section">
          <div class="">
            <div>
              <img alt="" src="./assets/img/logo.png" style="border-radius: none !important;">
            </div>
          </div>
          <div class="row text-center">
            <div>
              <p class="font-weight-medium mb-0 mt-1">{{notificationtoshow?.objecttype |titlecase}}
              </p>
              <p class="text-muted mb-1 text-small">{{notificationtoshow?.reason}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
