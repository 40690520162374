<!-- <mat-sidenav-content>  -->
<main class="l-main">
  <div class="content-wrapper content-wrapper--with-bg">
    <div class="container-fluid rounded-sm ">
      <div class="row ">
        <div class="col-lg-8 col-md-8 col-sm-12 mb-1" *ngIf=isHome>
          <doc-nav-bar></doc-nav-bar>
          <router-outlet></router-outlet>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 mb-1" *ngIf=!isHome>
          <doc-nav-bar></doc-nav-bar>
          <router-outlet></router-outlet>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12" *ngIf=isHome>

          <doc-sidebar></doc-sidebar>

        </div>
      </div>
    </div>
    <mat-sidenav-container>

      <mat-sidenav mode="side" position="center" #patientDetails cloused
        style="z-index:99;position:fixed;margin-top:0px;border:0;padding: 0px 0px 0px 0px;background-color:white;box-shadow:5px 10px 18px #3a3a3a;width: 53%;">
        <h3 class="sidenav-heading">
          Patient Details

        </h3>
        <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">×</a>
        <app-patient-details *ngIf="isPatientNave"
          [patientPersonalDetails]="patientPersonalDetails"></app-patient-details>

      </mat-sidenav>





      <mat-sidenav mode="side" class="mat-drawer-start" id="meicalReports" position="start" #pdfViewer cloused
        style="z-index:99;position:fixed;margin-top:0px;border:0;padding: 0px 0px 0px 0px;background-color:white;box-shadow:5px 10px 18px #3a3a3a;width:39%;">
        <h3 class="sidenav-heading">
          Medical Reports

        </h3>
        <a href="javascript:void(0)" class="closebtn" (click)="pdfViewer.close()">×</a>

        <div style="position: relative; height:715px;">
          <img *ngIf="showImage==true" [src]="newsrc" style="height: 264px" alt="" />
          <!-- <pdf-viewer [showPrintButton]="true"  [fit-to-page]="true" [original-size]="false" [show-all]="true" [autoresize]="true" [src]="src"  [showDownloadButton]="true" [render-text]="true"  [showZoomButtons]="true" style="position: relative;display: block;overflow-y: hidden;" alt="downloads"></pdf-viewer> -->
          <iframe *ngIf="showPdf==true" [src]="pdfSrc" class="pdf-viewer" frameborder="0" scrolling="no"
            style="width:100%;height: 600px;"></iframe>
        </div>
      </mat-sidenav>

      <mat-sidenav mode="side" position="start" #videoCalling cloused
        style="z-index:99;position:fixed;margin-top:0px;border:0;padding: 0px 0px 0px 0px;background-color:white;box-shadow:5px 10px 18px #3a3a3a;width: 40%;">
        <h3 class="sidenav-heading">
          Video Calling

        </h3>

        <a href="javascript:void(0)" class="closebtn mr-2" (click)="closeVideoCalling()">×</a>
        <a href="javascript:void(0)" class="closebtn mr-4 pr-4" (click)="openNotes()"><i
            class="fa fa-sticky-note-o"></i> Notes</a>







        <div class="grid-container" style="position: absolute;width:100%">
          <div class="w-100">

            <app-doctor-cameras #camera [style.display]="!!activeRoom ? 'none' : 'block'"></app-doctor-cameras>


            <app-doctor-participants #participants (leaveRoom)="onLeaveRoom($event)"
              (participantsChanged)="onParticipantsChanged($event)" [style.display]="!!activeRoom ? 'block' : 'none'"
              [activeRoomName]="!!activeRoom ? activeRoom.name : null"
              [activeRoom]="activeRoom"></app-doctor-participants>




            <div>

            </div>
          </div>

          <div class="float-left">
            <app-doctor-rooms #rooms (roomChanged)="onRoomChanged($event)" *ngIf="!joinedRoom"
              [activeRoomName]="!!activeRoom ? activeRoom.name : null"></app-doctor-rooms>

            <div style="position: absolute; top:0; left:0; ">
              <app-settings #settings (settingsChanged)="onSettingsChanged($event)"></app-settings>

            </div>

          </div>

        </div>
        <!-- <h3>Patient</h3> -->
        <!-- <app-camera #camera></app-camera> -->
      </mat-sidenav>

      <mat-sidenav mode="side" position="right" #viewAppointment cloused
        style="z-index:99;position:absolute;margin-top:0px;padding: 0px 0px 0px 0px;background-color:#F4F4F4;box-shadow:5px 10px 18px #3a3a3a;width:45%;height: auto;">
        <div class="row bg_top01">
          <div class="col-md-8 f-w-600">View Appointment</div>
          <div class="col-md-4">
            <a href="javascript:void(0)" class="closebtn" (click)="viewAppointment.close()">×</a>
          </div>
        </div>
        <app-view-appoinment [appointmentDetails]="appointmentDetails"></app-view-appoinment>
      </mat-sidenav>



      <mat-sidenav mode="side" position="left" #prescriptionList cloused
        style="z-index:9999;position:absolute;margin-top:0px;padding: 0px 0px 0px 0px;background-color:#F4F4F4;box-shadow:5px 10px 18px #3a3a3a;width: 500px;height: auto;">

        <h3 class="sidenav-heading">Patient Prescriptions</h3>

        <a href="javascript:void(0)" class="closebtn" (click)="prescriptionList.close()">×</a>


        <app-prescription-list *ngIf="isPrecription"
          [patientPrescription]="patientPrescription"></app-prescription-list>

      </mat-sidenav>
      <mat-sidenav mode="side" position="right" #medicalReportsList cloused
        style="z-index:9999;position:absolute;margin-top:0px;padding: 0px 0px 0px 0px;background-color:#F4F4F4;box-shadow:5px 10px 18px #3a3a3a;width: 500px;height: auto;">

        <h3 class="sidenav-heading">Patient MedicalReports</h3>

        <a href="javascript:void(0)" class="closebtn" (click)="medicalReportsList.close()">×</a>

        <app-medical-reports-list *ngIf="medicalReportsList" [patient]="patient"></app-medical-reports-list>

      </mat-sidenav>
    </mat-sidenav-container>
  </div>


</main>
<app-footer></app-footer>
<!-- </mat-sidenav-content> -->
<div class="modal fade modal-right" id="openNotes" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="openNotes"
  aria-hidden="true" style=" overflow-y:auto !important; background: none !important;z-index: 999999;">
  <div class="modal-dialog" role="document" style="min-width:53%; ">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notes</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-3">
        <form [formGroup]="notesForm">
          <div class="row">
            <div class="col-md-12 form-group">
              <label>Start Time</label>
              <input type="text" formControlName="startTime" class="form-control mb-0" [ngClass]="{
                          'is-invalid':
                            (submitted || n.startTime.touched) && n.startTime.errors
                        }" readonly />

              <div *ngIf="(submitted || n.startTime.touched) && n.startTime.errors" class="invalid-feedback">
                <div *ngIf="n.startTime.errors.required">
                  StartTime is required
                </div>
              </div>
            </div>

            <div class="col-md-12 form-group">
              <label>Description</label>
              <textarea autoResizeRows class="form-control " rows="8" cols="50" name="message" formControlName="description"
                placeholder="Enter Description"></textarea>

              <div *ngIf="(submitted || n.description.touched) && n.description.errors" class="invalid-feedback">
                <div *ngIf="n.description.errors.required">
                  Description is required
                </div>
              </div>
            </div>
            <div class="col-md-12 form-group">
              <button type="submit" class="btn btn-success float-right" (click)="saveNotes(notesForm)">Save</button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>
