import { Injectable } from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
  providedIn: 'root'
})
export class PdfMakerService {

  constructor() { }

  printPdf(printData) {
      pdfMake.createPdf(printData).print();
  }

  downloadPdf(printData) {
    pdfMake.createPdf(printData).download();
  }
}
