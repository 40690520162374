<div class="row mb-2">
  <div class="col-3 col-md-4 col-xl-3">
      <div class="card card-invoice detail-box1 details-box mb-3 p-1">
          <div class="card-body text-center">
              <a href="javascript:void(0)" class="my_sort_cut text-decoration-none">
                  <h4 class="mb-2 mt-2 font-size-20">{{ invoiceList?.remittanceDetailDTO?.orderCount }}</h4>
                  <span class="">Total Orders</span>
              </a>
          </div>
      </div>
  </div>
  <div class="col-3 col-md-4 col-xl-3">
      <div class="card  card-invoice detail-box5 details-box mb-3 p-1">
          <div class="card-body text-center">
              <a href="javascript:void(0)" class="my_sort_cut text-decoration-none">
                  <h4 class="mb-2 mt-2 font-size-20">{{ invoiceList?.remittanceDetailDTO?.pendingAmount }}</h4>
                  <span class="">Pending Amount</span>
              </a>
          </div>
      </div>
  </div>
  <div class="col-3 col-md-4 col-xl-3">
      <div class="card card-invoice detail-box6 details-box mb-3 p-1">
          <div class="card-body text-center">
              <a href="javascript:void(0)" class="my_sort_cut text-decoration-none">
                  <h4 class="mb-2 mt-2 font-size-20">{{ invoiceList?.remittanceDetailDTO?.remittanceAmountdouble }}</h4>
                  <span class="">Amount Remitted</span>
              </a>
          </div>
      </div>
  </div>
  <div class="col-3 col-md-4 col-xl-3">
      <div class="card card-invoice detail-box4 details-boxmb-3 p-1">
          <div class="card-body text-center">
              <a href="javascript:void(0)" class="my_sort_cut text-decoration-none">
                  <h4 class="mb-2 mt-2 font-size-20">{{ invoiceList?.remittanceDetailDTO?.totalAmount }}</h4>
                  <span class="">Total Amount</span>
              </a>
          </div>
      </div>
  </div>
</div>


<div class="row">
  <div class="table-responsive card">
    <table class="table table-hover table-vcenter table-striped mb-0 text-nowrap">
        <thead>
          <tr>
            <th>Invoice ID</th>
            <th>Patient ID</th>
            <th>Patientname</th>
            <th>Doctor Name</th>
            <th>Total</th>
            <th>Type</th>
            <th>Invoice</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let invoice of invoiceDetails | paginate: { id: 'p2', itemsPerPage: 10, currentPage: p2 };let i=index; ">
      <td>{{invoice?.invoiceId}}</td>
      <td>{{invoice?.patientid}}</td>
      <td>{{invoice?.patientname}}</td>
      <td>{{invoice?.doctorname}}</td>
      <td>{{invoice?.amount}}</td>
      <td>{{invoice?.transactiontype}}</td>
      <td>
        <button
        class="btn btn-icon btn-primary btn-sm ivf_border_radius_4"
        (click)="getCompleteInvoiceDetails(invoice)" data-toggle="modal" data-backdrop="static" data-target="#invoice">
        <i class="fas fa-file-invoice " aria-hidden="true"></i></button>
      </td>
          </tr>
        </tbody>
      </table>
      </div>




</div>

<div class="row" *ngIf="invoiceDetails?.length>9">
  <div class="col-lg-12 mt-3 text_right ">

    <ul class="pagination">
      <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
    </ul>
  </div>
</div>

<div class="modal fade modal-right" id="invoice" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Invoice</h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body" id="contentToConvertTwo">
        <div class="block inv-section">
          <div class="top-inv-col">
            <div class="inv-logo">
              <img alt="" src="./assets/img/logo.png">
            </div>
            <div class="float-right text-left order-details">
              <p>Order ID: <span>{{pdfData?.invoiceId}}</span></p>
              <p>Date: <span>{{invoicedata?.paidOn|date:'dd-MMM-yyyy'}}</span></p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-xs-6">
              <h5>Invoice From</h5>
              <ul class="inv-receiver">
                <li class="">Dr.{{pdfData?.doctorname}}
                  <br>{{doctorAddress?.street1}}
                  {{doctorAddress?.street2}}
                  <br>{{doctorAddress?.city}}
                  {{doctorAddress?.state}} <br>
                  {{doctorAddress?.country}}
                  <br>
                  zip : {{doctorAddress?.zip}}
                </li>
              </ul>
            </div>
            <div class="col-md-6 col-xs-6">
              <h5>Invoice To</h5>
              <ul class="inv-receiver">
                <li class="">{{pdfData?.patientname}}
                  <br>{{patientAddress?.street1}}
                  {{patientAddress?.street2}}
                  <br>{{patientAddress?.city}}
                  {{patientAddress?.state}} <br>
                  {{patientAddress?.country}} <br>
                  zip : {{patientAddress?.zip}}
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <div class="payment-method">
                <h5>Payment Method</h5>
                <ul>
                  <li style="display:none"> {{pdfData?.cardnumber}} </li>
                  <li> {{paymentType}} </li>
                </ul>
              </div>
            </div>
          </div>
          <table class="inv-table table table-striped">
            <tbody>
              <tr>
                <th class="text-align-left">Reason </th>
                <th class="text-align-center" style="text-align: center;padding-left: 6%;">Total</th>
              </tr>
              <tr>
                <td class="">
                  {{ailment}}
                </td>
                <td class="text-align-center" style="text-align: center;padding-left: 7%;"><span class="inr"><i
                      class="fa fa-inr" aria-hidden="true"></i>&nbsp;</span>{{pdfData?.amount}}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="1"><b>Sub-total:</b></td>
                <td colspan="1"><span class="inr"><i class="fa fa-inr"
                      aria-hidden="true"></i>&nbsp;</span>{{pdfData?.amount}}</td>
              </tr>

              <tr>
                <td colspan="1"><b>Total Amount:</b></td>
                <td colspan="1"><span class="inr"><i class="fa fa-inr"
                      aria-hidden="true"></i>&nbsp;</span>{{pdfData?.amount}}</td>
              </tr>
            </tfoot>
          </table>

        </div>
        <button  type="button" class="btn btn-warning btn-xs m-1 ml-auto float-right" aria-label="Close" (click)="printPdf(true)">Print Invoice
          <i class="fa fa-print"></i>
        </button>
        <button type="button" class="btn btn-danger btn-xs m-1 mr-4 float-right" aria-label="Close" (click)="printPdf(false)">Download
          <i class="fa fa-download"></i> </button>
        </div>
      </div>

    </div>
  </div>
