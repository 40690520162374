import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { CaretakerService } from '../../services/caretaker.service';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import html2canvas from 'html2canvas';
import * as moment from 'moment';
import jspdf from 'jspdf';
declare var $: any;
@Component({
  selector: 'app-prescription-screen',
  templateUrl: './prescription-screen.component.html',
  styleUrls: ['./prescription-screen.component.css']
})
export class PrescriptionScreenComponent implements OnInit {
  precId;Createddate;doctorName;dStreet1;dStreet2;dCity;dZip;dState;dCountry;
  pstreet1;pstreet2;pcity;pzip;pstate;pcountry;prescriptionFill;showPdf;medicalTestPrescription
  patientName;imageIsPresent=false;MedicationPrescriptions;newsrc;showImage;doctorSignatureSrc;
  associatedUserData;userId
  MEdprecId;
  constructor(private globalStorage: GlobalStorageService, private controller: PatientScheduleControlService,public router: Router,
    private caretakerServices:CaretakerService,private sanitizer: DomSanitizer,private doctorService: DoctorService, ) { }
  @Input() fromAllAppointments:any
  @Input() fromAllPrescription:any
  @Input() fromAllAppointmentsDoctors:any
  isPatient=false
  ngOnInit(): void {
    if(this.globalStorage.getItem('loggedInAs')=='PATIENT'){
      this.isPatient=true
      var data = this.globalStorage.getItem('userData')
      var obj = JSON.parse(data)
      this.userId = obj['userId']
      this.associatedUserData = this.controller.sharingData
      if (this.associatedUserData) {
        this.userId = this.associatedUserData.relationUserId
      }
      else {
        this.userId = obj['userId']
      }
    }
    if(this.fromAllAppointmentsDoctors!=undefined){
      this.getPrescriptions(this.fromAllAppointmentsDoctors)
      this.getPatientAddress(this.fromAllAppointmentsDoctors?.userId)
      if(this.fromAllAppointmentsDoctors?.doctorId!=-1 || this.fromAllAppointmentsDoctors?.doctorId!=undefined || this.fromAllAppointmentsDoctors?.doctorId!=null){
        this.doctorAddressDetails(this.fromAllAppointmentsDoctors?.doctorId)
      }
    }
    if(this.fromAllAppointments!=undefined){
      this.MedicationPrescriptions=[]
      this.MedicationPrescriptions=[]
      this.controller.getPrescriptionPost(this.fromAllAppointments['referenceAppointmentId'],this.fromAllAppointments.userId).subscribe(res=>{
      this.doctorName=res['data']['doctorName']
      this.precId=res['data']['prescriptionId']
      this.Createddate=res['data']['appointmentDate']
      if(res['data']?.['medications'] != undefined){
        if(res['data']?.['medications'].length > 0){
          this.MedicationPrescriptions=res['data']?.['medications']
        }
      }
      if(res['data']?.['medicaltests'] != undefined){
      if(res['data']?.['medicaltests'].length >0){
      this.medicalTestPrescription=res['data']?.['medicaltests']
      }
    }
    if (res['data'] != null && (res['data']?.['documentName'].includes(".png") || res['data']?.['documentName'].includes(".jpeg") || res['data']?.['documentName'].includes(".jpg"))) {
      this.imageIsPresent=true
      this.src = "data:image/jpeg;base64," + res['data']['image']

      this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
      this.showImage = true 

    } else if (res['data'] != null && (res['data']?.['image'].includes(".pdf"))) {
      this.imageIsPresent=true
      this.newsrc = null
      this.showPdf = true

      this.src = "data:application/pdf;base64," + res['data']['image']
      this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
    }
   })
      if(this.fromAllAppointments.appointmentFor=='DOCTOR' || this.fromAllAppointments.appointmentFor=='NUTRITIONIST'){
        if(this.fromAllAppointments.referenceEntityId!=-1 || this.fromAllAppointments.referenceEntityId!=undefined || this.fromAllAppointments.referenceEntityId!=null){
          this.doctorAddressDetails(this.fromAllAppointments.referenceEntityId)
        }
        this.getPatientAddress(this.fromAllAppointments.userId)
      }
      if(this.fromAllAppointments.appointmentFor=='CARETAKER'){
        this.caretakerAddress(this.fromAllAppointments.referenceEntityId)
      }
    }
    if(this.fromAllPrescription!=undefined){
      console.log('fromAllPrescription',this.fromAllPrescription)
      if(this.router.url=='/appointments'){
        this.precId=this.fromAllPrescription?.hospitalprescriptionId
        this.doctorName=this.fromAllPrescription?.doctorName
      }
      this.viewPrescription(this.fromAllPrescription)
    }
  }
  prescriptionImageData;numberOfRefills;precName;src;prescriptionImageLocation;DoctorAppointmentId;
  currentDate=new Date()
  today=moment(this.currentDate).format('YYYY-MM-DD');
  viewPrescription(reports) {
    console.log('ivf reports',reports)
    this.prescriptionImageData = reports
    if(reports['doctorId']!=-1 || reports['doctorId']!=undefined || reports['doctorId']!=null){
      this.doctorAddressDetails(reports['doctorId'])
    }
    this.doctorName = reports['doctorName']
    this.prescriptionFill = reports['prescriptionFill']
    this.numberOfRefills = reports['numberofrefills']
    this.getPatientAddress(reports['patientId'])
    //this.pharmaAddress(1)
    this.precName = reports?.['documentName']
    if(this.globalStorage.getItem('loggedInAs')=='IVF'){
      this.precId=reports?.hospitalprescriptionId
      this.doctorName=reports?.doctorName
      this.controller.ivfPrescription(reports['hospitalprescriptionId'],reports['ivfaddressId'],reports['patientId']).subscribe(res=>{
        console.log('medications',res)
      //  this.medicalTestPrescription = res['data']
          this.MedicationPrescriptions = res['data']
          this.doctorName=this.MedicationPrescriptions?.doctorName;
          this.dStreet1=this.MedicationPrescriptions?.ivfaddress?.address1;
          this.dStreet2=this.MedicationPrescriptions?.ivfaddress?.address2;
          this.dCity=this.MedicationPrescriptions?.ivfaddress?.city;
          this.dZip=this.MedicationPrescriptions?.ivfaddress?.zip;
          this.dState=this.MedicationPrescriptions?.ivfaddress?.state;
          this.dCountry=this.MedicationPrescriptions?.ivfaddress?.country;
            this.newsrc = null
            this.showPdf = false
            this.showImage = false
      })
      var body={
        "hospitalAddresId": reports['ivfaddressId'],
        "maxResults": 10,
        "medicalTestPrescriptionId": reports['hospitalprescriptionId'],
        "pageNum": 1,
        "patientId": reports['patientId']
      }
      this.controller.ivfTestPrescription(body,reports['patientId']).subscribe(res=>{
        this.medicalTestPrescription = res['data']
        this.doctorName=this.medicalTestPrescription?.doctorName;
        this.dStreet1=this.medicalTestPrescription?.ivfaddress?.address1;
        this.dStreet2=this.medicalTestPrescription?.ivfaddress?.address2;
        this.dCity=this.medicalTestPrescription?.ivfaddress?.city;
        this.dZip=this.medicalTestPrescription?.ivfaddress?.zip;
        this.dState=this.medicalTestPrescription?.ivfaddress?.state;
        this.dCountry=this.medicalTestPrescription?.ivfaddress?.country;
        this.newsrc = null
        this.showPdf = false
        this.showImage = false
      })
    }
    else{
      if (reports?.['prescriptioncontainsImage'] == 'Y') {
        this.imageIsPresent = true
        if(reports['patientId']==undefined || reports['patientId']==null){
          reports['patientId']=this.userId
        }
        if(reports?.['prescriptionId']!=null || reports?.['prescriptionId']!=undefined){
          this.controller.postGlobal('prescription/getPrescriptionDetailsById?presciptionId=' + reports?.['prescriptionId'], reports['patientId']).subscribe(res => {
            if (res['data'] != null && (res['data']?.['documentName'].includes(".png") || res['data']?.['documentName'].includes(".jpeg") || res['data']?.['documentName'].includes(".jpg"))) {
              this.src = "data:image/jpeg;base64," + res['data']['image']
    
              this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
              this.showImage = true
    
            } else if (res['data'] != null && (res['data']?.['image'].includes(".pdf"))) {
              this.newsrc = null
              this.showPdf = true
    
              this.src = "data:application/pdf;base64," + res['data']['image']
              this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
            }
          })
        }
      }
      else {
        this.imageIsPresent = false
        if(reports['patientId']==undefined || reports['patientId']==null){
          reports['patientId']=this.userId
        }
        if(reports['medicalPrescriptionId']!=null || reports['medicalPrescriptionId']!=undefined){
          this.controller.postGlobal('prescription/getprescriptionswithmedaction?medicalPresciptionId=' + reports['medicalPrescriptionId'], reports['patientId']).subscribe(res => {
            // this.controller.globalgetBody('prescription/id/',reports['medicalPrescriptionId'],this.userId).subscribe(res=>{    
            this.medicalTestPrescription = res['data']['medicalTests']
            this.MedicationPrescriptions = res['data']['medication']    
            this.newsrc = null
            this.showPdf = false
            this.showImage = false
           
          }, error => {
            // this.toaster.error(error['error']['message'])
          })
        }
       
      }
    }
   
    this.prescriptionImageLocation = reports['documentLocation']
    if(reports['medicalPrescriptionId']!=null || reports['medicalPrescriptionId']!=undefined){
      this.MEdprecId = reports['medicalPrescriptionId']
    }
    if(this.router.url=='/appointments'){
      this.precId=this.fromAllPrescription?.hospitalprescriptionId
    }
    else{
      this.precId = reports['prescriptionId']
    }
    
    
    if(this.router.url=='/appointments'){
      this.Createddate=this.fromAllPrescription?.lastUpdatedTs
    }
    else{
      this.Createddate = reports['appointmentDate']
    }
    this.DoctorAppointmentId = reports['doctorAppointmentId']
    // if( reports['doctorId']!=-1 ||  reports['doctorId']!=undefined ||  reports['doctorId']!=null){
    //   this.controller.getDoctorSignature('personaldetail/doctorid/', reports['doctorId']).subscribe(res => {
    //     this.doctorSignatureSrc = "data:image/jpeg;base64," + res['data']?.['doctorSignature']
    //     this.doctorSignatureSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.doctorSignatureSrc)
    //   })
    // }
   

  }
  getPrescriptions(appointment){
    this.doctorService.getPrescription(appointment['appointmentId'], appointment['userId']).subscribe(res => {
      if (res['data'] != undefined) {
        this.precId=res['data']?.prescriptionId
        this.Createddate=res['data']?.appointmentDate
        if (res['data']['medicaltests']?.length > 0) {
          this.medicalTestPrescription = res['data']['medicaltests']
        }
        if (res['data']['medications']?.length > 0) {
          this.MedicationPrescriptions = res['data']['medications']
        }
        if(res['data']['containsTestPrescription']=='Y' || res['data']['containsMedicalPrescription']=='Y'){
          this.imageIsPresent = true
          if (res['data'] != null && (res['data']?.['documentName'].includes(".png") || res['data']?.['documentName'].includes(".jpeg") || res['data']?.['documentName'].includes(".jpg"))) {
            this.src = "data:image/jpeg;base64," + res['data']['image']
  
            this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
            this.showImage = true
  
          } else if (res['data'] != null && (res['data']?.['image'].includes(".pdf"))) {
            this.newsrc = null
            this.showPdf = true
  
            this.src = "data:application/pdf;base64," + res['data']['image']
            this.newsrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.src)
          }
        }
      }

    }, error => {
    })
  }
  caretakerAddress(id){
    this.caretakerServices.getAddressDetails(id).subscribe(res=>{
      this.dStreet1 = res['data']?.[0]?.['street1']
      this.dStreet2 = res['data']?.[0]?.['street2']
      this.dState = res['data']?.[0]?.['state']
      this.dCountry = res['data']?.[0]?.['country']
      this.dCity = res['data']?.[0]?.['city']
      this.dZip = res['data']?.[0]?.['zip']
    })
  }
  getPatientAddress(id) {
    var request = {
      "forUserId": id,
      "userId": id
    }
    this.controller.globalPost('address/get', request, id).subscribe(res => {
      this.patientName = res['data'][0]['contactName']
      this.pstreet1 = res['data'][0]['street1']
      this.pstreet2 = res['data'][0]['street2']
      this.pstate = res['data'][0]['state']
      this.pcity = res['data'][0]['city']
      this.pcountry = res['data'][0]['country']
      this.pzip = res['data'][0]['zip']
    })
  }
  doctorAddressDetails(id) {
    if(id){
      this.controller.getAddressDetails(id).subscribe(res => {
        // this.doctorAddress = res['data'][0]
        if(this.router.url=='/appointments'){
         this.doctorName=this.fromAllPrescription?.doctorName
        }
        else{
         this.doctorName=res['data']?.[0]?.['contactName']
        }
         this.dStreet1 = res['data']?.[0]?.['street1']
         this.dStreet2 = res['data']?.[0]?.['street2']
         this.dState = res['data']?.[0]?.['state']
         this.dCountry = res['data']?.[0]?.['country']
         this.dCity = res['data']?.[0]?.['city']
         this.dZip = res['data']?.[0]?.['zip']
       })
    }
  }
  closeModal(){
    $('#viewPrescription').modal('hide')
  }
  navigateToSelectPharma(){
    this.controller.saveDoctorData(this.MEdprecId);
    this.router.navigate(['/Appointment-Booking']);
    this.router.navigate(['/select-pharma'], {
      state: { example: this.MEdprecId }
    })
  }
  downloadAsPDF(){
    var data = document.getElementById('contentToConvert');
    $('.invoice-info').css('display','none');
      
    html2canvas(data).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save('newPDF.pdf');
    });
    $('.invoice-info').css('display','block')
  }
}
