<main class="l-main">
  <div class="content-wrapper content-wrapper--with-bg"> 
    <div class="container-fluid rounded-sm ">
    <div class="row">
       <div class="col-lg-8 col-md-8 col-sm-12  " *ngIf= isHome>
         <app-diagnostic-navebar></app-diagnostic-navebar>
       <router-outlet></router-outlet>
       </div>
       <div class="col-lg-12  col-md-12 col-sm-12   " *ngIf= !isHome>
        <app-diagnostic-navebar></app-diagnostic-navebar>
      <router-outlet></router-outlet>
      </div>
       <div class="col-lg-4 col-md-4 col-sm-12 mb-0" *ngIf= isHome>
          <app-diagnostic-sidebar></app-diagnostic-sidebar>
        </div>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
<script src="https://code.jquery.com/jquery-1.11.2.min.js"></script>
    <script src="https://netdna.bootstrapcdn.com/bootstrap/3.3.4/js/bootstrap.min.js"></script>
    <script src="https://www.jquery-az.com/jquery/js/multi-select/multiselect.js"></script>
    <script src="https://www.jqueryscript.net/demo/jQuery-Plugin-For-Multi-Selectable-Tree-Structure-Tree-Multiselect/jquery.tree-multiselect.min.js"></script>
    