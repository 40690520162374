import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import {FormGroup,FormBuilder, Validators} from '@angular/forms'
import { MustMatch } from 'src/app/_helpers/must_match.validators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import {LoginService} from '../../../AppServices/login.service'
import { DoctorService } from 'src/app/AppServices/doctor.service';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  login
  resetPassword:FormGroup
  submitted=false
  constructor(private globalStorage: GlobalStorageService, private fb:FormBuilder,private doctorService:DoctorService,public dialogRef: MatDialogRef<ChangePasswordComponent>, private toastr:ToastrService,private loginService:LoginService) {
    
  }

  ngOnInit(): void {
    this.resetPassword=this.fb.group({
      oldPassword:['',Validators.required],
      otp:['',Validators.required],
      newPassword:['',[Validators.required,Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{8,15})')]],
      confirmPassword:['',[Validators.required]]
    },
    {
      validator: MustMatch('newPassword','confirmPassword')
    }) 
    this.login=JSON.parse(this.globalStorage.getItem('LoginDetails'))
  }
  get f() { return this.resetPassword.controls; }
 

  submit(){
    this.submitted = true;
      if (this.resetPassword.invalid) {
          return;
      }
      
  
  var  request={
      "emailId":this.login['data'].emailId,
      "newPassword":this.resetPassword.value.newPassword,
      "otp":this.resetPassword.value.otp
    }
    this.loginService.changePassword(JSON.stringify(request)).subscribe(res=>{
          this.toastr.success("Password changed successfully !")
        },error=>{
          this.toastr.error("Error in change password")
        })
  }

  
  close() {
    this.dialogRef.close();
  }

  loginUser
  sendOTP(){
    this.loginService.resendOTP(this.login['data'].emailId).subscribe(res=>{
      this.toastr.success("Check your email for OTP.")
    },error=>
    {
      this.toastr.error("Error in send OTP")
    });

  }


  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // ngOnInit(): void {
  //   this.form=this.fb.group({
  //     oldPassword:['',Validators.required],
  //     password: ['', [Validators.required,Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
  //     confirmpassword:['', Validators.required],
  //   }, {
  //     validator: MustMatch('password','confirmpassword')
  //   })

  // }
  // close() {
  //   this.dialogRef.close();
  // }

  // get f() { return this.form.controls; }
  // submitted:boolean=false;
  // changePassword(){
  //   this.submitted=true;
  //   if (this.form.invalid) {
  //      return
  //    }
  // }
}
