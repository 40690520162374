import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.css']
})
export class PatientHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
