<div class="col-12 list">

  <div class="card d-flex flex-row mb-0 p-2 border-bottom w-33 float-left  blt-2 mb-2 mt-1"
    *ngFor="let reports of patientMedicalReports">
    <a _ngcontent-hhw-c5="" class="p-2 icon-appointments  ">
      <i _ngcontent-hhw-c5="" aria-hidden="true" class="fa fa-file-pdf icon-col-2 icon-reports"></i></a>
    <div _ngcontent-hhw-c5="" class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
      <a _ngcontent-hhw-c5="">
        <p _ngcontent-hhw-c5="" class="font-weight-medium mb-1 truncate mt-1">{{ reports.reportName }}</p>
        <p _ngcontent-hhw-c5="" class="text-muted mb-1 text-small">{{reports.reportTakenOn | date:'dd-MMM-yyyy'}}</p>
      </a>
    </div>
    <div _ngcontent-hhw-c5="" class="btn-group float-right">
      <button _ngcontent-hhw-c5="" class="btn btn-xs btn0col1" data-backdrop="static" data-target="#viewpdf"
        data-toggle="modal">
        <i _ngcontent-hhw-c5="" aria-hidden="true" class="fa fa-eye" (click)="viewMedicalReport(reports)"></i>
      </button>
    </div>
  </div>

  <div class="no-reports" *ngIf="patientMedicalReports && patientMedicalReports?.length==0"></div>
</div>

<!-- <a href="javascript:void(0)" class="p-2 icon-appointments  ">
    <i class="fa fa-heart themecol2 icon-head" aria-hidden="true"></i>
    </a>

    <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
    <a href="javascript:void(0)">
    <p class="font-weight-medium mb-1 mt-1">{{ reports.reportName }}
    </p>
    <p class="text-muted mb-1 text-small">{{reports.reportTakenOn* 1000 | date:'dd/MM/yyyy'}}</p>
    </a>
    
    </div>
    
    <div class="btn-group float-right">
    <button  class="btn btn-xs btn0col1"  data-toggle="modal" data-backdrop="static" (click)="viewMedicalReport(reports)"><i class="fa fa-eye" aria-hidden="true"></i></button>
    </div> -->