<div class="shipping-method-wrapper mb-2">
    <div class="m-0">
        <h5 class="mt-2 mb-1 border-bottom pt-2 pb-2 pl-2 head bg-primary-light">Delivery method</h5>
        <div class="row">
            <div class="payment-checkout-form text-left col-md-6">
                <input name="shipping_option" type="hidden" value="1">
                <ul class="list-group list_payment_method mr-2">
                    <li class="list-group-item">
                        <input id="shipping-method-default-1" name="shipping_method"
                            class="magic-radio shipping_method_input" checked="checked" data-option="1" type="radio"
                            value="default">
                        <label for="shipping-method-default-1">
                            <div>
                                <span>
                                    <strong>Pickup</strong>
                                </span>
                            </div>
                            <div>
                            </div>
                        </label>
                    </li>
                </ul>
            </div>
            <div class="payment-checkout-form text-left col-md-6">
                <input name="shipping_option" type="hidden" value="1">
                <ul class="list-group list_payment_method ml-2">
                    <li class="list-group-item">
                        <input id="shipping-method-default-1" name="shipping_method"
                            class="magic-radio shipping_method_input" checked="checked" data-option="1" type="radio"
                            value="default">
                        <label for="shipping-method-default-1">
                            <div>
                                <span>
                                    <strong>Home Delivery</strong>
                                </span>
                            </div>
                            <div>
                            </div>
                        </label>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
<div class="mt-1">
    <h5 class="mt-2 mb-1 border-bottom pt-2 pb-2 pl-2 head bg-primary-light">
        Select Address
      
    </h5>
    <div class="">
        <div 
          class="p-2 card-child mb-3" 
          *ngFor="let address of addressList; let i = index" 
          (click)="selectAddress(i)"
          [ngClass]="{'selected-card': selectedIndex === i}"
        >
          <div class="d-flex flex-row align-items-center">
            <span class="circle">
              <i class="fa fa-home" aria-hidden="true"></i>
            </span>
            <div class="d-flex flex-column ms-1 pl-2">
              <h6 class="fw-bold text-left">{{ address?.contactName }}</h6>
              <span>
                {{ address?.street1 }} {{ address?.street2 }}, {{ address?.city }} {{ address?.postalLocation }},
                {{ address?.state }} - {{ address?.zip }}
              </span>
            </div>
          </div>
        </div>

        <div class="p-2 card-child mt-2"(click)="addNewAddress()" >
            <div class="d-flex flex-row align-items-center">
              <span class="circle-3">
                  <i class="fa fa-plus" aria-hidden="true"></i>
              </span>
              <div class="d-flex flex-column ms-3 mt-1 ml-3">
                  <h6 class="fw-bold text-primary">Add New Address</h6>
              </div>
            </div>
        </div>
        
        
      </div>
      
      <button class="btn btn-sm btn-success mt-2 float-right"  (click)="emitOUt()">Fill Prescription</button>
</div>