import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { LoginService } from '../../../AppServices/login.service'
import { Route, Router } from '@angular/router'
import { formatDate } from '@angular/common';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { gpsService } from '../../services/gps.service';
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
import { ApicallsService } from 'src/app/apicalls.service';
import { PatientCreatAndBookAppointmentComponent } from '../../components/patient-creat-and-book-appointment/patient-creat-and-book-appointment.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $;
@Component({
  selector: 'doc-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class docNavBarComponent implements OnInit {
  userName
  lastLogin
  setPersonalDetails
  notificationCount
  notificationLength
  notViewedNotifications
  viewedNotifications
  notications
  ImageSrc
  imgURL
  p2 = 1;
  notificationBell = true
  firstTimeLogin = false
  firstName
  lastName
  notifyToView
  constructor(private globalStorage: GlobalStorageService, private loginService: LoginService, private route: Router, private doctorService: DoctorService, private toaster: ToastrService
    , private matDialog: MatDialog, private gps: gpsService, private scripts: ScriptsService,
    private patientController: PatientScheduleControlService, private sanitizer: DomSanitizer, private apiservice: ApicallsService,private modalService:NgbModal,
   ) {

    this.doctorService.loginUsertObservable.subscribe(res => {

      this.lastLogin = res['lastLogin']
      this.setPersonalDetails = res['personalData']
      if (this.setPersonalDetails?.['profilePic'] != null) {
        this.ImageSrc = "data:image/jpeg;base64," + this.setPersonalDetails['profilePic']
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.ImageSrc);
      } else {
        this.imgURL = "/assets/img/avatar.png";
      }
    }, eror => {
      this.toaster.error("Error getting user data")
    });

  }



  ngOnInit(): void {
    // var myvar= setInterval(currentTime, 1000);
    //roles
    // var role = JSON.parse(this.globalStorage.getItem('roles'))
    // this.roles = role[0]?.['menu']
    // var setAppointmentsPermission = this.roles?.filter(obj => obj?.menuName == "Appointments")
    // var setCalenderPermission = this.roles?.filter(obj => obj?.menuName == "Calender")
    // var setBaseshedulePermission = this.roles?.filter(obj => obj?.menuName == "Baseshedule")
    // var setReportsPermission = this.roles?.filter(obj => obj?.menuName == "Reports")
    // var setProfilePermission = this.roles?.filter(obj => obj?.menuName == "Profile")
    // if (setAppointmentsPermission?.[0]?.active == 'Y') {
    //   this.AppointmentsPermission = true
    // }
    // if (setCalenderPermission?.[0]?.active == 'Y') {
    //   this.CalenderPermission = true
    // }
    // if (setBaseshedulePermission?.[0]?.active == 'Y') {
    //   this.BaseshedulePermission = true
    // }
    // if (setReportsPermission?.[0]?.active == 'Y') {
    //   this.ReportsPermission = true
    // }
    // if (setProfilePermission?.[0]?.active == 'Y') {
    //   this.ProfilePermission = true
    // }
    //roles end

    this.scripts.loadScripts()
    $("#loginUserName").text(JSON.parse(this.globalStorage.getItem('firstName')))
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });

      $('[data-toggle="tooltip"]').on('click', function () {
        $(this).tooltip('hide')
      });
    });

    $("button, a, body").on("click", function (e) {
      $('.tooltip').hide();
      $('[data-toggle="tooltip"]').tooltip("hide");
    });
    //  this.getAssociatedUsers()
    $(function () {
      $('[data-toggle="tooltip"]').tooltip({
        trigger: 'hover'
      });

      $('[data-toggle="tooltip"]').on('click', function () {
        $(this).tooltip('hide')
      });
    });
    this.checkProfile()
    this.loadData()
    this.LoadNotification()
    if (window.location.pathname == '/dashboard' || window.location.pathname == '/cureMetric/dashboard') {
      this.notificationBell = false
    }
    this.LoadDoctor()
  }
  checkProfile(){
    if (this.globalStorage.getItem('isPersonalDetailCompleted') != 'Y') {
      this.route.navigate(['/personalDetails']);
    }
    else {
      if (this.globalStorage.getItem('isAddressCompleted') != 'Y') {
        this.route.navigate(['/addressDetails'])
        return
      }
      else {
        if (this.globalStorage.getItem('isFeeDetailCompleted')!= 'Y') {
          this.route.navigate(['/EditProfile'])
          return
        }
        else {
          //this.route.navigate(['dashboard']);
        }
      }
    }
  }
  // checkProfile() {
  //   this.doctorService.doctorProfileCompletedOrNot(this.globalStorage.getItem('doctorId')).subscribe(res => {
  //     if (res['data']['isPersonalDetailCompleted'] == 'N') {
  //       this.route.navigate(['/personalDetails']);
  //     }
  //     else {
  //       if (res['data']['isAddressCompleted'] == 'N') {
  //         this.route.navigate(['/addressDetails'])
  //         return
  //       }
  //       else {
  //         if (res['data']['isFeeDetailCompleted'] == 'N') {
  //           this.route.navigate(['/EditProfile'])
  //           return
  //         }
  //         else {
  //           //this.route.navigate(['dashboard']);
  //         }
  //       }
  //     }

  //   })
  // }

  logOut() {
    this.loginService.LogOut().subscribe(res => {
      var data = this.globalStorage.getItem('LoginDetails')
      var obj = JSON.parse(data)
      if (obj['data']['type'] == "NUTRITIONIST") {
        this.route.navigate(['/nutritionistLogin'])
      }
      if (obj['data']['type'] == "DOCTOR") {
        this.route.navigate(['/Login'])
      }

      this.apiservice.isUserSessionExits = false
      this.apiservice.isforUserIdExits = false
      this.apiservice.ismoduleNameExits = false
      //this.globalStorage.clear();
    });
  }
  onSend(data) {
    var formData = new FormData();
    formData.append('screenshot', data.screenshot);
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    var dateTime = date + ' ' + time;
    var request = {
      "clientId": 11,
      "userId": this.globalStorage.getItem('doctorId'),
      "feedBackType": "TEST",
      "status": "NEW",
      "description": data.description,
      "userAgent": "test",
      "os": "Windows",
      "browser": "Chrome",
      "os_version": "windows-10",
      "browser_version": "85.0.4183.102",
      "device": "Unknown",
      "latitude": this.gps.latitude,
      "longitudes": this.gps.longitude,
      "feedbackTime": formatDate(dateTime, "yyyy-MM-dd", 'en-us')
    }
    formData.append('feedbackLoop', JSON.stringify(request));
    this.doctorService.sendFeedback(formData).subscribe(res => {
      this.toaster.success('Feedback sent successfully')
    })
  }
  loadData() {
    var data = this.globalStorage.getItem('LoginDetails')
    var obj = JSON.parse(data)
    this.lastLogin = obj['data']['lastLogin']
    this.firstName = obj['data']['firstName']
    this.lastName = obj['data']['lastName']
    var dateDate = new Date(obj['data']['lastLogin'])
    const date2 = formatDate(dateDate, "medium", 'en-us');
    this.lastLogin = date2
    if (this.lastLogin == "Jan 1, 1970, 5:30:00 AM") {
      this.firstTimeLogin = true
    }
    else {
      this.firstTimeLogin = false
    }
  }
  profileDetails
  LoadDoctor() {
    // this.doctorService.getDoctorDetailsById(this.globalStorage.getItem('doctorId')).subscribe(res => {

      this.profileDetails =this.globalStorage.getItem('doctorFirstName')+ ' '+ this.globalStorage.getItem('doctorLastName')
    // }, error => {
    //   this.toaster.error("Error in  getting doctor Details")
    // })
  }
  p1 = 1
  LoadNotification() {

    this.patientController.getLatestDoctorNotificationAlerts('notificationalerts/get/all?doctorId=' + this.globalStorage.getItem('doctorId') + '&maxResult=100&pageNumber=' + this.p1).subscribe(res => {

      this.notificationCount = res['data'].length
      this.notications = res['data']
      this.notificationLength = this.notications.filter((obj) => obj.viewed == 'N').length;
      this.notViewedNotifications = this.notications.filter((obj) => obj.viewed == 'N')
      this.viewedNotifications = this.notications.filter((obj) => obj.viewed == 'Y')
    }, error => {
    })
  }

  changePassword() {
    // var data= this.globalStorage.getItem('LoginDetails')
    // var obj=JSON.parse(data)
    // var request={
    //   "emailId":obj['data']['emailId'],
    //   "resetPasswordLink":"http:localhost:4200/resetPassword"
    // }
    // this.forgot.forgotPassword(JSON.stringify(request)).subscribe(res=>{
    //   this.toaster.success(res['message'])
    // },error=>{
    // this.toaster.error(error[error])
    // })

    const dialogConfig = new MatDialogConfig();
    // dialogConfig.data = arg;
    this.matDialog.open(ChangePasswordComponent, {
      height: '50%',
      width: '50%',
    });
  }

  // ngAfterViewInit() {
  //  // alert();
  //   //$("#logo").removeAttr("href").css("cursor","pointer");
  //   //$("#sliderButton").removeAttr("href").css("cursor","pointer");
  // }
  closeModal() {
    $('#notificationToView').modal('hide')
    this.LoadNotification()
  }
  updateNotify(data) {
    $('#notificationViewer').modal('show')
    this.notifyToView = data
    var req = {
      "active": data.active,
      "notificationalertsId": data.notificationalertsId,
      "objectkey": data.objectkey,
      "objecttype": data.objecttype,
      "doctorId": data.doctorId,
      "reason": data.reason,
      "viewed": "Y"
    }
    this.doctorService.updateNotifications(data.notificationalertsId, data.doctorId, req).subscribe(res => {
      this.notificationLength = this.notificationLength - 1
      this.LoadNotification()
    })

  }
  creatUserOrBookAppointment(){
    const modalRef = this.modalService.open(PatientCreatAndBookAppointmentComponent, {
      centered: false,
      windowClass: 'modal-left hw-60',
      backdrop: 'static',
        keyboard: false,
    });
    // modalRef.componentInstance.doctorDetails = doctorDetails;
    // modalRef.componentInstance.patientDetails=this.patientDetails

    modalRef.result.then(
      (userConfirmed: boolean) => {
        // this.activeModal.close()
        if (userConfirmed) {
          // this.getProtocols();
        }
      },
      () => { }  // Handle dismiss callback
    );
  }
}
