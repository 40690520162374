import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { DoctorService } from '../../AppServices/doctor.service'
import { ToastrService } from 'ngx-toastr';
import { MustMatch, NoMatch, NoSameFirstName, NoSamelastName } from '../../_helpers/must_match.validators';
import { Router, NavigationExtras } from '@angular/router'
import { AuthService } from 'src/app/shared/services/auth.service';
import { formatDate } from '@angular/common';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-nutritionist-registration',
  templateUrl: './nutritionist-registration.component.html',
  styleUrls: ['./nutritionist-registration.component.css']
})
export class NutritionistRegistrationComponent implements OnInit {
  maxDate
  minDate
  doctorForm: FormGroup;
  isExist = false
  invalidCredentials = false
  successCredentials = false
  message
  type
  isEmailExists = false
  isEmailExistsMessage
  isUserNameExists = false
  isUserNameExistsMessage
  isPhoneNumberExists = false
  isPhoneNumberExistsMessage
  doctorAddress: FormGroup
  isNutritionist: boolean = true
  isPsychiatrist: boolean = true
  isPhysiotherapist: boolean = true
  constructor(private fb: FormBuilder, private DoctorService: DoctorService, private ToastrService: ToastrService, private router: Router) {
    var d = new Date();
    this.minDate = new Date(d);
    this.maxDate = new Date(d);
  }
  medicalLicenceExpiryDate
  ngOnInit(): void {
    this.doctorForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      middleName: [''],
      lastName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      cellNumber: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      //emergencyContactNumber: ['999999999', Validators.required],
      // ethinicity: ['test'],
      userName: ['', Validators.required, Validators.pattern("^[A-Za-z0-9_-]*$")],
      gender: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: ['', [Validators.required, Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)*\\S{8,15})')]],//Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{8,15})')
      confirmpassword: ['', [Validators.required, Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)*\\S{8,15})')]],
      specialization: [''],
      dateOfExpiry: ['', Validators.required],
      // highestDegree: ['Surgeon'],
      medicalLicenceNumber: ['', [Validators.required, Validators.minLength(5)]],
      // medicalLicenceExpiryDate: ['', Validators.required],
      totalExperience: [0],
      // Address:this.fb.group(this.doctorAddress)
    }, {
      validator: [MustMatch('password', 'confirmpassword'), NoMatch('firstName', 'lastName')]
    })
    var input = document.querySelector("#telephone");
    jQuery(document).ready(function ($) {
      (<any>window).intlTelInputGlobals.loadUtils("./../../../assets/build/js/utils.js");

      if (jQuery('#telephone').length) {
        var input = document.querySelector("#telephone");
        (<any>window).intlTelInput(input, {
          preferredCountries: ['in'],
          separateDialCode: true, placeholderNumberType: "MOBILE",
          autoPlaceholder: "polite", autoHideDialCode: true,
          hiddenInput: "",

          customPlaceholder: null,
          formatOnDisplay: true,

        });
      }

    });
    input.addEventListener("countrychange", function () {
      (<any>input).intlTelInput();
      (<any>input).val('')
    });
    if (window.location.pathname == '/PsychiatristRegistration') {
      this.isNutritionist = false
      this.isPsychiatrist = true
      this.isPhysiotherapist = false
      this.type = "PSYCHIATRIST"
    }
    if (window.location.pathname == '/nutritionistRegistration') {
      this.isNutritionist = true
      this.isPsychiatrist = false
      this.isPhysiotherapist = false
      this.type = "NUTRITIONIST"
    }
    if (window.location.pathname == '/physiotherapistRegistration') {
      this.isNutritionist = false
      this.isPsychiatrist = false
      this.isPhysiotherapist = true
      this.type = "PHYSIOTHERAPIST"
    }
  }


  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
  }

  //NoMatch('firstName','lastName'),NoSameFirstName('firstName','password'),NoSamelastName('lastName','password')

  numberOnly(event, phn): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (phn.length < 1) {
      if (charCode < 54 || charCode > 57) {
        return false;
      }
      return true;
    }
    else {
      if (charCode < 48 || charCode > 57) {
        return false;
      }
      return true;

    }
  }
  alphaOnly(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/[a-zåäö ]/i);
    return pattern.test(value);
  };
  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z\_]+$/);
    return pattern.test(value);
  }
  checkUserName() {
    this.invalidCredentials = false
    this.DoctorService.userNameExistOrNot(this.doctorForm.controls['userName'].value).subscribe(res => {
      if (res['data'] == true) {
        this.invalidCredentials = true
        this.isUserNameExists = true
        this.isUserNameExistsMessage = res['message']
        //  this.message = res['message']
        //this.ToastrService.error("A user with this email address has already been registered in the system and the associated already exists.");
        this.isExist = true
        this.submitted = false;
        return
      }
      else {
        this.isUserNameExists = false
      }
    }, error => {
      //this.ToastrService.error("Error in exists email");
    });
  }

  get f() { return this.doctorForm.controls; }

  submitted: boolean = false;
  registration: boolean = false;
  saveDoctor() {
    this.checkUserName()
    this.submitted = true;
    if (this.doctorForm.invalid) {
      return
    }
    this.DoctorService.existingEmail(this.doctorForm.controls['emailId'].value).subscribe(res => {
      if (res.data == true) {
        this.ToastrService.error("A user with this email address has already been registered in the system and the associated already exists.");
        this.isExist = true
        this.submitted = false;
        return
      }
    }, error => {
      this.ToastrService.error("Error in exists email");
    });

    const dateOfExpiry = formatDate(this.doctorForm.value.dateOfExpiry, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');

    var d1 = moment(dateOfExpiry);

    var d2 = moment(new Date());
    var days = moment.duration(d1.diff(d2)).asDays();
    var datediff = parseInt(days.toFixed(0));
    if (datediff <= 7) {
      this.ToastrService.error("date of expiry should not be in a week")
      return
    }


    var newDoctor = {
      "activatedDate": null,
      "active": 'Y',
      "associations": null,
      "averagerating": 0,
      "cellNumber": $('.iti__selected-dial-code').text() + this.doctorForm.value.cellNumber,
      "certifications": null,
      "dateOfExpiry": this.doctorForm.value.dateOfExpiry,
      "doctorAddresses": null,
      "doctorId": null,
      "emailId": this.doctorForm.value.emailId,
      "emailVerificationLink": "",
      "feedetails": null,
      "firstName": this.doctorForm.value.firstName,
      "gender": this.doctorForm.value.gender,
      "userName": this.doctorForm.value.userName,
      "homeNumber": "",
      "lastName": this.doctorForm.value.lastName,
      "locked": null,
      "medicalLicenceNumber": this.doctorForm.value.medicalLicenceNumber,
      "middleName": this.doctorForm.value.middleName,
      "officeNumber": "",
      "password": this.doctorForm.value.password,
      "personaldetail": null,
      "primaryContact": "CELL_NUMBER",
      "type": "NUTRITIONIST",
      "retry": 0,
      "specialization": "NUTRITIONIST",
      "totalExperience": this.doctorForm.value.totalExperience,
    }
    if (!this.doctorForm.invalid) {
      if (window.location.pathname == '/PsychiatristRegistration') {
        this.DoctorService.doctorRegistration(newDoctor).subscribe(res => {
          // this.router.navigate(['/doctor-succesfull-Registration'])
          const navigationExtras: NavigationExtras = {
            state: {
              emailId: this.doctorForm.value.emailId,
              mobileNumber: $('.iti__selected-dial-code').text() + this.doctorForm.value.cellNumber,
            }
          };

          this.router.navigate(['/otp-verifcation-psychiatrist'], navigationExtras)
          this.ToastrService.success("User registered successfully.");
          // this.registration=true;
          //this.router.navigate(['/Login']);
        }, error => {
          this.router.navigate(['/FailureRegistration'])
          this.ToastrService.error("Error in saving registration");
        });
      }
      if (window.location.pathname == '/nutritionistRegistration' || window.location.pathname == '/cureMetric/nutritionistRegistration') {
        this.DoctorService.doctorRegistration(newDoctor).subscribe(res => {
          // this.router.navigate(['/doctor-succesfull-Registration'])
          const navigationExtras: NavigationExtras = {
            state: {
              emailId: this.doctorForm.value.emailId,
              userName: this.doctorForm.value.userName,
              mobileNumber: $('.iti__selected-dial-code').text() + this.doctorForm.value.cellNumber,
            }
          };

          this.router.navigate(['/otp-verifcation-nutritionist'], navigationExtras)
          this.ToastrService.success("Nutritionist registered successfully.");
          // this.registration=true;
          //this.router.navigate(['/Login']);
        }, error => {
          this.router.navigate(['/FailureRegistration'])
          this.ToastrService.error("Error in saving registration");
        });
      }
      if (window.location.pathname == '/physiotherapistRegistration') {
        this.DoctorService.doctorRegistration(newDoctor).subscribe(res => {
          // this.router.navigate(['/doctor-succesfull-Registration'])
          const navigationExtras: NavigationExtras = {
            state: {
              emailId: this.doctorForm.value.emailId,
              mobileNumber: $('.iti__selected-dial-code').text() + this.doctorForm.value.cellNumber,
            }
          };

          this.router.navigate(['/otp-verifcation-physiotherapist'], navigationExtras)
          this.ToastrService.success("User registered successfully.");
          // this.registration=true;
          //this.router.navigate(['/Login']);
        }, error => {
          this.router.navigate(['/FailureRegistration'])
          this.ToastrService.error("Error in saving registration");
        });
      }

    }
    else {
      this.ToastrService.error("Please enter all mandatory fields");
    }

  }

  keyPressAlphaNumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  reset() {
    this.doctorForm.reset();
    this.submitted = false;
    // this.doctorAddress.reset();
  }

  checkEmail() {
    this.invalidCredentials = false
    if (this.f.emailId.errors == null) {
      this.isExist = false
      this.DoctorService.existingEmail(this.doctorForm.controls['emailId'].value).subscribe(res => {
        if (res.data == true) {
          this.invalidCredentials = true
          this.isEmailExists = true
          this.isEmailExistsMessage = res?.message
          // this.message = res?.message
          //this.ToastrService.error("A user with this email address has already been registered in the system and the associated already exists.");
          this.isExist = true
          this.submitted = false;
          return
        }
        else {
          this.isEmailExists = false
        }
      }, error => {
        //this.ToastrService.error("Error in exists email");
      });
    }

  }
  checkPhoneNumber(num) {
    var number = $('.iti__selected-dial-code').text() + num
    this.invalidCredentials = false
    if (num.length == 10) {
      this.DoctorService.checkPhoneNumber(number.replace(/\+/gi, '%2B')).subscribe(res => {
        if (res.data == true) {
          this.invalidCredentials = true
          this.isPhoneNumberExists = true
          this.isPhoneNumberExistsMessage = res?.message
          //this.message = res?.message
          return
        }
        else {
          this.isPhoneNumberExists = false
        }
      }, (error) => {
        this.message = error['error']['message']
      });
    }
  }
  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });

    $("#show_hide_password1 a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password1 input').attr("type") == "text") {
        $('#show_hide_password1 input').attr('type', 'password');
        $('#show_hide_password1 i').addClass("fa-eye-slash");
        $('#show_hide_password1 i').removeClass("fa-eye");
      } else if ($('#show_hide_password1 input').attr("type") == "password") {
        $('#show_hide_password1 input').attr('type', 'text');
        $('#show_hide_password1 i').removeClass("fa-eye-slash");
        $('#show_hide_password1 i').addClass("fa-eye");
      }
    });
  }
}
