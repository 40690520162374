import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-payment-consent',
  templateUrl: './payment-consent.component.html',
  styleUrls: ['./payment-consent.component.css']
})
export class PaymentConsentComponent {
  @Input() consent: boolean = false;

 
  @Input() data;
  @Input() isChecked: boolean = false;

  constructor(public activeModal: NgbActiveModal) { }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.isChecked) {
  //     console.log(changes.isChecked.currentValue)
  //     this.consent = changes.isChecked.currentValue
  //   }
  // }

  agreedForm() {
    
    this.activeModal.close(this.consent);

  }

}
