import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService {
  private requests: HttpRequest<any>[] = [];

  constructor(private globalStorage: GlobalStorageService, private loaderService: LoaderService,private router: Router,private toastrService: ToastrService,) { }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.requests.push(req);
   const currentUser = JSON.parse(this.globalStorage.getItem('userSession'));
    if(req.url.includes('elasticsearch/searchPatient')){
      this.loaderService.isLoading.next(false);
    }
    else{
      this.loaderService.isLoading.next(true);
    }
    return Observable?.create(observer => {
      const subscription = next?.handle(req)
        ?.subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer?.next(event);
            }
          },
          err => {
            if (err.status == 401) {
              this.toastrService.error('Session Expired')
              if(this.globalStorage.getItem('loggedInAs')=='PATIENT'){
                this.router.navigate(['/patientLogin'])
              }
              if(this.globalStorage.getItem('loggedInAs')=='DOCTOR'){
                this.router.navigate(['/Login'])
              }
              if(this.globalStorage.getItem('loggedInAs')=='DIAGNOSTIC_CENTER'){
                this.router.navigate(['/DiagnosticLogin'])
              }
              if(this.globalStorage.getItem('loggedInAs')=='PHARMACY'){
                this.router.navigate(['/pharmaLogin'])
              }
              if(this.globalStorage.getItem('loggedInAs')=='CARETAKER'){
                this.router.navigate(['/caretakerLogin'])
              }
              if(this.globalStorage.getItem('loggedInAs')=='NUTRITIONIST'){
                this.router.navigate(['/nutritionistLogin'])
              }
              if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
                this.router.navigate(['/clinicLogin'])
              }
             }
            this.removeRequest(req);
            observer.error(err);
          },
          () => {
            this.removeRequest(req);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
