<div class="modal-header" *ngIf="requestFromOrder!='Y'">
    <h4 class="centerHeader mb-0">{{supplierInfo?.name}} Details</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <ul class="nav nav-tabs navhos navlistscroll" id="pills-tab" role="tablist" *ngIf="requestFromOrder!='Y'">
        <li class="nav-item">
            <a class="active" id="pills-items-tab" data-toggle="pill" href="#pills-items" role="tab"
                aria-controls="pills-items" aria-selected="true">Associated Items</a>
        </li>
        <li class="nav-item">
            <a id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home"
                aria-selected="true" (click)="currentOrders()">Current Orders</a>
        </li>
        <li class="nav-item">
            <a id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
                aria-controls="pills-profile" aria-selected="false" (click)="previousOrders()">Previous Orders</a>
        </li>
        <li class="nav-item">
            <a id="pills-deals-tab" data-toggle="pill" href="#pills-deals" role="tab"
                aria-controls="pills-deal" aria-selected="false" (click)="getActiveDeals()">Active Deals</a>
        </li>
    </ul>
    <div class="tab-content pl-0" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-items" role="tabpanel" aria-labelledby="pills-items-tab">
            <div class="float-right mb-2">
                <button type="button" class="btn btn-success" 
                (click)="subCategoryAdd(supplierInfo)">Add Item</button>
            </div>
            <table class="table table-hover table-striped">
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Item Name</th>
                        <th>Unit Price</th>
                        <th>Deal</th>
                        <!-- <th>Status</th> -->
                        <th width="200">Actions</th>
                    </tr>
                </thead>
    
                <tbody>
                    <tr *ngFor="let data of categoriesData | paginate: { id: 'p3', itemsPerPage: 10, currentPage: p3 };let i=index">
                        <td>{{data?.catagory}}</td>
                        <td>{{data?.itemTypeName}}</td>
                        <td>{{data?.unitprice}}</td>
                        <td>{{data?.deal}}</td>
                        <!-- <td *ngIf="data?.active=='Y'"><span class="bg-success p-1">Active</span></td>
                        <td *ngIf="data?.active=='N'"><span class="bg-danger p-1">Inactive</span></td>             -->
                        <td>
                            <div class="align-items-center d-flex justify-content-between">
                                <div>
                                    <button class="btn btn-success btn-xs btn-icon" data-toggle="tooltip"
                                    data-original-title="Place Order" title="Place Order" (click)="openPlaceOrder(data)"
                                    *ngIf="data.active=='Y'" [disabled]="categoriesData[i]?.isChecked"><i class="fa fa-user-plus"></i>
                                    </button>
                                    <button class="btn btn-primary btn-xs btn-icon" data-toggle="tooltip"
                                        data-original-title="Edit" title="Edit" (click)="openSaveSupplier(data)"
                                        *ngIf="data.active=='Y'" [disabled]="categoriesData[i]?.isChecked"><i class="fa fa-edit"></i></button>
                                    <button class="btn btn-success btn-xs btn-icon"
                                     (click)="activateSubCategory(data)"
                                        *ngIf="data.active=='N'" data-toggle="tooltip"
                                        data-original-title="Active" title="Active" [disabled]="categoriesData[i]?.isChecked"><i
                                            class="fa fa-user-check fs-10"></i></button>
                                    <button class="btn btn-danger btn-xs"
                                         *ngIf="data.active=='Y'" (click)="deactivateSubCatagory(data)"
                                        data-toggle="tooltip" data-original-title="Deactivate" title="Deactivate" [disabled]="categoriesData[i]?.isChecked"><i
                                            class="fa fa-trash-o"></i></button>
                                </div>

                                <div class="form-check">
                                    <input type="checkbox" [(ngModel)]="categoriesData[i].isChecked" class="form-check-input" id="{{data?.itemTypeName}}" name="{{data?.itemTypeName}}"
                                >
                                    <label class="form-check-label fs-16 mt-0" for="{{data?.itemTypeName}}">Add</label>    
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="text-right" *ngIf="categoriesData?.length > 0">
                <button class="btn btn-success" (click)="openCreatePO()"><i class="fa fa-plus"></i> Create Purchase Order</button>
            </div>

            <div *ngIf="categoriesData?.length == 0 || categoriesData?.length == null">
                <div class="no-data-found"></div>
            </div>
            <div *ngIf="categoriesData?.length > 10">
                <div class="text-right mt-2">
                    <ul class="pagination">
                        <pagination-controls autoHide="true" id="p3"
                            (pageChange)="p3=$event"></pagination-controls>
                    </ul>
                </div>
            </div>
    
        </div>

        <div class="tab-pane fade" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

            <table class="table table-bordered table-hover table-styled table-striped">
                <thead>
                    <tr role="row">
                        <th>Order Id</th>
                        <th>Order Date</th>
                        <th>Delivery Date</th>
                        <th>Expected Delivery Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of currentOrd | paginate: { id: 'p1', itemsPerPage: 10, currentPage: p1 }">
                        <td>{{data?.supplierordersId}}</td>
                        <td>{{data?.dateOrdered | date: 'dd-MMM-yyyy'}}</td>
                        <td>{{data?.deliveryDate | date: 'dd-MMM-yyyy'}}</td>
                        <!-- <td>{{data?.deliveredDate | date: 'dd-MMM-yyyy'}}</td> -->
                        <td>{{data?.expectedDeliveryDate | date: 'dd-MMM-yyyy'}}</td>
                        <!-- <td>
                            <span *ngIf="data?.status == 'ORDERPLACED'">Placed</span>
                            <span *ngIf="data?.status == 'PARTLYDELIVER'">Partial Delivered</span>
                            <span *ngIf="data?.status == 'ORDERDELIVER'">Delivered</span>
                            <span *ngIf="data?.status == 'CANCLEORDER'">Cancelled</span>
                        </td> -->
                        <td>
                            <button aria-hidden="true" class="btn btn-danger btn-xs ml-2" data-original-title="Delivered" title="Deliver Items"
                            data-toggle="tooltip" data-placement="top"(click)="delivaryItems(data)"><i
                            class="fa-solid fa-truck-ramp-box"></i></button>
                            <button class="btn btn-success btn-xs ml-2" data-placement="top" (click)="(0)" data-toggle="tooltip" data-original-title="Edit"
                            title="Edit" (click)="editOrder(data)"><i
                                class="fa fa-edit"></i></button>
                            <button aria-hidden="true" class="btn btn-success btn-xs ml-2" data-original-title="PO Details"
                            title="Order Details" data-toggle="tooltip" data-placement="top" (click)="poDetails(data)"><i
                            class='fa fa-file-download'></i></button>
                            <button aria-hidden="true" class="btn btn-primary btn-xs ml-2" data-original-title="Edit Order"
                            title="Repeat Order" data-toggle="tooltip"data-placement="top" (click)="reOrder(data)"><i
                            class='fa fa-repeat'></i></button>
                            <button aria-hidden="true" class="btn btn-success btn-xs ml-2"data-original-title="Batch Details"
                            title="Batch Details" data-toggle="tooltip" data-placement="top" (click)="getBatchList(data)" *ngIf="data?.status=='PARTLYDELIVER'"><i
                            class='fa fa-sitemap'></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="currentOrd?.length == 0 || currentOrd?.length == 0">
                <div class="no-data-found"></div>
            </div>
            <div *ngIf="currentOrd?.length > 10">
                <div class="text-right mt-2">
                    <ul class="pagination">
                        <pagination-controls autoHide="true" id="p1" (pageChange)="p1=$event"></pagination-controls>
                    </ul>
                </div>
            </div>
        </div>

        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <table class="table table-bordered table-hover table-styled table-striped">
                <thead>
                    <tr role="row">
                        <th>Order Id</th>
                        <th>Order Date</th>
                        <th>Delivery Date</th>
                        <th>Expected Delivery Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of prevOrd | paginate: { id: 'p2', itemsPerPage: 10, currentPage: p2 }"
                    [ngClass]="{'bg-danger-light': isExpectedDateNotMet(data), 'bg-light': isExpectedDateMet(data)}">
                        <td>{{data?.supplierordersId}}</td>
                        <!-- <td>
                            <a *ngIf="data?.status == 'PARTLYDELIVER' || data?.status == 'ORDERDELIVER'" href="javascript:void(0)" (click)="showorderBatchInformation(data)">{{data?.itemName}}</a>

                            <span *ngIf="data?.status == 'CANCLEORDER'">{{data?.itemName}}</span>
                        </td> -->
                        <!-- <td>{{data?.totalStockOrdered}}</td>
                        <td>{{data?.totalPrice}}</td> -->
                        <td>{{data?.dateOrdered | date: 'dd-MMM-yyyy'}}</td>
                        <td>{{data?.deliveredDate | date: 'dd-MMM-yyyy'}}</td>
                        <td>{{data?.expectedDeliveryDate | date: 'dd-MMM-yyyy'}}</td>
                        <!-- <td [ngStyle]="{'color': isExpectedDatePassed(data?.expectedDeliveryDate) ? 'red' : 'black'}"></td> -->
                        <td>
                            <!-- <span *ngIf="data?.status == 'ORDERPLACED'">Placed</span>
                            <span *ngIf="data?.status == 'PARTLYDELIVER'">Partial Delivered</span>
                            <span *ngIf="data?.status == 'ORDERDELIVER'">Delivered</span>
                            <span *ngIf="data?.status == 'CANCLEORDER'">Cancelled</span> -->
                            <button aria-hidden="true" class="btn btn-success btn-xs ml-2" data-original-title="PO Details"
                            title="Order Details" data-toggle="tooltip" data-placement="top" (click)="poDetails(data)"><i
                            class='fa fa-file-download'></i></button>
                            <button aria-hidden="true" class="btn btn-primary btn-xs ml-2" data-original-title="Repeat Order"
                            title="Repeat Order" data-toggle="tooltip"data-placement="top" (click)="reOrder(data)"><i
                            class='fa fa-repeat'></i></button>
                            <button aria-hidden="true" class="btn btn-success btn-xs ml-2"data-original-title="Batch Details"
                            title="Batch Details" data-toggle="tooltip" data-placement="top" (click)="getBatchList(data)"><i
                            class='fa fa-sitemap'></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="prevOrd?.length == 0 || prevOrd?.length == null">
                <div class="no-data-found"></div>
            </div>
            <div *ngIf="prevOrd?.length > 10">
                <div class="text-right mt-2">
                    <ul class="pagination">
                        <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
                    </ul>
                </div>
            </div>
        </div>

        <div class="tab-pane fade" id="pills-deals" role="tabpanel" aria-labelledby="pills-deals-tab">
            <table class="table table-bordered table-hover table-styled table-striped">
                <thead>
                    <tr role="row">
                        <th>Supplier</th>
                        <th>Category</th>
                        <th>Item Name</th>
                        <th>Unit Price</th>
                        <th>Deal</th>
                        <th>Deal From Date</th>
                        <th>Deal To Date</th>
                        <th>Buy Quantity</th>
                        <th>Free Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of activeDealsInfo | paginate: { id: 'p4', itemsPerPage: 10, currentPage: p4 }">
                        <td>{{data?.supplierName}}</td>
                        <td>{{data?.catagory}}</td>
                        <td>{{data?.name}}</td>
                        <td>{{data?.unitprice}}</td>
                        <td>{{data?.deal}}</td>
                        <td>{{data?.dealFromTime | date: 'dd-MMM-yyyy'}}</td>
                        <td>{{data?.dealToTime | date: 'dd-MMM-yyyy'}}</td>
                        <td>{{data?.buyQuantity}}</td>
                        <td>{{data?.freeQuantity}}</td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="activeDealsInfo?.length == 0 || activeDealsInfo?.length == null">
                <div class="no-data-found"></div>
            </div>
            <div *ngIf="activeDealsInfo?.length > 10">
                <div class="text-right mt-2">
                    <ul class="pagination">
                        <pagination-controls autoHide="true" id="p4" (pageChange)="p4=$event"></pagination-controls>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</div>