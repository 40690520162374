import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';


@Injectable({
  providedIn: 'root'
})
export class GenericValidationService {

  constructor() { }

  validate(control: AbstractControl, validationTypes: string[], minlength?: number, maxlength?: number,minLength?: number, maxLength?: number, min?: number, max?: number): { [key: string]: any } | null {
    // console.log(validationTypes)
    let errors: { [key: string]: any } = {};
    for (const type of validationTypes) {
      let error = null;
      switch (type) {
        case 'required':
          error = Validators.required(control);
          break;
        case 'email':
          error = Validators.email(control);
          break;
        case 'numberonly':
          error = Validators.pattern('^[0-9]*$')(control);
          break;
        case 'minLength':
          error = Validators.minLength(minLength)(control);
          break;
        case 'maxLength':
          error = Validators.maxLength(maxLength)(control);
          break;
        case 'minlength':
          error = Validators.minLength(minlength)(control);
          break;
        case 'maxlength':
          error = Validators.maxLength(maxlength)(control);
          break;
        case 'min':
          error = Validators.min(min)(control);
          break;
        case 'max':
          error = Validators.max(max)(control);
          break;
      }
      if (error) {
        errors = { ...errors, ...error };
      }
    }
    return Object.keys(errors).length > 0 ? errors : null;
  }

  getValidationMessage(errors: any, validationType: string): string | null {
    // console.log(errors,validationType)
    if (validationType === 'required' && errors.required) {
      return 'Field is required';
    } else if (validationType === 'numberonly' && errors.numberonly) {
      return 'Only numbers are allowed';
    } else if (validationType === 'minlength' && errors.minlength) {
      return `Minimum length is ${errors.minlength.requiredLength} characters`;
    } else if (validationType === 'maxlength' && errors.maxlength) {
      return `Maximum length is ${errors.maxlength.requiredLength} characters`;
    } else if (validationType === 'email' && errors.email) {
      return 'Invalid email address';
    } else if (validationType === 'min' && errors.min) {
      return `Value must be greater than or equal to ${errors.min.min}`;
    } else if (validationType === 'max' && errors.max.max) {
      return `Value must be less than or equal to ${errors.max.max}`;
    }
    return null;
  }
}
