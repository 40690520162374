import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';

@Component({
  selector: 'app-discount-request-info',
  templateUrl: './discount-request-info.component.html',
  styleUrls: ['./discount-request-info.component.css']
})
export class DiscountRequestInfoComponent implements OnInit {
  @Input() info:any;discountInfoForm:FormGroup
  constructor(private globalStorage: GlobalStorageService, private clinicServices: ClinicService,private toastr: ToastrService,public activeModal:NgbActiveModal,private formBuilder:FormBuilder,) { }

  ngOnInit(): void {
    this.discountInfoForm=this.formBuilder.group({
      "patientName":[''],
      "netAmount":[''],
      "requestAmount":[''],
      "approverName":['']
    })
    this.getDiscountInfo()
  }
  discountDetails
  getDiscountInfo(){
    this.clinicServices.getDiscountDetailsByDiscountReqId(this.info?.discountRequestId,parseInt(this.globalStorage.getItem('hospitalAddressId'))).subscribe(res=>{
      this.discountDetails=res['data']
      if(res['data']){
        var requestAmount
        if(this.discountDetails?.amount==0){
          requestAmount=this.discountDetails?.requestedAmount
        }
        else{
          requestAmount=this.discountDetails?.amount
        }
        this.discountInfoForm.patchValue({
          patientName:this.discountDetails?.patientName,
          netAmount:this.discountDetails?.netAmount,
          requestAmount:requestAmount,
          approverName:this.discountDetails?.approverName
        })
      }
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
}
