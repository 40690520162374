import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';
import { CreateOrdersComponent } from '../../inventory/create-orders/create-orders.component';
import { SaveSupplierComponent } from '../save-supplier/save-supplier.component';
import { AddSubcatAtSupplieritemsComponent } from '../add-subcat-at-supplieritems/add-subcat-at-supplieritems.component';
import * as moment from 'moment';
import { OrderBatchInformationComponent } from '../order-batch-information/order-batch-information.component';
import { SaveBulkOrderComponent } from '../save-bulk-order/save-bulk-order.component';
import { CurrentBatchInfoComponent } from '../current-batch-info/current-batch-info.component';
import { BulkOrderDeliveryComponent } from '../bulk-order-delivery/bulk-order-delivery.component';

@Component({
  selector: 'app-currentor-previous-inv-orders',
  templateUrl: './currentor-previous-inv-orders.component.html',
  styleUrls: ['./currentor-previous-inv-orders.component.css']
})
export class CurrentorPreviousInvOrdersComponent implements OnInit {

  @Input() supplierInfo;
  @Input() addressId;
  @Input() service;
  @Input() requestFromOrder;
  p1 = 1; p2 = 1; p3 = 1; p4 = 1;
  constructor(private globalStorage: GlobalStorageService, 
    private activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private inventoryService: InventoryService
  ) { }

  ngOnInit(): void {
    this.fetchSuppliercatgeries();
  }

  closeModal() {
    this.activeModal.close(true);
  }

  categoriesData;
  fetchSuppliercatgeries() {
    this.inventoryService.getSuppliercatgeryBySupplierId(this.supplierInfo?.supplierId).subscribe(res => {
      this.categoriesData = res?.data || [];
      this.categoriesData.map(res => {
        res['catagoryId']=res?.categryId
        res['isChecked'] = false;
      });

      this.p3 = 1;
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  openPlaceOrder(data) {
    var sendingData=[]
    sendingData[0]=data
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'right hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-left hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-80';
    }
    // const modalRef = this.modalService.open(CreateOrdersComponent, {
    //   centered: false,
    //   backdrop: 'static',
    //   keyboard: false,
    //   windowClass: wClass
    // });
    // modalRef.componentInstance.assignAssociatedItems = true;
    // modalRef.componentInstance.associatedItems = data;
    // modalRef.componentInstance.addressId = this.addressId;
    const modalRef = this.modalService.open(SaveBulkOrderComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.componentInstance.assignAssociatedItems = true;
    modalRef.componentInstance.purchaseItems = sendingData;
    modalRef.componentInstance.supplieId=data?.supplierId
    modalRef.componentInstance.addressId =data?.addressId;
  }

  subCategoryAdd(supplierInfo) {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-left hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-40';
    }
    const modalRef = this.modalService.open(AddSubcatAtSupplieritemsComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.supplierInfoObj = supplierInfo;
    modalRef.componentInstance.service = this.service;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.fetchSuppliercatgeries();
        }
      },
      (reason) => {
        if (reason) {
          this.fetchSuppliercatgeries();
        }
      }
    );
  }

  openSaveSupplier(object) {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-left hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-40';
    }
    const modalRef = this.modalService.open(SaveSupplierComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.componentInstance.assignTime = true;
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.supplier = this.supplierInfo;
    modalRef.componentInstance.supplierCategory = object;
    modalRef.componentInstance.service = this.service;
  }

  activateSubCategory(data) {
    let suppliercatgeryId = data?.suppliercatgeryId;
    this.inventoryService.ActiveAssignSupplier(this.addressId, suppliercatgeryId).subscribe(res => {
      this.toaster.success('Supplier Details Activated.')
      this.fetchSuppliercatgeries();
    }, error => {
      this.toaster.error(error?.['error']?.['message'])
    }
    )
  }

  deactivateSubCatagory(data) {
    let suppliercatgeryId = data?.suppliercatgeryId;
    this.inventoryService.deleteAssignSupplier(this.addressId, suppliercatgeryId).subscribe(res => {
      this.toaster.success('Supplier Details Deactivated.');
      this.fetchSuppliercatgeries();
    }, error => {
      this.toaster.error(error?.['error']?.['message'])
    }
    )
  }

  currentOrd;
  currentOrders() {
    this.inventoryService.currentOrdersList(this.supplierInfo?.supplierId).subscribe(res => {
      this.currentOrd = res?.['data'] || [];
      this.p1 = 1;
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  prevOrd;
  previousOrders() {
    this.inventoryService.previousOrdersList(this.supplierInfo?.supplierId).subscribe(res => {
      this.prevOrd = res?.['data'] || [];
      this.p2 = 1;
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  isExpectedDatePassed(expectedDate): boolean {
    return expectedDate < moment().format('YYYY-MM-DDTHH:MM:SS');
  }

  isExpectedDateNotMet(order): boolean {
    const currentDate = moment().format('YYYY-MM-DDTHH:MM:SS');
    return order.expectedDeliveryDate < currentDate &&
           order.dateOrdered > order.expectedDeliveryDate &&
           order.deliveredDate > order.expectedDeliveryDate;
  }
  
  isExpectedDateMet(order): boolean {
    const currentDate = moment().format('YYYY-MM-DDTHH:MM:SS');
    return order.expectedDeliveryDate >= currentDate ||
           order.dateOrdered <= order.expectedDeliveryDate ||
           order.deliveredDate <= order.expectedDeliveryDate;
  }

  showorderBatchInformation(data) {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'right hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-left hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-80';
    }
    const modalRef = this.modalService.open(OrderBatchInformationComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.ordersData = data;
  }

  activeDealsInfo;
  getActiveDeals() {
    let body = {
      "addressId": this.addressId,
      "supplierId": this.supplierInfo?.supplierId
    }
    this.inventoryService.activeDeals(body).subscribe(res => {
      this.activeDealsInfo = res?.['data'];
      this.p4 = 1;
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

  // selectItemOncheck(data, isChecked: boolean){
  //   this.isButtonDisabled = false;
  //   var obj = {
  //     "category": data?.catagoryname,
  //     "subCategory": data?.itemTypeName,
  //     "itemName" : data?.itemTypeName,
  //     "deal":data?.deal,
  //     "unitPrice":data?.unitPrice,
  //     "cgst": data?.cgst ? data?.cgst : null,
  //     "sgst": data?.sgst ? data?.sgst : null,
  //     "igst":data?.igst ? data?.igst : null,
  //     "discount":data?.discount,
  //     "freeQuantity":data?.freeQuantity ? data?.freeQuantity : null,
  //     "buyQuantity":data?.buyQuantity ? data?.buyQuantity : null
  //   }
  //   if(isChecked){
  //     this.selectedInvItems.push(obj);
  //     // console.log('selected items:', this.selectedInvItems);
  //   } 
  //   else {
  //     let selectedObjIndex = this.selectedInvItems.indexOf(obj);
  //     this.selectedInvItems.splice(selectedObjIndex, 1);
  //     // console.log('un select:', this.selectedInvItems);
  //     if(this.selectedInvItems?.length == 0){
  //       this.isButtonDisabled = true;
  //     } 
  //   }   
  // }

  isButtonDisabled: boolean = true;
  selectedInvItems = [];
  openCreatePO() {
    this.selectedInvItems = this.categoriesData.filter(res => res.isChecked);
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'right hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-left hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-80';
    }
    const modalRef = this.modalService.open(SaveBulkOrderComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.componentInstance.purchaseItems = this.selectedInvItems;
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.supplieId=this.supplierInfo?.supplierId
    this.closeModal();
  }

  delivaryItems(o){
    if (o?.isMultipleOrder == "Y") {
      let wclass = 'right'
      if (this.globalStorage.getItem('loggedInAs') == 'IVF') {
        wclass = 'right w-90'
      }
      if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
        wclass = 'modal-left hw-90';
      }
      
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wclass = 'modal-left hw-90';
    }
      const modalRef = this.modalService.open(BulkOrderDeliveryComponent, {
        centered: false,
        backdrop: 'static',
        keyboard: false,
        windowClass: wclass
      });
      modalRef.componentInstance.deliverOrder = o;
      modalRef.componentInstance.addressId = this.addressId;
      modalRef.result.then(
  
      )}

  }
  poDetails(data){
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'left hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-right hw-80';
    }

    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-right hw-80';
    }
    // const modalRef = this.modalService.open(SupplierOrderInvoiceComponent, {
    //   centered: false,
    //   backdrop: 'static',
    //   keyboard: false,
    //   windowClass: wClass
    // })
    // modalRef.componentInstance.supplierOrder = data;
    const modalRef = this.modalService.open(SaveBulkOrderComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
   // modalRef.componentInstance.purchaseItems = this.selectedInvItems;
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.mode='ORDERVIEW'
    modalRef.componentInstance.supplierOrderId=data?.supplierordersId
    modalRef.componentInstance.supplieId=data?.supplierId
  }
  reOrder(data){
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'left hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-left hw-80';
    }
    const modalRef = this.modalService.open(SaveBulkOrderComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
   // modalRef.componentInstance.purchaseItems = this.selectedInvItems;
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.mode='REORDER'
    modalRef.componentInstance.supplierOrderId=data?.supplierordersId
    modalRef.componentInstance.supplieId=data?.supplierId
  }
  getBatchList(data){
    let wClass = 'left';
    if(this.globalStorage.getItem("loggedInAs")=='IVF'){
      wClass='left hw-60';
    }
    if(this.globalStorage.getItem("loggedInAs")=='HOSPITAL'){
      wClass='modal-right hw-60';
    }
    if(this.globalStorage.getItem("loggedInAs")=='PHARMACY'){
      wClass='modal-right hw-60';
    }
    const modalRef = this.modalService.open(OrderBatchInformationComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    })
    modalRef.componentInstance.through='SUPPLIERLEVEL'
    modalRef.componentInstance.ordersData = data;
  }
  editOrder(data){
    let wClass = 'left';
    if(this.globalStorage.getItem("loggedInAs")=='IVF'){
      wClass='left hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-left hw-80';
    }
    const modalRef = this.modalService.open(SaveBulkOrderComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
   // modalRef.componentInstance.purchaseItems = this.selectedInvItems;
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.mode='ORDEREDIT'
    modalRef.componentInstance.supplierOrderId=data?.supplierordersId
    modalRef.componentInstance.supplieId=data?.supplierId
  }
}
