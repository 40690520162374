import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router, NavigationExtras } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DiagnosticService } from 'src/app/AppServices/diagnostic.service';

import { AuthService } from 'src/app/shared/services/auth.service';
import { gpsService } from 'src/app/shared/services/gps.service';
import { PatientService } from 'src/app/shared/services/patient.service';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import { MustMatch } from 'src/app/_helpers/must_match.validators'
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
declare var $: any;
@Component({
  selector: 'app-diagnostic-registration',
  templateUrl: './diagnostic-registration.component.html',
  styleUrls: ['./diagnostic-registration.component.css']
})
export class DiagnosticRegistrationComponent implements OnInit {
  minDate
  maxDate
  registerForm: FormGroup;
  isExist = false
  registerForms: FormGroup;
  loading = false;
  submitted = false;
  cond_vall = false
  invalidCredentials = false; selectedValue; postalLocations;
  successCredentials = false; searchTxt;
  isEmailExists = false
  isEmailExistsMessage
  isUserNameExists = false
  isUserNameExistsMessage
  isPhoneNumberExists = false
  isPhoneNumberExistsMessage
  message
  telOptions = { initialCountry: 'us' }
  constructor(
    private formBuilder: FormBuilder, private patientService: PatientService,
    private diagnosticService: DiagnosticService, private gps: gpsService,
    private script: ScriptsService,
    private router: Router, private ToastrService: ToastrService) {
    var d = new Date();
    this.minDate = new Date(d);
    this.maxDate = new Date(d);
  }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      diagnosticName: ['', [Validators.required, Validators.pattern("^[\.a-zA-Z& ]*$"), Validators.minLength(3), Validators.maxLength(40)]],
      licencenumber: ['', Validators.required],
      firstName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      middleName: ['',],
      gstin: ['', Validators.required],
      userName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      //officeNumber:['', Validators.required],
      contactNum: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      password: ['', [Validators.required, Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)*\\S{8,15})')]],
      confirmPassword: ['', [Validators.required]],
      primaryContact: ['', Validators.required],
      primaryContactNumber: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      longitude: [],
      latitude: [],
      dateOfExpiry: ['', Validators.required],
      postalLocation: ['', Validators.required],
    },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
    var input = document.querySelector("#telephone");
    jQuery(document).ready(function ($) {
      (<any>window).intlTelInputGlobals.loadUtils("./../../../assets/build/js/utils.js");

      if (jQuery('#telephone').length) {
        var input = document.querySelector("#telephone");
        (<any>window).intlTelInput(input, {
          preferredCountries: ['in'],
          separateDialCode: true, placeholderNumberType: "MOBILE",
          autoPlaceholder: "polite", autoHideDialCode: true,
          hiddenInput: "",

          customPlaceholder: null,
          formatOnDisplay: true,

        });
      }

    });
    input.addEventListener("countrychange", function () {
      (<any>input).intlTelInput();
      (<any>input).val('')
    });
    var input1 = document.querySelector("#telephone1");
    jQuery(document).ready(function ($) {
      (<any>window).intlTelInputGlobals.loadUtils("./../../../assets/build/js/utils.js");

      if (jQuery('#telephone1').length) {
        var input1 = document.querySelector("#telephone1");
        (<any>window).intlTelInput(input1, {
          preferredCountries: ['in'],
          separateDialCode: true, placeholderNumberType: "MOBILE",
          autoPlaceholder: "polite", autoHideDialCode: true,
          hiddenInput: "",

          customPlaceholder: null,
          formatOnDisplay: true,

        });
      }

    });
    input1.addEventListener("countrychange", function () {
      (<any>input1).intlTelInput();
      (<any>input1).val('')
    });
  }
  isUserNameExist = false
  checkUserName() {
    this.invalidCredentials = false
    if (this.f.userName.errors == null) {
      this.isExist = false
      this.diagnosticService.existingUsername(this.registerForm.controls['userName'].value).subscribe(res => {
        if (res.data) {
          this.invalidCredentials = true
          // this.message = res?.message
          this.isUserNameExists = true
          //this.message = res?.message
          this.isUserNameExistsMessage = res?.message
          //this.ToastrService.error("This email already exists");
          this.isExist = true
          this.submitted = false;
          this.isUserNameExist = true
          return
        }
        else {
          this.isUserNameExist = false
          this.isUserNameExists = false
        }
      }, (error) => {
        this.ToastrService.error((error['error']['message']))

      });
    }
  }

  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z\_]+$/);
    return pattern.test(value);
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  onSubmit(item: any) {
    this.submitted = true;
    this.checkEmail()
    this.checkUserName()
    this.checkPhoneNumber(this.registerForm.value.contactNum)
    if (this.registerForm.invalid) {
      return;
    }
    if (this.isExist) {
      return;
    }
    var number = $('.telephone').html()
    var str = "";
    $(number).find("div[class^='iti__selected-dial-code']").each(function () {
      str += $(this).text();
    })
    var finaNumber = str
    var newRegistration = {

      "details": "test",
      "name": this.registerForm.value.diagnosticName,
      "newlyRegistered": "test",
      "dateOfExpiry": this.registerForm.value.dateOfExpiry,
      "primaryDataCenterAddress": {
        "address1": this.registerForm.value.address1,
        "address2": this.registerForm.value.address2,
        "city": this.registerForm.value.city,
        "country": this.registerForm.value.country,
        "gstin": this.registerForm.value.gstin,
        "isPrimary": "Y",
        "state": this.registerForm.value.state,
        "zip": this.registerForm.value.zip,
        "postalLocation": this.registerForm.value.postalLocation?.postalLocation,
        "latitude": this.latitude,
        "longitude": this.longitude,
      },
      "primaryDiagnosticcenteruser": {
        "cellNumber": finaNumber + this.registerForm.value.contactNum,
        //"diagnosticCenterAddressId": 0,
        "diagnosticName": this.registerForm.value.diagnosticName,
        "emailId": this.registerForm.value.email,
        "firstName": this.registerForm.value.firstName,
        "gstInr": this.registerForm.value.gstInr,
        "homeNumber": "test",
        "lastName": this.registerForm.value.lastName,
        "licencenumber": this.registerForm.value.licencenumber,
        "middleName": this.registerForm.value.middleName,
        // "officeNumber":this.registerForm.value.contactNum,
        "password": this.registerForm.value.password,
        "primaryContact": "HOME_NUMBER",
        "userName": this.registerForm.value.userName
      },
      "cellNumber": finaNumber + this.registerForm.value.contactNum
    }

    if (this.isPhoneNumberExist == false && this.isUserNameExist == false && this.isEmailExist == false) {
      this.diagnosticService.registerDiagnostic(newRegistration).subscribe(res => {
        this.successCredentials = true
        this.message = res['message']
        const navigationExtras: NavigationExtras = {
          state: {
            emailId: this.registerForm.value.email,
            mobileNumber: finaNumber + this.registerForm.value['contactNum'],
            userName: this.registerForm.value.userName,
          }
        };
        this.router.navigate(['/otp-verification-diagnosticcenter'], navigationExtras)
        //this.ToastrService.success("Registered successfully");
      }, error => {
        this.invalidCredentials = true
        this.message = error['error']['message']
        // this.ToastrService.error("Error in saving registration");
      })
    }
  }
  charOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) || charCode == 32) {
      return true;
    }
    return false;
  }
  numberOnly(event, phn) {
    const charCode = (event.which) ? event.which : event.keyCode;

  }

  numberOnlyForZip(event): boolean {
    const charCode = event.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    else return true;
  }

  keyPressAlphaNumeric(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  isEmailExist = false
  checkEmail() {
    this.invalidCredentials = false
    if (this.f.email.errors == null) {
      this.isExist = false
      this.diagnosticService.existingEmail(this.registerForm.controls['email'].value).subscribe(res => {
        if (res.data == true) {
          this.invalidCredentials = true
          // this.message = res?.message
          this.isEmailExists = true
          this.isEmailExistsMessage = res?.message
          //this.ToastrService.error("This email already exists");
          this.isExist = true
          this.submitted = false;
          this.isEmailExist = true
          return
        }
        else {
          this.isEmailExist = false
          this.isEmailExists = false
        }
      }, (error) => {
        this.ToastrService.error((error['error']['message']))

      });
    }
  }
  isPhoneNumberExist = false
  checkPhoneNumber(num) {
    var number = $('.telephone').html()
    var str = "";
    $(number).find("div[class^='iti__selected-dial-code']").each(function () {
      str += $(this).text();
    })
    var finaNumber = str + num;
    this.invalidCredentials = false
    if (num.length == 10) {
      this.diagnosticService.existingPhone(finaNumber.replace(/\+/gi, '%2B')).subscribe(res => {
        if (res['data'] == true) {
          this.invalidCredentials = true
          this.isPhoneNumberExists = true
          // this.message = res?.message
          this.isPhoneNumberExistsMessage = res?.['message']
          // this.message = res?.['message']
          this.isPhoneNumberExist = true
          return
        }
        else {
          this.isPhoneNumberExist = false
          this.isPhoneNumberExists = false
        }
      }, (error) => {
        this.message = error['error']['message']
      });
    }
  }
  // checkPharmacymail(){
  //   if(this.f.email.errors==null){
  //     this.diagnosticService.existingEmail(this.registerForm.controls['email'].value).subscribe(res=>{
  //       if(res.data==true){
  //         this.toastr.error("This email already exists");
  //         this.submitted=false;
  //         return
  //       }
  //   },(error)=>{
  //     this.toastr.error((error['error']['message']))

  //   });
  //   }
  // }

  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });

    $("#show_hide_password1 a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password1 input').attr("type") == "text") {
        $('#show_hide_password1 input').attr('type', 'password');
        $('#show_hide_password1 i').addClass("fa-eye-slash");
        $('#show_hide_password1 i').removeClass("fa-eye");
      } else if ($('#show_hide_password1 input').attr("type") == "password") {
        $('#show_hide_password1 input').attr('type', 'text');
        $('#show_hide_password1 i').removeClass("fa-eye-slash");
        $('#show_hide_password1 i').addClass("fa-eye");
      }
    });
  }
  latitude; longitude
  drpdownSelection(e) {
    this.latitude = e?.latitude
    this.longitude = e?.longitude
  }
  getZip(Zip) {
    this.patientService.getAddressAll(Zip).subscribe(res => {
      if (res['result'].length == 0) {
        this.registerForm.patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.ToastrService.error("Incorrect pincode")
      }
      this.postalLocations = res['result']
      this.registerForm.patchValue({
        country: res['result'][0]['country'],
        city: res['result'][0]['province'],
        state: res['result'][0]['state'],

      })
    })
  }
  zip1; countryCodeSelected
  perChanged(zip) {
    if (zip.length == 6) {
      this.zip1 = zip;
      this.countryCodeSelected = null;
      this.registerForm.patchValue({
        country: '',
        city: '',
        state: ''
      });
    }
  }
  countryCodeEntered(cc) {
    this.selectedValue = null;
    this.patientService.getPincodeDetails(this.zip1, cc).subscribe(res => {
      if (res['data'].length == 0) {
        this.registerForm.patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.ToastrService.error("Incorrect pincode")
      }
      this.postalLocations = res['data'];
      this.latitude = res['data'][0]['latitude'];
      this.longitude = res['data'][0]['longitude'];
      this.registerForm.patchValue({
        country: res['data'][0]['country'],
        city: res['data'][0]['province'],
        state: res['data'][0]['state'],
        latitude: res['data'][0]['latitude'],
        longitude: res['data'][0]['longitude']

      })
    })
  }

}
