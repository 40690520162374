<div class="modal-header">
    <h4 class="modal-title"><i class="fa-solid fa-user-lock mr-2"></i>Register Patient</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body p-4 pt-0">
  
  <form [formGroup]="shortReg">
  
        <div class="row mt-2 pr-0">
          <div class="col-md-6">
            <label class="form-label">Mobile Number <span class="text-danger">*</span></label>
            <input #cellInput type="text" ng2TelInput [appUnifiedValidation]="['required','minlength']" [minlength]="10"
              maxlength="10"
              [ng2TelInputOptions]="{autoHideDialCode: false, initialCountry: 'in', separateDialCode:true,formatOnDisplay:false}"
              class="form-control" appInputRestrict="mobileNumber" formControlName="cellNumber"
              placeholder="Enter mobile number" />
          </div>
          <div class="col-md-6 pr-0">
            <label class="form-label">Email ID</label>
            <input type="email" (focusout)="checkEmail()" placeholder="Enter Email" [appUnifiedValidation]="['email']"
              formControlName="emailId" class="form-control no-caps" />
          </div>
        </div>
        <div class="card-bar p-1 mb-0 mt-4" *ngIf="patientSearched && patientList">
          <h4 class="card-header text-white bg-primary py-2 m-0"> Primary
            Details </h4>
        </div>
         <div class="row mt-4 pr-0" *ngIf="patientSearched && patientList">
  
            
            <div class="row">

          <div class="col-md-4 mb-2">
            <label class="form-label">First Name<span class="text-danger">*</span></label>
            <input appAppTitleCase type="text" [appUnifiedValidation]="['required']"
              appInputRestrict="alphaOnlyWithSpaces" formControlName="firstName" class="form-control" />
          </div>
          <div class="col-md-4">
            <label class="form-label">Last Name<span class="text-danger">*</span></label>
            <input appAppTitleCase type="text" [appUnifiedValidation]="['required']"
              appInputRestrict="alphaOnlyWithSpaces" formControlName="lastName" class="form-control" />
          </div>
  
          <div class="col-md-4">
            <label class="form-label">Gender <span class="text-danger">*</span></label>
            <mat-select formControlName="gender"  [appUnifiedValidation]="['required']"
              class="form-control">
              <mat-option *ngFor="let option of genderArray" [value]="option">{{option}}</mat-option>
            </mat-select>
          </div>
  
  
          <div class="col-md-4 form-group">
  
            <div class="row">
              <label class="width-100 ml-1">DOB <span class="font-size-10 text-muted">(MM/DD/YYYY)</span><span
                  class="text-danger">*</span></label>
  
              <div class="col-md-12 col-xs-12 pr-0">
                <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker" (dateChange)="calculateAge($event,true)" [matDatepicker]="picker" 
                  [appUnifiedValidation]="['required']" class="form-control mb-0" formControlName="dob"
                  datePickerFormat="MMMM Do YYYY">
              </div>
              <div class="col-md-1 col-xs-2" style="margin-top: -5px;margin-left: -55px;">
  
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
  
              </div>
            </div>
          </div>
  
  
          <div class="col-md-4">
            <label class="form-labe">Age</label>
            <input type="number" (focusout)="updateDOB($event.target.value,true)" [(ngModel)]="primaryAge" class="form-control"
              formControlName="primaryAge" />
          </div>
  
  
         
      </div> 
      </div>
    </form> 
 
 
    <div class="card-bar p-1 mb-0 mt-2" *ngIf="patientList && patientSearched">
      <h4 class="card-header text-white bg-primary py-2 m-0">
        Address </h4>
    </div>
    <div class="row mt-4"  *ngIf="patientList && patientSearched">
      <form [formGroup]="shortReg" >
        <div class="row"> 
          <div class="col-md-4 mb-2">
            <label class="form-labe">Country</label>
            <mat-select formControlName="country" class="form-control">
              <mat-option *ngFor="let option of countryArray" [value]="option">{{option}}</mat-option>
            </mat-select>
          </div> 
  
          <div class="col-md-4">
            <label class="form-labe">Zip</label>
            <input type="text" placeholder="Enter Zip/Pin Code" appInputRestrict="numberOnly" (focusout)="getPostalCode()"
              maxlength="6" formControlName="zip" class="form-control" />
          </div>

         
          <div class="col-md-4">
            <label class="form-labe">Postal Location</label>
            <mat-select placeholder="Select" formControlName="postalLocation" class="form-control">
              <mat-option *ngFor="let option of postalLocation"
                [value]="option.postalLocation">{{option.postalLocation}}</mat-option>
            </mat-select>
  
          </div>
          <div class="col-md-4">
            <label class="form-label">Street</label>
            <input type="text" formControlName="street" class="form-control" />
          </div>
         
          <div class="col-md-4">
            <label class="form-label">City</label>
            <input type="text" formControlName="city" class="form-control" />
          </div>
  
          <div class="col-md-4">
            <label class="form-label">State</label>
            <input type="text" formControlName="state" class="form-control" readonly />
          </div>
  
  
        </div>
      </form>

    </div>

    <div class="card-bar p-1 mb-0 mt-2" *ngIf="cardsShow && patientList.length>1">
      <h4 class="card-header text-white bg-primary py-2 m-0">
        Users Found
      </h4>
    </div> 
    

      <div class="row pb-2 pr-2  mt-2 d-flex justify-content-center" *ngIf="cardsShow && patientList.length>1">
        <div class="col-xl-3 mb-2 mr-4 mt-2 box-body pad-r-tigger card cursor-pointer" *ngFor="let patient of patientList"
         (click)="patchPatientData(patient)" >
          <div class="boxdesign  card-body shadow_patient">
            <div class="d-flex pl-3  pt-2 pb-1 ">
              <div class="patenttext">
                <h4> {{patient?.firstName}} {{patient?.lastName}} </h4>
                <p class="colp"><b class="bcolor">
                    Gender:</b>{{patient?.gender}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  
  
    <div class="col-lg-12 text-right mt-4" *ngIf="patientList && patientSearched">
      <button class="btn btn-success float-right" (click)=saveData() [disabled]="!shortReg.valid">Save</button>
    </div>
    <!-- <div *ngIf=" patientList.length>1   ">
      <button class="btn btn-primary float-right mr-4"  (click)="backToPatient()">Back</button>

    </div> -->

  </div>