import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClinicOpdPrescriptionsComponent } from 'src/app/clinic-pages/clinic-opd-prescriptions/clinic-opd-prescriptions.component';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
declare var $: any;


@Component({
  selector: 'app-patient-prescrtions',
  templateUrl: './patient-prescrtions.component.html',
  styleUrls: ['./patient-prescrtions.component.css']
})
export class PatientPrescrtionsComponent implements OnInit {
  @Input() prescriptions:any
  @Input() userId;
  p3=1;p4=1;p2=1
  medicalReportsData=[];medications=[];
  constructor(private controller: PatientScheduleControlService,private toastrService: ToastrService,private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getPrescriptions()
  }
  totalResults
    getPrescriptions(){
      var req = {
        "forUserId": this.userId,
        "userId": this.userId
      }
      this.controller.getPrescriptionPostSession('prescription/getprecriptionbyuserId/' + this.p2 + '/21/' + this.userId, req, this.userId).subscribe(res => {
        console.log('patient Prescriptions',res)
        // this.patientController.postGlobal('prescription/getallprescription',this.userId).subscribe(res=>{
        this.medications = res['data']
        this.totalResults=res['totalResults']
        // this.medicalReportsData=this.medications.filter(obj=>obj?.containsMedicalPrescription=='Y')
        // this.medications=this.medications.filter(obj=>obj?.containsTestPrescription=='Y')
        //this.medications =this.medications.filter(obj=>obj.prescriptionFill == 'N')
      }, error => {
        this.toastrService.error(error['error']['message'])
      })
    }
  
  getClass(id){

  }
  onPageChange(event: number): void {
    this.p2 = event;
  }
  medicalPrescriptionTab(){
    $('.presc').addClass('active show')
    $('.labts').removeClass('active show')
  }
  medicalTestsTab(){
    $('.presc').removeClass('active show')
    $('.labts').addClass('active show')
  }
  viewTestPrescription(reports){
    console.log('Test',reports)
    const modal=this.modalService.open(ClinicOpdPrescriptionsComponent,{
      centered: false,
      windowClass: 'modal-left hw-40',
      backdrop: 'static',
      keyboard: false
    });
    modal.componentInstance.prescription = reports;
    modal.result.then(
      (result) => {
        if (result) {
        }
      },
      (reason) => {
        // Handle dismissal of modal
      }
    );
  }
  viewPrescription(mediations){
    console.log('prescription',mediations)
    const modal=this.modalService.open(ClinicOpdPrescriptionsComponent,{
      centered: false,
      windowClass: 'modal-left hw-40',
      backdrop: 'static',
      keyboard: false
    });
    modal.componentInstance.prescription = mediations;
    modal.result.then(
      (result) => {
        if (result) {
        }
      },
      (reason) => {
        // Handle dismissal of modal
      }
    );
  }

}
