<!--
<h3>Search Appointments</h3> -->
<div>

  <div [formGroup]="frmAdvanceSearch" class="row">
    <div class="col-md-2">
      <div class="form-group">
          <label for="exampleInputEmail1">Ailment </label>
          <input type="text" class="form-control" placeholder="Ailment" formControlName="ailment">
      </div>
  </div>

    <div class="col-md-2">
        <div class="form-group">
            <label for="exampleInputEmail1">Name </label>
            <input type="text" class="form-control" placeholder="Name" formControlName="patientName">
        </div>
    </div>
    <div class="col-md-2">
        <div class="row">
            <div class="col-md-11">
             <div class="form-group">
               <label for="exampleInputEmail1">From Date  <span class="font-size-10 text-muted">(MM/DD/YYYY)</span></label>
               <input id="matDate" matInput placeholder="mm/dd/yyyy" [matDatepicker]="picker" class="form-control"  formControlName="fromDate" readonly >
                </div> </div>

                <div class="col-md-1" style="margin-top: 24px; margin-left: -44px;">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </div>
        </div>
    </div>
    <div class="col-md-2">
      <div class="row">
          <div class="col-md-11">
           <div class="form-group">
             <label for="exampleInputEmail1">To Date  <span class="font-size-10 text-muted">(MM/DD/YYYY)</span></label>
             <input id="matDate" matInput placeholder="mm/dd/yyyy"   [matDatepicker]="picker1" class="form-control"  formControlName="toDate" readonly >
              </div> </div>

              <div class="col-md-1" style="margin-top: 24px; margin-left: -44px;">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
              </div>
      </div>
  </div>
      <!-- <div class="row">
        <div class="col-md-11">
          <div class="form-group">
            <label for="exampleInputEmail1">Date</label>
            <input id="matDate" type="text" formControlName="appointmentDate" class="form-control matDate" matInput [max]="maxDate" [matDatepicker]="dob">
          </div>
      </div>

      <div class="col-md-1" style="margin-top: 24px; margin-left: -44px;">
        <mat-datepicker #dob></mat-datepicker>
        <mat-datepicker-toggle matSuffix [for]="dob"></mat-datepicker-toggle>
      </div>
      </div> -->
      <!-- <input type="text" class="form-control" value="2012-05-15 21:05" id="datetimepicker" data-date-format="yyyy-mm-dd hh:ii"> -->

<!-- <div class="col-md-2">
  <div class="form-group">
      <label for="exampleInputEmail1">From Time </label>
      <select  class="form-control" formControlName="fromTime" >
        <option value="" selected hidden disabled>select from time</option>
        <option *ngFor="let h of h" value="{{h}}">{{h|convertFrom24To12Format}}</option>
    </select>
  </div>
</div> -->
<!-- <div class="col-md-3">
  <div class="row">

  </div>

</div> -->


<!-- <div class="form-group">
    <label for="exampleInputEmail1">To Time </label>
    <select  class="form-control" formControlName="toTime">
      <option value="" selected hidden disabled>select to time</option>
      <option *ngFor="let h of h" value="{{h}}">{{h|convertFrom24To12Format}}</option>
  </select> -->



<div class="col-md-2">
<div class="form-group">
    <label for="exampleInputEmail1">Status </label>
  <select class="form-control" formControlName="appointmentStatus">
    <option value="" selected hidden disabled>Please select</option>
    <option value="NEW">NEW</option>
    <option value="ENTITY_ACCEPTED">ACCEPTED</option>
    <option value="ENTITY_CANCELLED">CANCEL</option>
    <option value="ENTITY_RESCHEDULED">RESHEDULE</option>

    <!-- NEW, ENTITY_PENDING, ENTITY_ACCEPTED, ENTITY_CANCELLED, PATIENT_CANCELLED, ENTITY_RESCHEDULED -->
  </select>
</div>
</div>
<div class="col-md-2">
<div class="btn-group" style="float:right;margin-top: 20px;">
  <button class="btn btn-primary btn-sm" (click)="Reset()"><i class="fa fa-sync"></i> Reset</button>
   <!-- <button class="btn btn-danger btn-sm" style="margin-left:5px;"  routerLink="/Appoinments"><i class="fa fa-close"></i></button> -->
   <button class="btn btn-success btn-sm" style="margin-left:5px;" (click)="appointmentSearch()"><i class="fa fa-search"></i></button>
</div>
</div>

    </div>

  </div>

  <div class="row ">

    <div  *ngFor="let appointment of searchApointments|paginate: { id: 'p1', itemsPerPage: 20, currentPage: p1 }">
      <div class=" col-md-3 dc-recentapoint ng-star-inserted mb-2">
        <div class="d-flex flex-row card p-2  bta-1 previous-ap mt-0" data-toggle="modal" data-backdrop="static"
        data-target="#mypreviousappointment1" >

        <div class=" d-flex flex-column flex-grow-1 text-center ">
        <a href="javascript:void(0)">
          <h4 class="mb-0 pb-0" (click)="getPatientDetails(appointment)">{{appointment.patientName | titlecase}} </h4>
      <p class="pt-2 m-0">  <span class="mt-2 text-muted">  {{appointment.appointmentDate | date: 'dd-MMM-yyyy'}}</span>  </p>
      <p class="p-0 m-0"> <span class="mt-2 text-muted text-small">  {{appointment.fromTime|convertFrom24To12Format}}
      </span></p>

        </a>
        <div class="border-top pt-2">
        <div class="btn-group btn-group-justified ">

        <button class="btn   btn-sm" (click)="getPatientDetails(appointment)"><i class="fa fa-user themecol2" aria-hidden="true"></i></button>
        <button class="btn   btn-sm" (click)="getPatientMedicalReports(appointment)"><i class="fa fa-notes-medical themecol3" aria-hidden="true"></i></button>
        <button class="btn   btn-sm" (click)="prescriptionList(appointment)"><i class="fa fa-file-prescription  themecol4" aria-hidden="true"></i></button>
        </div>



        </div>

        </div>
        </div>
          </div>
        </div>

    </div>

    <div class="col-lg-12 mt-3 text_right ">

      <ul class="pagination">
        <pagination-controls  autoHide="true"id="p1" autoHide="true" responsive="true" (pageChange)="p1=$event" ></pagination-controls>
                    </ul>
        </div>


    <div align="center" style="width: 100%;" *ngIf="isNoData">

      <h3>Data not available</h3>

    </div>
















<!-- <div id="demo" class="carousel slide" data-ride="carousel">


   The slideshow
  <div class="carousel-inner">
    <div class="carousel-item active">
      <h1>Test1</h1>
    </div>
    <div class="carousel-item">
      <h1>Test2</h1>
    </div>
    <div class="carousel-item">
      <h1>Test3</h1>
    </div>
  </div>

  <!-- Left and right controls
  <a class="carousel-control-prev" href="#demo" data-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </a>
  <a class="carousel-control-next" href="#demo" data-slide="next">
    <span class="carousel-control-next-icon"></span>
  </a>

</div> -->
