import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';

@Component({
  selector: 'app-discount-requests',
  templateUrl: './discount-requests.component.html',
  styleUrls: ['./discount-requests.component.css']
})
export class DiscountRequestsComponent implements OnInit {
  dpgNo=1
  @Input() requestList:any
  constructor(private globalStorage: GlobalStorageService, private clinicServices: ClinicService,private toastr: ToastrService,public activeModal: NgbActiveModal,) { }

  ngOnInit(): void {
    this.getRequestLists()
  }
  getRequestLists(){
    this.clinicServices.getRequests(parseInt(this.globalStorage.getItem('hospitalAddressId')),parseInt(this.globalStorage.getItem('hospitalUserId'))).subscribe(res=>{
      this.requestList=res['data']
    },error=>{
      
    })
  }
  approve(data,value){
    if(data?.netAmount<value){
      this.toastr.warning('Discount amount can not greater than new amount')
      return
    }
    var body=
      {
        "amount": value,
        "hospitalUserId":data?.hospitalUserId,
        "discountRequestId": data?.discountRequestId,
        "flag":'APPROVED',
        "inpatientId": data?.inPatientId
      }
    this.clinicServices.approveRequest(body).subscribe(res=>{
      this.toastr.success('Discount Approved')
      this.getRequestLists()
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
  reject(data){
    var body=
    {
      "amount": 0,
      "discountRequestId": data?.discountRequestId,
      "flag":'CANCELLED',
      "inpatientId": data?.inPatientId,
      "requestedAmount":data?.amount
    }
  this.clinicServices.rejectRequest(body).subscribe(res=>{
    this.toastr.success('Discount Rejected')
    this.getRequestLists()
  },error=>{
    this.toastr.error(error?.error?.message)
  })
  }
  close(){
    this.activeModal.close()
  }
}
