import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { ClinicOpdAppointmentBookingComponent } from '../../components/clinic-opd-appointment-booking/clinic-opd-appointment-booking.component';
import { GlobalStorageService } from 'src/app/global-storage.service';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pat-assignment',
  templateUrl: './pat-assignment.component.html',
  styleUrls: ['./pat-assignment.component.css']
})
export class PatAssignmentComponent implements OnInit {

  @Input() patientId:any;
  @Input() Id:any;
  @Input() Info: any;
  constructor(public activeModal:NgbActiveModal,private clinicServices:ClinicService ,
    private globalStorage: GlobalStorageService, private router: Router,private modal:NgbModal, private toastrService: ToastrService,) { }

  ngOnInit(): void {
    this.getPatientDetails()
  }

  patientInfo:any;
  getPatientDetails(){

    this.clinicServices.findUserById(this.patientId || this.Id).subscribe(res => {
    this.patientInfo = JSON.parse(res['data'])})
  }

  book(){
    this.activeModal.close('OPD')
if(this.Id){
    const modalRef = this.modal.open(ClinicOpdAppointmentBookingComponent, {
      centered: false,
      windowClass: 'modal-right hw-40',
      backdrop: 'static',
      keyboard: false,
    });
   
    
    modalRef.componentInstance.userId = this.Id

    modalRef.result.then(
      (res) => {

      },
      () => { }  
    );
  }
}



ptdetails
  assignIpd(){

    if(this.Id){
      var currentDate = new Date().toJSON();
      var body = {
        "active": 'Y',
        "checkInTime": currentDate,
        "hospitalAddresId": this.globalStorage.getItem('hospitalAddressId'),
        "patientId": this.Id,
        "patientName": this.Info?.['firstName'] + ' ' + this.Info?.['lastName']
      }
      this.clinicServices.assinInPatient(body).subscribe(res => {
        this.ptdetails['inPatientId'] = res['data']
        const navigationExtras: NavigationExtras = {
          state: {
            patientName: this.ptdetails
          }
        };
        if(this.router.url=='/users-list'){
          this.router.navigate(['/clinic-users'], navigationExtras)
        }
        else{
          this.router.navigate(['/users-list'], navigationExtras)
        }
      },error=>{
        this.toastrService.error(error?.['error']?.['message'])
      })

    }

    this.activeModal.close('IPD')
  }

  assignEmergency(){

    if(this.Id){
      var currentDate = new Date().toJSON();
      var body = {
        "active": 'Y',
        "checkInTime": currentDate,
        "hospitalAddresId": this.globalStorage.getItem('hospitalAddressId'),
        "patientId": this.Id,
        "patientName": this.Info?.['firstName'] + ' ' + this.Info?.['lastName']
      }
      this.clinicServices.assinInPatient(body).subscribe(res => {
        this.ptdetails['inPatientId'] = res['data']
        const navigationExtras: NavigationExtras = {
          state: {
            patientName: this.ptdetails
          }
        };
        if(this.router.url=='/users-list'){
          this.router.navigate(['/clinic-users'], navigationExtras)
        }
        else{
          this.router.navigate(['/users-list'], navigationExtras)
        }
      },error=>{
        this.toastrService.error(error?.['error']?.['message'])
      })

    }
    this.activeModal.close('EMERGENCY')
  }

}
