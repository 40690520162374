<div class="modal-header">
    <h5 class="modal-title">Vital - ({{this.vital_name?this.vital_name:this.vitalName}}) History</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row align-items-center mb-3">
        <div class="col-md-4">
            <label>From Date</label>
            <input type="date" class="form-control" [(ngModel)]="fromDatee"
            [max]="todayDate">
        </div>

        <div class="col-md-4">
            <label>To Date</label>
            <input type="date" class="form-control" [(ngModel)]="toDatee"
            [max]="todayDate">
        </div>
        <div class="col-md-4 text-right">
            <button type="button" class="btn btn-primary mt-4" (click)="vitalHistory()">Search</button>
            <button type="button" class="btn btn-danger mt-4 ml-2" (click)="clearDates()">Clear</button>
        </div>
    </div>
    <table class="table table-bordered table-hover table-styled table-striped"
        role="grid">
        <thead>
            <tr role="row">
                <th>Vital Name</th>
                <th>Value</th>
                <th>Date </th>
            </tr>
        </thead>
        <tbody>
            <tr class="odd" *ngFor="let data of vitalHist | paginate: { id: 'p1', itemsPerPage: 10, currentPage: p1 }">
                <td>{{data?.vitalname}}</td>
                <td>{{data?.vitalValue}}</td>
                <td>{{data?.recordedDate | date: 'dd-MMM-yyyy'}}</td>
            </tr>
        </tbody>
    </table>
    <div *ngIf="vitalHist?.length == 0">
        <div class="no-data-found"></div>
    </div>
    <div class="row" *ngIf="vitalHist?.length>10">
        <div class="col-lg-12 mt-3 text_right">
           <ul class="pagination">
              <pagination-controls autoHide="true" id="p1" (pageChange)="p1=$event"></pagination-controls>
           </ul>
        </div>
     </div>
</div>