<div class="card patient-right-bg  pb-3">
  <div class="card ml-0 mr-0 mt-0 mb-2 p-profile-widget no-mobile ">
    <div class="position-absolute card-top-buttons">
      <button class="btn btn-header-light icon-button p-3 mr-3">
        <i class="fa fa-bell fa-lg pt-2 faa-shake animated" aria-hidden="true" data-toggle="modal"
          data-target="#viewreminder"></i>

      </button>
      <span class="count"> {{notificationLength==null?0:notificationLength}}</span>
    </div>
    <div class="card-body  ">
      <div class="   d-flex flex-row ml-2 mr-2 mb-1 mt-0 notificationbox">

        <a class="d-flex pt-2 profile-image" href="javascript:void(0)" (click)="fileInput.click()" data-toggle="tooltip"
          data-original-title="Upload Profile pic">
          <input #fileInput type="file" (change)="onFileInput($event.target.files)" style="display:none;" />
          <img alt="Profile" *ngIf="imageSource == null && gender == 'Male'" src="./assets/img/img_avatar.png"
            class="img-thumbnail border-0  p-0 list-thumbnail align-self-center xs-small male"
            style="border-radius: 50%;background: none;">
            <img alt="Profile" *ngIf="imageSource == null && gender == null" src="./assets/img/img_avatar.png"
            class="img-thumbnail border-0  p-0 list-thumbnail align-self-center xs-small male"
            style="border-radius: 50%;background: none;">
          <img alt="Profile" *ngIf="imageSource == null && gender == 'Female'" src="./assets/img/user/11.png"
            class="img-thumbnail border-0  p-0 list-thumbnail align-self-center xs-small female"
            style="border-radius: 50%;background: none;">
          <img alt="Profile" *ngIf="imageSource != null" [src]="imageSource"
            class="img-thumbnail border-0  p-0 list-thumbnail align-self-center xs-small null"
            style="border-radius: 50%;background: none;">
          <div class="profile-overlay">
            <a href="javascript:void(0)" class="profile-icon" id="profile-image" title="User Profile">
              <i class="fa fa-image"></i>
            </a>
          </div>
        </a>
        <h5 class="card-title border-0 mb-0 pb-2 mt-0" *ngIf="patientName!='null null'"> {{patientName |titlecase}}<br>
          <span class="text-muted text-small pt-2"> {{age}} Years- {{gender}}</span>
        </h5>
        <h5 class="card-title border-0 mb-0 pb-2 mt-0" *ngIf="patientName=='null null'"> Guest<br>
          <span class="text-muted text-small pt-2"> {{age}} Years- {{gender}}</span>
        </h5>
      </div>



    </div>
  </div>








  <div class="no-mobile">
    <div class="box no-data-vitals  mb-0 pb-0">

      <div class="box-header bg-primary ">
        <h5 class="box-title text-white" id="vitals">Vitals</h5>
        <ul class="box-controls pull-right">
          <li class="dropdown"> Last updated on: {{vitalsLastUpadatedDate | date:'dd-MMM-yyyy'}}
            <a data-toggle="dropdown" href="javascript:void(0)" id="vitals" routerLink="/vitals-view"
              class="btn btn-info-light px-10 base-font">More</a>

          </li>
        </ul>
      </div>
      <div class="box-body px-0 bg-primary rounded-0">
        <div id="spark1" class="text-dark"></div>
      </div>
      <div class="box-body up-mar40 pt-0 pl-0 pr-0 pb-0">

        <div class="row pl-3 mt-2 pr-3 pt-0 pb-2 text-center">
          <div class=" w-50  pr-2">
            <a href="javascript:void(0)" data-toggle="modal" data-backdrop="static" id="heartbeat" (click)="editOrAddVitals()"
              class="btn btn-sm  vitals-b4 w-100 prelative">
              <div class="fixedtag bg-red" *ngIf="(pulse>0)&&(60>pulse || pulse>90)">Alert</div> <i
                class="fas fa-heartbeat faa-pulse animated faa-fast" aria-hidden="true"></i> <span>Heart Rate<div
                  class="clear"> </div>
                <p class="m-0"> {{pulse | split2}}<sup>BPM</sup></p>
              </span>
            </a>
          </div>
          <div class=" w-50  ">
            <a href="javascript:void(0)" data-toggle="modal" data-backdrop="static" id="blood-glucose" (click)="editOrAddVitals()"
              class="btn btn-sm vitals-b2 w-100 prelative">
              <div class="fixedtag bg-red"
                *ngIf="(glucolseLevelPreLunch>70 || glucolseLevelPostLunch>110)&&(glucolseLevelPreLunch>140 || glucolseLevelPostLunch>200)">
                Alert</div> <i class="fa fa-vial faa-bounce animated" aria-hidden="true"></i> <span>Blood Glucose <div
                  class="clear">
                </div>
                <p class="m-0">{{glucolseLevelPreLunch}} | {{glucolseLevelPostLunch}}</p>
              </span>
            </a>
          </div>

          <div class=" w-50 mt-2   pr-2">
            <a href="javascript:void(0)" data-toggle="modal" data-backdrop="static" id="blood-pressure" (click)="editOrAddVitals()"
              class="btn btn-sm vitals-b3 w-100 prelative">
              <div class="fixedtag bg-red blink"
                *ngIf="(systolic>0 || diastolic>0)&&(110>systolic || systolic>140 || 70>diastolic || diastolic>90)">
                Alert</div> <i class="fa fa-male faa-shake animated" aria-hidden="true"></i> <span>Pressure


                <div class="clear"> </div>
                <p class="m-0">{{ Bp }}<sup> mm/Hg</sup></p>
              </span>
            </a>
          </div>
          <div class="w-50  mt-2 ">
            <a href="javascript:void(0)" id="blood-group" (click)="editOrAddVitals()" class="btn btn-sm  vitals-b4 w-100 prelative"> <i
                class="fa fa-tint faa-flash animated faa-slow" aria-hidden="true"></i> <span>Blood Group
                <div class="clear"> </div>
                <p class="m-0">{{BloodGroup}}<sup> </sup></p>
              </span></a>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="card p-min-height mt-12 mb-2 ">
    <div class="position-absolute card-top-buttons">
      <button class="btn icon-button" data-placement="top" data-toggle="tooltip" data-original-title="View">
        <i class="fa fa-eye themecol2" id="my-prescriptions" routerLink="/prescription"></i>
      </button>
    </div>
    <div class="card-body">
      <h5 class="card-title">
        <i class="fa fa-file-prescription themecol2"></i> My Prescriptions
        
      </h5>
     

      <div class="no-prescriptions-h" *ngIf="medications?.length==0"> </div>
      <div class="list disable-text-selection">
        <div class=" list ">
          <div class="" *ngFor="let reports of medications?.slice(0,4)">
            <div class=" card m-2 d-flex flex-row mb-0 p-1 border-bottom blt-1 "
              [ngClass]="{' blt-3': reports?.doctorName=='SELF', ' blt-1':reports?.doctorName!='self' }">
              <a href="javascript:void(0)" class="p-2 icon-appointments mt-1 " routerLink="/prescription"
                *ngIf="reports?.doctorName=='self'">
                <i class="fa fa-user themecol3 fa-2x" aria-hidden="true"></i>
              </a>
              <a href="javascript:void(0)" class="p-2 icon-appointments mt-1 curser-null "
                *ngIf="reports?.doctorName!='self'">
                <i class="fa fa-user-md themecol1 fa-2x" aria-hidden="true"></i>
              </a>
              <div class="pl-1 pr-2 d-flex  flex-column flex-grow-1 mr-2 ">
                <a href="javascript:void(0)" href="javascript:void(0)" class="curser-null">
                  <p class="font-weight-medium mb-1 mt-1"><span
                      *ngIf="reports?.doctorName!='SELF'">Dr.</span>{{reports?.doctorName | titlecase}}
                  </p>
                  <p class="text-muted mb-1 text-small"> {{ reports?.appointmentDate | date: "dd-MMM-yyyy" }} - <span class="text-center" style="margin-left: 25%;" >Status: </span>
                    <span style="font-size:14px" [ngClass]="{'text-primary': reports?.status=='New','text-danger': reports?.status=='Pending', 'text-success': reports?.status=='Paid', 'text-warning': reports?.status=='Confirmed' || reports?.status=='Shipped' }">{{reports?.status}}</span></p>
                </a>
              </div>
              <div class=" float-right  ">
                <a href="javascript:void(0)" class="btn btn-sm mr-1 mt-2 p-1" data-toggle="modal"
                data-backdrop="static" data-target="#orderdetails"> <i class="fa fa-shipping-fast  patientc3"
                  aria-hidden="true" data-placement="top" data-toggle="tooltip"
                  data-original-title="Order Details" (click)="showStatus(reports)"></i> </a>
                <a href="javascript:void(0)" href="javascript:void(0)" data-placement="top" data-toggle="tooltip"
                  data-original-title="View Prescription" class="btn btn-sm mr-1 mt-2 p-1"
                  *ngIf="reports?.containsMedicalPrescription=='Y' || reports?.prescriptioncontainsImage=='Y'"> <i
                    class="fa fa-file-prescription  patientc3" aria-hidden="true" data-toggle="modal"
                    data-backdrop="static" data-target="#medicalPrescriptionDoctor"
                    (click)="viewPrescription(reports)"></i> </a>
                <a href="javascript:void(0)" data-placement="top" data-toggle="tooltip" data-original-title="Lab Test"
                  class="btn  btn-sm mr-1 mt-2 p-1" type="submit" *ngIf="reports?.containsTestPrescription=='Y'">
                  <i class="fa fa-microscope  patientc2" (click)="viewTestPrescription(reports)"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="pt-0 pl-0 pr-0 no-mobile">


    <div class="card notification-height">

      <div class="card-body big ">
        <h5 class="card-title">
          <i class="fa fa-bell  themecol2 "></i> Notifications
        </h5>
        <div class="no-notifications" *ngIf="nonViewData?.length==0"> </div>

        <div class="d-flex flex-row mb-1 p-2 border-bottom  " *ngFor="let notif of nonViewData?.slice(0,5)"
          (click)="updateNotify(notif)">

          <a href="javascript:void(0)" class=" w-5  mr-2 mt-1 p-2 ">
            <div *ngIf="notif.objecttype=='New Appointment'"><i class="fa fa-calendar-check font-size-16 text-primary "
                aria-hidden="true"></i></div>
                <div *ngIf="notif.objecttype=='Appointment Accepted '"><i class="fa fa-calendar-check font-size-16 text-primary "
                  aria-hidden="true"></i></div>
            <div *ngIf="notif.objecttype=='Upcoming Appointment'"><i
                class="fa fa-calendar-day font-size-16 text-warning" aria-hidden="true"></i></div>
                <div *ngIf="notif.objecttype=='Followup Appointment '"><i
                  class="fa fa-calendar-day font-size-16 text-warning" aria-hidden="true"></i></div>
            <div *ngIf="notif.objecttype=='Appointment Consultation'"><i
                class="fa fa-calendar-day font-size-16 text-danger " aria-hidden="true"></i></div>
            <div *ngIf="notif.objecttype=='Payment Status'"><i class="fas fa-wallet text-success font-size-16"></i>
            </div>
            <div *ngIf="notif.objecttype=='PHARMACY Invoice REQUEST_PAYMENT'"><i
                class="fa fa-receipt text-danger font-size-16"></i></div>
            <div *ngIf="notif.objecttype=='Medical Prescription'"><i
                class="fas fa-file-prescription text-primary font-size-16"></i></div>
            <div *ngIf="notif.objecttype=='Your appointment has been accepted'"><i
                class="fas fa-calendar-check text-primary font-size-16"></i></div>
            <div *ngIf="notif.objecttype=='Appointment Cancelled'"><i
                class="fa fa-calendar-times  themecold5 text-primary font-size-16"></i></div>
            <div *ngIf="notif.objecttype=='SHIPPED'"><i class="fa fa-shipping-fast  patientc3 font-size-16"></i></div>
            <div *ngIf="notif.objecttype=='DELIVERED'"><i class="fa fa-shipping-fast  patientc3 font-size-16"></i></div>
            <div *ngIf="notif.objecttype=='Appointment Rescheduled'"><i
                class="fas fa-calendar-day text-primary font-size-16"></i></div>
            <div *ngIf="notif.objecttype=='CANCEL_ORDER'"><i class="fa fa-calendar-day text-danger font-size-16"></i>
            </div>
          </a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
            <a href="javascript:void(0)">
              <p class="font-weight-medium mb-0 mt-1 text-truncate text-nowrap  overflow-hidden eclips"
                style="text-overflow: ellipsis;">{{notif.objecttype |titlecase}} <span
                  *ngIf="check(notif.lastUpdatedTs)"> {{notif.lastUpdatedTs | date:'h:mm a'}}</span>
                <span *ngIf="!check(notif.lastUpdatedTs)"> {{notif.lastUpdatedTs | date:'dd-MMM-yyyy h:mm a'}} </span>
              </p>
              <!-- <p class="font-weight-medium mb-0 mt-1">{{notif.objecttype  |titlecase}} {{notif.lastUpdatedTs | date:'dd-MMM-yyyy'}}
  </p> -->
              <p class="text-muted mb-1 text-small eclips">{{notif.reason}}</p>
            </a>

          </div>


        </div>

      </div>
    </div>





  </div>



</div>

<div class="modal fade modal-right" id="viewreminder" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight">
  <div class="modal-dialog" role="document" style="min-width:35%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notifications</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>


      <!-- <app-view-reminders></app-view-reminders> -->
      <div class="row">
        <div class="col-lg-12 mt-3 text_right ">

          <ul class="pagination">
            <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
          </ul>
        </div>
      </div>
      <div class="no-notifications" *ngIf="nonViewData?.length==0"></div>
      <div class="row d-flex  ml-2">

        <div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left  blt-2 mb-2 mt-1 "
          *ngFor="let reports of nonViewData |paginate: { id: 'p2', itemsPerPage: 6, currentPage: p2 }"
          [ngClass]="{' blt-3': reports.viewed == 'Y'}" (click)="updateNotify(reports)">

          <a href="javascript:void(0)" class="p-2 icon-appointments  ">
            <i *ngIf="reports.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3" aria-hidden="true"></i>
            <i *ngIf="reports.viewed != 'Y'" class="fa fa-bell fa-lg pt-2  icon-col-3" aria-hidden="true"></i>
          </a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 "
            [ngStyle]="{'backgroundColor':reports.viewed==='Y'?'##A49D9C':'white'}">
            <a href="javascript:void(0)">
              <p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
              </p>
              <p class="text-muted mb-1 text-small">{{reports.reason}}</p>
            </a>

          </div>

          <div class="btn-group float-right">
            <button class="btn btn-xs btn0col1" data-toggle="modal" data-backdrop="static" data-target="#viewpdf"><i
                class="fa fa-eye" aria-hidden="true" (click)="updateNotify(reports)"></i></button>
          </div>



        </div>
      </div>
      <div class="modal fade" id="notificationToView" role="dialog" data-backdrop="static" data-keyboard="false"
        aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document"
          style="min-width:300px !important;height: 50% !important;">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Notification Viewer</h5>

              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="block inv-section text-center">
                <div class="text-center">
                    <img alt="Logo" src="./assets/img/logo.png" style="border-radius: none !important;">
                </div>
                <div class="text-center">
                  <div class="font-weight-medium mb-0 mt-1 text-center col-md-12">{{notifyToView?.objecttype
                    |titlecase}} </div>
                  <div class="text-muted mb-1 text-center text-small">{{notifyToView?.reason}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<div class="modal fade modal-left mrgleftmob" id="medicalPrescriptionDoctor" style="margin-left:71px"
  role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:40%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Prescription Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" (click)="closeMedPresModal()">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="contentToConvert">
        <div class="block inv-section">
          <div class="top-inv-col row">
            <div class="inv-logo col-md-6">
              <img alt="" src="./assets/img/logo.png">
            </div>
            <div class="float-right text-left">
              <p>Order Id: <span>{{precId}}</span></p>
              <p>Date: <span>{{appointmentDate | date:'dd-MMM-yyyy'}}</span></p>
            </div>
          </div>
          <div class="row   pl-0">
            <div class="col-md-6">
              <h5>Prescription From</h5>
              <ul class="inv-receiver">
                <li class="" *ngIf="doctorName!='SELF'">Dr.{{doctorName | titlecase}}<br>{{dStreet1}} {{dStreet2}}<br>
                  {{dCity}}-{{dZip}}<br>{{dState}}&nbsp;{{dCountry}}</li>
                <li class="" *ngIf="doctorName=='SELF'">{{patientName}}
                  <br>{{pstreet1}}
                  {{pstreet2}}
                  <br>{{pcity}}
                  {{pstate}}
                  {{pcountry}}
                  <br>
                  zip : {{pzip}}
                </li>
              </ul>

            </div>
            <div class="col-md-6">
              <div class="float-right text-left">
                <h5>Prescription To</h5>
                <ul class="inv-receiver ">
                  <li class="">{{patientName}}<br>{{pstreet1}} {{pstreet2}}<br>
                    {{pcity}}-{{pzip}}<br>{{pstate}}&nbsp;{{pcountry}}</li>
                </ul>
              </div>
            </div>
          </div>


          <img *ngIf="showImage==true && prescriptionImage" [src]="newsrc" style="height: 264px" alt="" />
          <iframe *ngIf="showPdf==true && prescriptionImage" [src]="newsrc" class="pdf-viewer" frameborder="0"
            scrolling="no" style="height: 500px;width: 200px;">
            <p>It appears your web browser doesn't support iframes.</p>
          </iframe>
          <!-- <table   class="pnv-table table table-striped" *ngIf="!prescriptionImage">
                  <tr>
                      <th class="text-align-left">Medicine</th>
                      <th class="text-align-left">Power</th>
                      <th class="text-align-center">Usage</th>
                      <th class="text-align-center">No of Days</th>
                      <th class="text-align-center">Morning</th>
                      <th class="text-align-center">Evening</th>
                      <th class="text-align-center">Afternoon</th>
                  </tr>
                  <tbody>
                    <tr *ngFor="let medicine of MedicationPrescriptions">
                      <td >{{medicine.medicineName}}</td>
                      <td >{{medicine.power}}</td>
                      <td >{{medicine.usage}}</td>
                      <td >{{medicine.numberOfDays}}</td>
                      <td >{{medicine.morning}}</td>
                      <td >{{medicine.afternoon}}</td>
                      <td >{{medicine.evening}}</td>

                  </tr>
                  </tbody>

              </table> -->


        </div>
        <ol class="prescription-ol pl-0" *ngIf="MedicationPrescriptions && MedicationPrescriptions?.length>0 ">

          <li *ngFor="let med of MedicationPrescriptions ;let i=index"><i>{{med.type}}</i> <b> {{med.medicineName}}
              {{med.power}} </b><span *ngIf="med.instructions"> ({{med.instructions}})</span>
            <!-- <button  class="btn btn-danger btn-sm mr-2 float-right" ><i  class="fa fa-minus "></i></button>
            <button  class="btn btn-primary btn-sm mr-2 float-right" ><i  class="fa fa-pencil-alt "></i></button> -->

            <span class="float-right"
            *ngIf="med.numberOfDays !== null && med.refill !== null"
            style="border-left: 1px solid #000;  padding-left: 10px;margin-right: 0px;
            ">{{med.numberOfDays}}days - {{med.refill}} Refill(s)</span>

            <ul style="padding-left: 10px" *ngIf="med.morning !== null && med.morning !== 0 || med.afternoon !== null && med.afternoon !== 0 || med.evening !== null && med.evening !== 0 || med.instructions !== null">
              <li style="list-style: none" *ngIf="med.morning !== null && med.morning !== 0 || med.afternoon !== null && med.afternoon !== 0 || med.evening !== null && med.evening !== 0 || med.instructions !== null">
                {{med.morning}}+{{med.afternoon}}+{{med.evening}} &emsp; {{med.instructions}}</li>
              <li style="list-style: none"></li>
            </ul>
          </li>

        </ol>
        <button href="javascript:void(0)" class="btn  btn-sm btn-success  mr-2  mt-2 p-2 float-right display-btn"
          type="submit" (click)="navigateToSelectPharma()" data-dismiss="modal" aria-label="Close"
          *ngIf="today>=nextFillRequestDate">
          <!-- <i class="fa fa-microscope icon-head patientc2" routerLink="/search-diagnostic" ></i></a> -->
          Fill Prescription
        </button>
        <a (click)="downloadPDF()" style="color: #ffffff"
          class="btn  btn-sm btn-primary mr-2 mt-2 p-2 float-right display-btn" type="button">
          <!-- <i class="fa fa-microscope icon-head patientc2" routerLink="/search-diagnostic" ></i></a> -->
          Download
        </a>
      </div>

    </div>

  </div>
</div>
<div class="modal fade modal-left" id="viewTestPrescription" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:40%;" id="pdfTable" #pdfTable>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Lab Tests Prescripiton Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="contentToConvertTwo">
        <div class="block inv-section">
          <div class="top-inv-col row">
            <div class="inv-logo col-md-6">
              <img alt="" src='./assets/img/logo.png'>
            </div>
            <div class="order-details float-right text-left">
              <p>Order Id: <span>{{precId}}</span></p>
              <p>Date: <span>{{testPrescDate | date: 'dd-MMM-yyyy'}}</span></p>
            </div>
          </div>
          <div class="row   pl-2">
            <div class="col-md-6">
              <h5>Prescription From</h5>
              <ul class="inv-receiver">
                <li class="" *ngIf="doctorName!='SELF'">Dr.{{doctorName}}<br>{{dStreet1}} {{dStreet2}}<br>
                  {{dCity}}-{{dZip}}<br>{{dState}}&nbsp;{{dCountry}}</li>
                <li class="" *ngIf="doctorName=='SELF'">{{patientName}}<br>{{pstreet1}} {{pstreet2}}<br>
                  {{pcity}}-{{pzip}}<br>{{pstate}}&nbsp;{{pcountry}}</li>
              </ul>
            </div>
            <p></p>
            <div class="float-right text-left" *ngIf="doctorName!='SELF'">

              <h5>Prescription To</h5>
              <ul class="inv-receiver">
                <li class="">{{patientName}}<br>{{pstreet1}} {{pstreet2}}<br>
                  {{pcity}}-{{pzip}}<br>{{pstate}}&nbsp;{{pcountry}}</li>
              </ul>
            </div>
          </div>
          <img *ngIf="showImage==true" [src]="newsrc" style="height: 264px" alt="" />
          <iframe *ngIf="showPdf==true" [src]="newsrc" class="pdf-viewer" frameborder="0" scrolling="no"
            style="height: 600px;">
            <p>It appears your web browser doesn't support iframes.</p>
          </iframe>


          <!-- <table   class="pnv-table table table-striped">
                    <tr>
                      <th class="text-align-left">Test Name</th>
                      <th class="text-align-left">Instructions</th>

                    </tr>
                    <tbody>
                      <tr *ngFor="let medicine of medicalTestPrescription">
                        <td>{{medicine.testName}}</td>
                        <td>{{medicine.instructions}}</td>


                      </tr>
                    </tbody>

                  </table> -->
          <ol class="prescription-ol pl-0">
            <li *ngFor="let row of medicalTestPrescription;let i =index"><i>{{row.testType}}</i> <b> {{row.testName}}
              </b><span> ({{row.instructions}})</span>
              <button class="btn btn-success p-2 btn-sm mr-2 float-right" routerLink="/search-diagnostic"
                (click)="navigateToDiagnosticSearch([row])"
                style="padding: 3px 5px;height: 27px;line-height: 14px;font-size: 12px;" *ngIf="row?.fill=='N'">Book
                Appointment</button>
              <!-- <button  class="btn btn-primary btn-sm mr-2 float-right"><i  class="fa fa-pencil-alt "></i></button> -->

              <ul style="padding-left: 10px">
                <li style="list-style: none">
                  {{row.notes}}</li>
                <li style="list-style: none"></li>
              </ul>
            </li>
          </ol>

          <div class="invoice-info">
            <a (click)="downloadAsPDF()" style="color: #ffffff"
              class="btn  btn-sm btn-primary mr-2 mt-2 p-2 float-right display-btn" type="button">
              Download
            </a>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>
<div class="modal fade center" id="notificationViewer" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document"
    style="min-width:300px !important;height: 50% !important;margin-left: 39%;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notification Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="block inv-section">
          <div class="text-center">
              <img alt="Logo" src="./assets/img/logo.png" style="border-radius: none !important;">
          </div>
          <div class="text-center">
            <div *ngFor="let notify of ViewedNotifications?.slice(0,1)">
              <p class="font-weight-medium mb-0 mt-1">{{notify?.objecttype |titlecase}}
              </p>
              <p class="text-muted mb-1 text-small">{{notify?.reason}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- edit vitals code -->
<div class="modal fade modal-right" id="addvitals" role="dialog" data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <app-add-vitals *ngIf="addVitalsShow"></app-add-vitals>
    </div>
  </div>
