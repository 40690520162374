<form [formGroup]="registerForm">
    <div class="row mt-2">
        <div class="col-md-4 mb-2">
            <label class="form-labe">First Name <span class="text-danger">*</span></label>
            <input type="text" appAppTitleCase placeholder="Enter First Name" appInputRestrict="alphaOnlyWithSpaces" [appUnifiedValidation]="['required','minlength']" formControlName="firstName" class="form-control" />
        </div>
        <div class="col-md-4 mb-2">
            <label class="form-labe">Middle Name</label>
            <input type="text" placeholder="Enter Middle Name" appInputRestrict="alphaOnlyWithSpaces" formControlName="middleName" class="form-control" />
        </div>
        <div class="col-md-4 mb-2">
            <label class="form-labe">Last Name <span class="text-danger">*</span></label>
            <input type="text" appAppTitleCase placeholder="Enter Last Name" appInputRestrict="alphaOnlyWithSpaces" [appUnifiedValidation]="['required','minlength']" minlength="1"  formControlName="lastName" class="form-control" />
        </div>
        <div class="col-md-4 mb-2">
            <label class="form-labe">Email ID</label>
            <input type="email" (focusout)="checkEmail()" placeholder="Enter Email"  [appUnifiedValidation]="['email']" formControlName="emailId" class="form-control no-caps" readonly/>
        </div>
        <div class="col-md-4 mb-2">
            <label class="form-labe">Mobile Number <span class="text-danger" *ngIf="!isPrimaryRegistered">*</span></label>
          <input #cellInput type="text"  [appUnifiedValidation]="['required','minlength']" [minlength]="10" maxlength="10"
          class="form-control" (focusout)="checkPhNumber()" appInputRestrict="mobileNumber"
          formControlName="cellNumber"  placeholder="Enter mobile number"/>
        </div>
        <!-- <div class="col-md-4 mb-2">
            <label class="form-labe">Emergeny Contact Name</label>
            <input type="text" placeholder="Enter Contact Name" appAppTitleCase formControlName="emergenyContactName" class="form-control" />
        </div>
        <div class="col-md-4 mb-2">
            <label class="form-labe">Emergeny Contact Number</label>
            <input #emInput type="text" ng2TelInput
            [ng2TelInputOptions]="{autoHideDialCode: false, initialCountry: 'in', separateDialCode:true,formatOnDisplay:false}"
            class="form-control" appInputRestrict="mobileNumber"
            formControlName="emergenyContactNumber" maxlength="10" placeholder="Enter Emergency Contact number"/>
        </div> -->
        <div class="col-md-4 mb-2">
            <label class="width-100 ml-1">DOB <span class="font-size-10 text-muted">(DD/MM/YYYY)</span> <span
                    class="text-danger">*</span></label>
            <div class="position-relative">
                <input matInput [matDatepicker]="picker" [max]="maxDate" class="form-control mb-0" formControlName="DOB"
                       (keyup)="picker.open()" (keypress)="picker.open()">
                <div class="" style="position: absolute; top: 0; right: 10px;">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </div>
            </div>
    </div>

    <!-- <div class="col-md-1">
        <label class="form-labe">Age (yrs)</label>
        <input type="number" (focusout)="updateDOB($event.target.value)" [(ngModel)]="genAge" class="form-control" formControlName="age" />
    </div> -->

    <div class="col-md-4 mb-2">
        <label class="form-labe">Gender <span class="text-danger">*</span></label>
        <mat-select formControlName="gender" class="form-control" disabled>
            <mat-option *ngFor="let option of genderArray" [value]="option" >{{option}}</mat-option>
        </mat-select>
    </div>
    </div>

    <h4 class="card-title mt-2 mb-2 p-2 cardtitlenew bg-primary-light font-size-14"> Address </h4>
    <div formGroupName="address">
        <div class="row">
           
            <div class="col-md-4 mb-2">
                <label class="form-labe">Zip/Pin Code</label>
                <input type="text" placeholder="Enter Zip/Pin Code"  #zipCode appInputRestrict="numberOnly" (keyup)="percChanged(zipCode.value)"  maxlength="6" formControlName="zip" class="form-control" />
            </div>
            <div class="col-md-4 mb-2">
                <label class="form-labe">Country Code</label>
                <mat-select formControlName="country" class="form-control"  [(value)]="countryCodeSelected" (selectionChange)="countryCodeEntered(countryCodeSelected)">
                    <mat-option *ngFor="let option of countryArray" [value]="option">{{option}}</mat-option>
                </mat-select>
            </div> 
            <div class="col-md-4 mb-2">
                <label class="form-labe">Postal Location</label>
                <mat-select placeholder="Select postal location" [(value)]="selectedValue"
              (selectionChange)="drpdownSelectionForAddress(selectedValue)" class="form-control"
              formControlName="postalLocation" name="item"  placeholder="Enter postal location">

              <mat-option *ngFor="let item of postalLocations" [value]="item.postalLocation">
                {{item.postalLocation}}
              </mat-option>
            </mat-select>

                   </div>
            <div class="col-md-4 mb-2">
                <label class="form-labe">Street1</label>
                <input placeholder="Street1" type="text" formControlName="street1" class="form-control" />
            </div>
            <div class="col-md-4 mb-2">
                <label class="form-labe">Street2</label>
                <input type="text" placeholder="Street2" formControlName="street2" class="form-control" />
            </div>
            <div class="col-md-4 mb-2">
                <label class="form-labe">City</label>
                <input type="text" placeholder="City" formControlName="city" class="form-control" />
            </div>
            <div class="col-md-4 mb-2">
                <label class="form-labe">State</label>
                <input type="text" placeholder="State" formControlName="state" class="form-control" />
            </div>
        </div>
    </div>
</form>
<div class="col-md-12">
    <div class="d-inline-block w-100">
        <button type="submit" class="btn btn-primary float-right" (click)="onSubmit()"> Save </button>
    </div>
</div>