<div class="modal-header">
    <h4 class="centerHeader mb-0" *ngIf="!isManufEdit">Add Manufacturer</h4>
    <h4 class="centerHeader mb-0" *ngIf="isManufEdit">Edit Manufacturer</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="manufacturForm">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label>Manufacturer <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="manufacturer" 
                    [ngClass]="{'is-invalid' : (submitted || mControls.manufacturer.touched) && mControls.manufacturer.errors}">

                    <div *ngIf="(submitted || mControls.manufacturer.touched) && mControls.manufacturer.errors" class="invalid-feedback">
                        <div *ngIf="mControls.manufacturer.errors.required">
                            This field is required.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 text-right mt-2" *ngIf="!isManufEdit">
                <button class="btn btn-primary" [disabled]="manufacturForm.invalid" 
                (click)="onSaveManufacturerData()"><i class="plusicon fas fa-save"></i> Save</button>
            </div>

            <div class="col-lg-12 col-md-12 text-right mt-2" *ngIf="isManufEdit">
                <button class="btn btn-primary"
                (click)="onSaveManufacturerData()"><i class="plusicon fas fa-pencil"></i> Update</button>
            </div>
        </div>
    </form>
</div>