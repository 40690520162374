import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoginService } from '../../AppServices/login.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr'
import { MustMatch } from 'src/app/_helpers/must_match.validators';
import { interval, Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { NavigationExtras, Router } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DoctorService } from 'src/app/AppServices/doctor.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPassword: FormGroup
  submitted = false
  isResend = false
  isSend = true
  message
  isGetOtp = false
  invalidCredentials = false
  successCredentials = false
  isResendOtp = false
  timerOn = true;
  subscription: Subscription;
  subscribeTimer
  timeLeft: number = 120; verify = false;
  uValid: boolean = true;
  eValid: boolean = true;
  showTimer: boolean = false;

  npValid: boolean = true;
  cpValid: boolean = true;

  constructor(private loginService: LoginService, private doctorService: DoctorService, private toastrService: ToastrService, private router: Router, private fb: FormBuilder) {
    this.forgotPassword = this.fb.group({
      userName: ['', Validators.required],
      emailId: ['', [Validators.required, Validators.email]],
      otp: [, Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{8,15})')]],
      confirmPassword: ['', [Validators.required]]
    },
      {
        validator: MustMatch('newPassword', 'confirmPassword')
      })
  }

  // forgotPassword:FormGroup;
  //submitted:boolean=false;
  ngOnInit(): void {
    if (this.router.url == '/doctor-verify') {
      this.verify = true
    }
  }
  get f() { return this.forgotPassword.controls; }
  email
  emailId
  newPassword
  OTP
  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z\_]+$/);
    return pattern.test(value);
  }
  verifyNow() {
    this.doctorService.getDoctorDtsByEmail(this.forgotPassword.controls['emailId'].value).subscribe(res => {
      const navigationExtras: NavigationExtras = {
        state: {
          emailId: this.forgotPassword.controls['emailId'].value,
          mobileNumber: res['data']['cellNumber'],
          userName: this.forgotPassword.controls['userName'].value
        }
      };
      this.router.navigate(['/otp-verification-doctor'], navigationExtras)
    })
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  ChangePassword() {
    this.submitted = true;

    if (this.forgotPassword.invalid) {
      //this.toastrService.error("Please eneter all mandatory fields")
      return;
    }

    var forGotPassword = {
      "userName": this.forgotPassword.value.userName,
      "emailId": this.forgotPassword.value.emailId,
      "newPassword": this.forgotPassword.value.newPassword,
      "type": "DOCTOR",
      "otp": parseInt(this.forgotPassword.value.otp),
    }


    this.loginService.forgotPassword(forGotPassword).subscribe(res => {
      if (res) {
        this.successCredentials = true
        this.message = res['data']
        // this.toastrService.success("Password changed successfully !")
        this.router.navigate(['/Login'])
      }
    }, res => {
      // this.toastrService.error(res['error'].message)
      this.invalidCredentials = true
      this.message = res['error']['message']
    })
  }

  isOtpSent; isResentOtp: boolean = false
  sendOTP() {
    this.invalidCredentials = false
    this.successCredentials = false
    if (this.forgotPassword.value.emailId != "") {
      this.loginService.resendEmailOTPForChangePasswordD(this.forgotPassword.value.emailId, this.forgotPassword.value.userName).subscribe(res => {
        this.successCredentials = true
        this.message = "Check your email for OTP."
        //this.toastrService.success("Check your email for OTP.")
        this.isOtpSent = true;
        this.uValid = true;
        this.eValid = true;
        this.isResentOtp = true;
        this.showTimer = true;
        this.observableTimer()
      }, error => {
        this.invalidCredentials = true
        this.message = error['error']['message']
      });
    } else {
      this.invalidCredentials = true
      this.message = "Please enter emailId"
    }

  }
  checkUserName() {
    this.invalidCredentials = false
    this.doctorService.userNameExistOrNot(this.forgotPassword.controls['userName'].value).subscribe(res => {
      if (res['data'] == false) {
        this.invalidCredentials = true
        this.message = res['message']

        this.uValid = true;
        this.submitted = false;
        return
      }
      else {
        this.uValid = false;
      }
    }, error => {
      //this.ToastrService.error("Error in exists email");
    });
  }
  observableTimer() {
    if (this.forgotPassword.value.emailId == "") {
      return
    }
    this.timeLeft = 120
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.showTimer = false;
        this.isSend = false
        this.isResend = true
        this.isResentOtp = false
        this.isGetOtp = false
        this.isResendOtp = false
        this.toastrService.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  isOtpValid = true; type
  otpCheck(otp) {
    if (this.router.url == '/nutritionistForgot') {
      this.type = "NUTRITIONIST"
    }
    else {
      this.type = "DOCTOR"
    }
    if (otp.length == 6) {
      var req =
      {
        "emailId": this.forgotPassword.value.emailId,
        "otp": parseInt(this.forgotPassword.value.otp),
        "type": this.type
      }

      this.loginService.otpVerify(req).subscribe(res => {
        this.isOtpValid = true;
        this.uValid = true;
        this.eValid = true;
        this.showTimer = false;
      }, error => {
        this.isOtpValid = false
        this.successCredentials = false
        this.invalidCredentials = true;
        this.uValid = false;
        this.eValid = false;

        //this.message=error['error']['message']
      })
    }
    else {
      this.invalidCredentials = false
    }
  }
  showHidePwd() {
    if ($('#show_hide_password input').attr("type") == "text") {
      $('#show_hide_password input').attr('type', 'password');
      $('#show_hide_password i').addClass("fa-eye-slash");
      $('#show_hide_password i').removeClass("fa-eye");
    } else if ($('#show_hide_password input').attr("type") == "password") {
      $('#show_hide_password input').attr('type', 'text');
      $('#show_hide_password i').removeClass("fa-eye-slash");
      $('#show_hide_password i').addClass("fa-eye");
    }
  }
  showHidePwd1() {
    if ($('#show_hide_password1  input').attr("type") == "text") {
      $('#show_hide_password1 input').attr('type', 'password');
      $('#show_hide_password1 i').addClass("fa-eye-slash");
      $('#show_hide_password1 i').removeClass("fa-eye");
    } else if ($('#show_hide_password1 input').attr("type") == "password") {
      $('#show_hide_password1 input').attr('type', 'text');
      $('#show_hide_password1 i').removeClass("fa-eye-slash");
      $('#show_hide_password1 i').addClass("fa-eye");
    }
  }

  emailEntered() {
    if (this.forgotPassword.controls['emailId'].status === "VALID")
      this.eValid = false;

    else
      this.eValid = true;
  }

  npEntered() {
    if (this.forgotPassword.controls['newPassword'].status === "VALID") {
      this.npValid = false;
    }
    else {
      this.npValid = true;
    }
  }

  cpEntered() {
    if (this.forgotPassword.controls['confirmPassword'].status === "VALID") {
      this.cpValid = false;
    }
    else {
      this.cpValid = true;
    }

  }


}
