<div class="modal-header">
    <h5 class="modal-title">
        <i aria-hidden="true" class="fa fa-user-plus"></i> Place Order
    </h5>
    <button type="button" class="close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-bodycure pt-0">
    <form [formGroup]="createorders">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3 ml-2 mt-2" >
                <label for="" class="">Select Supplier<b>*</b></label>
                <div class="form-group input-material">
                    <div class="form-group input-material">
                        <div>
                            <mat-select (selectionChange)="selectedSuppiler(Supplierselected)" [(ngModel)]="Supplierselected" class="form-control" id="Supplier" formControlName="Supplier"
                                [ngClass]="{ 'is-invalid': (submitted || createordersControls.Supplier.touched) && createordersControls.Supplier.errors }">
                                <mat-option *ngFor="let sup of suplier"
                                    [value]="sup.name" (click)="getsupplierIdbyClick(sup)">{{sup.name}}</mat-option>
                            </mat-select>
                            <div *ngIf="(submitted || createordersControls.Supplier.touched) && createordersControls.Supplier.errors"
                            class="invalid-feedback">
                            <div *ngIf="createordersControls.Supplier.errors.required">
                                Please Select SubCategory to Select Supplier.
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="supplierSelected">
        <app-currentor-previous-inv-orders [supplierInfo]="supplierInfo" [addressId]="addressId" [requestFromOrder]="'Y'"></app-currentor-previous-inv-orders>
    </div>
</div>
