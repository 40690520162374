<div class="modal-header">
    <h4 class="centerHeader mb-0">Add Item</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="subCategoryForm">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>Supplier <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="supplier" readonly>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>Category <span class="text-danger">*</span></label>
                    <mat-select class="form-control" formControlName="category">
                        <mat-option *ngFor="let c of categorylist" [value]="c.catagory"
                            (click)="selectedCategory(c)">{{c.catagory}}</mat-option>
                    </mat-select>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>Item Name <span class="text-danger">*</span></label>
                    <!-- <mat-select class="form-control" formControlName="subCategory"
                        (selectionChange)="selectedsubCategory(subCategorySelected)"
                        [ngClass]="{ 'is-invalid': (submitted || subCatFormControl.subCategory.touched) && subCatFormControl.subCategory.errors }">
                        <mat-option *ngFor="let s of subcategory" [value]="s.name"
                            (click)="getsubcategyById(s)">{{s.name}}</mat-option>
                    </mat-select> -->
                    <input type="text" class="form-control" formControlName="subCategory">
                    <div *ngIf="(submitted || subCatFormControl.subCategory.touched) && subCatFormControl.subCategory.errors"
                        class="invalid-feedback">
                        <div *ngIf="subCatFormControl.subCategory.errors.required">
                            Item Name is Required.
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="form-group">
                    <label>Price <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="price" maxlength="10"
                        appInputRestrict="numberOnly" [ngClass]="{ 'is-invalid':
                          (submitted || subCatFormControl.price.touched) && subCatFormControl.price.errors }">
                    <div *ngIf="(submitted || subCatFormControl.price.touched) && subCatFormControl.price.errors"
                        class="invalid-feedback">
                        <div *ngIf="subCatFormControl.price.errors.required"> Price is Required. </div>
                    </div>
                </div>
            </div>


            <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3">
                <label for="" class="col-form-label">Description</label>
                <div class="form-group input-material">
                    <textarea autoResizeRows class="form-control" rows="5" formControlName="description" [ngClass]="{
        'is-invalid':
          (submitted || Subf.description.touched) && Subf.description.errors
    }"></textarea>
                    <div *ngIf="(submitted || Subf.description.touched) && Subf.description.errors"
                        class="invalid-feedback">

                        <div *ngIf="Subf.description.errors.required">
                            Description Required
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label>Manufactured By</label>
                <mat-select placeholder="Select..." class="form-control" formControlName="manufacturer">
                    <mat-option *ngFor="let data of manufacturersData" [value]="data.manufacturer">
                        {{ data.manufacturer }}
                    </mat-option>
                </mat-select>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label>Disposable</label>
                <mat-select placeholder="Select..." formControlName="disposal" class="form-control">
                    <mat-option *ngFor="let val of selectOptions" [value]="val.value">{{val.label}}</mat-option>
                </mat-select>
            </div>


            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label>Usage Units</label>
                <input type="text" class="form-control" formControlName="usageUnits" appInputRestrict="decimalOnly"
                    maxlength="10" placeholder="Enter Units">
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label>Rack</label>
                <input type="text" class="form-control" formControlName="rack" placeholder="Enter Here">
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label>HSN Code</label>
                <input type="text" class="form-control" formControlName="hsn" appInputRestrict="decimalOnly"
                    maxlength="10" placeholder="Enter Code">
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label>Tax</label>
                <!-- <input type="text" class="form-control" formControlName="tax" appInputRestrict="decimalOnly"
                    maxlength="10" placeholder="Enter Tax"> -->
                <select formControlName="tax" class="form-control">
                    <option *ngFor="let percentage of percentages" [value]="percentage">
                        {{ percentage }}%
                    </option>
                </select>
            </div>


            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12"></div>

            <!-- pharma fields -->
            <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3" *ngIf="category?.isPharmacy == 'Y'">
                <h3 class="mt-2 font-size-14 border-bottom p-2 bg-primary-light">Pharmacy</h3>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-xs-12">
                        <div class="form-group">
                            <label>Potency (mg/ml)</label>
                            <input type="text" placeholder="" class="form-control" formControlName="potency">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                        <div class="form-group">
                            <label>RX Required</label>
                            <mat-select placeholder="Select..." formControlName="prescription" class="form-control">
                                <mat-option *ngFor="let val of selectOptions"
                                    [value]="val.value">{{val.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                        <div class="form-group">
                            <label>Injectable</label>
                            <mat-select placeholder="Select..." formControlName="injectable" class="form-control">
                                <mat-option *ngFor="let val of selectOptions"
                                    [value]="val.value">{{val.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                        <div class="form-group">
                            <label>Alternate</label>
                            <input type="text" placeholder="" class="form-control" formControlName="alternate">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                        <div class="form-group">
                            <label>Freezable</label>
                            <mat-select placeholder="Select..." formControlName="freezable" class="form-control">
                                <mat-option *ngFor="let val of selectOptions"
                                    [value]="val.value">{{val.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div>
            <!-- pharma fields /-->

            <!-- <h3 class="mb-3 mt-2 font-size-16 border-bottom p-2 bg-danger-light ">GST</h3>

            <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3">
                <mat-radio-group class="example-radio-group" [(ngModel)]="selectedOption"
                    [ngModelOptions]="{standalone: true}">
                    <mat-radio-button class="example-radio-button" type="radio"
                        (change)="gstCheckBox($event)" *ngFor="let option of gstOptions"
                        [value]="option">
                        {{option}}
                    </mat-radio-button>
                </mat-radio-group>

                <ng-container *ngIf="showGstFields">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mt-2">
                            <label>C-GST <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" #cgst maxlength="5"
                                appInputRestrict="decimalOnly" placeholder="C GST"
                                formControlName="cgst"
                                [ngClass]="{ 'is-invalid': (submitted || Subf.cgst.touched) && Subf.cgst.errors }">
                            <div *ngIf="(submitted || Subf.cgst.touched) && Subf.cgst.errors"
                                class="invalid-feedback">

                                <div *ngIf="Subf.cgst.errors.required">
                                    Please enter C-GST.
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mt-2">
                            <label>S-GST <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" #sgst maxlength="5"
                                appInputRestrict="decimalOnly" placeholder="S GST"
                                formControlName="sgst" readonly
                                [ngClass]="{ 'is-invalid': (submitted || Subf.sgst.touched) && Subf.sgst.errors }">
                            <div *ngIf="(submitted || Subf.sgst.touched) && Subf.sgst.errors"
                                class="invalid-feedback">

                                <div *ngIf="Subf.sgst.errors.required">
                                    Please enter S-GST.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="showIGstFields">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mt-2">
                            <label>I-GST <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" #igst maxlength="5"
                                appInputRestrict="decimalOnly" placeholder="I GST"
                                formControlName="igst"
                                [ngClass]="{ 'is-invalid': (submitted || Subf.igst.touched) && Subf.igst.errors }">
                            <div *ngIf="(submitted || Subf.igst.touched) && Subf.igst.errors"
                                class="invalid-feedback">

                                <div *ngIf="Subf.igst.errors.required">
                                    Please enter I-GST.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div> -->

            <h3 class="mb-3 mt-2 font-size-16 border-bottom p-2 bg-primary-light ">Deal</h3>


            <div class="col-lg-12 col-md-12 mb-2">
                <div class="form-group input-material">
                    <input type="text" class="form-control" formControlName="deal" [(ngModel)]="showSet" [ngClass]="{ 'is-invalid':
                          (submitted || subCatFormControl.deal.touched) && subCatFormControl.deal.errors }">
                    <div *ngIf="(submitted || subCatFormControl.deal.touched) && subCatFormControl.deal.errors"
                        class="invalid-feedback">
                    </div>
                </div>
            </div>

            <ng-container *ngIf="showSet">
                <div class="col-lg-6 col-md-6 position-relative">
                    <div class="form-group">
                        <label>Deal From <span class="text-danger">*</span></label>
                        <input matInput [matDatepicker]="dealFromDt" class="form-control" formControlName="dealFromDate"
                            (keypress)="dealFromDt.open()" (keyup)="dealFromDt.open()" [max]="minDate"
                            (click)="dealFromDt.open()">

                        <mat-datepicker-toggle matIconSuffix [for]="dealFromDt"
                            style="position: absolute;top: 30px;right: 10px;"></mat-datepicker-toggle>
                        <mat-datepicker #dealFromDt></mat-datepicker>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                        <label>Deal To <span class="text-danger">*</span></label>
                        <input matInput [matDatepicker]="dealToDt" class="form-control" formControlName="dealToDate"
                            (keypress)="dealToDt.open()" (keyup)="dealToDt.open()" [min]="minDate"
                            (click)="dealToDt.open()">

                        <mat-datepicker-toggle matIconSuffix [for]="dealToDt"
                            style="position: absolute;top: 30px;right: 10px;"></mat-datepicker-toggle>
                        <mat-datepicker #dealToDt></mat-datepicker>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12 form-group mb-3">
                    <label class="col-form-label">Set Offer</label>
                </div>
                <div class="col-lg-12 col-md-12 form-group mb-3">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                        [(ngModel)]="selectedOption" [ngModelOptions]="{standalone: true}">
                        <mat-radio-button class="example-radio-button" type="radio"
                            (change)="checkBoxCheck($event,option)" *ngFor="let option of options" [value]="option">
                            {{option}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </ng-container>

            <ng-container *ngIf="showSet && showDiscount">
                <div class="col-lg-12 col-md-12 form-group mb-3">
                    <label>Discount on price</label>
                    <div class="form-group input-material">
                        <input type="text" class="form-control" formControlName="discount" maxlength="3"
                            appInputRestrict="numberOnly"
                            [ngClass]="{ 'is-invalid':
                              (submitted || subCatFormControl.discount.touched) && subCatFormControl.discount.errors }">
                        <div *ngIf="(submitted || subCatFormControl.discount.touched) && subCatFormControl.discount.errors"
                            class="invalid-feedback">
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="showSet && showOffer">
                <div class="col-lg-6 col-md-6 form-group mb-3">
                    <label>Buy Quantity</label>
                    <div class="form-group input-material">
                        <input type="text" class="form-control" formControlName="buyQuantity" maxlength="3"
                            appInputRestrict="numberOnly"
                            [ngClass]="{ 'is-invalid':
                              (submitted || subCatFormControl.buyQuantity.touched) && subCatFormControl.buyQuantity.errors }">
                        <div *ngIf="(submitted || subCatFormControl.buyQuantity.touched) && subCatFormControl.buyQuantity.errors"
                            class="invalid-feedback">

                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 form-group mb-3">
                    <label>Deal Quantity</label>
                    <div class="form-group input-material">
                        <input type="text" class="form-control" formControlName="freeQuantity" maxlength="3"
                            appInputRestrict="numberOnly"
                            [ngClass]="{ 'is-invalid':
                              (submitted || subCatFormControl.freeQuantity.touched) && subCatFormControl.freeQuantity.errors }">
                        <div *ngIf="(submitted || subCatFormControl.freeQuantity.touched) && subCatFormControl.freeQuantity.errors"
                            class="invalid-feedback">
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="vime al text-right">
                <button type="button" class="btn btn-danger al mt-3 float-right btn-sm ml-2"
                    (click)="closeModal()">Cancel</button>
                <button type="button" class="btn btn-success al mt-3 float-right btn-sm ml-2" (click)="saveItems()"
                    [disabled]='subCategoryForm.invalid'><i class="fa fa-save"></i> Save </button>
            </div>
        </div>
    </form>
</div>