<script>
  $(document).ready(function () {
    $("#event_NotAvailable").click(function (event) {
      event.stopPropagation();
      alert("The span element was clicked.");
    });
  });
</script>

<div class="modal-header">
  <h4 class="modal-title">Reschedule Appointment</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body p-2">
  <full-calendar style="width: 100%; display: inline-block;" [options]="calendarOptions"></full-calendar>
</div>

<div class="modal fade modal-centered" id="currentAppointmentCancel" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Appointment reschedule</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="">
        <div class="schedule-tab">



          <div class="tab-content">

            <div class="tab-pane active" id="tab_start">
              <div class=" ">
                <div class="row backgound_imgsidebar mb-3 pt-2 pb-2 pr-2">
                  <div class="col-md-9 col-sm-12">

                    <h4>Do you want reschedule this appointment?</h4>



                  </div>


                </div>





                <!-- Slot listing 5 end -->
              </div>

              <!-- Tab pane end -->

            </div>
            <!-- tab content -->

          </div>





        </div>




      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success btn-xs float-right" data-dismiss="modal"
          (click)="cancel()">Yes</button>
        <button type="button" class="btn btn-danger btn-xs float-right mr-2" data-dismiss="modal">No</button>
      </div>
    </div>
  </div>
</div>
