import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { pharmaService } from '../../services/pharma.service';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { GlobalStorageService } from 'src/app/global-storage.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.css']
})
export class OrderStatusComponent implements OnInit {
  @Input() prescriptionInfo:any
  customStripeForm: FormGroup;
  formProcess: boolean = false
  checkAgree: boolean = false;
  pharmacySelected:boolean=false
  consent;disableCB;pharmaInvoiceData;medicationPrescriptions=[];paidAmount
  addressId: any;
  constructor(private activeModal:NgbActiveModal,private globalStorage:GlobalStorageService,
     private pharmacyServices:pharmaService,private patientServices: PatientScheduleControlService,
    private toaster:ToastrService) { }

  ngOnInit(): void {
   this.initialize()
  }
  initialize(){
    console.log('Prescription Data',this.prescriptionInfo)
    this.checkStatus()
    if(this.prescriptionInfo?.status=='Paid' || this.prescriptionInfo?.status=='Confirmed' || this.prescriptionInfo?.status=='Shipped' || this.prescriptionInfo?.status=='DELIVERED'){
      this.getPrescriptionList()
    }
  }

  checkStatus(){
    if(this.prescriptionInfo?.status=='NEW_REQUEST' || this.prescriptionInfo?.status=='New'){
      $('.new').addClass('active')
    }
    if(this.prescriptionInfo?.status=='REQUEST_PAYMENT' || this.prescriptionInfo?.status=='Pending'){
      $('.new').addClass('active')
      $('.pending').addClass('active')
    }
    if(this.prescriptionInfo?.status=='REQUEST_PAYMENT' || this.prescriptionInfo?.status=='REQUEST_TO_PAY'){
      $('.new').addClass('active')
      $('.pending').addClass('active')
      $('.confirmed').addClass('active')
    }
    if(this.prescriptionInfo?.status=='PAYMENT_DONE'  || this.prescriptionInfo?.status=='Confirmed'){
      $('.new').addClass('active')
      $('.pending').addClass('active')
      $('.confirmed').addClass('active')
    }
    if(this.prescriptionInfo?.status=='PAYMENT_DONE'  || this.prescriptionInfo?.status=='Paid'){
      $('.new').addClass('active')
      $('.pending').addClass('active')
      $('.confirmed').addClass('active')
      $('.paid').addClass('active')
    }
    if(this.prescriptionInfo?.status=='SHIPPED' || this.prescriptionInfo?.status=='Shipped'){
      $('.new').addClass('active')
      $('.pending').addClass('active')
      $('.confirmed').addClass('active')
      $('.paid').addClass('active')
      $('.shipped').addClass('active')
    }
    if(this.prescriptionInfo?.status=='DELIVERED'){
      $('.new').addClass('active')
      $('.pending').addClass('active')
      $('.confirmed').addClass('active')
      $('.paid').addClass('active')
      $('.shipped').addClass('active')
      $('.delivered').addClass('active')
    }
  }
  closeModal(){
    this.activeModal.close()
  }
  getPrescriptionList(){
    this.pharmacyServices.getInvoiceDataByPharmacy(this.prescriptionInfo?.entityInvoiceId).subscribe(res => {
      console.log('medicines list',res)
      this.pharmaInvoiceData = res?.['data'];
      this.medicationPrescriptions = this.pharmaInvoiceData?.['prescriptionprices'];
      this.paidAmount = this.pharmaInvoiceData?.total;
    })
  }

  selPharmaAddressId:any;
  pharmaSelect(id){
    this.pharmacySelected=true
    this.selPharmaAddressId=id 
    console.log(this.selPharmaAddressId)
  }
  sendToPharmacy(id){
    this.addressId=id
    
    var request = {
      "forUserName":this.globalStorage.getItem('userName') ,
      "medicalprescriptionid":this.prescriptionInfo?.medicalPrescriptionId,
      "patientId":this.globalStorage.getItem('userId'),
      "pharmacyaddressId":this.selPharmaAddressId,
      "shippingAddressId":this.addressId
    }
  
    this.patientServices.globalPost('prescription/fillrequest', request,this.globalStorage.getItem('userId')).subscribe(res => {
      this.toaster.success('Prescription sent to pharmacy.')
      if(res){
        this.prescriptionInfo['status']='Pending';
        this.checkStatus()
        if(this.prescriptionInfo?.status=='Paid' || this.prescriptionInfo?.status=='Confirmed' || this.prescriptionInfo?.status=='Shipped' || this.prescriptionInfo?.status=='DELIVERED'){
          this.getPrescriptionList()
        }
      }

  })
  }

}



