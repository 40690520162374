<div class="modal-header">
    <h4 class="modal-title">{{data?.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" style=" padding: 4px !important; ">
    <p>{{data?.message}}</p>
    <div *ngIf="isTreatmentPlanEdit" class="row">
      <form [formGroup]="dayForm">
        <div class="col-md-12">
          <mat-form-field appearance="fill" style="display: block !important;">
            <mat-label>Choose a day</mat-label>
            <mat-select formControlName="day">
              <mat-option *ngFor="let day of days" [value]="day" (selectionChange)="selectedDay(day)" (click)="selectedDay(day)">{{ day }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
  </div>
  <div class="modal-footer" *ngIf="!isTreatmentPlanEdit && !fromBed">
    <button type="button" class="btn btn-primary float-right" (click)="modal.close(true)">Yes</button>
    <button type="button" class="btn btn-danger float-right" (click)="modal.dismiss(false)">No</button>
  </div>
  <div class="modal-footer" *ngIf="fromBed">
    <button type="button" class="btn btn-primary float-right" (click)="modal.close(true)">Change</button>
    <button type="button" class="btn btn-danger float-right" (click)="modal.dismiss(false)">Cancel</button>
  </div>
