import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { MustMatch, NoMatch } from 'src/app/_helpers/must_match.validators';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../../AppServices/login.service'
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { timer } from 'rxjs';

@Component({
  selector: 'app-doctor-change-password',
  templateUrl: './doctor-change-password.component.html',
  styleUrls: ['./doctor-change-password.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class DoctorChangePasswordComponent implements OnInit {

  login
  resetPassword: FormGroup
  submitted = false
  isCorrect = false
  password
  isResend = false
  isSend = true
  isGetOtp = false
  isResendOtp = false
  timerOn = true;
  subscription: Subscription;
  subscribeTimer
  timeLeft: number = 120;
  userName;
  showOTP = false;
  isTimer = false;
  constructor(private globalStorage: GlobalStorageService, private fb: FormBuilder, private doctorService: DoctorService, private router: Router, private toastr: ToastrService, private loginService: LoginService) {

  }

  ngOnInit(): void {
    this.resetPassword = this.fb.group({
      oldPassword: ['', Validators.required],
      otp: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)*\\S{8,15})')]],
      confirmPassword: ['', [Validators.required]],
      previousPwd: this.globalStorage.getItem('dpswd')
    },
      {
        validator: [MustMatch('newPassword', 'confirmPassword'), NoMatch('oldPassword', 'newPassword'), NoMatch('oldPassword', 'confirmPassword')]
      })
    this.login = JSON.parse(this.globalStorage.getItem('LoginDetails'));

  }
  get f() { return this.resetPassword.controls; }
  // checkOldpswd() {
  //   var pswd = this.globalStorage.getItem('dpswd')
  //   if (pswd != null) {
  //     if (this.resetPassword.value.oldPassword == pswd) {
  //       this.isCorrect = false
  //     }
  //     else {
  //       this.isCorrect = true;
  //     }
  //   }
  // }

  storedPassword;
  checkOldpswd() {
    this.storedPassword = this.globalStorage.getItem('dpswd');
    const enteredOldPassword = this.resetPassword.value.oldPassword;
  
    // if (this.storedPassword !== enteredOldPassword) {
    //   // Old password does not match the stored password
    //   this.isCorrect = true;
    //   this.showOTP = false;
    // } else {
    //   // Old password matches the stored password
    //   this.isCorrect = false;
    // }
    this.loginService.checkOldPassword(this.globalStorage.getItem('email'),enteredOldPassword).subscribe(res=>{
      if(res['data']==true){
        this.toastr.success('Old password verified.')
      }
      else{
        this.toastr.error('Password not verified with old password')
      }
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
  

  type
  submit() {
    var data = this.globalStorage.getItem('LoginDetails')
    var obj = JSON.parse(data)
    if (obj['data']['type'] == "NUTRITIONIST") {
      this.type = 'NUTRITIONIST'
    }
    if (obj['data']['type'] == "PSYCHIATRIST") {
      this.type = 'PSYCHIATRIST'
    }
    if (obj['data']['type'] == "PHYSIOTHERAPIST") {
      this.type = 'PHYSIOTHERAPIST'
    }
    if (obj['data']['type'] == "DOCTOR") {
      this.type = 'DOCTOR'
    }
    this.submitted = true;
    if (this.resetPassword.invalid) {
      return;
    }
    var pswd = this.globalStorage.getItem('dpswd')
    if (this.resetPassword.value.oldPassword != pswd) {
      return;
    }

    var request = {
      "emailId": this.login['data'].emailId,
      "newPassword": this.resetPassword.value.newPassword,
      "otp": this.resetPassword.value.otp,
      "type": this.type,
      resetPwdLink: window.location.origin
    }
    this.loginService.resetPassword(request).subscribe(res => {
      this.toastr.success("Password changed.")
      if (obj['data']['type'] == "NUTRITIONIST") {
        this.router.navigate(['/nutritionistLogin'])
      }
      if (obj['data']['type'] == "PSYCHIATRIST") {
        this.router.navigate(['/psychiatristLogin'])
      }
      if (obj['data']['type'] == "PHYSIOTHERAPIST") {
        this.router.navigate(['/physiotherapistLogin'])
      }
      if (obj['data']['type'] == "DOCTOR") {
        this.router.navigate(['/Login'])
      }
    }, error => {
      this.toastr.error(error.error.message)
    })
  }


  close() {
    // this.dialogRef.close();
  }

  // checkPassword() {
  //   if (this.resetPassword.value.oldPassword.length != 0 && this.resetPassword.value.oldPassword.length != 0 && this.resetPassword.value.oldPassword.length != 0) {
  //     if (this.resetPassword.value.newPassword != this.resetPassword.value.confirmPassword) {
  //       this.isGetOtp = false
  //       //this.isResendOtp = false
  //       this.toastr.error('New Password and Confirm Password do not match')
  //       return
  //     }
  //     else if ((this.resetPassword.value.oldPassword != this.resetPassword.value.newPassword) && (this.resetPassword.value.oldPassword != this.resetPassword.value.confirmPassword)) {
  //       this.showOTP = true;
  //     }
  //   }
  // }

  checkPassword() {
    const oldPassword = this.resetPassword.value.oldPassword;
    const newPassword = this.resetPassword.value.newPassword;
    const confirmPassword = this.resetPassword.value.confirmPassword;
  
    // Check if all fields are filled
    if (oldPassword && newPassword && confirmPassword) {
      // Check if new password and confirm password match
      if (newPassword === confirmPassword) {
        // Check if old password is different from both new and confirm passwords
        if (oldPassword == this.storedPassword && oldPassword !== newPassword && oldPassword !== confirmPassword) {
          // All conditions met, show the OTP input field
          this.showOTP = true;
          this.sendOTP();
        } else {
          // Old password matches either new or confirm password
          // this.toastr.error('Old Password should not match with New Password or Confirm Password');
          this.toastr.error('Enter the correct Old Password');
          this.showOTP = false;
        }
      } else {
        // New password and confirm password do not match
        this.toastr.error('New Password and Confirm Password do not match');
        this.showOTP = false;
      }
    }else{
      this.toastr.error('Required fields can not be empty!');
    }
  }

  loginUser
  sendOTP() {
    this.userName = this.login['data'].userName;

    if ((!this.resetPassword.value.newPassword) || (!this.resetPassword.value.confirmPassword) || (!this.resetPassword.value.oldPassword!)) {
      this.toastr.error('Enter Required fields')
      return
    }
    if (this.resetPassword.value.newPassword != this.resetPassword.value.confirmPassword) {
      this.toastr.error('New Password and Confirm Password must match')
      return
    }
    if ((this.resetPassword.value.oldPassword === this.resetPassword.value.confirmPassword) || ((this.resetPassword.value.oldPassword === this.resetPassword.value.newPassword))) {
      this.toastr.error('New Password and Confirm Password shouldnt be same as old Password ')
      return
    }
    this.loginService.resendEmailOTPForChangePasswordD(this.login['data'].emailId, this.userName).subscribe(res => {

      this.observableTimer();
      this.isGetOtp = true;
      this.isResendOtp = true;
      this.toastr.success(" Check your email for OTP.")
    }, error => {

      this.isTimer = false;
      this.toastr.error("Error in send OTP")
    });

  }

  observableTimer() {
    this.isTimer = true;
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isSend = false;
        this.isResend = true;
        this.isGetOtp = false;
        this.isResendOtp = false;
        this.toastr.error('OTP expired. Click Resend OTP for new OTP.');
        this.isTimer = false;
      }
    });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });
    $("#show_hide_password1 a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password1 input').attr("type") == "text") {
        $('#show_hide_password1 input').attr('type', 'password');
        $('#show_hide_password1 i').addClass("fa-eye-slash");
        $('#show_hide_password1 i').removeClass("fa-eye");
      } else if ($('#show_hide_password1 input').attr("type") == "password") {
        $('#show_hide_password1 input').attr('type', 'text');
        $('#show_hide_password1 i').removeClass("fa-eye-slash");
        $('#show_hide_password1 i').addClass("fa-eye");
      }
    });
    $("#show_hide_password2 a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password2 input').attr("type") == "text") {
        $('#show_hide_password2 input').attr('type', 'password');
        $('#show_hide_password2 i').addClass("fa-eye-slash");
        $('#show_hide_password2 i').removeClass("fa-eye");
      } else if ($('#show_hide_password2 input').attr("type") == "password") {
        $('#show_hide_password2 input').attr('type', 'text');
        $('#show_hide_password2 i').removeClass("fa-eye-slash");
        $('#show_hide_password2 i').addClass("fa-eye");
      }
    });
  }
  // ngOnInit(): void {
  //   this.form=this.fb.group({
}
