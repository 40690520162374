import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClinicService } from '../clinic.service';
import { ToastrService } from 'ngx-toastr';
import { GlobalStorageService } from 'src/app/global-storage.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';

@Component({
  selector: 'app-clinic-opd-prescriptions',
  templateUrl: './clinic-opd-prescriptions.component.html',
  styleUrls: ['./clinic-opd-prescriptions.component.css']
})
export class ClinicOpdPrescriptionsComponent implements OnInit {
  medicalTestPrescription;MedicationPrescriptions;
  @Input() prescription:any
  constructor(public activeModal:NgbActiveModal,private clinicService:ClinicService,private toaster: ToastrService,
    private controller: PatientScheduleControlService,
    private globalStorage: GlobalStorageService,) { }
  @Input() appointmentInfo: any;
  ngOnInit(): void {
    this.getPrescription(this.appointmentInfo)
  }
  getPrescription(appointmentInfo){
    if(this.globalStorage.getItem('loggedInAs')=='DOCTOR'){
      console.log('prescription',this.prescription)
      if(this.prescription?.containsMedicalPrescription=='Y'){
        this.controller.postGlobal('prescription/getprescriptionswithmedaction?medicalPresciptionId=' + this.prescription?.['medicalPrescriptionId'], this.prescription?.['patientId']).subscribe(res => {
          console.log('presc',res['data'])
          this.MedicationPrescriptions=res['data']?.['medication']
        },error=>{
          this.toaster.error(error?.error?.['message'])
        })
      }
      if(this.prescription?.containsTestPrescription=='Y'){
        this.controller.postGlobal('prescription/getmedicaltestprescriptiondetails?medicalTestPresciptionId=' +this.prescription?.['medicaltestprescriptionId'], this.prescription?.['patientId']).subscribe(res => {
          this.medicalTestPrescription=res['data']?.['medicaltest']
        })
      }
    }
    else{
      var body={
        "appointmentId": this.appointmentInfo?.appointmentId,
        "hospitalAddresId": this.appointmentInfo?.hospitalAddressId,
        "patientId": this.appointmentInfo?.userId
      }
      this.clinicService.getOpdPrescription(body).subscribe(res=>{
        console.log('hi opd patient',res['data'])
        this.MedicationPrescriptions=res['data']?.['medications']
        this.medicalTestPrescription=res['data']?.['medicaltests']
      },error=>{
        this.toaster.error(error?.error?.message)
      })
    }
  }
  closeModal(){
    this.activeModal.close()
  }
}
