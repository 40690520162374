<div class="card no-shadow">
  <div class="card-body">
    <h5 class="card-title pl-0 pt-0">
      <i class="fa fa-file-prescription themecol2" aria-hidden="true"></i>
      Prescriptions
    </h5>


    <div *ngIf="medicalReportsData?.length == 0">
      <div class="no-prescriptions"></div>
    </div>

    <div class="row list">
      <div class=" col-md-6" *ngFor="
        let reports of medicalReportsData
          | paginate
            : {
                id: 'p3',
                itemsPerPage: 6,
                currentPage: p3,
                totalItems: totalItem
              }
      ">
        <div class="patient-widget mt-1 mb-1">
          <div class="patient-top-details">
            <div *ngIf="!isIvf"> <span class="invoice-id"> ID: {{ reports?.medicalPrescriptionId }}</span></div>
            <div *ngIf="isIvf"><span class="invoice-id"> ID: {{ reports?.hospitalprescriptionId }}</span></div>
            <div><span class="date-col"> {{ reports?.lastUpdatedTs | date: "dd-MMM-yyyy" }}</span></div>
          </div>
          <div class="invoice-widget">
            <div class="pat-info-left">
              <div class="pat-info-cont">
                <h4 class="pat-name"><a data-backdrop="static" data-target="#invoice" data-toggle="modal"
                    href="javascript:void(0)"> {{ reports?.doctorName | titlecase }}</a></h4>
                <!---->
              </div>
            </div>
            <div class="pat-info-right float-right">
              <div class=" ">
                <div class=" float-right "><a (click)="viewPrescription(reports)" class="btn btn-sm mr-0 mt-2 p-1"
                    href="javascript:void(0)"><i aria-hidden="true" class="fa fa-file-prescription  patientc3"
                      data-original-title="Prescription" data-placement="top" data-toggle="tooltip"></i></a></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div
          class="col-md-4"
          *ngFor="
            let reports of medicalReportsData
              | paginate
                : {
                    id: 'p3',
                    itemsPerPage: 6,
                    currentPage: p3,
                    totalItems: totalItem
                  }
          "
        >
          <div
            class=" d-flex flex-row mb-0 p-2 border-bottom w-100 card  float-left  blt-2 mb-2 mt-1"
            data-toggle="modal"
            data-backdrop="static"

          >
            <a class="p-2 icon-appointments" href="javascript:void(0)">
              <i
                aria-hidden="true"
                class="fa fa-file-pdf icon-col-2 icon-reports"
              ></i
            ></a>
            <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2">
              <a href="javascript:void(0)">
                <p class="font-weight-medium mb-1 mt-1 text-custom" *ngIf="!isIvf">
                 Prescription {{ reports.medicalPrescriptionId }}
                </p>
                <p class="font-weight-medium mb-1 mt-1 text-custom" *ngIf="isIvf">
                  Prescription {{ reports.hospitalprescriptionId }}
                 </p>
                 <p class="text-muted mb-1 text-small" *ngIf="isIvf">
                  {{ reports.doctorName | titlecase }}
                </p>
                <p class="text-muted mb-1 text-small" *ngIf="!isIvf">
                  {{ reports.lastUpdatedTs | date: "dd-MMM-yyyy" }}
                </p></a
              >
            </div>
            <div class="btn-group float-right">
              <button class="btn btn-xs btn0col1">
                <i
                  aria-hidden="true"
                  class="fa fa-eye"
      (click)="viewPrescription(reports)"
                ></i>
              </button>
            </div>
          </div>
        </div> -->
    </div>
  </div>

  <div class="row" *ngIf="!isIvf">
    <div class="col-lg-12 mt-3 text_right">
      <ul class="pagination" *ngIf="medicalReportsData?.length>6">
        <pagination-controls id="p3" (pageChange)="p3 = $event"></pagination-controls>
        <!-- <div *ngIf="medicalReportsData?.length == 0 && p3 > 1" (click)="previousPageMedical()">
Previous Page
          </div> -->
      </ul>
    </div>
  </div>
  <div *ngIf="!isIvf">
    <h5 class="card-title" *ngIf="!isIvf">
      <i class="fa fa-file-prescription themecol2" aria-hidden="true"></i>
      Lab Tests Prescriptions
    </h5>
    <div *ngIf="testReportsData?.length == 0">
      <div class="no-prescriptions"></div>
    </div>
    <div class="row list">
      <div class="col-md-4" *ngFor="
      let reports of testReportsData
        | paginate
          : {
              id: 'p2',
              itemsPerPage: 6,
              currentPage: p2,
              totalItems: totalItem1
            }
    ">
        <div class=" d-flex flex-row mb-0 p-2 border-bottom w-100 card  float-left  blt-2 mb-2 mt-1" data-toggle="modal"
          data-backdrop="static">
          <a class="p-2 icon-appointments" href="javascript:void(0)">
            <i aria-hidden="true" class="fa fa-file-pdf icon-col-2 icon-reports"></i></a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2">
            <a href="javascript:void(0)">
              <p class="font-weight-medium mb-1 mt-1 text-custom">
                Prescription {{ reports.medicaltestprescriptionId }}
              </p>
              <p class="text-muted mb-1 text-small">
                {{ reports.createdDate | date: "dd-MMM-yyyy" }}
              </p>
            </a>
          </div>
          <div class="btn-group float-right">
            <button class="btn btn-xs btn0col1">
              <i aria-hidden="true" class="fa fa-eye" (click)="viewTestPrescription(reports)"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="testReportsData?.length>6 ">
      <div class="col-lg-12 mt-3 text_right">
        <ul class="pagination">
          <pagination-controls id="p2" (pageChange)="p2 = $event"></pagination-controls>
        </ul>
      </div>
    </div>
  </div>
</div>



<div class="modal fade modal-left" id="medicalPrescriptionDoctor" role="dialog" data-backdrop="static"
  data-keyboard="false" aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:40%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Prescripiton Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-prescription-screen [fromAllPrescription]="fromAllPrescription" *ngIf="isData"></app-prescription-screen>
        <!-- <div class="block inv-section">
                <div class="top-inv-col">
                    <div class="inv-logo">
                        <img alt="" src="./assets/img/logo.png">
                    </div>
                      <div class="float-right text-left order-details">
                        <p>Order Id: <span>{{precId}}</span></p>
                        <p>Date: <span>{{appointmentDate | date:'dd-MMM-yyyy'}}</span></p>
                    </div>
                </div>



                <img *ngIf="showImage==true" [src]="newsrc" style="height: 264px" alt="" />
                <iframe *ngIf="showPdf==true" [src]="newsrc" class="pdf-viewer" frameborder="0" scrolling="no" style="height: 500px;width: 200px;">
                  <p>It appears your web browser doesn't support iframes.</p>
                  </iframe>
                  <table   class="pnv-table table table-striped">
                    <tr>
                        <th class="text-align-left">Medicine</th>
                        <th class="text-align-left">Power</th>
                        <th class="text-align-center">Usage</th>
                        <th class="text-align-center">No of Days</th>
                        <th class="text-align-center">Morning</th>
                        <th class="text-align-center">Evening</th>
                        <th class="text-align-center">Afternoon</th>
                    </tr>
                    <tbody>
                      <tr *ngFor="let medicine of MedicationPrescriptions">
                        <td >{{medicine.medicineName}}</td>
                        <td >{{medicine.power}}</td>
                        <td >{{medicine.usage}}</td>
                        <td >{{medicine.numberOfDays}}</td>
                        <td >{{medicine.morning}}</td>
                        <td >{{medicine.afternoon}}</td>
                        <td >{{medicine.evening}}</td>

                    </tr>
                    </tbody>

                </table>


            </div> -->

      </div>

    </div>

  </div>
</div>
<div class="modal fade modal-right" id="medicalTestPrescriptionDoctor" role="dialog"
  data-backdrop="static" data-keyboard="false" aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:35%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Prescripiton Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="block inv-section">
          <div class="top-inv-col">
            <div class="inv-logo">
              <img alt="" src="./assets/img/logo.png">
            </div>
            <div class="float-right text-left order-details">
              <p>Order Id: <span>{{precId}}</span></p>
              <p>Date: <span>20/07/2019</span></p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h5>Prescription From</h5>
              <ul class="inv-receiver">
                <li class="">Dr. Darren Elder<br> 806 Twin Willow Lane, Old Forge,<br> Newyork, USA</li>
              </ul>
              <h5>Invoice To</h5>
              <ul class="inv-receiver">
                <li class="">Dr. Darren Elder<br> 806 Twin Willow Lane, Old Forge,<br> Newyork, USA</li>
              </ul>
            </div>
          </div>
          <img *ngIf="showImage==true" [src]="newsrc" style="height: 264px" alt="" />
          <iframe *ngIf="showPdf==true" [src]="newsrc" class="pdf-viewer" frameborder="0" scrolling="no"
            style="height: 600px;">
            <p>It appears your web browser doesn't support iframes.</p>
          </iframe>


          <table class="pnv-table table table-striped">
            <tr>
              <th class="text-align-left">Test Name</th>
              <th class="text-align-left">Instructions</th>

            </tr>
            <tbody>
              <tr *ngFor="let medicine of MedicalTestPrescriptions">
                <td>{{medicine.testName}}</td>
                <td>{{medicine.instructions}}</td>


              </tr>
            </tbody>

          </table>


        </div>

      </div>

    </div>
  </div>
</div>
<div class="modal fade modal-left" id="viewTestPrescription" role="dialog" data-backdrop="static"
  data-keyboard="false" aria-labelledby="exampleModalRight" aria-hidden="true">
  <div class="modal-dialog" role="document" style="min-width:40%;" id="pdfTable" #pdfTable>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Lab Tests Prescripiton Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="contentToConvertTwo">
        <div class="block inv-section">
          <div class="top-inv-col row">
            <div class="inv-logo col-md-6">
              <img alt="" src='./assets/img/logo.png'>
            </div>
            <div class="order-details float-right text-left">
              <p>Order Id: <span>{{precId}}</span></p>
              <p>Date: <span>{{testPrescDate | date: 'dd-MMM-yyyy'}}</span></p>
            </div>
          </div>
          <div class="row   pl-2">
            <div class="col-md-6">
              <h5>Prescription From</h5>
              <ul class="inv-receiver">
                <li class="" *ngIf="doctorName!='SELF'">Dr.{{doctorName}}<br>{{dStreet1}} {{dStreet2}}<br>
                  {{dCity}}-{{dZip}}<br>{{dState}}&nbsp;{{dCountry}}</li>
                <li class="" *ngIf="doctorName=='SELF'">{{patientName}}<br>{{pstreet1}} {{pstreet2}}<br>
                  {{pcity}}-{{pzip}}<br>{{pstate}}&nbsp;{{pcountry}}</li>
              </ul>
            </div>
            <p></p>
            <div class="float-right text-left" *ngIf="doctorName!='SELF'">

              <h5>Prescription To</h5>
              <ul class="inv-receiver">
                <li class="">{{patientName}}<br>{{pstreet1}} {{pstreet2}}<br>
                  {{pcity}}-{{pzip}}<br>{{pstate}}&nbsp;{{pcountry}}</li>
              </ul>
            </div>
          </div>
          <img *ngIf="showImage==true" [src]="newsrc" style="height: 264px" alt="" />
          <iframe *ngIf="showPdf==true" [src]="newsrc" class="pdf-viewer" frameborder="0" scrolling="no"
            style="height: 600px;">
            <p>It appears your web browser doesn't support iframes.</p>
          </iframe>


          <!-- <table   class="pnv-table table table-striped">
                    <tr>
                      <th class="text-align-left">Test Name</th>
                      <th class="text-align-left">Instructions</th>

                    </tr>
                    <tbody>
                      <tr *ngFor="let medicine of medicalTestPrescription">
                        <td>{{medicine.testName}}</td>
                        <td>{{medicine.instructions}}</td>


                      </tr>
                    </tbody>

                  </table> -->
          <ol class="prescription-ol pl-0">
            <li *ngFor="let row of medicalTestPrescription;let i =index"><i>{{row.testType}}</i> <b> {{row.testName}}
              </b><span> ({{row.instructions}})</span>

              <!-- <button  class="btn btn-primary btn-sm mr-2 float-right"><i  class="fa fa-pencil-alt "></i></button> -->

              <ul style="padding-left: 10px">
                <li style="list-style: none">
                  {{row.notes}}</li>
                <li style="list-style: none"></li>
              </ul>
            </li>
          </ol>


        </div>

      </div>

    </div>
  </div>
</div>
