import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { InternalMessageService } from '../internal-message.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.css']
})
export class SendMessageComponent implements OnInit {

  messageForm:FormGroup;submitted:boolean=false;selectedDepartment;departmentId;departments;
  selectedRole;allRoles;selectedPerson;textareaValue
  @Input() addressId
  @ViewChild('myTextarea') myTextarea: ElementRef;
  constructor(private globalStorage: GlobalStorageService, private messageService:InternalMessageService,private formBuilder: FormBuilder, 
    public activeModal: NgbActiveModal,private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.messageForm=this.formBuilder.group({
      "message": ['', Validators.required],
      "department": [''],
      "name": [''],
      "role":[' ']
    })
    this.fetchDepartments()
    this.getALLroles()
  }
  get messageFormControls() {
    return this.messageForm.controls;
  }
  sendMessage(){

  }
  drpdownSelectionDepartment(data){

  }
  getSelectedDept(data){
    this.departmentId=data?.departmentId
  }
  fetchDepartments() {
    this.messageService.getdepartmentInformation(this.addressId).subscribe(res => {
      this.departments = res?.['data'];
    },
      error => {
        this.toastr.error(error?.['error']?.['message']);
      }
    )
  }
  drpdownForSelectionRole(data,evnt){
    console.log('status',data,evnt)
  }
  selectedRoles(data){
    console.log('roles',data)
    this.selectedRole=data
    this.getUsersByRole()
  }
  usersList
  getAllUsers(){
    var req = {
      "flag": "Y",
      "hospitalAddressId": this.addressId,
      "type": this.selectedRole?.roleName
    }
    this.messageService.getAllAssociatedUsers(req).subscribe(res=>{
      this.usersList=res['data']
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
  getUsersByRole(){
    this.messageService.getUsersByRole(this.addressId,this.selectedRole?.roleId,this.departmentId).subscribe(res=>{
      this.usersList=res['data']
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
  selectedData=[]
  selectedPersonData(data){
    // this.selectedData=data
  }
  selectedUsers=[]
  drpdownForSelection(data){
    this.selectedUsers=[]
    console.log('selected',data)
    this.selectedData=data
  }
  getALLroles(){
    this.messageService.allroles().subscribe(res=>{
      this.allRoles=res['data']
    })
  }
  buildArrayList(){
    var body={
      "addressId": this.addressId,
      "message": this.messageForm?.get('message').value,
      "messageType": "Normal",
      "msgOwner": parseInt(this.globalStorage.getItem('forUserId')),
      "departmentId":this.departmentId,
      "roleId": this.selectedRole?.roleId
    }
    if(this.selectedData?.length==0 && (this.selectedRole?.roleId || this.departmentId)){
      this.selectedUsers?.push(body)
    }
    else{
      var result
      for(var i=0;i<this.selectedData?.length;i++){
        result={
          "addressId":this.addressId,
          "message":this.messageForm?.get('message').value,
          "messageType":"Normal",
          "msgOwner":parseInt(this.globalStorage.getItem('forUserId')),
          "roleId":this.selectedRole?.roleId,
          "userId":this.selectedData?.[i]?.['hospitalUserId']?this.selectedData?.[i]?.['hospitalUserId']:this.selectedData?.[i]?.['ivfuserId']
        }
        if(this.selectedUsers?.some(user => user?.userId === result?.['userId'])){
  
        }
        else{
          this.selectedUsers?.push(result)
        }
      }
    }
  }
  createMessage(){
    this.buildArrayList()
    console.log('uniques',this.selectedUsers)
    if(this.selectedUsers?.length==0){
      this.toastr.warning("Please select recipient")
      return
    }
    var body={
        "message":this.selectedUsers
    }
    this.messageService.createMessage(body).subscribe(res=>{
      this.toastr.success('Message sent')
      this.activeModal.close()
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
}
