<div class="modal-header">
    <h5 class="modal-title">
        <i aria-hidden="true" class="fas fa-shipping-fast"></i> Dispatch Information
    </h5>
    <button type="button" class="close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="my-2 px-2 modal-body pt-0">
    <div class="position-relative">
        <h5>Category: <span class="text-success">{{stockObj?.catageryType}}</span></h5>
        <h5>Sub Category: <span class="text-success">{{stockObj?.subCatagery}}</span></h5>

        <button class="btn btn-success btn-xs dispatch-btn" 
        (click)="saveDispatch()"><i class="fa fa-plus"></i> Dispatch</button>
    </div>
    <table class="table table-hover table-striped">
        <thead>
            <tr>
                <th>Batch</th>
                <th>Dispatched To</th>
                <th>Dispatched By</th>
                <th>Dispatch Date</th>
                <th>Requested By</th>
                <th>Requested Date</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of orderDispatch | paginate: { id: 'p3', itemsPerPage: 10, currentPage: p3 }">
                <td>{{data?.batchNumber}}</td>
                <td>{{data?.dispatchedTo}}</td>
                <td>{{data?.dispatchedBy}}</td>
                <td>{{data?.dispachedOn | date:'dd-MMM-yyyy'}}</td>
                <td>{{data?.dispatchRequestedBy}}</td>
                <td>{{data?.dispatchRequestedOn | date:'dd-MMM-yyyy'}}</td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="orderDispatch?.length == 0">
        <div class="no-data-found"></div>
    </div>

    <div *ngIf="orderDispatch?.length > 10">
        <div class="text-right">
            <ul class="pagination">
                <pagination-controls autoHide="true" id="p3" (pageChange)="p3=$event"></pagination-controls>
            </ul>
        </div>
    </div>
</div>