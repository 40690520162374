<link rel='stylesheet' href="./../../../../assets/landing/css/core.css">
<link rel='stylesheet' href="./../../../../assets/landing/css/base.css">

<footer class="footer widget-footer clearfix">
    <div class="first-footer ttm-bgcolor-skincolor">
        <div class="container">
            <div class="row">
                <div class="widget-area col-xs-12 col-sm-12 col-md-6 col-lg-4">
                    <aside class="widget widget-text">
                        <!--featured-icon-box-->
                        <div class="featured-icon-box iconalign-before-heading">
                            <div class="featured-icon">
                                <div
                                    class="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                                    <i class="fa fa-phone" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="featured-title">
                                <h4>Have a question? call us now</h4>
                                <h5>+1800-266-18023</h5>
                            </div>
                        </div><!-- featured-icon-box end-->
                    </aside>
                </div>
                <div class="widget-area col-xs-12 col-sm-12 col-md-6 col-lg-4">
                    <aside class="widget widget-text">
                        <!--featured-icon-box-->
                        <div class="featured-icon-box iconalign-before-heading">
                            <div class="featured-icon">
                                <div
                                    class="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="featured-title">
                                <h4>For any questions, write to us at:</h4>
                                <h5>support@curemetric.com</h5>
                            </div>
                        </div><!-- featured-icon-box end-->
                    </aside>
                </div>
                <div class="widget-area col-xs-12 col-sm-12 col-md-6 col-lg-4">
                    <aside class="widget widget-text">
                        <!--featured-icon-box-->
                        <div class="featured-icon-box iconalign-before-heading">
                            <div class="featured-icon">
                                <div
                                    class="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-sm ttm-icon_element-style-square">
                                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                                </div>
                            </div>
                            <div class="featured-title">
                                <h4>We are open</h4>
                                <h5>24 x 7</h5>
                            </div>
                        </div><!-- featured-icon-box end-->
                    </aside>
                </div>
            </div>
        </div>
    </div>
    <div class="second-footer ttm-textcolor-white">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
                    <div class="widget widget_text clearfix">
                        <h3 class="widget-title">About Curemetric</h3>
                        <div class="textwidget widget-text">
                            We are one of a kind healthcare marketplace and aggregator service.
                            <br><br>Our Marketplace enables various healthcare providers to compete for customers by
                            offering a variety of plans based on cost and need.
                            <br><br>
                            <div class="social-icons social-hover">
                                <ul class="list-inline">
                                    <li class="social-facebook"><a class="tooltip-top" target="_blank"
                                            href="https://www.facebook.com/Curemetric" data-tooltip="Facebook"><i
                                                class="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    <li class="social-twitter"><a class="tooltip-top" target="_blank"
                                            href="https://twitter.com/Cure_Metric" data-tooltip="Twitter"><i
                                                class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li class="social-linkedin"><a class=" tooltip-top" target="_blank"
                                            href="https://www.linkedin.com/company/curemetric"
                                            data-tooltip="LinkedIn"><i class="fa fa-linkedin"
                                                aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
                    <div class="widget style2 widget-out-link clearfix">
                        <h3 class="widget-title">Latest News</h3>
                        <ul class="widget-post ttm-recent-post-list">
                            <li>
                                <a #nogo=""><img src="https://curemetric.com/images/vector_page/post-001-720x544.jpg"
                                        alt="post-img"></a>
                                <span class="post-date">April 1, 2022</span>
                                <a href="javascript:void(0)">How much aspirin to take for..</a>
                            </li>
                            <li>
                                <a #nogo=""><img src="https://curemetric.com/images/vector_page/post-002-720x544.jpg"
                                        alt="post-img"></a>
                                <span class="post-date">April 1, 2022</span>
                                <a href="javascript:void(0)">Implant Surgical equipment..</a>
                            </li>
                            <li>
                                <a #nogo=""><img src="https://curemetric.com/images/vector_page/post-003-720x544.jpg"
                                        alt="post-img"></a>
                                <span class="post-date">April 05, 2022</span>
                                <a href="javascript:void(0)">The Benefits of Middle-Age..</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
                    <div class="widget widget_nav_menu menu-footer-services-menu clearfix">
                        <h3 class="widget-title">Our Services</h3>
                        <ul id="menu-footer-services-menu" class="menu">
                            <li><a routerLink="/patientLogin">User</a></li>
                            <li><a routerLink="/Login">Doctor</a></li>
                            <li><a routerLink="/DiagnosticLogin">Diagnostic </a></li>
                            <li><a routerLink="/pharmaLogin">Pharmacy </a></li>
                            <li><a routerLink="/caretakerLogin"> Caretaker </a></li>
                            <li><a routerLink="/nutritionistLogin">Nutritionist </a></li>
                            <li><a routerLink="/clinicLogin"> Clinic</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-footer-text ttm-textcolor-white">
        <div class="container">
            <div class="row copyright">
                <div class="col-md-12">
                    <span class="float-left">Copyright © {{ currentYear }} CureMetric Pvt. Ltd. All Rights Reserved. </span>
                    <ul id="menu-footer-menu" class="footer-nav-menu float-right">
                        <li><a href="https://curemetric.com/terms.html">Terms and Conditions</a></li>
                        <li><a href="https://curemetric.com/privacy-policy.html">Privacy Policy</a></li>
                        <li><a href="https://curemetric.com/advertising-policy.html">Advertising Policy</a></li>
                        <li><a href="https://curemetric.com/return-policy.html">Return Policy</a></li>
                        <li><a href="https://curemetric.com/shipping-policy.html">Shipping Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<!--back-to-top start-->
<a id="totop" href="#top">
    <i class="fa fa-angle-up"></i>
</a>
<!--back-to-top end-->
