// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  billdeskSDK: {
    css: "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.css",
    esmJs: "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.esm.js",
    js: "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk.js"
  },
  firebase: {
    apiKey: "AIzaSyBWBi-AasccOQlkBtMkSthy3WkUnUJNeGQ",
    authDomain: "curemetric-ivf.firebaseapp.com",
    projectId: "curemetric-ivf",
    storageBucket: "curemetric-ivf.appspot.com",
    messagingSenderId: "969717061435",
    appId: "1:969717061435:web:1b807ef29b09e6bacd491e",
    measurementId: "G-7VZP3KRETQ"
  },


  ivfUrl1: 'https://dev.cure-metric.com/api/ivf/',
  doctorBaseUrl1: 'https://dev.cure-metric.com/api/doctor/',
  patientBaseUrl1: 'https://dev.cure-metric.com/api/patient/',
  pharmaUrl1: "https://dev.cure-metric.com/api/pharmacy/",
  diagnosticBaseUrl1: "https://dev.cure-metric.com/api/diagnosticcenter/",
  caretakerUrl1: "https://dev.cure-metric.com/api/caretaker/",
  nutritionistUrl1: "https://dev.cure-metric.com/api/nutritionist/",
  elesticSearchUrl1: "https://dev.cure-metric.com/api/elasticsearch/",
  utilitiesUrl1: "https://dev.cure-metric.com/api/utilities/",
  hospitalUrl1: "https://dev.cure-metric.com/api/hospital/",
  rpmAdminUrl1: "https://dev.cure-metric.com/api/rpmadmin/",
  pdfUrl1 : 'https://dev.cure-metric.com/api/pdf-generator/',


  doctorBaseUrl: '/api/doctor/',
  patientBaseUrl: '/api/patient/',
  pharmaUrl: "/api/pharmacy/",
  diagnosticBaseUrl: "/api/diagnosticcenter/",
  caretakerUrl: "/api/caretaker/",
  nutritionistUrl: "/api/nutritionist/",
  elesticSearchUrl: "/api/elasticsearch/",
  utilitiesUrl: "/api/utilities/",
  hospitalUrl: "/api/hospital/",
  rpmAdminUrl: "/api/rpmadmin/",
  ivfUrl: '/api/ivf/',
  pdfUrl: '/api/pdf-generator/'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
