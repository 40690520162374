import { GlobalStorageService } from 'src/app/global-storage.service';
import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApicallsService } from '../apicalls.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  baseURL = environment.doctorBaseUrl;
  patientBaseURL = environment.patientBaseUrl;
  pharmaURL=environment.pharmaUrl
  diagnosticBaseUrl = environment.diagnosticBaseUrl;
  constructor(private globalStorage: GlobalStorageService, private httpClient: HttpClient, private apiServices: ApicallsService) {
    const hostname = window.location.hostname;

    if (hostname === 'localhost') {
    this.baseURL = environment.doctorBaseUrl1;
    this.patientBaseURL = environment.patientBaseUrl1;
    this.pharmaURL=environment.pharmaUrl1
    this.diagnosticBaseUrl = environment.diagnosticBaseUrl1;
    }
   }

 

  Login(request: any) {
    return this.apiServices.post(this.baseURL + "login",
      request,
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', Accept: 'application/json', 'accept': '*/*' }) }
    )
  }


  LogOut() {
    return this.apiServices.post(this.baseURL + "logoutasdoctor", {}, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }

  forgotPassword(forgotPassword: any) {
    return this.apiServices.post(this.baseURL + "password/reset", forgotPassword, {})
  }


  changePassword(changePassword: any) {
    return this.apiServices.post(this.baseURL + "password/change", changePassword, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*", 'user-session': JSON.parse(this.globalStorage.getItem('userSession')) }) })
  }

  doctorVerifyEmail(vid: string, emailid) {
    return this.apiServices.get(this.baseURL + "verification/email/" + vid.trim() + '?emailId=' + emailid, {})
  }

  diagnosticVerifyEmail(vid: string, emailid) {
    return this.apiServices.get(this.diagnosticBaseUrl + "verification/email/" + vid.trim() + '?emailId=' + emailid, {})
  }

  verifyOTP(OTP: string, email: string) {
    let params = new HttpParams().set('uiHostURL', window.location.origin + '/cureMetric');
    return this.apiServices.get(this.baseURL + 'verification/phone/' + parseInt(OTP.trim()) + "?verificationId=" + email.trim(), { params, headers: new HttpHeaders({ 'Content-Type': 'application/json', 'user-session': JSON.parse(this.globalStorage.getItem('userSession')), "accept": "*/*" }) })
  }
  resendOTP(emailid) {
    return this.apiServices.get(this.baseURL + 'verification/request/phone/otp?actionName=changepassword&emailId=' + emailid, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })

  }
  resendEmailOTP(emailid) {
    return this.apiServices.get(this.baseURL + 'verification/request/email/otp?emailid=' + emailid, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }
  resendEmailOTPForChangePassword(emailid) {
    return this.apiServices.get(this.baseURL + 'resendOTPForEmail?emailid=' + emailid, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }

  resendEmailOTPForVerification(emailid, userName) {
    return this.apiServices.get(this.baseURL + 'resendOTPForEmail?emailid=' + emailid + '&username=' + userName, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }

  resendEmailOTPForChangePasswordD(emailid, userName) {
    return this.apiServices.get(this.baseURL + 'resendOTPForEmail?emailid=' + emailid + '&username=' + userName, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }
userNameCheckPharmacy(userName){
    return this.apiServices.get(this.pharmaURL + 'isuserNameExists?userName=' + userName, {})
}
  otpVerify(body) {
    return this.apiServices.post(this.baseURL + 'otpVerificationForEmail',
      body, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
  }

  resendPhoneOtp(mobilenumber) {
    return this.apiServices.get(this.baseURL + 'verification/request/phone/otp?phonenumber=' + mobilenumber, { headers: new HttpHeaders({ 'Content-Type': 'application/json', "accept": "*/*" }) })
  }
  resetPassword(request: any): Observable<any> {
    return this.apiServices.post(this.baseURL + 'password/reset',
      request, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), observe: 'response' })
  }
  checkOldPassword(email,password){
    return this.apiServices.get(this.baseURL+'oldPaswwordVerification?emailid='+email+'&paswword='+password,{})
  }
}
