<div class="modal-header">
          <h5 class="modal-title">Appointment Details</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"  (click)="activeModal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
</div>
<div class="modal-body">
          <div class="schedule-tab">
            <h3 class="heading ml-2" *ngIf="appointmentData?.appointmentFor=='DOCTOR'"><i class="fa fa-user text-warning "></i> <span class="ml-1"> Doctor details</span> </h3>
            <h3 class="heading ml-2" *ngIf="appointmentData?.appointmentFor=='DIAGNOSTIC_CENTER'"><i class="fa fa-user text-warning "></i>
              <span class="ml-1"> Diagnostic center Details</span>
            </h3>
            <div class="tab-content">
              <div class="tab-pane active" id="tab_start">
                <div class=" ">
                  <div class="row backgound_imgsidebar mb-3 pt-2 pb-2 pr-2">
                    <div class="col-md-9 col-sm-12">
                      <div class="d-flex flex-row  ">
                        <a class="" href="javascript:void(0)" *ngIf="appointmentData?.appointmentFor=='DOCTOR' || appointmentData?.appointmentFor=='NUTRITIONIST' || appointmentData?.appointmentFor=='CARETAKER'"><img [src]="img"></a>
                        <a class="" href="javascript:void(0)" *ngIf="appointmentData?.appointmentFor=='DIAGNOSTIC_CENTER'"><img src="./assets/img/diagnostic-1.png"></a>
                        <div class="pl-1  d-flex flex-column flex-grow-1  ">
                          <a href="javascript:void(0)">
                            <p class="font-weight-medium mb-0 mt-2" *ngIf="appointmentData?.appointmentFor=='DOCTOR' || appointmentFor=='NUTRITIONIST' || appointmentFor=='CARETAKER'"> Dr.{{appointmentData?.doctorName |titlecase}} <i class="fa fa-check-circle fa-sm faa-shake text_color_green animated" aria-hidden="true"></i></p>
                            <p class="font-weight-medium mb-0 mt-2" *ngIf="appointmentData?.appointmentFor=='DIAGNOSTIC_CENTER'">
                              {{referenceEntityName |titlecase}} <i class="fa fa-check-circle fa-sm faa-shake text_color_green animated" aria-hidden="true"></i>
                            </p>
                            <p class="text-muted mb-1 text-small mr-2" *ngIf="appointmentData?.appointmentFor=='DOCTOR'">{{specilization}}
                            </p>
                            <p class="text-muted mb-1 text-small mr-2" *ngIf=" appointmentData?.appointmentFor=='NUTRITIONIST'"> Nutritionist</p>
                            <p class="text-muted mb-1 text-small mr-2" *ngIf="appointmentData?.appointmentFor=='CARETAKER'">Caretaker</p>
                            <div class="d-block">
                              <div *ngIf="rating==1">
                                <span class="fa fa-star checked fs-12" *ngFor='let in of rating | demoNumber ;let i = index'></span>
                                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2,3]'></span>
                              </div>
                              <div *ngIf="rating==2">
                                <span class="fa fa-star checked fs-12" *ngFor='let in of rating | demoNumber ;let i = index'></span>
                                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2]'></span>
                              </div>
                              <div *ngIf="rating==3">
                                <span class="fa fa-star checked fs-12" *ngFor='let in of rating | demoNumber ;let i = index'></span>
                                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1]'></span>
                              </div>
                              <div *ngIf="rating==4">
                                <span class="fa fa-star checked fs-12" *ngFor='let in of rating | demoNumber ;let i = index'></span>
                                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0]'></span>
                              </div>
                              <div *ngIf="rating==0">
                                <span class="fa fa-star  fs-12" style="color:#ccc" *ngFor='let in of [0,1,2,3,4]'></span>
                              </div>
                            </div>
                            <div class="available fs-10 ">{{exp}} Years</div>
                          </a>
                        </div>
                      </div>
                      <div class="row text_left padding_left20 padding_bt p-3">
                        <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                            <div class="row" >
                              <div class="col-md-6">Patient Name :</div> <span class="col-md-6"> {{appointmentData?.patientName}}</span>
                            </div>
                          </div>
                        <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                          <div class="row" *ngIf="appointmentData?.appointmentFor=='DOCTOR' || appointmentData?.appointmentFor=='NUTRITIONIST' || appointmentData?.appointmentFor=='CARETAKER'">
                            <div class="col-md-6">Ailment :</div> <span class="col-md-6"> {{appointmentData?.ailment}}</span>
                          </div>
                        </div>
                        <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                          <div class="row" *ngIf="appointmentFor=='DIAGNOSTIC_CENTER'">
                            <div class="col-md-6">Test Name :</div> <span class="col-md-6"> {{testName}}</span>
                          </div>
                        </div>
                        <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                          <div class="row">
                            <div class="col-md-6">Appointment Date :</div> <span class="col-md-6"> {{appointmentData?.appointmentDate |
                              date:'dd-MMM-yyyy'}}</span>
                          </div>
                        </div>
                        <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                          <div class="row">
                            <div class="col-md-6">Appointment Time :</div> <span class="col-md-6"> {{appointmentData?.fromTime |
                              convertFrom24To12Format}}-{{appointmentData?.toTime | convertFrom24To12Format}}</span>
                          </div>
                        </div>
                        <div class="col-md-12 col-sm-12 textbig_14white pb-1">
                          <div class="row">
                            <div class="col-md-6">Amount Paid :</div> <span class="col-md-6"><i class="fa fa-inr" aria-hidden="true"></i>&nbsp; {{appointmentData?.amountPaid}}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane " id="tab_four">
                <!-- <app-patient-report-sidenav></app-patient-report-sidenav> -->
              </div>
              <!-- Tab pane end -->
            </div>
            <!-- tab content -->
          </div>
</div>
  