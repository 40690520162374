import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CaretakerService } from 'src/app/shared/services/caretaker.service';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { VitalsService } from 'src/app/shared/components/add-vitals/vitals.service';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from 'src/app/shared/services/patient.service';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-caretaker-sidebar',
  templateUrl: './caretaker-sidebar.component.html',
  styleUrls: ['./caretaker-sidebar.component.css']
})
export class CaretakerSidebarComponent implements OnInit {
  notViewedNotifications = [];
  vitalsReports; vitalsForm: FormGroup;
  imgURL; loginUser; tempVitals = []; pressureVitals = []; oxygenVitals = []; appointments; imageSource;
  MyNotification;
  notifyToView;
  p2 = 1; caretakerPersonalDetails; patientnames
  notifications; notificationLength; viewedNotifications;

  constructor(private globalStorage: GlobalStorageService, private router: Router,private fb: FormBuilder, private caretakerServices: CaretakerService,
    private vitalsService: VitalsService, private sanitizer: DomSanitizer,
    private toastrService: ToastrService, private patientService: PatientService) {
     }
  isrpmcaretaker = false
  ngOnInit(): void {
    if (this.globalStorage.getItem('rpmadminAddressId') == 'null') {
      this.isrpmcaretaker = true;
    }

    this.vitalsForm = this.fb.group({
      bloodgroup: [''],
      weight: [''],
      feet: [''],
      inch: [''],
      oxygenLevel: [''],
      temperature: [''],
      pulse: [''],
      systolic: [''],
      diastolic: [''],
      afterLunch: [''],
      beforeLunch: [''],
    })
    var data = JSON.parse(this.globalStorage.getItem('caretakerData'));
    this.loginUser = data;
    this.getNotifications();
    this.getCaretakerPersonalDts();
    this.getLiveVitals();
  }

  file;
  onFileInput(files: File) {
    const f = files;

    if ((f[0].type) == "image/jpeg" || (f[0].type) == "image/png") {
      if (f[0].size > 1048769) {
        this.toastrService.warning('file should be 1 Mb')
      } else {
        this.file = files
      }
    } else {
      this.toastrService.warning('file should be in jpeg or PNG')
    }
    this.saveProfilePic();
  }

  saveProfilePic() {
    var formData = new FormData();
    for (let i = 0; i < this.file.length; i++) {
      formData.append('profilePic', this.file[i], this.file[i].name);
    }
    this.caretakerServices.postProfilePic(this.loginUser.caretakerId, formData).subscribe(res => {
      this.toastrService.success('Profile pic uploaded');
      this.getCaretakerPersonalDts();
    })
  }

  usersList;
  getUsersList() {
    if(this.loginUser?.rpmadminAddressId != null) {
      this.caretakerServices.getRpmUserList(this.loginUser?.caretakerId).subscribe(res=>{
        this.usersList=res['body']['data'];
      },error=>{
        this.toastrService.error(error?.['error']?.['message'])
      })
    }
  }

  getLiveVitals() {
    if(this.loginUser?.rpmadminAddressId != null){
      let rpmAdminaddressId = parseInt(this.globalStorage.getItem('rpmadminAddressId'));
      this.caretakerServices.getVitalsOfCaretakerPatient(this.loginUser.caretakerId, rpmAdminaddressId).subscribe(res => {
        // this.vitalsReports = res['data']
        this.tempVitals = res?.['body']?.['data'].filter(obj => obj.vitalname == "temperature")
        this.pressureVitals = res?.['body']?.['data'].filter(obj => obj.vitalname == "bloodPressure")
        this.oxygenVitals = res?.['body']?.['data'].filter(obj => obj.vitalname == "oxygenLevel")
        // this.appointments = res['data']['appointment']
        this.getUsersList();
      });
    }
  }

  allVitalsToShow; bg; oxy; pls; tem; ds; ss; ht; wt; fst; rdm
  isAllVitals = false
  editVitals(id) {
    this.vitalsService.saveData(id)
    this.isAllVitals = true
    $('#show-all-Vitals').modal('show')
  }

  viewAllVitals(id) {
    this.allVitalsToShow = this.vitalsReports.filter(obj => obj.userId == id)
    for (var i = 0; i < this.allVitalsToShow.length; i++) {
      if (this.allVitalsToShow[i].vitalname == "bloodgroup") {
        this.bg = this.allVitalsToShow[i].vitalValue
      }
      if (this.allVitalsToShow[i].vitalname == "oxygenLevel") {
        this.oxy = this.allVitalsToShow[i].vitalValue
      }
      if (this.allVitalsToShow[i].vitalname == "pulse") {
        this.pls = this.allVitalsToShow[i].vitalValue
      }
      if (this.allVitalsToShow[i].vitalname == "temperature") {
        this.tem = this.allVitalsToShow[i].vitalValue
      }
      if (this.allVitalsToShow[i].vitalname == "systolic") {
        this.ss = this.allVitalsToShow[i].vitalValue
      }
      if (this.allVitalsToShow[i].vitalname == "diastolic") {
        this.ds = this.allVitalsToShow[i].vitalValue
      }
      if (this.allVitalsToShow[i].vitalname == "height") {
        this.ht = this.allVitalsToShow[i].vitalValue
      }
      if (this.allVitalsToShow[i].vitalname == "weight") {
        this.wt = this.allVitalsToShow[i].vitalValue
      }
      if (this.allVitalsToShow[i].vitalname == "fasting") {
        this.fst = this.allVitalsToShow[i].vitalValue
      }
      if (this.allVitalsToShow[i].vitalname == "random") {
        this.rdm = this.allVitalsToShow[i].vitalValue
      }
    }
    $('#show-all-Vitals').modal('show')
    this.vitalsForm.patchValue({
      bloodgroup: this.bg,
      weight: this.wt,
      feet: this.ht?.split('.')?.[0],
      inch: this.ht?.split('.')?.[1],
      oxygenLevel: this.oxy,
      temperature: this.tem,
      pulse: this.pls,
      systolic: this.ss,
      diastolic: this.ds,
      afterLunch: this.rdm,
      beforeLunch: this.fst,
    })
  }
  getNotifications() {
    this.caretakerServices.getNotificationsAlertsCaretaker(this.loginUser.caretakerId).subscribe(res => {
      this.notifications = res['body']['data'];
      this.notificationLength = this.notifications.filter((obj) => obj.viewed == 'N').length;
      this.notViewedNotifications = this.notifications.filter((obj) => obj.viewed == 'N');
      this.viewedNotifications = this.notifications.filter((obj) => obj.viewed == 'Y').length;
    }, error => {
      this.toastrService.error(error?.['error']?.['message']);
    })
  }
  age; gender
  getCaretakerPersonalDts() {
    this.caretakerServices.getPersonalDetails(this.loginUser.caretakerId).subscribe(res => {
      this.caretakerPersonalDetails = res['body']['data']
      var d1 = moment(JSON.stringify(res.body.data.dob));
      var d2 = moment(new Date());
      var days = moment.duration(d2.diff(d1)).asDays();
      var years = days / 360
      this.age = years.toFixed(0);
      this.globalStorage.setItem('age', this.age)
      if (res['body']['data']['gender'] == null) {
        this.gender = this.loginUser?.gender
        this.caretakerPersonalDetails['gender'] = this.gender
      }
      if (res['body']['data']['profilePic'] != null) {
        var src = "data:image/jpeg;base64," + res['body']['data']['profilePic']
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(src);
      }
      else {
        if (res['body']['data']['gender'] == 'male' || res['body']['data']['gender'] == 'Male' || this.gender == 'male' || this.gender == 'Male') {
          this.imgURL = './assets/img/care-taker-male-icon.png'
        }
        else {
          this.imgURL = './assets/img/care-taker-icon.png'
        }
      }

    })
  }

  updateNotify(data) {
    $('#caretaker-sidebar-noficationview').modal('show');
    this.notifyToView = data;
    console.log('my notif:', this.notifyToView)
    var req = {
      "active": data.active,
      "notificationalertsId": data.notificationalertsId,
      "objectkey": data.objectkey,
      "objecttype": data.objecttype,
      "caretakerId": data.caretakerId,
      "reason": data.reason,
      "viewed": "Y"
    }
    this.caretakerServices.updateNotifications(data.notificationalertsId, data.caretakerId, req).subscribe(res => {
      this.notificationLength = this.notificationLength - 1;
      this.getNotifications();
    },
      error => {
        this.toastrService.error(error?.['error']?.['message']);
      }
    )
  }

  closeNotificationViewerModal() {
    $('#caretaker-sidebar-noficationview').modal('hide');
    $("#caretaker-sidebar-noficationview").val(null).trigger("change");
    $('#viewremindersDoctor').modal('hide');
    this.getNotifications();
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

}
