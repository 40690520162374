
<div class="card  " style="min-height:586px">
    <div class="card-body pb-height ">
      <div id="prescriptions" class="presc tab-pane active show">
        <div class="no-prescriptions" *ngIf="medications?.length==0"></div>
            <div class="row list">
        <div class="col-md-4 mb-2"
        *ngFor=" let reports of medications | paginate : { itemsPerPage: 21, currentPage: p2,totalItems:totalResults } ">
        <div class="d-flex flex-row mb-0 p-1    blt-1 card " (click)="viewPrescription(reports)"
          [ngClass]="{' blt-3': getClass(reports.doctorAppointmentId), ' blt-1':reports.doctorName!='self' }">
          <a href="javascript:void(0)" class="p-2 icon-appointments mt-1 " *ngIf="reports.doctorName=='self'"
            data-placement="top">
            <i class="fa fa-user themecol3 fa-2x" aria-hidden="true"></i>
          </a>
          <a href="javascript:void(0)" class="p-2 icon-appointments mt-1 " *ngIf="reports.doctorName!='self'"
            data-placement="top" data-toggle="tooltip" data-original-title="View">
            <i class="fa fa-user-md themecol1 fa-2x" aria-hidden="true"></i>
          </a>
          <div class="pl-1 pr-2 d-flex  flex-column flex-grow-1 mr-2 ">
            <a href="javascript:void(0)" href="javascript:void(0)">
              <p class="font-weight-medium mb-1 mt-1">{{reports.doctorName | titlecase}}
              </p>
              <p class="text-muted mb-1 text-small"> {{ reports.appointmentDate| date: "dd-MMM-yyyy" }}</p>
            </a>
          </div>
          <div class=" float-right  ">
          
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="totalResults>21">
      <div class="col-lg-12 mt-0 text_right">
        <ul class="pagination">
          <pagination-controls autoHide="true"  (pageChange)="onPageChange($event)" (click)="getPrescriptions()">
          </pagination-controls>
        </ul>
      </div>
    </div>
  </div>
    </div>
 </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
   
  