import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dialog-component',
  templateUrl: './dialog-component.component.html',
  styleUrls: ['./dialog-component.component.css']
})
export class DialogComponentComponent implements OnInit {
  dayForm:FormGroup;
  days=[]
  @Input() data:any;
  @Input() unAssignDoctorOrCaretaker=false
  @Input() isTreatmentPlanEdit:boolean=false
  @Input() treatmentData:any
  @Input() fromBed:boolean=false
  constructor(public modal:NgbActiveModal,private fb:FormBuilder) { }

  ngOnInit(): void {
    if(this.isTreatmentPlanEdit){
      this.days = Array.from({ length: 45 }, (_, i) => i + 1);
      this.days=this.filterDays(this.days, this.treatmentData);
      this.dayForm=this.fb.group({
        day: ['']
      })
    }
  }
  selectedDay(data){
    this.modal.close(data)
  }
  filterDays(a, b): number[] {
    const daysToRemove = b?.map(item => item?.day);
    return a.filter(day => !daysToRemove.includes(day));
  }
}
