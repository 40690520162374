import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';
import { formatDate } from '@angular/common';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { parseJSON } from 'jquery';
declare var $: any;
@Component({
  selector: 'app-save-bulk-order',
  templateUrl: './save-bulk-order.component.html',
  styleUrls: ['./save-bulk-order.component.css']
})
export class SaveBulkOrderComponent implements OnInit {

  @Input() purchaseItems: any = [];
  @Input() addressId: any;
  @Input() supplieId: any;
  @Input() mode: any;
  @Input() supplierOrderId: any
  @Input() allItems: any = [];
  reason;
  inventoryData: any
  expectedDeliveryDate;
  percentages = [0, 5, 12, 18, 28];
  minDate;
  netPayDays = 0;
  terms = '';
  entityData:any
  entityName:any;
  constructor(private globalStorage: GlobalStorageService, 
    private activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private inventoryService: InventoryService,
    private clinicService: ClinicService,
    private modalService: NgbModal) {
    this.minDate = new Date();
  }

  ngOnInit(): void {
    this.entityName=parseJSON(this.globalStorage.getItem('ivfDetails'));
    if (this.allItems.length > 0) {
      this.getAllItemsInv()
      return
    }
    this.purchaseItems?.map(item => {
      item['totalStockOrdered'] = 0;
      item['quantityToBeReceived'] = 0;
      item['gstAmount'] = 0;
      item['totalPrice'] = 0;
      if (item?.cgst) {
        item['totalGst'] = parseFloat(item?.cgst) + parseFloat(item?.sgst)
      }

      if (item?.igst) {
        item['totalGst'] = parseFloat(item?.igst)
      }

    })
    if (this.mode == 'ORDEREDIT') {
      this.getMultipleOrders()
    }
    else if (this.mode == 'REORDER') {
      this.getReorderInventory()
    }
    else if (this.mode == 'ORDERVIEW') {
      this.getMultipleOrders()
      this.getInvoiceData()
    }
    else {
      this.getInventory()
    }
  }

  splitGst(){
    return this.supplierDetails?.state == this.entityData?.state
  }

  supplierDetails:any
  dataLoading:boolean=false;
  getAllItemsInv() {

    const itemIdList = this.allItems.map(item => item.itemTypeId);
    const body = {
      "itemTypeId": itemIdList,
      "supplierId": this.supplieId,
    }
    this.dataLoading=true;
    this.inventoryService.supplierOrderItemList(body).subscribe(res => {
      // console.log('get inventory', res)
      this.inventoryData = res['data']['supplierItemResponceDTO']
      this.purchaseItems = res['data']['supplierItemResponceDTO']
      this.supplierDetails= res['data']['supplierAddressDTO']
      this.entityData=res['data']?.['addressDTO']
      this.dataLoading=false;

      this.purchaseItems?.map(item => {
        item['totalStockOrdered'] = 0;
        item['quantityToBeReceived'] = 0;
        item['gstAmount'] = 0;
        item['totalPrice'] = 0;
        item['totalStockOrdered'] = 0;
        if (item?.cgst) {
          item['totalGst'] = parseFloat(item?.cgst) + parseFloat(item?.sgst)
        }

        if (item?.igst) {
          item['totalGst'] = parseFloat(item?.igst)
        }

      })
    }, error => {
      this.toaster.error(error?.error?.message)
    })
  }


  getReorderInventory() {
    this.dataLoading=true;
    this.inventoryService.getMultipleOrdersList(this.addressId, this.supplierOrderId).subscribe(res => {
      // console.log('get inventory', res)
      this.dataLoading=false;
      this.inventoryData = res['data']?.['orderItem']
      this.purchaseItems = res['data']?.['orderItem']
      this.supplierDetails=res['data']?.['supplierAddressDTO']
      this.entityData=res['data']?.['addressDTO']
      this.purchaseItems?.map(item => {
        item['totalStockOrdered'] = 0;
        item['quantityToBeReceived'] = 0;
        item['gstAmount'] = 0;
        item['totalPrice'] = 0;
        item['totalStockOrdered'] = 0;
        if (item?.cgst) {
          item['totalGst'] = parseFloat(item?.cgst) + parseFloat(item?.sgst)
        }

        if (item?.igst) {
          item['totalGst'] = parseFloat(item?.igst)
        }


        if (item?.tax) {
          item['totalGst'] = parseFloat(item?.tax)
        }
      })
    }, error => {
      this.toaster.error(error?.error?.message)
    })
  }
  invoiceData
  getInvoiceData() {
    this.dataLoading=true;
    this.inventoryService.getInventoryInvoice(this.addressId, this.supplierOrderId).subscribe(res => {
      // console.log('get inventory', res)
      this.dataLoading=false;
      this.invoiceData = res['data']
      this.expectedDeliveryDate = res['data']?.['expectedDeliveryDate']
    }, error => {
      this.toaster.error(error?.error?.message)
    })
  }

  getInventory() {
    var ids = []
    console.log('get list', this.purchaseItems)
    for (var i = 0; i < this.purchaseItems?.length; i++) {
      ids[i] = this.purchaseItems?.[i]?.itemTypeId
    }

    const body = {
      "itemTypeId": ids,
      "supplierId": this.supplieId,
    }
    this.dataLoading=true;
    this.inventoryService.supplierOrderItemList(body).subscribe(res => {
      this.dataLoading=false;
      this.inventoryData = res['data']['supplierItemResponceDTO']
      this.purchaseItems = res['data']['supplierItemResponceDTO']
      this.supplierDetails= res['data']['supplierAddressDTO']
      this.entityData=res['data']?.['addressDTO']
      this.purchaseItems?.map(item => {
        item['totalStockOrdered'] = 0;
        item['quantityToBeReceived'] = 0;
        item['gstAmount'] = 0;
        item['totalPrice'] = 0;
        if (item?.cgst) {
          item['totalGst'] = parseFloat(item?.cgst) + parseFloat(item?.sgst)
        }

        if (item?.igst) {
          item['totalGst'] = parseFloat(item?.igst)
        }

      })

    }, error => {
      this.toaster.error(error?.error?.message)
    })
  }

  getMultipleOrders() {
    this.dataLoading=true;
    this.inventoryService.getMultipleOrdersList(this.addressId, this.supplierOrderId).subscribe(res => {
      // console.log('get inventory', res)
      this.dataLoading=false;
      this.inventoryData = res['data']?.['orderItem']
      this.expectedDeliveryDate = res['data']?.['expectedDeliveryDate']
      this.purchaseItems = res['data']?.['orderItem']
      this.supplierDetails=res['data']?.['supplierAddressDTO']
      this.entityData=res['data']?.['addressDTO']
      this.reason = this.purchaseItems?.[0]?.['reason']
      for (var i = 0; i < this.purchaseItems?.length; i++) {
        if (this.purchaseItems[i]?.['igst']) {
          this.purchaseItems[i]['totalGst'] = this.purchaseItems[i]?.igst
        }
        if (this.purchaseItems[i]?.['tax']) {
          this.purchaseItems[i]['totalGst'] = this.purchaseItems[i]?.tax
        }
        if (this.purchaseItems[i]?.['cgst']) {
          this.purchaseItems[i]['totalGst'] = this.purchaseItems[i]?.sgst + this.purchaseItems[i]?.cgst
        }

        if (this.purchaseItems[i]?.['buyQuantity']) {
          var itemsFree
          if (this.purchaseItems[i]?.['quantityToBeReceived'] % this.purchaseItems[i]?.['buyQuantity'] === 0) {
            itemsFree = (this.purchaseItems[i]?.['quantityToBeReceived'] / this.purchaseItems[i]?.['buyQuantity']) * this.purchaseItems[i]?.['freeQuantity']
          }
          else {
            itemsFree = (Math.floor(this.purchaseItems[i]?.['quantityToBeReceived'] / this.purchaseItems[i]?.['buyQuantity'])) * this.purchaseItems[i]?.['freeQuantity']
          }
          this.purchaseItems[i]['totalStockOrdered'] = this.purchaseItems[i]?.['quantityToBeReceived'] - itemsFree
        }
        else {
          this.purchaseItems[i]['totalStockOrdered'] = this.purchaseItems[i]?.['totalStockOrdered']        }
        this.purchaseItems[i].gstAmount = parseFloat(this.purchaseItems[i].totalStockOrdered) * parseFloat(this.purchaseItems[i].unitPrice) * (this.purchaseItems[i].totalGst / 100);
        this.calReceiveItem(i)
      }
      this.calcAll()

    }, error => {
      this.toaster.error(error?.error?.message)
    })
  }
  closeModal() {
    this.activeModal.close(true);
  }

  formatNum(num) {
    return parseFloat(num)
  }

  updateSgst(i) {
    if (this.purchaseItems[i]?.cgst > 0) {
      this.purchaseItems[i]['sgst'] = this.purchaseItems[i]?.cgst
      this.purchaseItems[i]['igst'] = 0
      this.calReceiveItem(i)
    }
  }

  updateIgst(i) {
    if (this.purchaseItems[i]?.igst > 0) {
      this.purchaseItems[i]['cgst'] = 0
      this.purchaseItems[i]['sgst'] = 0
      this.calReceiveItem(i)
    }
  }

  calReceiveItem(i) {
    // if (this.purchaseItems[i]?.cgst && this.purchaseItems[i]?.sgst) {
    //   this.purchaseItems[i]['totalGst'] = parseFloat(this.purchaseItems[i]?.cgst) + parseFloat(this.purchaseItems[i]?.sgst)
    // }

    // if (this.purchaseItems[i]?.igst) {
    //   this.purchaseItems[i]['totalGst'] = parseFloat(this.purchaseItems[i]?.igst)
    // }

    if(!this.purchaseItems[i].totalStockOrdered){
      this.purchaseItems[i].totalStockOrdered=0;
    }

    if(!this.purchaseItems[i].unitPrice){
      this.purchaseItems[i].unitPrice=0;
    }
    
    if (this.purchaseItems[i]?.tax) {
      this.purchaseItems[i]['totalGst'] = parseFloat(this.purchaseItems[i]?.tax)
    }else{
      this.purchaseItems[i]['tax']=0
      this.purchaseItems[i]['totalGst']=0
    }

    if (this.purchaseItems[i].freeQuantity) {
      this.purchaseItems[i].quantityToBeReceived = parseFloat(this.purchaseItems[i].totalStockOrdered) + parseFloat(this.purchaseItems[i].freeQuantity);
    } else {
      this.purchaseItems[i].freeQuantity=0
      this.purchaseItems[i].quantityToBeReceived = this.purchaseItems[i].totalStockOrdered;
    }

    // if (this.purchaseItems[i].freeQuantity && this.purchaseItems[i].totalStockOrdered >= this.inventoryData[i]?.['buyQuantity']) {
    //   const freeItems: number = Math.floor(this.purchaseItems[i].totalStockOrdered / parseFloat(this.purchaseItems[i].buyQuantity)) * parseFloat(this.purchaseItems[i].freeQuantity);
    //   this.purchaseItems[i].quantityToBeReceived = this.purchaseItems[i].totalStockOrdered + freeItems;
    // } else {
    //   this.purchaseItems[i].quantityToBeReceived = this.purchaseItems[i].totalStockOrdered;
    // }



    if (this.purchaseItems[i]?.totalGst) {
      this.purchaseItems[i].gstAmount = parseFloat(this.purchaseItems[i].totalStockOrdered) * parseFloat(this.purchaseItems[i].unitPrice) * (this.purchaseItems[i].totalGst / 100);
      this.purchaseItems[i].totalPrice = parseFloat(this.purchaseItems[i].totalStockOrdered) * parseFloat(this.purchaseItems[i].unitPrice) * ((1 + this.purchaseItems[i].totalGst / 100));
    } else {
      this.purchaseItems[i].gstAmount=0;
      this.purchaseItems[i].totalPrice = parseFloat(this.purchaseItems[i].totalStockOrdered) * parseFloat(this.purchaseItems[i].unitPrice);
    }
    if (parseFloat(this.purchaseItems[i]?.discount) > 0) {
      const dsctdAmt = (parseFloat(this.purchaseItems[i].discount) / 100) * this.purchaseItems[i].totalPrice;
      this.purchaseItems[i].totalPrice = this.purchaseItems[i].totalPrice - dsctdAmt
    }else{
      this.purchaseItems[i].discount=0
    }
    

    this.calcAll()
  }

  totalGstPrice = 0;
  totalAmount = 0
  calcAll() {
    this.totalGstPrice = this.purchaseItems.reduce((a, { gstAmount }) => a + gstAmount, 0)
    this.totalAmount = this.purchaseItems.reduce((a, { totalPrice }) => a + totalPrice, 0)
  }

  placeOrder() {
    const orderedDate = formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    const expDeliverDate = formatDate(this.expectedDeliveryDate, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    var body: any
    body = {
      "orderItem": this.purchaseItems,
      "active": "Y",
      "addressId": this.addressId,
      "dateOrdered": orderedDate,
      "expectedDeliveryDate": expDeliverDate,
      "supplierId": this.purchaseItems?.[0]?.supplierId,
      "totalPrice": this.totalAmount,
      "netPayDays": this.netPayDays,
      "termsAndCondition": this.terms
    }
    if (this.mode == 'ORDEREDIT' || this.mode == 'REORDER') {
      body['supplierordersId'] = this.supplierOrderId
      if(!body?.supplierId){
        body['supplierId'] = this.supplieId
      }
      for (var i = 0; i < this.purchaseItems?.length; i++) {
        this.purchaseItems[i]['reason'] = this.reason
      }
    }
    if (this.allItems.length > 0) {
      if(!body?.supplierId){
        body['supplierId'] = this.supplieId
      }    }

      if(!body?.supplierId){
        body['supplierId'] = this.supplieId
      }  
    this.inventoryService.saveBulkOrder(body).subscribe(res => {
      this.toaster.success('Order Placed.');
      this.closeModal();
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )

  }




}
