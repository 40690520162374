<div class="modal-header">
  <h4 class="modal-title">Appointment Booking</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row ">
    <div class="col-md-12 col-sm-12 " *ngIf="!typeselected && !isIvf">Please Select type of Appointment</div>
    <div class="card-body p-0" style="padding-bottom: 5px !important;">
      <h5 class="card-title bg-primary rounded mr-2 ml-2"
        style="min-height: 30px !important;padding-top: 7px !important; padding-left: 12px !important;">
        Reason</h5>
      <form [formGroup]="reasonForm" class="shadow-nn mr-2 ml-2">
        <div class="input-group input-group-sm form-group mb-0 pl-2" style="height: 35px;">
          <input type="text" class="form-control reason" formControlName="reason" id="kt_subheader_search_form"
            [(ngModel)]="reason" placeholder="Enter Reason for visit" [ngClass]="{
            'is-invalid':
              (submittedReason || r.reason.touched) &&
              r.reason.errors
          }" (keyup)="reasonEnter(reason)">
          <div *ngIf="(submittedReason || r.reason.touched) && r.reason.errors
                              " class="invalid-feedback">
            <div *ngIf="r.reason.errors.required">
              Reason should be specified.
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-lg-12 mt-0 ca-12">
      <div class="row ">
      </div>
    </div>

    <div class="col-lg-6 ca-12 pl-0 pr-0">

      <div class="d-flex flex-row mb-0 pb-2 card border_bgcolor3 ml-2 mr-2 rounded">
        <a class="d-flex align-items-center pb-2 icon-appointments mt-1 " href="javascript:void(0)" style="cursor: default;">
          <img alt="" style="width: 40px;height: 40px;" src="assets/img/profile.png" class=" border-0 m-1 profileimagecss ml-2 mr-2">

        </a>
        <div class="pl-2 pr-2 pb-3 d-flex  flex-column flex-grow-1 mr-2 ">
          <h5 class="font-weight-medium mb-1 mt-1 text-white mt-4">Dr. {{doctorName |titlecase}} </h5>
          <p class="mb-1 text-small  text-white"> {{specializ}} - {{ttlExperience==null?0:ttlExperience}} Years
          </p>
        </div>
        <div class=" float-right" *ngIf="isClinicOrIvf">
          <div class="btn btn-sm mr-1 mt-2 p-1">
            <a href="javascript:void(0)" class="person  btn btn-sm colr-5 ml-2 mt-3 p-2 profile-btns mm_40 fs-23 hs_user"
              style="cursor: default;">
              <i class="fa-solid fa-hospital-user color_green" aria-hidden="true"></i> </a>
          </div>
        </div>

        <div class=" float-right" *ngIf="!isClinicOrIvf">
          <div class="btn btn-sm mt-2 p-1" *ngFor="let type of typeOfAppointmentList">
            <a href="javascript:void(0)" class="phone btn btn-sm colr-5 mt-2 p-2 profile-btns mm_40 fs-23"
              *ngIf="type?.type=='Telephone'" (click)="typeOfAppointment('1','Telephone',type?.fee)"> <i
                class="fa fa-phone-alt color_yellow" aria-hidden="true"></i> </a>
            <a href="javascript:void(0)" class="comments   btn btn-sm colr-5 mt-2 p-2  profile-btns mm_40 fs-23"
              *ngIf="type?.type=='Chat'" (click)="typeOfAppointment('2','Chat',type?.fee)">
              <i class="fa fa-comments  color_red" aria-hidden="true"></i> </a>
            <a href="javascript:void(0)" class="video btn   type-select btn-sm colr-5 mt-2 p-2 profile-btns mm_40 fs-23"
              *ngIf="type?.type=='Video'" (click)="typeOfAppointment('3','Video',type?.fee)">
              <i class="fa fa-video color_green" aria-hidden="true"></i> </a>
            <a href="javascript:void(0)" class="person  btn btn-sm colr-5 mt-2 p-2 profile-btns mm_40 fs-23"
              *ngIf="type?.type=='In person' || type?.type=='Inperson'"
              (click)="typeOfAppointment('4','Inperson',type?.fee)"> <i class="fa-solid fa-hospital-user color_green"
                aria-hidden="true"></i> </a>
          </div>
        </div>
      </div>
      <div class="card appiontment-pai">
        <div class=" col-md-12 ">
          <div class=" ">
            <div class="d-flex flex-row mt-1 pt-2 pr-2 pl-2 pb-0">
              <div class="w-50">
                <h3 class="head">{{currentMonth}} {{currentYear}}</h3>

              </div>
              <div class="w-50 float-right text-right pr-2">
                <span class="page-item ">
                  <a class="" (click)="scrollLeft()">
                    <i class="fas fa-angle-left fa-1x"></i>
                  </a>
                </span>
                <span class="page-item  ">
                  <a class="" (click)="scrollRight()" aria-label="Next">
                    <i class="fas fa-angle-right fa-1x"></i>
                  </a>
                </span>
              </div>
            </div>
            <div class="mobileDatePicker">
              <div class="cdk-virtual-scroll-data-source-example">
                <cdk-virtual-scroll-viewport orientation="horizontal" #widgetsContent class="middle" itemSize="60"
                  minBufferPx="400" maxBufferPx="1000" class="example-viewport"
                  (scrolledIndexChange)="changeMonth($event)">

                  <div *cdkVirtualFor="let item of items" class="example-item">

                    <div class="item" (click)="select(item)" [ngClass]="{'is-active':item===selectedItem}">
                      <span class="weekName">{{returnWeekDay(item)}}</span>
                      <button class="disableddaycss" *ngIf="dateObject?.length ==0" [disabled]="dateObject?.length ==0">
                        {{item | date:'d'}}</button>
                      <button class="daycss" *ngIf="dateObject?.length !=0"> {{item | date:'d'}}</button>
                    </div>
                  </div>

                </cdk-virtual-scroll-viewport>

              </div>
            </div>


          </div>
          <!-- <div class="warpper ">
            <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active show" id="home-tab" data-toggle="tab" href="#home" role="tab"
                  (click)="changeTab('home')" aria-controls="home" aria-selected="false"><i class="fas fa-cloud-sun "
                    aria-hidden="true"></i>
                  Morning </a>
              </li>
              <li class="nav-item">
                <a class="nav-link " id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                  (click)="changeTab('profile')" aria-controls="profile" aria-selected="true"><i
                    class="fas fa-cloud-moon " aria-hidden="true"></i>
                  Afternoon</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab"
                  (click)="changeTab('contact')" aria-controls="contact" aria-selected="false"><i class="fas fa-moon "
                    aria-hidden="true"></i>
                  Evening</a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="col-md-12 text-center" *ngIf="morningHours?.length==0">
                  <div>
                    Morning
                  </div>
                </div>
                <div class="row p-0">
                  <div class="col-md-3 float-left p-2 mr-1 mb-1 " *ngFor="let object of morningHours"
                    data-toggle="modal" data-backdrop="static" data-target="#appointmentDetailsLeft"
                    class="col-md-2  mr-2 p-0" [ngClass]="{

                            'middle':object?.scheduleStatus== 'AVAILABLE'
                   }">
                    <div class=" mr-1 mb-1 float-left ">
                      <i class="fas fa-clock float-left color_light mt-1 mr-2" aria-hidden="true"></i>
                      <span class="fs-12 top float-left">{{object?.fromTime?.slice(0, -3) |
                        convertFrom24To12Format}}</span>
                      <p class="text-center fs-10 p-0 m-0 float-left w-100">{{object?.associateEntityName}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade " id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div class="row p-2">
                  <div class="col-md-12 text-center" *ngIf="afternoonHours?.length==0">
                    <div class="no-slots ">
                      Afternoon
                    </div>
                  </div>
                  <div class="col-md-2 float-left p-2 mr-1 mb-1" *ngFor="let object of afternoonHours"
                    data-toggle="modal" data-backdrop="static" data-target="#appointmentDetailsLeft"
                    class="col-md-2  mr-2 p-0" [ngClass]="{
                            'middle':object?.scheduleStatus== 'AVAILABLE'
                   }">
                    <span class="mr-1 mb-1 float-left ">
                      <i class="fas fa-clock color_light mt-1 mr-2" aria-hidden="true"></i> <span
                        class="fs-12">{{object.fromTime?.slice(0, -3) | convertFrom24To12Format}}</span>
                      <p class="text-center fs-10 p-0 m-0 float-left w-100">{{object?.associateEntityName}}</p>
                    </span>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <div class="row p-2">
                  <div class="col-md-12 text-center" *ngIf="eveningHours?.length==0">
                    <div class="no-slots">
                      Evening
                    </div>
                  </div>
                  <div class="col-md-2 float-left p-2 mr-1 mb-1" *ngFor="let object of eveningHours" data-toggle="modal"
                    data-backdrop="static" data-target="#appointmentDetailsLeft" class="col-md-2 mr-2 p-0" [ngClass]="{
                            'middle':object?.scheduleStatus== 'AVAILABLE'
         }">
                    <span class="mr-1 mb-1 float-left  ">
                      <i class="fas fa-clock color_light mt-1 mr-2" aria-hidden="true"></i>
                      <span class="fs-12">{{object.fromTime.slice(0, -3) | convertFrom24To12Format}}</span>
                      <p class="text-center fs-10 p-0 m-0 float-left w-100">{{object?.associateEntityName}}</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          
          
        </div>
      </div>
      <div class="wrapper pl-2 pr-2">
        <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
          <li class="nav-item" *ngFor="let tab of tabs">
            <a class="nav-link rounded" [ngClass]="{'active show': activeTab === tab.title}" (click)="setActiveTab(tab.title)">
              <i [class]="tab.icon"></i> {{ tab.title }}
            </a>
          </li>
        </ul>

        <div class="tab-content" id="myTabContent">
          <!-- Morning Tab -->
          <div *ngIf="activeTab === 'Morning'" class="tab-pane fade show active" id="morning" role="tabpanel">
            <div class="col-md-12 text-center" *ngIf="morningHours?.length==0">
              <div class="no-slots "></div>
            </div>
            <div class="row p-0">
              <div class="col-md-3 float-left p-2 mr-1 mb-1 " *ngFor="let object of morningHours"
                data-toggle="modal" data-backdrop="static" data-target="#appointmentDetailsLeft"
                class="col-md-2  mr-2 p-0" (click)="openModel(object)"  [ngClass]="{

                          'middle':object?.scheduleStatus== 'AVAILABLE'
                 }">
                <div class=" mr-1 mb-1 float-left pl-3 pt-1">
                  <i class="fas fa-clock float-left color_light mt-1 mr-2" aria-hidden="true"></i>
                  <span class="fs-12 top float-left">{{object?.fromTime?.slice(0, -3) |
                    convertFrom24To12Format}}</span>
                  <p class="text-center fs-10 p-0 m-0 float-left w-100">{{object?.associateEntityName}}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Afternoon Tab -->
          <div *ngIf="activeTab === 'Afternoon'" class="tab-pane fade show active" id="afternoon" role="tabpanel">
            <div class="row p-2">
              <div class="col-md-12 text-center" *ngIf="afternoonHours?.length==0">
                <div class="no-slots "></div>
              </div>
              <div class="col-md-2 float-left p-2 mr-1 mb-1" *ngFor="let object of afternoonHours"
                data-toggle="modal" data-backdrop="static" data-target="#appointmentDetailsLeft"
                class="col-md-2  mr-2 p-0" (click)="openModel(object)"  [ngClass]="{
                          'middle':object?.scheduleStatus== 'AVAILABLE'
                 }">
                <span class="mr-1 mb-1 float-left pl-3 pt-1">
                  <i class="fas fa-clock color_light mt-1 mr-2" aria-hidden="true"></i> <span
                    class="fs-12">{{object.fromTime?.slice(0, -3) | convertFrom24To12Format}}</span>
                  <p class="text-center fs-10 p-0 m-0 float-left w-100">{{object?.associateEntityName}}</p>
                </span>
              </div>
            </div>
          </div>

          <!-- Evening Tab -->
          <div *ngIf="activeTab === 'Evening'" class="tab-pane fade show active" id="evening" role="tabpanel">
            <div class="row p-2">
              <div class="col-md-12 text-center" *ngIf="eveningHours?.length==0">
                <div class="no-slots">
                </div>
              </div>
              <div class="col-md-2 float-left p-2 mr-1 mb-1" *ngFor="let object of eveningHours" data-toggle="modal"
                data-backdrop="static" data-target="#appointmentDetailsLeft" class="col-md-2 mr-2 p-0" (click)="openModel(object)"  [ngClass]="{
                          'middle':object?.scheduleStatus== 'AVAILABLE'
       }">
                <span class="mr-1 mb-1 float-left  p-1 pl-3 pt-1">
                  <i class="fas fa-clock color_light mt-1 mr-2" aria-hidden="true"></i>
                  <span class="fs-12">{{object.fromTime.slice(0, -3) | convertFrom24To12Format}}</span>
                  <p class="text-center fs-10 p-0 m-0 float-left w-100">{{object?.associateEntityName}}</p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
