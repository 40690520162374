import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/shared/services/auth.service';
import { MustMatch } from 'src/app/_helpers/must_match.validators';
import { interval, Subscription } from 'rxjs';
import { timer } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPassword: FormGroup
  submitted = false
  isResend = false
  isSend = true
  message
  isGetOtp = false
  isResendOtp = false
  timerOn = true;
  subscription: Subscription;
  invalidCredentials: boolean = false
  successCredentials: boolean = false
  subscribeTimer
  timeLeft: number = 120; cellnumber;
  unValid: boolean = true;
  emailValid: boolean = true;

  npValid: boolean = true;
  cpValid: boolean = true;
  showTimer: boolean = false;

  constructor(private globalStorage: GlobalStorageService, private fb: FormBuilder, private reset: AuthService, private router: Router,
    private toastr: ToastrService) {
    this.resetPassword = this.fb.group({
      emailId: ['', [Validators.required, Validators.email]],
      userName: ['', [Validators.required]],
      newPassword: ['', [Validators.required, Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)*\\S{8,15})')]],
      confirmPassword: ['', [Validators.required, Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)*\\S{8,15})')]],
      otp: ['', [Validators.required]]
    },
      {
        validator: MustMatch('newPassword', 'confirmPassword')
      })

  }
  verify = false
  ngOnInit(): void {
    // this.resetPass()
    if (this.router.url == '/verify') {
      this.verify = true
    }
  }
  get f() { return this.resetPassword.controls; }
  resetPass() {
    var data = this.globalStorage.getItem('userData')
    // var data= this.globalStorage.getItem('LoginDetails')
    var obj = JSON.parse(data)
    this.resetPassword.patchValue({
      emailId: obj.emailId
    })
  }
  numberOnly(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      return false;
    }
    return true;
  }

  verifyNow() {
    this.invalidCredentials = false
    if (this.router.url == '/dia-verify') {
      //otp-verification-diagnosticcenter
      this.reset.getDiaRegisteredUserUserId(this.resetPassword.controls['emailId'].value, this.resetPassword.controls['userName'].value).subscribe(res => {
        const navigationExtras: NavigationExtras = {
          state: {
            emailId: this.resetPassword.controls['emailId'].value,
            mobileNumber: res['data']['cellNumber'],
            userName: this.resetPassword.controls['userName'].value
          }
        };
        this.router.navigate(['/otp-verification-diagnosticcenter'], navigationExtras)
      })
    } else {
      this.reset.resendOTPForChangePassword(this.resetPassword.controls['userName'].value, this.resetPassword.controls['emailId'].value).subscribe(res => {
        const navigationExtras: NavigationExtras = {
          state: {
            emailId: this.resetPassword.controls['emailId'].value,
            mobileNumber: res['data'],
            userName: this.resetPassword.controls['userName'].value
          }
        };
        this.router.navigate(['/otp-verification-patient'], navigationExtras)
      }, error => {
        this.invalidCredentials = true
        this.message = error['error']['message']
      })
    }

  }
  submit(item: any) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPassword.invalid) {
      return;
    }
    var request = item.value
    request = {
      "emailId": item.value.emailId,
      "userName": item.value.userName,
      "newPassword": item.value.newPassword,
      "otp": item.value.otp
    }

    this.reset.resetPassword(request).subscribe(res => {
      this.successCredentials = true
      this.message = res['data']
      //this.toastr.success('Password Reset Done Succesfully')
      this.router.navigate(['/patientLogin'])
    }, error => {
      this.invalidCredentials = true
      //this.message = error['error']['message']
    })
  }


  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z\_]+$/);
    return pattern.test(value);
  }
  isOtpSent; isResentOtp: boolean = false
  getOtp() {
    this.invalidCredentials = false
    if (this.resetPassword.value.emailId == "" || this.resetPassword.value.emailId == undefined) {
      this.invalidCredentials = true
      this.message = 'Enter the Email to send verification Link'
      return
    } else {
      var request = {
        "emailId": this.resetPassword.value.emailId,

        "resetPasswordLink": window.location.origin + "/resetPassword"
      }
      this.reset.resendOTPForChangePassword(this.resetPassword.value.userName, this.resetPassword.value.emailId).subscribe(res => {
        this.successCredentials = true
        this.invalidCredentials = false
        this.message = "Check your email for OTP."
        // this.toastr.success('Check your email for OTP.')
        this.isOtpSent = true
        this.isResentOtp = true;
        this.showTimer = true;
        this.observableTimer();
        this.unValid = true;
        this.emailValid = true;

      }, error => {
        this.successCredentials = false
        this.invalidCredentials = true
        this.message = error['error']['message']
      })
    }
  }

  checkUserName() {
    this.invalidCredentials = false
    if (this.f.userName.errors == null) {

      this.reset.userNameExist(this.resetPassword.controls['userName'].value).subscribe(res => {
        if (res.data == false) {
          this.invalidCredentials = true
          this.message = "username not exist"
          // this.toastr.error("A user with this email address has already been registered in the system and the associated already exists.");
          this.unValid = true;
          this.submitted = false;
          return
        }
        else {
          this.unValid = false;
        }
      }, (error) => {
        // this.toastr.error((error['error']['message']))

      });
    }
  }
  isOtpValid = true
  otpCheck(otp) {
    if (otp.length == 6) {
      this.reset.otpVerify(this.resetPassword.value.emailId, otp, this.resetPassword.value.userName).subscribe(res => {
        this.invalidCredentials = false;
        this.isOtpValid = true;
        this.unValid = true;
        this.emailValid = true;
        this.showTimer = false;
      }, error => {
        this.showTimer = true;
        this.isOtpValid = false
        this.successCredentials = false
        this.invalidCredentials = true
        this.message = error['error']['message']
      })



    }
    else {
      this.invalidCredentials = false
    }
  }
  isEnterOtp
  fillOtp() {
    this.isEnterOtp = true;
    if (this.resetPassword.controls['newPassword'].status === "VALID") {
      this.npValid = false;
    }
    else {
      this.npValid = true;
    }
  }
  enteredCP() {
    if (this.resetPassword.controls['confirmPassword'].status === "VALID") {
      this.cpValid = false;
    }
    else {
      this.cpValid = true;
    }
  }
  observableTimer() {
    if (this.resetPassword.value.emailId == "" || this.resetPassword.value.emailId == undefined) {
      return
    }
    this.timeLeft = 120
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isSend = false
        this.isResend = true
        this.isResentOtp = false
        this.isGetOtp = false
        this.isResendOtp = false
        this.isOtpSent = false
        this.toastr.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  showHidePwd() {
    if ($('#show_hide_password input').attr("type") == "text") {
      $('#show_hide_password input').attr('type', 'password');
      $('#show_hide_password i').addClass("fa-eye-slash");
      $('#show_hide_password i').removeClass("fa-eye");
    } else if ($('#show_hide_password input').attr("type") == "password") {
      $('#show_hide_password input').attr('type', 'text');
      $('#show_hide_password i').removeClass("fa-eye-slash");
      $('#show_hide_password i').addClass("fa-eye");
    }
  }
  showHidePwd1() {
    if ($('#show_hide_password1  input').attr("type") == "text") {
      $('#show_hide_password1 input').attr('type', 'password');
      $('#show_hide_password1 i').addClass("fa-eye-slash");
      $('#show_hide_password1 i').removeClass("fa-eye");
    } else if ($('#show_hide_password1 input').attr("type") == "password") {
      $('#show_hide_password1 input').attr('type', 'text');
      $('#show_hide_password1 i').removeClass("fa-eye-slash");
      $('#show_hide_password1 i').addClass("fa-eye");
    }
  }
  emailAdded() {
    if (this.resetPassword.controls['emailId'].status === "VALID") {
      this.emailValid = false;
    }
    else {
      this.emailValid = true;
    }
  }

}

