import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.css']
})
export class TemplateFormComponent implements OnInit {
  templateForm:FormGroup
  constructor(private fb: FormBuilder,public modal:NgbActiveModal) { }

  ngOnInit(): void {
    this.templateForm=this.fb.group({
      templateName:['',Validators.required],
      description:['']
    })
  }
  closeModal(){
    this.modal.close('')
  }
  saveTemplate(){
   var data={
    templateName:this.templateForm?.value?.templateName,
    description:this.templateForm?.value?.description
    }
    this.modal.close(data)
  }
}
