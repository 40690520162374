import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Console } from 'console';
import { CookieService } from 'ngx-cookie-service';
import { ApicallsService } from 'src/app/apicalls.service';
import { Crypto } from '../../_helpers/CryptoJ'
import { ClinicService } from '../clinic.service';
import { ClinicNotificationsService } from 'src/app/shared/clinic-notifications.service';

@Component({
  selector: 'app-clinic-login',
  templateUrl: './clinic-login.component.html',
  styleUrls: ['./clinic-login.component.css']
})
export class ClinicLoginComponent implements OnInit {
  registerForm: FormGroup;
  loading = false;
  submitted = false;
  cond_vall = false
  rememberme: boolean = false;
  email
  invalidCredentials: boolean = false
  message
  password;
  verifyNowButton: boolean = false;

  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder, private apiService:ApicallsService,private router: Router, private cookieService: CookieService, private apiservice: ApicallsService,
    private clinicServices: ClinicService, private notification:ClinicNotificationsService,
    private crpto: Crypto,) {
    if (this.cookieService.get('rememberme') == 'Yes' && this.cookieService.get('module') == 'Clinic') {
      this.rememberme = true;
      this.email = this.crpto.Decrypt(this.cookieService.get('email'), 'email');
      this.password = this.crpto.Decrypt(this.cookieService.get('password'), 'password');
    }
  }

  ngOnInit(): void {

    // if (navigator.userAgent.match(/Android/i)) {
    //   window.location.href = 'https://play.google.com/store/apps/details?id=com.curemetric.hospital'
    // }
    // else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    //   window.location.href = 'https://apps.apple.com/in/app/curemetric/id1659587794'
    // }


    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }
  get f() { return this.registerForm.controls; }
  get pwd() {
    return this.registerForm.get('password');

  }

  verifyNow() {
    this.router.navigate(['/clinicVerify']);
  }

  forgotPassword() {

    this.router.navigate(['/clinicForgot']);

  }


  navigateToEnquiryForm(): void {
    this.router.navigate(['/enquiry-form'], { queryParams: { module:'Hospital' , returnUrl: this.router.url} });
}


  change() {
    this.cond_vall = !this.cond_vall

  }
  onSubmit() {
    this.invalidCredentials = false;
    this.apiService.setReload(true)
    this.submitted = true;
    this.registerForm.value.email.toLowerCase()
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    if (this.rememberme) {
      this.cookieService.set('email', this.crpto.encryption(this.email, "email"));
      this.cookieService.set('password', this.crpto.encryption(this.password, 'password',));
      this.cookieService.set('rememberme', 'Yes')
      this.cookieService.set('module', 'Clinic')
    } else {
      this.cookieService.set('email', "");
      this.cookieService.set('password', "");
      this.cookieService.set('rememberme', "")
    }
    this.globalStorage.setItem('userName',this.registerForm.value.email)
    this.globalStorage.setItem('password',this.registerForm.value.password)
    var request = {
      'password': this.registerForm.value.password,
      'userName': this.registerForm.value.email
    }
    this.clinicServices.login(request).subscribe(res => {
      if (res) {
        if (res['data']['verficationDone'] == 'N') {
          this.invalidCredentials = true;
          this.message == "Your Email Id is Unverified";
          this.verifyNowButton = true;
        }
        sessionStorage.setItem('clinicDetails', JSON.stringify(res?.['data']))
        this.globalStorage.setItem('clinicDetails', JSON.stringify(res?.['data']))
        this.globalStorage.setItem('userId',JSON.stringify(res?.['data']['hospitalUserId']))
        this.globalStorage.setItem('userSession', JSON.stringify(res?.['userSession']))
        this.cookieService.set('usersession',JSON.stringify(res?.['userSession']))
        this.cookieService.set('forUserId',res['data']['hospitalUserId'])
        this.cookieService.set('hospitalAddressId', res['data']['hospitalAddressId'])
        this.cookieService.set('clinicDetails',JSON.stringify(res?.['data']))
        this.globalStorage.setItem('forUserId', res['data']['hospitalUserId'])
        this.globalStorage.setItem('hospitalAddressId', res['data']['hospitalAddressId'])
        this.globalStorage.setItem('hospitalId', res['data']['hospitalId'])
        this.globalStorage.setItem('hospitalName', res['data']['hospitalName'])
        this.globalStorage.setItem('loggedInAs', "HOSPITAL")
        this.globalStorage.setItem('roles', JSON.stringify(res?.['data']['roles']))
        this.globalStorage.setItem('entityId',res['data']['hospitalAddressId'])
        this.globalStorage.setItem('userId',res['data']['hospitalUserId'])
        this.globalStorage.setItem('hospitalUserId',res['data']['hospitalUserId'])
        this.apiservice.isUserSessionExits = true
        this.apiservice.isforUserIdExits = true
        this.apiservice.ismoduleNameExits = true
        // this.clinicServices.clinicProfileCompletedOrNot(this.globalStorage.getItem('forUserId')).subscribe(res => {
        //   if (res['data']['isPersonalDetailCompleted'] == 'N') {
        //     this.router.navigate(['/clinic-personalDetails']);
        //   }
        //   else {
        //     this.router.navigate(['/clinic-dashboard'])
        //   }

        //   // else{
        //   //   if(res['data']['isAddressCompleted']=='N'){
        //   //     this.router.navigate(['/addressDetails'])
        //   //     return
        //   //   }
        //   // else{
        //   //   if(res['data']['isFeeDetailCompleted']=='N'){
        //   //     this.router.navigate(['/EditProfile'])
        //   //     return
        //   //   }
        //   //   else{
        //   //     this.router.navigate(['/clinic-dashboard'])
        //   //   }
        //   //     }
        //   //   }
        // })
        if(res['data']?.['roleId']==1 || res['data']?.['roleId']==11 ){
          this.router.navigate(['/clinic-dashboard'])
        }
        if(res['data']?.['roleId']==2){
          this.router.navigate(['/clinic-doctor-dashboard'])
        }
        if(res['data']?.['roleId']==4){
          this.router.navigate(['/clinic-manager-dashboard'])
        }
        if(res['data']?.['roleId']==5){
          this.router.navigate(['/clinic-cashier-dashboard'])
        }
        if(res['data']?.['roleId']==6){
          this.router.navigate(['/clinic-worker-dashboard'])
        }
        if(res['data']?.['roleId']==9){
          this.router.navigate(['/clinic-nutritionist-dashboard'])
        }
        if(res['data']?.['roleId']==10){
          this.router.navigate(['/clinic-nurse-dashboard'])
        }
        if(res['data']?.['roleId']==11){
          this.router.navigate(['/clinic-dashboard'])
        }
        if(res['data']?.['roleId']==12){
          this.router.navigate(['/clinic-driver-dashboard'])
        }
        this.notification.requestPermission(this.globalStorage.getItem('userId'));
        // this.notification.receiveMessage();
        // this.notification.currentMessage;
      }
    }, error => {
      this.invalidCredentials = true;
      this.message = error['error']['message'];
      if (this.message == "Your Email Id is Unverified") {
        this.verifyNowButton = true;
      }
      //this.router.navigate(['/clinicLogin'])
    })

  }
  remembermeCheck() {
    if (this.rememberme) {
      this.rememberme = false;
    } else {
      this.rememberme = true;
    }
  }

  showValidationMessage: boolean = false;
  preventCopy(event: ClipboardEvent) {
    event.preventDefault();
    this.showValidationMessage = true;
    setTimeout(() => {
      this.showValidationMessage = false;
    }, 5000);
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });
  }

}
