/// <reference lib="dom" />

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class GpsServiceService {

  private pincodeApiUrl = 'https://api.postalpincode.in/pincode/';


  getCurrentPosition(): Observable<GeolocationPosition> {
    return from(new Promise<GeolocationPosition>((resolve, reject) => {
      if (!navigator.geolocation) {
        reject('Geolocation is not supported by your browser');
      } else {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        });
      }
    }));
  }

  requestGeolocationPermission(): Observable<string> {
    return from(new Promise<string>((resolve, reject) => {
      if (!navigator.geolocation) {
        reject('Geolocation is not supported by your browser');
      } else {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          if (result.state === 'granted') {
            resolve('granted');
          } else if (result.state === 'prompt') {
            // This will trigger the permission prompt
            navigator.geolocation.getCurrentPosition(
              () => resolve('granted'),
              () => resolve('denied'),
              { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
            );
          } else {
            resolve('denied');
          }
        });
      }
    }));
  }

}

