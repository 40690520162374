<div class="row" *ngIf="feedBack?.length>5">
  <div class="col-lg-12 mt-3 text_right ">

    <ul class="pagination">
      <pagination-controls autoHide="true" id="p6" (pageChange)="p6=$event"></pagination-controls>
    </ul>
  </div>
</div>
<div class="row d-flex flex-column ml-2">
  <div *ngIf="feedBack?.length==0" class="no-image-bg">

  </div>
  <div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left  blt-2 mb-1 mt-1 "
    *ngFor="let reports of feedBack |paginate: { id: 'p6', itemsPerPage: 6, currentPage: p6 }">


    <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
      <a href="javascript:void(0)">
        <div class="reviews-members pt-1 pb-1">
          <div class="media mb-0">
            <div class="media-body">
              <div class="reviews-members-header">
                <span class="star-rating fl  oat-right"> <a><i class="icofont-ui-rating active"></i></a> <a href="#"><i
                      class="icofont-ui-rating active"></i></a> <a href="#"><i class="icofont-ui-rating active"></i></a>
                  <a href="#"><i class="icofont-ui-rating active"></i></a> <a href="#"><i
                      class="icofont-ui-rating"></i></a> </span>
                <h6 class="mb-1 float-left"><a class="text-black">{{reports?.patientname | titlecase}}</a></h6>
                <p class="text-gray float-left ml-2 mb-0">{{reports?.lastUpdatedTs | date:'dd-MMM-yyyy'}}</p>
                <div class="float-right">
                  <a href="javascript:void(0)" class="p-0 icon-appointments  "
                    *ngFor='let in of counter(reports.rating) ;let i = index'>
                    <i class="fa fa-star fa-lg pt-2  icon-col-1" aria-hidden="true"></i>
                  </a>
                </div>

              </div>
              <div class="reviews-members-body pt-0 float-left w-100">
                <p class="mb-0">{{reports.feedback}}</p>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>