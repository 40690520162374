import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../inventory/inventory.service';
import { SingleSupplierInfoComponent } from '../single-supplier-info/single-supplier-info.component';
import { SaveSupplierComponent } from '../modals/save-supplier/save-supplier.component';
import { CreateOrdersComponent } from '../inventory/create-orders/create-orders.component';
import { SaveBulkOrderComponent } from '../modals/save-bulk-order/save-bulk-order.component';

@Component({
  selector: 'app-all-suppliers-of-individual-category',
  templateUrl: './all-suppliers-of-individual-category.component.html',
  styleUrls: ['./all-suppliers-of-individual-category.component.css']
})
export class AllSuppliersOfIndividualCategoryComponent implements OnInit {

  @Input() categoryInfo: any;
  @Input() addressId: any;
  @Input() service: any;
  p1 = 1;

  constructor(private globalStorage: GlobalStorageService, 
    private activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private inventoryService: InventoryService
  ) { }

  ngOnInit(): void {
    this.allSuppliersOfCat();
  }

  closeModal() {
    this.activeModal.close(true);
  }

  suppliers;
  allSuppliersOfCat() {
    this.inventoryService.supplierListOfOrder(this.categoryInfo?.catagoryId).subscribe(res => {
      this.suppliers = res?.['data'];
      this.p1 = 1;
    },
      error => {
        this.toaster.error(error?.['error']?.['message'])
      }
    )
  }

  myInfo(data){
    let wClass='left';
    if(this.globalStorage.getItem("loggedInAs")=='IVF'){
      wClass='right hw-30';
    }
    if(this.globalStorage.getItem("loggedInAs")=='HOSPITAL'){
      wClass='modal-left hw-30';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-30';
    }
    const modalRef = this.modalService.open(SingleSupplierInfoComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.componentInstance.singleSupplierInfo = data;
    modalRef.componentInstance.addressId = this.addressId;
  }

  suppliercategoryEdit(data){
    let wClass='left';
    if(this.globalStorage.getItem("loggedInAs")=='IVF'){
      wClass='right hw-40';
    }
    if(this.globalStorage.getItem("loggedInAs")=='HOSPITAL'){
      wClass='modal-left hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-40';
    }
    const modalRef = this.modalService.open(SaveSupplierComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    })
    modalRef.componentInstance.isAssignedSupplierEdit = true;
    modalRef.componentInstance.editTime = true;
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.service = this.service;
    modalRef.componentInstance.editedAssignedSupplierObj = data;
    modalRef.result.then(
      (result) => {
        this.allSuppliersOfCat();
      },
      (reason) => {
      }
    );
  }

  placeOrder(data){
    var sendingData=[]
    sendingData[0]=data
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'left hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-right hw-80';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-right hw-80';
    }
    // const modalRef = this.modalService.open(CreateOrdersComponent, {
    //   centered: false,
    //   backdrop: 'static',
    //   keyboard: false,
    //   windowClass: wClass
    // });
    // modalRef.componentInstance.assignAssociatedItems = true;
    // modalRef.componentInstance.associatedItems = data;
    // modalRef.componentInstance.addressId = this.addressId;
    // modalRef.componentInstance.service = this.service;
    const modalRef = this.modalService.open(SaveBulkOrderComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    modalRef.componentInstance.assignAssociatedItems = true;
    modalRef.componentInstance.purchaseItems = sendingData;
    modalRef.componentInstance.supplieId=data?.supplierId
    modalRef.componentInstance.addressId =data?.addressId;

  }


}
