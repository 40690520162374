
<body class="login-bg ivflogin" id="bodybc_main">
     
  <div class="container-fluid overflowxlogin">
      <div class="row">
          <div class="col-lg-6 col-md-4 col-sm-12 col-xs-12">
              <div class="auth-logo mb-3 ">
                  <div class="n768none">
                      <a href="javascript:void(0)" class="auth-logo-dark">
                          <img src="assets/images/login/logo.svg" alt=""
                              class="d-flex n768none p-2 justify-content mx-auto logwi text-left ms-5">
                      </a>
                  </div>
                  <img src="assets/images/login/loginimage.png" alt=""
                      class="d-flex p-2 justify-content mx-auto loginimgw">
              </div>
          </div>
          <div class="col-lg-6 col-md-8 col-sm-12 col-xs-12">
              <div class="navyalogocss">
                  <img src="assets/images/login/logo.svg" alt="" class="p-2 img-fluid">
              </div>
              <div class="row justify-content-center mt-5 headingcss">
                  <div class="col-md-9">
                      <div class="p-0">
                        
<form [formGroup]="queryForm" autocomplete="off">
  <h3 class="">Enquiry </h3>
  <div class="row">

    <div class="col-md-6 mb-3">
      <!-- Contact Name Input -->
      <div class="form-group">
        <label for="contactName">Contact Name <span class="text-danger">*</span></label>
        <input type="text" id="contactName" formControlName="contactName" maxlength="35" class="form-control"
          placeholder="Enter Contact Name" [appUnifiedValidation]="['required']">
      </div>
    </div>

    <div class="col-md-6 mb-3">
      <!-- Entity Name Input -->
      <div class="form-group">
        <label for="entityName">Entity Name <span class="text-danger">*</span></label>
        <input type="text" id="entityName" formControlName="entityName" maxlength="35" class="form-control"
          placeholder="Enter Entity Name" [appUnifiedValidation]="['required']">
      </div>
    </div>


    <div class="col-md-6 mb-3">
      <!-- Cell Number Input -->
      <div class="form-group">
        <label for="exampleInputPassword1">Mobile Number <span class="text-danger">*</span></label>
        <input type="tel" id="cellNumber" formControlName="cellNumber" maxlength="10" minlength="10" class="form-control"
          placeholder="Enter Cell Number" [appUnifiedValidation]="['required']" appInputRestrict="mobileNumber">

      </div>
    </div>

    <div class="col-md-6 mb-3">
      <!-- Email Input -->
      <div class="form-group">
        <label for="emailId">Email Id <span class="text-danger">*</span></label>
        <input type="email" id="emailId" formControlName="emailId" class="form-control" placeholder="Enter Email Id"
          [appUnifiedValidation]="['required', 'email']">
      </div>
    </div>

    <div class="col-md-6 mb-3">
      <!-- License Number Input -->
      <div class="form-group">
        <label for="licenceNumber">Licence Number  <span class="text-danger">*</span></label>
        <input type="text" id="licenceNumber" formControlName="licenceNumber" maxlength="25" class="form-control"
          placeholder="Enter Licence Number" [appUnifiedValidation]="['required']" appInputRestrict="licenseNumber">
      </div>
    </div>

    <div class="col-md-6 mb-3">
      <!-- Query Input -->
      <div class="form-group">
        <label for="query">Query  <span class="text-danger">*</span></label>
        <input type="text" id="query" formControlName="query" maxlength="35" class="form-control"
          placeholder="Enter Query" [appUnifiedValidation]="['required']">
      </div>
    </div>
  </div>
  <!-- Submit Button -->
    <div class="row mt-2 clr button_sigincolor">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 float-left">
       <a href="javascript:void(0)"  type="submit" (click)="getData()" class="text-muted lock_color linespace_design">
        <i class="mdi mdi-lock me-1"></i> Login</a>
      </div> 
    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 float-right">    
      <button type="submit"
          class="btn mb-3 btn-warning float-right " [disabled]="queryForm.invalid"
          (click)="saveData()"><i class="bx bx-lock label-icon"></i>
          Submit
      </button>
        </div>
      </div>
</form>
                      </div>
                      <div class="mt-2 text-center">
                          <div>
                              <p class="r-lite"> ©
                                  <script>
                                      document.write(new Date().getFullYear())
                                  </script> CureMetric
                              </p>
                              <div class="text-center d-flex justify-content-center">
                                  <div class="flt pr-2"><img src="assets/images/login/logopng.png" alt="" class="">
                                  </div>
                                  <div class="flt">
                                      <p class="px-2 r-lite">Powered By CureMetric</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="d-flex d-flexs  align-items-center d-flex d-flexs login_footer">
                  <div class="d-flex align-items-center mrg30">
                      <div class="mrleft">
                          <img src="assets/images/login/phoneicon.svg" alt="" class="">
                      </div>
                      <div class="mrleft">
                          <p class="allpmargin">Have a question? call us now</p>
                          <p>+1800-266-18023</p>
                      </div>
                  </div>
                  <div class="d-flex align-items-center mrg30">
                      <div class="mrleft">
                          <img src="assets/images/login/emailicon.svg" alt="" class="">
                      </div>
                      <div class="mrleft">
                          <p class="allpmargin">For any questions, write to us at:</p>
                          <p>support@curemetric.com</p>
                      </div>
                  </div>
                  <div class="d-flex align-items-center">
                      <div class="mrleft">
                          <img src="assets/images/login/time_icon.svg" alt="" class="">
                      </div>
                      <div class="mrleft">
                          <p class="allpmargin">We are open</p>
                          <p>24 x 7</p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</body>


 