import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { ClinicOpdPrescriptionsComponent } from 'src/app/clinic-pages/clinic-opd-prescriptions/clinic-opd-prescriptions.component';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { ViewPrescriptionsModalComponent } from 'src/app/commonComponents/view-prescriptions-modal/view-prescriptions-modal.component';
import { ObservableService } from 'src/app/shared/services/observable.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
declare var $: any;
@Component({
  selector: 'app-previous-appointment-list',
  templateUrl: './previous-appointment-list.component.html',
  styleUrls: ['./previous-appointment-list.component.css']
})
export class PreviousAppointmentListComponent implements OnInit {

  constructor(private globalStorage: GlobalStorageService, private doctorPatient:DoctorPatientService,private router: Router, private controller: PatientScheduleControlService,private obs:ObservableService,
    private clinicServices:ClinicService, private toastrService:ToastrService,private patientService:DoctorPatientService,private modalService:NgbModal) { }
  @Input() patientPreviousAppointmentList:any;hospitalDetails;
  p1=1
  ngOnInit(): void {
    if(this.patientPreviousAppointmentList!=undefined){
      this.getPatientPreviousAppointment(this.patientPreviousAppointmentList);
      }
      if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
        this.hospitalDetails=JSON.parse(this.globalStorage.getItem('clinicDetails'))
      }  
  }

  patientPersonalDetails:any[]=[]
  previousAppointmentList:any[];
  getPatientPreviousAppointment(patientPreviousAppointment){

    var patient={
      "forUserId":this.patientPreviousAppointmentList.userId,
      "referenceEntityId":parseInt(this.globalStorage.getItem('doctorId'))
    }
    var body={
      "maxResults": 100,
      "pageNum": 1,
      "patientId": this.patientPreviousAppointmentList.userId,
      "referenceEntityId": parseInt(this.globalStorage.getItem('doctorId')),
      "hospitalAddressId":null,
      "viewAppointmentEnum": [
        "PREVIOUS"
      ]
    }
    if(this.globalStorage.getItem('loggedInAs')=='IVF'){
      var req={
        "ivfaddressId": this.patientPreviousAppointmentList?.['AppointmentDetails']?.['ivfaddressId'],
        "maxResults":20,
        "pageNum": 1,
        "patientId": this.patientPreviousAppointmentList?.['AppointmentDetails']?.userId,
        "referenceEntityId": this.patientPreviousAppointmentList?.['AppointmentDetails']?.doctorId,
        "viewAppointmentEnum": [
          "PREVIOUS"
        ]
      }
      this.controller.ivfPreviousAppointments('myAppointments',body).subscribe(res=>{
        console.log('previous data',res)
        this.previousAppointmentList=res['data']?.['PREVIOUS']?.['data']
      })
      return
    }
    else if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
      this.hospitalDetails=JSON.parse(this.globalStorage.getItem('clinicDetails'))
      console.log('this.hospitalDetails',this.hospitalDetails)
      if(this.hospitalDetails?.roleId==1){
        var postbody={
          "maxResults": 100,
          "pageNum": 1,
          "patientId": this.patientPreviousAppointmentList.userId,
          "referenceEntityId": null,
          "hospitalAddressId":parseInt(this.globalStorage.getItem('hospitalAddressId')),
          "viewAppointmentEnum": [
            "PREVIOUS"
          ]
        }
        this.clinicServices.getAppointments(postbody).subscribe(res => {
         this.previousAppointmentList=res['data']?.['PREVIOUS']?.['data']
       })
      }
      if(this.hospitalDetails?.roleId==2){
        var bd={
          "maxResults": 100,
          "pageNum": 1,
          "patientId": this.patientPreviousAppointmentList.userId,
          "referenceEntityId": parseInt(this.globalStorage.getItem('hospitalUserId')),
          "hospitalAddressId":parseInt(this.globalStorage.getItem('hospitalAddressId')),
          "viewAppointmentEnum": [
            "PREVIOUS"
          ]
        }
        this.clinicServices.getAppointments(bd).subscribe(res => {
          this.previousAppointmentList=res['data']?.['PREVIOUS']?.['data']
        })
      }
   }
    else{
      this.doctorPatient.getPatientPreviosAppointments(body).subscribe(res=>{
        this.previousAppointmentList=res['data']?.['PREVIOUS']?.['data']
},error=>{
})
    }

  }


 prescriptionList(appointment){
  if(this.globalStorage.getItem('loggedInAs')== 'IVF'){
    this.openPrescriptionModal(appointment)
    return
  }
  if(this.globalStorage.getItem('loggedInAs')== 'HOSPITAL'){
    this.openPrescriptionModal(appointment)
    return
  }
  if(this.globalStorage.getItem('loggedInAs')== 'DIAGNOSTIC_CENTER'){
    this.openPrescriptionModal(appointment)
    return
  }
  else{
   this.doctorPatient.openPrescriptionList(appointment);
   this.obs.prescriptionOn(appointment)
  }
 }

 openPrescriptionModal(appointment) {
  let wClass='right hw-50';

  if(this.globalStorage.getItem("loggedInAs")=='IVF'){
    wClass='left w-50';
  }
  if(this.globalStorage.getItem("loggedInAs")=='HOSPITAL'){
    wClass='modal-left hw-40';
  }
  if(this.globalStorage.getItem("loggedInAs")=='DIAGNOSTIC_CENTER'){
    wClass='modal-left hw-40';
  }
  if(this.patientPreviousAppointmentList?.patientType=='OPD'){
    const modalRef = this.modalService.open(ClinicOpdPrescriptionsComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    console.log('patient prescription',this.patientPreviousAppointmentList)
    if(this.patientPreviousAppointmentList?.patientType=='OPD'){
      appointment['patientType']="OPD"
    }
    modalRef.componentInstance.appointmentInfo = appointment;
    // modalRef.componentInstance.appointment = this.appointment;
  
    modalRef.result.then(
      (result) => {
        if (result) {
          // this.editPatient(data)
        }
      },
      (reason) => {
        // Handle dismissal of modal
      }
    );
  }else{
    const modalRef = this.modalService.open(ViewPrescriptionsModalComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    });
    console.log('patient prescription',this.patientPreviousAppointmentList)
    if(this.patientPreviousAppointmentList?.patientType=='OPD'){
      appointment['patientType']="OPD"
    }
    modalRef.componentInstance.appointment = appointment;
    // modalRef.componentInstance.appointment = this.appointment;
  
    modalRef.result.then(
      (result) => {
        if (result) {
          // this.editPatient(data)
        }
      },
      (reason) => {
        // Handle dismissal of modal
      }
    );
  }
}

 getPatientMedicalReports(patient){
var patientDetails=  {
    "forUserId": patient.userId,
    "userId":patient.userIdl,
    'postion':'start'
  }
  this.patientService.openMedicalReportList(patientDetails);
}


viewAppointment(appointment){
   this.doctorPatient.openAppointment(appointment);
}


  public ngOnChanges(changes: SimpleChanges){
    if ('patientPreviousAppointmentList' in changes){
      if(this.patientPreviousAppointmentList!=undefined){
      this.getPatientPreviousAppointment(this.patientPreviousAppointmentList);
      }
    }
  }

  viewNotes(appointmentNots){
    // this.obs.appointmentNotesOn(appointmentNots)
    $('#notes-modal').modal('show');
  }

  closenotes(){
    $('#notes-modal').modal('hide');
  }

}
