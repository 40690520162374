import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { ClinicService } from '../clinic.service';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'app-clinic-rooms-for-assign',
  templateUrl: './clinic-rooms-for-assign.component.html',
  styleUrls: ['./clinic-rooms-for-assign.component.css']
})
export class ClinicRoomsForAssignComponent implements OnInit {
  allotedRoom;allotedIcuRoom;selectedEmergency;roomForm: FormGroup;icuRooms;roomArray;allRooms;operationTheaters
  constructor(private globalStorage: GlobalStorageService, private clinicServices:ClinicService,private toastr: ToastrService,private formBuilder: FormBuilder,public activeModal:NgbActiveModal,
    private router: Router,) { }
  @Input() info: any;
  ngOnInit(): void {
    console.log('patient details',this.info)
    this.roomForm = this.formBuilder.group({
      "patientName": [''],
      "bedNumber": [],
      "price": [],
      "startDate": [],
      "endDate": [],
      "floorNumber": [],
      "roomNumber": [],
    })
    // this.getAllotedRoom()
    this.getRooms()
  }
  getAllotedRoom(){
    this.clinicServices.getRoomDetails(this.globalStorage.getItem('hospitalAddressId'), this.info).subscribe(res => {
      if (res['data']) {

        this.allotedRoom = res['data']?.filter(obj => obj?.endDate == null)
        this.allotedIcuRoom = res['data']?.filter(obj => obj?.roomType == 'ICU')
        if (this.selectedEmergency == 'ICU') {
          this.loadRoomData(this.allotedIcuRoom?.[0])
        }
        else {
          this.loadRoomData(this.allotedRoom?.[0])
        }
      }
      else {
        this.allotedRoom = []
        this.allotedIcuRoom = []
      }
    }, error => {
      this.toastr.error(error?.['error']?.['message'])
    })
  }
  roomData
  loadRoomData(data) {

    this.roomData = data
    if (data && data?.endDate == null) {
      const date2 = formatDate(data?.startDate, "yyyy-MM-dd", 'en-us');
      this.roomForm.patchValue({
        patientName: data.patientName,
        bedNumber: data.bedNumber,
        price: data.price,
        startDate: date2,
        floorNumber: data.floorNumber,
        roomNumber: data.roomNumber
      })
    }
    if (data?.roomType == 'ICU') {
      this.roomForm.patchValue({
        patientName: data.patientName,
        bedNumber: data.bedNumber,
        price: data.price,
        floorNumber: data.floorNumber,
        roomNumber: data.roomNumber
      })
    }
  }
  removeDuplicatess(array: any[]) {
    const uniqueArray = [];
    for (const item of array) {
      if (!uniqueArray.includes(item)) {
        uniqueArray.push(item);
      }
    }
    return uniqueArray;
  }
  getRooms() {
    this.icuRooms = [];
    this.roomArray = [];
    this.clinicServices.getRoom(this.globalStorage.getItem('hospitalAddressId')).subscribe(res => {
      if (res['data']) {

        this.allRooms = res['data']?.['floors']
        //this.icuRooms = res['data']?.['floors']?.filter(obj => obj.roomType == 'ICU')
        for (var i = 0; i < res['data']?.['floors']?.length; i++) {
          for (var k = 0; k < res['data']?.['floors'][i]['rooms']?.length; k++) {
            if (res['data']?.['floors']?.[i]?.['rooms']?.[k]?.['roomType'] == 'ICU') {
              this.roomArray.push(res['data']?.['floors']?.[i]?.['rooms']?.[k])
              // this.roomArray = this.roomArray.filter(e=> e.roomId != res['data']?.['floors']?.[i]?.['rooms']?.[k].roomId)
              // this.roomArray = this.removeDuplicatess(this.roomArray)
              var obj = {
                floorNumber: res['data']?.['floors']?.[i]?.['floorNumber'],
                rooms: this.roomArray
              }

              this.icuRooms.push(obj)
              this.icuRooms = this.removeDuplicatess(this.icuRooms)

            }
          }
        }

        this.operationTheaters = res['data']?.['floors']?.filter(obj => obj.roomType == 'Operation Theater')
      }
    }, error => {
      this.toastr.error(error?.['error']?.['message'])
    })
  }
  changeRoomLeftModal(room, bedDetails) {

    var dt = new Date()
    const date2 = formatDate(dt, "yyyy-MM-dd", 'en-us');
    this.roomForm.patchValue({
      patientName: this.roomData.patientName,
      bedNumber: bedDetails.bedNumber,
      price: room.price,
      startDate: date2,
      floorNumber: room.floorNumber,
      roomNumber: bedDetails.roomNumber
    })
    this.selectedChangeRoom(room, bedDetails)
  }
  selectedChangeRoom(room, bedDetails) {

  }
  bedInfo(a,b){

  }
  closeModal(){
    this.activeModal.close()
  }
  assignOPDBed(room,bed){
    console.log('room details',room,bed)
    var body={
      "active":"Y",
      "availableBed":1,
      "hospitaladdressId":this.info?.AppointmentDetails?.hospitalAddressId,
      "appointmentId":this.info?.AppointmentDetails?.appointmentId,
      "inpatientId":-1,
      "patientId":this.info?.AppointmentDetails?.userId,
      "patientName":this.info?.AppointmentDetails?.patientName,
      "roomType":room?.roomType,
      "price":room?.price,
      "roombedId":bed?.roombedId,
      "opd":"Y"
    }
    this.clinicServices.assignOpdBed(body).subscribe(res=>{
      this.toastr.success('Bed Assigned')
      this.getRooms()
      this.closeModal()
      var ptdetails = {
        'patientName': this.info?.AppointmentDetails?.patientName,
        'patientReferenceId': this.info?.AppointmentDetails?.userId,
        'patientType': 'IPD',
        'inPatientId': res['data'],
      }
      const navigationExtras: NavigationExtras = {
        state: {
          userDetails: ptdetails
        }
      }
      this.router.navigate(['/in-patient-details'], navigationExtras)
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
}
