<div class="modal-header">
    <h5 class="modal-title" *ngIf="!isEdit && !isView">
        <i aria-hidden="true" class="fa fa-user-plus"></i> Add Supplier
    </h5>
    <h5 class="modal-title" *ngIf="isEdit">
        <i aria-hidden="true" class="fa fa-user-plus"></i> Edit Supplier
    </h5>
    <h5 class="modal-title" *ngIf="isView">
        <i aria-hidden="true" class="fa fa-user-plus"></i> Supplier Details
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body modal-bodycure pt-0 p-1">
    <div class="container user-curetab">
        <form [formGroup]="supplierForm">
            <h3 class="mb-3 mt-2 font-size-16 border-bottom p-2 bg-primary-light ">Contact Details</h3>
            <div class="row">

                <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mb-3">
                    <label for="" class="">Supplier Name<b>*</b></label>
                    <input type="text" class="form-control" name="supplierName" id="suplierName" [readonly]="isView"
                        appInputRestrict="alphaOnlyWithSpaces" noWhiteSpaceAtTheStart placeholder="Supplier Name"
                        formControlName="supplierName" [ngClass]="{
                                                'is-invalid':
                                                  (submitted || f.supplierName.touched) && f.supplierName.errors
                                              }">

                    <div *ngIf="(submitted || f.supplierName.touched) && f.supplierName.errors"
                        class="invalid-feedback">

                        <div *ngIf="f.supplierName.errors.required">
                            Supplier Name is Required
                        </div>
                    </div>
                </div>


                <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mb-3">
                    <label for="" class="">Contact Name<b>*</b></label>
                    <input type="text" class="form-control" name="primaryContact" id="contact"
                        appInputRestrict="alphaOnlyWithSpaces" noWhiteSpaceAtTheStart placeholder="Contact Name"
                        [readonly]="isView" formControlName="primaryContact" [ngClass]="{
                                                'is-invalid':
                                                  (submitted || f.primaryContact.touched) && f.primaryContact.errors
                                              }">
                    <div *ngIf="(submitted || f.primaryContact.touched) && f.primaryContact.errors"
                        class="invalid-feedback">

                        <div *ngIf="f.primaryContact.errors.required">
                            Contact Name is Required
                        </div>
                    </div>


                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mb-3">
                    <label>Email ID <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" formControlName="email" placeholder="Enter Email ID" 
                    [readonly]="isView"
                    [ngClass]="{
                        'is-invalid':
                          (submitted || f.email.touched) && f.email.errors
                      }">

                      <div *ngIf="(submitted || f.email.touched) && f.email.errors"
                        class="invalid-feedback">

                        <div *ngIf="f.email.errors.required">
                            Email ID is Required
                        </div>
                        <div *ngIf="f.email.errors.email">Enter a valid Email Address.
                        </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mb-3">
                    <label>Contact Number<b>*</b></label>
                    <input type="text" ng2TelInput appInputRestrict="numberOnly"
                        [ng2TelInputOptions]="{autoHideDialCode: false, initialCountry: 'in', separateDialCode:true}"
                        #phNum class="form-control mb-0" [readonly]="isView" formControlName="primaryContactNumber"
                        appInputRestrict="mobileNumber"
                        [minlength]="10" maxlength="10"
                        [ngClass]="{ 'is-invalid': (submitted || f.primaryContactNumber.touched) && f.primaryContactNumber.errors }"
                        placeholder="Contact Number">

                    <div *ngIf="(submitted || f.primaryContactNumber.touched) && f.primaryContactNumber.errors"
                        class="invalid-feedback prmnumber">

                        <div *ngIf="f.primaryContactNumber.errors.required">
                            primaryContact Number is Required.
                        </div>
                    </div>
                </div>

                <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-3">
                    <div class="form-group">
                        <label>GSTIN Number <span class="text-danger">*</span></label>
                        <input type="text" class="form-control" placeholder="Enter GSTIN Number" formControlName="gstin"
                            maxlength="15" [readonly]="isView"
                            [ngClass]="{ 'is-invalid': (submitted || f.gstin.touched) && f.gstin.errors }">

                        <div *ngIf="(submitted || f.gstin.touched) && f.gstin.errors"
                            class="invalid-feedback prmnumber">

                            <div *ngIf="f.gstin.errors.required">
                                GSTIN Number is Required.
                            </div>
                            <div *ngIf="f.gstin.errors.minlength">
                                GSTIN Number should be 15 Characters Long.
                            </div>
                        </div>
                    </div>
                </div>

              </div>
                <h3 class="mb-3 mt-2 font-size-16 border-bottom p-2 bg-danger-light ">Address Details</h3>
                <div class="row">

                    <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                        <div class="form-group">
                            <label for="exampleInputEmail1"> Zip/Pin Code <span class="text-danger">*</span></label>
                            <input #zipCode type="text" placeholder="Enter Zip/Pin Code" maxlength="6" minlength="6"
                                class="form-control" (keyup)="percChanged(zipCode.value)"
                                appInputRestrict="numberOnly" formControlName="zip" [readonly]="isView"
                                [ngClass]="{ 'is-invalid': (submitted || f.zip.touched) && f.zip.errors }">
                            <div *ngIf="(submitted || f.zip.touched) && f.zip.errors" class="invalid-feedback">
                                <div *ngIf="f.zip.errors.required">Zip is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 cureadd mb-3">
                        <div class="form-group">
                            <label> Country Code</label><span class="text-danger">*</span>
                            <mat-select (selectionChange)="countryCodeEntered()" [disabled]="isView"
                                [(value)]="selectedCC" class="form-control" formControlName="cc"
                                [ngClass]="{ 'is-invalid': (submitted || f.cc.touched) && f.cc.errors }">
                                <mat-option value="IN">IN</mat-option>
                                <mat-option value="US">US</mat-option>
                            </mat-select>
                            <div *ngIf="(submitted || f.cc.touched) && f.cc.errors" class="invalid-feedback">
                                <div *ngIf="f.cc.errors.required">Country Code is required.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 cureadd mb-3" *ngIf="!isView">
                        <div class="form-group">
                            <label for="exampleInputPassword1">Postal Location</label><span class="text-danger">*</span>
                            <mat-select placeholder="Select postal location" [(ngModel)]="selectedValue"
                                (selectionChange)="drpdownSelectionForAddress(selectedValue)" [disabled]="isView"
                                class="form-control" formControlName="postalLocation" [ngClass]="{
                                                    'is-invalid': (primarysubmitted || h.postalLocation.touched) && h.postalLocation.errors
                                                        }" placeholder="Enter postal location">

                                <mat-option *ngFor="let item of postalLocations" [value]="item.postalLocation">
                                    {{item.postalLocation}}
                                </mat-option>
                            </mat-select>
                            <div *ngIf="(primarysubmitted || h.postalLocation.touched) && h.postalLocation.errors"
                                class="invalid-feedback">
                                <div *ngIf="h.postalLocation.errors.required">Postal location is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-3" *ngIf="isView">
                        <div class="form-group">
                            <label>Postal Location<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" formControlName="postalLocation" [readonly]="isView"
                                placeholder="Enter postal location">
                        </div>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 mb-3">
                        <div class="form-group">
                            <label for="exampleInputEmail1"> Street1 <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter street1"
                                formControlName="street1" [readonly]="isView"
                                [ngClass]="{ 'is-invalid': (submitted || f.street1.touched) && f.street1.errors }">
                            <div *ngIf="(submitted || f.street1.touched) && f.street1.errors" class="invalid-feedback">
                                <div *ngIf="f.street1.errors.required">street1 is required.</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1"> Street2</label>
                                            <input type="text" class="form-control" placeholder="Enter street2" formControlName="street2">
                                        </div>
                                    </div> -->
                    <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                        <div class="form-group">
                            <label for="exampleInputEmail1"> City <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter city" formControlName="city"
                                appInputRestrict="alphaNumarics" [readonly]="isView" disabled
                                [ngClass]="{ 'is-invalid': (primarysubmitted) && h.city.errors}">
                            <div *ngIf="(primarysubmitted)  && h.city.errors" class="invalid-feedback">
                                <div *ngIf="h.city.errors.required">City is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12">
                        <div class="form-group">
                            <label for="exampleInputEmail1"> State <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter state" formControlName="state"
                                [ngClass]="{ 'is-invalid': (primarysubmitted) && h.state.errors}" readonly>
                            <div *ngIf="(primarysubmitted)  && h.state.errors" class="invalid-feedback">
                                <div *ngIf="h.state.errors.required">State is required</div>
                            </div>
                        </div>
                    </div>
                </div>


        </form>

        <div class="vime " [ngClass]="{'text-right':fromWhere}" *ngIf="!isView">
          <button type="button" class="btn btn-danger al  float-right btn-sm ml-2" (click)="activeModal.close()"
          >
          Cancel
       </button>

            <button type="button" class="btn btn-success al  float-right btn-sm ml-2" [disabled]="supplierForm.invalid"
                (click)="saveSupplier()" *ngIf="!isEdit">
                <i class="plusicon fas fa-save"></i> Save
            </button>

            <button type="button" class="btn btn-primary al  float-right btn-sm ml-2" (click)="updateSupplier()" [disabled]="supplierForm.invalid"
                *ngIf="isEdit">
                <i class="plusicon fas fa-pencil"></i> Update
            </button>


        </div>
    </div>
</div>
