import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Crypto } from '../../_helpers/CryptoJ'
import { NotificationService } from 'src/app/shared/notification.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { PatientService } from 'src/app/shared/services/patient.service';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import * as moment from 'moment';
import { ApicallsService } from 'src/app/apicalls.service';
import { PharmaNotificationService } from 'src/app/shared/pharma-notification.service';
declare var $: any;
@Component({
  selector: 'app-pharma-login',
  templateUrl: './pharma-login.component.html',
  styleUrls: ['./pharma-login.component.css']
})
export class PharmaLoginComponent implements OnInit {

  registerForm: FormGroup;
  loading = false;
  submitted = false;
  cond_vall = false
  rememberme: boolean = false;
  invalidCredentials: boolean = false
  email
  message
  password

  constructor(private globalStorage: GlobalStorageService, 
    private apiservice: ApicallsService,
    private formBuilder: FormBuilder,
    private patientController: PatientScheduleControlService,
    private router: Router, private cookieService: CookieService,
    private crpto: Crypto, private update: PatientService,
    private notification: NotificationService,
    private ToastrService: ToastrService,
    private service: pharmaService,
    private pharmanotification:PharmaNotificationService) {
    if (this.cookieService.get('rememberme') == 'Yes' && this.cookieService.get('module') == 'Pharmacy') {
      this.rememberme = true;
      this.email = this.crpto.Decrypt(this.cookieService.get('email'), 'email');
      this.password = this.crpto.Decrypt(this.cookieService.get('password'), 'password');
    }
  }

  ngOnInit() {


    // if (navigator.userAgent.match(/Android/i)) {
    //   window.location.href = 'https://play.google.com/store/apps/details?id=com.curemetric.pharmacy'
    // }
    // else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    //   window.location.href = 'https://apps.apple.com/in/app/curemetric-pharmacy/id1671548768'
    // }



    $(function () {
      $(window).off('beforeunload');
      $("#preloader").fadeOut();
      $("#status").fadeOut(9000);
    });
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  get pwd() {
    return this.registerForm.get('password');
  }
  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
  }
  navigateToEnquiryForm(): void {
    this.router.navigate(['/enquiry-form'], { queryParams: { module:'pharma', returnUrl: this.router.url } });
}

  showValidationMessage: boolean = false;
  preventCopy(event: ClipboardEvent) {
    event.preventDefault();
    this.showValidationMessage = true;
    setTimeout(() => {
      this.showValidationMessage = false;
    }, 5000);
  }

  onSubmit() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    if (this.rememberme) {
      this.cookieService.set('email', this.crpto.encryption(this.email, "email"));
      this.cookieService.set('password', this.crpto.encryption(this.password, 'password',));
      this.cookieService.set('rememberme', 'Yes')
      this.cookieService.set('module', 'Pharmacy')
    } else {
      this.cookieService.set('email', "");
      this.cookieService.set('password', "");
      this.cookieService.set('rememberme', "")
    }
    var request = {
      'password': this.registerForm.value.password,
      'userName': this.registerForm.value.email,
      "type": "string"
    }

    this.apiservice.isUserSessionExits = false
    this.apiservice.isforUserIdExits = false
    this.apiservice.ismoduleNameExits = false
    this.globalStorage.clear()
    this.service.globalPostPharma('user/login', (request)).subscribe(res => {
      console.log('pharma res:', res);
      if (res.body['status'] == "SUCCESS") {
        //this.ToastrService.success('Logged in successfully')
        this.globalStorage.setItem('userData', JSON.stringify(res.body['data']));
        sessionStorage.setItem('pharmacyData', JSON.stringify(res.body['data']))
        this.globalStorage.setItem('loggedInAs', 'PHARMACY')
        var data = this.globalStorage.getItem('userData')
        var objs = JSON.parse(data)
        var today = moment(objs['lastLogin']).format('YYYY-MM-DD HH:mm:ss');
        this.globalStorage.setItem('pharmacyId', objs['pharmacyaddressId'])
        this.globalStorage.setItem('pharmacyAddressId', objs['pharmacyaddressId'])
        this.globalStorage.setItem('phpswd', this.registerForm.value.password)
        this.globalStorage.setItem('userId', objs['pharmacyUserId']);
        this.globalStorage.setItem('entityId', objs['pharmacyaddressId']);
        this.globalStorage.setItem('forUserId', objs['pharmacyUserId'])
        this.globalStorage.setItem('userSession', JSON.stringify(res.body['userSession']))
        this.cookieService.set('usersession',JSON.stringify(res.body['userSession']))

        //roles
        this.globalStorage.setItem('roles', JSON.stringify(res.body['data']['roles']))
        //roles


        this.apiservice.isUserSessionExits = true
        this.apiservice.isforUserIdExits = true
        this.apiservice.ismoduleNameExits = true

        // this.scripts.loadCssStyles()
        this.service.globalPostPharmaSession('isProfileComplted/' + this.globalStorage.getItem('userId'), {}).subscribe(res => {
          if (res['body']['data']['ispersonaldetailComplted'] == 'N') {
            this.router.navigate(['/pharmaPersonal'])
          }
          else {
            // if(res['body']['data']['isaddresComplted']=='N'){
            //   //this.router.navigate(['/pharmaAddress'])
            // }
            // else
            // {
            this.router.navigate(['pharmaDashboard'])
            this.pharmanotification.requestPermission(parseInt(this.globalStorage.getItem('pharmacyId')));
            // this.pharmanotification.receiveMessage();
            // this.pharmanotification.currentMessage;
            //}
          }
        })

        // this.service.getNotificications('notificationalerts/getnotificationalertsfordate/' + this.globalStorage.getItem('pharmacyId'), today).subscribe(res => {
        //   var array = res['body']['data'].reverse().slice(-5)
        //   for (var i = 0; i < array.length; i++) {
        //     this.ToastrService.info(array[i]['reason'], array['objecttype'], {
        //       positionClass: 'toast-bottom-right'
        //     })
        //   }

        // })
        this.globalStorage.setItem('theme', 'cureMetric.light.blue.min.css')

      }
      else {
        this.ToastrService.error('Invalid credentials either Username or Password wrong')
      }
    }
      , error => {
        this.invalidCredentials = true;
        if(error?.error?.status == "FAILURE"){
          this.message = "Invalid UserID or Password.";
        }
        this.router.navigate(['/pharmaLogin'])
      }
    )


  }
  change() {
    this.cond_vall = !this.cond_vall

  }
  forgotPassword() {

    this.router.navigate(['/pharmaForgot'])
  }
  remembermeCheck() {
    if (this.rememberme) {
      this.rememberme = false;
    } else {
      this.rememberme = true;
    }
  }

  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });
  }
  resetMessage(){
    this.invalidCredentials = false
  }
}
