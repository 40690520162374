import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { LoginService } from 'src/app/AppServices/login.service';
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { DoctorPatientService } from 'src/app/AppServices/DoctorPatient.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import * as moment from 'moment';
declare var $: any;
import { DomSanitizer } from '@angular/platform-browser';
import { PatientScheduleControlService } from '../../services/patientScedulecontrol.service';
import { ApicallsService } from 'src/app/apicalls.service';

@Component({
  selector: 'doc-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class docSidebarComponent implements OnInit {

  userName
  notViewedNotifications = []
  viewedNotifications = []
  userEmailId
  notificationCount
  notificationLength
  reminders
  userId
  setItemsPerPage = 6;
  nonViewed
  notifyToView
  p2 = 1;
  constructor(private globalStorage: GlobalStorageService, private router: Router, private loginService: LoginService,
    private route: Router, private doctorService: DoctorService,
    private sanitizer: DomSanitizer,
    private patientService: DoctorPatientService, private patientController: PatientScheduleControlService, private apiservice: ApicallsService,
    private toastrService: ToastrService, private matDialog: MatDialog) {

    this.doctorService.myAssociansObservable.subscribe(res => {
      this.getAssociation();
    })

    this.doctorService.refreshObservable.subscribe(res => {
      this.getPersonalDetails();
    })

  }

  ngOnInit(): void {
    var data = this.globalStorage.getItem('userData')

    var obj = JSON.parse(data)
    this.LoadDoctor();
   // this.getDoctorfeeDetails()
    this.getPersonalDetails();
    this.getAssociation();
    this.LoadNotification()
    this.getRatings()
    this.getInpatientList()
  }



  profileDetails
  LoadDoctor() {
    this.doctorService.getDoctorDetailsById(this.globalStorage.getItem('doctorId')).subscribe(res => {
      this.profileDetails = res['data']
    }, error => {
      this.toastrService.error("Error in  getting doctor Details")
    })
  }

  doctorDetails
  Age
  imgURL
  ImageSrc
  getAddressDetails() {
    this.doctorService.getAddressDetails(this.globalStorage.getItem('doctorId')).subscribe(res => {
      if (res['data'].length == 0) {
        this.route.navigate(['/addressDetails']);
      }
    })
  }
  getDoctorfeeDetails() {
    this.doctorService.getDoctorFeeDetails(this.globalStorage.getItem('doctorId')).subscribe(res => {
      if (res['data'].length == 0) {
        this.route.navigate(['/feeDetails']);
      }
      else {
        this.getPersonalDetails();
      }
    })
  }
  getPersonalDetails() {

    this.doctorService.getPersonalDetails(this.globalStorage.getItem('doctorId')).subscribe(res => {
      if (res['data'] != null) {
        this.doctorDetails = res['data'];
        var d1 = moment(this.doctorDetails.dob);
        var d2 = moment(new Date());
        var days = moment.duration(d2.diff(d1)).asDays();
        var years = days / 360
        this.Age = years.toFixed(0);
        this.globalStorage.setItem('Age', this.Age)
       // this.getAddressDetails()
        if (this.doctorDetails['profilePic'] != null) {
          //this.personalDetails.controls['profilePic'].patchValue(this.doctorDetails?.profilePic)
          this.ImageSrc = "data:image/jpeg;base64," + this.doctorDetails['profilePic']
          this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.ImageSrc);
        } else {
          if (this.profileDetails?.gender == 'Male' || this.profileDetails?.gender == 'male') {
            this.imgURL = "./assets/img/profile-male.png";
          }
          else {
            this.imgURL = "./assets/img/profile-female.png";
          }
        }
      } else {
        if (this.profileDetails?.gender == 'Male' || this.profileDetails?.gender == 'male') {
          this.imgURL = "./assets/img/profile-male.png";
        }
        else {
          this.imgURL = "./assets/img/profile-female.png";
        }
        this.Age = 0;
        this.globalStorage.setItem('Age', this.Age)
       // this.route.navigate(['/personalDetails']);
        this.toastrService.info('Please fill personal details')

      }

    }, error => {
      this.toastrService.error("Error in getting personal details")
    });
  }



  associationList: any[] = []
  MyAssociation: any[] = [{ 'entityId': 1, 'Name': 'Apollo ', 'type': 'General' }, { 'entityId': 2, 'Name': 'Med Plus', 'type': 'Appointment' }, { 'entityId': 3, 'Name': 'Vijay Clinic', 'type': 'General' },
  { 'entityId': 4, 'Name': 'Clinic 4', 'type': 'General' }, { 'entityId': 5, 'Name': 'Clinic 5', 'type': 'General' }, { 'entityId': 6, 'Name': 'Clinic 6', 'type': 'General' }]
  mapedMyAssociation: any[] = []
  getAssociation() {
    this.doctorService.getAssociationDetails(this.globalStorage.getItem('doctorId')).subscribe(res => {

      this.associationList = res['data'].filter(obj=>obj?.active == 'Y')
      this.mapedMyAssociation.length = 0;
      for (var i = 0; i < this.associationList.length; i++) {
        this.MyAssociation.find(x => {
          if (this.associationList[i].entityId == x.entityId && this.associationList[i].active === 'Y') {

            this.mapedMyAssociation.push(this.associationList[i]);
          }

        })

      }
    }, error => {
      this.toastrService.error("Error in getting certification details")
    });
  }




  dashboard() {
    this.route.navigate(['/dashboard'])
  }

  Appoinments() {
    this.route.navigate(['/Appoinments']);
  }

  EditProfile() {
    this.route.navigate(['/EditProfile']);
  }
  Scheduler() {
    //this.route.navigate(['/EditProfile']);
  }
  navigateToAssociation() {
    this.router.navigate(['/EditProfileAssociation'])
  }

  logOut() {
    this.loginService.LogOut().subscribe(res => {
      this.route.navigate(['/Login'])
      this.apiservice.isUserSessionExits = false
      this.apiservice.isforUserIdExits = false
      this.apiservice.ismoduleNameExits = false
      //this.globalStorage.clear();
    });
  }

  notications = []
  LoadNotification() {

    this.patientController.getLatestDoctorNotificationAlerts('notificationalerts/get/all?doctorId='+this.globalStorage.getItem('doctorId')+'&maxResult=100&pageNumber='+this.p1).subscribe(res => {

      this.notificationCount = res['data'].length
      this.notications = res['data']
      this.notificationLength = this.notications.filter((obj) => obj.viewed == 'N').length;
      this.notViewedNotifications = this.notications.filter((obj) => obj.viewed == 'N')
      this.viewedNotifications = this.notications.filter((obj) => obj.viewed == 'Y')
    }, error => {
    })
  }
  a = []
  rating
  rate: number
  getRatings() {
    this.doctorService.getDoctorRating(this.globalStorage.getItem('doctorId')).subscribe(res => {
      res['data'].forEach((value) => {
        this.a.push(value.rating)
      })
      this.rating = (this.a.reduce((a, b) => a + b, 0) / this.a.length).toFixed(0)

      this.rate = this.rating
      if (isNaN(this.rating)) {
        this.rating = 0
      }
      this.counter(JSON.parse(this.rating))
    })
  }
  array
  counter(i: any) {

    this.array = new Array(i)

  }

  file
  fileData
  uploadFile


  onFileInput(files: File) {
    const f = files
    if ((f[0].type) == "image/jpeg" || (f[0].type) == "image/png") {
      if (f[0].size > 1048769) {
        this.toastrService.warning('file should be 1 Mb')
      } else {
        this.file = files
      }


    } else {
      this.toastrService.warning('file should be in jpeg or PNG')
    }
    this.submitPrimary()
  }


  submitPrimary() {
    var data = this.globalStorage.getItem('userData')
    var obj = JSON.parse(data)



    var formData = new FormData();
    for (let i = 0; i < this.file.length; i++) {
      formData.append('profilePic', this.file[i], this.file[i].name);
    }

    var doctor = {
      'doctorId': parseInt(this.globalStorage.getItem('doctorId'))
    }

    this.doctorService.uploadProfilePic(formData, this.globalStorage.getItem('doctorId')).subscribe(res => {
      this.doctorService.getPersonalDetails(this.globalStorage.getItem('doctorId')).subscribe(res => {
        var src = "data:image/jpeg;base64," + res['data']['profilePic']
        this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(src);
        this.toastrService.success("Doctor profile pic updated successfully")
      })

    },
      error => {
        this.toastrService.error("Error in  updated profile")
      })
    // this.profileImage.updateProfilePic(formData,this.userId).subscribe(res=>{
    //   this.patientServices.navigationDetails()
    //   this.toaster.success('user Profile Updated')
    //   this.profileImage.getPersonalDetails(obj['userId']).subscribe(res=>{
    //     var src="data:image/jpeg;base64,"+res['body']['data']['profilePic']


    //     this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(src);


    //   })
    // })
  }










  // onFileInput(fileInput:any){
  //   this.fileData = <File>fileInput.target.files[0];
  //   this.preview();
  // //this.UpdateProfilePic()
  // }

  // preview(){

  //   var mimeType = this.fileData.type;
  //   if (mimeType == "image/jpeg"||mimeType== "image/png") {
  //     if(this.fileData.size>1048769){
  //          this.toastrService.warning('Profile size should be less than 1 Mb')
  //         }else{
  //           this.uploadFile = this.fileData

  //           var formData=new FormData();;
  //           if(this.uploadFile==undefined || this.uploadFile==null){
  //             formData.append('profilePic',null);
  //             //formData.append('doctorId', JSON.stringify(this.globalStorage.getItem('doctorId')));
  //           }else{
  //               formData.append('profilePic', this.uploadFile, this.uploadFile.name);
  //           }

  //           var doctor={
  //             'doctorId':parseInt(this.globalStorage.getItem('doctorId'))
  //           }
  //           this.doctorService.uploadProfilePic(formData,doctor).subscribe(res => {
  //            // this.doctorService.navigationDetails();
  //            var src="data:image/jpeg;base64,"+res['body']['data']['profilePic']

  //            this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(src);
  //             this.toastrService.success("Doctor profile pic updated successfully")
  //           },
  //           error=>{
  //             this.toastrService.error("Error in  updated profile")
  //           })

  //         }
  //   }else{
  //      this.toastrService.warning('Profile farmat should jpeg or PNG')
  //     }

  //   var reader = new FileReader();
  //   reader.readAsDataURL(this.fileData);

  //   reader.onload = (_event) => {
  //     this.imgURL = reader.result;
  //   }
  // }
  p1=1
  loadReminders() {
    this.patientController.getLatestDoctorNotificationAlerts('notificationalerts/get/all?doctorId='+this.globalStorage.getItem('doctorId')+'&maxResult=100&pageNumber='+this.p1).subscribe(res => {
      this.reminders = res['data'].reverse()
      this.nonViewed = this.reminders.filter((obj) => obj.viewed == 'N')
      this.notificationLength = this.reminders.filter((obj) => obj.viewed == 'N').length;
    }, error => {
    })
  }

  closeModal() {
    $('#notificationToView').modal('hide')
    this.loadReminders()
  }
  openPayment(){
    this.router.navigate(['/Payments'])
  }
  openAllAppointments(){
    this.router.navigate(['/Appoinments'])
  }
  updateNotify(data) {
    this.notifyToView=data;
    $('#notificationViewer123').modal('show');
    var req = {
      "active": data.active,
      "notificationalertsId": data.notificationalertsId,
      "objectkey": data.objectkey,
      "objecttype": data.objecttype,
      "doctorId": data.doctorId,
      "reason": data.reason,
      "viewed": "Y"
    }
    this.doctorService.updateNotifications(data.notificationalertsId, data.doctorId, req).subscribe(res => {
      this.notificationLength = this.notificationLength - 1
      this.LoadNotification();
    },
    error => {
      this.toastrService.error(error);
    }
    )

  }
  reviewOpen() {
    $('#viewRatings').modal('show')
  }
  assignedPatientList
  getInpatientList(){
  this.doctorService.getAssignedPatients(this.globalStorage.getItem('doctorId')).subscribe(res=>{
    this.assignedPatientList=res['data']
  })
  }
  viewCurrentAppontment(appointmentDetails) {
    this.doctorService.appointments = appointmentDetails
    this.getPatientDetails(appointmentDetails);
  }

  getPatientDetails(appointmentDetails) {
    let mainUserId = JSON.parse(this.globalStorage.getItem('forUserId'))

    this.globalStorage.setItem('forUserId', appointmentDetails.patientId)
    var req = {
      "forUserId": appointmentDetails.patientId,
      "userId": JSON.parse(this.globalStorage.getItem('doctorId')),
    }

    this.patientService.getUserPersonalDetails('personaldetails/get/', appointmentDetails.patientId, req).subscribe(res => {
      this.globalStorage.setItem('forUserId', mainUserId)
      var patientDetails = res['body']['data'];
      patientDetails.AppointmentDetails = appointmentDetails;
      patientDetails.Appointment = 'Current';
      patientDetails.type = 'Hospital';
      patientDetails.inPatientId=appointmentDetails.inpatientId
      patientDetails.hospitalAddresId=appointmentDetails.hospitalAddresId
      //patientDetails.Appointment='Upcoming';
      this.doctorService.OpenPatientSideNave(patientDetails);
    }, error => {
      this.globalStorage.setItem('forUserId', mainUserId)
      this.toastrService.error("Error in getting patient details");
    })
  }
}
