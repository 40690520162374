import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { IvfService } from 'projects/ivf/src/app/services/ivf.service';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { AppointmentBookingComponentNew } from 'src/app/common-components/appointment/appointment-booking/appointment-booking.component';
import { DoctorScheduleComponent } from '../doctor-schedule/doctor-schedule.component';

@Component({
  selector: 'app-clinic-opd-appointment-booking',
  templateUrl: './clinic-opd-appointment-booking.component.html',
  styleUrls: ['./clinic-opd-appointment-booking.component.css']
})
export class ClinicOpdAppointmentBookingComponent implements OnInit {
  @Input() userId: any;

  bookingForm:FormGroup;
  dNoo: number=1;
  doctorsList=[]
  search1:any;
  sortOrder: string = 'asc';
  sortField: string;
  constructor(private globalStorage: GlobalStorageService, private fb: FormBuilder, public activeModal: NgbActiveModal, private ivfService: IvfService, private toastr: ToastrService, private modalService:NgbModal,
    private clinicServices:ClinicService) { }

  ngOnInit(): void {
    console.log('user Id',this.userId)
    this.initializeData();
    this.getUserDetails();
    this.getDoctorsList();
  }

  initializeData(){
    this.bookingForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      middleName: [''],
      userName: [''],
      email: [''],
      contactNum: [''],
      password: [''],
      confirmPassword: [''],
      dob: [''],
      gender: [''],
      bloodgroup: ['']
    })
  }
  sort: any;
  onSort(name,field){

    if(name=='unpaid'){
      this.dNoo=1;
      this.doctorsList=this.ivfService.sort(this.doctorsList,field)
    }



  }


  getDoctorsList(){
    if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
      this.clinicServices.getDoctorandNutritionistsList(this.globalStorage.getItem('hospitalAddressId')).subscribe(res => {
        console.log('res body',res)
        this.doctorsList = res['body']?.['data']
      }, error => {
        this.toastr.error(error?.['error']?.['message'])
      })
      // this.clinicServices.getHospitalDoctors(this.globalStorage.getItem('hospitalAddressId'), 'DOCTOR').subscribe(res => {
      //   this.doctorsList = res['data'].filter(obj => obj?.type == 'DOCTOR' && obj.active == 'Y')
      // }, error => {
      //   this.toastr.error(error?.['error']?.['message'])
      // })
    }
    if(this.globalStorage.getItem('loggedInAs')=='IVF'){
      this.ivfService.postWithSessionWithEmptyBody('getDoctorDetail?ivfaddressId='+this.globalStorage.getItem('ivfAddressId')).subscribe(res => {
        console.log('doctors list',res)
        for(var k=0;k<res['body']?.['data']?.length;k++){
          var obj={
            firstName:res['body']?.['data']?.[k]?.['firstName'],
            lastName:res['body']?.['data']?.[k]?.['lastName'],
            specialization:res['body']?.['data']?.[k]?.['specialization'],
            feedetails:res['body']?.['data']?.[k]?.['feedetails'].filter(obj=>obj.type=="Inperson"),
            avgRating:res['body']?.['data']?.[k]?.['averagerating'],
            totalExperience:res['body']?.['data']?.[k]?.['totalExperience'],
            gender:res['body']?.['data']?.[k]?.['gender'],
            referenceentityId:res['body']?.['data']?.[k]?.['doctorReferenceId']
          }
          this.doctorsList.push(obj)
        }
      }, error => {
        this.toastr.error(error?.['error']?.['message'])
      })
    }
  }

  patientDetails:any;
  getUserDetails() {
    if(this.userId){
      if(this.globalStorage.getItem('loggedInAs')=='HOSPITAL'){
        var req = {
          "forUserId": this.userId,
          "userId": this.globalStorage.getItem('hospitalAddressId')
        }
        this.clinicServices.findUserById(this.userId).subscribe(res=>{
          console.log('user data',res)
          this.patientDetails = JSON.parse(res['data'])
          this.bookingForm.patchValue({
            firstName: this.patientDetails?.firstName,
            lastName: this.patientDetails?.lastName,
            middleName: this.patientDetails?.middleName,
            userName: this.patientDetails?.userName,
            email: this.patientDetails?.emailId,
            contactNum: this.patientDetails?.cellNumber,
          })
        },error=>{
          this.toastr.error(error?.error?.message)
        })
      }
      if(this.globalStorage.getItem('loggedInAs')=='IVF'){
        this.ivfService.getIVFUserDetails(this.globalStorage.getItem('ivfAddressId'),this.userId).subscribe(res=>{
          this.patientDetails = res['body']['data']
         this.bookingForm.patchValue({
           firstName: this.patientDetails?.firstName,
           lastName: this.patientDetails?.lastName,
           middleName: this.patientDetails?.middleName,
           userName: this.patientDetails?.userName,
           email: this.patientDetails?.emailId,
           contactNum: this.patientDetails?.cellNumber,
         })
       })
      }
    }

  }

  bookDoctor(doctorDetails) {
    const modalRef = this.modalService.open(DoctorScheduleComponent, {
      centered: false,
      windowClass: 'modal-left hw-50',
      backdrop: 'static',
        keyboard: false,
    });
    modalRef.componentInstance.doctorDetails = doctorDetails;
    modalRef.componentInstance.patientDetails=this.patientDetails

    modalRef.result.then(
      (userConfirmed: boolean) => {
        this.activeModal.close()
        if (userConfirmed) {
          // this.getProtocols();
        }
      },
      () => { }  // Handle dismiss callback
    );

    // console.log('fee details',doctorDetails)
    // this.dNoo=1;
    // this.doctorName = doctorDetails?.firstName + ' ' + doctorDetails?.lastName
    // this.specializ = doctorDetails?.specialization
    // this.ttlExperience = doctorDetails?.totalExperience
    // this.gender = doctorDetails?.gender
    // if(doctorDetails?.avgRating){
    //   this.aRating=doctorDetails?.avgRating
    // }
    // else{
    //   this.aRating=0
    // }
    // this.fee = doctorDetails?.feedetails?.[0]?.fee
    // this.advanceAmount=0;
    // this.DocID = doctorDetails?.referenceentityId

    // this.ivfService.getDoctorSlots(this.globalStorage.getItem('ivfAddressId'),doctorDetails?.referenceentityId).subscribe(res => {
    //   console.log('baseschedule',res)
    //   this.baseScheduleSlots = res['data']
    //   this.sceduleDate(this.baseScheduleSlots)
    //   $('#appointmentBookingFirst').modal('show')
    // })
  }

  checkDctr(name){

  }

  doctorSearching(){
    this.dNoo=1;
  }

}
