import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-doctor-all-reports',
  templateUrl: './doctor-all-reports.component.html',
  styleUrls: ['./doctor-all-reports.component.css']
})
export class DoctorAllReportsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
