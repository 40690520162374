import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import {DoctorService} from '../../AppServices/doctor.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-reject-appontment',
  templateUrl: './reject-appontment.component.html',
  styleUrls: ['./reject-appontment.component.css']
})
export class RejectAppontmentComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public patientId: any,private doctorService:DoctorService,private toastrService:ToastrService,public dialogRef: MatDialogRef<RejectAppontmentComponent>) { }


  ngOnInit(): void {

  }

  Reject(){
    
    //  this.doctorService.rejectPatientRequist(patientId).subscribe(res=>{
    //   this.toastrService.success("Rejected patient request")
    // },error=>{
    //   this.toastrService.error("Error in rejected patient request")
    // })
  }

  close() {
    this.dialogRef.close();
  }

}
