<div class="modal-header">
    <h4 class="centerHeader mb-0" *ngIf="!isSubCatEdit">Add Item</h4>
    <h4 class="centerHeader mb-0" *ngIf="isSubCatEdit">Edit Item</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="SubcategoryForm">
        <div class="row">

            <div class="col-lg-6 col-md-6">
                <label class="form-label">Item Name<b class="text-danger">*</b></label>

                <input type="text" class="form-control" placeholder="Enter Item Name" formControlName="name"
                    [readonly]="isSubCatEdit" noWhiteSpaceAtTheStart
                    [ngClass]="{ 'is-invalid': (submitted || Subf.name.touched) && Subf.name.errors }">
                <div *ngIf="(submitted || Subf.name.touched) && Subf.name.errors" class="invalid-feedback">

                    <div *ngIf="Subf.name.errors.required">
                        Item Name is Required.
                    </div>

                </div>
            </div>

            <div class="col-lg-6 col-md-6" *ngIf="!category">
                <label class="form-label">Category <span class="text-danger">*</span></label>
                <mat-select class="form-control">
                    <mat-option *ngFor="let c of categorylist" [value]="c.catagory"
                        (click)="selectedCategory(c)">{{c.catagory}}</mat-option>
                </mat-select>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label>Manufactured By</label>
                <mat-select placeholder="Select..." [(ngModel)]="selectedManufact" class="form-control"
                    formControlName="manufacturer" (selectionChange)="drpdownSelection(selectedManufact)"
                    [ngClass]="{'is-invalid': (submitted || Subf.manufacturer.touched) && Subf.manufacturer.errors}">
                    <mat-option *ngFor="let data of manufacturersData" [value]="data.manufacturer"
                        (click)="selectedManufacter(data)">
                        {{ data.manufacturer }}
                    </mat-option>
                </mat-select>
                <div *ngIf="(submitted || Subf.manufacturer.touched) && Subf.manufacturer.errors"
                    class="invalid-feedback">
                    <div *ngIf="Subf.manufacturer.errors.required">Please select Manufacturer.</div>
                </div>
            </div>

            <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label>Disposable</label>
                <mat-select placeholder="Select..." formControlName="disposal" class="form-control"
                    (selectionChange)="optionSelected($event)">
                    <mat-option *ngFor="let val of selectOptions" [value]="val.value">{{val.label}}</mat-option>
                </mat-select>
            </div> -->


            <!--  <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label>Usage Units</label>
                <input type="text" class="form-control" formControlName="usageUnits" appInputRestrict="decimalOnly"
                    maxlength="10" placeholder="Enter Units">
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label>Location</label>
                <input type="text" class="form-control" formControlName="rack" placeholder="Enter Here">
            </div> -->

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label>HSN Code</label>
                <input type="text" class="form-control" formControlName="hsn" appInputRestrict="decimalOnly"
                    maxlength="10" placeholder="Enter Code">
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12 form-group mb-3">
                <label>Tax</label>
                <!-- <input type="text" class="form-control" formControlName="tax" appInputRestrict="decimalOnly"
                    maxlength="10" placeholder="Enter Tax"> -->
                <select formControlName="tax" class="form-control">
                    <option *ngFor="let percentage of percentages" [value]="percentage">
                        {{ percentage }}%
                    </option>
                </select>
            </div>


            <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3">
                <label for="" class="col-form-label">Description</label>
                <div class="form-group input-material">
                    <textarea autoResizeRows class="form-control" rows="5" formControlName="description" [ngClass]="{
                        'is-invalid':
                          (submitted || Subf.description.touched) && Subf.description.errors
                    }"></textarea>
                    <div *ngIf="(submitted || Subf.description.touched) && Subf.description.errors"
                        class="invalid-feedback">

                        <div *ngIf="Subf.description.errors.required">
                            Description Required
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-6 col-lg-6 col-md-6 col-xs-12"></div>

            <!-- pharma fields -->
            <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3">
                <h3 class="mt-2 font-size-14 border-bottom p-2 bg-primary-light">Pharmacy</h3>
                <div class="row">
                    <div class="col-lg-6 col-md-6 col-xs-12">
                        <div class="form-group">
                            <label>Potency (mg/ml)</label>
                            <input type="text" placeholder="" class="form-control" formControlName="potency">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                        <div class="form-group">
                            <label>RX Required</label>
                            <mat-select placeholder="Select..." formControlName="prescription" class="form-control"
                                (selectionChange)="optionSelected($event)">
                                <mat-option *ngFor="let val of selectOptions"
                                    [value]="val.value">{{val.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                        <div class="form-group">
                            <label>Injectable</label>
                            <mat-select placeholder="Select..." formControlName="injectable" class="form-control"
                                (selectionChange)="optionSelected($event)">
                                <mat-option *ngFor="let val of selectOptions"
                                    [value]="val.value">{{val.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                        <div class="form-group">
                            <label>Alternate</label>
                            <input type="text" placeholder="" class="form-control" formControlName="alternate">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                        <div class="form-group">
                            <label>Freezable</label>
                            <mat-select placeholder="Select..." formControlName="freezable" class="form-control"
                                (selectionChange)="optionSelected($event)">
                                <mat-option *ngFor="let val of selectOptions"
                                    [value]="val.value">{{val.label}}</mat-option>
                            </mat-select>
                        </div>
                    </div>
                </div>
            </div> -->
            <!-- pharma fields /-->
            <!-- 
            <h3 class="mb-3 mt-2 font-size-16 border-bottom p-2 bg-danger-light ">GST</h3>

            <div class="col-xl-12 col-lg-12 col-md-12 col-xs-12 form-group mb-3">
                <mat-radio-group class="example-radio-group"
                    [(ngModel)]="selectedOption" [ngModelOptions]="{standalone: true}">
                    <mat-radio-button class="example-radio-button" type="radio"
                        (change)="gstCheckBox($event)" *ngFor="let option of gstOptions"
                        [value]="option">
                        {{option}}
                    </mat-radio-button>
                </mat-radio-group>

                <ng-container *ngIf="showGstFields">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mt-2">
                            <label>C-GST <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" #cgst maxlength="5" appInputRestrict="decimalOnly"
                                placeholder="C GST" formControlName="cgst" 
                                [ngClass]="{ 'is-invalid': (submitted || Subf.cgst.touched) && Subf.cgst.errors }">
                            <div *ngIf="(submitted || Subf.cgst.touched) && Subf.cgst.errors"
                                class="invalid-feedback">

                                <div *ngIf="Subf.cgst.errors.required">
                                    Please enter C-GST.
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mt-2">
                            <label>S-GST <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" #sgst maxlength="5" appInputRestrict="decimalOnly"
                                placeholder="S GST" formControlName="sgst" readonly
                                [ngClass]="{ 'is-invalid': (submitted || Subf.sgst.touched) && Subf.sgst.errors }">
                            <div *ngIf="(submitted || Subf.sgst.touched) && Subf.sgst.errors"
                                class="invalid-feedback">

                                <div *ngIf="Subf.sgst.errors.required">
                                    Please enter S-GST.
                                </div>
                            </div>
                        </div>    
                    </div>
                </ng-container>
                <ng-container *ngIf="showIGstFields">
                    <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-xs-12 form-group mt-2">
                            <label>I-GST <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" #igst maxlength="5" appInputRestrict="decimalOnly"
                                placeholder="I GST" formControlName="igst"
                                [ngClass]="{ 'is-invalid': (submitted || Subf.igst.touched) && Subf.igst.errors }">
                            <div *ngIf="(submitted || Subf.igst.touched) && Subf.igst.errors"
                                class="invalid-feedback">

                                <div *ngIf="Subf.igst.errors.required">
                                    Please enter I-GST.
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div> -->
        </div>
        <div class="vime text-right">
            <button type="button" class="btn btn-danger al mt-3 float-right btn-sm ml-2" (click)="closeModal()">
                Cancel
            </button>
            <button type="button" class="btn btn-success al mt-3 float-right btn-sm ml-2" *ngIf="!isSubCatEdit"
                (click)="savesubcategoryform()" [disabled]="SubcategoryForm.invalid">
                <i class="plusicon fas fa-save"></i> Save
            </button>

            <button type="button" class="btn btn-primary al mt-3 float-right btn-sm ml-2" *ngIf="isSubCatEdit"
                (click)="upDateSubCat()">
                <i class="plusicon fas fa-pencil"></i> Update
            </button>
        </div>
    </form>
</div>