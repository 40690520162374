import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DoctorService } from 'src/app/AppServices/doctor.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from "@angular/material/dialog";
import * as moment from 'moment';
import { CaretakerService } from 'src/app/shared/services/caretaker.service';
import { Router } from '@angular/router';
import { DiagnosticService } from 'src/app/AppServices/diagnostic.service';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-cancel-appintment',
  templateUrl: './cancel-appintment.component.html',
  styleUrls: ['./cancel-appintment.component.css']
})
export class CancelAppintmentComponent implements OnInit {

  cancelReasonForm: FormGroup;

  constructor(private fb: FormBuilder, private doctorService: DoctorService,public clinicServices: ClinicService, private caretakerServices: CaretakerService, private router: Router,
    private toastrService: ToastrService, @Inject(MAT_DIALOG_DATA) public appintmentData: any, private dcServices: DiagnosticService,
    public dialogRef: MatDialogRef<CancelAppintmentComponent>) { }

  ngOnInit(): void {
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":"
    var dateTime = date + ' ' + time;

    this.cancelReasonForm = this.fb.group({
      reason : ['', Validators.required]
    });
  }

  close() {
    this.dialogRef.close();
  }


  cancelAppontment(reason) {
    // var appintmentObj={
    //   appointmentId:this.appintmentData.appointmentId
    // } 
    var toDay = new Date()
    var today = moment(toDay).format('YYYY-MM-DD');
    var date: any = new Date()
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds()
    var ampm = hours >= 12 ? 'PM' : 'AM';
    // hours = hours % 12;
    // hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    var strTime = hours + ':' + minutes + ':' + seconds
    if (this.appintmentData.appointmentDate == today) {
      if (this.appintmentData.fromTime == strTime || (this.appintmentData.fromTime <= strTime && strTime <= this.appintmentData.toTime)) {
        this.toastrService.warning("Opps slot was not cancelled!! this slot already started")
        this.close()
        return
      }
    }
    if(this.router.url == '/caretaker-dashboard' || this.router.url == '/caretaker-appointments') {
      console.log('appointments data',this.appintmentData)
    this.appintmentData.reason= reason;
    console.log('RPMPATIENT',this.appintmentData);
      if(this.appintmentData?.appointmentFor=='RPMPATIENT'){
        var bd={
          "appointmentStatus": "ENTITY_CANCELLED",
          "caretakerrpmappointmentId": this.appintmentData?.caretakerrpmappointmentId,
          "description": reason
        }
        this.caretakerServices.cancelRpmAppointment(bd).subscribe(res => {
          this.toastrService.success("Appontment cancelled.");
          let currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
        }, error => {
          this.toastrService.error("Error in cancel appontment.");
        })
      }
      else{
        this.caretakerServices.cancelAppointment(this.appintmentData).subscribe(res => {
          this.toastrService.success("Appontment cancelled.");
          let currentUrl = this.router.url;
          this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          this.router.onSameUrlNavigation = 'reload';
          this.router.navigate([currentUrl]);
        }, error => {
          this.toastrService.error("Error in cancel appontment.");
        })
      }
    }
    else if (this.router.url == '/diagnosticDashboard' || this.router.url == '/diagnosticAppointments') {
      this.dcServices.cancelDcAppointment(this.appintmentData).subscribe(res => {

        this.toastrService.success("Appointment cancelled.");
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
      }, error => {
        this.toastrService.error("Error in cancel appointment.");
      })
    }
    else if (this.router.url == '/clinic-dashboard' || this.router.url == '/clinic-appointments' || this.router.url=='/clinic-doctor-dashboard') {
      this.clinicServices.cancelHospitalAppointment(this.appintmentData).subscribe(res => {
        this.toastrService.success("Appointment cancelled.");
        let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
      }, error => {
        this.toastrService.error("Error in cancel appointment.");
      })
    }
    else {
      this.doctorService.cancelAppointment(this.appintmentData).subscribe(res => {
        this.doctorService.reloadCurrentAppointment();
        this.toastrService.success("Appointment cancelled.");
      }, error => {
        this.toastrService.error("Error in cancel appointment.");
      })
    }
    this.close()
  }
}
