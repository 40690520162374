<div class="modal-header">
    <h3>Patient Details</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <app-patient-primary-details-screen [patientId]="patientId" ></app-patient-primary-details-screen>
    <!-- <div class="row startforms ivfm">
        <div class="col-lg-12">
            <div class="cm-height">

                <ul class="nav nav-tabs navhos navlistscroll nav-justified">
                    <li class="nav-item" *ngFor="let tab of tabs">
                        <a [class.active]="tab.identifier === activeTab.identifier" (click)="tabClicked(tab)"
                            href="javascript:void(0);">
                            <i class="fa fa-calendar-check" aria-hidden="true"></i>
                            {{ tab.title }}
                        </a>
                    </li>
                </ul>
    

                <div class="tab-content">
    
                    <div *ngIf="activeTab.identifier === 'registrationTab'">
                        <app-patient-primary-details-screen [patientId]="patientId" ></app-patient-primary-details-screen>
                    </div>
    
                    <div *ngIf="activeTab.identifier === 'personalDetailsTab'">
                         <app-patient-personal-details-screen [userId]="referenceId" [isPrimaryRegistered]="isPrimaryRegistered" [isPatientSingle]="isPatientSingle" (personalDetailsSaved)="personalDetailsSaved($event)" (spouseRegistered)="bookDoctorAppointment(patientId)"></app-patient-personal-details-screen>
                    </div>
    
                  
    
                </div>
            </div>
        </div>
    </div> -->
</div>
