import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { GenericValidationService } from './generic-validation.service';

@Directive({
  selector: '[appUnifiedValidation]'
})
export class UnifiedValidationDirective implements OnInit {
  private errorMessageDiv: HTMLElement;

  @Input('appUnifiedValidation') validationTypes: string[];
  @Input() minlength: number=3;
  @Input() maxlength: number=10;
  @Input() min: number=1;
  @Input() max: number=99999;

  constructor(
    private el: ElementRef,
    private control: NgControl,
    private renderer: Renderer2,
    private unifiedValidationService: GenericValidationService
  ) {}

  ngOnInit() {

    // this.applyValidation()
    // Subscribe to value changes of the form control
    this.control.valueChanges.subscribe(() => {
      this.applyValidation();
      this.updateErrorMessage();
    });

    // Subscribe to status changes of the form control
    this.control.statusChanges.subscribe(() => {
      this.updateErrorMessage();
    });

      // Listen for focus events
  this.renderer.listen(this.el.nativeElement, 'focus', () => {
    this.applyValidation(); // Optionally validate on focus
    this.updateErrorMessage(); // Update or show the error message
  });

  this.renderer.listen(this.el.nativeElement, 'focusout', () => {
    // Check if the control has the 'required' error
    if (this.control.control.hasError('required')) {
      // Clear the 'required' error when the input loses focus
      // this.control.control.setErrors(null);
    }
  });

  // // Optional: Listen for blur events if you want to change behavior when the input is no longer focused
  // this.renderer.listen(this.el.nativeElement, 'blur', () => {
  //   // Implement any specific behavior on blur if needed
  //   // For example, you might want to hide the error message if there are no errors
  //   if (!this.control.errors) {
  //     this.removeErrorMessage();
  //   }
  // });

  }

  applyValidation() {
    const errors = this.unifiedValidationService.validate(this.control.control, this.validationTypes , this.minlength, this.maxlength,this.min,this.max);
    if (errors) {
      this.control.control.setErrors(errors);
    }
  }

  updateErrorMessage() {
    const controlErrors = this.control.errors;
    if (controlErrors) {
      let validationMessage = '';
      for (const validationType of this.validationTypes) {
        const message = this.unifiedValidationService.getValidationMessage(controlErrors, validationType);
        if (message) {
          validationMessage = message;
          break; // Display the first relevant error message
        }
      }
      this.showErrorMessage(validationMessage);
    } else {
      this.removeErrorMessage();
    }
  }




    private showErrorMessage(message: string) {
    if (!this.errorMessageDiv) {
      this.errorMessageDiv = this.renderer.createElement('div');
      this.renderer.addClass(this.errorMessageDiv, 'invalid-error');
      this.renderer.setStyle(this.errorMessageDiv, 'display', 'block');
    }
    this.renderer.setProperty(this.errorMessageDiv, 'innerHTML', message);
    if (!this.errorMessageDiv.parentNode) {
      this.renderer.appendChild(this.el.nativeElement.parentElement, this.errorMessageDiv);
    }
  }

  // private showErrorMessage(message: string) {
  //   if (!this.errorMessageDiv) {
  //     // Create the error message div if it does not exist
  //     this.errorMessageDiv = this.renderer.createElement('div');
  //     this.renderer.addClass(this.errorMessageDiv, 'invalid-feedback');
  //     this.renderer.setStyle(this.errorMessageDiv, 'display', 'block');

  
  //     const messageDiv = this.renderer.createElement('div');
  //     this.renderer.appendChild(this.errorMessageDiv, messageDiv);
  
  //     this.renderer.appendChild(this.el.nativeElement.parentElement, this.errorMessageDiv);
  //   }
  
  //   // Whether it's newly created or already exists, update the message
  //   const messageDiv = this.errorMessageDiv.firstChild;
  //   if (messageDiv) {
  //     this.renderer.setProperty(messageDiv, 'innerText', message);
  //   }
  // }
  
  

  private removeErrorMessage() {
    if (this.errorMessageDiv && this.errorMessageDiv.parentNode) {
      this.renderer.removeChild(this.el.nativeElement.parentElement, this.errorMessageDiv);
    }
  }
}
