import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appAppTitleCase]'
})
export class AppTitleCaseDirective {

  @Input() appTitleCase:string
  constructor(private el: ElementRef) {
  }


  @HostListener('input', ['$event']) onInputChange(event) {
  
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = this.toTitleCase(initialValue)
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
   toTitleCase(input) {
    return input.replace(/\w\S*/g, (txt => txt[0].toUpperCase() + txt.substr(1).toLowerCase()));
   }
}
