import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryService } from '../inventory.service';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-create-orders',
  templateUrl: './create-orders.component.html',
  styleUrls: ['./create-orders.component.css']
})
export class CreateOrdersComponent implements OnInit {
  @Input() addressId: any;
  @Input() service: any;
  @Input() isEdit: boolean = false;
  @Input() order: any;
  @Input() fromWhere: boolean = false;
  @Input() stock:any;
  @Input() assignAssociatedItems : boolean = false;
  @Input() associatedItems : any;
  @Input() repeatOrd: boolean = false;
  createorders: FormGroup;
  freeProducts: any;
  freeItemsAvailable: any;
  showMessage: boolean = false;
  message: string = '';
  categorySelected: any;
  subCategorySelected: any;
  categorylist: any[] = [];
  subcategory: any[] = [];
  Supplierselected: any;
  suplier: any[] = [];
  deal: string = '';
  buyQuantity: any;
  sellQuantity: any;
  discount: any;
  selectedOption: any;
  options: string[] = ['Discount on price (%)', 'Addtional Items Offer',];
  gstOptions: string[] = ["GST", "I-GST"];
  showGstFields: boolean = false;
  showIGstFields: boolean = false;
  minDate;
  submitted: boolean = false;
  fb: any;

  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder, private inventoryService: InventoryService, private toastrService: ToastrService, public activeModal: NgbActiveModal, private invService: InventoryService) {
    this.minDate = new Date();
     this.selectedOption = this.gstOptions[0];
     this.showGstFields = true;
  }

  ngOnInit(): void {
    this.createorders = this.formBuilder.group({
      Category: ['', Validators.required],
      SubCategory: ['', Validators.required],
      Supplier: ['', Validators.required],
      unitPrice: ['', Validators.required],
      Quantity: ['', Validators.required],
      totalPrice: ['', Validators.required],
      ExpectedDate: ['', Validators.required],
      cgst: [''],
      sgst: [''],
      igst: [''],
      itemName: ['', Validators.required],
      freeItems: [''],
    })

    this.createorders.get('cgst').valueChanges.subscribe((cgstValue => {
      this.createorders.get('sgst').setValue(cgstValue);
    }))

    if (!this.isEdit) {
      this.getcategory();
    }

    if(this.assignAssociatedItems){
      // console.log('repeat order:', this.associatedItems);
      this.getcategory();
      let catId = this.associatedItems?.categryId || this.associatedItems?.catagoryId;
      this.getcategyById(catId);
      this.getsubcategyById(this.associatedItems);
      console.log('incoming assciated items:', this.associatedItems);
      this.createorders.patchValue({
        Category: this.associatedItems?.catagoryname || this.associatedItems?.catagory || this.associatedItems?.catagoryName,
        SubCategory: this.associatedItems?.itemTypeName || this.associatedItems?.itemName || this.associatedItems?.subCatagory,
        Supplier: this.associatedItems?.supplierName,
        unitPrice: this.associatedItems.unitPrice,
        // Quantity: this.associatedItems.totalStockOrdered,
        // totalPrice: this.associatedItems.totalPrice,
        // ExpectedDate: this.associatedItems.expectedDeliveryDate,
        cgst:this.associatedItems.cgst,
        sgst:this.associatedItems.sgst,
        igst:this.associatedItems.igst,
        itemName: this.associatedItems?.itemTypeName || this.associatedItems.itemName,
        freeItems:this.associatedItems.freeQuantity,
      })
      this.inventoryidFromObject = this.associatedItems?.inventoryId;
      this.supplierIdfromObj = this.associatedItems?.supplierId;
      this.deal = this.associatedItems?.deal;
      if(this.associatedItems?.buyQuantity != null){
        this.buyQuantity = this.associatedItems?.buyQuantity;
      }
      if(this.associatedItems?.freeQuantity != null){
        this.sellQuantity = this.associatedItems?.freeQuantity;
        this.freeItemsAvailable = this.associatedItems?.freeQuantity;
      }
      if(this.associatedItems?.discount != null){
        this.discount = this.associatedItems?.discount;
      }
    }

    if(this.repeatOrd){
      this.createorders.patchValue({
        Quantity: this.associatedItems.totalStockOrdered,
        unitPrice: this.associatedItems.unitPrice,
      })
    }

    if (this.isEdit) {
      this.fetchOrderDetailsById();
      this.createorders.patchValue({
        Category: this.order.catagoryName,
        SubCategory: this.order.itemName,
        Supplier: this.order.supplierName,
        unitPrice: this.order.unitPrice,
        Quantity: this.order.totalStockOrdered,
        totalPrice: this.order.totalPrice,
        ExpectedDate: this.order.expectedDeliveryDate,
        cgst:this.order.cgst,
        sgst:this.order.sgst,
        igst:this.order.igst,
        itemName:this.order.itemName,
        freeItems:this.order.freeItems,
      })
      this.inventoryidFromObject = this.order?.inventoryId;
      this.deal = this.order.deal;
      this.buyQuantity = this.order.buyQuantity,
      this.sellQuantity = this.order.freeQuantity,
      this.discount = this.order.discount
      this.Supplierselected=this.order
      this.Supplierselected = this.order.supplierName
      this.subCategorySelected = this.order.itemName;
      this.categorySelected = this.order.catagoryName;
    }

    if(this.stock){
      this.inventoryidFromObject = this.stock?.inventoryId;
      this.createorders.patchValue(this.stock)
    }

  }

  get createordersControls() {
    return this.createorders.controls;
  }
  updateItemName() {
    const selectedSubCategory = this.createorders.get('SubCategory').value;
    this.createorders.patchValue({
        itemName: selectedSubCategory
    });
}

  selectedCategory(categorySelected) { }

  catIdfromObj;
  getcategyById(cat) {
    this.suplier = [];
    this.selectedOption = '';
    this.createorders.patchValue({
      unitPrice: '',
      totalPrice: '',
      cgst: '',
      sgst: '',
      igst: ''
    });
    this.showIGstFields = false;
    this.showGstFields = false;
    this.catIdfromObj = cat;
    var body = {
      "catagoryId": this.catIdfromObj,
      "flag": "Y",
      "addressId": this.addressId
    }
    this.invService.subcategory(body).subscribe(res => {
      this.subcategory = res?.['data'];
      if(this.stock){
        this.createorders.patchValue(
          {  SubCategory:this.stock?.name}
        )
        const sto=this.subcategory.filter(item=>{
          return item?.name==this.stock?.name
        })
        this.getsubcategyById(sto[0])
      }

    })
  }

  selectedsubCategory(subCategorySelected) {
    this.subCategorySelected = subCategorySelected;
  }

  itemTypidFromObj; inventoryidFromObject;
  getsubcategyById(subCat) {
    if (subCat?.cgst) {
      this.selectedOption = 'GST';
      this.showGstFields = true;
      this.showIGstFields = false;
      this.createorders.patchValue({
        cgst: subCat?.cgst
      });
    }
    if (subCat?.sgst) {
      this.selectedOption = 'GST';
      this.showGstFields = true;
      this.showIGstFields = false;
      this.createorders.patchValue({
        sgst: subCat?.sgst
      });
    }
    if (subCat?.igst) {
      this.selectedOption = 'I-GST';
      this.showIGstFields = true;
      this.showGstFields = false;
      this.createorders.patchValue({
        igst: subCat?.igst
      });
    }
    this.itemTypidFromObj = subCat?.itemTypeId;
    this.inventoryidFromObject = subCat?.inventoryId
    var body = {
      "flag": "Y",
      "addressId": this.addressId,
      "itemTypeId": this.itemTypidFromObj
    }
    this.invService.getSuppliercatgeryByItemTypeId(body).subscribe(res => {
      this.suplier = res?.['data'];
      if(this.stock){
        this.createorders.patchValue(
          {  Supplier:this.stock?.supplierName}
        )
        const sto=this.suplier.filter(item=>{
          return item?.supplierName==this.stock?.supplierName
        })
        this.getsupplierIdbyClick(sto[0])
      }
    })
  }

  supliercatItemTyp;
  selectedSuppiler(Supplierselected) {``
    // this.deal = Supplierselected.deal
    // this.buyQuantity = Supplierselected.buyQuantity,
    //   this.sellQuantity = Supplierselected.freeQuantity,
    //   this.discount = Supplierselected.discount
    this.Supplierselected = Supplierselected
  }

  supplierIdfromObj;
  getsupplierIdbyClick(sup) {
    this.deal = sup?.deal;
    this.buyQuantity = sup?.buyQuantity,
      this.sellQuantity = sup?.freeQuantity,
      this.discount = sup?.discount
    this.createorders.patchValue({
      unitPrice: sup?.unitPrice,
      totalPrice: sup?.unitPrice,
      freeItems: sup?.freeQuantity
    })
    this.supplierIdfromObj = sup?.supplierId;
    this.invService.SuppliercatgeryByItemType(this.addressId, this.itemTypidFromObj).subscribe(res => {
      this.supliercatItemTyp = res?.['data'];
      // this.createorders.patchValue({
      //   unitPrice: this.supliercatItemTyp['unitPrice'],
      //   totalPrice: this.supliercatItemTyp['unitPrice']
      // })
    })
  }

  cGst;sGst;gst;
  multiply() {
    var totalPrice = (this.createorders?.value?.Quantity) * (this.createorders?.value?.unitPrice);

    if (this.discount) {

      var discountAmount = 0
      discountAmount = this.discount / 100
      discountAmount = totalPrice * discountAmount
      totalPrice = totalPrice - discountAmount
      // console.log(totalPrice, "This is the total Price");

      this.createorders.patchValue({
        totalPrice: parseFloat(totalPrice.toFixed(2))

      })
    } else if (this.deal) {
      if (this.createorders?.value.Quantity > this.buyQuantity) {
        var a = this.createorders?.value.Quantity / this.buyQuantity
        this.freeProducts = this.sellQuantity * a;
        this.freeProducts = Math.round(this.freeProducts)
        this.freeItemsAvailable = this.freeProducts;
        this.createorders.patchValue({
          freeItems: this.freeProducts

        })
      }
      this.createorders.patchValue({
        totalPrice: parseFloat(totalPrice.toFixed(2))

      })
    }


    if ((this.createorders?.value?.cgst || this.createorders.value.sgst) && this.showGstFields) {

      this.cGst = 0
      this.sGst = 0
      if (this.createorders?.value?.cgst) {
        this.cGst = this.createorders.value.cgst / 100
        this.cGst = totalPrice * this.cGst
      }
      if (this.createorders?.value?.sgst) {
        this.sGst = this.createorders.value.sgst / 100
        this.sGst = totalPrice * this.sGst
      }
      totalPrice = totalPrice + this.cGst + this.sGst;
      this.createorders.patchValue({
        totalPrice: parseFloat(totalPrice.toFixed(2))

      })
    } else if (this.showIGstFields) {
      this.gst = 0;
      if (this.createorders?.value?.igst) {
        this.gst = this.createorders.value.igst / 100
        this.gst = totalPrice * this.gst;
        this.cGst = this.gst;
      }
      totalPrice = totalPrice + this.gst;
      this.createorders.patchValue({
        totalPrice: parseFloat(totalPrice.toFixed(2))
      })
    }

    else {
      this.createorders.patchValue({
        totalPrice: parseFloat(totalPrice.toFixed(2))

      })


    }
  }

  getcategory() {
    var body = {
      "flag": "Y",
      "addressId": this.addressId
    }
    this.inventoryService.category(body).subscribe(res => {
      this.categorylist = res?.['data'];
      if(this.stock){
        this.createorders.patchValue(
          {  Category:this.stock?.catageryType}
        )
        this.getcategyById(this.stock?.categoryId)
      }
    })

  }

  saveOrder() {
    var orderDate = formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    var expDate = formatDate(this.createorders.value.ExpectedDate, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    if (this.showIGstFields) {
      if (this.createorders.value.igst == "") {
        this.message = "Please add GST to the Product"
        this.showMessage = true;
        return;
      }
    }
    if (this.showGstFields) {
      if (this.createorders.value.cgst == "" && this.createorders.value.sgst == "") {
        this.message = "Please add GST to the Product"
        this.showMessage = true;
        return;
      }
    }
    var req = {
      "active": "Y",
      "dateOrdered": orderDate,
      "expectedDeliveryDate": expDate,
      "addressId": this.addressId,
      "catagoryId": this.catIdfromObj,
      "itemTypeId": this.itemTypidFromObj,
      "itemName": this.createorders.value.itemName,
      "supplierId": this.supplierIdfromObj,
      "totalPrice": this.createorders.value.totalPrice,
      "totalStockOrdered": parseInt(this.createorders.value.Quantity),
      "unitPrice": this.createorders.value.unitPrice,
      "buyQuantity": this.buyQuantity,
      "freeQuantity": this.sellQuantity,
      "quantityToBeRecived": this.freeItemsAvailable,
      "deal": this.deal,
      "discount": this.discount,
      "cgst": this.createorders.value.cgst,
      "sgst": this.createorders.value.sgst,
      "igst": this.createorders.value.igst,
      "inventoryId" : this.inventoryidFromObject
    }

    if (this.createorders.valid) {
      this.inventoryService.savesupplierOrders(req).subscribe(res => {
        this.toastrService.success('Order placed Successfully.');
        this.createorders.reset();
        this.activeModal.close();
      }, error => {
        this.toastrService.error(error?.[error]?.['message']);
      })
    }
  }

  gstCheckBox(a) {
    if ($('.mat-radio-checked')) {
      if (a.value == 'GST') {
        this.selectedOption = a.value;
        this.showGstFields = true;
        this.showIGstFields = false;


      } if (a.value == 'I-GST') {
        this.selectedOption = a.value;
        this.showGstFields = false;
        this.showIGstFields = true;
      }
    }
  }

  receivededCategory: any;
  receivededSubcategory: any;
  receivedSupplier: any;
  fetchOrderDetailsById() {
    this.inventoryService.getordersByorderId(parseInt(this.globalStorage.getItem('ivfAddressId')), this.order?.supplierordersId).subscribe(res => {
      const expecteddate = formatDate(res['data']?.['expectedDeliveryDate'], "yyyy-MM-dd", 'en-us');
      this.receivededCategory = res?.data?.catagoryName;
      this.receivededSubcategory = res?.data?.subCatagory;
      this.receivedSupplier = res?.data?.supplierName;
      // this.getcategory()
      if (res?.data?.cgst) {
        this.selectedOption = 'GST';
        this.showGstFields = true;
        this.createorders.patchValue({
          cgst: res?.data?.cgst
        });
      }
      if (res?.data?.sgst) {
        this.selectedOption = 'GST';
        this.showGstFields = true;
        this.createorders.patchValue({
          sgst: res?.data?.sgst
        });
      }
      if (res?.data?.igst) {
        this.selectedOption = 'I-GST';
        this.showIGstFields = true;
        this.createorders.patchValue({
          igst: res?.data?.igst
        });
      }
      this.createorders.patchValue({
        Category: res?.data?.catagoryName,
        SubCategory: res?.data?.subCatagory,
        Supplier: res?.data?.supplierName,
        unitPrice: res?.data?.unitPrice,
        Quantity: res?.data?.totalStockOrdered,
        totalPrice: res?.data?.totalPrice,
        ExpectedDate: expecteddate,
        // cgst: res?.data?.cgst,
        // sgst: res?.data?.sgst,
        // igst: res?.data?.igst,
        itemName: res?.data?.itemName,
      });
    })
  }

  exptedDeliveryDate;
  updateOrder() {
    let expDat = this.createorders.value.ExpectedDate;
    if (expDat) {
      this.exptedDeliveryDate = formatDate(expDat, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');
    }
    let updateReq = {
      "addressId": this.addressId,
      "expectedDeliveryDate": this.exptedDeliveryDate,
      "reson": "test",
      "supplierordersId": this.order?.supplierordersId,
      "totalPrice": this.createorders.value.totalPrice,
      "totalStockOrdered": parseInt(this.createorders.value.Quantity),
      "unitPrice": this.createorders.value.unitPrice,
      "inventoryId" : this.inventoryidFromObject
    }
    this.inventoryService.updateSupplierorders(updateReq).subscribe(res => {
      this.toastrService.success('Order Updated Successfully.');
      this.createorders.reset();
      this.activeModal.close();
    },
      error => {
        this.toastrService.error(error?.[error]?.['message']);
      }
    )
  }

}

