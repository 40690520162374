

<ol class="breadcrumb">
  <li ><a href="javascript:void(0)"  routerLink="/dashboard"><i class="fa fa-home" aria-hidden="true">

  </i></a></li>
  <li> <a href="javascript:void(0)"  >Profile</a></li>
</ol>



<div class="row ">
  <div class="col-lg-12 col-md-12 col-xs-12">
  
  <div class="container-profile">
    <nav class="m-profilenav show-mobile">  
      <a href="javascript:void(0)"  id="professional"  class="icon-appointments  active-tabs ">
  <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
  
  
  <i class="p-2 fa fa-user-tie w-100 themecol2 icon-head animated" aria-hidden="true"></i>
 
  </div>
      </a>											
                                                  
   
        <a id="personal" (click)="personal()" class="personalDts">
        <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
  
  
  <i class="p-2 fa fa-user-cog themecol2 w-100 icon-head animated" aria-hidden="true"></i>
  
 
  </div>
  
   </a>
        
        
        
   <a id="certification" (click)="certification()" class="cer-ass">		
   <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
  <i class="p-2 fa fa-user-graduate themecol2 w-100 icon-head animated" aria-hidden="true"></i>
 
  </div>
   </a>
   
   <a id="contactdetails" (click)="contactdetails()" class="addressDts">
    <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
    <i class="p-2 fa fa-map-marked-alt themecol2 w-100 icon-head animated" aria-hidden="true"></i>
 
    </div>
    </a>

    <a id="changePassword" (click)="changePassword()" class="changePassword">
      <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
      <i class="p-2 fa fa-unlock-alt themecol2 w-100 icon-head animated" aria-hidden="true"></i>
   
      </div>
      </a>

      
    <a id="payments" (click)="invoice()" class="paymt">
      <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
      <i class="p-2 fa fa-receipt themecol2 w-100 icon-head animated" aria-hidden="true"></i>
   
      </div>
      </a>

  <!-- <a id="certification" >		
   <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
  <i class="p-2 fa fa-user-graduate themecol2 icon-head animated" aria-hidden="true"></i>
  <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
  <p class="font-weight-medium mb-0 mt-1 themecol2 ">Certification
  </p>
  <p class="text-muted mb-1 text-small">Street, State, Country</p>
  </div>
  </div>
   </a> -->
   </nav>


  <div class="leftbox  no-mobile">
    <nav> 
      <a href="javascript:void(0)"  id="professional"  class="icon-appointments  active-tabs " (click)="primary()">
  <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
  
  
  <i class="p-2 fa fa-user-tie themecol2 icon-head animated" aria-hidden="true"></i>
  
  <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-1 ">
   
  <p class="font-weight-medium mb-0 mt-1 themecol2">Primary
  </p>
  <p class="text-muted mb-1 text-small"> Name, Gender, Number</p>
   
  
  </div>
  </div>
      </a>											
                                                  
   
        <a id="personal" class="personalDts" (click)="personal()">
        <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
  
  
  <i class="p-2 fa fa-user-cog themecol2 icon-head animated" aria-hidden="true"></i>
  
  <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 " >
   
  <p class="font-weight-medium mb-0 mt-1 themecol2 ">Personal 
  </p>
  <p class="text-muted mb-1 text-small">DOB, Degree</p>
   
  
  </div>
  </div>
  
   </a>
        
        
        
   <a id="certification"  class="cer-ass" (click)="certification()">		
   <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
  <i class="p-2 fa fa-user-graduate themecol2 icon-head animated" aria-hidden="true"></i>
  <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
  <p class="font-weight-medium mb-0 mt-1 themecol2 ">Certification
  </p>
  <p class="text-muted mb-1 text-small">Name, Degree, Year</p>
  </div>
  </div>
   </a>
   <a id="certification"  class="cer-ass asso" (click)="associations()">		
    <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
   <i class="p-2 fa fa-user-graduate themecol2 icon-head animated" aria-hidden="true"></i>
   <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
   <p class="font-weight-medium mb-0 mt-1 themecol2 ">Associations
   </p>
   <p class="text-muted mb-1 text-small">Name</p>
   </div>
   </div>
    </a>
   
   <a id="contactdetails" class="addressDts" (click)="contactdetails()">
    <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
    <i class="p-2 fa fa-map-marked-alt themecol2 icon-head animated" aria-hidden="true"></i>
    <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
    
    <p class="font-weight-medium mb-0 mt-1 themecol2 ">Address 
    </p>
    <p class="text-muted mb-1 text-small">Primary, Secondary</p>
    </div>
    </div>
    </a>

    <a id="changePassword" class="changePassword">
      <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
      <i class="p-2 fa fa-unlock-alt themecol2 icon-head animated" aria-hidden="true"></i>
      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
      
      <p class="font-weight-medium mb-0 mt-1 themecol2 ">Change Password 
      </p>
      <p class="text-muted mb-1 text-small">Old, New , Confirm</p>
      </div>
      </div>
      </a>

      
    <a id="payments" class="paymt" (click)="invoice()">
      <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
      <i class="p-2 fa fa-receipt themecol2 icon-head animated" aria-hidden="true"></i>
      <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
      
      <p class="font-weight-medium mb-0 mt-1 themecol2 ">Payments
      </p>
      <p class="text-muted mb-1 text-small">Invoices, Bills</p>
      </div>
      </div>
      </a>

  <!-- <a id="certification" >		
   <div class="d-flex flex-row mb-0 p-2 border-bottom  blt-2  ">
  <i class="p-2 fa fa-user-graduate themecol2 icon-head animated" aria-hidden="true"></i>
  <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
  <p class="font-weight-medium mb-0 mt-1 themecol2 ">Certification
  </p>
  <p class="text-muted mb-1 text-small">Street, State, Country</p>
  </div>
  </div>
   </a> -->
   </nav>
  </div>
  <div class="rightbox">
    <div class="professional noshow" >
      <h3 class="mt-2 mb-3 border-bottom pt-2 pb-2 head"> Professional Details
        <span class="float-right text-danger" style="margin-right: 50%;">{{feeDtsMessage}}</span> 
      </h3>
      <app-doctor-professional-details (childComponentValue)="recievedFromChild($event)"></app-doctor-professional-details> 
    </div>
    
    <div class="personal noshow">
    <app-doctor-personal-details (childComponentValue)="recievedFromChild($event)"  *ngIf="isPersonal"></app-doctor-personal-details>
    </div>
    
    <div class="certification noshow">
      <app-doctor-certification-details *ngIf="isCertification" [type]="certOrAssociation"></app-doctor-certification-details>
     </div>
     
    
    <div class="contactdetails noshow">
      <app-doctor-address-details *ngIf="isContactdetails"></app-doctor-address-details>
    </div>

    

    <div class="changePassword noshow">
      <h3 class="mt-2 mb-3 border-bottom pt-2 pb-2  head"> Change Password </h3>
       <app-doctor-change-password></app-doctor-change-password>
     </div>

    <div class="payments noshow">
      <h3 class="mt-2 mb-3 border-bottom pt-2 pb-2  head"> Payments </h3>

      <app-invoice-details *ngIf="isInvoice"></app-invoice-details>
     </div>
  
  </div>
  </div>

</div>

</div>
  
