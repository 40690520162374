import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InternalMessageService } from '../internal-message.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-view-message',
  templateUrl: './view-message.component.html',
  styleUrls: ['./view-message.component.css']
})
export class ViewMessageComponent implements OnInit {
  @Input() meessageInfo;
  @Input() addressId

  constructor(public activeModal:NgbActiveModal,private messageService:InternalMessageService,private toastr: ToastrService,) { }

  ngOnInit(): void {
    console.log('message',this.meessageInfo)
  }
  close(){
    this.messageService.updateMessage(this.meessageInfo?.userMessageId).subscribe(res=>{
      this.activeModal.close()
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
}
