import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { InventoryService } from '../../inventory/inventory.service';

@Component({
  selector: 'app-supplier-order-invoice',
  templateUrl: './supplier-order-invoice.component.html',
  styleUrls: ['./supplier-order-invoice.component.css']
})
export class SupplierOrderInvoiceComponent implements OnInit {

  @Input() supplierOrder: any;
  @Input() addressId;

  constructor(
    private activeModal: NgbActiveModal,
    private toaster: ToastrService,
    private modalService: NgbModal,
    private inventoryService: InventoryService
  ) { }

  ngOnInit(): void {
    console.log('incoming data:', this.supplierOrder);

    this.getInvoice();
  }

  closeModal() {
    this.activeModal.close(true);
  }

  orderItems;
  getInvoice() {
    this.inventoryService.supplierOrderInvoice(this.supplierOrder?.supplierordersId).subscribe(res => {
      console.log('invoice response:', res?.['data']);
      this.orderItems = res?.['data'];
    },
      error => {
        this.toaster.error(error?.['error']?.['message']);
      }
    )
  }

}
