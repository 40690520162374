import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AssignSupplierComponent } from '../assign-supplier/assign-supplier.component';
import { InventoryService } from '../inventory.service';
import { SaveSubcategoryComponent } from '../../modals/save-subcategory/save-subcategory.component';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';

@Component({
  selector: 'app-add-sub-category',
  templateUrl: './add-sub-category.component.html',
  styleUrls: ['./add-sub-category.component.css']
})
export class AddSubCategoryComponent implements OnInit {
  @Input() fromWhere: boolean = false;
  @Input() service: any;
  @Input() category: any;
  @Input() addressId: any;
  subcategory: any[] = [];
  editSubCat: any;
  p5 = 1;
  submitted: boolean = false;
  SubcategoryForm: FormGroup;
  edit: boolean = false;
  constructor(private globalStorage: GlobalStorageService, private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private toastrService: ToastrService, private modalService: NgbModal, private inventoryService: InventoryService,
    private clinicServices:ClinicService) { }

  ngOnInit(): void {
    this.getsubcategory(this.category);
  }

  addSubCategory() {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-left hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-40';
    }
    const modalRef = this.modalService.open(SaveSubcategoryComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    })
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.category = this.category;
    modalRef.result.then(
      (result) => {
        this.getsubcategory(this.category);
      },
      (reason) => {
      }
    );
  }

  editSubcategory(data) {
    let wClass = 'left';
    if (this.globalStorage.getItem("loggedInAs") == 'IVF') {
      wClass = 'right hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'HOSPITAL') {
      wClass = 'modal-left hw-40';
    }
    if (this.globalStorage.getItem("loggedInAs") == 'PHARMACY') {
      wClass = 'modal-left hw-40';
    }
    const modalRef = this.modalService.open(SaveSubcategoryComponent, {
      centered: false,
      backdrop: 'static',
      keyboard: false,
      windowClass: wClass
    })
    modalRef.componentInstance.isSubCatEdit = true;
    modalRef.componentInstance.itemTypeId = data?.itemTypeId;
    modalRef.componentInstance.subCategoryObj = data;
    modalRef.componentInstance.addressId = this.addressId;
    modalRef.componentInstance.category = this.category;
    modalRef.result.then(
      (result) => {
        this.getsubcategory(this.category);
      },
      (reason) => {
      }
    );
  }

  getsubcategory(data) {
    var body = {
      "catagoryId": data?.catagoryId,
      "flag": "ALL",
      "addressId": this.addressId
    }
    // this.fromWhere?body['ivfAddressId']=this.addressId : body['hospitalAddresId'] = this.addressId
    this.inventoryService.subcategory(body).subscribe(res => {
      this.subcategory = res?.['data'];
    })
  }

  searchBySubCategoryName(name) {
      let body = {
        "addressId": this.addressId,
        "name": name,
        "catagoryId" : this.category?.catagoryId
      }
      this.inventoryService.searchItemtype(body).subscribe(res => {
        this.subcategory = res?.['data'];
        this.p5 = 1;
      },
        error => {
          this.toastrService.error(error?.['error']?.['message'])
        }
      )
  }

  getAssignSupplier(data) {
    var assignSupplier = this.modalService.open(
      AssignSupplierComponent, {
      centered: false,
      windowClass: this.fromWhere ? 'right hw-40' : 'modal-left hw-40',
    }
    );
    assignSupplier.componentInstance.addressId = this.addressId
    assignSupplier.componentInstance.service = this.service
    assignSupplier.componentInstance.fromWhere = this.fromWhere
    assignSupplier.componentInstance.category = this.category
    assignSupplier.componentInstance.subcategory = data
  }

  activateSubCategory(subCat) {
    this.inventoryService.activateSubCatagory(this.addressId, subCat?.itemTypeId).subscribe(res => {
      this.toastrService.success('SubCategory Details Activated.')
      this.getsubcategory(this.category)
    },
      error => {
        this.toastrService.error(error?.['error']?.['message'])
      }
    )
  }

  deactivateSubCatagory(sub) {
      this.inventoryService.deleteSubCatagory(this.addressId, sub?.itemTypeId).subscribe(res => {
        this.toastrService.success('SubCategory Details Deactivated.');
        this.getsubcategory(this.category);
      },
        error => {
          this.toastrService.error(error?.['error']?.['message'])
        }
      )
  }

}
