<link rel='stylesheet' href="./../../../../assets/landing/css/core.css">
<link rel='stylesheet' href="./../../../../assets/landing/css/base.css">
<link rel='stylesheet' href="./../../../../assets/landing/css/helper.css">

<!--header start-->
<header id="masthead" class="header ttm-header-style-overlay ttm-header-style-classic-overlay">
  <!-- ttm-header-wrap -->
  <div class="ttm-header-wrap">
    <!-- ttm-stickable-header-w -->
    <div id="ttm-stickable-header-w" class="ttm-stickable-header-w ttm-bgcolor-white clearfix">
      <div id="site-header-menu" class="site-header-menu">
        <div class="site-header-menu-inner ttm-stickable-header">
          <div class="container">
            <!-- site-branding -->
            <div class="site-branding">
              <a href="javascript:void(0)" (click)="goHome('https://curemetric.com/index.html')" rel="home">
                <img id="logo-img" class="img-center" src="./assets/landing/images/logo-img.png" alt="logo-img">
              </a>
            </div><!-- site-branding end -->
            <!--site-navigation -->
            <div id="site-navigation" class="site-navigation" data-sticky-height="70">
              <nav role="navigation">
                <div id="menuToggle">
                  <input type="checkbox" />
                  <span></span>
                  <span></span>
                  <span></span>
                  <ul id="menu">
                    <li><a aria-current="page" href="javascript:void(0)"
                        (click)="goHome('https://curemetric.com/index.html')">Home</a></li>
                    <li><a aria-current="page" href="javascript:void(0)"
                        (click)="goHome('https://curemetric.com/about.html')">About</a></li>
                    <li><a aria-current="page" href="javascript:void(0)"
                        (click)="goHome('https://curemetric.com/services.html')">Services</a>
                    </li>
                    <li><a aria-current="page" href="javascript:void(0)"
                        (click)="goHome('https://curemetric.com/index.html#testmo')">Testimonial</a>
                    </li>
                    <li><a aria-current="page" href="javascript:void(0)"
                        (click)="goHome('https://curemetric.com/contact-us.html')">Contact
                        Us</a></li>
                    <li class="item-submenu">
                      <ul class="sub-menu">
                        <a>
                          <input type="radio" name="menuopt" id="drop1" />
                          <label class="opener" for="drop1"><i class="fa fa-user"></i>
                            Login</label>
                          <label class="closer" for="dropclose"><i class="fa fa-user"></i>
                            Login</label>
                          <ul>
                            <li><a routerLink="/patientLogin"><i class="fa fa-user-injured"></i> User</a></li>
                            <li><a routerLink="/Login"><i class="fa fa-user-md"></i>
                                Doctor</a></li>
                            <li><a routerLink="/DiagnosticLogin"><i class="fa fa-x-ray"></i>
                                Diagnostic </a></li>
                            <li><a routerLink="/pharmaLogin"><i class="fa fa-file-prescription"></i> Pharmacy</a>
                            </li>
                            <li><a routerLink="/caretakerLogin"><i class="fa fa-user-nurse"></i>CareTaker</a></li>
                            <li><a routerLink="/nutritionistLogin"><i
                                  class="fa-brands fa-nutritionix"></i>Nutritionist</a>
                            </li>
                            <!-- <li><a routerLink="/psychiatristLogin"><i class="fa-brands fa-nutritionix"></i>Psychiatrist</a></li> -->
                            <!-- <li><a routerLink="/physiotherapistLogin"><i class="fa-brands fa-nutritionix"></i>Physiotherapist</a></li> -->
                            <li><a routerLink="/clinicLogin"><i class="fa fa-hospital"></i>
                                Hospital </a></li>
                            <!-- <li><a routerLink="#nogo"><i class="fa fa-user-tie"></i>Employer </a></li> -->
                          </ul>
                        </a>
                      </ul>
                    </li>
                  </ul>
                </div>
              </nav>
              <!--/.Navbar-->

              <nav id="menu" class="menu">
                <ul class="dropdown">
                  <li><a aria-current="page" href="javascript:void(0)"
                      (click)="goHome('https://curemetric.com/index.html')">Home</a></li>
                  <li><a aria-current="page" href="javascript:void(0)"
                      (click)="goHome('https://curemetric.com/about.html')">About</a></li>
                  <li><a aria-current="page" href="javascript:void(0)"
                      (click)="goHome('https://curemetric.com/services.html')">Services</a></li>
                  <li><a aria-current="page" href="javascript:void(0)"
                      (click)="goHome('https://curemetric.com/index.html#testmo')">Testimonial</a>
                  </li>
                  <li><a aria-current="page" href="javascript:void(0)"
                      (click)="goHome('https://curemetric.com/contact-us.html')">Contact Us</a>
                  </li>
                  <li><a href="javascript:void(0)" style="padding-left: 30px; padding-right: 50px;"><i
                        class="fa fa-user"></i>
                      Login</a>
                    <ul class="sub-menu">
                      <li><a routerLink="/patientLogin"><i class="fa fa-user-injured"></i>
                          User</a></li>
                      <li><a routerLink="/Login"><i class="fa fa-user-md"></i> Doctor</a></li>
                      <li><a routerLink="/DiagnosticLogin"><i class="fa fa-x-ray"></i> Diagnostic
                        </a></li>
                      <li><a routerLink="/pharmaLogin"><i class="fa fa-file-prescription"></i>
                          Pharmacy</a></li>

                      <li><a routerLink="/caretakerLogin"><i class="fa fa-user-nurse"></i>CareTaker</a></li>
                      <li><a routerLink="/nutritionistLogin"><i class="fa-brands fa-nutritionix"></i>Nutritionist</a>
                      </li>
                      <!-- <li><a routerLink="/psychiatristLogin"><i class="fa-brands fa-nutritionix"></i>Psychiatrist</a></li> -->
                      <!-- <li><a routerLink="/physiotherapistLogin"><i class="fa-brands fa-nutritionix"></i>Physiotherapist</a></li> -->
                      <li><a routerLink="/clinicLogin"><i class="fa fa-hospital"></i> Hospital
                        </a></li>
                      <!-- <li><a routerLink="#nogo"><i class="fa fa-user-tie"></i>Employer </a></li> -->
                    </ul>
                  </li>
                </ul>
              </nav>
            </div><!-- site-navigation end-->
          </div>
        </div>
      </div>
    </div><!-- ttm-stickable-header-w end-->
  </div><!--ttm-header-wrap end -->
</header><!--header end-->