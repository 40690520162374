import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MustMatch, NoMatch } from 'src/app/_helpers/must_match.validators';
import { PatientService } from '../../services/patient.service';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { AuthService } from '../../services/auth.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-newpatient',
  templateUrl: './newpatient.component.html',
  styleUrls: ['./newpatient.component.css']
})
export class NewpatientComponent implements OnInit {
  @Input() selectedOption: string
  registerForm : FormGroup
  primaryAddress : FormGroup
  message
  isPhoneNumberExistsMessage
  successCredentials
  addressDetailsOn :boolean = false
  submitted: boolean;
  minDate = new Date(1925, 0, 1);
  maxDate = new Date();
  selectedValue3
  selectedValue4
  genderList: any[] = [{ id: 1, value: 'Male' }, { id: 2, value: 'Female' }, { id: 3, value: 'Others' }]
  bloodGroupList: any[] = [{ id: 1, value: 'A+' }, { id: 2, value: 'A-' }, { id: 3, value: 'B+' }, { id: 4, value: 'B-' }, { id: 5, value: 'AB+' }, { id: 6, value: 'AB-' }, { id: 7, value: 'O+' }, { id: 8, value: 'O-' }]
  search3
  search4
  invalidCredentials = false;
  isExist;
  isPhoneNumberExists=false
  primarysubmitted: boolean = false;
  constructor(private globalStorage: GlobalStorageService, private formBuilder : FormBuilder,private router : Router,private toastrService : ToastrService,private patientService : PatientService,private clinicServices: ClinicService , private service: AuthService,) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      middleName: [''],
      emergencyContactName: ['', Validators.required],
     emergenyContactNumber:['',[Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      // userName: ['',],
      email: ['', [Validators.required, Validators.email]],
      contactNum: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      // password: ['', [Validators.required,
      // Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)*\\S{8,15})')]],
      // confirmPassword: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      bloodgroup: ['', [Validators.required]]

    },
      {
        validators: [ NoMatch('firstName', 'lastName')]
      }
    );
    this.primaryAddress = this.formBuilder.group({
      addressId: [],
      isPrimary: ['Y'],
      city: ['', Validators.required],
      country: ['', Validators.required],
      longitude: [''],
      state: ['', Validators.required],
      latitude: [''],
      street1: ['', Validators.required],
      street2: [''],
      zip: ['', Validators.required],
      cc: ['', Validators.required],
      postalLocation: ['', Validators.required]
    });
  }
  get f() { return this.registerForm.controls; }
  get h() { return this.primaryAddress.controls; }
  closeModal() {
    this.registerForm.reset()
    this.primaryAddress.reset()
    this.registerForm.patchValue({
      gender:'',
      bloodgroup:''
    })
    this.submitted = false
    this.addressDetailsOn = false
  }
  getFormValue(value){
  }


  selectedValue; countryCodeSelected; postalLocations; zip;
  zipEntered() {
    this.selectedValue = null;
    this.countryCodeSelected = null;
  }
  latitude; longitude
  drpdownSelectionForAddress(e) {
    this.latitude = e?.latitude
    this.longitude = e?.longitude
  }
  percChanged(zip) {
      this.zip = zip;
      this.countryCodeSelected = null;
      this.primaryAddress.patchValue({
        country: '',
        city: '',
        state: '',
        postalLocation: ''
      });
    if(this.zip?.length < 6){
      this.postalLocations = [];
    }
  }
  countryCodeEntered(cc) {
    this.patientService.getPincodeDetails(this.zip, cc).subscribe(res => {
      if (res['data'].length == 0) {
        this.primaryAddress.patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.toastrService.error("Incorrect pincode")
      }
      this.postalLocations = res['data'];
      // this.latitude = res['result'][0]['latitude'];
      // this.longitude = res['result'][0]['longitude'];
      this.primaryAddress.patchValue({
        country: res['data'][0]['country'],
        city: res['data'][0]['province'],
        state: res['data'][0]['state'],

      })
    })
  }
  numberOnly1(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  numberOnly(event, phn): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    if (phn.value.length < 1) {
      if (charCode < 54 || charCode > 57) {
        return false;
      }
      return true;
    }
    else {
      if (charCode < 48 || charCode > 57) {
        return false;
      }
      return true;

    }
  }
  alphaOnly(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/[a-zåäö ]/i);
    return pattern.test(value);
  };
  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z]+$/);
    return pattern.test(value);
  }

  omit_special_char_and_numbers(e) {
    var k;
    document.all ? k = e.keyCode : k = e.which;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);
  }


  // checkUserName() {
  //   this.invalidCredentials = false
  //   if (this.f.userName.errors == null) {
  //     this.isExist = false
  //     this.service.userNameExist(this.registerForm.controls['userName'].value).subscribe(res => {
  //       if (res.data == true) {
  //         this.invalidCredentials = true
  //         this.message = res?.message
  //         // this.toastr.error("A user with this email address has already been registered in the system and the associated already exists.");
  //         this.isExist = true
  //         this.submitted = false;
  //         if (this.invalidCredentials == true) {
  //           this.addressDetailsOn = false
  //         }
  //         return
  //       }
  //     }, (error) => {
  //       // this.toastr.error((error['error']['message']))

  //     });
  //   }
  // }
  next(data) {
    // this.checkUserName()
    this.checkEmail()
    this.checkPhoneNumber($('.iti__selected-dial-code').text() + this.registerForm.value['contactNum'])
    if (this.invalidCredentials == false) {
      this.addressDetailsOn = true
    }
  }
  checkEmail() {
    this.invalidCredentials = false
    if (this.f.email.errors == null) {
      this.isExist = false
      this.service.userExist(this.registerForm.controls['email'].value).subscribe(res => {
        if (res.data == true) {
          this.invalidCredentials = true
          var ema = document.getElementById('directLink').innerHTML;
          $('#directLink').addClass("email");
          this.message = res?.message
          // this.toastr.error("A user with this email address has already been registered in the system and the associated already exists.");
          this.isExist = true
          this.submitted = false;
          if (this.invalidCredentials == true) {
            this.addressDetailsOn = false
          }
          return
        }

      }, (error) => {
        // this.toastr.error((error['error']['message']))

      });
    }
  }
  checkForm() {
    if (this.registerForm.value.email  && this.registerForm.value.contactNum && this.registerForm.value.contactNum.length == 10
      && this.registerForm.value.firstName && this.registerForm.value.lastName && (this.registerForm.value.password === this.registerForm.value.confirmPassword)) {
      return false;
    }
    else {
      return true;
    }
  }
  drpdownSelection(item) {
  }

  phNum;
  getNumber(event){
    this.phNum=event;
  }
  emergencyNum
  getEmrNumber(event){
    this.emergencyNum=event
  }


  checkPhNumber(num) {
    this.invalidCredentials = false;
    this.service.checkPhoneNumber(this.phNum.replace(/\+/gi, '%2B')).subscribe(res => {
      if (res?.['data']?.['isExist'] == true) {
        this.isPhoneNumberExists = true;
        this.isPhoneNumberExistsMessage = res?.['message'];
        return
      }
      else {
        this.isPhoneNumberExists = false;
      }
    }, error => {
      this.message = error?.['error']?.['message'];
    });
  }

  checkPhoneNumber(num) {
    this.invalidCredentials = false;
    if (num.length == 10) {
      this.service.checkPhoneNumber(this.phNum.replace(/\+/gi, '%2B')).subscribe(res => {
        if (res?.data?.isExist == true) {
          this.invalidCredentials = true
          this.message = res?.message
          if (this.invalidCredentials == true) {
            this.addressDetailsOn = false
          }
          return
        }
      }, (error) => {
        this.checkForm();
        this.message = error['error']['message'];
      });
    }
  }

  patientDetails
  onSubmit(form) {
    const date = formatDate(this.registerForm.value.dob, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');

    var body = {
      "cellNumber": this.phNum,
      "emailId": this.registerForm.value.email,
      "firstName": this.registerForm.value.firstName.charAt(0).toUpperCase()+this.registerForm.value.firstName.slice(1),
      "hospitaladdressId": this.globalStorage.getItem('hospitalAddressId'),
      "hospitalUserId": this.globalStorage.getItem('forUserId'),
      "lastName": this.registerForm.value.lastName.charAt(0).toUpperCase()+this.registerForm.value.lastName.slice(1),
      "patientName": this.registerForm.value.firstName + " " + this.registerForm.value.lastName,
      "userName": this.registerForm.value.contactNum
    }
    var reqBody = {
      "active": "Y",
      "address": {
        "active": "Y",
        "city": this.primaryAddress.value.city,
        "contactName": this.registerForm.value.firstName.charAt(0).toUpperCase()+this.registerForm.value.firstName.slice(1) + " " + this.registerForm.value.lastName.charAt(0).toUpperCase()+this.registerForm.value.lastName.slice(1),
        "country": this.primaryAddress.value.country,
        "latitude": this.latitude,
        "longitude": this.longitude,
        "postalLocation": this.primaryAddress.value.postalLocation,
        "state": this.primaryAddress.value.state,
        "street1": this.primaryAddress.value.street1,
        "street2": this.primaryAddress.value.street2,
        "zip": this.primaryAddress.value.zip,
      },
      "cellNumber": this.phNum,
      "emergenyContactNumber":this.emergencyNum,
      "emailId": this.registerForm.value.email,
      "firstName": this.registerForm.value.firstName,
      "password": this.registerForm.value.password,
      "hospitaladdressId": this.globalStorage.getItem('hospitalAddressId'),
      "emergencyContact": this.registerForm.value.emergencyContactName,
      "lastName": this.registerForm.value.lastName,
      "patientName": this.registerForm.value.firstName.charAt(0).toUpperCase()+this.registerForm.value.firstName.slice(1) + " " + this.registerForm.value.lastName.charAt(0).toUpperCase()+this.registerForm.value.lastName.slice(1),
      "personaldetail": {
        "bloodgroup": this.registerForm.value.bloodgroup,
        "dob": date.concat('Z'),
        "ethinicity": " ",
        "eyeColor": " ",
        "gender": this.registerForm.value.gender,
        "height": " ",
        "highestDegree": " ",
        "martialstatus": " ",
        "nationality": " ",
        "preferredlanguages": " "
      },
      "userName": this.registerForm.value.contactNum
    }
    this.clinicServices.saveUser(reqBody).subscribe(res => {
      this.patientDetails = res['data']
      this.toastrService.success('User added successfully.')
      // $('#add-user').modal('hide')
      this.primaryAddress.reset()
      this.registerForm.reset()
      this.selectedValue3=null
      this.selectedValue4=null
      this.addressDetailsOn = false
      this.submitted = false
      if (this.selectedOption == 'IPD' || this.selectedOption == 'EMERGENCY') {
        this.navigateDataToUserScreen(res['data']['userId'])
      }
      if (this.selectedOption == 'OPD') {
        this.navigateDataToUserScreen(res['data']['userId'])
      }
    }, error => {
      this.toastrService.error(error?.['error']?.['message'])
    })
  }

  showHidePwd() {
    if ($('#show_hide_password input').attr("type") == "text") {
      $('#show_hide_password input').attr('type', 'password');
      $('#show_hide_password i').addClass("fa-eye-slash");
      $('#show_hide_password i').removeClass("fa-eye");
    } else if ($('#show_hide_password input').attr("type") == "password") {
      $('#show_hide_password input').attr('type', 'text');
      $('#show_hide_password i').removeClass("fa-eye-slash");
      $('#show_hide_password i').addClass("fa-eye");
    }
  }
  showHidePwd1() {
    if ($('#show_hide_password1  input').attr("type") == "text") {
      $('#show_hide_password1 input').attr('type', 'password');
      $('#show_hide_password1 i').addClass("fa-eye-slash");
      $('#show_hide_password1 i').removeClass("fa-eye");
    } else if ($('#show_hide_password1 input').attr("type") == "password") {
      $('#show_hide_password1 input').attr('type', 'text');
      $('#show_hide_password1 i').removeClass("fa-eye-slash");
      $('#show_hide_password1 i').addClass("fa-eye");
    }
  }
  isItValid() {
  }
  navigateDataToUserScreen(data) {
    // if(this.selectedOption=='IPD'){

  var body = {
    "hospitaladdressId": this.globalStorage.getItem('hospitalAddressId'),
    "keywords": data,
    "patientSearch": "NAME"
  }
  this.clinicServices.findUserById(data).subscribe(res => {
    var PatDetails = JSON.parse(res['data'])
    console.log(PatDetails)
    if (PatDetails == null) {
      // $('#userFoundConfirmation').modal('show')
    }
    else {
      var ptdetails = {
        'firstName':PatDetails['firstName'],
        'patientName': PatDetails['firstName'] + ' ' + PatDetails['lastName'],
        'patientReferenceId': PatDetails['userId'],
        'patientType': this.selectedOption,
        // "emailId":res['data']?.['emailId'],
        // "userName":res['data']?.['userName'],
        // 'cellNumber':res['data']?.['cellNumber']
      }
      const navigationExtras: NavigationExtras = {
        state: {
          patientName: ptdetails
        }
      };
      if(this.selectedOption == 'IPD' || this.selectedOption == 'EMERGENCY'){
        var currentDate = new Date().toJSON();
        var body = {
          "active": 'Y',
          "checkInTime": currentDate,
          "hospitalAddresId": this.globalStorage.getItem('hospitalAddressId'),
          "patientId": res['data']?.['userId'],
          "patientName": res['data']?.['firstName'] + ' ' + res['data']?.['lastName']
        }
        this.clinicServices.assinInPatient(body).subscribe(res => {
          ptdetails['inPatientId'] = res['data']
          const navigationExtras: NavigationExtras = {
            state: {
              patientName: ptdetails
            }
          };
          if(this.router.url=='/users-list'){
            this.router.navigate(['/clinic-users'], navigationExtras)
          }
          else{
            this.router.navigate(['/users-list'], navigationExtras)
          }
        },error=>{
          this.toastrService.error(error?.['error']?.['message'])
        })

      }
      else{
        this.router.navigate(['/users-list'], navigationExtras)
      }
    }
  }, error => {
    this.toastrService.error(error?.['error']?.['message'])
  })


    //  }
    //   else if(this.selectedOption=='OPD'){
    //     const navigationExtras: NavigationExtras = {
    //       state: {
    //         patientName:ptdetails
    //       }
    //     };
    //     this.router.navigate(['/clinic-users'],navigationExtras)
    //   }
  }
}
