import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-cancel-patient-appointment',
  templateUrl: './cancel-patient-appointment.component.html',
  styleUrls: ['./cancel-patient-appointment.component.css']
})
export class CancelPatientAppointmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
