import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InventoryService } from '../inventory.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-order-delivery',
  templateUrl: './order-delivery.component.html',
  styleUrls: ['./order-delivery.component.css']
})
export class OrderDeliveryComponent implements OnInit {
  @Input() deliverOrder: any;
  @Input() addressId: any;
  @Input() fromWhere: any;
  orderDelivered: FormGroup;
  options: string[] = ['Full Delivery', 'Partial Delivery'];
  selectedOption: string = 'Full Delivery';
  partial: boolean = false;
  minDate;
  submitted: boolean = false;

  constructor(public activeModal: NgbActiveModal, private fromBuilder: FormBuilder, private inventoryService: InventoryService,
    private toastrService: ToastrService
  ) {
    this.minDate = new Date();
  }

  ngOnInit(): void {
    this.orderDelivered = this.fromBuilder.group({
      'orderId': [''],
      'invoiceId': ['', Validators.required],
      'batchNumber': ['', Validators.required],
      'saleUnitPrice': [''],
      'manufacturerDate': ['', Validators.required],
      'expDate': ['', Validators.required],
      'warrentyDate': [''],
      'itemsDelivered': ['', [Validators.required, Validators.minLength(1)]],
      'expectedDelivery': ['', [Validators.required, Validators.minLength(1)]]
    });

    this.orderDelivered.patchValue({
      orderId: this.deliverOrder?.supplierordersId
    });
  }

  get deliveryFormControls(){
    return this.orderDelivered.controls;
  }

  checkBoxCheck(a, e) {
    if ($('.mat-radio-checked')) {
      a.value.toUpperCase()
      if (a.value == 'Full Delivery') {
        this.selectedOption = "FULL_DELIVERED"
        this.partial = false;
        //  this.orderDelivered.controls['itemsDelivered'].clearValidators()
        //   this.orderDelivered.controls['expectedDelivery'].clearValidators()

      }
      if (a.value == 'Partial Delivery') {
        this.selectedOption = 'PARTLY_DELIVERED'
        this.partial = true;
        // this.orderDelivered.controls['itemsDelivered'].setValidators([Validators.required, Validators.minLength(1)])
        // this.orderDelivered.controls['expectedDelivery'].setValidators([Validators.required, Validators.minLength(1)])
      }
    }
  }

  validateDeliveryOrderForm(){
    return this.orderDelivered.value.invoiceId && this.orderDelivered.value.batchNumber && this.orderDelivered.value.expDate;
  }
  deliver() {
    var body = {
      "supplierordersId": this.deliverOrder.supplierordersId,
      "orderStatus": this.partial ? 'PARTLY_DELIVERED' : 'FULL_DELIVERED',
      "supplierInvoiceId": this.orderDelivered.value.invoiceId,
      "nextOrderDate": this.orderDelivered.value.expectedDelivery,
      "expireDate": this.orderDelivered.value.expDate,
      "batchNumber": this.orderDelivered.value.batchNumber,
      "manufacturerDate" : this.orderDelivered.value.manufacturerDate,
      "saleUnitPrice" : this.orderDelivered.value.saleUnitPrice,
      "warranty" : this.orderDelivered.value.warrentyDate
    };

    if (this.partial) {
      // If partial delivery, include itemsDelivered and check against totalStockOrdered
      body["numOfItemDelivered"] = this.orderDelivered.value.itemsDelivered;

      if (this.orderDelivered.value.itemsDelivered >= this.deliverOrder?.totalStockOrdered) {
        // Show an error message or handle the condition appropriately
        this.toastrService.error('Items delivered count should not be more than or equal to total stock ordered.');
        return; // Do not proceed with the API call
      }
    } else {
      // If fully delivered, set numOfItemDelivered to totalStockOrdered
      body["numOfItemDelivered"] = this.deliverOrder?.totalStockOrdered;
    }

    // Add the address information based on the condition
    this.fromWhere ? (body['addressId'] = this.addressId) : (body['addressId'] = this.addressId);

    // Make the API call
    this.inventoryService.deliverOrder(body).subscribe(
      (res) => {
        this.toastrService.success('Order delivered.');
        this.activeModal.close();
        this.deliverOrder.reset();
      },
      (error) => {
        this.toastrService.error(error?.['error']?.['message']);
      }
    );
  }


  closeModal() {
    this.orderDelivered.reset();
    this.activeModal.close(true);
  }
}
