<!DOCTYPE html>
<html xmlns="http://www.w3.org/1999/xhtml">
<head>
     <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.11.3/jquery.min.js"></script>
    <script src="http://ajax.googleapis.com/ajax/libs/angularjs/1.4.8/angular.min.js"></script>   
    <!-- <link href="../css/newstyles.css" rel="stylesheet" /> -->
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">

<!-- jQuery library -->
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

<!-- Latest compiled JavaScript -->
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
    <script>
        var myapp = angular.module('verification', []);
        var slashpostion = location.href.indexOf("/", 7);
        var serverpath = location.href.substring(0, slashpostion);
        myapp.constant('BaseURL', serverpath + '/RMEWeb/services/')
        myapp.controller('VerificationCtr', function ($scope, $http, BaseURL, $location) {
            $scope.EmailVerification = function () {
                if (typeof $scope.verificationCode == "undefined") {
                    alert("Please Enter Verification Code");
                    return false;
                }
                var url = BaseURL + 'companyRegistrationWebService/companyEmailVerification/' + $scope.verificationCode;
               
                var sucess = function (res) {
                   if (res.data.status == "SUCCESS") {
                       var verification = angular.element('#verification');
                       verification.addClass('hide');
                       var message = angular.element('#message');
                       message.addClass('show green')
                       $scope.message = res.data.data;
                       //var loginlink = angular.element('#loginlink');
                       //loginlink.addClass('show ')
                   }
                   else {
                       var message = angular.element('#message');
                       message.addClass('show red')
                       $scope.message = "Please Enter Valid Code";
                   }

                }
                var error = function (data) {
                    $location.path("/Error");
                }
                $http({
                    method: "get",
                    url: url,
                    data: {},
                    headers:{
                        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8;',
                        'VCUBE.WS.USER.TOKEN':'undefined'
                    },
                    responseType: 'json'
                }).then(sucess, error);
            }
            $scope.keypress = function () {
                var message = angular.element('#message');
                message.addClass('hide');
                message.removeClass('show');
            }
        });
    </script>
    <style>
        #logo h1, #logo-mobile h1 {
    font-size: 30px;
    font-weight: 200;
    letter-spacing: -1px;
    text-decoration: inherit;
    text-transform: uppercase;
    color: #fff;
}

        #logo {
            margin-top:0;
            background: rgba(48, 65, 96, 0.8);
            padding:20px;
        }
    </style>
    <title></title>

</head>
<body data-ng-app="verification">
<div data-ng-controller="VerificationCtr">
    <div style="border-style:solid;color:black;border-width:1px;width:100%;">
      <div id="logo">
            <h1>
                <img _ngcontent-nyh-c1="" alt="logo" height="38" src="assets/images/logo-white.png">
            </h1>
        </div>

        <div style="padding-left: 20px;">
            <h2 class="page-header">Welcome to CureMetric.</h2>
            <div *ngIf="!emailVerification" id="verification" style="margin-bottom:50px;">  
         Enter Doctor Email :&nbsp; <input type="text"  [(ngModel)]="email"  data-ng-focus="keypress()"/> <br/><br/>
         Enter Verification Code  :<input type="text"  [(ngModel)]="verificationCode"  data-ng-focus="keypress()"/>
            <input type="button"  style="margin-left: 15px;" class="btn btn-sm btn-success" value="Verify" (click)="EmailVerification();" />
            </div>
               <div *ngIf="emailVerification"  style="margin-bottom:50px;">
               <h2 style="color: rgb(9, 129, 9);">Thank you for verifying your email addres.</h2> 
                 <div id="loginlink" style="margin-bottom:50px;">
                    Your new CureMetric account has been activated. All your account details  will be sent to the registed email. Please login with your username (email) and password to get started.
                 </div>
                </div>
        </div>
    
        
        </div>
      
    
  <!--<a href="/Admin/CompanyVerivication">Verify</a>-->
</div>
</body>
</html>

