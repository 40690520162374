import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Crypto } from '../../_helpers/CryptoJ'
import { LoginService } from 'src/app/AppServices/login.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
import { PatientScheduleControlService } from 'src/app/shared/services/patientScedulecontrol.service';
import { PatientService } from 'src/app/shared/services/patient.service';
import * as moment from 'moment';
import { ApicallsService } from 'src/app/apicalls.service';
import { Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { MustMatch } from 'src/app/_helpers/must_match.validators';
import { LoaderService } from 'src/app/shared/services/loader.service';
declare var $: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  registerForm: FormGroup;mobileLogin:FormGroup;
  loading = false;
  submitted = false;
  cond_vall = false
  rememberme: boolean = false;
  email
  invalidCredentials: boolean = false
  message
  password
  verifyNowButton = false; isProfileData; timeLeft: number = 120;
  subscription: Subscription;
  subscribeTimer;
  dataFromWebsite;
  loginWithPin=false;loginWithPassword
  constructor(private globalStorage: GlobalStorageService, 
    private apiservice: ApicallsService,
    private formBuilder: FormBuilder, private scripts: ScriptsService, private patientController: PatientScheduleControlService,
    private router: Router, private cookieService: CookieService, private crpto: Crypto, private update: PatientService,
    private login: LoginService, private notification: NotificationService,
    private ToastrService: ToastrService,
    private service: AuthService,private loaderService:LoaderService) {
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras?.state as {
      obj: any
    }
    this.dataFromWebsite = state
    if (state == undefined) {
      this.dataFromWebsite = JSON.parse(this.globalStorage.getItem('webData'))
    }

    if (this.cookieService.get('rememberme') == 'Yes' && this.cookieService.get('module') == 'User') {
      this.rememberme = true;
      this.email = this.crpto.Decrypt(this.cookieService.get('email'), 'email');
      this.password = this.crpto.Decrypt(this.cookieService.get('password'), 'password');
    }
  }

  ngOnInit() {



    // if (navigator.userAgent.match(/Android/i)) {
    //   window.location.href = 'https://play.google.com/store/apps/details?id=com.cure.metric.curemetric'
    // }
    // else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    //   window.location.href = 'https://apps.apple.com/in/app/curemetric/id1659587794'
    // }




    $(function () {
      $(window).off('beforeunload');
      $("#preloader").fadeOut();
      $("#status").fadeOut(9000);
    });
    if (document.readyState === 'complete') {
    }
    this.registerForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });
    this.mobileLogin=this.formBuilder.group({
      contactNum:[, [Validators.required]],
      otp:['',[Validators.required]],
      pin:['',[Validators.required]],
      cpin:['',[Validators.required]],
      lpin:['',[Validators.required]]
    },
    {
      validators: [MustMatch('pin', 'cpin')]
    })
    this.loginWithPassword=true
    var input = document.querySelector("#telephone");
    jQuery(document).ready(function ($) {
      (<any>window).intlTelInputGlobals.loadUtils("./assets/build/js/utils.js");

      if (jQuery('#telephone').length) {
        var input = document.querySelector("#telephone");
        (<any>window).intlTelInput(input, {
          preferredCountries: ['in'],
          separateDialCode: true, placeholderNumberType: "MOBILE",
          autoPlaceholder: "polite", autoHideDialCode: true,
          hiddenInput: "full_number", nationalMode: "false",

          customPlaceholder: null,
          formatOnDisplay: true,

        });
      }

    });
    if(input){
      input.addEventListener("countrychange", function () {
        (<any>input).intlTelInput();
        (<any>input).val('')
      });
    }
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  get m() { return this.mobileLogin.controls; }
  get pwd() {
    return this.registerForm.get('password');
  }

  showValidationMessage: boolean = false;
  preventCopy(event: ClipboardEvent) {
    event.preventDefault();
    this.showValidationMessage = true;
    setTimeout(() => {
      this.showValidationMessage = false;
    }, 5000);
  }
  checkcapslockon(e) {
    // var charKeyCode = e.keyCode ? e.keyCode : e.which;
    // var shiftKey = e.shiftKey ? e.shiftKey : ((charKeyCode == 16) ? true : false);
    // if (((charKeyCode >= 65 && charKeyCode <= 90) && !shiftKey) || ((charKeyCode >= 97 && charKeyCode <= 122) && shiftKey)) {
    // alert('Caps Loc On');
    // }
    // else {
    // alert('Caps Loc Off');
    // }
  }
  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  onSubmit() {
    this.loaderService.isLoading.next(true)
if (this.cookieService.get('userdata')) {
  this.cookieService.delete('userdata')
}
    this.submitted = true;
    this.registerForm.value.email.toLowerCase()
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    if (this.rememberme) {
      this.cookieService.set('email', this.crpto.encryption(this.email, "email"));
      this.cookieService.set('password', this.crpto.encryption(this.password, 'password',));
      this.cookieService.set('rememberme', 'Yes')
      this.cookieService.set('module', 'User')
    } else {
      this.cookieService.set('email', "");
      this.cookieService.set('password', "");
      this.cookieService.set('rememberme', "")
    }
    var request = {
      'password': this.registerForm.value.password,
      'userName': this.registerForm.value.email
    }
    //   var encoded = btoa(JSON.stringify(request))
    this.service.signin((request)).subscribe(res => {

      // var decode=atob(res.body.data)
      // var actualData=atob(decode)
      if (res.body.status == "SUCCESS") {
        // this.ToastrService.success('Logged in successfully')
        if (res.body.data.verified === 'N') {
          this.verifyNowButton = true;
        }
        else {
          sessionStorage.setItem('patientLoginDetails', JSON.stringify(res.body.data))
          this.globalStorage.setItem('patientCredentials', JSON.stringify(request))
          this.globalStorage.setItem('userData', JSON.stringify(res.body.data));
          this.globalStorage.setItem('forUserId', JSON.stringify(res.body.data.userId))
          this.globalStorage.setItem('userId', res.body.data.userId)
          this.globalStorage.setItem('loggedInAs', 'PATIENT')
          this.globalStorage.setItem('ppswd', this.registerForm.value.password)
          this.globalStorage.setItem('userSession', JSON.stringify(res.body.userSession))
          this.globalStorage.setItem('entityId', null)

          this.cookieService.set('usersession',JSON.stringify(res?.body?.userSession))
          this.apiservice.isUserSessionExits = true
          this.apiservice.isforUserIdExits = true
          this.apiservice.ismoduleNameExits = true
          // this.scripts.loadCssStyles()
          var datas = this.globalStorage.getItem('userData')
          var obj = JSON.parse(datas)
          var today = moment(obj['lastLogin']).format('YYYY-MM-DD');
          var request = {
            "forUserId": obj['userId'],
            "userId": obj['userId']
          }
          this.globalStorage.setItem('firstName',obj?.firstName)
          this.globalStorage.setItem('lastName',obj?.lastName)
          this.globalStorage.setItem('email',obj?.emailId)
          this.globalStorage.setItem('userName',obj?.userName)
          this.globalStorage.setItem('isPersonalDetailcompleted',obj?.isPersonalDetailcompleted)
          this.globalStorage.setItem('isAddrsscompleted',obj?.['isAddrsscompleted'])
          this.globalStorage.setItem('isProfileCompleted',obj?.isProfileCompleted)
          this.globalStorage.setItem('height',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='height'))?.[0]?.['vitalValue'])
          this.globalStorage.setItem('bloodGroup',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='bloodgroup'))?.[0]?.['vitalValue'])
          this.globalStorage.setItem('pulse',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='pulse'))?.[0]?.['vitalValue'])
          this.globalStorage.setItem('glucolseLevelPreLunch',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='fasting'))?.[0]?.['vitalValue'])
          this.globalStorage.setItem('glucolseLevelPostLunch',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='random'))?.[0]?.['vitalValue'])
          this.globalStorage.setItem('bp',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='bloodPressure'))?.[0]?.['vitalValue'])
          if(this.globalStorage.getItem('isPersonalDetailcompleted')=='N' || this.globalStorage.getItem('isPersonalDetailcompleted')!='Y'){
            this.router.navigate(['/pat-personal-details'])
          }
          if (this.globalStorage.getItem('isPersonalDetailcompleted') == 'Y') {
                if (this.globalStorage.getItem('isAddrsscompleted') == 'N' || this.globalStorage.getItem('isAddrsscompleted') != 'Y' ) {
                  this.router.navigate(['/pat-address-details'])
                }
              }
              if (this.globalStorage.getItem('isPersonalDetailcompleted') == 'Y' && this.globalStorage.getItem('isAddrsscompleted') == 'Y') {
                if (this.dataFromWebsite != undefined) {
                  const navigationExtras: NavigationExtras = {
                    state: {
                      obj: this.dataFromWebsite
                    }
                  };
                  if (this.dataFromWebsite?.type == "DOCTOR") {
                    this.router.navigate(['/search-Doctor'], navigationExtras)
                  }
                  if (this.dataFromWebsite?.type == "NUTRITIONIST") {
                    this.router.navigate(['/search-nutriotionist'], navigationExtras)
                  }
                  if (this.dataFromWebsite?.specialization == "CARETAKER") {
                    this.router.navigate(['/search-caretaker'], navigationExtras)
                  }
                }
                else {
                  this.loaderService.isLoading.next(false)
                  this.router.navigate(['p-dashboard'])
                  this.notification.requestPermission(parseInt(this.globalStorage.getItem('userId')));
                  // this.notification.receiveMessage();
                  // this.notification.currentMessage;
                }
              }

          // this.patientController.isPatientProfileCompletedOrNot(obj['userId'], obj['userId']).subscribe(res => {
          //   if (res['body']) {
          //     this.isProfileData = res["body"]['data']
          //   } else {
          //     this.isProfileData = res['data']
          //   }
          //   if (this.isProfileData?.['isPersonalDetailcompleted'] == 'N') {
          //     this.router.navigate(['/pat-personal-details'])
          //   }
          //   if (this.isProfileData?.['isPersonalDetailcompleted'] == 'Y') {
          //     if (this.isProfileData?.['isAddrssComplted'] == 'N') {
          //       this.router.navigate(['/pat-address-details'])
          //     }
          //   }
          //   if (this.isProfileData?.['isPersonalDetailcompleted'] == 'Y' && this.isProfileData?.['isAddrssComplted'] == 'Y') {
          //     if (this.dataFromWebsite != undefined) {
          //       const navigationExtras: NavigationExtras = {
          //         state: {
          //           obj: this.dataFromWebsite
          //         }
          //       };
          //       if (this.dataFromWebsite?.type == "DOCTOR") {
          //         this.router.navigate(['/search-Doctor'], navigationExtras)
          //       }
          //       if (this.dataFromWebsite?.type == "NUTRITIONIST") {
          //         this.router.navigate(['/search-nutriotionist'], navigationExtras)
          //       }
          //       if (this.dataFromWebsite?.specialization == "CARETAKER") {
          //         this.router.navigate(['/search-caretaker'], navigationExtras)
          //       }
          //     }
          //     else {
          //       this.router.navigate(['p-dashboard'])
          //     }

          //   }
          // })
        }
        // this.update.getPersonalDetails(request,obj['userId']).subscribe(res=>{
        //    if(res['body']['data']!=null){
        //     var request={
        //       "forUserId": obj['userId'],
        //       "relationType": "DOCTOR",
        //       "userId":obj['userId']
        //     }
        //      this.update.getAddress(request ,obj['userId']).subscribe(res=>{
        //          if(res['body']['data'].length==0){
        //           this.router.navigate(['/pat-address-details'])
        //          }
        //      },error=>{
        //      // this.ToastrService.error("Error in getting address")
        //      });
        //   }
        //   if(res['body']['data']['nationality']==null){
        //     this.router.navigate(['/pat-personal-details'])
        //     this.ToastrService.info('Please fill the personal data')
        //   }
        // },error=>{
        //   // this.router.navigate(['/pat-personal-details'])
        //   // this.ToastrService.info('Please fill the personal and address details')
        // })
        //notifications for week
        // setTimeout(() => { this.patientController.getNotificationForWeek(today,obj['userId'],obj['userId']).subscribe(res=>{
        //   var array=res['data'].reverse().slice(-5)
        //   for (var i=0;i<array.length;i++){
        //     this.ToastrService.info(res['data'][i]['reason'],res['data'][i]['objectType'],{
        //     positionClass: 'toast-bottom-right'
        //  })
        //   }

        // })
        // this.globalStorage.setItem('theme','cureMetric.light.blue.min.css')
        //  }, 2000)

      }
    }
      , error => {
        this.invalidCredentials = true;
        if(error?.error?.status == "FAILURE"){
          this.message = "Invalid UserID or Password.";
        }
        // if(error['status']== 0){
        //   this.ToastrService.error("Certification Authority Invalid")
        // }

        if (error['error']['message'] == 'Patient login Failed:Your Email Id Is  Unverified.' || error['error']['message'] == 'Your Email Id Is  Unverified.') {
          this.invalidCredentials = true
          // this.message = error?.['error']?.['message']
          if(error?.error?.status == "FAILURE"){
            this.message = "Your Email Id is not verified.";
          }
          this.verifyNowButton = true
        }
        else {
          // this.ToastrService.error(error['error']['message'])
          this.invalidCredentials = true
          if(error?.error?.status == "FAILURE"){
            this.message = "Invalid UserID or Password.";
          }
          this.router.navigate(['/patientLogin'])
        }
        //this.oastrService.error("You have not verified your email address. Please click the Verify Now button provided in the verification email.In case you have not received a verification email, please contact us at support@qontrack.com")

      }
    )


  }

  change() {
    this.cond_vall = !this.cond_vall

  }

  verifyNow() {
    this.router.navigate(['/verify'])
  }
  forgotPassword() {

    this.router.navigate(['/resetPassword'])
    //   if(this.registerForm.value.email=="" || this.registerForm.value.email==undefined){
    //     this.ToastrService.warning('Enter the Email to send verification Link')
    //   }else{
    //   var request={
    //     "emailId":this.registerForm.value.email,
    //     "resetPasswordLink":window.location.origin+"/resetPassword"
    //   }
    //   this.service.forgotPassword(JSON.stringify(request)).subscribe(res=>{
    //     this.ToastrService.success(res['message'])
    //   },error=>{
    //   this.ToastrService.error(error['error']['message'])
    //   })
    // }
  }
  remembermeCheck() {
    if (this.rememberme) {
      this.rememberme = false;
    } else {
      this.rememberme = true;
    }
  }

  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });
    var input = document.querySelector("#telephone");
    jQuery(document).ready(function ($) {
      (<any>window).intlTelInputGlobals.loadUtils("./assets/build/js/utils.js");

      if (jQuery('#telephone').length) {
        var input = document.querySelector("#telephone");
        (<any>window).intlTelInput(input, {
          preferredCountries: ['in'],
          separateDialCode: true, placeholderNumberType: "MOBILE",
          autoPlaceholder: "polite", autoHideDialCode: true,
          hiddenInput: "full_number", nationalMode: "false",

          customPlaceholder: null,
          formatOnDisplay: true,

        });
      }

    });
    input.addEventListener("countrychange", function () {
      (<any>input).intlTelInput();
      (<any>input).val('')
    });
  }
  onCountryChange(data){
  }
  phNum
  getNumber(data,num){
    this.phNum=data
    // this.checkPhoneNumber(num)
  }
  isPhoneNumberExists=false;isPhoneNumberExistsMessage;isGetOtp=false;otpEnable=false;pinExists=false
  checkPhoneNumber(num) {
    //$("#telephone").intlTelInput("getSelectedCountryData").dialCode;
    this.invalidCredentials = false
    if (num.length == 10) {
      this.service.checkPhoneNumber((this.phNum).replace(/\+/gi, '%2B')).subscribe(res => {
        if (res?.data?.isExist == true && res?.data?.isPin=='Y') {
          this.invalidCredentials = true
          this.isPhoneNumberExists=true
         // this.message = res?.message
          this.isPhoneNumberExistsMessage=res?.message
          return
        }
        else{
           this.isTimeOn2=false;
          if(res?.data?.isPin=='Y'){
            this.isPhoneNumberExists=true
          }
          else{
            this.otpValid=true
          }
          this.isPhoneNumberExists=false
        }
      }, (error) => {
        this.message = error['error']['message']
      });
    }
  }
  numberOnly(event, phn): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    if (phn?.value?.length < 1) {
      if (charCode < 54 || charCode > 57) {
        return false;
      }
      return true;
    }
    else {
      if (charCode < 48 || charCode > 57) {
        return false;
      }
      return true;

    }
  }

  otpCheck(){

  }
  isResend=false
  observableTimer2() {
    this.isTimeOn2 = true
    this.isResend=false
    this.otpEnable=true
    var source = timer(1000, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      var m: any = Math.floor(this.subscribeTimer / 60);
      var s: any = this.subscribeTimer % 60;
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      if (this.subscribeTimer >= 0) {
        document.getElementById('timer2').innerHTML = m + ':' + s;
      }
      if (this.subscribeTimer == 0) {
        this.isTimeOn2 = false
        // this.isSend=false
         this.isResend=true
        // this.isGetOtp = false
        // this.isResendOtp = false
        this.message = 'OTP expired. Click Resend OTP for new OTP.'
        this.invalidCredentials = true
        // this.toaster.error('OTP expired. Click Resend OTP for new OTP.')
      }
    });
  }
  isTimeOn2=true
  getOTP(){
    var number = $('.iti__selected-dial-code').text() + this.mobileLogin.value?.contactNum
    this.service.mobileOtp(this.phNum.replace(/\+/gi, '%2B')).subscribe(res=>{
      this.observableTimer2()
      this.isTimeOn2=true
      this.invalidCredentials=false
    },error=>{
      this.ToastrService.error(error?.['error']?.['message'])
    })
  }
  otpValid=false
  verifyOtp(otp){
    if(otp?.length==6){
      this.otpValid=true

    }
  }
  register(){
    var phn=$('.iti__selected-dial-code').text()+this.mobileLogin.value?.contactNum
    this.service.verifyMobile(this.mobileLogin.value?.otp,this.phNum.replace(/\+/gi, '%2B'),this.mobileLogin.value?.pin).subscribe(res=>{
      sessionStorage.setItem('patientLoginDetails', JSON.stringify(res.data))
      this.globalStorage.setItem('patientCredentials', JSON.stringify(request))
      this.globalStorage.setItem('userData', JSON.stringify(res.data));
      this.globalStorage.setItem('forUserId', JSON.stringify(res.data.userId))
      this.globalStorage.setItem('userId', JSON.stringify(res.data.userId))
      this.globalStorage.setItem('loggedInAs', 'PATIENT')
      this.globalStorage.setItem('ppswd', this.registerForm.value.password)
      this.globalStorage.setItem('userSession', JSON.stringify(res.userSession))

      this.cookieService.set('usersession',JSON.stringify(res?.body?.userSession))
      this.apiservice.isUserSessionExits = true
      this.apiservice.isforUserIdExits = true
      this.apiservice.ismoduleNameExits = true
      // this.scripts.loadCssStyles()
      var datas = this.globalStorage.getItem('userData')
      var obj = JSON.parse(datas)
      var today = moment(obj['lastLogin']).format('YYYY-MM-DD');
      var request = {
        "forUserId": obj['userId'],
        "userId": obj['userId']
      }
      this.globalStorage.setItem('firstName',obj?.firstName)
      this.globalStorage.setItem('lastName',obj?.lastName)
      this.globalStorage.setItem('isPersonalDetailcompleted',obj?.isPersonalDetailcompleted)
      this.globalStorage.setItem('isAddrsscompleted',obj?.['isAddrsscompleted'])
      this.globalStorage.setItem('isProfileCompleted',obj?.isProfileCompleted)
      this.globalStorage.setItem('height',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='height'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('bloodGroup',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='bloodgroup'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('pulse',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='pulse'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('glucolseLevelPreLunch',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='fasting'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('glucolseLevelPostLunch',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='random'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('bp',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='bloodPressure'))?.[0]?.['vitalValue'])
     this.router.navigate(['p-dashboard'])

    },error=>{
      this.ToastrService.error(error?.['error']?.['message'])
    })
  }
  loginUser(){
    var phn=$('.iti__selected-dial-code').text()+this.mobileLogin.value?.contactNum
    this.service.loginWithPin(this.phNum.replace(/\+/gi, '%2B'),this.mobileLogin.value?.lpin).subscribe(res=>{
      sessionStorage.setItem('patientLoginDetails', JSON.stringify(res.body.data))
      this.globalStorage.setItem('patientCredentials', JSON.stringify(request))
      this.globalStorage.setItem('userData', JSON.stringify(res.body.data));
      this.globalStorage.setItem('forUserId', JSON.stringify(res?.body?.data?.userId))
      this.globalStorage.setItem('userId', JSON.stringify(res?.body?.data?.userId))
      this.globalStorage.setItem('loggedInAs', 'PATIENT')
      this.globalStorage.setItem('ppswd', this.registerForm.value.password)
      this.globalStorage.setItem('userSession', JSON.stringify(res?.body?.userSession))
      this.cookieService.set('usersession',JSON.stringify(res?.body?.userSession))
      this.apiservice.isUserSessionExits = true
      this.apiservice.isforUserIdExits = true
      this.apiservice.ismoduleNameExits = true
      // this.scripts.loadCssStyles()
      var datas = this.globalStorage.getItem('userData')
      var obj = JSON.parse(datas)
      var today = moment(obj['lastLogin']).format('YYYY-MM-DD');
      var request = {
        "forUserId": obj['userId'],
        "userId": obj['userId']
      }
      this.globalStorage.setItem('firstName',obj?.firstName)
      this.globalStorage.setItem('lastName',obj?.lastName)
      this.globalStorage.setItem('isPersonalDetailcompleted',obj?.isPersonalDetailcompleted)
      this.globalStorage.setItem('isAddrsscompleted',obj?.['isAddrsscompleted'])
      this.globalStorage.setItem('isProfileCompleted',obj?.isProfileCompleted)
      this.globalStorage.setItem('height',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='height'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('bloodGroup',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='bloodgroup'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('pulse',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='pulse'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('glucolseLevelPreLunch',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='fasting'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('glucolseLevelPostLunch',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='random'))?.[0]?.['vitalValue'])
      this.globalStorage.setItem('bp',(obj?.vitalsDTOForPatients?.filter(obj=>obj?.vitalname=='bloodPressure'))?.[0]?.['vitalValue'])
     this.router.navigate(['p-dashboard'])

    },error=>{
      this.ToastrService.error(error?.['error']?.['message'])
    })
  }
  loginWithMobile(){
    this.loginWithPin=true;
    this.loginWithPassword=false
  }
  loginWithPwd(){
    this.loginWithPin=false;
    this.loginWithPassword=true
  }
}
