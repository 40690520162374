<div class="row ">
  <div class="col-lg-12  ">
    <div class="card mb-2"
      style="background-position: right bottom; background-size: auto 100%; background-image: url(./assets/images/doctor-bg.jpg)">
      <div class="box-body d-flex pb-3">
        <div class="flex-grow-1 p-15 flex-grow-1 dask-bg bg-none-md">
          <div class="row">
            <div class="col-12 col-xl-3 no-mobile"><img src="./assets/images/doc-img.jpg" /></div>
            <div class="col-12 col-xl-9 col-xs-12">
              <h2 class="pt-2">Welcome back, <strong>Dr. {{profileDetails |titlecase}}</strong>
              </h2>

              <!-- <p class="text-dark my-10 font-size-12">

              You have <span class="themec1">10</span> Appointments today. Your profile is only
              <strong class="text-warning">80%</strong>  completed. <br>
              <a href="javascript:void(0)"  class="themec2 mt-1" routerLink="/EditProfile">Complete Now</a>

            </p> -->
              <p class="pb-0 mb-0"> You have <span class="themec1" *ngIf="currentNotificationLength>0"><a
                    href="javascript:void(0)" class="themec1" data-toggle="modal"
                    data-target="#viewTodayReminders">{{currentNotificationLength}}</a></span><span class="themec1"
                  *ngIf="currentNotificationLength==0">No</span> alerts today.
                <br><span class="no-mobile"
                  *ngIf="feeDetailsPercentage!=5 && addressDetailsPercentage!=25 && personalDetailsPercentage !=25 && certificationPercentage!=25">Your
                  profile is only <span class="themec2">{{primaryDetailsPercentage}}%</span> complete.
                  <br><a class="no-mobile" href="javascript:void(0)" class="themec2" routerLink="/EditProfile">Complete
                    Now</a> </span>
                <span class="no-mobile"
                  *ngIf="addressDetailsPercentage!=25 && personalDetailsPercentage !=25 && certificationPercentage!=25">Your
                  profile is only <span class="themec2">{{primaryDetailsPercentage + feeDetailsPercentage!=5}}%</span>
                  complete.
                  <br><a class="no-mobile" href="javascript:void(0)" class="themec2" routerLink="/EditProfile">Complete
                    Now</a> </span>
                <span class="no-mobile" *ngIf="personalDetailsPercentage !=25 && certificationPercentage!=25">Your
                  profile is only <span class="themec2">{{primaryDetailsPercentage + feeDetailsPercentage +
                    addressDetailsPercentage}}%</span> complete.
                  <br><a class="no-mobile" href="javascript:void(0)" class="themec2" routerLink="/EditProfile">Complete
                    Now</a> </span>
                <span class="no-mobile" *ngIf="certificationPercentage!=25">Your profile is only <span
                    class="themec2">{{primaryDetailsPercentage + feeDetailsPercentage + addressDetailsPercentage +
                    personalDetailsPercentage}}%</span> complete.
                  <br><a class="no-mobile" href="javascript:void(0)" class="themec2" routerLink="/EditProfile">Complete
                    Now</a> </span>
              </p>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="col-lg-6 col-sm-12 mb-2  ">
    <div class="card d-min-height">
      <div class="position-absolute card-top-buttons">
        <button class="btn  icon-button" data-placement="top" data-toggle="tooltip" data-original-title="View">
          <i class="fa fa-eye themecol1" aria-hidden="true" routerLink="/Appoinments"></i>
        </button>
      </div>
      <div class="card-body ">
        <h5 class="card-title3"> <i class="fa fa-calendar-day themecol3 mr-2"></i>My Appointments</h5>
        <div *ngIf="current.length==0 && upcoming.length==0">
          <div class="no-appointments"></div>


        </div>
        <div *ngFor="let item of current.slice(0,4);let index=index">

          <div class="card m-2 d-flex flex-row  p-1 border-bottom  blt-1  ">


            <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
              <a href="javascript:void(0)" (click)="viewCurrentAppontment(item)">
                <p class="font-weight-medium mb-1 mt-1">{{item.patientName}} - {{item.ailment}}
                </p>
                <p class="text-muted mb-1 text-small">{{item.appointmentDate | date:'dd-MMM-yyyy'}} {{item.fromTime |
                  convertFrom24To12Format}} - {{item.toTime | convertFrom24To12Format}}</p>
              </a>

            </div>

            <div class=" float-right ">

              <button class="btn btn-sm mr-1 mt-2 p-1 curser-pointer"
                *ngIf="item?.chatType=='VIDEO' || item?.chatType=='Video'"
                [ngClass]="{' visible': presentTime > convertH2M(item?.toTime)}"
                (click)="videoCall(item,item.appointmentDate,item.fromTime,item.userId)" data-placement="top"
                data-toggle="tooltip">
                <i class="fa fa-play-circle icon-head patientc1" data-placement="top" data-toggle="tooltip"
                  data-original-title="Join Now"></i> </button>



              <button class="btn btn-sm mr-1 mt-2 p-1 curser-pointer"
                [ngClass]="{' visible':  presentTime > convertH2M(item?.fromTime)}"
                (click)="rescheduleAppointmentNew(item)" data-placement="top" data-toggle="tooltip"> <i
                  class="fa fa-calendar-alt themecol2 text-primary patientc1" data-placement="top" data-toggle="tooltip"
                  data-original-title="Reschedule"></i> </button>
              <button class="btn btn-sm mr-1 mt-2 p-1 curser-pointer"
                [ngClass]="{' visible': presentTime>convertH2M(item?.fromTime)}" (click)="rejectAppointment(item)"
                data-placement="top" data-toggle="tooltip" data-original-title="Cancel"> <i
                  class="fa fa-calendar-times themecol4   patientc4"></i> </button>
            </div>

          </div>
        </div>

        <div *ngFor="let item of upcoming.slice(0,4);let index=index">
          <div class="card m-2 d-flex flex-row p-1 border-bottom  blt-3">


            <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
              <a href="javascript:void(0)" (click)="viewCurrentAppontment(item)">
                <p class="font-weight-medium mb-1 mt-1">{{item.patientName}} - {{item.ailment}}
                </p>
                <p class="text-muted mb-1 text-small">{{item.appointmentDate | date:'dd-MMM-yyyy'}}
                  {{item.fromTime|convertFrom24To12Format}} - {{item.toTime | convertFrom24To12Format}}</p>
              </a>

            </div>
            <div class=" float-right ">
              <a class="btn btn-sm mr-1 mt-2 p-1 curser-pointer" data-placement="top" data-toggle="tooltip"
                data-original-title="Reschedule" (click)="rescheduleAppointmentNew(item)"> <i
                  class="fa fa-calendar-alt themecol2   icon-head patientc2"></i> </a>
              <a class="btn btn-sm mr-1 mt-2 p-1 curser-pointer" data-placement="top" data-toggle="tooltip"
                data-original-title="Cancel" (click)="rejectAppointment(item)"> <i
                  class="fa fa-calendar-times themecol4  icon-head patientc4"></i> </a>
            </div>

          </div>
        </div>


        <!-- <div *ngIf="currentApointments.length==0">
          <label>No data available</label>
        </div> -->


      </div>


    </div>



  </div>

  <div class="col-xl-6 col-12">

    <div class="row">
      <div class="col-lg-6 col-md-4 col-xs-6 mb-2">
        <a class="card detail-box1 details-box pull-up" routerLink="/PatientsList">
          <div class="card-body p-4">
            <div class="dash-contetnt">
              <div class="mb-2">
                <span class=""><i class="fa fa-user-injured mr-1 font-size-24"></i> </span>
              </div>
              <h4 class="">Total Patients</h4>
              <h2 class=" mb-0">{{reports?.totalDoctor}}</h2>

            </div>
          </div>
        </a>


      </div>

      <div class="col-lg-6 col-md-6 col-xs-6">
        <a class="card detail-box5  details-box pull-up" routerLink="/Payments">
          <div class="card-body p-4">
            <div class="dash-contetnt">
              <div class="mb-2"><span class=""><i class="fa fa-wallet mr-1 font-size-24"></i> </span>

              </div>
              <h4 class="">Total Income</h4>
              <h2 class=" mb-0" *ngIf="reports?.Total_Booked_Revenue"><span class="inr"><i aria-hidden="true"
                    class="fa fa-inr"></i> </span> {{reports?.Total_Booked_Revenue}}</h2>
              <h2 class=" mb-0" *ngIf="!reports?.Total_Booked_Revenue"><span class="inr"><i aria-hidden="true"
                    class="fa fa-inr"></i> </span> 0 </h2>
            </div>
          </div>
        </a>


      </div>

      <div class="col-lg-6 col-md-6 col-xs-6 mb-2">
        <div class="card detail-box4 details-box pull-up">
          <div class="card-body p-4">
            <div class="dash-contetnt">
              <div class="mb-3"><span class=""><i class="fa fa-handshake mr-1 font-size-24"></i> </span>

              </div>
              <h4 class="">Referrals</h4>
              <h2 class=" mb-0"><span class="inr"><i aria-hidden="true" class="fa fa-inr"></i> </span> 0</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-xs-6">
        <a class="card detail-box6 details-box pull-up" routerLink="/Appoinments">
          <div class="card-body p-4">
            <div class="dash-contetnt">
              <div class="mb-3">
                <span class=""><i class="fa fa-calendar-check mr-1 font-size-24"></i> </span>
              </div>
              <h4 class="">Appointments</h4>
              <h2 class=" mb-0">{{totalNumberOfAppointments}}</h2>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="row ">
  <div class="col-lg-6 col-sm-12 mb-2">
    <div class="card d-min-height">

      <div class="position-absolute card-top-buttons">
        <button class="btn  icon-button" data-placement="top" data-toggle="tooltip" data-original-title="View">
          <i class="fa fa-eye themecol1" aria-hidden="true" routerLink="/Appoinments"></i>
        </button>
      </div>
      <div class="card-body chart-height">
        <h5 class="card-title3"> <i class="fa fa-calendar-day themecol3 mr-2"></i>Followup Appointments</h5>
        <div *ngIf="followUpAppointments.length==0">
          <div class="no-appointments"></div>


        </div>
        <div *ngFor="let item of followUpAppointments ;let index=index">

          <div class="card m-2 d-flex flex-row  p-1 border-bottom  blt-1  ">

            <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
              <a href="javascript:void(0)" (click)="viewCurrentAppontment(item)">
                <p class="font-weight-medium mb-1 mt-1">{{item.patientName}}
                </p>
                <p class="text-muted mb-1 text-small">{{item.date | date:'dd-MMM-yyyy'}}</p>
              </a>

            </div>

            <div class=" float-right ">

              <button class="btn btn-sm mr-1 mt-2 p-1 curser-pointer" data-placement="top" data-toggle="tooltip">
                <i class="fa fa-bell icon-head patientc1" data-placement="top" data-toggle="tooltip"
                  data-original-title="Send Notification"></i> </button>
            </div>

          </div>
        </div>

      </div>

    </div>
  </div>

  <div class="col-lg-6 col-sm-12 mb-2">
    <div class="card d-min-height">
      <div class="position-absolute card-top-buttons">
        <button class="btn icon-button" data-placement="top" data-toggle="tooltip" data-original-title="View">
          <i class="fa fa-eye themecol1" aria-hidden="true"></i>
        </button>
      </div>
      <div class="card-body chart-height">
        <h5 class="card-title"><i class="fa fa-hand-holding-usd themecol2 mr-2"></i> Revenue</h5>
        <!-- <canvas baseChart class="chart-min-height" height="290" [data]="pieChartData" [labels]="pieChartLabels"
          [chartType]="pieChartType"></canvas> -->
        <div class="no-data-found"></div>
      </div>
    </div>
  </div>
</div>

<div class="modal fade modal-right" id="viewTodayReminders" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalRight">
  <div class="modal-dialog" role="document" style="min-width:35%">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notifications</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="row">
        <div class="col-lg-12 mt-3 text_right ">

          <ul class="pagination">
            <pagination-controls autoHide="true" id="p2" (pageChange)="p2=$event"></pagination-controls>
          </ul>
        </div>
      </div>
      <div class="row d-flex  ml-2">

        <div class="col-md-12 card d-flex flex-row mb-0 p-2 border-bottom float-left  blt-2 mb-2 mt-1 "
          *ngFor="let reports of currentNotifications |paginate: { id: 'p2', itemsPerPage: 6, currentPage: p2 }"
          [ngClass]="{' blt-3': reports.viewed == 'Y'}" (click)="updaterNotify(reports)">

          <a href="javascript:void(0)" class="p-2 icon-appointments  ">
            <i *ngIf="reports.viewed == 'Y'" class="fa fa-calendar-day icon-head themecol3" aria-hidden="true"></i>
            <i *ngIf="reports.viewed != 'Y'" class="fa fa-bell fa-lg pt-2  icon-col-3" aria-hidden="true"></i>
          </a>
          <div class="pl-1 pr-2 d-flex flex-column flex-grow-1 mr-2 ">
            <a href="javascript:void(0)">
              <p class="font-weight-medium mb-1 mt-1">{{ reports.objecttype }}
              </p>
              <p class="text-muted mb-1 text-small">{{reports.reason}}</p>
            </a>

          </div>

          <div class="btn-group float-right">
            <button class="btn btn-xs btn0col1" data-toggle="modal" data-backdrop="static" data-target="#viewpdf"><i
                class="fa fa-eye" aria-hidden="true"></i></button>
          </div>



        </div>
      </div>


    </div>
  </div>
</div>
<div class="modal fade" id="notificationToView" role="dialog" data-backdrop="static" data-keyboard="false"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document"
    style="min-width:300px !important;height: 50% !important;">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Notification Viewer</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="block inv-section">
          <div class="">
            <div>
              <img alt="" src="./assets/img/logo.png" style="border-radius: none !important;">
            </div>
          </div>
          <div class="text-center">
            <div class="font-weight-medium mb-0 mt-1 text-center col-md-12">{{notifyToView?.objecttype |titlecase}}
            </div>
            <div class="text-muted mb-1 text-center text-small">{{notifyToView?.reason}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>