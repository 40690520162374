export const environment = {
  production: false,
  billdeskSDK: {
    css: "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.css",
    esmJs: "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.esm.js",
    js: "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk.js"
  },
  
  firebase: {
    apiKey: "AIzaSyBoaV2yUMP13YZEnf_4wUEAj7KsTn9QE50",
    authDomain: "curemetric-6dedb.firebaseapp.com",
    // databaseURL: "https://jatintest-7bfa6.firebaseio.com",
    projectId: "curemetric-6dedb",
    storageBucket: "curemetric-6dedb.appspot.com",
    messagingSenderId: "449279881832",
    appId: "1:449279881832:web:c8285fe81013a09bacc1dd",
    measurementId: "G-5GPBLK9RS7"
  },

  doctorBaseUrl1: 'https://dev.cure-metric.com/api/doctor/',
  patientBaseUrl1: 'https://dev.cure-metric.com/api/patient/',
  pharmaUrl1: "https://dev.cure-metric.com/api/pharmacy/",
  diagnosticBaseUrl1: "https://dev.cure-metric.com/api/diagnosticcenter/",
  caretakerUrl1: "https://dev.cure-metric.com/api/caretaker/",
  nutritionistUrl1: "https://dev.cure-metric.com/api/nutritionist/",
  elesticSearchUrl1: "https://dev.cure-metric.com/api/elasticsearch/",
  utilitiesUrl1: "https://dev.cure-metric.com/api/utilities/",
  hospitalUrl1: "https://dev.cure-metric.com/api/hospital/",
  rpmAdminUrl1: "https://dev.cure-metric.com/api/rpmadmin/",
  ivfUrl1:'https://dev.cure-metric.com/api/ivf/',
  pdfUrl1 : 'https://dev.cure-metric.com/api/pdf-generator/',
  adminUrl1 : 'https://dev.cure-metric.com/api/superadmin/',
 

  doctorBaseUrl: '/api/doctor/',
  patientBaseUrl: '/api/patient/',
  pharmaUrl: "/api/pharmacy/",
  diagnosticBaseUrl: "/api/diagnosticcenter/",
  caretakerUrl: "/api/caretaker/",
  nutritionistUrl: "/api/nutritionist/",
  elesticSearchUrl: "/api/elasticsearch/",
  utilitiesUrl: "/api/utilities/",
  hospitalUrl: "/api/hospital/",
  rpmAdminUrl: "/api/rpmadmin/",
  ivfUrl:'/api/ivf/',
  pdfUrl : '/api/pdf-generator/',
  adminUrl : '/api/superadmin/',


  // PROD
  // doctorBaseUrl: 'https://app.curemetric.com/api/doctor/',
  // patientBaseUrl: 'https://app.curemetric.com/api/patient/',
  // pharmaUrl: "https://app.curemetric.com/api/pharmacy/",
  // diagnosticBaseUrl: "https://app.curemetric.com/api/diagnosticcenter/",
  // caretakerUrl: "https://app.curemetric.com/api/caretaker/",
  // nutritionistUrl: "https://app.curemetric.com/api/nutritionist/",
  // elesticSearchUrl: "https://app.curemetric.com/api/elasticsearch/",
  // utilitiesUrl: "https://app.curemetric.com/api/utilities/",
  // hospitalUrl: "https://app.curemetric.com/api/hospital/",
  // rpmAdminUrl: "https://app.curemetric.com/api/rpmadmin/",
  // ivfUrl:'https://app.curemetric.com/api/ivf/'

};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.





