import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ClinicService } from 'src/app/clinic-pages/clinic.service';
import { GlobalStorageService } from 'src/app/global-storage.service';

@Component({
  selector: 'app-appointment-notes',
  templateUrl: './appointment-notes.component.html',
  styleUrls: ['./appointment-notes.component.css']
})
export class AppointmentNotesComponent implements OnInit {
  notesForm: FormGroup;
  @Input() appointment;
  @Input() isView=false
  submitted=false;
  notesData;
  constructor( private fb: FormBuilder,public activeModal: NgbActiveModal,private clinicServices:ClinicService,
    private globalStorage: GlobalStorageService,private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.notesForm = this.fb.group({
      startTime: ['', Validators.required],
      description: ['', Validators.required,],
      endTime: ['']
    })
    this.giveNotes(this.appointment)
    if(this.isView==true){
      this.getNotes()
    }
  }
  get n() { return this.notesForm.controls; }
  currentDateAndTime; hosDts
  giveNotes(appointment) {
    this.hosDts = appointment
    var today = new Date();
    var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    this.currentDateAndTime = date + ' ' + time;
    this.notesForm.patchValue({
      startTime: this.currentDateAndTime
    })
  }
  saveNotes(notesData){
    var body={
      "appointmentId": this.appointment?.appointmentId,
      "doctorId": this.appointment?.doctorId,
      "hospitalAddressId": this.appointment?.hospitalAddressId,
      "notes": this.notesForm?.value?.description
      }
      this.clinicServices.saveAppointmentNotes(body).subscribe(res=>{
        this.toastr.success('Notes saved')
        this.activeModal.close('true')
      },error=>{
        this.toastr.error(error?.error?.message)
      })
  }
  getNotes(){
    var body=
      {
        "appointmentId": this.appointment?.appointmentId,
        "doctorId": this.appointment?.doctorId,
        "hospitalAddressId": this.appointment?.hospitalAddressId
        }
    
    this.clinicServices.getAppointmentNotes(body).subscribe(res=>{
      console.log('notes Data',res)
      this.notesData=res['body']?.['data']
      this.notesForm.patchValue({
        "description":this.notesData?.notes
      })
    },error=>{
      this.toastr.error(error?.error?.message)
    })
  }
}
