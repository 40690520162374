import { formatDate } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

import { AuthService } from 'src/app/shared/services/auth.service';
import { gpsService } from 'src/app/shared/services/gps.service';
import { PatientService } from 'src/app/shared/services/patient.service';
import { pharmaService } from 'src/app/shared/services/pharma.service';
import { MustMatch } from 'src/app/_helpers/must_match.validators'
import { ScriptsService } from 'src/app/_helpers/scriptloader.service';
declare var $: any;
@Component({
  selector: 'app-pharma-register',
  templateUrl: './pharma-register.component.html',
  styleUrls: ['./pharma-register.component.css']
})
export class PharmaRegisterComponent implements OnInit {
  minDate
  maxDate
  registerForm: FormGroup;
  isExist = false
  registerForms: FormGroup;
  loading = false;
  submitted = false;
  cond_vall = false
  invalidCredentials = false
  successCredentials = false; searchTxt;
  message; selectedValue; postalLocations;
  isEmailExists = false
  isEmailExistsMessage
  isUserNameExists = false
  isUserNameExistsMessage
  isPhoneNumberExists = false
  isPhoneNumberExistsMessage
  telOptions = { initialCountry: 'us' }
  constructor(
    private authservice: AuthService,
    private formBuilder: FormBuilder, private gps: gpsService,
    private service: pharmaService, private patientService: PatientService,
    private script: ScriptsService,
    private router: Router, private toastr: ToastrService) {
    var d = new Date();
    this.minDate = new Date(d);
    this.maxDate = new Date(d);
  }
  // (^(?=.{10,}$)(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*?\W).*$)
  // ((?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,30})
  ngOnInit() {
    this.gps.getCoordinates()
    this.registerForm = this.formBuilder.group({
      pharmacyName: ['', [Validators.required, Validators.pattern("^[\.a-zA-Z& ]*$"), Validators.minLength(3), Validators.maxLength(40)]],
      pharmacyLicence: ['', [Validators.required, Validators.minLength(6)]],
      address1: ['', Validators.required],
      address2: [''],
      city: ['', Validators.required],
      country: ['', Validators.required],
      state: ['', Validators.required],
      zip: ['', Validators.required],
      longitude: [],
      latitude: [],
      dateOfExpiry: ['', Validators.required],
      userName: ['', Validators.required],
      gstin: ['', Validators.required],
      postalLocation: ['', Validators.required],
      // pharmacyEmailId:['', [Validators.required,Validators.email]],
      firstName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.pattern("^([A-Za-z]+[,.]?[ ]?|[A-Za-z]+['-]?)+$"), Validators.minLength(3), Validators.maxLength(50)]],
      middleName: [''],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      contactNum: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      password: ['', [Validators.required,
      Validators.pattern('((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W)*\\S{8,15})')
      ]],
      confirmPassword: ['', [Validators.required]]
    },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );


    var input = document.querySelector("#telephone");
    jQuery(document).ready(function ($) {
      (<any>window).intlTelInputGlobals.loadUtils("./../../../assets/build/js/utils.js");

      if (jQuery('#telephone').length) {
        var input = document.querySelector("#telephone");
        (<any>window).intlTelInput(input, {
          preferredCountries: ['in'],
          separateDialCode: true, placeholderNumberType: "MOBILE",
          autoPlaceholder: "polite", autoHideDialCode: true,
          hiddenInput: "",

          customPlaceholder: null,
          formatOnDisplay: true,

        });
      }

    });
    input.addEventListener("countrychange", function () {
      (<any>input).intlTelInput();
      (<any>input).val('')
    });
  }

  goHome(url) {
    // routerLink="https://curemetric.com/index.html"
    $(window).off('beforeunload.windowReload');
    window.onbeforeunload = null
    window.location.href = url
    //this.router.navigate(['/home'])
  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  onSubmit(item: any) {
    const dateOfExpiry = formatDate(this.registerForm.value.dateOfExpiry, "yyyy-MM-dd'T'HH:mm:ss.SSS", 'en-us');

    var d1 = moment(dateOfExpiry);

    var d2 = moment(new Date());
    var days = moment.duration(d1.diff(d2)).asDays();
    var datediff = parseInt(days.toFixed(0));

    this.checkEmail()
    this.checkPhoneNumber(this.registerForm.value['contactNum'])
    this.checkUserName()
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    if (this.isExist) {
      return;
    }
    var request = {
      "active": 'Y',
      "emailId": this.registerForm.value.email,
      "licenseNumber": this.registerForm.value.pharmacyLicence,
      "name": this.registerForm.value.pharmacyName,
      "gstin": this.registerForm.value.gstin,
      "phoneNumber": $('.iti__selected-dial-code').text() + this.registerForm.value['contactNum'],
      "dateOfExpiry": this.registerForm.value.dateOfExpiry,
      "secondaryPhoneNumber": "",
      "primaryPharmacyAddress": {
        "address1": this.registerForm.value.address1,
        "address2": this.registerForm.value.address2,
        "city": this.registerForm.value.city,
        "country": this.registerForm.value.country,

        "isPrimary": "Y",
        "state": this.registerForm.value.state,
        "zip": this.registerForm.value.zip,
        "postalLocation": this.registerForm.value.postalLocation?.postalLocation,
        "latitude": this.latitude,
        "longitude": this.longitude,
      },
      "primaryPharmacyUser": {
        "userName": this.registerForm.value.userName,
        "emailId": this.registerForm.value.email,
        "firstName": this.registerForm.value.firstName,
        "lastName": this.registerForm.value.lastName,
        "middleName": this.registerForm.value.middleName,
        "password": this.registerForm.value.password,
        "cellNumber": $('.iti__selected-dial-code').text() + this.registerForm.value['contactNum'],
        'primaryContact': 'CELL_NUMBER',
        "gstin": this.registerForm.value.gstin
      },
      // "uiHostURL": window.location.origin,
      "cellNumber": $('.iti__selected-dial-code').text() + this.registerForm.value['contactNum']
    }

    if (this.isEmailExist == false && this.isPhoneNumberExists == false && this.isUserNameExists == false) {
      this.service.globalPostPharma('register', (request)).subscribe(res => {
        this.successCredentials = true
        this.message = res['body']['message']
        const navigationExtras: NavigationExtras = {
          state: {
            emailId: this.registerForm.value.email,
            userName: this.registerForm.value.userName,
            mobileNumber: $('.iti__selected-dial-code').text() + this.registerForm.value['contactNum'],
          }
        };
        //this.toastr.success(res['body']['message'])
        this.router.navigate(['/otp-verification-pharmacy'], navigationExtras)
      }, error => {
        this.invalidCredentials = true
        this.message = error['error']['message']
        this.router.navigate(['/pharmacy-Failure-Registration'])
        //this.toastr.error(error['error']['message'])
      })
    }
  }

  checkPhoneNumber(num) {
    var number = $('.iti__selected-dial-code').text() + num
    this.invalidCredentials = false
    this.service.checkPhoneNumber('validate/phone?phone=', number.replace(/\+/gi, '%2B')).subscribe(res => {
      if (res['data'] == true) {
        this.invalidCredentials = true
        this.isPhoneNumberExists = true
        this.isPhoneNumberExistsMessage = "This Phone number already exist"
        //this.message = "This Phone number already exist"
        return
      }
      else {
        this.isPhoneNumberExists = false
      }
    }, (error) => {
      this.message = error
    });

  }
  checkUserName() {
    this.invalidCredentials = false
    if (this.f.userName.errors == null) {
      this.isExist = false
      this.authservice.pharmaUserNameExist(this.registerForm.controls['userName'].value).subscribe(res => {
        if (res.data == true) {
          this.invalidCredentials = true
          //this.message = res?.message
          this.isUserNameExistsMessage = res?.message
          // this.toastr.error("A user with this email address has already been registered in the system and the associated already exists.");
          this.isExist = true
          this.isUserNameExists = true
          this.submitted = false;
          return
        }
        else {
          this.isUserNameExists = false
        }
      }, (error) => {
        // this.toastr.error((error['error']['message']))

      });
    }
  }
  alphaNumarics(event) {
    var value = String.fromCharCode(event.which);
    var pattern = new RegExp(/^[0-9a-zA-Z\_]+$/);
    return pattern.test(value);
  }
  isEmailExist = false
  checkEmail() {
    this.invalidCredentials = false
    if (this.f.email.errors == null) {
      this.isExist = false
      this.service.globalGetPharma('validate/email', this.registerForm.controls['email'].value).subscribe(res => {
        if (res.data == true) {
          this.invalidCredentials = true
          //this.message = res?.message
          this.isEmailExists = true
          this.isExist = true
          this.isEmailExistsMessage = res?.message
          this.isEmailExist = true
          //this.toastr.error("This email already exists");
          this.submitted = false;
          return
        }
        else {
          this.isEmailExist = false
          this.isEmailExists = false
        }
      }, (error) => {
        this.toastr.error((error['error']['message']))

      });
    }
  }
  latitude; longitude
  drpdownSelection(e) {
    this.latitude = e?.latitude
    this.longitude = e?.longitude
  }
  checkPharmacymail() {
    if (this.f.email.errors == null) {
      this.service.globalGetPharma('validate/email', this.registerForm.controls['pharmacyEmailId'].value).subscribe(res => {
        if (res.data == true) {
          this.toastr.error("This email already exists");
          this.submitted = false;
          return
        }
      }, (error) => {
        this.toastr.error((error['error']['message']))

      });
    }
  }
  numberOnly(event, phn): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (phn.length < 1) {
      if (charCode < 54 || charCode > 57) {
        return false;
      }
      return true;
    }
    else {
      if (charCode < 48 || charCode > 57) {
        return false;
      }
      return true;
    }
  }

  numberOnly1(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  ngAfterViewInit() {
    $("#show_hide_password a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password input').attr("type") == "text") {
        $('#show_hide_password input').attr('type', 'password');
        $('#show_hide_password i').addClass("fa-eye-slash");
        $('#show_hide_password i').removeClass("fa-eye");
      } else if ($('#show_hide_password input').attr("type") == "password") {
        $('#show_hide_password input').attr('type', 'text');
        $('#show_hide_password i').removeClass("fa-eye-slash");
        $('#show_hide_password i').addClass("fa-eye");
      }
    });

    $("#show_hide_password1 a").on('click', function (event) {
      event.preventDefault();
      if ($('#show_hide_password1 input').attr("type") == "text") {
        $('#show_hide_password1 input').attr('type', 'password');
        $('#show_hide_password1 i').addClass("fa-eye-slash");
        $('#show_hide_password1 i').removeClass("fa-eye");
      } else if ($('#show_hide_password1 input').attr("type") == "password") {
        $('#show_hide_password1 input').attr('type', 'text');
        $('#show_hide_password1 i').removeClass("fa-eye-slash");
        $('#show_hide_password1 i').addClass("fa-eye");
      }
    });
  }
  getZip(Zip) {
    this.patientService.getAddressAll(Zip).subscribe(res => {
      if (res['result'].length == 0) {
        this.registerForm.patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.toastr.error("Incorrect pincode")
      }
      this.postalLocations = res['result']
      this.registerForm.patchValue({
        country: res['result'][0]['country'],
        city: res['result'][0]['province'],
        state: res['result'][0]['state'],

      })
    })
  }
  zip1; countryCodeSelected
  perChanged(zip) {
    if (zip.length == 6) {
      this.zip1 = zip;
      this.countryCodeSelected = null;
      this.registerForm.patchValue({
        country: '',
        city: '',
        state: ''
      });
    }
  }
  countryCodeEntered(cc) {
    this.selectedValue = null;
    this.patientService.getPincodeDetails(this.zip1, cc).subscribe(res => {
      if (res['data'].length == 0) {
        this.registerForm.patchValue({
          country: '',
          city: '',
          state: ''
        })
        this.toastr.error("Incorrect pincode")
      }
      this.postalLocations = res['data'];
      this.latitude = res['data'][0]['latitude'];
      this.longitude = res['data'][0]['longitude'];
      this.registerForm.patchValue({
        country: res['data'][0]['country'],
        city: res['data'][0]['province'],
        state: res['data'][0]['state'],
        latitude: res['data'][0]['latitude'],
        longitude: res['data'][0]['longitude']

      })
    })
  }
}
