<div class="container">

    <div class="row">
        <div class="col-md-12">
            <mat-tab-group #tab  [@.disabled]="true" class="mat_tab_width" (click)="TabChanges(tab)">
                <mat-tab label="New Registration"  class="table-custom">
                  <div class="input-group" style="margin-top: 10px;margin-bottom: 10px;">
                    <input type="text" class="form-control" placeholder="Search" style="width: 100%;" (keyup)="search($event.target.value)">
                </div>     
                    <div class="col-sm-12" style="padding:0px;">     
                            <table style="width: 100%;" class="demo table" (click)="onEvent($event)" #table mat-table [dataSource]="BindingNewlyRegDoctors" matSort class="mat-elevation-z8">
  
                                 <ng-container matColumnDef="select">
                                    <th mat-header-cell *matHeaderCellDef>
                                        <mat-checkbox (change)="$event ? masterToggle() : null"
                                            [checked]="selection.hasValue() && isAllSelected()"
                                            [indeterminate]="selection.hasValue() && !isAllSelected()">
                                        </mat-checkbox>
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <mat-checkbox (click)="$event.stopPropagation();" (change)="$event ? selection.toggle(row) : null"
                                            [checked]="selection.isSelected(row)">
                                        </mat-checkbox>
                                    </td>
                                </ng-container> 

                                <ng-container matColumnDef="firstName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                                    <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"><a style="text-decoration: underline;color:blue" (click)="viewDoctorDerails(element)">{{element?.firstName}}</a></div></td>
                                </ng-container>

                                <ng-container matColumnDef="middleName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Middle Name </th>
                                    <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"> {{element?.middleName}} </div></td>
                                </ng-container>

                                <ng-container matColumnDef="lastName">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                                    <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"> {{element?.lastName}} </div></td>
                                </ng-container>
                                  
                                <ng-container matColumnDef="emailId">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> EmailId </th>
                                    <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"> {{element?.emailId}} </div></td>
                                </ng-container>

                                <!-- <ng-container matColumnDef="primaryContact">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Contact </th>
                                    <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"> {{element.primaryContact}} </div></td>
                                </ng-container> -->
                                    <ng-container matColumnDef="Action">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                                        <td mat-cell *matCellDef="let row">
                                            <div class="btn-group" style="float: right;">
                                                <button type="button" class="btn btn-success btn-sm">
                                                    <i class="fa fa-edit"></i></button>
                                                <button type="button" class="btn btn-warning btn-sm"><span
                                                        class="fa fa-user"></span></button>
                                                <button type="button" class="btn btn-danger btn-sm">
                                                    <span class="fa fa-trash"></span>
                                                </button>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                            
                                <mat-paginator (click)="onEvent($event)"  [pageSize]="6" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
                        </div>
                     
                </mat-tab>
                <mat-tab label="Verify">
                    <div class="input-group" style="margin-top: 10px;margin-bottom: 10px;">
                        <input type="text" class="form-control" placeholder="Search" style="width: 100%;" (keyup)="search($event.target.value)">
                    </div>     
                        <div class="col-sm-12" style="padding:0px;">     
                                <table style="width: 100%;" class="demo table" (click)="onEvent($event)" #table mat-table [dataSource]="BindingverifiedDoctors" matSort class="">
      
                                     <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation();" (change)="$event ? selection.toggle(row) : null"
                                                [checked]="selection.isSelected(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container> 
    
                                    <ng-container matColumnDef="firstName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                                        <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"><a style="text-decoration: underline;color:blue" (click)="viewDoctorDerails(element)">{{element?.firstName}}</a></div></td>
                                    </ng-container>
    
                                    <ng-container matColumnDef="middleName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Middle Name </th>
                                        <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"> {{element?.middleName}} </div></td>
                                    </ng-container>
    
                                    <ng-container matColumnDef="lastName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                                        <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"> {{element?.lastName}} </div></td>
                                    </ng-container>
                                      
                                    <ng-container matColumnDef="emailId">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> EmailId </th>
                                        <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"> {{element?.emailId}} </div></td>
                                    </ng-container>
    
                                    <!-- <ng-container matColumnDef="primaryContact">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Contact </th>
                                        <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"> {{element.primaryContact}} </div></td>
                                    </ng-container> -->
                                        <ng-container matColumnDef="Action">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                                            <td mat-cell *matCellDef="let row">
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-success btn-sm">
                                                        <i class="fa fa-edit"></i></button>
                                                    <button type="button" class="btn btn-warning btn-sm"><span
                                                            class="fa fa-user"></span></button>
                                                    <button type="button" class="btn btn-danger btn-sm">
                                                        <span class="fa fa-trash"></span>
                                                    </button>
                                                </div>
                                            </td>
                                        </ng-container>
    
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                
                                    <mat-paginator (click)="onEvent($event)"  [pageSize]="6" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
                            </div>
                </mat-tab>
                <mat-tab label="Approved">
                    <div class="input-group" style="margin-top: 10px;margin-bottom: 10px;">
                        <input type="text" class="form-control" placeholder="Search" style="width: 100%;" (keyup)="search($event.target.value)">
                    </div>     
                        <div class="col-sm-12" style="padding:0px;">     
                                <table style="width: 100%;" class="demo table" (click)="onEvent($event)" #table mat-table [dataSource]="BindingverifiedDoctors" matSort class="mat-elevation-z8">
      
                                     <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation();" (change)="$event ? selection.toggle(row) : null"
                                                [checked]="selection.isSelected(row)">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container> 
    
                                    <ng-container matColumnDef="firstName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
                                        <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"><a style="text-decoration: underline;color:blue" (click)="viewDoctorDerails(element)">{{element?.firstName}}</a></div></td>
                                    </ng-container>
    
                                    <ng-container matColumnDef="middleName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Middle Name </th>
                                        <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"> {{element?.middleName}} </div></td>
                                    </ng-container>
    
                                    <ng-container matColumnDef="lastName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
                                        <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"> {{element?.lastName}} </div></td>
                                    </ng-container>
                                      
                                    <ng-container matColumnDef="emailId">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header> EmailId </th>
                                        <td mat-cell *matCellDef="let element"> <div class="table_name_ellipsis"> {{element?.emailId}} </div></td>
                                    </ng-container>
                                        <ng-container matColumnDef="Action">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                                            <td mat-cell *matCellDef="let row">
                                                <div class="btn-group">
                                                    <button type="button" class="btn btn-success btn-sm">
                                                        <i class="fa fa-edit"></i></button>
                                                    <button type="button" class="btn btn-warning btn-sm"><span
                                                            class="fa fa-user"></span></button>
                                                    <button type="button" class="btn btn-danger btn-sm">
                                                        <span class="fa fa-trash"></span>
                                                    </button>
                                                </div>
                                            </td>
                                        </ng-container>
    
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                    </table>
                                
                                    <mat-paginator (click)="onEvent($event)"  [pageSize]="6" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
                            </div>
                </mat-tab>
            </mat-tab-group>
           
        </div>
       

    </div>

</div>




<mat-sidenav mode="side" #doctorDetails cloused style="position:absolute;">

    <h3 class="head">
        Patient Details 
        <a href="javascript:void(0)" class="closebtn vitalsCls" (click)="doctorDetails.close()">×</a>

    </h3>
 

<app-doctor-details></app-doctor-details>

</mat-sidenav>


<!-- style="z-index:99;position:absolute;margin-top: 62px;padding: 0px 0px 0px 0px;background-color:#F4F4F4;box-shadow:5px 10px 18px #3a3a3a;width: 450px;" -->