import { GlobalStorageService } from 'src/app/global-storage.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DoctorService } from 'src/app/AppServices/doctor.service';

@Component({
  selector: 'app-view-ratings',
  templateUrl: './view-ratings.component.html',
  styleUrls: ['./view-ratings.component.css']
})
export class ViewRatingsComponent implements OnInit {
  setItemsPerPage=6;
  p6=1;
  feedBack=[]
  constructor(private globalStorage: GlobalStorageService, private ratings:DoctorService) { }

  ngOnInit(): void {
    this.ratings.getDoctorRating(this.globalStorage.getItem('doctorId')).subscribe(res=>{
      this.feedBack=res['data'];
    })
  }
  counter(i:any) {
    return new Array(JSON.parse(i));
}
}
