<div class="modal-header">
    <h5 class="modal-title"> <span class="spaneeditem2"><i class="fas fa-notes-medical"></i>&nbsp; Discount Request Information</span>
    </h5>
    <button type="button" class="close" (click)="activeModal.close()" aria-label="Close"
     > <span aria-hidden="true">×</span>
    </button>
  </div>
<div class="modal-body">
    <div class="row" [formGroup]="discountInfoForm">
        <div class="col-md-6 form-group">
            <label  for="" class="col-form-label">Patient Name</label>
            <input type="text" class="form-control" formControlName="patientName" readonly>
        </div>
        <div class="col-md-6 form-group">
            <label  for="" class="col-form-label">Net Amount</label>
            <input type="text" class="form-control" formControlName="netAmount" readonly>
        </div>
        <div class="col-md-6 form-group">
            <label  for="" class="col-form-label">Requested Amount</label>
            <input type="text" class="form-control" formControlName="requestAmount" readonly>
        </div>
        <div class="col-md-6 form-group">
            <label  for="" class="col-form-label">Approver Name</label>
            <input type="text" class="form-control" formControlName="approverName" readonly>
        </div>
    </div>
</div>

